import { Fragment, useMemo, useState } from 'react'
import { Campaignservicev1OrderLine, V1OrderLineSearch } from 'next-gen-sdk'
import {
  showErrorMessage,
  TableColumnType,
  TableHeader,
  TableRow,
  TextHeader,
} from '@eltoro-ui/components'
import { usePagination } from '@eltoro-ui/hooks'
import { useAppContext } from 'Contexts'
import {
  CampaignContainer,
  CollapseButton,
  EmptyField,
  TablePagination,
} from 'Components'
import { OrderlineContainer } from 'Components/OrderlineTable/OrderlineContainer'
import { OrderlineStateIndicator } from 'Components/OrderlineTable/OrderlineStateIndicator'
import { orderLineStatusOptions } from 'searchOptions'
import { OrgNameCell } from '../../OrgNameCell'
import { statMakerForEnvironment } from 'Components/Reports/Stats/statMockData'
import { getOrderLineStats } from 'Helpers/getOrderLineStats'

// prettier-ignore
const searchOrderLineTableColumns = (isAdmin?: boolean): TableColumnType<V1OrderLineSearch>[] => [
  {
    path: 'name',
    label: 'Name',
    width: '22rem',
    removeFilter: true,
    removeSort: true,
  },
  {
    path: 'state',
    label: 'Status',
    filterOn: orderLineStatusOptions(isAdmin),
    removeFilter: true,
    removeSort: true,
    RowCell: ({ state }) =>
      state ? <OrderlineStateIndicator state={state} /> : <EmptyField />,
  },
  {
    path: 'orgId',
    label: 'Source Org',
    removeFilter: true,
    removeSort: true,
    RowCell: ({ orgLogo, orgName }) =>
      orgName ? <OrgNameCell name={orgName} logo={orgLogo} /> : <EmptyField />,
  },
  {
    path: 'orderLineId',
    label: 'ID',
    removeFilter: true,
    removeSort: true
  },
]

export const SearchOrderLineTable: React.FC<{
  results: V1OrderLineSearch[]
}> = ({ results }) => {
  const [opened, setOpened] = useState<{
    orderline?: Campaignservicev1OrderLine
    campaignId?: string
    type: 'campaign' | 'ol'
  }>()
  const [olStats, setOlStats] = useState({})
  const { campaignServiceApi, isAdmin } = useAppContext()

  const orderLinePagination = usePagination<V1OrderLineSearch>({
    pageSize: 5,
    sourceData: results,
  })

  const getOLStats = (ol: Campaignservicev1OrderLine) => {
    if (!ol.orgId) return
    if (
      process.env.REACT_APP_ENV === 'production' &&
      !/dev/.test(window.location.href)
    ) {
      getOrderLineStats(ol, ol.orgId, 'day').then((statsRes) => {
        if (statsRes) setOlStats(statsRes)
      })
    } else {
      setOlStats(statMakerForEnvironment(ol))
    }
  }

  const tableColumns = useMemo(
    () => [
      ...searchOrderLineTableColumns(isAdmin),
      {
        path: '',
        label: '',
        alignment: 'right' as 'right', // loltypescript
        removeFilter: true,
        removeSort: true,
        RowCell: () => (
          <div className="SearchOrderLineTableRow__collapse-btn flex justify-end">
            <CollapseButton isCollapsed handleClick={() => {}} iconOnly />
          </div>
        ),
      },
    ],
    [isAdmin],
  )

  if (!results.length) return null

  return (
    <div className="SearchOrderLineTable">
      <TextHeader type={4} className="SearchOrderLineTable__header mt-4">
        Order Lines ({results.length})
      </TextHeader>
      {orderLinePagination.data && (
        <div className="p-4">
          <table className="Table">
            <TableHeader
              columns={tableColumns}
              // onSort={(columnIndex, sort, path) => {}}
              // onFilter={(columnIndex, path, filterOn) => {}}
            />
            <tbody className="Table__body">
              {orderLinePagination.data.map((row) => {
                return (
                  <Fragment key={row.orderLineId}>
                    {!opened ||
                    (opened && opened.orderline?.id !== row.orderLineId) ? (
                      <TableRow
                        key={`SearchOrderLineTableRow-${row.orderLineId}`}
                        row={row}
                        columns={tableColumns}
                        onClick={() => {
                          if (row.orderLineId && row.orgId) {
                            campaignServiceApi
                              ?.advertisingPlatformServiceGetOrderLine(
                                row.orderLineId,
                                row.orgId,
                              )
                              .then((orderline) => {
                                getOLStats(orderline)
                                setOpened({
                                  orderline,
                                  type: 'ol',
                                })
                              })
                              .catch((err) => {
                                if (
                                  err?.body?.message === 'orderLineNotFound'
                                ) {
                                  showErrorMessage(
                                    'Order Line Not Found',
                                    `The order line was not found. Order Line ID: ${row.orderLineId}`,
                                  )
                                }
                              })
                          }
                        }}
                        getColspan={() =>
                          searchOrderLineTableColumns(isAdmin).length + 1
                        }
                      />
                    ) : (
                      <tr
                        key={`OpenedContainer-${row.orderLineId}`}
                        className="TableRow--has-on-click OrderlineTableRow__expanded-row bg-base border-y-thin border-tint-gray-400 border-solid"
                      >
                        <td
                          colSpan={
                            searchOrderLineTableColumns(isAdmin).length + 1
                          }
                          className="TableCell OrderlineTableRow__expanded-cell animate-slidedown"
                        >
                          {opened?.type === 'ol' && opened.orderline && (
                            <OrderlineContainer
                              stats={olStats}
                              orderline={opened.orderline}
                              handleCollapse={() => {
                                setOpened(undefined)
                              }}
                              handleOpenCampaign={(campaignId, orderline) =>
                                setOpened({
                                  orderline,
                                  campaignId,
                                  type: 'campaign',
                                })
                              }
                            />
                          )}
                          {opened?.type === 'campaign' &&
                            opened?.campaignId &&
                            opened.orderline?.orgId && (
                              <>
                                <CampaignContainer
                                  campaignId={opened.campaignId}
                                  handleCollapse={() => setOpened(undefined)}
                                  orgId={opened.orderline?.orgId}
                                />
                              </>
                            )}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
            </tbody>
          </table>
          <div className="flex justify-end pt-2">
            <TablePagination pagination={orderLinePagination} />
          </div>
        </div>
      )}
    </div>
  )
}
