import { Button } from '@eltoro-ui/components'

// To be used as the footer for all order line edit modals
export const EditingCancelUpdateButtons = ({
  onCloseModal,
  handleUpdate,
  textAction = 'Update',
  textCancel = 'Cancel',
  updateDisabled = false,
}: {
  onCloseModal: () => void
  handleUpdate: () => void
  textAction?: string
  textCancel?: string
  updateDisabled?: boolean
}) => {
  return (
    <div className="StickyFooter EditingCancelUpdateButtons ButtonGroup flex justify-end gap-5">
      <Button size="l" onClick={onCloseModal}>
        {textCancel}
      </Button>
      <Button
        size="l"
        onClick={handleUpdate}
        className={!updateDisabled ? 'ripples' : 'disabled:!bg-transparent'}
        disabled={updateDisabled}
      >
        {textAction}
      </Button>
    </div>
  )
}
