import React from 'react'
import { Button, Modal, Fa } from '@eltoro-ui/components'
import { Creativeservicev1Creative, V1CreativeFile } from 'next-gen-sdk'
import { isVideo, isImage, cleanedMime } from 'Helpers'
import './CreativePreview.scss'

interface CreativePreviewBasic {
  offClick: () => void
}

interface CreativePreviewMultiple extends CreativePreviewBasic {
  file?: never
  creatives: Creativeservicev1Creative[]
}

interface CreativePreviewFile extends CreativePreviewBasic {
  file: V1CreativeFile
  creatives?: never
}

export const CreativePreview = ({
  file,
  creatives,
  offClick,
}: CreativePreviewFile | CreativePreviewMultiple) => {
  // Single file view
  if (file) {
    const { mime, height, width, bucket, key } = file
    const url = `https://s3.amazonaws.com/${bucket}/${key}`
    if (!mime) return null
    const cleanMime = cleanedMime(mime)
    return (
      <Modal
        className="CreativePreview__modal"
        scrollable={false}
        offClick={offClick}
      >
        {file && isImage(cleanMime) && (
          <>
            <img
              src={url}
              width={width}
              height={height}
              alt="full size"
              className="CreativePreview__full-image"
            />

            <div className="CreativePreview__dimensions">
              {width} x {height}, {mime}
            </div>
          </>
        )}
        {file && isVideo(cleanMime) && (
          <video className="CreativePreview__video" controls>
            <source src={url} type={cleanMime} />
            <track default kind="captions" />
            Your browser does not support the video element.
          </video>
        )}
        <div className="CreativePreview__close-button">
          <Button
            iconOnly={<Fa icon="times" size={1} />}
            rounded
            onClick={offClick}
          />
        </div>
      </Modal>
    )
  }
  // Multiple file view
  return (
    <Modal
      className="CreativePreview__modal"
      scrollable={false}
      offClick={offClick}
    >
      {creatives &&
        creatives?.map((currentCreative: Creativeservicev1Creative) => {
          const imgFile = currentCreative.files && currentCreative.files[0]
          if (!imgFile) return null
          const { mime } = imgFile
          if (!mime) return null
          const cleanMime = cleanedMime(mime)
          return (
            <div className="CreativePreview__item" key={currentCreative.id}>
              {isImage(cleanMime) && (
                <div className="CreativePreview__image-wrap">
                  {/* second map needed to drill down the nested array for files in Creativeservicev1Creative[] to get the images' urls, widths, heights in V1CreativeFile */}
                  {currentCreative?.files?.map(
                    (creativeImage: V1CreativeFile) => (
                      <React.Fragment key={`figure-${creativeImage.id}`}>
                        <figure className="CreativePreview__image">
                          <img
                            key={currentCreative.id}
                            // second map provides the prop from V1CreativeFile
                            src={`https://s3.amazonaws.com/${creativeImage.bucket}/${creativeImage.key}`}
                            alt={currentCreative?.name}
                          />
                          <figcaption>{currentCreative?.name}</figcaption>
                        </figure>
                        <div className="CreativePreview__dimensions">
                          {/* second map provides the props from V1CreativeFile */}
                          {creativeImage?.width} x {creativeImage?.height}
                        </div>
                      </React.Fragment>
                    ),
                  )}
                </div>
              )}
            </div>
          )
        })}
      <div className="CreativePreview__close-button">
        <Button
          iconOnly={<Fa icon="times" size={1} />}
          rounded
          onClick={offClick}
        />
      </div>
    </Modal>
  )
}
