import { PreviewType, showErrorMessage } from '@eltoro-ui/components'
import {
  Targetorchestratorv2Column,
  Targetorchestratorv2FileType,
  V2Target,
} from 'next-gen-sdk'
import axios from 'axios'
import { ApiFactory } from 'apiFactory'
import { getApiUrl, poll } from 'Helpers'
import { getApiConfig } from 'Requests'

export type ReplaceOptions = {
  targetId: string
  audienceIds: string[]
}

export const uploadTargetFile = async (
  name: string,
  file: PreviewType | File | Blob,
  token: string,
  orgId: string,
  fileType: Targetorchestratorv2FileType,
  hasHeader?: boolean,
  columns?: Targetorchestratorv2Column[],
  onProgress?: (percentage: number) => void,
  dataSource?: string,
  replace?: ReplaceOptions, // Used to hit the replace endpoint instead of upload- updates target file
) => {
  const url = `${getApiUrl()}/v1`

  const config = { Authorization: `Bearer ${token}` }

  const data = new FormData()
  data.append(
    'metadata',
    JSON.stringify({
      filename: name,
      org_id: orgId,
      file_type: fileType,
      has_header: hasHeader,
      columns,
      data_source: replace ? undefined : dataSource,
      ...(replace
        ? {
            target_id: replace.targetId,
            audience_ids: replace.audienceIds,
          }
        : {}),
    }),
  )
  data.append('chunk', file)

  const res = await axios.post(
    `${url}/targets:${replace ? 'replace' : 'upload'}?org_id=${orgId}`,
    data,
    {
      headers: config,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        if (onProgress) onProgress(percentage)
      },
    },
  )

  if (res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }

  const uploadedTarget = res.data as V2Target
  if (uploadedTarget.id) {
    const apis = ApiFactory(token)
    const { targetServiceAPI } = apis
    if (!targetServiceAPI) return undefined
    // wait for the status to be targetable
    const validate = (targ?: V2Target) =>
      targ?.status !== 'TARGET_STATUS_TARGETABLE' &&
      targ?.status !== 'TARGET_STATUS_ERRORED' &&
      targ?.status !== 'TARGET_STATUS_CANCELED'

    const target = await poll(
      (token) =>
        targetServiceAPI.advertisingPlatformServiceGetTarget(
          uploadedTarget.id || '',
          orgId,
          token ? getApiConfig(token) : undefined,
        ),
      validate,
      1000,
      900000,
    )

    if (target?.status !== 'TARGET_STATUS_TARGETABLE') {
      showErrorMessage(
        'Error creating target',
        'There was an error creating your target. Please try again or contact support of you continue to see this error.',
      )
      return
    }

    return target
  }

  return undefined
}
