export const capitalize = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`

export const truncateIt = (text: string, maxLength: number) =>
  text?.length > maxLength ? `${text.substring(0, maxLength - 1)} ... ` : text

export const isInt = (value: string) => {
  let x
  if (isNaN(Number(value))) {
    return false
  }
  x = parseFloat(value)
  return (x | 0) === x
}

export const truncateMiddle = (str = '', strLen: number, midStr = '... ') => {
  if (str.length <= strLen) return str
  const midLen = midStr.length
  const charsToShow = strLen - midLen
  const startChars = Math.ceil(charsToShow / 2)
  const endChars = Math.floor(charsToShow / 2)
  return (
    str.substring(0, startChars) + midStr + str.substring(str.length - endChars)
  )
}

export const breakTextWithDelimiters = (
  text: string,
  maxCharsPerLine: number,
): string[] => {
  const delimiters = ['_', '-'] // Add hyphen as a delimiter
  const words: string[] = []
  let currentWord = ''

  for (const char of text) {
    if (delimiters.includes(char)) {
      if (currentWord) {
        words.push(currentWord)
        currentWord = ''
      }
      words.push(char)
      words.push(' ') // Add a space after '_' or '-', so text wraps naturally
    } else {
      currentWord += char
    }
  }
  if (currentWord) {
    words.push(currentWord)
  }

  let lines: string[] = []
  let currentLine = ''

  for (const word of words) {
    if (currentLine.length + word.length <= maxCharsPerLine) {
      currentLine += word
    } else {
      lines.push(currentLine)
      currentLine = word
    }
  }

  if (currentLine) {
    lines.push(currentLine)
  }

  return lines
}
