/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1AdType } from '../models/Creativeservicev1AdType';
import { Creativeservicev1AuditStatus } from '../models/Creativeservicev1AuditStatus';
import { Creativeservicev1Type } from '../models/Creativeservicev1Type';
import { HttpFile } from '../http/http';

/**
* The event emitted when Creative is updated on an order line
*/
export class V1OrderLineCreativeSet {
    'orderLineId'?: string;
    'creative'?: string;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;
    'creativeType'?: Creativeservicev1Type;
    'adType'?: Creativeservicev1AdType;
    'auditStatus'?: Creativeservicev1AuditStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creative",
            "baseName": "creative",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeType",
            "baseName": "creative_type",
            "type": "Creativeservicev1Type",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Creativeservicev1AdType",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Creativeservicev1AuditStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineCreativeSet.attributeTypeMap;
    }

    public constructor() {
    }
}

