const arrayToCsv = (data: any[]) => {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v: string) => v.replaceAll('"', '""')) // escape double colons
          .map((v: string) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n') // rows starting on new lines
}
const downloadBlob = (
  content: string,
  filename: string,
  contentType: string,
) => {
  // Create a blob
  var blob = new Blob([content], { type: contentType })
  var url = URL.createObjectURL(blob)

  // Create a link to download it
  var invisibileLink = document.createElement('a')
  invisibileLink.href = url
  invisibileLink.setAttribute('download', filename)
  invisibileLink.click()
}
export const BuildAndDownloadCSV = (
  rawData: any[],
  fName: string,
  cType: string,
) => {
  const csvData = arrayToCsv(rawData)
  downloadBlob(csvData, fName, cType)
}
