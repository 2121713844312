import { ETIconPaths } from './ETIconPaths'
import './ETIcon.scss'

interface ISVGIcon extends React.SVGProps<SVGSVGElement> {
  box?: string
  className?: string
  fill?: string
  height?: string
  icon: string
  width?: string
}

export const ETIcons = () => {
  return (
    <svg id="SVG-source" className="ETSVGWrap" style={{ display: 'none' }}>
      <defs>
        {ETIconPaths.map(({ iconName, pathPrimary, pathSecondary }) => (
          <g id={`${iconName}-icon`} key={iconName}>
            <path id={`${iconName}-primary`} d={pathPrimary} />
            <path
              id={`${iconName}-secondary`}
              fill="currentColor"
              d={pathSecondary}
            />
          </g>
        ))}
      </defs>
    </svg>
  )
}

export const ETIcon = ({
  box = '50',
  className = '',
  icon,
  height = '100%',
  width = '100%',
  ...other
}: ISVGIcon) => (
  <svg
    id={`${icon}-use`}
    xmlns="http://www.w3.org/2000/svg"
    className={`ETIcon ${className}`}
    height={height}
    viewBox={`0 0 ${box} ${box}`}
    width={width}
    {...other}
  >
    <title>{icon} icon</title>
    <use href={`#${icon}-icon`} />
  </svg>
)
