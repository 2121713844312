/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3BudgetInterval } from '../models/V3BudgetInterval';
import { V3PoliticalContent } from '../models/V3PoliticalContent';
import { HttpFile } from '../http/http';

/**
* A specific Insertion Order configuration for Invest Campaigns. See [Insertion Order](https://docs.xandr.com/bundle/xandr-api/page/insertion-order-service.html) in Xandr's documentation.
*/
export class V3SeamlessInsertionOrder {
    /**
    * The name of the insertion order. (Maximum of 255 characters.)
    */
    'name'?: string;
    /**
    * The custom code for the insertion order.
    */
    'code'?: string;
    /**
    * The budget type of the insertion order. Values may be 'revenue' or 'impression'.
    */
    'budgetType'?: string;
    /**
    * The currency assigned to the insertion order.
    */
    'currency'?: string;
    /**
    * The timezone with which the line item is set to. This will affect the start_date and end_date.
    */
    'timezone'?: string;
    /**
    * Budget intervals enable multiple date intervals to be attached to an insertion order, each with corresponding budget values.
    */
    'budgetIntervals'?: Array<V3BudgetInterval>;
    'politicalContent'?: V3PoliticalContent;
    /**
    * The ID of the insertion order.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "budgetType",
            "baseName": "budget_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "currency",
            "baseName": "currency",
            "type": "string",
            "format": ""
        },
        {
            "name": "timezone",
            "baseName": "timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "budgetIntervals",
            "baseName": "budget_intervals",
            "type": "Array<V3BudgetInterval>",
            "format": ""
        },
        {
            "name": "politicalContent",
            "baseName": "political_content",
            "type": "V3PoliticalContent",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3SeamlessInsertionOrder.attributeTypeMap;
    }

    public constructor() {
    }
}

