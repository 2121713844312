import { Button, Fa, Popover, Spacer } from '@eltoro-ui/components'
import React, { useState } from 'react'
import { ResetSettings } from '../EditBlock/components/ResetSettings'
import { HorizontalLabel } from '../HorizontalLabel'
import './MoreInfoContainer.scss'

export const MoreInfoContainer: React.FC<{
  label: string
  showReset?: boolean
  onReset?: () => void
  hasError?: boolean
}> = ({ label, showReset = false, onReset, hasError = false, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="MoreInfoContainer">
      <HorizontalLabel label={label} hasError={hasError}>
        <ResetSettings
          showReset={showReset}
          onReset={() => onReset && onReset()}
        >
          <Popover
            align="end"
            position={['bottom']}
            content={
              <>
                <Spacer height="0.5rem" />
                <div className="MoreInfoContainer__body">{children}</div>
              </>
            }
            isOpen={open}
          >
            <div>
              <Button
                kind={open ? 'default' : 'text'}
                onClick={() => setOpen(!open)}
                iconLeft={<Fa icon="edit" size={1} />}
              />
            </div>
          </Popover>
        </ResetSettings>
      </HorizontalLabel>
    </div>
  )
}
