import { Button, FlexBox, Spacer, Fa } from '@eltoro-ui/components'

import React from 'react'

export const ResetSettings: React.FC<{
  showReset: boolean
  onReset: () => void
  children: React.ReactNode
}> = ({ showReset, onReset, children }) => {
  return (
    <FlexBox alignItems="center">
      {showReset && (
        <Button
          kind="text"
          iconLeft={<Fa icon="undo" size={1} />}
          onClick={onReset}
        />
      )}
      <Spacer width="0.5rem" />
      {children && <>{children}</>}
    </FlexBox>
  )
}
