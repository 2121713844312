import { InfoPopover } from 'Components'

export const BrandURLPop = ({ className = '' }: { className?: string }) => {
  return (
    <span className="BrandURLPop relative flex min-w-min">
      <span className="Popover__label !text-text flex gap-0">
        <span className="Optional !font-light">(Optional) &nbsp;</span>
        <span className="text-tint-gray-700 flex-1">Brand URL</span>
        <span className="text-tint-gray-700 Colon">:</span>
      </span>
      <InfoPopover
        classNameWrap={`OptionalBrandURL__infopopover ml-auto w-60 p-3 text-m ${className}`}
        content={
          <>
            <span className="text-text leading-snug">
              The optional brand URL is a secondary URL used in Political and
              Advocacy campaigns. It provides your campaign's branding
              information when the click-through (primary) URL is insufficient,
              such as a news article.
            </span>
            <a
              className="block pt-2"
              href="https://support.eltoro.com/hc/en-us/articles/16762100131483-Political-Creatives"
              target="_blank"
              rel="noopener noreferrer"
            >
              More about Political Creatives &#8811;
            </a>
          </>
        }
      />
    </span>
  )
}
