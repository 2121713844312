/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Notificationservicev1Dependency } from '../models/Notificationservicev1Dependency';
import { Notificationservicev1Miscellanea } from '../models/Notificationservicev1Miscellanea';
import { Notificationservicev1SystemStat } from '../models/Notificationservicev1SystemStat';
import { HttpFile } from '../http/http';

export class Notificationservicev1Checks {
    'systemStat'?: Notificationservicev1SystemStat;
    'dependencies'?: Array<Notificationservicev1Dependency>;
    'miscellaneous'?: Array<Notificationservicev1Miscellanea>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "systemStat",
            "baseName": "systemStat",
            "type": "Notificationservicev1SystemStat",
            "format": ""
        },
        {
            "name": "dependencies",
            "baseName": "dependencies",
            "type": "Array<Notificationservicev1Dependency>",
            "format": ""
        },
        {
            "name": "miscellaneous",
            "baseName": "miscellaneous",
            "type": "Array<Notificationservicev1Miscellanea>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Notificationservicev1Checks.attributeTypeMap;
    }

    public constructor() {
    }
}

