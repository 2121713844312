import { OptionalFilterConfig } from 'Components/OptionalFilters'

export const creativeFilterConfig: OptionalFilterConfig[] = [
  {
    filter: 'all',
    icon: 'browsers',
  },
  {
    filter: 'banner',
    icon: 'image',
  },
  {
    filter: 'video',
    icon: 'circle-play',
  },
  {
    filter: 'print',
    icon: 'envelopes-bulk',
  },
  {
    filter: 'audio',
    icon: 'headphones',
  },
]
