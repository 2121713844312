import React from 'react'
import { LoadingBar } from '@eltoro-ui/components'
import { ErrorMsg } from 'Components'
import { ETIcon } from 'Assets'

export const AdTagFormPreview = ({
  type,
  loading,
  error,
}: {
  type: 'video' | 'banner'
  loading: boolean
  error: boolean
}) => {
  return (
    <div className="AdTagFormPreview bg-tint-light mt-3 flex w-full flex-grow flex-col items-center justify-center rounded-xl p-4 shadow-lg">
      {loading ? (
        <LoadingBar />
      ) : (
        <ETIcon
          icon={(() => {
            if (error) return 'question'
            if (type === 'video') return 'vast-tag'
            return 'ad-tag'
          })()}
          className="ETIcon--full w-28"
        />
      )}
      {/* <p>Your ad will preview here</p> */}
      {error && (
        <ErrorMsg className="animate-slidedown max-w-lg">
          <span>
            Your creative failed to save. Please re-check your
            {type === 'video' ? ' url' : ' code'}. If you are still experiencing
            issues, please review this
            <a
              target="_blank"
              rel="noopener noreferrer"
              // TODO: Link the proper article when it is published
              href="https://eltoro.zendesk.com/hc/en-us"
              className="font-bold"
            >
              {' '}
              help article{' '}
            </a>
            or contact
            <a href="mailto:support@eltoro.com" className="font-bold">
              {' '}
              support@eltoro.com
            </a>
          </span>
        </ErrorMsg>
      )}
    </div>
  )
}
