/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3AuditResult } from '../models/Deployservicev3AuditResult';
import { Deployservicev3AuditStatus } from '../models/Deployservicev3AuditStatus';
import { Deployservicev3DeploymentType } from '../models/Deployservicev3DeploymentType';
import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { HttpFile } from '../http/http';

/**
* A deployment's audit information
*/
export class Deployservicev3Audit {
    /**
    * The ID of the audit.
    */
    'id'?: string;
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;
    'result'?: Deployservicev3AuditResult;
    'type'?: Deployservicev3DeploymentType;
    'destination'?: Deployservicev3Destination;
    /**
    * The deployment id of the audit.
    */
    'deploymentId'?: string;
    'auditStatus'?: Deployservicev3AuditStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "result",
            "baseName": "result",
            "type": "Deployservicev3AuditResult",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Deployservicev3DeploymentType",
            "format": ""
        },
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "deploymentId",
            "baseName": "deployment_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Deployservicev3AuditStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Deployservicev3Audit.attributeTypeMap;
    }

    public constructor() {
    }
}

