import { useEffect, useState } from 'react'
import { useAppContext } from 'Contexts'
import { Fa } from '@eltoro-ui/components'

export const GetName = ({ userId }: { userId: string }) => {
  const { userServiceApi } = useAppContext()
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const _getName = async () => {
      if (!userId || !userServiceApi) return ''

      return await userServiceApi
        .advertisingPlatformServiceGetUser(userId)
        .then((res) => {
          setName(`${res.firstName} ${res.lastName}`)
          setLoading(false)
        })
    }

    _getName()
  }, [])

  if (loading) {
    return <Fa icon="spinner" animate="spin" size={1} />
  }

  return <>{name}</>
}
