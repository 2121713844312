/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* The Deal Service lets buyers, sellers, and external bidders set up and manage negotiated deals.
*/
export class V3LineItemDeal {
    /**
    * The id of the deal [deals](https://docs.xandr.com/bundle/xandr-api/page/deal-service.html).
    */
    'id'?: number;
    /**
    * The name of the deal.
    */
    'name'?: string;
    /**
    * The type of deal. For sellers, a deal can be an open auction or a private auction.
    */
    'type'?: string;
    /**
    * The size_preference of the deal.
    */
    'sizePreference'?: string;
    /**
    * Specifies how the deal handles creatives. There are three options: none(default), provisional, and max_trust.
    */
    'auditStatusOption'?: string;
    /**
    * Specifies how this deal handles media types/subtypes. There are two options: standard and append.
    */
    'mediaPreference'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "sizePreference",
            "baseName": "size_preference",
            "type": "string",
            "format": ""
        },
        {
            "name": "auditStatusOption",
            "baseName": "audit_status_option",
            "type": "string",
            "format": ""
        },
        {
            "name": "mediaPreference",
            "baseName": "media_preference",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3LineItemDeal.attributeTypeMap;
    }

    public constructor() {
    }
}

