import {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEvent,
  MutableRefObject,
  Ref,
  useState,
} from 'react'
import './TextInput.scss'
import classNames from 'classnames'
import '../../Assets/css/all.css'
import { Fa } from '../FontAwesome'
import { styleTailwind } from '../../helpers'

type TextInputType = {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  password?: boolean
  id?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  size?: 's' | 'm' | 'l' | 'xl'
  type?:
    | 'date'
    | 'email'
    | 'image'
    | 'number'
    | 'reset'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
  disabled?: boolean
  classNameWrap?: string
  className?: string
  maxLength?: number
  minLength?: number
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  inputRef?: Ref<HTMLInputElement> | MutableRefObject<HTMLInputElement>
  wrapperRef?: Ref<HTMLDivElement> | MutableRefObject<HTMLDivElement>
  autoComplete?: string
  valid?: boolean
  hideValidIcon?: boolean
}

const TextInputIcon = styleTailwind(
  'div',
  'bg-primary-100 justify-center flex items-center text-primary-600',
)

export const TextInput = ({
  value = '',
  onChange,
  placeholder = '',
  iconLeft,
  iconRight,
  valid,
  hideValidIcon = false,
  password,
  id,
  onKeyDown,
  size = 'm',
  type = 'text',
  disabled = false,
  classNameWrap = 'TextInput__wrap',
  className = 'TextInput__input-field',
  maxLength = 256,
  minLength,
  onFocus,
  onBlur,
  inputRef,
  wrapperRef,
  autoComplete,
}: TextInputType) => {
  const [isFocused, setIsFocused] = useState(false)
  const [touched, setTouched] = useState(false)

  const sizeClass = ` TextInput--${size}-size`
  const modifierClasses = classNames(' ', sizeClass, classNameWrap, {
    'TextInput--is-focused': isFocused,
  })
  // add this handler to prevent inputs with type 'tel' from accepting alpha characters
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    if (type === 'tel') {
      inputValue = inputValue.replace(/[^0-9]/g, '')
    }
    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength)
    }
    onChange({
      ...e,
      target: {
        ...e.target,
        value: inputValue,
      },
    })
    setTouched(true)
  }

  return (
    <div
      className={`TextInput${modifierClasses} relative w-full disabled:!animate-none`}
      ref={wrapperRef}
    >
      {!!iconLeft && (
        <TextInputIcon className="TextInput__iconLeft">
          {iconLeft}
        </TextInputIcon>
      )}
      <input
        onKeyDown={onKeyDown}
        id={id}
        className={`TextInput__input transition-all ${className} rounded-lg`}
        type={password ? 'password' : type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        onFocus={(e) => {
          setIsFocused(true)
          if (onFocus) onFocus(e)
        }}
        onBlur={(e) => {
          setIsFocused(false)
          if (onBlur) onBlur(e)
          setTouched(true)
        }}
        disabled={disabled && !!maxLength}
        ref={inputRef}
        autoComplete={autoComplete}
      />
      {(() => {
        if (iconRight)
          return (
            <TextInputIcon className="TextInput__iconRight absolute right-0">
              {iconRight}
            </TextInputIcon>
          )
        if (!hideValidIcon && valid !== undefined && touched)
          return (
            <TextInputIcon className="TextInput__iconRight absolute right-0">
              <Fa
                icon={valid && value.length <= maxLength ? 'check' : 'times'}
                size={1}
              />
            </TextInputIcon>
          )
        return null
      })()}
    </div>
  )
}
