import { useRef, useState } from 'react'
import {
  Fa,
  Pagination,
  Popover,
  Table,
  TextHeader,
  showErrorMessage,
} from '@eltoro-ui/components'
import { useOnClickOutside, useTokenPagination } from '@eltoro-ui/hooks'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { GetName } from 'Helpers'

const OrderLineEventHistoryTable = ({
  orderline,
}: {
  orderline: Campaignservicev1OrderLine
}) => {
  const { campaignServiceApi, currentOrg } = useAppContext()

  const fetchData = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (orderline?.id && campaignServiceApi && currentOrg?.id) {
      const res = await campaignServiceApi
        .advertisingPlatformServiceListOrderLineEvents(
          orderline.id, // orderline id
          currentOrg.id, //orgId
          pageSize, // pageSize
          nextPageToken, // pageToken
        )
        .then((res) => {
          if (res.events) {
            return {
              data: res.events.map((event) => {
                const type = event.eventType
                const objectValues = Object.values(event)
                const eventObj = objectValues.find(
                  (obj) => typeof obj === 'object',
                )
                const date: Date | undefined = eventObj?.timestamp || undefined
                const userId: string = eventObj?.userId || undefined
                return { type, date, userId }
              }),
              nextPageToken: res.nextPageToken,
              totalItems: res.totalSize || 0,
            }
          }
          return undefined
        })
        .catch(() => {
          showErrorMessage('Error fetching order line events', '')
          return undefined
        })

      return res?.data
        ? {
            data: res.data,
            totalItems: res.totalItems,
            nextPageToken: res.nextPageToken,
          }
        : empty
    }
    return empty
  }

  const pagination = useTokenPagination<{
    type?: string
    date?: Date
    userId?: string
  }>({
    pageSize: 10,
    fetchData,
  })

  const {
    currentPageData: history,
    loading,
    setPage,
    // totalItems,
    // page,
  } = pagination

  if (loading)
    return (
      <div className="OrderLineEventHistory__loading flex min-h-[5rem] w-full items-center justify-center gap-2">
        <Fa
          icon="history"
          size={5}
          className="text-tint-gray-200 animate-pulse"
        />
      </div>
    )
  return (
    <>
      <Table
        className="animate-fadein duration-1000"
        rows={history}
        columns={[
          {
            path: 'type',
            label: 'Event Type',
            RowCell: (row) => {
              if (!row.type) return null
              return (
                row.type
                  ?.match(/([A-Z]?[^A-Z]*)/g)
                  ?.slice(0, -1)
                  .join(' ') || ''
              )
            },
          },
          {
            path: 'user',
            label: 'User',
            RowCell: (row) => {
              if (!row.userId) return null
              return <GetName userId={row.userId} />
            },
          },
          {
            path: 'date',
            label: 'Date',
            RowCell: (row) => {
              if (!row.date) return null
              const h = row.date.getHours()
              const m = row.date.getMinutes()
              const s = row.date.getSeconds()
              return `${row.date.toLocaleDateString()} ${h}:${m}:${s}`
            },
          },
        ]}
      />
      {history.length > 0 && (
        <Pagination
          {...pagination}
          onChange={(pageInfo) => {
            setPage(pageInfo.page)
          }}
          className="OrderLineEventHistory__pagination mt-2"
        />
      )}
    </>
  )
}

export const OrderLineEventHistory = ({
  orderline,
}: {
  orderline: Campaignservicev1OrderLine
}) => {
  const [showHistory, setShowHistory] = useState(false)

  const historyIconRef = useRef<HTMLDivElement>(null)
  const historyPopoverRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([historyIconRef, historyPopoverRef], () =>
    setShowHistory(false),
  )

  return (
    <Popover
      position={['bottom']}
      align="start"
      isOpen={showHistory}
      content={
        <div
          className="OrderLineEventHistory__history OrderLine__popover"
          ref={historyPopoverRef}
        >
          <TextHeader type={4} className="my-1 w-full text-center">
            Order Line Event History
          </TextHeader>
          <OrderLineEventHistoryTable orderline={orderline} />
        </div>
      }
    >
      <div
        className="OrderLineEventHistory__history-icon Button desktop:ml-auto w-auto cursor-pointer"
        ref={historyIconRef}
        aria-label="View Order Line History"
        data-tooltip="right"
      >
        <Fa
          className="text-primary-400"
          icon="history"
          size={1}
          onClick={() => setShowHistory(true)}
        />
      </div>
    </Popover>
  )
}
