/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1FileType } from '../models/Creativeservicev1FileType';
import { V1FileSubType } from '../models/V1FileSubType';
import { HttpFile } from '../http/http';

export class V1CreativeFileMetadata {
    /**
    * The name of the file for the creative
    */
    'filename'?: string;
    'type'?: Creativeservicev1FileType;
    'subtype'?: V1FileSubType;
    /**
    * Optional for most but required field for adtags
    */
    'height'?: number;
    /**
    * Optional for most but required field for adtags
    */
    'width'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Creativeservicev1FileType",
            "format": ""
        },
        {
            "name": "subtype",
            "baseName": "subtype",
            "type": "V1FileSubType",
            "format": ""
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1CreativeFileMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

