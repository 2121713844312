import { TableColumnType } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import {
  formatDate,
  getCreativeFaETIcons,
  getFileType,
  isVideo,
  readableCreativeType,
} from 'Helpers'
import { EmptyField, InfoPopover, OrderLineCount } from 'Components'
import { creativeStatusOptions, creativeTypeOptions } from 'searchOptions'
import {
  CreativeStatusIndicator,
  CreativeStatusPopover,
} from './CreativeStatusPopover'

type CreativeTableColumnsType = TableColumnType<Creativeservicev1Creative>

export const CreativesTableColumns = (): CreativeTableColumnsType[] => [
  {
    path: 'name',
    label: 'Creative Name',
    filterOn: 'string',
    RowCell: (row) => (
      <div className="flex max-w-[22rem] items-center break-all">
        {getCreativeFaETIcons(row.type || undefined)}
        <span className="ml-1">{row.name}</span>
      </div>
    ),
  },
  {
    path: 'type',
    label: 'Creative Type',
    filterOn: creativeTypeOptions,
    RowCell: (row) => {
      if (!row.type) return <EmptyField />
      return readableCreativeType(row.type)
    },
  },
  // Filtering by mime type of the child files would require a special kind of filtering
  {
    path: 'mime',
    label: 'File Type',
    // filterOn: creativeFileTypeOptions,
    removeSort: true,
    removeFilter: true,
    RowCell: (row) => {
      const fileType = getFileType(row)
      if (!fileType) return <EmptyField />
      return <span>{fileType}</span>
    },
  },
  {
    path: '',
    label: 'Specs',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      if (row.type === 'CREATIVE_TYPE_AD_TAG') {
        return <span className="text-s">{`${row.width}x${row.height}`}</span>
      }
      if (
        row.type === 'CREATIVE_TYPE_NATIVE_BANNER' ||
        row.type === 'CREATIVE_TYPE_VAST_TAG'
      )
        return '1x1'
      if (row.files) {
        const [file] = row.files
        if (file) {
          if (file.mime && isVideo(file.mime)) {
            return <div className="text-s">{file.duration} seconds</div>
          }
          if (file.width !== undefined && file.height !== undefined)
            return (
              <span className="text-s">{`${file.width}x${file.height}`}</span>
            )
        }
      }
      return <EmptyField />
    },
  },
  {
    path: 'orderLines',
    label: 'Order Lines',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      return <OrderLineCount item={row} />
    },
  },
  {
    path: 'create_time',
    label: 'Date Created',
    removeFilter: true,
    RowCell: (row) => {
      if (!row.createTime) return <EmptyField />
      return formatDate(row.createTime)
    },
  },
  {
    path: 'status',
    label: (
      <div className="CreativesTableCol__status flex items-center">
        Status
        <InfoPopover content={<CreativeStatusPopover />} />
      </div>
    ),
    filterOn: creativeStatusOptions,
    RowCell: (row) => {
      if (!row.status) return <EmptyField />
      return <CreativeStatusIndicator status={row.status} />
    },
  },
  {
    path: 'id',
    label: 'Creative ID',
    filterOn: 'string',
    RowCell: (row) => {
      if (!row.id) return <EmptyField />
      return (
        <span className="CreativesTableCol__creative-id text-s overflow-visible py-1 leading-none">
          {row.id}
        </span>
      )
    },
  },
]
