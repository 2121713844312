/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetorchestratorv2Checks } from '../models/Targetorchestratorv2Checks';
import { Targetorchestratorv2HealthCondition } from '../models/Targetorchestratorv2HealthCondition';
import { HttpFile } from '../http/http';

export class Targetorchestratorv2HealthCheck {
    'serviceName'?: string;
    'healthCondition'?: Targetorchestratorv2HealthCondition;
    'commitHash'?: string;
    'serviceVersion'?: string;
    'checks'?: Targetorchestratorv2Checks;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "serviceName",
            "baseName": "serviceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "healthCondition",
            "baseName": "healthCondition",
            "type": "Targetorchestratorv2HealthCondition",
            "format": ""
        },
        {
            "name": "commitHash",
            "baseName": "commitHash",
            "type": "string",
            "format": ""
        },
        {
            "name": "serviceVersion",
            "baseName": "serviceVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "checks",
            "baseName": "checks",
            "type": "Targetorchestratorv2Checks",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Targetorchestratorv2HealthCheck.attributeTypeMap;
    }

    public constructor() {
    }
}

