/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1MigrationEstimate {
    'creatives'?: number;
    'targets'?: number;
    'childOrgs'?: number;
    'users'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "targets",
            "baseName": "targets",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "childOrgs",
            "baseName": "child_orgs",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "users",
            "baseName": "users",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1MigrationEstimate.attributeTypeMap;
    }

    public constructor() {
    }
}

