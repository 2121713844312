import React, { useEffect, useState } from 'react'
import { Modal, PreviewType, TextHeader } from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { CPMObjectType } from 'types'
import { VerticalStepper, VerticalStepperConfig } from 'Components'
import { Step1ChildOrgDetails } from './Step1ChildOrgDetails'
import { useContactState } from './useContactState'
import { Step2ChildOrgCPM } from './Step2ChildOrgCPM'

export const AddChildOrgModal = ({
  onClose,
  onSubmit,
  parentOrg,
}: {
  onClose: () => void
  onSubmit: (childOrg: Orgmanagerv1Org, logo?: PreviewType) => void
  parentOrg: Orgmanagerv1Org
}) => {
  const handleClickStep = (id: string) => {
    setCurrentStep(id)
  }
  const baseSteps: VerticalStepperConfig = [
    {
      id: 'step1',
      onClick: handleClickStep,
    },
    {
      id: 'step2',
      onClick: handleClickStep,
      disabled: true,
    },
  ]
  const [currentStep, setCurrentStep] = useState<string>('step1')
  const [steps, setSteps] = useState<VerticalStepperConfig>(baseSteps)
  // keep track of the child org we are going to submit to api
  const [childOrg, setChildOrg] = useState<Orgmanagerv1Org>({})
  // For Step 1
  const [orgName, setOrgName] = useState('')
  const [newChildOrgLogo, setNewChildOrgLogo] = useState<PreviewType>()
  const parentOrgOpsContact = parentOrg.contacts?.find(
    (contact) => contact.type === 'operations',
  )
  const parentOrgSalesContact = parentOrg.contacts?.find(
    (contact) => contact.type === 'sales',
  )
  const parentOrgBillingContact = parentOrg.contacts?.find(
    (contact) => contact.type === 'billing',
  )
  const opsState = useContactState(parentOrgOpsContact)
  const salesState = useContactState(parentOrgSalesContact)
  const billingState = useContactState(parentOrgBillingContact)
  // For Step 2
  const [cpmChoice, setCpmChoice] = useState('')
  const [cpmIncrease, setCpmIncrease] = useState(25)
  const [editingCpmIncrease, setEditingCpmIncrease] = useState(false)
  const [inputCpms, setInputCpms] = useState<CPMObjectType | undefined>(
    parentOrg.cpms ? (parentOrg.cpms as CPMObjectType) : undefined,
  )

  useEffect(() => {
    const newSteps = baseSteps.map((step) => {
      if (step.id === 'step1') {
        return {
          ...step,
          completed: !!childOrg?.contacts?.length,
        }
      }
      if (step.id === 'step2') {
        return {
          ...step,
          disabled: !childOrg?.contacts?.length,
        }
      }
      return step
    })
    setSteps(newSteps)
  }, [currentStep])

  const step1Props = {
    parentOrg,
    orgName,
    setOrgName,
    opsState,
    salesState,
    billingState,
    newChildOrgLogo,
    setNewChildOrgLogo,
  }

  const step2Props = {
    parentOrg,
    cpmChoice,
    setCpmChoice,
    cpmIncrease,
    setCpmIncrease,
    editingCpmIncrease,
    setEditingCpmIncrease,
    inputCpms,
    setInputCpms,
  }

  return (
    <Modal
      header={
        <div>
          <TextHeader on="white" type={3}>
            Add Child Org
          </TextHeader>
          <p className="text-s font-light">
            Add a new org as a child org of {parentOrg.name}
          </p>
        </div>
      }
      offClick={onClose}
      className="AddChildOrgModal min-w-[50%] p-6"
    >
      <div className="AddChildOrgModal__layout flex gap-4">
        <VerticalStepper steps={steps} />
        {currentStep === 'step1' ? (
          <Step1ChildOrgDetails
            onSave={(org) => {
              setChildOrg((prev) => ({ ...prev, ...org }))
              setCurrentStep('step2')
            }}
            {...step1Props}
          />
        ) : null}
        {currentStep === 'step2' ? (
          <Step2ChildOrgCPM
            onSave={(changes) => {
              onSubmit({ ...childOrg, cpms: changes }, newChildOrgLogo)
            }}
            onBack={() => setCurrentStep('step1')}
            {...step2Props}
          />
        ) : null}
      </div>
    </Modal>
  )
}
