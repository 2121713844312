import './PieChart.scss'

type PProps = {
  data: Array<{
    title: string
    value: number
    color: string
  }>
  isMoney?: boolean
}

function comma(num: number) {
  const str = num.toString().split('.')
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return str.join('.')
}

export const PieChart = ({ data, isMoney }: PProps) => {
  const val1 = data[0].value
  const val2 = data[1].value
  const spent = (val1 / (val1 + val2)) * 100
  // const remain = (val2 / (val1 + val2)) * 100
  //  make sure the total is always 100 and no negative remainder
  const remain = 100 - spent >= 0 ? 100 - spent : 0

  const diameter = 20
  const viewBox = `0 0 ${diameter * 1.3} ${diameter}`
  const radius = diameter / 2
  const remainColor = data[1].color
  const spentColor = data[0].color
  // spent needs to be between 0 and 100
  const spentSlice = (spent * (Math.PI * 10)) / 100 // calc angle rotation

  const getPositionalData = (
    xRemain: number,
    positionType: 'xAxis' | 'baseline' | 'yAxis',
    type: 'remaining' | 'used',
  ) => {
    // adjust served-text's baseline middle/auto (baseline)/hanging (top of ascenders)
    if (type === 'used') {
      if (positionType === 'baseline') {
        if (xRemain > 60 && xRemain < 100) {
          return 'auto' //SVG attr 'dominant-baseline',  does't seem to include 'right' or 'left', only middle, auto and hanging
        }
        if (xRemain >= 40 && xRemain <= 60) {
          return 'middle'
        }
        if (xRemain >= 0 && xRemain < 40) {
          return 'hanging'
        }
      }
      // move served-text left or right
      if (positionType === 'xAxis') {
        if (xRemain >= 90 && xRemain <= 100) {
          //  move text to left so large num wouldn't be cropped
          return radius
        }
        if (xRemain >= 80 && xRemain < 90) {
          return radius + 1
        }
        if (xRemain > 60 && xRemain < 80) {
          // console.log('here', radius)
          return radius + 0.8
        }
        if (xRemain > 50 && xRemain <= 60) {
          return radius * 1.1
        }
        if (xRemain >= 40 && xRemain <= 50) {
          return radius
        }
        if (xRemain > 0 && xRemain < 40) {
          return radius
        }
        return radius
      }
      // move served-text up or down
      if (positionType === 'yAxis') {
        if (xRemain >= 90 && xRemain <= 100) {
          return radius * 0.5
        }
        if (xRemain >= 80 && xRemain < 90) {
          return radius * 0.7
        }
        if (xRemain > 60 && xRemain < 80) {
          // console.log('here', radius)
          return radius * 0.95
        }
        if (xRemain >= 50 && xRemain <= 60) {
          return radius * 1.05
        }
        if (xRemain >= 40 && xRemain < 50) {
          return radius * 1.1
        }
        if (xRemain > 0 && xRemain < 40) {
          return radius * 1.1
        }
        return radius
      }
    }
    // move remaining-text's baseline up or down
    if (type === 'remaining') {
      if (positionType === 'baseline') {
        return 'hanging'
      }
      // move remaining-text left or right
      if (positionType === 'xAxis') {
        if (xRemain > 0 && xRemain <= 10) {
          return radius * 0.5
        }
        if (xRemain > 10 && xRemain <= 20) {
          return radius * 0.6
        }
        if (xRemain > 20 && xRemain <= 40) {
          return radius * 0.65
        }
        if (xRemain > 40 && xRemain < 50) {
          return radius * 0.7
        }
        if (xRemain >= 50 && xRemain < 60) {
          return radius * 0.75
        }
        if (xRemain >= 60) {
          return radius * 0.95
        }
        if (xRemain <= 0) {
          return radius * 0.4
        }
        return radius
      }
      // move remaining-text up or down
      if (positionType === 'yAxis') {
        if (xRemain > 0 && xRemain <= 10) {
          return radius * 0.3
        }
        if (xRemain > 10 && xRemain <= 20) {
          return radius * 0.8
        }
        if (xRemain > 20 && xRemain <= 40) {
          return radius * 0.9
        }
        if (xRemain > 40 && xRemain < 50) {
          return radius * 1.2
        }
        if (xRemain >= 50 && xRemain < 60) {
          return radius * 1.3
        }
        if (xRemain >= 60) {
          return radius * 1.2
        }
        return radius
      }
    }
  }

  const valueFormatted = isMoney
    ? `$${comma(data[0].value)}`
    : comma(data[0].value)

  const valueFormattedRemaining = isMoney
    ? `$${comma(data[1].value)}`
    : comma(data[1].value)

  return (
    <div className="PieChart">
      <svg
        className="PieChartSVG"
        xmlns="http://www.w3.org/2000/svg"
        width={diameter * 4}
        height={diameter * 4}
        viewBox={viewBox}
        overflow="visible"
      >
        {/* remaining/whole ring stays 100% */}
        <circle
          className="PieChartSVG__circle remained"
          r={radius / 2}
          cx={radius}
          cy={radius}
          fill="transparent"
          strokeWidth={radius}
          stroke={remainColor}
        />
        {/* spent/serve pie slice */}
        <circle
          className="PieChartSVG__circle served"
          r={radius / 2}
          cx={radius}
          cy={radius}
          fill="transparent"
          stroke={spentColor}
          strokeWidth={radius}
          strokeDasharray={`${spentSlice} ${Math.PI * 10}`}
          transform="rotate(-90) translate(-20)"
        />
        {/* Served slice label */}
        {remain <= 90 && (
          <text
            className="PieChartSVG__text served"
            fontSize="2"
            x={getPositionalData(remain, 'xAxis', 'used')}
            y={getPositionalData(remain, 'yAxis', 'used')}
            dominantBaseline={getPositionalData(remain, 'baseline', 'used')}
            textAnchor={remain >= 0 ? 'start' : 'end'}
          >
            {valueFormatted}
          </text>
        )}
        {/* Remaining slice label */}
        {remain >= 10 && (
          <text
            className="PieChartSVG__text remaining"
            fontSize="2"
            x={getPositionalData(remain, 'xAxis', 'remaining')}
            y={getPositionalData(remain, 'yAxis', 'remaining')}
            dominantBaseline={remain > 50 ? 'hanging' : 'auto'}
            textAnchor={remain >= 0 ? 'middle' : 'start'}
          >
            {valueFormattedRemaining}
          </text>
        )}
      </svg>
    </div>
  )
}
