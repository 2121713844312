import { ReactNode, ChangeEvent, FunctionComponent } from 'react'
import './Checkbox.scss'
import classNames from 'classnames'

export const Checkbox: FunctionComponent<{
  className?: string
  classNameWrap?: string
  classNameWrapperLabel?: string
  checked: boolean
  onChange: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void
  label?: ReactNode
  labelSub?: ReactNode
  id?: string
  size?: 's' | 'm' | 'l' | 'xl'
  disabled?: boolean
}> = ({
  className = 'Checkbox__base',
  classNameWrap = '',
  classNameWrapperLabel = '',
  checked,
  onChange,
  label,
  labelSub,
  id,
  size = 'm',
  disabled = false,
  ...props
}) => {
  const sizeClass = ` Checkbox--${size}-size`
  const modifierClasses = classNames(' ', sizeClass, {
    'Checkbox--checked': checked,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.checked, event)
    }
  }
  return (
    <div className={`Checkbox${modifierClasses} ${className} w-auto`}>
      <label
        htmlFor={id}
        className={classNames(
          'Checkbox__wrapper-label',
          classNameWrapperLabel,
          {
            'hover:cursor-not-allowed': disabled,
            'hover:cursor-pointer': !disabled,
          },
        )}
      >
        <div
          className={`Checkbox__input-wrapper w-5 h-5 ${classNameWrap}`}
          {...props}
        >
          <input
            className="Checkbox__input"
            type="checkbox"
            onChange={handleChange}
            id={id}
            checked={checked}
            disabled={disabled}
          />
          <div className="Checkbox__checkmark" />
        </div>
        {label && (
          <span className={`Checkbox__label ${className}`}>
            <span className="LabelMain block">{label}</span>
            {labelSub && (
              <span className="LabelSub text-sm leading-none block">
                {labelSub}
              </span>
            )}
          </span>
        )}
      </label>
    </div>
  )
}
