import './SuggestionsList.scss'

type SuggestionListType = {
  filteredSuggestions: string[]
  activeSuggestionIndex: number
  onClick: (e: React.MouseEvent) => void
  isKeyNavigating: boolean
}

export const SuggestionsList: React.FC<SuggestionListType> = ({
  filteredSuggestions,
  activeSuggestionIndex,
  onClick,
  isKeyNavigating,
}) => {
  return filteredSuggestions.length ? (
    <ul className="SuggestionsList">
      {filteredSuggestions.map((suggestion, index) => {
        const showActive = isKeyNavigating && index === activeSuggestionIndex
        return (
          <li
            className={`SuggestionList__suggestion ${
              showActive ? 'SuggestionList__suggestion-active' : ''
            }`}
            key={suggestion}
          >
            <button
              className="w-full flex-col justify-start"
              type="button"
              onClick={(e) => onClick(e)}
            >
              {suggestion}
            </button>
          </li>
        )
      })}
    </ul>
  ) : (
    <div className="SuggestionList__no-suggestions">
      <em>No suggestions</em>
    </div>
  )
}
