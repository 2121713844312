import React, { useState } from 'react'
import { Creativeservicev1Creative, Creativeservicev1Type } from 'next-gen-sdk'
import { PreviewFileWithId } from 'Pages'
import { Card, CurrentStepType, GoBackButton } from 'Components'
import { uploadCreative } from 'Requests'
import { useAppContext } from 'Contexts'
import { CreativeUploader, CreativeUploaderStatus } from './components'

export type MessageType = {
  code?: number
  details?: string
  message?: string
}
export type ErroredWithReason = {
  file: PreviewFileWithId
  reason?: {
    message?: MessageType
  }
}
export const CreativeUpload: React.FC<{
  currentOrg: string
  onUpload?: (creative: Creativeservicev1Creative) => void
  uploadType?: Creativeservicev1Type
  onClose?: () => void
  isPoliticalInCampaign?: boolean
}> = ({ currentOrg, onUpload, uploadType, onClose, isPoliticalInCampaign }) => {
  const [uploadFiles, setFiles] = useState<PreviewFileWithId[]>([])
  const [completed, setCompleted] = useState<PreviewFileWithId[]>([])
  const [errored, setErrored] = useState<ErroredWithReason[]>([])
  const [progress, setProgress] = useState<
    { id: string; uploadProgress: number; currentStep?: CurrentStepType }[]
  >([])
  const { creativeServiceApi, rebuild } = useAppContext()
  const updateProgress = (id: string, prog: number, step?: CurrentStepType) => {
    const updatedProgress = {
      id,
      uploadProgress: prog,
      currentStep: step || ('upload' as CurrentStepType),
    }
    setProgress((prev) => {
      const filtered = prev.filter((p) => p.id !== id)
      return [...filtered, updatedProgress]
    })
  }
  const handleUpload = async (fileandId: PreviewFileWithId, token: string) => {
    updateProgress(fileandId.id, 0) // Initiate progress
    if (!token) return
    if (rebuild) return
    if (!currentOrg) return

    const creative = await uploadCreative(
      fileandId.fileData.name,
      fileandId.fileData,
      token,
      currentOrg,
      (prog) => {
        updateProgress(fileandId.id, prog)
      },
      uploadType === 'CREATIVE_TYPE_HTML5'
        ? 'CREATIVE_FILE_TYPE_ZIP'
        : 'CREATIVE_FILE_TYPE_MEDIA',
    ).catch((err) => {
      setErrored((prev) => [
        ...prev,
        { file: fileandId, reason: JSON.parse(err.message) },
      ])
    })
    if (!creative) return
    updateProgress(fileandId.id, 100, 'uploaded')
    if (!creative.id || !creative.mime || !currentOrg) return
    creativeServiceApi
      ?.advertisingPlatformServiceCreateCreative(currentOrg, {
        files: [creative],
        name: creative.name,
        type: uploadType,
      })
      .then((res) => {
        updateProgress(fileandId.id, 100, 'created')
        setCompleted((prev) => [...prev, fileandId])
        if (onUpload) onUpload(res)
      })
      .catch(() => setErrored((prev) => [...prev, { file: fileandId }]))
  }
  const uploaderProps = { uploadType, setFiles, handleUpload }
  const statusProps = { uploadFiles, setFiles, completed, progress, errored }
  return (
    <Card className="CreativeUpload UploaderCard laptopsmall:gap-0 flex !px-0 !pt-0">
      {onClose && (
        <GoBackButton
          classWrap={`CreativeUpload--goback ${uploadType}`}
          onClick={onClose}
          position="top-right"
        />
      )}
      <CreativeUploader
        {...uploaderProps}
        isInPoliticalCampaignCreation={isPoliticalInCampaign}
      />
      <CreativeUploaderStatus {...statusProps} />
    </Card>
  )
}
