import { ApiFactory } from 'apiFactory'
import dayjs from 'dayjs'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'

const createEmptyPromise = (): Promise<undefined> => {
  return new Promise((resolve) => {
    return resolve(undefined)
  })
}

export const getOrderLineStats = async (
  orderLine: Campaignservicev1OrderLine,
  orgId: string,
  timeFrame?: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const { statsServiceAPI } = ApiFactory(token)

  if (!orgId || !orderLine.id) {
    return undefined
  }
  try {
    return statsServiceAPI?.advertisingPlatformServiceGetStats([orgId], {
      startTime: dayjs(orderLine.startTime)
        .startOf('day')
        .utc(true)
        .toISOString(),
      endTime: dayjs(orderLine.endTime)
        .add(1, 'day')
        .startOf('day')
        .toISOString(),
      timezone: 'EST',
      timeFrame: timeFrame ? timeFrame : 'total',
      levelOfDetail: ['order_line_id'],
      orderLineId: [orderLine.id],
    })
  } catch (error) {
    console.log(`Error Fetching Stats for ${orderLine.id}`)
    return createEmptyPromise()
  }
}
