import { RecoilRect } from 'Components/Canvas/RecoilRect'
import { RecoilText } from 'Components/Canvas/RecoilText'
import Konva from 'konva'
import React, { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  blockFontSizeAtom,
  blockTextValueAtom,
  mainDimensionsBlockAtom,
} from 'State'
import { BlockConfigType } from 'Typings'

export const ActionButton: React.FC<{
  id: string
  width: number
  height: number
  isOnMobile?: boolean
}> = ({ id, width, height, isOnMobile = false }) => {
  const [mainDimensions, setMainDimensions] = useRecoilState(
    mainDimensionsBlockAtom(id),
  )
  const textRef = useRef<Konva.Text>(null)
  const shapeConfig: BlockConfigType = {
    id,
    kind: 'actionButton',
    key: 'button',
  }

  const textConfig: BlockConfigType = {
    id,
    kind: 'actionButton',
    key: 'label',
  }

  const fontSize = useRecoilValue(blockFontSizeAtom(textConfig))
  const textValue = useRecoilValue(blockTextValueAtom(textConfig))

  useEffect(() => {
    if (!(textRef.current && isOnMobile)) return
    // else if not on mobile...
    const textWidth = textRef.current.getTextWidth()
    const { textHeight } = textRef.current

    setMainDimensions({
      width: textWidth + 50,
      height: textHeight + 20,
    })
  }, [fontSize, textValue, textRef.current?.getTextWidth()])

  const buttonTextY =
    (isOnMobile ? mainDimensions.height : height) / 2 -
    (textRef.current?.textHeight || 0) / 2

  const finalWidth = () => {
    if (!isOnMobile) return width
    if (mainDimensions.width >= width) return width
    return mainDimensions.width
  }
  return (
    <>
      <RecoilRect
        config={shapeConfig}
        width={finalWidth()}
        height={isOnMobile ? mainDimensions.height : height}
      />
      <RecoilText
        ref={textRef}
        config={textConfig}
        y={buttonTextY}
        width={finalWidth()}
        isOnMobile={isOnMobile}
      />
    </>
  )
}
