import { Fa, Text } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { WarningMsg } from 'Components'
import {
  isBannerAndMissingATop5Dimension,
  isOTTAndNotRecommendedDimension,
  isOTTAndNotRecommendedBitrate,
  isOTTAndNotRecommendedDuration,
} from 'Helpers'

export const CreativeDimensions = ({
  creative,
}: {
  creative: Creativeservicev1Creative
}) => {
  const getOTTChecklistIcon = (bool: boolean) => (
    <span className="fa-li">
      <Fa
        icon={bool ? 'circle-xmark' : 'check-circle'}
        className={bool ? 'text-warning' : 'text-success'}
        size={1}
      />
    </span>
  )

  const sharedProps = { className: 'mt-2', popoverText: 'View Specs' }

  return (
    <>
      {creative.type === 'CREATIVE_TYPE_BANNER' &&
        creative.files?.[0]?.size &&
        +creative.files?.[0]?.size > 100000 && (
          <WarningMsg
            {...sharedProps}
            popoverContent={
              <Text>
                <strong>Recommended banner file size: </strong> less than 100kb
              </Text>
            }
          >
            Your creative file size is above our recommended specifications.
            This <strong>may</strong> limit our serving capacity.
          </WarningMsg>
        )}
      {(creative.type === 'CREATIVE_TYPE_BANNER' ||
        creative.type === 'CREATIVE_TYPE_AD_TAG' ||
        creative.type === 'CREATIVE_TYPE_HTML5') &&
        isBannerAndMissingATop5Dimension(creative) && (
          <WarningMsg
            {...sharedProps}
            popoverContent={
              <Text>
                <strong>Top 5 recommended banner sizes: </strong>
                <ul className="list-inside list-disc">
                  <li>300x250</li>
                  <li>728x90</li>
                  <li>160x600</li>
                  <li>320x50</li>
                  <li>300x600</li>
                </ul>
              </Text>
            }
          >
            Your creative is not in the top 5 recommended sizes. This{' '}
            <strong>may</strong> limit our serving capacity.
          </WarningMsg>
        )}
      {/* CREATIVE OTT WARNINGS */}
      {(isOTTAndNotRecommendedDimension(creative) ||
        isOTTAndNotRecommendedBitrate(creative) ||
        isOTTAndNotRecommendedDuration(creative)) && (
        <WarningMsg
          {...sharedProps}
          popoverContent={
            <>
              <Text className="pb-2" tag="p">
                <strong>OTT Recommendations: </strong>
              </Text>
              <ul className="list-inside list-disc">
                <li className="pb-2">
                  <strong>Dimensions</strong>: 1280x720 (720p)
                </li>
                <li className="pb-2">
                  <strong>Bitrate</strong>: Greater than 14,000kbps
                </li>
                <li className="pb-2">
                  <strong>Duration</strong>: 15 or 30 seconds
                </li>
              </ul>

              <Text className="pb-2" tag="p">
                <strong>This creative: </strong>
              </Text>
              <ul className="fa-ul">
                <li className="pb-2">
                  {getOTTChecklistIcon(
                    isOTTAndNotRecommendedDimension(creative),
                  )}
                  <strong>Dimensions</strong>:
                  {creative.files?.[0]?.width !== undefined &&
                    creative.files?.[0]?.height !== undefined &&
                    ` ${creative.files[0].width} x ${creative.files[0].height}`}
                </li>
                <li className="pb-2">
                  {getOTTChecklistIcon(isOTTAndNotRecommendedBitrate(creative))}
                  <strong>Bitrate</strong>:
                  {creative.files?.[0]?.size !== undefined &&
                    creative.files?.[0]?.duration !== undefined &&
                    ` ${(
                      ((+creative.files?.[0]?.size /
                        +creative.files?.[0]?.duration) *
                        8) /
                      1000
                    ).toFixed(2)} kbps`}
                </li>
                <li className="pb-2">
                  {getOTTChecklistIcon(
                    isOTTAndNotRecommendedDuration(creative),
                  )}
                  <strong>Duration</strong>:
                  {creative.files?.[0].duration &&
                    ` ${creative.files?.[0].duration} seconds`}
                </li>
              </ul>
            </>
          }
        >
          Your creative(s) do not meet our recommended specifications for
          optimal delivery. This <strong>may</strong> limit our serving
          capacity.
        </WarningMsg>
      )}
    </>
  )
}
