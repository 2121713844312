/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1ClientRoles } from '../models/V1ClientRoles';
import { V1CredentialRepresentation } from '../models/V1CredentialRepresentation';
import { V1ListOfString } from '../models/V1ListOfString';
import { HttpFile } from '../http/http';

/**
* Contains fields and values associated with a User
*/
export class Hagridv1User {
    /**
    * ID of a User
    */
    'id'?: string;
    /**
    * Time and date the user was created
    */
    'createTime'?: Date;
    /**
    * Username associated with the User
    */
    'username'?: string;
    /**
    * The boolean for whether or not the user is enabled
    */
    'enabled'?: boolean;
    /**
    * Whether or not there is a Time-based One-Time Password
    */
    'totp'?: boolean;
    /**
    * Indicates if the User's email has been verified
    */
    'emailVerified'?: boolean;
    /**
    * First name of the User
    */
    'firstName'?: string;
    /**
    * Last Name of the User
    */
    'lastName'?: string;
    /**
    * An email belonging to the User
    */
    'email'?: string;
    /**
    * List of attributes specific to the user
    */
    'attributes'?: { [key: string]: V1ListOfString; };
    /**
    * List of required actions to be addressed before the user logs in
    */
    'requiredActions'?: Array<string>;
    /**
    * Map of string fields with the corresponding client role (namespace dedicated to a clients)
    */
    'clientRoles'?: { [key: string]: V1ClientRoles; };
    /**
    * List of roles in global namespace shared by all clients
    */
    'realmRoles'?: Array<string>;
    /**
    * ID of the Service account that the user is associated with
    */
    'serviceAccountClientId'?: string;
    /**
    * List of groups that the User belongs to
    */
    'groups'?: Array<string>;
    /**
    * List of value collections for verifying the user 
    */
    'credentials'?: Array<V1CredentialRepresentation>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "username",
            "baseName": "username",
            "type": "string",
            "format": ""
        },
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "totp",
            "baseName": "totp",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "emailVerified",
            "baseName": "email_verified",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "first_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "last_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "attributes",
            "baseName": "attributes",
            "type": "{ [key: string]: V1ListOfString; }",
            "format": ""
        },
        {
            "name": "requiredActions",
            "baseName": "required_actions",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "clientRoles",
            "baseName": "client_roles",
            "type": "{ [key: string]: V1ClientRoles; }",
            "format": ""
        },
        {
            "name": "realmRoles",
            "baseName": "realm_roles",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "serviceAccountClientId",
            "baseName": "service_account_client_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "groups",
            "baseName": "groups",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "credentials",
            "baseName": "credentials",
            "type": "Array<V1CredentialRepresentation>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Hagridv1User.attributeTypeMap;
    }

    public constructor() {
    }
}

