import React from 'react'
import { CollapsibleContainer, Text } from '@eltoro-ui/components'
import { CollapseButton } from '../CollapseButton'
import './IconButtons.scss'

export const IconButtons: React.FC<{
  className?: string
  classNameHeader?: string
  title?: string | React.ReactNode
  subTitle?: string | React.ReactNode
  tip?: string | React.ReactNode
  filterChildren?: React.ReactNode
  iconChildren: React.ReactNode
  isCollapsed?: boolean
  showChevron?: boolean
  onCollapseClick?: () => void
}> = ({
  className = '',
  classNameHeader = '',
  title,
  subTitle,
  tip,
  filterChildren,
  iconChildren,
  isCollapsed,
  showChevron = false,
  onCollapseClick,
}) => {
  return (
    <div className={`IconButtons ${className}`}>
      {(title || subTitle) && (
        <div className="IconButtons__header-container relative flex">
          {title && (
            <div
              className={`IconButtons__header pointer-events-none relative z-20 w-auto ${classNameHeader}`}
            >
              <Text className="IconButtons__title" tag="h4">
                {title}
              </Text>
              {subTitle && (
                <>
                  <Text className="IconButtons__subtitle leading-none">
                    {subTitle}
                  </Text>
                </>
              )}
            </div>
          )}

          {onCollapseClick && (
            <CollapseButton
              isCollapsed={isCollapsed || false}
              showChevron={!showChevron}
              handleClick={onCollapseClick}
            />
          )}
        </div>
      )}
      <CollapsibleContainer
        className={`CollapsibleContainer ${
          !isCollapsed ? 'relative z-10' : 'isCollapsed'
        }`}
        isOpen={!isCollapsed}
      >
        {/* {filterChildren && (
          <div className="IconButtons__filters mb-4 flex h-full items-center gap-4">
            {filterChildren}
          </div>
        )} */}
        {tip && (
          <p className="IconButtons__tip text-grey-400 mx-2 flex text-sm">
            {tip}
          </p>
        )}
        <span className="IconButtons__list laptop:flex desktop:auto-grid laptop:gap-2 laptop:flex-wrap relative z-10 w-full">
          {iconChildren}
        </span>
      </CollapsibleContainer>
    </div>
  )
}
