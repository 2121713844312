import React from 'react'
import {
  LoadingBar,
  Text,
  Button,
  Fa,
  PreviewType,
} from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import { typeGetter } from 'Pages'
import './UploadStatusBar.scss'
import { MessageType } from 'Components/CreativeUpload'

export type CurrentStepType =
  | 'upload'
  | 'configure'
  | 'job'
  | 'queue'
  | 'queue_success'
  // Creatives
  | 'uploaded'
  | 'creating'
  | 'created'

export const UploadStatusBar = ({
  file,
  id,
  complete,
  errored,
  onRemove,
  currentStep,
  uploadPercentage,
  className,
  reason,
}: {
  file: PreviewType
  complete: boolean
  errored: boolean
  id?: string
  onRemove?: (removedFileId: string) => void
  currentStep?: CurrentStepType
  uploadPercentage?: number
  className?: string
  reason?: MessageType
}) => {
  const getPercentageText = () => {
    if (errored) return 'Error'
    if (complete) return 'Finished'
    if (uploadPercentage && uploadPercentage < 100)
      return `Uploading ${uploadPercentage}%`
    if (currentStep) {
      if (currentStep === 'upload') return 'Uploading'
      if (currentStep === 'configure') return 'Configuring'
      if (currentStep === 'job') return 'Adding job'
      if (currentStep === 'queue') return 'Queuing'
      return 'Finished'
    }
    return 'In progress'
  }

  const getIcon = () => {
    if (errored) return <Fa icon="exclamation-circle" size={1} />
    if (complete) return <Fa icon="check-circle" size={1} />
    return <Fa icon="file-alt" size={1} />
  }

  const getColor = () => {
    if (errored) return 'text-danger-500'
    if (complete) return 'text-success'
    return 'text-gray'
  }

  const H5Link = () => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://support.eltoro.com/hc/en-us/articles/4770007799195-HTML5s"
      >
        Click here for more information
      </a>
    )
  }

  const getLoadingBarProps = (): {
    percentage?: number
    colorKind: 'danger' | 'success' | 'grey'
  } => {
    if (errored) return { percentage: 0, colorKind: 'danger' }
    if (complete) return { percentage: 100, colorKind: 'success' }
    return { percentage: uploadPercentage, colorKind: 'grey' }
  }

  const getReason = (x: MessageType) => {
    if (
      x.details ===
      'rpc error: code = InvalidArgument desc = failed to find height and width'
    ) {
      return `${x.message}. Could not find the height and width of the creative.`
    }
    if (x.message === 'Invalid or malformed token') {
      return 'Your token has expired, please refresh your browser and try to upload the creative again'
    }
    return (
      <>
        {`Invalid file type. HTML5 banner must be a ".zip" file with a valid index.html file. If you think this is an error, please contact us, email support@eltoro.com.  `}
        <H5Link />
      </>
    )
  }
  return (
    <div className={`UploadStatusBar ${className || ''}`}>
      <div className="UploadStatusBar__status-indicator flex flex-col items-center gap-1">
        <Text className={getColor()} size="2xl">
          {getIcon()}
        </Text>
        <Text
          weight="light"
          className={`UploadStatusBar__percentage-text ${
            errored ? 'text-danger' : ''
          }`}
        >
          {getPercentageText()}
        </Text>
      </div>
      <div className="UploadStatusBar__main flex flex-grow flex-col gap-2">
        <div className="UploadStatusBar__file-info flex w-full flex-1 justify-between gap-2">
          <Text className="font-bold">{file.name}</Text>
          <Text size="s">{formatByteString(file.size)}</Text>
        </div>
        <LoadingBar {...getLoadingBarProps()} />
        {errored && reason && (
          <Text className="UploadStatusBar__ErrorMessage pb-1">
            {file.type === 'application/zip' ? (
              <>
                {`Your HTML5 zip file requires changes.  `}
                <H5Link />
              </>
            ) : (
              getReason(reason)
            )}
          </Text>
        )}
      </div>

      {onRemove && id && (
        <Button
          kind="text"
          onClick={() => onRemove(id)}
          iconOnly={<Fa icon="times" size={1} />}
          className="m-2"
        />
      )}
    </div>
  )
}
