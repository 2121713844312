import { Creativeservicev1Type } from 'next-gen-sdk'
import { PreviewType } from '@eltoro-ui/components'

type CreativeValidatorType =
  | {
      type: 'warning' | 'error'
      msg: string
    }
  | undefined

const loadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })

const loadVideoDimensions = (src: string) =>
  new Promise((resolve) => {
    const video = document.createElement('video')
    video.addEventListener(
      'loadeddata',
      () => {
        const { duration, videoHeight, videoWidth } = video
        const height = videoHeight
        const width = videoWidth

        resolve({ duration, height, width })
      },
      false,
    )
    video.src = src
  })

export const creativeValidator = async (
  file: PreviewType,
  uploadType: Creativeservicev1Type,
): Promise<CreativeValidatorType> => {
  const suggestedDimensions = [
    '300x250',
    '728x90',
    '160x600',
    '320x50',
    '300x600',
  ]

  if (file.size > 100000000) {
    return { type: 'error', msg: 'File size cannot be greater than 100 MB.' }
  }
  if (uploadType === 'CREATIVE_TYPE_BANNER') {
    const acceptedType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif'
    if (!acceptedType) {
      return {
        type: 'error',
        msg: 'Must be a "JPG", "PNG" or "GIF" Image file.',
      }
    }
    if (file.size > 200000) {
      return { type: 'error', msg: 'File size cannot be greater than 200 KB.' }
    }
    // Load image here because height/width from uploader keeps coming back undefined?
    const image = (await loadImage(
      window.URL.createObjectURL(file),
    )) as HTMLImageElement

    if (image.width && image.height) {
      // if both too big + wrong dimensions
      if (
        file.size > 100000 &&
        !suggestedDimensions.find((s) => s === `${file.width}x${file.height}`)
      ) {
        return {
          type: 'warning',
          msg:
            'Your creative file size is above the recommended size of 100 KB and is not in the top 5 recommended sizes. This may limit our serving capacity.',
        }
      }
      // if is not in top 5 creative dimension size
      if (
        !suggestedDimensions.find((s) => s === `${image.width}x${image.height}`)
      ) {
        return {
          type: 'warning',
          msg:
            'Your creative is not in the top 5 recommended sizes. This may limit our serving capacity.',
        }
      }
    }
    // if is bigger than 100000 (recommended)
    if (file.size > 100000) {
      return {
        type: 'warning',
        msg:
          'Your creative file size is above the recommended size of 100 KB. This may limit our serving capacity.',
      }
    }

    return undefined
  }
  if (uploadType === 'CREATIVE_TYPE_HTML5') {
    return undefined
  }
  if (uploadType === 'CREATIVE_TYPE_VIDEO') {
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
    const acceptedType =
      file.type === 'video/mp4' ||
      file.type === 'video/mpg' ||
      file.type === 'video/avi' ||
      file.type === 'video/mov'
    if (!acceptedType || fileExt === 'm4v') {
      return {
        type: 'error',
        msg: 'Must be an "MP4", "MPG", "MOV" or "AVI" Image file.',
      }
    }
    const videoDimensions = (await loadVideoDimensions(
      window.URL.createObjectURL(file),
    )) as { duration: number; width: number; height: number }
    if (videoDimensions.duration && videoDimensions.duration > 60) {
      return {
        type: 'warning',
        msg: 'Duration is greater than the recommended 60 seconds.',
      }
    }
    return undefined
  }
  if (uploadType === 'CREATIVE_TYPE_OTT') {
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
    const videoDimensions = (await loadVideoDimensions(
      window.URL.createObjectURL(file),
    )) as { duration: number; width: number; height: number }

    if (videoDimensions.duration) {
      const kbps = ((file.size / videoDimensions.duration) * 8) / 1000
      if (file.type !== 'video/mp4' || fileExt === 'm4v') {
        return { type: 'error', msg: 'Must be an "MP4" video file.' }
      }
      if (videoDimensions.duration >= 90) {
        return {
          type: 'error',
          msg: 'Duration cannot be greater than 90 seconds.',
        }
      }
      if (
        videoDimensions.duration <= 14 &&
        videoDimensions.duration >= 16 &&
        videoDimensions.duration <= 29 &&
        videoDimensions.duration >= 31
      ) {
        return {
          type: 'warning',
          msg: 'Duration is not the recommended 15 or 30 seconds.',
        }
      }
      if (videoDimensions.width < 1280 && videoDimensions.height < 720) {
        return {
          type: 'error',
          msg: 'Dimensions cannot be smaller than 1280 x 720.',
        }
      }
      if (videoDimensions.width !== 1280 && videoDimensions.height !== 720) {
        return {
          type: 'warning',
          msg: 'Dimensions are not the recommended 1280 x 720 (720p).',
        }
      }
      if (kbps < 2000) {
        return { type: 'error', msg: 'Bit rate cannot be less than 2000 kbps.' }
      }
      if (kbps < 14000) {
        return {
          type: 'warning',
          msg: 'Bit rate is less than the recommended 14,000 kbps.',
        }
      }
    }
    return undefined
  }
  return undefined
}
