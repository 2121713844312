import React, { useCallback, useState } from 'react'
import { useLocalStorage } from '@eltoro-ui/hooks'
import { themeNamesType, themes } from './Theme/Themes'

type ThemeContextType = {
  theme: string
  name: themeNamesType
  logoThumb: string
  logoLarge: string
  handleThemeChange: (theme: themeNamesType) => void
}

const baseState: ThemeContextType = {
  ...themes.default,
  name: 'default',
  handleThemeChange: () => undefined,
}

export const ThemeContext = React.createContext<ThemeContextType>(baseState)

export const ThemeContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [themeName, setThemeName] = useLocalStorage<themeNamesType>(
    baseState.name,
    'theme_name',
  )
  const [theme, setTheme] = useState(themes[themeName as themeNamesType].theme)
  const [logoThumb, setLogoThumb] = useState(
    themes[themeName as themeNamesType].logoThumb,
  )
  const [logoLarge, setLogoLarge] = useState(
    themes[themeName as themeNamesType].logoLarge,
  )

  const handleThemeChange = useCallback(
    (newThemeName: themeNamesType) => {
      setThemeName(newThemeName)
      setTheme(themes[newThemeName].theme)
      setLogoThumb(themes[newThemeName].logoThumb)
      setLogoLarge(themes[newThemeName].logoLarge)
    },
    [setThemeName],
  )

  const contextValues = {
    theme,
    name: themeName,
    logoThumb,
    logoLarge,
    handleThemeChange,
  }

  return (
    <ThemeContext.Provider value={contextValues}>
      {children}
    </ThemeContext.Provider>
  )
}
