import React, { useState } from 'react'
import { Button, Text, Fa, showSuccessMessage } from '@eltoro-ui/components'
import './ColorSwatches.scss'
import { browserLog } from 'Tools'

const range = [
  '050',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
]

const kinds = [
  'color-grey',
  'color-primary',
  'color-secondary',
  'color-tertiary',
  'color-success',
  'color-warning',
  'color-danger',
  'tint-dark',
  'tint-khaki',
  'tint-gray',
  'tint-light',
  'base-info',
] as const

type ColorKinds = typeof kinds[number]

const getScale = (kind: ColorKinds) => {
  const scale = range.map((shade) => {
    return {
      backgroundColor: `var(--${kind}-${shade})`,
      color: `var(--text-on-${kind}-${shade})`,
      scss: `${kind}-${shade}`,
    }
  })
  return scale
}

const Swatch: React.FC<{
  color: string
  backgroundColor: string
  label: string
  main?: boolean
  onClick: (color: string) => void
}> = ({ color, backgroundColor, label, main, onClick }) => {
  return (
    <div
      className={`Swatch ${main ? 'Swatch--main' : ''}`}
      style={{
        backgroundColor,
        color,
      }}
    >
      {label}
      <div className="Swatch__button">
        <Button
          onClick={() => onClick(label)}
          iconOnly={<Fa icon="clipboard" size={1} />}
        />
      </div>
    </div>
  )
}

export const ColorSwatches = () => {
  const [showVars, setShowVars] = useState(false)

  const copyColor = (color: string) => {
    navigator.clipboard
      .writeText(color)
      .then(() => browserLog.log('successfully copied'))
      .then(() => {
        showSuccessMessage('Done!', `${color} copied to clipboard`)
      })
      .catch((err) => browserLog.error('could not copy', err))
  }
  return (
    <div className="ColorSwatches">
      <div className="mb-6 flex w-full items-center justify-between">
        <Text weight="bold">{showVars ? 'CSS vars' : 'SCSS tokens'}</Text>
        <Button onClick={() => setShowVars(!showVars)}>
          {showVars ? 'View SCSS tokens' : 'View CSS vars'}
        </Button>
      </div>

      <div className="ColorSwatches__container">
        {kinds.map((kind, i) => (
          <div key={`${kind}-${i}`} className="ColorSwatches__column">
            {kind === 'color-grey' ? (
              <Swatch
                color="var(--text-on-color-white)"
                backgroundColor="var(--color-white)"
                label={showVars ? 'var(--color-white)' : '$color-white'}
                onClick={copyColor}
                main
              />
            ) : (
              <Swatch
                color={`var(--text-on-${kind})`}
                backgroundColor={`var(--${kind})`}
                label={showVars ? `var(--${kind})` : kind}
                onClick={copyColor}
                main
              />
            )}
            {getScale(kind).map((shade) => (
              <Swatch
                key={shade.color}
                color={shade.color}
                backgroundColor={shade.backgroundColor}
                label={showVars ? shade.backgroundColor : shade.scss}
                onClick={copyColor}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
