/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3Language } from '../models/V3Language';
import { HttpFile } from '../http/http';

export class V3ListXandrLanguagesResponse {
    'languages'?: Array<V3Language>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "languages",
            "baseName": "languages",
            "type": "Array<V3Language>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3ListXandrLanguagesResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

