import React, { useEffect, useState } from 'react'
import { Button, Fa, TextHeader } from '@eltoro-ui/components'
import { useValidatedState } from '@eltoro-ui/hooks'
import { V1RuleSet } from 'next-gen-sdk'
import classNames from 'classnames'
import { VerticalStepperConfig, VerticalStepper } from 'Components'
import { styleTailwind } from 'Helpers'
import {
  Step1Domain,
  Step2BusinessLogic,
  Step3Finalize,
  WebToHomeSuccess,
} from './components'

export const StepperSection = styleTailwind(
  'div',
  'ml-2 mr-4 flex-grow flex flex-col',
)

export type SelectedUseCaseType =
  | 'pixel_entire_site'
  | 'visitors_who_left'
  | 'keyword_targeting'
  | 'visits_to_site'
  | 'advanced_options'

export const WebToHome = () => {
  const handleClickStep = (id: string) => {
    setCurrentStep(id)
  }
  const baseSteps: VerticalStepperConfig = [
    {
      id: 'domain',
      onClick: handleClickStep,
    },
    {
      id: 'logic',
      onClick: handleClickStep,
      disabled: true,
    },
    {
      id: 'finalize',
      onClick: handleClickStep,
      disabled: true,
    },
  ]
  const [currentStep, setCurrentStep] = useState<string>('domain')
  const [steps, setSteps] = useState<VerticalStepperConfig>(baseSteps)
  const [domain, setDomain, isValidDomain] = useValidatedState(
    'https://',
    'url',
  )
  const [selectedUseCase, setSelectedUseCase] = useState<SelectedUseCaseType>()
  const [ruleSets, setRuleSets] = useState<Array<V1RuleSet>>([])
  const [audienceName, setAudienceName] = useState('')
  const [loading, setLoading] = useState(false)

  const nextDisabler = () => {
    if (currentStep === 'domain') return !!domain && !!isValidDomain
    if (currentStep === 'logic') return !!selectedUseCase && !!ruleSets.length
    if (currentStep === 'finalize') return !!audienceName
    return false
  }

  useEffect(() => {
    const newSteps = baseSteps.map((step) => {
      if (step.id === 'domain') {
        return {
          ...step,
          completed: !!domain && isValidDomain,
        }
      }
      if (step.id === 'logic') {
        return {
          ...step,
          completed: !!selectedUseCase && !!ruleSets.length,
          disabled: !domain || !isValidDomain,
        }
      }
      if (step.id === 'finalize') {
        return {
          ...step,
          completed: !!audienceName,
          disabled:
            !domain || !isValidDomain || !selectedUseCase || !ruleSets.length,
        }
      }
      return step
    })
    setSteps(newSteps)
  }, [currentStep])

  const fakeSaveFunc = (): Promise<void> =>
    new Promise((resolve) => {
      setLoading(true)
      setTimeout(() => {
        console.log('saved')
        setLoading(false)
        resolve()
      }, 3000)
    })

  const navButtons = (
    nextStep: 'domain' | 'logic' | 'finalize' | 'success',
    backStep?: 'domain' | 'logic' | 'finalize',
  ) => (
    <div
      className={classNames('WebToHome__progress-buttons mt-6 flex p-4', {
        'justify-between': backStep,
        'justify-end': !backStep,
      })}
    >
      {backStep && (
        <Button size="l" onClick={() => setCurrentStep(backStep)}>
          Back
        </Button>
      )}
      {nextStep === 'success' ? (
        <Button
          size="l"
          // TODO: Name submitted should be `${audienceName}_${domain}` (SEE WIRES)
          onClick={() => fakeSaveFunc().then(() => setCurrentStep(nextStep))}
          disabled={!nextDisabler() || loading}
        >
          {loading ? (
            <div className="flex">
              <Fa icon="circle-notch" size={1} className="animate-spin" />{' '}
              Saving
            </div>
          ) : (
            'Save'
          )}
        </Button>
      ) : (
        <Button
          className="Button--primary"
          size="l"
          onClick={() => setCurrentStep(nextStep)}
          disabled={!nextDisabler()}
        >
          Next
        </Button>
      )}
    </div>
  )

  const fixturePixelCode = `<script type="text/javascript">
  var etPixelId = '';
  var etPixel = document.createElement('img');
  etPixel.src = '//pixel.locker2.com/image/' + etPixelId + '.png?cb=' + Math.round(new Date().getTime());
  etPixel.setAttribute("referrerpolicy", "no-referrer-when-downgrade"); document.addEventListener('DOMContentLoaded',
  function() { document.body.appendChild(etPixel); }, false);
  </script>`

  console.log(ruleSets, 'rule sets to be created')

  // If Pixel Entire Site selected, set automatically with no form visible:
  useEffect(() => {
    if (selectedUseCase === 'pixel_entire_site')
      setRuleSets([
        {
          operator: 'and',
          rules: [
            {
              type: 'regex',
              term: '.*',
            },
          ],
        },
      ])
  }, [selectedUseCase])

  return (
    <div className="WebToHome text-grey-700">
      <header className="WebToHome__header border-grey-100 my-6 border-b pb-4 leading-8">
        <TextHeader type={2} className="WebToHome__header--text">
          Web-To-Home: Retargeting with direct mail
        </TextHeader>
        <p className="text-m">
          Capture site visitor IP addresses via web pixel, match to their
          physical address, and send targeted direct mail pieces
        </p>
      </header>
      {currentStep !== 'success' && (
        <div className="WebToHome__stepper ml-6 flex gap-16">
          <VerticalStepper steps={steps} />
          {currentStep === 'domain' && (
            <Step1Domain
              domain={domain}
              setDomain={setDomain}
              isValidDomain={isValidDomain}
              navButtons={navButtons}
            />
          )}
          {currentStep === 'logic' && (
            <Step2BusinessLogic
              selectedUseCase={selectedUseCase}
              setSelectedUseCase={setSelectedUseCase}
              setRuleSets={setRuleSets}
              navButtons={navButtons}
            />
          )}
          {currentStep === 'finalize' && (
            <Step3Finalize
              audienceName={audienceName}
              setAudienceName={setAudienceName}
              navButtons={navButtons}
              loading={loading}
            />
          )}
        </div>
      )}
      {currentStep === 'success' && (
        <WebToHomeSuccess
          // TODO: put returned pixel copy/paste here (keep in state)
          pixelCode={fixturePixelCode}
          reset={() => {
            setSteps(baseSteps)
            setDomain('https://')
            setAudienceName('')
            setSelectedUseCase(undefined)
            // TODO: reset pixel code returned
            setCurrentStep('domain')
          }}
        />
      )}
    </div>
  )
}
