import { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  Table,
  TextHeader,
  showErrorMessage,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { downloadTos } from 'Requests'
import { useAuth } from 'react-oidc-context'

type TOSTableColumn = {
  user: string
  userId: string
  version: string
  created: string
  fileName: string
}

export const TOSList = () => {
  const [tosList, setTosList] = useState<TOSTableColumn[]>([])
  const [downloading, setDownloading] = useState('')
  const { tosApi, currentOrg, isAdmin, isUser } = useAppContext()
  const auth = useAuth()
  const userId = auth?.user?.profile?.sub
  useEffect(() => {
    if (!tosApi || !currentOrg) return
    tosApi
      .advertisingPlatformServiceListTOSAgreements(
        currentOrg.id,
        isAdmin || isUser ? undefined : userId, // only show the user's TOS if they are read only
      )
      .then(({ tosAgreements }) => {
        const convertedTosList = tosAgreements?.reduce(
          (acc: TOSTableColumn[], curr) => {
            if (!curr.userId || !curr.filename) return acc
            const splitFileName = curr.filename.split('-')
            const arrLength = splitFileName.length - 1
            const created = `${splitFileName[arrLength - 1]}/${splitFileName[
              arrLength
            ].replace('.pdf', '')}/${splitFileName[arrLength - 2]}`
            const version = splitFileName[arrLength - 3]
            const user = splitFileName[arrLength - 4]
              .replace('_', ' ')
              .replace('*', '')
            return [
              ...acc,
              {
                userId: curr.userId,
                fileName: curr.filename,
                created,
                version,
                user,
              },
            ]
          },
          [],
        )
        setTosList(convertedTosList || [])
      })
      .catch((err) => {
        if (err.body?.message !== 'could not find tos agreements')
          showErrorMessage('Error fetching terms of service list', '')
      })
  }, [currentOrg, isAdmin, isUser, tosApi, userId])
  return (
    <div className="TOSList">
      <TextHeader type={3} className="text-primary">
        Terms of service
      </TextHeader>
      <Table
        className="TOSList__table"
        columns={[
          { path: 'user', label: 'Name' },
          { path: 'version', label: 'Version' },
          { path: 'created', label: 'Created' },
          {
            path: '',
            RowCell: ({ fileName, userId }) => (
              <span
                data-tooltip="left"
                aria-label="Download terms of service"
                className="justify-center"
              >
                <Button
                  iconOnly={<Fa icon="file-arrow-down" size={1} />}
                  disabled={downloading === fileName}
                  onClick={() => {
                    if (!currentOrg?.id) return
                    setDownloading('fileName')
                    downloadTos(fileName, currentOrg.id, userId).then(() =>
                      setDownloading(''),
                    )
                  }}
                />
              </span>
            ),
          },
        ]}
        rows={tosList}
      />
    </div>
  )
}
