/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Metrics } from '../models/V1Metrics';
import { V1ResultMetrics } from '../models/V1ResultMetrics';
import { V1SubDetails } from '../models/V1SubDetails';
import { HttpFile } from '../http/http';

export class V1Results {
    /**
    * Results within a timeframe
    */
    'results'?: Array<V1ResultMetrics>;
    'totals'?: V1Metrics;
    'details'?: V1SubDetails;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "results",
            "baseName": "results",
            "type": "Array<V1ResultMetrics>",
            "format": ""
        },
        {
            "name": "totals",
            "baseName": "totals",
            "type": "V1Metrics",
            "format": ""
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "V1SubDetails",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Results.attributeTypeMap;
    }

    public constructor() {
    }
}

