import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  Suspense,
} from 'react'
import { useKeyboardFocus } from '@eltoro-ui/hooks'
import { LoadingBar } from '../LoadingBar'
import { FCc } from '../types'
import { getColors } from '../../helpers'
import { ColorTokensWithLighterAndDarkerType } from '../Text'
import { Tab } from './Tab'
import './Tabs.scss'

export type TabType = {
  id: string
  label: ReactNode
  onClick?: () => void
  defaultTab?: boolean
  isDisabled?: boolean
  component?: ReactNode
}

export const Tabs: FCc<{
  classNameContent?: string
  classNamePanel?: string
  classNameTabs?: string
  horizontal?: boolean
  tabs: TabType[]
  on?: ColorTokensWithLighterAndDarkerType
}> = ({
  classNameContent = '',
  classNamePanel = '',
  classNameTabs = '',
  horizontal = true,
  tabs,
  on = 'grey-100',
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [currentTab, setCurrentTab] = useState('')
  const [activeBg, setActiveBg] = useState({ left: 0, width: 0 })
  const tabsRef = useRef<HTMLUListElement>(null)
  const hasKeyboardFocus = useKeyboardFocus(tabsRef)

  const {
    colors: { color },
  } = getColors(on)

  const selectTab = useCallback(() => {
    const [activeTab] = tabs.filter((tab) => tab.defaultTab)

    if (activeTab) {
      setCurrentTab(activeTab?.id)
    } else {
      setCurrentTab(tabs[0].id)
    }
  }, [tabs]) // memoize the function once and change when tab length changes, cannot use useMemo to memoize function, doing this allows referential comparison when component renders -- avoids eslint-login error for not including dependencies in useEffect but including it would cause infinite loop error in React 18

  useEffect(() => {
    selectTab()
  }, [selectTab]) // return selectTab function with useCallback so when tab length changes, re-render the component and memoize it again

  const handleClick = (tabId: string, tabClick?: () => void) => {
    setCurrentTab(tabId)

    if (tabClick) tabClick()
  }

  // get new tab from keyboard input
  const getNewTab = (next: boolean, index: number): TabType => {
    const newIndex = index + (next ? 1 : -1)
    const newTabIndex = (i: number) => {
      if (next) {
        return i > tabs.length - 1 ? 0 : i
      }
      return i < 0 ? tabs.length - 1 : i
    }
    // if the new index is disabled, run func again with new index
    if (tabs[newTabIndex(newIndex)].isDisabled)
      return getNewTab(next, newTabIndex(newIndex))
    return tabs[newTabIndex(newIndex)]
  }
  // set new tab and trigger optional onClick from keyboard inputs
  const handleTabChange = (next: boolean) => {
    const indexOfCurrentTab = tabs.findIndex((tab) => tab.id === currentTab)
    const newTab = getNewTab(next, indexOfCurrentTab)
    setCurrentTab(newTab.id)
    if (newTab.onClick) newTab.onClick()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    if (e.key === 'Tab') handleTabChange(true)
    if (e.key === 'ArrowLeft') handleTabChange(false)
    if (e.key === 'ArrowRight') handleTabChange(true)
  }

  const [activeTab] = tabs.filter((tab) => tab.id === currentTab)

  return (
    <div
      className={`Tabs ${classNamePanel}  ${
        horizontal ? 'Tabs--is-horizontal' : 'Tabs--is-vertical'
      }`}
      role="tabpanel"
    >
      <ul
        className={`Tab__nav ${classNameTabs} ${color}`}
        ref={tabsRef}
        role="tablist"
        tabIndex={0}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={handleKeyDown}
      >
        {tabs.map((tab) => (
          <Tab
            className={`Tab-${tab.id}`}
            key={tab.id}
            tab={tab}
            isActive={currentTab === tab.id}
            onClick={() => handleClick(tab.id, tab?.onClick)}
            onIsActiveChange={setActiveBg}
          />
        ))}
        <span
          className={`Tab__activeBg inline-block ${
            isFocused ? 'Tab__activeBg--is-focused' : ''
          } ${hasKeyboardFocus ? 'Tab__activeBg--is-keyboard-focused' : ''} ${
            activeTab ? 'Tab--is-active' : ''
          }`}
          style={{
            width: activeBg.width,
            left: activeBg.left,
          }}
        />
      </ul>
      <Suspense
        fallback={
          <LoadingBar className="m-auto" kind="circle" colorKind="primary" />
        }
      >
        <div className={`Tabs__content animate-slidedown ${classNameContent}`}>
          {activeTab?.component}
        </div>
      </Suspense>
    </div>
  )
}
