import React from 'react'
import { Modal } from '@eltoro-ui/components'
import { CreativeGen } from '@eltoro-ui/creative-generator'
import { uploadCreativeGen } from 'Requests'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'

export const CreativeGenModal: React.FC<{
  currentOrg: string
  onCloseOrOffClick: (bool: boolean) => void
  defaultType?: 'banner' | 'directMail'
  onCreate?: (creative: Creativeservicev1Creative) => void
}> = ({ currentOrg, onCloseOrOffClick, defaultType, onCreate }) => {
  const { creativeServiceApi, tok, rebuild } = useAppContext()
  const handleCreativeGenUpload = async (
    base64: string,
    name: string,
    type: string,
    order: string,
  ) => {
    if (!tok) return
    if (rebuild) return
    if (!currentOrg) return

    const creative = await uploadCreativeGen(
      name,
      base64,
      type,
      order,
      tok,
      currentOrg,
      // eslint-disable-next-line
      (prog: number) => console.log(prog),
    )
    if (!creative.id) return
    creativeServiceApi
      ?.advertisingPlatformServiceCreateCreative(currentOrg, {
        files: [creative],
        name: creative.name,
        type: creative.subType
          ? 'CREATIVE_TYPE_MAIL_PIECE'
          : 'CREATIVE_TYPE_BANNER',
      })
      .then((res) => {
        if (onCreate) onCreate(res)
      })
  }
  return (
    <Modal offClick={() => onCloseOrOffClick(false)} noBackground>
      <CreativeGen
        onClose={() => onCloseOrOffClick(false)}
        width="90vw"
        height="90vh"
        onComplete={(base64s: string[], type: string, order: string[]) => {
          base64s.map((file: string, i: number) => {
            return handleCreativeGenUpload(
              `DM${i}-${order[i]}`,
              file,
              type,
              order[i],
            )
          })
          onCloseOrOffClick(false)
        }}
        defaultCreativeType={defaultType}
      />
    </Modal>
  )
}
