/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3ConsoleCampaign } from '../models/V3ConsoleCampaign';
import { V3CurateCampaign } from '../models/V3CurateCampaign';
import { V3InvestCampaign } from '../models/V3InvestCampaign';
import { V3ViantAdelphicConfiguration } from '../models/V3ViantAdelphicConfiguration';
import { HttpFile } from '../http/http';

/**
* The request to update a deployment on an Order Line.
*/
export class UpdateDeploymentRequest {
    'investCampaign'?: V3InvestCampaign;
    'consoleCampaign'?: V3ConsoleCampaign;
    'orgId': string;
    'curateCampaign'?: V3CurateCampaign;
    'viantAdelphicConfiguration'?: V3ViantAdelphicConfiguration;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "investCampaign",
            "baseName": "invest_campaign",
            "type": "V3InvestCampaign",
            "format": ""
        },
        {
            "name": "consoleCampaign",
            "baseName": "console_campaign",
            "type": "V3ConsoleCampaign",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "curateCampaign",
            "baseName": "curate_campaign",
            "type": "V3CurateCampaign",
            "format": ""
        },
        {
            "name": "viantAdelphicConfiguration",
            "baseName": "viant_adelphic_configuration",
            "type": "V3ViantAdelphicConfiguration",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return UpdateDeploymentRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

