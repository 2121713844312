import { useRef } from 'react'
import dayjs from 'dayjs'
import { OrderLineTableRow } from 'types'
import { EmptyField } from 'Components'
import { getOrderLineSpent } from 'Helpers'

export const SpendCell = ({ row }: { row: OrderLineTableRow }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { orderLine, stats } = row

  return (
    <div className="SpendCell" ref={ref}>
      {(() => {
        const hasStarted = dayjs().isAfter(dayjs(orderLine.startTime))

        if (!hasStarted || !stats?.totals?.imps || !orderLine.impressions)
          return <EmptyField />
        const spent = getOrderLineSpent(orderLine, stats?.totals?.imps)

        return <>{`$${spent.toFixed(2)}`}</>
      })()}
    </div>
  )
}
