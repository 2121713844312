/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Assignment } from '../models/V1Assignment';
import { HttpFile } from '../http/http';

/**
* CommissionGroup
*/
export class Billingservicev1CommissionGroup {
    /**
    * Commission group
    */
    'recordNo'?: string;
    /**
    * Name of commission group
    */
    'name'?: string;
    /**
    * Commission Group Assignments
    */
    'assignments'?: Array<V1Assignment>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "recordNo",
            "baseName": "record_no",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "assignments",
            "baseName": "assignments",
            "type": "Array<V1Assignment>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Billingservicev1CommissionGroup.attributeTypeMap;
    }

    public constructor() {
    }
}

