import React, {
  Attributes,
  ClassAttributes,
  ElementType,
  FunctionComponentElement,
  ReactElement,
} from 'react'
import classNames from 'classnames'

// using overload signatures, defining three times for more robust Typescript support
// first func dec. for React components, or to extend ET components
export function styleTailwind<P extends Record<string, unknown>>(
  type: ElementType,
  ...className: string[]
): (props?: (Attributes & P) | null) => FunctionComponentElement<P>

// second dec.. add JSX/html elements, div, p, spans, a, sections, buttons
// eslint-disable-next-line no-redeclare
export function styleTailwind<
  T extends keyof JSX.IntrinsicElements,
  P extends JSX.IntrinsicElements[T]
>(
  type: keyof JSX.IntrinsicElements,
  ...className: string[]
): (props?: (ClassAttributes<T> & P) | null) => ReactElement<P, T>

// third dec.. implementation signature combines 1st and 2nd, returns a proxy component,
// TailwindElement, and creates new element with className attribute
// based on parameters,
// e.g. usage: const Subheader = styleTailwind('h2', 'text-xl text-primary')
// used in jsx as <Subheader>Subhead</Subheader>
// will output <h2 class="text-xl text-primary">Subhead</h2>
// eslint-disable-next-line no-redeclare
export function styleTailwind<P extends Record<string, unknown>>(
  type: ElementType | keyof JSX.IntrinsicElements,
  ...className: string[]
): (
  props?: (Attributes & P & { className?: string }) | null,
) => ReactElement<P> {
  return function TailwindElement(props) {
    return React.createElement(type, {
      ...props,
      className: classNames(
        // eslint-disable-next-line react/prop-types
        props?.className,
        ...className,
      ),
    })
  }
}
