/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1SubscriptionPeriodMetadata } from '../models/V1SubscriptionPeriodMetadata';
import { HttpFile } from '../http/http';

export class V1SubscriptionMetadata {
    'monthly'?: V1SubscriptionPeriodMetadata;
    'yearly'?: V1SubscriptionPeriodMetadata;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "monthly",
            "baseName": "monthly",
            "type": "V1SubscriptionPeriodMetadata",
            "format": ""
        },
        {
            "name": "yearly",
            "baseName": "yearly",
            "type": "V1SubscriptionPeriodMetadata",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1SubscriptionMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

