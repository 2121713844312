// This is the general template for email header, made into a separate function just in case we need it.
export const getEmailTemplate = (title: string, content: string) => `
<!DOCTYPE html>
<html lang="en">
<head>
<meta http-equiv="Content-Type" content="text/html" charset=UTF-8" />
<meta name="x-apple-disable-message-reformatting" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta name="color-scheme" content="light dark" />
<meta name="supported-color-schemes" content="light dark" />
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
href="https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400;500;700&display=swap"
rel="stylesheet"
/>
<title></title>
<style>
:root {
color-scheme: light dark;
supported-color-schemes: light dark;
}
a[class='action-btn']:hover {
background: #cf5157 !important;
}
[data-ogsc] img[class='image-light'] {
display: none !important;
}
[data-ogsc] img[class='image-dark'] {
display: inline-block !important;
width: auto !important;
overflow: visible !important;
float: none !important;
max-height: inherit !important;
max-width: 100% !important;
line-height: auto !important;
margin-top: 0px !important;
visibility: inherit !important;
}
@media screen and (max-width: 480px) {
.top {
  display: table-header-group !important;
  width: 100% !important;
}
.bottom {
  display: table-footer-group !important;
  width: 100% !important;
}
}
@media (prefers-color-scheme: dark) {
body {
  color: #dfdfeb !important;
  background-color: #050e17 !important;
}
a {
  color: #a7c9eb !important;
}
.action-btn {
  color: #ffffff !important;
}
.image-light {
  display: none !important;
}
.image-dark {
  display: inline-block !important;
  width: auto !important;
  overflow: visible !important;
  max-height: inherit !important;
  max-width: 100% !important;
  line-height: auto !important;
  margin-top: 0px !important;
  visibility: inherit !important;
}
p[class='warning-text'] {
  color: #ee9da0 !important;
}
}
</style>
</head>
<body
style="
color: #454545;
margin: 0;
padding: 0;
font-family: 'Source Sans 3', Helvetica, Arial, sans-serif;
"
>
<table
cellpadding="0"
cellspacing="0"
border="0"
style="
max-width: 600px;
border-collapse: collapse;
border-spacing: 0;
text-align: left;
margin: 0 auto;
"
role="presentation"
>
<tr>
<td>
  <table
    cellpadding="0"
    cellspacing="0"
    border="0"
    style="border-collapse: collapse; border-spacing: 0; width: 100%"
    role="presentation"
  >
    <tr style="border-bottom: 2px solid #af1e23">
      <td style="padding: 6px 12px; border-bottom: 2px solid #af1e23">
        <img
          src="https://awseast-prod-nextgeneltorocom-origin.s3.amazonaws.com/email_assets/logo.png"
          alt="El Toro Logo"
          height="42.5"
          width="121"
        />
      </td>
      <td
        style="
          padding: 6px 12px;
          font-weight: bold;
          text-align: right;
          text-transform: uppercase;
          border-bottom: 2px solid #af1e23;
        "
      >
        ${title}
      </td>
    </tr>
  </table>
</td>
</tr>
<tr>
<td style="padding: 20px">
  ${content}
</td>
</tr>
</table>
</body>
</html>
`
