import { ReactNode } from 'react'
import { Button, Modal, Fa } from '@eltoro-ui/components'
import { useWindowWidth } from 'Hooks'
import './MainWithSide.scss'

const CloseButton = ({
  className = '',
  onClose,
}: {
  className?: string
  onClose: () => void
}) => {
  return (
    <div className={`PreviewPanel__close-button-wrap ${className}`}>
      <Button
        className="PreviewPanel__close-button invisible transition-all group-hover:visible"
        iconOnly={
          <span data-tooltip="left" aria-label="Close panel">
            <Fa icon="times" size={1} />
          </span>
        }
        rounded
        onClick={onClose}
      />
    </div>
  )
}

export const MainWithSide: React.FC<{
  side?: ReactNode
  onSideClose?: () => void
  showAsModal?: boolean
  children?: React.ReactNode
}> = ({ side, showAsModal, onSideClose = () => null, children }) => {
  const showPreviewModal = useWindowWidth() <= 1045

  return (
    <div className="MainWithSide">
      <div className="MainWithSide__main flex max-w-full flex-1 flex-col">
        {children}
      </div>

      {side &&
        (showPreviewModal || showAsModal ? (
          <Modal
            offClick={onSideClose}
            classNameWrap="PreviewPanelModal"
            className={`MainWithSide__side MainWithSide__side--is-modal bg-base group relative z-30 rounded shadow-2xl ${
              showAsModal ? 'mobile:w-1/2' : ''
            }`}
          >
            <CloseButton onClose={onSideClose} />
            {side}
          </Modal>
        ) : (
          <div className="PreviewPanel MainWithSide__side RightColumn border-l-thin border-tint-gray-500 group relative -mr-2">
            <CloseButton
              className="CloseButton__wrap--inline relative top-5 right-7"
              onClose={onSideClose}
            />
            <div className="PreviewPanel__inner-wrap">{side}</div>
          </div>
        ))}
    </div>
  )
}
