import {
  Fa,
  Popover,
  TextHeader,
  Table,
  TextInput,
  Button,
} from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { useAppContext } from 'Contexts'
import dayjs from 'dayjs'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useState, useRef } from 'react'
import { GetName } from 'Helpers'

export const OrderLineNotes = ({
  className = '',
  orderLine,
  rejected,
}: {
  className?: string
  orderLine: Campaignservicev1OrderLine
  rejected?: boolean
}) => {
  const [showNotes, setShowNotes] = useState(false)
  const [addNotes, setAddNotes] = useState(false)
  const { campaignServiceApi, currentOrg } = useAppContext()

  const noteIconRef = useRef<HTMLDivElement>(null)
  const notePopoverRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([noteIconRef, notePopoverRef], () => setShowNotes(false))

  const noteSimple = (noteType: string) => {
    switch (noteType) {
      case 'NOTE_TYPE_APPROVE':
        return 'Approved'
      case 'NOTE_TYPE_GENERAL':
        return 'General'
      case 'NOTE_TYPE_PLAY':
        return 'Playing'
      case 'NOTE_TYPE_PAUSE':
        return 'Paused'
      case 'NOTE_TYPE_REJECT':
        return 'Rejected'
      case 'NOTE_TYPE_UNSPECIFIED':
        return 'Unspecified'
      default:
        return 'Unknown'
    }
  }

  const AddNotes = () => {
    const [note, setNote] = useState('')
    return (
      <div>
        <TextHeader type={4} className="my-1">
          Add Notes
        </TextHeader>
        <TextInput
          onChange={(e) => {
            return setNote(e.target.value)
          }}
          value={note}
        />
        <div className="flex justify-between pt-4">
          <Button onClick={() => setAddNotes(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (orderLine.id && currentOrg?.id) {
                campaignServiceApi?.advertisingPlatformServiceUpdateOrderLine(
                  orderLine.id,
                  currentOrg?.id,
                  {
                    notes: [{ content: note, createTime: new Date() }],
                  },
                  '',
                )
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
  return (
    <Popover
      className="OrderLineNotes__popover-wrap"
      position={['bottom']}
      align="start"
      isOpen={showNotes}
      content={
        <div
          className={`OrderLine_Notes OrderLine__popover ${className}`}
          ref={notePopoverRef}
        >
          <div className="flex justify-around">
            <TextHeader type={4} className="my-1">
              Order Line Notes
            </TextHeader>
            {/*
            Disable until notes are not read_only PLCAM-620 
            {!addNotes && (
              <Button
                iconOnly={<Fa icon="plus" size={1} />}
                onClick={() => setAddNotes(true)}
                rounded={false}
              />
            )} */}
          </div>
          {!addNotes && (
            <Table
              className="animate-fadein duration-1000"
              rows={orderLine.notes || []}
              columns={[
                {
                  path: 'content',
                  label: 'Note',
                  RowCell: (row) => {
                    if (!row.content) return null
                    return <small>{row.content}</small>
                  },
                },
                {
                  path: 'action',
                  label: 'Action',
                  RowCell: (row) => {
                    if (!row.type) return null
                    return <small>{noteSimple(row.type)}</small>
                  },
                },
                {
                  path: 'user',
                  label: 'User',
                  RowCell: (row) => {
                    if (!row.userId) return null
                    return <GetName userId={row.userId} />
                  },
                },
                {
                  path: 'date',
                  label: 'Date',
                  RowCell: (row) => {
                    if (!row.createTime) return null
                    return dayjs
                      .utc(row.createTime.toISOString().substring(0, 23))
                      .local()
                      .format('MM/DD/YY h:mm A')
                  },
                },
              ]}
            />
          )}
          {addNotes && <AddNotes />}
        </div>
      }
    >
      {orderLine.notes?.length! > 0 ? (
        <div
          className={`OrderLine_Notes-icon h-6 rounded-full hover:cursor-pointer ${
            rejected ? 'bg-danger-500 border-danger-400' : 'text-primary-400'
          }`}
          ref={noteIconRef}
          aria-label="View Order Line notes"
          data-tooltip="left"
        >
          <Fa
            className={`Notes focus:text-base ${
              rejected ? 'text-primer' : 'text-primary-400'
            }`}
            icon={rejected ? 'exclamation' : 'note'}
            type={rejected ? undefined : 'light'}
            size={1}
            onClick={() => setShowNotes(true)}
          />
        </div>
      ) : (
        <></>
      )}
    </Popover>
  )
}
