/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* * A full date, with non-zero year, month, and day values * A month and day value, with a zero year, such as an anniversary * A year on its own, with zero month and day values * A year and month value, with a zero day, such as a credit card expiration date  Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and `google.protobuf.Timestamp`.
*/
export class TypeDate {
    /**
    * Year of the date. Must be from 1 to 9999, or 0 to specify a date without a year.
    */
    'year'?: number;
    /**
    * Month of a year. Must be from 1 to 12, or 0 to specify a year without a month and day.
    */
    'month'?: number;
    /**
    * Day of a month. Must be from 1 to 31 and valid for the year and month, or 0 to specify a year by itself or a year and month where the day isn't significant.
    */
    'day'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "month",
            "baseName": "month",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "day",
            "baseName": "day",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return TypeDate.attributeTypeMap;
    }

    public constructor() {
    }
}

