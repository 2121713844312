/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3Advertiser } from '../models/V3Advertiser';
import { V3Deal } from '../models/V3Deal';
import { V3LineItem } from '../models/V3LineItem';
import { HttpFile } from '../http/http';

export class V3DealList {
    /**
    * The ID of a deal list.
    */
    'id'?: number;
    /**
    * The name of the deal list.
    */
    'name'?: string;
    /**
    * The deals that are to be included in the deal list. The deal IDs of the deals are included here.
    */
    'deals'?: Array<V3Deal>;
    /**
    * The advertisers for whom the deal list is meant for. The advertiser IDs are included here.
    */
    'advertisers'?: Array<V3Advertiser>;
    /**
    * Time of last modification made to the deal list.
    */
    'lastModified'?: string;
    /**
    * Time of creation of the deal list.
    */
    'createdOn'?: string;
    /**
    * The line items that are targeting the deal list (line items that have a profile attached to them and that profile is targeting the deal list).
    */
    'lineItems'?: Array<V3LineItem>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "deals",
            "baseName": "deals",
            "type": "Array<V3Deal>",
            "format": ""
        },
        {
            "name": "advertisers",
            "baseName": "advertisers",
            "type": "Array<V3Advertiser>",
            "format": ""
        },
        {
            "name": "lastModified",
            "baseName": "last_modified",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdOn",
            "baseName": "created_on",
            "type": "string",
            "format": ""
        },
        {
            "name": "lineItems",
            "baseName": "line_items",
            "type": "Array<V3LineItem>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3DealList.attributeTypeMap;
    }

    public constructor() {
    }
}

