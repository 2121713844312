/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TypeDate } from '../models/TypeDate';
import { V1SubscriptionFreeTrialMetadata } from '../models/V1SubscriptionFreeTrialMetadata';
import { HttpFile } from '../http/http';

export class V1SubscriptionPeriodMetadata {
    /**
    * Recurring price of the Subscription
    */
    'price'?: number;
    /**
    * Amount due today
    */
    'dueToday'?: number;
    'billDate'?: TypeDate;
    /**
    * Amount due on the bill date
    */
    'amountDue'?: number;
    'freeTrial'?: V1SubscriptionFreeTrialMetadata;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "price",
            "baseName": "price",
            "type": "number",
            "format": "double"
        },
        {
            "name": "dueToday",
            "baseName": "due_today",
            "type": "number",
            "format": "double"
        },
        {
            "name": "billDate",
            "baseName": "bill_date",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "amountDue",
            "baseName": "amount_due",
            "type": "number",
            "format": "double"
        },
        {
            "name": "freeTrial",
            "baseName": "free_trial",
            "type": "V1SubscriptionFreeTrialMetadata",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1SubscriptionPeriodMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

