/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1Status } from '../models/Campaignservicev1Status';
import { V1CampaignOrderLine } from '../models/V1CampaignOrderLine';
import { V1PoliticalTransparency } from '../models/V1PoliticalTransparency';
import { HttpFile } from '../http/http';

/**
* The event emitted when a Campaign is created
*/
export class V1CampaignCreated {
    'id'?: string;
    'created'?: Date;
    'refId'?: string;
    'orgId'?: string;
    'name'?: string;
    'startDate'?: Date;
    'endDate'?: Date;
    'orderLines'?: Array<V1CampaignOrderLine>;
    'userId'?: string;
    'requestId'?: string;
    'timestamp'?: Date;
    'politicalTransparency'?: V1PoliticalTransparency;
    'updated'?: Date;
    'status'?: Campaignservicev1Status;
    'jobId'?: string;
    'poId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<V1CampaignOrderLine>",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "politicalTransparency",
            "baseName": "political_transparency",
            "type": "V1PoliticalTransparency",
            "format": ""
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Campaignservicev1Status",
            "format": ""
        },
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "poId",
            "baseName": "po_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1CampaignCreated.attributeTypeMap;
    }

    public constructor() {
    }
}

