export const redTheme = `:root{
   /* base background-color tint of tertiary */

  --base-bg:                                          var(--tint-light);
  --base-text:                                    var(--color-grey-900);
  --base-gray:                                 var(--color-primary-050);
  --base-info:                                   var(--color-secondary);
  --base-info-050:                           var(--color-secondary-050);
  --base-info-100:                           var(--color-secondary-100);
  --base-info-200:                           var(--color-secondary-200);
  --base-info-300:                           var(--color-secondary-300);
  --base-info-400:                           var(--color-secondary-400);
  --base-info-500:                           var(--color-secondary-500);
  --base-info-600:                           var(--color-secondary-600);
  --base-info-700:                           var(--color-secondary-700);
  --base-info-800:                           var(--color-secondary-800);
  --base-info-900:                           var(--color-secondary-900);
  --highlight-bg:                              var(--color-primary-200);
  --tw-ring-color:                                 var(--color-primary);
  --primer:                                           var(--tint-light);

    /* font awesome duo-tone colors */
  --fa-primary-color:                         var(--color-primary-600);
  --fa-primary-opacity:                                              1;
  --fa-secondary-color:                      var(--color-tertiary-300);
  --fa-secondary-opacity:                                            1;

  /* tints of khaki -- El Toro gold, #c1ba9d, hsl(48,23%,69%); higher number, opaque */
  --tint-khaki:                                                hsla(48, 23%, 55%, 1);
  --tint-khaki-050:                                         hsla(48, 23%, 40%, 0.05);
  --tint-khaki-100:                                         hsla(48, 23%, 45%, 0.15);
  --tint-khaki-200:                                         hsla(48, 23%, 50%, 0.25);
  --tint-khaki-300:                                         hsla(48, 23%, 55%, 0.38);
  --tint-khaki-400:                                         hsla(48, 23%, 60%, 0.48);
  --tint-khaki-500:                                         hsla(48, 23%, 69%, 0.51);
  --tint-khaki-600:                                         hsla(48, 23%, 58%, 0.58);
  --tint-khaki-700:                                         hsla(48, 23%, 50%, 0.69);
  --tint-khaki-800:                                         hsla(48, 23%, 40%, 0.87);
  --tint-khaki-900:                                         hsla(48, 23%, 35%, 0.95);

  --text-on-tint-khaki: hsl(48, 22%, 90%,);
  --text-on-tint-gray: hsl(0, 0%, 90%);
  --text-on-tint-gray-700: hsl(0, 0%, 65%);
  --text-on-tint-gray-800: hsl(0, 0%, 70%);
  --text-on-tint-gray-900: hsl(0, 0%, 85%);
  --text-on-tint-dark: hsl(0, 10%, 90%);
  --text-on-tint-dark-700: hsl(0, 10%, 70%);
  --text-on-tint-dark-800: hsl(0, 10%, 80%);
  --text-on-tint-dark-900: hsl(0, 10%, 85%);
  --text-on-tint-light: hsl(0, 10%, 40%);


 /* tints of gray -- neutrals for light/dark backgrounds, higher number more opaque */ 
  --tint-gray:                                                var(--tint-gray-600);
  --tint-gray-050:                                            hsla(0, 0%, 40%, 0.05);
  --tint-gray-100:                                             hsla(0, 0%, 40%, 0.1);
  --tint-gray-200:                                             hsla(0, 0%, 40%, 0.2);
  --tint-gray-300:                                             hsla(0, 0%, 40%, 0.3);
  --tint-gray-400:                                             hsla(0, 0%, 40%, 0.4);
  --tint-gray-500:                                             hsla(0, 0%, 40%, 0.5);
  --tint-gray-600:                                             hsla(0, 0%, 40%, 0.6);
  --tint-gray-700:                                             hsla(0, 0%, 40%, 0.7);
  --tint-gray-800:                                             hsla(0, 0%, 40%, 0.8);
  --tint-gray-900:                                             hsla(0, 0%, 40%, 0.9);
  
 /* tints of whites -- used to lighten dark background, higher number more opaque */
  --tint-light:                                                   hsl(0, 0%, 100%);
  --tint-light-050:                                        hsla(0, 0%, 100%, 0.05);
  --tint-light-100:                                         hsla(0, 0%, 100%, 0.1);
  --tint-light-200:                                         hsla(0, 0%, 100%, 0.2);
  --tint-light-300:                                         hsla(0, 0%, 100%, 0.3);
  --tint-light-400:                                         hsla(0, 0%, 100%, 0.4);
  --tint-light-500:                                         hsla(0, 0%, 100%, 0.5);
  --tint-light-600:                                         hsla(0, 0%, 100%, 0.6);
  --tint-light-700:                                         hsla(0, 0%, 100%, 0.7);
  --tint-light-800:                                         hsla(0, 0%, 100%, 0.8);
  --tint-light-900:                                         hsla(0, 0%, 100%, 0.9);

  /* tints of dark - use to lighten light background, higher number more opaque */
  --tint-dark:                                                    hsl(0, 10%, 8%);
  --tint-dark-050:                                          hsla(0, 10%, 8%, 0.05);
  --tint-dark-100:                                          hsla(0, 10%, 8%, 0.1);
  --tint-dark-200:                                           hsla(0, 10%, 8%, 0.2);
  --tint-dark-300:                                          hsla(0, 10%, 8%, 0.3);
  --tint-dark-400:                                           hsla(0, 10%, 8%, 0.4);
  --tint-dark-500:                                          hsla(0, 10%, 8%, 0.5);
  --tint-dark-600:                                           hsla(0, 10%, 8%, 0.6);
  --tint-dark-700:                                          hsla(0, 10%, 8%, 0.7);
  --tint-dark-800:                                           hsla(0, 10%, 8%, 0.8);
  --tint-dark-900:                                          hsla(0, 10%, 8%, 0.9);

  --color-white:                                                   hsl(0, 0%, 100%);
  --text-on-color-white:                                                           #051317;
  --text-on-color-white--subdued:                                                  #3d4548;

  --text-on-color-white--primary:                                      hsl(48, 22.5%, 40%);
  --text-on-color-white--primary-subdued--UNSAFE:                                  #f2405e;
  --text-on-color-white--secondary:                                                #0079c5;
  --text-on-color-white--secondary-subdued--UNSAFE:                                #409bd4;
  --text-on-color-white--tertiary:                                                 #807700;
  --text-on-color-white--tertiary-subdued--UNSAFE:                                 #a09940;
  --text-on-color-white--success:                                                  #008940;
  --text-on-color-white--success-subdued--UNSAFE:                                  #40a770;
  --text-on-color-white--warning:                                                  #787b00;
  --text-on-color-white--warning-subdued--UNSAFE:                                  #9a9c40;
  --text-on-color-white--danger:                                                   #eb002a;
  --text-on-color-white--danger-subdued--UNSAFE:                                   #f0405f;

  /* GREY 050 ******************************************************************** */
  --color-grey-050:                                                          #f4f7f8;

  --text-on-color-grey-050:                                                        #051317;
  --text-on-color-grey-050--subdued:                                               #3b4447;

  --text-on-color-grey-050--primary:                                   hsl(48, 22.5%, 35%);
  --text-on-color-grey-050--primary-subdued--UNSAFE:                               #e83e5a;
  --text-on-color-grey-050--secondary:                                             #0075bf;
  --text-on-color-grey-050--secondary-subdued--UNSAFE:                             #3d96cd;
  --text-on-color-grey-050--tertiary:                                              #7c7300;
  --text-on-color-grey-050--tertiary-subdued--UNSAFE:                              #9a943e;
  --text-on-color-grey-050--success:                                               #00833d;
  --text-on-color-grey-050--success-subdued--UNSAFE:                               #3da06c;
  --text-on-color-grey-050--warning:                                               #747600;
  --text-on-color-grey-050--warning-subdued--UNSAFE:                               #94963e;
  --text-on-color-grey-050--danger:                                                #e20028;
  --text-on-color-grey-050--danger-subdued--UNSAFE:                                #e63e5c;

  /* GREY 100 ******************************************************************** */
  --color-grey-100:                                                          #e2e9ec;

  --text-on-color-grey-100:                                                        #051317;
  --text-on-color-grey-100--subdued:                                               #374145;

  --text-on-color-grey-100--primary:                                   hsl(48, 22.5%, 35%);
  --text-on-color-grey-100--primary-subdued--UNSAFE:                               #d73a54;
  --text-on-color-grey-100--secondary:                                             #006cb1;
  --text-on-color-grey-100--secondary-subdued--UNSAFE:                             #398bc0;
  --text-on-color-grey-100--tertiary:                                              #726a00;
  --text-on-color-grey-100--tertiary-subdued--UNSAFE:                              #8e8a3b;
  --text-on-color-grey-100--success:                                               #007937;
  --text-on-color-grey-100--success-subdued--UNSAFE:                               #399564;
  --text-on-color-grey-100--warning:                                               #6a6c00;
  --text-on-color-grey-100--warning-subdued--UNSAFE:                               #888b3b;
  --text-on-color-grey-100--danger:                                                #d30024;
  --text-on-color-grey-100--danger-subdued--UNSAFE:                                #d73a56;

  /* GREY 200 ******************************************************************** */
  --color-grey-200:                                                          #cdd6d9;

  --text-on-color-grey-200:                                                        #051317;
  --text-on-color-grey-200--subdued:                                               #333d41;

  --text-on-color-grey-200--primary:                                               #b9001d;
  --text-on-color-grey-200--primary-subdued--UNSAFE:                               #be364c;
  --text-on-color-grey-200--secondary:                                             #005e9b;
  --text-on-color-grey-200--secondary-subdued--UNSAFE:                             #337cab;
  --text-on-color-grey-200--tertiary:                                              #655e00;
  --text-on-color-grey-200--tertiary-subdued--UNSAFE:                              #7f7c36;
  --text-on-color-grey-200--success:                                               #006b30;
  --text-on-color-grey-200--success-subdued--UNSAFE:                               #33865a;
  --text-on-color-grey-200--warning:                                               #5e6000;
  --text-on-color-grey-200--warning-subdued--UNSAFE:                               #7a7e36;
  --text-on-color-grey-200--danger:                                                #bb001f;
  --text-on-color-grey-200--danger-subdued--UNSAFE:                                #bf364d;

  /* GREY 300 ******************************************************************** */
  --color-grey-300:                                                          #aeb7bb;

  --text-on-color-grey-300:                                                        #051317;
  --text-on-color-grey-300--subdued--UNSAFE:                                       #2d373b;

  --text-on-color-grey-300--primary:                                               #930015;
  --text-on-color-grey-300--primary-subdued--UNSAFE:                               #9a2e3e;
  --text-on-color-grey-300--secondary:                                             #004a7b;
  --text-on-color-grey-300--secondary-subdued--UNSAFE:                             #2c658b;
  --text-on-color-grey-300--tertiary:                                              #4e4800;
  --text-on-color-grey-300--tertiary-subdued--UNSAFE:                              #66642f;
  --text-on-color-grey-300--success:                                               #005324;
  --text-on-color-grey-300--success-subdued--UNSAFE:                               #2c6c4a;
  --text-on-color-grey-300--warning:                                               #484a00;
  --text-on-color-grey-300--warning-subdued--UNSAFE:                               #61652f;
  --text-on-color-grey-300--danger:                                                #930016;
  --text-on-color-grey-300--danger-subdued--UNSAFE:                                #9a2e3f;

  /* GREY 400 ******************************************************************** */
  --color-grey-400:                                                          #8d989c;

  --text-on-color-grey-400:                                                        #051317;
  --text-on-color-grey-400--subdued--UNSAFE:                                       #263134;

  --text-on-color-grey-400--primary:                                               #660008;
  --text-on-color-grey-400--primary-subdued--UNSAFE:                               #70262d;
  --text-on-color-grey-400--secondary:                                             #003154;
  --text-on-color-grey-400--secondary-subdued--UNSAFE:                             #234b66;
  --text-on-color-grey-400--tertiary:                                              #342f00;
  --text-on-color-grey-400--tertiary-subdued--UNSAFE:                              #4a4927;
  --text-on-color-grey-400--success:                                               #003716;
  --text-on-color-grey-400--success-subdued--UNSAFE:                               #234f37;
  --text-on-color-grey-400--warning:                                               #313100;
  --text-on-color-grey-400--warning-subdued--UNSAFE:                               #484b27;
  --text-on-color-grey-400--danger:                                                #66000a;
  --text-on-color-grey-400--danger-subdued--UNSAFE:                                #70262e;

  /* GREY 500 ******************************************************************** */
  --color-grey-500:                                                          #6d7679;

  --text-on-color-grey-500:                                                        #ffffff;
  --text-on-color-grey-500--subdued--UNSAFE:                                       #d9dbdc;

  --text-on-color-grey-500--primary:                                               #fffbfa;
  --text-on-color-grey-500--primary-subdued--UNSAFE:                               #dadada;
  --text-on-color-grey-500--secondary:                                             #fafcff;
  --text-on-color-grey-500--secondary-subdued--UNSAFE:                             #d7dbdd;
  --text-on-color-grey-500--tertiary:                                              #fffcf0;
  --text-on-color-grey-500--tertiary-subdued--UNSAFE:                              #dadbd2;
  --text-on-color-grey-500--success:                                               #f1fff3;
  --text-on-color-grey-500--success-subdued--UNSAFE:                               #d0ddd4;
  --text-on-color-grey-500--warning:                                               #fffce8;
  --text-on-color-grey-500--warning-subdued--UNSAFE:                               #dadbcc;
  --text-on-color-grey-500--danger:                                                #fffbfa;
  --text-on-color-grey-500--danger-subdued--UNSAFE:                                #dadada;

  /* GREY 600 ******************************************************************** */
  --color-grey-600:                                                          #545d61;

  --text-on-color-grey-600:                                                        #ffffff;
  --text-on-color-grey-600--subdued--UNSAFE:                                       #d1d4d5;

  --text-on-color-grey-600--primary:                                               #ffcac2;
  --text-on-color-grey-600--primary-subdued--UNSAFE:                               #d4afaa;
  --text-on-color-grey-600--secondary:                                             #bbd5ff;
  --text-on-color-grey-600--secondary-subdued--UNSAFE:                             #a1b7d7;
  --text-on-color-grey-600--tertiary:                                              #e9da00;
  --text-on-color-grey-600--tertiary-subdued--UNSAFE:                              #c4bb18;
  --text-on-color-grey-600--success:                                               #00f678;
  --text-on-color-grey-600--success-subdued--UNSAFE:                               #15d072;
  --text-on-color-grey-600--warning:                                               #dade00;
  --text-on-color-grey-600--warning-subdued--UNSAFE:                               #b8be18;
  --text-on-color-grey-600--danger:                                                #ffcac2;
  --text-on-color-grey-600--danger-subdued--UNSAFE:                                #d4afaa;

  /* GREY 700 ******************************************************************** */
  --color-grey-700:                                                          #394144;

  --text-on-color-grey-700:                                                        #ffffff;
  --text-on-color-grey-700--subdued:                                               #c9cccc;

  --text-on-color-grey-700--primary:                                               #ff897b;
  --text-on-color-grey-700--primary-subdued--UNSAFE:                               #cd776d;
  --text-on-color-grey-700--secondary:                                             #64afff;
  --text-on-color-grey-700--secondary-subdued--UNSAFE:                             #5994d0;
  --text-on-color-grey-700--tertiary:                                              #bbaf00;
  --text-on-color-grey-700--tertiary-subdued--UNSAFE:                              #9a9411;
  --text-on-color-grey-700--success:                                               #00c65f;
  --text-on-color-grey-700--success-subdued--UNSAFE:                               #0ea558;
  --text-on-color-grey-700--warning:                                               #afb300;
  --text-on-color-grey-700--warning-subdued--UNSAFE:                               #919711;
  --text-on-color-grey-700--danger:                                                #ff8b7e;
  --text-on-color-grey-700--danger-subdued--UNSAFE:                                #cd796f;

  /* GREY 800 ******************************************************************** */
  --color-grey-800:                                                          #1f272a;

  --text-on-color-grey-800:                                                        #ffffff;
  --text-on-color-grey-800--subdued:                                               #c1c3c4;

  --text-on-color-grey-800--primary:                                               #ff4743;
  --text-on-color-grey-800--primary-subdued--UNSAFE:                               #c73f3d;
  --text-on-color-grey-800--secondary:                                             #0091ea;
  --text-on-color-grey-800--secondary-subdued--UNSAFE:                             #0876ba;
  --text-on-color-grey-800--tertiary:                                              #9a9000;
  --text-on-color-grey-800--tertiary-subdued--UNSAFE:                              #7b760a;
  --text-on-color-grey-800--success:                                               #00a34d;
  --text-on-color-grey-800--success-subdued--UNSAFE:                               #088444;
  --text-on-color-grey-800--warning:                                               #909304;
  --text-on-color-grey-800--warning-subdued--UNSAFE:                               #74780d;
  --text-on-color-grey-800--danger:                                                #ff4c48;
  --text-on-color-grey-800--danger-subdued--UNSAFE:                                #c74340;

  /* GREY 900 ******************************************************************** */
  --color-grey-900:                                                          #0a1215;

  --text-on-color-grey-900:                                                        #ffffff;
  --text-on-color-grey-900--subdued:                                               #babcbd;

  --text-on-color-grey-900--primary:                                               #f8002a;
  --text-on-color-grey-900--primary-subdued--UNSAFE:                               #bc0525;
  --text-on-color-grey-900--secondary:                                             #0082d3;
  --text-on-color-grey-900--secondary-subdued--UNSAFE:                             #0366a4;
  --text-on-color-grey-900--tertiary:                                              #867e00;
  --text-on-color-grey-900--tertiary-subdued--UNSAFE:                              #676305;
  --text-on-color-grey-900--success:                                               #008f42;
  --text-on-color-grey-900--success-subdued--UNSAFE:                               #037037;
  --text-on-color-grey-900--warning:                                               #7f8100;
  --text-on-color-grey-900--warning-subdued--UNSAFE:                               #626505;
  --text-on-color-grey-900--danger:                                                #fa002d;
  --text-on-color-grey-900--danger-subdued--UNSAFE:                                #be0527;




  /* PRIMARY 050 ***************************************************************** */
  --color-primary-050:                                                       #fff4f2;

  --text-on-color-primary-050:                                                     #190e0c;
  --text-on-color-primary-050--subdued:                                            #4a403e;
  --text-on-color-primary-050--vivid:                                              #e40026;
  --text-on-color-primary-050--vivid-subdued--UNSAFE:                              #f46054;

  /* PRIMARY 100 ***************************************************************** */
  --color-primary-100:                                                       #ffe2dd;

  --text-on-color-primary-100:                                                     #190e0c;
  --text-on-color-primary-100--subdued:                                            #4a3c3a;
  --text-on-color-primary-100--vivid:                                              #d30022;
  --text-on-color-primary-100--vivid-subdued--UNSAFE:                              #e5574c;

  /* PRIMARY 200 ***************************************************************** */
  --color-primary-200:                                                       #ffc7bf;

  --text-on-color-primary-200:                                                     #190e0c;
  --text-on-color-primary-200--subdued:                                            #493734;
  --text-on-color-primary-200--vivid:                                              #b9001d;
  --text-on-color-primary-200--vivid-subdued--UNSAFE:                              #cf4a42;

  /* PRIMARY 300 ***************************************************************** */
  --color-primary-300:                                                       #ff9b8e;

  --text-on-color-primary-300:                                                     #190e0c;
  --text-on-color-primary-300--subdued--UNSAFE:                                    #4a2e2b;
  --text-on-color-primary-300--vivid:                                              #950015;
  --text-on-color-primary-300--vivid-subdued--UNSAFE:                              #b13531;

  /* PRIMARY 400 ***************************************************************** */
  --color-primary-400:                                                       #f8675b;

  --text-on-color-primary-400:                                                     #190e0c;
  --text-on-color-primary-400--subdued--UNSAFE:                                    #492420;
  --text-on-color-primary-400--vivid:                                              #660008;
  --text-on-color-primary-400--vivid-subdued--UNSAFE:                              #891d1d;

  /* PRIMARY 500 ***************************************************************** */
  --color-primary-500:                                                       #d23e3a;

  --text-on-color-primary-500:                                                     #ffffff;
  --text-on-color-primary-500--subdued--UNSAFE:                                    #fcd1ca;
  --text-on-color-primary-500--vivid:                                              #fffaf9;
  --text-on-color-primary-500--vivid-subdued--UNSAFE:                              #fccdc4;

  /* PRIMARY 600 ***************************************************************** */
  --color-primary-600:                                                       #b22125;

  --text-on-color-primary-600:                                                     #ffffff;
  --text-on-color-primary-600--subdued--UNSAFE:                                    #f5cac3;
  --text-on-color-primary-600--vivid:                                              #ffcac2;
  --text-on-color-primary-600--vivid-subdued--UNSAFE:                              #f0a398;

  /* PRIMARY 700 ***************************************************************** */
  --color-primary-700:                                                       #860012;

  --text-on-color-primary-700:                                                     #ffffff;
  --text-on-color-primary-700--subdued:                                            #e9c3bd;
  --text-on-color-primary-700--vivid:                                              #ff897b;
  --text-on-color-primary-700--vivid-subdued--UNSAFE:                              #e16b5f;

  /* PRIMARY 800 ***************************************************************** */
  --color-primary-800:                                                       #540001;

  --text-on-color-primary-800:                                                     #ffffff;
  --text-on-color-primary-800--subdued:                                            #d8bdb9;
  --text-on-color-primary-800--vivid:                                              #ff4743;
  --text-on-color-primary-800--vivid-subdued--UNSAFE:                              #d13433;

  /* PRIMARY 900 ***************************************************************** */
  --color-primary-900:                                                       #240800;

  --text-on-color-primary-900:                                                     #ffffff;
  --text-on-color-primary-900--subdued:                                            #c3bab9;
  --text-on-color-primary-900--vivid:                                              #f8002a;
  --text-on-color-primary-900--vivid-subdued--UNSAFE:                              #bd1321;

  --color-primary-lighter:                                                   var(--color-primary-400);
  --color-primary:                                                           var(--color-primary-600);
  --color-primary-darker:                                                    var(--color-primary-700);
  --text-on-color-primary-lighter:                                                 var(--text-on-color-primary-500);
  --text-on-color-primary-lighter--subdued--UNSAFE:                                var(--text-on-color-primary-500--subdued--UNSAFE);
  --text-on-color-primary-lighter--vivid:                                          var(--text-on-color-primary-500--vivid);
  --text-on-color-primary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-primary-500--vivid-subdued--UNSAFE);
  --text-on-color-primary:                                                         var(--text-on-color-primary-600);
  --text-on-color-primary--subdued--UNSAFE:                                        var(--text-on-color-primary-600--subdued--UNSAFE);
  --text-on-color-primary--vivid:                                                  var(--text-on-color-primary-600--vivid);
  --text-on-color-primary--vivid-subdued--UNSAFE:                                  var(--text-on-color-primary-600--vivid-subdued--UNSAFE);
  --text-on-color-primary-darker:                                                  var(--text-on-color-primary-700);
  --text-on-color-primary-darker--subdued--UNSAFE:                                 var(--text-on-color-primary-700--subdued--UNSAFE);
  --text-on-color-primary-darker--vivid:                                           var(--text-on-color-primary-700--vivid);
  --text-on-color-primary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-primary-700--vivid-subdued--UNSAFE);




  /* SECONDARY 050 *************************************************************** */
  --color-secondary-050:                                                     #f2f7ff;

  --text-on-color-secondary-050:                                                   #0c1118;
  --text-on-color-secondary-050--subdued:                                          #3e4249;
  --text-on-color-secondary-050--vivid:                                            #0075bf;
  --text-on-color-secondary-050--vivid-subdued--UNSAFE:                            #6094cf;

  /* SECONDARY 100 *************************************************************** */
  --color-secondary-100:                                                     #dce9ff;

  --text-on-color-secondary-100:                                                   #f2f7ff;
  --text-on-color-secondary-100--subdued:                                          #394049;
  --text-on-color-secondary-100--vivid:                                            #006cb1;
  --text-on-color-secondary-100--vivid-subdued--UNSAFE:                            #5689c4;

  /* SECONDARY 200 *************************************************************** */
  --color-secondary-200:                                                     #bcd6ff;

  --text-on-color-secondary-200:                                                   #0c1118;
  --text-on-color-secondary-200--subdued:                                          #333c49;
  --text-on-color-secondary-200--vivid:                                            #005e9b;
  --text-on-color-secondary-200--vivid-subdued--UNSAFE:                            #467ab3;

  /* SECONDARY 300 *************************************************************** */
  --color-secondary-300:                                                     #81b9ff;

  --text-on-color-secondary-300:                                                   #0c1118;
  --text-on-color-secondary-300--subdued--UNSAFE:                                  #273649;
  --text-on-color-secondary-300--vivid:                                            #004a7b;
  --text-on-color-secondary-300--vivid-subdued--UNSAFE:                            #2b649a;

  /* SECONDARY 400 *************************************************************** */
  --color-secondary-400:                                                     #009bfa;

  --text-on-color-secondary-400:                                                   #0c1118;
  --text-on-color-secondary-400--subdued--UNSAFE:                                  #1a3048;
  --text-on-color-secondary-400--vivid:                                            #003154;
  --text-on-color-secondary-400--vivid-subdued--UNSAFE:                            #004a7a;

  /* SECONDARY 500 *************************************************************** */
  --color-secondary-500:                                                     #0078c4;

  --text-on-color-secondary-500:                                                   #ffffff;
  --text-on-color-secondary-500--subdued--UNSAFE:                                  #cfdbf1;
  --text-on-color-secondary-500--vivid:                                            #f8fbff;
  --text-on-color-secondary-500--vivid-subdued--UNSAFE:                            #c7d9f0;

  /* SECONDARY 600 *************************************************************** */
  --color-secondary-600:                                                     #005f9d;

  --text-on-color-secondary-600:                                                   #ffffff;
  --text-on-color-secondary-600--subdued--UNSAFE:                                  #cad4e6;
  --text-on-color-secondary-600--vivid:                                            #bbd5ff;
  --text-on-color-secondary-600--vivid-subdued--UNSAFE:                            #96b6e6;

  /* SECONDARY 700 *************************************************************** */
  --color-secondary-700:                                                     #004270;

  --text-on-color-secondary-700:                                                   #ffffff;
  --text-on-color-secondary-700--subdued:                                          #c4ccda;
  --text-on-color-secondary-700--vivid:                                            #64afff;
  --text-on-color-secondary-700--vivid-subdued--UNSAFE:                            #4d92d9;

  /* SECONDARY 800 *************************************************************** */
  --color-secondary-800:                                                     #002745;

  --text-on-color-secondary-800:                                                   #ffffff;
  --text-on-color-secondary-800--subdued:                                          #bec3cd;
  --text-on-color-secondary-800--vivid:                                            #0091ea;
  --text-on-color-secondary-800--vivid-subdued--UNSAFE:                            #0174be;

  /* SECONDARY 900 *************************************************************** */
  --color-secondary-900:                                                     #001224;

  --text-on-color-secondary-900:                                                   #ffffff;
  --text-on-color-secondary-900--subdued:                                          #b9bcc3;
  --text-on-color-secondary-900--vivid:                                            #0082d3;
  --text-on-color-secondary-900--vivid-subdued--UNSAFE:                            #0363a3;

  --color-secondary-lighter: var(--color-secondary-700);
  --color-secondary: var(--color-secondary-800);
  --color-secondary-darker: var(--color-secondary-900);
  --text-on-color-secondary-lighter: var(--text-on-color-secondary-700);
  --text-on-color-secondary-lighter--subdued--UNSAFE: var(--text-on-color-secondary-700--subdued--UNSAFE);
  --text-on-color-secondary-lighter--vivid: var(--text-on-color-secondary-700--vivid);
  --text-on-color-secondary-lighter--vivid-subdued--UNSAFE: var(--text-on-color-secondary-700--vivid-subdued--UNSAFE);

  --text-on-color-secondary: var(--text-on-color-secondary-100);
  --text-on-color-secondary--subdued--UNSAFE: var(--text-on-color-secondary-800--subdued--UNSAFE);
  --text-on-color-secondary--vivid: var(--text-on-color-secondary-800--vivid);
  --text-on-color-secondary--vivid-subdued--UNSAFE: var(--text-on-color-secondary-800--vivid-subdued--UNSAFE);
  --text-on-color-secondary-darker: var(--text-on-color-secondary-900);
  --text-on-color-secondary-darker--subdued--UNSAFE: var(--text-on-color-secondary-900--subdued--UNSAFE);
  --text-on-color-secondary-darker--vivid: var(--text-on-color-secondary-900--vivid);
  --text-on-color-secondary-darker--vivid-subdued--UNSAFE: var(--text-on-color-secondary-900--vivid-subdued--UNSAFE);




  /* TERTIARY 050 **************************************************************** */
  --color-tertiary-050:                                                      #f9f6ed;

  --text-on-color-tertiary-050:                                                    #131106;
  --text-on-color-tertiary-050--subdued:                                           #44423a;
  --text-on-color-tertiary-050--vivid:                                             #7c7300;
  --text-on-color-tertiary-050--vivid-subdued--UNSAFE:                             #9e9246;

  /* TERTIARY 100 **************************************************************** */
  --color-tertiary-100:                                                      #efe8d0;

  --text-on-color-tertiary-100:                                                    #131106;
  --text-on-color-tertiary-100--subdued:                                           #423f35;
  --text-on-color-tertiary-100--vivid:                                             #726a00;
  --text-on-color-tertiary-100--vivid-subdued--UNSAFE:                             #92873d;

  /* TERTIARY 200 **************************************************************** */
  --color-tertiary-200:                                                      #dfd5a8;

  --text-on-color-tertiary-200:                                                    #131106;
  --text-on-color-tertiary-200--subdued:                                           #3e3c2d;
  --text-on-color-tertiary-200--vivid:                                             #655e00;
  --text-on-color-tertiary-200--vivid-subdued--UNSAFE:                             #837a30;

  /* TERTIARY 300 **************************************************************** */
  --color-tertiary-300:                                                      #c4b768;

  --text-on-color-tertiary-300:                                                    #131106;
  --text-on-color-tertiary-300--subdued--UNSAFE:                                   #393620;
  --text-on-color-tertiary-300--vivid:                                             #4e4800;
  --text-on-color-tertiary-300--vivid-subdued--UNSAFE:                             #6a621b;

  /* TERTIARY 400 **************************************************************** */
  --color-tertiary-400:                                                      #a39814;

  --text-on-color-tertiary-400:                                                    #131106;
  --text-on-color-tertiary-400--subdued--UNSAFE:                                   #332f12;
  --text-on-color-tertiary-400--vivid:                                             #342f00;
  --text-on-color-tertiary-400--vivid-subdued--UNSAFE:                             #4d4706;

  /* TERTIARY 500 **************************************************************** */
  --color-tertiary-500:                                                      #7f7600;

  --text-on-color-tertiary-500:                                                    #ffffff;
  --text-on-color-tertiary-500--subdued--UNSAFE:                                   #e1dbc1;
  --text-on-color-tertiary-500--vivid:                                             #fffbea;
  --text-on-color-tertiary-500--vivid-subdued--UNSAFE:                             #e0d8b1;

  /* TERTIARY 600 **************************************************************** */
  --color-tertiary-600:                                                      #645e00;

  --text-on-color-tertiary-600:                                                    #ffffff;
  --text-on-color-tertiary-600--subdued--UNSAFE:                                   #d9d4be;
  --text-on-color-tertiary-600--vivid:                                             #e9da00;
  --text-on-color-tertiary-600--vivid-subdued--UNSAFE:                             #c6b900;

  /* TERTIARY 700 **************************************************************** */
  --color-tertiary-700:                                                      #464100;

  --text-on-color-tertiary-700:                                                    #ffffff;
  --text-on-color-tertiary-700--subdued:                                           #d0cbba;
  --text-on-color-tertiary-700--vivid:                                             #bbaf00;
  --text-on-color-tertiary-700--vivid-subdued--UNSAFE:                             #9c9200;

  /* TERTIARY 800 **************************************************************** */
  --color-tertiary-800:                                                      #2b2600;

  --text-on-color-tertiary-800:                                                    #ffffff;
  --text-on-color-tertiary-800--subdued:                                           #c6c3b9;
  --text-on-color-tertiary-800--vivid:                                             #9a9000;
  --text-on-color-tertiary-800--vivid-subdued--UNSAFE:                             #7c7305;

  /* TERTIARY 900 **************************************************************** */
  --color-tertiary-900:                                                      #141100;

  --text-on-color-tertiary-900:                                                    #ffffff;
  --text-on-color-tertiary-900--subdued:                                           #bdbcb9;
  --text-on-color-tertiary-900--vivid:                                             #867e00;
  --text-on-color-tertiary-900--vivid-subdued--UNSAFE:                             #66600a;

  --color-tertiary-lighter:                                                   var(--color-tertiary-200);
  --color-tertiary:                                                           var(--color-tertiary-300);
  --color-tertiary-darker:                                                    var(--color-tertiary-400);
  --text-on-color-tertiary-lighter:                                                 var(--text-on-color-tertiary-200);
  --text-on-color-tertiary-lighter--subdued--UNSAFE:                                var(--text-on-color-tertiary-200--subdued--UNSAFE);
  --text-on-color-tertiary-lighter--vivid:                                          var(--text-on-color-tertiary-200--vivid);
  --text-on-color-tertiary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-tertiary-200--vivid-subdued--UNSAFE);
  --text-on-color-tertiary:                                                         var(--text-on-color-tertiary-300);
  --text-on-color-tertiary--subdued--UNSAFE:                                        var(--text-on-color-tertiary-300--subdued--UNSAFE);
  --text-on-color-tertiary--vivid:                                                  var(--text-on-color-tertiary-300--vivid);
  --text-on-color-tertiary--vivid-subdued--UNSAFE:                                  var(--text-on-color-tertiary-300--vivid-subdued--UNSAFE);
  --text-on-color-tertiary-darker:                                                  var(--text-on-color-tertiary-400);
  --text-on-color-tertiary-darker--subdued--UNSAFE:                                 var(--text-on-color-tertiary-400--subdued--UNSAFE);
  --text-on-color-tertiary-darker--vivid:                                           var(--text-on-color-tertiary-400--vivid);
  --text-on-color-tertiary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-tertiary-400--vivid-subdued--UNSAFE);




  /* SUCCESS 050 ***************************************************************** */
  --color-success-050:                                                       #dfffe3;

  --text-on-color-success-050:                                                     #0a130c;
  --text-on-color-success-050--subdued:                                            #39453c;
  --text-on-color-success-050--vivid:                                              #00833d;
  --text-on-color-success-050--vivid-subdued--UNSAFE:                              #4fa164;

  /* SUCCESS 100 ***************************************************************** */
  --color-success-100:                                                       #95ffad;

  --text-on-color-success-100:                                                     #0a130c;
  --text-on-color-success-100--subdued:                                            #2b4531;
  --text-on-color-success-100--vivid:                                              #007937;
  --text-on-color-success-100--vivid-subdued--UNSAFE:                              #329953;

  /* SUCCESS 200 ***************************************************************** */
  --color-success-200:                                                       #04f477;

  --text-on-color-success-200:                                                     #0a130c;
  --text-on-color-success-200--subdued:                                            #1a4327;
  --text-on-color-success-200--vivid:                                              #006b30;
  --text-on-color-success-200--vivid-subdued--UNSAFE:                              #018b41;

  /* SUCCESS 300 ***************************************************************** */
  --color-success-300:                                                       #0ad165;

  --text-on-color-success-300:                                                     #0a130c;
  --text-on-color-success-300--subdued--UNSAFE:                                    #183c23;
  --text-on-color-success-300--vivid:                                              #005324;
  --text-on-color-success-300--vivid-subdued--UNSAFE:                              #017033;

  /* SUCCESS 400 ***************************************************************** */
  --color-success-400:                                                       #01ad52;

  --text-on-color-success-400:                                                     #0a130c;
  --text-on-color-success-400--subdued--UNSAFE:                                    #15351f;
  --text-on-color-success-400--vivid:                                              #003716;
  --text-on-color-success-400--vivid-subdued--UNSAFE:                              #005224;

  /* SUCCESS 500 ***************************************************************** */
  --color-success-500:                                                       #06863f;

  --text-on-color-success-500:                                                     #ffffff;
  --text-on-color-success-500--subdued--UNSAFE:                                    #c9e1cc;
  --text-on-color-success-500--vivid:                                              #effff1;
  --text-on-color-success-500--vivid-subdued--UNSAFE:                              #bce0c2;

  /* SUCCESS 600 ***************************************************************** */
  --color-success-600:                                                       #006b30;

  --text-on-color-success-600:                                                     #ffffff;
  --text-on-color-success-600--subdued--UNSAFE:                                    #c4d9c7;
  --text-on-color-success-600--vivid:                                              #00f678;
  --text-on-color-success-600--vivid-subdued--UNSAFE:                              #00d165;

  /* SUCCESS 700 ***************************************************************** */
  --color-success-700:                                                       #024b20;

  --text-on-color-success-700:                                                     #ffffff;
  --text-on-color-success-700--subdued:                                            #c0cfc2;
  --text-on-color-success-700--vivid:                                              #00c65f;
  --text-on-color-success-700--vivid-subdued--UNSAFE:                              #02a54e;

  /* SUCCESS 800 ***************************************************************** */
  --color-success-800:                                                       #002d10;

  --text-on-color-success-800:                                                     #ffffff;
  --text-on-color-success-800--subdued:                                            #bbc6bc;
  --text-on-color-success-800--vivid:                                              #00a34d;
  --text-on-color-success-800--vivid-subdued--UNSAFE:                              #00833d;

  /* SUCCESS 900 ***************************************************************** */
  --color-success-900:                                                       #011504;

  --text-on-color-success-900:                                                     #ffffff;
  --text-on-color-success-900--subdued:                                            #b9bdb9;
  --text-on-color-success-900--vivid:                                              #008f42;
  --text-on-color-success-900--vivid-subdued--UNSAFE:                              #0c6d33;

  --color-success-lighter:                                                   var(--color-success-400);
  --color-success:                                                           var(--color-success-500);
  --color-success-darker:                                                    var(--color-success-600);
  --text-on-color-success-lighter:                                                 var(--text-on-color-success-400);
  --text-on-color-success-lighter--subdued--UNSAFE:                                var(--text-on-color-success-400--subdued--UNSAFE);
  --text-on-color-success-lighter--vivid:                                          var(--text-on-color-success-400--vivid);
  --text-on-color-success-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-success-400--vivid-subdued--UNSAFE);
  --text-on-color-success:                                                         var(--text-on-color-success-500);
  --text-on-color-success--subdued--UNSAFE:                                        var(--text-on-color-success-500--subdued--UNSAFE);
  --text-on-color-success--vivid:                                                  var(--text-on-color-success-500--vivid);
  --text-on-color-success--vivid-subdued--UNSAFE:                                  var(--text-on-color-success-500--vivid-subdued--UNSAFE);
  --text-on-color-success-darker:                                                  var(--text-on-color-success-600);
  --text-on-color-success-darker--subdued--UNSAFE:                                 var(--text-on-color-success-600--subdued--UNSAFE);
  --text-on-color-success-darker--vivid:                                           var(--text-on-color-success-600--vivid);
  --text-on-color-success-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-success-600--vivid-subdued--UNSAFE);




  /* WARNING 050 ***************************************************************** */
  --color-warning-050:                                                       #fff6eb;

  --text-on-color-warning-050:                                                     #121106;
  --text-on-color-warning-050--subdued:                                            #43423b;
  --text-on-color-warning-050--vivid:                                              #747600;
  --text-on-color-warning-050--vivid-subdued--UNSAFE:                              #969447;

  /* WARNING 100 ***************************************************************** */
  --color-warning-100:                                                       #ffdcb1;

  --text-on-color-warning-100:                                                     #121106;
  --text-on-color-warning-100--subdued:                                            #404035;
  --text-on-color-warning-100--vivid:                                              #6a6c00;
  --text-on-color-warning-100--vivid-subdued--UNSAFE:                              #8b893d;

  /* WARNING 200 ***************************************************************** */
  --color-warning-200:                                                       #ffc581;

  --text-on-color-warning-200:                                                     #121106;
  --text-on-color-warning-200--subdued:                                            #3d3c2b;
  --text-on-color-warning-200--vivid:                                              #5e6000;
  --text-on-color-warning-200--vivid-subdued--UNSAFE:                              #7d7c2e;

  /* WARNING 300 ***************************************************************** */
  --color-warning-300:                                                       #ffaf4d;

  --text-on-color-warning-300:                                                     #121106;
  --text-on-color-warning-300--subdued--UNSAFE:                                    #37361a;
  --text-on-color-warning-300--vivid:                                              #4a4b00;
  --text-on-color-warning-300--vivid-subdued--UNSAFE:                              #656512;

  /* WARNING 400 ***************************************************************** */
  --color-warning-400:                                                       #ff8c00;

  --text-on-color-warning-400:                                                     #121106;
  --text-on-color-warning-400--subdued--UNSAFE:                                    #303011;
  --text-on-color-warning-400--vivid:                                              #313100;
  --text-on-color-warning-400--vivid-subdued--UNSAFE:                              #494a04;

  /* WARNING 500 ***************************************************************** */
  --color-warning-500:                                                       #e67e00;

  --text-on-color-warning-500:                                                     #ffffff;
  --text-on-color-warning-500--subdued--UNSAFE:                                    #dfdcc1;
  --text-on-color-warning-500--vivid:                                              #fffce2;
  --text-on-color-warning-500--vivid-subdued--UNSAFE:                              #dedaac;

  /* WARNING 600 ***************************************************************** */
  --color-warning-600:                                                       #c46c00;

  --text-on-color-warning-600:                                                     #ffffff;
  --text-on-color-warning-600--subdued--UNSAFE:                                    #d7d5be;
  --text-on-color-warning-600--vivid:                                              #dade00;
  --text-on-color-warning-600--vivid-subdued--UNSAFE:                              #b9bd00;

  /* WARNING 700 ***************************************************************** */
  --color-warning-700:                                                       #9d5600;

  --text-on-color-warning-700:                                                     #ffffff;
  --text-on-color-warning-700--subdued:                                            #ceccbb;
  --text-on-color-warning-700--vivid:                                              #afb300;
  --text-on-color-warning-700--vivid-subdued--UNSAFE:                              #929500;

  /* WARNING 800 ***************************************************************** */
  --color-warning-800:                                                       #4e2b00;

  --text-on-color-warning-800:                                                     #ffffff;
  --text-on-color-warning-800--subdued:                                            #c5c3b9;
  --text-on-color-warning-800--vivid:                                              #8f9100;
  --text-on-color-warning-800--vivid-subdued--UNSAFE:                              #737505;

  /* WARNING 900 ***************************************************************** */
  --color-warning-900:                                                       #131100;

  --text-on-color-warning-900:                                                     #ffffff;
  --text-on-color-warning-900--subdued:                                            #bdbcb9;
  --text-on-color-warning-900--vivid:                                              #7f8100;
  --text-on-color-warning-900--vivid-subdued--UNSAFE:                              #62620a;

  --color-warning-lighter:                                                   var(--color-warning-400);
  --color-warning:                                                           var(--color-warning-500);
  --color-warning-darker:                                                    var(--color-warning-600);
  --text-on-color-warning-lighter:                                                 var(--text-on-color-warning-400);
  --text-on-color-warning-lighter--subdued--UNSAFE:                                var(--text-on-color-warning-400--subdued--UNSAFE);
  --text-on-color-warning-lighter--vivid:                                          var(--text-on-color-warning-400--vivid);
  --text-on-color-warning-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-warning-400--vivid-subdued--UNSAFE);
  --text-on-color-warning:                                                         var(--text-on-color-warning-500);
  --text-on-color-warning--subdued--UNSAFE:                                        var(--text-on-color-warning-500--subdued--UNSAFE);
  --text-on-color-warning--vivid:                                                  var(--text-on-color-warning-500--vivid);
  --text-on-color-warning--vivid-subdued--UNSAFE:                                  var(--text-on-color-warning-500--vivid-subdued--UNSAFE);
  --text-on-color-warning-darker:                                                  var(--text-on-color-warning-600);
  --text-on-color-warning-darker--subdued--UNSAFE:                                 var(--text-on-color-warning-600--subdued--UNSAFE);
  --text-on-color-warning-darker--vivid:                                           var(--text-on-color-warning-600--vivid);
  --text-on-color-warning-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-warning-600--vivid-subdued--UNSAFE);




  /* DANGER 050 ****************************************************************** */
  --color-danger-050:                                                        #fff4f2;

  --text-on-color-danger-050:                                                      #190e0c;
  --text-on-color-danger-050--subdued:                                             #4a403e;
  --text-on-color-danger-050--vivid:                                               #e20028;
  --text-on-color-danger-050--vivid-subdued--UNSAFE:                               #f26055;

  /* DANGER 100 ****************************************************************** */
  --color-danger-100:                                                        #ffe2dd;

  --text-on-color-danger-100:                                                      #190e0c;
  --text-on-color-danger-100--subdued:                                             #4a3c3a;
  --text-on-color-danger-100--vivid:                                               #d30024;
  --text-on-color-danger-100--vivid-subdued--UNSAFE:                               #e5574e;

  /* DANGER 200 ****************************************************************** */
  --color-danger-200:                                                        #ffc7bf;

  --text-on-color-danger-200:                                                      #190e0c;
  --text-on-color-danger-200--subdued:                                             #493734;
  --text-on-color-danger-200--vivid:                                               #bb001f;
  --text-on-color-danger-200--vivid-subdued--UNSAFE:                               #d14a44;

  /* DANGER 300 ****************************************************************** */
  --color-danger-300:                                                        #ff9b8f;

  --text-on-color-danger-300:                                                      #190e0c;
  --text-on-color-danger-300--subdued--UNSAFE:                                     #4a2e2b;
  --text-on-color-danger-300--vivid:                                               #950016;
  --text-on-color-danger-300--vivid-subdued--UNSAFE:                               #b13532;

  /* DANGER 400 ****************************************************************** */
  --color-danger-400:                                                        #ff6057;

  --text-on-color-danger-400:                                                      #190e0c;
  --text-on-color-danger-400--subdued--UNSAFE:                                     #4b231f;
  --text-on-color-danger-400--vivid:                                               #66000a;
  --text-on-color-danger-400--vivid-subdued--UNSAFE:                               #8a1a1d;

  /* DANGER 500 ****************************************************************** */
  --color-danger-500:                                                        #e90029;

  --text-on-color-danger-500:                                                      #ffffff;
  --text-on-color-danger-500--subdued--UNSAFE:                                     #ffcdc5;
  --text-on-color-danger-500--vivid:                                               #fffaf9;
  --text-on-color-danger-500--vivid-subdued--UNSAFE:                               #ffcabf;

  /* DANGER 600 ****************************************************************** */
  --color-danger-600:                                                        #bb001f;

  --text-on-color-danger-600:                                                      #ffffff;
  --text-on-color-danger-600--subdued--UNSAFE:                                     #f9c9c2;
  --text-on-color-danger-600--vivid:                                               #ffcac2;
  --text-on-color-danger-600--vivid-subdued--UNSAFE:                               #f3a196;

  /* DANGER 700 ****************************************************************** */
  --color-danger-700:                                                        #860013;

  --text-on-color-danger-700:                                                      #ffffff;
  --text-on-color-danger-700--subdued:                                             #e9c3bd;
  --text-on-color-danger-700--vivid:                                               #ff8b7e;
  --text-on-color-danger-700--vivid-subdued--UNSAFE:                               #e16c61;

  /* DANGER 800 ****************************************************************** */
  --color-danger-800:                                                        #540002;

  --text-on-color-danger-800:                                                      #ffffff;
  --text-on-color-danger-800--subdued:                                             #d8bdb9;
  --text-on-color-danger-800--vivid:                                               #ff4645;
  --text-on-color-danger-800--vivid-subdued--UNSAFE:                               #d13434;

  /* DANGER 900 ****************************************************************** */
  --color-danger-900:                                                        #240800;

  --text-on-color-danger-900:                                                      #ffffff;
  --text-on-color-danger-900--subdued:                                             #c3bab9;
  --text-on-color-danger-900--vivid:                                               #fa002d;
  --text-on-color-danger-900--vivid-subdued--UNSAFE:                               #be1323;

  --color-danger-lighter:                                                   var(--color-danger-400);
  --color-danger:                                                           var(--color-danger-500);
  --color-danger-darker:                                                    var(--color-danger-600);
  --text-on-color-danger-lighter:                                                 var(--text-on-color-danger-400);
  --text-on-color-danger-lighter--subdued--UNSAFE:                                var(--text-on-color-danger-400--subdued--UNSAFE);
  --text-on-color-danger-lighter--vivid:                                          var(--text-on-color-danger-400--vivid);
  --text-on-color-danger-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-danger-400--vivid-subdued--UNSAFE);
  --text-on-color-danger:                                                         var(--text-on-color-danger-500);
  --text-on-color-danger--subdued--UNSAFE:                                        var(--text-on-color-danger-500--subdued--UNSAFE);
  --text-on-color-danger--vivid:                                                  var(--text-on-color-danger-500--vivid);
  --text-on-color-danger--vivid-subdued--UNSAFE:                                  var(--text-on-color-danger-500--vivid-subdued--UNSAFE);
  --text-on-color-danger-darker:                                                  var(--text-on-color-danger-600);
  --text-on-color-danger-darker--subdued--UNSAFE:                                 var(--text-on-color-danger-600--subdued--UNSAFE);
  --text-on-color-danger-darker--vivid:                                           var(--text-on-color-danger-600--vivid);
  --text-on-color-danger-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-danger-600--vivid-subdued--UNSAFE);

  --pixels-1:                                                                0.0625rem;
  --pixels-2:                                                                0.125rem;
  --pixels-3:                                                                0.1875rem;
  --pixels-4:                                                                0.25rem;
  --pixels-5:                                                                0.3125rem;
  --pixels-6:                                                                0.375rem;
  --pixels-7:                                                                0.4375rem;
  --pixels-8:                                                                0.5rem;


 /* change styles so text would be responsive using clamp(Min-value, Preferred-value, Max-value) uses calc /* @link https://utopia.fyi/type/calculator?c=320,10,1.125,1366,10,1.25,7,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
--text-size-xs:               clamp(8px, calc(0.5rem + ((1vw - 4.8px) * 0.5)), 12.5px);
--text-size-vs:           clamp(8.5px, calc(0.53125rem + ((1vw - 4.8px) * 0.5)), 13px);
--text-size-s:                         clamp(0.79rem, calc(0.76rem + 0.11vw), 0.80rem);
--text-size-sm:           clamp(9px, calc(0.5625rem + ((1vw - 4.8px) * 0.5556)), 14px);
--text-size-m:                         clamp(0.80rem, calc(0.79rem + 0.19vw), 0.86rem);
--text-size-ml:          clamp(12px, calc(0.75rem + ((1vw - 4.8px) * 0.3889)), 15.5px);
--text-size-l:                         clamp(0.84rem, calc(0.80rem + 0.40vw), 1.15rem);
--text-size-lg:          clamp(13px, calc(0.8125rem + ((1vw - 4.8px) * 0.5556)), 18px);
--text-size-xl:                        clamp(0.96rem, calc(0.82rem + 0.71vw), 1.41rem);
--text-size-xxl:                       clamp(1.08rem, calc(0.85rem + 0.98vw), 1.70rem);
--text-size-xxxl:                      clamp(1.13rem, calc(0.89rem + 1.20vw), 1.91rem);
--text-size-4xl:                       clamp(1.27rem, calc(0.93rem + 1.71vw), 2.38rem);
--text-size-5xl:                       clamp(1.43rem, calc(0.95rem + 2.38vw), 2.98rem);

  --line-height-xs:                                                          1.05;
  --line-height-s:                                                           1.125;
  --line-height-m:                                                           1.25;
  --line-height-l:                                                           1.025;
  --line-height-xl:                                                          1.015;
  --line-height-xxl:                                                         1;
  --line-height-xxxl:                                                        0.8;
  --line-height-4xl:                                                         0.7;

  --border-radius-xs:                                                        0.125rem;
  --border-radius-s:                                                         0.25rem;
  --border-radius-m:                                                         0.5rem;
  --border-radius-l:                                                         0.75rem;
  --border-radius-xl:                                                        1rem;
  --border-radius-2xl:                                                       2rem;

  --text-weight-bold:                                                        700;
  --text-weight-semibold:                                                    500;
  --text-weight-medium:                                                      500;
  --text-weight-normal:                                                      400;
  --text-weight-regular:                                                     400;
  --text-weight-light:                                                       300;

  --transition-150:                                                          150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  --transition-300:                                                          300ms cubic-bezier(0.25, 0.8, 0.25, 1);

  --border-width-thin:                                                       0.0625rem;
  --border-width-default:                                                    0.125rem;
  --focus-ring:                               0 0 0 2px var(--color-primary);
  --focus-ring-inset:                   0 0 0 2px inset var(--color-primary);
  --focus-ring-warning:                   0 0 0 2px hsla(35, 100%, 39%, 0.4);
  --focus-ring-inset-warning:       0 0 0 2px inset hsla(35, 100%, 39%, 0.4);
  --focus-ring-success:                   0 0 0 2px hsla(147, 91%, 27%, 0.4);
  --focus-ring-inset-success:      0 0 0 2px inset hsla(147, 91%, 27%, 0.4);
  
  --box-shadow-100:
  0 0 3px  rgba(0, 0, 0, 0.02),
  0 1px  3px  rgba(0, 0, 0, 0.04),
  0 1px  2px  rgba(0, 0, 0, 0.08);
  --box-shadow-100-dark:                 0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-100-focus:                0 0 0 2px var(--color-primary),    0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-200:                      0 0 6px  rgba(0, 0, 0, 0.02665),  0 3px  6px  rgba(0, 0, 0, 0.0533), 0 3px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-200-dark:                 0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-200-focus:                0 0 0 2px var(--color-primary),    0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300:                      0 0    20px rgba(0, 0, 0, 0.03165),  0 10px 20px rgba(0, 0, 0, 0.0633), 0 6px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-300-dark:                                                     0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300-focus:                0 0 0 2px var(--color-primary),    0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-400:                      0 0    28px rgba(0, 0, 0, 0.04165),  0 14px 28px rgba(0, 0, 0, 0.0833), 0 10px 10px rgba(0, 0, 0, 0.0733);
  --box-shadow-400-dark:                                                     0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-400-focus:                0 0 0 2px var(--color-primary),    0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-500:                      0 0 38px rgba(0, 0, 0, 0.05),     0 19px 38px rgba(0, 0, 0, 0.1),    0 15px 12px rgba(0, 0, 0, 0.0733);
  --box-shadow-500-dark:                 0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  --box-shadow-500-focus:                0 0 0 2px var(--color-primary),    0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  /* darker, bolder box-shadow with more layer values */
  --box-shadow-600:
  0px 0px 2.2px rgba(0, 0, 0, 0.028),
  0px 0px 5.3px rgba(0, 0, 0, 0.04),
  0px 0px 10px rgba(0, 0, 0, 0.043),
  0px 0px 17.9px rgba(0, 0, 0, 0.043),
  0px 0px 33.4px rgba(0, 0, 0, 0.047),
  0px 0px 80px rgba(0, 0, 0, 0.08)
;
--box-shadow-600-dark:
  0px 0px 2.2px rgba(0, 0, 0, 0.043),
  0px 0px 5.3px rgba(0, 0, 0, 0.06),
  0px 0px 10px rgba(0, 0, 0, 0.065),
  0px 0px 17.9px rgba(0, 0, 0, 0.065),
  0px 0px 33.4px rgba(0, 0, 0, 0.07),
  0px 0px 80px rgba(0, 0, 0, 0.12)
;
--box-shadow-600-focus:
  0px 0px 2.2px rgba(0, 0, 0, 0.061),
  0px 0px 5.3px rgba(0, 0, 0, 0.071),
  0px 0px 10px rgba(0, 0, 0, 0.074),
  0px 0px 17.9px rgba(0, 0, 0, 0.077),
  0px 0px 33.4px rgba(0, 0, 0, 0.085),
  0px 0px 80px rgba(0, 0, 0, 0.12)
;
--box-shadow-long:
  0px 2px 3px -15px rgba(0, 0, 0, 0.005),
  0px 4px 8px -15px rgba(0, 0, 0, 0.015),
  0px 9px 14px -15px rgba(0, 0, 0, 0.029),
  0px 16px 18px -15px rgba(0, 0, 0, 0.048),
  0px 20px 20px -15px rgba(0, 0, 0, 0.068),
  0px 38px 30px -15px rgba(0, 0, 0, 0.08)
;

/* shadow with thin border for sidemenu */
--box-shadow-100-border:
  0.25px 0px 0px 0px rgba(0,0, 0, 0.2),
  0 0 3px -4px rgba(0, 0, 0, 0.02),
  0 1px 3px -6px rgba(0, 0, 0, 0.04),
  0 1px 2px -7px rgba(0, 0, 0, 0.08),
  5px 1px 8px -8px rgba(0, 0, 0, 0.05)
;
/* shadow with thin border for sidemenu hover */
--box-shadow-500-shift-up:
  0.5px 0px 0px 0px rgba(0,0, 0, 0.25),
  0px -1px 5px rgba(0, 0, 0, 0.02),
  0px -5px 17px rgba(0, 0, 0, 0.05),
  0px -30px 38px rgba(0, 0, 0, 0.08),
  0px -20px 45px rgba(0, 0, 0, 0.0733),
  0px -10px 55px rgba(0, 0, 0, 0.0333)
;

/* box shadow for tooltip with subtle but more spread so default (white/base) tooltip will show up better */
--box-shadow-tooltip: 
  0px 0.8px 1.1px rgba(0, 0, 0, 0.011),
  0px 0.9px 1.1px rgba(0, 0, 0, 0.023),
  0px 1.12px 1.1px rgba(0, 0, 0, 0.038),
  0px 1.15px 1.2px rgba(0, 0, 0, 0.059),
  0px 1.2px 1.15px rgba(0, 0, 0, 0.08),
  0px 0px 10px rgba(0, 0, 0, 0.125);

/* darker, bolder inset box-shadows */
--box-inset-100: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.57);
--box-inset-200: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.68);
--box-inset-300: inset 2px 2px 16px 0px rgba(0, 0, 0, 0.69);
--box-inset-ol: inset 0 0.6875rem 0.5rem -0.625rem var(--tint-gray-200), inset 0 -0.6875rem 0.5rem -0.625rem var(--tint-gray-200);
}`
