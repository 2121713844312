/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Audit } from '../models/Deployservicev3Audit';
import { Deployservicev3DeploymentType } from '../models/Deployservicev3DeploymentType';
import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { HttpFile } from '../http/http';

export class TheDeploymentToCreate {
    /**
    * The reference ID for the deployment
    */
    'refId'?: string;
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    'type'?: Deployservicev3DeploymentType;
    'destination'?: Deployservicev3Destination;
    /**
    * The configurations for the deployment
    */
    'config'?: string;
    /**
    * The ID of the advertiser if you are creating a deployment for an advertiser.
    */
    'advertiserId'?: string;
    /**
    * The ID of the creative if you are creating a deployment for an creative.
    */
    'creativeId'?: string;
    /**
    * The ID of the campaign if you are creating a deployment for an campaign.
    */
    'campaignId'?: string;
    /**
    * The ID of the segment if you are creating a deployment for an segment.
    */
    'segmentId'?: string;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;
    'audit'?: Deployservicev3Audit;
    /**
    * The id of the step function to be used to deploy
    */
    'stepFunction'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Deployservicev3DeploymentType",
            "format": ""
        },
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "config",
            "baseName": "config",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeId",
            "baseName": "creative_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentId",
            "baseName": "segment_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "audit",
            "baseName": "audit",
            "type": "Deployservicev3Audit",
            "format": ""
        },
        {
            "name": "stepFunction",
            "baseName": "step_function",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheDeploymentToCreate.attributeTypeMap;
    }

    public constructor() {
    }
}

