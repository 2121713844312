import React, { FC, ReactNode, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ColorTokensWithLighterAndDarkerType } from '@eltoro-ui/components'
import './SideMenuItem.scss'

export const getColors = (
  on: ColorTokensWithLighterAndDarkerType,
  kind?: 'primary' | 'secondary' | 'tertiary',
) => {
  const baseColor = () => {
    // does on have a number?
    const onHasNumbers = /\d/.test(on)
    // get numbers
    const numbersOfOn = parseInt(on.replace(/\D/g, ''), 10)
    const stringOfOn = on.replace(/[0-9]/g, '')
    if (on === 'white') return 'grey-100'
    if (!onHasNumbers) {
      return `${on}-darker`
    }
    // if color is darker than 500; lighten, otherwise darken
    const lighten = numbersOfOn > 500
    const newNumber = () => {
      if (numbersOfOn === 50) {
        return 100
      }
      return numbersOfOn + (lighten ? -100 : 100)
    }
    return `${stringOfOn}${newNumber()}`
  }
  const colors = {
    color: `var(--text-on-${on}${kind ? `--${kind}` : ''})`,
    backgroundColor: `var(--color-${on})`,
  }
  const hovered = {
    color: `var(--text-on-${baseColor()}${kind ? `--${kind}` : ''})`,
    backgroundColor: `var(--color-${baseColor()})`,
  }
  return { colors, hovered }
}

interface HeaderItemCommonPropsType {
  icon?: JSX.Element
  label: ReactNode
  on?: ColorTokensWithLighterAndDarkerType
  kind?: 'primary' | 'secondary' | 'tertiary'
  childDepth?: 1 | 2 | 3
  isActive?: boolean
}

interface HeaderItemWithLinkPropsType {
  to: string
  onClick?: undefined
  disabled?: boolean
}

interface HeaderItemWithOnClickPropsType {
  to?: undefined
  onClick: () => void
  disabled?: boolean
}

interface HeaderItemWithoutClickPropsType {
  to?: undefined
  onClick?: undefined
  disabled?: boolean
}
type HeaderItemPropsType = HeaderItemCommonPropsType &
  (
    | HeaderItemWithLinkPropsType
    | HeaderItemWithOnClickPropsType
    | HeaderItemWithoutClickPropsType
  )

export const SideMenuItem: FC<HeaderItemPropsType> = ({
  icon,
  label,
  childDepth,
  isActive,
  to,
  on,
  kind,
  onClick,
  disabled,
}) => {
  const [hovering, setHovering] = useState(false)
  const style = () => {
    if (!on) return {}
    const { colors, hovered } = getColors(on, kind)
    if (isActive) return hovered
    return hovering ? hovered : colors
  }
  const body = (
    <>
      {childDepth && <div style={{ minWidth: `${childDepth}rem` }} />}
      {icon}
      <span className="SideMenuItem__label">{label}</span>
    </>
  )
  if (to) {
    return (
      <NavLink
        to={to}
        style={style()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className={`SideMenuItem ${
          childDepth ? 'SideMenuItem--is-child' : ''
        } ${isActive ? 'SideMenuItem--is-active' : ''}`}
      >
        {body}
      </NavLink>
    )
  }
  if (onClick) {
    return (
      <button
        onClick={onClick}
        type="button"
        style={style()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className={`SideMenuItem gap-1 ${
          childDepth ? 'SideMenuItem--is-child' : ''
        }`}
      >
        {body}
      </button>
    )
  }
  return (
    <div
      className={`SideMenuItem SideMenuItem--${
        disabled ? 'is-disabled' : 'is-not-clickable'
      } gap-1 ${childDepth ? 'SideMenuItem--is-child' : ''}`}
      style={style()}
    >
      {body}
    </div>
  )
}
