/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1AdType } from '../models/Creativeservicev1AdType';
import { Creativeservicev1AuditStatus } from '../models/Creativeservicev1AuditStatus';
import { Creativeservicev1Type } from '../models/Creativeservicev1Type';
import { HttpFile } from '../http/http';

/**
* Creative associated with a OrderLine
*/
export class Campaignservicev1Creative {
    /**
    * Creative ID of the creative being associated.
    */
    'id'?: string;
    'type'?: Creativeservicev1Type;
    'adType'?: Creativeservicev1AdType;
    'auditStatus'?: Creativeservicev1AuditStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Creativeservicev1Type",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Creativeservicev1AdType",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Creativeservicev1AuditStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1Creative.attributeTypeMap;
    }

    public constructor() {
    }
}

