import { useState, useEffect } from 'react'

export const useCopy = () => {
  const [copied, setCopied] = useState(false)

  const copyText = (text: string, type?: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log(`successfully copied ${type || ''}`)
        setCopied(true)
      })
      .catch((err) => console.error(`could not copy ${type || ''}`, err))
  }

  useEffect(() => {
    const resetCopied = (): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          setCopied(false)
          resolve()
        }, 4000)
      })

    if (copied) resetCopied()
  }, [copied])

  return [copyText, copied] as const
}
