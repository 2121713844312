/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrderLineViantAdelphicIDsSetAudienceID } from '../models/OrderLineViantAdelphicIDsSetAudienceID';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V1OrderLineViantAdelphicIDsSetCreativeID } from '../models/V1OrderLineViantAdelphicIDsSetCreativeID';
import { HttpFile } from '../http/http';

export class V1OrderLineViantAdelphicIDsSet {
    'requestId'?: string;
    'userId'?: string;
    'timestamp'?: Date;
    'orderLineId'?: string;
    'orgId'?: string;
    'adelphicAdvertiserId'?: string;
    'adelphicCampaignId'?: string;
    'adelphicAdOrderId'?: string;
    'adelphicAudienceIds'?: Array<OrderLineViantAdelphicIDsSetAudienceID>;
    'adelphicCreativeIds'?: Array<V1OrderLineViantAdelphicIDsSetCreativeID>;
    'status'?: V1OrderLineStatus;
    'reason'?: string;
    'adelphicAudienceLineId'?: string;
    'adelphicParentTaxonomyId'?: string;
    'adelphicAdOrderStatus'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicAdvertiserId",
            "baseName": "adelphic_advertiser_id",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicCampaignId",
            "baseName": "adelphic_campaign_id",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicAdOrderId",
            "baseName": "adelphic_ad_order_id",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicAudienceIds",
            "baseName": "adelphic_audience_ids",
            "type": "Array<OrderLineViantAdelphicIDsSetAudienceID>",
            "format": ""
        },
        {
            "name": "adelphicCreativeIds",
            "baseName": "adelphic_creative_ids",
            "type": "Array<V1OrderLineViantAdelphicIDsSetCreativeID>",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "reason",
            "baseName": "reason",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicAudienceLineId",
            "baseName": "adelphic_audience_line_id",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicParentTaxonomyId",
            "baseName": "adelphic_parent_taxonomy_id",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicAdOrderStatus",
            "baseName": "adelphic_ad_order_status",
            "type": "string",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineViantAdelphicIDsSet.attributeTypeMap;
    }

    public constructor() {
    }
}

