import { useState, SetStateAction } from 'react'
import {
  Fa,
  Modal,
  Tabs,
  Text,
  TextHeader,
  showInfoMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import {
  Creativeservicev1Creative,
  Campaignservicev1OrderLine,
} from 'next-gen-sdk'
import {
  AdTagCreate,
  CreativeUpload,
  NativeBannerCreate,
  WarningModal,
} from 'Components'
import { CreativesLibraryPage } from 'Pages'
import { useAppContext } from 'Contexts'
import './EditOLCreatives.scss'
import { ETIcon } from 'Assets'

export const EditOLCreatives: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onClose: () => void
  startingState?: 'library' | 'create'
  setCurrentOrderLine: React.Dispatch<
    SetStateAction<Campaignservicev1OrderLine>
  >
}> = ({ orderLine, onClose, startingState, setCurrentOrderLine }) => {
  const [addError, setAddError] = useState<string>()

  const { campaignServiceApi } = useAppContext()

  const filterByAdType = orderLine.political
    ? undefined
    : orderLine.creatives?.[0].adType
  // blocks adding HTML5 to political banner OLs
  const filterByType = orderLine.political
    ? orderLine.creatives?.[0].type
    : undefined

  const defaultTab = orderLine.creatives?.[0]?.type

  const handleAddCreative = (creative: Creativeservicev1Creative) => {
    if (!orderLine.id || !campaignServiceApi || !creative.id || !creative.orgId)
      return
    showInfoMessage(
      'Adding Creative...',
      'Your creative is being added to the order line',
    )
    // single creative so only use AddCreative here.
    campaignServiceApi
      .advertisingPlatformServiceAddCreative(orderLine.id, {
        creativeId: creative.id,
        orgId: creative.orgId,
      })
      .then((res) => {
        showSuccessMessage(
          'Creative Attached',
          'Your creative was attached to the order line',
        )
        setCurrentOrderLine(res)
      })
  }
  // single creative to remove use RemoveCreative.
  const handleRemoveCreative = (creativeId: string) => {
    if (!orderLine.id || !orderLine.orgId || !campaignServiceApi) return
    showInfoMessage(
      'Removing Creative...',
      'Your creative is being removed from the order line',
    )
    campaignServiceApi
      .advertisingPlatformServiceRemoveCreative(orderLine.id, {
        creativeId,
        orgId: orderLine.orgId,
      })
      .then((res) => {
        showSuccessMessage(
          'Creative Removed',
          'Your creative was removed from the order line',
        )
        setCurrentOrderLine(res)
      })
  }

  return (
    <Modal
      className="EditOLCreatives"
      offClick={onClose}
      scrollable={false}
      header={<TextHeader type={3}>Add Creatives: {orderLine.name}</TextHeader>}
    >
      <div className="EditOLCreatives__selections mb-4 mt-4 grid grid-cols-3 gap-2 px-5"></div>
      {startingState === 'library' && (
        <CreativesLibraryPage
          selectedCreatives={orderLine.creatives}
          addToCreativeSet={handleAddCreative}
          removeFromCreativeSet={handleRemoveCreative}
          filterByAdType={filterByAdType && [filterByAdType]}
          filterByType={filterByType && [filterByType]}
        />
      )}
      {startingState === 'create' && orderLine.orgId && (
        <>
          {(() => {
            if (
              filterByAdType === 'CREATIVE_ADTYPE_BANNER' ||
              filterByType === 'CREATIVE_TYPE_BANNER'
            ) {
              return (
                <Tabs
                  tabs={[
                    {
                      id: 'CREATIVE_TYPE_BANNER',
                      ...(defaultTab && {
                        defaultTab: defaultTab === 'CREATIVE_TYPE_BANNER',
                      }),
                      label: (
                        <>
                          <Fa icon="image" size={2} type="duotone" />
                          <Text>Banner</Text>
                        </>
                      ),
                      component: (
                        <CreativeUpload
                          currentOrg={orderLine.orgId}
                          onUpload={handleAddCreative}
                          uploadType="CREATIVE_TYPE_BANNER"
                        />
                      ),
                    },
                    ...(filterByAdType === 'CREATIVE_ADTYPE_BANNER' &&
                    !filterByType
                      ? [
                          {
                            id: 'CREATIVE_TYPE_HTML5',
                            ...(defaultTab && {
                              defaultTab: defaultTab === 'CREATIVE_TYPE_HTML5',
                            }),
                            label: (
                              <>
                                <ETIcon icon="html5" />
                                <Text>HTML5</Text>
                              </>
                            ),
                            component: (
                              <CreativeUpload
                                currentOrg={orderLine.orgId}
                                onUpload={handleAddCreative}
                                uploadType="CREATIVE_TYPE_HTML5"
                              />
                            ),
                          },
                        ]
                      : []),
                    {
                      id: 'CREATIVE_TYPE_AD_TAG',
                      ...(defaultTab && {
                        defaultTab: defaultTab === 'CREATIVE_TYPE_AD_TAG',
                      }),
                      label: (
                        <>
                          <ETIcon icon="ad-tag" />
                          <Text>Ad Tag</Text>
                        </>
                      ),
                      component: (
                        <AdTagCreate
                          type="banner"
                          handleSelect={handleAddCreative}
                        />
                      ),
                    },
                  ]}
                />
              )
            }
            if (filterByAdType === 'CREATIVE_ADTYPE_VIDEO') {
              return (
                <Tabs
                  tabs={[
                    {
                      id: 'CREATIVE_TYPE_VIDEO',
                      ...(defaultTab && {
                        defaultTab: defaultTab === 'CREATIVE_TYPE_VIDEO',
                      }),
                      label: (
                        <>
                          <Fa icon="video" size={2} type="duotone" />
                          <Text>Video</Text>
                        </>
                      ),
                      component: (
                        <CreativeUpload
                          currentOrg={orderLine.orgId}
                          onUpload={handleAddCreative}
                          uploadType="CREATIVE_TYPE_VIDEO"
                        />
                      ),
                    },
                    {
                      id: 'CREATIVE_TYPE_VAST_TAG',
                      ...(defaultTab && {
                        defaultTab: defaultTab === 'CREATIVE_TYPE_VAST_TAG',
                      }),
                      label: (
                        <>
                          <ETIcon icon="vast-tag" />
                          <Text>VAST Tag</Text>
                        </>
                      ),
                      component: (
                        <AdTagCreate
                          type="video"
                          handleSelect={handleAddCreative}
                        />
                      ),
                    },
                  ]}
                />
              )
            }
            if (filterByAdType === 'CREATIVE_ADTYPE_NATIVE')
              return (
                <NativeBannerCreate handleAddCreative={handleAddCreative} />
              )
            if (filterByAdType === 'CREATIVE_ADTYPE_OTT')
              return (
                <CreativeUpload
                  currentOrg={orderLine.orgId}
                  onUpload={handleAddCreative}
                  uploadType="CREATIVE_TYPE_OTT"
                />
              )
          })()}
        </>
      )}
      {addError && (
        <WarningModal onConfirm={() => setAddError(undefined)}>
          {addError}
        </WarningModal>
      )}
    </Modal>
  )
}
