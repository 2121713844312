import { FC, memo, Ref, useState } from 'react'
import classNames from 'classnames'
import { AvatarWrapper } from '../AvatarWrapper'
import './Avatar.scss'

type AvatarType = {
  size?: 's' | 'm' | 'l' | 'xl'
  rounded?: boolean
  onClick?: () => void
  hoverOverlay?: JSX.Element
  className?: string
  avatarRef?: Ref<HTMLButtonElement>
} & ({ src: string; name?: string } | { src?: string; name: string })
// Requires either src or name or both

export const Avatar: FC<AvatarType> = memo(
  ({
    src,
    name = '',
    size = 'm',
    rounded = false,
    onClick,
    hoverOverlay,
    className,
    avatarRef,
  }) => {
    const [imageHasValidURL, setImageHasValidURL] = useState(true)

    const getInitials = () => {
      return (
        name
          .match(/\b(\w)/g)
          ?.slice(0, 3)
          .map((string) => string.toUpperCase()) || ''
      )
    }

    const sizeClass =
      getInitials().length > 2
        ? `Avatar--${size}-size-long`
        : `Avatar--${size}-size`

    const wrapperClasses = classNames(
      'Avatar',
      sizeClass,
      {
        'Avatar--rounded': rounded,
        'has-image': src && imageHasValidURL,
      },
      className,
    )

    return onClick ? (
      <AvatarWrapper
        avatarRef={avatarRef}
        onClick={onClick}
        hoverOverlay={hoverOverlay}
      >
        <div className={wrapperClasses}>
          {src && imageHasValidURL ? (
            <img
              src={src}
              alt={`${name} Avatar`}
              onError={() => {
                setImageHasValidURL(false)
              }}
            />
          ) : (
            getInitials()
          )}
        </div>
      </AvatarWrapper>
    ) : (
      <div className={wrapperClasses}>
        {src && imageHasValidURL ? (
          <img
            src={src}
            alt={`${name} Avatar`}
            onError={() => {
              setImageHasValidURL(false)
            }}
          />
        ) : (
          getInitials()
        )}
      </div>
    )
  },
)
