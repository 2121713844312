export const blueTheme = `:root{
  /* base background-color */
  --base-bg:                                          var(--tint-light);
  --base-text:                                    var(--color-grey-900);
  --base-gray:                                 var(--color-primary-050);
  --base-info:                                     var(--color-primary);
  --base-info-050:                             var(--color-primary-050);
  --base-info-100:                             var(--color-primary-100);
  --base-info-200:                             var(--color-primary-200);
  --base-info-300:                             var(--color-primary-300);
  --base-info-400:                             var(--color-primary-400);
  --base-info-500:                             var(--color-primary-500);
  --base-info-600:                             var(--color-primary-600);
  --base-info-700:                             var(--color-primary-700);
  --base-info-800:                             var(--color-primary-800);
  --base-info-900:                             var(--color-primary-900);
  --highlight-bg:                              var(--color-primary-200);
  --tw-ring-color:                                 var(--color-primary);
  --primer:                                           var(--tint-light);
  
    /* font awesome duo-tone colors */
  --fa-primary-color:                         var(--color-tertiary-500);
  --fa-primary-opacity:                                               1;
  --fa-secondary-color:                        var(--color-primary-300);
  --fa-secondary-opacity:                                             1;
  
  /* tints of khaki -- El Toro gold, #c1ba9d, hsl(48,23%,69%); higher number, opaque */
  --tint-khaki:                                   hsla(48, 23%, 55%, 1);
  --tint-khaki-050:                            hsla(48, 23%, 40%, 0.05);
  --tint-khaki-100:                            hsla(48, 23%, 45%, 0.15);
  --tint-khaki-200:                            hsla(48, 23%, 50%, 0.25);
  --tint-khaki-300:                            hsla(48, 23%, 55%, 0.38);
  --tint-khaki-400:                            hsla(48, 23%, 60%, 0.48);
  --tint-khaki-500:                            hsla(48, 23%, 69%, 0.51);
  --tint-khaki-600:                            hsla(48, 23%, 58%, 0.58);
  --tint-khaki-700:                            hsla(48, 23%, 50%, 0.69);
  --tint-khaki-800:                            hsla(48, 23%, 40%, 0.87);
  --tint-khaki-900:                            hsla(48, 23%, 35%, 0.95);

  --text-on-tint-khaki: hsl(48, 22%, 90%,);
  --text-on-tint-gray: hsl(0, 0%, 90%);
  --text-on-tint-gray-700: hsl(0, 0%, 65%);
  --text-on-tint-gray-800: hsl(0, 0%, 70%);
  --text-on-tint-gray-900: hsl(0, 0%, 85%);
  --text-on-tint-dark: hsl(0, 10%, 90%);
  --text-on-tint-dark-700: hsl(0, 10%, 70%);
  --text-on-tint-dark-800: hsl(0, 10%, 80%);
  --text-on-tint-dark-900: hsl(0, 10%, 85%);
  --text-on-tint-light: hsl(0, 10%, 40%);


 /* tints of gray -- neutrals for light/dark backgrounds, higher number more opaque */ 
  --tint-gray:                                    hsla(0, 0%, 40%, 1);
  --tint-gray-050:                             hsla(0, 0%, 40%, 0.05);
  --tint-gray-100:                              hsla(0, 0%, 40%, 0.1);
  --tint-gray-200:                              hsla(0, 0%, 40%, 0.2);
  --tint-gray-300:                              hsla(0, 0%, 40%, 0.3);
  --tint-gray-400:                              hsla(0, 0%, 40%, 0.4);
  --tint-gray-500:                              hsla(0, 0%, 40%, 0.5);
  --tint-gray-600:                              hsla(0, 0%, 40%, 0.6);
  --tint-gray-700:                              hsla(0, 0%, 40%, 0.7);
  --tint-gray-800:                              hsla(0, 0%, 40%, 0.8);
  --tint-gray-900:                              hsla(0, 0%, 40%, 0.9);
  
 /* tints of whites -- used to lighten dark background, higher number more opaque */ 
  --tint-light:                                      hsl(0, 0%, 100%);
  --tint-light-050:                                        hsla(0, 0%, 100%, 0.05);
  --tint-light-100:                                         hsla(0, 0%, 100%, 0.1);
  --tint-light-200:                                         hsla(0, 0%, 100%, 0.2);
  --tint-light-300:                                         hsla(0, 0%, 100%, 0.3);
  --tint-light-400:                                         hsla(0, 0%, 100%, 0.4);
  --tint-light-500:                                         hsla(0, 0%, 100%, 0.5);
  --tint-light-600:                                         hsla(0, 0%, 100%, 0.6);
  --tint-light-700:                                         hsla(0, 0%, 100%, 0.7);
  --tint-light-800:                                         hsla(0, 0%, 100%, 0.8);
  --tint-light-900:                                         hsla(0, 0%, 100%, 0.9);

  /* tints of dark - use to lighten light background, higher number more opaque */
  --tint-dark:                                               var(--tint-dark-800);
  --tint-dark-050:                                          hsla(0, 10%, 8%, 0.05);
  --tint-dark-100:                                          hsla(0, 10%, 8%, 0.15);
  --tint-dark-200:                                           hsla(0, 10%, 8%, 0.2);
  --tint-dark-300:                                          hsla(0, 10%, 8%, 0.35);
  --tint-dark-400:                                           hsla(0, 10%, 8%, 0.4);
  --tint-dark-500:                                          hsla(0, 10%, 8%, 0.55);
  --tint-dark-600:                                           hsla(0, 10%, 8%, 0.6);
  --tint-dark-700:                                          hsla(0, 10%, 8%, 0.75);
  --tint-dark-800:                                           hsla(0, 10%, 8%, 0.8);
  --tint-dark-900:                                          hsla(0, 10%, 8%, 0.95);

 --color-white:                                                    hsl(0, 0%, 100%);
  --text-on-color-white:                                           hsla(0, 2%, 47%, 0.73);
  --text-on-color-white--subdued:                                  hsla(0, 2%, 47%, 0.60);

  --text-on-color-white--primary:                                hsla(210, 63%, 45%, 0.9);
  --text-on-color-white--primary-subdued--UNSAFE:                hsla(210, 63%, 45%, 0.5);
  --text-on-color-white--secondary:                              hsla(358, 71%, 40%, 0.9);
  --text-on-color-white--secondary-subdued--UNSAFE:              hsla(358, 71%, 40%, 0.6);
  --text-on-color-white--tertiary:                            hsla(48.3, 22.5%, 95%, 0.9);
  --text-on-color-white--tertiary-subdued--UNSAFE:            hsla(48.3, 22.5%, 95%, 0.6);
  --text-on-color-white--success:                                                  #008562;
  --text-on-color-white--success-subdued--UNSAFE:                                  #40a489;
  --text-on-color-white--warning:                                                  #947200;
  --text-on-color-white--warning-subdued--UNSAFE:                                  #af9540;
  --text-on-color-white--danger:                                                   #e9005c;
  --text-on-color-white--danger-subdued--UNSAFE:                                   #ee4085;

  /* GREY 050 ******************************************************************** */
  --color-grey-050:                                                          #f4f7f8;

  --text-on-color-grey-050:                                                        #051317;
  --text-on-color-grey-050--subdued:                                               #3b4447;

  --text-on-color-grey-050--primary:                                               #007a9f;
  --text-on-color-grey-050--primary-subdued--UNSAFE:                               #3d99b5;
  --text-on-color-grey-050--secondary:                                             #0076b7;
  --text-on-color-grey-050--secondary-subdued--UNSAFE:                             #3d96c7;
  --text-on-color-grey-050--tertiary:                                              #6e7700;
  --text-on-color-grey-050--tertiary-subdued--UNSAFE:                              #8f973e;
  --text-on-color-grey-050--success:                                               #00815f;
  --text-on-color-grey-050--success-subdued--UNSAFE:                               #3d9f85;
  --text-on-color-grey-050--warning:                                               #8d6d00;
  --text-on-color-grey-050--warning-subdued--UNSAFE:                               #a7903e;
  --text-on-color-grey-050--danger:                                                #e00058;
  --text-on-color-grey-050--danger-subdued--UNSAFE:                                #e53e80;

  /* GREY 100 ******************************************************************** */
  --color-grey-100:                                                          #e2e9ec;

  --text-on-color-grey-100:                                                        #051317;
  --text-on-color-grey-100--subdued:                                               #374145;

  --text-on-color-grey-100--primary:                                               #007193;
  --text-on-color-grey-100--primary-subdued--UNSAFE:                               #398fa9;
  --text-on-color-grey-100--secondary:                                             #006dab;
  --text-on-color-grey-100--secondary-subdued--UNSAFE:                             #398cbb;
  --text-on-color-grey-100--tertiary:                                              #656e00;
  --text-on-color-grey-100--tertiary-subdued--UNSAFE:                              #848d3b;
  --text-on-color-grey-100--success:                                               #007758;
  --text-on-color-grey-100--success-subdued--UNSAFE:                               #39947d;
  --text-on-color-grey-100--warning:                                               #836400;
  --text-on-color-grey-100--warning-subdued--UNSAFE:                               #9b853b;
  --text-on-color-grey-100--danger:                                                #ce0050;
  --text-on-color-grey-100--danger-subdued--UNSAFE:                                #d33a77;

  /* GREY 200 ******************************************************************** */
  --color-grey-200:                                                          #cdd6d9;

  --text-on-color-grey-200:                                                        #051317;
  --text-on-color-grey-200--subdued:                                               #333d41;

  --text-on-color-grey-200--primary:                                               #006483;
  --text-on-color-grey-200--primary-subdued--UNSAFE:                               #338199;
  --text-on-color-grey-200--secondary:                                             #005f95;
  --text-on-color-grey-200--secondary-subdued--UNSAFE:                             #337da6;
  --text-on-color-grey-200--tertiary:                                              #596100;
  --text-on-color-grey-200--tertiary-subdued--UNSAFE:                              #767e36;
  --text-on-color-grey-200--success:                                               #00684c;
  --text-on-color-grey-200--success-subdued--UNSAFE:                               #33846f;
  --text-on-color-grey-200--warning:                                               #725700;
  --text-on-color-grey-200--warning-subdued--UNSAFE:                               #897736;
  --text-on-color-grey-200--danger:                                                #b90047;
  --text-on-color-grey-200--danger-subdued--UNSAFE:                                #be366b;

  /* GREY 300 ******************************************************************** */
  --color-grey-300:                                                          #aeb7bb;

  --text-on-color-grey-300:                                                        #051317;
  --text-on-color-grey-300--subdued--UNSAFE:                                       #2d373b;

  --text-on-color-grey-300--primary:                                               #004d66;
  --text-on-color-grey-300--primary-subdued--UNSAFE:                               #2c687b;
  --text-on-color-grey-300--secondary:                                             #004a76;
  --text-on-color-grey-300--secondary-subdued--UNSAFE:                             #2c6587;
  --text-on-color-grey-300--tertiary:                                              #444b00;
  --text-on-color-grey-300--tertiary-subdued--UNSAFE:                              #5e662f;
  --text-on-color-grey-300--success:                                               #00513b;
  --text-on-color-grey-300--success-subdued--UNSAFE:                               #2c6b5b;
  --text-on-color-grey-300--warning:                                               #5a4400;
  --text-on-color-grey-300--warning-subdued--UNSAFE:                               #6f612f;
  --text-on-color-grey-300--danger:                                                #910036;
  --text-on-color-grey-300--danger-subdued--UNSAFE:                                #982e57;

  /* GREY 400 ******************************************************************** */
  --color-grey-400:                                                          #8d989c;

  --text-on-color-grey-400:                                                        #051317;
  --text-on-color-grey-400--subdued--UNSAFE:                                       #263134;

  --text-on-color-grey-400--primary:                                               #003345;
  --text-on-color-grey-400--primary-subdued--UNSAFE:                               #234c5b;
  --text-on-color-grey-400--secondary:                                             #003251;
  --text-on-color-grey-400--secondary-subdued--UNSAFE:                             #234c64;
  --text-on-color-grey-400--tertiary:                                              #2e3200;
  --text-on-color-grey-400--tertiary-subdued--UNSAFE:                              #464c27;
  --text-on-color-grey-400--success:                                               #003425;
  --text-on-color-grey-400--success-subdued--UNSAFE:                               #234d43;
  --text-on-color-grey-400--warning:                                               #3c2d00;
  --text-on-color-grey-400--warning-subdued--UNSAFE:                               #504827;
  --text-on-color-grey-400--danger:                                                #640023;
  --text-on-color-grey-400--danger-subdued--UNSAFE:                                #6e2641;

  /* GREY 500 ******************************************************************** */
  --color-grey-500:                                                          #6d7679;

  --text-on-color-grey-500:                                                        #ffffff;
  --text-on-color-grey-500--subdued--UNSAFE:                                       #d9dbdc;

  --text-on-color-grey-500--primary:                                               #f8fcff;
  --text-on-color-grey-500--primary-subdued--UNSAFE:                               #d5dbdd;
  --text-on-color-grey-500--secondary:                                             #fafcff;
  --text-on-color-grey-500--secondary-subdued--UNSAFE:                             #d7dbdd;
  --text-on-color-grey-500--tertiary:                                              #fffed0;
  --text-on-color-grey-500--tertiary-subdued--UNSAFE:                              #dadcba;
  --text-on-color-grey-500--success:                                               #f1fff8;
  --text-on-color-grey-500--success-subdued--UNSAFE:                               #d0ddd8;
  --text-on-color-grey-500--warning:                                               #fffbf5;
  --text-on-color-grey-500--warning-subdued--UNSAFE:                               #dadad6;
  --text-on-color-grey-500--danger:                                                #fffbfb;
  --text-on-color-grey-500--danger-subdued--UNSAFE:                                #dadada;

  /* GREY 600 ******************************************************************** */
  --color-grey-600:                                                          #545d61;

  --text-on-color-grey-600:                                                        #ffffff;
  --text-on-color-grey-600--subdued--UNSAFE:                                       #d1d4d5;

  --text-on-color-grey-600--primary:                                               #9addff;
  --text-on-color-grey-600--primary-subdued--UNSAFE:                               #89bdd7;
  --text-on-color-grey-600--secondary:                                             #b6d6ff;
  --text-on-color-grey-600--secondary-subdued--UNSAFE:                             #9db8d7;
  --text-on-color-grey-600--tertiary:                                              #d0e100;
  --text-on-color-grey-600--tertiary-subdued--UNSAFE:                              #b1c018;
  --text-on-color-grey-600--success:                                               #00f0b4;
  --text-on-color-grey-600--success-subdued--UNSAFE:                               #15cb9f;
  --text-on-color-grey-600--warning:                                               #ffcd4c;
  --text-on-color-grey-600--warning-subdued--UNSAFE:                               #d4b151;
  --text-on-color-grey-600--danger:                                                #ffc5cb;
  --text-on-color-grey-600--danger-subdued--UNSAFE:                                #d4abb0;

  /* GREY 700 ******************************************************************** */
  --color-grey-700:                                                          #394144;

  --text-on-color-grey-700:                                                        #ffffff;
  --text-on-color-grey-700--subdued:                                               #c9cccc;

  --text-on-color-grey-700--primary:                                               #00b9ee;
  --text-on-color-grey-700--primary-subdued--UNSAFE:                               #0e9bc4;
  --text-on-color-grey-700--secondary:                                             #55b1ff;
  --text-on-color-grey-700--secondary-subdued--UNSAFE:                             #4e95d0;
  --text-on-color-grey-700--tertiary:                                              #a7b500;
  --text-on-color-grey-700--tertiary-subdued--UNSAFE:                              #8b9811;
  --text-on-color-grey-700--success:                                               #00c392;
  --text-on-color-grey-700--success-subdued--UNSAFE:                               #0ea37e;
  --text-on-color-grey-700--warning:                                               #d3a400;
  --text-on-color-grey-700--warning-subdued--UNSAFE:                               #ac8b11;
  --text-on-color-grey-700--danger:                                                #ff889a;
  --text-on-color-grey-700--danger-subdued--UNSAFE:                                #cd7684;

  /* GREY 800 ******************************************************************** */
  --color-grey-800:                                                          #1f272a;

  --text-on-color-grey-800:                                                        #ffffff;
  --text-on-color-grey-800--subdued:                                               #c1c3c4;

  --text-on-color-grey-800--primary:                                               #0097c4;
  --text-on-color-grey-800--primary-subdued--UNSAFE:                               #087b9e;
  --text-on-color-grey-800--secondary:                                             #0094e5;
  --text-on-color-grey-800--secondary-subdued--UNSAFE:                             #0879b6;
  --text-on-color-grey-800--tertiary:                                              #899500;
  --text-on-color-grey-800--tertiary-subdued--UNSAFE:                              #6e7a0a;
  --text-on-color-grey-800--success:                                               #00a177;
  --text-on-color-grey-800--success-subdued--UNSAFE:                               #088364;
  --text-on-color-grey-800--warning:                                               #b08800;
  --text-on-color-grey-800--warning-subdued--UNSAFE:                               #8c700a;
  --text-on-color-grey-800--danger:                                                #ff4072;
  --text-on-color-grey-800--danger-subdued--UNSAFE:                                #c73a60;

  /* GREY 900 ******************************************************************** */
  --color-grey-900:                                                          #0a1215;

  --text-on-color-grey-900:                                                        #ffffff;
  --text-on-color-grey-900--subdued:                                               #babcbd;

  --text-on-color-grey-900--primary:                                               #0088b0;
  --text-on-color-grey-900--primary-subdued--UNSAFE:                               #036b89;
  --text-on-color-grey-900--secondary:                                             #0083cb;
  --text-on-color-grey-900--secondary-subdued--UNSAFE:                             #03679e;
  --text-on-color-grey-900--tertiary:                                              #798400;
  --text-on-color-grey-900--tertiary-subdued--UNSAFE:                              #5d6805;
  --text-on-color-grey-900--success:                                               #008d68;
  --text-on-color-grey-900--success-subdued--UNSAFE:                               #036e53;
  --text-on-color-grey-900--warning:                                               #9a7700;
  --text-on-color-grey-900--warning-subdued--UNSAFE:                               #765e05;
  --text-on-color-grey-900--danger:                                                #f70061;
  --text-on-color-grey-900--danger-subdued--UNSAFE:                                #bc054e;




  /* PRIMARY 050 ***************************************************************** */
  --color-primary-050:                                             hsl(199, 70%, 95%);

  --text-on-color-primary-050:                                                     #071217;
  --text-on-color-primary-050--subdued:                                            #3b4348;
  --text-on-color-primary-050--vivid:                                              #007a9f;
  --text-on-color-primary-050--vivid-subdued--UNSAFE:                              #5a98b6;

  /* PRIMARY 100 ***************************************************************** */
  --color-primary-100:                                             hsl(200, 70%, 90%);

  --text-on-color-primary-100:                                                     #071217;
  --text-on-color-primary-100--subdued:                                            #344148;
  --text-on-color-primary-100--vivid:                                              #007193;
  --text-on-color-primary-100--vivid-subdued--UNSAFE:                              #4b8ead;

  /* PRIMARY 200 ***************************************************************** */
  --color-primary-200:                                             hsl(200, 70%, 80%);

  --text-on-color-primary-200:                                                     #071217;
  --text-on-color-primary-200--subdued:                                            #2a3e48;
  --text-on-color-primary-200--vivid:                                              #006483;
  --text-on-color-primary-200--vivid-subdued--UNSAFE:                              #3681a1;

  /* PRIMARY 300 default ********************************************************* */
  --color-primary-300:                                             hsl(200, 70%, 70%);

  --text-on-color-primary-300:                                                     #071217;
  --text-on-color-primary-300--subdued--UNSAFE:                                    #173948;
  --text-on-color-primary-300--vivid:                                              #004d66;
  --text-on-color-primary-300--vivid-subdued--UNSAFE:                              #006989;

  /* PRIMARY 400 ***************************************************************** */
  --color-primary-400:                                             hsl(200, 70%, 60%);

  --text-on-color-primary-400:                                                     #071217;
  --text-on-color-primary-400--subdued--UNSAFE:                                    #15323f;
  --text-on-color-primary-400--vivid:                                              #003345;
  --text-on-color-primary-400--vivid-subdued--UNSAFE:                              #014d65;

  /* PRIMARY 500 ***************************************************************** */
  --color-primary-500:                                             hsl(200, 70%, 50%);

  --text-on-color-primary-500:                                                     #ffffff;
  --text-on-color-primary-500--subdued--UNSAFE:                                    #ccdde8;
  --text-on-color-primary-500--vivid:                                              #f7fcff;
  --text-on-color-primary-500--vivid-subdued--UNSAFE:                              #c6dbe8;

  /* PRIMARY 600 ***************************************************************** */
  --color-primary-600:                                             hsl(200, 70%, 40%);

  --text-on-color-primary-600:                                                     #ffffff;
  --text-on-color-primary-600--subdued--UNSAFE:                                    #c7d6df;
  --text-on-color-primary-600--vivid:                                              #9addff;
  --text-on-color-primary-600--vivid-subdued--UNSAFE:                              #79bdde;

  /* PRIMARY 700 ***************************************************************** */
  --color-primary-700:                                             hsl(199, 70%, 30%);

  --text-on-color-primary-700:                                                     #ffffff;
  --text-on-color-primary-700--subdued:                                            #c2cdd4;
  --text-on-color-primary-700--vivid:                                              #00b9ee;
  --text-on-color-primary-700--vivid-subdued--UNSAFE:                              #009bc7;

  /* PRIMARY 800 ***************************************************************** */
  --color-primary-800:                                             hsl(200, 70%, 20%);

  --text-on-color-primary-800:                                                     #ffffff;
  --text-on-color-primary-800--subdued:                                            #bcc4c9;
  --text-on-color-primary-800--vivid:                                              #0097c4;
  --text-on-color-primary-800--vivid-subdued--UNSAFE:                              #00799e;

  /* PRIMARY 900 ***************************************************************** */
  --color-primary-900:                                            hsl(200, 70%, 10%);

  --text-on-color-primary-900:                                                     #ffffff;
  --text-on-color-primary-900--subdued:                                            #b9bdbf;
  --text-on-color-primary-900--vivid:                                              #0088b0;
  --text-on-color-primary-900--vivid-subdued--UNSAFE:                              #0a6887;

  --color-primary-lighter:                                                   var(--color-primary-200);
  --color-primary:                                                           var(--color-primary-400);
  --color-primary-darker:                                                    var(--color-primary-500);
  --text-on-color-primary-lighter:                                                 var(--text-on-color-primary-200);
  --text-on-color-primary-lighter--subdued--UNSAFE:                                var(--text-on-color-primary-200--subdued--UNSAFE);
  --text-on-color-primary-lighter--vivid:                                          var(--text-on-color-primary-200--vivid);
  --text-on-color-primary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-primary-200--vivid-subdued--UNSAFE);
  --text-on-color-primary:                                                         var(--text-on-color-primary-300);
  --text-on-color-primary--subdued--UNSAFE:                                        var(--text-on-color-primary-300--subdued--UNSAFE);
  --text-on-color-primary--vivid:                                                  var(--text-on-color-primary-300--vivid);
  --text-on-color-primary--vivid-subdued--UNSAFE:                                  var(--text-on-color-primary-300--vivid-subdued--UNSAFE);
  --text-on-color-primary-darker:                                                  var(--text-on-color-primary-400);
  --text-on-color-primary-darker--subdued--UNSAFE:                                 var(--text-on-color-primary-400--subdued--UNSAFE);
  --text-on-color-primary-darker--vivid:                                           var(--text-on-color-primary-400--vivid);
  --text-on-color-primary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-primary-400--vivid-subdued--UNSAFE);


  /* SECONDARY 050 *************************************************************** */
  --color-secondary-050:                                           hsl(208, 70%, 95%);

  --text-on-color-secondary-050:                                   hsl(208, 70%, 15%);
  --text-on-color-secondary-050--subdued:                                          #3e4249;
  --text-on-color-secondary-050--vivid:                                            #0076b7;
  --text-on-color-secondary-050--vivid-subdued--UNSAFE:                            #5f94c9;

  /* SECONDARY 100 *************************************************************** */
  --color-secondary-100:                                          hsl(208, 70%, 90%);

  --text-on-color-secondary-100:                                  hsl(208, 70%, 15%);
  --text-on-color-secondary-100--subdued:                                          #394049;
  --text-on-color-secondary-100--vivid:                                            #006dab;
  --text-on-color-secondary-100--vivid-subdued--UNSAFE:                            #558ac0;

  /* SECONDARY 200 *************************************************************** */
  --color-secondary-200:                                           hsl(208, 70%, 80%);

  --text-on-color-secondary-200:                                   hsl(208, 70%, 20%);
  --text-on-color-secondary-200--subdued:                                          #333c49;
  --text-on-color-secondary-200--vivid:                                            #005f95;
  --text-on-color-secondary-200--vivid-subdued--UNSAFE:                            #447baf;

  /* SECONDARY 300 *************************************************************** */
  --color-secondary-300:                                           hsl(208, 70%, 70%);

  --text-on-color-secondary-300:                                   hsl(208, 70%, 25%);
  --text-on-color-secondary-300--subdued--UNSAFE:                                  #273649;
  --text-on-color-secondary-300--vivid:                                            #004a76;
  --text-on-color-secondary-300--vivid-subdued--UNSAFE:                            #266496;

  /* SECONDARY 400 *************************************************************** */
  --color-secondary-400:                                          hsl(208, 70%, 60%);

  --text-on-color-secondary-400:                                  hsl(208, 70%, 25%);
  --text-on-color-secondary-400--subdued--UNSAFE:                                  #1b3047;
  --text-on-color-secondary-400--vivid:                                            #003251;
  --text-on-color-secondary-400--vivid-subdued--UNSAFE:                            #004b76;

  /* SECONDARY 500 *************************************************************** */
  --color-secondary-500:                                           hsl(208, 70%, 50%);

  --text-on-color-secondary-500:                                   hsl(208, 70%, 90%);
  --text-on-color-secondary-500--subdued--UNSAFE:                                  #d0dcec;
  --text-on-color-secondary-500--vivid:                                            #fafcff;
  --text-on-color-secondary-500--vivid-subdued--UNSAFE:                            #cdd9ec;

  /* SECONDARY 600 *************************************************************** */
  --color-secondary-600:                                          hsl(208, 70%, 40%);

  --text-on-color-secondary-600:                                   hsl(208, 70%, 90%);
  --text-on-color-secondary-600--subdued--UNSAFE:                                  #c9d5e5;
  --text-on-color-secondary-600--vivid:                                            #b6d6ff;
  --text-on-color-secondary-600--vivid-subdued--UNSAFE:                            #91b7e4;

  /* SECONDARY 700 *************************************************************** */
  --color-secondary-700:                                           hsl(208, 70%, 30%);

  --text-on-color-secondary-700:                                   hsl(208, 70%,90%);
  --text-on-color-secondary-700--subdued:                                          #c3ccd8;
  --text-on-color-secondary-700--vivid:                                            #55b1ff;
  --text-on-color-secondary-700--vivid-subdued--UNSAFE:                            #4194d8;

  /* SECONDARY 800 *************************************************************** */
  --color-secondary-800:                                          hsl(208, 66%, 20%);

  --text-on-color-secondary-800:                                  hsl(208, 70%, 90%);
  --text-on-color-secondary-800--subdued:                                          #bec4cc;
  --text-on-color-secondary-800--vivid:                                            #0094e5;
  --text-on-color-secondary-800--vivid-subdued--UNSAFE:                            #0077b9;

  /* SECONDARY 900 *************************************************************** */
  --color-secondary-900:                                           hsl(208, 70%, 10%);

  --text-on-color-secondary-900:                                  hsl(208, 70%, 100%);
  --text-on-color-secondary-900--subdued:                                          #b9bcc2;
  --text-on-color-secondary-900--vivid:                                            #0083cb;
  --text-on-color-secondary-900--vivid-subdued--UNSAFE:                            #06649d;

  --color-secondary-lighter: var(--color-secondary-500);
  --color-secondary: var(--color-secondary-700);
  --color-secondary-darker: var(--color-secondary-800);
  --text-on-color-secondary-lighter: var(--text-on-color-secondary-600);
  --text-on-color-secondary-lighter--subdued--UNSAFE: var(--text-on-color-secondary-600--subdued--UNSAFE);
  --text-on-color-secondary-lighter--vivid: var(--text-on-color-secondary-600--vivid);
  --text-on-color-secondary-lighter--vivid-subdued--UNSAFE: var(--text-on-color-secondary-600--vivid-subdued--UNSAFE);

  --text-on-color-secondary: var(--text-on-color-secondary-100);
  --text-on-color-secondary--subdued--UNSAFE: var(--text-on-color-secondary-700--subdued--UNSAFE);
  --text-on-color-secondary--vivid: var(--text-on-color-secondary-700--vivid);
  --text-on-color-secondary--vivid-subdued--UNSAFE: var(--text-on-color-secondary-700--vivid-subdued--UNSAFE);
  --text-on-color-secondary-darker: var(--text-on-color-secondary-800);
  --text-on-color-secondary-darker--subdued--UNSAFE: var(--text-on-color-secondary-800--subdued--UNSAFE);
  --text-on-color-secondary-darker--vivid: var(--text-on-color-secondary-800--vivid);
  --text-on-color-secondary-darker--vivid-subdued--UNSAFE: var(--text-on-color-secondary-800--vivid-subdued--UNSAFE);




  /* TERTIARY 050 **************************************************************** */
  --color-tertiary-050:                                             hsl(0, 70%, 95%);

  --text-on-color-tertiary-050:                                                    #121107;
  --text-on-color-tertiary-050--subdued:                                           #43423b;
  --text-on-color-tertiary-050--vivid:                                             #6e7700;
  --text-on-color-tertiary-050--vivid-subdued--UNSAFE:                             #919546;

  /* TERTIARY 100 **************************************************************** */
  --color-tertiary-100:                                             hsl(0, 70%, 90%);

  --text-on-color-tertiary-100:                                                    #121107;
  --text-on-color-tertiary-100--subdued:                                           #404035;
  --text-on-color-tertiary-100--vivid:                                             #656e00;
  --text-on-color-tertiary-100--vivid-subdued--UNSAFE:                             #878b3d;

  /* TERTIARY 200 **************************************************************** */
  --color-tertiary-200:                                             hsl(0, 70%, 80%);

  --text-on-color-tertiary-200:                                                    #121107;
  --text-on-color-tertiary-200--subdued:                                           #3d3c2d;
  --text-on-color-tertiary-200--vivid:                                             #596100;
  --text-on-color-tertiary-200--vivid-subdued--UNSAFE:                             #787d31;

  /* TERTIARY 300 **************************************************************** */
  --color-tertiary-300:                                             hsl(0, 70%, 70%);

  --text-on-color-tertiary-300:                                                    #121107;
  --text-on-color-tertiary-300--subdued--UNSAFE:                                   #373624;
  --text-on-color-tertiary-300--vivid:                                             #444b00;
  --text-on-color-tertiary-300--vivid-subdued--UNSAFE:                             #606520;

  /* TERTIARY 400 **************************************************************** */
  --color-tertiary-400:                                             hsl(0, 70%, 60%);

  --text-on-color-tertiary-400:                                                    #121107;
  --text-on-color-tertiary-400--subdued--UNSAFE:                                   #30301a;
  --text-on-color-tertiary-400--vivid:                                             #2e3200;
  --text-on-color-tertiary-400--vivid-subdued--UNSAFE:                             #464a13;

  /* TERTIARY 500 **************************************************************** */
  --color-tertiary-500:                                              hsl(0, 70%, 50%);

  --text-on-color-tertiary-500:                                                    #ffffff;
  --text-on-color-tertiary-500--subdued--UNSAFE:                                   #dddcc1;
  --text-on-color-tertiary-500--vivid:                                             #ffffb6;
  --text-on-color-tertiary-500--vivid-subdued--UNSAFE:                             #dbdc8b;

  /* TERTIARY 600 **************************************************************** */
  --color-tertiary-600:                                             hsl(0, 70%, 40%);

  --text-on-color-tertiary-600:                                                    #ffffff;
  --text-on-color-tertiary-600--subdued--UNSAFE:                                   #d6d5be;
  --text-on-color-tertiary-600--vivid:                                             #d0e100;
  --text-on-color-tertiary-600--vivid-subdued--UNSAFE:                             #b1bf00;

  /* TERTIARY 700 **************************************************************** */
  --color-tertiary-700:                                             hsl(0, 70%, 30%);

  --text-on-color-tertiary-700:                                                    #ffffff;
  --text-on-color-tertiary-700--subdued:                                           #cdccbb;
  --text-on-color-tertiary-700--vivid:                                             #a7b500;
  --text-on-color-tertiary-700--vivid-subdued--UNSAFE:                             #8b9700;

  /* TERTIARY 800 **************************************************************** */
  --color-tertiary-800:                                             hsl(0, 70%, 20%);

  --text-on-color-tertiary-800:                                                    #ffffff;
  --text-on-color-tertiary-800--subdued:                                           #c4c4b9;
  --text-on-color-tertiary-800--vivid:                                             #899500;
  --text-on-color-tertiary-800--vivid-subdued--UNSAFE:                             #6e7805;

  /* TERTIARY 900 **************************************************************** */
  --color-tertiary-900:                                             hsl(0, 70%, 10%);

  --text-on-color-tertiary-900:                                                    #ffffff;
  --text-on-color-tertiary-900--subdued:                                           #bcbcb9;
  --text-on-color-tertiary-900--vivid:                                             #798400;
  --text-on-color-tertiary-900--vivid-subdued--UNSAFE:                             #5c650a;

  --color-tertiary-lighter:                                                   var(--color-tertiary-400);
  --color-tertiary:                                                           var(--color-tertiary-600);
  --color-tertiary-darker:                                                    var(--color-tertiary-700);
  --text-on-color-tertiary-lighter:                                                 var(--text-on-color-tertiary-400);
  --text-on-color-tertiary-lighter--subdued--UNSAFE:                                var(--text-on-color-tertiary-400--subdued--UNSAFE);
  --text-on-color-tertiary-lighter--vivid:                                          var(--text-on-color-tertiary-400--vivid);
  --text-on-color-tertiary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-tertiary-400--vivid-subdued--UNSAFE);
  --text-on-color-tertiary:                                                         var(--text-on-color-tertiary-500);
  --text-on-color-tertiary--subdued--UNSAFE:                                        var(--text-on-color-tertiary-500--subdued--UNSAFE);
  --text-on-color-tertiary--vivid:                                                  var(--text-on-color-tertiary-500--vivid);
  --text-on-color-tertiary--vivid-subdued--UNSAFE:                                  var(--text-on-color-tertiary-500--vivid-subdued--UNSAFE);
  --text-on-color-tertiary-darker:                                                  var(--text-on-color-tertiary-600);
  --text-on-color-tertiary-darker--subdued--UNSAFE:                                 var(--text-on-color-tertiary-600--subdued--UNSAFE);
  --text-on-color-tertiary-darker--vivid:                                           var(--text-on-color-tertiary-600--vivid);
  --text-on-color-tertiary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-tertiary-600--vivid-subdued--UNSAFE);




  /* SUCCESS 050 ***************************************************************** */
  --color-success-050:                                            hsl(150, 70%, 95%);

  --text-on-color-success-050:                                                     #07130e;
  --text-on-color-success-050--subdued:                                            #37453f;
  --text-on-color-success-050--vivid:                                              #00815f;
  --text-on-color-success-050--vivid-subdued--UNSAFE:                              #4ca080;

  /* SUCCESS 100 ***************************************************************** */
  --color-success-100:                                            hsl(150, 70%, 90%);

  --text-on-color-success-100:                                                     #07130e;
  --text-on-color-success-100--subdued:                                            #264538;
  --text-on-color-success-100--vivid:                                              #007758;
  --text-on-color-success-100--vivid-subdued--UNSAFE:                              #2a9774;

  /* SUCCESS 200 ***************************************************************** */
  --color-success-200:                                            hsl(150, 70%, 80%);

  --text-on-color-success-200:                                                     #07130e;
  --text-on-color-success-200--subdued:                                            #194333;
  --text-on-color-success-200--vivid:                                              #00684c;
  --text-on-color-success-200--vivid-subdued--UNSAFE:                              #008864;

  /* SUCCESS 300 ***************************************************************** */
  --color-success-300:                                             hsl(150, 70%, 70%);

  --text-on-color-success-300:                                                     #07130e;
  --text-on-color-success-300--subdued--UNSAFE:                                    #173c2e;
  --text-on-color-success-300--vivid:                                              #00513b;
  --text-on-color-success-300--vivid-subdued--UNSAFE:                              #006e51;

  /* SUCCESS 400 ***************************************************************** */
  --color-success-400:                                            hsl(150, 70%, 60%);

  --text-on-color-success-400:                                                     #07130e;
  --text-on-color-success-400--subdued--UNSAFE:                                    #143428;
  --text-on-color-success-400--vivid:                                              #003425;
  --text-on-color-success-400--vivid-subdued--UNSAFE:                              #004f3a;

  /* SUCCESS 500 ***************************************************************** */
  --color-success-500:                                            hsl(150, 70%, 50%);

  --text-on-color-success-500:                                                     #ffffff;
  --text-on-color-success-500--subdued--UNSAFE:                                    #c8e0d5;
  --text-on-color-success-500--vivid:                                              #f1fff8;
  --text-on-color-success-500--vivid-subdued--UNSAFE:                              #bee0d0;

  /* SUCCESS 600 ***************************************************************** */
  --color-success-600:                                            hsl(150, 70%, 40%);

  --text-on-color-success-600:                                                     #ffffff;
  --text-on-color-success-600--subdued--UNSAFE:                                    #c4d8cf;
  --text-on-color-success-600--vivid:                                              #00f0b4;
  --text-on-color-success-600--vivid-subdued--UNSAFE:                              #00cc99;

  /* SUCCESS 700 ***************************************************************** */
  --color-success-700:                                            hsl(150, 70%, 30%);

  --text-on-color-success-700:                                                     #ffffff;
  --text-on-color-success-700--subdued:                                            #bfcfc8;
  --text-on-color-success-700--vivid:                                              #00c392;
  --text-on-color-success-700--vivid-subdued--UNSAFE:                              #00a379;

  /* SUCCESS 800 ***************************************************************** */
  --color-success-800:                                            hsl(150, 70%, 20%);

  --text-on-color-success-800:                                                     #ffffff;
  --text-on-color-success-800--subdued:                                            #bac5c0;
  --text-on-color-success-800--vivid:                                              #00a177;
  --text-on-color-success-800--vivid-subdued--UNSAFE:                              #00825f;

  /* SUCCESS 900 ***************************************************************** */
  --color-success-900:                                            hsl(150, 70%, 10%);

  --text-on-color-success-900:                                                     #ffffff;
  --text-on-color-success-900--subdued:                                            #b9bdbb;
  --text-on-color-success-900--vivid:                                              #008d68;
  --text-on-color-success-900--vivid-subdued--UNSAFE:                              #0b6c50;

  --color-success-lighter:                                                   var(--color-success-300);
  --color-success:                                                           var(--color-success-500);
  --color-success-darker:                                                    var(--color-success-600);
  --text-on-color-success-lighter:                                                 var(--text-on-color-success-100);
  --text-on-color-success-lighter--subdued--UNSAFE:                                var(--text-on-color-success-100--subdued--UNSAFE);
  --text-on-color-success-lighter--vivid:                                          var(--text-on-color-success-100--vivid);
  --text-on-color-success-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-success-100--vivid-subdued--UNSAFE);
  --text-on-color-success:                                                         var(--text-on-color-success-200);
  --text-on-color-success--subdued--UNSAFE:                                        var(--text-on-color-success-200--subdued--UNSAFE);
  --text-on-color-success--vivid:                                                  var(--text-on-color-success-200--vivid);
  --text-on-color-success--vivid-subdued--UNSAFE:                                  var(--text-on-color-success-200--vivid-subdued--UNSAFE);
  --text-on-color-success-darker:                                                  var(--text-on-color-success-300);
  --text-on-color-success-darker--subdued--UNSAFE:                                 var(--text-on-color-success-300--subdued--UNSAFE);
  --text-on-color-success-darker--vivid:                                           var(--text-on-color-success-300--vivid);
  --text-on-color-success-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-success-300--vivid-subdued--UNSAFE);




  /* WARNING 050 ***************************************************************** */
  --color-warning-050:                                             hsl(35, 70%, 95%);

  --text-on-color-warning-050:                                                     #151006;
  --text-on-color-warning-050--subdued:                                            #464139;
  --text-on-color-warning-050--vivid:                                              #8d6d00;
  --text-on-color-warning-050--vivid-subdued--UNSAFE:                              #ac8d43;

  /* WARNING 100 ***************************************************************** */
  --color-warning-100:                                             hsl(35, 70%, 90%);

  --text-on-color-warning-100:                                                     #151006;
  --text-on-color-warning-100--subdued:                                            #463e30;
  --text-on-color-warning-100--vivid:                                              #836400;
  --text-on-color-warning-100--vivid-subdued--UNSAFE:                              #a28236;

  /* WARNING 200 ***************************************************************** */
  --color-warning-200:                                              hsl(35, 70%, 80%);

  --text-on-color-warning-200:                                                     #151006;
  --text-on-color-warning-200--subdued:                                            #453a23;
  --text-on-color-warning-200--vivid:                                              #725700;
  --text-on-color-warning-200--vivid-subdued--UNSAFE:                              #927321;

  /* WARNING 300 ***************************************************************** */
  --color-warning-300:                                              hsl(35, 70%, 70%);

  --text-on-color-warning-300:                                                     #151006;
  --text-on-color-warning-300--subdued--UNSAFE:                                    #423312;
  --text-on-color-warning-300--vivid:                                              #5a4400;
  --text-on-color-warning-300--vivid-subdued--UNSAFE:                              #7a5d00;

  /* WARNING 400 ***************************************************************** */
  --color-warning-400:                                             hsl(35, 70%, 60%);

  --text-on-color-warning-400:                                                     #151006;
  --text-on-color-warning-400--subdued--UNSAFE:                                    #3a2d10;
  --text-on-color-warning-400--vivid:                                              #3c2d00;
  --text-on-color-warning-400--vivid-subdued--UNSAFE:                              #594404;

  /* WARNING 500 ***************************************************************** */
  --color-warning-500:                                              hsl(35, 70%, 50%);

  --text-on-color-warning-500:                                                     #ffffff;
  --text-on-color-warning-500--subdued--UNSAFE:                                    #e7d9c1;
  --text-on-color-warning-500--vivid:                                              #fffbf5;
  --text-on-color-warning-500--vivid-subdued--UNSAFE:                              #e7d6b9;

  /* WARNING 600 ***************************************************************** */
  --color-warning-600:                                             hsl(35, 70%, 40%);

  --text-on-color-warning-600:                                                     #ffffff;
  --text-on-color-warning-600--subdued--UNSAFE:                                    #dfd2be;
  --text-on-color-warning-600--vivid:                                              #ffcd4c;
  --text-on-color-warning-600--vivid-subdued--UNSAFE:                              #daae39;

  /* WARNING 700 ***************************************************************** */
  --color-warning-700:                                              hsl(35, 70%, 30%);

  --text-on-color-warning-700:                                                     #ffffff;
  --text-on-color-warning-700--subdued:                                            #d4caba;
  --text-on-color-warning-700--vivid:                                              #d3a400;
  --text-on-color-warning-700--vivid-subdued--UNSAFE:                              #b18901;

  /* WARNING 800 ***************************************************************** */
  --color-warning-800:                                              hsl(35, 70%, 20%);

  --text-on-color-warning-800:                                                     #ffffff;
  --text-on-color-warning-800--subdued:                                            #c8c2b9;
  --text-on-color-warning-800--vivid:                                              #b08800;
  --text-on-color-warning-800--vivid-subdued--UNSAFE:                              #8e6d05;

  /* WARNING 900 ***************************************************************** */
  --color-warning-900:                                              hsl(35, 70%, 10%);

  --text-on-color-warning-900:                                                     #ffffff;
  --text-on-color-warning-900--subdued:                                            #bebcb9;
  --text-on-color-warning-900--vivid:                                              #9a7700;
  --text-on-color-warning-900--vivid-subdued--UNSAFE:                              #765b09;

  --color-warning-lighter:                                                   var(--color-warning-400);
  --color-warning:                                                           var(--color-warning-500);
  --color-warning-darker:                                                    var(--color-warning-600);
  --text-on-color-warning-lighter:                                                 var(--text-on-color-warning-200);
  --text-on-color-warning-lighter--subdued--UNSAFE:                                var(--text-on-color-warning-200--subdued--UNSAFE);
  --text-on-color-warning-lighter--vivid:                                          var(--text-on-color-warning-200--vivid);
  --text-on-color-warning-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-warning-200--vivid-subdued--UNSAFE);
  --text-on-color-warning:                                                         var(--text-on-color-warning-300);
  --text-on-color-warning--subdued--UNSAFE:                                        var(--text-on-color-warning-300--subdued--UNSAFE);
  --text-on-color-warning--vivid:                                                  var(--text-on-color-warning-300--vivid);
  --text-on-color-warning--vivid-subdued--UNSAFE:                                  var(--text-on-color-warning-300--vivid-subdued--UNSAFE);
  --text-on-color-warning-darker:                                                  var(--text-on-color-warning-400);
  --text-on-color-warning-darker--subdued--UNSAFE:                                 var(--text-on-color-warning-400--subdued--UNSAFE);
  --text-on-color-warning-darker--vivid:                                           var(--text-on-color-warning-400--vivid);
  --text-on-color-warning-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-warning-400--vivid-subdued--UNSAFE);




  /* DANGER 050 ****************************************************************** */
  --color-danger-050:                                              hsl(0, 70%, 95%);

  --text-on-color-danger-050:                                                      #190e0f;
  --text-on-color-danger-050--subdued:                                             #4a4041;
  --text-on-color-danger-050--vivid:                                               #e00058;
  --text-on-color-danger-050--vivid-subdued--UNSAFE:                               #ef607c;

  /* DANGER 100 ****************************************************************** */
  --color-danger-100:                                               hsl(0, 70%, 90%);

  --text-on-color-danger-100:                                                      #190e0f;
  --text-on-color-danger-100--subdued:                                             #4a3c3d;
  --text-on-color-danger-100--vivid:                                               #d00051;
  --text-on-color-danger-100--vivid-subdued--UNSAFE:                               #e15773;

  /* DANGER 200 ****************************************************************** */
  --color-danger-200:                                               hsl(0, 70%, 80%);

  --text-on-color-danger-200:                                                      #190e0f;
  --text-on-color-danger-200--subdued:                                             #4a3738;
  --text-on-color-danger-200--vivid:                                               #b90047;
  --text-on-color-danger-200--vivid-subdued--UNSAFE:                               #ce4a66;

  /* DANGER 300 ****************************************************************** */
  --color-danger-300:                                               hsl(0, 70%, 70%);

  --text-on-color-danger-300:                                                      #190e0f;
  --text-on-color-danger-300--subdued--UNSAFE:                                     #4a2e31;
  --text-on-color-danger-300--vivid:                                               #930037;
  --text-on-color-danger-300--vivid-subdued--UNSAFE:                               #ae3551;

  /* DANGER 400 ****************************************************************** */
  --color-danger-400:                                               hsl(0, 70%, 60%);

  --text-on-color-danger-400:                                                      #190e0f;
  --text-on-color-danger-400--subdued--UNSAFE:                                     #4a2329;
  --text-on-color-danger-400--vivid:                                               #640023;
  --text-on-color-danger-400--vivid-subdued--UNSAFE:                               #881938;

  /* DANGER 500 ****************************************************************** */
  --color-danger-500:                                               hsl(0, 70%, 50%);

  --text-on-color-danger-500:                                                      #ffffff;
  --text-on-color-danger-500--subdued--UNSAFE:                                     #ffced3;
  --text-on-color-danger-500--vivid:                                               #fffafa;
  --text-on-color-danger-500--vivid-subdued--UNSAFE:                               #ffcacc;

  /* DANGER 600 ****************************************************************** */
  --color-danger-600:                                               hsl(0, 70%, 40%);

  --text-on-color-danger-600:                                                      #ffffff;
  --text-on-color-danger-600--subdued--UNSAFE:                                     #f5c9cd;
  --text-on-color-danger-600--vivid:                                               #ffc5cb;
  --text-on-color-danger-600--vivid-subdued--UNSAFE:                               #f19da8;

  /* DANGER 700 ****************************************************************** */
  --color-danger-700:                                               hsl(0, 70%, 30%);

  --text-on-color-danger-700:                                                      #ffffff;
  --text-on-color-danger-700--subdued:                                             #e6c3c6;
  --text-on-color-danger-700--vivid:                                               #ff889a;
  --text-on-color-danger-700--vivid-subdued--UNSAFE:                               #e06a7e;

  /* DANGER 800 ****************************************************************** */
  --color-danger-800:                                               hsl(0, 70%, 20%);

  --text-on-color-danger-800:                                                      #ffffff;
  --text-on-color-danger-800--subdued:                                             #d6bdbf;
  --text-on-color-danger-800--vivid:                                               #ff4072;
  --text-on-color-danger-800--vivid-subdued--UNSAFE:                               #d12f5a;

  /* DANGER 900 ****************************************************************** */
  --color-danger-900:                                                hsl(0, 70%, 10%);

  --text-on-color-danger-900:                                                      #ffffff;
  --text-on-color-danger-900--subdued:                                             #c3babb;
  --text-on-color-danger-900--vivid:                                               #f70061;
  --text-on-color-danger-900--vivid-subdued--UNSAFE:                               #bc114b;

  --color-danger-lighter:                                                   var(--color-danger-400);
  --color-danger:                                                           var(--color-danger-500);
  --color-danger-darker:                                                    var(--color-danger-600);
  --text-on-color-danger-lighter:                                                 var(--text-on-color-danger-400);
  --text-on-color-danger-lighter--subdued--UNSAFE:                                var(--text-on-color-danger-400--subdued--UNSAFE);
  --text-on-color-danger-lighter--vivid:                                          var(--text-on-color-danger-400--vivid);
  --text-on-color-danger-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-danger-400--vivid-subdued--UNSAFE);
  --text-on-color-danger:                                                         var(--text-on-color-danger-500);
  --text-on-color-danger--subdued--UNSAFE:                                        var(--text-on-color-danger-500--subdued--UNSAFE);
  --text-on-color-danger--vivid:                                                  var(--text-on-color-danger-500--vivid);
  --text-on-color-danger--vivid-subdued--UNSAFE:                                  var(--text-on-color-danger-500--vivid-subdued--UNSAFE);
  --text-on-color-danger-darker:                                                  var(--text-on-color-danger-600);
  --text-on-color-danger-darker--subdued--UNSAFE:                                 var(--text-on-color-danger-600--subdued--UNSAFE);
  --text-on-color-danger-darker--vivid:                                           var(--text-on-color-danger-600--vivid);
  --text-on-color-danger-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-danger-600--vivid-subdued--UNSAFE);



  --pixels-1:                                                                0.0625rem;
  --pixels-2:                                                                0.125rem;
  --pixels-3:                                                                0.1875rem;
  --pixels-4:                                                                0.25rem;
  --pixels-5:                                                                0.3125rem;
  --pixels-6:                                                                0.375rem;
  --pixels-7:                                                                0.4375rem;
  --pixels-8:                                                                0.5rem;


 /* change styles so text would be responsive using clamp(Min-value, Preferred-value, Max-value) uses calc /* @link https://utopia.fyi/type/calculator?c=320,10,1.125,1366,10,1.25,7,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
--text-size-xs:               clamp(8px, calc(0.5rem + ((1vw - 4.8px) * 0.5)), 12.5px);
--text-size-vs:           clamp(8.5px, calc(0.53125rem + ((1vw - 4.8px) * 0.5)), 13px);
--text-size-s:                         clamp(0.79rem, calc(0.76rem + 0.11vw), 0.80rem);
--text-size-sm:           clamp(9px, calc(0.5625rem + ((1vw - 4.8px) * 0.5556)), 14px);
--text-size-m:                         clamp(0.80rem, calc(0.79rem + 0.19vw), 0.86rem);
--text-size-ml:          clamp(12px, calc(0.75rem + ((1vw - 4.8px) * 0.3889)), 15.5px);
--text-size-l:                         clamp(0.84rem, calc(0.80rem + 0.40vw), 1.15rem);
--text-size-lg:          clamp(13px, calc(0.8125rem + ((1vw - 4.8px) * 0.5556)), 18px);
--text-size-xl:                        clamp(0.96rem, calc(0.82rem + 0.71vw), 1.41rem);
--text-size-xxl:                       clamp(1.08rem, calc(0.85rem + 0.98vw), 1.70rem);
--text-size-xxxl:                      clamp(1.13rem, calc(0.89rem + 1.20vw), 1.91rem);
--text-size-4xl:                       clamp(1.27rem, calc(0.93rem + 1.71vw), 2.38rem);
--text-size-5xl:                       clamp(1.43rem, calc(0.95rem + 2.38vw), 2.98rem);

  --line-height-xs:                                                          1.05;
  --line-height-s:                                                           1.125;
  --line-height-m:                                                           1.25;
  --line-height-l:                                                           1.025;
  --line-height-xl:                                                          1.015;
  --line-height-xxl:                                                         1;
  --line-height-xxxl:                                                        0.8;
  --line-height-4xl:                                                         0.7;

  --border-radius-xs:                                                        0.125rem;
  --border-radius-s:                                                         0.25rem;
  --border-radius-m:                                                         0.5rem;
  --border-radius-l:                                                         0.75rem;
  --border-radius-xl:                                                        1rem;
  --border-radius-2xl:                                                       2rem;

  --text-weight-bold:                                                        700;
  --text-weight-semibold:                                                    500;
  --text-weight-medium:                                                      500;
  --text-weight-normal:                                                      400;
  --text-weight-regular:                                                     400;
  --text-weight-light:                                                       300;

  --transition-150:                                                          150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  --transition-300:                                                          300ms cubic-bezier(0.25, 0.8, 0.25, 1);

  --border-width-thin:                                                       0.0625rem;
  --border-width-default:                                                    0.125rem;
  --focus-ring:                                0 0 0 2px var(--color-primary);
  --focus-ring-inset:                    0 0 0 2px inset var(--color-primary);
  --focus-ring-warning:                    0 0 0 2px hsla(35, 100%, 39%, 0.4);
  --focus-ring-inset-warning:        0 0 0 2px inset hsla(35, 100%, 39%, 0.4);
  --focus-ring-success:                    0 0 0 2px hsla(147, 91%, 27%, 0.4);
  --focus-ring-inset-success:        0 0 0 2px inset hsla(147, 91%, 27%, 0.4);
  
  --box-shadow-100:
  0 0 3px  rgba(0, 0, 0, 0.02),
  0 1px  3px  rgba(0, 0, 0, 0.04),
  0 1px  2px  rgba(0, 0, 0, 0.08);
  --box-shadow-100-dark:                 0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-100-focus:                0 0 0 2px var(--color-primary),    0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-200:                      0 0 6px  rgba(0, 0, 0, 0.02665),  0 3px  6px  rgba(0, 0, 0, 0.0533), 0 3px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-200-dark:                 0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-200-focus:                0 0 0 2px var(--color-primary),    0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300:                      0 0    20px rgba(0, 0, 0, 0.03165),  0 10px 20px rgba(0, 0, 0, 0.0633), 0 6px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-300-dark:                                                     0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300-focus:                0 0 0 2px var(--color-primary),    0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-400:                      0 0    28px rgba(0, 0, 0, 0.04165),  0 14px 28px rgba(0, 0, 0, 0.0833), 0 10px 10px rgba(0, 0, 0, 0.0733);
  --box-shadow-400-dark:                                                     0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-400-focus:                0 0 0 2px var(--color-primary),    0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-500:                      0 0 38px rgba(0, 0, 0, 0.05),     0 19px 38px rgba(0, 0, 0, 0.1),    0 15px 12px rgba(0, 0, 0, 0.0733);
  --box-shadow-500-dark:                 0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  --box-shadow-500-focus:                0 0 0 2px var(--color-primary),    0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  /* darker, bolder box-shadow with more layer values */
  --box-shadow-600:
  0px 0px 2.2px rgba(0, 0, 0, 0.028),
  0px 0px 5.3px rgba(0, 0, 0, 0.04),
  0px 0px 10px rgba(0, 0, 0, 0.043),
  0px 0px 17.9px rgba(0, 0, 0, 0.043),
  0px 0px 33.4px rgba(0, 0, 0, 0.047),
  0px 0px 80px rgba(0, 0, 0, 0.08)
;
--box-shadow-600-dark:
  0px 0px 2.2px rgba(0, 0, 0, 0.043),
  0px 0px 5.3px rgba(0, 0, 0, 0.06),
  0px 0px 10px rgba(0, 0, 0, 0.065),
  0px 0px 17.9px rgba(0, 0, 0, 0.065),
  0px 0px 33.4px rgba(0, 0, 0, 0.07),
  0px 0px 80px rgba(0, 0, 0, 0.12)
;
--box-shadow-600-focus:
  0px 0px 2.2px rgba(0, 0, 0, 0.061),
  0px 0px 5.3px rgba(0, 0, 0, 0.071),
  0px 0px 10px rgba(0, 0, 0, 0.074),
  0px 0px 17.9px rgba(0, 0, 0, 0.077),
  0px 0px 33.4px rgba(0, 0, 0, 0.085),
  0px 0px 80px rgba(0, 0, 0, 0.12)
;
--box-shadow-long:
  0px 2px 3px -15px rgba(0, 0, 0, 0.005),
  0px 4px 8px -15px rgba(0, 0, 0, 0.015),
  0px 9px 14px -15px rgba(0, 0, 0, 0.029),
  0px 16px 18px -15px rgba(0, 0, 0, 0.048),
  0px 20px 20px -15px rgba(0, 0, 0, 0.068),
  0px 38px 30px -15px rgba(0, 0, 0, 0.08)
;

/* shadow with thin border for sidemenu */
--box-shadow-100-border:
  0.25px 0px 0px 0px rgba(0,0, 0, 0.2),
  0 0 3px -4px rgba(0, 0, 0, 0.02),
  0 1px 3px -6px rgba(0, 0, 0, 0.04),
  0 1px 2px -7px rgba(0, 0, 0, 0.08),
  5px 1px 8px -8px rgba(0, 0, 0, 0.05)
;
/* shadow with thin border for sidemenu hover */
--box-shadow-500-shift-up:
  0.5px 0px 0px 0px rgba(0,0, 0, 0.25),
  0px -1px 5px rgba(0, 0, 0, 0.02),
  0px -5px 17px rgba(0, 0, 0, 0.05),
  0px -30px 38px rgba(0, 0, 0, 0.08),
  0px -20px 45px rgba(0, 0, 0, 0.0733),
  0px -10px 55px rgba(0, 0, 0, 0.0333)
;

/* box shadow for tooltip with subtle but more spread so default (white/base) tooltip will show up better */
--box-shadow-tooltip: 
  0px 0.8px 1.1px rgba(0, 0, 0, 0.011),
  0px 0.9px 1.1px rgba(0, 0, 0, 0.023),
  0px 1.12px 1.1px rgba(0, 0, 0, 0.038),
  0px 1.15px 1.2px rgba(0, 0, 0, 0.059),
  0px 1.2px 1.15px rgba(0, 0, 0, 0.08),
  0px 0px 10px rgba(0, 0, 0, 0.125);

/* darker, bolder inset box-shadows */
--box-inset-100: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.57);
--box-inset-200: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.68);
--box-inset-300: inset 2px 2px 16px 0px rgba(0, 0, 0, 0.69);
--box-inset-ol: inset 0 0.6875rem 0.5rem -0.625rem var(--tint-gray-200), inset 0 -0.6875rem 0.5rem -0.625rem var(--tint-gray-200);
}`
