import classnames from 'classnames'
import { FC } from 'react'
import './FA.scss'

const getSize = (size: number | 'xs' | 'sm' | 'lg') => {
  if (typeof size === 'string') {
    return `fa-${size}`
  }
  if (size) return `fa-${size}x`
  return null
}

type IconProps = {
  icon: string
  className?: string
  inverse?: boolean
  // lg 1x-5x (or 1-5)
  size: number | 'xs' | 'sm' | 'lg'
  color?: string
  onClick?: () => void
  ariaLabel?: string
  type?: 'branded' | 'duotone' | 'light' | 'solid'
  animate?: 'beat' | 'fade' | 'beat-fade' | 'bounce' | 'flip' | 'shake' | 'spin'
}

export const Fa: FC<IconProps> = ({
  size,
  icon,
  inverse,
  color,
  onClick,
  ariaLabel,
  className,
  type = 'solid',
  animate,
}) => {
  if (!icon) return null
  const getAnimation = (aniType: string) => {
    switch (aniType) {
      case 'beat':
        return 'fa-beat'
      case 'fade':
        return 'fa-fade'
      case 'beat-fade':
        return 'fa-beat-fade'
      case 'bounce':
        return 'fa-bounce'
      case 'flip':
        return 'fa-flip'
      case 'shake':
        return 'fa-shake'
      case 'spin':
        return 'fa-spin'
      default:
        return ''
    }
  }

  if (onClick) {
    return (
      <i
        className={classnames(
          icon.indexOf('fa-') === -1 ? `fa fa-${icon}` : icon,
          { 'fa-inverse': inverse },
          { 'fa-brands': type === 'branded' },
          { 'fa-duotone': type === 'duotone' },
          { 'fa-light': type === 'light' },
          { 'fa-solid': type === 'solid' },
          getSize(size),
          animate && getAnimation(animate),
          className,
        )}
        style={{ color: `${color}` }}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
          if (e.key === 'Enter' || e.key === 'Space') onClick()
        }}
        role="button"
        aria-label={ariaLabel || 'Icon button'}
        tabIndex={0}
      />
    )
  }

  return (
    <i
      className={classnames(
        icon.indexOf('fa-') === -1 ? `fa fa-${icon}` : icon,
        { 'fa-inverse': inverse },
        { 'fa-brands': type === 'branded' },
        { 'fa-duotone': type === 'duotone' },
        { 'fa-light': type === 'light' },
        { 'fa-solid': type === 'solid' },
        getSize(size),
        animate && getAnimation(animate),
        className,
      )}
      style={{ color: `${color}` }}
    />
  )
}
export default Fa
