import { FC } from 'react'
import { Fa } from '@eltoro-ui/components'
import { V1InvoiceStatus } from 'next-gen-sdk'
import classNames from 'classnames'
import { EmptyField } from 'Components'
import { simplifyEnum } from 'Helpers'
import './InvoiceStatusIndicator.scss'

const getStatusIcon = (status: V1InvoiceStatus) => {
  const iconName = () => {
    switch (status) {
      case 'INVOICE_STATUS_PAID':
        return 'file-invoice-dollar'
      case 'INVOICE_STATUS_PROCESSING':
        return 'credit-card'
      case 'INVOICE_STATUS_DECLINED':
        return 'times-circle'
      case 'INVOICE_STATUS_PAYMENT_DUE':
        return 'file-exclamation'
      case 'INVOICE_STATUS_PARTIALLY_PAID':
        return 'circle-half-stroke'
      case 'INVOICE_STATUS_PAST_DUE':
        return 'calendar-exclamation'
      default:
        return 'exclamation-triangle'
    }
  }

  const baseClassName = 'InvoiceStatusIndicator__icon'

  return (
    <Fa
      className={classNames(baseClassName, {
        [`${baseClassName}--yellow`]: [
          'INVOICE_STATUS_PAYMENT_DUE',
          'INVOICE_STATUS_PARTIALLY_PAID',
          'INIVOICE_STATUS_PAST_DUE',
        ].includes(status),
        [`${baseClassName}--blue`]: status === 'INVOICE_STATUS_PROCESSING',
        [`${baseClassName}--green`]: status === 'INVOICE_STATUS_PAID',
        [`${baseClassName}--red`]: status === 'INVOICE_STATUS_DECLINED',
      })}
      icon={iconName()}
      size={1}
    />
  )
}

export const InvoiceStatusIndicator: FC<{
  status: V1InvoiceStatus
}> = ({ status }) => {
  return (
    <div className="InvoiceStatusIndicator">
      {/* TODO: https://eltorocorp.atlassian.net/browse/ASG-88 */}
      {status && getStatusIcon(status)}
      {simplifyEnum(2, status, true) || <EmptyField />}
    </div>
  )
}
