import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { AutoCompleteInput, Button, Fa } from '..'
import './TagInput.scss'
import '../../Assets/css/all.css'

type TagInputType = {
  onAddTag: (tags: string[]) => void
  onRemoveTag?: () => void
  id?: string
  maxWidth?: string
  validationPattern?: string
  wildCardValidationPattern?: string
  addTagsInBulk?: boolean
  value?: string[] // Allows passing in a value (to use as a controlled input)
  autoCompleteSuggestionsList?: string[]
  validateFromSuggestions?: boolean
  maxAutoCompleteSuggestions?: number
  tagErrorLabel?: string
  limit?: number
}

export const TagInput: React.FC<TagInputType> = ({
  id,
  maxWidth,
  onAddTag,
  onRemoveTag,
  validationPattern,
  wildCardValidationPattern,
  addTagsInBulk,
  value,
  autoCompleteSuggestionsList,
  maxAutoCompleteSuggestions,
  validateFromSuggestions,
  tagErrorLabel = 'input',
  limit,
}) => {
  const [tags, setTags] = useState<string[]>(value || [])
  const [currentInput, setCurrentInput] = useState<string>('')
  const [badInputs, setBadInputs] = useState<string[]>([])
  const [emptySubmit, setEmptySubmit] = useState(false)
  const hasBadInputs = !!badInputs.length
  const validationRegEx = validationPattern && new RegExp(validationPattern)
  const wildCardRegEx =
    wildCardValidationPattern && new RegExp(wildCardValidationPattern)
  const checkAgainstSuggestions = (input: string) =>
    autoCompleteSuggestionsList?.some(
      (suggestion: string) => input === suggestion,
    )

  const addTags = (values: string[]) => {
    const newArr = Array.from(new Set([...tags, ...values])).filter((n) => n)
    setTags(newArr)
  }

  const removeTags = (value: string) => {
    if (onRemoveTag) onRemoveTag()
    if (badInputs) setBadInputs([])
    if (emptySubmit) setEmptySubmit(false)
    setTags(tags.filter((tag) => tag !== value))
  }

  const checkInput = (input: string) => {
    if (input.trim() === '') {
      setEmptySubmit(true)
      setBadInputs([])
      return ''
    }
    const badInputMessage = `${input} is not a valid ${tagErrorLabel}`
    if (wildCardRegEx && wildCardRegEx.test(input)) {
      if (autoCompleteSuggestionsList) {
        const cleanedInput = input.replace(/\*{1,2}/, '')
        if (
          autoCompleteSuggestionsList.filter(
            (suggestion: string) =>
              cleanedInput.toLowerCase() ===
              suggestion.toLowerCase().slice(0, cleanedInput.length),
          ).length === 0 &&
          tagErrorLabel === 'zip code'
        ) {
          return setBadInputs([...badInputs, badInputMessage])
        }
        return autoCompleteSuggestionsList.filter(
          (suggestion: string) =>
            cleanedInput.toLowerCase() ===
            suggestion.toLowerCase().slice(0, cleanedInput.length),
        )
      }
      return input
    }
    if (validationRegEx && validationRegEx.test(input)) {
      if (!!autoCompleteSuggestionsList && validateFromSuggestions) {
        if (checkAgainstSuggestions(input)) {
          return input
        }
        setBadInputs([...badInputs, badInputMessage])
        return ''
      }
      return input
    }
    setBadInputs([...badInputs, badInputMessage])
    return ''
  }

  const checkBulkInputs = (inputArray: string[]) => {
    return inputArray.reduce(
      (
        acc: { validInputs: string[]; badInputs: string[] },
        currentInput: string,
      ) => {
        if (currentInput.trim() === '') return acc
        const badInputMessage = `${currentInput} is not a valid ${tagErrorLabel}`
        if (wildCardRegEx && wildCardRegEx.test(currentInput)) {
          if (autoCompleteSuggestionsList) {
            const cleanedInput = currentInput.replace(/\*{1,2}/, '')
            const wildCardInputs = autoCompleteSuggestionsList.filter(
              (suggestion: string) =>
                cleanedInput.toLowerCase() ===
                suggestion.toLowerCase().slice(0, cleanedInput.length),
            )
            return {
              validInputs: [...acc.validInputs, ...wildCardInputs],
              badInputs: acc.badInputs,
            }
          }
          return {
            validInputs: [...acc.validInputs, currentInput],
            badInputs: acc.badInputs,
          }
        }
        if (validationRegEx && validationRegEx?.test(currentInput)) {
          if (!!autoCompleteSuggestionsList && validateFromSuggestions) {
            if (checkAgainstSuggestions(currentInput)) {
              return {
                validInputs: [...acc.validInputs, currentInput],
                badInputs: acc.badInputs,
              }
            }
            return {
              validInputs: acc.validInputs,
              badInputs: [...acc.badInputs, badInputMessage],
            }
          }
          return {
            validInputs: [...acc.validInputs, currentInput],
            badInputs: acc.badInputs,
          }
        }
        return {
          validInputs: acc.validInputs,
          badInputs: [...acc.badInputs, badInputMessage],
        }
      },
      { validInputs: [], badInputs: [] },
    )
  }

  useEffect(() => {
    onAddTag(tags)
  }, [tags])

  useEffect(() => {
    if (value) setTags(value)
  }, [value])

  return (
    <div className="TagInput__wrapper">
      {limit && (
        <div className="TagInput__limit-count py-1 flex flex-row-reverse">
          {tags.length}/{limit}
        </div>
      )}
      <div
        className={classNames('TagInput', {
          'TagInput--alert': hasBadInputs,
          'TagInput--warning': emptySubmit,
        })}
        style={{ maxWidth }}
      >
        <ul className="TagInput__tags max-h-64 overflow-y-auto mb-2">
          {tags.map((tag) => (
            <li key={tag} className="TagInput__tags--tag">
              <Button
                size="s"
                onClick={() => removeTags(tag)}
                iconRight={<Fa className="scale-90" icon="times" size={1} />}
              >
                {tag}
              </Button>
            </li>
          ))}
        </ul>
        <div className="TagInput__input-clear-button-wrapper flex justify-between gap-2">
          <div className="TagInput__auto-complete-wrapper w-full">
            <AutoCompleteInput
              id={id}
              value={currentInput}
              suggestions={autoCompleteSuggestionsList}
              maxSuggestions={maxAutoCompleteSuggestions}
              onSuggestionSelect={(selectedValue: string) => {
                if (badInputs) {
                  setBadInputs([])
                }
                if (emptySubmit) setEmptySubmit(false)
                setCurrentInput('')
                if (limit && tags.length + 1 > limit) {
                  setBadInputs([
                    `more than ${limit} ${tagErrorLabel}s are not allowed`,
                  ])
                  return
                }
                addTags([selectedValue])
              }}
              onChange={(e?: React.ChangeEvent<HTMLInputElement>) => {
                if (badInputs) {
                  setBadInputs([])
                }
                if (emptySubmit) setEmptySubmit(false)
                setCurrentInput(e?.target?.value || '')
              }}
              onInputSelect={(e?: React.KeyboardEvent<HTMLInputElement>) => {
                setCurrentInput('')
                const commaBulkInput = currentInput
                  .trim()
                  .split(',')
                  .map((tag: string) => tag.trim())
                const spaceBulkInput = currentInput
                  .trim()
                  .split(' ')
                  .map((tag: string) => tag.trim())
                if (e?.key === 'Enter') {
                  if (commaBulkInput.length > 1) {
                    if (addTagsInBulk) {
                      const sortedInputs = checkBulkInputs(commaBulkInput)
                      e?.preventDefault()
                      if (limit && commaBulkInput.length > limit) {
                        setBadInputs([
                          `more than ${limit} ${tagErrorLabel}s are not allowed`,
                        ])
                        return
                      }
                      addTags(sortedInputs.validInputs)
                      setBadInputs(sortedInputs.badInputs)
                      if (!tags.includes(currentInput)) setCurrentInput('')
                    } else {
                      setBadInputs([
                        `multiple comma separated ${tagErrorLabel}s are not valid`,
                      ])
                    }
                  } else if (spaceBulkInput.length > 1) {
                    if (addTagsInBulk) {
                      const sortedInputs = checkBulkInputs(spaceBulkInput)
                      e?.preventDefault()
                      if (limit && spaceBulkInput.length > limit) {
                        setBadInputs([
                          `more than ${limit} ${tagErrorLabel}s are not allowed`,
                        ])
                        return
                      }
                      addTags(sortedInputs.validInputs)
                      setBadInputs(sortedInputs.badInputs)
                      if (!tags.includes(currentInput)) setCurrentInput('')
                    } else {
                      setBadInputs([
                        `multiple space separated ${tagErrorLabel}s are not valid`,
                      ])
                    }
                  } else if (validationPattern) {
                    e?.preventDefault()
                    const validInput = checkInput(currentInput)
                    if (validInput) {
                      if (Array.isArray(validInput)) {
                        if (limit && tags.length + validInput.length > limit) {
                          setBadInputs([
                            `more than ${limit} ${tagErrorLabel}s are not allowed`,
                          ])
                          return
                        }
                        addTags(validInput)
                      } else {
                        if (limit && tags.length + 1 > limit) {
                          setBadInputs([
                            `more than ${limit} ${tagErrorLabel}s are not allowed`,
                          ])
                          return
                        }
                        addTags([validInput])
                        if (!tags.includes(validInput)) setCurrentInput('')
                      }
                    }
                  } else {
                    e?.preventDefault()
                    addTags([currentInput])
                    if (!tags.includes(currentInput)) setCurrentInput('')
                  }
                }
              }}
            />
            {hasBadInputs && (
              <div className="TagInput__errors--message px-2 py-1">
                {badInputs.map((message: string, index: number) => {
                  return (
                    <div key={index} className="px-2">
                      {message}
                    </div>
                  )
                })}
              </div>
            )}
            {emptySubmit && (
              <div className="TagInput__errors--message px-2 py-1">
                <div className="px-2">Please enter a {tagErrorLabel}</div>
              </div>
            )}
          </div>
          {tags.length > 0 && (
            <Button
              onClick={() => {
                setEmptySubmit(false)
                setBadInputs([])
                setTags([])
              }}
            >
              Clear All
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
