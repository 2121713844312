/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3BuyerSeat } from '../models/V3BuyerSeat';
import { V3DealType } from '../models/V3DealType';
import { HttpFile } from '../http/http';

/**
* The Deal Service lets buyers, sellers, and external bidders set up and manage negotiated deals. [deals](https://docs.xandr.com/bundle/xandr-api/page/deal-service.html)
*/
export class V3Deal {
    /**
    * The id of the deal [deals](https://docs.xandr.com/bundle/xandr-api/page/deal-service.html).
    */
    'id'?: number;
    /**
    * The code of the deal.
    */
    'code'?: string;
    /**
    * The name of the deal.
    */
    'name'?: string;
    /**
    * The version of the deal.
    */
    'version'?: number;
    /**
    * If true, the deal is active.
    */
    'active'?: boolean;
    /**
    * The day and time when the deal starts being available to the buyer.
    */
    'startDate'?: string;
    /**
    * The day and time when the deal stops being available to the buyer.
    */
    'endDate'?: string;
    /**
    * The minimum CPM value that the buyer must bid to be eligible for the deal.
    */
    'floorPrice'?: number;
    /**
    * The currency for the floor_price
    */
    'currency'?: string;
    /**
    * If true, the floor_price is applied for the deal.
    */
    'useDealFloor'?: boolean;
    'type'?: V3DealType;
    /**
    * The buying bidder and seat who can target this deal. 
    */
    'buyerSeats'?: Array<V3BuyerSeat>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "version",
            "baseName": "version",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "string",
            "format": ""
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "string",
            "format": ""
        },
        {
            "name": "floorPrice",
            "baseName": "floor_price",
            "type": "number",
            "format": "float"
        },
        {
            "name": "currency",
            "baseName": "currency",
            "type": "string",
            "format": ""
        },
        {
            "name": "useDealFloor",
            "baseName": "use_deal_floor",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V3DealType",
            "format": ""
        },
        {
            "name": "buyerSeats",
            "baseName": "buyer_seats",
            "type": "Array<V3BuyerSeat>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3Deal.attributeTypeMap;
    }

    public constructor() {
    }
}

