import React from 'react'
import { Button, Spacer, Fa } from '@eltoro-ui/components'
import './BlockOverlaySettingsMenu.scss'

export const BlockOverlaySettingsMenu: React.FC<{
  onHide: () => void
  onLock: () => void
}> = ({ onHide, onLock }) => {
  return (
    <div className="BlockOverlaySettingsMenu">
      <Button
        onClick={onHide}
        iconLeft={<Fa size={1} icon="eye-slash" />}
        kind="text"
      />
      <Spacer height="0.5rem" />
      <Button
        onClick={onLock}
        iconLeft={<Fa size={1} icon="lock" />}
        kind="text"
      />
    </div>
  )
}
