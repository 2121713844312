/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1Note } from '../models/Campaignservicev1Note';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V1State } from '../models/V1State';
import { HttpFile } from '../http/http';

/**
* The event emitted when an Ad Ops has rejected an Order Line submitted for review
*/
export class V1OrderLineRejected {
    'id'?: string;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;
    'note'?: Campaignservicev1Note;
    'state'?: V1State;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "note",
            "baseName": "note",
            "type": "Campaignservicev1Note",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "V1State",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineRejected.attributeTypeMap;
    }

    public constructor() {
    }
}

