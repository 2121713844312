import React from 'react'
import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'

export type PaceStatus = 'under' | 'over' | 'on' | 'not started' | 'paused'

const getPaceIcon = (status: PaceStatus) => {
  const iconName = () => {
    switch (status) {
      case 'under':
        return 'tachometer-slowest'
      case 'over':
        return 'tachometer-fastest'
      case 'on':
        return 'tachometer-average'
      case 'paused':
        return 'pause-circle'
      // not started
      default:
        return 'hourglass-start'
    }
  }

  const baseClassName = 'OrderlineIndicator__icon'

  return (
    <Fa
      className={classNames(baseClassName, {
        [`${baseClassName}--orange`]: ['under', 'over', 'paused'].includes(
          status,
        ),
        [`${baseClassName}--green`]: status === 'on',
      })}
      icon={iconName()}
      size={1}
    />
  )
}

export const OrderlinePaceIndicator = ({
  className = '',
  pace,
}: {
  className?: string
  pace: PaceStatus
}) => {
  return (
    <div
      className={`OrderlinePaceIndicator flex h-5 items-center ${className}`}
    >
      {getPaceIcon(pace)}
      {`${pace[0]?.toUpperCase()}${pace.substring(1)} ${
        pace === 'on' || pace === 'over' || pace === 'under' ? 'Pace' : ''
      }`}
    </div>
  )
}
