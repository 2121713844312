import React from 'react'
import { useRecoilValue } from 'recoil'
import {
  isDraggingLayerAtom,
  selectedBlockIdsAtom,
  showBlockOverlaysSelector,
} from 'State'
import { BlockOverlay } from './components'

export const BlockOverlays = () => {
  const ids = useRecoilValue(selectedBlockIdsAtom)
  const showOverlays = useRecoilValue(showBlockOverlaysSelector)
  const hideOverlaysOnLayerDrag = useRecoilValue(isDraggingLayerAtom)
  if (hideOverlaysOnLayerDrag || !showOverlays) return null
  return (
    <div className="BlockOverlays">
      {ids.map((id) => (
        <BlockOverlay key={id} id={id} />
      ))}
    </div>
  )
}
