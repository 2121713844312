/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1Status } from '../models/Campaignservicev1Status';
import { V1CampaignOrderLine } from '../models/V1CampaignOrderLine';
import { V1PoliticalTransparency } from '../models/V1PoliticalTransparency';
import { HttpFile } from '../http/http';

/**
* Information around a campaign
*/
export class Campaignservicev1Campaign {
    /**
    * ID of the Campaign
    */
    'id'?: string;
    /**
    * Name of the Campaign
    */
    'name'?: string;
    /**
    * Id of the org to which this campaign belongs
    */
    'orgId'?: string;
    /**
    * Time Campaign was created
    */
    'createTime'?: Date;
    /**
    * Time Campaign was updated
    */
    'updateTime'?: Date;
    /**
    * Time Campaign was deleted
    */
    'deleteTime'?: Date;
    /**
    * The status of the campaign
    */
    'archiveTime'?: Date;
    /**
    * Date the Campaign should start
    */
    'startTime'?: Date;
    /**
    * Date the Campaign should end
    */
    'endTime'?: Date;
    'status'?: Campaignservicev1Status;
    /**
    * OrderLines on the campaign
    */
    'orderLines'?: Array<V1CampaignOrderLine>;
    /**
    * Client reference ID
    */
    'refId'?: string;
    'politicalTransparency'?: V1PoliticalTransparency;
    'jobId'?: string;
    'poId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endTime",
            "baseName": "end_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Campaignservicev1Status",
            "format": ""
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<V1CampaignOrderLine>",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": "string"
        },
        {
            "name": "politicalTransparency",
            "baseName": "political_transparency",
            "type": "V1PoliticalTransparency",
            "format": ""
        },
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "poId",
            "baseName": "po_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1Campaign.attributeTypeMap;
    }

    public constructor() {
    }
}

