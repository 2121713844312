import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import { createAndGenerateAudience, uploadTargetFile } from 'Requests'
import { TargetsWithColumnsType } from 'Components'
import { ApiFactory } from 'apiFactory'
import { getTargetFileType } from 'Helpers'

export const handleUploadTarget = async (
  uploadingFile: TargetsWithColumnsType,
  audienceType: string,
  jobType: string,
  orgId: string,
  onUpload: (
    uploadedTarget: V2Target,
    uploadedJob: Targetjobservicev1Audience,
  ) => void,
  onError: (err?: any) => void,
  onProgress: (percent: number, currentStatus?: string) => void,
  token: string,
  locked?: boolean,
  hidden?: boolean,
) => {
  const apis = await ApiFactory(token)
  const { audienceServiceAPI } = apis
  if (!audienceServiceAPI) return

  const getJobTypeEnum = () => {
    if (jobType === 'b2b') return 'AUDIENCE_TYPE_B2B'
    if (jobType === 'b2c') return 'AUDIENCE_TYPE_B2C'
    if (jobType === 'ipForMailing') return 'AUDIENCE_TYPE_ADDRESSES_FOR_IP'
    if (jobType === 'reverseIp') return 'AUDIENCE_TYPE_REVERSE'
    if (jobType === 'dc') return 'AUDIENCE_TYPE_DC'
    if (jobType === 'zip') return 'AUDIENCE_TYPE_ZIP'
    if (jobType === 'ip') return 'AUDIENCE_TYPE_IP'
    if (jobType === 'device') return 'AUDIENCE_TYPE_DEVICE'
    return 'AUDIENCE_TYPE_UNKNOWN'
  }

  try {
    // Step 1: Upload target, get its id
    onProgress(0, 'upload') // Initiate progress
    const uploadedTarget = await uploadTargetFile(
      uploadingFile.fileData.name,
      uploadingFile.fileData,
      token,
      orgId,
      getTargetFileType(audienceType),
      uploadingFile.hasHeader || true,
      uploadingFile.columns,
      (prog) => {
        onProgress(prog)
      },
      'DATA_SOURCE_CLIENT',
    )
    // Step 2: If uploaded target, create job
    if (uploadedTarget?.id) {
      onProgress(100, 'job')
      const createdAudience = await createAndGenerateAudience(
        orgId,
        getJobTypeEnum(),
        uploadedTarget.id,
        uploadingFile.fileData.name || '',
        hidden,
        'DATA_SOURCE_CLIENT',
      )
      // Step 4: If job created, add it to the queue
      if (
        createdAudience?.id &&
        createdAudience.status === 'AUDIENCE_STATUS_CREATED'
      ) {
        onProgress(100, 'queue')
        audienceServiceAPI
          ?.advertisingPlatformServiceGenerateAudience(
            createdAudience?.id,
            orgId,
          )
          .then(() => {
            // Step 4: Success! After this, AudienceCard will need to poll for job status
            onProgress(100, 'queue_success')
            onUpload(uploadedTarget, createdAudience)
          })
          .then(() => {
            if (locked && createdAudience && createdAudience.id) {
              audienceServiceAPI.advertisingPlatformServiceUpdateAudience(
                createdAudience?.id,
                orgId,
                {
                  locked: locked,
                },
                'locked',
              )
            }
          })
      }
    }
  } catch (e) {
    onError()
  }
}
