import axios from 'axios'
import { getApiUrl } from 'Helpers'

export const downloadInvoicePDF = async (
  downloadKey: string,
  orgId: string,
) => {
  const url = `${getApiUrl()}/v1/invoices:download?id=${downloadKey}&org_id=${orgId}`
  const token = localStorage.getItem('eltoro_token')
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: 'application/pdf',
  }

  const res = await axios({
    method: 'get',
    url,
    headers,
    responseType: 'arraybuffer',
  })
  const fileUrl = window.URL.createObjectURL(
    new Blob([res.data], { type: 'application/pdf' }),
  )
  const a = document.createElement('a')
  a.href = fileUrl
  a.download = `El_Toro_invoice_${downloadKey}.pdf`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(fileUrl)
}
