import { useState } from 'react'
import { Button, Checkbox, Fa } from '@eltoro-ui/components'
import { useValidatedState } from '@eltoro-ui/hooks'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { useCampaignContext } from 'Contexts'
import { ClickThroughWithUTM } from '../ClickThroughWithUTM'
import './AdSetFormInputs.scss'

const AdSetFormButtonWrap = styleTailwind(
  'div',
  'AdSetFormButtonWrap flex flex-1',
)
const AdSetFormButton = styleTailwind(
  Button,
  'AdSetFormInputButton h-10 flex-auto text-m',
)
export const AdSetFormInputs = ({
  type,
  selectedCreatives,
  resetSelectedCreatives,
  showBrandPerCreative,
  setShowBrandPerCreative,
}: {
  type: 'banner' | 'video' | 'OTT' | 'native'
  selectedCreatives: Creativeservicev1Creative[]
  resetSelectedCreatives: () => void
  showBrandPerCreative: boolean
  setShowBrandPerCreative?: (x: boolean) => void
}) => {
  const [clickThru, setClickThru, isValidClickThru] = useValidatedState(
    'https://',
    'url',
  )
  const [brandURL, setBrandURL, isValidBrandURL] = useValidatedState(
    'https://',
    'url',
  )
  const [loading, setLoading] = useState(false)
  const { NewhandleAttachCreatives } = useCampaignContext()

  const isDisabled =
    loading ||
    selectedCreatives.length <= 0 ||
    !clickThru ||
    !isValidClickThru ||
    clickThru.length < 2 ||
    clickThru.length > 1000

  return (
    <div className="AdSetFormInputs laptopsmall:flex-wrap flex gap-x-4 pt-8">
      <div className="AdSetFormInputs__TextFields-buttons flex gap-x-2 pb-4">
        {/* (It does have the associated control inside TextInputWithErrorText) */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <ClickThroughWithUTM
          OLName={''}
          clickThru={clickThru}
          setClickThru={setClickThru}
          isValidClickThru={isValidClickThru}
        />
        {!showBrandPerCreative && (
          <>
            <ClickThroughWithUTM
              OLName={''}
              clickThru={brandURL}
              setClickThru={setBrandURL}
              isValidClickThru={isValidBrandURL}
              brand
            />
          </>
        )}
        {/* REMOVE THIS ON NEXT PR: Confimration from MARI/PAT we do not 
            need this functionatilty. Will remove all per creative brand urls.
         <Checkbox
          className="BrandURL__checkbox"
          label={
            <span className="BrandURL__text text-s">
              Brand URL per creative?
            </span>
          }
          checked={showBrandPerCreative}
          onChange={(e) => {
            if (setShowBrandPerCreative) {
              setShowBrandPerCreative(e)
            }
          }}
        /> */}
      </div>
      <div className="AdSetFormInputs__CartButtons tabletsmall:flex-col tabletsmall:gap-y-4 ml-auto flex items-start gap-x-4 pb-4">
        <AdSetFormButtonWrap
          data-tooltip="top"
          aria-label={
            isDisabled ? 'Add creative/URL to save to cart' : 'Save to cart'
          }
        >
          <AdSetFormButton
            className={
              isDisabled ? '' : 'ripples bg-base hover:bg-primary-500 z-20'
            }
            type="button"
            disabled={isDisabled}
            onClick={() => {
              setLoading(true)
              NewhandleAttachCreatives(
                selectedCreatives,
                clickThru,
                undefined,
                brandURL,
              ).then(() => {
                setLoading(false)
              })
            }}
            iconLeft={
              <Fa
                className="ShoppingCart__icon"
                icon="shopping-cart"
                size={1}
              />
            }
          >
            Save to Cart
          </AdSetFormButton>
        </AdSetFormButtonWrap>
        <AdSetFormButtonWrap
          data-tooltip="top"
          aria-label={
            isDisabled
              ? 'Add creative/URL to add to cart'
              : 'Add to cart & make another'
          }
        >
          <AdSetFormButton
            className={isDisabled ? '' : 'Button--primary ripples'}
            disabled={isDisabled}
            type="button"
            onClick={() => {
              setLoading(true)
              NewhandleAttachCreatives(
                selectedCreatives,
                clickThru,
                undefined,
                brandURL,
              ).then(() => {
                resetSelectedCreatives()
                setLoading(false)
              })
            }}
            iconLeft={
              <Fa
                className="ShoppingCart__icon-plus"
                icon="cart-plus"
                size={1}
              />
            }
          >
            Add to cart & make another
          </AdSetFormButton>
        </AdSetFormButtonWrap>
      </div>
    </div>
  )
}
