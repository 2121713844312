import { useState } from 'react'
import {
  Fa,
  Text,
  showWarningMessage,
  showSuccessMessage,
  showErrorMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Campaignservicev1CPM,
  Orgmanagerv1CPM,
} from 'next-gen-sdk'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { useAppContext, useCampaignContext } from 'Contexts'
import {
  getTypeMoneyCost,
  simplifyEnum,
  styleTailwind,
  useOrderLineCost,
} from 'Helpers'
import { PriceTextInput } from 'Components/PriceTextInput'
import {
  IMPRESSION_MAX,
  IMPRESSION_MIN,
  // LabelInputPair,
  NA,
  TabletLabel,
} from '../shared'

const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')

const BudgetGridRow = styleTailwind(
  'div',
  'BudgetEdit__GridRow grid grid-cols-3 gap-2',
)
const OLEditTableHeader = styleTailwind(Text, 'EditOLTable__header-text')

export type BudgetChangeType = {
  olId: string
  cost: number
  cpm: Campaignservicev1CPM | Orgmanagerv1CPM
}

export const BudgetEditOLRow = ({
  ol,
  setOrderLineChanges,
}: {
  ol: Campaignservicev1OrderLine
  setOrderLineChanges: React.Dispatch<React.SetStateAction<BudgetChangeType[]>>
}) => {
  const { isAdmin } = useAppContext()
  const [input, setInput] = useState<number>(
    ol.costRange?.estimate ? getTypeMoneyCost(ol.costRange?.estimate) : 0,
  )
  const { minCost, cpmCreativeGroup, maxBudgetCPM } = useOrderLineCost(
    ol,
    ol?.minimumImpressions || IMPRESSION_MIN,
    IMPRESSION_MAX,
  )
  const costSwitcher = isAdmin ? 0 : minCost

  return (
    <BudgetGridRow className="EditOLRowItem">
      <EditOLRowItem className="pr-2">
        <TabletLabel>Order Line</TabletLabel>
        <Text>{ol.name}</Text>
      </EditOLRowItem>
      <EditOLRowItem className="OLCard__Budget-value relative w-full !gap-0">
        <TabletLabel>Adjust Subtotal</TabletLabel>
        {maxBudgetCPM ? (
          <div className="flex gap-3">
            <PriceTextInput
              value={input}
              onChange={(cost) => {
                setInput(cost)
                if (!ol.id || cost < costSwitcher || !cost) {
                  return showWarningMessage(
                    'Unable to update order line',
                    `Minimum budget for this order line: $${minCost}`,
                  )
                }
                const olId = ol.id
                const change = { olId, cost, cpm: maxBudgetCPM }
                setOrderLineChanges((prev) => {
                  const filtered = prev.filter((p) => p.olId !== change.olId)
                  return [...filtered, change]
                })
              }}
              min={isAdmin ? undefined : minCost}
              className="max-w-[7rem]"
            />
            {input < minCost && !isAdmin && (
              <span
                aria-label={`The minimum cost for this order line is $${minCost}`}
                data-tooltip="top"
                className="ImpressionsOLRow__min-imps-warning font-normal"
              >
                <Fa icon="circle-info" size={1} className="!text-warning" />
              </span>
            )}
            {isAdmin && input < minCost && (
              <span
                aria-label={`You are now below the minimum budget of $${minCost}`}
                data-tooltip="top"
                className="ImpressionsOLRow__min-imps-warning font-normal"
              >
                <Fa icon="circle-info" size={1} className="!text-warning" />
              </span>
            )}
          </div>
        ) : (
          <div>
            <div className="OLCard__Budget--tooltip text-warning group group-hover:text-warning-700 absolute right-0">
              <span
                className="Tooltip-css-wrap"
                data-tooltip="bottom"
                aria-label="Attach Creative(s) to calculate and adjust Budget."
              >
                <Fa icon="comment-exclamation" size={1} />
              </span>
            </div>
            $0.00
          </div>
        )}
      </EditOLRowItem>
      <EditOLRowItem>
        <TabletLabel>Order Line CPM</TabletLabel>
        {maxBudgetCPM ? (
          <Text>
            ${getTypeMoneyCost(maxBudgetCPM).toFixed(2)}&nbsp;
            {simplifyEnum(2, cpmCreativeGroup, true)}
          </Text>
        ) : (
          NA
        )}
      </EditOLRowItem>
    </BudgetGridRow>
  )
}

export const BudgetEdit = ({
  className = '',
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [orderLineChanges, setOrderLineChanges] = useState<BudgetChangeType[]>(
    [],
  )
  const { campaignServiceApi, currentOrg, roles } = useAppContext()
  const { setRefresh } = useCampaignContext()

  const handleUpdate = () => {
    Promise.all(
      orderLineChanges.map((change) => {
        if (!campaignServiceApi || !currentOrg?.id) return Promise.resolve({})
        const { cost, olId, cpm } = change
        const cpmCost = getTypeMoneyCost(cpm)
        const impressions = Math.round((cost / cpmCost) * 1000)
        const ol = selectedOls.find((c) => c.id === olId)
        if (
          roles &&
          roles.includes('nextgen_admin') &&
          ol?.minimumImpressions &&
          impressions < ol?.minimumImpressions
        ) {
          return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
            olId,
            currentOrg?.id,
            {
              impressions: impressions,
              minimumImpressions: impressions,
            },
          )
        }
        return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          olId,
          currentOrg?.id,
          {
            impressions: impressions,
          },
          'impressions',
        )
      }),
    )
      .then(() => {
        showSuccessMessage('Budget was updated successfully', '')
      })
      .catch(() => {
        showErrorMessage('Budget update failed', '')
      })
      .then(() => {
        onCloseModal()
        if (setRefreshCampaign) {
          setRefreshCampaign(true)
        }
        setRefresh(true)
      })
  }

  const hasUpdates = () => {
    // if a change has a different cost than the original
    if (!orderLineChanges.length) return false
    return !!selectedOls.some((originalOL) => {
      const { costRange } = originalOL
      const olCost = getTypeMoneyCost(costRange?.estimate || {})

      const matchingChange = orderLineChanges.find(
        (change) => change.olId === originalOL.id,
      )
      if (!matchingChange) return false

      return matchingChange.cost !== olCost
    })
  }

  return (
    <div className={`BudgetEdit ${className}`}>
      <BudgetGridRow className="EditOLTable__header StickyHeader laptopsmall:hidden">
        <OLEditTableHeader>Order Line Name</OLEditTableHeader>
        <OLEditTableHeader>Adjust Subtotal</OLEditTableHeader>
        <OLEditTableHeader>Order Line CPM</OLEditTableHeader>
      </BudgetGridRow>
      <div className="EditOLTable__body">
        {currentOrg &&
          selectedOls.map((ol, i) => (
            <BudgetEditOLRow
              ol={ol}
              setOrderLineChanges={setOrderLineChanges}
              key={`BudgetEditOLRow__${ol.id}_${i}`}
            />
          ))}
      </div>

      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!hasUpdates()}
      />
    </div>
  )
}
