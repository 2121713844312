import { useState } from 'react'
import {
  SortDirectionType,
  SortFieldType,
  TablePropsType,
  TableStateType,
} from '../../types'

const initState = {
  sort: undefined,
  filter: undefined,
}

export const useTable = (): TablePropsType => {
  const [state, setState] = useState<TableStateType>(initState)

  const clearSort = () => setState((prev) => ({ ...prev, sort: undefined }))

  const clearFilter = (field: string) =>
    setState((prev) => {
      const newState = { ...prev }
      delete newState.filter?.[field]
      return newState
    })

  const clearAllFilters = () =>
    setState((prev) => ({ ...prev, filter: undefined }))

  const resetTable = () =>
    setState(() => ({ filter: undefined, sort: undefined }))

  const setSort = (field: SortFieldType, direction: SortDirectionType) =>
    setState((prev) => ({ ...prev, sort: { field, direction } }))

  const addFilter = (field: string, value: Array<string | number>) =>
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [field]: value },
    }))

  return {
    ...state,
    clearSort,
    clearFilter,
    clearAllFilters,
    resetTable,
    setSort,
    addFilter,
  }
}
