import { PropsWithChildren, ReactNode } from 'react'
import { Button, Fa, CollapsibleContainer } from '@eltoro-ui/components'
import classNames from 'classnames'
import { styleTailwind } from 'Helpers'
import { useSearchParams } from 'react-router-dom'

export const PrimaryHeader = styleTailwind(
  'h3',
  'text-primary flex items-center pb-1',
)

export const SectionCollapse = ({
  title,
  editButtons,
  isOpen,
  toggleOpen,
  children,
  sectionName,
  className,
}: PropsWithChildren<{
  title: string | ReactNode
  editButtons: ReactNode
  isOpen: boolean
  toggleOpen: () => void
  sectionName: string
  className?: string
}>) => {
  const [search, setSearch] = useSearchParams()
  const handleToggleAndUrl = () => {
    search.delete(sectionName)
    search.append(sectionName, JSON.stringify(!isOpen))
    setSearch(search)
    toggleOpen()
  }
  return (
    <div
      className={classNames('SectionCollapse BlockWithBorder', className, {
        'cursor-pointer': !isOpen,
      })}
      onClick={!isOpen ? handleToggleAndUrl : undefined}
    >
      <PrimaryHeader className="OrgSettingsPage__label flex justify-between">
        <div className="flex items-center gap-4">
          <span
            aria-label={`${isOpen ? 'Close' : 'Open'} ${sectionName}`}
            data-tooltip="top"
          >
            <Button
              iconOnly={
                <Fa icon={isOpen ? 'chevron-up' : 'chevron-down'} size={1} />
              }
              onClick={(e) => {
                e?.stopPropagation()
                handleToggleAndUrl()
              }}
            />
          </span>
          <p className="flex align-baseline">{title}</p>
        </div>
        {isOpen && <div className="animate-fadein">{editButtons}</div>}
      </PrimaryHeader>
      <CollapsibleContainer isOpen={isOpen}>{children}</CollapsibleContainer>
    </div>
  )
}
