/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Inventory List Service](https://docs.xandr.com/bundle/xandr-api/page/inventory-list-service.html) in Xandr's documentation.
*/
export class V3InventoryListTarget {
    /**
    * ID of the inventory list.
    */
    'id'?: number;
    /**
    * Name of the inventory list.
    */
    'name'?: string;
    /**
    * Description of the inventory list.
    */
    'description'?: string;
    /**
    * Type of inventory list.
    */
    'inventoryListType'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "inventoryListType",
            "baseName": "inventory_list_type",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3InventoryListTarget.attributeTypeMap;
    }

    public constructor() {
    }
}

