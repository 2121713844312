/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* An object containing several boolean fields used to designate which inventory supply sources you would like to target. The values of this object's boolean fields supersede the setting of the inventory_type field and once set will cause the inventory_type field to be permanently locked and ignored.
*/
export class V3SupplyStrategies {
    /**
    * An object containing several boolean fields used to designate which inventory supply sources you would like to target. The values of this object's boolean fields supersede the setting of the inventory_type field and once set will cause the inventory_type field to be permanently locked and ignored.
    */
    'deals'?: boolean;
    /**
    * Designates whether you wish to target managed inventory. This only includes inventory managed by your network.
    */
    'managed'?: boolean;
    /**
    * Designates whether you wish to target inventory on the Open Exchange. This includes all third-party inventory not managed by your network that has been enabled for reselling (including external supply partners such as Microsoft Advertising Exchange and Google AdX).
    */
    'rtb'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "deals",
            "baseName": "deals",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "managed",
            "baseName": "managed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "rtb",
            "baseName": "rtb",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3SupplyStrategies.attributeTypeMap;
    }

    public constructor() {
    }
}

