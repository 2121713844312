import { ReactNode, useRef } from 'react'
import { useKeyboardFocus } from '@eltoro-ui/hooks'
import './Toggle.scss'

export const Toggle: React.FunctionComponent<{
  className?: string
  isDisabled?: boolean
  isChecked?: boolean
  label?: ReactNode
  onChange: (checked: boolean) => void
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
}> = ({
  className = '',
  isDisabled,
  isChecked,
  label,
  onChange,
  size = 'm',
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const hasKeyboardFocus = useKeyboardFocus(inputRef)
  const sizeClass = `Toggle--${size}-size`
  return (
    <label
      className={`Toggle ${sizeClass} ${!label ? 'noLabel' : 'hasLabel'} ${
        isChecked ? 'Toggle--is-checked' : ''
      } ${isDisabled ? 'Toggle--is-disabled' : ''} ${
        hasKeyboardFocus ? 'Toggle--has-keyboard-focus' : ''
      } ${className}`}
    >
      <input
        ref={inputRef}
        className="Toggle__input"
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={isDisabled}
      />
      <span
        className={`Toggle__slider ${
          isChecked ? 'Toggle__slider--is-checked' : ''
        } ${isDisabled ? 'Toggle__slider--is-disabled' : ''}`}
      />
      {label ? (
        <span className={`Toggle__label ${className}`}>{label}</span>
      ) : null}
    </label>
  )
}
