import { ApiFactory } from 'apiFactory'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { OrderLinesWithError } from 'types'

export const getCreativeOrderLines = async (
  creative: Creativeservicev1Creative,
  org: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { campaignServiceAPI } = apis
  if (!creative?.orderLines?.length || !campaignServiceAPI) return []

  const olIds = creative.orderLines?.reduce((acc: string[], ol) => {
    if (!ol.id) return acc
    return [...acc, ol.id]
  }, [])

  // get orderLines, filter out the placeholder OLs
  const orderLines = await Promise.all(
    olIds.map((id) => {
      return campaignServiceAPI
        .advertisingPlatformServiceGetOrderLine(id, org)
        .then((ol) => ({
          id: ol.id,
          ol,
          err: !ol.id ? 'Missing order line id' : undefined,
        }))
        .catch(() => ({ id, err: 'Order line not found' }))
    }),
  )

  return orderLines as OrderLinesWithError[]
}
