/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1Audience } from '../models/Targetjobservicev1Audience';
import { Targetjobservicev1Deployment } from '../models/Targetjobservicev1Deployment';
import { Targetjobservicev1Note } from '../models/Targetjobservicev1Note';
import { Targetjobservicev1Timeframe } from '../models/Targetjobservicev1Timeframe';
import { V1AudienceStatus } from '../models/V1AudienceStatus';
import { V1AudienceSubType } from '../models/V1AudienceSubType';
import { V1AudienceType } from '../models/V1AudienceType';
import { V1DataSource } from '../models/V1DataSource';
import { V1ProductType } from '../models/V1ProductType';
import { V1Result } from '../models/V1Result';
import { HttpFile } from '../http/http';

export class TheAudienceToUpdate {
    /**
    * The Name that is associated with the Audience.
    */
    'name'?: string;
    /**
    * The Org's ID that is associated with the Audience.
    */
    'orgId'?: string;
    /**
    * The ID of the Target this job is associated with.
    */
    'targetId'?: string;
    /**
    * This ID only exists if the job is part of a pipeline job. This is the ID of the parent pipeline job.
    */
    'audienceId'?: string;
    /**
    * The time the Audience was created.
    */
    'createTime'?: Date;
    /**
    * The time the Audience was updated.
    */
    'updateTime'?: Date;
    /**
    * The time the Audience was deleted.
    */
    'deleteTime'?: Date;
    /**
    * The time the Audience was archived.
    */
    'archiveTime'?: Date;
    /**
    * The time the Audience was expired.
    */
    'expireTime'?: Date;
    /**
    * The time the Audience was purged.
    */
    'purgeTime'?: Date;
    'status'?: V1AudienceStatus;
    'type'?: V1AudienceType;
    'subType'?: V1AudienceSubType;
    'productType'?: V1ProductType;
    /**
    * The array of Audiences that will be ran in a pipeline.
    */
    'audiences'?: Array<Targetjobservicev1Audience>;
    'orderLineIds'?: Array<string>;
    /**
    * An ID that is generated when a Audience is ran.
    */
    'instanceId'?: string;
    'result'?: V1Result;
    /**
    * The limit you want to set on the number of matches.
    */
    'matchCap'?: number;
    /**
    * The Code associated with a Segment.
    */
    'code'?: number;
    /**
    * The start and end times for a venue replay job.
    */
    'timeframes'?: Array<Targetjobservicev1Timeframe>;
    'dataSource'?: V1DataSource;
    /**
    * The the folder where the audience is stored.
    */
    'folder'?: string;
    'hidden'?: boolean;
    'locked'?: boolean;
    /**
    * The deployments for the job.
    */
    'deployments'?: Array<Targetjobservicev1Deployment>;
    /**
    * The notes for the audience.
    */
    'notes'?: Array<Targetjobservicev1Note>;
    'initialMatches'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expireTime",
            "baseName": "expire_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "purgeTime",
            "baseName": "purge_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1AudienceStatus",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1AudienceType",
            "format": ""
        },
        {
            "name": "subType",
            "baseName": "sub_type",
            "type": "V1AudienceSubType",
            "format": ""
        },
        {
            "name": "productType",
            "baseName": "product_type",
            "type": "V1ProductType",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<Targetjobservicev1Audience>",
            "format": ""
        },
        {
            "name": "orderLineIds",
            "baseName": "order_line_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "instanceId",
            "baseName": "instance_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "result",
            "baseName": "result",
            "type": "V1Result",
            "format": ""
        },
        {
            "name": "matchCap",
            "baseName": "match_cap",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "timeframes",
            "baseName": "timeframes",
            "type": "Array<Targetjobservicev1Timeframe>",
            "format": ""
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "folder",
            "baseName": "folder",
            "type": "string",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Targetjobservicev1Deployment>",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<Targetjobservicev1Note>",
            "format": ""
        },
        {
            "name": "initialMatches",
            "baseName": "initial_matches",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return TheAudienceToUpdate.attributeTypeMap;
    }

    public constructor() {
    }
}

