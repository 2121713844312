import { useEffect, useRef } from 'react'

type TimeoutProps = {
  callback: () => void
  delay: React.MutableRefObject<number | null>
}
type MouseHandlersProps = {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  delay: React.MutableRefObject<number | null>
}

type CallbackFunc = () => void

export const useTimeout = ({ callback, delay }: TimeoutProps) => {
  const timeoutRef = useRef<number | null>(null)
  const savedCallback = useRef<CallbackFunc>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const time = () => savedCallback.current()
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(time, delay)
      return () => {
        if (timeoutRef.current !== null) {
          window.clearTimeout(timeoutRef.current)
        }
      }
    }
  }, [delay, callback])

  const clear = () => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current)
    }
  }

  return { timeoutRef, clear }
}

export const useMouseHandlers = ({
  onMouseEnter,
  onMouseLeave,
  delay,
}: MouseHandlersProps) => {
  const { clear } = useTimeout({
    delay,
    callback: onMouseEnter ?? (() => {}),
  })

  const handleMouseEnter = () => {
    clear()
    onMouseEnter?.()
  }

  const handleMouseLeave = () => {
    clear()
    onMouseLeave?.()
  }

  return { handleMouseEnter, handleMouseLeave }
}
