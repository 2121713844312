import * as React from 'react'
import './Confetti.scss'

export function Confetti({ number }: { number: number }) {
  const [confetti, setConfetti] = React.useState(number)

  return (
    <div className="ConfettiWrapper">
      {/* {Confettis} */}
      {[...Array(confetti)].map((value: undefined, index: number) => (
        <Confettis id={index + 1} key={`confetti-${index}`} />
      ))}
    </div>
  )
}

const Confettis = ({ id }: { id: number }) => (
  <div className={`confetti-${id}`} />
)
