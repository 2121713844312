/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Orgmanagerv1User } from '../models/Orgmanagerv1User';
import { HttpFile } from '../http/http';

export class Orgmanagerv1CommissionGroup {
    /**
    * id of the commission group
    */
    'id'?: string;
    /**
    * List of users that are associated with the org's commission group
    */
    'users'?: Array<Orgmanagerv1User>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "users",
            "baseName": "users",
            "type": "Array<Orgmanagerv1User>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Orgmanagerv1CommissionGroup.attributeTypeMap;
    }

    public constructor() {
    }
}

