import { useState } from 'react'
import { Button, Fa, Table, showErrorMessage } from '@eltoro-ui/components'
import { V1Invoice } from 'next-gen-sdk'
import dayjs from 'dayjs'
import { EmptyField, InfoPopover } from 'Components'
import { useAppContext } from 'Contexts'
import { billingInvoiceStatusOptions } from 'searchOptions'
import { InvoiceStatusIndicator } from '../InvoiceStatusIndicator'
import { InvoiceStatusPopover } from '../InvoiceStatusPopover'
import { InvoicePaymentModal } from '../InvoicePaymentModal'
import { downloadInvoicePDF } from 'Requests'

export const InvoiceTable = ({
  invoices,
  loading,
  handleReset,
  error,
}: {
  invoices: V1Invoice[]
  loading: boolean
  handleReset: (type: 'credit-cards' | 'invoice') => void
  error: boolean
}) => {
  const [downloading, setDownloading] = useState(false)
  const [invoiceToPay, setInvoiceToPay] = useState<V1Invoice>()
  const { currentOrg } = useAppContext()

  if (loading)
    return (
      <div className="InvoiceTable__loading py-10 text-center text-lg leading-8">
        <div className="stack">
          <Fa
            icon="file-invoice"
            size={9}
            color="var(--color-primary-100)"
            className="mb-4 animate-pulse"
          />
          <Fa
            icon="magnifying-glass"
            size={6}
            type="duotone"
            className="mb-4 animate-pulse"
          />
        </div>
        <p className="animate-pulse font-bold">Loading invoices</p>
      </div>
    )
  if (!loading && error)
    return (
      <div className="InvoiceTable__error py-10 text-center text-lg leading-8">
        <div className="stack">
          <Fa
            icon="file-invoice"
            size={9}
            color="var(--color-primary-100)"
            className="mb-4"
          />
          <Fa
            icon="triangle-exclamation"
            size={5}
            type="duotone"
            className="mb-4 opacity-70"
          />
        </div>
        <p className="text-danger font-bold">Error loading invoices</p>
      </div>
    )
  return (
    <>
      <div className="InvoiceTable">
        {invoices.length ? (
          <Table
            className="animate-fadein"
            columns={[
              { path: 'recordNo', label: 'Invoice Number', removeFilter: true },
              {
                path: 'total',
                label: 'Amount',
                removeFilter: true,
                RowCell: (invoice) => <>{`$${invoice.total?.toFixed(2)}`}</>,
              },
              {
                path: 'status',
                filterOn: billingInvoiceStatusOptions,
                label: (
                  <div className="flex items-center">
                    Status <InfoPopover content={<InvoiceStatusPopover />} />
                  </div>
                ),
                RowCell: (invoice) =>
                  invoice.status ? (
                    <InvoiceStatusIndicator status={invoice.status} />
                  ) : (
                    <EmptyField />
                  ),
              },
              {
                path: '',
                label: '',
                removeFilter: true,
                removeSort: true,
                RowCell: (invoice) => (
                  <span
                    className="flex items-center justify-center"
                    aria-label={(() => {
                      if ((invoice.totalDue || 0) < 0)
                        return 'This invoice is a credit'
                    })()}
                    data-tooltip="top"
                  >
                    {invoice.status === 'INVOICE_STATUS_PAID' ? (
                      <span>Invoice paid</span>
                    ) : (
                      <Button
                        className="ButtonUrgent"
                        size="s"
                        disabled={(invoice.totalDue || 0) < 0}
                        onClick={() => setInvoiceToPay(invoice)}
                      >
                        Pay now
                      </Button>
                    )}
                  </span>
                ),
              },
              {
                path: 'whenDue',
                label: 'Due',
                removeFilter: true,
                RowCell: (invoice) => {
                  if (!invoice.whenDue) return <EmptyField />
                  const isPastDue =
                    dayjs(invoice.whenDue).isBefore(new Date(), 'day') &&
                    invoice.status !== 'INVOICE_STATUS_PAID'
                  if (isPastDue) {
                    return (
                      <div className="flex items-center gap-2">
                        <span
                          aria-label="This invoice is past due"
                          data-tooltip="top"
                        >
                          <Fa
                            icon="calendar-exclamation"
                            size={1}
                            color="#e2bb37"
                          />
                        </span>
                        {invoice.whenDue}
                      </div>
                    )
                  }
                  return invoice.whenDue
                },
              },
              {
                path: 'appliedAmount',
                label: 'Applied Amount',
                removeFilter: true,
                RowCell: (invoice) =>
                  `$${invoice.appliedAmount?.toFixed(2) || 0}`,
              },
              {
                path: 'totalDue',
                label: 'Balance',
                removeFilter: true,
                RowCell: (invoice) => `$${invoice.totalDue?.toFixed(2) || 0}`,
              },
              {
                path: '',
                label: '',
                removeFilter: true,
                RowCell: (invoice) => (
                  <Button
                    size="s"
                    onClick={() => {
                      if (invoice.downloadKey && currentOrg?.id) {
                        setDownloading(true)
                        downloadInvoicePDF(invoice.downloadKey, currentOrg.id)
                          .catch(() =>
                            showErrorMessage(
                              'Download Error',
                              'There was an error downloading your invoice.',
                            ),
                          )
                          .finally(() => setDownloading(false))
                      }
                    }}
                    disabled={!invoice.recordNo || downloading}
                  >
                    Download
                  </Button>
                ),
              },
            ]}
            rows={invoices}
          />
        ) : (
          <div className="InvoiceTable__empty animate-fadein py-10 text-center text-lg leading-8">
            <Fa
              icon="file-invoice"
              size={9}
              color="var(--color-primary-100)"
              className="mb-4"
            />
            <p className="font-bold">
              Looks like you don&apos;t have any invoices yet
            </p>
            <p>
              If you believe this is a mistake, please contact{' '}
              <a href="mailto:billing@eltoro.com">billing@eltoro.com</a>
            </p>
          </div>
        )}
      </div>
      {invoiceToPay && (
        <InvoicePaymentModal
          invoice={invoiceToPay}
          handleClose={() => setInvoiceToPay(undefined)}
          handleReset={() => handleReset('invoice')}
        />
      )}
    </>
  )
}
