import { useState, useEffect, ReactElement, isValidElement } from 'react'
import { unmount } from 'rc-util/lib/React/render'
import { useBrowser } from '@eltoro-ui/hooks'
import { getSanitizedHTML } from './helpers'
import { Button } from '../Button'
import { Fa } from '../FontAwesome'
import './Notifications.scss'

/**
 * Neat Idea: When we integrate a state manager we can have a parent component that handles the display of notifications
 * instead of mounting them using reactDOM. Also we could use that container to render multiple notifications
 * at once.
 */
export const NotificationBar = ({
  messageType,
  className,
  timer = 10,
  title,
  message,
  dismissMessage,
  alert,
  autoDismiss = true,
  onDismiss,
}: {
  messageType: string
  className?: string
  timer?: number
  title?: string
  message?: string | ReactElement
  dismissMessage?: boolean
  alert?: boolean
  autoDismiss?: boolean
  onDismiss?: () => void
}) => {
  const Browser = useBrowser()
  const [closeTimeout, setCloseTimeout] = useState<number>(timer)

  useEffect(() => {
    if (autoDismiss) beginCloseTimeout()
  }, [])

  const closeSnackBar = () => {
    clearTimeout(closeTimeout)
    const bar = document.getElementById('notification-bar-fixed-container')
    if (onDismiss) onDismiss()
    if (bar) unmount(bar)
  }

  const beginCloseTimeout = () => {
    setTimeout(() => closeSnackBar(), timer)
    setCloseTimeout(timer)
  }

  const sanitizedMessage = getSanitizedHTML(message as string, Browser)

  return (
    <div
      className={`${className} notification-bar-container ${
        alert ? `${messageType}-alert` : ''
      }  ${messageType}-container`}
      onMouseEnter={() => autoDismiss && clearTimeout(closeTimeout)}
      onMouseLeave={() => autoDismiss && beginCloseTimeout()}
    >
      <div className="NotificationMessage__message-wrap flex items-center w-full gap-4">
        <div className="NotificationMessage__icon">
          {messageType === 'success' && (
            <Fa icon="fa-check-double" size={4} className="success-icon" />
          )}
          {messageType === 'error' && (
            <Fa icon="fa-shield-exclamation" size={4} className="dang-icon" />
          )}
          {messageType === 'info' && (
            <Fa
              icon="fa-circle-info"
              size={4}
              className="info-icon"
              type="solid"
            />
          )}
          {messageType === 'warning' && (
            <Fa icon="fa-circle-exclamation" size={4} className="warn-icon" />
          )}
        </div>
        <div className="NotificationMessage flex flex-col items-start justify-center">
          <h5>{title}</h5>
          {typeof message === 'string' && (
            <div
              className="NotificationMessage NotificationMessage__text-inner-html"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
            />
          )}
          {isValidElement(message) && message}
        </div>
        {dismissMessage &&
          (messageType === 'error' ||
            messageType === 'success' ||
            messageType === 'warning' ||
            messageType === 'info') && (
            <div
              className="NotificationMessage__dismiss-button-wrap justify-end ml-auto"
              data-tooltip="left"
              aria-label="Dismiss message"
            >
              <Button
                className="NotificationMessage_dismiss-button transition-all"
                iconOnly={<Fa icon="times" size={1} />}
                rounded
                onClick={() => closeSnackBar()}
              />
            </div>
          )}
      </div>
    </div>
  )
}
