import { ReactNode } from 'react'
import classNames from 'classnames'
import { Text, TextTypesUnionType } from '../Text'

export const TextBody = (
  props: TextTypesUnionType & {
    className?: string
    children: ReactNode
    type?: 1 | 2 | 3
    lineHeight?: 1 | 2 | 3
  },
) => {
  const { children, type, className, lineHeight } = props
  const fontSize = () => {
    if (type === 1) return 'l'
    if (type === 2) return 'm'
    if (type === 3) return 's'
    return 'm'
  }
  const leading = () => {
    if (lineHeight === 1) return 'none'
    if (lineHeight === 2) return 'snug'
    if (lineHeight === 3) return 'normal'
    return 'tight'
  }
  return (
    <Text
      className={classNames('TextBody my-1', className)}
      {...props}
      weight="normal"
      size={fontSize()}
      lineHeight={leading()}
      tag="p"
      subComponent
    >
      {children}
    </Text>
  )
}
