/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Viant Adelphic Creative Config
*/
export class V3ViantCreativeConfig {
    /**
    * Creative Size
    */
    'creativeSize'?: string;
    /**
    * Creative Height
    */
    'creativeHeight'?: string;
    /**
    * Creative Width
    */
    'creativeWidth'?: string;
    /**
    * Creative Duration
    */
    'creativeDuration'?: string;
    /**
    * Creative Duration
    */
    'creativeName'?: string;
    /**
    * Creative landing page URL
    */
    'creativeLandingPageURL'?: string;
    /**
    * Creative crawlable URL
    */
    'creativeCrawlableURL'?: string;
    /**
    * Creative advertiser id
    */
    'creativeAdvertiserID'?: string;
    /**
    * Creative HTML asset
    */
    'creativeHTMLAsset'?: string;
    /**
    * Creative Type
    */
    'creativeType'?: string;
    /**
    * Creative Subtype
    */
    'creativeSubtype'?: string;
    'creativeVendorID'?: number;
    'creativeMediaType'?: string;
    'creativeVideoType'?: string;
    'internalCreativeID'?: string;
    'adelphicCreativeID'?: string;
    /**
    * Creative S3 Location
    */
    'creativeS3Location'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "creativeSize",
            "baseName": "CreativeSize",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeHeight",
            "baseName": "CreativeHeight",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeWidth",
            "baseName": "CreativeWidth",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeDuration",
            "baseName": "CreativeDuration",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeName",
            "baseName": "CreativeName",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeLandingPageURL",
            "baseName": "CreativeLandingPageURL",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeCrawlableURL",
            "baseName": "CreativeCrawlableURL",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeAdvertiserID",
            "baseName": "CreativeAdvertiserID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeHTMLAsset",
            "baseName": "CreativeHTMLAsset",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeType",
            "baseName": "CreativeType",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeSubtype",
            "baseName": "CreativeSubtype",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeVendorID",
            "baseName": "CreativeVendorID",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "creativeMediaType",
            "baseName": "CreativeMediaType",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeVideoType",
            "baseName": "CreativeVideoType",
            "type": "string",
            "format": ""
        },
        {
            "name": "internalCreativeID",
            "baseName": "InternalCreativeID",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicCreativeID",
            "baseName": "AdelphicCreativeID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "creativeS3Location",
            "baseName": "CreativeS3Location",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3ViantCreativeConfig.attributeTypeMap;
    }

    public constructor() {
    }
}

