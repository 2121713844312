import './FilePreview.scss'
import { Spacer } from '../Spacer'
import '../../Assets/css/all.css'
import { Fa } from '../FontAwesome'

export const FilePreview = ({
  URL,
  filename,
  onRemove,
  type,
}: {
  URL: string
  filename: string
  onRemove: (URL: string, filename: string) => void
  type: string
}) => {
  if (type === 'video') {
    return (
      <div className="FilePreview">
        <p key={filename}>{filename}</p>
        <video height="200" width="200" controls>
          <track kind="captions" />
          <source src={URL} />
        </video>
        <Fa icon="times" onClick={() => onRemove(URL, filename)} size={1} />
      </div>
    )
  }
  if (type === 'file-upload') {
    return (
      <div className="FilePreview">
        <div className="FilePreview__remove">
          <Fa icon="times" onClick={() => onRemove(URL, filename)} size={1} />
        </div>
        <p key={filename}>{filename}</p>
        <Spacer height="1.5rem" width="100%" />
        <Fa size={8} icon="file" />
      </div>
    )
  }
  return (
    <div className="FilePreview">
      <p key={filename}>{filename}</p>
      <img src={URL} alt="test" />
      <Fa size={1} icon="times" onClick={() => onRemove(URL, filename)} />
    </div>
  )
}
