import React from 'react'
import { FormItem, TextHeader, Toggle, TextInput } from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { SelectCountry, SelectState } from 'Components'
import {
  ErrorMessage,
  isValidCharLength,
  validCheck,
  truncateMiddle,
} from 'Helpers'
import { ContactStateType } from './useContactState'

export const ContactSection = ({
  parentOrg,
  // Name
  firstName,
  setFirstName,
  lastName,
  setLastName,
  // Email
  isValidEmail,
  email,
  setEmail,
  // Phone
  phone,
  setPhone,
  isValidPhone,
  // Address
  address,
  setAddress,
  zip,
  setZip,
  isValidZip,
  isValidCanadianZip,
  city,
  setCity,
  state,
  setState,
  country,
  setCountry,
  // Manip functions
  clearAll,
  setToDefault,
  sameAsParent,
  setSameAsParent,
  type,
}: {
  parentOrg: Orgmanagerv1Org
  type: 'ops' | 'billing' | 'sales'
} & ContactStateType) => {
  const handleToggleSameAsParent = (bool: boolean) => {
    setSameAsParent(bool)
    if (bool) {
      setToDefault()
    } else clearAll()
  }
  return (
    <div className="ContactSection flex flex-col gap-2">
      <div className="flex items-center gap-1 leading-tight">
        <TextHeader type={6} className={`ContactTitle__${type} pt-1`}>
          {`${
            type === 'ops'
              ? 'Operations'
              : `${type.charAt(0).toUpperCase()}${type.slice(1)}`
          } Contact`}
        </TextHeader>
        <span className="font-light">(optional)</span>
      </div>
      <div className="flex items-center gap-2">
        <Toggle
          isChecked={sameAsParent}
          onChange={handleToggleSameAsParent}
          size="xl"
          label={`Same as ${truncateMiddle(parentOrg.name, 20)}`}
        />
      </div>
      {!sameAsParent && (
        <div className="ContactSection__form animate-slidedown bg-tint-gray-50 mb-4 mt-2 mr-6 px-5 pt-3 pb-4">
          <div className="grid grid-cols-1 gap-2 py-2 md:grid-cols-2">
            <FormItem
              htmlFor={`${type}FirstName`}
              label="First Name"
              className="w-full"
              errorMessage={ErrorMessage({
                fieldName: firstName,
                max: 255,
                label: 'First name',
                isValid:
                  validCheck(firstName) && isValidCharLength(firstName, 255),
              })}
              valid={validCheck(firstName) && isValidCharLength(firstName, 255)}
              counter={firstName.length > 0}
              required
            >
              <TextInput
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(e.target.value)
                }}
                disabled={sameAsParent}
              />
            </FormItem>
            <FormItem
              htmlFor={`${type}LastName`}
              label="Last Name"
              className="w-full"
              errorMessage={ErrorMessage({
                fieldName: lastName,
                max: 255,
                label: 'Last name',
                isValid:
                  validCheck(lastName) && isValidCharLength(lastName, 255),
              })}
              valid={validCheck(lastName) && isValidCharLength(lastName, 255)}
              counter={lastName.length > 0}
              required
            >
              <TextInput
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(e.target.value)
                }}
                disabled={sameAsParent}
              />
            </FormItem>
          </div>
          <FormItem
            htmlFor={`${type}Email`}
            label="Email"
            errorMessage={ErrorMessage({
              fieldName: email,
              max: 255,
              label: 'Email address',
              isValid: validCheck(email) && isValidCharLength(email, 255),
            })}
            valid={
              isValidEmail && validCheck(email) && isValidCharLength(email, 255)
            }
            counter={email.length > 0}
            required
          >
            <TextInput
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value)
              }}
              disabled={sameAsParent}
            />
          </FormItem>
          <FormItem
            htmlFor={`${type}Phone`}
            label="Phone"
            errorMessage={ErrorMessage({
              fieldName: phone,
              max: 10,
              label: 'Phone number',
              isValid: validCheck(phone) && isValidCharLength(phone, 10),
            })}
            valid={
              isValidPhone && validCheck(phone) && isValidCharLength(phone, 10)
            }
            counter={phone.length > 0}
            required
          >
            <TextInput
              value={phone}
              type="tel"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value)
              }}
              disabled={sameAsParent}
            />
          </FormItem>
          <FormItem
            htmlFor={`${type}Address`}
            label="Address"
            errorMessage={ErrorMessage({
              fieldName: address,
              max: 255,
              label: 'Address',
              isValid: validCheck(address) && isValidCharLength(address, 255),
            })}
            valid={validCheck(address) && isValidCharLength(address, 255)}
            counter={address.length > 0}
            required
          >
            <TextInput
              maxLength={256}
              value={address}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAddress(e.target.value)
              }}
              disabled={sameAsParent}
            />
          </FormItem>
          <div className="grid grid-cols-1 gap-5 pt-2 md:grid-cols-3">
            <FormItem
              htmlFor={`${type}City`}
              label="City"
              errorMessage={ErrorMessage({
                fieldName: city,
                max: 100,
                label: 'City',
                isValid: validCheck(city) && isValidCharLength(city, 100),
              })}
              valid={validCheck(city) && isValidCharLength(city, 100)}
              counter={city.length > 0}
              required
            >
              <TextInput
                value={city}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCity(e.target.value)
                }}
                disabled={sameAsParent}
              />
            </FormItem>
            <FormItem htmlFor={`${type}Country`} label="Country:" required>
              <SelectCountry
                value={country}
                onChange={(country) => setCountry(country)}
              />
            </FormItem>
            <FormItem htmlFor={`${type}State`} label="State">
              <SelectState
                value={state}
                onChange={(value) => setState(value)}
                disabled={sameAsParent}
                className="min-h-[2.5rem]"
                country={country}
              />
            </FormItem>
            {country !== 'Canada' ? (
              <FormItem
                htmlFor={`${type}Zip`}
                label="ZIP Code"
                errorMessage={ErrorMessage({
                  fieldName: zip,
                  max: 5,
                  label: 'ZIP code',
                  isValid:
                    isValidZip && validCheck(zip) && isValidCharLength(zip, 5),
                })}
                valid={
                  isValidZip && validCheck(zip) && isValidCharLength(zip, 5)
                }
                counter={zip.length > 0}
                required
              >
                <TextInput
                  maxLength={6}
                  value={zip}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setZip(e.target.value)
                  }}
                  disabled={sameAsParent}
                />
              </FormItem>
            ) : (
              <FormItem
                htmlFor={`${type}Zip`}
                label="Postal Code"
                errorMessage={ErrorMessage({
                  fieldName: zip,
                  max: 6,
                  label: 'Postal code',
                  isValid:
                    isValidCanadianZip(zip) &&
                    validCheck(zip) &&
                    isValidCharLength(zip, 6),
                })}
                valid={
                  isValidCanadianZip(zip) &&
                  validCheck(zip) &&
                  isValidCharLength(zip, 6)
                }
                counter={zip.length > 0}
                required
              >
                <TextInput
                  maxLength={7}
                  value={zip}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setZip(e.target.value)
                  }}
                  disabled={sameAsParent}
                />
              </FormItem>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
