/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3SGSegment } from '../models/V3SGSegment';
import { HttpFile } from '../http/http';

/**
* See [Segment Group Targets](https://docs.xandr.com/bundle/xandr-api/page/profile-service.html#ProfileService-SegmentGroupTargets) in the Profile Service in Xandr's documentation.
*/
export class V3SegmentGroupTarget {
    /**
    * The Boolean logic between segments in a group is defined directly in the segment_group_targets array. Possible values: 'and' or 'or'.
    */
    'booleanOperator'?: string;
    /**
    * The segments in the group.
    */
    'segments'?: Array<V3SGSegment>;
    /**
    * ID of the segment group target.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "booleanOperator",
            "baseName": "boolean_operator",
            "type": "string",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "segments",
            "type": "Array<V3SGSegment>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3SegmentGroupTarget.attributeTypeMap;
    }

    public constructor() {
    }
}

