import { useRef, useState } from 'react'
import { TextHeader, Fa, Button, Popover } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { Creativeservicev1Type } from 'next-gen-sdk'
import { getCreativeFaETIcons, styleTailwind } from 'Helpers'
import { useAppContext } from 'Contexts'

const LibraryMenuHeader = styleTailwind(
  TextHeader,
  'text-grey-400 leading-tight',
)

const LibraryMenuItem = styleTailwind(
  Button,
  'LibraryMenuItem text-primary hover:bg-grey-100 hover:text-primary-600 w-full py-1',
)

type CreativeLibraryType = {
  type: 'creative'
  onCreateNew: () => void
  closePreviewPanel: () => void
  setSelected: React.Dispatch<React.SetStateAction<string | undefined>>
  onUpload?: never
}

type AudienceLibraryType = {
  type: 'audience'
  onUpload: () => void
  closePreviewPanel?: never
  setSelected?: never
  onCreateNew?: never
}

export const LibraryHeader: React.FC<
  CreativeLibraryType | AudienceLibraryType
> = ({ type, onCreateNew, onUpload, setSelected, closePreviewPanel }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([menuRef], () => {
    setMenuOpen(false)
  })
  const { roles, isOnHold } = useAppContext()

  const changeView = (selectedName: string) => {
    if (type === 'creative' && closePreviewPanel) closePreviewPanel()
    if (setSelected) setSelected(selectedName)
  }
  return (
    <div className="LibraryHeader border-b-thin border-tint-gray-300 mb-1 flex items-end justify-between gap-x-4 pb-4">
      <TextHeader className="LibraryHeader__text" type={1}>
        {type === 'creative' ? 'Creative Library' : 'Audience Library'}
      </TextHeader>
      {roles && !roles.includes('nextgen_read_only') && !isOnHold && (
        <div className="LibraryHeader__btns flex gap-4 pt-2">
          {type === 'creative' && setSelected! ? (
            // CREATIVE LIBRARY UPLOAD BUTTON
            <Popover
              isOpen={menuOpen}
              content={
                <div ref={menuRef} className="bg-base flex flex-col gap-4 p-3">
                  <div className="LibraryHeader__dropdown-item">
                    <LibraryMenuHeader type={6}>Image</LibraryMenuHeader>
                    {([
                      {
                        creativeType: 'CREATIVE_TYPE_BANNER',
                        view: 'banner',
                        title: 'Banner',
                      },
                      {
                        creativeType: 'CREATIVE_TYPE_HTML5',
                        view: 'html5',
                        title: 'HTML 5',
                      },
                      {
                        creativeType: 'CREATIVE_TYPE_NATIVE_BANNER',
                        view: 'native',
                        title: 'Native Ad',
                      },
                      {
                        creativeType: 'CREATIVE_TYPE_AD_TAG',
                        view: 'ad-tag',
                        title: 'Ad Tag',
                      },
                    ] as {
                      creativeType: Creativeservicev1Type
                      view: string
                      title: string
                    }[]).map(({ creativeType, view, title }) => (
                      <LibraryMenuItem
                        kind="text"
                        size="s"
                        iconLeft={getCreativeFaETIcons(creativeType)}
                        onClick={() => {
                          setMenuOpen(false)
                          changeView(view)
                        }}
                        key={`LibraryMenuItem-${view}`}
                      >
                        {title}
                      </LibraryMenuItem>
                    ))}
                  </div>

                  <div className="LibraryHeader__dropdown-item">
                    <LibraryMenuHeader type={6}>Video</LibraryMenuHeader>
                    {([
                      {
                        creativeType: 'CREATIVE_TYPE_NATIVE_VIDEO',
                        view: 'video',
                        title: 'Video',
                      },
                      {
                        creativeType: 'CREATIVE_TYPE_VAST_TAG',
                        view: 'vast-ad-tag',
                        title: 'VAST Tag',
                      },
                      {
                        creativeType: 'CREATIVE_TYPE_OTT',
                        view: 'ott',
                        title: 'OTT',
                      },
                    ] as {
                      creativeType: Creativeservicev1Type
                      view: string
                      title: string
                    }[]).map(({ creativeType, view, title }) => (
                      <LibraryMenuItem
                        kind="text"
                        size="s"
                        iconLeft={getCreativeFaETIcons(creativeType)}
                        onClick={() => {
                          setMenuOpen(false)
                          changeView(view)
                        }}
                        key={`LibraryMenuItem-${view}`}
                      >
                        {title}
                      </LibraryMenuItem>
                    ))}
                  </div>
                  <div className="LibraryHeader__dropdown-item">
                    <LibraryMenuHeader type={6}>Print</LibraryMenuHeader>
                    <LibraryMenuItem
                      kind="text"
                      size="s"
                      iconLeft={getCreativeFaETIcons(
                        'CREATIVE_TYPE_MAIL_PIECE',
                      )}
                      onClick={() => {
                        console.log('DirectMail')
                      }}
                      disabled
                    >
                      Direct Mail
                    </LibraryMenuItem>
                  </div>
                </div>
              }
              align="start"
              position={['bottom']}
            >
              <div className="Popover__button-wrap">
                <Button
                  className="Popover__button-trigger px-4"
                  size="s"
                  iconLeft={
                    <Fa className="mr-1" icon="cloud-arrow-up" size={1} />
                  }
                  onClick={() => setMenuOpen(true)}
                  disabled={isOnHold}
                >
                  Upload/ Create
                </Button>
              </div>
            </Popover>
          ) : (
            // AUDIENCE LIBRARY UPLOAD BUTTON
            <Button
              className="Popover__button--trigger px-4"
              size="s"
              iconLeft={<Fa className="mr-1" icon="cloud-arrow-up" size={1} />}
              onClick={() => onUpload && onUpload()}
              disabled={isOnHold}
            >
              Upload/ Create
            </Button>
          )}
          {type === 'creative' && onCreateNew && (
            <Button
              className="Popover__button--creative-gen px-4"
              size="s"
              iconLeft={<Fa icon="sparkles" size={1} />}
              onClick={() => onCreateNew()}
              disabled={isOnHold}
            >
              Creative Generator
            </Button>
          )}
          {/* API does not allow user to make a folder by itself (need to set folder on an asset in order to place it) 
            Leaving in case this is supported in the future.
        */}
          {/* <Button
          className="Popover__button--folder"
          size="s"
          iconLeft={<Fa icon="folder-plus" size={1} />}
          disabled
        >
          Folder
        </Button> */}
        </div>
      )}
    </div>
  )
}
