import React, { useState } from 'react'
import {
  showErrorMessage,
  Table,
  TableColumnType,
  TextHeader,
} from '@eltoro-ui/components'
import { usePagination } from '@eltoro-ui/hooks'
import { Creativeservicev1Creative, V1CreativeSearch } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { EmptyField, TablePagination } from 'Components'
import { getCreativeFaETIcons, simplifyEnum } from 'Helpers'
import { creativeStatusOptions, creativeTypeOptions } from 'searchOptions'
import { OrgNameCell } from '../../OrgNameCell'

const searchCreativeTableColumns: TableColumnType<V1CreativeSearch>[] = [
  {
    path: 'name',
    label: 'Name',
    filterOn: 'string',
    RowCell: ({ type, name }) => (
      <div className="flex items-center">
        {getCreativeFaETIcons(type || undefined)}
        <span className="ml-1">{name}</span>
      </div>
    ),
  },
  {
    path: 'type',
    label: 'Type',
    filterOn: creativeTypeOptions,
    RowCell: ({ type }) => (type ? simplifyEnum(2, type) : <EmptyField />),
  },
  {
    path: 'status',
    label: 'Status',
    filterOn: creativeStatusOptions,
    RowCell: ({ status }) =>
      status ? simplifyEnum(2, status) : <EmptyField />,
  },
  {
    path: 'orgId',
    label: 'Source Org',
    removeFilter: true,
    RowCell: ({ orgLogo, orgName }) =>
      orgName ? <OrgNameCell name={orgName} logo={orgLogo} /> : <EmptyField />,
  },
  {
    path: 'creativeId',
    label: 'ID',
    removeFilter: true,
  },
]

export const SearchCreativeTable: React.FC<{
  results: V1CreativeSearch[]
  handleRowClick: (clicked: Creativeservicev1Creative) => void
}> = ({ results, handleRowClick }) => {
  const [clicked, setClicked] = useState<V1CreativeSearch>()
  const { creativeServiceApi } = useAppContext()

  const getCreative = async (creative: V1CreativeSearch) => {
    if (!creativeServiceApi || !creative.creativeId || !creative.orgId)
      return undefined
    return creativeServiceApi.advertisingPlatformServiceGetCreative(
      creative.creativeId,
      creative.orgId,
    )
  }

  const creativePagination = usePagination<V1CreativeSearch>({
    pageSize: 5,
    sourceData: results,
  })

  if (!results.length) return null

  return (
    <div className="SearchCreativeTable">
      <TextHeader type={4} className="SearchCreativeTable__header mt-4">
        Creatives ({results.length})
      </TextHeader>
      <div className="p-4">
        {creativePagination.data && (
          <>
            <Table
              columns={searchCreativeTableColumns}
              rows={creativePagination.data}
              onClick={(creative) => {
                getCreative(creative)
                  .then((fullCreative) => {
                    if (fullCreative) {
                      handleRowClick(fullCreative)
                      if (
                        clicked &&
                        creative.creativeId === clicked.creativeId
                      ) {
                        setClicked(undefined)
                      } else setClicked(creative)
                    }
                  })
                  .catch(() =>
                    showErrorMessage(
                      'Error getting creative',
                      `Error getting creative id ${creative.creativeId}`,
                    ),
                  )
              }}
              clicked={clicked}
              // onSort={() => {}}
              // onFilter={() => {}}
            />
            <div className="flex justify-end pt-2">
              <TablePagination pagination={creativePagination} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
