import { Creativeservicev1Creative } from 'next-gen-sdk'
import { CreativeSets } from 'types'

export const bannerFixture: Creativeservicev1Creative = {
  id: 'banner-fixture-1',
  createTime: new Date(),
  updateTime: new Date(),
  orgId: 'fake-org-id',
  name: 'My banner',
  status: 'CREATIVE_STATUS_PROCESSING',
  files: [
    {
      id: 'banner-file-1',
      createTime: new Date(),
      updateTime: new Date(),
      creativeId: 'banner-fixture-1',
      mime: 'image/jpeg',
      size: 200,
      height: 400,
      width: 300,
      type: 'CREATIVE_FILE_TYPE_MEDIA',
      name: 'My banner',
    },
  ],
  category: '',
  orderLines: [],
  thumbnail:
    'https://upload.wikimedia.org/wikipedia/commons/0/0e/Human_Thumbnail.JPG',
  type: 'CREATIVE_TYPE_BANNER',
}

export const banner2Fixture: Creativeservicev1Creative = {
  id: 'banner-fixture-2',
  createTime: new Date(),
  updateTime: new Date(),
  orgId: 'fake-org-id',
  name: 'The Matrix',
  status: 'CREATIVE_STATUS_PROCESSING',
  files: [
    {
      id: 'banner-file-2',
      createTime: new Date(),
      updateTime: new Date(),
      creativeId: 'banner-fixture-2',
      mime: 'image/jpeg',
      size: 200,
      height: 400,
      width: 300,
      type: 'CREATIVE_FILE_TYPE_MEDIA',
      name: 'The Matrix',
    },
  ],
  category: '',
  orderLines: [],
  thumbnail:
    'https://www.gannett-cdn.com/presto/2021/03/02/USAT/a51266de-f2dc-404c-a46a-bcca17c496eb-matrix-reloaded-keanu-reeves.png',
  type: 'CREATIVE_TYPE_NATIVE_BANNER',
}

export const videoFixture: Creativeservicev1Creative = {
  id: 'video-fixture-1',
  createTime: new Date(),
  updateTime: new Date(),
  orgId: 'fake-org-id',
  name: 'My video',
  status: 'CREATIVE_STATUS_PROCESSING',
  files: [
    {
      id: 'video-file-1',
      createTime: new Date(),
      updateTime: new Date(),
      creativeId: 'banner-fixture-1',
      mime: 'video/mp4',
      size: 200,
      height: 400,
      width: 300,
      type: 'CREATIVE_FILE_TYPE_MEDIA',
      name: 'My video',
      duration: 10,
    },
  ],
  category: '',
  orderLines: [],
  thumbnail: 'https://www.w3schools.com/css/mov_bbb.mp4',
  type: 'CREATIVE_TYPE_VIDEO',
}

export const creativeFixtures = [bannerFixture, banner2Fixture, videoFixture]

export const creativeSetFixture: CreativeSets = {
  id: '39349dkdklajf',
  name: 'test creative set',
  click_through: 'http://www.neopets.com',
  creatives: [bannerFixture, banner2Fixture],
  attached: true,
  orderLineID: '3908240982349kdlsjfas',
}
