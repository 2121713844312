import { Fa } from '@eltoro-ui/components'

export const TableRowLoadingIcon = ({ className }: { className?: string }) => {
  return (
    <Fa
      icon="ellipsis"
      size={1}
      className={`TableRowLoadingIcon text-tint-khaki-400 animate-pulse delay-[0s] duration-[2ms] ${className}`}
    />
  )
}
