/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1OrderLine } from '../models/Campaignservicev1OrderLine';
import { HttpFile } from '../http/http';

/**
* The event emitted when the Audience is Removed
*/
export class V1AudienceRemoved {
    'orderLineId'?: string;
    'jobId'?: string;
    'targetId'?: string;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;
    'exclude'?: boolean;
    'orderLinePreUpdate'?: Campaignservicev1OrderLine;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "exclude",
            "baseName": "exclude",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "orderLinePreUpdate",
            "baseName": "order_line_pre_update",
            "type": "Campaignservicev1OrderLine",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1AudienceRemoved.attributeTypeMap;
    }

    public constructor() {
    }
}

