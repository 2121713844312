import { useContext, useEffect, useState } from 'react'
import {
  TextHeader,
  Text,
  Button,
  CollapsibleContainer,
  Fa,
  TextInput,
  Checkbox,
  showErrorMessage,
} from '@eltoro-ui/components'
import { useAuth } from 'react-oidc-context'
import { Targetjobservicev1Audience } from 'next-gen-sdk'
import { Link } from 'react-router-dom'
import { ThemeContext, useAppContext } from 'Contexts'
import { poll, styleTailwind } from 'Helpers'
import { Loading } from 'Components'
import { useSSEListener } from 'Hooks'
import './RetargetingPixel.scss'
import { getApiConfig } from 'Requests'
import classNames from 'classnames'

const RetargetingFormLabel = styleTailwind(
  'div',
  'RetargetingFormLabel text-l text-tint-gray-500 pr-1 leading-none font-bold w-auto',
)

type RetargetingPixelProps = {
  onAddAudienceToAttach?: (audience: Targetjobservicev1Audience) => void
  onRemoveAudienceFromAttach?: (audience: Targetjobservicev1Audience) => void
}

export const RetargetingPixel = ({
  onAddAudienceToAttach,
  onRemoveAudienceFromAttach, // could this ever be needed here? (will pixel audience types ever be available from the library?)
}: RetargetingPixelProps) => {
  const [name, setName] = useState<string>('')
  const [complete, setComplete] = useState<boolean>(false)
  const [jsOpen, setJsOpen] = useState<boolean>(true)
  const [imgOpen, setImgOpen] = useState<boolean>(false)
  const [job, setJob] = useState<Targetjobservicev1Audience>()
  const [imgTag, setImgTag] = useState<string>('')
  const [jsTag, setJsTag] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [locked, setLocked] = useState(false)

  const auth = useAuth()
  const {
    targetServiceApi,
    currentOrg,
    audienceServiceApi,
    roles,
  } = useAppContext()
  const { name: theme } = useContext(ThemeContext)

  const [audienceUpdate, clearAudienceUpdate] = useSSEListener('JobFinished')

  useEffect(() => {
    if (audienceUpdate) {
      const { message } = audienceUpdate
      if (!job?.id || message.audience.id !== job.id) return
      const results = message.audience.result
      if (results.image_pixel) setImgTag(results.image_pixel)
      if (results.script_pixel) setJsTag(results.script_pixel)
      setLoading(false)
      setComplete(true)
      clearAudienceUpdate()
    }
  }, [audienceUpdate, clearAudienceUpdate, job?.id])

  useEffect(() => {
    // After 2 mins and the job hasn't finished, show the queue error
    setTimeout(() => {
      if (job?.status === 'AUDIENCE_STATUS_QUEUED') setError('queue')
    }, 120000)
  }, [job?.status])

  const handleGeneration = async () => {
    if (!currentOrg?.id) return
    const targ = await targetServiceApi?.advertisingPlatformServiceCreateJsonTarget(
      {
        type: 'FILE_TYPE_FILELESS',
        dataSource: 'DATA_SOURCE_CLIENT',
        userId: auth.user?.profile.sub,
        orgId: currentOrg.id,
      },
    )
    if (targ?.id && audienceServiceApi) {
      const job = await audienceServiceApi
        ?.advertisingPlatformServiceCreateAudience(currentOrg.id, {
          type: 'AUDIENCE_TYPE_RETARGETING',
          name,
          targetId: targ.id,
        })
        .then((result) => {
          setLoading(true)
          setComplete(true)
          setJob(result)
          return result
        })
      const validate = (aud?: Targetjobservicev1Audience) =>
        aud?.status !== 'AUDIENCE_STATUS_CREATED'

      const createdAudience = await poll(
        (token) =>
          audienceServiceApi?.advertisingPlatformServiceGetAudience(
            job.id || '',
            currentOrg.id || '',
            token ? getApiConfig(token) : undefined,
          ),
        validate,
        1000,
        900000,
      )
      if (
        !createdAudience ||
        createdAudience.status !== 'AUDIENCE_STATUS_CREATED'
      ) {
        showErrorMessage('Error creating audience', '')
        return
      }
      // Step 4: If job created, add it to the queue
      if (
        createdAudience?.id &&
        createdAudience.status === 'AUDIENCE_STATUS_CREATED'
      ) {
        audienceServiceApi
          ?.advertisingPlatformServiceGenerateAudience(
            createdAudience.id,
            currentOrg.id,
          )
          .then(() => {
            if (onAddAudienceToAttach && createdAudience.id)
              onAddAudienceToAttach(createdAudience)
          })
          .then(() => {
            if (locked && createdAudience.id && currentOrg.id) {
              audienceServiceApi.advertisingPlatformServiceUpdateAudience(
                createdAudience.id,
                currentOrg?.id,
                {
                  locked: locked,
                },
                'locked',
              )
            }
          })
      }
    }
  }

  return (
    <>
      <div
        className={classNames(
          'bg-danger-600 text-l rounded-m mb-2 flex items-center justify-center gap-2 p-4 text-white',
          { 'bg-danger-300': theme === 'darkBlue' || theme === 'dark' },
        )}
      >
        We currently do not support this audience type for political
        advertising.
      </div>
      <div className="RetargetingPixel grid grid-cols-1">
        {!complete && (
          <div className="RetargetingForm mx-auto flex w-full flex-col gap-2 pt-4">
            <label htmlFor="name" className="flex items-center gap-4">
              <RetargetingFormLabel
                data-tooltip="top"
                aria-label="Name that will be displayed in your library"
              >
                Name your audience
              </RetargetingFormLabel>
              <TextInput
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={255}
              />
            </label>
            <div className="flex justify-end gap-8 py-4">
              {roles && roles.includes('nextgen_admin') && (
                <Checkbox
                  label="Lock audience"
                  checked={locked}
                  onChange={(val) => {
                    setLocked(val)
                  }}
                />
              )}
              <Button
                kind="primary"
                type="button"
                onClick={() => handleGeneration()}
                disabled={name.length < 2 ? true : false}
              >
                Generate
              </Button>
            </div>
          </div>
        )}
        {loading && (
          <div className="mx-auto pt-6">
            <div>
              <TextHeader
                on="white"
                kind="subdued"
                type={2}
                className="text-center"
              >
                Your Retargeting pixel is being generated
              </TextHeader>
            </div>
            <Loading />
            {error === 'queue' && job?.status !== 'AUDIENCE_STATUS_READY' && (
              <div>
                <TextHeader on="white" kind="subdued" type={2}>
                  Hang Tight! We&#39;re generating your pixel but its taking
                  longer than expected.
                </TextHeader>
                <div className="text-center">
                  <Text on="white" kind="subdued" size="s">
                    You may leave this page and will be able to find your pixel
                    in the{' '}
                    <Link
                      className="text-secondary-500 font-bold hover:underline"
                      to="/audiences/audience-library"
                    >
                      Audience Library
                    </Link>{' '}
                    once it&#39;s finished.
                  </Text>
                </div>
              </div>
            )}
          </div>
        )}
        {complete && !loading && (
          <div className="RetargetingPixel__tracking-section">
            <div className="grid grid-cols-1 gap-4 pt-8">
              <TextHeader on="white" kind="subdued" type={4}>
                Add tracking code to your website
              </TextHeader>
              <Text on="white" kind="subdued" className="pb-4">
                Two options are provided below. Select the one that works best
                for your website. Place the pixel code before your site&#39;s
                closing body tag.{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://eltoro.com/retargeting-web-traffic/"
                  className="font-bold"
                >
                  Read El Toro&#39;s article on retargeting.
                </a>
              </Text>
            </div>
            <div
              className="flex"
              role="button"
              onClick={() => setJsOpen(!jsOpen)}
              tabIndex={0}
              onKeyDown={() => setJsOpen(!jsOpen)}
            >
              JavaScript Code{' '}
              <Fa
                className="text-primary -mt-1 pb-2"
                icon={`caret-${jsOpen ? 'down' : 'up'}`}
                size={1}
              />
            </div>
            <CollapsibleContainer isOpen={jsOpen}>
              <textarea
                className="TextInput TextInput__input border-secondary-500 w-full border-4"
                value={jsTag}
                disabled
              />
              <div className="flex  w-full justify-end pr-6 pt-4">
                <Button
                  type="button"
                  onClick={() => {
                    if (jsTag) navigator.clipboard.writeText(jsTag)
                  }}
                >
                  Copy
                </Button>
              </div>
            </CollapsibleContainer>
            <div
              className="mt-4 flex"
              role="button"
              onClick={() => setImgOpen(!imgOpen)}
              tabIndex={0}
              onKeyDown={() => setImgOpen(!imgOpen)}
            >
              Image Code{' '}
              <Fa
                className="text-primary -mt-1 pb-2"
                icon={`caret-${imgOpen ? 'down' : 'up'}`}
                size={1}
              />
            </div>
            <CollapsibleContainer isOpen={imgOpen}>
              <textarea
                className="TextInput TextInput__input w-full"
                value={imgTag}
                disabled
              />
              <div className="flex w-full justify-end pr-6 pt-4">
                <Button
                  type="button"
                  onClick={() => {
                    if (imgTag) navigator.clipboard.writeText(imgTag)
                  }}
                >
                  Copy
                </Button>
              </div>
            </CollapsibleContainer>
          </div>
        )}
      </div>
    </>
  )
}
