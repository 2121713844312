/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See registration_form in [Political Content](https://docs.xandr.com/bundle/xandr-api/page/insertion-order-service.html#InsertionOrderService-PoliticalContent) in Xandr's documentation.
*/
export class V3RegistrationForm {
    /**
    * The file path of the registration form.
    */
    'filePath'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "filePath",
            "baseName": "file_path",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3RegistrationForm.attributeTypeMap;
    }

    public constructor() {
    }
}

