import { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Fa,
  Loader,
  Select,
  SelectOptionType,
  Table,
  showErrorMessage,
} from '@eltoro-ui/components'
import { CopyButton, EmptyField } from 'Components'
import { useAppContext } from 'Contexts'
import {
  Billingservicev1CommissionGroup,
  Orgmanagerv1CommissionGroup,
} from 'next-gen-sdk'
import { SectionCollapse } from '../SectionCollapse'

export const SalesExecTable = ({
  loading,
  setLoading,
  isOpen,
  toggleOpen,
  existingCommGroup,
}: {
  loading: boolean
  setLoading: (loading: boolean) => void
  isOpen: boolean
  toggleOpen: () => void
  existingCommGroup?: Orgmanagerv1CommissionGroup
}) => {
  const [editing, setEditing] = useState(false)
  const [allCommGroups, setAllCommGroups] = useState<
    Billingservicev1CommissionGroup[]
  >()
  const [selectedCommGroup, setSelectedCommGroup] = useState<SelectOptionType>()
  const {
    groups,
    isAdmin,
    billingServiceApi,
    orgServiceApi,
    currentOrg,
    setCurrentOrg,
  } = useAppContext()

  useEffect(() => {
    if (isAdmin)
      billingServiceApi
        ?.advertisingPlatformServiceListCommissionGroups()
        .then(({ commissionGroups }) => setAllCommGroups(commissionGroups))
        .catch(() => showErrorMessage('Error loading commission groups', ''))
  }, [isAdmin])

  const handleSaveCommGroup = async () => {
    if (!orgServiceApi || !currentOrg?.id || !selectedCommGroup?.value) return
    setLoading(true)
    try {
      await orgServiceApi.advertisingPlatformServiceAddCommissionGroup(
        currentOrg.id,
        {
          recordNumber: selectedCommGroup.value,
        },
      )
      // refresh the org
      if (!currentOrg?.id || !orgServiceApi) return
      const updatedOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
        currentOrg.id,
      )
      setCurrentOrg(updatedOrg)
    } catch {
      showErrorMessage('Error adding sales executives to org', '')
    } finally {
      setLoading(false)
    }
  }

  const isAccountingOrDev =
    groups?.includes('/Accounting') || groups?.includes('/Dev')

  return (
    <SectionCollapse
      title={`${editing ? 'Edit s' : 'S'}ales executives ${
        existingCommGroup?.users && !editing
          ? `(${existingCommGroup.users.length})`
          : ''
      }`}
      editButtons={
        isAdmin ? (
          <div className="SalesExecTable__button-row flex gap-2">
            <Button
              iconLeft={<Fa icon={editing ? 'xmark' : 'edit'} size={1} />}
              onClick={() => setEditing(!editing)}
            >
              {editing ? 'Close' : 'Edit'}
            </Button>
            {editing && (
              <Button
                iconLeft={<Fa icon="save" size={1} />}
                onClick={handleSaveCommGroup}
                disabled={!allCommGroups || loading}
              >
                Save
              </Button>
            )}
          </div>
        ) : null
      }
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      className="SalesExecTable"
      sectionName="Sales Executives"
    >
      {(() => {
        if (loading) return <Loader />
        if (isAdmin && editing) {
          return (
            <div className="SalesExecTable__edit mt-4 flex max-w-lg flex-col gap-4">
              <p>
                <strong>Select a single or paired sales executive:</strong>
              </p>
              <Select
                clearButton={false}
                enableSearch
                placeholder={
                  !allCommGroups
                    ? 'Loading commission groups'
                    : 'Select commission group'
                }
                disabled={!allCommGroups}
                options={(allCommGroups || []).reduce(
                  (acc: SelectOptionType[], commGroup) => {
                    if (!commGroup.recordNo || !commGroup.name) return acc
                    return [
                      ...acc,
                      {
                        label: commGroup.name.replaceAll(/^(CGA|CG)-/g, ''),
                        value: commGroup.recordNo,
                      },
                    ]
                  },
                  [],
                )}
                selectedOption={selectedCommGroup}
                onChange={setSelectedCommGroup}
                maxHeight={250}
              />
            </div>
          )
        }

        if (
          !existingCommGroup ||
          !existingCommGroup.users ||
          !existingCommGroup.users.length ||
          !existingCommGroup.id
        )
          return (
            <div className="SalesExecTable__empty-state bg-grey-50 mb-2 flex items-center justify-center gap-4 p-4 mt-2 rounded">
              <Fa icon="users" type="duotone" size={4} />
              <p className="font-bold">No sales executives found.</p>
            </div>
          )

        return (
          <>
            {isAccountingOrDev && existingCommGroup.id && (
              <div className="SalesExecTable__comm-group-id flex items-center gap-2 py-2">
                <p>
                  <strong>Commission Group Id:&nbsp;</strong>
                  {existingCommGroup.id}
                </p>
                <CopyButton
                  text={existingCommGroup.id}
                  type="commission group id"
                />
              </div>
            )}
            <Table
              columns={[
                {
                  path: 'name',
                  label: 'Name',
                  removeFilter: true,
                  RowCell: ({ firstName, lastName }) => {
                    return (
                      <div className="ml-2 flex items-center gap-2">
                        <Avatar
                          name={`${firstName} ${lastName}`}
                          size="s"
                          className="p-2"
                          rounded
                        />
                        {`${firstName} ${lastName}`}
                      </div>
                    )
                  },
                },
                {
                  path: 'email',
                  label: 'Email Address',
                  removeFilter: true,
                  RowCell: ({ email }) =>
                    email ? (
                      <div className="flex items-center justify-between">
                        <p>{email}</p>
                        <CopyButton text={email} type="email" />
                      </div>
                    ) : (
                      <EmptyField />
                    ),
                },
                {
                  path: 'id',
                  label: 'ID',
                  removeFilter: true,
                  RowCell: ({ id }) =>
                    id ? (
                      <div className="flex items-center justify-between">
                        <p>{id}</p>
                        <CopyButton text={id} type="ID" className="mr-2" />
                      </div>
                    ) : (
                      <EmptyField />
                    ),
                },
              ]}
              rows={existingCommGroup.users || []}
            />
          </>
        )
      })()}
    </SectionCollapse>
  )
}
