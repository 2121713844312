/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1AdType } from '../models/Campaignservicev1AdType';
import { HttpFile } from '../http/http';

/**
* The event emitted when OrderLine ad_type is set
*/
export class V1OrderLineAdTypeSet {
    /**
    * ID of the Order Line.
    */
    'orderLineId'?: string;
    /**
    * ID of the Org.
    */
    'orgId'?: string;
    'adType'?: Campaignservicev1AdType;
    'requestId'?: string;
    'timestamp'?: Date;
    'userId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Campaignservicev1AdType",
            "format": ""
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineAdTypeSet.attributeTypeMap;
    }

    public constructor() {
    }
}

