import { useState } from 'react'
import {
  Text,
  Button,
  Fa,
  Checkbox,
  showSuccessMessage,
  Tooltip,
} from '@eltoro-ui/components'
import {
  Targetjobservicev1Audience,
  V1AudienceType,
  V1AudienceSubType,
} from 'next-gen-sdk'
import { userFriendlyAudienceName, getJobCounts } from 'Helpers'
import { useCampaignContext, useAppContext } from 'Contexts'
import { WarningModal } from 'Components'
import './AudienceCard.scss'

const SubJobRow = ({ job }: { job: Targetjobservicev1Audience }) => {
  const {
    orderLines,
    setRefresh,
    attachedAudiences,
    handleRemoveAudience,
  } = useCampaignContext()
  const { currentOrg, campaignServiceApi } = useAppContext()
  const getJobName = (type: V1AudienceType | V1AudienceSubType) => {
    if (type === 'AUDIENCE_TYPE_ADDRESS') return 'Mapped Homes'
    if (type === 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES') return 'Mapped Devices'
    if (type === 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN')
      return 'Other Devices In Home'
    if (type === 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES')
      return 'Unmapped Devices'
    return ''
  }
  const getCountText = (type: V1AudienceType | V1AudienceSubType) => {
    if (
      type === 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES' ||
      type === 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN'
    )
      return 'device IDs'
    if (type === 'AUDIENCE_TYPE_ADDRESS') return 'unique households'
    if (type === 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES')
      return 'device IDs without households'
    return ''
  }
  const isAttached =
    attachedAudiences?.some((aud) => aud.id === job.id) || false

  const referenceType =
    job.type === 'AUDIENCE_TYPE_ADDRESS' ? job.type : job.subType

  return (
    <li key={job.id} className="AudienceCard__SubJobRow flex items-center">
      <Checkbox
        checked={isAttached}
        onChange={() => {
          if (isAttached) {
            if (job.id) handleRemoveAudience(job.id)
          } else {
            Promise.all(
              (orderLines || []).map((currentOL) => {
                if (currentOL.id && job.id && currentOrg?.id) {
                  return campaignServiceApi?.advertisingPlatformServiceAddAudience(
                    currentOL.id,
                    { audienceId: job.id, orgId: currentOrg.id },
                  )
                }
                return Promise.resolve({})
              }),
            ).then(() => {
              showSuccessMessage(
                'Audience Attached',
                'The audience has been attached ot the order line',
              )
              setRefresh(true)
            })
          }
        }}
        label={
          <div className="AudienceCard__SubJobRow__count flex flex-col">
            <p>
              {job.result?.matched?.toLocaleString()}{' '}
              {referenceType && job.type && getCountText(referenceType)}
            </p>
            <p className="AudienceCard__SubJobRow__name text-grey-500 text-xs">
              {referenceType && job.type && getJobName(referenceType)}
            </p>
          </div>
        }
      />
    </li>
  )
}

const SubJobListing = ({ job }: { job: Targetjobservicev1Audience }) => {
  if (!job.audiences) return null
  const mappedDeviceIDsJob = job.audiences?.find(
    (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES',
  )
  const mappedHomesJob = job.audiences?.find(
    (subJob) => subJob.type === 'AUDIENCE_TYPE_ADDRESS',
  )
  const otherDeviceIDsInHomeJob = job.audiences?.find(
    (subJob) =>
      subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN',
  )
  const unmappedDeviceIDsJob = job.audiences?.find(
    (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES',
  )
  const { deviceIdCount, addressCount } = handleVRSubJobCounts(job)
  return (
    <div className="AudienceCard__SubJobListing text-left">
      <p className="text-xs font-bold">
        {deviceIdCount.toLocaleString()} Device IDs
      </p>
      <p className="text-xs font-bold">
        {addressCount.toLocaleString()} IP Addresses
      </p>
      <ul>
        {/* Mapped Devices */}
        {mappedDeviceIDsJob && <SubJobRow job={mappedDeviceIDsJob} />}
        {/* Mapped Homes */}
        {mappedHomesJob && <SubJobRow job={mappedHomesJob} />}
        {/* Other Devices in Home */}
        {otherDeviceIDsInHomeJob && <SubJobRow job={otherDeviceIDsInHomeJob} />}
        {/* Unmapped Devices */}
        {unmappedDeviceIDsJob && <SubJobRow job={unmappedDeviceIDsJob} />}
      </ul>
    </div>
  )
}

const handleVRSubJobCounts = (job: Targetjobservicev1Audience) => {
  if (job.audiences) {
    const deviceIdCount = job.audiences.reduce(
      (acc: number, subJob: Targetjobservicev1Audience) => {
        const { result } = subJob
        if (result && subJob.type === 'AUDIENCE_TYPE_DEVICE' && result.matched)
          return acc + result.matched
        return acc
      },
      0,
    )
    const addressCount =
      job.audiences.find((j) => j.type === 'AUDIENCE_TYPE_ADDRESS')?.result
        ?.matched || 0

    return { deviceIdCount, addressCount }
  }
  return { deviceIdCount: 0, addressCount: 0 }
}

export const AudienceCard = ({
  audience,
  onRemove,
}: {
  audience: Targetjobservicev1Audience
  onRemove?: (ids: string[]) => void
}) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false)

  const getQuoteStatus = () => {
    if (
      audience?.status === 'AUDIENCE_STATUS_CANCELLED' ||
      audience?.status === 'AUDIENCE_STATUS_ERRORED'
    ) {
      return (
        <div className="AudienceCard__errored">
          <Fa icon="exclamation-circle" size={1} />
          <span>
            {audience?.status === 'AUDIENCE_STATUS_ERRORED'
              ? 'Errored'
              : 'Cancelled'}
          </span>
        </div>
      )
    }
    if (audience.result) {
      const { processCompleted } = audience.result
      const numberMatched = getJobCounts(audience)
      if (processCompleted === 1) {
        const getSegmentName = () => {
          if (
            audience.type === 'AUDIENCE_TYPE_MAID' ||
            audience.type === 'AUDIENCE_TYPE_VR'
          )
            return ' Device IDs'
          if (audience.type === 'AUDIENCE_TYPE_DC') return ' Neighbors'

          return ' IP Addresses'
        }
        return (
          <span className="text-grey-500 text-xs">
            {numberMatched
              ? `${numberMatched.toLocaleString()}${getSegmentName()}`
              : ''}
          </span>
        )
      }
    }
    return (
      <div className="AudienceCard__quote-status text-grey-500 flex animate-pulse items-center gap-1 text-xs">
        <Fa icon="cog" size={1} className="min-w-[unset] !text-xs" />
        <span>Quoting</span>
      </div>
    )
  }

  const getJobType = () => {
    if (audience?.type) {
      return (
        <span className="text-grey-500 text-s">
          {userFriendlyAudienceName(audience.type)}
        </span>
      )
    }
    return null
  }

  return (
    <>
      <div className="AudienceCard group even:bg-primer">
        <div className="AudienceCard__body pr-2">
          <div className="AudienceCard__name-wrapper">
            <p className="text-l text-primary truncate font-bold">
              {audience?.name || audience?.id || 'No name'}
            </p>
          </div>
          {getJobType()}
          {audience?.type === 'AUDIENCE_TYPE_VR' &&
          audience?.status === 'AUDIENCE_STATUS_COMPLETED' ? (
            <SubJobListing job={audience} />
          ) : (
            getQuoteStatus()
          )}
        </div>
        {onRemove && (
          <Tooltip
            className="AudienceCard__remove-button -mr-1"
            position="bottomLeft"
            content="Click to delete audience"
          >
            <Button
              className="group-hover:bg-danger-600 text-primary group-hover:!text-primer"
              iconOnly={<Fa size={1} icon="times" />}
              kind="text"
              size="s"
              rounded
              onClick={() => setWarningModalOpen(true)}
            />
          </Tooltip>
        )}
      </div>
      {warningModalOpen && (
        <WarningModal
          onConfirm={() => {
            if (onRemove && audience.id) {
              if (
                audience.type === 'AUDIENCE_TYPE_VR' &&
                audience.audiences &&
                audience.audiences.length
              ) {
                // remove the sub audiences
                onRemove(
                  audience.audiences.reduce((acc: string[], aud) => {
                    if (aud.id) return [...acc, aud.id]
                    return acc
                  }, []),
                )
              } else {
                onRemove([audience.id])
              }
            }
            setWarningModalOpen(false)
          }}
          onCancel={() => setWarningModalOpen(false)}
        >
          <Text on="white" kind="subdued" size="l">
            Are you sure you want to remove
            <span className="break-all">
              <strong>{` ${audience.name || 'this audience'} `}</strong>
            </span>
            from this campaign and its order lines?
          </Text>
        </WarningModal>
      )}
    </>
  )
}
