/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Campaign } from '../models/Deployservicev3Campaign';
import { Deployservicev3Creative } from '../models/Deployservicev3Creative';
import { Deployservicev3Deployment } from '../models/Deployservicev3Deployment';
import { Deployservicev3Status } from '../models/Deployservicev3Status';
import { V3Segment } from '../models/V3Segment';
import { HttpFile } from '../http/http';

export class TheAdvertiserToCreate {
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    /**
    * The reference ID for the advertiser.
    */
    'refId'?: string;
    /**
    * The name of the advertiser.
    */
    'name'?: string;
    /**
    * The campaigns related to the advertiser.
    */
    'campaigns'?: Array<Deployservicev3Campaign>;
    /**
    * The creatives related to the advertiser.
    */
    'creatives'?: Array<Deployservicev3Creative>;
    /**
    * The segments related to the advertiser.
    */
    'segments'?: Array<V3Segment>;
    /**
    * The deployments related to the advertiser.
    */
    'deployments'?: Array<Deployservicev3Deployment>;
    'status'?: Deployservicev3Status;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaigns",
            "baseName": "campaigns",
            "type": "Array<Deployservicev3Campaign>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Deployservicev3Creative>",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "segments",
            "type": "Array<V3Segment>",
            "format": ""
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Deployservicev3Deployment>",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Deployservicev3Status",
            "format": ""
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return TheAdvertiserToCreate.attributeTypeMap;
    }

    public constructor() {
    }
}

