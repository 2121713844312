import { Fa, Text, Tooltip } from '@eltoro-ui/components'

export const HiddenSegment = () => {
  return (
    <div className="HiddenSegment flex w-full items-center justify-between gap-1 border-t border-tint-gray-200 pt-2">
      <span className="HiddenSegment--text flex items-center">
        <Fa
          className="PreviewPanel__user-secret-icon text-primary mr-1 h-8"
          icon="user-secret"
          size={1}
        />
        <Text className="text-m" tag="p">
          Audience details are hidden.
        </Text>
      </span>
      <Tooltip
        classNameTooltip="bottom-0 top-auto"
        position="topLeft"
        content="This audience is protected by data privacy laws."
      >
        <Fa
          color="grey"
          type="light"
          className="PreviewPanel__helpButton-icon ml-auto"
          icon="circle-question"
          size={1}
        />
      </Tooltip>
    </div>
  )
}
