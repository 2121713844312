/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3GovernmentLevel } from '../models/V3GovernmentLevel';
import { V3RegistrationForm } from '../models/V3RegistrationForm';
import { HttpFile } from '../http/http';

/**
* The fields necessary for serving political advertisements.
*/
export class V1PoliticalTransparency {
    'electionDates'?: string;
    'usfecid'?: string;
    /**
    * Name of the person, group, Organization, or business that is advertising (the client that is paying you).
    */
    'orgName'?: string;
    /**
    * First address line of the org.
    */
    'orgAddress1'?: string;
    /**
    * Second address line of the org.
    */
    'orgAddress2'?: string;
    /**
    * City of the org.
    */
    'orgCity'?: string;
    /**
    * Region of the org.
    */
    'orgRegion'?: string;
    /**
    * Postal Code of the org.
    */
    'orgPostalCode'?: string;
    /**
    * Country of the org.
    */
    'orgCountry'?: string;
    /**
    * Phone Code of the org.
    */
    'orgPhoneCode'?: string;
    /**
    * Phone Number of the org.
    */
    'orgPhone'?: string;
    /**
    * Treasurer for committee purchasing the ads.
    */
    'treasurerName'?: string;
    /**
    * How the political org pays you, options are “Direct Debit”, “Check”, “Credit Card”, “Other”.
    */
    'paymentMethodType'?: string;
    /**
    * The candidate or ballot initiative that is supported or opposed.
    */
    'politicalObjective'?: string;
    /**
    * Required if “Other” is selected for payment_method_type, details on how you are being paid for political advertising.
    */
    'paymentMethodOther'?: string;
    /**
    * Bool that verifies whether it is an expenditure committee.
    */
    'isExpenditureCommittee'?: boolean;
    'registrationForm'?: V3RegistrationForm;
    /**
    * Specifies that the Xandr member has certified that the political_content info provided is accurate & up-to-date, and that Xandr is relying on the accuracy of information provided.
    */
    'isAccuracyAcknowledged'?: boolean;
    'governmentLevel'?: V3GovernmentLevel;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "electionDates",
            "baseName": "election_dates",
            "type": "string",
            "format": ""
        },
        {
            "name": "usfecid",
            "baseName": "usfecid",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgAddress1",
            "baseName": "org_address_1",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgAddress2",
            "baseName": "org_address_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgCity",
            "baseName": "org_city",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgRegion",
            "baseName": "org_region",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPostalCode",
            "baseName": "org_postal_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgCountry",
            "baseName": "org_country",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPhoneCode",
            "baseName": "org_phone_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPhone",
            "baseName": "org_phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "treasurerName",
            "baseName": "treasurer_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentMethodType",
            "baseName": "payment_method_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "politicalObjective",
            "baseName": "political_objective",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentMethodOther",
            "baseName": "payment_method_other",
            "type": "string",
            "format": ""
        },
        {
            "name": "isExpenditureCommittee",
            "baseName": "is_expenditure_committee",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "registrationForm",
            "baseName": "registration_form",
            "type": "V3RegistrationForm",
            "format": ""
        },
        {
            "name": "isAccuracyAcknowledged",
            "baseName": "is_accuracy_acknowledged",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "governmentLevel",
            "baseName": "government_level",
            "type": "V3GovernmentLevel",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1PoliticalTransparency.attributeTypeMap;
    }

    public constructor() {
    }
}

