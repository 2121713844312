import { Campaignservicev1OrderLine, V1StatsResponse } from 'next-gen-sdk'

export const statMakerForEnvironment = (
  orderLine: Campaignservicev1OrderLine,
) => {
  if (/localhost/.test(window.location.href)) {
    if (orderLine.creatives && orderLine.creatives[0]) {
      if (orderLine.creatives[0].type === 'CREATIVE_TYPE_HTML5') {
        return BannerStatObj
      }
      if (orderLine.creatives[0].type === 'CREATIVE_TYPE_BANNER') {
        return BannerTwoStatObj
      }
      if (orderLine.creatives[0].type === 'CREATIVE_TYPE_VIDEO') {
        return VideoStatObj
      }
    }
  }
  if (
    process.env.REACT_APP_ENV === 'development' ||
    /dev/.test(window.location.href)
  ) {
    if (orderLine.creatives && orderLine.creatives[0]) {
      if (
        orderLine.creatives[0].type === 'CREATIVE_TYPE_HTML5' &&
        orderLine.startTime &&
        orderLine.endTime
      ) {
        BannerStatObj.details.start = orderLine.startTime.toISOString()
        BannerStatObj.details.end = orderLine.endTime.toISOString()

        return BannerStatObj
      }
      if (
        orderLine.creatives[0].type === 'CREATIVE_TYPE_BANNER' &&
        orderLine.startTime &&
        orderLine.endTime
      ) {
        BannerTwoStatObj.details.start = orderLine.startTime.toISOString()
        BannerTwoStatObj.details.end = orderLine.endTime.toISOString()
        return BannerTwoStatObj
      }
      if (
        orderLine.creatives[0].type === 'CREATIVE_TYPE_BANNER' &&
        orderLine.startTime &&
        orderLine.endTime
      ) {
        VideoStatObj.details.start = orderLine.startTime.toISOString()
        VideoStatObj.details.end = orderLine.endTime.toISOString()
        return VideoStatObj
      }
    }
  }
  return BannerStatObj
}
export const VideoStatObj = {
  results: [
    {
      results: [
        {
          start: '2024-02-28T00:00:00.000Z',
          end: '2024-02-28T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-02-29T00:00:00.000Z',
          end: '2024-02-29T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-01T00:00:00.000Z',
          end: '2024-03-01T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-02T00:00:00.000Z',
          end: '2024-03-02T23:59:59.000Z',
          clicks: 1,
          completions: 37,
          conversions: 0,
          imps: 60,
          impsViewed: 0,
          pcts25: 46,
          pcts50: 44,
          pcts75: 40,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-03T00:00:00.000Z',
          end: '2024-03-03T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-04T00:00:00.000Z',
          end: '2024-03-04T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-05T00:00:00.000Z',
          end: '2024-03-05T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-06T00:00:00.000Z',
          end: '2024-03-06T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
      ],
      totals: {
        clicks: 1,
        completions: 37,
        conversions: 0,
        imps: 60,
        impsViewed: 0,
        pcts25: 46,
        pcts50: 44,
        pcts75: 40,
        postClickConvs: 0,
        postViewConvs: 0,
        starts: 0,
        viewMeasuredImps: 0,
      },
      details: {
        resultsCount: 8,
        ids: {
          orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
          campaignId: '',
          orderLineId: 'cnh29f9l5nhulp51sul0',
          creativeId: '',
        },
      },
    },
  ],
  totals: {
    clicks: 1,
    completions: 37,
    conversions: 0,
    imps: 60,
    impsViewed: 0,
    pcts25: 46,
    pcts50: 44,
    pcts75: 40,
    postClickConvs: 0,
    postViewConvs: 0,
    starts: 0,
    viewMeasuredImps: 0,
  },
  details: {
    timezone: 'UTC',
    hourCount: 192,
    resultsCount: 1,
    levelOfDetail: ['order_line_id'],
    ids: [
      {
        orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
        campaignId: '',
        orderLineId: 'cnh29f9l5nhulp51sul0',
        creativeId: '',
      },
    ],
    start: '2024-02-28T00:00:00.000Z',
    end: '2024-03-06T23:59:59.000Z',
  },
}

export const BannerStatObj = {
  results: [
    {
      results: [
        {
          start: '2024-02-28T00:00:00.000Z',
          end: '2024-02-28T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-02-29T00:00:00.000Z',
          end: '2024-02-29T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-01T00:00:00.000Z',
          end: '2024-03-01T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 6,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-02T00:00:00.000Z',
          end: '2024-03-02T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 18,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-03T00:00:00.000Z',
          end: '2024-03-03T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 18,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-04T00:00:00.000Z',
          end: '2024-03-04T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 18,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-05T00:00:00.000Z',
          end: '2024-03-05T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 18,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-06T00:00:00.000Z',
          end: '2024-03-06T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
      ],
      totals: {
        clicks: 0,
        completions: 0,
        conversions: 0,
        imps: 78,
        impsViewed: 0,
        pcts25: 0,
        pcts50: 0,
        pcts75: 0,
        postClickConvs: 0,
        postViewConvs: 0,
        starts: 0,
        viewMeasuredImps: 0,
      },
      details: {
        resultsCount: 8,
        ids: {
          orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
          campaignId: '',
          orderLineId: 'cnh004hl5nhulp51suc0',
          creativeId: '',
        },
      },
    },
  ],
  totals: {
    clicks: 0,
    completions: 0,
    conversions: 0,
    imps: 78,
    impsViewed: 0,
    pcts25: 0,
    pcts50: 0,
    pcts75: 0,
    postClickConvs: 0,
    postViewConvs: 0,
    starts: 0,
    viewMeasuredImps: 0,
  },
  details: {
    timezone: 'UTC',
    hourCount: 192,
    resultsCount: 1,
    levelOfDetail: ['orderLineId'],
    ids: [
      {
        orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
        campaignId: '',
        orderLineId: 'cnh004hl5nhulp51suc0',
        creativeId: '',
      },
    ],
    start: '2024-02-28T00:00:00.000Z',
    end: '2024-03-06T23:59:59.000Z',
  },
}

export const BannerTwoStatObj = {
  results: [
    {
      results: [
        {
          start: '2024-02-28T00:00:00.000Z',
          end: '2024-02-28T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-02-29T00:00:00.000Z',
          end: '2024-02-29T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-01T00:00:00.000Z',
          end: '2024-03-01T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-02T00:00:00.000Z',
          end: '2024-03-02T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-03T00:00:00.000Z',
          end: '2024-03-03T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 0,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-04T00:00:00.000Z',
          end: '2024-03-04T23:59:59.000Z',
          clicks: 4,
          completions: 0,
          conversions: 0,
          imps: 113,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-05T00:00:00.000Z',
          end: '2024-03-05T23:59:59.000Z',
          clicks: 2,
          completions: 0,
          conversions: 0,
          imps: 108,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
        {
          start: '2024-03-06T00:00:00.000Z',
          end: '2024-03-06T23:59:59.000Z',
          clicks: 0,
          completions: 0,
          conversions: 0,
          imps: 6,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
      ],
      totals: {
        clicks: 6,
        completions: 0,
        conversions: 0,
        imps: 227,
        impsViewed: 0,
        pcts25: 0,
        pcts50: 0,
        pcts75: 0,
        postClickConvs: 0,
        postViewConvs: 0,
        starts: 0,
        viewMeasuredImps: 0,
      },
      details: {
        resultsCount: 8,
        ids: {
          orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
          campaignId: '',
          orderLineId: 'cnh4pu9l5nhulp51sut0',
          creativeId: '',
        },
      },
    },
  ],
  totals: {
    clicks: 6,
    completions: 0,
    conversions: 0,
    imps: 227,
    impsViewed: 0,
    pcts25: 0,
    pcts50: 0,
    pcts75: 0,
    postClickConvs: 0,
    postViewConvs: 0,
    starts: 0,
    viewMeasuredImps: 0,
  },
  details: {
    timezone: 'UTC',
    hourCount: 192,
    resultsCount: 1,
    levelOfDetail: ['orderLineId'],
    ids: [
      {
        orgId: '8f570ff1-a9c7-4b9a-93bb-3ac507573108',
        campaignId: '',
        orderLineId: 'cnh4pu9l5nhulp51sut0',
        creativeId: '',
      },
    ],
    start: '2024-02-28T00:00:00.000Z',
    end: '2024-03-06T23:59:59.000Z',
  },
}
export const creativeStatMaker = (
  orderLine: Campaignservicev1OrderLine,
): V1StatsResponse => {
  const { creatives } = orderLine

  // for each creative, populate results with... results
  const results = (creatives || [])?.map((c) => {
    const clicks = Math.floor(Math.random() * 20)
    const imps = Math.floor(Math.random() * 3000)
    return {
      results: [
        {
          start: orderLine.startTime?.toISOString(),
          end: new Date().toISOString(),
          clicks: clicks,
          completions: 0,
          conversions: 0,
          imps: imps,
          impsViewed: 0,
          pcts25: 0,
          pcts50: 0,
          pcts75: 0,
          postClickConvs: 0,
          postViewConvs: 0,
          starts: 0,
          viewMeasuredImps: 0,
        },
      ],
      totals: {
        clicks: clicks,
        completions: 0,
        conversions: 0,
        imps: imps,
        impsViewed: 0,
        pcts25: 0,
        pcts50: 0,
        pcts75: 0,
        postClickConvs: 0,
        postViewConvs: 0,
        starts: 0,
        viewMeasuredImps: 0,
      },
      details: {
        resultsCount: 1,
        ids: {
          orgId: orderLine.orgId,
          campaignId: orderLine.campaign?.id,
          orderLineId: orderLine.id,
          creativeId: c.id,
        },
      },
    }
  })

  return {
    results,
    totals: {
      clicks: 308,
      completions: 0,
      conversions: 0,
      imps: 110796,
      impsViewed: 0,
      pcts25: 0,
      pcts50: 0,
      pcts75: 0,
      postClickConvs: 0,
      postViewConvs: 0,
      starts: 0,
      viewMeasuredImps: 0,
    },
    details: {
      timezone: 'UTC',
      hourCount: 192,
      resultsCount: 13,
      levelOfDetail: ['creative_id'],
      start: '2024-06-12T00:00:00.000Z',
      end: '2024-06-19T23:59:59.999Z',
      ids: (creatives || []).map((c) => ({
        orgId: orderLine.orgId,
        campaignId: '',
        orderLineId: orderLine.id,
        creativeId: c.id,
      })),
    },
  }
}
