/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1JWT {
    'accessToken'?: string;
    'idToken'?: string;
    'expiresIn'?: number;
    'refreshExpiresIn'?: number;
    'refreshToken'?: string;
    'tokenType'?: string;
    'notBeforePolicy'?: number;
    'sessionState'?: string;
    'scope'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "accessToken",
            "baseName": "access_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "idToken",
            "baseName": "id_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "expiresIn",
            "baseName": "expires_in",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "refreshExpiresIn",
            "baseName": "refresh_expires_in",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "refreshToken",
            "baseName": "refresh_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "tokenType",
            "baseName": "token_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "notBeforePolicy",
            "baseName": "not_before_policy",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "sessionState",
            "baseName": "session_state",
            "type": "string",
            "format": ""
        },
        {
            "name": "scope",
            "baseName": "scope",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1JWT.attributeTypeMap;
    }

    public constructor() {
    }
}

