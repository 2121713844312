/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1Stats {
    /**
    * Id of the stat
    */
    'id'?: string;
    /**
    * RunID of the stat
    */
    'runId'?: string;
    /**
    * RunDate of the stat
    */
    'runDate'?: Date;
    /**
    * Hits this run
    */
    'hits'?: number;
    /**
    * hits that were cellular
    */
    'filteredCellularHits'?: number;
    /**
    * whether the files were sent to the server
    */
    'sent'?: boolean;
    /**
    * how long it took the run to finish
    */
    'runTimeInSeconds'?: number;
    /**
    * how many matches were found
    */
    'matcherMatches'?: number;
    /**
    * Ttotal number of matches found
    */
    'matcherTotal'?: number;
    /**
    * percentage of hits that had matches
    */
    'matcherMatchRate'?: number;
    /**
    * how many hits were duplicates
    */
    'duplicates'?: number;
    /**
    * how many matches were found in the end
    */
    'matches'?: number;
    /**
    * total number of matches found
    */
    'total'?: number;
    /**
    * ratch rate of this run
    */
    'matchRate'?: number;
    /**
    * the date this run was ran on
    */
    'reportDate'?: Date;
    /**
    * the subscription ID related to this Stat
    */
    'subscriptionId'?: string;
    /**
    * How many times the geocoder returned non 200 responses while getting matches
    */
    'non200GeocoderResponseCount'?: number;
    /**
    * the ID of the onspot request 
    */
    'onspotRequestId'?: string;
    /**
    * Zips Filtered out
    */
    'zipFilterReductions'?: number;
    /**
    * states filtered out
    */
    'stateFilterReductions'?: number;
    /**
    * how many were removed for the match cap field
    */
    'matchCapFilterReductions'?: number;
    /**
    * how many duplicates were found
    */
    'matcherDuplicates'?: number;
    /**
    * The publisher id related to this stat
    */
    'runtimePublisherId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "runId",
            "baseName": "run_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "runDate",
            "baseName": "run_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "hits",
            "baseName": "hits",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "filteredCellularHits",
            "baseName": "filtered_cellular_hits",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "sent",
            "baseName": "sent",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "runTimeInSeconds",
            "baseName": "run_time_in_seconds",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matcherMatches",
            "baseName": "matcher_matches",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matcherTotal",
            "baseName": "matcher_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matcherMatchRate",
            "baseName": "matcher_match_rate",
            "type": "number",
            "format": "double"
        },
        {
            "name": "duplicates",
            "baseName": "duplicates",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matches",
            "baseName": "matches",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "total",
            "baseName": "total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matchRate",
            "baseName": "match_rate",
            "type": "number",
            "format": "double"
        },
        {
            "name": "reportDate",
            "baseName": "report_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "subscriptionId",
            "baseName": "subscription_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "non200GeocoderResponseCount",
            "baseName": "non200_geocoder_response_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "onspotRequestId",
            "baseName": "onspot_request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "zipFilterReductions",
            "baseName": "zip_filter_reductions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "stateFilterReductions",
            "baseName": "state_filter_reductions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matchCapFilterReductions",
            "baseName": "match_cap_filter_reductions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matcherDuplicates",
            "baseName": "matcher_duplicates",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "runtimePublisherId",
            "baseName": "runtime_publisher_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Stats.attributeTypeMap;
    }

    public constructor() {
    }
}

