import { Fa } from '@eltoro-ui/components'
import { Creativeservicev1Creative, V1CreativeFile } from 'next-gen-sdk'
import { ETIcon } from 'Assets'
import { CreativeThumbnail } from 'Components/CreativeThumbnail'

export const getThumbnail = (
  creative: Creativeservicev1Creative,
  size?: 's' | 'xs' | 'm' | 'l' | 'f',
  hoverStyles?: boolean,
) => {
  // If creative has files
  if (creative?.files && creative.files[0]) {
    if (creative.type === 'CREATIVE_TYPE_HTML5')
      return (
        <div
          className={`CreativeThumbnail__thumbnail CreativeThumbnail--size-${
            size || 's'
          } flex cursor-pointer items-center justify-center`}
        >
          <ETIcon className="HTML5Icon" icon="html5" />
        </div>
      )
    return (
      <CreativeThumbnail
        file={creative.files[0] as V1CreativeFile}
        size={size || 's'}
        hoverStyles={hoverStyles}
      />
    )
  }
  // If creative has no files, but is ad tag
  if (
    creative.type === 'CREATIVE_TYPE_AD_TAG' ||
    creative.type === 'CREATIVE_TYPE_VAST_TAG'
  )
    return <ETIcon icon="ad-tag" />
  // Else return empty icon
  return <Fa icon="empty-set" size={4} type="duotone" />
}
