import React, { useState } from 'react'
import { useValidatedState, useValidation } from '@eltoro-ui/hooks'
import { Orgmanagerv1Contact } from 'next-gen-sdk'

export type ContactStateType = {
  sameAsParent: boolean
  setSameAsParent: React.Dispatch<React.SetStateAction<boolean>>
  firstName: string
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  lastName: string
  setLastName: React.Dispatch<React.SetStateAction<string>>
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  isValidEmail: boolean
  phone: string
  setPhone: (value: string) => void
  isValidPhone: boolean
  address: string
  setAddress: React.Dispatch<React.SetStateAction<string>>
  state: string
  setState: React.Dispatch<React.SetStateAction<string>>
  city: string
  setCountry: React.Dispatch<React.SetStateAction<string>>
  country: string
  setCity: React.Dispatch<React.SetStateAction<string>>
  zip: string
  setZip: (value: string) => void
  isValidZip: boolean
  clearAll: () => void
  setToDefault: () => void
  isValidCanadianZip: (value: string) => boolean
}

export const useContactState = (defaultContact?: Orgmanagerv1Contact) => {
  const [sameAsParent, setSameAsParent] = useState(true)
  const [firstName, setFirstName] = useState(defaultContact?.firstName || '')
  const [lastName, setLastName] = useState(defaultContact?.lastName || '')
  const [email, setEmail, isValidEmail] = useValidatedState(
    defaultContact?.email || '',
    'email',
  )
  const [phone, setPhone, isValidPhone] = useValidatedState(
    defaultContact?.phone || '',
    'phone-number',
  )
  const [country, setCountry] = useState(defaultContact?.country || '')
  const [address, setAddress] = useState(defaultContact?.address || '')
  const [state, setState] = useState(defaultContact?.state || '')
  const [city, setCity] = useState(defaultContact?.city || '')
  const [zip, setZip, isValidZip] = useValidatedState(
    defaultContact?.zip || '',
    /^\d{5}$/,
  )
  const isValidCanadianZip = useValidation('canadian-zip')

  const clearAll = () => {
    setCountry('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setAddress('')
    setState('')
    setCity('')
    setZip('')
  }

  const setToDefault = () => {
    setCountry(defaultContact?.country || '')
    setFirstName(defaultContact?.firstName || '')
    setLastName(defaultContact?.lastName || '')
    setEmail(defaultContact?.email || '')
    setPhone(defaultContact?.phone || '')
    setAddress(defaultContact?.address || '')
    setState(defaultContact?.state || '')
    setCity(defaultContact?.city || '')
    setZip(defaultContact?.zip || '')
  }

  return {
    sameAsParent,
    setSameAsParent,
    // Name
    firstName,
    setFirstName,
    lastName,
    setLastName,
    // Email
    isValidEmail,
    email,
    setEmail,
    // Phone
    phone,
    setPhone,
    isValidPhone,
    // Address
    address,
    setAddress,
    zip,
    setZip,
    isValidZip,
    isValidCanadianZip,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    // Manip functions
    clearAll,
    setToDefault,
  }
}
