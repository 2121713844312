import type { SVGProps } from 'react'
import { useBrowser } from '@eltoro-ui/hooks'
import './LoadingIcons.scss'

const browser = useBrowser()

export const LoadingIcons = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      {browser === 'Safari' ? (
        <div className="LoaderDots Safari" />
      ) : (
        <svg
          className="LoadingIcons"
          xmlns="http://www.w3.org/2000/svg"
          baseProfile="tiny"
          width="1.2rem"
          height="1.2rem"
          viewBox="0 0 24 24"
          {...props}
        >
          <circle cx={18} cy={12} r={0} fill="currentColor">
            <animate
              attributeName="r"
              begin="0.67s"
              dur="1.5s"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle cx={12} cy={12} r={0} fill="currentColor">
            <animate
              attributeName="r"
              begin="0.33s"
              dur="1.5s"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle cx={6} cy={12} r={0} fill="currentColor">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.5s"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
        </svg>
      )}
    </>
  )
}
