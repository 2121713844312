/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { HttpFile } from '../http/http';

/**
* Request to deploy the orderline and set status to deployed.
*/
export class DeployOrderLineRequest {
    'destination'?: Deployservicev3Destination;
    'orgId': string;
    'stepFunction': string;
    'scheduledTime'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "stepFunction",
            "baseName": "step_function",
            "type": "string",
            "format": ""
        },
        {
            "name": "scheduledTime",
            "baseName": "scheduled_time",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return DeployOrderLineRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

