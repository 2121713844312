import axios from 'axios'

export const getAPIDocsPage = async (token: string, isAdmin?: boolean) => {
  const endpoint = isAdmin ? '.internal.swagger.json' : '.external.swagger.json'
  const url =
    process.env.NODE_ENV === 'production' && !/dev/.test(window.location.href)
      ? 'https://hagrid.api.eltoro.com/docs/open-apis/prod'
      : 'https://hagrid.api.dev.eltoro.com/docs/open-apis/dev'
  const config = { Authorization: `Bearer ${token}` }

  const res = await axios.get(`${url}${endpoint}`, {
    headers: config,
  })
  if (res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }
  return res.data
}
