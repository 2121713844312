import { useState } from 'react'
import { Button, Fa, Text, showSuccessMessage } from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { addCommas } from 'Components/OrderlineTable/OrderLineImpressionsEdit'
import { v2SyncPrompt } from 'Helpers'

export const MinimumImpressions = () => {
  const { isAdmin, currentOrg, orgServiceApi, setCurrentOrg } = useAppContext()
  // Min Imps Stuff
  const [minOrgImps, setMinOrgImps] = useState<number | undefined>(
    currentOrg?.minimumImpressions,
  )
  const [editImps, setEditImps] = useState<boolean>(false)

  const handleMinImpChange = () => {
    if (!currentOrg?.id) return
    orgServiceApi
      ?.advertisingPlatformServiceUpdateOrg(
        currentOrg?.id,
        { minimumImpressions: minOrgImps },
        'minimum_impressions',
      )
      .then((res) => {
        if (res) {
          setCurrentOrg(res)
          setEditImps(false)
          showSuccessMessage(
            'Org Updated',
            `Updated minimum impressions for ${currentOrg.name}`,
          )
        }
      })
  }
  return isAdmin ? (
    <Text className="flex items-center gap-1 text-lg font-bold">
      {/* TODO: get org minimum */}
      Minimum Impressions:
      {editImps ? (
        <div className="flex">
          <input
            placeholder={String(currentOrg?.minimumImpressions)}
            className="is-editable pl-3 text-xl"
            value={minOrgImps}
            onChange={(e) => setMinOrgImps(Number(e.target.value))}
            type="number"
            min={0}
          />
          <Button
            kind="text"
            iconOnly={<Fa icon="save" size={1} />}
            onClick={() => {
              if (minOrgImps && minOrgImps > 0) {
                handleMinImpChange()
              }
            }}
          />
          <Button
            kind="text"
            iconOnly={<Fa icon="times" size={1} />}
            onClick={() => {
              setMinOrgImps(currentOrg?.minimumImpressions)
              setEditImps(false)
            }}
          />
        </div>
      ) : (
        <div className="flex">
          <Button
            className="EditMinImpression__Btn !justify-between !pl-3 text-xl"
            kind="text"
            iconRight={<Fa icon="pencil" size={1} />}
            onClick={() => v2SyncPrompt(() => setEditImps(true), currentOrg)}
          >
            <Text size="xl">
              {addCommas(
                (minOrgImps && String(minOrgImps)) ||
                  String(currentOrg?.minimumImpressions),
              )}
            </Text>
          </Button>
        </div>
      )}
    </Text>
  ) : (
    <Text className="px-2 text-lg font-bold">
      Minimum Impressions:{' '}
      {currentOrg?.minimumImpressions && currentOrg?.minimumImpressions > 0
        ? addCommas(String(currentOrg?.minimumImpressions))
        : '50,000'}
    </Text>
  )
}
