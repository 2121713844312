/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Value } from '../models/Deployservicev3Value';
import { HttpFile } from '../http/http';

/**
* See [Conditions](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html#SplitsService-Conditions) in Xandr's documentation.
*/
export class V3Values {
    /**
    * The values of the condition
    */
    'values'?: Array<Deployservicev3Value>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "values",
            "baseName": "values",
            "type": "Array<Deployservicev3Value>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3Values.attributeTypeMap;
    }

    public constructor() {
    }
}

