/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Dependency } from '../models/Deployservicev3Dependency';
import { Deployservicev3Miscellanea } from '../models/Deployservicev3Miscellanea';
import { Deployservicev3SystemStat } from '../models/Deployservicev3SystemStat';
import { HttpFile } from '../http/http';

export class Deployservicev3Checks {
    'systemStat'?: Deployservicev3SystemStat;
    'dependencies'?: Array<Deployservicev3Dependency>;
    'miscellaneous'?: Array<Deployservicev3Miscellanea>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "systemStat",
            "baseName": "systemStat",
            "type": "Deployservicev3SystemStat",
            "format": ""
        },
        {
            "name": "dependencies",
            "baseName": "dependencies",
            "type": "Array<Deployservicev3Dependency>",
            "format": ""
        },
        {
            "name": "miscellaneous",
            "baseName": "miscellaneous",
            "type": "Array<Deployservicev3Miscellanea>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Deployservicev3Checks.attributeTypeMap;
    }

    public constructor() {
    }
}

