import { Checkbox, Spacer } from '@eltoro-ui/components'
import React from 'react'
import { useRecoilState } from 'recoil'
import {
  blockStrokeColorSelector,
  blockStrokeEnabledSelector,
  blockStrokeWidthSelector,
} from 'State'
import { BlocksConfigType } from 'Typings'
import { ColorSelect } from '../ColorSelect'
import { HorizontalLabel } from '../HorizontalLabel'
import './StrokeSettings.scss'

export const StrokeSettings: React.FC<{ config: BlocksConfigType }> = ({
  config,
}) => {
  const [enabled, setEnabled] = useRecoilState(
    blockStrokeEnabledSelector(config),
  )
  const [color, setColor] = useRecoilState(blockStrokeColorSelector(config))
  const [width, setWidth] = useRecoilState(blockStrokeWidthSelector(config))

  return (
    <div className="StrokeSettings">
      <Checkbox
        label="Enable stroke"
        checked={enabled.error ? false : enabled.value}
        onChange={(checked) => setEnabled({ value: checked, error: false })}
      />
      <Spacer />
      <HorizontalLabel label="Color">
        <ColorSelect
          color={color.value}
          onChange={(c) => setColor({ value: c.hex, error: false })}
        />
      </HorizontalLabel>
      <Spacer />
      <HorizontalLabel label="Width">
        <input
          className="StrokeSettings__number-input"
          type="number"
          value={width.value}
          onChange={(e) =>
            setWidth({ value: parseInt(e.target.value, 10), error: false })
          }
          min="0"
        />
      </HorizontalLabel>
    </div>
  )
}
