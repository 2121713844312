/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Creative } from '../models/Deployservicev3Creative';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { HttpFile } from '../http/http';

/**
* The event emitted when Xandr Console Creative information is updated
*/
export class V1OrderLineConsoleCreativesSet {
    'id'?: string;
    'creatives'?: Array<Deployservicev3Creative>;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Deployservicev3Creative>",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineConsoleCreativesSet.attributeTypeMap;
    }

    public constructor() {
    }
}

