import { render } from 'rc-util/lib/React/render'
import DOMPurify from 'dompurify'
import { ReactElement } from 'react'
import { NotificationBar } from './Notification'

const triggerSnackbar = (
  title: string,
  message: string | ReactElement,
  messageType: string,
  alert?: boolean,
  dismiss?: boolean,
  autoDismiss?: boolean,
) => {
  const validMessageTypes = ['error', 'info', 'warning', 'success']
  if (!validMessageTypes.includes(messageType)) {
    throw Error('Invalid notification message type')
  }
  const bar = document.getElementById(
    alert ? 'alert-wrap' : 'notification-bar-fixed-container',
  )
  if (bar) {
    render(
      <NotificationBar
        className={`${alert ? 'alert-banner' : ''} ${messageType}`}
        messageType={messageType}
        timer={30000}
        title={title}
        message={message}
        dismissMessage={dismiss}
        alert={alert}
        autoDismiss={autoDismiss}
      />,
      bar,
    )
  }
}
export const showErrorAlert = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'error', true, true, false)
}
export const showWarningAlert = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'warning', true, true, false)
}
export const showInfoAlert = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'info', true, true, false)
}
export const showErrorMessage = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'error', false, true)
}

export const showInfoMessage = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'info', false, true)
}

export const showSuccessMessage = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'success', false, true)
}

export const showWarningMessage = (
  title: string,
  message: string | ReactElement,
) => {
  triggerSnackbar(title, message, 'warning', false, true)
}

export const getSanitizedHTML = (html: string, browser: string): string => {
  const purifyConfig = {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'span', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['href', 'class', 'target', 'rel'],
  }

  const _temp = document.createElement('div')
  _temp.innerHTML = html // move text into temp div then return it as just text

  if (
    browser === 'Chrome' ||
    browser === 'Safari' ||
    browser === 'Firefox' ||
    browser === 'Opera'
  ) {
    return DOMPurify.sanitize(html, purifyConfig)
  }
  return _temp.textContent || _temp.innerText || '' // return empty string if no text found
}
