import { useState, useRef, useEffect } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { CalendarInput, DateTargetToggle } from './components'

export const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  min,
  max,
  indefinite,
  onIndefiniteSelect,
  altToggleStyle,
}: {
  startDate: Date
  endDate: Date
  onStartDateChange: (newStartDate: Date) => void
  onEndDateChange: (newEndDate: Date) => void
  min?: Date
  max?: Date
  indefinite?: boolean
  onIndefiniteSelect?: (selection: boolean) => void
  altToggleStyle?: boolean
}) => {
  const [target, setTarget] = useState<'start' | 'end'>()
  const [isShown, setIsShown] = useState<boolean>(false)
  const ref = useRef<null | HTMLDivElement>(null)
  const scrollRef = useRef<null | HTMLDivElement>(null)
  useOnClickOutside(ref, () => setIsShown(false))
  const handleStartDateChange = (newStartDate: Date) => {
    onStartDateChange(newStartDate)
    if (newStartDate > endDate) {
      setIsShown((setShown) => !setShown)
    }
  }
  const handleEndDateChange = (newEndDate: Date) => {
    onEndDateChange(newEndDate)
    if (newEndDate > startDate) {
      setIsShown((setShown) => !setShown)
    }
  }
  const toggleCalView = () => {
    setIsShown((setShown) => !setShown)
  }
  useEffect(() => {
    if (isShown) {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [isShown])

  return (
    <div
      role="button"
      className="DateRangePicker relative py-2 w-full z-10 max-w-[31rem]"
      onClick={toggleCalView}
      onKeyDown={toggleCalView}
      tabIndex={0}
      ref={scrollRef}
    >
      <DateTargetToggle
        startDate={startDate}
        endDate={endDate}
        inputTarget={target || 'start'}
        onInputTargetChange={setTarget}
        indefinite={indefinite}
        onIndefiniteSelect={onIndefiniteSelect}
        altButtonStyle={altToggleStyle}
      />
      <div
        role="button"
        className={`CalendarInput__container min-w-[258px] w-full bg-base ${
          isShown ? 'is-shown animate-slidedown shadow-6xl' : ''
        }`}
        onClick={toggleCalView}
        onKeyDown={toggleCalView}
        tabIndex={0}
        ref={ref}
      >
        <CalendarInput
          startDate={startDate}
          endDate={endDate}
          inputTarget={target || 'start'}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          onInputTargetChange={setTarget}
          min={(!!min && new Date(min.toDateString())) || undefined}
          max={(!!max && new Date(max.toDateString())) || undefined}
          indefinite={indefinite || false}
        />
      </div>
    </div>
  )
}
