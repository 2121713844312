import { ColorSwatches, PageContent } from 'Components'
import { useContext } from 'react'
import {
  RadioButton,
  RadioButtonGroup,
  Section,
  Text,
  TextHeader,
  TextBody,
  TextDetail,
  Tooltip,
} from '@eltoro-ui/components'
import { useMediaQuery, useScreenSize, ScreenSize } from '@eltoro-ui/hooks'
import { styleTailwind } from 'Helpers'
import { ThemeContext, themeNamesType, themes } from 'Contexts'
import { ETIconPaths, ETIcon } from 'Assets'
import './ThemeSelectionPage.scss'

type DeviceType = 'mobile' | 'mobileL' | 'tablet' | 'tabletL' | 'desktop'

const CodeText = styleTailwind(
  'code',
  'CodeText inline-block px-2 py-1 leading-none bg-primary-50 border-thin border-primary-200 text-success-600 rounded',
)
const TooltipExample = styleTailwind(
  Tooltip,
  'TooltipExample relative flex flex-col text-center  items-center justify-center',
)
const TooltipCSSExample = styleTailwind(
  'div',
  'TooltipCSSExample w-auto text-center relative flex flex-col',
)
const SmallText = styleTailwind(
  'small',
  'SmallText absolute text-xs flex flex-col top-12 gap-2 text-center w-auto items-center justify-center',
)

const Label = styleTailwind(
  'small',
  'Label text-m w-auto block font-medium leading-none p-2 text-center',
)

export const ThemeSelectionPage = () => {
  const { name, handleThemeChange } = useContext(ThemeContext)
  const screenSize: ScreenSize = useScreenSize()

  const [mobile] = useMediaQuery('(max-width: 540px)')
  const [mobileLandscape] = useMediaQuery(
    '(orientation: landscape) and (max-width: 844px)',
  )
  const [tablet] = useMediaQuery('(max-width: 1024px)')
  const [tabletLandscape] = useMediaQuery(
    '(orientation: landscape) and (max-width: 1080px)',
  )
  const [desktop] = useMediaQuery('(min-width: 1024px)')

  const Devices: Record<DeviceType, boolean> = {
    mobile: mobile,
    mobileL: mobileLandscape,
    tablet: tablet,
    tabletL: tabletLandscape,
    desktop: desktop,
  }

  const Device = Object.entries(Devices).find(([key, value]) => value)?.[0] as
    | DeviceType
    | ''

  return (
    <PageContent className="ThemeSelectionPage h-[100vh] overflow-auto px-10 pb-24">
      <TextHeader type={1} className="py-2">
        Theme info + El Toro UI elements
      </TextHeader>

      <div className="Section__theme-selection flex gap-5">
        <Section title="Theme selection">
          <RadioButtonGroup
            className="ThemeSelectionPage__radio-group"
            name="Theme selection"
            valueSelected={name}
            layout="horizontal"
            onChange={(newTheme) =>
              handleThemeChange(newTheme as themeNamesType)
            }
          >
            {Object.keys(themes).map((themeName) => (
              <RadioButton
                key={themeName}
                value={themeName}
                label={themeName}
              />
            ))}
          </RadioButtonGroup>
        </Section>
        <Section title="useMediaQuery" className="flex-1">
          <div className="flex flex-1 justify-between gap-5">
            <div className="pr-2">
              <TextHeader type={5} className="flex flex-col py-2">
                Screen size
                <TextHeader className="text-l !font-light">
                  useScreenSize hook
                </TextHeader>
              </TextHeader>
              <Text>
                <Text tag="p">Width: {screenSize.width}px</Text>
                <Text tag="p">Height: {screenSize.height}px</Text>
              </Text>
            </div>
            <div className="pr-4">
              <TextHeader type={5} className="flex flex-col py-2">
                Device type
                <TextHeader className="text-l !font-light">
                  useMediaQuery hook
                </TextHeader>
              </TextHeader>
              <Text>
                <Text tag="p" className="capitalize">
                  Device: {Device}
                </Text>
                <Text tag="p">
                  Mode: &nbsp;
                  {Device === 'mobileL' ||
                  Device === 'tabletL' ||
                  screenSize.width > screenSize.height
                    ? 'Landscape'
                    : 'Portrait'}
                  , {screenSize.height}px
                </Text>
              </Text>
            </div>
          </div>
        </Section>
      </div>

      <Section
        title="ET UI Design colors"
        className="from-tint-dark bg-gradient-to-l"
      >
        <ColorSwatches />
      </Section>
      <hr className="border-primary-200 my-10" />
      <Section
        title="El Toro custom icons"
        className="ETIcons flex flex-col"
        noBackground
      >
        <Text className="pb-4 pt-1" tag="p">
          Text under icon is the icon name (kebab-case, similar to font-awesome)
        </Text>
        <div className="icons-wrap flex flex-wrap gap-2">
          {ETIconPaths.map(({ iconName }, index) => (
            <div
              key={index}
              className="icon-wrapper bg-primary-100 flex w-28 flex-col items-center justify-center p-5"
            >
              <ETIcon key={index} icon={iconName} />
              <h4 className="break-all text-center text-sm leading-none">
                {iconName.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, (a) => a)}
              </h4>
            </div>
          ))}
        </div>
        <div className="example pt-10">
          <h3 className="bold py-5 text-2xl">Single icon usage</h3>
          <div className="bg-tint-khaki-50 flex max-w-full gap-2 overflow-x-scroll rounded-xl p-5 text-lg">
            <div className="w-1/3">
              <h3 className="text-grey-800 bold mt-0 text-xl">How to use</h3>
              <div className="text-success-400">
                import &#123; ETIcon &#125; from &apos;Assets&apos;
              </div>
              <p className="text-danger-600 block">
                &lt;ETIcon icon=&quot;algorithm&quot; height=&quot;40&quot;
                width=&quot;40&quot; /&gt;
                <span className="text-grey-700 block pr-10">
                  &#47;/ Requires &quot;icon&quot; name in kebab-case.
                  Width/height/box/className are optional. SVG max-width is
                  2.35rem. Add
                  <CodeText className="text-s my-2 mr-1">
                    className="ETIcon--full"
                  </CodeText>
                  to make width fill parent container. Box adds 50 to attribute,
                  viewbox: (0 0 50 50). The SVG default is a block element. The
                  className allows you to add Tailwind classes, alt. 3 --
                  className=&quot;inline-block h-24 w-24&quot;
                </span>
              </p>
            </div>
            <div className="w-2/3">
              <h3 className="text-grey-800 bold mt-0 text-xl">How it looks</h3>
              <div className="flex gap-4">
                <div className="flex w-1/3 flex-col gap-1">
                  <div className="flex flex-col">
                    <ETIcon icon="algorithm" />
                    <p className="py-1 leading-tight">
                      <span className="text-primary-400 text-xl">Default</span>
                      <br />
                      Algorithm example with no props
                      <CodeText className="text-s my-1">
                        &lt;ETIcon icon="algorithm" /&gt;
                      </CodeText>
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <ETIcon className="ETIcon--full" icon="algorithm" />
                    <p className="py-1 leading-tight">
                      <span className="text-primary-400 text-xl">Alt 1</span>
                      <br />
                      Algorithm example with full-width
                      <CodeText className="text-s my-1">
                        className="ETIcon--full"
                      </CodeText>
                    </p>
                  </div>
                </div>
                <div className="w-1/3">
                  <ETIcon
                    className="ETIcon--full"
                    icon="algorithm"
                    height="80"
                    width="80"
                  />
                  <Text tag="div" className="flex flex-col py-1 leading-tight">
                    <span className="text-primary-400 text-xl">Alt 2</span>
                    <Text>
                      Algorithm example here has a fixed size at 80px square
                    </Text>
                    <CodeText className="text-s my-1">
                      className="ETIcon--full" height="80" width="80"
                    </CodeText>
                  </Text>
                </div>
                <div className="w-1/3">
                  <ETIcon
                    className="ETIcon--full inline-block h-24 w-24"
                    icon="algorithm"
                  />
                  <span className="text-primary-400 mx-1 inline-block text-xl">
                    Alt 3
                  </span>
                  <p className="py-1 leading-tight">
                    Algorithm example with Tailwind classNames, 6rem width, 6
                    rem height (w-24, h-24) and inline-block
                  </p>
                  <CodeText className="text-s my-1">
                    className="ETIcon--full inline-block h-24 w-24"
                  </CodeText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <hr className="border-primary-200 my-10" />
      <Section title="Text components">
        {/* Text component examples here */}
        <div className="TextExamples">
          <Text className="text-l text-grey-500 pb-2" tag="p" weight="normal">
            Include Text, TextHeader, TextBody, TextDetail components
          </Text>

          <hr className="border-tint-gray-200 border-t-thin my-1" />
          <TextHeader type={3}>Text props</TextHeader>
          <Text
            className="
              text-success
              py-2
              leading-none"
            tag="p"
            weight="bold"
          >
            <Text className="text-grey-500 block" tag="span" weight="normal">
              Text props now works. But best bet is still using classname
            </Text>
            <CodeText>
              &lt;Text on=&apos;grey-100&apos; kind=&apos;primary&apos;&gt;
            </CodeText>
          </Text>
          <Text className="flex flex-col gap-1" tag="span" weight="normal">
            <div className="text-on-color-grey-100--primary bg-grey-100">
              <Text tag="span" className="text-danger-400 font-bold">
                className=
              </Text>
              <Text
                tag="span"
                className="text-on-color-grey-100--primary font-bold"
              >
                &apos;text-on-color-grey-100--primary bg-grey-100&apos;
              </Text>
            </div>
            <Text className="bg-grey-100" on="grey-100" kind="primary" tag="h5">
              <CodeText className="mr-1">
                &lt;Text on=&apos;grey-100&apos; kind=&apos;primary&apos;&gt;
              </CodeText>
              text-on-color-grey-100--primary
            </Text>
            <Text className="bg-grey-500" on="grey-500" kind="primary" tag="h5">
              text-on-color-grey-500--primary
            </Text>
            <Text
              className="bg-primary-500"
              on="primary-500"
              kind="vivid"
              tag="h5"
            >
              text-on-color-primary-500--vivid
            </Text>
            <Text className="bg-grey-100" on="grey-100" kind="success" tag="h5">
              text-on-color-grey-100--success
            </Text>
            <Text
              className="bg-secondary-300"
              on="secondary-300"
              kind="vivid"
              tag="h5"
            >
              text-on-color-secondary-300--vivid
            </Text>
            <Text
              className="bg-warning-500"
              on="warning-500"
              kind="vivid"
              tag="h5"
            >
              text-on-color-warning-500--vivid
            </Text>
          </Text>
          {/* TextHeader */}
          <TextHeader type={3} className="pt-10">
            TextHeader
            <Text className="text-l text-grey-500 pb-2" tag="p" weight="normal">
              ClassName allowed
            </Text>
          </TextHeader>

          <div className="TextHeader__wrap">
            <TextHeader
              type={1}
              className="odd:bg-primary-50 even:bg-gray p-2"
              leading={1}
            >
              H1 TextHeader type=&#x7B;1&#x7D; leading=&#x7B;1&#x7D;
            </TextHeader>
            <TextHeader
              type={2}
              leading={1}
              className="odd:bg-primary-50 even:bg-gray p-2"
            >
              H2 TextHeader type=&#x7B;2&#x7D; leading=&#x7B;1&#x7D;
            </TextHeader>
            <TextHeader type={3} className="odd:bg-primary-50 even:bg-gray p-2">
              H3 TextHeader type=&#x7B;3&#x7D;
            </TextHeader>
            <TextHeader type={4} className="odd:bg-primary-50 even:bg-gray p-2">
              H4 TextHeader type=&#x7B;4&#x7D;
            </TextHeader>
            <TextHeader type={5} className="odd:bg-primary-50 even:bg-gray p-2">
              H5 TextHeader type=&#x7B;5&#x7D;
            </TextHeader>
            <TextHeader type={6} className="odd:bg-primary-50 even:bg-gray p-2">
              H6 TextHeader type=&#x7B;6&#x7D;
            </TextHeader>
          </div>

          {/* Text */}
          <TextHeader
            type={3}
            className="odd:bg-primary-50 even:bg-gray p-2 pt-12"
            leading={1}
          >
            Text
            <Text className="text-l text-grey-500 pb-2" tag="p" weight="normal">
              ClassName allowed
            </Text>
          </TextHeader>
          {/*  */}
          <Text
            className="odd:bg-primary-50 even:bg-gray p-5"
            tag="p"
            weight="light"
          >
            <Text
              className="text-success block leading-none"
              tag="span"
              weight="bold"
            >
              &lt;Text tag=&quot;p&quot; weight=&quot;light&quot;&gt; &nbsp;
            </Text>
            <Text className="block" tag="span">
              <ul className="list-disc leading-tight">
                <li>Default font-size (16px or 1rem)</li>
                <li>Default line-height or leading (1.5)</li>
                <li>Font-weight is light or 300</li>
              </ul>
            </Text>
            <Text tag="p" weight="light">
              Text, tag="p" weight="light" - Austin echo park 8-bit coloring
              book +1 prism hashtag iPhone la croix man bun pork belly live-edge
              deep v. Copper mug mumblecore listicle, chia live-edge hashtag
              post-iron ic neutra activated charcoal farm-to-table vegan
              glossier 8-bit.
            </Text>
          </Text>
          {/*  */}
          <Text
            className="odd:bg-primary-50 even:bg-gray p-5"
            textAlign="center"
            tag="div"
          >
            <Text
              className="text-success block leading-none"
              tag="span"
              weight="bold"
            >
              &lt;Text tag=&quot;div&quot; textAlign=&quot;center&quot;&gt;
              &nbsp;
            </Text>
            <Text className="block" tag="span">
              <ul className="leading-tight">
                <li>Default font-size (16px or 1rem)</li>
                <li>Default font-weight (normal or regular -- 400)</li>
                <li>Default line-height or leading (1.5)</li>
              </ul>
            </Text>
            <Text textAlign="center" tag="div">
              Text, tag="div" textAlign="center" -- Austin echo park 8-bit
              coloring book +1 prism hashtag iPhone la croix man bun pork belly
              live-edge deep v. Copper mug mumblecore listicle, chia live-edge
              hashtag post-iron ic neutra activated charcoal farm-to-table vegan
              glossier 8-bit.
            </Text>
          </Text>
          {/*  */}
          <Text
            className="odd:bg-primary-50 even:bg-gray p-5"
            tag="p"
            lineHeight="snug"
            weight="medium"
            size="m"
            textAlign="right"
          >
            <Text className="text-success leading-none" tag="div" weight="bold">
              &lt;Text tag=&quot;p&quot; weight=&quot;medium&quot;
              textAlign=&quot;right&quot; lineHeight=&quot;snug&quot;
              size=&quot;m&quot;&gt; &nbsp;
            </Text>
            <Text tag="div">
              <ul className="leading-tight">
                <li>p tag</li>
                <li>Medium or semibold text-weight (600)</li>
                <li>snug line-height and </li>
                <li>font-size: text-m (≈14px)</li>
              </ul>
            </Text>
            <Text
              tag="p"
              size="m"
              weight="medium"
              textAlign="right"
              lineHeight="snug"
            >
              Text, tag="p" weight="medium" textAlign="right" lineHeight="snug"
              size="m" -- Meggings health goth sriracha twee heirloom
              kickstarter venmo. Paleo succulents umami pinterest palo santo
              kickstarter bushwick everyday carry. YOLO letterpress +1 celiac.
              Affogato fanny pack jean shorts, copper mug twee four dollar toast
              shaman tattooed hella iPhone godard. Intelligentsia iceland umami
            </Text>
          </Text>
          {/*  */}
          <Text
            className="odd:bg-primary-50 even:bg-gray p-5"
            textAlign="justify"
            lineHeight="loose"
            weight="semibold"
            tag="p"
          >
            <Text className="text-success leading-none" tag="div" weight="bold">
              &lt;Text tag=&quot;p&quot; weight=&quot;semibold&quot;
              lineHeight=&quot;loose&quot; textAlign=&quot;justify&quot;&gt;
            </Text>
            <Text tag="div">
              <ul className="list-disc leading-tight">
                <li>Default font-size (16px or 1rem)</li>
                <li>Line-height is loose (2)</li>
                <li>Font-weight is medium or semibold -- 600</li>
                <li>Text-align is justified -- flush-left and flush-right</li>
              </ul>
            </Text>
            <Text
              textAlign="justify"
              lineHeight="loose"
              weight="semibold"
              tag="p"
            >
              Text, tag="p" weight="semibold" lineHeight="loose"
              textAlign="justify" -- bicycle rights post-ironic disrupt neutra
              squid. Ennui pabst paleo waistcoat meh, knausgaard mixtape squid.
              Brooklyn fanny pack hella flannel 90s banh mi authentic small
              batch viral prism four loko cardigan squid. Beard palo santo
              cornhole you probably have not heard of them quinoa messenger bag
              letterpress kogi adaptogen chartreuse truffaut butcher shoreditch
              tattooed prism. Leggings pour-over kitsch church-key fanny pack
              artisan, edison bulb gastropub keytar. Kale chips lyft chartreuse
              coloring book kickstarter next level deep v kitsch gluten-free.
              Quinoa live-edge hashtag
            </Text>
          </Text>
          {/*  */}
          <Text
            className="odd:bg-primary-50 even:bg-gray p-5"
            lineHeight="relaxed"
            weight="bold"
            tag="p"
            size="l"
          >
            <Text className="text-success leading-none" tag="div" weight="bold">
              &lt;Text tag=&quot;p&quot; weight=&quot;bold&quot;
              lineHeight=&quot;relaxed&quot; size=&quot;l&quot;&gt;
            </Text>
            <Text tag="div">
              <ul className="list-disc leading-tight">
                <li>Tag is paragraph p</li>
                <li>Font-size is large</li>
                <li>Line-height is relaxed (2)</li>
                <li>Font-weight is bold -- 700</li>
              </ul>
            </Text>
            <Text lineHeight="relaxed" weight="bold" tag="p" size="l">
              Text tag="p" weight="bold" lineHeight="relaxed" size="l" -- icycle
              rights post-ironic disrupt neutra squid. Ennui pabst paleo
              waistcoat meh, knausgaard mixtape squid. Brooklyn fanny pack hella
              flannel 90s banh mi authentic small batch viral prism four loko
              cardigan squid. Beard palo santo cornhole you probably have not
              heard of them quinoa messenger bag letterpress kogi adaptogen
              chartreuse truffaut butcher shoreditch tattooed prism. Leggings
              pour-over kitsch church-key fanny pack artisan, edison bulb
              gastropub keytar. Kale chips lyft chartreuse coloring book
              kickstarter next level deep v kitsch gluten-free. Quinoa live-edge
              hashtag
            </Text>
          </Text>

          {/* TextBody */}
          <TextHeader type={3} className="pt-12">
            TextBody
            <Text
              className="text-grey-500 pb-2"
              tag="p"
              size="l"
              weight="normal"
              lineHeight="none"
            >
              ClassName allowed: One prop - type -- for font-size and
              line-height (leading)
            </Text>
          </TextHeader>
          {/*  */}
          <TextBody className="odd:bg-primary-50 even:bg-gray p-5">
            <Text tag="div">
              <Text
                className="text-success leading-none"
                tag="div"
                weight="bold"
              >
                &lt;TextBody /&gt; &nbsp;
              </Text>
              <ul className="list-disc leading-tight">
                <li>Type: 1, 2, 3,</li>
                <li>Font-size is m</li>
                <li>font-size: 1 = l (large), 2 = m, 3 = s</li>
                <li>Line-height is leading-normal (1.5)</li>
                <li>line-height: 1 = snug, 2 = normal and 3 = loose;</li>
              </ul>
            </Text>
            <TextBody>
              TextBody. type={2} is default - Echo park irony waistcoat mustache
              trust fund lumber seitan. Offal crucifix literally post-ironic
              succulents cray, keytar 3 wolf moon viral live-edge organic.
              Yuccie deep v shabby chic fingerstache scenester roof party trust
              fund salvia etsy. Wayfarers poke tofu, echo park scenester beard
              ramps af chambray twee schlitz marfa glossier copper mug cliche.
              Pork belly occupy marfa flannel bushwick kale chips, edison bulb
              8-bit lo-fi austin fanny pack trust fund quinoa. Tbh offal craft
              beer readymade echo park, edison bulb vegan hexagon tattooed hell
              of salvia cliche selvage deep v.
            </TextBody>
          </TextBody>

          {/* TextDetail, from ET branding guide */}
          <TextHeader type={3} className="pt-12">
            TextDetail
          </TextHeader>
          <Text
            className="text-l text-grey-500 pb-2 leading-tight"
            tag="p"
            weight="normal"
          >
            ClassName allowed - sets in div tag, no props. Use sparingly as the
            text is all caps, based off ET branding guide.
          </Text>
          {/*  */}
          <TextDetail className="bg-primary-50 p-5">
            <Text
              className="text-success normal-case leading-none"
              weight="bold"
            >
              &lt;TextDetail /&gt;
            </Text>
            <Text tag="div">
              <ul className="list-disc leading-tight">
                <li>All caps</li>
                <li>Medium font-size</li>
                <li>semibold font-weight</li>
                <li>Tight line-height or leading-tight (1.25)</li>
              </ul>
            </Text>
            <TextDetail>
              Text Detail -- Pork belly occupy marfa flannel bushwick kale
              chips, edison bulb 8-bit lo-fi austin fanny pack trust fund
              quinoa. Tbh offal craft beer readymade echo park, edison bulb
              vegan hexagon tattooed hell of salvia cliche selvage deep v.
            </TextDetail>
          </TextDetail>
        </div>
      </Section>
      <hr className="border-primary-200 my-10" />
      <Section
        title="ToolTips"
        className="ToolTipExamples pb-42 flex flex-col gap-4"
        noBackground
      >
        {/* Tooltip wrapper examples, tooltip component wraps over element needing a tooltipi */}
        <TextHeader type={3} className="pt-4">
          Tooltip wrapper examples
          <span className="text-xl font-light">
            , requires one prop,
            <CodeText className="text-m ml-1">content</CodeText>, optional
            props:
            <CodeText className="text-m ml-1">position</CodeText>, top
            (default), right, bottom or left;
            <CodeText className="text-m ml-1">color</CodeText>, base (light gray
            -- default), primary, secondary, tertiary, success,&nbsp; warning or
            danger;
            <CodeText className="text-m ml-1">size</CodeText>, xs, s, m, l, xl,
            xxl;
            <CodeText className="text-m mx-1">classNameTooltip</CodeText>
            tooltip styles, and
            <CodeText className="text-m mx-1">className</CodeText>
            for parent div, if needed
          </span>
        </TextHeader>
        <CodeText className="text-m ml-1 leading-loose">
          import &#123; Tooltip &#125; from '@eltoro-ui/components' <br />
          from &lt;Tooltip content=&quot;Tooltip info&quot;&gt;&lt;Element
          /&gt;&lt;/Tooltip&gt;
        </CodeText>
        <div className="mb-24 flex h-12 justify-between gap-12">
          <TooltipExample
            className="tooltip__content-wrap items-start"
            classNameTooltip="tooltip__content w-48 !left-[-10px]"
            color="primary"
            content="Tooltip top, 'primary' Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="top" /&gt;
            </Label>
            <SmallText className="items-start">
              <CodeText className="text-xs">position="top"</CodeText>
              <CodeText className="text-xs">color="primary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip 'top', 'primary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            id="TooltipExampleRight"
            className="tooltip__content-wrap items-center"
            classNameTooltip="tooltip__content w-48"
            position="right"
            color="tertiary"
            content="Tooltip 'right', 'tertiary' Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="right" /&gt;
            </Label>
            <SmallText className="items-center">
              <CodeText className="text-xs">position="right"</CodeText>
              <CodeText className="text-xs">color="tertiary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip 'right', 'tertiary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            className="tooltip__content-wrap TooltipExampleBottom items-center"
            classNameTooltip="tooltip__content !left-[-107px] w-72"
            position="bottom"
            content="Tooltip 'bottom' Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="bottom" /&gt;
            </Label>
            <SmallText className="items-center">
              <CodeText className="text-xs">position="bottom"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip 'bottom'"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            id="TooltipExampleLeft"
            className="tooltip__content-wrap items-end"
            classNameTooltip="tooltip__content w-72"
            position="left"
            color="secondary"
            content="Tooltip content, position='left', color='secondary' Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="left" /&gt;
            </Label>
            <SmallText className="items-end">
              <CodeText className="text-xs">position="left"</CodeText>
              <CodeText className="text-xs">color="secondary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip 'left', 'secondary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
        </div>
        <div className="mb-24 mt-6 flex justify-between gap-12">
          <TooltipExample
            className="tooltip__content-wrap items-start"
            classNameTooltip="tooltip__content"
            position="topRight"
            color="primary"
            content="Tooltip topRight"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="topRight" /&gt;
            </Label>
            <SmallText className="items-start">
              <CodeText className="text-xs">position="topRight"</CodeText>
              <CodeText className="text-xs">color="primary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip topRight"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            className="tooltip__content-wrap items-center"
            classNameTooltip="tooltip__content"
            position="bottomRight"
            color="tertiary"
            content="Tooltip content, position='bottomRight', color='tertiary'"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="bottomRight" /&gt;
            </Label>
            <SmallText className="items-center">
              <CodeText className="text-xs">position="bottomRight"</CodeText>
              <CodeText className="text-xs">color="tertiary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip content, position='bottomRight',
                color='tertiary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            className="tooltip__content-wrap items-center"
            classNameTooltip="tooltip__content"
            position="bottomLeft"
            color="warning"
            content="Tooltip content, position='bottomLeft'"
          >
            <Label>
              &lt;Tooltip
              <br />
              position="bottomLeft" /&gt;
            </Label>
            <SmallText className="items-center">
              <CodeText className="text-xs">position="bottomLeft"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip content, position='bottomLeft',
                color='tertiary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
          <TooltipExample
            className="tooltip__content-wrap items-end"
            classNameTooltip="tooltip__content"
            position="topLeft"
            color="secondary"
            content="Tooltip content, position='topLeft', color='secondary'"
          >
            <Label>
              &lt;Tooltip
              <br />
              "topLeft"" /&gt;
            </Label>
            <SmallText className="items-end">
              <CodeText className="text-xs">position="topLeft"</CodeText>
              <CodeText className="text-xs">color="secondary"</CodeText>
              <CodeText className="text-xs">
                content="Tooltip content, position='topLeft', color='secondary'"
              </CodeText>
            </SmallText>
          </TooltipExample>
        </div>
        <div className="TooltipCSS__examplesWrap flex flex-col gap-4 pt-4 pb-20">
          {/* Tooltip attributes examples, add attributes to element needing a tooltip */}
          <TextHeader type={3} className="pt-4">
            Tooltip-CSS examples
            <span className="text-xl font-light leading-none">
              , CSS only, requires only two attributes -- add
              <CodeText className="text-m mx-1">data-tooltip</CodeText>
              and
              <CodeText className="text-m mx-1">aria-label</CodeText>
              to the element
            </span>
          </TextHeader>
          <Text className="pb-4 normal-case" tag="h6">
            Examples of usage, check out &nbsp;
            <a href="http://localhost:3000/campaigns/creative-library">
              Preview panel's
            </a>
            &nbsp; icon-only buttons (buttons with no labels, e.g. pencil/disk
            icons)
          </Text>
          <div className="TooltipCSS__examples mb-36 flex justify-between gap-24">
            <TooltipCSSExample
              className="tooltip-primary flex cursor-pointer flex-col items-start"
              data-tooltip="top"
              aria-label="Tooltip top"
              tabIndex={0}
            >
              Top
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-primary"
                </CodeText>
                <CodeText className="text-xs">data-tooltip="top"</CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip top"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="tooltip-warning items-center"
              data-tooltip="right 500"
              aria-label="Tooltip right, 500ms delay"
              tabIndex={0}
            >
              Right
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-warning"
                </CodeText>
                <CodeText className="text-xs">
                  data-tooltip="right 500"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip right, 500ms delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="items-center before:w-72"
              data-tooltip="bottom 1000"
              aria-label="Tooltip bottom, 1s delay 'bottom' Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni do
                lores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam?"
              tabIndex={0}
            >
              Bottom
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  data-tooltip="bottom 1000"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip bottom, 1s delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="tooltip-success items-end"
              data-tooltip="left 250"
              aria-label="Tooltip left 250ms delay"
              tabIndex={0}
            >
              Left
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-success"
                </CodeText>
                <CodeText className="text-xs">data-tooltip="left 250"</CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip left, 250ms delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
          </div>
          {/* using CSS names without data-tooltip */}
          <TextHeader type={3} className="pt-4">
            Tooltip-CSS with CSS classes
            <span className="text-xl font-light leading-none">
              , CSS only but with
              <CodeText className="text-m ml-1">
                className="TooltipCSS"
              </CodeText>
              , requires only one attribute -- add
              <CodeText className="text-m ml-1">aria-label</CodeText> to the
              element
            </span>
          </TextHeader>
          <div className="TooltipCSS__examples mb-28 flex justify-between gap-24">
            <TooltipCSSExample
              className="tooltip-primary TooltipCSS top flex flex-col items-start"
              aria-label="Tooltip top"
              tabIndex={0}
            >
              Top
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-primary TooltipCSS top"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip top"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="tooltip-warning TooltipCSS right t500 items-center"
              aria-label="Tooltip right, 500ms delay"
              tabIndex={0}
            >
              Right
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-warning TooltipCSS right t500"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="tooltip-warning Tooltip right, 500ms delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="TooltipCSS bottom t1000 items-center"
              aria-label="Tooltip bottom, 1s delay"
              tabIndex={0}
            >
              Bottom
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="TooltipCSS bottom t1000"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip bottom, 1s delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
            <TooltipCSSExample
              className="tooltip-success TooltipCSS left t250 items-end"
              aria-label="Tooltip left 250ms delay"
              tabIndex={0}
            >
              Left
              <SmallText className="!top-8">
                <CodeText className="text-xs">
                  className="tooltip-success TooltipCSS left t250"
                </CodeText>
                <CodeText className="text-xs">
                  aria-label="Tooltip left, 250ms delay"
                </CodeText>
              </SmallText>
            </TooltipCSSExample>
          </div>
        </div>
      </Section>
    </PageContent>
  )
}
