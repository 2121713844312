import { getApiUrl } from 'Helpers'
import * as SDK from 'next-gen-sdk'

export const getApiConfig = (token: string) => {
  const url = getApiUrl()
  const apiConfiguration = SDK.createConfiguration({
    baseServer: new SDK.ServerConfiguration(url, {}),
    authMethods: { OAuth2: { accessToken: token } },
  })
  return apiConfiguration
}
