import React, { useState } from 'react'
import {
  Text,
  TextInput,
  Uploader,
  PreviewType,
  Fa,
} from '@eltoro-ui/components'
import './BackgroundImageTab.scss'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { backgroundPhotoAtom, unsplashPhotosAtom } from 'State'
import { UploadedImageType } from 'Typings'
import { PhotoButton } from './components'

export const BackgroundImageTab: React.FC<{
  search: string
  onChange: (s: string) => void
}> = ({ search, onChange }) => {
  const photos = useRecoilValue(unsplashPhotosAtom)
  const setBackgroundPhoto = useSetRecoilState(backgroundPhotoAtom)
  const [customBackgrounds, setCustomBackgrounds] = useState<PreviewType[]>([])

  const handleSetCustomBackground = (photo: PreviewType) => {
    const customAsUploadedImageType: UploadedImageType = {
      path: photo.path || '',
      preview: photo.preview,
      width: 0,
      height: 0,
    }
    const newImage = new Image()
    newImage.onload = () => {
      setBackgroundPhoto({
        ...customAsUploadedImageType,
        width: newImage.width,
        height: newImage.height,
      })
    }
    newImage.src = photo.preview
  }

  return (
    <div className="BackgroundImageTab">
      <div className="BackgroundImageTab__header flex flex-col h-full w-full gap-2">
        <span className="flex flex-col gap-2">
          <Text on="white" weight="bold">
            Background Images
          </Text>
          <TextInput
            value={search}
            onChange={(e) => onChange(e.target.value)}
            icon={<Fa icon="search" size={1} />}
          />
        </span>
        <div className="BackgroundImageTab__photo-container flex flex-col">
          <Uploader
            accepts="images"
            isStyled
            onDrop={(files) =>
              setCustomBackgrounds((prevBackgrounds: PreviewType[]) => [
                ...files,
                ...prevBackgrounds,
              ])
            }
          >
            <Text on="white">Upload your own background images</Text>
          </Uploader>
          {customBackgrounds?.map((custom: PreviewType) => (
            <span key={custom.path} className="flex flex-col gap-2">
              <button
                type="button"
                className="BackgroundImageTab__custom-bkgd-btn"
                onClick={() => handleSetCustomBackground(custom)}
              >
                <img src={custom.preview} alt={custom.path} />
              </button>
            </span>
          ))}
          {photos.map((photo) => (
            <span key={photo.id} className="flex flex-col gap-2">
              <PhotoButton
                photo={photo}
                onClick={() => setBackgroundPhoto(photo)}
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
