/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3Position } from '../models/V3Position';
import { HttpFile } from '../http/http';

export class V3PositionTargets {
    'allowUnknown'?: boolean;
    'positions'?: Array<V3Position>;
    /**
    * ID of the position target.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "allowUnknown",
            "baseName": "allow_unknown",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "positions",
            "baseName": "positions",
            "type": "Array<V3Position>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3PositionTargets.attributeTypeMap;
    }

    public constructor() {
    }
}

