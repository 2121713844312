import { useState } from 'react'
import { Button, Tooltip } from '@eltoro-ui/components'
import classNames from 'classnames'

export const LibraryTableAttachButton = ({
  isAttached,
  onClick,
  disabled,
  disabledReason,
  allowDetach = true,
}: {
  isAttached: boolean
  onClick: (event?: React.MouseEvent) => void
  disabled?: boolean
  disabledReason?: string
  allowDetach?: boolean
}) => {
  const [hovered, setHovered] = useState(false)

  const baseClassName =
    'LibraryTableAttachButton w-auto font-bold transition-all duration-500 ease-in-out'

  const MainButton = (
    <Button
      kind="default"
      onClick={(e) => {
        e?.stopPropagation()
        onClick()
      }}
      className={classNames(baseClassName, {
        [`${baseClassName}--selected !bg-success text-tint-light !border-success`]: isAttached,
        'hover:!border-warning-400 hover:!bg-warning-400':
          isAttached && allowDetach,
      })}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      disabled={disabled}
    >
      {(() => {
        if (hovered && isAttached && allowDetach) return 'REMOVE'
        if (isAttached) return 'SELECTED'
        return 'SELECT'
      })()}
    </Button>
  )

  return disabled && disabledReason ? (
    <Tooltip
      position="left"
      content={disabledReason}
      classNameTooltip="min-w-[10rem]"
    >
      {MainButton}
    </Tooltip>
  ) : (
    MainButton
  )
}
