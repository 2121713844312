import { useRef, useState, useEffect } from 'react'
import {
  Button,
  FormItem,
  TextInput,
  Fa,
  Text,
  showErrorMessage,
  Tooltip,
  TextHeader,
  triggerPrompt,
} from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { useLocation } from 'react-router-dom'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Timeframe,
  V2Target,
  V1CreativeFile,
  Targetjobservicev1Audience,
  V1AudienceType,
  Targetjobservicev1NoteType,
} from 'next-gen-sdk'
import { formatByteString } from '@eltoro-ui/helpers'
import {
  DurationOrDimensionDetail,
  checkIfCreative,
  styleTailwind,
  userFriendlyAudienceName,
  userFriendlyJobStatus,
  getJobCounts,
  readableCreativeType,
  convertMetadata,
  getFileType,
  ErrorMessage,
  isValidCharLength,
  validCheck,
  capitalize,
} from 'Helpers'
import { useLibraryContext, useAppContext } from 'Contexts'
import { downloadFile, getFullJobs } from 'Requests'
import {
  CreativeThumbnail,
  CreativePreview,
  OrderLineCount,
  AdTagViewer,
  PermalinkButton,
  Loading,
} from 'Components'
import { useSSEListener } from 'Hooks'
import {
  FolderListButton,
  LocationSection,
  SubJobBreakdown,
  EditNativeBanner,
  RelatedAudiences,
  CloneAudience,
  ZipPreview,
  AdOpsSettings,
  HiddenSegment,
  CreativeDimensions,
  RemoveButton,
  ColumnEditModal,
  ReplaceFileModal,
} from './components'
import { Feature } from 'geojson'
import { NotesModal } from './components/NotesModal'
import { useAudienceNotes } from './useAudienceNotes'
import './PreviewPanel.scss'
import classNames from 'classnames'
import { AUDIENCE_NOTE_LIMIT } from 'constantVars'

const DividerRule = styleTailwind(
  'hr',
  'divider-rule border-t-thin border-tint-gray-500 my-4',
)

const Stats = styleTailwind('div', 'Stats__detail')

const StatsLink = styleTailwind(
  'span',
  'Stats__link font-bold text-base-info-500',
)

const PreviewDetail = styleTailwind('span', 'PreviewPanel__detail')

export const PreviewPanel: React.FC<{
  previewItem: Creativeservicev1Creative | Targetjobservicev1Audience
  onUseInCampaign?: () => void
  isAttached?: boolean
}> = ({ previewItem, onUseInCampaign, isAttached }) => {
  const [editingNotes, setEditingNotes] = useState(false)
  const [editingHeader, setEditingHeader] = useState(false)
  const [editedName, setEditedName] = useState<string>('')
  // Native Editing State
  const [editing, setEditing] = useState(false)
  // prettier-ignore
  const [previewedFile, setPreviewedFile] = useState<
    V1CreativeFile
  >()
  const [target, setTarget] = useState<V2Target>()
  const [timeFrames, setTimeFrames] = useState<Targetjobservicev1Timeframe[]>()
  const [zipPreviewList, setZipPreviewList] = useState<string[]>([])
  const [mapFeatures, setMapFeatures] = useState<Feature[]>([])
  const [adTagOpen, setAdTagOpen] = useState(false)
  const [columnModalOpen, setColumnModalOpen] = useState(false)
  const [replaceFileOpen, setReplaceFileOpen] = useState(false)
  // prettier-ignore
  const [
    notesModalOpen,
    setNotesModalOpen,
  ] = useState<Targetjobservicev1NoteType>()
  const [hasDeployments, setHasDeployments] = useState<boolean>()
  const [fetchTarget, setFetchTarget] = useState(true)
  const [notes, setNotes] = useState('')
  const [subJobs, setSubJobs] = useState<Targetjobservicev1Audience[]>()
  const {
    removeFromFolder,
    deleteFolder,
    renameFolder,
    moveToNewFolder,
    folderList,
  } = useLibraryContext()
  const {
    targetServiceApi,
    audienceServiceApi,
    creativeServiceApi,
    isAdmin,
    roles,
    groups,
    tok,
    isOnHold,
  } = useAppContext()
  const { pathname } = useLocation()
  const [targetUpdated, handleClearTargetUpdated] = useSSEListener(
    'TargetFileCleaned',
  )
  const {
    notesList,
    currentNote,
    setCurrentNote,
    handleSaveNote,
    handleDeleteNote,
    handleUpdateNote,
  } = useAudienceNotes({
    previewItem,
    setEditingNotes,
  })
  const isOnSearchPage = pathname.includes('search')

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const notesSaveButtonRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside([textAreaRef, notesSaveButtonRef], () =>
    setEditingNotes(false),
  )
  const nameInputRef = useRef<HTMLInputElement>(null)
  const saveButtonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([nameInputRef, saveButtonRef], () => {
    setEditingHeader(false)
  })

  const previewType = checkIfCreative(previewItem) ? 'Creative' : 'Audience'

  useEffect(() => {
    const handleDeployments = async () => {
      if (!checkIfCreative(previewItem)) {
        if (
          previewItem.type !== 'AUDIENCE_TYPE_VR' &&
          previewItem.deployments
        ) {
          setHasDeployments(previewItem.deployments.length > 0)
          return
        }
        // if vr, fetch full jobs
        if (previewItem.audiences && previewItem.orgId) {
          const subJobs = await getFullJobs(
            previewItem.audiences,
            tok,
            previewItem.orgId,
          )
          setSubJobs(subJobs)
          const audienceHasDeployments = subJobs.some(
            (subJob) => subJob.deployments && subJob.deployments.length > 0,
          )
          setHasDeployments(audienceHasDeployments)
        }
      }
    }
    handleDeployments()
  }, [hasDeployments, previewItem, tok])

  const isInQuote =
    !checkIfCreative(previewItem) &&
    (previewItem.status === 'AUDIENCE_STATUS_RUNNING' ||
      previewItem.status === 'AUDIENCE_STATUS_QUEUED' ||
      previewItem.status === 'AUDIENCE_STATUS_WAITING')

  useEffect(() => {
    if (!checkIfCreative(previewItem) && previewItem.orgId && fetchTarget) {
      if (!targetServiceApi || !previewItem.targetId || isInQuote) return
      targetServiceApi
        .advertisingPlatformServiceGetTarget(
          previewItem.targetId,
          previewItem.orgId,
        )
        .then((target) => {
          setTarget(target)
          setMapFeatures([])
          if (
            target?.fileType === 'FILE_TYPE_GEOJSON' &&
            !checkIfCreative(previewItem)
          ) {
            downloadFile({ target, audience: previewItem }, true, true).then(
              (res) => {
                setMapFeatures(res?.feature_collection)
              },
            )
          }
          if (
            target?.fileType === 'FILE_TYPE_ZIP' &&
            !checkIfCreative(previewItem)
          ) {
            downloadFile({ target, audience: previewItem }, true, true).then(
              (res) => {
                setZipPreviewList(
                  res
                    .split(/\r?\n/)
                    .filter((zip: string) => zip.match(/^\d{5}$/)),
                )
              },
            )
          }
        })
        .finally(() => setFetchTarget(false))
    }
  }, [fetchTarget, isInQuote, previewItem, targetServiceApi])

  useEffect(() => {
    if (targetUpdated) {
      setFetchTarget(true)
      handleClearTargetUpdated()
    }
  }, [handleClearTargetUpdated, targetUpdated])

  // reset name edit when changing preview item
  useEffect(() => {
    setEditedName(previewItem.name || '')
    setEditingHeader(false)
  }, [previewItem.name])
  // reset time frames when edited
  useEffect(() => {
    if (!checkIfCreative(previewItem)) {
      setTimeFrames(previewItem.timeframes)
      setFetchTarget(true)
    }
  }, [previewItem])

  // need to open the admin OR user modal based on the currentNote
  useEffect(() => {
    if (currentNote?.type) setNotesModalOpen(currentNote.type)
  }, [currentNote])

  const handleSaveHeader = (newName: string) => {
    if (!previewItem.id || !previewItem.orgId) return
    if (checkIfCreative(previewItem)) {
      creativeServiceApi
        ?.advertisingPlatformServiceUpdateCreative(
          previewItem.id,
          previewItem.orgId,
          {
            name: newName,
          },
          'name',
        )
        .then(() => setEditingHeader(false))
        .catch((err) => {
          showErrorMessage(
            'Error updating creative name',
            err?.body?.message
              ? capitalize(err?.body?.message)
              : 'There was an error saving your new creative name. Please try again.',
          )
        })
    } else {
      audienceServiceApi
        ?.advertisingPlatformServiceUpdateAudience(
          previewItem.id,
          previewItem.orgId,
          {
            name: newName,
          },
          'name',
        )
        .then(() => setEditingHeader(false))
        .catch((err) => {
          showErrorMessage(
            'Error updating audience name',
            err?.body?.message
              ? capitalize(err?.body?.message)
              : 'There was an error saving your new audience name. Please try again.',
          )
        })
    }
  }

  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  const isDownloadable = () => {
    if (
      !checkIfCreative(previewItem) &&
      (previewItem.locked ||
        previewItem.hidden ||
        previewItem.type === 'AUDIENCE_TYPE_ADDRESSES_FOR_IP' ||
        previewItem.type === 'AUDIENCE_TYPE_REVERSE') &&
      isAdmin
    ) {
      return true
    }
    if (
      !checkIfCreative(previewItem) &&
      (previewItem.locked ||
        previewItem.hidden ||
        previewItem.type === 'AUDIENCE_TYPE_ADDRESSES_FOR_IP' ||
        previewItem.type === 'AUDIENCE_TYPE_REVERSE')
    ) {
      return false
    }
    if (!checkIfCreative(previewItem) && target?.fileType) {
      if (
        [
          'FILE_TYPE_ADDRESS',
          'FILE_TYPE_IP',
          'FILE_TYPE_ZIP',
          'FILE_TYPE_DEVICE',
        ].some((ft: string) => ft === target.fileType)
      )
        return true

      return (
        isAdmin &&
        (previewItem.productType === 'PRODUCT_TYPE_VENUE_REPLAY' ||
          previewItem.productType === 'PRODUCT_TYPE_MAP_POLYGON')
      )
    }

    return true
  }

  const nameCharacterLimit = checkIfCreative(previewItem) ? 400 : 255

  // fields needed: https://eltorocorp.atlassian.net/wiki/spaces/PNG/pages/2079195510/Audience+Details+Breakdown
  const dropdownTable = () => {
    if (checkIfCreative(previewItem) || !previewItem.result) return
    const { matchRate } = previewItem.result
    const cleaned =
      target?.locations && target.locations.find((c) => c.type === 'cleaned')
    const data = convertMetadata(cleaned?.metadata)
    if (!data) {
      return (
        <div className="Details Details__data">
          <TextHeader type={5} className="Details__header">
            Details
          </TextHeader>
          <PreviewDetail>
            <strong>Match Rate:</strong>
            <span>{matchRate ? (matchRate * 100).toFixed(1) : 'N/A'}</span>
          </PreviewDetail>
        </div>
      )
    }
    const {
      badAddresses,
      badZips,
      duplicates,
      remainingLines,
      poBoxes,
      totalLines,
      apartments,
      specialChars,
    } = data.bucketReview
    return (
      <div className="Details Details__addresses">
        <TextHeader type={5} className="Details__header">
          Details
        </TextHeader>
        <>
          {previewItem &&
            !checkIfCreative(previewItem) &&
            previewItem.type !== 'AUDIENCE_TYPE_ESCROWMOVER' &&
            previewItem.type !== 'AUDIENCE_TYPE_POSTMOVER' &&
            previewItem.type !== 'AUDIENCE_TYPE_PREMOVER' &&
            previewItem.type !== 'AUDIENCE_TYPE_DC' &&
            previewItem.type !== 'AUDIENCE_TYPE_IPSFORGEOJSON' &&
            previewItem.type !== 'AUDIENCE_TYPE_IPSFORWKB' &&
            previewItem.type !== 'AUDIENCE_TYPE_REVERSE' &&
            previewItem.type !== 'AUDIENCE_TYPE_ZIP' && (
              <Stats>
                <strong>Match Rate:</strong>
                <span>
                  {matchRate ? `${(matchRate * 100).toFixed(1)}%` : 'N/A'}
                </span>
              </Stats>
            )}
          {target?.fileType === 'FILE_TYPE_ADDRESS' &&
            previewItem.type !== 'AUDIENCE_TYPE_DC' && (
              <>
                <Stats>
                  <strong>Apartments: </strong>
                  <span>{apartments !== undefined ? apartments : 'N/A'}</span>
                </Stats>
                <Stats>
                  <strong>P.O. Boxes: &nbsp;</strong>
                  <span>{poBoxes !== undefined ? poBoxes : 'N/A'}</span>
                </Stats>
              </>
            )}
          {target?.fileType === 'FILE_TYPE_ADDRESS' && (
            <>
              <Stats>
                <strong>Bad Addresses:</strong>
                <span>{badAddresses !== undefined ? badAddresses : 'N/A'}</span>
              </Stats>
              <Stats>
                <strong>Special Characters:</strong>
                <span>{specialChars !== undefined ? specialChars : 'N/A'}</span>
              </Stats>
            </>
          )}
          {(target?.fileType === 'FILE_TYPE_ZIP' ||
            target?.fileType === 'FILE_TYPE_ADDRESS') && (
            <Stats>
              <strong>Bad Zips:</strong>
              <span>{badZips !== undefined ? badZips : 'N/A'}</span>
            </Stats>
          )}
          {target?.fileType === 'FILE_TYPE_ZIP' && (
            <Stats>
              <strong>Total Zips:</strong>
              <span>{totalLines !== undefined ? totalLines : 'N/A'}</span>
            </Stats>
          )}
          {target?.fileType === 'FILE_TYPE_IP' && (
            <Stats>
              <strong>Bad IPs:</strong>
              <span>
                {(totalLines || 0) - (previewItem.result?.matched || 0)}
              </span>
            </Stats>
          )}
          {target?.fileType === 'FILE_TYPE_DEVICE' && (
            <Stats>
              <strong>Bad MAIDs:&nbsp;</strong>
              <span>
                {(totalLines || 0) - (previewItem.result?.matched || 0)}
              </span>
            </Stats>
          )}
          <Stats>
            <strong>Duplicates:</strong>
            <span>{duplicates ? duplicates : 0}</span>
          </Stats>
          {previewItem?.type === 'AUDIENCE_TYPE_DC' && (
            <Stats>
              <strong>Total Uploaded:</strong>
              <span>{totalLines !== undefined ? totalLines : 'N/A'}</span>
            </Stats>
          )}
          {target?.fileType === 'FILE_TYPE_ADDRESS' &&
            previewItem.type !== 'AUDIENCE_TYPE_DC' && (
              <Stats>
                <strong>Remaining Lines:</strong>
                <span>{remainingLines ? remainingLines : 'N/A'}</span>
              </Stats>
            )}
        </>
      </div>
    )
  }
  const matchNamer = (audType: V1AudienceType) => {
    if (checkIfCreative(previewItem)) return 'N/A'
    if (!previewItem.type || !previewItem.result) return 'N/A'
    if (
      audType === 'AUDIENCE_TYPE_ADDRESS' ||
      audType === 'AUDIENCE_TYPE_B2B' ||
      audType === 'AUDIENCE_TYPE_B2C'
    ) {
      return (
        <PreviewDetail>
          <strong>Matches:</strong>
          <Tooltip
            classNameTooltip={`Tooltip__${audType}`}
            position="bottomLeft"
            content={dropdownTable()}
          >
            <StatsLink>
              {getJobCounts(previewItem).toLocaleString()}/
              {previewItem.result?.submitted?.toLocaleString()}
            </StatsLink>
          </Tooltip>
        </PreviewDetail>
      )
    }
    if (
      audType === 'AUDIENCE_TYPE_REVERSE' ||
      audType === 'AUDIENCE_TYPE_IP' ||
      audType === 'AUDIENCE_TYPE_ADDRESSES_FOR_IP'
    ) {
      return (
        <>
          <PreviewDetail>
            <strong>Total Uploaded:</strong>
            <Tooltip
              position="bottomLeft"
              classNameTooltip={`${audType}_tooltip`}
              content={dropdownTable()}
            >
              <StatsLink>{previewItem.result?.submitted}</StatsLink>
            </Tooltip>
          </PreviewDetail>
          <PreviewDetail>
            <strong>Matched:</strong>
            <Tooltip
              position="bottomLeft"
              classNameTooltip={`${audType}_tooltip`}
              content={dropdownTable()}
            >
              <StatsLink>
                {getJobCounts(previewItem).toLocaleString()}
              </StatsLink>
            </Tooltip>
          </PreviewDetail>
        </>
      )
    }
    if (audType === 'AUDIENCE_TYPE_ZIP') {
      return (
        <>
          <PreviewDetail>
            <strong>Matched Addresses:</strong>
            <Tooltip
              position="left"
              classNameTooltip={`${audType}_tooltip`}
              content={dropdownTable()}
            >
              <StatsLink>
                {getJobCounts(previewItem).toLocaleString()}/
                {previewItem.result?.addressesFound?.toLocaleString()}
              </StatsLink>
            </Tooltip>
          </PreviewDetail>
          <PreviewDetail>
            <strong>Matched ZIPs:</strong>
            <Tooltip
              position="left"
              classNameTooltip={`${audType}_tooltip`}
              content={dropdownTable()}
            >
              <StatsLink>{zipPreviewList.length || 'N/A'}</StatsLink>
            </Tooltip>
          </PreviewDetail>
        </>
      )
    }
    if (
      audType === 'AUDIENCE_TYPE_ESCROWMOVER' ||
      audType === 'AUDIENCE_TYPE_POSTMOVER' ||
      audType === 'AUDIENCE_TYPE_PREMOVER'
    ) {
      const matches = getJobCounts(previewItem)
      if (!matches) return 'No Matches'
      return (
        <Stats>
          <strong>Matches:</strong>
          <span>{matches.toLocaleString()}</span>
        </Stats>
      )
    }
    if (audType === 'AUDIENCE_TYPE_IPSFORGEOJSON') {
      const matches = getJobCounts(previewItem)
      return (
        <Stats>
          <span
            className="PreviewPanel__detail text-center font-bold hover:underline"
            data-tooltip="right"
            aria-label={
              !matches
                ? 'No matches were found for the mapped locations. Please revise them or select another target audience.'
                : 'The audience is matched and ready to target the mapped locations.'
            }
          >
            {!matches ? 'No Matches' : 'Matched'}
          </span>
        </Stats>
      )
    }
    if (audType === 'AUDIENCE_TYPE_DEVICE') {
      return (
        <PreviewDetail>
          <strong>Matches:</strong>
          <Tooltip
            classNameTooltip={`${audType}_tooltip`}
            position="bottomLeft"
            content={dropdownTable()}
          >
            <StatsLink>
              {getJobCounts(previewItem).toLocaleString()}
              {previewItem?.result?.submitted &&
                `/${previewItem.result.submitted.toLocaleString()}`}
            </StatsLink>
          </Tooltip>
        </PreviewDetail>
      )
    }
    if (audType === 'AUDIENCE_TYPE_DC') {
      return (
        <PreviewDetail>
          <strong>Neighbors Matched:</strong>
          <Tooltip
            classNameTooltip={`${audType}_tooltip`}
            position="bottomLeft"
            content={dropdownTable()}
          >
            <StatsLink>
              <strong>{getJobCounts(previewItem).toLocaleString()}</strong>
              {previewItem?.result?.neighbors &&
                `/${previewItem.result.neighbors.toLocaleString()}`}
            </StatsLink>
          </Tooltip>
        </PreviewDetail>
      )
    }
    if (audType === 'AUDIENCE_TYPE_RETARGETING') {
      return (
        <div
          data-tooltip="bottom"
          aria-label={
            previewItem.status === 'AUDIENCE_STATUS_READY'
              ? 'The retargeting pixel is ready! Place the pixel on the desired website and attach this audience to an order line to begin targeting.'
              : undefined
          }
        >
          <span className="PreviewPanel__detail font-bold hover:underline">
            Ready to target
          </span>
        </div>
      )
    }
  }
  const isVROrMapPoly =
    !checkIfCreative(previewItem) &&
    (previewItem.productType === 'PRODUCT_TYPE_VENUE_REPLAY' ||
      previewItem.productType === 'PRODUCT_TYPE_MAP_POLYGON')
  const isNewMoverTarget =
    !checkIfCreative(previewItem) &&
    previewItem.productType === 'PRODUCT_TYPE_NEW_MOVERS'
  const isLeadershipOrDev =
    groups?.includes('/Operations/Leadership') || groups?.includes('/Dev')
  const canDownloadSelected =
    !checkIfCreative(previewItem) &&
    isLeadershipOrDev &&
    (previewItem.type === 'AUDIENCE_TYPE_IPSFORGEOJSON' ||
      previewItem.type === 'AUDIENCE_TYPE_ADDRESSES_FOR_IP')
  const canEdit = checkIfCreative(previewItem) || !previewItem.locked || isAdmin

  return (
    <div className="PreviewPanel">
      {/* Header/details */}
      <div className="PreviewPanel__header gap-4">
        <Text className="PreviewPanel__preview-type" tag="h4">
          {previewType} Preview
        </Text>
        {(roles && !roles.includes('nextgen_read_only')) || isOnHold ? (
          <Text
            className={`PreviewPanel__name relative flex gap-1 ${
              editingHeader ? 'h-auto' : ''
            }`}
            tag="h5"
          >
            {editingHeader ? (
              <FormItem
                className="mr-1 w-full"
                htmlFor="editedName"
                errorMessage={ErrorMessage({
                  fieldName: editedName,
                  max: nameCharacterLimit,
                  label: 'Creative name',
                })}
                valid={
                  validCheck(editedName) &&
                  isValidCharLength(editedName, nameCharacterLimit)
                }
              >
                <TextInput
                  classNameWrap="PreviewPanel__TextInput-wrap"
                  className="PreviewPanel__TextInput h-auto min-w-full"
                  value={editedName}
                  size="l"
                  onChange={(e) => setEditedName(e.target.value)}
                  inputRef={nameInputRef}
                  maxLength={nameCharacterLimit + 1}
                />
              </FormItem>
            ) : (
              <button
                type="button"
                className="PreviewPanel__detail--button m-0"
                onClick={() => setEditingHeader(true)}
                disabled={!canEdit || hasDeployments || isOnHold}
              >
                <span className="PreviewPanel__name-inner break-all">
                  {previewItem.name}
                </span>
              </button>
            )}
          </Text>
        ) : (
          <Text className="PreviewPanel__name-inner break-all pb-2" tag="h5">
            {previewItem.name}
          </Text>
        )}
        <div className="flex items-center justify-center gap-4">
          <Button
            className="Button--editor"
            rounded
            iconOnly={
              <span
                className="Tooltip-css-wrap"
                data-tooltip="top"
                aria-label={(() => {
                  if (editingHeader) return 'Type file name & click save icon'
                  if (!checkIfCreative(previewItem)) {
                    if (isOnHold) {
                      return 'Org is on hold'
                    }
                    if (!canEdit && previewItem.locked)
                      return 'Audience is locked'
                    if (hasDeployments)
                      return `Audience has deployments. It can't be edited`
                  }
                  return 'Click to edit name'
                })()}
              >
                <Fa
                  className="transition-all"
                  icon={editingHeader ? 'save' : 'pencil'}
                  size={1}
                />
              </span>
            }
            disabled={
              (editingHeader && !editedName) ||
              !canEdit ||
              hasDeployments ||
              isOnHold
            }
            onClick={() => {
              if (editingHeader && editedName) {
                if (
                  !checkIfCreative(previewItem) &&
                  previewItem.locked &&
                  isAdmin
                ) {
                  triggerPrompt(
                    'Edit Audience Name',
                    <p>
                      This audience is currently locked. Are you sure you want
                      to change the name from{' '}
                      <strong>{previewItem.name}</strong> to{' '}
                      <strong>{editedName}</strong>?
                    </p>,
                    async () => handleSaveHeader(editedName),
                  )
                } else {
                  handleSaveHeader(editedName)
                }
              } else {
                setEditingHeader(true)
              }
            }}
            buttonRef={saveButtonRef}
          />
          <PermalinkButton
            url={(() => {
              if (checkIfCreative(previewItem))
                return `/campaigns/creative-library/${previewItem.id}?org_id=${previewItem.orgId}`
              return `/audiences/audience-library/${previewItem.id}?org_id=${previewItem.orgId}`
            })()}
          />
        </div>

        <div className="PreviewPanel__detail-container border-tint-gray-200 border-t-thin flex flex-col">
          {previewItem.id && (
            <PreviewDetail className="pb-1">
              <strong>ID:</strong>
              <span>{previewItem.id}</span>
            </PreviewDetail>
          )}
          <PreviewDetail className="type">
            <strong>Type:</strong>&nbsp;
            {(() => {
              if (previewItem.type) {
                if (checkIfCreative(previewItem)) {
                  return readableCreativeType(previewItem.type)
                }
                return userFriendlyAudienceName(previewItem.type)
              }
              return 'Unknown'
            })()}
          </PreviewDetail>
          {!checkIfCreative(previewItem) && (
            <PreviewDetail className="status">
              <strong>Status:</strong>&nbsp;
              {previewItem.status && userFriendlyJobStatus(previewItem.status)}
            </PreviewDetail>
          )}
          {checkIfCreative(previewItem) &&
            previewItem.files?.[0]?.size &&
            previewItem.type !== 'CREATIVE_TYPE_NATIVE_BANNER' && (
              <PreviewDetail className="fileSize">
                <strong>File Size:</strong>&nbsp;
                <span>
                  {formatByteString(Number(previewItem.files[0].size))}
                </span>
              </PreviewDetail>
            )}
          {checkIfCreative(previewItem) && (
            <PreviewDetail className="fileType flex">
              <strong>File Type:</strong>&nbsp;
              <span>{getFileType(previewItem)}</span>
            </PreviewDetail>
          )}
          {/* only show file dimensions if there is only one file to display */}
          {checkIfCreative(previewItem) && (
            <PreviewDetail>
              {DurationOrDimensionDetail(previewItem)}
            </PreviewDetail>
          )}

          {/* Audience matches */}
          {!checkIfCreative(previewItem) &&
            previewItem.result &&
            previewItem.type &&
            (previewItem.status === 'AUDIENCE_STATUS_READY' ||
              previewItem.status === 'AUDIENCE_STATUS_COMPLETED') &&
            matchNamer(previewItem.type)}
        </div>
      </div>
      {!checkIfCreative(previewItem) && previewItem.hidden && <HiddenSegment />}
      {checkIfCreative(previewItem) && (
        <CreativeDimensions creative={previewItem} />
      )}
      <DividerRule />
      {checkIfCreative(previewItem) &&
        previewItem.type !== 'CREATIVE_TYPE_AD_TAG' &&
        previewItem.type !== 'CREATIVE_TYPE_VAST_TAG' &&
        previewItem.type !== 'CREATIVE_TYPE_HTML5' && (
          <div className="PreviewPanel__section pt-2">
            <Text className="PreviewPanel__section-label flex flex-1 justify-between gap-2">
              <span className="PreviewPanel__section-header">Preview</span>
              <span className="font-light">Click to enlarge</span>
            </Text>
            <div className="PreviewPanel__thumbnails">
              {previewItem.type === 'CREATIVE_TYPE_NATIVE_BANNER'
                ? previewItem.files?.map((file) => (
                    <div
                      key={file.id}
                      className="PreviewPanel__native-size-thumb-overlay relative"
                    >
                      {file.width && file.height && (
                        <div className="FileDimensions bg-gray absolute left-[0.125rem] rounded-br px-2 py-1 text-xs opacity-75">
                          {file.width} x {file.height}
                        </div>
                      )}
                      <CreativeThumbnail
                        key={file.id}
                        file={file}
                        onClick={() => setPreviewedFile(file)}
                      />
                    </div>
                  ))
                : previewItem.files?.map((file) => (
                    <CreativeThumbnail
                      key={file.id}
                      file={file}
                      onClick={() => setPreviewedFile(file)}
                    />
                  ))}
            </div>
            <DividerRule />
          </div>
        )}
      {checkIfCreative(previewItem) &&
        (previewItem.type === 'CREATIVE_TYPE_AD_TAG' ||
          previewItem.type === 'CREATIVE_TYPE_VAST_TAG') && (
          <div className="PreviewPanel__section flex flex-col gap-2">
            <Text className="PreviewPanel__section-label flex flex-1 justify-between gap-2">
              <span className="PreviewPanel__section-header">Script</span>
              <span className="font-light">Click to view/edit</span>
            </Text>
            <div
              className="PreviewPanel__thumbnails border-thin hover:border-grey-300 border-transparent"
              onClick={() => setAdTagOpen(true)}
            >
              <p className="block cursor-pointer break-all">
                {previewItem.adTag}
              </p>
            </div>
            <DividerRule />
          </div>
        )}
      {/* Pixel Scripts */}
      {!checkIfCreative(previewItem) &&
        previewItem.result &&
        previewItem.type === 'AUDIENCE_TYPE_RETARGETING' &&
        previewItem.status === 'AUDIENCE_STATUS_READY' &&
        previewItem.result.imagePixel &&
        previewItem.result.scriptPixel && (
          <div className="PreviewPanel__section flex flex-col gap-2 pb-4">
            <Text className="PreviewPanel__section-header flex-1">
              JavaScript
            </Text>
            <div className="border-grey-500 text-grey-500 border-thin break-all p-1 leading-normal">
              {previewItem.result?.scriptPixel}
            </div>
            <div className="flex justify-end pt-2">
              <Button
                type="button"
                onClick={() => {
                  if (!previewItem.result) return
                  if (previewItem.result?.scriptPixel) {
                    navigator.clipboard.writeText(
                      previewItem.result?.scriptPixel,
                    )
                  }
                }}
              >
                Copy
              </Button>
            </div>
            <Text className="PreviewPanel__section-header flex-1">
              Image Tag
            </Text>
            <div className="border-grey-500 text-grey-500 border-thin break-all p-1 leading-normal">
              {previewItem.result?.imagePixel}
            </div>
            <div className="flex justify-end pt-2">
              <Button
                type="button"
                onClick={() => {
                  if (!previewItem.result) return
                  if (previewItem.result?.imagePixel) {
                    navigator.clipboard.writeText(
                      previewItem.result?.imagePixel,
                    )
                  }
                }}
              >
                Copy
              </Button>
            </div>
          </div>
        )}
      {/* Native banner metadata */}
      {checkIfCreative(previewItem) &&
        previewItem.type === 'CREATIVE_TYPE_NATIVE_BANNER' && (
          <>
            <Text className="PreviewPanel__section-header flex-1">
              Native details
            </Text>
            {/* Removing edit ability in lieu of Sunflower not supporting it currently */}
            {!editing && false && (
              <span
                onClick={() => {
                  if (!editing) {
                    return setEditing(true)
                  }
                  return setEditing(false)
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  if (!editing) {
                    return setEditing(true)
                  }
                  return setEditing(false)
                }}
              >
                {!editing && 'Click to Edit'}
              </span>
            )}
            <EditNativeBanner
              editing={editing}
              previewItem={previewItem}
              cancelEdit={(x: boolean) => setEditing(x)}
            />
            <DividerRule />
          </>
        )}
      {/* Use in campaign button */}
      {(() => {
        if (
          // temporary- these can't be added to political campaigns
          // avoiding having to write the logic to force the campaign to be non-political at that point,
          // but let me know if i should do that. i suspect this button isn't used much.
          !isInCampaignCreation &&
          (previewItem.type === 'CREATIVE_TYPE_NATIVE_BANNER' ||
            previewItem.type === 'AUDIENCE_TYPE_RETARGETING' ||
            previewItem.type === 'CREATIVE_TYPE_HTML5')
        )
          return null
        if (!isOnHold && onUseInCampaign) {
          return (
            <>
              <Button
                width="100%"
                iconLeft={
                  isInCampaignCreation ? (
                    <Fa icon={isAttached ? 'minus' : 'plus'} size={1} />
                  ) : (
                    <Fa icon="external-link" size={1} />
                  )
                }
                kind="primary"
                onClick={onUseInCampaign}
                className="PreviewPanel__campaign-btn"
                disabled={
                  isInQuote &&
                  !checkIfCreative(previewItem) &&
                  !previewItem.result?.matched
                }
              >
                {(() => {
                  if (isInCampaignCreation) {
                    const type = checkIfCreative(previewItem)
                      ? 'creatives'
                      : 'audiences'
                    return isAttached
                      ? `Remove from selected ${type}`
                      : `Add to selected ${type}`
                  }

                  return 'Use in new campaign'
                })()}
              </Button>
              <DividerRule />
            </>
          )
        }
      })()}

      {/* ZIP Preview */}
      {!checkIfCreative(previewItem) &&
        target?.fileType === 'FILE_TYPE_ZIP' &&
        !!zipPreviewList.length && (
          <>
            <ZipPreview
              zipCodes={zipPreviewList}
              audience={previewItem}
              target={target}
            />
            <DividerRule />
          </>
        )}
      {/* VR Sub job breakdown */}
      {!checkIfCreative(previewItem) &&
        previewItem.type === 'AUDIENCE_TYPE_VR' && (
          <>
            <SubJobBreakdown
              audience={previewItem}
              hasDeployments={hasDeployments}
              target={target}
            />
            <DividerRule />
          </>
        )}
      {/* Notes  */}
      {!checkIfCreative(previewItem) && (
        <>
          <div className="PreviewPanel__section relative">
            <div className="PreviewPanel__section-inner flex items-center !gap-1">
              <span className="PreviewPanel__section-label flex flex-1 justify-between">
                <Text className="PreviewPanel__section-header flex-1">
                  Notes
                </Text>
                <span className="font-light">
                  Limit {AUDIENCE_NOTE_LIMIT} characters
                </span>
              </span>
              {((roles && !roles.includes('nextgen_read_only')) ||
                isOnHold) && (
                <Button
                  className="Button--editor"
                  rounded
                  buttonRef={notesSaveButtonRef}
                  disabled={
                    editingNotes &&
                    (!notes || notes.length > AUDIENCE_NOTE_LIMIT)
                  }
                  iconOnly={
                    <span
                      data-tooltip="left"
                      aria-label={(() => {
                        if (editingNotes) {
                          if (notes.length > AUDIENCE_NOTE_LIMIT)
                            return `Note is over the character limit of ${AUDIENCE_NOTE_LIMIT}`
                          return 'Click icon to save'
                        }
                        return 'Click to add notes'
                      })()}
                    >
                      <Fa icon={editingNotes ? 'save' : 'pencil'} size={1} />
                    </span>
                  }
                  onClick={(e) => {
                    e?.preventDefault()
                    if (editingNotes) {
                      handleSaveNote('NOTE_TYPE_USER', notes).then(() =>
                        setNotes(''),
                      )
                    } else {
                      setEditingNotes(true)
                    }
                  }}
                />
              )}
            </div>
            {editingNotes ? (
              <>
                <textarea
                  className={classNames(
                    'PreviewPanel__text-area border-grey-400 resize-none rounded border p-2',
                    {
                      'border-warning animate-glowwarning text-warning-500':
                        notes.length > AUDIENCE_NOTE_LIMIT,
                    },
                  )}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  ref={textAreaRef}
                />
                <p
                  className={classNames('text-right text-xs font-semibold', {
                    'text-warning-500': notes.length > AUDIENCE_NOTE_LIMIT,
                  })}
                >
                  Remaining: {AUDIENCE_NOTE_LIMIT - notes.length}
                </p>
              </>
            ) : (
              <button
                type="button"
                className="PreviewPanel__detail--button h-[2rem]"
                onClick={() => setEditingNotes(true)}
              >
                Click to add notes...
              </button>
            )}
            {(notesList || []).length > 0 && !editingNotes ? (
              <Button
                className="mt-2 w-full"
                iconLeft={<Fa icon="note-sticky" size={1} />}
                onClick={() => setNotesModalOpen('NOTE_TYPE_USER')}
              >{`View Notes (${(notesList || [])
                .filter((n) => n.type === 'NOTE_TYPE_USER')
                .length.toString()})`}</Button>
            ) : null}
          </div>
          <DividerRule />
        </>
      )}
      {/* Location (VR / Map Polygon) */}
      {!checkIfCreative(previewItem) &&
        previewItem.status !== 'AUDIENCE_STATUS_ERRORED' &&
        previewItem.status !== 'AUDIENCE_STATUS_RUNNING' &&
        previewItem.status !== 'AUDIENCE_STATUS_QUEUED' &&
        (previewItem.type === 'AUDIENCE_TYPE_VR' ||
          previewItem.type === 'AUDIENCE_TYPE_IPSFORGEOJSON' ||
          previewItem.type === 'AUDIENCE_TYPE_IPSFORWKB') && (
          <>
            {mapFeatures && mapFeatures.length > 0 ? (
              <>
                <LocationSection
                  job={previewItem}
                  timeFrames={timeFrames}
                  mapFeatures={mapFeatures}
                />
                <DividerRule />
              </>
            ) : (
              <Loading />
            )}
          </>
        )}
      {/* Used in order lines */}
      <div className="PreviewPanel__section UsedInOrderLineCounter">
        <div className="PreviewPanel__section-inner flex items-center justify-between">
          <Text className="PreviewPanel__section-label flex flex-1">
            <span className="PreviewPanel__section-header">
              Used in order lines
            </span>
          </Text>
          <OrderLineCount item={previewItem} isInPreviewPanel />
        </div>
      </div>
      <DividerRule />
      {!checkIfCreative(previewItem) &&
        canEdit &&
        target &&
        !isOnHold &&
        (previewItem.status === 'AUDIENCE_STATUS_READY' ||
          previewItem.status === 'AUDIENCE_STATUS_COMPLETED') &&
        previewItem.result &&
        previewItem.type !== 'AUDIENCE_TYPE_RETARGETING' &&
        !(
          hasDeployments && previewItem.type === 'AUDIENCE_TYPE_IPSFORGEOJSON'
        ) &&
        !isOnSearchPage && (
          <>
            <CloneAudience
              job={previewItem}
              target={target}
              DBFeatures={mapFeatures}
              hasDeployments={hasDeployments}
            />
            <DividerRule />
          </>
        )}
      {/* Related Audiences */}
      {!checkIfCreative(previewItem) &&
        canEdit &&
        target &&
        !isOnHold &&
        (previewItem.type === 'AUDIENCE_TYPE_DC' ||
          previewItem.type === 'AUDIENCE_TYPE_B2C') &&
        previewItem.status === 'AUDIENCE_STATUS_READY' &&
        previewItem.result &&
        (previewItem.result.matched ||
          previewItem.result.total ||
          previewItem.result.neighborsMatched) &&
        !isOnSearchPage && (
          <>
            <RelatedAudiences job={previewItem} target={target} />
            <DividerRule />
          </>
        )}
      {/* Folder, download, remove buttons */}
      <div className="PreviewPanel__section PreviewPanel__edit-buttons flex-1 py-2">
        <Text className="PreviewPanel__section-header">Edit</Text>
        {(() => {
          if (folderList && !isOnSearchPage) {
            return (
              <FolderListButton
                selectedFolderId={previewItem.folder as string}
                folders={folderList.filter((f) => f !== previewItem.folder)}
                onAddFolder={(folderName) => {
                  if (
                    !checkIfCreative(previewItem) &&
                    previewItem.locked &&
                    isAdmin
                  ) {
                    triggerPrompt(
                      'Change Audience Folder',
                      <p>
                        This audience is currently locked. Are you sure you want
                        to move <strong>{previewItem.name}</strong> to folder{' '}
                        <strong>{folderName}</strong>?
                      </p>,
                      async () =>
                        previewItem.id &&
                        previewItem.orgId &&
                        moveToNewFolder(
                          previewItem.id,
                          folderName,
                          previewItem.orgId,
                        ),
                    )
                  } else if (previewItem.id && previewItem.orgId) {
                    moveToNewFolder(
                      previewItem.id,
                      folderName,
                      previewItem.orgId,
                    )
                  }
                }}
                onRenameFolder={(newName) => {
                  renameFolder(
                    previewItem,
                    newName,
                    previewItem.orgId as string,
                  )
                }}
                onRemoveFromFolder={
                  previewItem.folder
                    ? () => {
                        if (
                          !checkIfCreative(previewItem) &&
                          previewItem.locked &&
                          isAdmin
                        ) {
                          triggerPrompt(
                            'Remove Audience From Folder',
                            <p>
                              This audience is currently locked. Are you sure
                              you want to remove
                              <strong> {previewItem.name} </strong>from folder
                              <strong> {previewItem.folder}</strong>?
                            </p>,
                            async () =>
                              previewItem.id &&
                              previewItem.orgId &&
                              removeFromFolder(
                                previewItem,
                                previewItem.folder || '',
                                previewItem.orgId,
                              ),
                          )
                        } else if (previewItem.orgId)
                          removeFromFolder(
                            previewItem,
                            previewItem.folder || '',
                            previewItem.orgId,
                          )
                      }
                    : undefined
                }
                onDeleteFolder={
                  previewItem.folder
                    ? () => {
                        deleteFolder(
                          previewItem,
                          previewItem.folder || '',
                          previewItem.orgId as string,
                        )
                      }
                    : undefined
                }
                locked={!canEdit || isOnHold}
                hasDeployments={!checkIfCreative(previewItem) && hasDeployments}
              />
            )
          }
        })()}
        {!checkIfCreative(previewItem) &&
          !isVROrMapPoly &&
          !isNewMoverTarget &&
          previewItem.type !== 'AUDIENCE_TYPE_RETARGETING' &&
          target &&
          !isOnSearchPage && (
            <>
              <div
                data-tooltip="top"
                aria-label={(() => {
                  if (isOnHold) {
                    return 'Org is on hold'
                  }
                  if (hasDeployments || (!canEdit && previewItem.locked)) {
                    return `Audience ${
                      previewItem.locked ? 'is locked' : 'has deployments'
                    } and cannot be changed`
                  }
                  return ''
                })()}
              >
                <Button
                  width="100%"
                  iconLeft={<Fa icon="table-columns" size={1} />}
                  onClick={() => {
                    if (
                      !checkIfCreative(previewItem) &&
                      previewItem.locked &&
                      isAdmin
                    ) {
                      triggerPrompt(
                        'Edit Audience Columns',
                        <p>
                          This audience is currently locked. Are you sure you
                          want to edit the columns for{' '}
                          <strong>{previewItem.name}</strong>?
                        </p>,
                        async () => setColumnModalOpen(true),
                      )
                    } else {
                      setColumnModalOpen(true)
                    }
                  }}
                  disabled={isInQuote || hasDeployments || !canEdit || isOnHold}
                >
                  Edit Columns
                </Button>
              </div>
              <div
                data-tooltip="top"
                aria-label={(() => {
                  if (isOnHold) {
                    return 'Org is on hold'
                  }
                  if (hasDeployments || (!canEdit && previewItem.locked)) {
                    return `Audience ${
                      previewItem.locked ? 'is locked' : 'has deployments'
                    } and cannot be changed`
                  }
                  return ''
                })()}
              >
                <Button
                  width="100%"
                  iconLeft={<Fa icon="upload" size={1} />}
                  onClick={() => {
                    if (
                      !checkIfCreative(previewItem) &&
                      previewItem.locked &&
                      isAdmin
                    ) {
                      triggerPrompt(
                        'Replace Audience File',
                        <p>
                          This audience is currently locked. Are you sure you
                          want to replace the file for{' '}
                          <strong>{previewItem.name}</strong>?
                        </p>,
                        async () => setReplaceFileOpen(true),
                      )
                    } else {
                      setReplaceFileOpen(true)
                    }
                  }}
                  disabled={
                    isInQuote ||
                    (previewItem.deployments || []).length > 0 ||
                    !canEdit ||
                    isOnHold
                  }
                >
                  Replace File
                </Button>
              </div>
            </>
          )}
        {(!isInCampaignCreation || !isOnHold) && (
          <RemoveButton
            previewItem={previewItem}
            hasDeployments={hasDeployments}
            subJobs={subJobs}
          />
        )}
        {(() => {
          const processNotCompleted =
            !checkIfCreative(previewItem) &&
            previewItem.result?.processCompleted !== 1
          if (isDownloadable() && !isOnHold) {
            return (
              <>
                <DividerRule />
                <Text className="PreviewPanel__section-header">Download</Text>
                {canDownloadSelected && (
                  <Button
                    width="100%"
                    iconLeft={<Fa icon="user-secret" size={1} />}
                    onClick={() => {
                      if (!checkIfCreative(previewItem) && target) {
                        downloadFile(
                          { target, audience: previewItem },
                          false,
                          false,
                          true,
                        )
                      }
                    }}
                  >
                    Download Selected
                  </Button>
                )}
                {/* if is target and is not new mover do not show */}
                {!isNewMoverTarget && (
                  <div
                    data-tooltip="top"
                    aria-label={
                      processNotCompleted
                        ? 'This append file is not available.'
                        : undefined
                    }
                  >
                    <Button
                      width="100%"
                      iconLeft={<Fa icon="download" size={1} />}
                      onClick={() => {
                        if (checkIfCreative(previewItem)) {
                          downloadFile(previewItem)
                        } else if (target) {
                          downloadFile({ target, audience: previewItem })
                        }
                      }}
                      disabled={processNotCompleted || isOnHold}
                    >
                      {checkIfCreative(previewItem)
                        ? 'Download'
                        : 'Download append'}
                    </Button>
                  </div>
                )}
                {!checkIfCreative(previewItem) && !isVROrMapPoly && !isOnHold && (
                  <Button
                    width="100%"
                    iconLeft={<Fa icon="download" size={1} />}
                    onClick={() => {
                      if (target)
                        downloadFile({ target, audience: previewItem }, true)
                    }}
                  >
                    Download Source
                  </Button>
                )}
              </>
            )
          }
        })()}
      </div>
      {isAdmin && !checkIfCreative(previewItem) && !isOnHold && (
        <AdOpsSettings
          previewItem={previewItem}
          notes={notesList?.filter((n) => n.type === 'NOTE_TYPE_ADMIN')}
          handleSaveNote={handleSaveNote}
          openNoteModal={() => setNotesModalOpen('NOTE_TYPE_ADMIN')}
          hasDeployments={hasDeployments}
        />
      )}
      {previewedFile && (
        <CreativePreview
          file={previewedFile}
          offClick={() => setPreviewedFile(undefined)}
        />
      )}
      {adTagOpen && checkIfCreative(previewItem) && previewItem.adTag && (
        <AdTagViewer
          adTag={previewItem.adTag}
          offClick={() => setAdTagOpen(false)}
          id={previewItem.id}
        />
      )}
      {columnModalOpen && target && !checkIfCreative(previewItem) && (
        <ColumnEditModal
          onClose={() => setColumnModalOpen(false)}
          target={target}
          audience={previewItem}
        />
      )}
      {replaceFileOpen && target && !checkIfCreative(previewItem) && (
        <ReplaceFileModal
          onClose={() => setReplaceFileOpen(false)}
          target={target}
          audience={previewItem}
        />
      )}
      {notesModalOpen && !checkIfCreative(previewItem) && (
        <NotesModal
          notes={
            notesModalOpen === 'NOTE_TYPE_ADMIN'
              ? notesList?.filter((n) => n.type === 'NOTE_TYPE_ADMIN')
              : notesList?.filter((n) => n.type === 'NOTE_TYPE_USER')
          }
          type={notesModalOpen}
          offClick={() => setNotesModalOpen(undefined)}
          currentNote={currentNote}
          setCurrentNote={setCurrentNote}
          handleSaveNote={handleSaveNote}
          handleDeleteNote={handleDeleteNote}
          handleUpdateNote={handleUpdateNote}
          audienceName={previewItem.name}
          orgId={previewItem.orgId}
        />
      )}
    </div>
  )
}
