/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* If a credit card is not provided, than the default credit card will be used. If the org is not billable, then the credit card will always be ignored and it will use the default card on the billable organization.
*/
export class PrepayRequest {
    /**
    * ID(s) of the Order Line to prepay
    */
    'orderLineId'?: Array<string>;
    /**
    * ID of the Credit Card to prepay with
    */
    'creditCardId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "creditCardId",
            "baseName": "credit_card_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PrepayRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

