import classNames from 'classnames'
import { TextPropsType } from './TextPropsType'
import './Text.scss'

export const Text = ({
  on,
  kind,
  children,
  size,
  lineHeight,
  tag = 'span',
  weight,
  textAlign,
  textTransform,
  textWrap,
  className = '',
  truncate = false,
  subComponent = true,
}: TextPropsType) => {
  const color =
    `${on !== undefined ? `text-on-${on}` : ''}` +
    `${kind !== undefined ? `--${kind}` : ''}`
  const TextAlign = `${textAlign !== undefined ? `text-${textAlign}` : ''}`

  const textClasses = classNames(
    {
      Text: !subComponent,
    },
    {
      'text-xs': size === 'xs',
      'text-s': size === 's',
      'text-m': size === 'm',
      'text-l': size === 'l',
      'text-xl': size === 'xl',
      'text-2xl': size === '2xl',
      'text-3xl': size === '3xl',
      'text-4xl': size === '4xl',
    },
    {
      'text-center': textAlign === 'center',
      'text-justify': textAlign === 'justify',
      'text-left': textAlign === 'left',
      'text-right': textAlign === 'right',
    },
    {
      'font-bold': weight === 'bold',
      'font-semibold': weight === 'semibold',
      'font-medium': weight === 'medium',
      'font-normal': weight === 'normal',
      'font-light': weight === 'light',
    },
    {
      'leading-tight': lineHeight === 'tight',
      'leading-snug': lineHeight === 'snug',
      'leading-loose': lineHeight === 'loose',
      'leading-none': lineHeight === 'none',
      'leading-normal': lineHeight === 'normal',
      'leading-relaxed': lineHeight === 'relaxed',
      'leading-3': lineHeight === '3',
      'leading-4': lineHeight === '4',
      'leading-5': lineHeight === '5',
      'leading-6': lineHeight === '6',
      'leading-7': lineHeight === '7',
      'leading-8': lineHeight === '8',
      'leading-9': lineHeight === '9',
      'leading-10': lineHeight === '10',
    },
    className,
    color,
    TextAlign,
    textTransform,
    textWrap,
    truncate,
  )

  const Element = tag

  return <Element className={textClasses}>{children}</Element>
}
