import React from 'react'
import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import './VerticalStepper.scss'

export type VerticalStepperConfig = {
  completed?: boolean
  errored?: boolean
  disabled?: boolean
  id: string
  onClick?: (id: string) => void
}[]

export const VerticalStepper = ({
  steps,
}: {
  steps: VerticalStepperConfig
}) => {
  return (
    <div className="VerticalStepper">
      {steps.map(({ id, onClick, completed, errored, disabled }, index) => (
        <button
          key={id}
          className={classNames('VerticalStepper__button', {
            'VerticalStepper__button--completed': completed,
            'VerticalStepper__button--errored': errored,
            'VerticalStepper__button--disabled': disabled,
          })}
          type="button"
          onClick={() => onClick && onClick(id)}
          disabled={disabled}
        >
          {(() => {
            if (!disabled && completed) return <Fa icon="check" size={1} />
            if (!disabled && errored) return <Fa icon="exclamation" size={1} />
            return index + 1
          })()}
        </button>
      ))}
    </div>
  )
}
