import {
  creativeTypeOptions,
  audienceTypeOptions,
  orderLineStatusOptions,
} from 'searchOptions'

export const sharedOptions = [{ label: 'All', value: 'all' }]

export const globalSearchAudienceOptions = [
  ...sharedOptions,
  ...audienceTypeOptions,
]

export const globalSearchCreativeOptions = [
  ...sharedOptions,
  ...creativeTypeOptions,
]

export const globalSearchOrderLineOptions = (isAdmin?: boolean) => [
  ...sharedOptions,
  ...orderLineStatusOptions(isAdmin),
]
