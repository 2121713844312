import {
  Targetjobservicev1Audience,
  Campaignservicev1Audience,
  V2TargetJob,
} from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'

// Get Targetjobservicev1Job from array of Campaignservicev1Audience
export const getFullJobs = async (
  jobs: Array<
    Targetjobservicev1Audience | Campaignservicev1Audience | V2TargetJob
  >,
  token: string,
  orgId: string,
) => {
  const apis = ApiFactory(token)
  const { audienceServiceAPI } = apis
  if (audienceServiceAPI) {
    const fullJobs: Targetjobservicev1Audience[] = await Promise.all(
      jobs.reduce((acc: Promise<Targetjobservicev1Audience>[], job) => {
        if (!job?.id) return acc
        return [
          ...acc,
          audienceServiceAPI.advertisingPlatformServiceGetAudience(
            job.id,
            orgId,
          ),
        ]
      }, []),
    )
    return fullJobs
  }
  return []
}
