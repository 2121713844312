// export const getSessionUser = () => {
//   const userString = localStorage.getItem('eltoro_user')
//   if (!userString) return { user: {} }
//   if (userString === 'undefined') return { user: {} }

//   const user = JSON.parse(userString || '{}')

//   return { user }
// }
// export const setSessionUser = (user: UserType) => {
//   localStorage.setItem('eltoro_user', JSON.stringify(user || '{}'))
// }

export const clearSession = () => {
  localStorage.removeItem('eltoro_token')
  // localStorage.removeItem('eltoro_user')
}

export const setAuthHeader = (token: string) => {
  localStorage.setItem('eltoro_token', token)
}

export const getAuthHeader = () => {
  const token = localStorage.getItem('eltoro_token')
  return { Authorization: `Bearer ${token}` }
}
