/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Creative } from '../models/Deployservicev3Creative';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V3InvestCampaign } from '../models/V3InvestCampaign';
import { V3Segment } from '../models/V3Segment';
import { HttpFile } from '../http/http';

/**
* The event emitted when Xandr Invest deploy configuration is changed
*/
export class V1OrderLineDestinationXandrInvestSet {
    'id'?: string;
    'advertiserId'?: string;
    'advertiserName'?: string;
    'clickThroughUrl'?: string;
    'investCampaign'?: V3InvestCampaign;
    'segments'?: Array<V3Segment>;
    'creatives'?: Array<Deployservicev3Creative>;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserName",
            "baseName": "advertiser_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickThroughUrl",
            "baseName": "click_through_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "investCampaign",
            "baseName": "invest_campaign",
            "type": "V3InvestCampaign",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "segments",
            "type": "Array<V3Segment>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Deployservicev3Creative>",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineDestinationXandrInvestSet.attributeTypeMap;
    }

    public constructor() {
    }
}

