import { ReactNode } from 'react'
import classNames from 'classnames'
import { Text, TextTypesUnionType } from '../Text'

export const TextDetail = (
  props: TextTypesUnionType & { className?: string; children: ReactNode },
) => {
  const { children, className } = props
  return (
    <Text
      className={classNames('TextDetail', className)}
      {...props}
      weight="semibold"
      size="m"
      lineHeight="tight"
      textTransform="uppercase"
      tag="div"
      subComponent
    >
      {children}
    </Text>
  )
}
