/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* The info about the TOS file being uploaded.
*/
export class Hagridv1FileMetaData {
    /**
    * The id of the org
    */
    'orgId'?: string;
    /**
    * The name of the Org.
    */
    'orgName'?: string;
    /**
    * The id of the user signing the file.
    */
    'userId'?: string;
    /**
    * The first name of the user.
    */
    'firstName'?: string;
    /**
    * The last name of the user.
    */
    'lastName'?: string;
    /**
    * The version being agreed upon.
    */
    'version'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "first_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "last_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "version",
            "baseName": "version",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Hagridv1FileMetaData.attributeTypeMap;
    }

    public constructor() {
    }
}

