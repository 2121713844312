/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1NoteType } from '../models/Campaignservicev1NoteType';
import { HttpFile } from '../http/http';

/**
* The note object on an Order Line or Org. This is where Ad Operations can take notes about that Order Line or Organization.
*/
export class Campaignservicev1Note {
    /**
    * The content of the note.
    */
    'content'?: string;
    /**
    * The timestamp the note was added.
    */
    'createTime'?: Date;
    /**
    * The timestamp the note was updated.
    */
    'updateTime'?: Date;
    'type'?: Campaignservicev1NoteType;
    'userId'?: string;
    'inactive'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "content",
            "baseName": "content",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Campaignservicev1NoteType",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "inactive",
            "baseName": "inactive",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1Note.attributeTypeMap;
    }

    public constructor() {
    }
}

