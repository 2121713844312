import React from 'react'
import { Fa, TextBody } from '@eltoro-ui/components'
import classNames from 'classnames'

export const ErrorMsg = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={classNames(
        'ErrorMsg bg-danger-50 text-danger mt-4 flex items-center gap-4 rounded-md p-4',
        className,
      )}
    >
      <Fa
        icon="circle-exclamation text-[color:var(--color-danger-600)]"
        size={3}
      />
      <TextBody className="text-danger-600" type={1}>
        <strong>Oh no!</strong>
      </TextBody>
      <TextBody className="text-danger-600" type={2}>
        {children}
      </TextBody>
    </div>
  )
}
