import { useEffect, useRef, useState, FC, ReactNode } from 'react'

export const CollapsibleContainer: FC<{
  isOpen: boolean
  className?: string
  classNameWrap?: string
  overrideHeight?: string
  children: ReactNode
}> = ({
  isOpen,
  overrideHeight,
  children,
  className = '',
  classNameWrap = '',
}) => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(bodyRef.current?.clientHeight)

  useEffect(() => {
    const handleResize = () => {
      setHeight(bodyRef.current?.clientHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!bodyRef.current) return
    setHeight(bodyRef.current.clientHeight)
  }, [bodyRef.current?.clientHeight, children])

  const finalHeight = overrideHeight || height
  return (
    <div
      className={`CollapsibleContainer ${className} ${
        isOpen
          ? 'overflow-visible !h-auto animate-slidedown'
          : 'overflow-hidden'
      } transition-all`}
      style={{ height: isOpen ? finalHeight : 0 }}
    >
      <div
        className={`CollapsibleContainer__BodyRef ${classNameWrap}`}
        ref={bodyRef}
      >
        {children}
      </div>
    </div>
  )
}
