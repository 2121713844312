/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { HttpFile } from '../http/http';

/**
* The event emitted when an OrderLine is updated
*/
export class V1OrderLineConfigured {
    'id'?: string;
    'refId'?: string;
    'orgId'?: string;
    'name'?: string;
    'startDate'?: Date;
    'endDate'?: Date;
    'campaignId'?: string;
    'clickThroughUrl'?: string;
    'impressions'?: number;
    /**
    * The amount of impressions the Order Line will serve per day.
    */
    'impressionsPerDay'?: number;
    /**
    * The amount of impressions adops is giving to the orderline for free.
    */
    'freeImpressions'?: number;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickThroughUrl",
            "baseName": "click_through_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "impressions",
            "baseName": "impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "impressionsPerDay",
            "baseName": "impressions_per_day",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "freeImpressions",
            "baseName": "free_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineConfigured.attributeTypeMap;
    }

    public constructor() {
    }
}

