import { useEffect, useState } from 'react'

export const getValidInputClasses = (
  max: number,
  valid: boolean,
  value: string,
  disabled?: boolean,
  ifSubmitted?: boolean,
) => {
  const touch = value.length > 0 && value !== ''
  const warning = !valid && ifSubmitted
  const lengthWarning = touch && (value.length < 2 || value.length > max)
  const lengthOK = value.length >= 2 && !lengthWarning && !disabled
  const inputWarning = touch && (warning || lengthWarning)

  return {
    // eslint-disable-next-line no-nested-ternary
    classNameWrap: inputWarning
      ? 'animate-glowwarning'
      : lengthOK
      ? 'animate-glowsuccess'
      : '',
    className:
      // eslint-disable-next-line no-nested-ternary
      inputWarning || warning
        ? 'border-warning border-default'
        : lengthOK
        ? '!border-success border-default'
        : '',
  }
}

export const isValidCharLength = (text: string, max: number, min = 2) => {
  return text.length <= max && text.length >= min
}

export const validCheck = (
  field: string,
  charLengthCheck = true,
  ifSubmitted?: boolean,
) => {
  if (ifSubmitted || (field !== '' && field.length < 2)) {
    if (field && charLengthCheck) {
      return true
    }
    return false
  }
  return true
}

export const ErrorMessage = ({
  fieldName = '',
  max = 255,
  label = 'Character count',
  isValid = false,
}: {
  fieldName: string
  max?: number
  label?: string
  isValid?: boolean
}): string => {
  let errorMessage = ''
  switch (true) {
    case fieldName.length === 0 && !fieldName:
      // so blank fields wouldn't get error styles
      errorMessage = ''
      break
    case fieldName.length > max && max !== 5 && max !== 10:
      // so ZIP code and phone wouldn't get this error
      errorMessage = `${label} must be under ${max} characters`
      break
    case fieldName.length < max && max === 10:
      // show phone specific msg
      errorMessage = `${label} needs to be 10 digits with area code`
      break
    case fieldName.length > max && max === 6:
      // show ZIP specific msg
      errorMessage = `${label} must be 5-digit code, not ZIP + 4, including leading zero`
      break
    // add max so ZIP code and phone wouldn't get delayed msg
    case fieldName.length < 2 && max !== 5 && max !== 10:
      errorMessage = 'Please enter two or more characters'
      break
    case !isValid:
      // any not-valid error msg
      errorMessage = `Please type a valid ${label.toLowerCase()}`
      break
    default:
      errorMessage = `Please re-enter ${label.toLowerCase()}`
      break
  }
  return errorMessage
}
