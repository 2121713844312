import React, { useContext } from 'react'
import { ThemeContext } from 'Contexts'
import './PlaceholderPage.scss'
import { Fa, Header } from '@eltoro-ui/components'

export const PlaceholderPage = () => {
  const { logoLarge } = useContext(ThemeContext)
  return (
    <div className="PlaceholderPage">
      <div className="PlaceholderPage__logo">
        <img src={logoLarge} alt="Eltoro Logo" />
      </div>
      <div className="PlaceholderPage__comingSoon">
        <h1 className="text-tertiary-700 text-3xl font-bold">
          Page Coming Soon
        </h1>
      </div>
    </div>
  )
}
