import { ReactNode, FC } from 'react'
import { MaxHeightContainer } from '../MaxHeightContainer'
import { TextHeader } from '../TextHeader'

type SectionType = {
  className?: string
  title: string
  actions?: ReactNode
  headerType?: 1 | 2 | 3
  children?: ReactNode
} & (
  | { dark?: boolean; noBackground?: never }
  | { dark?: never; noBackground?: boolean }
)

export const Section: FC<SectionType> = ({
  className = '',
  title,
  actions = false,
  headerType = 2,
  dark = false,
  noBackground = false,
  children,
}) => {
  return (
    <section
      className={`Section mt-2 mb-3 ${className} ${title} ${
        !noBackground ? 'bg-tint-gray-100 p-4 rounded-md' : ''
      } ${dark ? 'Section--dark bg-tint-gray-300' : ''} ${
        noBackground ? 'Section--no-background transparent' : ''
      }`}
    >
      <MaxHeightContainer
        header={
          <div className="Section__header flex justify-between items-center">
            <TextHeader type={headerType}>{title}</TextHeader>
            {actions && (
              <div className="Section__actions flex justify-end items-center gap-2 ml-auto">
                {actions}
              </div>
            )}
          </div>
        }
      >
        <div className="Section__body flex flex-col gap-2 h-full">
          {children}
        </div>
      </MaxHeightContainer>
    </section>
  )
}
