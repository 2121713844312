import { useContext, useState } from 'react'
import {
  Form,
  FormItem,
  TextInput,
  Uploader,
  Button,
  Fa,
  showErrorMessage,
  Tabs,
  Text,
  TextHeader,
} from '@eltoro-ui/components'
import { useForm, useValidatedState } from '@eltoro-ui/hooks'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { v4 as uuid } from 'uuid'
import { TextInputWithErrorText, Notification } from 'Components'
import { ThemeContext, useAppContext } from 'Contexts'
import {
  ErrorMessage,
  isValidCharLength,
  styleTailwind,
  validCheck,
} from 'Helpers'
import { CreativesLibraryPage, PreviewFileWithId } from 'Pages'
import { uploadCreative } from 'Requests'
import { NativePreview } from './Components'
import { AdSetDisplay } from '../components'
import './NativeBannerForm.scss'
import classNames from 'classnames'

const NativeBannerLabel = styleTailwind(
  'label',
  'NativeBannerLabel FormItem__label font-semibold flex gap-1 items-center',
)

const Link = styleTailwind(
  'a',
  'NativeBannerLink font-semibold flex items-center leading-none text-base-info',
)

export type DimType = {
  height: number
  width: number
}
type NBprops = {
  handleAddCreative?: (creative: Creativeservicev1Creative) => void
  onUseInNewCampaign?: (creative: Creativeservicev1Creative) => void
  onBackToLibrary?: () => void
}

const NBButton = styleTailwind(
  Button,
  'NativeBanner__button-remove h-auto pl-4 justify-self-end right-0 absolute ml-auto',
)

const UploaderContentInner = styleTailwind(
  'div',
  'UploaderContentInner flex flex-col text-ml text-center w-full',
)

// The 'guts' of the NativeBannerForm. For use in places where the tab structure is not needed.
export const NativeBannerCreate = ({
  handleAddCreative,
  onBackToLibrary,
  onUseInNewCampaign,
}: NBprops) => {
  const [name, setName] = useState<string>('')
  const [icon, setIcon] = useState<PreviewFileWithId>()
  const [logo, setLogo] = useState<PreviewFileWithId>()
  const [iconUploaded, setIconUploaded] = useState(false)
  const [logoUploaded, setLogoUploaded] = useState(false)
  const [logoError, setLogoError] = useState<string>('')
  const [iconError, setIconError] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [clickThru, setClickThru, isValidClickThru] = useValidatedState(
    'https://',
    'url',
  )
  const [sponsor, setSponsor] = useState<string>('')
  const [callToAction, setCallToAction] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  // const { isPolitical } = useCampaignContext()
  // const { pathname } = useLocation()
  // const isInCampaignCreation =
  //   pathname.includes('/campaigns/create') ||
  //   pathname.includes('/campaigns/edit')
  // const [political, setPolitical] = useState<boolean | null>(
  //   isInCampaignCreation ? isPolitical : null,
  // )
  // const [highlightOptions, setHighlightOptions] = useState(false)
  const { currentOrg, creativeServiceApi, tok } = useAppContext()
  const { name: theme } = useContext(ThemeContext)

  const handleNativeCreation = async (
    iconImg?: PreviewFileWithId,
    logoImg?: PreviewFileWithId,
  ) => {
    if (
      !iconImg ||
      !logoImg ||
      !currentOrg ||
      !currentOrg.id ||
      (!validForm && !iconUploaded && !logoUploaded)
    )
      return

    const logoCreative = await uploadCreative(
      logoImg.fileData.name,
      logoImg.fileData,
      tok,
      currentOrg.id,
      (prog) => {
        console.log(logoImg.id, prog)
      },
      'CREATIVE_FILE_TYPE_MEDIA',
      'CREATIVE_FILE_SUB_TYPE_NATIVE_MAIN',
    )
    const iconCreative = await uploadCreative(
      iconImg.fileData.name,
      iconImg.fileData,
      tok,
      currentOrg.id,
      (prog) => {
        console.log(iconImg.id, prog)
      },
      'CREATIVE_FILE_TYPE_MEDIA',
      'CREATIVE_FILE_SUB_TYPE_NATIVE_ICON',
    )

    if (
      !logoCreative.id ||
      !iconCreative.id ||
      !creativeServiceApi ||
      !currentOrg.id
    )
      return

    creativeServiceApi
      .advertisingPlatformServiceCreateCreative(currentOrg.id, {
        files: [logoCreative, iconCreative],
        name,
        type: 'CREATIVE_TYPE_NATIVE_BANNER',
        nativeMetadata: {
          title,
          bodyText: description,
          callToAction,
          sponsoredBy: sponsor,
          alternativeMobileLandingPageUrl: clickThru,
          fallbackUrl: clickThru,
        },
      })
      .then((res) => {
        if (handleAddCreative) handleAddCreative(res)
      })
      .finally(() => {
        setIcon(undefined)
        setLogo(undefined)
        setTitle('')
        setDescription('')
        setClickThru('')
        setSponsor('')
        setCallToAction('')
        setSuccess(true)
      })
      .catch(() => {
        showErrorMessage(
          'Problem uploading',
          `The banner, ${name}, can't be added into the Creative Library`,
        )
      })
  }

  const getHeightAndWidthFromDataUrl = (url: string) =>
    new Promise<DimType>((resolve) => {
      const img = new Image()
      img.src = url
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        })
      }
    })
  const { isValidForm, touched } = useForm({
    name,
    title,
    description,
    clickThru,
    callToAction,
    sponsor,
  })
  const required = ['name', 'title', 'description', 'sponsor', 'clickThru']
  const { validForm, missingFields } = isValidForm([
    ...required,
    isValidCharLength(name, 255),
    isValidCharLength(title, 60),
    isValidCharLength(description, 200),
    isValidCharLength(sponsor, 25),
    isValidCharLength(clickThru, 1000, 12),
    isValidCharLength(callToAction, 50),
    logoUploaded,
    iconUploaded,
  ])

  const NativeBannerSuccess = () => {
    return (
      <div className="NativeBannerCreate__success-msg flex h-full items-center justify-center leading-snug">
        <Notification header="Success!" layOut="row" icon iconType="browser">
          Your Native banner
          {name ? (
            <>
              , <strong>{name}</strong>,&nbsp;
            </>
          ) : (
            ' '
          )}
          has been created.
          <div className="SuccessButtonWrap flex justify-end gap-8 py-6">
            {onBackToLibrary && (
              <Button
                size="l"
                type="submit"
                onClick={() => {
                  setSuccess(false)
                  setName('')
                  onBackToLibrary()
                }}
              >
                Back to library
              </Button>
            )}

            <Button
              size="l"
              onClick={() => {
                setSuccess(false)
                setName('')
              }}
            >
              Create another
            </Button>

            {/* {onUseInNewCampaign && (
              <Button
                size="l"
                kind="primary"
                onClick={() => {
                  if (onUseInNewCampaign && uploadedCreative)
                    onUseInNewCampaign(uploadedCreative)
                }}
              >
                Use in new campaign
              </Button>
            )} */}
          </div>
        </Notification>
      </div>
    )
  }

  return (
    <>
      <div className="NativeBannerCreate laptopsmall:flex-col-reverse -mt-2 flex gap-12 pb-8">
        <div className="NativeBannerForm__form">
          <div className="NativeBannerForm__header">
            <TextHeader
              type={3}
              className="text-tint-gray-600 flex min-w-full text-2xl leading-none"
            >
              Create your Native banner ad below
            </TextHeader>
            <p className="NativeBannerForm__header-text min-w-full pt-2 font-normal leading-normal">
              Native ads have been said to be the future of digital advertising
              as they are not as intrusive or distracting as other forms of
              digital ads, but rather seem native to the page the ad is
              displayed on. Native ads are a great compliment to traditional
              banner ads!
              <span className="Links flex min-w-full gap-3 pt-3 pb-5">
                <Link
                  href="https://eltoro.zendesk.com/hc/en-us/articles/4770083270683-Native-Ads"
                  className="NativeAdsLink__zendesk RightVerticalPipe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Fa icon="circle-info" size={1} />
                  <span>Read more about Native banner ads</span>
                </Link>
                <Link
                  href="https://eltoro.com/how-to-use-native-ads/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  El Toro's post - Blend in and Stand Out with Native Ads
                </Link>
                <Text className="RequiredLabel text-m ml-auto flex items-center gap-1 pb-2 font-normal leading-none">
                  <span className="text-danger mt-1">*</span>Required
                </Text>
              </span>
            </p>
          </div>

          <div
            className={classNames(
              'bg-danger-600 text-l rounded-m mb-2 flex items-center justify-center gap-2 p-4 text-white',
              { 'bg-danger-300': theme === 'darkBlue' || theme === 'dark' },
            )}
          >
            We currently do not support this media type for political
            advertising.
          </div>

          <Form
            className="NativeBannerForm__form border-t-thin border-tint-gray-200 laptopsmall:flex-col mt-1 flex basis-2/3 gap-8 gap-10 pb-2 pt-8"
            required={required}
            valid={validForm && iconUploaded && logoUploaded}
            touched={touched}
            missingFields={missingFields}
          >
            <div className="NativeBannerForm__form-fields flex flex-col">
              <FormItem
                wrapperClassname="NativeBanner__form--creative-name flex flex-col"
                label={<NativeBannerLabel>Creative name</NativeBannerLabel>}
                htmlFor="name"
                required
                errorMessage={ErrorMessage({
                  fieldName: name,
                  max: 255,
                  label: 'Creative name',
                })}
                valid={validCheck(name) && isValidCharLength(name, 255)}
                counter={name.length > 0}
              >
                <TextInput
                  value={name}
                  placeholder="Displayed in the Creative Library's name column"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value)
                  }}
                  maxLength={256}
                />
              </FormItem>

              <FormItem
                wrapperClassname="NativeBanner__form--ad-title flex flex-col"
                htmlFor="title"
                label={<NativeBannerLabel>Title</NativeBannerLabel>}
                required
                errorMessage={ErrorMessage({
                  fieldName: title,
                  max: 25,
                  label: 'Title',
                })}
                valid={validCheck(title) && isValidCharLength(title, 25)}
                counter={title.length > 0}
              >
                <TextInput
                  value={title}
                  placeholder="Headline of Native ad"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(e.target.value)
                  }}
                  maxLength={26}
                />
              </FormItem>
              <FormItem
                wrapperClassname="NativeBanner__form--ad-copy flex flex-col"
                htmlFor="description"
                label={<NativeBannerLabel>Description</NativeBannerLabel>}
                errorMessage={ErrorMessage({
                  fieldName: description,
                  max: 90,
                  label: 'Description',
                  isValid:
                    validCheck(description) &&
                    isValidCharLength(description, 90),
                })}
                valid={
                  validCheck(description) && isValidCharLength(description, 90)
                }
                counter={description.length > 0}
                required
              >
                <TextInput
                  placeholder="Short ad copy that engage, persuade and convert audiences"
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={91}
                  value={description}
                />
              </FormItem>
              <FormItem
                wrapperClassname="NativeBanner__form--ad-sponsor flex flex-col"
                htmlFor="sponsor"
                label={<NativeBannerLabel>Sponsor name</NativeBannerLabel>}
                errorMessage={ErrorMessage({
                  fieldName: sponsor,
                  max: 25,
                  label: 'Sponsor',
                })}
                valid={validCheck(sponsor) && isValidCharLength(sponsor, 25)}
                required
                counter={sponsor.length > 0}
              >
                <TextInput
                  placeholder="Name of sponsor or advertiser"
                  value={sponsor}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSponsor(e.target.value)
                  }}
                  maxLength={26}
                />
              </FormItem>
              {/* (It does have the associated control inside TextInputWithErrorText) */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <FormItem
                htmlFor="clickthru"
                className="NativeBanner__form--clickthru"
                wrapperClassname="flex flex-col"
                label={
                  <NativeBannerLabel>
                    Click-through URL for your creative
                  </NativeBannerLabel>
                }
                errorMessage={ErrorMessage({
                  fieldName: clickThru,
                  max: 1000,
                  label: 'Click-through URL',
                })}
                counter={clickThru.length > 8}
              >
                <TextInputWithErrorText
                  id="clickthru"
                  value={clickThru}
                  onChange={(e) => setClickThru(e.target.value)}
                  isValid={isValidClickThru}
                  baseValues={['http://', 'https://']}
                  maxLength={1001}
                  minLength={8}
                  popoverContent={(() => {
                    if (!clickThru.includes('http'))
                      return (
                        <div className="PopoverMessage__required">
                          URL requires &quot;https://&quot;
                        </div>
                      )
                    return (
                      <div className="PopoverMessage__message">
                        Please enter a valid url in the format:
                        &quot;https://www.websitename.com&quot;
                      </div>
                    )
                  })()}
                  // errorMsg={false}
                />
              </FormItem>
              <FormItem
                wrapperClassname="NativeBanner__form--cta flex flex-col"
                htmlFor="callToAction"
                label={<NativeBannerLabel>Call to action</NativeBannerLabel>}
                errorMessage={ErrorMessage({
                  fieldName: callToAction,
                  max: 50,
                  label: 'Call to action',
                })}
                valid={
                  validCheck(callToAction) &&
                  isValidCharLength(callToAction, 50)
                }
                counter={callToAction.length > 0}
              >
                <TextInput
                  placeholder="Button/text field that spurs user to action (e.g., Get 10% off today)"
                  value={callToAction}
                  onChange={(e) => {
                    setCallToAction(e.target.value)
                  }}
                  maxLength={51}
                />
              </FormItem>
            </div>
            <div className="NativeBanner__form--image-icon flex flex-col items-stretch gap-8">
              {/* <FormItem
                wrapperClassname={classNames(
                  'NativeBanner__form--political flex flex-col p-2',
                  {
                    'animate-shakex border border-warning rounded bg-warning-50':
                      political === null && highlightOptions,
                  },
                )}
                label={
                  <>
                    <NativeBannerLabel>Type of advertising</NativeBannerLabel>
                    <span className="text-danger mt-1 pl-1">*</span>
                  </>
                }
                htmlFor="political"
              >
                <RadioButtonGroup
                  valueSelected={
                    political !== null ? political.toString() : null
                  }
                  name="Is used for political order lines"
                  onChange={(politicalValue) => {
                    setHighlightOptions(false)
                    setPolitical(politicalValue === 'true')
                  }}
                  layout="vertical"
                >
                  <RadioButton value="true" label="Political" />
                  <RadioButton value="false" label="Non-political" />
                </RadioButtonGroup>
              </FormItem> */}
              <div
                className="NativeBannerForm__uploader-wrap-click-catch"
                // onClick={() => {
                //   if (political === null) setHighlightOptions(true)
                // }}
              >
                <div
                  className={classNames('NativeBannerForm__uploader-wrap', {
                    // 'pointer-events-none opacity-30': political === null,
                    // 'animate-fadein opacity-0': political !== null,
                  })}
                >
                  <FormItem
                    wrapperClassname="NativeBanner__form--ad-image flex flex-col"
                    label={
                      <div className="NativeBanner__uploader relative flex min-w-full items-center">
                        <NativeBannerLabel>Image</NativeBannerLabel>
                        <span className="text-danger mt-1 pl-1">*</span>
                        {logo && (
                          <NBButton
                            onClick={() => setLogo(undefined)}
                            iconOnly={
                              <span
                                aria-label="Click to delete image"
                                data-tooltip="left"
                              >
                                <Fa
                                  className="NativeBannerImage--button-remove"
                                  icon="trash-can"
                                  size={1}
                                />
                              </span>
                            }
                            type="button"
                          />
                        )}
                      </div>
                    }
                    htmlFor="logo"
                    required
                  >
                    {!logo && (
                      <div className="CreativeUploader__wrap">
                        <Uploader
                          className={`UploaderLogo ${
                            logoError
                              ? 'border-warning animate-glowwarning'
                              : ''
                          }`}
                          maxFiles={1}
                          onDrop={async (file) => {
                            setLogoError('')
                            const dims = await getHeightAndWidthFromDataUrl(
                              file[0].preview,
                            )
                            const fileId: PreviewFileWithId = {
                              fileData: file[0],
                              id: uuid(),
                            }
                            // check for political requirements
                            // if (political) {
                            //   if (dims.width === 1200 && dims.height === 627) {
                            //     setLogo(fileId)
                            //     setLogoError('')
                            //     setLogoUploaded(true)
                            //   } else {
                            //     setLogoError('1200x627 dimensions required')
                            //   }
                            // } else {
                            // check for non-political requirements
                            if (
                              Number((dims.height / dims.width).toFixed(2)) ===
                                1.91 ||
                              Number((dims.width / dims.height).toFixed(2)) ===
                                1.91
                            ) {
                              setLogo(fileId)
                              setLogoError('')
                              setLogoUploaded(true)
                            } else {
                              setLogoError('Aspect ratio of 1.9:1 required')
                            }
                            // }
                          }}
                          // handleDropDeps={[political]} // triggers uploader re-render when political changes
                        >
                          <UploaderContentInner>
                            <Fa
                              className="mt-auto pb-1"
                              icon="cloud-arrow-up"
                              type="duotone"
                              size={5}
                            />
                            <TextHeader
                              type={5}
                              className="pb-1 font-semibold normal-case"
                            >
                              Click to upload image
                            </TextHeader>
                            <Text className="LogoInstructions text-m">
                              {/* {political
                                ? 'Image dimensions of 1200x627 required.'
                                : 'Aspect ratio of 1.9:1. (1200x627 recommended, 620px min. width, 375px min. height)'} */}
                              Aspect ratio of 1.9:1. (1200x627 recommended,
                              620px min. width, 375px min. height)
                            </Text>
                          </UploaderContentInner>
                        </Uploader>
                      </div>
                    )}
                    {logoError && (
                      <Text className="animate-slidedown text-m text-warning py-2 font-bold">
                        {logoError}
                      </Text>
                    )}
                    {logo && (
                      <figure className="NativeBannerForm__logo animate-slidedown flex flex-col gap-4">
                        <img
                          className="ml-auto h-auto w-full"
                          src={logo && logo.fileData.preview}
                          alt="NativeBanner logo"
                          height={100}
                          width={100}
                        />
                      </figure>
                    )}
                  </FormItem>
                  <FormItem
                    wrapperClassname="NativeBanner__form--ad-icon-logo  flex flex-col"
                    label={
                      <div className="NativeBannerIcon__uploader relative flex min-w-full items-center">
                        <NativeBannerLabel>Icon</NativeBannerLabel>
                        <span className="text-danger mt-1 pl-1">*</span>
                        {icon && (
                          <NBButton
                            onClick={() => {
                              setIconError('')
                              setIcon(undefined)
                            }}
                            iconOnly={
                              <span
                                aria-label="Click to delete icon"
                                data-tooltip="left"
                              >
                                <Fa
                                  className="NativeBannerIcon--button-remove"
                                  icon="trash-can"
                                  size={1}
                                />
                              </span>
                            }
                            type="button"
                          />
                        )}
                      </div>
                    }
                    htmlFor="icon"
                    required
                  >
                    {!icon && (
                      <div className="CreativeUploader__wrap">
                        <Uploader
                          className={`UploaderIcon ${
                            iconError
                              ? 'border-warning animate-glowwarning'
                              : ''
                          }`}
                          isStyled
                          maxFiles={1}
                          onDrop={async (file) => {
                            setIconError('')
                            const dims = await getHeightAndWidthFromDataUrl(
                              file[0].preview,
                            )
                            const fileId: PreviewFileWithId = {
                              fileData: file[0],
                              id: uuid(),
                            }
                            // if (political) {
                            //   if (dims.height === 300 && dims.width === 300) {
                            //     setIcon(fileId)
                            //     setIconError('')
                            //     setIconUploaded(true)
                            //   } else {
                            //     setIconError('300x300 dimensions required')
                            //   }
                            // } else {
                            if (
                              Number((dims.height / dims.width).toFixed(2)) ===
                              1
                            ) {
                              setIcon(fileId)
                              setIconError('')
                              setIconUploaded(true)
                            } else {
                              setIcon(fileId)
                              setIconError('Aspect ratio of 1:1 recommended')
                            }
                            // }
                          }}
                          // handleDropDeps={[political]} // triggers uploader re-render when political changes
                        >
                          <UploaderContentInner>
                            <Fa
                              className="mt-auto pb-1"
                              icon="cloud-arrow-up"
                              type="duotone"
                              size={5}
                            />
                            <TextHeader
                              type={5}
                              className="pb-1 font-semibold normal-case"
                            >
                              Click to upload icon
                            </TextHeader>
                            <Text className="IconInstructions text-m">
                              {/* {political
                                ? 'Image dimensions of 300x300 required.'
                                : '300 pixels recommended, with a 1:1 aspect ratio (80x80 min.)'} */}
                              300 pixels recommended, with a 1:1 aspect ratio
                              (80x80 min.)
                            </Text>
                          </UploaderContentInner>
                        </Uploader>
                      </div>
                    )}

                    {icon && (
                      <figure className="NativeBannerForm__icon animate-slidedown flex flex-col items-center gap-2">
                        <img
                          src={icon && icon.fileData.preview}
                          alt="test"
                          height={50}
                          width={50}
                        />
                      </figure>
                    )}
                    {iconError && (
                      <Text className="animate-slidedown text-m text-warning py-2 font-bold">
                        {iconError}
                      </Text>
                    )}
                  </FormItem>
                </div>
              </div>

              <div className="NativeBannerForm_submit_button animate-slidedown mt-auto flex justify-center gap-4 py-1">
                <Button
                  className={`NativeBannerForm__button px-8 transition-all ${
                    validForm && iconUploaded && logoUploaded
                      ? 'Button--primary ripples'
                      : ''
                  }`}
                  size="l"
                  type="submit"
                  disabled={!validForm}
                  onClick={() => handleNativeCreation(icon, logo)}
                >
                  {validForm && iconUploaded && logoUploaded
                    ? 'Create Native banner'
                    : 'Fill in details to create'}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <NativePreview
          title={title}
          description={description}
          logo={logo}
          icon={icon}
          sponsor={sponsor}
          callToAction={callToAction}
        />
      </div>
      {/* When form rendered conditionally, causes the error of early return; success component failed to load */}
      {success ? <NativeBannerSuccess /> : null}
    </>
  )
}

export const NativeBannerForm = ({
  refreshLibrary,
}: {
  refreshLibrary?: () => void
}) => {
  const [selectedTab, setSelectedTab] = useState('NativeBanner')
  const [selectedNativeCreatives, setSelectedNativeCreatives] = useState<
    Creativeservicev1Creative[]
  >([])

  const handleSelect = (creative: Creativeservicev1Creative) => {
    setSelectedNativeCreatives((prev) => [...prev, creative])
    setSelectedTab('GetFromLibrary')
  }
  const handleRemove = (creativeId: string) => {
    setSelectedNativeCreatives((prev) =>
      prev.filter((f) => f.id !== creativeId),
    )
  }

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => {
    setSelectedTab(buttonId)
  }

  const shouldOpenAdDisplay = !!selectedNativeCreatives.length

  return (
    <div className="laptopsmall:overflow-x-auto NativeBannerForm overflow-y-hidden">
      <div className="NativeBannerForm__header flex items-center gap-3">
        <Tabs
          classNamePanel="flex-1 w-full"
          on="grey-100"
          tabs={[
            {
              id: 'NativeBanner',
              label: (
                <div className="NativeBannerAdForm__header-text-icon flex w-auto items-center justify-center gap-2">
                  <Fa icon="browser" size={4} type="duotone" />
                  <TextHeader
                    className="NativeBannerAdForm__header-text font-normal normal-case"
                    type={5}
                  >
                    Create a Native ad
                  </TextHeader>
                </div>
              ),
              onClick: () => handleButtonClick,
              defaultTab: selectedTab === 'NativeBanner',
              component: (
                <NativeBannerCreate
                  onBackToLibrary={() => {
                    if (refreshLibrary) refreshLibrary()
                    setSelectedTab('GetFromLibrary')
                  }}
                  handleAddCreative={(creative) =>
                    setSelectedNativeCreatives((prev) => [...prev, creative])
                  }
                />
              ),
            },
            {
              id: 'GetFromLibrary',
              label: (
                <>
                  <span
                    aria-label="Select from Creative Library"
                    data-tooltip="left"
                  >
                    <Fa
                      className="text-primary"
                      icon="folders"
                      size={3}
                      type="duotone"
                    />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text">
                    Select from Creative Library
                  </span>
                </>
              ),
              onClick: () => handleButtonClick,
              defaultTab: selectedTab === 'GetFromLibrary',
              component: (
                <CreativesLibraryPage
                  selectedCreatives={selectedNativeCreatives}
                  addToCreativeSet={handleSelect}
                  removeFromCreativeSet={handleRemove}
                  modalPreview
                  windowed
                  filterByType={['CREATIVE_TYPE_NATIVE_BANNER']}
                  emptyState={
                    <div className="NativeBannerForm__library-empty-state text-grey-700 flex flex-col items-center">
                      Oops! There are no Native banner creatives in your
                      library.
                    </div>
                  }
                />
              ),
            },
          ]}
        />
      </div>
      <AdSetDisplay
        shouldOpen={shouldOpenAdDisplay}
        selectedCreatives={selectedNativeCreatives}
        setSelectedCreatives={setSelectedNativeCreatives}
        type="native"
        showBannerChecklist={false}
      />
    </div>
  )
}
