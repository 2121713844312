/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Conditions](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html#SplitsService-SegmentGroup) in Xandr's documentation.
*/
export class Deployservicev3Value {
    /**
    * The id of the segment that was created in xander
    */
    'segmentId'?: number;
    /**
    * This can be include or exclude - whether to target this segment or not.
    */
    'action'?: string;
    /**
    * The lower bound of minutes since the user has been added to this segment, inclusive. 'start_minutes': 5 includes users who have been added to the segment from the five-minute mark, including 5:00.
    */
    'startMinutes'?: number;
    /**
    * The upper bound of minutes since the user has been added to this segment, inclusive. 'expire_minutes': 10 includes users who have been added to the segment until the ten-minute mark, including 10:00.
    */
    'expireMinutes'?: number;
    /**
    * The low end of the range of permitted values, exclusive. 'value_lesser' : 5 includes only values greater than, but not including, 5. TODO: SHOULD THIS BE value_lesser?
    */
    'valueLess'?: number;
    /**
    * The high end of the range of permitted values, exclusive. 'value_greater' : 10 includes only values up to, but not including, 10.
    */
    'valueGreater'?: number;
    /**
    * The exact match to the permitted value.
    */
    'valueEquals'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "segmentId",
            "baseName": "segment_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "action",
            "baseName": "action",
            "type": "string",
            "format": ""
        },
        {
            "name": "startMinutes",
            "baseName": "start_minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "expireMinutes",
            "baseName": "expire_minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "valueLess",
            "baseName": "value_less",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "valueGreater",
            "baseName": "value_greater",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "valueEquals",
            "baseName": "value_equals",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Deployservicev3Value.attributeTypeMap;
    }

    public constructor() {
    }
}

