import { useState } from 'react'
import { Button, Fa } from '@eltoro-ui/components'

interface IUTM extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string
  onClick: () => void
  text?: string
}

export const UTMButton = ({ className = '', onClick, text = '' }: IUTM) => {
  const [isClicked, setIsClicked] = useState(false)
  const handleClick = () => {
    setIsClicked((click) => !click)
    onClick()
  }
  return (
    <Button
      className={`UTMButton Button flex items-center justify-end ${className} ${
        isClicked ? 'is-open' : ''
      }`}
      type="button"
      iconRight={<Fa className="scale-95" icon="chevron-down" size={1} />}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}
