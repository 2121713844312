import { useRef, useState } from 'react'
import day from 'dayjs'
import { Notificationservicev1Type, V1Notification } from 'next-gen-sdk'
import { Button, Fa, Text } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { simpleNotifyLabel, styleTailwind } from 'Helpers'
import { AlertDetail } from './AlertDetail'

type Props = {
  list: V1Notification[]
  onDelete: (data: V1Notification) => void
  onEdit: (data: V1Notification) => void
  isAdmin: boolean
}

const DropdownLink = styleTailwind(
  'li',
  'Dropdown__li w-full whitespace-nowrap text-l text-left transition-all',
)

export const AlertsList = ({
  list,
  onDelete,
  onEdit,
  isAdmin = false,
}: Props) => {
  const [showData, setShowData] = useState(false)
  const [dataToShow, setDataToShow] = useState(null as V1Notification | null)

  const viewAlert = (data: V1Notification) => {
    setDataToShow(data)
    setShowData(true)
  }

  const onClose = () => setShowData(false)

  const AlertDropDown = ({ alert }: { alert: V1Notification }) => {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside([dropdownRef], () => setOpen(false))
    return (
      <div className="AlertsList__action-dropdown relative z-10 flex">
        {open && (
          <div
            className="AlertDropDownMenu animate-slideright z-20 flex"
            ref={dropdownRef}
          >
            <ul
              className="AlertDropDown__list shadow-4xl bg-light flex list-none flex-col border-none"
              aria-labelledby="dropdown"
            >
              <DropdownLink>
                <Button
                  type="button"
                  kind="text"
                  className="Dropdown_link"
                  onClick={() => viewAlert(alert)}
                >
                  View detail
                </Button>
              </DropdownLink>
              {isAdmin && (
                <>
                  <DropdownLink>
                    <Button
                      type="button"
                      kind="text"
                      className="Dropdown_link"
                      onClick={() => onEdit(alert)}
                    >
                      Edit alert
                    </Button>
                  </DropdownLink>
                  <DropdownLink>
                    <Button
                      type="button"
                      className="Dropdown_link"
                      kind="text"
                      onClick={() => onDelete(alert)}
                    >
                      Delete alert
                    </Button>
                  </DropdownLink>
                </>
              )}
            </ul>
          </div>
        )}
        <Button
          type="button"
          className="AlertsList-action-button w-7 border-none transition-all"
          iconOnly={
            <Fa className="AlertEdit__icon" icon="ellipsis-v" size={4} />
          }
          onClick={() => {
            setOpen(!open)
          }}
        />
      </div>
    )
  }

  return (
    <div className="AlertsPage__list animate-slide-in-up">
      <Text className="flex items-center gap-1 pt-2 pb-4 text-xl" tag="h3">
        <span>
          {list.length < 1 ? 'No alert banner found' : 'Active alerts'}
        </span>
        <span className="font-semibold">
          {list.length > 1 ? `(${list.length})` : null}
        </span>
      </Text>
      {showData && dataToShow !== null && (
        <AlertDetail onClose={onClose} data={dataToShow} />
      )}
      {list.length >= 1 ? (
        <>
          <table className={`Alerts__table`}>
            <thead className="TableHeader">
              <tr className="TableHeader__row border-b-thin">
                <th className="TableHeader__cell py-1">Title</th>
                <th className="TableHeader__cell py-1">Type</th>
                <th className="TableHeader__cell w-28 py-1 text-right">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="Table__body text-tint-gray-900">
              {list.map((alert) => {
                return (
                  <tr key={alert.id} className="TableRow text-tint-gray-800">
                    <td className="TableCell">
                      <span className="Alert__title !text-l">
                        {alert.title || 'Alert Title'}
                      </span>
                      <span className="Alert__date-time flex items-center">
                        <Fa
                          className="text-grey-300 -ml-1 scale-75"
                          icon="clock"
                          size={1}
                        />
                        <span>
                          {day(alert.createTime).format(
                            'MMM DD, YYYY [at] h:mm a, [ET]',
                          )}
                        </span>
                      </span>
                    </td>
                    <td className="TableCell">
                      <span className="Alert__title !text-l">
                        {simpleNotifyLabel(
                          alert.type as Notificationservicev1Type,
                        )}
                      </span>
                    </td>
                    <td className="TableCell">
                      <AlertDropDown alert={alert} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      ) : null}
    </div>
  )
}
