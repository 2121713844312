import dayjs from 'dayjs'

export const getDaysBetween = (date1: Date, date2: Date) => {
  const utc1 = dayjs(date1).startOf('day')
  const utc2 = dayjs(date2).endOf('day')
  return Math.ceil(utc2.diff(utc1, 'days', true))
}

export const formatDate = (date: Date) =>
  `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date)
    .getFullYear()
    .toString()
    .slice(2, 4)}`

// Checks a credit card expiration date (format: MM/YY)
export const checkExp = (exp: string) => {
  const d = new Date()
  const year = Number(exp.split('/')[1])
  const month = Number(exp.split('/')[0])
  const yearNow = Number(String(d.getFullYear()).slice(-2))
  const monthNow = d.getMonth() + 1
  if (year > yearNow) {
    return true
  }
  if (year === yearNow) {
    return month >= monthNow
  }
  return false
}
