/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1Column } from '../models/Targetjobservicev1Column';
import { HttpFile } from '../http/http';

/**
* Object that contains info the results of a component of a job. Admin Only
*/
export class V1ComponentResult {
    /**
    * The number of rows in the initial uploaded target.
    */
    'recordsSubmitted'?: number;
    /**
    * The number of rows that got results.
    */
    'recordsMatched'?: number;
    /**
    * The number of rows generated off initial input.
    */
    'recordsGenerated'?: number;
    'fileLocation'?: string;
    /**
    * The number of records in the file if there was one.
    */
    'fileLength'?: number;
    'columns'?: Array<Targetjobservicev1Column>;
    'hasHeader'?: boolean;
    'duplicatesRemoved'?: number;
    'recordsFilteredOut'?: number;
    'ipsFilteredOut'?: number;
    'addressesFilteredOut'?: number;
    'devicesFilteredOut'?: number;
    'name'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "recordsSubmitted",
            "baseName": "records_submitted",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsMatched",
            "baseName": "records_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsGenerated",
            "baseName": "records_generated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "fileLocation",
            "baseName": "file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "fileLength",
            "baseName": "file_length",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "columns",
            "baseName": "columns",
            "type": "Array<Targetjobservicev1Column>",
            "format": ""
        },
        {
            "name": "hasHeader",
            "baseName": "has_header",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "duplicatesRemoved",
            "baseName": "duplicates_removed",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsFilteredOut",
            "baseName": "records_filtered_out",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "ipsFilteredOut",
            "baseName": "ips_filtered_out",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "addressesFilteredOut",
            "baseName": "addresses_filtered_out",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "devicesFilteredOut",
            "baseName": "devices_filtered_out",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ComponentResult.attributeTypeMap;
    }

    public constructor() {
    }
}

