/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1AudienceType } from '../models/V1AudienceType';
import { V1DataSource } from '../models/V1DataSource';
import { V1ProductType } from '../models/V1ProductType';
import { HttpFile } from '../http/http';

/**
* Audience info associated with a OrderLine
*/
export class Campaignservicev1Audience {
    /**
    * The ID of the job associated with this order line.
    */
    'id'?: string;
    /**
    * The name field should contain the relative resource name.
    */
    'name'?: string;
    /**
    * The ID of the Target that this Job was associated with.
    */
    'targetId'?: string;
    /**
    * This is the ID of the parent pipeline job. It only exists if the job is part of a pipeline or VR job.
    */
    'audienceId'?: string;
    'type'?: V1AudienceType;
    'productType'?: V1ProductType;
    /**
    * The location in s3 that hold the selected file.
    */
    'selectedFileLocation'?: string;
    /**
    * The code associated with the segment that was created from this Target Job.
    */
    'code'?: number;
    /**
    * Whether this Target Job's segment is to be excluded or not.
    */
    'exclude'?: boolean;
    'targetDataSource'?: V1DataSource;
    'hidden'?: boolean;
    /**
    * The number of matches.
    */
    'matched'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1AudienceType",
            "format": ""
        },
        {
            "name": "productType",
            "baseName": "product_type",
            "type": "V1ProductType",
            "format": ""
        },
        {
            "name": "selectedFileLocation",
            "baseName": "selected_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "exclude",
            "baseName": "exclude",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "targetDataSource",
            "baseName": "target_data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "matched",
            "baseName": "matched",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1Audience.attributeTypeMap;
    }

    public constructor() {
    }
}

