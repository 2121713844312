import { useState, ReactNode } from 'react'
import {
  Modal,
  PreviewType,
  Text,
  TextHeader,
  Uploader,
} from '@eltoro-ui/components'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import {
  AddressListExample,
  IPListExample,
  MaidExample,
  ZIPListExample,
} from 'Components/AudienceUploadContainer/components/AudienceUploadStepper/formatExamples'
import { formatByteString } from '@eltoro-ui/helpers'
import { FileRejection } from 'react-dropzone'
import {
  AudienceColumnSelector,
  getColumnSelectorTypes,
} from 'Components/AudienceColumnSelector'
import './ReplaceFileModal.scss'
import { useLibraryContext } from 'Contexts'

export const ReplaceFileModal = ({
  onClose,
  target,
  audience,
}: {
  onClose: () => void
  target: V2Target
  audience: Targetjobservicev1Audience
}) => {
  const [droppedFile, setDroppedFile] = useState<PreviewType>()
  const [uploadError, setUploadError] = useState<string>()
  const {
    pagination: { updateRow, findCachedRow },
    handleSetSelectedItem,
  } = useLibraryContext()

  const handleDrop = (
    dropped: PreviewType[],
    rejectedFiles?: FileRejection[],
  ) => {
    setUploadError(undefined)
    if (rejectedFiles?.length) {
      setUploadError('File is not a valid csv')
      return
    }
    if (dropped.length > 1) {
      setUploadError('Please only upload one file at a time')
      return
    }
    const [file] = dropped
    if (file) setDroppedFile(file)
  }
  const maxGB = 1024 * 1024 * 1024
  const MAX_FILE_SIZE = maxGB // maybe put this in some shared place

  return (
    <Modal
      offClick={() => onClose()}
      header={<TextHeader className="py-1">Replace File</TextHeader>}
      className="ReplaceFileModal min-w-[calc(100%_-_2rem)]"
      classNameContent="flex flex-col gap-4 pt-1"
    >
      <div className="ReplaceFileModal__type-selection flex flex-wrap gap-4">
        <div className="ReplaceFileModal__upload-container flex w-full justify-center">
          {!droppedFile ? (
            <Uploader accepts="file-upload" onDrop={handleDrop} isStyled>
              <div className="AudienceUploadStepper__uploader-text flex flex-col p-4">
                <div className="AudienceUploadStepper__uploader-header animate-slidedown flex flex-col py-1 text-center font-medium">
                  <span className="desktop:text-3xl block">
                    Drag and drop files here
                  </span>
                  <span className="desktop:text-2xl font-light">
                    or click to select a file
                  </span>
                  <Text className="py-2 text-lg font-light uppercase" tag="p">
                    Max file size: {formatByteString(MAX_FILE_SIZE)}
                  </Text>
                </div>

                {uploadError && (
                  <Text className="animate-slidedown text-m text-warning mt-2 font-bold">
                    {uploadError}
                  </Text>
                )}
                <div className="AudienceUploadStepper__format-example">
                  <Text className="pb-2 normal-case leading-none" tag="h6">
                    Formatting example:
                  </Text>
                  <pre>
                    {((): ReactNode => {
                      if (target.fileType === 'FILE_TYPE_ADDRESS')
                        return <AddressListExample />
                      if (target.fileType === 'FILE_TYPE_IP')
                        return <IPListExample />
                      if (target.fileType === 'FILE_TYPE_DEVICE')
                        return <MaidExample />
                      if (target.fileType === 'FILE_TYPE_ZIP')
                        return <ZIPListExample />
                      return null
                    })()}
                  </pre>
                </div>
              </div>
            </Uploader>
          ) : (
            <AudienceColumnSelector
              droppedFile={droppedFile}
              onBack={() => setDroppedFile(undefined)}
              onUploadComplete={() => {
                const existingAudience = findCachedRow(
                  (aud) => aud?.id === audience.id,
                )?.existingItem
                if (existingAudience) {
                  const updatedAudience = {
                    ...existingAudience,
                    status: 'AUDIENCE_STATUS_RUNNING',
                  } as Targetjobservicev1Audience

                  updateRow((aud) => aud?.id === audience.id, updatedAudience)

                  handleSetSelectedItem(updatedAudience)
                }
                onClose()
              }}
              replaceFileOptions={
                target.id && audience.id
                  ? {
                      targetId: target.id,
                      audienceIds: [audience.id],
                      audienceName: audience.name,
                    }
                  : undefined
              }
              {...getColumnSelectorTypes(audience.type)}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
