/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Creativeservicev1NativeMetadata {
    /**
    * Used for NATIVE creative fields. The title of the creative
    */
    'title'?: string;
    /**
    * Used for NATIVE creative fields. The text associated with the creative
    */
    'bodyText'?: string;
    /**
    * Suggested action for next step
    */
    'callToAction'?: string;
    /**
    * Used for NATIVE creative fields. Brand name of the sponsor
    */
    'sponsoredBy'?: string;
    /**
    * In order for a mobile creative with a deep link or mobile-specific landing page (opening an app or click-to-call) to pass our audit, you must provide an alternative landing page URL in the Brand URL field in the Creative Quality pane.
    */
    'alternativeMobileLandingPageUrl'?: string;
    /**
    * A backup url if the main deeplink url is not supported.
    */
    'fallbackUrl'?: string;
    /**
    * A secure third-party tracking URL
    */
    'urlSecure'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "bodyText",
            "baseName": "body_text",
            "type": "string",
            "format": ""
        },
        {
            "name": "callToAction",
            "baseName": "call_to_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "sponsoredBy",
            "baseName": "sponsored_by",
            "type": "string",
            "format": ""
        },
        {
            "name": "alternativeMobileLandingPageUrl",
            "baseName": "alternative_mobile_landing_page_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "fallbackUrl",
            "baseName": "fallback_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "urlSecure",
            "baseName": "url_secure",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Creativeservicev1NativeMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

