/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3DeploymentDestinationConfigurations } from '../models/V3DeploymentDestinationConfigurations';
import { HttpFile } from '../http/http';

/**
* Event emitted when the DeploymentDestinationConfiguration is set on an order line
*/
export class V1OrderLineDeploymentDestinationConfigurationSet {
    'orderLineId'?: string;
    'deploymentDestinationConfiguration'?: V3DeploymentDestinationConfigurations;
    'userId'?: string;
    'orgId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "deploymentDestinationConfiguration",
            "baseName": "deployment_destination_configuration",
            "type": "V3DeploymentDestinationConfigurations",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineDeploymentDestinationConfigurationSet.attributeTypeMap;
    }

    public constructor() {
    }
}

