export const orgCpms = [
  {
    id: '1',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 25,
    name: 'default',
    orgId: '1234',
  },
  {
    id: '2',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 35,
    name: 'mover',
    orgId: '1234',
  },
  {
    id: '3',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 55,
    name: 'retargeting',
    orgId: '1234',
  },
  {
    id: '4',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 25,
    name: 'captive',
    orgId: '1234',
  },
  {
    id: '5',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 25,
    name: 'segmentshare',
    orgId: '1234',
  },
  {
    id: '6',
    created: new Date(),
    updated: new Date(),
    type: 'banner',
    price: 15,
    name: 'venueReplay',
    orgId: '1234',
  },
  {
    id: '7',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 25,
    name: 'default',
    orgId: '1234',
  },
  {
    id: '8',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 35,
    name: 'mover',
    orgId: '1234',
  },
  {
    id: '9',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 55,
    name: 'retargeting',
    orgId: '1234',
  },
  {
    id: '10',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 25,
    name: 'captive',
    orgId: '1234',
  },
  {
    id: '11',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 25,
    name: 'segmentshare',
    orgId: '1234',
  },
  {
    id: '12',
    created: new Date(),
    updated: new Date(),
    type: 'video',
    price: 15,
    name: 'venueReplay',
    orgId: '1234',
  },
]
