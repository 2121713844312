/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1ComponentResult } from '../models/V1ComponentResult';
import { HttpFile } from '../http/http';

/**
* Object that holds all the info about all components of a job
*/
export class V1ComponentResults {
    'componentResults'?: Array<V1ComponentResult>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "componentResults",
            "baseName": "component_results",
            "type": "Array<V1ComponentResult>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ComponentResults.attributeTypeMap;
    }

    public constructor() {
    }
}

