/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1BatchAddAudiencesRequestAudience } from '../models/V1BatchAddAudiencesRequestAudience';
import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceBatchAddAudiencesRequest {
    'orgId'?: string;
    /**
    * A maximum of 1000 Audiences can be added in a batch.
    */
    'audiences'?: Array<V1BatchAddAudiencesRequestAudience>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<V1BatchAddAudiencesRequestAudience>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceBatchAddAudiencesRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

