import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Form,
  FormItem,
  PreviewType,
  TextBody,
  TextHeader,
  TextInput,
  Uploader,
} from '@eltoro-ui/components'
import { useForm } from '@eltoro-ui/hooks'
import { Orgmanagerv1Contact, Orgmanagerv1Org } from 'next-gen-sdk'
import { ErrorMessage, isValidCharLength, validCheck } from 'Helpers'
import { ContactSection } from './ContactSection'
import { ContactStateType } from './useContactState'

type cOrgProp = {
  parentOrg: Orgmanagerv1Org
  orgName: string
  setOrgName: React.Dispatch<React.SetStateAction<string>>
  salesState: ContactStateType
  opsState: ContactStateType
  billingState: ContactStateType
  newChildOrgLogo?: PreviewType
  setNewChildOrgLogo: React.Dispatch<
    React.SetStateAction<PreviewType | undefined>
  >
  onSave: (newOrgDetails: Orgmanagerv1Org) => void
}
export const Step1ChildOrgDetails = ({
  parentOrg,
  orgName,
  setOrgName,
  salesState,
  opsState,
  billingState,
  newChildOrgLogo,
  setNewChildOrgLogo,
  onSave,
}: cOrgProp) => {
  const [logoError, setLogoError] = useState<string>()
  const { isValidForm, touched } = useForm({
    orgName,
    // operations
    opsFirstName: opsState.firstName,
    opsLastName: opsState.lastName,
    opsEmail: opsState.email,
    opsPhone: opsState.phone,
    opsAddress: opsState.address,
    opsZip: opsState.zip,
    opsCity: opsState.city,
    opsState: opsState.state,
    // sales
    salesFirstName: salesState.firstName,
    salesLastName: salesState.lastName,
    salesEmail: salesState.email,
    salesPhone: salesState.phone,
    salesAddress: salesState.address,
    salesZip: salesState.zip,
    salesCity: salesState.city,
    salesState: salesState.state,
    // billing
    billingFirstName: billingState.firstName,
    billingLastName: billingState.lastName,
    billingEmail: billingState.email,
    billingPhone: billingState.phone,
    billingAddress: billingState.address,
    billingZip: billingState.zip,
    billingCity: billingState.city,
    billingState: billingState.state,
  })

  const { validForm, missingFields } = isValidForm([
    'orgName',
    opsState.isValidPhone,
    opsState.isValidEmail,
    salesState.isValidEmail,
    salesState.isValidPhone,
    billingState.isValidEmail,
    billingState.isValidPhone,
  ])
  const inputLength = 255
  const lengthLimit = orgName.length > inputLength

  const checkIfValidContact = (contact: Orgmanagerv1Contact) => {
    // eslint-disable-next-line no-unused-vars
    const { type, ...rest } = contact
    const isValid = Object.values(rest).every((n) => n)
    return isValid
  }

  const handleSubmit = async () => {
    const billingContact = {
      email: billingState.email,
      firstName: billingState.firstName,
      lastName: billingState.lastName,
      address: billingState.address,
      city: billingState.city,
      state: billingState.state,
      zip: billingState.zip,
      phone: billingState.phone,
      country: billingState.country,
      type: 'billing',
    }
    const opsContact = {
      email: opsState.email,
      firstName: opsState.firstName,
      lastName: opsState.lastName,
      address: opsState.address,
      city: opsState.city,
      state: opsState.state,
      zip: opsState.zip,
      phone: opsState.phone,
      country: opsState.country,
      type: 'operations',
    }
    const salesContact = {
      email: salesState.email,
      firstName: salesState.firstName,
      lastName: salesState.lastName,
      address: salesState.address,
      city: salesState.city,
      state: salesState.state,
      zip: salesState.zip,
      phone: salesState.phone,
      country: salesState.country,
      type: 'sales',
    }

    onSave({
      billableOrgId: !parentOrg.reseller ? parentOrg.id : undefined, // reseller orgs create children that are billable
      name: orgName,
      parentOrgId: parentOrg.id,
      contacts: [
        ...(checkIfValidContact(billingContact) ? [billingContact] : []),
        ...(checkIfValidContact(opsContact) ? [opsContact] : []),
        ...(checkIfValidContact(salesContact) ? [salesContact] : []),
      ],
    })
  }

  return (
    <div className="Step1ChildOrgDetails flex-grow">
      <Form
        className="flex flex-col gap-2"
        missingFields={missingFields}
        touched={touched}
        valid={validForm}
        onSubmit={handleSubmit}
      >
        <TextHeader type={5} className="my-2 flex items-center text-lg">
          <span className="RightVerticalPipe font-light uppercase">Step 1</span>
          <span>Child org details</span>
        </TextHeader>

        <FormItem
          className="Step1ChildOrgDetails__org-details-container pb-2"
          htmlFor="orgName"
          label="Org name"
          errorMessage={ErrorMessage({
            fieldName: orgName || '',
            max: 255,
            label: 'Org name',
            isValid:
              touched.includes('orgName') && !lengthLimit
                ? !!orgName
                : true &&
                  validCheck(orgName) &&
                  isValidCharLength(orgName, 255),
          })}
          valid={
            touched.includes('orgName') && !lengthLimit
              ? !!orgName
              : true && validCheck(orgName) && isValidCharLength(orgName, 255)
          }
          required
        >
          <TextInput
            classNameWrap={`ChildOrg_name-input-wrap text-tint-gray-800  ${
              lengthLimit ? 'border-warning animate-glowwarning' : ''
            } ${
              validForm && !lengthLimit
                ? 'border-success animate-glowsuccess'
                : ''
            }`}
            value={orgName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgName(e.target.value)
            }
            maxLength={inputLength + 1} // so warning message will show
          />
        </FormItem>

        <TextHeader type={5} className="py-2">
          Logo <span className="font-light lowercase">(optional)</span>
        </TextHeader>
        <div className="mb-4 flex items-center gap-4">
          <Uploader
            isStyled
            maxFiles={1}
            accepts="logo"
            onDrop={(files: PreviewType[], rejected) => {
              setLogoError(undefined)
              const [file] = files
              if (!file || rejected?.length) {
                if (rejected?.length) {
                  const [rejectedFile] = rejected
                  setLogoError(rejectedFile.errors[0].message)
                }
                return
              }
              setNewChildOrgLogo(file)
            }}
            className="border-dashed"
          >
            <div className="flex items-center justify-center">
              <TextBody type={1}>
                Click or drag files here to upload from your computer
              </TextBody>
            </div>
          </Uploader>
          {newChildOrgLogo ? (
            <Avatar src={newChildOrgLogo.preview} rounded size="l" />
          ) : (
            <Avatar name={orgName} rounded size="l" />
          )}
        </div>
        {logoError && (
          <div className="Step1ChildOrgDetails__error bg-danger-50 text-danger animate-slidedown flex items-center justify-center gap-4 rounded-md p-2">
            {logoError}
          </div>
        )}
        <TextHeader type={5} className="py-3">
          Contact information
        </TextHeader>
        <div className="div flex flex-col gap-4">
          <ContactSection type="ops" parentOrg={parentOrg} {...opsState} />
          <ContactSection type="sales" parentOrg={parentOrg} {...salesState} />
          <ContactSection
            type="billing"
            parentOrg={parentOrg}
            {...billingState}
          />
        </div>
        <div className="m-4 flex justify-end">
          <Button
            className={`OrgName_next-button ${
              validForm && !lengthLimit
                ? 'bg-primary text-tint-light ripples'
                : 'is-disabled'
            }`}
            type="submit"
            disabled={!validForm && !lengthLimit}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  )
}
