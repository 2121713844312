import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import './CalendarInput.scss'
import { CalendarInputDay, CalendarInputMonth } from '..'

const isSameDay = (
  day1: Date | null | undefined,
  day2: Date | null | undefined,
) => {
  return day1?.toDateString() === day2?.toDateString()
}

export const CalendarInput = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  inputTarget,
  onInputTargetChange,
  min,
  max,
  indefinite,
}: {
  startDate: Date
  endDate: Date
  onStartDateChange: (newStartDate: Date) => void
  onEndDateChange: (newEndDate: Date) => void
  inputTarget: 'start' | 'end'
  onInputTargetChange: (newTarget: 'start' | 'end') => void
  min?: Date
  max?: Date
  indefinite?: boolean
}) => {
  const [modifiedDisplayMonth, setModifiedDisplayMonth] = useState<Date>()
  const [calDays, setCalDays] = useState<Date[] | null>()
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null)
  const initialDisplayMonth = startDate || min || new Date()

  useEffect(() => {
    const makePaddedMonth = (date: Date) => {
      const padding =
        new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1
      return new Array(42)
        .fill(null)
        .map(
          (day, i) =>
            new Date(date.getFullYear(), date.getMonth(), i - padding),
        )
    }
    if (!modifiedDisplayMonth) {
      const days = makePaddedMonth(initialDisplayMonth)
      setCalDays(days)
    }
    if (modifiedDisplayMonth) {
      const days = makePaddedMonth(modifiedDisplayMonth)
      setCalDays(days)
    }
  }, [modifiedDisplayMonth])

  const toggleTarget = () =>
    onInputTargetChange(inputTarget === 'start' ? 'end' : 'start')

  const handleDayClick = (date: Date) => {
    if (indefinite) {
      onStartDateChange(date)
    } else {
      if (date === startDate && date === endDate) {
        onStartDateChange(date)
        onEndDateChange(date)
        onInputTargetChange('end')
      }
      if (inputTarget === 'start') {
        if (date > endDate) {
          onStartDateChange(date)
          onEndDateChange(date)
          toggleTarget()
        } else {
          onStartDateChange(date)
          toggleTarget()
        }
      }
      if (inputTarget === 'end') {
        if (date < startDate) {
          onStartDateChange(date)
          onEndDateChange(date)
        } else {
          onEndDateChange(date)
          toggleTarget()
        }
      }
    }
  }

  return (
    <div className="CalendarInput px-4 pt-2 pb-5">
      <CalendarInputMonth
        month={modifiedDisplayMonth || initialDisplayMonth}
        onMonthChange={(change) => {
          setModifiedDisplayMonth(dayjs(change).startOf('month').toDate())
        }}
      />
      <div className="CalendarInput__days-grid">
        {calDays &&
          calDays.map((day) => {
            const isDisabled =
              (!!min && (day < dayjs().startOf('day').toDate() || day < min)) ||
              (!!max && day > max)

            const isInRange = indefinite
              ? day >= startDate
              : day >= startDate && day <= endDate
            const isStart = isSameDay(day, startDate)
            const isEnd = indefinite ? false : isSameDay(day, endDate)
            const isHovered = isSameDay(day, hoveredDay)

            const isInHoveredRange = (() => {
              if (isHovered) return true
              if (hoveredDay) {
                if (
                  inputTarget === 'start' &&
                  day >= hoveredDay &&
                  day <= endDate
                )
                  return true

                if (
                  inputTarget === 'end' &&
                  day >= startDate &&
                  day <= hoveredDay
                )
                  return true
              }
              return false
            })()

            const isHoveredBeforeStart =
              isHovered &&
              !!hoveredDay &&
              hoveredDay < startDate &&
              !isSameDay(hoveredDay, endDate)

            const isHoveredAfterEnd =
              isHovered &&
              !!hoveredDay &&
              hoveredDay > endDate &&
              !isSameDay(hoveredDay, startDate)

            const isHoveredStart =
              isHoveredBeforeStart ||
              (isHovered && inputTarget === 'start') ||
              (isHovered && isStart && inputTarget === 'end') ||
              (!!hoveredDay && hoveredDay > startDate && isStart)

            const isHoveredEnd =
              isHoveredAfterEnd ||
              (isHovered && inputTarget === 'end') ||
              (isHovered && isEnd && inputTarget === 'start') ||
              (isHoveredBeforeStart && inputTarget === 'end') ||
              (!isHovered && !!hoveredDay && isEnd && inputTarget === 'start')
            return (
              <CalendarInputDay
                key={day.toDateString()}
                day={day}
                indefinite={indefinite}
                isInMonth={initialDisplayMonth.getMonth() === day.getMonth()}
                isSubdued={!!hoveredDay && isInRange}
                isDisabled={isDisabled}
                isStart={isStart}
                isEnd={indefinite ? false : isEnd}
                isInRange={isInRange}
                isHovered={isHovered}
                isHoveredStart={isHoveredStart}
                isHoveredEnd={indefinite ? false : isHoveredEnd}
                isInHoveredRange={isInHoveredRange}
                onClick={handleDayClick}
                onHoverChange={(hovered, _day) =>
                  setHoveredDay(hovered ? _day : null)
                }
              />
            )
          })}
      </div>
    </div>
  )
}
