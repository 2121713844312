import { useEffect, useState } from 'react'
import { Map } from '@eltoro-ui/map'
import { Feature } from 'geojson'
import {
  FormItem,
  TextInput,
  Text,
  Button,
  Fa,
  Modal,
  Checkbox,
  RadioButtonGroup,
  RadioButton,
} from '@eltoro-ui/components'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useAppContext, useCampaignContext, useLibraryContext } from 'Contexts'
import {
  createAndGenerateAudience,
  getApiConfig,
  uploadTargetFile,
} from 'Requests'
import {
  Targetjobservicev1Audience,
  Targetjobservicev1NoteType,
  Targetjobservicev1Timeframe,
} from 'next-gen-sdk'
import { UploadSuccess } from 'Components'
import { FormattedFeatureType, FeatureType, TimeFrame } from 'types'
import {
  ErrorMessage,
  isValidCharLength,
  poll,
  styleTailwind,
  validCheck,
} from 'Helpers'
import { AUDIENCE_NOTE_LIMIT } from 'constantVars'
import { Timeframes } from './TimeFrames'
import 'react-datepicker/dist/react-datepicker.css'
import './PolygonMap.scss'

type LatLngTuple = [number, number]

const markerHtml =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="32px" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#FF0000" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>'

export const WarningMessageText = styleTailwind(
  Text,
  'WarningMessageText text-warning leading-none text-s',
)
export const WarningMessage = styleTailwind(
  'div',
  'WarningMessage mb-4 mt-1 flex items-center text-warning w-full gap-1 animate-slidedown',
)
export const PolygonMap = ({
  vr,
  onAddAudienceToAttach,
  onRemoveAudienceFromAttach, // could this ever be needed here? (will pixel audience types ever be available from the library?)
  DBFeatures,
  DBTimeFrames,
  existingAudience,
  hasDeployments,
  onSaveEdit,
}: {
  vr: boolean
  onAddAudienceToAttach?: (audience: Targetjobservicev1Audience) => void
  onRemoveAudienceFromAttach?: (audience: Targetjobservicev1Audience) => void
  // For editing:
  DBFeatures?: Feature[]
  DBTimeFrames?: Targetjobservicev1Timeframe[]
  existingAudience?: Targetjobservicev1Audience
  hasDeployments?: boolean
  onSaveEdit?: () => void
}) => {
  const {
    currentOrg,
    audienceServiceApi,
    tok,
    rebuild,
    isAdmin,
    isExternalSales,
    isInternalSales,
  } = useAppContext()
  const { launchFreshCampaign } = useCampaignContext()
  const { pagination } = useLibraryContext()
  const history = useNavigate()
  const [completed, setCompleted] = useState<boolean>(false)
  const [features, setFeatures] = useState<Feature[] | []>([])
  const [audName, setAudName] = useState<string>(existingAudience?.name || '')
  const [timeFrames, setTimeFrames] = useState<TimeFrame[]>([{}])
  const [notes, setNotes] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])
  const [timeframeErrors, setTimeframeErrors] = useState<
    {
      index: number
      error: string[]
    }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [loaded, SetAlreadyLoaded] = useState(false)
  const [
    //
    uploadedAudience,
    setUploadedAudience,
  ] = useState<Targetjobservicev1Audience>()
  const [locked, setLocked] = useState(false)
  const [createClone, setCreateClone] = useState(false)
  const [
    //
    audienceNoteType,
    setAudienceNoteType,
  ] = useState<Targetjobservicev1NoteType>(
    isAdmin ? 'NOTE_TYPE_ADMIN' : 'NOTE_TYPE_USER',
  )

  useEffect(() => {
    if (DBFeatures && DBFeatures.length > 0 && !loaded) {
      if (existingAudience?.name) setAudName(existingAudience.name)
      setFeatures(DBFeatures)
      if (DBTimeFrames && DBTimeFrames.length > 0) {
        const timeFrameFormatter = DBTimeFrames.map((cur) => {
          if (!cur.start || !cur.stop) return {}
          return {
            start: new Date(cur.start),
            stop: new Date(cur.stop),
          }
        })
        setTimeFrames(timeFrameFormatter as TimeFrame[])
      } else {
        setTimeFrames([{}]) // init empty time frame for creation mode
      }
      SetAlreadyLoaded(true)
    }
    if (hasDeployments) setCreateClone(true)
  }, [
    DBFeatures,
    DBTimeFrames,
    existingAudience,
    existingAudience?.name,
    hasDeployments,
    loaded,
    tok,
    vr,
  ])

  const handleAudienceCreation = async () => {
    const orgId = currentOrg?.id
    if (!tok || !orgId || rebuild) return
    // Step 1: upload either a wkb .csv file or a geojson .json file (along with the columns/ other metadata)
    const geoJSONBlob = new Blob(
      [
        JSON.stringify(
          {
            feature_collection: (features as FeatureType[]).reduce(
              (collection: FormattedFeatureType[], feature: FeatureType) => {
                const coordChecker =
                  feature.geometry.coordinates &&
                  feature.geometry.coordinates[0].length > 1
                    ? [feature.geometry.coordinates]
                    : feature.geometry.coordinates
                return [
                  ...collection,
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'MultiPolygon',
                      coordinates: coordChecker,
                    },
                    properties: {
                      ...feature.properties,
                      key: feature.properties.key.toString(),
                      area: Number(feature.properties.area),
                    },
                  },
                ]
              },
              [],
            ),
          },
          null,
          2,
        ),
      ],
      {
        type: 'application/json',
      },
    )

    const target = await uploadTargetFile(
      `${audName}.json`,
      geoJSONBlob,
      tok,
      orgId,
      'FILE_TYPE_GEOJSON',
      false,
      [{ index: 0, value: 'geojson', type: 'geojson' }],
      undefined,
      'DATA_SOURCE_CLIENT',
      !createClone && existingAudience?.id && existingAudience?.targetId
        ? {
            targetId: existingAudience.targetId,
            audienceIds: [existingAudience.id],
          }
        : undefined, // replace options- editing
    )

    // Step 2a: update audience if this is not a clone
    if (!createClone && existingAudience?.id && audienceServiceApi) {
      if (existingAudience.type === 'AUDIENCE_TYPE_VR') {
        // need to cancel the audience processing since the target replace triggered it (updating timeframes will trigger again)
        await audienceServiceApi
          .advertisingPlatformServiceCancelAudienceGeneration({
            orgId,
            audienceId: existingAudience.id,
          })
          .catch(() => {}) // sometimes this doesn't work because it has already finished quoting. move on to next step.
      }

      // wait for the audience to be cancelled
      const validate = (aud?: Targetjobservicev1Audience) =>
        aud?.status !== 'AUDIENCE_STATUS_CANCELLED' &&
        aud?.status !== 'AUDIENCE_STATUS_COMPLETED' &&
        aud?.status !== 'AUDIENCE_STATUS_READY'

      const currentAud = await poll(
        (token) =>
          audienceServiceApi.advertisingPlatformServiceGetAudience(
            existingAudience.id || '',
            orgId,
            token ? getApiConfig(token) : undefined,
          ),
        validate,
        1000,
        900000,
      )

      if (currentAud?.status !== 'AUDIENCE_STATUS_RUNNING') {
        await audienceServiceApi?.advertisingPlatformServiceUpdateAudience(
          existingAudience.id,
          orgId,
          {
            name: audName,
            timeframes: vr
              ? timeFrames.map((current) => {
                  return {
                    start: current.start?.toISOString(),
                    stop: current.stop?.toISOString(),
                  }
                })
              : undefined,
          },
        )
        // only allowing note additions when creating a new audience (keeps table accurate)
        if (notes && createClone) {
          await audienceServiceApi.advertisingPlatformServiceCreateAudienceNote(
            existingAudience.id,
            orgId,
            { content: notes, type: audienceNoteType },
          )
        }
        // have to set locked after updates, otherwise we get an error that it cannot be updated
        if (locked) {
          await audienceServiceApi?.advertisingPlatformServiceUpdateAudience(
            existingAudience.id,
            orgId,
            {
              locked: locked ? true : undefined,
            },
          )
        }
        // re-generate map polygon
        if (
          existingAudience.type === 'AUDIENCE_TYPE_IPSFORGEOJSON' ||
          existingAudience.type === 'AUDIENCE_TYPE_IPSFORWKB'
        ) {
          await audienceServiceApi.advertisingPlatformServiceGenerateAudience(
            existingAudience.id,
            existingAudience.orgId,
          )
        }
        if (onSaveEdit) onSaveEdit()
      }
      // else?
      return
    }

    if (target?.id && orgId && audienceServiceApi) {
      // Step 2b: create a VR or map polygon audience and validate that it is created
      if (vr) {
        const createdAudience = await createAndGenerateAudience(
          orgId,
          'AUDIENCE_TYPE_VR',
          target.id,
          audName,
          undefined,
          undefined,
          timeFrames.map((current) => {
            return {
              start: current.start?.toISOString(),
              stop: current.stop?.toISOString(),
            }
          }),
        )
        // Step 4: If job created, add it to the queue
        if (
          createdAudience?.id &&
          createdAudience.status === 'AUDIENCE_STATUS_CREATED'
        ) {
          // Step 3: hit the generate audience endpoint with the audience id
          await audienceServiceApi
            ?.advertisingPlatformServiceGenerateAudience(
              createdAudience.id,
              orgId,
            )
            .then(() => {
              if (onAddAudienceToAttach && createdAudience.id)
                onAddAudienceToAttach(createdAudience)
              setUploadedAudience(createdAudience)
            })
            .then(() => {
              if (!createdAudience.id) return
              if (notes) {
                audienceServiceApi.advertisingPlatformServiceCreateAudienceNote(
                  createdAudience.id,
                  orgId,
                  { content: notes, type: audienceNoteType },
                )
              }
              if (locked) {
                audienceServiceApi.advertisingPlatformServiceUpdateAudience(
                  createdAudience.id,
                  orgId,
                  {
                    locked: locked,
                  },
                  'locked',
                )
              }
            })
        }
        // IF MAP POLYGON
      } else {
        const createdAudience = await createAndGenerateAudience(
          orgId,
          'AUDIENCE_TYPE_IPSFORGEOJSON',
          target.id,
          audName,
        )
        // Step 4: If job created, add it to the queue
        if (
          createdAudience?.id &&
          createdAudience.status === 'AUDIENCE_STATUS_CREATED'
        ) {
          // Step 3: hit the generate audience endpoint with the audience id
          await audienceServiceApi
            ?.advertisingPlatformServiceGenerateAudience(
              createdAudience.id,
              orgId,
            )
            .then(() => {
              if (onAddAudienceToAttach && createdAudience.id)
                onAddAudienceToAttach(createdAudience)
            })
            .then(() => {
              if (!createdAudience.id) return
              if (notes) {
                audienceServiceApi.advertisingPlatformServiceCreateAudienceNote(
                  createdAudience.id,
                  orgId,
                  { content: notes, type: audienceNoteType },
                )
              }
              if (locked) {
                audienceServiceApi.advertisingPlatformServiceUpdateAudience(
                  createdAudience.id,
                  orgId,
                  {
                    locked: locked,
                  },
                  'locked',
                )
              }
            })
          setUploadedAudience(createdAudience)
        }
      }
      setCompleted(true)
    }
  }
  const areaChecker = features.filter((currentFeat) => {
    return currentFeat?.properties?.area > 1
  })
  const totalArea =
    features.length > 0 &&
    features
      .map((cur: Feature) => Number(cur.properties?.area))
      .reduce((a, b) => b + a)
  const handleOnUseInCampaign = () => {
    // clear+add to campaign context
    if (uploadedAudience) launchFreshCampaign({ audience: uploadedAudience })

    // redirect to create campaign
    const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
    history(`/campaigns/create${query}`)
  }

  return completed && !loading ? (
    <UploadSuccess
      jobType={vr ? 'venue-replay' : 'map-polygon'}
      fileName={audName}
      onUseInCampaign={handleOnUseInCampaign}
      onUploadAnother={() => {
        setAudName('')
        setFeatures([])
        setCompleted(false)
        if (vr) setTimeFrames([{}])
      }}
    />
  ) : (
    <>
      <div className="PolygonMap divide-grey-200 grid grid-cols-1 divide-y-[1px]">
        {!vr ? (
          <div className="pb-4 pt-4">
            <Text className="mt-0 flex gap-1 leading-none" tag="h3">
              Map Polygon:
              <span className="font-light">
                Target in real-time at the IP level of mapped locations
              </span>
            </Text>
            <Text className="text-ml py-1 leading-tight" tag="p">
              This feature creates matches for any IP within a given polygon. It
              does not currently distinguish between business and residential
              IPs.
            </Text>
          </div>
        ) : (
          <div className="pb-4 pt-4">
            <Text className="mt-0 flex gap-1 leading-none" tag="h3">
              Venue Replay:
              <span className="font-light">
                Geo-framing cookieless remarketing based on physical location
              </span>
            </Text>
            <Text className="text-ml py-1 leading-tight" tag="p">
              Capture devices seen at high value locations. By mapping the area,
              identify any devices (smartphones, tablets, laptops) after they
              leave the venue and continue to target them with ads at their home
              and across all of their devices.
            </Text>
          </div>
        )}
        <div className="divide-grey-200 desktop:grid-cols-6 laptopsmall:flex laptopsmall:flex-col laptop:grid-cols-5 desktop:divide-x-[1px] grid pb-4">
          <div className="desktop:col-span-5 laptopsmall:flex laptop:col-span-4 inline-block h-auto">
            <Map
              center={[38.194706, -85.71053] as LatLngTuple}
              markerHtml={markerHtml}
              tileProvider="Esri.WorldTopoMap"
              ClientFeatures={
                DBFeatures && features.length === 0 && !loaded
                  ? DBFeatures
                  : features
              }
              onShapeChange={(data) => {
                if (!data) return false
                if (
                  errors.length > 0 &&
                  (errors as string[]).includes('features')
                ) {
                  setErrors(errors.filter((current) => current !== 'features'))
                }
                return setFeatures(data)
              }}
              hideSearch={false}
              cutMode={false}
              editable
              vr={vr}
              uniqueMapId={`${Math.random() * 70}`}
            />
          </div>
          <div className="PolygonMap__MapDetails desktop:col-span-1 desktop:pl-4 laptop:pl-4 laptopsmall:!pl-0 max-h-screen pt-4">
            {features.length > 0 && (
              <div className="MapDetails flex flex-col gap-1">
                <h3 className="text-tint-gray-800 mt-0 mb-2 text-xl leading-none">
                  Map Details
                </h3>
                <Text
                  className="PolyMessageText text-primary-600 text-m py-2 leading-tight"
                  tag="p"
                >
                  {features.length} polygon{features.length > 1 ? 's, ' : ', '}
                  {Number(totalArea).toFixed(3)} sq. miles targeted
                </Text>
              </div>
            )}

            {vr && (
              <div className="PolygonMap__timeframe--vr flex items-center pb-3">
                <h3 className="text-tint-gray-900 mt-0 text-xl leading-normal">
                  Timeframes
                </h3>
                <span
                  className="Timeframe__tooltip hover:cursor-pointer"
                  data-tooltip="top"
                  aria-label={`Timeframe is limited to the past ${
                    isAdmin || isExternalSales || isInternalSales
                      ? 'year'
                      : 'six months'
                  } and must end at least one day ago`}
                >
                  <Fa
                    icon="circle-info"
                    size={1}
                    className="!text-primary ml-1"
                  />
                </span>
              </div>
            )}
            {vr && (
              <div className="PolygonMap__timeFrame__start-stop flex flex-col gap-1 pb-8">
                <Timeframes
                  timeFrames={timeFrames}
                  setTimeFrames={setTimeFrames}
                  timeframeErrors={timeframeErrors}
                />
              </div>
            )}
            <div className="PolygonMap__audience-details">
              <h3 className="text-tint-gray-800 text-l mt-0 mb-2 leading-none">
                Audience details
              </h3>
              <div className="PolygonMap__audience-details-name py-2">
                <FormItem
                  wrapperClassname="flex-col"
                  htmlFor="name"
                  label="Name:"
                  required
                  errorMessage={ErrorMessage({
                    fieldName: audName,
                    max: 255,
                    label: 'Audience name',
                  })}
                  valid={
                    validCheck(audName) &&
                    isValidCharLength(audName, 255) &&
                    !(errors as string[]).includes('name')
                  }
                  counter={audName.length > 0}
                >
                  <TextInput
                    // classNameWrap={classNames('PolygonMap__name-input', {
                    // glow warning even if the input was not touched
                    //   'border-warning animate-glowwarning': (errors as string[]).includes(
                    //     'name',
                    //   ),
                    // })}
                    classNameWrap="PolygonMap__name-input"
                    value={audName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (
                        errors.length > 0 &&
                        (errors as string[]).includes('name')
                      ) {
                        setErrors(
                          errors.filter((current) => current !== 'name'),
                        )
                      }
                      setAudName(e.target.value)
                    }}
                    valid={
                      !(errors as string[]).includes('name') &&
                      audName.length > 2 &&
                      audName.length <= 255
                    }
                  />
                </FormItem>
                {(errors as string[]).includes('name') && (
                  <WarningMessage>
                    <Fa
                      icon="diamond-exclamation"
                      size={1}
                      className="text-inherit"
                    />
                    <WarningMessageText className="leading-none">
                      An audience name is required.
                    </WarningMessageText>
                  </WarningMessage>
                )}
              </div>
              {(!existingAudience?.id ||
                (existingAudience.id && createClone)) && (
                <div className="PolygonMap__notes animate-slidedown pb-2">
                  <FormItem
                    wrapperClassname="flex-col"
                    htmlFor="note"
                    label="Notes:"
                    errorMessage={ErrorMessage({
                      fieldName: notes || '',
                      max: AUDIENCE_NOTE_LIMIT,
                      label: 'Notes',
                      isValid:
                        notes.length > 1 && notes.length < AUDIENCE_NOTE_LIMIT,
                    })}
                    valid={
                      validCheck(notes) &&
                      isValidCharLength(notes, AUDIENCE_NOTE_LIMIT)
                    }
                    counter={notes.length > 0}
                  >
                    <textarea
                      className={classNames(
                        'PolygonMap__notes--textarea border-grey-400 resize-none rounded border p-2 text-xs',
                        {
                          'border-warning animate-glowwarning text-warning-500':
                            notes.length > AUDIENCE_NOTE_LIMIT,
                        },
                      )}
                      value={notes || ''}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                    {/* <p
                      className={classNames(
                        'text-right text-xs font-semibold',
                        {
                          'text-warning-500':
                            notes.length > AUDIENCE_NOTE_LIMIT,
                        },
                      )}
                    >
                      Remaining: {AUDIENCE_NOTE_LIMIT - notes.length}
                    </p> */}
                  </FormItem>
                  {isAdmin && (
                    <FormItem
                      wrapperClassname="flex-col mt-4"
                      htmlFor="Admin vs User note"
                      label="Note Type:"
                    >
                      <RadioButtonGroup
                        className="PolygonMap-note-type-radio-group"
                        layout="horizontal"
                        name="Admin vs User note"
                        valueSelected={audienceNoteType}
                        defaultSelected={
                          isAdmin ? 'NOTE_TYPE_ADMIN' : 'NOTE_TYPE_USER'
                        }
                        size="s"
                        onChange={(value) => {
                          setAudienceNoteType(
                            value as Targetjobservicev1NoteType,
                          )
                        }}
                      >
                        <RadioButton
                          id="admin"
                          value="NOTE_TYPE_ADMIN"
                          checked={audienceNoteType === 'NOTE_TYPE_ADMIN'}
                          label="Admin"
                        />
                        <RadioButton
                          id="user"
                          value="NOTE_TYPE_USER"
                          checked={audienceNoteType === 'NOTE_TYPE_USER'}
                          label="User"
                        />
                      </RadioButtonGroup>
                    </FormItem>
                  )}
                  {(errors as string[]).includes('note_length') && (
                    <WarningMessage>
                      <Fa
                        icon="diamond-exclamation"
                        size={1}
                        className="text-inherit"
                      />
                      <WarningMessageText className="leading-none">
                        Note must be less than {AUDIENCE_NOTE_LIMIT} characters.
                      </WarningMessageText>
                    </WarningMessage>
                  )}
                </div>
              )}
            </div>
            {isAdmin && (
              <Checkbox
                label="Lock audience"
                checked={locked}
                onChange={(val) => {
                  setLocked(val)
                }}
              />
            )}

            {loaded && DBFeatures && vr && (
              <span
                data-tooltip="bottom"
                aria-label={
                  hasDeployments
                    ? 'This audience is deployed and cannot be edited directly'
                    : undefined
                }
              >
                <Checkbox
                  label="Clone audience"
                  checked={createClone}
                  disabled={hasDeployments}
                  onChange={(val) => {
                    setCreateClone(val)
                    if (
                      existingAudience?.name &&
                      (existingAudience?.name === audName ||
                        audName === `${existingAudience?.name}-clone`)
                    ) {
                      if (val) {
                        setAudName(`${existingAudience.name}-clone`)
                      } else {
                        setAudName(existingAudience.name)
                      }
                    }
                  }}
                />
              </span>
            )}
            {(features.length > 0 || errors.length > 0) && !vr && (
              <div className="PolygonMap__PolygonMap-warning flex-col justify-center pt-4">
                {(errors as string[]).includes('features') && (
                  <WarningMessage>
                    <Fa
                      icon="diamond-exclamation"
                      size={1}
                      className="text-inherit"
                    />
                    <WarningMessageText>
                      At least one map polygon required.
                    </WarningMessageText>
                  </WarningMessage>
                )}
              </div>
            )}
            {(areaChecker.length > 0 || errors.length > 0) && vr && (
              <div className="PolygonMap__VRMap-warning flex-col justify-center pt-4">
                {areaChecker.length > 0 && (
                  <WarningMessage>
                    <Fa
                      icon="diamond-exclamation"
                      size={1}
                      className="text-inherit"
                    />
                    <WarningMessageText className="leading-none">
                      {areaChecker.length} polygon
                      {areaChecker.length > 1 ? 's have an ' : ' has an '} area
                      greater than 1 sq. mile.
                    </WarningMessageText>
                  </WarningMessage>
                )}
                {(errors as string[]).includes('features') && (
                  <WarningMessage>
                    <Fa
                      icon="diamond-exclamation"
                      size={1}
                      className="text-inherit"
                    />
                    <WarningMessageText>
                      At least one map polygon required.
                    </WarningMessageText>
                  </WarningMessage>
                )}
              </div>
            )}

            <div className="PolygonMap__buttons flex flex-col py-4">
              <Button
                className="laptopsmall:w-40 laptopsmall:mx-auto"
                type="submit"
                iconLeft={<Fa icon="fa-save" size={1} />}
                disabled={loading}
                onClick={() => {
                  let errors: string[] = []
                  let tfErrors: {
                    index: number
                    error: string[]
                  }[] = []
                  if (!audName) {
                    errors = [...errors, 'name']
                  }
                  if (features.length <= 0) {
                    errors = [...errors, 'features']
                  }
                  if (notes && notes.length > AUDIENCE_NOTE_LIMIT) {
                    errors = [...errors, 'note_length']
                  }
                  if (vr) {
                    tfErrors = timeFrames.reduce(
                      (
                        acc: {
                          index: number
                          error: string[]
                        }[],
                        tf,
                        index,
                      ) => {
                        let error: string[] = []
                        if (!tf.start) {
                          error = [...error, 'start']
                        }
                        if (!tf.stop) {
                          error = [...error, 'stop']
                        }
                        if (dayjs(tf.start).isSame(tf.stop)) {
                          error = [...error, 'equal_dates']
                        }
                        if (dayjs(tf.start).isAfter(tf.stop)) {
                          error = [...error, 'start_after_stop']
                        }
                        if (error) {
                          return [...acc, { index, error }]
                        } else return acc
                      },
                      [],
                    )
                    setTimeframeErrors(tfErrors)
                  }
                  if (errors.length > 0) {
                    setErrors(errors)
                  }
                  if (
                    errors.length > 0 ||
                    tfErrors.some((tf) => tf.error?.length > 0)
                  ) {
                    return
                  }
                  if (
                    vr &&
                    audName &&
                    features.length > 0 &&
                    timeFrames.length > 0 &&
                    areaChecker.length <= 0
                  ) {
                    setLoading(true)
                    handleAudienceCreation().finally(() => {
                      if (!existingAudience || createClone) pagination.refetch()
                      setLoading(false)
                    })
                  }
                  if (!vr && audName && features.length) {
                    setLoading(true)
                    handleAudienceCreation().finally(() => {
                      if (!existingAudience || createClone) pagination.refetch()
                      setLoading(false)
                    })
                  }
                }}
              >
                {`Save
                ${(() => {
                  if (existingAudience) {
                    if (createClone) return ' Clone'
                    return ' Changes'
                  }
                  return ''
                })()}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* TODO: We can make this better, just need something to stop the user from leaving while the target becomes 'targetable' */}
      {loading && (
        <Modal>
          <Fa icon="spinner" size={2} className="text-grey-200 animate-spin" />
          <p>Loading</p>
        </Modal>
      )}
    </>
  )
}
