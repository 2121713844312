import { RecoilText } from 'Components/Canvas/RecoilText'
import { Text as TextType } from 'konva/types/shapes/Text'
import { Text } from 'react-konva'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  blockFontSizeAtom,
  blockTextColorAtom,
  blockTextValueAtom,
  mainDimensionsBlockAtom,
} from 'State'
import { BlockConfigType } from 'Typings'

export const BasicParagraph: React.FC<{
  id: string
  width: number
  height: number
  isOnMobile?: boolean
}> = ({ id, width, height, isOnMobile = false }) => {
  const setMainDimensions = useSetRecoilState(mainDimensionsBlockAtom(id))
  const letterRef = useRef<TextType>(null)
  const topTextRef = useRef<TextType>(null)
  const [topText, setTopText] = useState('')
  const [letterDims, setLetterDims] = useState({ width: 0, height: 0 })
  const textConfig: BlockConfigType = {
    id,
    kind: 'basicParagraph',
    key: 'paragraphText',
  }
  const textValue = useRecoilValue(blockTextValueAtom(textConfig))
  const fontSize = useRecoilValue(blockFontSizeAtom(textConfig))
  const color = useRecoilValue(blockTextColorAtom(textConfig))
  const firstLetter = textValue.substring(0, 1)
  const topTextValue = textValue.substring(1)
  const remainingText = topTextValue.replace(topText, '')

  useEffect(() => {
    if (!letterRef.current) return
    setLetterDims({
      width: letterRef.current.textWidth,
      height: letterRef.current.textHeight,
    })
  }, [letterRef.current, fontSize])

  useEffect(() => {
    if (!topTextRef.current) return
    const text = topTextRef.current.textArr.map((t) => t.text).join(' ')
    // All text in <Group>
    const allChildren = topTextRef.current.parent?.getChildren()
    const childArray = allChildren?.toArray()
    const allTextArrs = childArray?.map((c: any) => c.textArr.length)
    const childAttrs = childArray?.map((c) => c.getAttrs())
    const childWidth = childAttrs?.filter((c) => c.width)
    // Figure out how many lines of text
    const numberOfLines = allTextArrs?.reduce((a, b) => a + b) - 1
    if (!childWidth) return
    setMainDimensions({
      width: Math.max.apply(
        null,
        childWidth.map((c) => c.width),
      ),
      height: numberOfLines * fontSize,
    })
    setTopText(text)
  }, [topTextRef.current, fontSize, textValue])

  const bigLetterFontSize = fontSize * 2.5
  const bigLetterLineHeight = fontSize * 2
  return (
    <>
      <Text
        ref={letterRef}
        fill={color}
        isOnMobile={isOnMobile}
        fontSize={isOnMobile ? bigLetterFontSize * 0.75 : bigLetterFontSize}
        text={firstLetter}
        fontStyle="bold"
        listening={false}
      />
      <RecoilText
        ref={topTextRef}
        config={textConfig}
        width={width - letterDims.width}
        height={bigLetterLineHeight}
        x={letterDims.width}
        isOnMobile={isOnMobile}
        value={topTextValue}
        ellipsis={false}
      />
      {!!remainingText && (
        <RecoilText
          id={id}
          config={textConfig}
          width={width}
          height={height - bigLetterLineHeight}
          y={bigLetterLineHeight}
          isOnMobile={isOnMobile}
          value={remainingText}
        />
      )}
    </>
  )
}
