import { ReactNode } from 'react'
import classNames from 'classnames'
import { Text, TextTypesUnionType } from '../Text'
import './TextHeader.scss'

export const TextHeader = (
  props: TextTypesUnionType & {
    className?: string
    children: ReactNode
    type?: 1 | 2 | 3 | 4 | 5 | 6
    leading?: 1 | 2 | 3 | 4 | 5 | 6
  },
) => {
  const { className, children, type, leading } = props
  const fontSize = () => {
    if (type === 1) return '4xl'
    if (type === 2) return '3xl'
    if (type === 3) return '2xl'
    if (type === 4) return 'xl'
    if (type === 5) return 'l'
    if (type === 6) return 'm'
    return undefined
  }
  const lineHeight = () => {
    if (leading === 1) return 'none'
    if (leading === 2) return 'tight'
    if (leading === 3) return 'snug'
    if (leading === 4) return 'normal'
    if (leading === 5) return 'relaxed'
    if (leading === 6) return 'loose'
    return 'none'
  }
  const tag = () => {
    if (type === 1) return 'h1'
    if (type === 2) return 'h2'
    if (type === 3) return 'h3'
    if (type === 4) return 'h4'
    if (type === 5) return 'h5'
    if (type === 6) return 'h6'
    return 'h3'
  }
  return (
    <Text
      className={classNames('TextHeader', className)}
      {...props}
      weight="bold"
      size={fontSize()}
      lineHeight={lineHeight()}
      tag={tag()}
      subComponent
    >
      {children}
    </Text>
  )
}
