/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1OAuthTokenRequest {
    /**
    * OAuth2 grant_type being used for authorization. Can be either \"password\" or \"refresh_token\".
    */
    'grantType': string;
    /**
    * OAuth2 client_id being used for authorization.
    */
    'clientId': string;
    /**
    * OAuth2 client_secret being used for authorization.
    */
    'clientSecret': string;
    /**
    * Username of user logging in. Used when grant_type is \"password\".
    */
    'username'?: string;
    /**
    * Password of user logging in. Used when grant_type is \"password\".
    */
    'password'?: string;
    /**
    * The refresh token used to refresh an access token. Used when grant_type is \"refresh_token\".
    */
    'refreshToken'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "grantType",
            "baseName": "grant_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "clientId",
            "baseName": "client_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "clientSecret",
            "baseName": "client_secret",
            "type": "string",
            "format": ""
        },
        {
            "name": "username",
            "baseName": "username",
            "type": "string",
            "format": ""
        },
        {
            "name": "password",
            "baseName": "password",
            "type": "string",
            "format": ""
        },
        {
            "name": "refreshToken",
            "baseName": "refresh_token",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OAuthTokenRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

