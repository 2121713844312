import { useState } from 'react'
import {
  Button,
  CollapsibleContainer,
  Fa,
  RadioButton,
  RadioButtonGroup,
  Text,
  TextHeader,
} from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import {
  getTypeMoneyCost,
  rate,
  truncateMiddle,
  separatePoliticalCPMs,
} from 'Helpers'
import { CPMTable, PoliticalToggle } from 'Components'
import { CPMObjectType } from 'types'

export const Step2ChildOrgCPM: React.FC<{
  parentOrg: Orgmanagerv1Org
  cpmChoice: string
  setCpmChoice: React.Dispatch<React.SetStateAction<string>>
  cpmIncrease: number
  setCpmIncrease: React.Dispatch<React.SetStateAction<number>>
  editingCpmIncrease: boolean
  setEditingCpmIncrease: React.Dispatch<React.SetStateAction<boolean>>
  inputCpms?: CPMObjectType
  setInputCpms: React.Dispatch<React.SetStateAction<CPMObjectType | undefined>>
  onSave: (newCpms?: CPMObjectType) => void
  onBack: () => void
}> = ({
  parentOrg,
  cpmChoice,
  setCpmChoice,
  editingCpmIncrease,
  setEditingCpmIncrease,
  cpmIncrease,
  setCpmIncrease,
  inputCpms,
  setInputCpms,
  onSave,
  onBack,
}) => {
  const [showPoliticalPricing, setShowPoliticalPricing] = useState(false)

  const handleSubmit = () => {
    if (cpmChoice === 'overall') {
      onSave(handleIncreaseCPMsByPercent())
      return
    }
    if (cpmChoice === 'set') {
      onSave(inputCpms)
      return
    }
    onSave(parentOrg.cpms)
  }

  // Calculates the new values based on percentage
  const getNewCPMValues = (value: CPMObjectType, percent: number) => {
    const valueWithNanos = getTypeMoneyCost(value)
    const increasedValue = rate(valueWithNanos * (percent + 1), 1, 100) // round to two decimal places
    const newUnits = increasedValue - (increasedValue % 1)
    const newNanos = Math.round(+(increasedValue % 1) * 10 ** 9)

    return {
      units: newUnits.toString(),
      nanos: +newNanos,
      currency_code: value.currency_code,
    }
  }

  // Updates parent cpms with new prices by percentage increase, returns new CPM object
  const handleIncreaseCPMsByPercent = () => {
    const updatedCpms = Object.entries(parentOrg.cpms || {}).reduce(
      (acc, [key, cpm]) => {
        return {
          ...acc,
          [key]: getNewCPMValues(cpm as CPMObjectType, cpmIncrease / 100),
        }
      },
      {},
    )
    return updatedCpms
  }

  const nextIsDisabled = !cpmChoice

  const cpmOnChange = (newCpm: CPMObjectType) =>
    setInputCpms((prev) => {
      if (prev) {
        return { ...prev, ...newCpm }
      }
      return newCpm
    })

  const { nonPoliticalCPMs, politicalCPMs } = separatePoliticalCPMs(
    inputCpms || {},
  )

  return (
    <div className="Step2ChildOrgCPM flex-grow pr-4">
      <TextHeader type={5} className="text-l flex items-center pb-2 ">
        <span className="RightVerticalPipe font-light uppercase">Step 2</span>
        <span>Set Child Org Price Structure</span>
      </TextHeader>

      <RadioButtonGroup
        className="ChildOrg__price-structure grid-cols-1"
        valueSelected={cpmChoice}
        name="Job type selection"
        onChange={(newCpmChoice) => {
          setCpmChoice(newCpmChoice as string)
        }}
      >
        <RadioButton
          className="CPM w-full flex-1"
          value="same"
          label={`Same CPM prices as ${truncateMiddle(parentOrg.name, 25)}`}
        />
        <RadioButton
          value="overall"
          label={
            <span className="-mt-1 flex w-full flex-1 items-center gap-1">
              Overall CPM increase from {truncateMiddle(parentOrg.name, 25)}
              &nbsp; by&nbsp;
              {editingCpmIncrease ? (
                <>
                  <input
                    type="number"
                    value={cpmIncrease}
                    min={0}
                    onChange={(e) => {
                      e.stopPropagation()
                      setCpmIncrease(Number(e.target.value))
                    }}
                    className="border-tint-gray-200 max-w-[8ch] rounded border p-1 px-2"
                  />
                  &nbsp;%
                </>
              ) : (
                <div className="flex items-center gap-2">
                  <Text
                    size="l"
                    weight="bold"
                  >{`${cpmIncrease.toString()}%`}</Text>
                  <span data-tooltip="top" aria-label="Edit CPM Increase">
                    <Button
                      iconOnly={<Fa icon="pencil" size="sm" />}
                      size="s"
                      onClick={() => setEditingCpmIncrease(true)}
                    />
                  </span>
                </div>
              )}
            </span>
          }
        />
        <RadioButton
          value="set"
          label={
            <div className="flex items-center">
              Set each CPM price individually
              <span
                data-tooltip="top"
                aria-label="CPM cannot be lower than the parent org"
              >
                <Fa
                  icon="info-circle"
                  size={1}
                  className="InfoPopover__popover-icon text-grey-400 focus:ring"
                />
              </span>
            </div>
          }
        />
      </RadioButtonGroup>
      <CollapsibleContainer
        className="ChildOrg__CPM-collapsible mb-8"
        isOpen={cpmChoice === 'set'}
      >
        <TextHeader type={4} className="py-3 normal-case">
          <span className="RightVerticalPipe">Digital products</span>
          <span className="font-light">
            CPM (Cost per Thousand Impressions)
          </span>
        </TextHeader>
        <div className="flex flex-col gap-2">
          {/* Non-political CPM table */}
          {showPoliticalPricing && (
            <TextHeader type={5} className="text-grey-500">
              CPMs
            </TextHeader>
          )}
          <CPMTable
            inputCpms={nonPoliticalCPMs}
            onChange={cpmOnChange}
            isPolitical={false}
            parentCpms={parentOrg.cpms}
          />

          {/* Political CPM table */}
          {showPoliticalPricing && (
            <div className="animate-slidedown">
              <TextHeader type={5} className="text-grey-500">
                Limited Inventory(Political) CPMs
              </TextHeader>
              <CPMTable
                inputCpms={politicalCPMs}
                onChange={cpmOnChange}
                isPolitical
                parentCpms={parentOrg.cpms}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end pt-2">
          <PoliticalToggle
            isChecked={showPoliticalPricing}
            onChange={setShowPoliticalPricing}
          />
        </div>
      </CollapsibleContainer>
      <div className="m-3 flex justify-between">
        <Button type="button" onClick={() => onBack()}>
          Back
        </Button>
        <Button
          className={`Button ${
            !nextIsDisabled ? 'Button--primary ripples' : ''
          }`}
          type="button"
          disabled={nextIsDisabled}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
