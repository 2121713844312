/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3ViantCreativeConfig } from '../models/V3ViantCreativeConfig';
import { V3ViantSegmentConfig } from '../models/V3ViantSegmentConfig';
import { HttpFile } from '../http/http';

export class V3ViantAdelphicConfiguration {
    'internalAdvertiserID'?: string;
    'adelphicAdvertiserID'?: string;
    'advertiserName'?: string;
    'advertiserAgencyID'?: string;
    'internalCampaignID'?: string;
    'adelphicCampaignID'?: string;
    'campaignStartDate'?: string;
    'campaignEndDate'?: string;
    'campaignName'?: string;
    'campaignDomain'?: string;
    'campaignIABCategory'?: Array<string>;
    'campaignMaxBudget'?: number;
    'campaignLifetimeImpressionCap'?: string;
    'internalAdOrderID'?: string;
    'adelphicAdOrderID'?: string;
    'adOrderStartDate'?: string;
    'adOrderEndDate'?: string;
    'adOrderLifetimeBudget'?: number;
    'adOrderOptimizationGoal'?: string;
    'adOrderMaxBid'?: number;
    'adOrderChannelsV2'?: Array<string>;
    'adOrderGoalMode'?: string;
    'adOrderHourlyPacingMode'?: string;
    'adOrderCostOptimizationEnabled'?: boolean;
    'adOrderName'?: string;
    'segments'?: Array<V3ViantSegmentConfig>;
    'creatives'?: Array<V3ViantCreativeConfig>;
    /**
    * Name of the Audience
    */
    'audienceName'?: string;
    /**
    * Target of the Audience
    */
    'audienceTarget'?: string;
    /**
    * Exclude Audience
    */
    'audienceExclude'?: string;
    /**
    * Audience Max Bid
    */
    'audienceMaxBid'?: number;
    /**
    * Audience Lifetime Frequency Cap
    */
    'audienceLifetimeFrequencyCap'?: number;
    /**
    * Audience Frequency Cap
    */
    'audienceFrequencyCap'?: number;
    'adelphicAudienceID'?: string;
    'adOrderLifetimeImpCap'?: string;
    'adelphicParentTaxonomyID'?: string;
    'adOrderStatus'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "internalAdvertiserID",
            "baseName": "InternalAdvertiserID",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicAdvertiserID",
            "baseName": "AdelphicAdvertiserID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "advertiserName",
            "baseName": "AdvertiserName",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserAgencyID",
            "baseName": "AdvertiserAgencyID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "internalCampaignID",
            "baseName": "InternalCampaignID",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicCampaignID",
            "baseName": "AdelphicCampaignID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "campaignStartDate",
            "baseName": "CampaignStartDate",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "campaignEndDate",
            "baseName": "CampaignEndDate",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "campaignName",
            "baseName": "CampaignName",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignDomain",
            "baseName": "CampaignDomain",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignIABCategory",
            "baseName": "CampaignIABCategory",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "campaignMaxBudget",
            "baseName": "CampaignMaxBudget",
            "type": "number",
            "format": "double"
        },
        {
            "name": "campaignLifetimeImpressionCap",
            "baseName": "CampaignLifetimeImpressionCap",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "internalAdOrderID",
            "baseName": "InternalAdOrderID",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicAdOrderID",
            "baseName": "AdelphicAdOrderID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adOrderStartDate",
            "baseName": "AdOrderStartDate",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adOrderEndDate",
            "baseName": "AdOrderEndDate",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adOrderLifetimeBudget",
            "baseName": "AdOrderLifetimeBudget",
            "type": "number",
            "format": "double"
        },
        {
            "name": "adOrderOptimizationGoal",
            "baseName": "AdOrderOptimizationGoal",
            "type": "string",
            "format": ""
        },
        {
            "name": "adOrderMaxBid",
            "baseName": "AdOrderMaxBid",
            "type": "number",
            "format": "double"
        },
        {
            "name": "adOrderChannelsV2",
            "baseName": "AdOrderChannelsV2",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "adOrderGoalMode",
            "baseName": "AdOrderGoalMode",
            "type": "string",
            "format": ""
        },
        {
            "name": "adOrderHourlyPacingMode",
            "baseName": "AdOrderHourlyPacingMode",
            "type": "string",
            "format": ""
        },
        {
            "name": "adOrderCostOptimizationEnabled",
            "baseName": "AdOrderCostOptimizationEnabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "adOrderName",
            "baseName": "AdOrderName",
            "type": "string",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "Segments",
            "type": "Array<V3ViantSegmentConfig>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "Creatives",
            "type": "Array<V3ViantCreativeConfig>",
            "format": ""
        },
        {
            "name": "audienceName",
            "baseName": "AudienceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceTarget",
            "baseName": "AudienceTarget",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceExclude",
            "baseName": "AudienceExclude",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceMaxBid",
            "baseName": "AudienceMaxBid",
            "type": "number",
            "format": "double"
        },
        {
            "name": "audienceLifetimeFrequencyCap",
            "baseName": "AudienceLifetimeFrequencyCap",
            "type": "number",
            "format": "double"
        },
        {
            "name": "audienceFrequencyCap",
            "baseName": "AudienceFrequencyCap",
            "type": "number",
            "format": "double"
        },
        {
            "name": "adelphicAudienceID",
            "baseName": "AdelphicAudienceID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adOrderLifetimeImpCap",
            "baseName": "AdOrderLifetimeImpCap",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adelphicParentTaxonomyID",
            "baseName": "AdelphicParentTaxonomyID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "adOrderStatus",
            "baseName": "AdOrderStatus",
            "type": "string",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return V3ViantAdelphicConfiguration.attributeTypeMap;
    }

    public constructor() {
    }
}

