import { FC, ReactNode } from 'react'
import './MaxHeightContainer.scss'

type MaxHeightContainerType = {
  className?: string
  classNameContent?: string
  classNameFooter?: string
  classNameHeader?: string
  idContent?: string
  idFooter?: string
  idHeader?: string
  header?: ReactNode
  id?: string
  footer?: ReactNode
  fullHeight?: boolean
  children?: ReactNode
}

export const MaxHeightContainer: FC<MaxHeightContainerType> = ({
  className = '',
  classNameContent = '',
  classNameHeader = '',
  classNameFooter = '',
  idContent = '',
  idHeader = '',
  idFooter = '',
  header,
  id = '',
  footer,
  fullHeight = false,
  children,
}) => {
  return (
    <div
      id={id}
      className={`MaxHeightContainer ${className} ${
        fullHeight ? 'MaxHeightContainer--full-height' : ''
      }`}
    >
      {header && (
        <div
          className={`MaxHeightContainer__header ${classNameHeader}`}
          id={idHeader}
        >
          {header}
        </div>
      )}
      <div
        className={`MaxHeightContainer__content ${classNameContent}`}
        id={idContent}
      >
        {children}
      </div>
      {footer && (
        <div
          className={`MaxHeightContainer__footer ${classNameFooter}`}
          id={idFooter}
        >
          {footer}
        </div>
      )}
    </div>
  )
}
