import { useState, useRef, useEffect } from 'react'
import { Checkbox } from '../Checkbox'
import { TableRowType } from '../Table'
import { TableCell } from '../TableCell'
import { Fa } from '../FontAwesome'
import '../../Assets/css/all.css'
import './TableRow.scss'

export const TableRow = <T extends { [key: string]: any }>({
  row,
  columns,
  ExpandableRow,
  onClick,
  selected,
  handleSelectRow,
  getColspan,
}: TableRowType<T>) => {
  const [expanded, setExpanded] = useState(false)
  const [rowWidth, setRowWidth] = useState<number>()

  const colWidth = rowWidth ? `${rowWidth / columns.length}px` : undefined

  const _handleExpand = () => {
    if (ExpandableRow) {
      setExpanded(!expanded)
    }
  }

  const widthRef = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setRowWidth(widthRef.current?.offsetWidth)
    })
    if (widthRef.current) {
      resizeObserver.observe(widthRef.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <>
      <tr
        onClick={() => onClick && onClick(row)}
        onKeyDown={(e) => {
          if ((e.key === 'Enter' || e.key === ' ') && onClick) onClick(row)
        }}
        className={`TableRow ${
          selected ? ' TableRow--selected' : 'text-tint-gray-900'
        } ${onClick ? 'TableRow--has-on-click' : ''}`}
        ref={widthRef}
        tabIndex={0}
      >
        {handleSelectRow && (
          // Stops onClick from firing when clicking checkbox
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <td
            className="TableRow__checkbox"
            onClick={(e) => {
              if (onClick) {
                e.stopPropagation()
              }
            }}
            onKeyDown={(e) => {
              e.stopPropagation()
            }}
          >
            <Checkbox
              checked={selected || false}
              onChange={(checked) => handleSelectRow(row, checked)}
            />
          </td>
        )}
        {ExpandableRow && (
          <td className="TableRow__expand">
            {ExpandableRow(row) && (
              <button
                className="TableRow__caret"
                type="button"
                onClick={() => _handleExpand()}
              >
                <Fa icon={expanded ? 'angle-down' : 'angle-right'} size={1} />
              </button>
            )}
          </td>
        )}
        {columns.map((col, index) => {
          return (
            <TableCell
              key={JSON.stringify(row) + index}
              column={col}
              row={row}
              colWidth={colWidth}
            />
          )
        })}
      </tr>
      {expanded && (
        <tr className="TableRow__expansion">
          <td colSpan={getColspan()} className="TableExpandedRow__column">
            {ExpandableRow && ExpandableRow(row)}
          </td>
        </tr>
      )}
    </>
  )
}
