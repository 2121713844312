/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* response to Download a TOS file.
*/
export class V1TOSAgreement {
    /**
    * The id of the org
    */
    'orgId'?: string;
    /**
    * The id of the user who signed the file.
    */
    'userId'?: string;
    /**
    * The name of the file.
    */
    'filename'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1TOSAgreement.attributeTypeMap;
    }

    public constructor() {
    }
}

