import { RecoilCircle } from 'Components/Canvas/RecoilCircle'
import { RecoilRect } from 'Components/Canvas/RecoilRect'
import { placeImage, rectWithBorderRadiusClip } from 'Helpers'
import React, { useEffect, useState } from 'react'
import { Group, Image as KonvaImage } from 'react-konva'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  blockBorderRadiusAtom,
  blockShapeTypeAtom,
  headShotImageAtom,
  headShotImageScaleAtom,
  mainDimensionsBlockAtom,
} from 'State'
import { BlockConfigType, DimensionsType } from 'Typings'
import useImage from 'use-image'

export const ImageFrame: React.FC<{
  id: string
  width: number
  height: number
  isOnMobile?: boolean
}> = ({ id, width, height, isOnMobile = false }) => {
  const setMainDimensions = useSetRecoilState(mainDimensionsBlockAtom(id))
  const [imageDimensions, setImageDimensions] = useState<DimensionsType>()

  const frameConfig: BlockConfigType = {
    id,
    kind: 'imageFrame',
    key: 'frame',
  }
  const borderRadius = useRecoilValue(blockBorderRadiusAtom(frameConfig))
  const shapeType = useRecoilValue(blockShapeTypeAtom(frameConfig))
  const image = useRecoilValue(headShotImageAtom(id))
  const scale = useRecoilValue(headShotImageScaleAtom(id))

  const [img] = useImage(image, 'anonymous')

  useEffect(() => {
    const createImageDimensions = () => {
      const newImage = new Image()
      newImage.onload = () => {
        setImageDimensions({
          height: newImage.height,
          width: newImage.width,
        })
      }
      newImage.src = image
    }
    createImageDimensions()
  }, [image])

  const diameter = width < height ? width : height
  useEffect(() => {
    setMainDimensions({
      width: diameter,
      height: diameter,
    })
  }, [width, height])

  const radius = diameter / 2

  const imageDetails =
    imageDimensions &&
    placeImage({ width: diameter, height: diameter }, imageDimensions, scale)

  if (!imageDetails) return null

  return (
    <>
      {shapeType === 'circle' && (
        <RecoilCircle
          config={frameConfig}
          radius={radius}
          x={radius}
          y={radius}
          fill="white"
        />
      )}
      {shapeType === 'square' && (
        <RecoilRect
          config={frameConfig}
          width={radius * 2}
          height={radius * 2}
          fill="white"
        />
      )}
      <Group
        name="main"
        width={diameter}
        height={diameter}
        clipFunc={(ctx: CanvasRenderingContext2D) => {
          if (shapeType === 'circle') ctx.arc(radius, radius, radius, 0, 360)
          if (shapeType === 'square')
            rectWithBorderRadiusClip(
              ctx,
              0,
              0,
              diameter,
              diameter,
              borderRadius,
            )
        }}
      >
        <KonvaImage
          image={img}
          x={imageDetails.x}
          y={imageDetails.y}
          width={imageDetails.width}
          height={imageDetails.height}
        />
      </Group>
      {shapeType === 'circle' && (
        <RecoilCircle
          config={frameConfig}
          radius={radius}
          x={radius}
          y={radius}
          shadowEnabled={false}
        />
      )}
      {shapeType === 'square' && (
        <RecoilRect
          config={frameConfig}
          width={radius * 2}
          height={radius * 2}
          shadowEnabled={false}
        />
      )}
    </>
  )
}
