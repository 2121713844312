/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetorchestratorv2Column } from '../models/Targetorchestratorv2Column';
import { HttpFile } from '../http/http';

/**
* The info about a file's location.
*/
export class Targetorchestratorv2Location {
    /**
    * The ID of the Location.
    */
    'id'?: string;
    /**
    * The name of the file at this location.
    */
    'filename'?: string;
    /**
    * The ID of the Target to which this file is associated.
    */
    'targetId'?: string;
    /**
    * The time the location was created.
    */
    'createTime'?: Date;
    /**
    * The type of file. (For example, it could be a source file.)
    */
    'type'?: string;
    /**
    * An array of Column Objects that explains what is in each column in the file.
    */
    'columns'?: Array<Targetorchestratorv2Column>;
    /**
    * The s3 bucket that holds the file.
    */
    'bucket'?: string;
    /**
    * The s3 key where the file is located.
    */
    'key'?: string;
    /**
    * Any extra info about this file location.
    */
    'metadata'?: string;
    /**
    * The version of the file.
    */
    'version'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "columns",
            "baseName": "columns",
            "type": "Array<Targetorchestratorv2Column>",
            "format": ""
        },
        {
            "name": "bucket",
            "baseName": "bucket",
            "type": "string",
            "format": ""
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string",
            "format": ""
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "version",
            "baseName": "version",
            "type": "number",
            "format": "uuid"
        }    ];

    static getAttributeTypeMap() {
        return Targetorchestratorv2Location.attributeTypeMap;
    }

    public constructor() {
    }
}

