import { PieChart } from './PieChart'
import './DonutChartContainer.scss'

export type DonutChartDataType = {
  total: {
    title: string
    value: number
    color: string
  }
  used: {
    title: string
    value: number
    color: string
  }
}
export const DonutChartContainer = ({
  className = '',
  data,
  isMoney,
  isEmpty,
}: {
  className?: string
  data: DonutChartDataType
  isMoney: boolean
  isEmpty: boolean
}) => {
  return (
    <div
      className={`DonutChartContainer DonutChart__text-chart flex w-full items-center justify-center gap-8 ${className}`}
    >
      <div className="DonutChart__text">
        <div className="DonutChart__text--total flex flex-col gap-1">
          <div className="topStatData DonutChart__text--header text-3xl font-bold leading-none">
            {isMoney && '$'}
            {data.total.value &&
              data.total.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
          <div className="topStatLabel font-semibold">
            {data && data.total.title}
          </div>
        </div>
        <div className="data-container DonutChart__text--details text-l">
          <div className="DonutChart__text--key served">
            <div className="DonutChart__text--key data">
              <span
                className="colorIcon-data DonutChart__text--key--color"
                style={{ background: data && data.total.color }}
              />
              <span className="label">{data && data.used.title}:&nbsp;</span>
              <span className="value-data served font-bold">
                {isMoney && '$'}
                {data &&
                  data.used.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </span>
            </div>
          </div>
          <div className="DonutChart__text--key remaining">
            <div className="DonutChart__text--key data">
              <span
                className="colorIcon-data DonutChart__text--key--color"
                style={{ background: data && data.used.color }}
              />
              <span className="label">Remaining:&nbsp;</span>
              <span className="value-data remaining font-bold">
                {isMoney && '$'}
                {data &&
                  (data.total.value - data.used.value >= 0
                    ? (data.total.value - data.used.value)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '0')}
              </span>
            </div>
          </div>
        </div>
      </div>

      {isEmpty ? (
        <div className="Empty__chart">Not available</div>
      ) : (
        <PieChart
          data={[
            {
              title: data && data.used.title,
              value: data && Number(data.used.value.toFixed(2)),
              color: data && data.total.color,
            },
            {
              title: 'Remaining',
              value: data && data.total.value - data.used.value,
              color: data && data.used.color,
            },
          ]}
          isMoney={isMoney}
        />
      )}
    </div>
  )
}
