import {
  useEffect,
  CSSProperties,
  FC,
  Ref,
  ReactNode,
  MutableRefObject,
} from 'react'
import { createPortal } from 'react-dom'
import { StandardLonghandProperties } from 'csstype'
import classNames from 'classnames'
import { Button } from '../Button'
import { Fa } from '../FontAwesome'
import '../../Assets/css/all.css'
import './Modal.scss'

type ModalProps = {
  offClick?: () => void
  noBackground?: boolean
  noShade?: boolean
  noStyles?: boolean
  positionFromTop?: StandardLonghandProperties['marginTop']
  className?: string
  classNameContent?: string
  classNameWrap?: string
  scrollable?: boolean
  style?: CSSProperties
  header?: ReactNode
  modalContentRef?: Ref<HTMLDivElement> | MutableRefObject<HTMLDivElement>
  modalRef?: MutableRefObject<null>
  children?: ReactNode
  attachTo?: HTMLElement
}

export const Modal: FC<ModalProps> = ({
  offClick,
  children,
  noBackground = false,
  noShade = false,
  noStyles = false,
  positionFromTop,
  className = '',
  classNameContent = '',
  classNameWrap = '',
  scrollable = true,
  style = {},
  header,
  modalContentRef,
  modalRef,
  attachTo = document.getElementById('MainAppContainer') ?? document.body,
}) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return createPortal(
    <div className={`Modal ${classNameWrap}`} ref={modalRef}>
      {!noShade && (
        <div role="presentation" className="Modal__shade" onClick={offClick} />
      )}
      <div
        role="dialog"
        className={classNames('Modal__content', className, {
          'Modal__content--has-no-background': noBackground,
        })}
        style={
          !noStyles
            ? {
                maxHeight: positionFromTop
                  ? `calc(100vh - 1rem - ${positionFromTop})`
                  : 'calc(100vh - 1rem)',
                alignSelf: positionFromTop ? 'start' : undefined,
                marginTop: positionFromTop,
                ...style,
              }
            : {}
        }
      >
        {header && (
          <div className="flex justify-between gap-2 Modal__header flex-end">
            {header}
            <Button
              onClick={offClick}
              iconOnly={
                <span data-tooltip="left" aria-label="Close box">
                  <Fa icon="times" size={1} />
                </span>
              }
              className="Modal__close"
            />
          </div>
        )}
        <div
          className={`${
            scrollable
              ? 'Modal__scrollable-content'
              : 'Modal__nonscrolling-content'
          } relative ${classNameContent}`}
          ref={modalContentRef}
        >
          {children}
        </div>
      </div>
    </div>,
    attachTo || document.getElementById('MainAppContainer') || document.body,
  )
}
