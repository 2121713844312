import { Campaignservicev1Campaign } from 'next-gen-sdk'

export const Campaigns: Campaignservicev1Campaign[] = [
  {
    id: 'dfe4df15-c343-456a-904d-69f114a2c585',
    createTime: new Date('2021-11-18T03:02:10.544Z'),
    name: 'Donuts forever',
    refId: '',
    orgId: 'cfa59e04-4408-4c87-a421-5c8e75437576',
    startTime: new Date('2021-11-18T02:59:31.958Z'),
    endTime: new Date('2021-11-18T02:59:31.958Z'),
  },
  {
    id: '875b7c3b-8680-4ffb-af4d-3c1dd57abc33',
    createTime: new Date('2021-11-18T04:03:47.391Z'),
    name: 'Donuts of Fury',
    refId: '',
    orgId: 'cfa59e04-4408-4c87-a421-5c8e75437576',
    startTime: new Date('2021-12-18T04:02:13.693Z'),
    endTime: new Date('2021-12-18T04:02:13.693Z'),
  },
  {
    id: 'c8c30977-e617-4219-ae8d-97585b20cecb',
    createTime: new Date('2021-11-18T04:06:00.662Z'),
    name: 'Donuts are Bad?',
    refId: '',
    orgId: 'cfa59e04-4408-4c87-a421-5c8e75437576',
    startTime: new Date('2021-11-18T04:05:20.807Z'),
    endTime: new Date('2021-12-18T04:05:20.807Z'),
  },
  {
    id: '6811ff30-2fba-4b22-9b01-4ffa80e55e79',
    createTime: new Date('2021-11-19T20:19:03.175Z'),
    name: 'Donuts are Great!',
    refId: '',
    orgId: '6a197534-131c-4fc6-aecc-b7846087f8ec',
    startTime: new Date('2021-11-19T20:02:09.307Z'),
    endTime: new Date('2021-12-19T20:02:09.307Z'),
  },
]

export const ApqsCampaigns: Campaignservicev1Campaign[] = Campaigns.map(
  (campaign) => {
    return { ...campaign, id: undefined, campaignId: campaign.id }
  },
)
