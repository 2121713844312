/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1Deployment } from '../models/Targetjobservicev1Deployment';
import { Targetjobservicev1Note } from '../models/Targetjobservicev1Note';
import { Targetjobservicev1Timeframe } from '../models/Targetjobservicev1Timeframe';
import { V1DataSource } from '../models/V1DataSource';
import { V1Job } from '../models/V1Job';
import { V1JobStatus } from '../models/V1JobStatus';
import { V1JobSubType } from '../models/V1JobSubType';
import { V1JobType } from '../models/V1JobType';
import { V1ProductType } from '../models/V1ProductType';
import { HttpFile } from '../http/http';

/**
* Job holds info about a specific Job.
*/
export class Job {
    /**
    * The Name that is associated with the Job.
    */
    'name'?: string;
    /**
    * The Org's ID that is associated with the Job.
    */
    'orgId'?: string;
    /**
    * The ID of the Target this job is associated with.
    */
    'targetId'?: string;
    /**
    * This ID only exists if the job is part of a pipeline job. This is the ID of the parent pipeline job.
    */
    'jobId'?: string;
    /**
    * The time the Job was created.
    */
    'created'?: Date;
    /**
    * The time the Job was updated.
    */
    'updateTime'?: Date;
    /**
    * The time the Job was deleted.
    */
    'deleteTime'?: Date;
    /**
    * The time the Job was archived.
    */
    'archiveTime'?: Date;
    /**
    * The time the Job was expired.
    */
    'expireTime'?: Date;
    /**
    * The time the Job was purged.
    */
    'purgeTime'?: Date;
    'status'?: V1JobStatus;
    'type'?: V1JobType;
    'subType'?: V1JobSubType;
    'productType'?: V1ProductType;
    /**
    * The array of Jobs that will be ran in a pipeline.
    */
    'jobs'?: Array<V1Job>;
    'orderLineIds'?: Array<string>;
    /**
    * An ID that is generated when a Job is ran.
    */
    'instanceId'?: string;
    /**
    * An array of Bytes that holds result info about the Job. It holds the resulting file locations and stats about the Job, like the matches and match rate.
    */
    'result'?: string;
    /**
    * The limit you want to set on the number of matches.
    */
    'matchCap'?: number;
    /**
    * The Code associated with a Segment.
    */
    'code'?: number;
    /**
    * The start and end times for a venue replay job.
    */
    'timeframes'?: Array<Targetjobservicev1Timeframe>;
    'dataSource'?: V1DataSource;
    /**
    * The the folder where the job is stored.
    */
    'folder'?: string;
    'hidden'?: boolean;
    'locked'?: boolean;
    /**
    * The deployments for the job.
    */
    'deployments'?: Array<Targetjobservicev1Deployment>;
    /**
    * The notes for the job.
    */
    'notes'?: Array<Targetjobservicev1Note>;
    'initialMatches'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expireTime",
            "baseName": "expire_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "purgeTime",
            "baseName": "purge_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1JobStatus",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1JobType",
            "format": ""
        },
        {
            "name": "subType",
            "baseName": "sub_type",
            "type": "V1JobSubType",
            "format": ""
        },
        {
            "name": "productType",
            "baseName": "product_type",
            "type": "V1ProductType",
            "format": ""
        },
        {
            "name": "jobs",
            "baseName": "jobs",
            "type": "Array<V1Job>",
            "format": ""
        },
        {
            "name": "orderLineIds",
            "baseName": "order_line_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "instanceId",
            "baseName": "instance_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "result",
            "baseName": "result",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "matchCap",
            "baseName": "match_cap",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "timeframes",
            "baseName": "timeframes",
            "type": "Array<Targetjobservicev1Timeframe>",
            "format": ""
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "folder",
            "baseName": "folder",
            "type": "string",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Targetjobservicev1Deployment>",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<Targetjobservicev1Note>",
            "format": ""
        },
        {
            "name": "initialMatches",
            "baseName": "initial_matches",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return Job.attributeTypeMap;
    }

    public constructor() {
    }
}

