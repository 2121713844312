/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1CampaignSearch } from '../models/V1CampaignSearch';
import { V1CreativeSearch } from '../models/V1CreativeSearch';
import { V1OrderLineSearch } from '../models/V1OrderLineSearch';
import { V1OrgData } from '../models/V1OrgData';
import { V1TargetJobSearch } from '../models/V1TargetJobSearch';
import { HttpFile } from '../http/http';

/**
* Information returned from the global search
*/
export class V1GlobalSearchResponse {
    'orgs'?: Array<V1OrgData>;
    'audiences'?: Array<V1TargetJobSearch>;
    'creatives'?: Array<V1CreativeSearch>;
    'orderLines'?: Array<V1OrderLineSearch>;
    'campaigns'?: Array<V1CampaignSearch>;
    'totalCount'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgs",
            "baseName": "orgs",
            "type": "Array<V1OrgData>",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<V1TargetJobSearch>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<V1CreativeSearch>",
            "format": ""
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<V1OrderLineSearch>",
            "format": ""
        },
        {
            "name": "campaigns",
            "baseName": "campaigns",
            "type": "Array<V1CampaignSearch>",
            "format": ""
        },
        {
            "name": "totalCount",
            "baseName": "total_count",
            "type": "string",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return V1GlobalSearchResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

