/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3Values } from '../models/V3Values';
import { HttpFile } from '../http/http';

/**
* See [Conditions](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html#SplitsService-Conditions) in Xandr's documentation.
*/
export class V3Condition {
    /**
    *  The field of the condition.
    */
    'field'?: string;
    /**
    * The operator of the condition.
    */
    'operator'?: string;
    /**
    *  The value of the condition.
    */
    'value'?: Array<V3Values>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "field",
            "baseName": "field",
            "type": "string",
            "format": ""
        },
        {
            "name": "operator",
            "baseName": "operator",
            "type": "string",
            "format": ""
        },
        {
            "name": "value",
            "baseName": "value",
            "type": "Array<V3Values>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3Condition.attributeTypeMap;
    }

    public constructor() {
    }
}

