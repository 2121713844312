/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* The type of deal. For sellers, a deal can be an open auction or a private auction. [deal types](https://docs.xandr.com/bundle/xandr-api/page/deal-service.html#DealService-Type).
*/
export class V3DealType {
    /**
    * The ID representing the type of deal.
    */
    'id'?: number;
    /**
    * The name of the deal.
    */
    'name'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3DealType.attributeTypeMap;
    }

    public constructor() {
    }
}

