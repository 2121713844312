/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3LegacyCampaign } from '../models/V3LegacyCampaign';
import { V3LineItem } from '../models/V3LineItem';
import { V3NonSeamlessInsertionOrder } from '../models/V3NonSeamlessInsertionOrder';
import { V3Profile } from '../models/V3Profile';
import { HttpFile } from '../http/http';

/**
* Configuration for a Console deploy destination.
*/
export class V3ConsoleCampaign {
    /**
    * Keeps track of what timezone the time is in
    */
    'timezone'?: string;
    /**
    * User comments pertaining to the campaign.
    */
    'comments'?: string;
    /**
    * The URL the user will be redirected to if the creative is clicked.
    */
    'clickUrl'?: string;
    'profile'?: V3Profile;
    'insertionOrder'?: V3NonSeamlessInsertionOrder;
    'lineItem'?: V3LineItem;
    'campaign'?: V3LegacyCampaign;
    /**
    * The ID of the campaign.
    */
    'id'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "timezone",
            "baseName": "timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "comments",
            "baseName": "comments",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickUrl",
            "baseName": "click_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "profile",
            "baseName": "profile",
            "type": "V3Profile",
            "format": ""
        },
        {
            "name": "insertionOrder",
            "baseName": "insertion_order",
            "type": "V3NonSeamlessInsertionOrder",
            "format": ""
        },
        {
            "name": "lineItem",
            "baseName": "line_item",
            "type": "V3LineItem",
            "format": ""
        },
        {
            "name": "campaign",
            "baseName": "campaign",
            "type": "V3LegacyCampaign",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3ConsoleCampaign.attributeTypeMap;
    }

    public constructor() {
    }
}

