/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1AuditConditions {
    'creativeExists'?: boolean;
    'audienceExists'?: boolean;
    'audienceMinimumMatchCountMet'?: boolean;
    'clickThroughUrlExists'?: boolean;
    'costExists'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "creativeExists",
            "baseName": "creative_exists",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "audienceExists",
            "baseName": "audience_exists",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "audienceMinimumMatchCountMet",
            "baseName": "audience_minimum_match_count_met",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "clickThroughUrlExists",
            "baseName": "click_through_url_exists",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "costExists",
            "baseName": "cost_exists",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1AuditConditions.attributeTypeMap;
    }

    public constructor() {
    }
}

