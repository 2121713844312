/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Billingservicev1CommissionGroup } from '../models/Billingservicev1CommissionGroup';
import { HttpFile } from '../http/http';

/**
* ListCommissionGroupsResponse
*/
export class V1ListCommissionGroupsResponse {
    /**
    * List of commission groups
    */
    'commissionGroups'?: Array<Billingservicev1CommissionGroup>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "commissionGroups",
            "baseName": "commission_groups",
            "type": "Array<Billingservicev1CommissionGroup>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ListCommissionGroupsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

