import React, { MouseEvent, KeyboardEvent } from 'react'
import classNames from 'classnames'
import { truncateMiddle } from 'Helpers'
import './LinkButton.scss'

export const LinkButton = ({
  text,
  className = '',
  onClick,
  size = 'm',
  onDarkBackground = false,
  truncate = false,
  disabled = false,
  onBlur,
  dataToolTip = 'top',
}: {
  text: string
  className?: string
  onClick: (e: MouseEvent | KeyboardEvent) => void
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'
  onDarkBackground?: boolean
  truncate?: boolean
  disabled?: boolean
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  dataToolTip?: string
}) => {
  const truncatedText = truncateMiddle(text, 35)
  return (
    <div
      className={classNames(
        'LinkButton',
        className,
        {
          'text-xs': size === 'xs',
          'text-s': size === 's',
          'text-m': size === 'm',
          'text-l': size === 'l',
          'text-xl': size === 'xl',
          'text-2xl': size === 'xxl',
          'text-3xl': size === 'xxxl',
        },
        {
          'LinkButton--on-dark': onDarkBackground,
          'LinkButton--truncate': truncate,
          'LinkButton--disabled': disabled,
        },
      )}
      role="link"
      tabIndex={0}
      onClick={!disabled ? onClick : undefined}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') onClick(e)
      }}
      data-tooltip={dataToolTip}
      aria-label={`Link to "${text}"`}
      onBlur={onBlur}
    >
      {truncate ? truncatedText : text}
    </div>
  )
}
