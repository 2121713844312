/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3CreativeID } from '../models/Deployservicev3CreativeID';
import { V3Condition } from '../models/V3Condition';
import { HttpFile } from '../http/http';

/**
* See [Splits](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html) in Xandr's documentation.
*/
export class V3Split {
    /**
    * The percentage of the line item budget assigned to this split. The allocation for each split should be between 0 and 100. The sum of the allocation percentages for all splits in a line item must equal 100.
    */
    'allocationPercent'?: number;
    /**
    * Specifies how to handle conflicts between the split allocation goal and the line item delivery goal.
    */
    'allocationStrategy'?: string;
    /**
    * The number used to modify the bid for this split. A bid modifier can only be applied to a split when the line item's booked revenue type is CPM and optimization is not enabled. The value of the bid modifier will be multiplied by the CPM booked revenue to serve as the maximum CPM that the split can bid.
    */
    'bidModifier'?: number;
    /**
    * The name you assign the split.
    */
    'name'?: string;
    /**
    * Specifies whether this is the default split, which is used when an impression does not meet the targeting requirements for any other splits. Every line item must have one and only one default split. The default split must be named 'Default' or 'default' and cannot have any conditions. Possible values are 'true' and 'false'.
    */
    'isDefault'?: boolean;
    /**
    * The targeting conditions for the split. A condition is specified by an array containing a field, an operator, and a value.
    */
    'conditions'?: Array<V3Condition>;
    /**
    * The priority of this split in the budget_splitters array. (In the UI, this field is referred to as 'Priority'.)
    */
    'order'?: number;
    /**
    * Optional. Any creative macros that are added to the served creative.
    */
    'creativeMacros'?: Array<string>;
    /**
    * Optional. The IDs of the creatives to be served on this split.
    */
    'creatives'?: Array<Deployservicev3CreativeID>;
    /**
    * The expected value of the impression for this split. An expected value must be included for each split when the line item's booked revenue type is cost plus or dCPM and optimization is not enabled. The expected value serves as the maximum CPM that the split can bid.
    */
    'expectedValue'?: number;
    /**
    * Specifies the status of a split.
    */
    'active'?: boolean;
    /**
    * ID of the Split.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "allocationPercent",
            "baseName": "allocation_percent",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "allocationStrategy",
            "baseName": "allocation_strategy",
            "type": "string",
            "format": ""
        },
        {
            "name": "bidModifier",
            "baseName": "bid_modifier",
            "type": "number",
            "format": "double"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "isDefault",
            "baseName": "is_default",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "conditions",
            "baseName": "conditions",
            "type": "Array<V3Condition>",
            "format": ""
        },
        {
            "name": "order",
            "baseName": "order",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "creativeMacros",
            "baseName": "creative_macros",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Deployservicev3CreativeID>",
            "format": ""
        },
        {
            "name": "expectedValue",
            "baseName": "expected_value",
            "type": "number",
            "format": "double"
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3Split.attributeTypeMap;
    }

    public constructor() {
    }
}

