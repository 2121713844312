import { ReactNode, FunctionComponent } from 'react'
import classNames from 'classnames'
import { Button } from '../Button'
import { Text } from '../Text'
import './Pagination.scss'
import '../../Assets/css/all.css'
import { Fa } from '../FontAwesome'

type PaginationType = {
  onChange?: ({ page, pageSize }: { page: number; pageSize: number }) => void
  page: number
  pageSize: number
  totalItems: number
  children?: ReactNode
  className?: string
  showPageInfo?: boolean
}

export const Pagination: FunctionComponent<PaginationType> = ({
  page,
  pageSize,
  totalItems,
  onChange,
  children,
  className,
  showPageInfo = true,
}) => {
  const isLastPage = () => pageSize * page >= totalItems

  const itemRangeStart = page * pageSize - pageSize + 1

  const itemRangeEnd = isLastPage() ? totalItems : page * pageSize

  const handleChangePage = (newPage: number) => {
    if (onChange) onChange({ page: newPage, pageSize })
  }

  return (
    <div className={classNames('Pagination', className)}>
      {children || null}
      <Button
        onClick={() => handleChangePage(page - 1)}
        iconOnly={<Fa size={1} icon="caret-left" />}
        disabled={page < 2}
      />
      {showPageInfo && (
        <Text kind="subdued" className="whitespace-nowrap mx-2">
          {`${itemRangeStart} - ${itemRangeEnd} of ${totalItems}`}
        </Text>
      )}
      <Button
        onClick={() => handleChangePage(page + 1)}
        iconOnly={<Fa size={1} icon="caret-right" />}
        disabled={isLastPage()}
      />
    </div>
  )
}
