/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3BrowserTarget } from '../models/V3BrowserTarget';
import { V3CountryTarget } from '../models/V3CountryTarget';
import { V3DaypartTarget } from '../models/V3DaypartTarget';
import { V3DealList } from '../models/V3DealList';
import { V3DomainListTarget } from '../models/V3DomainListTarget';
import { V3EngagementRateTarget } from '../models/V3EngagementRateTarget';
import { V3InventoryAttributeTarget } from '../models/V3InventoryAttributeTarget';
import { V3InventoryListTarget } from '../models/V3InventoryListTarget';
import { V3LanguageTarget } from '../models/V3LanguageTarget';
import { V3PositionTargets } from '../models/V3PositionTargets';
import { V3RegionTarget } from '../models/V3RegionTarget';
import { V3SegmentGroupTarget } from '../models/V3SegmentGroupTarget';
import { HttpFile } from '../http/http';

/**
* Profile is a set of targeting configurations, see [Profile Service](https://docs.xandr.com/bundle/xandr-api/page/profile-service.html) for more information on the fields below.
*/
export class V3Profile {
    'allowUnaudited'?: boolean;
    'trust'?: string;
    'sessionFreqType'?: string;
    'daypartTimezone'?: string;
    'querystringBooleanOperator'?: string;
    'requireCookieForFreqCap'?: boolean;
    'useOperatingSystemExtendedTargeting'?: boolean;
    'operatingSystemAction'?: string;
    'operatingSystemFamilyAction'?: string;
    'domainAction'?: string;
    'venueAction'?: string;
    'deviceModelAction'?: string;
    'languageAction'?: string;
    'screenSizeAction'?: string;
    'cityAction'?: string;
    'regionAction'?: string;
    'nonAuditedUrlAction'?: string;
    'querystringAction'?: string;
    'carrierAction'?: string;
    'positionTargets'?: V3PositionTargets;
    /**
    * Enable or disable the use of Browser Targets.
    */
    'browserAction'?: string;
    /**
    * Browsers that will be targeted.
    */
    'browserTargets'?: Array<V3BrowserTarget>;
    /**
    * Enable or disable the use of Country Targets.
    */
    'countryAction'?: string;
    /**
    * Countries that will be targeted.
    */
    'countryTargets'?: Array<V3CountryTarget>;
    /**
    * Enable or disable Device Type Targets.
    */
    'deviceTypeAction'?: string;
    /**
    * Device types that will be targeted.
    */
    'deviceTypeTargets'?: Array<string>;
    /**
    * The deal list IDs to be targeted by this profile.
    */
    'dealListTargets'?: Array<V3DealList>;
    /**
    * Enable or disable Domain List Targets.
    */
    'domainListAction'?: string;
    /**
    * Domain Lists that will be targeted.
    */
    'domainListTargets'?: Array<V3DomainListTarget>;
    /**
    * Inventory Lists that will be targeted.
    */
    'inventoryListTargets'?: Array<V3InventoryListTarget>;
    /**
    * Enable or disable Inventory Targets.
    */
    'inventoryAction'?: string;
    /**
    * If true, the profile will allow inventory that has the sensitive attributes included in inventory_attribute_targets.
    */
    'inventoryAttributeTargets'?: Array<V3InventoryAttributeTarget>;
    /**
    * Supply types are web, mobile_web, and mobile_app. Possible values: include or exclude. If this field is set to include, only inventory of types included in supply_type_targets will be targeted. If exclude, only inventory not in supply_type_targets will be targeted.
    */
    'supplyTypeAction'?: string;
    /**
    * The type(s) of supply to either include in or exclude from targeting, as defined by the supply_type_action field. Possible values: web, mobile_web and mobile_app.
    */
    'supplyTypeTargets'?: Array<string>;
    /**
    * If true, the profile will allow inventory that has the sensitive attributes included in inventory_attribute_targets.
    */
    'useInventoryAttributeTargets'?: boolean;
    /**
    * The intended audience targets. Possible values: general, children, young_adult, or mature. Note that you can only choose to include (not exclude) intended audience targets.
    */
    'intendedAudienceTargets'?: Array<string>;
    /**
    * If using segment_targets, this defines the Boolean logic between the segments specified. If using segment_group_targets, this defines the Boolean logic between the segment groups (the Boolean logic between segments in a group is defined directly in the segment_group_targets array). Possible values: 'and' or 'or'.
    */
    'segmentBooleanOperator'?: string;
    /**
    * The segment groups to target. Whereas the segment_targets array allows you to define Boolean logic between individual segments, this array allows you to establish groups of segments, defining Boolean logic between the groups as well as between the segments within each group. You define the Boolean logic between groups with the segment_boolean_operator field outside of the array; you define the Boolean logic between segments in a group with the boolean_operator field within the group object.
    */
    'segmentGroupTargets'?: Array<V3SegmentGroupTarget>;
    /**
    * The day parts during which to serve the campaign.
    */
    'daypartTargets'?: Array<V3DaypartTarget>;
    /**
    * ID of the profile.
    */
    'id'?: number;
    'languageTargets'?: Array<V3LanguageTarget>;
    'regionTargets'?: Array<V3RegionTarget>;
    'engagementRateTargets'?: Array<V3EngagementRateTarget>;
    /**
    * Whether to include or exclude deals defined in deal_targets and deal lists defined in deal_list_targets in campaign and/or line item targeting. When set to true, deals and deal lists will be included.
    */
    'dealActionInclude'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "allowUnaudited",
            "baseName": "allow_unaudited",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "trust",
            "baseName": "trust",
            "type": "string",
            "format": ""
        },
        {
            "name": "sessionFreqType",
            "baseName": "session_freq_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "daypartTimezone",
            "baseName": "daypart_timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "querystringBooleanOperator",
            "baseName": "querystring_boolean_operator",
            "type": "string",
            "format": ""
        },
        {
            "name": "requireCookieForFreqCap",
            "baseName": "require_cookie_for_freq_cap",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "useOperatingSystemExtendedTargeting",
            "baseName": "use_operating_system_extended_targeting",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "operatingSystemAction",
            "baseName": "operating_system_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "operatingSystemFamilyAction",
            "baseName": "operating_system_family_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "domainAction",
            "baseName": "domain_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "venueAction",
            "baseName": "venue_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "deviceModelAction",
            "baseName": "device_model_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "languageAction",
            "baseName": "language_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "screenSizeAction",
            "baseName": "screen_size_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "cityAction",
            "baseName": "city_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "regionAction",
            "baseName": "region_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "nonAuditedUrlAction",
            "baseName": "non_audited_url_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "querystringAction",
            "baseName": "querystring_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "carrierAction",
            "baseName": "carrier_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "positionTargets",
            "baseName": "position_targets",
            "type": "V3PositionTargets",
            "format": ""
        },
        {
            "name": "browserAction",
            "baseName": "browser_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "browserTargets",
            "baseName": "browser_targets",
            "type": "Array<V3BrowserTarget>",
            "format": ""
        },
        {
            "name": "countryAction",
            "baseName": "country_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "countryTargets",
            "baseName": "country_targets",
            "type": "Array<V3CountryTarget>",
            "format": ""
        },
        {
            "name": "deviceTypeAction",
            "baseName": "device_type_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "deviceTypeTargets",
            "baseName": "device_type_targets",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "dealListTargets",
            "baseName": "deal_list_targets",
            "type": "Array<V3DealList>",
            "format": ""
        },
        {
            "name": "domainListAction",
            "baseName": "domain_list_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "domainListTargets",
            "baseName": "domain_list_targets",
            "type": "Array<V3DomainListTarget>",
            "format": ""
        },
        {
            "name": "inventoryListTargets",
            "baseName": "inventory_list_targets",
            "type": "Array<V3InventoryListTarget>",
            "format": ""
        },
        {
            "name": "inventoryAction",
            "baseName": "inventory_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "inventoryAttributeTargets",
            "baseName": "inventory_attribute_targets",
            "type": "Array<V3InventoryAttributeTarget>",
            "format": ""
        },
        {
            "name": "supplyTypeAction",
            "baseName": "supply_type_action",
            "type": "string",
            "format": ""
        },
        {
            "name": "supplyTypeTargets",
            "baseName": "supply_type_targets",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "useInventoryAttributeTargets",
            "baseName": "use_inventory_attribute_targets",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "intendedAudienceTargets",
            "baseName": "intended_audience_targets",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "segmentBooleanOperator",
            "baseName": "segment_boolean_operator",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentGroupTargets",
            "baseName": "segment_group_targets",
            "type": "Array<V3SegmentGroupTarget>",
            "format": ""
        },
        {
            "name": "daypartTargets",
            "baseName": "daypart_targets",
            "type": "Array<V3DaypartTarget>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "languageTargets",
            "baseName": "language_targets",
            "type": "Array<V3LanguageTarget>",
            "format": ""
        },
        {
            "name": "regionTargets",
            "baseName": "region_targets",
            "type": "Array<V3RegionTarget>",
            "format": ""
        },
        {
            "name": "engagementRateTargets",
            "baseName": "engagement_rate_targets",
            "type": "Array<V3EngagementRateTarget>",
            "format": ""
        },
        {
            "name": "dealActionInclude",
            "baseName": "deal_action_include",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3Profile.attributeTypeMap;
    }

    public constructor() {
    }
}

