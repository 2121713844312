import { useRef, useState } from 'react'
import {
  HeaderItem,
  HeaderDropdown,
  Modal,
  FormItem,
  TextInput,
  Text,
  Spacer,
  FlexBox,
  Button,
  Fa,
  showErrorAlert,
  showInfoAlert,
  showWarningAlert,
  showWarningMessage,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
/* import { Link } from 'react-router-dom' */
/* import { AppContext } from '../AppContext' */
/* import { clearSession } from './sessionManager' */
import { logger } from 'Tools'
import { useAppContext, useCampaignContext } from 'Contexts'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from 'react-oidc-context'

export const DebugButtons = () => {
  const auth = useAuth()
  const newOrgModalRef = useRef(null)
  const { orgServiceApi, currentOrg, tosApi } = useAppContext()
  const [showOrgCreationModal, setShowOrgCreationModal] = useState(false)
  const [tempOrgName, setTempOrgName] = useState('')
  const [tempOrgLogo, setTempOrgLogo] = useState('')

  const {
    launchFreshCampaign,
    isPolitical,
    setIsPolitical,
  } = useCampaignContext()
  const healthCheck = () => {
    axios
      .get('https://hagrid.api.dev.eltoro.com/v1/health', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('eltoro_token')}`,
        },
      })
      // eslint-disable-next-line
      .then((res) => console.log(res))
  }

  const handleCreateOrg = () => {
    orgServiceApi?.advertisingPlatformServiceCreateOrg({
      // logo: { bucket: tempOrgLogo },
      name: tempOrgName,
      contacts: [
        {
          id: '308ec89d-8c27-4edf-a3ba-b150fec72d8a',
          createTime: new Date('2022-04-26T12:34:04.647Z'),
          updateTime: new Date('2022-04-26T12:34:04.658Z'),
          deleteTime: undefined,
          email: 'mari@eltoro.com',
          orgId: '88b03ba4-f529-439b-b2d8-38af963ac9ed',
          phone: '222-222-2222',
          type: 'sales',
          firstName: 'Mari Humberg',
          lastName: '',
          address: '',
          city: '',
          state: '',
          zip: '',
        },
        {
          id: 'c432abe3-164d-4af4-93ac-a5f3ed6c1963',
          createTime: new Date('2022-04-26T12:35:39.405Z'),
          updateTime: new Date('2022-04-26T12:35:39.418Z'),
          deleteTime: undefined,
          email: 'joe@eltoro.com',
          orgId: '88b03ba4-f529-439b-b2d8-38af963ac9ed',
          phone: '222-222-2222',
          type: 'operations',
          firstName: 'Joe Young',
          lastName: '',
          address: '',
          city: '',
          state: '',
          zip: '',
        },
        {
          id: 'c432abe3-164d-4af4-93ac-a5f3ed6c1963',
          createTime: new Date('2022-04-26T12:35:39.405Z'),
          updateTime: new Date('2022-04-26T12:35:39.418Z'),
          deleteTime: undefined,
          email: 'joe@eltoro.com',
          orgId: '88b03ba4-f529-439b-b2d8-38af963ac9ed',
          phone: '222-222-2222',
          type: 'billing',
          firstName: 'Joe Young',
          lastName: '',
          address: '',
          city: '',
          state: '',
          zip: '',
        },
      ],
    })
    setShowOrgCreationModal(false)
  }

  const history = useNavigate()
  const location = useLocation()

  return (
    <HeaderDropdown
      position="left"
      icon={<Fa icon="bug" size={2} />}
      offClickExclusionRef={[newOrgModalRef]}
      hasDropdownIcon={false}
    >
      <HeaderItem to="/internal-api-docs">
        Internal API documentation
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          throw new Error('This is a test Error thrown by error debug button')
        }}
      >
        Throw Test Error
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          logger.log('local storge: ', localStorage.getItem('eltoro_token'))
          /* logger.log('Current User: ', currentUser) */
        }}
      >
        check token in local storage
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          logger.log(auth)
        }}
      >
        Log keycloak
      </HeaderItem>
      <HeaderItem to="/theme-info">Theme info</HeaderItem>
      <HeaderItem
        onClick={() =>
          orgServiceApi
            ?.advertisingPlatformServiceListOrgs()
            .then((result) =>
              logger.log(
                result,
                'result from calling advertisingPlatformServiceGetAllOrgs()',
              ),
            )
            .catch((err) =>
              logger.log(
                err,
                'error from calling advertisingPlatformServiceGetAllOrgs()',
              ),
            )
        }
      >
        Test advertisingPlatformServiceGetAllOrgs()
      </HeaderItem>
      <HeaderItem onClick={() => console.log('no')}>Create org</HeaderItem>
      <HeaderItem
        onClick={() => {
          launchFreshCampaign({})
          if (
            location.pathname.includes('/campaigns') &&
            !location.pathname.includes('/dashboard')
          )
            history('/campaigns/create')
        }}
      >
        Clear CampaignContext
      </HeaderItem>

      <HeaderItem to="/test-page">Test Page</HeaderItem>
      <HeaderItem
        onClick={() => {
          showErrorAlert(
            'Service Outage',
            'Digital Canvassing is currently not running. We will keep you updated as we know more.',
          )
        }}
      >
        Alert Error
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showWarningAlert(
            'Service Degradation',
            'Quote is currently taking longer than expected to run. Please be patient as we resolve this issue.',
          )
        }}
      >
        Alert Warning
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showInfoAlert(
            '',
            "We'd love to hear from you! Please fill out this brief 3-question survey.",
          )
        }}
      >
        Alert Info
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showWarningMessage(
            'WARNING',
            'This is a warning Please do not proceed and do not go forward. Whatever you take this with you ------{}::::::::::::::::::::>. The forest is dark',
          )
        }}
      >
        Send Warning
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showErrorMessage(
            'Error',
            'Please do not proceed and do not go forward. Whatever you take this with you ------{}::::::::::::::::::::>. The forest is dark and full of errors',
          )
        }}
      >
        Send Error
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showInfoMessage(
            'Info',
            'This is a info Please proceed Whatever you do take this with you ------{}::::::::::::::::::::>. The forest is dark and full of errors. You have been informed',
          )
        }}
      >
        Send Info
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          showSuccessMessage(
            'Success!',
            'This is not an error. Please proceed ahead. Beware:  The forest is dark and full of errors',
          )
        }}
      >
        Send Success
      </HeaderItem>
      {/* <HeaderItem
        onClick={() => {
          if (admin) {
            return setAdmin(false)
          }
          return setAdmin(true)
        }}
      >
        Toggle Admin: {admin ? 'off' : 'on'}
      </HeaderItem> */}
      {showOrgCreationModal && (
        <Modal
          modalRef={newOrgModalRef}
          offClick={() => setShowOrgCreationModal(false)}
        >
          <Text on="white" weight="bold" size="l">
            Create a new org
          </Text>
          <Spacer />
          <FormItem required htmlFor="org_name" label="Name">
            <TextInput
              value={tempOrgName}
              onChange={(e) => setTempOrgName(e.target.value)}
            />
          </FormItem>
          <FormItem required htmlFor="org_logo" label="Logo">
            <TextInput
              value={tempOrgLogo}
              onChange={(e) => setTempOrgLogo(e.target.value)}
            />
          </FormItem>
          <FlexBox justifyContent="flex-end">
            <Button onClick={() => setShowOrgCreationModal(false)}>
              Cancel
            </Button>
            <Spacer width="0.5rem" />
            <Button onClick={handleCreateOrg}>Save</Button>
          </FlexBox>
        </Modal>
      )}

      <HeaderItem
        onClick={() => {
          setIsPolitical(!isPolitical)
        }}
      >
        set current draft campaign as political
      </HeaderItem>
      <HeaderItem
        onClick={() => {
          healthCheck()
        }}
      >
        Throw Health Check
      </HeaderItem>
      <HeaderItem
        onClick={() =>
          tosApi?.advertisingPlatformServiceGetUserIPAddress().then(console.log)
        }
      >
        Log IP address
      </HeaderItem>
    </HeaderDropdown>
  )
}

export default DebugButtons
