/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TypeDate } from '../models/TypeDate';
import { V1SubscriptionInterval } from '../models/V1SubscriptionInterval';
import { V1SubscriptionService } from '../models/V1SubscriptionService';
import { V1SubscriptionStatus } from '../models/V1SubscriptionStatus';
import { HttpFile } from '../http/http';

export class TheSubscriptionToUpdate {
    'interval'?: V1SubscriptionInterval;
    'billDate'?: TypeDate;
    /**
    * If active is true upon creation, the Subscription will be charged immediately for the prorated amount until bill_date. Once cancelled, active will be false.
    */
    'active'?: boolean;
    /**
    * Whether or not the subscription will renew on the next_bill_date
    */
    'autoRenew'?: boolean;
    /**
    * ID of the Org that owns the Subscription
    */
    'orgId'?: string;
    /**
    * ID of the Credit Card to charge for the Subscription. Will use the default Credit Card on the org if not provided.
    */
    'creditCardId'?: string;
    'status'?: V1SubscriptionStatus;
    /**
    * Status Reason of the Subscription
    */
    'statusReason'?: string;
    'service'?: V1SubscriptionService;
    'nextBillDate'?: TypeDate;
    /**
    * The next charge amount of the Subscription.
    */
    'nextChargeAmount'?: number;
    /**
    * Time the Subscription was created at
    */
    'createTime'?: Date;
    /**
    * Time the Subscription was updated at
    */
    'updateTime'?: Date;
    /**
    * Time the Subscription was deleted at
    */
    'deleteTime'?: Date;
    'freeTrialExpirationDate'?: TypeDate;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "interval",
            "baseName": "interval",
            "type": "V1SubscriptionInterval",
            "format": ""
        },
        {
            "name": "billDate",
            "baseName": "bill_date",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "autoRenew",
            "baseName": "auto_renew",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "creditCardId",
            "baseName": "credit_card_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1SubscriptionStatus",
            "format": ""
        },
        {
            "name": "statusReason",
            "baseName": "status_reason",
            "type": "string",
            "format": ""
        },
        {
            "name": "service",
            "baseName": "service",
            "type": "V1SubscriptionService",
            "format": ""
        },
        {
            "name": "nextBillDate",
            "baseName": "next_bill_date",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "nextChargeAmount",
            "baseName": "next_charge_amount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "freeTrialExpirationDate",
            "baseName": "free_trial_expiration_date",
            "type": "TypeDate",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheSubscriptionToUpdate.attributeTypeMap;
    }

    public constructor() {
    }
}

