import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Text } from '../Text'

type CProps = {
  fieldName: string
  max: number
  text: string
}

export const CharacterCounter = ({ fieldName, max, text }: CProps) => {
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(max)

  useEffect(() => {
    const updatedCount = text.length
    const updatedRemaining = max - updatedCount

    setCount(updatedCount)
    setRemaining(updatedRemaining)
  }, [text, max])
  return (
    <div
      className={classNames(
        'CharacterCounter',
        `CharacterCounter__${fieldName}`,
        'tint-gray-800',
        'text-sm',
        'flex',
        'justify-between',
        {
          'text-warning animate-slidedown font-bold': count > max,
          'font-medium': count <= max,
        },
      )}
    >
      <Text className="flex gap-1">
        <span className="font-normal">Count:</span>
        <strong>{count}</strong>
      </Text>
      <Text className="flex gap-1">
        <span className="font-normal">Remain:</span>
        <span className="flex gap-1">
          {remaining >= 0 ? (
            <strong>{remaining}</strong>
          ) : (
            <>
              <strong>0</strong>
              <span className="font-normal">&nbsp; | &nbsp;Max: </span>
              <strong>{max}</strong>
            </>
          )}
        </span>
      </Text>
    </div>
  )
}
