import { DimensionsType } from 'Typings'
import {
  defaultArtboards,
  directMailInches,
  postalAreaInches,
  postalAreaSpacerInches,
  stampSizeInches,
} from '../Data'

// direct mail needs 0.125" bleed total (0.0625" per side)...
// ...different printhouses require different bleeds, may need to update
const directMailBleed = 0.125

export const addBleed = (dimension: number) => dimension + directMailBleed

// returns directmail size in inches and relavant artboard in pixels
const getDirectMailArtboard = (size: string) => {
  const directMailSize = directMailInches[size]
  const [selectedArtboard] = defaultArtboards.filter(
    (artboard) => artboard.size === size,
  )
  return { directMailSize, selectedArtboard }
}

// returns width and height ratios with bleeds
const getRatios = (parent: DimensionsType, child: DimensionsType) => {
  const widthRatio = addBleed(child.width) / addBleed(parent.width)
  const heightRatio = addBleed(child.height) / addBleed(parent.height)
  return { width: widthRatio, height: heightRatio }
}
// returns dims in pixel size
const getDimsInPixels = (parent: DimensionsType, ratio: DimensionsType) => ({
  width: Math.round(parent.width * ratio.width),
  height: Math.round(parent.height * ratio.height),
})

// combines above helpers to return pixel size of a direct mail piece with bleed
export const getPixelSize = (dmSize: string, sizeInInches: DimensionsType) => {
  const { directMailSize, selectedArtboard } = getDirectMailArtboard(dmSize)
  const ratios = getRatios(directMailSize, sizeInInches)
  const { width, height } = getDimsInPixels(selectedArtboard, ratios)
  return { width, height }
}

// creates stamp coords and dimensions...
// Stamp is placed in top right corner of direct mail piece.
// bleed is included when calculating width and height
export const stampSizePixels = (directMailSize: string) => {
  const { selectedArtboard } = getDirectMailArtboard(directMailSize)
  const { width, height } = getPixelSize(directMailSize, stampSizeInches)
  return {
    width,
    height,
    x: selectedArtboard.width - width,
    y: 0,
  }
}

// creates address block coords and dimensions...
// Address is placed in bottom left corner of direct mail piece...
// ...with a specified offset described as `spacer` below.
// bleed is included when calculating width and height
export const addressSizePixels = (directMailSize: string) => {
  const { selectedArtboard } = getDirectMailArtboard(directMailSize)
  const { width, height } = getPixelSize(directMailSize, postalAreaInches)
  const spacer = getPixelSize(directMailSize, postalAreaSpacerInches)
  return {
    width,
    height,
    x: selectedArtboard.width - width - spacer.width,
    y: selectedArtboard.height - height - spacer.height,
  }
}
