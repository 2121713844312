import { useState, useRef } from 'react'
import { Avatar, Popover, Button, LoadingBar, Fa } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppContext, useCampaignContext } from 'Contexts'
import { useMouseHandlers } from 'Hooks'
import { isOrgBillable, styleTailwind } from 'Helpers'
import { OrgSelect } from './OrgSelect'
import './SideMenuOrgDetails.scss'

const OrgMenuItem = styleTailwind(
  Button,
  'SideMenuOrgDetails__menu-item bg-base text-primary hover:bg-secondary-100 hover:text-grey-700 gap-1',
)

export const SideMenuOrgDetails = ({
  showOrgSelect,
  setShowOrgSelect,
  disableNav,
}: {
  showOrgSelect: boolean
  setShowOrgSelect: React.Dispatch<React.SetStateAction<boolean>>
  disableNav?: boolean
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const timeOutId = useRef<number>(1000)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const orgMenuRef = useRef<HTMLDivElement | null>(null)
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)

  const { currentOrg } = useAppContext()
  const { launchFreshCampaign } = useCampaignContext()
  const history = useNavigate()
  const location = useLocation()

  const { handleMouseEnter, handleMouseLeave } = useMouseHandlers({
    onMouseEnter: () => setMenuOpen(true),
    onMouseLeave: () => setMenuOpen(false),
    delay: timeOutId,
  })
  useOnClickOutside([menuRef, menuButtonRef, orgMenuRef], () => {
    setMenuOpen(false)
    setShowOrgSelect(false)
  })

  const logoSrc =
    currentOrg?.logo?.key &&
    `https://s3.amazonaws.com/c.eltoro.com/${currentOrg.logo.key}`

  const handleMenu = () => setMenuOpen(!menuOpen)

  return (
    <div ref={orgMenuRef} className="SideMenuOrgDetails__wrap relative w-full">
      {currentOrg ? (
        <div className="SideMenuOrgDetails relative h-full items-start justify-start">
          <div className="SideMenuOrgDetails_logo-orgname-btn flex w-full flex-col items-center justify-start py-1">
            <Popover
              className="SideMenuOrgDetail__dropdown"
              align="start"
              position={['right']}
              isOpen={menuOpen}
              attachId="SideMenu__dropdown"
              content={
                <div
                  ref={menuRef}
                  className="SideMenuOrgDetails__menu text-grey-600 PointerTip flex flex-col"
                  onMouseEnter={!showOrgSelect ? handleMouseEnter : undefined}
                  onMouseLeave={!showOrgSelect ? handleMouseLeave : undefined}
                >
                  <Button
                    className={`SideMenuOrgDetails__menu-item-org-change border-primary-600 border-t-thin border-l-thin border-r-thin !bg-primary-100 mb-2 !mt-0 !rounded-none ${
                      showOrgSelect ? 'BackButton' : 'ChangeOrgButton'
                    }`}
                    kind="text"
                    iconLeft={
                      showOrgSelect ? (
                        <Fa className="CaretLeft" icon="caret-left" size={1} />
                      ) : undefined
                    }
                    iconRight={
                      showOrgSelect ? undefined : (
                        <Fa
                          className="CaretRight"
                          icon="caret-right"
                          size={1}
                        />
                      )
                    }
                    onClick={() => setShowOrgSelect(!showOrgSelect)}
                  >
                    {showOrgSelect ? 'Back' : 'Change org'}
                  </Button>

                  {!showOrgSelect ? (
                    (() => {
                      if (disableNav) return null
                      return (
                        <>
                          <OrgMenuItem
                            kind="text"
                            iconLeft={<Fa icon="cog" size={1} />}
                            onClick={() => {
                              setMenuOpen(false)
                              history(`/orgs/${currentOrg.id}/org-settings`)
                            }}
                          >
                            Org Settings
                          </OrgMenuItem>
                          {currentOrg && isOrgBillable(currentOrg) && (
                            <OrgMenuItem
                              kind="text"
                              iconLeft={
                                <Fa icon="file-invoice-dollar" size={1} />
                              }
                              onClick={() => {
                                setMenuOpen(false)
                                history(`/orgs/${currentOrg.id}/billing`)
                              }}
                            >
                              Billing
                            </OrgMenuItem>
                          )}
                          <OrgMenuItem
                            kind="text"
                            iconLeft={<Fa icon="folder-tree" size={1} />}
                            onClick={() => {
                              setMenuOpen(false)
                              history(`/orgs/${currentOrg.id}/child-orgs`)
                            }}
                          >
                            Child Orgs
                          </OrgMenuItem>
                        </>
                      )
                    })()
                  ) : (
                    <OrgSelect
                      onOrgSelect={(orgId) => {
                        launchFreshCampaign({})
                        if (
                          location.pathname.includes('campaigns/edit') ||
                          location.pathname.includes('campaigns/create')
                        )
                          history(`/campaigns/create?org_id=${orgId}`)

                        // removing asset/campaign/order-line ids
                        if (
                          location.pathname.includes(
                            'campaigns/creative-library',
                          )
                        ) {
                          history(`/campaigns/creative-library?org_id=${orgId}`)
                        }
                        if (
                          location.pathname.includes(
                            'audiences/audience-library',
                          )
                        ) {
                          history(`/audiences/audience-library?org_id=${orgId}`)
                        }
                        // check order line view
                        // check campaign view
                        if (location.pathname.includes('campaigns/dashboard')) {
                          history(`/campaigns/dashboard?org_id=${orgId}`)
                        }
                        setShowOrgSelect(false)
                        setMenuOpen(false)
                      }}
                    />
                  )}
                </div>
              }
            >
              <button
                tabIndex={0}
                type="button"
                ref={menuButtonRef}
                className="SideMenuOrgDetails__menu-button flex flex-1 py-3"
                onClick={handleMenu}
                data-tooltip="right"
                aria-label="Click to see settings"
              >
                <span className="SideMenuOrgDetails_logo-orgname-cat flex flex-1 gap-2">
                  <Avatar name={currentOrg.name || ''} src={logoSrc} rounded />
                  <span className="SideMenuOrgDetails__main">
                    <p className="SideMenuOrgDetails__main-txt font-bold">
                      {currentOrg.name}
                    </p>
                    {currentOrg.reseller && (
                      <p className="SideMenuOrgDetails__main-txt text-xs font-light">
                        Reseller
                      </p>
                    )}
                  </span>
                  <Fa
                    className="ml-auto mr-2 rounded-full py-3 transition-all group-hover:text-white"
                    icon="ellipsis-v"
                    size={2}
                  />
                </span>
              </button>
            </Popover>
          </div>
        </div>
      ) : (
        <div className="SideMenuOrgDetails__loading flex h-24 items-center justify-center">
          <LoadingBar className="LoadingBar_sideMenu my-4 flex h-8 w-3/4 items-center justify-center p-0" />
        </div>
      )}
    </div>
  )
}
