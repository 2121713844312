/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { V1TemplateType } from '../models/V1TemplateType';
import { V3SegmentConfig } from '../models/V3SegmentConfig';
import { HttpFile } from '../http/http';

/**
* Request to get an OrderLine's Destination Configuration Default Template
*/
export class GetDefaultTemplateRequest {
    'destination'?: Deployservicev3Destination;
    'segmentConfig'?: V3SegmentConfig;
    'orgId': string;
    'useSplits'?: boolean;
    'templateType'?: V1TemplateType;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "segmentConfig",
            "baseName": "segment_config",
            "type": "V3SegmentConfig",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "useSplits",
            "baseName": "use_splits",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "templateType",
            "baseName": "template_type",
            "type": "V1TemplateType",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GetDefaultTemplateRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

