import { FC, ReactNode } from 'react'

export const SideMenu: FC<{
  className?: string
  children: ReactNode
}> = ({ className = '', children }) => {
  return (
    <div
      className={`SideMenu ${className} flex flex-col justify-start items-start`}
    >
      {children}
    </div>
  )
}
