export const emptyCPMs = {
  'digital_canvassing banner': {
    product_name: 'digital_canvassing banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'digital_canvassing html5': {
    product_name: 'digital_canvassing html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'digital_canvassing native': {
    product_name: 'digital_canvassing native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'digital_canvassing native_video': {
    product_name: 'digital_canvassing native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'digital_canvassing ott': {
    product_name: 'digital_canvassing ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'digital_canvassing video': {
    product_name: 'digital_canvassing video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting banner': {
    product_name: 'ip_targeting banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting html5': {
    product_name: 'ip_targeting html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting native': {
    product_name: 'ip_targeting native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting native_video': {
    product_name: 'ip_targeting native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting ott': {
    product_name: 'ip_targeting ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'ip_targeting video': {
    product_name: 'ip_targeting video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon banner': {
    product_name: 'map_polygon banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon html5': {
    product_name: 'map_polygon html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon native': {
    product_name: 'map_polygon native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon native_video': {
    product_name: 'map_polygon native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon ott': {
    product_name: 'map_polygon ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'map_polygon video': {
    product_name: 'map_polygon video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers banner': {
    product_name: 'new_movers banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers html5': {
    product_name: 'new_movers html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers native': {
    product_name: 'new_movers native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers native_video': {
    product_name: 'new_movers native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers ott': {
    product_name: 'new_movers ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'new_movers video': {
    product_name: 'new_movers video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing banner': {
    product_name: 'political digital_canvassing banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing html5': {
    product_name: 'political digital_canvassing html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing native': {
    product_name: 'political digital_canvassing native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing native_video': {
    product_name: 'political digital_canvassing native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing ott': {
    product_name: 'political digital_canvassing ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political digital_canvassing video': {
    product_name: 'political digital_canvassing video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting banner': {
    product_name: 'political ip_targeting banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting html5': {
    product_name: 'political ip_targeting html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting native': {
    product_name: 'political ip_targeting native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting native_video': {
    product_name: 'political ip_targeting native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting ott': {
    product_name: 'political ip_targeting ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political ip_targeting video': {
    product_name: 'political ip_targeting video',

    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon banner': {
    product_name: 'political map_polygon banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon html5': {
    product_name: 'political map_polygon html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon native': {
    product_name: 'political map_polygon native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon native_video': {
    product_name: 'political map_polygon native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon ott': {
    product_name: 'political map_polygon ott',

    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political map_polygon video': {
    product_name: 'political map_polygon video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers banner': {
    product_name: 'political new_movers banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers html5': {
    product_name: 'political new_movers html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers native': {
    product_name: 'political new_movers native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers native_video': {
    product_name: 'political new_movers native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers ott': {
    product_name: 'political new_movers ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political new_movers video': {
    product_name: 'political new_movers video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting banner': {
    product_name: 'political retargeting banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting html5': {
    product_name: 'political retargeting html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting native': {
    product_name: 'political retargeting native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting native_video': {
    product_name: 'political retargeting native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting ott': {
    product_name: 'political retargeting ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political retargeting video': {
    product_name: 'political retargeting video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay banner': {
    product_name: 'political venue_replay banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay html5': {
    product_name: 'political venue_replay html5',

    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay native': {
    product_name: 'political venue_replay native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay native_video': {
    product_name: 'political venue_replay native_video',

    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay ott': {
    product_name: 'political venue_replay ott',

    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'political venue_replay video': {
    product_name: 'political venue_replay video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting banner': {
    product_name: 'retargeting banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting html5': {
    product_name: 'retargeting html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting native': {
    product_name: 'retargeting native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting native_video': {
    product_name: 'retargeting native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting ott': {
    product_name: 'retargeting ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'retargeting video': {
    product_name: 'retargeting video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay banner': {
    product_name: 'venue_replay banner',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay html5': {
    product_name: 'venue_replay html5',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay native': {
    product_name: 'venue_replay native',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay native_video': {
    product_name: 'venue_replay native_video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay ott': {
    product_name: 'venue_replay ott',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
  'venue_replay video': {
    product_name: 'venue_replay video',
    currency_code: 'USD',
    units: '',
    nanos: 0,
  },
}
