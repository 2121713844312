import { PreviewType } from '@eltoro-ui/components'
import {
  V1CreativeFile,
  Creativeservicev1FileType,
  V1FileSubType,
} from 'next-gen-sdk'
import axios from 'axios'
import { creativeFileTypesEnum } from 'Enums'
import { getApiUrl } from 'Helpers'

// return a promise that resolves with a File instance
export const urltoFile = async (url: string, filename: string) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], filename, { type: 'image/jpeg' })
      return file
    })
}

export const uploadCreativeGen = async (
  base64: string,
  name: string,
  type: string,
  order: string,
  token: string,
  orgId: string,
  onProgress?: (percentage: number) => void,
) => {
  const config = { Authorization: `Bearer ${token}` }
  const file = await urltoFile(base64, name)
  const i = new Image()
  let x
  let y
  i.onload = () => {
    x = i.width
    y = i.height
  }

  const url = `${getApiUrl()}/v1`
  const data = new FormData()
  if (type === 'directMail') {
    data.append(
      'metadata',
      JSON.stringify({
        filename: name,
        height: y,
        width: x,
        type: 3,
        subtype: order === 'front' ? 130 : 133,
      }),
    )
  }
  if (type === 'banner') {
    data.append(
      'metadata',
      JSON.stringify({
        filename: name,
        height: y,
        width: x,
        type: 3,
      }),
    )
  }
  data.append('chunk', file)
  data.append('org_id', orgId)

  const res = await axios.post(
    `${url}/creatives:upload?org_id=${orgId}`,
    data,
    {
      headers: config,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        if (onProgress) onProgress(percentage)
      },
    },
  )

  if (res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }

  return res.data as V1CreativeFile
}

export const uploadCreative = async (
  name: string,
  file: PreviewType,
  token: string,
  orgId: string,
  onProgress?: (percentage: number) => void,
  fileType?: Creativeservicev1FileType,
  fileSubType?: V1FileSubType,
) => {
  const url = `${getApiUrl()}/v1`
  const config = { Authorization: `Bearer ${token}` }
  const data = new FormData()
  const type = fileType ? creativeFileTypesEnum[fileType] : 3

  data.append(
    'metadata',
    JSON.stringify({
      filename: name,
      height: file.height,
      width: file.width,
      type,
      subtype: fileSubType,
    }),
  )
  data.append('chunk', file)
  data.append('org_id', orgId)

  const res = await axios
    .post(`${url}/creatives:upload?org_id=${orgId}`, data, {
      headers: config,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        if (onProgress) onProgress(percentage)
      },
    })
    .then((res) => {
      return res
    })
    .catch((err) => {
      if (err) {
        throw new Error(
          JSON.stringify({ status: err.status, message: err.response.data }),
        )
      }
    })

  if (res && res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }
  if (res && res.data && !res.data.mime) {
    res.data.mime = file?.type
  }
  if (res && res.data) {
    return res?.data as V1CreativeFile
  }
  return {} as V1CreativeFile
}
