import React, { useEffect, useState } from 'react'

/**
 * custom useMediaQuery hook
 * one-off usage such as to hide/show an element; more responsive :B, the media matches the query provided, returns true/false and pass setQuery as string, if provided
 * @param {string} query - The media query to match against.
 * @returns {boolean, setQuery} - Returns a boolean, optional setQuery if set, set query to new query, true if it's isSafari
 * why change this hook?
 * * make it better and more flexible: add flexibility (setQuery allows media-query changes dynamically when needed), type safety, code readability, event listener fallback, and allows for easy reuse in various components, making it less brittle and more maintainable.
 *
 * example usage:
 * * const [isMobile, setIsMobile] = useMediaQuery('(max-width: 640px)')
 * * // Use the setter function to change the media query
 * * const updateMobileQuery = () => setIsMobile('(max-width: 768px)')
 * *
 * Or used without setQuery:
 * * const [isTablet] = useMediaQuery('screen and (max-width: 768px)')
 * *
 * browser would be
 * const [, , browser] = useMediaQuery('Safari');
 * returns browser type value if matched, Safari, Chrome etc..
 * *
 *
 * custom useScreenSize hook
 * @returns {height,width} of device screen's dimensions in number
 * * import { useScreenSize, ScreenSize } from './useMediaQuery'
 * * const screenSize = useScreenSize();
 *
 * * <p>Width: {screenSize.width}px</p>
 * * <p>Height: {screenSize.height}px</p>
 *
 *  * custom useBrowser hook
 * @returns {string} of device's browser type
 * * import { useBrowser } from './useMediaQuery'
 * * const browser = useBrowser();
 *
 * * <p>Browser: Your browser is {browser}</p>
 */

export type MediaQueryResult = {
  match: boolean
  setQuery: React.Dispatch<React.SetStateAction<string>>
}

export type ScreenSize = {
  width: number
  height: number
}

type MQProps = [boolean, (query: string) => void]

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const { width, height } = entries[0].contentRect
      setScreenSize({ width, height })
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(document.documentElement)

    return () => {
      resizeObserver.unobserve(document.documentElement)
    }
  }, [])

  return screenSize
}

export const useBrowser = () => {
  const uA = navigator.userAgent
  let browser

  switch (true) {
    case (uA.includes('Safari') && !uA.includes('Chrome')) ||
      (uA.search('Safari') >= 0 && uA.search('Chrome') < 0):
      browser = 'Safari'
      break
    case uA.includes('Edg') || uA.match(/edg/i):
      browser = 'Edge'
      break
    case uA.includes('Chrome') || uA.match(/chrome|chromium|crios/i):
      browser = 'Chrome'
      break
    case uA.includes('Firefox') || uA.match(/firefox|fxios/i):
      browser = 'Firefox'
      break
    case uA.includes('Opera') || uA.match(/opr\//i):
      browser = 'Opera'
      break
    default:
      browser = 'Other'
  }

  // return the user's browser
  return browser
}

export const useMediaQuery = (initialQuery: string): MQProps => {
  const [query, setQuery] = useState<string>(initialQuery)
  const [match, setMatch] = useState<boolean>(false)

  useEffect(() => {
    if (!query) return () => {}

    const mq = window.matchMedia(query)

    const handleMatchChange = (e: MediaQueryListEvent) => {
      setMatch(e.matches)
    }

    const _fallBackChange = (e: Event) => {
      handleMatchChange(e as MediaQueryListEvent)
    }

    if (mq.addEventListener) {
      mq.addEventListener('change', handleMatchChange)
    }
    mq.onchange = _fallBackChange

    // Initial check for the current media query
    setMatch(mq.matches)

    return () => {
      if (mq.removeEventListener) {
        mq.removeEventListener('change', handleMatchChange)
      }
      mq.onchange = null
    }
  }, [query])

  const updateQuery = (newQuery: string) => {
    setQuery(newQuery)
  }

  return [match, updateQuery] as MQProps
}
