import { ApiFactory } from 'apiFactory'

export const submitPrePayment = async (
  orgId: string,
  orderLineId: string[],
  creditCardId?: string, // if undefined, then this is a non-billable child org. parent org will be charged
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = await ApiFactory(token)
  const { billingServiceAPI } = apis
  await billingServiceAPI?.advertisingPlatformServicePrepay(orgId, {
    creditCardId,
    orderLineId,
  })
}
