/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Rule } from '../models/V1Rule';
import { HttpFile } from '../http/http';

export class V1RuleSet {
    /**
    * Id of the ruleset
    */
    'id'?: string;
    /**
    * RefId of the ruleset
    */
    'refId'?: string;
    /**
    * All Subscriptions should contain at least an \"And\" rule set. Other options are \"Or\" and \"Not\".
    */
    'operator'?: string;
    /**
    * Id of the rrelated subscription
    */
    'subscriptionId'?: string;
    /**
    * Id of the parent rule set subscription
    */
    'parentRuleSetId'?: string;
    /**
    * the combination of rulesets
    */
    'ruleSet'?: Array<V1RuleSet>;
    /**
    * The sombination of rules
    */
    'rules'?: Array<V1Rule>;
    /**
    * The Pixels OrgID
    */
    'orgId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "operator",
            "baseName": "operator",
            "type": "string",
            "format": ""
        },
        {
            "name": "subscriptionId",
            "baseName": "subscription_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "parentRuleSetId",
            "baseName": "parent_rule_set_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "ruleSet",
            "baseName": "rule_set",
            "type": "Array<V1RuleSet>",
            "format": ""
        },
        {
            "name": "rules",
            "baseName": "rules",
            "type": "Array<V1Rule>",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1RuleSet.attributeTypeMap;
    }

    public constructor() {
    }
}

