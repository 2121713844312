export const bannerChecklistConfig = [
  // Standard Sizes :
  {
    name: 'Inline rectangle',
    suggested: true,
    dimensions: '300x250',
    width: '6px',
    height: '5px',
  },
  {
    name: 'Leaderboard',
    dimensions: '728x90',
    suggested: true,
    width: '16px',
    height: '2px',
  },
  {
    name: 'Wide skyscraper',
    dimensions: '160x600',
    suggested: true,
    width: '4px',
    height: '13px',
  },
  {
    name: 'Half-page',
    dimensions: '300x600',
    suggested: true,
    width: '8px',
    height: '13px',
  },
  {
    name: 'Mobile Leaderboard Large',
    dimensions: '320x50',
    suggested: true,
    width: '6px',
    height: '1px',
  },
  {
    name: 'Skyscraper',
    dimensions: '120x600',
    suggested: false,
    width: '3px',
    height: '13px',
  },
  {
    name: 'Banner',
    dimensions: '468x60',
    suggested: false,
    width: '8px',
    height: '1px',
  },
  {
    name: 'Large Rectangle',
    dimensions: '336x280',
    suggested: false,
    width: '6px',
    height: '5px',
  },
  {
    name: 'Square',
    dimensions: '250x250',
    suggested: false,
    width: '4px',
    height: '4px',
  },
  {
    name: 'Small Rectangle',
    dimensions: '180x150',
    suggested: false,
    width: '3px',
    height: '2px',
  },
  {
    name: 'Billboard',
    dimensions: '970x250',
    suggested: false,
    width: '8px',
    height: '2px',
  },
  {
    name: 'Half-Banner',
    dimensions: '234x60',
    suggested: false,
    width: '4px',
    height: '1px',
  },
  {
    name: 'Leaderboard (Alt)',
    dimensions: '970x90',
    suggested: false,
    width: '19px',
    height: '2px',
  },
  // Mobile Sizes, include one above :
  {
    name: 'Mobile Leaderboard Large (alt)',
    dimensions: '300x50',
    suggested: false,
    width: '6px',
    height: '1px',
  },
  {
    name: 'Mobile Leaderboard Medium',
    dimensions: '216x36',
    suggested: false,
    width: '5px',
    height: '1px',
  },
  {
    name: 'Mobile Leaderboard Small',
    dimensions: '168x28',
    suggested: false,
    width: '3px',
    height: '1px',
  },
  {
    name: 'Mobile Leaderboard Small (alt)',
    dimensions: '120x20',
    suggested: false,
    width: '3px',
    height: '1px',
  },
  {
    name: 'Mobile Vertical Rectangle',
    dimensions: '320x480',
    suggested: false,
    width: '6px',
    height: '9px',
  },
  {
    name: 'Mobile Horizontal Rectangle',
    dimensions: '480x320',
    suggested: false,
    width: '9px',
    height: '6px',
  },
  {
    name: 'Moblie banner',
    dimensions: '320x100',
    suggested: false,
    width: '6px',
    height: '2px',
  },
]
