/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Domain List Service](https://docs.xandr.com/bundle/xandr-api/page/domain-list-service.html) in Xandr's documentation.
*/
export class V3DomainListTarget {
    /**
    * ID of the domain list.
    */
    'id'?: number;
    /**
    * Name of the domain list.
    */
    'name'?: string;
    /**
    * A description of the domain list.
    */
    'description'?: string;
    /**
    * The type of domain list. Possible values are black and white.
    */
    'type'?: string;
    /**
    * UNSURE OF THIS.
    */
    'deleted'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3DomainListTarget.attributeTypeMap;
    }

    public constructor() {
    }
}

