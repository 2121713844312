import { ReactElement, useState } from 'react'
import { render, unmount } from 'rc-util/lib/React/render'
import { Modal } from '../Modal'
import { Button } from '../Button'
import { Text } from '../Text'
import { Fa } from '../FontAwesome'

type ButtonKind =
  | 'default'
  | 'primary'
  | 'danger'
  | 'text'
  | 'text-icon'
  | 'default-grey'
  | 'primary-grey'
  | 'primary-light'

type TextSize = '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs'

type PromptProps = {
  confirmBtnKind?: ButtonKind
  textSize?: TextSize
}

export const triggerPrompt = (
  title: string,
  message: string | ReactElement,
  onSubmit: Function | (() => Promise<any>) | undefined,
  promptProps?: PromptProps, // optional tweaks for the Prompt component triggered
) => {
  const anchor = document.getElementById('prompt-anchor')

  const closePrompt = () => {
    if (anchor) unmount(anchor)
  }

  const handleConfirm = async () => {
    if (onSubmit) {
      await onSubmit()
    }
    closePrompt()
  }

  if (anchor) {
    render(
      <Prompt
        title={title}
        message={message}
        offClick={closePrompt}
        onSubmit={handleConfirm}
        {...promptProps}
      />,
      anchor,
    )
  }
}

export const Prompt = ({
  title,
  message,
  offClick,
  onSubmit,
  confirmBtnKind = 'primary',
  textSize = 'l',
}: {
  title: string
  message: string | ReactElement
  offClick: () => void
  onSubmit: () => Promise<any> | undefined
} & PromptProps) => {
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
  }

  return (
    <Modal className="Prompt" header={title} offClick={offClick}>
      <Text size={textSize}>{message}</Text>
      <div className="mt-4 mb-1 flex justify-end gap-2">
        <Button disabled={loading} onClick={offClick}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={handleConfirm}
          kind={confirmBtnKind}
          iconLeft={
            loading ? (
              <Fa icon="spinner" size={1} className="animate-spin" />
            ) : undefined
          }
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}
