/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Authorization for a credit card
*/
export class V1Authorization {
    /**
    * ID of the Authorization
    */
    'id'?: string;
    /**
    * Time the Authorization was created at
    */
    'createTime'?: Date;
    /**
    * Time the Authorization was updated at
    */
    'updateTime'?: Date;
    /**
    * Time the Authorization was deleted at
    */
    'deleteTime'?: Date;
    /**
    * date that the authorization was created
    */
    'dateSigned'?: Date;
    /**
    * User ID that the authorization is associated with
    */
    'userId'?: string;
    /**
    * The IP of the authorization
    */
    'ip'?: string;
    /**
    * Whether or not the authorization was approved
    */
    'approved'?: boolean;
    /**
    * The ID of the credit card that the authorization belongs to
    */
    'creditCardId'?: string;
    /**
    * Base 64 encoded client signature for the authorization
    */
    'signature'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dateSigned",
            "baseName": "date_signed",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "ip",
            "baseName": "ip",
            "type": "string",
            "format": ""
        },
        {
            "name": "approved",
            "baseName": "approved",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "creditCardId",
            "baseName": "credit_card_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "signature",
            "baseName": "signature",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Authorization.attributeTypeMap;
    }

    public constructor() {
    }
}

