/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1JobStatus } from '../models/V1JobStatus';
import { V1JobType } from '../models/V1JobType';
import { HttpFile } from '../http/http';

/**
* Target job information relevant to global search
*/
export class V1TargetJobSearch {
    'targetJobId'?: string;
    'name'?: string;
    'type'?: V1JobType;
    'status'?: V1JobStatus;
    'orgId'?: string;
    'orgName'?: string;
    'orgLogo'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "targetJobId",
            "baseName": "target_job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1JobType",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1JobStatus",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgLogo",
            "baseName": "org_logo",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1TargetJobSearch.attributeTypeMap;
    }

    public constructor() {
    }
}

