export const darkTheme = `:root{
  /* eltoro.com background-color #171313, hsla(0, 10%, 8%, 1), text is #f2f2f or  hsla(0, 0%, 95%, 1) change body text to tint gray so it would show up on white bg */

  --base-bg:                                          hsla(0, 6%, 15%, 1);
  --base-text:                                    hsla(48, 23%, 90%, 0.9);
  --base-gray:                                        hsla(30, 0%, 1%, 1); 
  --base-info:                                     var(--color-secondary);
  --base-info-050:                             var(--color-secondary-050);
  --base-info-100:                             var(--color-secondary-100);
  --base-info-200:                             var(--color-secondary-200);
  --base-info-300:                             var(--color-secondary-300);
  --base-info-400:                             var(--color-secondary-400);
  --base-info-500:                             var(--color-secondary-500);
  --base-info-600:                             var(--color-secondary-600);
  --base-info-700:                             var(--color-secondary-700);
  --base-info-800:                             var(--color-secondary-800);
  --base-info-900:                             var(--color-secondary-900);
  --highlight-bg:                                          var(--base-bg);
  --tw-ring-color:                                   var(--color-primary);
  --primer:                                           hsla(20, 0%, 1%, 1);

/* font awesome duo-tone colors */
  --fa-primary-color:                          var(--color-tertiary-500);
  --fa-primary-opacity:                                                1;
  --fa-secondary-color:                        var(--color-primary-300);
  --fa-secondary-opacity:                                             1;

  /* tints of khaki -- El Toro gold, #c1ba9d, hsl(48,23%,69%); higher number, opaque */
  --tint-khaki:                            hsla(48, 23%, 59%, 1);
  --tint-khaki-050:                     hsla(48, 23%, 76%, 0.12);
  --tint-khaki-100:                     hsla(48, 23%, 66%, 0.26);
  --tint-khaki-200:                     hsla(48, 23%, 65%, 0.30);
  --tint-khaki-300:                     hsla(48, 23%, 62%, 0.40);
  --tint-khaki-400:                     hsla(48, 23%, 59%, 0.55);
  --tint-khaki-500:                     hsla(48, 23%, 49%, 0.65);
  --tint-khaki-600:                     hsla(48, 23%, 39%, 0.75);
  --tint-khaki-700:                     hsla(48, 23%, 34%, 0.78);
  --tint-khaki-800:                     hsla(48, 23%, 28%, 0.88);
  --tint-khaki-900:                     hsla(48, 23%, 23%, 0.92);

  --text-on-tint-khaki: hsl(48, 22%, 90%,);
  --text-on-tint-gray: hsl(0, 0%, 90%);
  --text-on-tint-gray-700: hsl(0, 0%, 65%);
  --text-on-tint-gray-800: hsl(0, 0%, 70%);
  --text-on-tint-gray-900: hsl(0, 0%, 85%);
  --text-on-tint-dark: hsl(0, 10%, 90%);
  --text-on-tint-dark-700: hsl(0, 10%, 70%);
  --text-on-tint-dark-800: hsl(0, 10%, 80%);
  --text-on-tint-dark-900: hsl(0, 10%, 85%);
  --text-on-tint-light: hsl(0, 10%, 40%);


 /* tints of gray -- neutrals for light/dark backgrounds, higher number more opaque */ 
  --tint-gray:                                    var(--tint-gray-600);
  --tint-gray-050:                              hsla(0, 0%, 70%, 0.05);
  --tint-gray-100:                               hsla(0, 0%, 70%, 0.1);
  --tint-gray-200:                               hsla(0, 0%, 70%, 0.2);
  --tint-gray-300:                               hsla(0, 0%, 70%, 0.3);
  --tint-gray-400:                               hsla(0, 0%, 70%, 0.4);
  --tint-gray-500:                               hsla(0, 0%, 70%, 0.5);
  --tint-gray-600:                               hsla(0, 0%, 70%, 0.6);
  --tint-gray-700:                               hsla(0, 0%, 70%, 0.7);
  --tint-gray-800:                               hsla(0, 0%, 70%, 0.8);
  --tint-gray-900:                               hsla(0, 0%, 70%, 0.9);
  
 /* tints of whites -- used to lighten dark background, higher number more opaque */
  --tint-light:                                       hsl(0, 0%, 100%);
  --tint-light-050:                            hsla(0, 0%, 100%, 0.05);
  --tint-light-100:                             hsla(0, 0%, 100%, 0.1);
  --tint-light-200:                             hsla(0, 0%, 100%, 0.2);
  --tint-light-300:                             hsla(0, 0%, 100%, 0.3);
  --tint-light-400:                             hsla(0, 0%, 100%, 0.4);
  --tint-light-500:                             hsla(0, 0%, 100%, 0.5);
  --tint-light-600:                             hsla(0, 0%, 100%, 0.6);
  --tint-light-700:                             hsla(0, 0%, 100%, 0.7);
  --tint-light-800:                             hsla(0, 0%, 100%, 0.8);
  --tint-light-900:                             hsla(0, 0%, 100%, 0.9);

  /* tints of dark - use to lighten light background, higher number more opaque */
  --tint-dark:                                   var(--tint-dark-800);
  --tint-dark-050:                             hsla(0, 10%, 8%, 0.05);
  --tint-dark-100:                             hsla(0, 10%, 8%, 0.15);
  --tint-dark-200:                              hsla(0, 10%, 8%, 0.2);
  --tint-dark-300:                             hsla(0, 10%, 8%, 0.35);
  --tint-dark-400:                              hsla(0, 10%, 8%, 0.4);
  --tint-dark-500:                             hsla(0, 10%, 8%, 0.55);
  --tint-dark-600:                              hsla(0, 10%, 8%, 0.6);
  --tint-dark-700:                             hsla(0, 10%, 8%, 0.75);
  --tint-dark-800:                              hsla(0, 10%, 8%, 0.8);
  --tint-dark-900:                             hsla(0, 10%, 8%, 0.95);

  /* color-white set to base temporarily, keep PR small, revert in dark PR cleanup */
  --color-white:                                     hsl(0, 0%, 100%);
  --text-on-color-white:                       hsla(0, 2%, 27%, 0.80);
  --text-on-color-white--subdued:              hsla(0, 2%, 47%, 0.60);

  --text-on-color-white--primary:        hsla(48.3, 22.5%, 95%, 0.95);
  --text-on-color-white--primary-subdued--UNSAFE: hsla(48.3, 22.5%, 95%, 0.50);
  --text-on-color-white--secondary:          hsla(210, 63%, 45%, 0.9);
  --text-on-color-white--secondary-subdued--UNSAFE: hsla(210, 63%, 45%, 0.6);
  --text-on-color-white--tertiary:           hsla(358, 71%, 40%, 0.9);
  --text-on-color-white--tertiary-subdued--UNSAFE: hsla(358, 71%, 40%, 0.6);
  --text-on-color-white--success:                             #008940;
  --text-on-color-white--success-subdued--UNSAFE:             #40a770;
  --text-on-color-white--warning:                             #787b00;
  --text-on-color-white--warning-subdued--UNSAFE:             #9a9c40;
  --text-on-color-white--danger:                              #eb002a;
  --text-on-color-white--danger-subdued--UNSAFE:              #f0405f;

  /* GREY 050 ******************************************************************** */
  --color-grey-050:                                    hsl(20,1%,22%);

  --text-on-color-grey-050:                            hsl(20,1%,70%);
  --text-on-color-grey-050--subdued:                   hsl(20,1%,60%);
  --text-on-color-grey-050--primary:                 hsl(48, 23%,35%);
  --text-on-color-grey-050--primary-subdued--UNSAFE: hsl(48, 23%,40%);
  --text-on-color-grey-050--secondary:               hsl(210,63%,25%);
  --text-on-color-grey-050--secondary-subdued--UNSAFE:  hsl(210,63%,30%);
  --text-on-color-grey-050--tertiary:                hsl(358,71%,28%);
  --text-on-color-grey-050--tertiary-subdued--UNSAFE: hsl(358,71%,32%);
  --text-on-color-grey-050--success:                          #00833d;
  --text-on-color-grey-050--success-subdued--UNSAFE:          #3da06c;
  --text-on-color-grey-050--warning:                          #747600;
  --text-on-color-grey-050--warning-subdued--UNSAFE:          #94963e;
  --text-on-color-grey-050--danger:                           #e20028;
  --text-on-color-grey-050--danger-subdued--UNSAFE:           #e63e5c;

  /* GREY 100 ******************************************************************** */
  --color-grey-100:                                     hsl(20,1%,25%);
  --text-on-color-grey-100:                             hsl(20,1%,75%);
  --text-on-color-grey-100--subdued:                                    hsl(20,1%,70%);
  --text-on-color-grey-100--primary:                                    hsl(48, 23%,30%);
  --text-on-color-grey-100--primary-subdued--UNSAFE:                    hsl(48, 23%,35%);
  --text-on-color-grey-100--secondary:                                  hsl(210,63%,28%);
  --text-on-color-grey-100--secondary-subdued--UNSAFE:                  hsl(210,63%,30%);
  --text-on-color-grey-100--tertiary:                                   hsl(358,71%,28%);
  --text-on-color-grey-100--tertiary-subdued--UNSAFE:                   hsl(358,71%,32%);
  --text-on-color-grey-100--success:                                               #007937;
  --text-on-color-grey-100--success-subdued--UNSAFE:                               #399564;
  --text-on-color-grey-100--warning:                                               #6a6c00;
  --text-on-color-grey-100--warning-subdued--UNSAFE:                               #888b3b;
  --text-on-color-grey-100--danger:                                                #d30024;
  --text-on-color-grey-100--danger-subdued--UNSAFE:                                #d73a56;

  /* GREY 200 ******************************************************************** */
  --color-grey-200:                                                 hsl(20,1%,30%);
  --text-on-color-grey-200:                                             hsl(20,1%,80%);
  --text-on-color-grey-200--subdued:                                    hsl(20,1%,75%);
  --text-on-color-grey-200--primary:                                    hsl(48, 23%,25%);
  --text-on-color-grey-200--primary-subdued--UNSAFE:                    hsl(48, 23%,28%);
  --text-on-color-grey-200--secondary:                                  hsl(210,63%,24%);
  --text-on-color-grey-200--secondary-subdued--UNSAFE:                  hsl(210,63%,27%);
  --text-on-color-grey-200--tertiary:                                   hsl(358,71%,23%);
  --text-on-color-grey-200--tertiary-subdued--UNSAFE:                   hsl(358,71%,28%);
  --text-on-color-grey-200--success:                                              #006b30;
  --text-on-color-grey-200--success-subdued--UNSAFE:                              #33865a;
  --text-on-color-grey-200--warning:                                              #5e6000;
  --text-on-color-grey-200--warning-subdued--UNSAFE:                              #7a7e36;
  --text-on-color-grey-200--danger:                                               #bb001f;
  --text-on-color-grey-200--danger-subdued--UNSAFE:                               #bf364d;

  /* GREY 300 ******************************************************************** */
  --color-grey-300:                                                 hsl(18,1%,36%);
  --text-on-color-grey-300:                                             hsl(20,1%,80%);
  --text-on-color-grey-300--subdued--UNSAFE:                            hsl(20,1%,75%);
  --text-on-color-grey-300--primary:                                    hsl(48, 23%,20%);
  --text-on-color-grey-300--primary-subdued--UNSAFE:                    hsl(48, 23%,25%);
  --text-on-color-grey-300--secondary:                                  hsl(210,63%,20%);
  --text-on-color-grey-300--secondary-subdued--UNSAFE:                  hsl(210,63%,22%);
  --text-on-color-grey-300--tertiary:                                   hsl(358,71%,20%);
  --text-on-color-grey-300--tertiary-subdued--UNSAFE:                   hsl(358,71%,25%);
  --text-on-color-grey-300--success:                                               #005324;
  --text-on-color-grey-300--success-subdued--UNSAFE:                               #2c6c4a;
  --text-on-color-grey-300--warning:                                               #484a00;
  --text-on-color-grey-300--warning-subdued--UNSAFE:                               #61652f;
  --text-on-color-grey-300--danger:                                                #930016;
  --text-on-color-grey-300--danger-subdued--UNSAFE:                                #9a2e3f;

  /* GREY 400 ******************************************************************** */
  --color-grey-400:                                                  hsl(15,1%,43%);
  --text-on-color-grey-400:                                           hsl(20,1%,80%);
  --text-on-color-grey-400--subdued--UNSAFE:                          hsl(20,1%,75%);
  --text-on-color-grey-400--primary:                                  hsl(48, 23%,15%);
  --text-on-color-grey-400--primary-subdued--UNSAFE:                  hsl(48, 23%,10%);
  --text-on-color-grey-400--secondary:                                  hsl(210,63%,15%);
  --text-on-color-grey-400--secondary-subdued--UNSAFE:                  hsl(210,63%,10%);
  --text-on-color-grey-400--tertiary:                                   hsl(358,71%,10%);
  --text-on-color-grey-400--tertiary-subdued--UNSAFE:                   hsl(358,71%,15%);
  --text-on-color-grey-400--success:                                               #003716;
  --text-on-color-grey-400--success-subdued--UNSAFE:                               #234f37;
  --text-on-color-grey-400--warning:                                               #313100;
  --text-on-color-grey-400--warning-subdued--UNSAFE:                               #484b27;
  --text-on-color-grey-400--danger:                                                #66000a;
  --text-on-color-grey-400--danger-subdued--UNSAFE:                                #70262e;

  /* GREY 500 ******************************************************************** */
  --color-grey-500:                                                   hsl(14,1%,46%);
  --text-on-color-grey-500:                                             hsl(20,1%,85%);
  --text-on-color-grey-500--subdued--UNSAFE:                            hsl(20,1%,80%);
  --text-on-color-grey-500--primary:                                    hsl(48, 23%,83%);
  --text-on-color-grey-500--primary-subdued--UNSAFE:                    hsl(48, 23%,88%);
  --text-on-color-grey-500--secondary:                                  hsl(210,63%,86%);
  --text-on-color-grey-500--secondary-subdued--UNSAFE:                  hsl(210,63%,88%);
  --text-on-color-grey-500--tertiary:                                   hsl(358,71%,80%);
  --text-on-color-grey-500--tertiary-subdued--UNSAFE:                   hsl(358,71%,88%);
  --text-on-color-grey-500--success:                                               #f1fff3;
  --text-on-color-grey-500--success-subdued--UNSAFE:                               #d0ddd4;
  --text-on-color-grey-500--warning:                                               #fffce8;
  --text-on-color-grey-500--warning-subdued--UNSAFE:                               #dadbcc;
  --text-on-color-grey-500--danger:                                                #fffbfa;
  --text-on-color-grey-500--danger-subdued--UNSAFE:                                #dadada;

  /* GREY 600 ******************************************************************** */
  --color-grey-600:                                                    hsl(9,0%,39%);
  --text-on-color-grey-600:                                             hsl(20,1%,90%);
  --text-on-color-grey-600--subdued--UNSAFE:                            hsl(20,1%,85%);
  --text-on-color-grey-600--primary:                                    hsl(48, 23%,83%);
  --text-on-color-grey-600--primary-subdued--UNSAFE:                    hsl(48, 23%,88%);
  --text-on-color-grey-600--secondary:                                  hsl(210,63%,86%);
  --text-on-color-grey-600--secondary-subdued--UNSAFE:                  hsl(210,63%,88%);
  --text-on-color-grey-600--tertiary:                                   hsl(358,71%,80%);
  --text-on-color-grey-600--tertiary-subdued--UNSAFE:                   hsl(358,71%,88%);
  --text-on-color-grey-600--success:                                               #00f678;
  --text-on-color-grey-600--success-subdued--UNSAFE:                               #15d072;
  --text-on-color-grey-600--warning:                                               #dade00;
  --text-on-color-grey-600--warning-subdued--UNSAFE:                               #b8be18;
  --text-on-color-grey-600--danger:                                                #ffcac2;
  --text-on-color-grey-600--danger-subdued--UNSAFE:                                #d4afaa;

  /* GREY 700 ******************************************************************** */
  --color-grey-700:                                                   hsl(7,0%,29%);
  --text-on-color-grey-700:                                             hsl(20,1%,95%);
  --text-on-color-grey-700--subdued:                                    hsl(20,1%,90%);
  --text-on-color-grey-700--primary:                                    hsl(48, 23%,83%);
  --text-on-color-grey-700--primary-subdued--UNSAFE:                    hsl(48, 23%,88%);
  --text-on-color-grey-700--secondary:                                  hsl(210,63%,86%);
  --text-on-color-grey-700--secondary-subdued--UNSAFE:                  hsl(210,63%,88%);
  --text-on-color-grey-700--tertiary:                                   hsl(358,71%,80%);
  --text-on-color-grey-700--tertiary-subdued--UNSAFE:                   hsl(358,71%,88%);
  --text-on-color-grey-700--success:                                               #00c65f;
  --text-on-color-grey-700--success-subdued--UNSAFE:                               #0ea558;
  --text-on-color-grey-700--warning:                                               #afb300;
  --text-on-color-grey-700--warning-subdued--UNSAFE:                               #919711;
  --text-on-color-grey-700--danger:                                                #ff8b7e;
  --text-on-color-grey-700--danger-subdued--UNSAFE:                                #cd796f;

  /* GREY 800 ******************************************************************** */
  --color-grey-800:                                                    hsl(6,0%,20%);
  --text-on-color-grey-800:                                             hsl(20,1%,100%);
  --text-on-color-grey-800--subdued:                                    hsl(20,1%,95%);
  --text-on-color-grey-800--primary:                                    hsl(48, 23%,83%);
  --text-on-color-grey-800--primary-subdued--UNSAFE:                    hsl(48, 23%,88%);
  --text-on-color-grey-800--secondary:                                  hsl(210,63%,86%);
  --text-on-color-grey-800--secondary-subdued--UNSAFE:                  hsl(210,63%,80%);
  --text-on-color-grey-800--tertiary:                                   hsl(358,71%,80%);
  --text-on-color-grey-800--tertiary-subdued--UNSAFE:                   hsl(358,71%,88%);
  --text-on-color-grey-800--success:                                              #00a34d;
  --text-on-color-grey-800--success-subdued--UNSAFE:                              #088444;
  --text-on-color-grey-800--warning:                                              #909304;
  --text-on-color-grey-800--warning-subdued--UNSAFE:                              #74780d;
  --text-on-color-grey-800--danger:                                               #ff4c48;
  --text-on-color-grey-800--danger-subdued--UNSAFE:                               #c74340;

  /* GREY 900 ******************************************************************** */
  --color-grey-900:                                                   hsl(5,0%,10%);
  --text-on-color-grey-900:                                             hsl(20,1%,100%);
  --text-on-color-grey-900--subdued:                                    hsl(20,1%,95%);
  --text-on-color-grey-900--primary:                                    hsl(48, 23%,90%);
  --text-on-color-grey-900--primary-subdued--UNSAFE:                    hsl(48, 23%,95%);
  --text-on-color-grey-900--secondary:                                  hsl(210,63%,90%);
  --text-on-color-grey-900--secondary-subdued--UNSAFE:                  hsl(48, 23%,92%);
  --text-on-color-grey-900--tertiary:                                   hsl(358,71%,90%);
  --text-on-color-grey-900--tertiary-subdued--UNSAFE:                   hsl(358,71%,95%);
  --text-on-color-grey-900--success:                                               #008f42;
  --text-on-color-grey-900--success-subdued--UNSAFE:                               #037037;
  --text-on-color-grey-900--warning:                                               #7f8100;
  --text-on-color-grey-900--warning-subdued--UNSAFE:                               #626505;
  --text-on-color-grey-900--danger:                                                #fa002d;
  --text-on-color-grey-900--danger-subdued--UNSAFE:                                #be0527;

  
  /* primary 050 **************************************************************** */
  --color-primary-050:                                               hsl(48,6%,28%);

  --text-on-color-primary-050:                                       hsl(48,6%,78%);
  --text-on-color-primary-050--subdued:                                     #44423a;
  --text-on-color-primary-050--vivid:                                       #7c7300;
  --text-on-color-primary-050--vivid-subdued--UNSAFE:                       #9e9246;

  /* primary 100 **************************************************************** */
  --color-primary-100:                                               hsl(48,8%,34%);

  --text-on-color-primary-100:                                       hsl(48,6%,80%);
  --text-on-color-primary-100--subdued:                                     #423f35;
  --text-on-color-primary-100--vivid:                                       #726a00;
  --text-on-color-primary-100--vivid-subdued--UNSAFE:                       #92873d;

  /* primary 200 **************************************************************** */
  --color-primary-200:                                              hsl(48,10%,44%);

  --text-on-color-primary-200:                                       hsl(48,6%,90%);
  --text-on-color-primary-200--subdued:                                           #3e3c2d;
  --text-on-color-primary-200--vivid:                                             #655e00;
  --text-on-color-primary-200--vivid-subdued--UNSAFE:                             #837a30;

  /* primary 300 **************************************************************** */
  --color-primary-300:                                               hsl(48,16%,52%);

  --text-on-color-primary-300:                                       hsl(48,6%,90%);
  --text-on-color-primary-300--subdued--UNSAFE:                                   #393620;
  --text-on-color-primary-300--vivid:                                             #4e4800;
  --text-on-color-primary-300--vivid-subdued--UNSAFE:                             #6a621b;

  /* primary 400 **************************************************************** */
  --color-primary-400:                                              hsl(48,20%,65%);

  --text-on-color-primary-400:                                       hsl(48,6%,95%);
  --text-on-color-primary-400--subdued--UNSAFE:                                   #322f1c;
  --text-on-color-primary-400--vivid:                                             #343000;
  --text-on-color-primary-400--vivid-subdued--UNSAFE:                             #4d4816;

  /* primary 500 **************************************************************** */
  --color-primary-500:                                              hsl(48,23%,59%);

  --text-on-color-primary-500:                                      hsl(48,6%,95%);
  --text-on-color-primary-500--subdued--UNSAFE:                                   #dfdbcc;
  --text-on-color-primary-500--vivid:                                             #fffbec;
  --text-on-color-primary-500--vivid-subdued--UNSAFE:                             #ded8be;

  /* primary 600 **************************************************************** */
  --color-primary-600:                                              hsl(48,23%,52%);

  --text-on-color-primary-600:                                      hsl(48,6%,100%);
  --text-on-color-primary-600--subdued--UNSAFE:                                   #d7d4c7;
  --text-on-color-primary-600--vivid:                                             #e7d800;
  --text-on-color-primary-600--vivid-subdued--UNSAFE:                             #c5b820;

  /* primary 700 **************************************************************** */
  --color-primary-700:                                              hsl(48,23%,42%);

  --text-on-color-primary-700:                                      hsl(48,6%,100%);
  --text-on-color-primary-700--subdued:                                           #cecbc1;
  --text-on-color-primary-700--vivid:                                             #baad00;
  --text-on-color-primary-700--vivid-subdued--UNSAFE:                             #9c9015;

  /* primary 800 **************************************************************** */
  --color-primary-800:                                              hsl(48,23%,30%);

  --text-on-color-primary-800:                                      hsl(48,6%,100%);
  --text-on-color-primary-800--subdued:                                           #c4c3bd;
  --text-on-color-primary-800--vivid:                                             #9a9000;
  --text-on-color-primary-800--vivid-subdued--UNSAFE:                             #7c7310;

  /* primary 900 **************************************************************** */
  --color-primary-900:                                              hsl(48,23%,18%);

  --text-on-color-primary-900:                                       hsl(48,6%,100);
  --text-on-color-primary-900--subdued:                                           #bdbcb9;
  --text-on-color-primary-900--vivid:                                             #877e00;
  --text-on-color-primary-900--vivid-subdued--UNSAFE:                             #686009;

  --color-primary-lighter:                                     var(--color-primary-200);

   /* El Toro gold, #c1ba9d, branding guide ************************************ */
  --color-primary:                                          var(--color-primary-500);

  --color-primary-darker:                                 var(--color-primary-400);
  --text-on-color-primary-lighter:                                                 var(--text-on-color-primary-200);
  --text-on-color-primary-lighter--subdued--UNSAFE:                                var(--text-on-color-primary-200--subdued--UNSAFE);
  --text-on-color-primary-lighter--vivid:                                          var(--text-on-color-primary-200--vivid);
  --text-on-color-primary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-primary-200--vivid-subdued--UNSAFE);
  --text-on-color-primary:                                                         var(--text-on-color-primary-300);
  --text-on-color-primary--subdued--UNSAFE:                                        var(--text-on-color-primary-300--subdued--UNSAFE);
  --text-on-color-primary--vivid:                                                  var(--text-on-color-primary-300--vivid);
  --text-on-color-primary--vivid-subdued--UNSAFE:                                  var(--text-on-color-primary-300--vivid-subdued--UNSAFE);
  --text-on-color-primary-darker:                                                  var(--text-on-color-primary-400);
  --text-on-color-primary-darker--subdued--UNSAFE:                                 var(--text-on-color-primary-400--subdued--UNSAFE);
  --text-on-color-primary-darker--vivid:                                           var(--text-on-color-primary-400--vivid);
  --text-on-color-primary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-primary-400--vivid-subdued--UNSAFE);

  
  /* secondary 050 ***************************************************************** */
  --color-secondary-050:                                             hsl(210, 28%, 30%);

  --text-on-color-secondary-050:                                      hsl(209, 8%, 65%);
  --text-on-color-secondary-050--subdued:                                       #3e4249;
  --text-on-color-secondary-050--vivid:                                         #0075c0;
  --text-on-color-secondary-050--vivid-subdued--UNSAFE:                         #6094d0;

  /* secondary 100 ***************************************************************** */
  --color-secondary-100:                                            hsl(210, 35%, 37%);

  --text-on-color-secondary-100:                                      hsl(209, 8%, 70%);
  --text-on-color-secondary-100--subdued:                                            #394049;
  --text-on-color-secondary-100--vivid:                                              #006cb1;
  --text-on-color-secondary-100--vivid-subdued--UNSAFE:                              #5689c4;

  /* secondary 200 ***************************************************************** */
  --color-secondary-200:                                            hsl(210, 45%, 48%);

  --text-on-color-secondary-200:                                     hsl(209, 8%, 75%);
  --text-on-color-secondary-200--subdued:                                            #333c49;
  --text-on-color-secondary-200--vivid:                                              #005e9b;
  --text-on-color-secondary-200--vivid-subdued--UNSAFE:                              #467ab3;

  /* secondary 300 ***************************************************************** */
  --color-secondary-300:                                            hsl(210, 68%, 60%);

  --text-on-color-secondary-300:                                     hsl(209, 8%, 80%);
  --text-on-color-secondary-300--subdued--UNSAFE:                                    #273649;
  --text-on-color-secondary-300--vivid:                                              #004a7b;
  --text-on-color-secondary-300--vivid-subdued--UNSAFE:                              #2b649a;

  /* secondary 400 ***************************************************************** */
  --color-secondary-400:                                            hsl(210, 63%, 52%);

  --text-on-color-secondary-400:                                      hsl(209, 8%, 90%);
  --text-on-color-secondary-400--subdued--UNSAFE:                                    #193049;
  --text-on-color-secondary-400--vivid:                                              #003154;
  --text-on-color-secondary-400--vivid-subdued--UNSAFE:                              #004a7b;

  /* secondary 500 ***************************************************************** */
  --color-secondary-500:                                            hsl(210, 59%, 44%);

  --text-on-color-secondary-500:                                    hsl(209, 8%, 95%);
  --text-on-color-secondary-500--subdued--UNSAFE:                                    #cfdbf1;
  --text-on-color-secondary-500--vivid:                                              #f8fbff;
  --text-on-color-secondary-500--vivid-subdued--UNSAFE:                              #c7d9f0;

  /* secondary 600 ***************************************************************** */
  --color-secondary-600:                                            hsl(210, 63%, 35%);

  --text-on-color-secondary-600:                                     hsl(209, 8%, 95%);
  --text-on-color-secondary-600--subdued--UNSAFE:                                    #cad4e6;
  --text-on-color-secondary-600--vivid:                                              #bbd5ff;
  --text-on-color-secondary-600--vivid-subdued--UNSAFE:                              #96b6e6;

  /* secondary 700 ***************************************************************** */
  --color-secondary-700:                                            hsl(210, 63%, 29%);

  --text-on-color-secondary-700:                                    hsl(209, 8%, 100%);
  --text-on-color-secondary-700--subdued:                                            #c4ccda;
  --text-on-color-secondary-700--vivid:                                              #67afff;
  --text-on-color-secondary-700--vivid-subdued--UNSAFE:                              #4f92d9;

  /* secondary 800 ***************************************************************** */
  /* El Toro deep blue, #0e263e, branding guide ************************************ */
  --color-secondary-800:                                            hsl(210, 63%, 15%);

  --text-on-color-secondary-800:                                     hsl(209, 8%, 100%);
  --text-on-color-secondary-800--subdued:                                            #bec3cd;
  --text-on-color-secondary-800--vivid:                                              #0093ed;
  --text-on-color-secondary-800--vivid-subdued--UNSAFE:                              #0376c0;

  /* secondary 900 ***************************************************************** */
  --color-secondary-900:                                            hsl(210, 63%, 8%);

  --text-on-color-secondary-900:                                    hsl(209, 8%, 100%);
  --text-on-color-secondary-900--subdued:                                            #b9bcc3;
  --text-on-color-secondary-900--vivid:                                              #0080d1;
  --text-on-color-secondary-900--vivid-subdued--UNSAFE:                              #0262a1;

  --color-secondary-lighter:                                  var(--color-secondary-200);
  --color-secondary:                                          var(--color-secondary-300);
  --color-secondary-darker:                                   var(--color-secondary-400);
  --text-on-color-secondary-lighter:                              var(--text-on-color-secondary-400);
  --text-on-color-secondary-lighter--subdued--UNSAFE:     var(--text-on-color-secondary-700--subdued--UNSAFE);
  --text-on-color-secondary-lighter--vivid:                 var(--text-on-color-secondary-700--vivid);
  --text-on-color-secondary-lighter--vivid-subdued--UNSAFE: var(--text-on-color-secondary-700--vivid-subdued--UNSAFE);

  --text-on-color-secondary:                           var(--text-on-color-secondary-200);
  --text-on-color-secondary--subdued--UNSAFE:                  var(--text-on-color-secondary-800--subdued--UNSAFE);
  --text-on-color-secondary--vivid:                            var(--text-on-color-secondary-800--vivid);
  --text-on-color-secondary--vivid-subdued--UNSAFE:            var(--text-on-color-secondary-800--vivid-subdued--UNSAFE);
  --text-on-color-secondary-darker:                            var(--text-on-color-secondary-900);
  --text-on-color-secondary-darker--subdued--UNSAFE:           var(--text-on-color-secondary-900--subdued--UNSAFE);
  --text-on-color-secondary-darker--vivid:                      var(--text-on-color-secondary-900--vivid);
  --text-on-color-secondary-darker--vivid-subdued--UNSAFE:   var(--text-on-color-secondary-900--vivid-subdued--UNSAFE);


  /* tertiary 050 *************************************************************** */
  --color-tertiary-050:                                             hsl(358,22%,28%);

  --text-on-color-tertiary-050:                                      hsl(358,22%,72%);
  --text-on-color-tertiary-050--subdued:                                          #4a403e;
  --text-on-color-tertiary-050--vivid:                                            #e40026;
  --text-on-color-tertiary-050--vivid-subdued--UNSAFE:                            #f46054;

  /* tertiary 100 *************************************************************** */
  --color-tertiary-100:                                            hsl(358,36%,29%);

  --text-on-color-tertiary-100:                                      hsl(358,22%,78%);
  --text-on-color-tertiary-100--subdued:                                          #4a3c3a;
  --text-on-color-tertiary-100--vivid:                                            #d30023;
  --text-on-color-tertiary-100--vivid-subdued--UNSAFE:                            #e5574d;

  /* tertiary 200 *************************************************************** */
  --color-tertiary-200:                                            hsl(358,45%,33%);

  --text-on-color-tertiary-200:                                      hsl(358,22%,78%);
  --text-on-color-tertiary-200--subdued:                                          #493734;
  --text-on-color-tertiary-200--vivid:                                            #b9001d;
  --text-on-color-tertiary-200--vivid-subdued--UNSAFE:                            #cf4a42;

  /* tertiary 300 *************************************************************** */
  --color-tertiary-300:                                             hsl(358,52%,35%);

  --text-on-color-tertiary-300:                                       hsl(358,22%,85%);
  --text-on-color-tertiary-300--subdued--UNSAFE:                                  #4a2e2b;
  --text-on-color-tertiary-300--vivid:                                            #950015;
  --text-on-color-tertiary-300--vivid-subdued--UNSAFE:                            #b03631;

  /* tertiary 400 *************************************************************** */
  --color-tertiary-400:                                            hsl(358,63%,40%);

  --text-on-color-tertiary-400:                                      hsl(358,22%,85%);
  --text-on-color-tertiary-400--subdued--UNSAFE:                                  #462623;
  --text-on-color-tertiary-400--vivid:                                            #660009;
  --text-on-color-tertiary-400--vivid-subdued--UNSAFE:                            #862120;

  /* tertiary 500 *************************************************************** */
  --color-tertiary-500:                                             hsl(358,71%,40%);

  --text-on-color-tertiary-500:                                     hsl(358,22%,90%);
  --text-on-color-tertiary-500--subdued--UNSAFE:                                  #f8d2cc;
  --text-on-color-tertiary-500--vivid:                                            #fffbfa;
  --text-on-color-tertiary-500--vivid-subdued--UNSAFE:                            #f7d0c7;

  /* tertiary 600 *************************************************************** */
  --color-tertiary-600:                                            hsl(358,71%,35%);

  --text-on-color-tertiary-600:                                      hsl(358,22%,95%);
  --text-on-color-tertiary-600--subdued--UNSAFE:                                  #f5cac3;
  --text-on-color-tertiary-600--vivid:                                            #ffcac2;
  --text-on-color-tertiary-600--vivid-subdued--UNSAFE:                            #f0a398;

  /* tertiary 700 *************************************************************** */
  --color-tertiary-700:                                             hsl(358,71%,25%);

  --text-on-color-tertiary-700:                                     hsl(358,22%,95%);
  --text-on-color-tertiary-700--subdued:                                          #e4c4bf;
  --text-on-color-tertiary-700--vivid:                                            #ff897b;
  --text-on-color-tertiary-700--vivid-subdued--UNSAFE:                            #de6d61;

  /* tertiary 800 *************************************************************** */
  --color-tertiary-800:                                             hsl(358,71%,18%);

  --text-on-color-tertiary-800:                                      hsl(358,22%,100%);
  --text-on-color-tertiary-800--subdued:                                          #d4bebb;
  --text-on-color-tertiary-800--vivid:                                            #ff4945;
  --text-on-color-tertiary-800--vivid-subdued--UNSAFE:                            #cf3a36;

  /* tertiary 900 *************************************************************** */
  --color-tertiary-900:                                             hsl(358,71%,8%);

  --text-on-color-tertiary-900:                                      hsl(358,22%,100%);
  --text-on-color-tertiary-900--subdued:                                          #c3bab9;
  --text-on-color-tertiary-900--vivid:                                            #f8002b;
  --text-on-color-tertiary-900--vivid-subdued--UNSAFE:                            #bd1322;

  --color-tertiary-lighter:                                                   var(--color-tertiary-400);

   /* El Toro red, #af1e23, branding guide ************************************ */
  --color-tertiary:                                                  hsl(358,71%,40%);

  --color-tertiary-darker:                                                    var(--color-tertiary-600);
  --text-on-color-tertiary-lighter:                                                 var(--text-on-color-tertiary-500);
  --text-on-color-tertiary-lighter--subdued--UNSAFE:                                var(--text-on-color-tertiary-500--subdued--UNSAFE);
  --text-on-color-tertiary-lighter--vivid:                                          var(--text-on-color-tertiary-500--vivid);
  --text-on-color-tertiary-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-tertiary-500--vivid-subdued--UNSAFE);
  --text-on-color-tertiary:                                                         var(--text-on-color-tertiary-600);
  --text-on-color-tertiary--subdued--UNSAFE:                                        var(--text-on-color-tertiary-600--subdued--UNSAFE);
  --text-on-color-tertiary--vivid:                                                  var(--text-on-color-tertiary-600--vivid);
  --text-on-color-tertiary--vivid-subdued--UNSAFE:                                  var(--text-on-color-tertiary-600--vivid-subdued--UNSAFE);
  --text-on-color-tertiary-darker:                                                  var(--text-on-color-tertiary-700);
  --text-on-color-tertiary-darker--subdued--UNSAFE:                                 var(--text-on-color-tertiary-700--subdued--UNSAFE);
  --text-on-color-tertiary-darker--vivid:                                           var(--text-on-color-tertiary-700--vivid);
  --text-on-color-tertiary-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-tertiary-700--vivid-subdued--UNSAFE);



  /* SUCCESS 050 ***************************************************************** */
  --color-success-050:                                              hsl(148,20%,30%);

  --text-on-color-success-050:                                      hsl(148,20%,70%);
  --text-on-color-success-050--subdued:                                            #39453c;
  --text-on-color-success-050--vivid:                                              #00833d;
  --text-on-color-success-050--vivid-subdued--UNSAFE:                              #4fa164;

  /* SUCCESS 100 ***************************************************************** */
  --color-success-100:                                              hsl(148,26%,33%);

  --text-on-color-success-100:                                      hsl(148,20%,70%);
  --text-on-color-success-100--subdued:                                            #2b4531;
  --text-on-color-success-100--vivid:                                              #007937;
  --text-on-color-success-100--vivid-subdued--UNSAFE:                              #329953;

  /* SUCCESS 200 ***************************************************************** */
  --color-success-200:                                              hsl(147,35%,36%);

  --text-on-color-success-200:                                      hsl(148,20%,70%);
  --text-on-color-success-200--subdued:                                            #1a4327;
  --text-on-color-success-200--vivid:                                              #006b30;
  --text-on-color-success-200--vivid-subdued--UNSAFE:                              #018b41;

  /* SUCCESS 300 ***************************************************************** */
  --color-success-300:                                              hsl(147,42%,38%);

  --text-on-color-success-300:                                       hsl(148,20%,75%);
  --text-on-color-success-300--subdued--UNSAFE:                                    #183c23;
  --text-on-color-success-300--vivid:                                              #005324;
  --text-on-color-success-300--vivid-subdued--UNSAFE:                              #017033;

  /* SUCCESS 400 ***************************************************************** */
  --color-success-400:                                               hsl(147,60%,36%);

  --text-on-color-success-400:                                       hsl(148,20%,80%);
  --text-on-color-success-400--subdued--UNSAFE:                                    #15351f;
  --text-on-color-success-400--vivid:                                              #003716;
  --text-on-color-success-400--vivid-subdued--UNSAFE:                              #005224;

  /* SUCCESS 500 ***************************************************************** */
  --color-success-500:                                              hsl(147,75%,35%);

  --text-on-color-success-500:                                       hsl(148,20%,90%);
  --text-on-color-success-500--subdued--UNSAFE:                                    #c9e1cc;
  --text-on-color-success-500--vivid:                                              #effff1;
  --text-on-color-success-500--vivid-subdued--UNSAFE:                              #bce0c2;

  /* SUCCESS 600 ***************************************************************** */
  --color-success-600:                                              hsl(147,85%,30%);

  --text-on-color-success-600:                                      hsl(148,20%,90%);
  --text-on-color-success-600--subdued--UNSAFE:                                    #c4d9c7;
  --text-on-color-success-600--vivid:                                              #00f678;
  --text-on-color-success-600--vivid-subdued--UNSAFE:                              #00d165;

  /* SUCCESS 700 ***************************************************************** */
  --color-success-700:                                              hsl(147,91%,25%);

  --text-on-color-success-700:                                       hsl(148,20%,100%);
  --text-on-color-success-700--subdued:                                            #c0cfc2;
  --text-on-color-success-700--vivid:                                              #00c65f;
  --text-on-color-success-700--vivid-subdued--UNSAFE:                              #02a54e;

  /* SUCCESS 800 ***************************************************************** */
  --color-success-800:                                               hsl(147,91%,15%);

  --text-on-color-success-800:                                        hsl(148,20%,100%);
  --text-on-color-success-800--subdued:                                            #bbc6bc;
  --text-on-color-success-800--vivid:                                              #00a34d;
  --text-on-color-success-800--vivid-subdued--UNSAFE:                              #00833d;

  /* SUCCESS 900 ***************************************************************** */
  --color-success-900:                                               hsl(147,91%,5%);

  --text-on-color-success-900:                                       hsl(148,20%,100%);
  --text-on-color-success-900--subdued:                                            #b9bdb9;
  --text-on-color-success-900--vivid:                                              #008f42;
  --text-on-color-success-900--vivid-subdued--UNSAFE:                              #0c6d33;

  --color-success-lighter:                                                   var(--color-success-400);
  --color-success:                                                           var(--color-success-500);
  --color-success-darker:                                                    var(--color-success-600);
  --text-on-color-success-lighter:                                                 var(--text-on-color-success-400);
  --text-on-color-success-lighter--subdued--UNSAFE:                                var(--text-on-color-success-400--subdued--UNSAFE);
  --text-on-color-success-lighter--vivid:                                          var(--text-on-color-success-400--vivid);
  --text-on-color-success-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-success-400--vivid-subdued--UNSAFE);
  --text-on-color-success:                                                         var(--text-on-color-success-500);
  --text-on-color-success--subdued--UNSAFE:                                        var(--text-on-color-success-500--subdued--UNSAFE);
  --text-on-color-success--vivid:                                                  var(--text-on-color-success-500--vivid);
  --text-on-color-success--vivid-subdued--UNSAFE:                                  var(--text-on-color-success-500--vivid-subdued--UNSAFE);
  --text-on-color-success-darker:                                                  var(--text-on-color-success-600);
  --text-on-color-success-darker--subdued--UNSAFE:                                 var(--text-on-color-success-600--subdued--UNSAFE);
  --text-on-color-success-darker--vivid:                                           var(--text-on-color-success-600--vivid);
  --text-on-color-success-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-success-600--vivid-subdued--UNSAFE);




  /* WARNING 050 ***************************************************************** */
  --color-warning-050:                                               hsl(37,13%,29%);

  --text-on-color-warning-050:                                       hsl(37,13%,70%);
  --text-on-color-warning-050--subdued:                                            #43423b;
  --text-on-color-warning-050--vivid:                                              #747600;
  --text-on-color-warning-050--vivid-subdued--UNSAFE:                              #969447;

  /* WARNING 100 ***************************************************************** */
  --color-warning-100:                                               hsl(37,16%,34%);

  --text-on-color-warning-100:                                       hsl(37,13%,70%);
  --text-on-color-warning-100--subdued:                                            #404035;
  --text-on-color-warning-100--vivid:                                              #6a6c00;
  --text-on-color-warning-100--vivid-subdued--UNSAFE:                              #8b893d;

  /* WARNING 200 ***************************************************************** */
  --color-warning-200:                                               hsl(37,32%,37%);

  --text-on-color-warning-200:                                        hsl(37,13%,70%);
  --text-on-color-warning-200--subdued:                                            #3d3c2b;
  --text-on-color-warning-200--vivid:                                              #5e6000;
  --text-on-color-warning-200--vivid-subdued--UNSAFE:                              #7d7c2e;

  /* WARNING 300 ***************************************************************** */
  --color-warning-300:                                               hsl(37,48%,40%);

  --text-on-color-warning-300:                                        hsl(37,13%,80%);
  --text-on-color-warning-300--subdued--UNSAFE:                                    #37361a;
  --text-on-color-warning-300--vivid:                                              #4a4b00;
  --text-on-color-warning-300--vivid-subdued--UNSAFE:                              #656512;

  /* WARNING 400 ***************************************************************** */
  --color-warning-400:                                               hsl(37,60%,43%);

  --text-on-color-warning-400:                                        hsl(37,13%,80%);
  --text-on-color-warning-400--subdued--UNSAFE:                                    #303011;
  --text-on-color-warning-400--vivid:                                              #313100;
  --text-on-color-warning-400--vivid-subdued--UNSAFE:                              #494a04;

  /* WARNING 500 ***************************************************************** */
  --color-warning-500:                                                hsl(37,80%,45%);

  --text-on-color-warning-500:                                        hsl(37,13%,90%);
  --text-on-color-warning-500--subdued--UNSAFE:                                    #dfdcc1;
  --text-on-color-warning-500--vivid:                                              #fffce2;
  --text-on-color-warning-500--vivid-subdued--UNSAFE:                              #dedaac;

  /* WARNING 600 ***************************************************************** */
  --color-warning-600:                                               hsl(37,98%,40%);

  --text-on-color-warning-600:                                        hsl(37,13%,90%);
  --text-on-color-warning-600--subdued--UNSAFE:                                    #d7d5be;
  --text-on-color-warning-600--vivid:                                              #dade00;
  --text-on-color-warning-600--vivid-subdued--UNSAFE:                              #b9bd00;

  /* WARNING 700 ***************************************************************** */
  --color-warning-700:                                               hsl(37,98%,35%);

  --text-on-color-warning-700:                                       hsl(37,13%,100%);
  --text-on-color-warning-700--subdued:                                            #ceccbb;
  --text-on-color-warning-700--vivid:                                              #afb300;
  --text-on-color-warning-700--vivid-subdued--UNSAFE:                              #929500;

  /* WARNING 800 ***************************************************************** */
  --color-warning-800:                                               hsl(37,98%,20%);

  --text-on-color-warning-800:                                      hsl(37,13%,100%);
  --text-on-color-warning-800--subdued:                                            #c5c3b9;
  --text-on-color-warning-800--vivid:                                              #8f9100;
  --text-on-color-warning-800--vivid-subdued--UNSAFE:                              #737505;

  /* WARNING 900 ***************************************************************** */
  --color-warning-900:                                                hsl(37,98%,10%);

  --text-on-color-warning-900:                                          hsl(37,13%,100%);
  --text-on-color-warning-900--subdued:                                            #bdbcb9;
  --text-on-color-warning-900--vivid:                                              #7f8100;
  --text-on-color-warning-900--vivid-subdued--UNSAFE:                              #62620a;

  

  --color-warning-lighter:                                                   var(--color-warning-400);
  --color-warning:                                                           var(--color-warning-500);
  --color-warning-darker:                                                    var(--color-warning-600);
  --text-on-color-warning-lighter:                                                 var(--text-on-color-warning-400);
  --text-on-color-warning-lighter--subdued--UNSAFE:                                var(--text-on-color-warning-400--subdued--UNSAFE);
  --text-on-color-warning-lighter--vivid:                                          var(--text-on-color-warning-400--vivid);
  --text-on-color-warning-lighter--vivid-subdued--UNSAFE:                          var(--text-on-color-warning-400--vivid-subdued--UNSAFE);
  --text-on-color-warning:                                                         var(--text-on-color-warning-500);
  --text-on-color-warning--subdued--UNSAFE:                                        var(--text-on-color-warning-500--subdued--UNSAFE);
  --text-on-color-warning--vivid:                                                  var(--text-on-color-warning-500--vivid);
  --text-on-color-warning--vivid-subdued--UNSAFE:                                  var(--text-on-color-warning-500--vivid-subdued--UNSAFE);
  --text-on-color-warning-darker:                                                  var(--text-on-color-warning-600);
  --text-on-color-warning-darker--subdued--UNSAFE:                                 var(--text-on-color-warning-600--subdued--UNSAFE);
  --text-on-color-warning-darker--vivid:                                           var(--text-on-color-warning-600--vivid);
  --text-on-color-warning-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-warning-600--vivid-subdued--UNSAFE);




  /* DANGER 050 ****************************************************************** */
  --color-danger-050:                                                 hsl(0,16%,30%);

  --text-on-color-danger-050:                                         hsl(0,16%,70%);
  --text-on-color-danger-050--subdued:                                             #4a403e;
  --text-on-color-danger-050--vivid:                                               #e20028;
  --text-on-color-danger-050--vivid-subdued--UNSAFE:                               #f26055;

  /* DANGER 100 ****************************************************************** */
  --color-danger-100:                                                 hsl(0,20%,35%);

  --text-on-color-danger-100:                                         hsl(0,16%,70%);
  --text-on-color-danger-100--subdued:                                             #4a3c3a;
  --text-on-color-danger-100--vivid:                                               #d30024;
  --text-on-color-danger-100--vivid-subdued--UNSAFE:                               #e5574e;

  /* DANGER 200 ****************************************************************** */
  --color-danger-200:                                                 hsl(0,35%,39%);

  --text-on-color-danger-200:                                         hsl(0,16%,80%);
  --text-on-color-danger-200--subdued:                                             #493734;
  --text-on-color-danger-200--vivid:                                               #bb001f;
  --text-on-color-danger-200--vivid-subdued--UNSAFE:                               #d14a44;

  /* DANGER 300 ****************************************************************** */
  --color-danger-300:                                                 hsl(0,50%,43%);

  --text-on-color-danger-300:                                         hsl(0,16%,80%);
  --text-on-color-danger-300--subdued--UNSAFE:                                     #4a2e2b;
  --text-on-color-danger-300--vivid:                                               #950016;
  --text-on-color-danger-300--vivid-subdued--UNSAFE:                               #b13532;

  /* DANGER 400 ****************************************************************** */
  --color-danger-400:                                                 hsl(0,58%,46%);

  --text-on-color-danger-400:                                         hsl(0,16%,90%);
  --text-on-color-danger-400--subdued--UNSAFE:                                     #4b231f;
  --text-on-color-danger-400--vivid:                                               #66000a;
  --text-on-color-danger-400--vivid-subdued--UNSAFE:                               #8a1a1d;

  /* DANGER 500 ****************************************************************** */
  --color-danger-500:                                                 hsl(0,68%,50%);

  --text-on-color-danger-500:                                         hsl(0,16%,90%);
  --text-on-color-danger-500--subdued--UNSAFE:                                     #ffcdc5;
  --text-on-color-danger-500--vivid:                                               #fffaf9;
  --text-on-color-danger-500--vivid-subdued--UNSAFE:                               #ffcabf;

  /* DANGER 600 ****************************************************************** */
  --color-danger-600:                                                 hsl(0,98%,40%);

  --text-on-color-danger-600:                                         hsl(0,16%,90%);
  --text-on-color-danger-600--subdued--UNSAFE:                             #f9c9c2;
  --text-on-color-danger-600--vivid:                                       #ffcac2;
  --text-on-color-danger-600--vivid-subdued--UNSAFE:                        #f3a196;

  /* DANGER 700 ****************************************************************** */
  --color-danger-700:                                                  hsl(0,98%,30%);

  --text-on-color-danger-700:                                          hsl(0,16%,100%);
  --text-on-color-danger-700--subdued:                                 hsl(0,16%,90%);
  --text-on-color-danger-700--vivid:                                          #ff8b7e;
  --text-on-color-danger-700--vivid-subdued--UNSAFE:                          #e16c61;

  /* DANGER 800 ****************************************************************** */
  --color-danger-800:                                                  hsl(0,98%,20%);

  --text-on-color-danger-800:                                         hsl(0,16%,100%);
  --text-on-color-danger-800--subdued:                                        #d8bdb9;
  --text-on-color-danger-800--vivid:                                          #ff4645;
  --text-on-color-danger-800--vivid-subdued--UNSAFE:                          #d13434;

  /* DANGER 900 ****************************************************************** */
  --color-danger-900:                                                 hsl(0,98%,10%);

  --text-on-color-danger-900:                                        hsl(0,16%,100%);
  --text-on-color-danger-900--subdued:                                       #c3bab9;
  --text-on-color-danger-900--vivid:                                         #fa002d;
  --text-on-color-danger-900--vivid-subdued--UNSAFE:                         #be1323;

  --color-danger-lighter:                                    var(--color-danger-400);
  --color-danger:                                            var(--color-danger-500);
  --color-danger-darker:                                     var(--color-danger-600);
  --text-on-color-danger-lighter:                            var(--text-on-color-danger-400);
  --text-on-color-danger-lighter--subdued--UNSAFE: var(--text-on-color-danger-400--subdued--UNSAFE);
  --text-on-color-danger-lighter--vivid:      var(--text-on-color-danger-400--vivid);
  --text-on-color-danger-lighter--vivid-subdued--UNSAFE: var(--text-on-color-danger-400--vivid-subdued--UNSAFE);
  --text-on-color-danger:                            var(--text-on-color-danger-500);
  --text-on-color-danger--subdued--UNSAFE: var(--text-on-color-danger-500--subdued--UNSAFE);
  --text-on-color-danger--vivid:              var(--text-on-color-danger-500--vivid);
  --text-on-color-danger--vivid-subdued--UNSAFE:                                  var(--text-on-color-danger-500--vivid-subdued--UNSAFE);
  --text-on-color-danger-darker:                                                  var(--text-on-color-danger-600);
  --text-on-color-danger-darker--subdued--UNSAFE:                                 var(--text-on-color-danger-600--subdued--UNSAFE);
  --text-on-color-danger-darker--vivid:                                           var(--text-on-color-danger-600--vivid);
  --text-on-color-danger-darker--vivid-subdued--UNSAFE:                           var(--text-on-color-danger-600--vivid-subdued--UNSAFE);

  /* Teal */
  --color-teal:                                        #66b2b2;



  --pixels-1:                                                                0.0625rem;
  --pixels-2:                                                                0.125rem;
  --pixels-3:                                                                0.1875rem;
  --pixels-4:                                                                0.25rem;
  --pixels-5:                                                                0.3125rem;
  --pixels-6:                                                                0.375rem;
  --pixels-7:                                                                0.4375rem;
  --pixels-8:                                                                0.5rem;
  
 /* change styles so text would be responsive using clamp(Min-value, Preferred-value, Max-value) uses calc /* @link https://utopia.fyi/type/calculator?c=320,10,1.125,1366,10,1.25,7,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
--text-size-xs:               clamp(8px, calc(0.5rem + ((1vw - 4.8px) * 0.5)), 12.5px);
--text-size-vs:           clamp(8.5px, calc(0.53125rem + ((1vw - 4.8px) * 0.5)), 13px);
--text-size-s:                         clamp(0.79rem, calc(0.76rem + 0.11vw), 0.80rem);
--text-size-sm:           clamp(9px, calc(0.5625rem + ((1vw - 4.8px) * 0.5556)), 14px);
--text-size-m:                         clamp(0.80rem, calc(0.79rem + 0.19vw), 0.86rem);
--text-size-ml:          clamp(12px, calc(0.75rem + ((1vw - 4.8px) * 0.3889)), 15.5px);
--text-size-l:        clamp(0.84rem, calc(0.80rem + 0.40vw), 1.15rem);
--text-size-lg:   clamp(13px, calc(0.8125rem + ((1vw - 4.8px) * 0.5556)), 18px);
--text-size-xl:         clamp(0.96rem, calc(0.82rem + 0.71vw), 1.41rem);
--text-size-xxl:        clamp(1.08rem, calc(0.85rem + 0.98vw), 1.70rem);
--text-size-xxxl:       clamp(1.13rem, calc(0.89rem + 1.20vw), 1.91rem);
--text-size-4xl:        clamp(1.27rem, calc(0.93rem + 1.71vw), 2.38rem);
--text-size-5xl:        clamp(1.43rem, calc(0.95rem + 2.38vw), 2.98rem);
--text-size-6xl:        clamp(1.75rem, calc(0.95rem + 3vw), 3.28rem);

  --line-height-xs:                                                          1.05;
  --line-height-s:                                                           1.125;
  --line-height-m:                                                           1.25;
  --line-height-l:                                                           1.025;
  --line-height-xl:                                                          1.015;
  --line-height-xxl:                                                         1;
  --line-height-xxxl:                                                        0.8;
  --line-height-4xl:                                                         0.7;

  --border-radius-xs:                                                        0.125rem;
  --border-radius-s:                                                         0.25rem;
  --border-radius-m:                                                         0.5rem;
  --border-radius-l:                                                         0.75rem;
  --border-radius-xl:                                                        1rem;
  --border-radius-2xl:                                                       2rem;

  --text-weight-bold:                                                        700;
  --text-weight-semibold:                                                    600;
  --text-weight-medium:                                                      600;
  --text-weight-normal:                                                      400;
  --text-weight-regular:                                                     400;
  --text-weight-light:                                                       300;

  --transition-150:                                                          150ms cubic-bezier(0.25, 0.8, 0.25, 1);
  --transition-300:                                                          300ms cubic-bezier(0.25, 0.8, 0.25, 1);

  --border-width-thin:                                                       0.0625rem;
  --border-width-default:                                                    0.125rem;

  --focus-ring:                                0 0 0 2px var(--color-primary);
  --focus-ring-inset:                    0 0 0 2px inset var(--color-primary);

  --box-shadow-100: 0 0 3px  rgba(0, 0, 0, 0.02),     0 1px  3px  rgba(0, 0, 0, 0.04),   0 1px  2px  rgba(0, 0, 0, 0.08);
  --box-shadow-100-dark: 0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-100-focus: 0 0 0 2px var(--color-primary),    0 1px  3px  rgba(0, 0, 0, 0.12),     0 1px  2px  rgba(0, 0, 0, 0.24);
  --box-shadow-200:                                                          0 0    6px  rgba(0, 0, 0, 0.02665),  0 3px  6px  rgba(0, 0, 0, 0.0533), 0 3px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-200-dark:                                                     0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-200-focus:                0 0 0 2px var(--color-primary),    0 3px  6px  rgba(0, 0, 0, 0.16),     0 3px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300:                                                          0 0    20px rgba(0, 0, 0, 0.03165),  0 10px 20px rgba(0, 0, 0, 0.0633), 0 6px  6px  rgba(0, 0, 0, 0.0766);
  --box-shadow-300-dark:                                                     0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-300-focus:                0 0 0 2px var(--color-primary),    0 10px 20px rgba(0, 0, 0, 0.19),     0 6px  6px  rgba(0, 0, 0, 0.23);
  --box-shadow-400:                                                          0 0    28px rgba(0, 0, 0, 0.04165),  0 14px 28px rgba(0, 0, 0, 0.0833), 0 10px 10px rgba(0, 0, 0, 0.0733);
  --box-shadow-400-dark:                                                     0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-400-focus:                0 0 0 2px var(--color-primary),    0 14px 28px rgba(0, 0, 0, 0.25),     0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-500:                                                          0 0    38px rgba(0, 0, 0, 0.05),     0 19px 38px rgba(0, 0, 0, 0.1),    0 15px 12px rgba(0, 0, 0, 0.0733);
  --box-shadow-500-dark:                                                     0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);
  --box-shadow-500-focus:                0 0 0 2px var(--color-primary),    0 19px 38px rgba(0, 0, 0, 0.3),      0 15px 12px rgba(0, 0, 0, 0.22);

/* darker, bolder box-shadow with more layer values */
  --box-shadow-600:
  0px 0px 2.2px rgba(0, 0, 0, 0.028),
  0px 0px 5.3px rgba(0, 0, 0, 0.04),
  0px 0px 10px rgba(0, 0, 0, 0.043),
  0px 0px 17.9px rgba(0, 0, 0, 0.043),
  0px 0px 33.4px rgba(0, 0, 0, 0.047),
  0px 0px 80px rgba(0, 0, 0, 0.08)
;
--box-shadow-600-dark:
  0px 0px 2.2px rgba(0, 0, 0, 0.043),
  0px 0px 5.3px rgba(0, 0, 0, 0.06),
  0px 0px 10px rgba(0, 0, 0, 0.065),
  0px 0px 17.9px rgba(0, 0, 0, 0.065),
  0px 0px 33.4px rgba(0, 0, 0, 0.07),
  0px 0px 80px rgba(0, 0, 0, 0.12)
;
--box-shadow-600-focus:
  0px 0px 2.2px rgba(0, 0, 0, 0.061),
  0px 0px 5.3px rgba(0, 0, 0, 0.071),
  0px 0px 10px rgba(0, 0, 0, 0.074),
  0px 0px 17.9px rgba(0, 0, 0, 0.077),
  0px 0px 33.4px rgba(0, 0, 0, 0.085),
  0px 0px 80px rgba(0, 0, 0, 0.12);
  /* darker, bolder inset box-shadows */
--box-inset-100: inset 0px 0px 7px 0px rgba(0, 0, 0, 0.57);
--box-inset-200: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.68);
--box-inset-300: inset 2px 2px 16px 0px rgba(0, 0, 0, 0.69);
--box-inset-ol: inset 0 0.6875rem 0.5rem -0.625rem var(--base-gray), inset 0 -0.6875rem 0.5rem -0.625rem var(--base-gray);
}`
