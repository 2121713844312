/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Input to Archive an campaign.
*/
export class V1ArchiveCampaignRequest {
    /**
    * The id of the campaign to archive
    */
    'campaignId'?: string;
    /**
    * The ID of the Org associated to the Campaign.
    */
    'orgId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ArchiveCampaignRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

