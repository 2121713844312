import { MouseEvent, Ref, MutableRefObject } from 'react'
import './Button.scss'
import classNames from 'classnames'
import * as CSS from 'csstype'
import { Link, LinkProps } from 'react-router-dom'

type ButtonProps = {
  children?: string | JSX.Element
  iconCenter?: JSX.Element
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  iconOnly?: JSX.Element
  className?: string
  classNameText?: string
  classNameWrap?: string
  rounded?: boolean
  type?: 'button' | 'submit'
  kind?:
    | 'default'
    | 'primary'
    | 'danger'
    | 'text'
    | 'text-icon'
    | 'default-grey'
    | 'primary-grey'
    | 'primary-light'
  size?: 's' | 'm' | 'l' | 'xl'
  width?: string
  UNSAFE_style?: CSS.Properties
  disabled?: boolean
  onMouseOver?: (event?: MouseEvent) => void
  onMouseOut?: (event?: MouseEvent) => void
  title?: string
  ariaLabel?: string
  dataTooltip?: string
} & (
  | { onClick?: (event?: MouseEvent) => void; to?: undefined }
  | { onClick?: undefined; to?: LinkProps['to'] }
)
type RefProps =
  | {
      buttonRef?: Ref<HTMLButtonElement> | MutableRefObject<HTMLButtonElement>
      linkRef?: undefined
    }
  | {
      linkRef?: Ref<HTMLAnchorElement> | MutableRefObject<HTMLAnchorElement>
      buttonRef?: undefined
    }

export const Button = ({
  children,
  iconCenter,
  iconLeft,
  iconRight,
  iconOnly,
  onClick,
  className = '',
  classNameWrap = '',
  classNameText = '',
  rounded = false,
  type,
  kind = 'default',
  size = 'm',
  width,
  UNSAFE_style,
  disabled = false,
  onMouseOver,
  onMouseOut,
  to,
  buttonRef,
  linkRef,
  title,
  ariaLabel,
  dataTooltip,
}: ButtonProps & RefProps) => {
  const sizeClass = ` Button--${size}-size`

  const wrapperClasses = classNames('Button', sizeClass, className, {
    'Button Button--default-grey': kind === 'default-grey',
    'Button Button--primary': kind === 'primary',
    'Button Button--primary-grey': kind === 'primary-grey',
    'Button Button--primary-light': kind === 'primary-light',
    'Button Button--danger': kind === 'danger',
    'Button Button--text': kind === 'text',
    'Button Button--text-icon': kind === 'text-icon',
    'Button Button--disabled': disabled,
    'Button Button--rounded rounded-3xl': rounded,
    'Button Button--has-center-icon': !!iconCenter,
    'Button Button--has-right-icon': !!iconRight,
    'Button Button--has-left-icon': !!iconLeft,
    'Button Button--has-icon-only': !!iconOnly,
  })

  const handleMouseOver = (event: MouseEvent) => {
    if (onMouseOver) {
      onMouseOver(event)
    }
  }
  const handleMouseOut = (event: MouseEvent) => {
    if (onMouseOut) {
      onMouseOut(event)
    }
  }

  const handleClick = (event: MouseEvent) => {
    if (onClick && !disabled) {
      onClick(event)
    }
  }

  const customStyle: CSS.Properties = { width, ...UNSAFE_style }

  const RenderIcon = ({ icon }: { icon: JSX.Element }) => icon

  const buttonContent = (
    <>
      {iconCenter ? (
        <span
          className={`Button__icon-wrapper IconCenter flex justify-center items-center ${classNameWrap}`}
        >
          <RenderIcon icon={iconCenter} />
        </span>
      ) : null}
      {iconLeft ? (
        <span
          className={`Button__icon-wrapper IconCenter flex justify-center items-center ${classNameWrap}`}
        >
          <RenderIcon icon={iconLeft} />
        </span>
      ) : null}
      {children ? (
        <span className={`Button__text ${classNameText}`}>{children}</span>
      ) : null}
      {iconRight ? (
        <span
          className={`Button__icon-wrapper IconCenter flex justify-center items-center ${classNameWrap}`}
        >
          <RenderIcon icon={iconRight} />
        </span>
      ) : null}
      {iconOnly ? (
        <span
          className={`Button__icon-wrapper IconCenter flex justify-center items-center ${classNameWrap}`}
        >
          <RenderIcon icon={iconOnly} />
        </span>
      ) : null}
    </>
  )

  const commonProps = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    style: customStyle,
    onFocus: () => false,
    onBlur: () => false,
    title,
  }

  const linkProps = {
    ...commonProps,
    ref: linkRef,
    className: `${wrapperClasses} Button__link`,
  }

  const buttonProps = {
    ...commonProps,
    onClick: handleClick,
    disabled,
    type: type || 'button',
    className: wrapperClasses,
    ref: buttonRef,
  }

  const renderButton = (passedType: 'button' | 'submit' | undefined) => {
    if (passedType === 'submit') {
      return (
        <button
          aria-label={ariaLabel}
          data-tooltip={dataTooltip}
          {...buttonProps}
          type="submit"
        >
          {buttonContent}
        </button>
      )
    }
    return (
      <button
        aria-label={ariaLabel}
        data-tooltip={dataTooltip}
        {...buttonProps}
        type="button"
      >
        {buttonContent}
      </button>
    )
  }

  return to && !disabled ? (
    <Link {...linkProps} to={to}>
      {buttonContent}
    </Link>
  ) : (
    renderButton(type)
  )
}
