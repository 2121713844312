import { getApiUrl } from 'Helpers'
import axios from 'axios'

export const uploadTOSFile = async (
  file: File,
  meta: {
    first_name: string
    last_name: string
    org_id: string
    org_name: string
    user_id: string
    version?: string
  },
  onProgress?: (percentage: number) => void,
) => {
  const token = localStorage.getItem('eltoro_token')
  const config = { Authorization: `Bearer ${token}` }

  const url = `${getApiUrl()}/v1`
  const data = new FormData()
  data.append('metadata', JSON.stringify(meta))
  data.append('chunk', file)

  const res = await axios.post(`${url}/tos:upload`, data, {
    headers: config,
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      if (onProgress) onProgress(percentage)
    },
  })

  if (res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }

  return res.data
}
