export const EmptyChildOrgsSVG = () => {
  return (
    <svg
      className="EmptyChildOrgsSVG desktop:w-1/3 laptop:w-3/4 m-auto flex h-full items-center justify-center"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 809.67 462.58"
      xmlSpace="preserve"
    >
      <path
        d="M133.09 211.44V102.12c0-3.25 3.13-5.89 7-5.89s7 2.64 7 5.89v109.33c0 3.25-3.13 5.89-7 5.89s-7-2.65-7-5.9zm-16.75 0V102.12c0-3.25 3.13-5.89 7-5.89s7 2.64 7 5.89v109.33c0 3.25-3.13 5.89-7 5.89s-7-2.65-7-5.9zm-16.75 0V102.12c0-3.25 3.13-5.89 7-5.89s7 2.64 7 5.89v109.33c0 3.25-3.13 5.89-7 5.89s-7-2.65-7-5.9zm336.5 2.2v-68.38c0-2.03 3.14-3.68 7-3.68s7 1.65 7 3.68v68.38c0 2.03-3.14 3.68-7 3.68s-7-1.64-7-3.68zm-16.75 0v-68.38c0-2.03 3.14-3.68 7-3.68s7 1.65 7 3.68v68.38c0 2.03-3.14 3.68-7 3.68s-7-1.64-7-3.68zm-16.75 0v-68.38c0-2.03 3.14-3.68 7-3.68s7 1.65 7 3.68v68.38c0 2.03-3.14 3.68-7 3.68s-7-1.64-7-3.68z"
        style={{ opacity: '.71', fill: '#999fdd' }}
      />
      <path
        d="M412.34 249.47v19.11h142v-30c-.01-12.42-10.08-22.49-22.5-22.5h-425c-12.42.01-22.49 10.08-22.5 22.5v213.11c.01 5.18 4.21 9.39 9.39 9.39h4.22c5.18-.01 9.39-4.21 9.39-9.39V249.47c.01-4.63 3.76-8.39 8.39-8.39h288.22c4.63 0 8.38 3.76 8.39 8.39zm0 106.11v96.11c.01 5.18 4.21 9.39 9.39 9.39h123.21c5.19-.01 9.39-4.21 9.39-9.39v-96.11H412.34zm0-81h142v75h-142v-75zm275.11-13.57c17.62-2.4 30.01-8.06 30.01-14.68 0-8.78-21.84-15.9-48.79-15.9s-48.79 7.12-48.79 15.9c0 7.67 16.68 14.07 38.86 15.57l-36.91 198.82 6.14-.15 20.32-107.85h49.75l20.32 107.85 6.47.15-37.38-199.71zm-22.09 1.18c1.1.02 2.2.04 3.32.04 4.22 0 8.32-.18 12.23-.5l8.67 46h-32.79l8.57-45.54zm-15.55 82.54 5.47-29h35.81l5.46 29h-46.74zm-392.58 51.63v-29.2h-9.16v-46.71h-13.74v46.71h-9.16v29.19l-67.56 45.84a10.045 10.045 0 0 0-5.71-1.78c-5.56 0-10.07 4.51-10.07 10.07s4.51 10.07 10.07 10.07 10.07-4.51 10.07-10.07c0-.77-.09-1.51-.26-2.23l63.45-43.05v15.07h11.9v20.84c-3.75 1.47-6.41 5.11-6.41 9.38 0 5.56 4.51 10.07 10.07 10.07s10.07-4.51 10.07-10.07c0-4.27-2.66-7.91-6.41-9.38v-20.84h12.82v-15.06l71.21 48.32 4.11-6.06-75.29-51.11z"
        style={{ opacity: '.7', fill: '#b5b4b6' }}
      />
      <path
        d="m553.96 53.63-48.64-26.07c-1.59-.86-3.5-.94-5.16-.21a5.981 5.981 0 0 0-3.41 3.99 141.5 141.5 0 0 1-31.07 57.98 5.998 5.998 0 0 0-1.44 5.05 5.865 5.865 0 0 0 3.03 4.18l48.65 26.07c2.57 1.37 5.75.68 7.53-1.63a231.71 231.71 0 0 0 33.32-62.19c.95-2.76-.24-5.79-2.81-7.17zm-1.1 5.78a227.766 227.766 0 0 1-32.73 61.08c-.54.67-1.48.87-2.24.46l-48.64-26.07a1.72 1.72 0 0 1-.9-1.24c-.1-.56.06-1.14.45-1.56a145.788 145.788 0 0 0 31.98-59.69c.14-.55.53-1.01 1.05-1.24.49-.22 1.06-.2 1.54.06L552 57.29c.76.41 1.12 1.3.86 2.12z"
        style={{ fill: '#b4b1b2' }}
      />
      <path
        d="M354.38 170.68H187.61a3.35 3.35 0 0 1-3.35-3.35V75.89c0-1.85 1.5-3.35 3.35-3.35h166.78c1.85 0 3.35 1.5 3.35 3.35v91.44a3.365 3.365 0 0 1-3.36 3.35z"
        style={{ opacity: '.48', fill: '#363f84' }}
      />
      <path
        d="M731.99 417.96c-2.82 0-5.62-.48-8.27-1.44l-.41-.15.19-.91c-4.75-9.6-3.09-22.75 4.25-33.57 6.68-9.83 16.83-16.98 25.79-23.28a6.972 6.972 0 0 1 9.71 1.69 6.98 6.98 0 0 1 1.14 5.34l-3.2 16.43c-2.31 11.86-5.06 23.08-13.22 30.07a24.822 24.822 0 0 1-15.98 5.82zm20.98 28.4c-6.3-.04-12.16-3.26-15.56-8.57l-.23-.36.55-.49c1.43-7.82 8.17-14.78 17.22-17.78 8.23-2.72 17.31-2.28 25.31-1.89 2.95.15 5.23 2.66 5.08 5.62-.07 1.42-.7 2.75-1.76 3.7l-9.01 8.13c-6.55 5.91-13.07 11.24-20.95 11.62-.21.02-.43.02-.65.02z"
        style={{ opacity: '.68', fill: '#89bd87' }}
      />
      <path
        d="M759.75 430.98c-3.84-.35-7.72.06-11.4 1.2-9.87 3.06-16.5 10.96-21.89 19.58-.6-4.53-1.15-9.07-1.43-13.63-.57-9.09-.04-18.42 3.25-26.97a38.809 38.809 0 0 1 15.11-18.64c1.03-.66.08-2.34-.95-1.67a40.414 40.414 0 0 0-10.31 9.55c-5.41 7.12-8.06 15.81-8.89 24.7-.91 9.76.37 19.55 1.67 29.2-1.27 2.15-2.49 4.32-3.66 6.45-.6 1.08 1.14 1.86 1.73.79 1.09-1.97 2.18-3.94 3.31-5.88.35-.18.56-.53.54-.93.62-1.05 1.26-2.1 1.92-3.13 3.38-5.26 7.37-10.22 12.5-13.79 5.3-3.72 11.74-5.46 18.19-4.92 1.22.1 1.52-1.8.31-1.91z"
        style={{ opacity: '.66', fill: '#00953d' }}
      />
      <path
        d="M543.5 163.35c-.06-.42-.3-.79-.66-1.03l-52.77-34.84c.8-.33 1.46-.91 1.89-1.66 1.1-1.91.44-4.35-1.46-5.46l-4.33-2.5a4.012 4.012 0 0 0-5.46 1.46c-.29.5-.46 1.06-.51 1.64l-38.09-25.15a23.091 23.091 0 0 0-14.27-13.16V71.58h-14v11.09c-9.53 3.05-16 11.91-16 21.91v3h14a9 9 0 0 0 18 0h14v-3c0-1.44-.14-2.87-.4-4.29l96.37 63.62-40.03 48.67h-.93c-3.19 0-6.29.07-9.24.21.18-.49.26-1.02.23-1.54-.12-2.2-2-3.89-4.2-3.79l-4.99.26c-2.2.12-3.9 2-3.79 4.2.04.63.22 1.23.54 1.77-8.83.94-14.55 2.23-14.55 2.89 0 1.08 15.36.05 34.59 0 .27.78 1.13 1.19 1.91.91.26-.09.49-.25.66-.46l.37-.45c19.17.05 34.47 1.07 34.47 0 0-1.01-13.6-3.54-31.23-3.94l39.57-48.1c.26-.34.38-.77.31-1.19z"
        style={{ opacity: '.79', fill: '#b5b4b6' }}
      />
      <path
        d="m296.12 215.09-4.05-1.71-2.78-20.32h-37.02l-3.01 20.23-3.62 1.81c-.43.21-.6.73-.39 1.15.15.29.45.48.78.48h49.77c.47 0 .86-.38.86-.86a.871.871 0 0 0-.54-.78z"
        style={{ fill: '#e6e6e6' }}
      />
      <path
        d="M365.69 68.92c0-2.4-1.95-4.34-4.34-4.34H180.64c-2.4 0-4.34 1.95-4.34 4.34v109.72h.17v13.52c0 1.99 1.61 3.6 3.59 3.6h181.85c1.99 0 3.6-1.62 3.6-3.6v-13.52h.19V68.92h-.01zm-178.08 3.62h166.78c1.85 0 3.35 1.5 3.35 3.35v90.64H184.26V75.89c0-1.85 1.5-3.35 3.35-3.35z"
        style={{ opacity: '.76', fill: '#adccdc' }}
      />
      <path
        d="M541.34 83.04c6.03-.87 11.62 3.31 12.48 9.34.09.64.13 1.29.11 1.94l36.57 14.07-16.57 11.84-32.18-15.18c-6.09-.61-10.54-6.04-9.93-12.13.51-5.1 4.44-9.18 9.52-9.88zM559.22 447.11l-12.96-3.58 7.65-51.68 19.12 5.29-13.81 49.97z"
        style={{ fill: '#9e616a' }}
      />
      <path
        d="m559.05 460.58-41.78-11.55.15-.53c2.48-8.98 11.77-14.25 20.75-11.77l25.52 7.05-4.64 16.8z"
        style={{ fill: '#2f2e41' }}
      />
      <path
        d="m521.65 447.27-12.3-5.43 15.09-50.01 18.15 8.01-20.94 47.43z"
        style={{ fill: '#9e616a' }}
      />
      <path
        d="m519.53 460.58-39.65-17.51.22-.5c3.76-8.52 13.72-12.38 22.25-8.62l24.22 10.69-7.04 15.94zm187.12-253.11s6.52 20.19-33.47 45.73l-51.5 51.32-53.51 128.01-31.77-14.24 6.23-16.63 4.18-11.16 2.14-5.71 2.29-6.12 34.01-90.79 28.57-50.02.83-27.14 7.03-19.13 50.98-18.09 33.99 33.97z"
        style={{ fill: '#2f2e41' }}
      />
      <path
        d="M680.65 208.47s6.52 20.19-33.47 45.73l-51.5 51.32-53.51 128.01-31.77-14.24 6.23-16.63 4.18-11.16 2.14-5.71 2.29-6.12 34.01-90.79 9.57-16.75a304.76 304.76 0 0 1 45.65-60.82l9.38-9.69 1-15 21.82-12.13 33.98 33.98z"
        style={{ fill: '#2f2e41' }}
      />
      <circle cx="667.79" cy="44.78" r="25.26" style={{ fill: '#9e616a' }} />
      <path
        d="M683.93 35.71c-.05.67-.11 1.54-.19 2.54-.08 2.73.69 5.41 2.2 7.68 1.41 2.39-.27 16.82-.27 16.82 41.37-28.84 8.68-57.81-4.1-55.23.27-3.67-10.16-2.21-10.16-2.21-3.97-6.19-12.68 2.03-12.68 2.03l1.68-2.83c-6.76 1.72-13.44 22.61-13.44 22.61 2.28 9.02 37.23 4.93 36.96 8.59z"
        style={{ fill: '#2f2e41' }}
      />
      <g style={{ opacity: '0.67' }}>
        <path
          d="m503.91 52.94 34.78 17.41c3.46 1.73 6.13-3.64 2.68-5.37l-34.78-17.41c-3.46-1.73-6.14 3.64-2.68 5.37zm-5.26 11.38 34.78 17.41c3.46 1.73 6.13-3.64 2.68-5.37l-34.78-17.41c-3.46-1.74-6.14 3.64-2.68 5.37zm-6.2 12.43 34.78 17.41c3.46 1.73 6.13-3.64 2.68-5.37l-34.78-17.41c-3.46-1.73-6.13 3.64-2.68 5.37zm-6.32 10.44 34.78 17.41c3.46 1.73 6.13-3.64 2.68-5.37l-34.78-17.41c-3.46-1.73-6.13 3.64-2.68 5.37z"
          style={{ fill: '#b4b1b2' }}
        />
      </g>
      <path d="m655.28 208.84 14.44 27.43c.71 1.33.69 2.93-.04 4.26-.86 1.59-2.6 2.5-4.4 2.32-.3-.03-.59-.08-.88-.18a4.556 4.556 0 0 1-2.65-2.2l-14.44-27.43a4.508 4.508 0 0 1 1.82-6.1 4.508 4.508 0 0 1 6.1 1.82c.02.02.04.05.05.08z" />
      <path
        d="m668.56 234.05 2.9 5.54 1.82 8c.3 1.32-.52 2.63-1.84 2.93-.86.2-1.75-.08-2.35-.73l-5.64-6.14-2.85-5.42 7.96-4.18zm-24.8-32.35 2.55 11.2a2.45 2.45 0 0 0 3.53 1.63l1.73-.91 3.96 7.52c.27.48.87.66 1.36.4.47-.26.66-.85.41-1.33l-3.96-7.52 1.73-.91a2.45 2.45 0 0 0 1.03-3.31c-.1-.19-.23-.37-.37-.53l-7.79-8.44a2.461 2.461 0 0 0-3.47-.14c-.62.59-.9 1.49-.71 2.34z"
        style={{ fill: '#3f3d56' }}
      />
      <path
        d="M654.76 221.56c1.07-5.99 6.8-9.98 12.79-8.91.64.11 1.27.28 1.88.51l24.87-30.28 6.02 19.45-24.54 25.77c-2.49 5.59-9.05 8.1-14.64 5.61a11.08 11.08 0 0 1-6.38-12.15z"
        style={{ fill: '#9e616a' }}
      />
      <path
        d="M353.21 138.85c-.09.77-.12 1.54-.09 2.32l-56.07 40.79-15.4-6.82-13.33 21.9 26.1 14.72c4.33 2.44 9.75 1.73 13.31-1.74l56.44-55.12c7.86 2 15.84-2.76 17.84-10.61s-2.76-15.84-10.61-17.84c-7.86-2-15.84 2.76-17.84 10.61-.16.59-.27 1.18-.35 1.79z"
        style={{ fill: '#ffb8b8' }}
      />
      <path d="m295.24 183.45-16.35 27.25a6.297 6.297 0 0 1-8.63 2.16c-.26-.16-.52-.34-.75-.53l-24.48-20.11c-8.23-5.04-10.81-15.8-5.77-24.03 5-8.15 15.62-10.78 23.84-5.88l29.17 12.1a6.29 6.29 0 0 1 3.4 8.22c-.14.28-.27.55-.43.82z" />
      <circle cx="330.49" cy="450.5" r="10.07" style={{ fill: '#cacaca' }} />
      <path
        d="M312.64 444.13h16.97l8.07-44.01h-25.04v44.01z"
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d="M308.31 460.58h54.71v-.69c0-11.76-9.53-21.3-21.3-21.3H308.3l.01 21.99zm29.71-43.74 33.83-75.86c5.86-13.26.54-28.79-12.21-35.69l-73.38-39.35-33.48-2.85-30.89-2.81-.22.38c-.29.48-6.93 11.95-2.44 24.53 3.4 9.54 12.26 16.99 26.32 22.15 32.82 12.04 70.74 22.32 85.52 26.18 1.32.34 2.43 1.22 3.06 2.43.63 1.2.73 2.6.27 3.87l-31.43 70.61c-1.18 3.23.47 6.81 3.7 7.99.51.19 1.03.3 1.57.35l23.48 1.71c.2.02.4.03.6.03 2.47.02 4.7-1.43 5.7-3.67z"
        style={{ fill: '#2f2e41' }}
      />
      <path
        d="M301.57 444.13h16.97l8.07-44.01h-25.04v44.01z"
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d="M297.24 460.58h54.71v-.69c0-11.76-9.53-21.3-21.3-21.3h-33.42l.01 21.99zm28.32-39.59 33.83-75.86c5.86-13.26.54-28.79-12.21-35.69l-73.38-39.35-33.48-2.85-24.19-2.1v-.33c-.28.48-6.93 11.95-2.44 24.53 3.4 9.54 5.34 16.99 19.4 22.15 32.82 12.04 70.74 22.32 85.52 26.18 1.32.34 2.43 1.22 3.06 2.44.63 1.2.73 2.6.27 3.87l-31.43 70.61c-1.18 3.23.47 6.81 3.7 7.99.51.19 1.03.3 1.57.35l23.48 1.71c2.67.27 5.21-1.2 6.3-3.65z"
        style={{ fill: '#592e49' }}
      />
      <circle cx="271.27" cy="86.37" r="33.99" style={{ fill: '#ffb8b8' }} />
      <path
        d="M388.45 216.99a231.71 231.71 0 0 0 33.32-62.19 5.96 5.96 0 0 0-2.81-7.17l-48.64-26.07c-1.59-.86-3.5-.94-5.16-.21a5.981 5.981 0 0 0-3.41 3.99 141.5 141.5 0 0 1-31.07 57.98 5.998 5.998 0 0 0-1.44 5.05 5.865 5.865 0 0 0 3.03 4.18l48.65 26.07c2.58 1.37 5.76.68 7.53-1.63z"
        style={{ fill: '#ccc' }}
      />
      <path
        d="M385.13 214.49c-.54.67-1.48.87-2.24.46l-48.64-26.07a1.72 1.72 0 0 1-.9-1.24c-.1-.56.06-1.14.45-1.56a145.788 145.788 0 0 0 31.98-59.69c.14-.55.53-1.01 1.05-1.24.49-.22 1.06-.2 1.54.06L417 151.29c.76.41 1.12 1.3.86 2.13-7.73 21.9-18.77 42.5-32.73 61.07z"
        style={{ fill: '#fff' }}
      />
      <path
        d="m368.11 149.99 33.55 19.67c3.34 1.96 6.36-3.23 3.03-5.18l-33.55-19.67c-3.34-1.96-6.36 3.22-3.03 5.18zm-6 11 33.55 19.67c3.34 1.96 6.36-3.23 3.03-5.18l-33.55-19.67c-3.34-1.96-6.36 3.22-3.03 5.18zm-4.71 10.95 30.51 18.11c3.04 1.8 6.28-1.92 3.25-3.72l-30.51-18.12c-3.03-1.79-6.28 1.93-3.25 3.73zm-9.29 11.05 33.55 19.67c3.34 1.96 6.36-3.23 3.03-5.18l-33.55-19.67c-3.34-1.96-6.36 3.22-3.03 5.18z"
        style={{ opacity: '.71', fill: '#b4b1b2' }}
      />
      <path d="m290.87 268.69-67.27-.4-.02-.48c-1.28-33.75 2.84-64.33 12.61-93.47.14-1.09.29-2.26.43-3.4 1.67-13.04 2.99-23.39 15.39-27.25a8.828 8.828 0 0 1-1.75-4.73c-.16-2.3.61-4.57 2.15-6.3a8.272 8.272 0 0 1 5.75-2.81l23.95-.82c1.26-.01 2.48.41 3.48 1.18a9.56 9.56 0 0 1 2.78 12.08c-.28.56-.39.82-.43.93.85 4.46 3.38 9.76 6.05 15.36 3.83 8.03 8.17 17.12 9.28 26.86.6 5.26.35 10.57-.74 15.75-.5 2.79-.61 5.63-.34 8.45.22 4.18.42 8.13-1.94 11.95-.67 1.14-1.37 2.26-2.1 3.36l-3.33 7.95-3.95 35.79z" />
      <path
        d="M274.63 303.52h-46.25V178.47c-.01-9.33-7.57-16.88-16.9-16.9h-4.67c-9.33.01-16.88 7.57-16.9 16.9v137.46c.01 7.6 5.03 14.03 11.94 16.15.33 6.03 5.31 10.81 11.41 10.82h61.36c6.32-.01 11.44-5.13 11.45-11.45v-16.48c0-6.32-5.12-11.44-11.44-11.45z"
        style={{ fill: '#3f3d56' }}
      />
      <path
        d="M370.32 192.19c-.48.6-.92 1.25-1.3 1.92l-69.16 5.05-9.48-13.92-22.88 11.56 14.4 26.28c2.39 4.36 7.37 6.62 12.23 5.55l77.03-17.03c5.62 5.84 14.91 6.02 20.75.4 5.84-5.62 6.02-14.91.4-20.75s-14.91-6.02-20.75-.4c-.45.42-.86.87-1.24 1.34z"
        style={{ fill: '#ffb6b6' }}
      />
      <path d="m297.54 199.47-28.27 14.51a6.29 6.29 0 0 1-8.83-3.57l-10.18-30c-4.35-8.57-.94-19.05 7.61-23.43-12.53 6.43 13.57-11.84 23.53 7.57l18.39 25.67c2.02 2.83 1.37 6.76-1.45 8.78-.27.17-.53.33-.8.47z" />
      <path
        d="M255.91 119.44c-2.05 0-4.3-.99-6.85-2.98-15.39-12.09-20.82-32.97-13.26-51.03 1.85-4.42 5.31-10.43 11.29-13.95 4.3-2.53 9.35-3.44 13.74-4.1 3.8-.57 7.62-1.02 11.45-1.35 4.53-.39 9.77-.65 14.55 1.17 3.5 1.33 6.48 3.68 9.36 5.95 1 .79 2.04 1.61 3.08 2.37 2.52 1.97 5.43 3.38 8.53 4.14 1.32.27 2.46 1.11 3.13 2.29.67 1.2.8 2.63.33 3.93-.45 1.26-.86 2.39-.03 3.63.62.88 1.63 1.4 2.71 1.38.57-.01 1.1.27 1.42.74s.39 1.06.19 1.59l-.13.34c.71.07 1.35.48 1.7 1.11.53.9.36 2.06-.41 2.77a22.322 22.322 0 0 1-10.92 5.71c-2.81.55-5.69.54-8.5-.02l-.45-.09.05-.5c-11.34-4.05-21.58 2.82-27.42 10.67l-.84-.53c.29-.59.33-1.28.11-1.9-.24-.74-.76-1.36-1.46-1.72a3.789 3.789 0 0 0-4.14.83 5.642 5.642 0 0 0-1.55 4.29c.15 1.66.58 3.28 1.25 4.81.27.73.56 1.48.79 2.24 1.29 4.32.95 8.96-.97 13.03-.88 1.86-2.48 4.23-5.08 4.97-.54.13-1.1.21-1.67.21z"
        style={{ fill: '#2f2e41' }}
      />
      <path
        d="M808.48 462.58H1.19c-.66 0-1.19-.53-1.19-1.19s.53-1.19 1.19-1.19h807.29c.66 0 1.19.53 1.19 1.19s-.53 1.19-1.19 1.19z"
        style={{ opacity: '.25' }}
      />
      <path
        d="M733.63 158.2c-4.1-17.74-11.47-48.73-14.78-56.99a32.517 32.517 0 0 0-14.1-16.42l-.16-.08-2.97.55c-4.03-8.55-7.86-13.45-7.86-13.45l-27.92 3.81-3.13 6.64a160.802 160.802 0 0 0-48.8 31.6L564.83 97.4l-10.94 14.53 62.52 31.39s13.45-8.08 27.67-16.95l-22.4 65.19c13.3-2 36.26 4.16 55.29 9.74l-1.85 2.47 10.95 15.82 12.12-12.12c6.15 1.62 10.16 2.3 10.65 1.14-.21-3.14-1.28-6.16-3.05-8.74l22.29-22.29a20.661 20.661 0 0 0 5.55-19.38z"
        style={{ fill: '#c13a3a' }}
      />
      <path d="m675.11 203.78 31.1-41.47-16.02-34.33 1.17-40.83-5.48 39.57 16.02 34.33-31.09 41.46 12.97 13.79-8.67-12.52z" />
    </svg>
  )
}
