import { useAppContext } from 'Contexts'
import { useEffect, useState } from 'react'

export const useCurrentIPAddress = () => {
  const [ip, setIp] = useState<string>()
  const { tosApi } = useAppContext()

  useEffect(() => {
    tosApi
      ?.advertisingPlatformServiceGetUserIPAddress()
      .then(({ ipAddress }) => setIp(ipAddress))
  }, [tosApi])

  return ip
}
