/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1FolderStats } from '../models/Creativeservicev1FolderStats';
import { HttpFile } from '../http/http';

export class V1ListCreativeFoldersResponse {
    /**
    * An array of Folders.
    */
    'folders'?: Array<Creativeservicev1FolderStats>;
    /**
    * The page token to send to retrieve the next page of results
    */
    'nextPageToken'?: string;
    /**
    * The number of total folders returned from the query
    */
    'totalSize'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "folders",
            "baseName": "folders",
            "type": "Array<Creativeservicev1FolderStats>",
            "format": ""
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "totalSize",
            "baseName": "total_size",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1ListCreativeFoldersResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

