import {
  Button,
  Fa,
  showErrorMessage,
  triggerPrompt,
} from '@eltoro-ui/components'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import { checkIfCreative } from 'Helpers'
import { useAppContext, useLibraryContext } from 'Contexts'
import { useNavigate } from 'react-router-dom'

export const RemoveButton = ({
  previewItem,
  hasDeployments,
  subJobs,
}: {
  previewItem: Creativeservicev1Creative | Targetjobservicev1Audience
  hasDeployments?: boolean
  subJobs?: Targetjobservicev1Audience[]
}) => {
  const {
    audienceServiceApi,
    creativeServiceApi,
    isAdmin,
    currentOrg,
  } = useAppContext()
  const {
    pagination: { refetch },
    handleClearSelectedItem,
  } = useLibraryContext()
  const navigate = useNavigate()

  const checkOLAttachment = () => {
    if (checkIfCreative(previewItem)) {
      return !!(previewItem.orderLines && previewItem.orderLines.length)
    }
    if (
      previewItem.type === 'AUDIENCE_TYPE_VR' &&
      previewItem.audiences &&
      previewItem.orgId &&
      subJobs
    ) {
      // Just in case the VR job itself is attached (this should not be happening):
      if (previewItem.orderLineIds && previewItem.orderLineIds.length) {
        return true
      }
      // if vr, look at sub jobs instead (fetched by PreviewPanel)
      return !!subJobs.find(
        (job) => job.orderLineIds && !!job.orderLineIds.length,
      )
    }
    return !!(previewItem.orderLineIds && previewItem.orderLineIds.length > 0)
  }

  const audienceLock =
    !checkIfCreative(previewItem) && previewItem.locked && !isAdmin

  const handleRemove = async () => {
    if (!previewItem?.orgId || !previewItem?.id) return
    const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

    handleClearSelectedItem()
    if (checkIfCreative(previewItem)) {
      await creativeServiceApi
        ?.advertisingPlatformServiceDeleteCreative(
          previewItem.id,
          previewItem.orgId,
        )
        .then(() => {
          refetch()
          navigate(`/campaigns/creative-library${query}`, {
            replace: true,
          })
        })
        .catch(() => {
          showErrorMessage(
            'Error',
            'There was an error deleting your creative. Please try again.',
          )
        })
    } else {
      await audienceServiceApi
        ?.advertisingPlatformServiceDeleteAudience(
          previewItem.id,
          previewItem.orgId,
        )
        .then(() => {
          refetch()
          navigate(`/audiences/audience-library${query}`, { replace: true })
        })
        .catch(() => {
          showErrorMessage(
            'Error',
            'There was an error deleting your audience. Please try again.',
          )
        })
    }
  }

  const isAttachedToOLs = checkOLAttachment()

  const removeButton = (
    <Button
      width="100%"
      iconLeft={<Fa icon="trash" size={1} />}
      onClick={() =>
        handleRemove &&
        triggerPrompt(
          `Delete ${checkIfCreative(previewItem) ? 'Creative' : 'Audience'}`,
          <p>
            Are you sure you want to delete <strong>{previewItem.name}</strong>?
          </p>,
          handleRemove,
          { confirmBtnKind: 'danger', textSize: 'l' },
        )
      }
      disabled={
        isAttachedToOLs ||
        audienceLock ||
        hasDeployments ||
        (!checkIfCreative(previewItem) && hasDeployments === undefined) // still loading from preview panel
      }
    >
      Remove
    </Button>
  )
  return isAttachedToOLs || audienceLock || hasDeployments ? (
    <div
      data-tooltip="top"
      aria-label={(() => {
        if (isAttachedToOLs || audienceLock)
          return `Cannot remove this ${
            checkIfCreative(previewItem) ? 'creative' : 'audience'
          } because ${
            isAttachedToOLs && !audienceLock
              ? 'it is attached to at least one order line. Please remove it from the order lines before deleting'
              : 'it is currently locked'
          }.`
        if (hasDeployments)
          return 'Cannot remove this audience because it is deployed'

        return 'Checking if this audience can be removed...'
      })()}
    >
      {removeButton}
    </div>
  ) : (
    removeButton
  )
}
