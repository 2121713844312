import { Avatar } from '@eltoro-ui/components'

export const OrgNameCell: React.FC<{
  name: string
  logo?: string
}> = ({ name, logo }) => {
  return (
    <div className="OrgNameCell__name-row flex min-w-[9rem] items-center gap-2">
      {(() => {
        if (logo) {
          return <Avatar size="s" src={logo} rounded />
        }
        return <Avatar size="s" name={name || ''} rounded />
      })()}
      {name}
    </div>
  )
}
