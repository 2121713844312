/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1OrderLine } from '../models/Campaignservicev1OrderLine';
import { HttpFile } from '../http/http';

export class V1UpdateOrderLineRequest {
    'orderLine'?: Campaignservicev1OrderLine;
    /**
    * The list of fields to update.
    */
    'updateMask'?: string;
    /**
    * The Organization where this Order Line belongs to.
    */
    'orgId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLine",
            "baseName": "order_line",
            "type": "Campaignservicev1OrderLine",
            "format": ""
        },
        {
            "name": "updateMask",
            "baseName": "update_mask",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1UpdateOrderLineRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

