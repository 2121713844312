import { useState, useEffect, useRef } from 'react'
import {
  Modal,
  showErrorMessage,
  Text,
  TextHeader,
  Toggle,
  Tooltip,
} from '@eltoro-ui/components'
import { Campaignservicev1OrderLine, V1StatsResponse } from 'next-gen-sdk'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { CampaignContainer, OrderlineTable, PageContent } from 'Components'
import { OrderlineContainer } from 'Components/OrderlineTable/OrderlineContainer'
import { getOrgFilter, styleTailwind } from 'Helpers'
import { DashboardCards } from './DashboardCards'
import { useDashboardTableData } from './useDashboardTableData'
import './DashboardPage.scss'
import { statMakerForEnvironment } from 'Components/Reports/Stats/statMockData'
import { getOrderLineStats } from 'Helpers/getOrderLineStats'

export type DashStatsType = {
  OL_completed: number
  OL_serving: number
  OL_drafts: number
  OL_review_started: number
  audience_types: string[]
  creative_types: string[]
  most_used_audience_type: string
  most_used_creative_type: string
}
const dashInitial = {
  OL_completed: 0,
  OL_serving: 0,
  OL_drafts: 0,
  OL_review_started: 0,
  audience_types: [],
  creative_types: [],
  most_used_audience_type: '',
  most_used_creative_type: '',
}

const DashToggle = styleTailwind('div', 'DashToggle flex items-center')

const updateAssetStats = (OLs: Campaignservicev1OrderLine[]) => {
  return OLs.reduce(
    (stats: Partial<DashStatsType>, OL: Campaignservicev1OrderLine) => {
      if (OL) {
        const OLAudienceTypes = OL?.audiences?.map((audience) => audience.type)
        const OLCreativeTypes = OL?.creatives?.map((creative) => creative.type)
        const orgCreativeTypeList = [
          ...(stats.creative_types || []),
          OLCreativeTypes,
        ]
        const orgAudienceTypeList = [
          ...(stats.audience_types || []),
          OLAudienceTypes,
        ]
        const getMostUsed = (list: string[]) =>
          Object.entries<number>(
            (list.flat() as string[]).reduce(
              (acc: { [key: string]: number }, val: string) => {
                return { ...acc, [val]: acc[val] + 1 }
              },
              [...new Set(list.flat() as string[])].reduce(
                (acc, val: string) => {
                  return { ...acc, [val]: 0 }
                },
                {},
              ),
            ),
          ).sort(
            (a: [string, number], b: [string, number]) => b[1] - a[1],
          )[0]?.[0]

        const newStats = {
          audience_types: orgAudienceTypeList as string[],
          creative_types: orgCreativeTypeList as string[],
          most_used_audience_type: getMostUsed(orgAudienceTypeList as string[]),
          most_used_creative_type: getMostUsed(orgCreativeTypeList as string[]),
        }
        return newStats
      }
      return stats
    },
    dashInitial,
  )
}

export const DashboardPage = () => {
  const [assetModalOpen, setAssetModalOpen] = useState(false)
  const [orderLine, setOrderLine] = useState<Campaignservicev1OrderLine>()
  const [dashStats, setDashStats] = useState<DashStatsType>(dashInitial)
  const [olStats, setOlStats] = useState<V1StatsResponse>()
  const {
    currentOrg,
    campaignServiceApi,
    rebuild,
    statsServiceApi,
  } = useAppContext()
  const { campaignId, orderLineId } = useParams<{
    campaignId?: string
    orderLineId?: string
  }>()
  const navigate = useNavigate()
  const orgId = new URLSearchParams(window.location.search).get('org_id')
  const hasLoadedInitItem = useRef<boolean>(false)
  const isMounted = useRef(false)

  const handleCloseAssetModal = () => {
    // reset url
    if (campaignId || orderLineId) {
      const query = new URLSearchParams(window.location.search)
      window.history.replaceState('', '', `/campaigns/dashboard?${query}`)
    }
    // close it
    setAssetModalOpen(false)
  }
  const {
    paginationProps,
    filter,
    childOrgsOnly,
    showArchived,
    setShowArchived,
    setChildOrgsOnly,
    setSort,
    setFilter,
    setChildOrgsFilter,
  } = useDashboardTableData()

  useEffect(() => {
    if (
      !orgId ||
      paginationProps.loading ||
      paginationProps.currentPageData.length === 0
    )
      return
    if (campaignId) {
      if (hasLoadedInitItem.current === false) setAssetModalOpen(true)
      hasLoadedInitItem.current = true
      return
    }
    if (orderLineId && campaignServiceApi && isMounted.current) {
      if (hasLoadedInitItem.current === false) {
        campaignServiceApi
          .advertisingPlatformServiceGetOrderLine(orderLineId, orgId)
          .then((orderLine) => {
            setOrderLine(orderLine)
            setAssetModalOpen(true)
            hasLoadedInitItem.current = true
          })
          .catch((err) => {
            if (err?.body?.message === 'orderLineNotFound') {
              navigate(`/campaigns/dashboard?org_id=${orgId}`, {
                replace: true,
              })
              showErrorMessage(
                'Order Line Not Found',
                `The order line was not found. Order Line ID: ${orderLineId}`,
              )
            }
          })
      }
    } else {
      isMounted.current = true
    }
  }, [
    campaignId,
    navigate,
    orderLineId,
    campaignServiceApi,
    orgId,
    paginationProps.currentPageData.length,
    paginationProps.loading,
  ])

  // Dashboard stats (NOT most used creative/audience- those are done by page)

  useEffect(() => {
    let ignore = false

    if (!ignore && !rebuild && isMounted.current) {
      if (!campaignServiceApi || !currentOrg?.id) return
      const handleStatusCounts = async () => {
        const getCountForStatus = async (state: number) => {
          const { totalSize } = await campaignServiceApi
            .advertisingPlatformServiceListOrderLines(
              1, // pageSize
              undefined, // pageToken
              undefined, // orderBy
              `${getOrgFilter(childOrgsOnly, currentOrg)} AND state = ${state}`,
            )
            .catch(() => {
              return { totalSize: 0 }
            })

          return totalSize
        }

        // order lines draft
        const OL_drafts = await getCountForStatus(1)
        // order lines serving
        const OL_serving = await getCountForStatus(5)
        // order lines review started
        const OL_review_started = await getCountForStatus(2)
        // order lines completed
        const OL_completed = await getCountForStatus(7)
        setDashStats((prev) => {
          return {
            ...prev,
            OL_drafts: OL_drafts || 0,
            OL_serving: OL_serving || 0,
            OL_review_started: OL_review_started || 0,
            OL_completed: OL_completed || 0,
          }
        })
      }

      handleStatusCounts()
    } else {
      isMounted.current = true
    }
    return () => {
      ignore = true
    }
  }, [childOrgsOnly, showArchived, currentOrg, rebuild, campaignServiceApi])

  // Update the most used assets
  useEffect(() => {
    if (isMounted.current && assetModalOpen && currentOrg && statsServiceApi) {
      const orderLines = paginationProps.currentPageData.map(
        (row) => row.orderLine,
      )
      const updatedStats = updateAssetStats(orderLines || [])
      setDashStats((prev) => {
        return {
          ...prev,
          ...updatedStats,
        }
      })
    } else {
      isMounted.current = true
    }
  }, [paginationProps.currentPageData])

  // if loading order line from URL, get stats
  useEffect(() => {
    if (isMounted.current && assetModalOpen && currentOrg && statsServiceApi) {
      const handleLoadStats = (id: string) => {
        if (!currentOrg?.id || !statsServiceApi || !orderLine || !id) return
        getOrderLineStats(orderLine, currentOrg.id, 'day').then(
          (statsRes) => statsRes && setOlStats(statsRes),
        )
      }

      if (assetModalOpen && orderLine && orderLine.id) {
        handleLoadStats(orderLine.id)
      }
    } else {
      isMounted.current = true
    }
  }, [assetModalOpen, currentOrg, orderLine, statsServiceApi])

  return (
    <PageContent className="Dashboard">
      <DashboardCards stats={dashStats} />
      <header className="Dashboard__header flex items-end justify-between pt-2">
        <div className="Dashboard__inner flex w-full justify-between gap-2">
          <TextHeader className="DashboardHeader" type={1}>
            Recent Order Lines
          </TextHeader>
          <div className="Dashboard__toggle-container ml-4 flex flex-wrap items-end gap-4">
            {!!currentOrg?.childOrgs?.length && (
              <DashToggle className="Dashboard__child-org-toggle">
                <Text className="text-s" tag="p">
                  {childOrgsOnly ? 'Hide child orgs' : 'Show child orgs'}
                </Text>
                <Toggle
                  size="xs"
                  isChecked={childOrgsOnly}
                  onChange={(value) => setChildOrgsOnly(value)}
                />
              </DashToggle>
            )}
            {(() => {
              const archiveDisabled = filter?.status && filter.status.length > 0

              const ToggleContent = (
                <DashToggle
                  className={classNames(
                    'Dashboard__archived-toggle text-s flex items-center gap-2',
                    {
                      'opacity-40': filter?.status && filter.status.length > 0,
                    },
                  )}
                >
                  <span>{showArchived ? 'Hide archive' : 'Show archive'}</span>
                  <Toggle
                    isChecked={showArchived}
                    onChange={(checked) => setShowArchived(checked)}
                    size="xs"
                    isDisabled={filter?.status && filter.status.length > 0}
                  />
                </DashToggle>
              )

              return archiveDisabled ? (
                <Tooltip
                  position="right"
                  content="Please clear the status filter before using archive toggle."
                >
                  {ToggleContent}
                </Tooltip>
              ) : (
                ToggleContent
              )
            })()}
          </div>
        </div>
      </header>
      <OrderlineTable
        pagination={paginationProps}
        childOrgsOnly={childOrgsOnly}
        setSort={setSort}
        setFilter={setFilter}
        onOpenOrderLine={(orderLine) => {
          setOrderLine(orderLine)
          if (!orderLine.id || !orderLine.orgId) return
          window.history.replaceState(
            '',
            '',
            `/campaigns/dashboard/order-lines/${orderLine.id}${
              orderLine.orgId ? `?org_id=${orderLine.orgId}` : ''
            }`,
          )
        }}
        setChildOrgsFilter={setChildOrgsFilter}
      />
      {assetModalOpen && (
        <Modal
          className="DashboardPage__view-container bg-base fixed m-auto min-w-[90vw]"
          offClick={handleCloseAssetModal}
          scrollable={false}
        >
          <>
            {campaignId && (orderLine?.orgId || orgId) && (
              <CampaignContainer
                campaignId={campaignId}
                handleCollapse={handleCloseAssetModal}
                collapseIconName="xmark"
                orgId={orderLine?.orgId || orgId || ''}
              />
            )}
            {orderLineId && orderLine && (
              <OrderlineContainer
                stats={
                  process.env.REACT_APP_ENV === 'production' &&
                  !/dev/.test(window.location.href)
                    ? olStats
                    : statMakerForEnvironment(orderLine)
                }
                orderline={orderLine}
                handleCollapse={handleCloseAssetModal}
                handleOpenCampaign={(campaignId, { orgId }) => {
                  if (!orgId) return
                  navigate(`/campaigns/dashboard/${campaignId}?org_id=${orgId}`)
                }}
                collapseIconName="xmark"
              />
            )}
          </>
        </Modal>
      )}
    </PageContent>
  )
}
