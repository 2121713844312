import { Orgmanagerv1Org } from 'next-gen-sdk'

export const org1: Orgmanagerv1Org = {
  id: 'this_org_id',
  createTime: new Date(),
  updateTime: new Date(),
  accountReps: [],
  billableOrgId: 'this_org_id',
  contacts: [
    {
      id: 'org_contact_sales',
      createTime: new Date(),
      email: 'sales@fixture.org',
      firstName: 'Sales',
      lastName: 'McSalesFace Jr',
      orgId: 'this_org_id',
      phone: '555-555-5555',
      type: 'sales',
    },
    {
      id: 'org_contact_billing',
      createTime: new Date(),
      email: 'billing@fixture.org',
      firstName: 'Billing',
      lastName: 'McBillingFace Sr',
      orgId: 'this_org_id',
      phone: '555-555-5555',
      type: 'billing',
    },
    {
      id: 'org_contact_operations',
      createTime: new Date(),
      email: 'operations@fixture.org',
      firstName: 'Ops',
      lastName: 'McOpsFace',
      orgId: 'this_org_id',
      phone: '555-555-5555',
      type: 'operations',
    },
  ],
  logo: {
    bucket:
      'https://pbs.twimg.com/profile_images/1063925348205821958/DlGcxdOl_400x400.jpg',
  },
  name: 'Fixture Org',
  salesRepIds: [],
}

export const org2: Orgmanagerv1Org = {
  id: '1234',
  createTime: new Date(),
  updateTime: new Date(),
  accountReps: [],
  billableOrgId: '1234',
  childOrgs: [
    {
      id: 'abcd',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 1',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
    {
      id: 'efgh',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 2',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
    {
      id: 'ijkl',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 3',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
    {
      id: 'mnop',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 4',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
    {
      id: 'qrst',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 5',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
    {
      id: '5678',
      createTime: new Date(),
      updateTime: new Date(),
      accountReps: [],
      billableOrgId: '1234',
      childOrgs: [],
      contacts: [],
      cpms: undefined,
      logo: {},
      msaSigned: false,
      name: 'Test Child Org 6',
      parentOrgId: '1234',
      refId: '',
      reseller: false,
      salesRepIds: [],
    },
  ],
  contacts: org1.contacts,
  cpms: undefined,
  logo: {},
  msaSigned: false,
  name: 'Test Org',
  refId: '',
  reseller: false,
  salesRepIds: [],
}

export const orgWithCpms: Orgmanagerv1Org = {
  id: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
  accountReps: [],
  billableOrgId: '',
  childOrgs: [
    {
      id: 'd5d5166c-6c26-41b0-bbd0-498b6b3e98c1',
      accountReps: [],
      billableOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      childOrgs: [],
      contacts: [],
      cpms: {},
      msaSigned: false,
      name: 'child penguin org',
      parentOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      refId: '',
      reseller: false,
      salesRepIds: [],
      userIds: [],
      status: 'ORG_STATUS_CREATED',
      prepay: false,
    },
    {
      id: 'd8a9c60a-27c3-4740-8a66-c678d34a8f70',
      accountReps: [],
      billableOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      childOrgs: [],
      contacts: [],
      cpms: {},
      msaSigned: false,
      name: 'test child org 2',
      parentOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      refId: '',
      reseller: false,
      salesRepIds: [],
      userIds: [],
      status: 'ORG_STATUS_CREATED',
      prepay: false,
    },
    {
      id: '313c2389-3f84-451c-b17d-853f403761b3',
      accountReps: [],
      billableOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      childOrgs: [],
      contacts: [],
      cpms: {},
      msaSigned: false,
      name: 'test child org 3',
      parentOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      refId: '',
      reseller: false,
      salesRepIds: [],
      userIds: [],
      status: 'ORG_STATUS_CREATED',
      prepay: false,
    },
    {
      id: '04f801c7-3fb0-4767-9bb0-90558ffd9732',
      accountReps: [],
      billableOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      childOrgs: [],
      contacts: [],
      cpms: {},
      msaSigned: false,
      name: 'child test org 4',
      parentOrgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      refId: '',
      reseller: false,
      salesRepIds: [],
      userIds: [],
      status: 'ORG_STATUS_CREATED',
      prepay: false,
    },
  ],
  contacts: [
    {
      id: '3996f541-6ec7-455f-8b50-93cb60f0b975',
      email: 'mari@eltoro.com',
      orgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      phone: '222-222-2222',
      type: 'sales',
      firstName: 'Mari Humberg',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    {
      id: '2c482782-57df-4459-b3ed-1c418a44705e',
      email: 'joe@eltoro.com',
      orgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      phone: '222-222-2222',
      type: 'operations',
      firstName: 'Joe Young',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    {
      id: 'ef5cf971-137d-4609-8384-089b007a19ee',
      email: 'joe@eltoro.com',
      orgId: 'a3a79a90-8cae-48ec-b4ce-e046e0db28cc',
      phone: '222-222-2222',
      type: 'billing',
      firstName: 'Joe Young',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
  ],
  cpms: {
    'non-political aristotle banner': {
      currencyCode: 'USD',
      units: '28',
      nanos: 0,
    },
    'non-political aristotle video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political b2c banner': {
      currencyCode: 'USD',
      units: '40',
      nanos: 0,
    },
    'non-political captive banner': {
      currencyCode: 'USD',
      units: '15',
      nanos: 0,
    },
    'non-political captive video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political client banner': {
      currencyCode: 'USD',
      units: '31',
      nanos: 0,
    },
    'non-political client video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political default banner': {
      currencyCode: 'USD',
      units: '22',
      nanos: 0,
    },
    'non-political default video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political escrowmover banner': {
      currencyCode: 'USD',
      units: '16',
      nanos: 0,
    },
    'non-political escrowmover video': {
      currencyCode: 'USD',
      units: '26',
      nanos: 0,
    },
    'non-political gop banner': {
      currencyCode: 'USD',
      units: '32',
      nanos: 0,
    },
    'non-political gop video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political l2 banner': {
      currencyCode: 'USD',
      units: '23',
      nanos: 0,
    },
    'non-political l2 video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political mover banner': {
      currencyCode: 'USD',
      units: '34',
      nanos: 0,
    },
    'non-political mover video': {
      currencyCode: 'USD',
      units: '40',
      nanos: 0,
    },
    'non-political postmover banner': {
      currencyCode: 'USD',
      units: '18',
      nanos: 0,
    },
    'non-political postmover video': {
      currencyCode: 'USD',
      units: '28',
      nanos: 0,
    },
    'non-political retargeting banner': {
      currencyCode: 'USD',
      units: '10',
      nanos: 0,
    },
    'non-political retargeting video': {
      currencyCode: 'USD',
      units: '25',
      nanos: 0,
    },
    'non-political segmentshare banner': {
      currencyCode: 'USD',
      units: '25',
      nanos: 0,
    },
    'non-political segmentshare video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political van banner': {
      currencyCode: 'USD',
      units: '25',
      nanos: 0,
    },
    'non-political van video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
    'non-political venueReplay banner': {
      currencyCode: 'USD',
      units: '30',
      nanos: 0,
    },
    'non-political venueReplay video': {
      currencyCode: 'USD',
      units: '40',
      nanos: 0,
    },
    'non-political web_logs banner': {
      currencyCode: 'USD',
      units: '25',
      nanos: 0,
    },
    'non-political web_logs video': {
      currencyCode: 'USD',
      units: '35',
      nanos: 0,
    },
  },
  msaSigned: false,
  name: 'CInco Test Deploys',
  parentOrgId: '',
  refId: '',
  reseller: false,
  salesRepIds: [],
  userIds: [],
  status: 'ORG_STATUS_CREATED',
  prepay: false,
}

export const fixtureOrgs: Orgmanagerv1Org[] = [org1, org2]
