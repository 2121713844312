import { Campaignservicev1OrderLine } from 'next-gen-sdk'

export const ol_fixtures: Campaignservicev1OrderLine[] = [
  {
    createTime: new Date('2021-11-19T20:19:03.329042075Z'),
    updateTime: new Date('0001-01-01T00:00:00Z'),
    deleteTime: new Date('0001-01-01T00:00:00Z'),
    id: 'ce889c5a-36a9-478a-9627-4c0f2078eff6',
    refId: '',
    orgId: '6a197534-131c-4fc6-aecc-b7846087f8ec',
    status: 'ORDERLINE_STATUS_DRAFT',
    name: 'Donuts are Great!-OL',
    campaign: { id: '6811ff30-2fba-4b22-9b01-4ffa80e55e79' },
    creatives: [],
    audiences: [
      {
        id: 'c5fku1kml4phus9imr20',
      },
    ],
    startTime: new Date('2021-11-19T20:02:09.307Z'),
    endTime: new Date('2021-12-19T20:02:09.307Z'),
    impressions: 0,
    clickThroughUrl: '',
    deploymentDestinationConfiguration: {
      xandrInvest: undefined,
      xandrConsole: undefined,
      directMail: undefined,
    },
  },
  {
    createTime: new Date(),
    updateTime: undefined,
    deleteTime: undefined,
    id: '1f98dff7-442f-4f74-93e7-5609bfe48f47',
    refId: '',
    orgId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
    status: 'ORDERLINE_STATUS_DRAFT',
    name: 'funnyTest',
    campaign: { id: '1234' },
    creatives: [
      {
        id: 'e116fff9-95b4-4c84-9106-6b842a65d841',
      },
    ],
    audiences: [
      {
        id: 'c5ss89u4hrp22ltlf3rg',
        type: 'AUDIENCE_TYPE_ADDRESS',
      },
    ],
    startTime: new Date('2021-12-26T21:27:49.490Z'),
    endTime: new Date('2021-12-29T21:27:49.490Z'),
    impressions: 70000,
    clickThroughUrl: '',
    deploymentDestinationConfiguration: {
      xandrInvest: {
        advertiserRefId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
        advertiserName: 'SMOKE_TEST_NAME_2052f0a2-914f-4130-aca9-d006a52f0c81',
        clickThroughUrl: '',
        investCampaign: {
          useSplits: false,
          comments: 'Deploy Service Smoke Test',
          clickUrl: 'https://www.example.com',
          seamlessInsertionOrder: {
            name: 'funnyTest',
            code: '1f98dff7-442f-4f74-93e7-5609bfe48f47',
            budgetType: 'impression',
            currency: 'USD',
            timezone: 'EST5EDT',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                lifetimePacing: false,
                enablePacing: false,
              },
            ],
            politicalContent: undefined,
          },
          profile: {
            browserAction: 'exclude',
            browserTargets: [],
            countryAction: 'include',
            countryTargets: [
              {
                id: 233,
                name: 'United States',
                code: 'US',
              },
            ],
            deviceTypeAction: 'include',
            deviceTypeTargets: ['tablet', 'pc', 'tv', 'phone'],
            domainListAction: 'exclude',
            domainListTargets: [],
            inventoryListTargets: [],
            inventoryAction: 'exclude',
            inventoryAttributeTargets: [],
            supplyTypeAction: 'include',
            supplyTypeTargets: ['mobile_web', 'web'],
            useInventoryAttributeTargets: false,
            intendedAudienceTargets: [],
            segmentBooleanOperator: 'and',
            segmentGroupTargets: [
              {
                booleanOperator: 'or',
                segments: [],
              },
            ],
            daypartTargets: [
              {
                day: 'sunday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'monday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'tuesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'wednesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'thursday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'friday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'saturday',
                startHour: 5,
                endHour: 23,
              },
            ],
          },
          augmentedLineItem: {
            state: 'active',
            name: 'funnyTest',
            code: 'funnyTest',
            clickUrl: 'https://www.example.com',
            creativeDistributionType: 'even',
            discrepancyPct: 0,
            publishersAllowed: 'all',
            requireCookieForTracking: true,
            lineItemType: 'standard_v2',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                endDate: '2023-06-19 00:00:00',
                lifetimeBudgetImps: 30000,
                dailyBudgetImps: 6936,
                lifetimePacingPct: 110,
                lifetimePacing: true,
                enablePacing: true,
              },
            ],
            valuation: {
              minMarginPct: 80,
              bidPricePacingEnabled: false,
              bidPricePacingLever: 0,
              noRevenueLog: false,
            },
            revenueType: 'cpm',
            revenueValue: 10,
            priority: 5,
            adTypes: ['banner'],
            preferDeliveryOverPerformance: false,
            supplyStrategies: {
              deals: false,
              managed: false,
              rtb: true,
            },
            manageCreative: true,
            currency: 'USD',
          },
          budgetSplitter: [],
        },
        segments: [
          {
            refId: 'c5ss89u4hrp22ltlf3rg',
            code: 104,
            codeString: '',
            name: 'test.csv',
            type: 'DEPLOY_SEGMENT_TYPE_IP',
            location:
              'targetuploads.eltoro.com/dev/c5ss8887aim2f2sg1nj0/quote/address/2021-10-27/c5ss8fm4hrp22ltlf3s0-selected.csv',
            exclude: false,
            size: 0,
          },
        ],
        creatives: [
          {
            refId: 'e116fff9-95b4-4c84-9106-6b842a65d841',
          },
        ],
      },
      xandrConsole: undefined,
      directMail: undefined,
    },
  },
  {
    createTime: new Date(),
    updateTime: undefined,
    deleteTime: undefined,
    id: '1f98dff7-442f-4f74-93e7-5609bfe48f43',
    refId: '',
    orgId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
    status: 'ORDERLINE_STATUS_DRAFT',
    name: 'bannana man',
    campaign: { id: '1234' },
    creatives: [
      {
        id: 'e116fff9-95b4-4c84-9106-6b842a65d841',
      },
    ],
    audiences: [
      {
        id: 'c5ss89u4hrp22ltlf3rg',

        type: 'AUDIENCE_TYPE_ADDRESS',
      },
    ],
    startTime: new Date('2021-12-26T21:27:49.490Z'),
    endTime: new Date('2021-12-29T21:27:49.490Z'),
    impressions: 50000,
    clickThroughUrl: '',
    deploymentDestinationConfiguration: {
      xandrInvest: {
        advertiserRefId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
        advertiserName: 'SMOKE_TEST_NAME_2052f0a2-914f-4130-aca9-d006a52f0c81',
        clickThroughUrl: '',
        investCampaign: {
          useSplits: false,
          comments: 'Deploy Service Smoke Test',
          clickUrl: 'https://www.example.com',
          seamlessInsertionOrder: {
            name: 'bannana man',
            code: '1f98dff7-442f-4f74-93e7-5609bfe48f47',
            budgetType: 'impression',
            currency: 'USD',
            timezone: 'EST5EDT',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                lifetimePacing: false,
                enablePacing: false,
              },
            ],
            politicalContent: undefined,
          },
          profile: {
            browserAction: 'exclude',
            browserTargets: [],
            countryAction: 'include',
            countryTargets: [
              {
                id: 233,
                name: 'United States',
                code: 'US',
              },
            ],
            deviceTypeAction: 'include',
            deviceTypeTargets: ['tablet', 'pc', 'tv', 'phone'],
            domainListAction: 'exclude',
            domainListTargets: [],
            inventoryListTargets: [],
            inventoryAction: 'exclude',
            inventoryAttributeTargets: [],
            supplyTypeAction: 'include',
            supplyTypeTargets: ['mobile_web', 'web'],
            useInventoryAttributeTargets: false,
            intendedAudienceTargets: [],
            segmentBooleanOperator: 'and',
            segmentGroupTargets: [
              {
                booleanOperator: 'or',
                segments: [],
              },
            ],
            daypartTargets: [
              {
                day: 'sunday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'monday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'tuesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'wednesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'thursday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'friday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'saturday',
                startHour: 5,
                endHour: 23,
              },
            ],
          },
          augmentedLineItem: {
            state: 'active',
            name: 'bannana man',
            code: 'bannana man',
            clickUrl: 'https://www.example.com',
            creativeDistributionType: 'even',
            discrepancyPct: 0,
            publishersAllowed: 'all',
            requireCookieForTracking: true,
            lineItemType: 'standard_v2',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                endDate: '2023-06-19 00:00:00',
                lifetimeBudgetImps: 30000,
                dailyBudgetImps: 6936,
                lifetimePacingPct: 110,
                lifetimePacing: true,
                enablePacing: true,
              },
            ],
            valuation: {
              minMarginPct: 80,
              bidPricePacingEnabled: false,
              bidPricePacingLever: 0,
              noRevenueLog: false,
            },
            revenueType: 'cpm',
            revenueValue: 10,
            priority: 5,
            adTypes: ['banner'],
            preferDeliveryOverPerformance: false,
            supplyStrategies: {
              deals: false,
              managed: false,
              rtb: true,
            },
            manageCreative: true,
            currency: 'USD',
          },
          budgetSplitter: [],
        },
        segments: [
          {
            refId: 'c5ss89u4hrp22ltlf3rg',
            code: 104,
            codeString: '',
            name: 'test.csv',
            type: 'DEPLOY_SEGMENT_TYPE_IP',
            location:
              'targetuploads.eltoro.com/dev/c5ss8887aim2f2sg1nj0/quote/address/2021-10-27/c5ss8fm4hrp22ltlf3s0-selected.csv',
            exclude: false,
            size: 0,
          },
        ],
        creatives: [
          {
            refId: 'e116fff9-95b4-4c84-9106-6b842a65d841',
          },
        ],
      },
      xandrConsole: undefined,
      directMail: undefined,
    },
  },
  {
    createTime: new Date(),
    updateTime: undefined,
    deleteTime: undefined,
    id: '1f98dff7-442f-4f74-93e7-5609bfe48f49',
    refId: '',
    orgId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
    status: 'ORDERLINE_STATUS_DRAFT',
    name: 'fruity pebbs',
    campaign: { id: '1234' },
    creatives: [
      {
        id: 'e116fff9-95b4-4c84-9106-6b842a65d841',
      },
    ],
    audiences: [
      {
        id: 'c5ss89u4hrp22ltlf3rg',
        type: 'AUDIENCE_TYPE_ADDRESS',
      },
    ],
    startTime: new Date('2021-12-26T21:27:49.490Z'),
    endTime: new Date('2021-12-29T21:27:49.490Z'),
    impressions: 55000,
    clickThroughUrl: '',
    deploymentDestinationConfiguration: {
      xandrInvest: {
        advertiserRefId: '64368295-5f27-4982-95d9-6b2f31f8bf05',
        advertiserName: 'SMOKE_TEST_NAME_2052f0a2-914f-4130-aca9-d006a52f0c81',
        clickThroughUrl: '',
        investCampaign: {
          useSplits: false,
          comments: 'Deploy Service Smoke Test',
          clickUrl: 'https://www.example.com',
          seamlessInsertionOrder: {
            name: 'fruity pebbs',
            code: '1f98dff7-442f-4f74-93e7-5609bfe48f47',
            budgetType: 'impression',
            currency: 'USD',
            timezone: 'EST5EDT',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                lifetimePacing: false,
                enablePacing: false,
              },
            ],
            politicalContent: undefined,
          },
          profile: {
            browserAction: 'exclude',
            browserTargets: [],
            countryAction: 'include',
            countryTargets: [
              {
                id: 233,
                name: 'United States',
                code: 'US',
              },
            ],
            deviceTypeAction: 'include',
            deviceTypeTargets: ['tablet', 'pc', 'tv', 'phone'],
            domainListAction: 'exclude',
            domainListTargets: [],
            inventoryListTargets: [],
            inventoryAction: 'exclude',
            inventoryAttributeTargets: [],
            supplyTypeAction: 'include',
            supplyTypeTargets: ['mobile_web', 'web'],
            useInventoryAttributeTargets: false,
            intendedAudienceTargets: [],
            segmentBooleanOperator: 'and',
            segmentGroupTargets: [
              {
                booleanOperator: 'or',
                segments: [],
              },
            ],
            daypartTargets: [
              {
                day: 'sunday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'monday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'tuesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'wednesday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'thursday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'friday',
                startHour: 5,
                endHour: 23,
              },
              {
                day: 'saturday',
                startHour: 5,
                endHour: 23,
              },
            ],
          },
          augmentedLineItem: {
            state: 'active',
            name: 'fruitty pebbs',
            code: 'fruitty pebbs',
            clickUrl: 'https://www.example.com',
            creativeDistributionType: 'even',
            discrepancyPct: 0,
            publishersAllowed: 'all',
            requireCookieForTracking: true,
            lineItemType: 'standard_v2',
            budgetIntervals: [
              {
                startDate: '2022-08-23 00:00:00',
                endDate: '2023-06-19 00:00:00',
                lifetimeBudgetImps: 30000,
                dailyBudgetImps: 6936,
                lifetimePacingPct: 110,
                lifetimePacing: true,
                enablePacing: true,
              },
            ],
            valuation: {
              minMarginPct: 80,
              bidPricePacingEnabled: false,
              bidPricePacingLever: 0,
              noRevenueLog: false,
            },
            revenueType: 'cpm',
            revenueValue: 10,
            priority: 5,
            adTypes: ['banner'],
            preferDeliveryOverPerformance: false,
            supplyStrategies: {
              deals: false,
              managed: false,
              rtb: true,
            },
            manageCreative: true,
            currency: 'USD',
          },
          budgetSplitter: [],
        },
        segments: [
          {
            refId: 'c5ss89u4hrp22ltlf3rg',
            code: 104,
            codeString: '',
            name: 'test.csv',
            type: 'DEPLOY_SEGMENT_TYPE_IP',
            location:
              'targetuploads.eltoro.com/dev/c5ss8887aim2f2sg1nj0/quote/address/2021-10-27/c5ss8fm4hrp22ltlf3s0-selected.csv',
            exclude: false,
            size: 0,
          },
        ],
        creatives: [
          {
            refId: 'e116fff9-95b4-4c84-9106-6b842a65d841',
          },
        ],
      },
      xandrConsole: undefined,
      directMail: undefined,
    },
  },
]
