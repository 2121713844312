/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3RegistrationForm } from '../models/V3RegistrationForm';
import { HttpFile } from '../http/http';

/**
* See [Political Content](https://docs.xandr.com/bundle/xandr-api/page/insertion-order-service.html#InsertionOrderService-PoliticalContent) in Xandr's documentation.
*/
export class V3PoliticalContent {
    /**
    * Read-only. The name of the person or Organization that is purchasing ads on Xandr. This will be automatically filled out with the name of the Xandr member.
    */
    'billingName'?: string;
    /**
    * Required. The street address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingAddress1'?: string;
    /**
    * Optional additional line for the billing address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingAddress2'?: string;
    /**
    * Required. City of the billing address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingCity'?: string;
    /**
    * Required. State or region of the billing address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingRegion'?: string;
    /**
    * Required. ZIP or postal code of the billing address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingPostalCode'?: string;
    /**
    * Required. Country of the billing address for the person or Organization that is purchasing ads on Xandr.
    */
    'billingCountry'?: string;
    /**
    * Required. Country code for the phone number for the person or Organization that is purchasing ads on Xandr.
    */
    'billingPhoneCode'?: string;
    /**
    * Required. Contact phone number for the person or Organization that is purchasing ads on Xandr.
    */
    'billingPhone'?: string;
    /**
    * ID number assigned by the U.S. Federal Election Committee.
    */
    'usfecid'?: string;
    /**
    * Required. Name of the person, group, Organization, or business that is advertising (the client that is paying you). For example, a candidate, an agency, or a political consultant.
    */
    'orgName'?: string;
    /**
    * Required. Address of the person, group, Organization, or business that is advertising. For example, a candidate, an agency, or a political consultant.
    */
    'orgAddress1'?: string;
    /**
    * Optional second line for the address of the person, group, Organization, or business that is advertising.
    */
    'orgAddress2'?: string;
    /**
    * Required. City in the address of the person, group, Organization, or business that is advertising.
    */
    'orgCity'?: string;
    /**
    * Required. State or region in the address of the person, group, Organization, or business that is advertising.
    */
    'orgRegion'?: string;
    /**
    * Required.  ZIP or postal code of the person, group, Organization, or business that is advertising.
    */
    'orgPostalCode'?: string;
    /**
    * Required. Country of the person, group, Organization, or business that is advertising.
    */
    'orgCountry'?: string;
    /**
    * Required. Country code for the phone number of the person, group, Organization, or business that is advertising.
    */
    'orgPhoneCode'?: string;
    /**
    * Required. Phone number of the person, group, Organization, or business that is advertising.
    */
    'orgPhone'?: string;
    /**
    * Required. Treasurer for the committee purchasing the ads, or the individual whose role would most closely fit that of a treasurer.
    */
    'treasurerName'?: string;
    /**
    * Required. How the political Organization pays you.
    */
    'paymentMethodType'?: string;
    /**
    * Required. The candidate or ballot initiative that is supported or opposed.
    */
    'politicalObjective'?: string;
    /**
    * Required if '4' (Other) is selected for payment_method_type. Details of how you are being paid for political advertising.
    */
    'paymentMethodOther'?: string;
    /**
    * Required. Specifies whether any ads are being paid for by an independent expenditure committee: That is, a third party that spends money on political communications that expressly advocate the election or defeat of a clearly identified candidate and does not coordinate with a candidate, a candidate’s authorized committee, or an agent of the candidate.
    */
    'isExpenditureCommittee'?: boolean;
    'registrationForm'?: V3RegistrationForm;
    /**
    * Required. Specifies that the Xandr member has certified that the political_content information provided is accurate and up-to-date and that Xandr is relying on the accuracy of the information provided. If set to 0 (the default), creatives will not be allowed to serve.
    */
    'isAccuracyAcknowledged'?: boolean;
    /**
    * Required if is_running_political_ads is true.
    */
    'governmentLevel'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "billingName",
            "baseName": "billing_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingAddress1",
            "baseName": "billing_address_1",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingAddress2",
            "baseName": "billing_address_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingCity",
            "baseName": "billing_city",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingRegion",
            "baseName": "billing_region",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingPostalCode",
            "baseName": "billing_postal_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingCountry",
            "baseName": "billing_country",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingPhoneCode",
            "baseName": "billing_phone_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "billingPhone",
            "baseName": "billing_phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "usfecid",
            "baseName": "usfecid",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgAddress1",
            "baseName": "org_address_1",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgAddress2",
            "baseName": "org_address_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgCity",
            "baseName": "org_city",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgRegion",
            "baseName": "org_region",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPostalCode",
            "baseName": "org_postal_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgCountry",
            "baseName": "org_country",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPhoneCode",
            "baseName": "org_phone_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgPhone",
            "baseName": "org_phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "treasurerName",
            "baseName": "treasurer_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentMethodType",
            "baseName": "payment_method_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "politicalObjective",
            "baseName": "political_objective",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentMethodOther",
            "baseName": "payment_method_other",
            "type": "string",
            "format": ""
        },
        {
            "name": "isExpenditureCommittee",
            "baseName": "is_expenditure_committee",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "registrationForm",
            "baseName": "registration_form",
            "type": "V3RegistrationForm",
            "format": ""
        },
        {
            "name": "isAccuracyAcknowledged",
            "baseName": "is_accuracy_acknowledged",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "governmentLevel",
            "baseName": "government_level",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3PoliticalContent.attributeTypeMap;
    }

    public constructor() {
    }
}

