import { useState } from 'react'
import {
  Modal,
  Tabs,
  TextHeader,
  showErrorMessage,
  showInfoAlert,
  showSuccessMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import {
  PolygonMap,
  RetargetingPixel,
  WarningModal,
  AudienceUploadStepper,
} from 'Components'
import { AudienceLibraryPage, audienceUploadConfigs } from 'Pages'
import { useAppContext } from 'Contexts'
import './EditOLAudiences.scss'

export const EditOLAudiences: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onClose: () => void
  refreshTableOL: () => void
  startingState?: 'library' | 'create'
}> = ({ orderLine, onClose, startingState, refreshTableOL }) => {
  const [addError, setAddError] = useState<string>()

  const { campaignServiceApi, currentOrg } = useAppContext()

  // note- if we change this to allow any audiences to be added from here, may still need to block adding retargeting to political
  const filterByProductType = orderLine.audiences?.[0]?.productType

  const handleAddAudience = (audience: Targetjobservicev1Audience) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id) return
    showInfoAlert(
      'Adding audience...',
      'Your audience is being added to the order line',
    )
    const audiences =
      audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
        ? audience.audiences.map(({ id }) => ({ id, exclude: false }))
        : [{ id: audience.id, exclude: false }]

    campaignServiceApi
      .advertisingPlatformServiceBatchAddAudiences(orderLine.id, {
        orgId: currentOrg.id,
        audiences,
      })
      .then(() => {
        showSuccessMessage(
          `${audiences.length > 1 ? 'Audiences' : 'Audience'} Attached`,
          `Your ${
            audiences.length > 1 ? 'audiences were' : 'audience was'
          } attached to the order line`,
        )
        refreshTableOL()
      })
      .catch(() =>
        showErrorMessage(
          `Error adding audience${audiences.length > 1 ? 's' : ''}`,
          '',
        ),
      )
  }

  const handleRemoveAudience = (audience: Targetjobservicev1Audience) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id || !audience.id)
      return
    showInfoAlert(
      'Removing audience...',
      'Your audience is being removed from the order line',
    )
    const audienceIds =
      audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
        ? audience.audiences.reduce((acc: string[], curr) => {
            if (!curr.id) return acc
            return [...acc, curr.id]
          }, [])
        : [audience.id]

    campaignServiceApi
      .advertisingPlatformServiceBatchRemoveAudiences(orderLine.id, {
        orgId: currentOrg.id,
        audienceIds,
      })
      .then(() => {
        showSuccessMessage(
          `${audienceIds.length > 1 ? 'Audiences' : 'Audience'} Attached`,
          `Your ${
            audienceIds.length > 1 ? 'audiences were' : 'audience was'
          } attached to the order line`,
        )
        refreshTableOL()
      })
      .catch(() =>
        showErrorMessage(
          `Error adding audience${audienceIds.length > 1 ? 's' : ''}`,
          '',
        ),
      )
  }

  return (
    <Modal
      className="EditOLAudiences min-h-[85vh] w-[80%] p-4"
      offClick={onClose}
      header={<TextHeader type={3}>Add Audiences: {orderLine.name}</TextHeader>}
    >
      <div className="EditOLAudiences__selections mb-4 mt-4 grid grid-cols-3 gap-2 px-5"></div>
      {startingState === 'library' && (
        <AudienceLibraryPage
          selectedAudiences={orderLine.audiences}
          filterByProductType={filterByProductType && [filterByProductType]}
          onAddAudienceToAttach={handleAddAudience}
          onRemoveAudienceFromAttach={handleRemoveAudience}
        />
      )}
      {startingState === 'create' && (
        <>
          {(() => {
            if (!filterByProductType) return null
            if (filterByProductType === 'PRODUCT_TYPE_MAP_POLYGON')
              return (
                <PolygonMap
                  vr={false}
                  onAddAudienceToAttach={handleAddAudience}
                />
              )
            if (filterByProductType === 'PRODUCT_TYPE_VENUE_REPLAY')
              return <PolygonMap vr onAddAudienceToAttach={handleAddAudience} />
            if (filterByProductType === 'PRODUCT_TYPE_RETARGETING')
              return (
                <RetargetingPixel onAddAudienceToAttach={handleAddAudience} />
              )
            if (filterByProductType === 'PRODUCT_TYPE_NEW_MOVERS')
              return (
                <AudienceUploadStepper
                  audienceType="new-mover"
                  onAddAudienceToAttach={handleAddAudience}
                />
              )
            if (filterByProductType === 'PRODUCT_TYPE_DIGITAL_CANVASSING')
              return (
                <AudienceUploadStepper
                  audienceType="dc"
                  onAddAudienceToAttach={handleAddAudience}
                  useInCampaignButton={false}
                />
              )

            const uploadConfigs = audienceUploadConfigs.filter(
              ({ id }) =>
                id === 'address' ||
                id === 'zip' ||
                id === 'ip' ||
                id === 'reverseIp',
              // id === 'reverse'
            )

            const getDefaultTab = (tabId: string) => {
              const type = orderLine.audiences?.[0]?.type
              if (type) {
                if (
                  type === 'AUDIENCE_TYPE_ADDRESS' ||
                  type === 'AUDIENCE_TYPE_B2B' ||
                  type === 'AUDIENCE_TYPE_B2C'
                )
                  return tabId === 'address'
                if (type === 'AUDIENCE_TYPE_DC') return tabId === 'dc'
                if (type === 'AUDIENCE_TYPE_ZIP') return tabId === 'zip'
                if (type === 'AUDIENCE_TYPE_IP') return tabId === 'ip'
                if (type === 'AUDIENCE_TYPE_REVERSE')
                  return tabId === 'reverseIp'
              }
              return false
            }
            return (
              <Tabs
                tabs={uploadConfigs.map((config) => {
                  return {
                    id: config.id,
                    defaultTab: getDefaultTab(config.id),
                    label: (
                      <>
                        {config.icon}
                        <span className="flex flex-col">
                          <div className="EditOLTagets__tabs-category Tabs-category">
                            {config.tagLabel}
                          </div>
                          <span>{config.title}</span>
                        </span>
                      </>
                    ),
                    component: (
                      <AudienceUploadStepper
                        audienceType={config.id}
                        onAddAudienceToAttach={handleAddAudience}
                        useInCampaignButton={false}
                      />
                    ),
                  }
                })}
              />
            )
          })()}
        </>
      )}
      {addError && (
        <WarningModal onConfirm={() => setAddError(undefined)}>
          {addError}
        </WarningModal>
      )}
    </Modal>
  )
}
