import { FC, MouseEventHandler, ReactNode } from 'react'
import './DropdownInput.scss'

export const DropdownInput: FC<{
  options: any[]
  loading?: boolean
  children?: ReactNode
}> = ({ options, loading = false, children }) => {
  return (
    <div className="DropdownInput">
      {children}
      {options.length > 0 && !loading && (
        <div className="DropdownInput__dropdown">
          {options.map(
            (
              option: {
                label: ReactNode
                onClick: MouseEventHandler<HTMLButtonElement>
              },
              index: number,
            ) => (
              <button
                key={index}
                type="button"
                className="DropdownInput__result"
                onClick={option.onClick}
              >
                {option.label}
              </button>
            ),
          )}
        </div>
      )}
      {loading && (
        <div className="DropdownInput__dropdown DropdownInput__dropdown--loading">
          <p>Loading results...</p>
        </div>
      )}
    </div>
  )
}
