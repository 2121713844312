import { ReactNode, useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import './Uploader.scss'

type UploaderProps = {
  accepts?:
    | 'images'
    | 'video'
    | 'all'
    | 'hd-only'
    | 'file-upload'
    | 'logo'
    | 'support'
  className?: string
  isStyled?: boolean
  children?: ReactNode
  maxFiles?: number
  onDrop?: (files: PreviewType[], rejectedFiles?: FileRejection[]) => void
  onDragAccepted?: (isAccepted: boolean) => void
  onDragActive?: (isActive: boolean) => void
  onDragRejected?: (isRejected: boolean) => void
  handleDropDeps?: React.DependencyList
}

export interface PreviewType extends File {
  preview: string
  height: number
  width: number
  duration?: number
  rows?: number
  path?: string
}
export const cleanedMime = (mime: string) => mime?.replace('.', '')

const imageMimes = [
  'bmp',
  'gif',
  'ico',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/zip',
  'application/x-zip-compressed',
  'application/x-compressed',
  'zip',
]
export const videoMimes = [
  'avi',
  'mp4',
  'mpeg',
  'ogv',
  'ts',
  'webm',
  '3gp',
  '3g2',
  'video/avi',
  'video/mp4',
  'video/mpeg',
  'video/ogv',
  'video/ts',
  'video/webm',
  'video/3gp',
  'video/3g2',
]
// const audioMimes = ['aac', 'mid', 'midi', 'mp3', 'oga', 'opus', 'wav', 'weba']
const isImage = (mime: string) =>
  imageMimes.some((imageMime) => imageMime === mime)
const isVideo = (mime: string) =>
  videoMimes.some((videoMime) => videoMime === mime)
// const isAudio = (mime: string) =>
//   audioMimes.some((audioMime) => audioMime === mime)

export const Uploader = ({
  accepts = 'all',
  className = '',
  children,
  isStyled = false,
  maxFiles = 0, // no limit by default
  onDrop,
  onDragAccepted,
  onDragActive,
  onDragRejected,
  handleDropDeps,
}: UploaderProps) => {
  const cleanedMime = (mime: string) => mime?.replace('.', '')
  const reader = (file: PreviewType) => {
    const cleanMime = cleanedMime(file.type)
    // CSV/Txt File Uploads
    // Currently iterates through and counts rows
    // will need to figure out a way to

    if (accepts === 'file-upload') {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading failed')
      reader.onload = () => {
        const content = reader.result as string
        // split csv file using "\n" for new line ( each row)
        const lines = content?.split('\n')
        // File Row count minus headers
        const countWithoutHeaders = lines.length - 1
        if (lines[lines.length - 1] === '') {
          const removeEmpty = countWithoutHeaders - 1
          Object.assign(file, { rows: removeEmpty })
        } else {
          Object.assign(file, { rows: countWithoutHeaders })
        }
      }
      reader.readAsText(file)
    }
    if (isVideo(cleanMime)) {
      const reader = new FileReader()
      reader.onload = () => {
        const videoElement = document.createElement('video')
        videoElement.src = file.preview
        const timer = setInterval(() => {
          if (videoElement.readyState === 4) {
            if (videoElement.duration) {
              Object.assign(file, {
                duration: videoElement.duration,
                height: videoElement.videoHeight,
                width: videoElement.videoWidth,
                data: reader.result,
              }) // video duration
            }
            clearInterval(timer)
          }
        }, 500)
      }
      reader.readAsDataURL(file)
    }
    if (isImage(cleanMime)) {
      const i = new Image()
      i.onload = () => {
        const reader = new FileReader()
        i.src = file.preview

        reader.readAsDataURL(file)
        reader.onload = () => {
          Object.assign(file, {
            width: i.width,
            height: i.height,
            data: reader.result,
          })
        }
      }
    }
    return file
  }

  const handleDrop = useCallback(
    (acceptedFiles, fileRejections?: FileRejection[]) => {
      let droppedFiles: PreviewType[] = []
      acceptedFiles.forEach((file1: PreviewType) => {
        const file: PreviewType = file1
        file.preview = URL.createObjectURL(file)
        if (!file.type) {
          if (!file.type && /\.csv$/i.test(file.path || file.name)) {
            ;(file.type as string) = 'text/csv'
          }
          // if (!file.type && /\.te?xt/i.test(file.path || file.name)) {
          //   ;(file.type as string) = 'text/plain'
          // }
          if (!file.type && /\.(jpe?g|png|gif)/i.test(file.path || file.name)) {
            ;(file.type as string) = 'image/png'
          }
          if (
            !file.type &&
            /\.(mpg|mov|avi|flv)/i.test(file.path || file.name)
          ) {
            ;(file.type as string) = 'video/mpg'
          }
          if (!file.type) {
            ;(file.type as string) = 'application/octet-stream'
          }
        }

        for (let i = 0; i < acceptedFiles.length; i += 1) {
          reader(file)
        }
        droppedFiles = [...droppedFiles, file]
      })
      if (onDrop) onDrop(droppedFiles, fileRejections)
    },
    handleDropDeps || [],
  )

  const uploadTypeRestrictor = () => {
    switch (accepts) {
      case 'video':
        return '.mpg, .mp4, .mov, .avi'
      case 'images':
        return 'image/jpeg, image/gif, image/png, .zip, application/zip, application/x-gzip, application/octet-stream,'
      case 'logo':
        return 'image/jpeg, image/gif, image/png'
      case 'all':
        return ''
      case 'hd-only':
        return '.mp4'
      case 'file-upload':
        // return '.csv, .txt, text/csv, text/txt'
        return '.csv, text/csv'
      case 'support':
        return `video/avi,
          video/mp4,
          video/mpeg,
          video/ogv,
          video/ts,
          video/webm,
          video/3gp,
          video/3g2,
          video/mov,
          image/jpeg,
          image/gif,
          image/png,`

      default:
        return ''
    }
  }

  const {
    getRootProps,
    getInputProps,
    // acceptedFiles,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: uploadTypeRestrictor(),
    onDrop: handleDrop,
    // onDragEnter: () => console.log('disabpe-e cinco'),
    maxFiles,
  })

  if (onDragAccepted) onDragAccepted(isDragAccept)
  if (onDragActive) onDragActive(isDragActive)
  if (onDragRejected) onDragRejected(isDragReject)

  const uploaderClassNames = () => {
    if (isStyled) {
      return classNames(
        'Uploader IsStyled border-grey-300 border-default w-full rounded-lg hover:cursor-pointer border-dashed h-full items-center flex flex-col justify-center',
        {
          'Uploader--is-accept border-success-600': isDragAccept,
          'Uploader--is-rejected border-danger-600 text-danger-600 cursor-not-allowed': isDragReject,
          'Uploader--is-active border-grey-300': isDragActive,
          'Uploader--is-styled border-grey-200': isStyled,
        },
        className,
      )
    }
    return `Uploader ${className} w-full rounded-lg border-dashed w-full hover:cursor-pointer border-default border-grey-300 overflow-hidden`
  }

  return (
    <div className={uploaderClassNames()} {...getRootProps()}>
      <div className="Uploader__content w-full flex flex-col gap-2 items-center justify-center border-none">
        <input {...getInputProps()} />
        {children}
      </div>
    </div>
  )
}
