/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Hagridv1Checks } from '../models/Hagridv1Checks';
import { Hagridv1HealthCondition } from '../models/Hagridv1HealthCondition';
import { HttpFile } from '../http/http';

export class Hagridv1HealthCheck {
    'serviceName'?: string;
    'healthCondition'?: Hagridv1HealthCondition;
    'commitHash'?: string;
    'serviceVersion'?: string;
    'startTime'?: Date;
    'checks'?: Hagridv1Checks;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "serviceName",
            "baseName": "serviceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "healthCondition",
            "baseName": "healthCondition",
            "type": "Hagridv1HealthCondition",
            "format": ""
        },
        {
            "name": "commitHash",
            "baseName": "commitHash",
            "type": "string",
            "format": ""
        },
        {
            "name": "serviceVersion",
            "baseName": "serviceVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "startTime",
            "baseName": "startTime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "checks",
            "baseName": "checks",
            "type": "Hagridv1Checks",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Hagridv1HealthCheck.attributeTypeMap;
    }

    public constructor() {
    }
}

