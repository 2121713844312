import { useEffect, useState } from 'react'
import { Modal, PreviewType, TextHeader } from '@eltoro-ui/components'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import { AudienceColumnSelector, getColumnSelectorTypes } from 'Components'
import { downloadFile, urltoFile } from 'Requests'
import { useLibraryContext } from 'Contexts'

export const ColumnEditModal = ({
  onClose,
  target,
  audience,
}: {
  onClose: () => void
  target: V2Target
  audience: Targetjobservicev1Audience
}) => {
  const [file, setFile] = useState<PreviewType>()
  const {
    pagination: { updateRow, findCachedRow },
    handleSetSelectedItem,
  } = useLibraryContext()
  useEffect(() => {
    const handleGetFile = async () => {
      const fileData = await downloadFile({ target, audience }, true, true)
      const url = window.URL.createObjectURL(
        new Blob([fileData], { type: 'text/csv' }),
      )
      const targetName =
        target?.locations?.find((f) => f.type === 'source')?.filename ||
        `${audience.name}`
      const file = (await urltoFile(url, targetName)) as PreviewType
      Object.assign(file, { preview: url, height: 0, width: 0 })
      setFile(file)
    }
    handleGetFile()
  }, [audience, target])

  return (
    <Modal
      offClick={() => onClose()}
      header={<TextHeader className="py-1">Edit Columns</TextHeader>}
      className="ColumnEditModal min-w-[calc(100%_-_2rem)]"
    >
      {file && target.id && audience.id && (
        <AudienceColumnSelector
          droppedFile={file}
          onUploadComplete={() => {
            const existingAudience = findCachedRow(
              (aud) => aud?.id === audience.id,
            )?.existingItem
            if (existingAudience) {
              const updatedAudience = {
                ...existingAudience,
                status: 'AUDIENCE_STATUS_RUNNING',
              } as Targetjobservicev1Audience

              updateRow((aud) => aud?.id === audience.id, updatedAudience)

              handleSetSelectedItem(updatedAudience)
            }
            onClose()
          }}
          replaceFileOptions={{
            targetId: target.id,
            audienceIds: [audience.id],
            existingTarget: target,
          }}
          {...getColumnSelectorTypes(audience.type)}
        />
      )}
    </Modal>
  )
}
