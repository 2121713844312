import { useEffect, useState } from 'react'
import { Orgmanagerv1Contact } from 'next-gen-sdk'
import { useValidation } from '@eltoro-ui/hooks'
import { styleTailwind, v2SyncPrompt } from 'Helpers'
import { WarningModal, EditableSection, ContactForm } from 'Components'
import { useAppContext } from 'Contexts'

const PrimaryHeader = styleTailwind('h2', 'text-primary')

export const ContactBlock = ({
  className = '',
  contact,
  type,
  onUpdate,
  onCreate,
  onRemove,
  isLoading = false,
}: {
  className?: string
  contact?: Orgmanagerv1Contact
  type: string
  onUpdate: (updatedContact: Orgmanagerv1Contact) => void
  onCreate: (newContact: Orgmanagerv1Contact, type: string) => void
  onRemove: (contactId: string) => void
  isLoading?: boolean
}) => {
  const { currentOrg } = useAppContext()
  const [inputContact, setInputContact] = useState<Orgmanagerv1Contact>(
    contact || {},
  )
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const {
    phone,
    email,
    zip,
    address,
    city,
    firstName,
    lastName,
    state,
    country,
  } = inputContact

  const isValidEmail = useValidation('email')
  const isValidPhone = useValidation('phone-number')
  const isValidZip = useValidation('zip-code')
  const isValidCanadianZip = useValidation('canadian-zip')

  const validContact =
    isValidEmail(email || '') &&
    isValidPhone(phone || '') &&
    (isValidZip(zip || '') || isValidCanadianZip(zip || '')) &&
    (address || '').length > 0 &&
    (city || '').length > 0 &&
    (firstName || '').length > 0 &&
    (lastName || '').length > 0 &&
    (state || '').length > 1 &&
    (email || '').length > 0 &&
    (zip || '').length > 0 &&
    (phone || '').length > 0 &&
    (country || '').length > 0

  const handleRemoveContact = () => {
    setShowRemoveModal(false)
    if (contact?.id) onRemove(contact.id)
    setInputContact({})
  }

  const handleOnChange = (newContact: Orgmanagerv1Contact) =>
    setInputContact((prev) => ({ ...prev, ...newContact }))

  useEffect(() => {
    if (contact) {
      setInputContact(contact)
    } else {
      setInputContact({})
    }
  }, [contact])

  return (
    <>
      <EditableSection
        isLoading={isLoading}
        label={type}
        canSubmit={validContact}
        canDelete
        onDelete={() =>
          v2SyncPrompt(() => setShowRemoveModal(true), currentOrg)
        }
        onSave={
          contact
            ? () =>
                onUpdate({
                  ...contact,
                  ...inputContact,
                })
            : () => onCreate(inputContact, type)
        }
        writableBody={
          <ContactForm
            contact={inputContact}
            onChange={handleOnChange}
            isValidEmail={isValidEmail(email || '')}
            isValidPhone={isValidPhone(phone || '')}
            isValidZip={isValidZip(zip || '') || isValidCanadianZip(zip || '')}
          />
        }
        readableBody={
          contact ? (
            ([
              'firstName',
              'lastName',
              'phone',
              'email',
              'address',
              'city',
              'state',
              'zip',
              'country',
            ] as const).map((field) => {
              let label
              if (field === 'state' && contact.country === 'Canada') {
                label = 'Province'
              } else if (field === 'zip' && contact.country === 'Canada') {
                label = 'Postal code'
              } else {
                label = field.split(/(?=[A-Z])/).join(' ')
              }
              return (
                <div className={`FormFields ${className}`} key={field}>
                  <span
                    className={`ContactBlock__label capitalize ${className} FormLabel Formlabel__${label}`}
                  >
                    {label}:
                  </span>
                  <span className="ContactBlock__value">
                    {contact?.[field]}
                  </span>
                </div>
              )
            })
          ) : (
            <p className="text-basetext">No {type} contact found</p>
          )
        }
      />
      {showRemoveModal && (
        <WarningModal
          onCancel={() => setShowRemoveModal(false)}
          onConfirm={handleRemoveContact}
        >
          <div className="flex flex-col">
            <PrimaryHeader>Remove contact</PrimaryHeader>
            <p>Are you sure you want to remove this contact from your org?</p>
          </div>
        </WarningModal>
      )}
    </>
  )
}
