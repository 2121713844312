import { Fa, TextHeader } from '@eltoro-ui/components'
import { simplifyEnum } from 'Helpers'
import { Creativeservicev1Status } from 'next-gen-sdk'

const statusTexts: {
  status: Creativeservicev1Status
  text: string
  icon: string
  color: string
}[] = [
  {
    status: 'CREATIVE_STATUS_CREATING',
    text: 'The creative is being created',
    icon: 'cloud',
    color: '!text-warning-400',
  },
  {
    status: 'CREATIVE_STATUS_ACTIVE',
    text: 'The creative is available for use',
    icon: 'circle-check',
    color: '!text-success-400',
  },
  {
    status: 'CREATIVE_STATUS_APPROVED',
    text: 'The creative is active and approved',
    icon: 'user-check',
    color: '!text-success-400',
  },
  {
    status: 'CREATIVE_STATUS_ERROR',
    text: 'The creative file is invalid or there was a bad upload',
    icon: 'triangle-exclamation',
    color: '!text-danger-500',
  },
  {
    status: 'CREATIVE_STATUS_EXPIRED',
    text: 'The creative is expired',
    icon: 'cloud-xmark',
    color: '!text-grey-500',
  },
  {
    status: 'CREATIVE_STATUS_PURGED',
    text: 'The creative files have been deleted and cannot be recovered',
    icon: 'trash-can-xmark',
    color: '!text-danger-500',
  },
  {
    status: 'CREATIVE_STATUS_ARCHIVED',
    text: 'The creative is archived',
    icon: 'box-archive',
    color: '!text-warning-400',
  },
  {
    status: 'CREATIVE_STATUS_UNARCHIVING',
    text: 'The creative is in the process of unarchiving',
    icon: 'box-circle-check',
    color: '!text-warning-400',
  },
]

export const CreativeStatusIndicator = ({
  status,
  bold,
}: {
  status: Creativeservicev1Status
  bold?: boolean
}) => {
  const match = statusTexts.find((s) => s.status === status)
  if (!match)
    return (
      <p className={bold ? 'font-semibold' : ''}>{simplifyEnum(2, status)}</p>
    )
  const { icon, color } = match
  return (
    <div className="CreativeStatusIndicator flex items-center gap-1">
      <Fa icon={icon} size={1} className={color} />
      <p className={bold ? 'font-semibold' : ''}>{simplifyEnum(2, status)}</p>
    </div>
  )
}

export const CreativeStatusPopover = () => {
  return (
    <div
      className="CreativeStatusPopover overflow-auto"
      onClick={(e) => e.stopPropagation()} // prevent the click on popover from opening/closing the table column's dropdown menu
    >
      <TextHeader type={4} className="mb-2">
        Creative Statuses
      </TextHeader>
      {statusTexts.map(({ status, text }) => (
        <div
          className="CreativeStatusPopover__row child-stripe text-basetext grid grid-cols-[1.25fr_3fr] items-center py-2 px-1"
          key={status}
        >
          <CreativeStatusIndicator status={status} bold />
          <p className="text-xs">{text}</p>
        </div>
      ))}
    </div>
  )
}
