import React, { useEffect, useState } from 'react'
import wkx from 'wkx'
import { Buffer } from 'buffer'
import { Feature } from 'geojson'
import { AutoComplete, PredictionWithLocationType } from '@eltoro-ui/suggest'
// import { makeGeoJSON } from 'make-geo-json'
import './Map.scss'
import 'leaflet/dist/leaflet.css'
import 'leaflet-easybutton/src/easy-button.css'
import * as L from 'leaflet'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'leaflet-easybutton'
import 'leaflet.fullscreen'

// eslint-disable-next-line import/newline-after-import
import '@geoman-io/leaflet-geoman-free'
L.PM.setOptIn(false)
;(window as any).Buffer = Buffer
// eslint-disable-next-line import/first
import { getBounds, addArea } from './helpers'

interface LayerWithLeafletId extends L.Layer {
  _leaflet_id: string
}

export type FormattedFeatureType = {
  type: string
  geometry: {
    type: string
    coordinates: [number, number][][][]
  }
  properties: {
    key: string
    wkb: string
    area: number
  }
}

export type FeatureType = {
  type: string
  geometry: {
    type: string
    coordinates: [number, number][][]
  }
  properties: {
    key: number
    wkb: string
    area: string
  }
}

type ToolTipType = {
  comp: string
  func: () => void
  tooltip: string
  values: Array<string>
}

type BoundZoomType = {
  bounds: L.LatLngBounds
  zoom: number
}

export const Map = ({
  center,
  markerHtml,
  tileProvider,
  ClientFeatures,
  hideSearch,
  onShapeChange,
  cutMode,
  editable,
  tooltipContent,
  getBounding,
  uniqueMapId,
  hideButtons,
  showSmallZoomButton,
  vr,
}: {
  center: L.LatLngTuple
  markerHtml: string
  tileProvider: string
  ClientFeatures: Feature[] | []
  hideSearch: boolean
  onShapeChange: (feature?: Feature[]) => void
  cutMode: boolean
  editable: boolean
  tooltipContent?: ToolTipType
  getBounding?: (data?: BoundZoomType) => void
  uniqueMapId?: string
  hideButtons?: boolean
  vr?: boolean
  showSmallZoomButton?: boolean
}) => {
  const [features, setFeatures] = useState<Feature[] | []>([])
  const [mapState, setMapState] = useState<L.Map>()
  const [tileType, setTileType] = useState<'street' | 'satellite'>('street')
  // @ts-ignore
  // const tiles = L.tileLayer.provider(tileProvider || 'OpenStreetMap.Mapnik')
  const tiles = L.tileLayer(
    'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
  )
  // @ts-ignore
  // const satTiles = L.tileLayer.provider('Esri.WorldImagery')
  const satTiles = L.tileLayer(
    'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    },
  )

  const dependencyArray = []
  useEffect(() => {
    const container = L.DomUtil.get(uniqueMapId || 'mapid')
    if (container != null) {
      ;(container as any)._leaflet_id = null
    }
    // Initialize map to render at the ID returned from this class
    const map = L.map(uniqueMapId || 'mapid')
    // Set initial view at the center prop with a zoom level of 13
    map.setView(center, 13)
    L.control
      // @ts-ignore
      .fullscreen({
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: '<i class="fa-solid fa-arrows-maximize"></i>', // change the content of the button, can be HTML, default null
      })
      .addTo(map)
    tiles.addTo(map)

    map.pm.enableGlobalCutMode({
      allowSelfIntersection: false,
    })

    const dc = document.getElementsByClassName('leaflet-pm-toolbar')
    // Add drawcontrol to the map
    if (editable && dc.length <= 0)
      map.pm.addControls({
        position: 'topright',
        drawCircle: false,
        drawCircleMarker: false,
        drawRectangle: false,
        drawPolyline: false,
        dragMode: false,
        drawMarker: false,
        cutPolygon: cutMode,
        drawText: false,
      })
    map.pm.Draw.setPathOptions({ color: '#7cfc00', stroke: false })
    // Enable with options, and disable to save them.
    map.pm.enableDraw('Polygon', {
      allowSelfIntersection: false,
      editable: true,
      snappable: true,
      templineStyle: {
        color: 'purple',
      },
      hintlineStyle: {
        color: 'purple',
        dashArray: [5, 5],
      },
      pathOptions: {
        color: 'purple',
        fillColor: '#819BA1',
        fillOpacity: 0.5,
        opacity: 1,
        weight: 2,
      },
      finishOn: 'dblclick',
    })
    map.pm.disableDraw('Polygon')
    const icon2 = L.divIcon({
      className: 'my-custom-pin',
      html: markerHtml,
    })
    map.pm.enableDraw('Marker', {
      markerStyle: { icon: icon2, draggable: false },
      editable: false,
      markerEditable: false,
    })
    map.pm.disableDraw('Marker')

    /* CREATE ACTION */
    map.on('pm:create', (mapLayer) => {
      if (mapLayer.shape === 'Polygon') {
        const area =
          mapLayer.shape === 'Polygon' &&
          addArea((mapLayer.layer as L.Polygon).toGeoJSON())

        const drawnLayer = (mapLayer.layer as L.Polygon).toGeoJSON()
        const key = (mapLayer.layer as LayerWithLeafletId)._leaflet_id
        drawnLayer.properties = { key }
        const featureKeyArray = features.map((o) => o.properties?.key)
        if (Number(area) > 1 && mapLayer.layer instanceof L.Polygon && vr) {
          mapLayer.layer.setStyle({
            color: 'red',
            fillColor: '#E01919',
            fillOpacity: 0.5,
            opacity: 1,
            weight: 2,
          })
          mapLayer.layer.on('mouseover', (event) => {
            event.target.setStyle({
              color: '#E01919',
              opacity: 1,
              fillOpacity: 0.2,
            })
          })
          mapLayer.layer.on('mouseout', (event) => {
            event.target.setStyle({
              color: 'red',
              fillColor: '#E01919',
              fillOpacity: 0.5,
              opacity: 1,
              weight: 2,
            })
            mapLayer.layer.bindTooltip(
              (tpLayer) => `Area: ${`${area}mi`}<sup>2</sup>`,
            )
          })
        } else {
          mapLayer.layer.on('mouseover', (event) => {
            event.target.setStyle({
              color: '#819BA1',
              opacity: 1,
              fillOpacity: 0.2,
            })
          })
          mapLayer.layer.on('mouseout', (event) => {
            event.target.setStyle({
              color: 'purple',
              fillColor: '#819BA1',
              fillOpacity: 0.5,
              opacity: 1,
              weight: 2,
            })
          })
          mapLayer.layer.bindTooltip(
            (tpLayer) => `Area: ${`${area}mi`}<sup>2</sup>`,
          )
        }
        mapLayer.layer.on('pm:edit', (e) => {
          const checker = mapLayer.shape === 'Polygon'
          const editedArea =
            checker && addArea((e.layer as L.Polygon).toGeoJSON())
          if (Number(editedArea) > 1 && e.layer instanceof L.Polygon && vr) {
            e.layer.setStyle({
              color: 'red',
              fillColor: '#E01919',
              fillOpacity: 0.5,
              opacity: 1,
              weight: 2,
            })
            e.layer.on('mouseover', (event) => {
              event.target.setStyle({
                color: '#E01919',
                opacity: 1,
                fillOpacity: 0.2,
              })
            })
            e.layer.on('mouseout', (event) => {
              event.target.setStyle({
                color: 'red',
                fillColor: '#E01919',
                fillOpacity: 0.5,
                opacity: 1,
                weight: 2,
              })
            })
          } else {
            e.layer.on('mouseover', (event) => {
              event.target.setStyle({
                color: '#819BA1',
                opacity: 1,
                fillOpacity: 0.2,
              })
            })
            e.layer.on('mouseout', (event) => {
              event.target.setStyle({
                color: 'purple',
                fillColor: '#819BA1',
                fillOpacity: 0.5,
                opacity: 1,
                weight: 2,
              })
            })
          }
          e.layer.bindTooltip((layer) => {
            return `Area: ${`${editedArea}mi`}<sup>2</sup>`
          })
        })
        // Push edited newly 'pm:create' polygon to features when `Finish` is clicked
        mapLayer.layer.on('pm:update', (e) => {
          const editedLayer = (e.layer as L.Polygon).toGeoJSON()
          editedLayer.properties.key = key
          setFeatures((state) => {
            if (state.length > 1) {
              const filterFeats = state.filter((current: Feature) => {
                if (current && current.properties && current.properties.key) {
                  return current.properties.key !== editedLayer.properties.key
                }
                return []
              })
              const findDupe = filterFeats.map((o) => o.properties?.key)
              const Dupeless = filterFeats.filter(
                ({ properties }, index) =>
                  !findDupe.includes(properties?.key, index + 1),
              )

              return [...Dupeless, editedLayer]
            }
            return [editedLayer]
          })
        })
        setFeatures((state: Feature[]) => [
          ...state.filter(
            ({ properties }, index) =>
              !featureKeyArray.includes(properties?.key, index + 1),
          ),
          drawnLayer,
        ])
      }
      if (mapLayer.shape === 'Marker') {
        const markerLayer = (mapLayer.layer as L.Marker).toGeoJSON()
        const featureKeyArray = features.map((o: Feature) => o.properties?.key)
        setFeatures((state: Feature[]) => [
          ...state.filter(
            ({ properties }, index) =>
              !featureKeyArray.includes(properties?.key, index + 1),
          ),
          markerLayer,
        ])
      }
    })

    /* Remove Action */
    map?.on('pm:remove', (deletedLayer) => {
      const temp: Feature[] = []
      map.removeLayer(deletedLayer.layer)
      map.eachLayer((c: any) => {
        if (c.pm) {
          const visibleLayer = (c as L.Polygon).toGeoJSON()
          if (visibleLayer.type === 'Feature') {
            visibleLayer.properties.key = c._leaflet_id
            temp.push(visibleLayer)
          }
        }
      })
      setFeatures(temp)
    })
    // Add features from client
    if (ClientFeatures.length > 0) {
      const style = {
        color: 'purple',
        fillColor: '#819BA1',
        fillOpacity: 0.5,
        opacity: 1,
        weight: 2,
      }
      ClientFeatures.map((feat) => {
        // Create layer to add to map
        // add styles and area.
        if (
          feat.geometry.type === 'Polygon' ||
          feat.geometry.type === 'MultiPolygon'
        ) {
          if (feat.properties.wkb) {
            // const x = makeGeoJSON('wkb', feat.properties.wkb, false)
          }
          const layer = L.geoJSON(feat, { style })
          const layerArea = addArea(feat)
          layer.bindTooltip((tpLayer) => {
            return `Area: ${`${layerArea}mi`}<sup>2</sup>`
          })
          layer.on('mouseover', (event) => {
            event.target.setStyle({
              color: '#819BA1',
              opacity: 1,
              fillOpacity: 0.2,
            })
          })
          layer.on('mouseout', (event) => {
            event.target.setStyle({
              color: 'purple',
              fillColor: '#819BA1',
              fillOpacity: 0.5,
              opacity: 1,
              weight: 2,
            })
          })
          // Add Edit To Features froms client
          layer.on('pm:edit', (e) => {
            const editedArea = addArea((e.layer as L.Polygon).toGeoJSON())
            // Use Actual Layer from above creation to handle tooltips
            layer.unbindTooltip()
            layer.bindTooltip(() => {
              return `Area: ${`${editedArea}mi`}<sup>2</sup>`
            })
          })

          // On Finish Add Updated Layer to features array
          layer.on('pm:update', (e) => {
            const editedLayer = (e.layer as L.Polygon).toGeoJSON()
            editedLayer.properties.key = feat.properties.key
            setFeatures((state: Feature[]) => {
              if (state.length > 1) {
                const filterFeats = state.filter((current) => {
                  if (current && current.properties && current.properties.key) {
                    return current.properties.key !== editedLayer.properties.key
                  }
                  return []
                })
                const findDupe = filterFeats.map((o) => o.properties?.key)
                const Dupeless = filterFeats.filter(
                  ({ properties }, index) =>
                    !findDupe.includes(properties?.key, index + 1),
                )

                return [...Dupeless, editedLayer]
              }
              return [editedLayer]
            })
          })
          return layer.addTo(map)
        }
        if (feat.geometry.type === 'Marker') {
          const icon3 = L.divIcon({
            className: 'my-custom-pin',
            html: markerHtml,
          })

          return L.marker(feat, {
            icon: icon3,
            draggable: false,
          }).addTo(map)
        }
        return true
      })
      onShapeChange(ClientFeatures)
      map.fitBounds(getBounds(ClientFeatures) as L.LatLngBounds)
    }
    setMapState(map)
  }, dependencyArray)
  useEffect(() => {
    mapState?.invalidateSize()
  }, [mapState])
  useEffect(() => {
    setFeatures(ClientFeatures)
  }, [])
  useEffect(() => {
    const pointsRemoved = features.filter((current: Feature) => {
      if (
        current.geometry.type === 'Polygon' ||
        current.geometry.type === 'MultiPolygon'
      ) {
        const x = wkx.Geometry.parseGeoJSON(current.geometry)
          .toWkb()
          .toString('base64')
        const { properties } = current
        if (properties) {
          properties.wkb = x
          properties.area = addArea(current)
        }
      }
      return (
        current.geometry.type === 'Polygon' ||
        current.geometry.type === 'MultiPolygon'
      )
    })
    onShapeChange(pointsRemoved)
  }, [features])

  const onLocationSelect = (selection: PredictionWithLocationType) => {
    const locType = selection?.gmaps?.types
    let zoom
    // Street Address Zoom
    if (locType?.includes('street_address')) {
      zoom = 15
    }
    // City zoom
    if (locType?.includes('locality')) {
      zoom = 10
    }
    // State Zoom
    if (locType?.includes('administrative_area_level_1')) {
      zoom = 5
    }
    if (
      !selection ||
      !selection.location ||
      !selection.location.lat ||
      !selection.location.lng
    )
      return
    const point: Feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [selection?.location?.lng, selection?.location?.lat],
        type: 'Point',
      },
    }
    const icon3 = L.divIcon({
      className: 'my-custom-pin',
      html: markerHtml,
    })

    const marker =
      mapState &&
      L.marker(L.latLng(selection.location.lat, selection.location.lng), {
        draggable: false,
        icon: icon3,
      })
        .bindTooltip((layer) => selection?.gmaps?.formatted_address || '')
        .on('dblclick', (e) => {
          mapState?.removeLayer(e.target)
        })
    if (mapState) {
      marker?.addTo(mapState)
      const bounds = new L.LatLng(
        selection?.location?.lat,
        selection?.location?.lng,
      )
      mapState.panTo(bounds)
      mapState.setZoom(zoom)
    }
    const newFeats = [...ClientFeatures, point]
    setFeatures(newFeats)
  }
  const zoomToShapes = () => {
    if (ClientFeatures.length > 0) {
      const bounds = getBounds(ClientFeatures) as L.LatLngBounds
      return mapState?.fitBounds(bounds)
    }
    return []
  }
  return (
    <div id={uniqueMapId || 'mapid'} className="mapbox">
      <div className="search">
        <AutoComplete
          onSelectPrediction={(selectLocation) =>
            onLocationSelect(selectLocation)
          }
        />
      </div>
      {showSmallZoomButton && (
        <div className="buttonBox2">
          <button
            id="zoom-to-shape2"
            type="button"
            onClick={() => zoomToShapes()}
          >
            Zoom To Shape
          </button>
        </div>
      )}
      {!hideButtons && (
        <div className="buttonBox">
          <button
            id="zoom-to-shape"
            type="button"
            onClick={() => zoomToShapes()}
          >
            Zoom to shapes
          </button>
          <button
            id="satView"
            type="button"
            onClick={() => {
              if (tileType === 'street' && mapState) {
                satTiles.addTo(mapState)
                mapState.removeLayer(tiles)
                setTileType('satellite')
              }
              if (tileType === 'satellite' && mapState) {
                tiles.addTo(mapState)
                mapState.removeLayer(satTiles)
                setTileType('street')
              }
            }}
          >
            {tileType === 'street' ? 'Satellite View' : 'Street View'}
          </button>
        </div>
      )}
    </div>
  )
}
