/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Budget Interval](https://docs.xandr.com/bundle/xandr-api/page/insertion-order-service.html#InsertionOrderService-BudgetIntervals) in Xandr's documentation.
*/
export class V3BudgetInterval {
    /**
    * The start date of the budget interval. Format must be YYYY-MM-DD hh:mm:ss (hh:mm:ss must be set to 00).
    */
    'startDate'?: string;
    /**
    * The end date of the budget interval. Format must be YYYY-MM-DD hh:mm:ss (hh:mm:ss must be set to 23:59:59).
    */
    'endDate'?: string;
    /**
    * The lifetime budget in revenue for the budget interval. The revenue currency is defined by the currency field on the insertion_order object. This field defaults to null (unlimited).
    */
    'lifetimeBudgetImps'?: number;
    /**
    * The daily budget impression limit.This field defaults to null (unlimited). Instead, use the line item to set this value. If you also set the daily_budget field within this array, whichever budget is exhausted first will cause spending to stop. Best practice is to only set one of these fields.
    */
    'dailyBudgetImps'?: number;
    /**
    * If true, the non-seamless insertion order will attempt to spend its overall lifetime budget evenly over the insertion order flight dates. If true:  1.You must establish a lifetime_budget or lifetime_budget_imps 2.You must establish a start_date and end_date 3.You cannot set a daily_budget 4.You cannot set enable_pacing to false
    */
    'lifetimePacingPct'?: number;
    /**
    * If true, the insertion order will attempt to pace the lifetime budget evenly over the budget interval. If true: 1.You must establish a lifetime_budget or lifetime_budget_imps 2.You must establish a start_date and end_date 3.You cannot set a daily_budget 4.You cannot set enable_pacing to false
    */
    'lifetimePacing'?: boolean;
    /**
    * If true, then spending will be paced over the course of the day. Only applicable if there is a daily_budget.
    */
    'enablePacing'?: boolean;
    /**
    * The ID of the budget interval.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "string",
            "format": ""
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "string",
            "format": ""
        },
        {
            "name": "lifetimeBudgetImps",
            "baseName": "lifetime_budget_imps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dailyBudgetImps",
            "baseName": "daily_budget_imps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lifetimePacingPct",
            "baseName": "lifetime_pacing_pct",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lifetimePacing",
            "baseName": "lifetime_pacing",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enablePacing",
            "baseName": "enable_pacing",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3BudgetInterval.attributeTypeMap;
    }

    public constructor() {
    }
}

