/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1CampaignArchived } from '../models/V1CampaignArchived';
import { V1CampaignCreated } from '../models/V1CampaignCreated';
import { V1CampaignDeleted } from '../models/V1CampaignDeleted';
import { V1CampaignFlightDatesSet } from '../models/V1CampaignFlightDatesSet';
import { V1CampaignJobIdSet } from '../models/V1CampaignJobIdSet';
import { V1CampaignNameSet } from '../models/V1CampaignNameSet';
import { V1CampaignOrderLineUpdated } from '../models/V1CampaignOrderLineUpdated';
import { V1CampaignPoIdSet } from '../models/V1CampaignPoIdSet';
import { V1CampaignPoliticalTransparencySet } from '../models/V1CampaignPoliticalTransparencySet';
import { V1CampaignRefIDSet } from '../models/V1CampaignRefIDSet';
import { V1CampaignUnarchived } from '../models/V1CampaignUnarchived';
import { V1OrderLineAdded } from '../models/V1OrderLineAdded';
import { V1OrderLineAttachedToCampaign } from '../models/V1OrderLineAttachedToCampaign';
import { V1OrderLineDetachedFromCampaign } from '../models/V1OrderLineDetachedFromCampaign';
import { V1OrderLineRemoved } from '../models/V1OrderLineRemoved';
import { HttpFile } from '../http/http';

/**
* Wrapper for the different kinds of events
*/
export class V1CampaignEvent {
    /**
    * The type of event
    */
    'type'?: string;
    'campaignCreated'?: V1CampaignCreated;
    'orderLineAdded'?: V1OrderLineAdded;
    'orderLineRemoved'?: V1OrderLineRemoved;
    'orderLineAttachedToCampaign'?: V1OrderLineAttachedToCampaign;
    'orderLineDetachedFromCampaign'?: V1OrderLineDetachedFromCampaign;
    'campaignNameSet'?: V1CampaignNameSet;
    'campaignRefIdSet'?: V1CampaignRefIDSet;
    'campaignDeleted'?: V1CampaignDeleted;
    'campaignPoliticalTransparencySet'?: V1CampaignPoliticalTransparencySet;
    'orderLineUpdated'?: V1CampaignOrderLineUpdated;
    'campaignFlightDatesSet'?: V1CampaignFlightDatesSet;
    'campaignArchived'?: V1CampaignArchived;
    'campaignUnarchived'?: V1CampaignUnarchived;
    'campaignJobIdSet'?: V1CampaignJobIdSet;
    'campaignPoIdSet'?: V1CampaignPoIdSet;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignCreated",
            "baseName": "campaign_created",
            "type": "V1CampaignCreated",
            "format": ""
        },
        {
            "name": "orderLineAdded",
            "baseName": "order_line_added",
            "type": "V1OrderLineAdded",
            "format": ""
        },
        {
            "name": "orderLineRemoved",
            "baseName": "order_line_removed",
            "type": "V1OrderLineRemoved",
            "format": ""
        },
        {
            "name": "orderLineAttachedToCampaign",
            "baseName": "order_line_attached_to_campaign",
            "type": "V1OrderLineAttachedToCampaign",
            "format": ""
        },
        {
            "name": "orderLineDetachedFromCampaign",
            "baseName": "order_line_detached_from_campaign",
            "type": "V1OrderLineDetachedFromCampaign",
            "format": ""
        },
        {
            "name": "campaignNameSet",
            "baseName": "campaign_name_set",
            "type": "V1CampaignNameSet",
            "format": ""
        },
        {
            "name": "campaignRefIdSet",
            "baseName": "campaign_ref_id_set",
            "type": "V1CampaignRefIDSet",
            "format": ""
        },
        {
            "name": "campaignDeleted",
            "baseName": "campaign_deleted",
            "type": "V1CampaignDeleted",
            "format": ""
        },
        {
            "name": "campaignPoliticalTransparencySet",
            "baseName": "campaign_political_transparency_set",
            "type": "V1CampaignPoliticalTransparencySet",
            "format": ""
        },
        {
            "name": "orderLineUpdated",
            "baseName": "order_line_updated",
            "type": "V1CampaignOrderLineUpdated",
            "format": ""
        },
        {
            "name": "campaignFlightDatesSet",
            "baseName": "campaign_flight_dates_set",
            "type": "V1CampaignFlightDatesSet",
            "format": ""
        },
        {
            "name": "campaignArchived",
            "baseName": "campaign_archived",
            "type": "V1CampaignArchived",
            "format": ""
        },
        {
            "name": "campaignUnarchived",
            "baseName": "campaign_unarchived",
            "type": "V1CampaignUnarchived",
            "format": ""
        },
        {
            "name": "campaignJobIdSet",
            "baseName": "campaign_job_id_set",
            "type": "V1CampaignJobIdSet",
            "format": ""
        },
        {
            "name": "campaignPoIdSet",
            "baseName": "campaign_po_id_set",
            "type": "V1CampaignPoIdSet",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1CampaignEvent.attributeTypeMap;
    }

    public constructor() {
    }
}

