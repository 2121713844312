/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3CustomDateRange } from '../models/V3CustomDateRange';
import { HttpFile } from '../http/http';

export class V3CampaignCreative {
    'id'?: number;
    'code'?: string;
    'name'?: string;
    'width'?: number;
    'height'?: number;
    'weight'?: number;
    'state'?: string;
    'auditStatus'?: string;
    'isExpired'?: boolean;
    'isProhibited'?: boolean;
    'isSelfAudited'?: boolean;
    'format'?: string;
    'popWindowMaximize'?: boolean;
    'adType'?: string;
    'allBudgetIntervals'?: boolean;
    'customDateRanges'?: Array<V3CustomDateRange>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "weight",
            "baseName": "weight",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "string",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "string",
            "format": ""
        },
        {
            "name": "isExpired",
            "baseName": "is_expired",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isProhibited",
            "baseName": "is_prohibited",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isSelfAudited",
            "baseName": "is_self_audited",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "format",
            "baseName": "format",
            "type": "string",
            "format": ""
        },
        {
            "name": "popWindowMaximize",
            "baseName": "pop_window_maximize",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "allBudgetIntervals",
            "baseName": "all_budget_intervals",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "customDateRanges",
            "baseName": "custom_date_ranges",
            "type": "Array<V3CustomDateRange>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3CampaignCreative.attributeTypeMap;
    }

    public constructor() {
    }
}

