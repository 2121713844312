/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1AdType } from '../models/Creativeservicev1AdType';
import { Creativeservicev1Audit } from '../models/Creativeservicev1Audit';
import { Creativeservicev1AuditStatus } from '../models/Creativeservicev1AuditStatus';
import { Creativeservicev1NativeMetadata } from '../models/Creativeservicev1NativeMetadata';
import { Creativeservicev1OrderLine } from '../models/Creativeservicev1OrderLine';
import { Creativeservicev1Status } from '../models/Creativeservicev1Status';
import { Creativeservicev1Type } from '../models/Creativeservicev1Type';
import { V1CreativeFile } from '../models/V1CreativeFile';
import { HttpFile } from '../http/http';

export class TheCreativeToUpdate {
    /**
    * The official name of the Creative.
    */
    'name'?: string;
    /**
    * ID of the org the Creative belongs to
    */
    'orgId'?: string;
    /**
    * The creation timestamp of the Creative.
    */
    'createTime'?: Date;
    /**
    * The last update timestamp of the Creative.
    */
    'updateTime'?: Date;
    /**
    * The deletion timestamp of the Creative.
    */
    'deleteTime'?: Date;
    /**
    * The archival timestamp of the Creative.
    */
    'archiveTime'?: Date;
    /**
    * The expiration timestamp of the Creative.
    */
    'expireTime'?: Date;
    /**
    * The purge timestamp of the Creative.
    */
    'purgeTime'?: Date;
    'status'?: Creativeservicev1Status;
    'type'?: Creativeservicev1Type;
    'adType'?: Creativeservicev1AdType;
    /**
    * Files associated with the Creative
    */
    'files'?: Array<V1CreativeFile>;
    /**
    * List of order lines using the creatives
    */
    'orderLines'?: Array<Creativeservicev1OrderLine>;
    /**
    * Category of the Creative
    */
    'category'?: string;
    /**
    * Thumbnail for the Creative
    */
    'thumbnail'?: string;
    /**
    * Boolean that defines whether a video Creative meets all the OTT Video Specifications
    */
    'ottReady'?: boolean;
    /**
    * The folder that the Creative resides in.
    */
    'folder'?: string;
    /**
    * Ad tag of the Creative
    */
    'adTag'?: string;
    /**
    * Height of the Creative file in pixels
    */
    'height'?: number;
    /**
    * Width of the Creative file in pixels
    */
    'width'?: number;
    'nativeMetadata'?: Creativeservicev1NativeMetadata;
    'auditStatus'?: Creativeservicev1AuditStatus;
    /**
    * Each of the individual audits on a creative's deployments
    */
    'audits'?: Array<Creativeservicev1Audit>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expireTime",
            "baseName": "expire_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "purgeTime",
            "baseName": "purge_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Creativeservicev1Status",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Creativeservicev1Type",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Creativeservicev1AdType",
            "format": ""
        },
        {
            "name": "files",
            "baseName": "files",
            "type": "Array<V1CreativeFile>",
            "format": ""
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<Creativeservicev1OrderLine>",
            "format": ""
        },
        {
            "name": "category",
            "baseName": "category",
            "type": "string",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "ottReady",
            "baseName": "ott_ready",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "folder",
            "baseName": "folder",
            "type": "string",
            "format": ""
        },
        {
            "name": "adTag",
            "baseName": "ad_tag",
            "type": "string",
            "format": ""
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nativeMetadata",
            "baseName": "native_metadata",
            "type": "Creativeservicev1NativeMetadata",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Creativeservicev1AuditStatus",
            "format": ""
        },
        {
            "name": "audits",
            "baseName": "audits",
            "type": "Array<Creativeservicev1Audit>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheCreativeToUpdate.attributeTypeMap;
    }

    public constructor() {
    }
}

