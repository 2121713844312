import { ReactNode, useRef, useState, FC } from 'react'
import { useKeyboardFocus } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import './Tooltip.scss'

export const Tooltip: FC<{
  content: ReactNode
  children?: ReactNode
  classNameTooltip?: string
  className?: string
  position?: string
  isClicked?: boolean
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'base'
}> = ({
  content,
  children,
  classNameTooltip = '',
  className = '',
  position = 'top',
  isClicked = false,
  size = '',
  color = 'base',
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const hasKeyboardFocus = useKeyboardFocus(tooltipRef)
  const [isShown, setIsShown] = useState(false)

  return (
    <div
      className={`TooltipWrapper ${className} ${
        isClicked ? 'Tooltip--is-clicked' : ''
      } ${!hasKeyboardFocus ? '' : 'Tooltip--has-keyboard-focus'}`}
      // When to show the tooltip
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      role="button"
      onClick={() => setIsShown(!isShown)}
      onKeyDown={() => setIsShown(true)}
      onKeyUp={() => setIsShown(false)}
      tabIndex={0}
      ref={tooltipRef}
    >
      {children}
      {(isShown || hasKeyboardFocus || isClicked) && (
        <div
          className={classNames(
            'Tooltip__tip',
            classNameTooltip,
            {
              'text-xs': size === 'xs',
              'text-s': size === 's',
              'text-m': size === 'm',
              'text-l': size === 'l',
              'text-xl': size === 'xl',
              'text-2xl': size === 'xxl',
            },
            {
              'bg-base': color === 'base',
              'bg-primary': color === 'primary',
              'bg-secondary': color === 'secondary',
              'bg-tertiary': color === 'tertiary',
              'bg-warning': color === 'warning',
              'bg-success': color === 'success',
              'bg-danger': color === 'danger',
            },
            {
              left: position === 'left',
              right: position === 'right',
              top: position === 'top',
              topLeft: position === 'topLeft',
              topRight: position === 'topRight',
              bottom: position === 'bottom',
              bottomLeft: position === 'bottomLeft',
              bottomRight: position === 'bottomRight',
            },
          )}
        >
          {content}
        </div>
      )}
    </div>
  )
}
