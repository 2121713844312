import { FC, HTMLProps, ReactNode } from 'react'
import { useMediaQuery } from '@eltoro-ui/hooks'
import './SideMainContent.scss'

interface IProps extends HTMLProps<HTMLElement> {
  /** the children of the component */
  children?: ReactNode
  /** does card has rounded, shadow, default to yes */
  className?: string
}

// used as a section of a page, hence the HTML5 "section" tag
export const SideMainContent: FC<IProps> = ({
  children,
  className = '',
  ...other
}) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const [isTablet] = useMediaQuery('(max-width: 1023px)')
  return (
    <section
      className={`SideMainContent relative flex w-full transition-all ${className} ${
        isMobile
          ? 'SideMainContent__mobile'
          : isTablet
          ? 'SideMainContent__tablet'
          : 'SideMainContent__desktop'
      }`}
      {...other}
    >
      {children}
    </section>
  )
}
