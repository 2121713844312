/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3MemberDataSharingSegment } from '../models/V3MemberDataSharingSegment';
import { HttpFile } from '../http/http';

/**
* See [MemberDataSharing Service](https://docs.xandr.com/bundle/data-providers/page/member-data-sharing-service.html) in Xandr's documentation.
*/
export class V3MemberDataSharing {
    /**
    * The ID of the sharing record.
    */
    'id'?: number;
    /**
    * Your member ID..
    */
    'dataMemberId'?: number;
    /**
    * The ID of the member with whom you are sharing segments.
    */
    'buyerMemberId'?: number;
    /**
    * Whether you share all of your segments or a list of specific segments with the member. Possible values: 'all' or 'list'.  If you choose 'all', any newly created segments will automatically be shared with the buyer member. If you create custom segments that should only be accessible to certain buyers, you should use 'list' exposure.
    */
    'segmentExposure'?: string;
    /**
    * If segment_exposure is 'list', the list of segments that you are sharing with the member.
    */
    'segments'?: Array<V3MemberDataSharingSegment>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dataMemberId",
            "baseName": "data_member_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "buyerMemberId",
            "baseName": "buyer_member_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "segmentExposure",
            "baseName": "segment_exposure",
            "type": "string",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "segments",
            "type": "Array<V3MemberDataSharingSegment>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3MemberDataSharing.attributeTypeMap;
    }

    public constructor() {
    }
}

