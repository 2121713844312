import { useEffect, useState } from 'react'
import { Button, Fa, TagInput } from '@eltoro-ui/components'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import { uploadTargetFile } from 'Requests'
import { useAppContext } from 'Contexts'
import zips from '../../../NewMover/ZipCodes.json'
import './ZipPreview.scss'

export const ZipPreview: React.FC<{
  zipCodes: string[]
  audience: Targetjobservicev1Audience
  target: V2Target
}> = ({ zipCodes, audience, target }) => {
  const [page, setPage] = useState(1)
  const [editing, setEditing] = useState(false)
  const [newZipCodes, setNewZipCodes] = useState(zipCodes)
  const { tok } = useAppContext()

  // this splits up the passed-in zips into pages of arrays 24 zips long
  const zipPages = Array.from(Array(Math.ceil(zipCodes.length / 24)), () => [])
    .reduce(
      (acc: { lastIndex: number; pages: string[][] }) => {
        const newLastIndex = acc.lastIndex + 24
        const start = acc.lastIndex > -1 ? acc.lastIndex : 0
        const stop = acc.lastIndex > -1 ? newLastIndex : newLastIndex + 1
        return {
          lastIndex: newLastIndex,
          pages: [zipCodes.slice(start, stop), ...acc.pages],
        }
      },
      { lastIndex: -1, pages: [] },
    )
    .pages.reverse()

  const currentPage = (): number => {
    if (page > zipPages.length) {
      return 1
    }
    if (page < 1) {
      return zipPages.length
    }
    return page
  }

  const handleOnSave = async () => {
    if (!tok || !audience.orgId || !audience.id || !target.id) return
    // Upload new target with zip list (see new mover?)
    const zipCsv = ['zip', newZipCodes.join(',').replace(/,/g, '\n')].join(
      '\r\n',
    )
    const blob = new Blob([zipCsv], { type: 'text/csv' })
    const file: File = new File([blob], `${audience.name}.csv`, {
      type: 'text/csv',
    })

    await uploadTargetFile(
      `${audience.name}.csv`,
      file,
      tok,
      audience.orgId,
      'FILE_TYPE_ZIP',
      true,
      [{ index: 0, value: 'zip', type: 'zip' }],
      undefined,
      'DATA_SOURCE_CLIENT',
      {
        targetId: target.id,
        audienceIds: [audience.id],
      },
    )
  }

  // update input of zip codes if preview item changes
  useEffect(() => {
    if (zipCodes) setNewZipCodes(zipCodes)
  }, [zipCodes])

  return (
    <div className="ZipPreview-container flex-col items-center justify-center">
      <div className="ZipPreview__header flex items-center justify-between">
        <div>
          <div className="ZipPreview__header-title pr-2 pb-1">Location:</div>
          <div className="ZipPreview__header-detail">
            ZIP Codes({zipCodes.length})
          </div>
        </div>
        <div className="ZipPreview__header-edit-btns flex gap-2">
          <Button
            iconOnly={<Fa icon={editing ? 'save' : 'pencil'} size={1} />}
            onClick={() => {
              setEditing(!editing)
              if (editing) handleOnSave()
            }}
            className="justify-self-end"
            ariaLabel={editing ? 'Save changes' : 'Edit Zip Codes'}
            dataTooltip="left"
            disabled={editing && newZipCodes.length === 0}
          />
          {editing && (
            <Button
              iconOnly={<Fa icon="xmark" size={1} />}
              onClick={() => {
                setEditing(false)
                setNewZipCodes(zipCodes) // reset
              }}
              ariaLabel="Cancel"
              dataTooltip="left"
            />
          )}
        </div>
      </div>
      {editing ? (
        <div className="ZipPreview__edit">
          <TagInput
            addTagsInBulk
            validateFromSuggestions
            autoCompleteSuggestionsList={zips}
            maxAutoCompleteSuggestions={5}
            onAddTag={(tagArray: string[]) => {
              setNewZipCodes(tagArray)
            }}
            validationPattern="^\d{5}$"
            wildCardValidationPattern="^(\S{5})(?<=^\d{5}$|\d{1,3}\*{1,2})$"
            tagErrorLabel="zip code"
            value={newZipCodes}
          />
        </div>
      ) : (
        <>
          <div className="ZipPreview">
            {zipPages[currentPage() - 1].map((zip: string, i) => {
              return (
                <div
                  className="ZipPreview__zip flex items-center justify-center text-center"
                  key={`${zip}-${i}`}
                >
                  {zip}
                </div>
              )
            })}
          </div>
          <div className="ZipPreview__pagination item-center flex items-center justify-center">
            <button
              className="ZipPreview__pagination-buttonLeft "
              onClick={() => {
                setPage(currentPage() - 1)
              }}
            >
              <Fa icon="caret-left" size={'sm'} />
            </button>
            <div className="ZipPreview__pagination-label mt-[0.15rem]">
              {currentPage()} of {zipPages.length}
            </div>
            <button
              className="ZipPreview__pagination-buttonRight"
              onClick={() => {
                setPage(currentPage() + 1)
              }}
            >
              <Fa icon="caret-right" size={'sm'} />
            </button>
          </div>
        </>
      )}
    </div>
  )
}
