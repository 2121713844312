import { CreativeResType, StatsAccumulatorType } from 'types'
import { breakTextWithDelimiters } from 'Helpers'
import { ETIcon } from 'Assets'
import './HighestPerformingCreative.scss'

export const HighestPerformingCreative = ({
  creative,
  stats,
}: {
  creative: CreativeResType
  stats?: StatsAccumulatorType
}) => {
  const random = Math.random().toString()
  const featuredCreative = creative.creative
  const PreviewGenerator = () => {
    if (
      (featuredCreative?.type === 'CREATIVE_TYPE_BANNER' ||
        featuredCreative?.type === 'CREATIVE_TYPE_NATIVE_BANNER') &&
      featuredCreative.files
    ) {
      return (
        <img
          src={`https://s3.amazonaws.com/${featuredCreative.files[0].bucket}/${featuredCreative?.files[0].key}`}
          className="HighestPerformingCreative-img img-CreativeThumbSet h-auto w-full object-contain"
          alt="Creative preview"
        />
      )
    }
    if (
      (featuredCreative?.type === 'CREATIVE_TYPE_VIDEO' ||
        featuredCreative?.type === 'CREATIVE_TYPE_OTT') &&
      featuredCreative.files
    ) {
      return (
        <video
          className="HighestPerformingCreative-video img-video"
          src={`${featuredCreative?.files[0].uri}?random=${random}`}
        />
      )
    }
    if (featuredCreative?.type === 'CREATIVE_TYPE_AD_TAG') {
      return (
        <div className="Banner-thumb flex flex-col">
          <ETIcon className="scale-125" icon="ad-tag" />
        </div>
      )
    }
    if (featuredCreative?.type === 'CREATIVE_TYPE_VAST_TAG') {
      return (
        <div className="Banner-thumb flex flex-col">
          <ETIcon className="scale-125" icon="vast-tag" />
        </div>
      )
    }
    return <ETIcon icon="campaign" className="!text-primary" />
  }

  return featuredCreative?.files ? (
    <div className="HighestPerformingCreative flex flex-col">
      <div className="name-HighestPerformingCreative header-CampaignCreatives">
        Highest Performing Creative
      </div>
      <div className="title-HighestPerformingCreative flex max-w-full items-center justify-between gap-2 text-xs font-normal leading-tight">
        <span className="hyphens-auto flex w-2/3 flex-col break-words font-bold">
          {breakTextWithDelimiters(featuredCreative?.name as string, 26)}
        </span>
        <span className="w-1/3 text-right">
          {(() => {
            if (
              featuredCreative.type === 'CREATIVE_TYPE_AD_TAG' ||
              featuredCreative.type === 'CREATIVE_TYPE_VAST_TAG'
            ) {
              return <></>
            }
            if (
              featuredCreative.type === 'CREATIVE_TYPE_BANNER' ||
              featuredCreative.type === 'CREATIVE_TYPE_NATIVE_BANNER'
            )
              return `${featuredCreative.files[0].width} x ${featuredCreative.files[0].height}`
            if (
              featuredCreative.type === 'CREATIVE_TYPE_VIDEO' ||
              featuredCreative.type === 'CREATIVE_TYPE_OTT'
            )
              return `${featuredCreative.files[0].duration} sec.`
            return null
          })()}
        </span>
      </div>

      <div className="container-HighestPerformingCreative creative-container flex flex-col gap-3 leading-tight">
        <figure className="imgContainer mt-2 w-full">
          {PreviewGenerator()}
        </figure>
        {stats && (
          <figcaption className="HighestPerformingCreative-data basicData-info">
            <span>
              <strong>Imps:</strong>
              {stats?.imps ? stats?.imps : '0'}
            </span>
            <span>
              <strong>Clicks:</strong>
              {stats?.clicks ? stats?.clicks : '0'}
            </span>
            <span>
              <strong>CTR:</strong>
              {stats?.ctr?.toFixed(2) ? stats?.ctr?.toFixed(2) : '0'}
            </span>
          </figcaption>
        )}
      </div>
      {creative.err && (
        <div className="text-warning">
          We're having trouble getting all of your creative data right now. This
          statistic may not be accurate.
        </div>
      )}
    </div>
  ) : null
}
