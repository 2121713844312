import { FC, useCallback, useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { Theme } from 'Components'
import {
  ThemeContextProvider,
  CampaignContextProvider,
  SSEContextProvider,
  useAppContext,
} from 'Contexts'
import { AuthorizedRoutes } from './AuthorizedRoutes'
import { LoginPage } from './Pages'
import './App.scss'
import './Assets/FontAwesomePro6/css/all.css'
import { User } from 'oidc-client-ts'

const App: FC = () => {
  const auth = useAuth()
  const { setRebuild } = useAppContext()
  const isLoggedIn = auth && auth.isAuthenticated

  useEffect(() => {
    const handleUserSignedIn = () => {
      if (auth.user && auth.user.access_token && auth.user.refresh_token) {
        localStorage.setItem('eltoro_token', auth.user.access_token)
        localStorage.setItem('eltoro_refresh_token', auth.user.refresh_token)
      }
    }

    const handleAccessTokenExpiring = () => {
      const refreshToken = localStorage.getItem('eltoro_refresh_token')
      if (refreshToken) {
        try {
          auth.startSilentRenew()
        } catch (error) {
          console.error('Failed to refresh token', error)
          auth.removeUser()
        }
      }
    }

    const handleUserLoaded = (user: User) => {
      if (user && user.access_token) {
        localStorage.setItem('eltoro_token', user?.access_token)
        setRebuild(true)
      }
    }

    const handleAccessTokenExpired = () => {
      auth.removeUser()
      auth.revokeTokens()
    }

    auth.events.addUserSignedIn(handleUserSignedIn)
    auth.events.addAccessTokenExpiring(handleAccessTokenExpiring)
    auth.events.addUserLoaded(handleUserLoaded)
    auth.events.addAccessTokenExpired(handleAccessTokenExpired)

    return () => {
      auth.events.removeUserSignedIn(handleUserSignedIn)
      auth.events.removeAccessTokenExpiring(handleAccessTokenExpiring)
      auth.events.removeUserLoaded(handleUserLoaded)
      auth.events.removeAccessTokenExpired(handleAccessTokenExpired)
    }
  }, [auth, setRebuild])

  if (!isLoggedIn)
    return (
      <Router>
        <ThemeContextProvider>
          <div className="App">
            <Theme />
            <LoginPage />
          </div>
        </ThemeContextProvider>
      </Router>
    )
  // the router needs to wrap the contexts so they have access to the router methods
  return (
    <Router>
      <ThemeContextProvider>
        <SSEContextProvider>
          <CampaignContextProvider>
            <Theme />
            <div className="App">{isLoggedIn && <AuthorizedRoutes />}</div>
          </CampaignContextProvider>
        </SSEContextProvider>
      </ThemeContextProvider>
    </Router>
  )
}

export default App
