import React, { useRef, useState } from 'react'
import { Fa, Popover, TextHeader } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { V1State } from 'next-gen-sdk'
import { simplifyEnum } from 'Helpers'
import { useAppContext } from 'Contexts'
import './OrderlineStateIndicator.scss'

const getStatusIcon = (state: V1State, isAdmin?: boolean) => {
  const iconName = () => {
    switch (state) {
      case 'ORDERLINE_STATE_DRAFT':
        return 'edit'
      case 'ORDERLINE_STATE_IN_REVIEW':
        return 'list-alt'
      case 'ORDERLINE_STATE_APPROVED':
        return 'user-check'
      case 'ORDERLINE_STATE_REJECTED':
        return 'user-times'
      case 'ORDERLINE_STATE_ACTIVE':
        return 'play-circle'
      case 'ORDERLINE_STATE_COMPLETED':
        return 'check-circle'
      case 'ORDERLINE_STATE_ARCHIVED':
        return 'archive'
      case 'ORDERLINE_STATE_PAUSED':
        return 'pause-circle'
      default:
        return 'exclamation-triangle'
    }
  }

  const baseClassName = 'OrderlineIndicator__icon'

  return (
    <Fa
      className={classNames(
        baseClassName,
        {
          [`${baseClassName}--orange`]: [
            'ORDERLINE_STATE_DRAFT',
            'ORDERLINE_STATE_ARCHIVED',
          ].includes(state),
          [`${baseClassName}--blue`]: ['ORDERLINE_STATE_ACTIVE'].includes(
            state,
          ),
          [`${baseClassName}--green`]: [
            'ORDERLINE_STATE_APPROVED',
            'ORDERLINE_STATE_COMPLETED',
          ].includes(state),
          [`${baseClassName}--red`]: ['ORDERLINE_STATE_REJECTED'].includes(
            state,
          ),
        },
        'scale-95',
      )}
      icon={iconName()}
      size={1}
    />
  )
}

export const OrderlineStateIndicator = ({
  className = '',
  state,
  reason,
}: {
  className?: string
  state: V1State
  reason?: string
}) => {
  const [reasonOpen, setReasonOpen] = useState(false)

  const { isAdmin } = useAppContext()

  const infoContentRef = useRef<HTMLDivElement>(null)
  const infoButtonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([infoContentRef, infoButtonRef], () => setReasonOpen(false))

  return (
    <div
      className={`OrderlineStateIndicator flex items-center justify-start gap-1 ${className}`}
    >
      {getStatusIcon(state, isAdmin)}
      {reason && isAdmin && (
        <Popover
          position={['bottom']}
          align="center"
          content={
            <div
              ref={infoContentRef}
              className="OrderlineStateIndicator__reason-popover bg-base text-grey-500 max-w-md rounded p-4 shadow-sm"
            >
              <TextHeader type={6} className="mb-2">
                Reason
              </TextHeader>
              {reason}
            </div>
          }
          isOpen={reasonOpen}
        >
          <div ref={infoButtonRef}>
            <button
              className="OrderlineStateIndicator__reason-icon-btn"
              type="button"
              onClick={(e) => {
                e?.stopPropagation()
                setReasonOpen(!reasonOpen)
              }}
            >
              <Fa icon="note" size={1} type="duotone" />
            </button>
          </div>
        </Popover>
      )}
      <div className="TableText max-w-[unset] justify-center">
        <p>
          {(() => {
            if (state === 'ORDERLINE_STATE_ACTIVE') {
              return 'Deployed'
            }
            return simplifyEnum(2, state, true)
          })()}
        </p>
      </div>
    </div>
  )
}
