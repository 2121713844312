/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3AuditStatus } from '../models/Deployservicev3AuditStatus';
import { Deployservicev3Deployment } from '../models/Deployservicev3Deployment';
import { Deployservicev3NativeMetadata } from '../models/Deployservicev3NativeMetadata';
import { Deployservicev3Status } from '../models/Deployservicev3Status';
import { V3CreativeType } from '../models/V3CreativeType';
import { HttpFile } from '../http/http';

export class TheCreativeToCreate {
    /**
    * The reference ID for the creative.
    */
    'refId'?: string;
    /**
    * The file name of the creative.
    */
    'filename'?: string;
    'status'?: Deployservicev3Status;
    'type'?: V3CreativeType;
    /**
    * The location of the creative.
    */
    'location'?: string;
    /**
    * The mine type of the creative file.
    */
    'mimetype'?: string;
    /**
    * The size of the creative.
    */
    'size'?: number;
    /**
    * the height of the creative.
    */
    'height'?: number;
    /**
    * The width of the creative
    */
    'width'?: number;
    /**
    * The ID of the advertiser in which this creative is related.
    */
    'advertiserId'?: string;
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    /**
    * And array of deployments for this advertiser.
    */
    'deployments'?: Array<Deployservicev3Deployment>;
    'nativeMetadata'?: Deployservicev3NativeMetadata;
    /**
    * Ad tag of the creative
    */
    'adTag'?: string;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;
    'auditStatus'?: Deployservicev3AuditStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Deployservicev3Status",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V3CreativeType",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "string",
            "format": ""
        },
        {
            "name": "mimetype",
            "baseName": "mimetype",
            "type": "string",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Deployservicev3Deployment>",
            "format": ""
        },
        {
            "name": "nativeMetadata",
            "baseName": "native_metadata",
            "type": "Deployservicev3NativeMetadata",
            "format": ""
        },
        {
            "name": "adTag",
            "baseName": "ad_tag",
            "type": "string",
            "format": ""
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Deployservicev3AuditStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheCreativeToCreate.attributeTypeMap;
    }

    public constructor() {
    }
}

