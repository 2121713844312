import { useState, useRef, useContext } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import {
  Button,
  Text,
  TextInput,
  Fa,
  CollapsibleContainer,
  DateRangePicker,
  RadioButtonGroup,
  RadioButton,
} from '@eltoro-ui/components'
import classNames from 'classnames'
import { ThemeContext, useAppContext, useCampaignContext } from 'Contexts'
import {
  formatDate,
  getDaysBetween,
  styleTailwind,
  truncateMiddle,
} from 'Helpers'
import {
  saveCampaignFlightDates,
  saveCampaignName,
  saveOptionalCampaignIds,
} from 'Requests'
import { ETIcon } from 'Assets'

import { CampaignHeaderDetail } from './CampaignHeaderDetail'
import './CreateCampaignHeader.scss'

const CampaignHeaderIcon = styleTailwind(Fa, 'text-primary')
const CampaignHeaderDetailLabelHed = styleTailwind(
  Text,
  'CampaignHeaderDetailLabelHed text-m font-bold uppercase leading-none',
)
const CampaignHeaderDetailText = styleTailwind(
  'div',
  'CampaignHeaderDetailText flex flex-1 flex-col justify-center gap-1',
)

export type EditingType =
  | 'name'
  | 'flight dates'
  | 'political'
  | 'jobid'
  | 'poid'

export const CreateCampaignHeader = () => {
  const {
    campaign,
    setCampaign,
    orderLines,
    isPolitical,
    handlePolitical,
    start_date,
    end_date,
  } = useCampaignContext()
  const { currentOrg } = useAppContext()
  const [editing, setEditing] = useState<EditingType>()
  const [campaignNameInput, setCampaignNameInput] = useState(
    campaign?.name || '',
  )
  const [flightDatesInput, setFlightDatesInput] = useState({
    startDate: campaign?.startTime && new Date(campaign.startTime),
    endDate: campaign?.endTime && new Date(campaign.endTime),
  })
  const [campaignJobIdInput, setCampaignJobIdInput] = useState(
    campaign?.jobId || '',
  )
  const [campaignPoIdInput, setCampaignPoIdInput] = useState(
    campaign?.poId || '',
  )
  const [loading, setLoading] = useState(false)
  const [isShowCampaign, setIsShownCampaign] = useState(false)
  const campaignDetailsRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([campaignDetailsRef], () => setIsShownCampaign(false))
  const { name: theme } = useContext(ThemeContext)
  const isDarkTheme = theme === 'dark' || theme === 'darkBlue'

  const handleShowCampaignDetail = () => {
    setIsShownCampaign((show) => !show)
  }

  const CampaignHeaderDetailLabel = ({
    children,
  }: {
    children?: React.ReactNode
  }) => (
    <p
      className={classNames('text-xs font-bold uppercase leading-none', {
        'text-secondary-700': !isDarkTheme,
        'text-secondary-500': isDarkTheme,
      })}
    >
      {children}
    </p>
  )

  // Editing is not allowed if any order lines within are/have been deployed
  // Campaign is sort of equivalent to insertion order, which is created when OLs are deployed, but we don't have a way to update
  // the insertion order's flight dates. User cannot add an order line that starts before that IO's flight dates.
  const hasLiveOrderLines =
    orderLines &&
    !orderLines.every(
      (ol) =>
        ol.status === 'ORDERLINE_STATUS_DRAFT' ||
        ol.status === 'ORDERLINE_STATUS_CREATING',
    )

  return (
    <div
      ref={campaignDetailsRef}
      className="CreateCampaignHeader relative z-10 flex-col gap-2"
    >
      <div className="CreateCampaignHeader bg-gray relative z-10 justify-start">
        {campaign?.name && (
          <Button
            className="CampaignHeader--text pl-2"
            kind="text"
            onClick={() => handleShowCampaignDetail()}
            iconLeft={
              <div className="circle bg-success-400 text-white">
                <Fa icon="check" size={1} />
              </div>
            }
          >
            <span className="text-m text-secondary-600 flex items-center gap-x-1">
              <span className="desktopsmall:hidden font-bold">Campaign:</span>
              <span className="laptop:truncate font-medium">
                {truncateMiddle(campaign.name, 30)}
              </span>
            </span>
          </Button>
        )}
      </div>
      {isShowCampaign && (
        <div className="CreateCampaignHeader__details animate-slidedown bg-base PointerTip absolute z-30 flex flex-col">
          <div className="CreateCampaignHeader__details__buttons laptopsmall:grid-cols-1 grid grid-cols-[14rem_14rem_14rem] gap-1">
            <CampaignHeaderDetail
              type="name"
              onClick={() => {
                setEditing(editing === 'name' ? undefined : 'name')
                if (editing) setLoading(false)
              }}
              currentlyEditing={editing}
              disabled={hasLiveOrderLines}
            >
              <CampaignHeaderIcon icon="rectangle-ad" type="duotone" size={5} />
              <CampaignHeaderDetailText className="ml-2 max-w-[50%]">
                <CampaignHeaderDetailLabelHed className="truncate" tag="h5">
                  {campaign?.name || 'New Campaign'}
                </CampaignHeaderDetailLabelHed>
                <CampaignHeaderDetailLabel>
                  Campaign Name
                </CampaignHeaderDetailLabel>
              </CampaignHeaderDetailText>
            </CampaignHeaderDetail>
            <CampaignHeaderDetail
              type="flight dates"
              onClick={() => setEditing(undefined)}
              currentlyEditing={editing}
              disabled={true}
              notNeeded={true}
            >
              <CampaignHeaderIcon
                icon="calendar-days"
                type="duotone"
                size={5}
              />
              <CampaignHeaderDetailText>
                {start_date && end_date && (
                  <CampaignHeaderDetailLabelHed tag="h5">
                    {getDaysBetween(new Date(start_date), new Date(end_date))}{' '}
                    days: <br /> {formatDate(new Date(start_date))}-
                    {formatDate(new Date(end_date))}
                  </CampaignHeaderDetailLabelHed>
                )}
                <CampaignHeaderDetailLabel>
                  Flight Dates
                </CampaignHeaderDetailLabel>
              </CampaignHeaderDetailText>
            </CampaignHeaderDetail>
            <CampaignHeaderDetail
              type="political"
              onClick={() =>
                setEditing(editing === 'political' ? undefined : 'political')
              }
              currentlyEditing={editing}
              disabled={hasLiveOrderLines}
            >
              <ETIcon icon="advocacy" />
              <CampaignHeaderDetailText>
                <CampaignHeaderDetailLabelHed tag="h5">
                  {isPolitical ? 'Political' : 'Non-Political'}
                </CampaignHeaderDetailLabelHed>
                <CampaignHeaderDetailLabel>Type</CampaignHeaderDetailLabel>
              </CampaignHeaderDetailText>
            </CampaignHeaderDetail>
            {/* job id */}
            <CampaignHeaderDetail
              type="jobid"
              onClick={() =>
                setEditing(editing === 'jobid' ? undefined : 'jobid')
              }
              currentlyEditing={editing}
              disabled={hasLiveOrderLines}
            >
              <Fa
                icon="pen-field"
                size={3}
                className="py-4 px-2"
                type="duotone"
              />
              <CampaignHeaderDetailText>
                <CampaignHeaderDetailLabelHed tag="h5">
                  {campaign?.jobId || '(None)'}
                </CampaignHeaderDetailLabelHed>
                <CampaignHeaderDetailLabel>Job ID</CampaignHeaderDetailLabel>
                <sub>(optional)</sub>
              </CampaignHeaderDetailText>
            </CampaignHeaderDetail>
            {/* po # */}
            <CampaignHeaderDetail
              type="poid"
              onClick={() =>
                setEditing(editing === 'poid' ? undefined : 'poid')
              }
              currentlyEditing={editing}
              disabled={hasLiveOrderLines}
            >
              <Fa
                icon="pen-field"
                size={3}
                className="py-4 px-2"
                type="duotone"
              />
              <CampaignHeaderDetailText>
                <CampaignHeaderDetailLabelHed tag="h5">
                  {campaign?.poId || '(None)'}
                </CampaignHeaderDetailLabelHed>
                <CampaignHeaderDetailLabel>P.O. #</CampaignHeaderDetailLabel>
                <sub>(optional)</sub>
              </CampaignHeaderDetailText>
            </CampaignHeaderDetail>
          </div>
          {/* Editing container */}
          <CollapsibleContainer isOpen={!!editing}>
            <div
              className={classNames(
                'CreateCampaignHeader__editing bg-primary-50 rounded p-4',
                {
                  'rounded-tl-none': editing === 'name',
                  'rounded-tr-none': editing === 'political',
                },
              )}
            >
              <div className="CreateCampaignHeader__editing__header flex items-center justify-center gap-2">
                <div className="w-full">
                  {editing === 'name' && (
                    <div className="CreateCampaignHeader__Text-input CreateCampaignHeader__Text-input-edit">
                      <TextInput
                        value={campaignNameInput}
                        onChange={(e) => setCampaignNameInput(e.target.value)}
                        placeholder="Enter campaign name here"
                        size="l"
                        disabled={loading}
                      />
                      <Button
                        className="CreateCampaignHeader__button disabled:!bg-grey-200 disabled:text-tint-gray-800 flex rounded-full border-0"
                        size="l"
                        iconOnly={
                          <Fa
                            className={classNames(
                              'EditButton disabled:text-grey-600',
                              {
                                'animate-spin': loading,
                              },
                            )}
                            icon={loading ? 'circle-notch' : 'save'}
                            size={1}
                          />
                        }
                        disabled={
                          campaignNameInput === '' ||
                          loading ||
                          campaignNameInput === campaign?.name
                        }
                        onClick={() => {
                          if (!campaign?.id || !currentOrg?.id) return
                          setLoading(true)
                          saveCampaignName(
                            campaignNameInput,
                            campaign.id,
                            currentOrg.id,
                          )
                            .then((campaign) => {
                              setEditing(undefined)
                              if (campaign) setCampaign(campaign)
                            })
                            .finally(() => {
                              setLoading(false)
                            })
                        }}
                      />
                    </div>
                  )}
                  {editing === 'flight dates' && (
                    <div className="CreateCampaignHeader_flight-dates-wrap m-auto flex max-w-[30rem] items-center gap-4">
                      <DateRangePicker
                        min={new Date()}
                        startDate={flightDatesInput.startDate || new Date()}
                        endDate={flightDatesInput.endDate || new Date()}
                        onStartDateChange={(start) => {
                          setFlightDatesInput((prev) => {
                            return { ...prev, startDate: start }
                          })
                        }}
                        onEndDateChange={(end) => {
                          setFlightDatesInput((prev) => {
                            return { ...prev, endDate: end }
                          })
                        }}
                      />
                      <Button
                        className="disabled:text-grey-600"
                        size="l"
                        iconOnly={
                          <Fa
                            className={classNames(
                              'EditButton disabled:text-grey-600',
                              {
                                'animate-spin': loading,
                              },
                            )}
                            icon={loading ? 'circle-notch' : 'save'}
                            size={1}
                          />
                        }
                        disabled={
                          loading ||
                          !flightDatesInput.startDate ||
                          !flightDatesInput.endDate
                        }
                        onClick={() => {
                          setLoading(true)
                          if (
                            campaign?.id &&
                            flightDatesInput.startDate &&
                            flightDatesInput.endDate &&
                            currentOrg?.id
                          )
                            saveCampaignFlightDates(
                              flightDatesInput.startDate,
                              flightDatesInput.endDate,
                              campaign?.orderLines || [],
                              campaign.id,
                              currentOrg.id,
                            )
                              .then((campaign) => {
                                setEditing(undefined)
                                if (campaign) setCampaign(campaign)
                              })
                              .finally(() => setLoading(false))
                        }}
                      />
                    </div>
                  )}
                  {editing === 'political' && (
                    <RadioButtonGroup
                      disabled={!!campaign?.politicalTransparency?.orgName}
                      valueSelected={isPolitical.toString()}
                      name="Is a political campaign"
                      onChange={(politicalValue) =>
                        handlePolitical(politicalValue === 'true')
                      }
                      layout="horizontal"
                      className="Political__radio-button relative z-20 justify-center"
                    >
                      <RadioButton
                        value="true"
                        label="Is a political campaign"
                      />
                      <RadioButton
                        value="false"
                        label={
                          <span>
                            Is <strong>NOT</strong> a political campaign
                          </span>
                        }
                      />
                    </RadioButtonGroup>
                  )}
                  {editing === 'jobid' && (
                    <div className="CreateCampaignHeader__Text-input CreateCampaignHeader__Text-input-edit">
                      <TextInput
                        value={campaignJobIdInput}
                        onChange={(e) => setCampaignJobIdInput(e.target.value)}
                        placeholder="Enter job ID here"
                        size="l"
                        disabled={loading}
                      />
                      <Button
                        className="CreateCampaignHeader__button disabled:!bg-grey-200 disabled:text-tint-gray-800 flex rounded-full border-0"
                        size="l"
                        iconOnly={
                          <Fa
                            className={classNames(
                              'EditButton disabled:text-grey-600',
                              {
                                'animate-spin': loading,
                              },
                            )}
                            icon={loading ? 'circle-notch' : 'save'}
                            size={1}
                          />
                        }
                        disabled={
                          campaignJobIdInput === '' ||
                          loading ||
                          campaignJobIdInput === campaign?.jobId
                        }
                        onClick={() => {
                          if (!campaign?.id || !currentOrg?.id) return
                          setLoading(true)
                          saveOptionalCampaignIds(
                            campaign.id,
                            currentOrg.id,
                            campaignJobIdInput,
                          )
                            .then((campaign) => {
                              setEditing(undefined)
                              if (campaign) setCampaign(campaign)
                            })
                            .finally(() => setLoading(false))
                        }}
                      />
                    </div>
                  )}
                  {editing === 'poid' && (
                    <div className="CreateCampaignHeader__Text-input CreateCampaignHeader__Text-input-edit">
                      <TextInput
                        value={campaignPoIdInput}
                        onChange={(e) => setCampaignPoIdInput(e.target.value)}
                        placeholder="Enter P.O. ID # here"
                        size="l"
                        disabled={loading}
                      />
                      <Button
                        className="CreateCampaignHeader__button disabled:!bg-grey-200 disabled:text-tint-gray-800 flex rounded-full border-0"
                        size="l"
                        iconOnly={
                          <Fa
                            className={classNames(
                              'EditButton disabled:text-grey-600',
                              {
                                'animate-spin': loading,
                              },
                            )}
                            icon={loading ? 'circle-notch' : 'save'}
                            size={1}
                          />
                        }
                        disabled={
                          campaignPoIdInput === '' ||
                          loading ||
                          campaignPoIdInput === campaign?.jobId
                        }
                        onClick={() => {
                          if (!campaign?.id || !currentOrg?.id) return
                          setLoading(true)
                          saveOptionalCampaignIds(
                            campaign.id,
                            currentOrg.id,
                            undefined, // jobid
                            campaignPoIdInput,
                          )
                            .then((campaign) => {
                              setEditing(undefined)
                              if (campaign) setCampaign(campaign)
                            })
                            .finally(() => setLoading(false))
                        }}
                      />
                    </div>
                  )}
                </div>
                {(editing === 'poid' || editing === 'jobid') && (
                  <Button
                    className="CreateCampaignHeader__button disabled:!bg-grey-200 disabled:text-tint-gray-800 flex rounded-full border-0"
                    size="l"
                    iconOnly={
                      <Fa
                        className={classNames(
                          'EditButton disabled:text-grey-600',
                          {
                            'animate-spin': loading,
                          },
                        )}
                        icon={loading ? 'circle-notch' : 'trash'}
                        size={1}
                      />
                    }
                    disabled={loading}
                    onClick={() => {
                      if (!campaign?.id || !currentOrg?.id) return
                      setLoading(true)
                      saveOptionalCampaignIds(
                        campaign.id,
                        currentOrg.id,
                        editing === 'jobid' ? '' : undefined,
                        editing === 'poid' ? '' : undefined,
                      )
                        .then((campaign) => {
                          setEditing(undefined)
                          if (campaign) setCampaign(campaign)
                        })
                        .finally(() => setLoading(false))
                    }}
                  />
                )}
                <Button
                  className="HeaderButton__Hide"
                  iconOnly={<Fa icon="times" size={1} />}
                  onClick={() => {
                    setEditing(undefined)
                    setLoading(false)
                  }}
                />
              </div>
            </div>
          </CollapsibleContainer>
        </div>
      )}
    </div>
  )
}
