/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3AugmentedLineItem } from '../models/V3AugmentedLineItem';
import { V3Profile } from '../models/V3Profile';
import { V3SeamlessInsertionOrder } from '../models/V3SeamlessInsertionOrder';
import { V3Split } from '../models/V3Split';
import { HttpFile } from '../http/http';

/**
* Configuration for an Xandr Invest deploy destination.
*/
export class V3InvestCampaign {
    /**
    * Enable or disable [splits](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html).
    */
    'useSplits'?: boolean;
    /**
    * User comments pertaining to the campaign.
    */
    'comments'?: string;
    /**
    * The URL the user will be redirected to if the creative is clicked .
    */
    'clickUrl'?: string;
    'seamlessInsertionOrder'?: V3SeamlessInsertionOrder;
    'profile'?: V3Profile;
    'augmentedLineItem'?: V3AugmentedLineItem;
    /**
    * See [Splits Service](https://docs.xandr.com/bundle/xandr-api/page/splits-service.html) in Xandr's documentation.
    */
    'budgetSplitter'?: Array<V3Split>;
    /**
    * ID of the campaign.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "useSplits",
            "baseName": "use_splits",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "comments",
            "baseName": "comments",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickUrl",
            "baseName": "click_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "seamlessInsertionOrder",
            "baseName": "seamless_insertion_order",
            "type": "V3SeamlessInsertionOrder",
            "format": ""
        },
        {
            "name": "profile",
            "baseName": "profile",
            "type": "V3Profile",
            "format": ""
        },
        {
            "name": "augmentedLineItem",
            "baseName": "augmented_line_item",
            "type": "V3AugmentedLineItem",
            "format": ""
        },
        {
            "name": "budgetSplitter",
            "baseName": "budget_splitter",
            "type": "Array<V3Split>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3InvestCampaign.attributeTypeMap;
    }

    public constructor() {
    }
}

