import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  absoluteBlockPositionAtom,
  blockOverlayDimsAtom,
  blockTextOverflowErrorAtom,
  lockedBlockAtom,
  mainDimensionsBlockAtom,
  scaleBlockAtom,
  showBlockOverlayAtom,
  visibleBlockAtom,
} from 'State'
import { Button, CollapsibleContainer, Spacer, Fa } from '@eltoro-ui/components'
import './BlockOverlay.scss'
import { BlockOverlaySettingsMenu } from '../BlockOverlaySettingsMenu'

export const BlockOverlay: React.FC<{ id: string }> = ({ id }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { x, y } = useRecoilValue(absoluteBlockPositionAtom(id))
  const showOverlay = useRecoilValue(showBlockOverlayAtom(id))
  const hasError = useRecoilValue(blockTextOverflowErrorAtom(id))
  const [isLocked, setIsLocked] = useRecoilState(lockedBlockAtom(id))
  const [isVisible, setIsVisible] = useRecoilState(visibleBlockAtom(id))
  const { width, height } = useRecoilValue(blockOverlayDimsAtom(id))
  const mainDimensions = useRecoilValue(mainDimensionsBlockAtom(id))
  const scale = useRecoilValue(scaleBlockAtom(id))

  if (!showOverlay) return null

  const handleHide = () => {
    setIsVisible(false)
    setShowMenu(false)
  }
  const handleLock = () => {
    setIsLocked(true)
    setShowMenu(false)
  }
  return (
    <div
      className="BlockOverlay"
      style={{
        top: y,
        left: x,
        width:
          (mainDimensions.width > 0 ? mainDimensions.width : width) * scale.x,
        height:
          (mainDimensions.height > 0 ? mainDimensions.height : height) *
          scale.y,
      }}
    >
      <div
        className={`BlockOverlay__border ${
          isLocked ? 'BlockOverlay__border--is-locked' : ''
        } ${!isVisible ? 'BlockOverlay__border--is-invisible' : ''} ${
          hasError && isVisible ? 'BlockOverlay__border--has-error' : ''
        }`}
      />
      {!isVisible && (
        <div className="BlockOverlay__visible">
          <Button
            kind="text"
            iconLeft={<Fa size={1} icon="eye" />}
            onClick={() => setIsVisible(true)}
          />
        </div>
      )}
      {isLocked && (
        <div className="BlockOverlay__lock">
          <Button
            kind="danger"
            onClick={() => setIsLocked(false)}
            iconLeft={<Fa size={1} icon="lock" />}
          />
        </div>
      )}
      {hasError && isVisible && !isLocked && (
        <div className="BlockOverlay__error">
          <Fa size={1} icon="exclamation-triangle" />
        </div>
      )}
      {!isLocked && isVisible && (
        <div className="BlockOverlay__top-right-container">
          <Button
            onClick={() => setShowMenu(!showMenu)}
            kind="text"
            iconLeft={<Fa size={1} icon="ellipsis-h" />}
          />
          <CollapsibleContainer isOpen={showMenu}>
            <BlockOverlaySettingsMenu onHide={handleHide} onLock={handleLock} />
          </CollapsibleContainer>
          <Spacer />
        </div>
      )}
    </div>
  )
}
