import React, { useRef, useState } from 'react'
import { DropdownInput, Fa } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { TextInputWithErrorText } from 'Components'
import { StepperSection } from '../../WebToHome'

export const Step1Domain: React.FC<{
  domain: string
  setDomain: React.Dispatch<React.SetStateAction<string>>
  isValidDomain: boolean
  navButtons: (
    nextStep: 'domain' | 'logic' | 'finalize' | 'success',
    backStep?: 'domain' | 'logic' | 'finalize' | undefined,
  ) => JSX.Element
}> = ({ domain, setDomain, isValidDomain, navButtons }) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const resultsRef = useRef<HTMLDivElement>(null)
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([resultsRef, buttonRef, inputContainerRef], () =>
    setShowDropdown(false),
  )

  return (
    <StepperSection className="WebToHome__Step1Domain">
      <p className="text-l font-light">Step 1</p>
      <p className="text-l font-bold">Select Domain</p>
      <p>
        Type in a website&apos;s domain or select from previously used domains
      </p>
      <p>
        <strong>Tip:</strong> If this is a recurring client, select the{' '}
        <strong>previously generated pixel</strong> from the drop down list. We
        recommend (1) pixel for each domain you are tracking
      </p>
      <div className="relative mt-4 max-w-3xl" ref={inputContainerRef}>
        <TextInputWithErrorText
          value={domain}
          placeholder="Search for pixel or enter new pixel name"
          icon={
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              ref={buttonRef}
              className="focus:ring-2"
            >
              <Fa
                icon={showDropdown ? 'chevron-up' : 'chevron-down'}
                size={2}
              />
            </button>
          }
          onChange={(e) => {
            setDomain(e.target.value)
            setShowDropdown(true)
          }}
          isValid={isValidDomain}
          baseValues={['https://']}
          popoverContent={(() => {
            if (!domain.includes('https'))
              return <div>URL requires &quot;https://&quot;</div>
            return (
              <div>
                Please enter a valid url in the format:
                &quot;https://www.websitename.com&quot;
              </div>
            )
          })()}
          onBlur={() => setShowDropdown(false)}
        />
        {/* TODO! Make this query for other pixels in the user's library. Can populate when you get results, use loading prop */}
        {showDropdown && (
          <div ref={resultsRef} className="absolute w-full">
            <DropdownInput
              options={[]}
              // TODO: onClick -> setDomain()
              // Uncomment for visual example (also comment out 'loading')
              //   options={[
              //     { label: 'http://www.example.com', onClick: () => {} },
              //     { label: 'http://www.example.com', onClick: () => {} },
              //     { label: 'http://www.example.com', onClick: () => {} },
              //     { label: 'http://www.example.com', onClick: () => {} },
              //     { label: 'http://www.example.com', onClick: () => {} },
              //     { label: 'http://www.example.com', onClick: () => {} },
              //   ]}
              loading
            />
          </div>
        )}
      </div>
      {navButtons('logic')}
    </StepperSection>
  )
}
