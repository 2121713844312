/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1UpdateOrderLineRequest } from '../models/V1UpdateOrderLineRequest';
import { HttpFile } from '../http/http';

export class V1BatchUpdateOrderLinesRequest {
    /**
    * The request message specifying the resources to update. A maximum of 1000 Order Lines can be modified in a batch.
    */
    'requests'?: Array<V1UpdateOrderLineRequest>;
    /**
    * The list of fields to update. This top level update_mask applies to all Order Lines in the requests. For example: if update_mask is \"name\", requests[0].update_mask is \"click_through_url\", and requests[1].update_mask is \"template_type\", then the Order Line in requests[0] will have its name and click_through_url updated, and the Order Line in requests[1] will have its name and template_type updated.
    */
    'updateMask'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "requests",
            "baseName": "requests",
            "type": "Array<V1UpdateOrderLineRequest>",
            "format": ""
        },
        {
            "name": "updateMask",
            "baseName": "update_mask",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1BatchUpdateOrderLinesRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

