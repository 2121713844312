import { useState } from 'react'
import {
  Avatar,
  TextInput,
  Fa,
  Button,
  Pagination,
} from '@eltoro-ui/components'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import './OrgSelect.scss'
import { LinkButton } from 'Components/LinkButton'

export const OrgSelect = ({
  onOrgSelect,
}: {
  onOrgSelect: (newOrgId: string) => void
}) => {
  const [searchText, setSearchText] = useState('')
  const [loadingOrgChange, setLoadingOrgChange] = useState('')
  const [error, setError] = useState(false)
  const debouncedSearch = useDebounce(searchText, 500)

  const { currentOrg, setCurrentOrg, orgServiceApi } = useAppContext()

  const handleFetchOrgs = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!orgServiceApi) return empty
    setError(false)
    const res = await orgServiceApi
      .advertisingPlatformServiceListOrgs(
        pageSize, // pageSize
        nextPageToken, // pageToken
        'create_time desc', // orderBy
        `name = "*${debouncedSearch}*"`, // filter
        true, // skipPreload
      )
      .catch(() => setError(true))

    if (res && res?.orgs) {
      return {
        data: res.orgs || [],
        totalItems: res.totalSize || 0,
        nextPageToken: res.nextPageToken,
      }
    }
    return empty
  }

  const pagination = useTokenPagination<Orgmanagerv1Org>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgs,
    },
    [debouncedSearch, orgServiceApi],
  )

  const { currentPageData: orgs, loading, setPage } = pagination

  const padOrgs = (orgs: Orgmanagerv1Org[]) => {
    if (orgs.length === 10) return orgs
    return [...orgs, ...Array(10 - orgs.length).fill({})]
  }

  return (
    <div className="OrgSelect">
      <div className="OrgSelect__search flex items-center px-2">
        <TextInput
          iconLeft={<Fa icon="search" size={1} />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchText && (
          <>
            <Button
              iconOnly={<Fa icon="times" size={1} />}
              onClick={() => setSearchText('')}
              kind="text"
              size="s"
            />
          </>
        )}
      </div>
      <div
        className={classNames(
          'OrgSelect__org-list relative flex min-h-[4rem] flex-col overflow-x-hidden pt-2',
          { 'pointer-events-none': loading },
        )}
      >
        {loading && (
          <div className="OrgSelect__loading absolute top-0 bottom-0 right-0 left-0 z-40 overflow-clip p-2">
            <Fa
              className="text-tint-khaki"
              icon="spinner"
              size={2}
              animate="spin"
            />
          </div>
        )}
        {!orgs.length && error && (
          <div className="flex min-h-[41vh] flex-col items-center gap-4 py-3">
            <Fa icon="warning" size={3} />
            <p>Error getting results</p>
            <LinkButton
              text="Retry"
              onClick={() => {
                pagination.refetch()
                setError(false)
              }}
            />
          </div>
        )}
        {!error && orgs.length === 0 && !loading && searchText && (
          <div className="flex flex-col items-center gap-4 py-3">
            <Fa icon="magnifying-glass" size={3} />
            <p>No results for {searchText}</p>
            <LinkButton
              text="Clear search"
              onClick={() => {
                setSearchText('')
                setError(false)
              }}
            />
          </div>
        )}
        {!error &&
          padOrgs(orgs).map((org, index) => {
            return (
              <button
                type="button"
                onClick={async () => {
                  if (!org.id || !orgServiceApi) return
                  setLoadingOrgChange(org.id)
                  orgServiceApi
                    .advertisingPlatformServiceGetOrg(org.id)
                    .then((org) => {
                      setCurrentOrg(org)
                      setLoadingOrgChange('')
                      if (org.id) onOrgSelect(org.id)
                    })
                }}
                className={`OrgSelect__org-button flex-1 focus:ring-2 ${
                  currentOrg?.id === org.id
                    ? 'OrgSelect__org-button--active'
                    : ''
                } ${!org.id ? 'hidden opacity-0' : ''} relative`}
                key={`${org.id}_${index}`}
                disabled={!org.id}
              >
                {(() => {
                  if (org.logo?.key) {
                    return (
                      <Avatar
                        src={`https://s3.amazonaws.com/c.eltoro.com/${org.logo.key}`}
                        rounded
                        size="s"
                      />
                    )
                  }
                  return <Avatar name={org.name || ''} rounded size="s" />
                })()}
                <span className="OrgSelect__org-button-name text-s text-tint-gray-700 inline-block truncate">
                  {org.name}
                </span>
                {loadingOrgChange === org.id && (
                  <Fa
                    icon="spinner"
                    size={1}
                    className="absolute right-4 animate-spin"
                    color="var(--base-info)"
                  />
                )}
              </button>
            )
          })}
        {!error && orgs.length > 0 && (
          <Pagination
            {...pagination}
            onChange={(pageInfo) => {
              setPage(pageInfo.page)
            }}
          />
        )}
      </div>
    </div>
  )
}
