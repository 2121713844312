import { Fragment, useState, ReactNode, FC } from 'react'
import './Breadcrumbs.scss'
import { Link, useLocation } from 'react-router-dom'
import { getColors } from '../../helpers'
import { ColorTokensWithLighterAndDarkerType } from '../Text'

type BreadcrumbType = {
  path: string
  name?: ReactNode
}

const Breadcrumb: FC<{
  breadcrumb: BreadcrumbType
  on?: ColorTokensWithLighterAndDarkerType
  kind?: 'primary' | 'secondary' | 'tertiary'
  isActive: boolean
}> = ({ breadcrumb, on, kind, isActive }) => {
  const [hovering, setHovering] = useState(false)
  const style = () => {
    if (!on) return {}
    const { colors, hovered } = getColors(on, kind)
    return { color: hovering ? hovered.color : colors.color }
  }
  return (
    <Link
      style={style()}
      to={breadcrumb.path}
      className={`Breadcrumb ${isActive ? 'Breadcrumb--is-active' : ''}`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {breadcrumb.name}
    </Link>
  )
}

export const Breadcrumbs = ({
  breadcrumbs,
  className = '',
  separator = '',
  on,
  kind,
}: {
  breadcrumbs: BreadcrumbType[]
  className?: string
  separator?: string | JSX.Element
  on?: ColorTokensWithLighterAndDarkerType
  kind?: 'primary' | 'secondary' | 'tertiary'
}) => {
  const location = useLocation()
  const isExactLocation = (path: string) =>
    location.pathname + location.search === path
  return (
    <div className={`Breadcrumbs ${className}`}>
      {breadcrumbs.map(
        (breadcrumb) =>
          (location.pathname + location.search).includes(breadcrumb.path) && (
            <Fragment key={breadcrumb.path}>
              <Breadcrumb
                breadcrumb={breadcrumb}
                on={on}
                kind={kind}
                isActive={isExactLocation(breadcrumb.path)}
              />
              {/* {!(location.pathname + location.search === breadcrumb.path) && ( */}
              {/* <div className="Breadcrumbs__separator">{separator || '>'}</div> */}
              {separator !== '' && (
                <div className="Breadcrumbs__separator last:hidden">
                  {separator}
                </div>
              )}
            </Fragment>
          ),
      )}
    </div>
  )
}
