import { FC, ReactNode, ChangeEvent } from 'react'
import './RadioButton.scss'
import classNames from 'classnames'

export type RadioButtonType = {
  className?: string
  checked?: boolean
  value: string | number
  onChange?: (
    newSelection: string | number,
    name: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => void
  label?: ReactNode
  labelSub?: ReactNode
  id?: string
  name?: string
  size?: 's' | 'm' | 'l' | 'xl'
  disabled?: boolean
}

export const RadioButton: FC<RadioButtonType> = ({
  className = 'RadioButton__base',
  label,
  labelSub,
  onChange,
  value,
  checked,
  name,
  size = 'm',
  id,
  disabled,
}) => {
  const sizeClass = ` RadioButton--${size}-size`
  const modifierClasses = classNames(' ', sizeClass, {
    'RadioButton--checked': checked,
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && value && name) {
      onChange(value, name, event)
    }
  }

  return (
    <div className={`RadioButton${modifierClasses} ${className}`}>
      <label htmlFor={id} className="RadioButton__wrapper-label">
        <input
          className="RadioButton__input w-3 h-3"
          type="radio"
          onChange={handleChange}
          checked={checked}
          value={value}
          name={name}
          id={id}
          disabled={disabled}
        />
        {label && (
          <span className={`RadioButton__label ${className}`}>
            <span className="LabelMain flex flex-col gap-1">{label}</span>
            {labelSub && (
              <span className="LabelSub text-sm leading-none">{labelSub}</span>
            )}
          </span>
        )}
      </label>
    </div>
  )
}
