export const columnTypes: {
  value: string
  label: string
  fieldName: string
}[] = [
  {
    value: 'ig',
    label: 'Ignore',
    fieldName: 'other_ignore',
  },
  {
    value: 'bn',
    label: 'Business Name',
    fieldName: 'business_name',
  },
  {
    value: 'c',
    label: 'City',
    fieldName: 'city',
  },
  {
    value: 'd',
    label: 'County/District',
    fieldName: 'county',
  },
  {
    value: 'id',
    label: 'Custom ID',
    fieldName: 'custom_id',
  },
  {
    value: 'did',
    label: 'Device ID',
    fieldName: 'deviceid',
  },
  {
    value: 'et',
    label: 'ET Hash',
    fieldName: 'et_hash',
  },
  {
    value: 'e',
    label: 'Email Address',
    fieldName: 'email',
  },
  {
    value: 'fn',
    label: 'First Name',
    fieldName: 'first_name',
  },
  {
    value: 'n',
    label: 'Full Name',
    fieldName: 'full_name',
  },
  {
    value: 'i',
    label: 'IP Address',
    fieldName: 'ip',
  },
  {
    value: 'ir',
    label: 'IP Address Range',
    fieldName: 'ip_range',
  },

  {
    value: 'ln',
    label: 'Last Name',
    fieldName: 'last_name',
  },
  {
    value: 'lat',
    label: 'Latitude',
    fieldName: 'lat',
  },
  {
    value: 'll',
    label: 'Latitude & Longitude',
    fieldName: 'lat_lng',
  },
  {
    value: 'lng',
    label: 'Longitude',
    fieldName: 'lng',
  },
  {
    value: 'maid',
    label: 'MAID',
    fieldName: 'deviceid',
  },
  {
    value: 'mi',
    label: 'Middle Initial',
    fieldName: 'middle_initial',
  },
  {
    value: 'ph',
    label: 'Phone Number',
    fieldName: 'phone',
  },
  {
    value: 'a2',
    label: 'St. Address 2nd line',
    fieldName: 'address2',
  },
  {
    value: 'a3',
    label: 'St. Address 3rd line',
    fieldName: 'address3',
  },
  {
    value: 's',
    label: 'State',
    fieldName: 'state',
  },
  {
    value: 'a',
    label: 'Street Address',
    fieldName: 'address1',
  },
  {
    value: 'z4',
    label: 'Zip Plus Four',
    fieldName: 'zip4',
  },
  {
    value: 'z',
    label: 'Zip/Postal',
    fieldName: 'zip',
  },
]

export default columnTypes
