/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Medium } from '../models/V1Medium';
import { V1Message } from '../models/V1Message';
import { HttpFile } from '../http/http';

export class V1SendMessageRequest {
    'message'?: V1Message;
    'medium'?: V1Medium;
    'recipients'?: Array<string>;
    'sender'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "message",
            "baseName": "message",
            "type": "V1Message",
            "format": ""
        },
        {
            "name": "medium",
            "baseName": "medium",
            "type": "V1Medium",
            "format": ""
        },
        {
            "name": "recipients",
            "baseName": "recipients",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "sender",
            "baseName": "sender",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1SendMessageRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

