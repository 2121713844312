export const audienceTypesEnum: { [key: string]: number } = {
  AUDIENCE_TYPE_UNKNOWN: 0,
  AUDIENCE_TYPE_B2C: 1,
  AUDIENCE_TYPE_B2B: 3,
  AUDIENCE_TYPE_ADDRESS: 5,
  AUDIENCE_TYPE_PREVIOUS: 8,
  AUDIENCE_TYPE_DC: 11,
  AUDIENCE_TYPE_DIRECTMAIL: 14,
  AUDIENCE_TYPE_ZIP: 17,
  AUDIENCE_TYPE_IP: 20,
  AUDIENCE_TYPE_REVERSE: 23,
  AUDIENCE_TYPE_ONSPOTMAID: 26,
  AUDIENCE_TYPE_ADDRESSVALIDATION: 29,
  AUDIENCE_TYPE_PIPELINE: 31,
  AUDIENCE_TYPE_IPSFORGEOJSON: 33,
  AUDIENCE_TYPE_DEVICE: 35,
  AUDIENCE_TYPE_VR: 37,
  AUDIENCE_TYPE_IPSFORWKB: 39,
  AUDIENCE_TYPE_MAID: 41,
  AUDIENCE_TYPE_PREMOVER: 111,
  AUDIENCE_TYPE_POSTMOVER: 112,
  AUDIENCE_TYPE_ESCROWMOVER: 113,
  AUDIENCE_TYPE_RETARGETING: 115,
  AUDIENCE_TYPE_PREMOVER_ADDRESS: 117,
  AUDIENCE_TYPE_POSTMOVER_ADDRESS: 118,
  AUDIENCE_TYPE_ESCROWMOVER_ADDRESS: 119,
  AUDIENCE_TYPE_ENRICHMENT: 121,
  AUDIENCE_TYPE_ADDRESSES_FOR_IP: 122,
  AUDIENCE_TYPE_CONTACTS_FOUND_IN_HOUSING_MARKET: 123,
  AUDIENCE_TYPE_IP_FOR_ETHASH: 124,
  AUDIENCE_TYPE_PROSPECTS_IN_HOUSING_MARKET: 125,
  AUDIENCE_TYPE_PROSPECTS_LIKELY_HOME_SELLERS: 126,
  AUDIENCE_TYPE_PROSPECTS_IN_AUTOMOBILE_MARKET: 127,
  AUDIENCE_TYPE_CONTACT_ENRICHMENT: 128,
  AUDIENCE_TYPE_ENRICHMENTS_FOR_ADDRESSES_IN_AUTOMOBILE_MARKET: 130,
  AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS: 132,
  AUDIENCE_TYPE_HOUSEHOLDS_FOR_DEVICES: 134,
  AUDIENCE_TYPE_DEVICES_FOR_POLYGON: 136,
  AUDIENCE_TYPE_VR_2: 137,
  AUDIENCE_TYPE_GROW_YOUR_SPHERE: 138,
  AUDIENCE_TYPE_OCCUPANCY_ENRICHMENT: 139,
  AUDIENCE_TYPE_CONTACTS_FOUND_IN_HOUSING_MARKET_V2: 150,
}

export const audienceStatusEnum: { [key: string]: number } = {
  // Unknown Status.
  AUDIENCE_STATUS_UNKNOWN: 0,
  // Audience has been created.
  AUDIENCE_STATUS_CREATED: 5,
  // Audience has been added to the queue.
  AUDIENCE_STATUS_QUEUED: 10,
  // Audience is currently running.
  AUDIENCE_STATUS_RUNNING: 20,
  // Audience has completed.
  AUDIENCE_STATUS_COMPLETED: 30,
  // Audience has been created in deploy and the location has been set on the segment.
  AUDIENCE_STATUS_READY: 35,
  // Audience has been cancelled.
  AUDIENCE_STATUS_CANCELLED: 40,
  // Audience had an Error.
  AUDIENCE_STATUS_ERRORED: 50,
  // Used in onspot/pipeline jobs but currently unimplemented in this version.
  AUDIENCE_STATUS_WAITING: 25,
  // audience has been archived
  AUDIENCE_STATUS_ARCHIVED: 100,
  // audience has expired which means it is not easily accessible
  AUDIENCE_STATUS_EXPIRED: 200,
  // audience has been purged which means it is gone and unable to be returned
  AUDIENCE_STATUS_PURGED: 300,
  // audience is in the process of unarchiving
  AUDIENCE_STATUS_UNARCHIVING: 400,
}

export const audienceProductTypeEnum: { [key: string]: number } = {
  // Unknown Product Type the User sees
  PRODUCT_TYPE_UNKNOWN: 0,
  // Ip Targeting Product Type the User sees
  PRODUCT_TYPE_IP_TARGETING: 5,
  // Digital Canvassing Product Type the User sees
  PRODUCT_TYPE_DIGITAL_CANVASSING: 15,
  // Venue Replay Product Type the User sees
  PRODUCT_TYPE_VENUE_REPLAY: 20,
  // New Movers Product Type the User sees
  PRODUCT_TYPE_NEW_MOVERS: 25,
  // Map Polygon Product Type the User sees
  PRODUCT_TYPE_MAP_POLYGON: 30,
  // Retargeting Product Type the User sees
  PRODUCT_TYPE_RETARGETING: 35,
  // Enrichment Product Type the User sees
  PRODUCT_TYPE_ENRICHMENT: 40,
  // Mailing Product Type the User sees
  PRODUCT_TYPE_IP_LIST_FOR_MAILING: 45,
}

export const audienceSubTypeEnum: { [key: string]: number } = {
  AUDIENCE_SUB_TYPE_UNKNOWN: 0,
  AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN: 1,
  AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES: 3,
  AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES: 5,
  AUDIENCE_SUB_TYPE_HOMES: 7,
}
