import DOMPurify from 'dompurify'

export const getSanitizedHTML = (html: string, browser: string): string => {
  const purifyConfig = {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'span', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['href', 'class', 'target', 'rel'],
  }

  const _temp = document.createElement('div')
  _temp.innerHTML = html // move text into temp div then return it as just text

  if (
    browser === 'Chrome' ||
    browser === 'Safari' ||
    browser === 'Firefox' ||
    browser === 'Opera'
  ) {
    return DOMPurify.sanitize(html, purifyConfig)
  }
  return _temp.textContent || _temp.innerText || '' // return empty string if no text found
}
