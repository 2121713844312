/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1Note } from '../models/Campaignservicev1Note';
import { V1ReviewStatus } from '../models/V1ReviewStatus';
import { HttpFile } from '../http/http';

/**
* The review object on the Order Line. There is one review per Order Line.
*/
export class V1Review {
    /**
    * The User ID of who reviewed the Order Line. TODO: Unsure if I'm correct on this one.
    */
    'reviewerId'?: string;
    /**
    * The User ID of who submitted the Order Line to review.
    */
    'submitterId'?: string;
    'status'?: V1ReviewStatus;
    'note'?: Campaignservicev1Note;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "reviewerId",
            "baseName": "reviewer_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterId",
            "baseName": "submitter_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1ReviewStatus",
            "format": ""
        },
        {
            "name": "note",
            "baseName": "note",
            "type": "Campaignservicev1Note",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Review.attributeTypeMap;
    }

    public constructor() {
    }
}

