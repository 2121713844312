import {
  Campaignservicev1CPM,
  GoogletypeMoney,
  Orgmanagerv1CPM,
} from 'next-gen-sdk'
import { CPMObjectType } from 'types'

export const getTypeMoneyCost = (
  typeMoney:
    | Partial<Orgmanagerv1CPM>
    | Partial<GoogletypeMoney>
    | Partial<Campaignservicev1CPM>,
) => +(typeMoney?.units || 0) + (typeMoney?.nanos || 0) / 10 ** 9

export const separatePoliticalCPMs = (cpms: CPMObjectType) => {
  const politicalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => key.includes('political')),
  )
  const nonPoliticalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => !key.includes('political')),
  )
  return { nonPoliticalCPMs, politicalCPMs }
}
