import { styleTailwind } from 'Helpers'
import './CreativeUploader.scss'
import { Fa, TextHeader } from '@eltoro-ui/components'

const UploaderSubHed = styleTailwind(
  TextHeader,
  'UploaderSubHed text-tint-gray-600 py-2 gap-1',
)
const UploaderText = styleTailwind(
  'div',
  'UploaderText flex flex-col text-left w-auto gap-1',
)
const FALi = styleTailwind('li', 'Fa-ul-li py-1 leading-tight text-text')

const SpanLite = styleTailwind('span', 'text-text')

const FUL = styleTailwind('ul', 'fa-ul flex-col pl-6')

const UploaderTextWrap = styleTailwind(
  'div',
  'UploaderTextWrap flex tabletsmall:flex-wrap mx-auto gap-8',
)
export const HTML5Text = () => (
  <div className="HTML5Text relative flex flex-col items-center justify-center">
    <UploaderTextWrap className="CreativeUploader__Requirements--HTML5Text">
      <UploaderText className="border-tint-gray-500 border-b-thin !flex-row items-center">
        <UploaderSubHed type={6}>Required:</UploaderSubHed>
        <FUL className="ml-8">
          <FALi className="">
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>
              ZIP &nbsp;( HTML5 ad with a valid&nbsp;
              <code className="text-success bg-primary-50 !text-s p-1">
                index.html
              </code>
              &nbsp;in a&nbsp;
              <code className="text-success bg-primary-50 !text-s p-1">
                .zip
              </code>
              &nbsp;file.&nbsp; Go to link below for details )
            </SpanLite>
          </FALi>
        </FUL>
      </UploaderText>
    </UploaderTextWrap>
    <UploaderTextWrap className="CreativeUploader__Requirements--HTML5Text">
      <UploaderText>
        <UploaderSubHed type={6}>Supported Types:</UploaderSubHed>
        <FUL>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            Simple ads
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            Rich media ads (audio or visual)
          </FALi>
        </FUL>
      </UploaderText>
      <UploaderText>
        <UploaderSubHed type={6}>
          Supported files (inside zip file):
        </UploaderSubHed>
        <FUL>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            HTML
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            Javascript
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            CSS
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            JPG/JPEG
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            PNG
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            GIF
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            SVG
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            XML
          </FALi>
        </FUL>
      </UploaderText>
      <UploaderText>
        <UploaderSubHed type={6}>Recommended Sizes:</UploaderSubHed>
        <FUL>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>160 x 600</SpanLite>
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>300 x 250</SpanLite>
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>300 x 600</SpanLite>
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>320 x 50</SpanLite>
          </FALi>
          <FALi>
            <span className="fa-li">
              <Fa icon="check-circle" className="text-primary-300" size={1} />
            </span>
            <SpanLite>728 x 90</SpanLite>
          </FALi>
        </FUL>
      </UploaderText>
    </UploaderTextWrap>
  </div>
)
export const BannerText = () => (
  <UploaderTextWrap className="CreativeUploader__Requirements--BannerText">
    <UploaderText>
      <UploaderSubHed type={6}>Supported files:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>JPG</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>PNG</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>GIF</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>Recommended Sizes:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>160 x 600</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>300 x 250</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>300 x 600 </SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>320 x 50</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>728 x 90</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>File size:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>Under 150 KB</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
  </UploaderTextWrap>
)
export const VideoText = () => (
  <UploaderTextWrap className="CreativeUploader__Requirements--VideoText flex gap-12">
    <UploaderText>
      <UploaderSubHed type={6}>Supported formats:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>mpg</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>mp4</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>mov</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>avi</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>File size:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite>Under 1 GB</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    {/* <UploaderSubHed type={6}>
        Valid durations: (seconds)
        </UploaderSubHed>
        <FUL>
        <FALi className="leading-tight pb-1 text-s">
        <span className="fa-li">
        <Fa icon="check-circle" className="text-primary-300" size={1} />
        </span>
        <SpanLite>2-5.9</SpanLite>
        </FALi>
        <FALi className="leading-tight pb-1 text-s">
        <span className="fa-li">
        <Fa icon="check-circle" className="text-primary-300" size={1} />
        </span>
        <SpanLite>6-14.9</SpanLite>
        </FALi>
        <FALi className="leading-tight pb-1 text-s">
        <span className="fa-li">
        <Fa icon="check-circle" className="text-primary-300" size={1} />
        </span>
        <SpanLite>18-29.9</SpanLite>
        </FALi>
        <FALi className="leading-tight pb-1 text-s">
        <span className="fa-li">
        <Fa icon="check-circle" className="text-primary-300" size={1} />
        </span>
        <SpanLite>35-59.9</SpanLite>
        </FALi>
        </FUL> */}
  </UploaderTextWrap>
)
export const OTTText = () => (
  <UploaderTextWrap className="CreativeUploader__Requirements--OTTtext gap-12">
    <UploaderText>
      <UploaderSubHed type={6}>Supported format:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Required</strong>
          <SpanLite> - MP4</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Recommended</strong>
          <SpanLite> - MP4 with AAC audio</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>Length:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Required</strong>
          <SpanLite> - under 90s</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Recommended</strong> <SpanLite> - 15-30s</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>Dimensions:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Required</strong>
          <SpanLite> - 1280x720 (720p) or greater</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Recommended</strong>
          <SpanLite> - 1280x720 (720p)</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>Bitrate:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Required</strong>
          <SpanLite> - 2,000 kbps</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <strong>Recommended</strong>
          <SpanLite> - 14,000 kbps or greater</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
    <UploaderText>
      <UploaderSubHed type={6}>Additional Recommendations:</UploaderSubHed>
      <FUL>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>
          <SpanLite> 29.27 frames per second or higher</SpanLite>
        </FALi>
        <FALi>
          <span className="fa-li">
            <Fa icon="check-circle" className="text-primary-300" size={1} />
          </span>

          <SpanLite> VAST Version 2.0 or higher</SpanLite>
        </FALi>
      </FUL>
    </UploaderText>
  </UploaderTextWrap>
)
