import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'

export const StepIcon = ({
  step,
  currentStep,
  label,
  icon,
  success,
}: {
  step: number
  currentStep: number
  label: string
  icon: string
  success: boolean
}) => {
  return (
    <>
      <div
        title={label}
        className={classNames('CCModal__step-icon circle', {
          'bg-primary-600': step === currentStep && !success,
          'bg-grey-100': step !== currentStep && !success,
          'bg-success-400': success,
        })}
      >
        <Fa
          icon={success ? 'check' : icon}
          size={1}
          className={classNames({
            '!text-white': success,
          })}
        />
      </div>
    </>
  )
}
