import React from 'react'
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 * import { faInfoCircle } from '@fortawesome/free-solid-svg-icons' */
/* import { Tooltip } from 'antd' */
import './CreativeStats.scss'

export type CreativeDataType = {
  clicks: {
    label: string
    value: number
  }
  imps: {
    label: string
    value: number
  }
  CTR: {
    label: string
    value: string
  }
}

export const CreativeStats: React.FC<{ statsData: CreativeDataType }> = ({
  statsData,
}) => {
  const CTRTooltip = () => {
    return (
      <div className="infoCircle-CreativeStats">
        {/* <Tooltip
              title={
              <div>
              <p>CTR</p>
              <ul>
              <li>
              Click-through rate (CTR) reflects total clicks per
              impressions.
              </li>
              <li>0.06% to 0.08% is a healthy clickthrough rate.</li>
              </ul>
              </div>
              }
              placement="topLeft"
              >
              <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip> */}
      </div>
    )
  }

  return (
    <div className="CreativeStats">
      {Object.keys(statsData).map((key, index: number) => (
        <div key={index} className="dataRow-CreativeStats">
          <div className="label-dataRow">
            {statsData[key as keyof typeof statsData].label}
            {key === 'CTR' && <CTRTooltip />}
          </div>
          <p className="value-dataRow">
            {statsData[key as keyof typeof statsData].value}
          </p>
        </div>
      ))}
    </div>
  )
}
