import React from 'react'
import { LoadingBar } from '@eltoro-ui/components'

export const Loading = ({ className = '' }) => {
  return (
    <div
      className={`Loading ${className} m-auto flex h-full items-center justify-center`}
    >
      <LoadingBar kind="circle" colorKind="primary" />
    </div>
  )
}
