/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Pixel } from '../models/V1Pixel';
import { HttpFile } from '../http/http';

export class V1ListPixelsResponse {
    'pixels'?: Array<V1Pixel>;
    'totalSize'?: number;
    'nextPageToken'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "pixels",
            "baseName": "pixels",
            "type": "Array<V1Pixel>",
            "format": ""
        },
        {
            "name": "totalSize",
            "baseName": "total_size",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ListPixelsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

