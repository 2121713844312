import { useState, useRef, useEffect } from 'react'
import {
  Button,
  Fa,
  Popover,
  Text,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { BrandURLPop, GoBackButton } from 'Components'
import { useGetFullOLCreatives } from 'Hooks'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { CreativeEditOLTableProps } from './CreativeEditOLTable'
import {
  DurationOrDimensionDetail,
  getCreativeFaETIcons,
  styleTailwind,
} from 'Helpers'
import { useOnClickOutside, useValidatedState } from '@eltoro-ui/hooks'
import { getThumbnail } from './getThumbnail'
import { ChangeCreativeMenu } from './ChangeCreativeMenu'
import { ModifiedOrderLineType } from '../types'
import { ClickThroughWithUTM } from 'Components/AdSetForms/components/ClickThroughWithUTM'

const CarouselNavButton = styleTailwind(
  'button',
  'disabled:pointer-events-none disabled:bg-transparent',
)
const StackBtn = styleTailwind('div', 'StackedButtonIcon group')

const CreativeRowCarouselPopover = ({
  creative,
  orderLine,
  children,
  err,
  handleRemoveCreative,
  handleSwapCreative,
  modalClose,
}: {
  creative: Creativeservicev1Creative
  orderLine: ModifiedOrderLineType
  children: React.ReactNode
  err?: string
} & Partial<CreativeEditOLTableProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showChangeMenu, setShowChangeMenu] = useState(false)
  const infoContentRef = useRef<HTMLDivElement>(null)
  const infoButtonRef = useRef<HTMLDivElement>(null)
  const [brandUrl, setBrandUrl, isValidBrandURL] = useValidatedState(
    'https://',
    'url',
  )
  const [brand, setBrand] = useState(false)
  const [savedBrandUrl, setSavedBrandUrl] = useState(false)
  useEffect(() => {
    if (!isOpen) {
      setBrand(false)
    }
  }, [isOpen])
  useEffect(() => {
    if (creative.nativeMetadata?.alternativeMobileLandingPageUrl) {
      setBrandUrl(creative.nativeMetadata.alternativeMobileLandingPageUrl)
    }
  }, [creative, setBrandUrl])
  useOnClickOutside([infoContentRef, infoButtonRef], () => setIsOpen(false))
  const { currentOrg, creativeServiceApi } = useAppContext()
  const { setRefresh } = useCampaignContext()
  if (!children) return null
  return (
    <Popover
      position={['right']}
      align="center"
      content={
        <div
          ref={infoContentRef}
          className={`CreativeRowCarouselPopover__content ${
            brand && 'min-w-[30rem]'
          } ${
            brand ? 'max-w-[30rem]' : 'max-w-[20rem]'
          } bg-base text-s relative rounded-lg p-5`}
        >
          {!showChangeMenu ? (
            <>
              <div className="CreativeCarouselPopover__content-details flex flex-col">
                {err ? (
                  <div className="CreativeRowCarouselPopover__warning bg-warning-50 flex w-full items-center font-bold">
                    <Fa icon="warning" size={1} className="text-warning" />
                    {err}
                  </div>
                ) : (
                  <>
                    {getThumbnail(creative, 'f')}
                    <div className="Thumbnail__details flex flex-col justify-around gap-2">
                      {creative.name && (
                        <div className="flex flex-1 gap-2">
                          <span className="ThumbnailLabel">File name:</span>
                          <span className="ThumbnailText">{creative.name}</span>
                        </div>
                      )}
                      <div className="flex flex-1 gap-2">
                        {DurationOrDimensionDetail(creative, true)}
                      </div>
                      {creative.nativeMetadata
                        ?.alternativeMobileLandingPageUrl &&
                        !savedBrandUrl && (
                          <div className="CreativeSpecBrandUrl flex gap-2">
                            <span className="ThumbnailLabel flex justify-end font-bold">
                              <BrandURLPop />
                            </span>
                            {creative.nativeMetadata
                              .alternativeMobileLandingPageUrl && (
                              <span className="ThumbnailText">
                                {
                                  creative.nativeMetadata
                                    .alternativeMobileLandingPageUrl
                                }
                              </span>
                            )}
                          </div>
                        )}
                      {savedBrandUrl && brandUrl && brandUrl !== '' && (
                        <div className="flex flex-1 gap-2">
                          <span className="ThumbnailLabel">
                            <BrandURLPop />
                          </span>
                          <span className="ThumbnailText">{brandUrl}</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="CreativeCarouselPopover__content-menu flex flex-col gap-2">
                <Text className="CreativeCarouselPopover__content-menu-header text-m text-tint-gray-600 font-bold uppercase">
                  Edit Creative &amp; (Optional) Brand URL
                </Text>
                <div className="CreativeChangeButtonIcon__wrap flex w-full justify-around gap-1">
                  <StackBtn
                    className="CreativeChangeButtonIcon hover:text-primary"
                    data-tooltip="top"
                    aria-label="Search for creatives"
                  >
                    <Button
                      iconCenter={
                        <Fa
                          icon="file-magnifying-glass"
                          size={1}
                          className="StackedButtonIcon__icon"
                        />
                      }
                      onClick={() => setShowChangeMenu(true)}
                    >
                      <span className="IconLabel">Search</span>
                    </Button>
                  </StackBtn>
                  {!(
                    orderLine.status !== 'ORDERLINE_STATUS_DRAFT' &&
                    orderLine.creatives &&
                    orderLine.creatives.length === 1
                  ) && (
                    <StackBtn
                      className="CreativeChangeButtonIcon"
                      data-tooltip="top"
                      aria-label="Remove Creative"
                    >
                      <Button
                        iconCenter={
                          <Fa
                            icon="trash-xmark"
                            size={1}
                            className="StackedButtonIcon__icon"
                          />
                        }
                        onClick={() =>
                          handleRemoveCreative &&
                          handleRemoveCreative(orderLine, creative)
                        }
                      >
                        <span className="IconLabel StackedButtonIcon__label">
                          Remove
                        </span>
                      </Button>
                    </StackBtn>
                  )}
                  {orderLine.status === 'ORDERLINE_STATUS_DRAFT' && (
                    <StackBtn
                      className="CreativeChangeButtonIcon"
                      data-tooltip="top"
                      aria-label="Remove All Creatives from Order Line"
                    >
                      <Button
                        iconCenter={
                          <Fa
                            icon="trash-xmark"
                            size={1}
                            className="StackedButtonIcon__icon"
                          />
                        }
                        onClick={() =>
                          handleRemoveCreative &&
                          handleRemoveCreative(orderLine)
                        }
                      >
                        <span className="IconLabel">Remove All</span>
                      </Button>
                    </StackBtn>
                  )}

                  <StackBtn
                    className={`CreativeChangeButtonIcon ${
                      brand ? 'active' : ''
                    }`}
                    data-tooltip="top"
                    aria-label={
                      !brand
                        ? 'Add/Edit (Optional) Brand URL'
                        : 'Close Brand URL editor'
                    }
                  >
                    <Button
                      iconCenter={
                        <Fa
                          icon="browser"
                          size={1}
                          className="StackedButtonIcon__icon fa-regular"
                        />
                      }
                      onClick={() => setBrand(!brand)}
                    >
                      <BrandURLPop />
                    </Button>
                  </StackBtn>
                </div>

                {brand && (
                  <span className="CreativeRowCarousel__brandUrl animate-slidedown flex items-center gap-1 pt-2">
                    <BrandURLPop className="w-auto" />
                    {savedBrandUrl ? (
                      <span className="w-auto">{brandUrl}</span>
                    ) : (
                      <ClickThroughWithUTM
                        className="flex-1"
                        OLName={''}
                        clickThru={brandUrl}
                        setClickThru={setBrandUrl}
                        isValidClickThru={isValidBrandURL}
                        brand
                        preview
                      />
                    )}
                    {isValidBrandURL && (
                      <span className="m-auto pl-1">
                        <Button
                          className="Button"
                          rounded
                          iconOnly={
                            <span
                              className="Tooltip-css-wrap"
                              data-tooltip="left"
                              aria-label="Save (Optional) Brand Url"
                            >
                              {isValidBrandURL && (
                                <Fa
                                  icon={!savedBrandUrl ? 'save' : 'pencil'}
                                  size={1}
                                />
                              )}
                            </span>
                          }
                          onClick={() => {
                            if (
                              isValidBrandURL &&
                              currentOrg?.id &&
                              creative.id &&
                              !savedBrandUrl
                            ) {
                              creativeServiceApi
                                ?.advertisingPlatformServiceUpdateCreative(
                                  creative.id,
                                  currentOrg.id,
                                  {
                                    nativeMetadata: {
                                      alternativeMobileLandingPageUrl: brandUrl,
                                    },
                                  },
                                  creative.nativeMetadata !== null
                                    ? 'native_metadata.alternative_mobile_landing_page_url'
                                    : '',
                                )
                                .then((res) => {
                                  setRefresh(true)
                                  setSavedBrandUrl(true)
                                  if (modalClose) {
                                    modalClose()
                                    showSuccessMessage(
                                      `Creative ${creative.id}:`,
                                      `Brand URL Updated`,
                                    )
                                  }
                                })
                            }
                            if (savedBrandUrl) {
                              setSavedBrandUrl(false)
                            }
                          }}
                        />
                      </span>
                    )}
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <GoBackButton
                onClick={() => setShowChangeMenu(false)}
                position="top-right"
                classWrap="CreativeRowCarousel__button-cancel"
              />

              <ChangeCreativeMenu
                onCreativeSelect={(newCreative) =>
                  handleSwapCreative &&
                  handleSwapCreative(newCreative, creative, orderLine)
                }
                orderLine={orderLine}
              />
            </>
          )}
        </div>
      }
      isOpen={isOpen}
      className="CreativeRowCarouselPopover__creative-popover CreativeThumbnail__popover"
    >
      <div
        className="CreativeRowCarouselPopover__thumb"
        onClick={() => setIsOpen(true)}
        aria-label="Click to Edit Creative"
        data-tooltip="top"
      >
        {children}
      </div>
    </Popover>
  )
}

export const CreativeRowCarousel = ({
  orderLine,
  handleAddCreative,
  handleSwapCreative,
  handleRemoveCreative,
  modalClose,
}: {
  orderLine: ModifiedOrderLineType
} & Partial<CreativeEditOLTableProps>) => {
  const [page, setPage] = useState(1)
  const { currentOrg, tok } = useAppContext()
  const [creatives, loading] = useGetFullOLCreatives(
    orderLine.creatives,
    currentOrg?.id,
    tok,
  )

  const start = 3 * (page - 1)
  const offset = page * 3
  const totalPages = Math.ceil(creatives.length / 3)

  // reset page if all creatives removed from current page
  useEffect(() => {
    if (orderLine.creatives?.slice(start, offset).length === 0)
      setPage((prev) => (prev > 1 ? prev - 1 : prev))
  }, [orderLine.creatives, start, offset])

  if (!orderLine.creatives || orderLine.creatives.length === 0)
    return <Text className="text-warning font-bold">No creatives</Text>
  return (
    <div className="CreativeRowCarousel flex items-center">
      {page !== 1 && (
        <CarouselNavButton
          className="CreativeRowCarousel__left-btn disabled:text-grey-200 relative z-10"
          onClick={() => setPage((prev) => prev - 1)}
          disabled={page === 1}
        >
          <span
            aria-label={page !== 1 ? 'Previous Creatives' : undefined}
            data-tooltip="top"
          >
            <Fa icon="chevron-left" size={3} className="scale-75" />
          </span>
        </CarouselNavButton>
      )}
      {loading
        ? orderLine.creatives.slice(start, offset).map((creative, i) =>
            creative.type ? (
              <div key={`${creative.id}_${i}`} className="h-16 w-16">
                {getCreativeFaETIcons(creative.type)}
              </div>
            ) : (
              <div
                key={`${creative.id}_${i}`}
                className="bg-tint-gray-100 h-16 w-16"
              />
            ),
          )
        : creatives.slice(start, offset).map(({ creative, err }, i) => (
            <CreativeRowCarouselPopover
              key={`${creative.id}_${i}`}
              err={err}
              creative={creative}
              orderLine={orderLine}
              handleAddCreative={handleAddCreative}
              handleRemoveCreative={handleRemoveCreative}
              handleSwapCreative={handleSwapCreative}
              modalClose={modalClose}
            >
              {!err ? (
                getThumbnail(creative, undefined, true)
              ) : (
                <div className="CreativeRowCarousel__warning-thumbnail CreativeThumbnail CreativeThumbnail--size-s bg-warning-50 my-1 flex items-center justify-center">
                  <Fa icon="warning" size={2} className="text-warning" />
                </div>
              )}
            </CreativeRowCarouselPopover>
          ))}
      {(totalPages > 3 || page !== totalPages) && (
        <CarouselNavButton
          className="CreativeRowCarousel__right-btn disabled:text-grey-200 relative z-10"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={totalPages < 2 || page === totalPages}
        >
          <span
            aria-label={
              totalPages > 3 || page !== totalPages
                ? 'Click to see more creatives'
                : undefined
            }
            data-tooltip="top"
          >
            <Fa icon="chevron-right" size={3} className="scale-75" />
          </span>
        </CarouselNavButton>
      )}
    </div>
  )
}
