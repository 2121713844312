import { Text, Fa } from '@eltoro-ui/components'
import { V1CreditCard } from 'next-gen-sdk'
import { checkExp } from 'Helpers'
import classNames from 'classnames'

export const CCListItem = ({
  cc,
  onUpdateCard,
  onDeleteCard,
  disableEdit,
}: {
  cc: V1CreditCard
  onUpdateCard: () => void
  onDeleteCard: () => void
  disableEdit?: boolean
}) => {
  const icon = () => {
    if (cc.type === 'American Express') return 'cc-amex'
    if (cc.type === 'Diners Club International') return 'cc-diners-club'
    return `cc-${(cc?.type || '').toLowerCase()}`
  }

  const DeleteButton = () =>
    disableEdit ? null : (
      <span className="CCListItem__remove-wrap absolute flex flex-col justify-center">
        <button
          type="button"
          className="Button Button--m-size Button Button--has-icon-only CCListItem__remove group-hover:bg-danger-600 group-hover:text-primer hover:!bg-danger-600  ml-auto mt-4  border-none"
          onClick={onDeleteCard}
          data-tooltip="left"
          aria-label={`Click to delete ${icon()}`}
          disabled={disableEdit}
        >
          <Fa icon="times" size="lg" />
        </button>
      </span>
    )

  const isExpired = cc.expirationDate && !checkExp(cc?.expirationDate)
  const isNotAuthorized =
    !cc.authorization ||
    !cc.authorization.approved ||
    !cc.authorization.signature

  return (
    <div
      className={`CCListItem__credit-card--wrap group relative z-0 flex w-full flex-1 items-center justify-between ${
        cc.isDefault && !isExpired ? 'Default-card' : ''
      }`}
    >
      <div className="w-full">
        {/* change divs to spans as divs are not valid button's child elements  */}
        <span
          className={classNames(
            'CCListItem__card-detail-wrap relative flex w-full min-w-fit',
            {
              'border-danger-600 border-2': cc.isDefault && isExpired,
              'border-warning-400 border-2': !isExpired && isNotAuthorized,
            },
          )}
          data-tooltip="top"
          aria-label={`Click to edit ${cc.type} card${
            cc.nickname ? ` "${cc.nickname}"` : ''
          }`}
        >
          <button
            type="button"
            className={`CCListItem__card-detail relative z-0 w-full flex-1 py-4 pl-3 pr-2 ${
              disableEdit ? '!cursor-auto' : ''
            }`}
            onClick={disableEdit ? () => {} : onUpdateCard}
          >
            <span className="CCListItem__cc-detail flex items-center">
              <span className="CCListItem__credit-card--icon mr-2 flex self-center">
                <Fa
                  icon={icon()}
                  type="branded"
                  size={4}
                  className="text-tint-khaki-700 group-hover:!text-primary-600"
                />
              </span>
              <span className="CCListItem__cardInfo relative flex flex-col items-start gap-1">
                <span className="CCListItem__cardInfo-number flex gap-1 text-left">
                  {cc.type?.replace('International', "Int'l")} {cc.cardNumber}
                </span>

                <span className="CCListItem__cardInfo-exp flex">
                  {!isExpired ? (
                    <span className="text-left">
                      Exp. Date: {cc.expirationDate}
                    </span>
                  ) : (
                    <span className="flex gap-1">
                      <span className="text-danger-600 text-left font-bold">
                        Exp. Date: {cc.expirationDate}
                      </span>
                      <Fa
                        icon="circle-exclamation"
                        size="sm"
                        className="text-danger-600"
                      />
                    </span>
                  )}
                </span>
                {cc.isDefault && (
                  <Text
                    className={`Default-label ribbon top-left${
                      cc.isDefault && isExpired ? ' !bg-danger' : ''
                    }`}
                    size="s"
                  >
                    Default
                  </Text>
                )}
              </span>
            </span>
            <span className="CCListItem__cardName flex flex-col gap-1">
              <span className="CCListItem__cardName-value flex items-start justify-start">
                {cc.firstName} {cc.lastName}
              </span>
              <span className="CCListItem__cardName-label flex items-end justify-start">
                <Text size="s" weight="light">
                  Full name
                </Text>
              </span>
            </span>
            <span className="CCListItem__signed flex flex-col gap-1">
              <span className="CCListItem__signed-value flex items-start justify-start">
                {cc && cc.authorization && cc.authorization.signature ? (
                  <span>Yes</span>
                ) : (
                  <span className="flex">
                    <span className="text-warning-400 font-bold">No!</span>
                    <Fa
                      icon="circle-exclamation"
                      size="sm"
                      className="text-warning-400"
                    />
                  </span>
                )}
              </span>
              <span className="CCListItem__signed-label flex items-end justify-start">
                <Text size="s" weight="light">
                  Signed
                </Text>
              </span>
            </span>
            <span className="CCListItem__cardAuth flex flex-col gap-1">
              <span className="CCListItem__auth-value flex items-start justify-start">
                {cc &&
                cc.authorization &&
                cc.authorization.approved &&
                cc.authorization.id ? (
                  <span>Yes</span>
                ) : (
                  <span className="flex gap-1">
                    <span className="text-warning-400">No!</span>
                    <Fa
                      icon="circle-exclamation"
                      size="sm"
                      className="text-warning-400"
                    />
                  </span>
                )}
              </span>
              <span className="CCListItem__auth-label flex items-end justify-start">
                <Text size="s" weight="light">
                  Authorized
                </Text>
              </span>
            </span>
          </button>
        </span>
        <DeleteButton />
        {(() => {
          if ((cc.isDefault && isExpired) || isNotAuthorized)
            return (
              <div
                className={classNames('CCListItem__auth-msg py-2 px-3', {
                  'CCListItem__auth-msg--error bg-danger-300':
                    cc.isDefault && isExpired,
                  'CCListItem__auth-msg--warning bg-warning-300': isNotAuthorized,
                })}
              >
                {cc.isDefault && isExpired
                  ? "Please update this card's expiration date."
                  : 'Please update this card to authorize.'}
              </div>
            )
        })()}
      </div>
    </div>
  )
}
