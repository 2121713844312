import { Circle as CircleType } from 'konva/types/shapes/Circle'
import React from 'react'
import { Circle } from 'react-konva'
import { useRecoilValue } from 'recoil'
import {
  blockShadowBlurAtom,
  blockShadowColorAtom,
  blockShadowOffsetXAtom,
  blockShadowOffsetYAtom,
  blockShadowOpacityAtom,
  blockShadowVisibleAtom,
  blockShapeColorAtom,
  blockStrokeColorAtom,
  blockStrokeEnabledAtom,
  blockStrokeWidthAtom,
} from 'State'
import { BlockConfigType } from 'Typings'

// wraps Konva's Circle with recoil related values to user can supply only a block config if needed
export const RecoilCircle: React.FC<{
  config: BlockConfigType
  ref?: React.RefObject<CircleType>
  name?: string
  radius: number
  x?: number
  y?: number
  listening?: boolean
  fill?: string
  shadowEnabled?: boolean
}> = ({
  config,
  name,
  radius,
  ref,
  x,
  y,
  listening = true,
  fill,
  shadowEnabled,
}) => {
  const enableShadow = useRecoilValue(blockShadowVisibleAtom(config))
  const shadowColor = useRecoilValue(blockShadowColorAtom(config))
  const shadowBlur = useRecoilValue(blockShadowBlurAtom(config))
  const shadowOpacity = useRecoilValue(blockShadowOpacityAtom(config))
  const shadowOffsetX = useRecoilValue(blockShadowOffsetXAtom(config))
  const shadowOffsetY = useRecoilValue(blockShadowOffsetYAtom(config))

  const strokeEnabled = useRecoilValue(blockStrokeEnabledAtom(config))
  const strokeColor = useRecoilValue(blockStrokeColorAtom(config))
  const strokeWidth = useRecoilValue(blockStrokeWidthAtom(config))

  const color = useRecoilValue(blockShapeColorAtom(config))

  const enabledShadow =
    shadowEnabled !== undefined ? shadowEnabled : enableShadow
  return (
    <Circle
      ref={ref}
      name={name}
      radius={radius}
      x={x}
      y={y}
      fill={fill || color}
      shadowEnabled={enabledShadow}
      shadowColor={shadowColor}
      shadowBlur={shadowBlur}
      shadowOpacity={shadowOpacity}
      shadowOffsetX={shadowOffsetX}
      shadowOffsetY={shadowOffsetY}
      strokeEnabled={strokeEnabled}
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      listening={listening}
    />
  )
}
