/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1PoliticalFields } from '../models/V1PoliticalFields';
import { V1PoliticalTransparency } from '../models/V1PoliticalTransparency';
import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceSetCampaignPoliticalFormRequest {
    'orgId'?: string;
    'politicalTransparency'?: V1PoliticalTransparency;
    'politicalFields'?: V1PoliticalFields;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "politicalTransparency",
            "baseName": "political_transparency",
            "type": "V1PoliticalTransparency",
            "format": ""
        },
        {
            "name": "politicalFields",
            "baseName": "political_fields",
            "type": "V1PoliticalFields",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceSetCampaignPoliticalFormRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

