import { useState, useEffect } from 'react'
import {
  Creativeservicev1Creative,
  Campaignservicev1OrderLine,
  V1StatsResponse,
} from 'next-gen-sdk'
import { Text, Modal, Button, Fa } from '@eltoro-ui/components'
import { CreativeResType } from 'types'
import { breakTextWithDelimiters } from 'Helpers'
import { CreativeStats } from './CreativeStats'
import './CreativesDisplay.scss'
import { ETIcon } from 'Assets'
/* import { getStats } f rom 'requests' */

const DetailsImage = ({
  close,
  image,
  // orderline,
  creativeStats,
}: {
  close: () => void
  image: Creativeservicev1Creative
  orderline: Campaignservicev1OrderLine
  creativeStats: V1StatsResponse
}) => {
  const { results } = creativeStats

  const singleCreative = results?.filter((currentStat) => {
    return currentStat.details?.ids?.creativeId === image.id
  })
  const { files, type, name } = image
  const imageFile = files ? files[0] : undefined
  const { width, height, bucket, key } = imageFile || {}
  const url = `https://s3.amazonaws.com/${bucket}/${key}`

  if (
    !singleCreative ||
    singleCreative.length === 0 ||
    !singleCreative[0].results
  ) {
    return <>No stats available</>
  }
  const getCTR = (imps: number, clicks: number) => {
    const ctr = (Number(clicks) / Number(imps)) * 100
    return Number.isNaN(ctr) || ctr === Infinity ? '0' : `${ctr.toFixed(2)}%`
  }
  const creativeData = {
    clicks: {
      label: 'Clicks',
      value: Number(singleCreative[0].results[0]?.clicks),
    },
    imps: {
      label: 'Imps',
      value: Number(singleCreative[0].results[0]?.imps),
    },
    CTR: {
      label: 'CTR',
      value: getCTR(
        singleCreative[0].results[0]?.imps || 0,
        singleCreative[0].results[0]?.clicks || 0,
      ),
    },
  }

  return (
    <div className="DetailsImage">
      <div className="header-DetailsImage relative">
        <div className="text-header">
          <Text className="title-text">{name}</Text>
          <Text className="details-text">
            {(type || '').charAt(0).toUpperCase() +
              (type || '').substring(1).replace(/_/g, ' ')}
            {width && height && ` - ${width} x ${height}`}
          </Text>
        </div>
        <Button
          className="DetailsImage__close-button absolute top-0 right-0"
          onClick={close}
          iconOnly={<Fa icon="xmark" size={1} />}
        />
      </div>
      <div className="imageContainer-DetailsImage">
        {type === 'CREATIVE_TYPE_BANNER' ||
        type === 'CREATIVE_TYPE_NATIVE_BANNER' ? (
          <img
            className="imageContainer-DetailsImage--img"
            alt="thumbnail"
            src={url}
            height={height}
            width={width}
          />
        ) : type === 'CREATIVE_TYPE_OTT' || type === 'CREATIVE_TYPE_VIDEO' ? (
          <video className="img-video isPDF" src={`${url}`} />
        ) : (
          <ETIcon icon="campaign" className="!text-primary" />
        )}
      </div>
      {/* {loading && image.type !== 'direct_mail' && (
          <div className="loading-DetailsImage">loading</div>
          )} */}
      {creativeStats === undefined ? null : (
        <div className="CreativeStatistics__wrap flex flex-col gap-1">
          <span className="title-column">Creative statistics</span>
          {creativeData && <CreativeStats statsData={creativeData} />}
        </div>
      )}
    </div>
  )
}

const ImageButton = ({
  image,
  orderline,
  creativeStats,
}: {
  image: Creativeservicev1Creative
  orderline: Campaignservicev1OrderLine
  creativeStats: V1StatsResponse
}) => {
  const [showDetail, setShowDetail] = useState(false)
  const { files } = image
  const imageFile = files ? files[0] : undefined
  const { width, height, bucket, key } = imageFile || {}
  const url = `https://s3.amazonaws.com/${bucket}/${key}`

  const singleCreative = creativeStats.results?.filter((currentStat) => {
    return currentStat.details?.ids?.creativeId === image.id
  })

  const NoStats =
    !singleCreative || singleCreative.length === 0 || !singleCreative[0].results

  const random = Math.random().toString()
  return (
    <>
      <button
        type="button"
        className="ImageButton"
        disabled={NoStats}
        onClick={() => {
          if (NoStats) return
          setShowDetail(true)
        }}
      >
        <figure className="image-ImageButton !h-auto">
          {image.type === 'CREATIVE_TYPE_BANNER' ||
          image.type === 'CREATIVE_TYPE_NATIVE_BANNER' ? (
            <img
              className="mx-auto object-fill"
              alt="thumbnail"
              src={`${url}?random=${random}`}
              height={height}
              width={width}
            />
          ) : image.type === 'CREATIVE_TYPE_VIDEO' ||
            image.type === 'CREATIVE_TYPE_OTT' ? (
            <video
              className="CreativesDisplay--video img-video"
              src={`${url}?random=${random}`}
            />
          ) : (
            <ETIcon icon="campaign" className="!text-primary" />
          )}
        </figure>
        <figcaption className="title-ImageButton flex flex-col gap-1 break-words text-center">
          <span className="!text-text title-ImageButton mx-auto w-20 break-words text-center">
            {breakTextWithDelimiters(image?.name as string, 12)}
          </span>
          <span className="!text-text dimensions-ImageButton text-center">
            {width && height && `${width} x ${height}`}
          </span>
        </figcaption>
      </button>
      {showDetail ? (
        <Modal
          className="DetailsImage__modal"
          offClick={() => setShowDetail(false)}
        >
          <DetailsImage
            close={() => setShowDetail(false)}
            image={image}
            orderline={orderline}
            creativeStats={creativeStats}
          />
        </Modal>
      ) : null}
    </>
  )
}

export const CreativesDisplay = ({
  orderline,
  creatives,
  creativeStats,
  isPDF = false,
}: {
  orderline: Campaignservicev1OrderLine
  creatives: CreativeResType[]
  creativeStats: V1StatsResponse
  isPDF: boolean
  sortedStats: unknown // accepts any value but TS enforces type check; safer typing
}) => {
  /* type CreativeSearchParamsType = {
   *   search: string
   * } */

  /* const [
   *   creativeSearchParams,
   *   setCreativeSearchParams,
   * ] = useState<CreativeSearchParamsType>({
   *   search: '',
   * }) */

  /* const updateCreativeSearchParams = (param: string, value: string) => {
   *   setCreativeSearchParams((prevSearchParams) => ({
   *     ...prevSearchParams,
   *     [param]: value,
   *   }))
   * } */

  const [creativeResults, setCreativeResults] = useState<CreativeResType[]>()

  useEffect(() => {
    setCreativeResults(
      creatives.filter((creative: CreativeResType) => creative.creative),
    )
    /* if (creatives && creativeSearchParams.search.length >= 0) {
     *     const filteredCreatives = creatives.filter(
     *         (data: CreativeResType) =>
     *             data.creative.name
     *                 .toLowerCase()
     *                 .includes(creativeSearchParams.search.toLowerCase()),
     *     )
     *     setCreativeResults(filteredCreatives)
     * } */
  }, [
    /* creativeSearchParams, */
    orderline,
    creatives,
  ])

  return (
    <div
      className={`CreativesDisplay CampaignCreatives ${
        isPDF ? 'isPDF' : 'isOnline'
      }`}
    >
      <div className="CreativesDisplay__header header-CampaignCreatives">
        Creatives
      </div>
      <div className="CreativesDisplay__gallery gallery-CampaignCreatives gap-4">
        {creatives !== undefined ? (
          creativeResults?.map((creative: CreativeResType, index: number) => {
            const { creative: crt } = creative
            const { id } = crt || {}
            return (
              <ImageButton
                key={id || 0 + index}
                image={creative.creative || {}}
                orderline={orderline}
                creativeStats={creativeStats}
              />
            )
          })
        ) : (
          <Text>No attached creatives</Text>
        )}
      </div>
    </div>
  )
}
