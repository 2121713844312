/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Collection of values used to verify a user
*/
export class V1CredentialRepresentation {
    /**
    * Date and Time the credential was created
    */
    'createTime'?: Date;
    /**
    * Indicates whether the credentials are temporary
    */
    'temporary'?: boolean;
    /**
    * Type of Credential
    */
    'type'?: string;
    /**
    * Value assigned to the credential
    */
    'value'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "temporary",
            "baseName": "temporary",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "value",
            "baseName": "value",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1CredentialRepresentation.attributeTypeMap;
    }

    public constructor() {
    }
}

