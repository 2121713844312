/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Daypart Targets] https://docs.xandr.com/bundle/xandr-api/page/profile-service.html#ProfileService-DaypartTargets in Xandr's documentation.
*/
export class V3DaypartTarget {
    /**
    * The day of the week. Possible values: sunday, monday, tuesday, wednesday, thursday, friday, saturday, or all. Note that these strings must be in lower case.
    */
    'day'?: string;
    /**
    * The start hour for the daypart. This must be an integer between 0 and 23. The campaign will start serving at the beginning of the hour (6 is equivalent to 6:00 am).
    */
    'startHour'?: number;
    /**
    * The end hour for the daypart. This must be an integer between 0 and 23. The campaign will stop serving at the end of the hour (23 is equivalent to 23:59).
    */
    'endHour'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "day",
            "baseName": "day",
            "type": "string",
            "format": ""
        },
        {
            "name": "startHour",
            "baseName": "start_hour",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "endHour",
            "baseName": "end_hour",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3DaypartTarget.attributeTypeMap;
    }

    public constructor() {
    }
}

