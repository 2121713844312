import React, { FC } from 'react'
import './Card.scss'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /** the children of the component */
  children?: React.ReactNode
  /** does card has rounded, shadow, default to yes */
  className?: string | undefined
}

export const Card: FC<CardProps> = ({
  children,
  className = 'Basic',
  ...other
}: CardProps) => {
  return (
    <>
      <div className={`Card Card${className} ${className}`}>{children}</div>
    </>
  )
}
