import { useState } from 'react'
import { ConfirmOLChangeModal, PageContent } from 'Components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useEffect } from 'react'
import { useAppContext } from 'Contexts'

export const TestPage = () => {
  const [open, setOpen] = useState(true)
  const [orderLine, setOrderLine] = useState<Campaignservicev1OrderLine>()
  const { campaignServiceApi } = useAppContext()
  useEffect(() => {
    campaignServiceApi
      ?.advertisingPlatformServiceListOrderLines(1)
      .then((res) => setOrderLine(res?.orderLines?.[0]))
  }, [])
  return (
    <PageContent className="mt-10">
      <button onClick={() => setOpen((prev) => !prev)}>
        open change model
      </button>
      {open && orderLine && (
        <ConfirmOLChangeModal
          setOpen={setOpen}
          orderLine={orderLine}
          additionalCost={2000}
          // eslint-disable-next-line
          onSubmit={() => console.log('submitted')}
        />
      )}
    </PageContent>
  )
}
