import { Fa } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import {
  userFriendlyAudienceName,
  readableCreativeType,
  getCreativeFaETIcons,
  getTargetIconET,
} from 'Helpers'
import { Creativeservicev1Type } from 'next-gen-sdk'
import { Card } from '../../Components'
import { DashStatsType } from './DashboardPage'

export const DashboardCards = ({ stats }: { stats: DashStatsType }) => {
  const dashboardStats = [
    {
      id: '2',
      header: stats.OL_completed,
      text: 'Order Lines Completed',
      icon: <Fa className="text-success-400" icon="check-circle" size={4} />,
      type: 'OrderLinesCompleted',
    },
    {
      id: '3',
      header: stats.OL_serving,
      text: 'Active Order Lines',
      icon: <Fa className="text-secondary-400" icon="play-circle" size={4} />,
      type: 'OrderLinesServed',
    },
    {
      id: '4',
      header: stats.OL_review_started,
      text: 'Order Lines in Review',
      icon: <Fa icon="user-gear" size={4} type="duotone" />,
      type: 'OrderLinesReview',
    },
    {
      id: '5',
      header: stats.OL_drafts,
      text: 'Order Lines in Draft',
      icon: <Fa className="text-warning-500" icon="edit" size={4} />,
      type: 'OrderLinesDraft',
    },
    {
      id: '6',
      header: stats.most_used_creative_type
        ? readableCreativeType(
            stats.most_used_creative_type as Creativeservicev1Type,
          )
        : 'Unknown',
      text: 'Most used creative type',
      icon: getCreativeFaETIcons(
        stats.most_used_creative_type as Creativeservicev1Type,
      ),
      type: 'Creative',
    },
    {
      id: '7',
      header: stats.most_used_audience_type
        ? userFriendlyAudienceName(stats.most_used_audience_type, true)
        : 'Unknown',
      text: 'Most used audience type',
      icon: (
        <ETIcon icon={getTargetIconET(stats.most_used_audience_type || '')} />
      ),
      type: 'Audience',
    },
  ]
  return (
    <div className="DashboardCard DashboardCard__row mt-4">
      {dashboardStats.map(({ id, header, text, icon, type }) => (
        <Card key={id} className={`Basic ${type}`}>
          <div className="DashboardCard__content">
            <div className="DashboardCard__icon">{icon}</div>
            <div className="DashboardCard__text-body">
              <h2 className="DashboardCard__header">{header}</h2>
              <p className="DashboardCard__text">{text}</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  )
}
