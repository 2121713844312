import { Button, Fa } from '@eltoro-ui/components'

type GBProps = {
  className?: string
  classWrap?: string
  onClick: () => void
  position?: 'top-right' | 'bottom-right' | 'bottom-left'
  type?: 'button' | 'submit' | undefined
}

export const GoBackButton = ({
  className = '',
  classWrap = '',
  onClick,
  position = 'top-right',
  type = 'button',
}: GBProps) => {
  const getPositionClass = () => {
    switch (position) {
      case 'top-right':
        return 'absolute right-0 top-0'
      case 'bottom-right':
        return 'absolute right-0 bottom-0'
      case 'bottom-left':
        return 'absolute left-0 bottom-0'
      default:
        return ''
    }
  }

  return (
    <div
      className={`GoBackButton__wrap ${classWrap} ${getPositionClass()} z-20`}
    >
      <span
        className="GoBackButton"
        data-tooltip="left"
        aria-label="Go back to previous view"
      >
        <Button
          type={type}
          className={`GoBackButton__btn ${className}`}
          kind="primary"
          iconLeft={
            <Fa icon="turn-down-left" size={1} className="-ml-1 scale-90" />
          }
          onClick={onClick}
        >
          Back
        </Button>
      </span>
    </div>
  )
}
