import React, { useState, useEffect } from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useAuth } from 'react-oidc-context'
import { getAPIDocsPage } from 'Requests'
import { Loading } from 'Components'

export const InternalAPIDocsPage = () => {
  const [docsSpec, setDocsSpec] = useState('')

  const auth = useAuth()
  useEffect(() => {
    if (auth.user?.access_token) {
      getAPIDocsPage(auth.user?.access_token, true).then((res) => {
        setDocsSpec(res)
      })
    }
  }, [auth.user?.access_token])

  return (
    <div className="InternalAPIDocsPage flex h-full w-full flex-col">
      {docsSpec ? (
        // @ts-ignore
        <SwaggerUI spec={docsSpec} />
      ) : (
        <Loading className="h-[84vh]" />
      )}
    </div>
  )
}
