export * from '../models/AddAudienceRequest';
export * from '../models/AddCreativeRequest';
export * from '../models/AddOrderLineRequest';
export * from '../models/AdvertisingPlatformServiceAddAccountRepRequest';
export * from '../models/AdvertisingPlatformServiceAddCommissionGroupRequest';
export * from '../models/AdvertisingPlatformServiceAddSalesRepRequest';
export * from '../models/AdvertisingPlatformServiceAddUserRequest';
export * from '../models/AdvertisingPlatformServiceAppendOrderLineNoteRequest';
export * from '../models/AdvertisingPlatformServiceBatchAddAudiencesRequest';
export * from '../models/AdvertisingPlatformServiceBatchAddCreativesRequest';
export * from '../models/AdvertisingPlatformServiceBatchRemoveAudiencesRequest';
export * from '../models/AdvertisingPlatformServiceBatchRemoveCreativesRequest';
export * from '../models/AdvertisingPlatformServiceCloneOrderLineRequest';
export * from '../models/AdvertisingPlatformServiceCreateOrgNoteRequest';
export * from '../models/AdvertisingPlatformServiceDeactivateOrderLineNoteRequest';
export * from '../models/AdvertisingPlatformServiceRemoveUserRequest';
export * from '../models/AdvertisingPlatformServiceRunOneSubscriptionRequest';
export * from '../models/AdvertisingPlatformServiceSetCampaignPoliticalFormRequest';
export * from '../models/AdvertisingPlatformServiceUpdateFTPRequest';
export * from '../models/AdvertisingPlatformServiceUpdateOrderLineNoteRequest';
export * from '../models/AdvertisingPlatformServiceUpdatePixelRequest';
export * from '../models/AdvertisingPlatformServiceUpdatePublisherRequest';
export * from '../models/AdvertisingPlatformServiceUpdateRuleRequest';
export * from '../models/AdvertisingPlatformServiceUpdateRuleSetRequest';
export * from '../models/AdvertisingPlatformServiceUpdateSubscriptionRequest';
export * from '../models/ApproveOrderLineRequest';
export * from '../models/Apqsv1Checks';
export * from '../models/Apqsv1Dependency';
export * from '../models/Apqsv1HealthCheck';
export * from '../models/Apqsv1HealthCondition';
export * from '../models/Apqsv1Miscellanea';
export * from '../models/Apqsv1SystemStat';
export * from '../models/Billingservicev1Checks';
export * from '../models/Billingservicev1CommissionGroup';
export * from '../models/Billingservicev1Dependency';
export * from '../models/Billingservicev1HealthCheck';
export * from '../models/Billingservicev1HealthCondition';
export * from '../models/Billingservicev1ListSubscriptionsResponse';
export * from '../models/Billingservicev1Miscellanea';
export * from '../models/Billingservicev1Org';
export * from '../models/Billingservicev1Subscription';
export * from '../models/Billingservicev1SystemStat';
export * from '../models/CPMContainsInformationRelatingToAClientCpmanization';
export * from '../models/Campaignservicev1AdType';
export * from '../models/Campaignservicev1Audience';
export * from '../models/Campaignservicev1CPM';
export * from '../models/Campaignservicev1Campaign';
export * from '../models/Campaignservicev1Checks';
export * from '../models/Campaignservicev1Creative';
export * from '../models/Campaignservicev1Dependency';
export * from '../models/Campaignservicev1HealthCheck';
export * from '../models/Campaignservicev1HealthCondition';
export * from '../models/Campaignservicev1ListCampaignsResponse';
export * from '../models/Campaignservicev1Miscellanea';
export * from '../models/Campaignservicev1Note';
export * from '../models/Campaignservicev1NoteType';
export * from '../models/Campaignservicev1OrderLine';
export * from '../models/Campaignservicev1Status';
export * from '../models/Campaignservicev1SystemStat';
export * from '../models/CancelOrderLineDeploymentRequest';
export * from '../models/Contact';
export * from '../models/CreateCreditCardRequest';
export * from '../models/CreateDeploymentRequest';
export * from '../models/Creativeservicev1AdType';
export * from '../models/Creativeservicev1Audit';
export * from '../models/Creativeservicev1AuditResult';
export * from '../models/Creativeservicev1AuditStatus';
export * from '../models/Creativeservicev1Checks';
export * from '../models/Creativeservicev1Creative';
export * from '../models/Creativeservicev1Dependency';
export * from '../models/Creativeservicev1DeploymentType';
export * from '../models/Creativeservicev1Destination';
export * from '../models/Creativeservicev1FileType';
export * from '../models/Creativeservicev1FolderStats';
export * from '../models/Creativeservicev1HealthCheck';
export * from '../models/Creativeservicev1HealthCondition';
export * from '../models/Creativeservicev1ListCreativesResponse';
export * from '../models/Creativeservicev1Miscellanea';
export * from '../models/Creativeservicev1NativeMetadata';
export * from '../models/Creativeservicev1OrderLine';
export * from '../models/Creativeservicev1Status';
export * from '../models/Creativeservicev1SystemStat';
export * from '../models/Creativeservicev1Type';
export * from '../models/CreditCard';
export * from '../models/CreditCard1';
export * from '../models/DeployOrderLineRequest';
export * from '../models/Deployservicev3Audit';
export * from '../models/Deployservicev3AuditResult';
export * from '../models/Deployservicev3AuditStatus';
export * from '../models/Deployservicev3Campaign';
export * from '../models/Deployservicev3Checks';
export * from '../models/Deployservicev3Creative';
export * from '../models/Deployservicev3CreativeID';
export * from '../models/Deployservicev3Dependency';
export * from '../models/Deployservicev3Deployment';
export * from '../models/Deployservicev3DeploymentType';
export * from '../models/Deployservicev3Destination';
export * from '../models/Deployservicev3HealthCheck';
export * from '../models/Deployservicev3HealthCondition';
export * from '../models/Deployservicev3ListCampaignsResponse';
export * from '../models/Deployservicev3ListCreativesResponse';
export * from '../models/Deployservicev3Miscellanea';
export * from '../models/Deployservicev3NativeMetadata';
export * from '../models/Deployservicev3Status';
export * from '../models/Deployservicev3SystemStat';
export * from '../models/Deployservicev3Value';
export * from '../models/ExistingUserWithNewFieldValues';
export * from '../models/GetDefaultTemplateRequest';
export * from '../models/GooglerpcStatus';
export * from '../models/GoogletypeMoney';
export * from '../models/Hagridv1Checks';
export * from '../models/Hagridv1Dependency';
export * from '../models/Hagridv1FileMetaData';
export * from '../models/Hagridv1HealthCheck';
export * from '../models/Hagridv1HealthCondition';
export * from '../models/Hagridv1ListSubscriptionsResponse';
export * from '../models/Hagridv1Miscellanea';
export * from '../models/Hagridv1Subscription';
export * from '../models/Hagridv1SystemStat';
export * from '../models/Hagridv1User';
export * from '../models/Job';
export * from '../models/LockOrderLineRequest';
export * from '../models/Notification';
export * from '../models/Notificationservicev1Checks';
export * from '../models/Notificationservicev1Dependency';
export * from '../models/Notificationservicev1HealthCheck';
export * from '../models/Notificationservicev1HealthCondition';
export * from '../models/Notificationservicev1Miscellanea';
export * from '../models/Notificationservicev1SystemStat';
export * from '../models/Notificationservicev1Type';
export * from '../models/OrderLineViantAdelphicIDsSetAudienceID';
export * from '../models/Orgmanagerv1CPM';
export * from '../models/Orgmanagerv1Checks';
export * from '../models/Orgmanagerv1CommissionGroup';
export * from '../models/Orgmanagerv1Contact';
export * from '../models/Orgmanagerv1Dependency';
export * from '../models/Orgmanagerv1HealthCheck';
export * from '../models/Orgmanagerv1HealthCondition';
export * from '../models/Orgmanagerv1Miscellanea';
export * from '../models/Orgmanagerv1Note';
export * from '../models/Orgmanagerv1NoteType';
export * from '../models/Orgmanagerv1Org';
export * from '../models/Orgmanagerv1Status';
export * from '../models/Orgmanagerv1SystemStat';
export * from '../models/Orgmanagerv1User';
export * from '../models/PauseOrderLineRequest';
export * from '../models/PayInvoiceRequest';
export * from '../models/PaySetUpFeeRequest';
export * from '../models/PlayOrderLineRequest';
export * from '../models/PrepayRequest';
export * from '../models/ProtobufAny';
export * from '../models/RejectOrderLineRequest';
export * from '../models/RemoveAudienceRequest';
export * from '../models/RemoveCreativeRequest';
export * from '../models/RemoveOrderLineRequest';
export * from '../models/RequestReviewRequest';
export * from '../models/StartReviewRequest';
export * from '../models/StatsRequestWillHoldThePOSTRequestVars';
export * from '../models/StreamResultOfV1DownloadAudienceFileResponse';
export * from '../models/StreamResultOfV1DownloadBundleResponse';
export * from '../models/StreamResultOfV1DownloadCreativeFileResponse';
export * from '../models/StreamResultOfV1DownloadTOSAgreementResponse';
export * from '../models/StreamResultOfV2DownloadTargetFileResponse';
export * from '../models/Targetjobservicev1Audience';
export * from '../models/Targetjobservicev1Checks';
export * from '../models/Targetjobservicev1Column';
export * from '../models/Targetjobservicev1Dependency';
export * from '../models/Targetjobservicev1Deployment';
export * from '../models/Targetjobservicev1FolderStats';
export * from '../models/Targetjobservicev1HealthCheck';
export * from '../models/Targetjobservicev1HealthCondition';
export * from '../models/Targetjobservicev1Miscellanea';
export * from '../models/Targetjobservicev1Note';
export * from '../models/Targetjobservicev1NoteType';
export * from '../models/Targetjobservicev1SystemStat';
export * from '../models/Targetjobservicev1Timeframe';
export * from '../models/Targetorchestratorv2Checks';
export * from '../models/Targetorchestratorv2Column';
export * from '../models/Targetorchestratorv2Dependency';
export * from '../models/Targetorchestratorv2FileMetaData';
export * from '../models/Targetorchestratorv2FileType';
export * from '../models/Targetorchestratorv2FolderStats';
export * from '../models/Targetorchestratorv2HealthCheck';
export * from '../models/Targetorchestratorv2HealthCondition';
export * from '../models/Targetorchestratorv2Location';
export * from '../models/Targetorchestratorv2Miscellanea';
export * from '../models/Targetorchestratorv2SystemStat';
export * from '../models/TheAdvertiserToCreate';
export * from '../models/TheAudienceToUpdate';
export * from '../models/TheCampaignToCreate';
export * from '../models/TheCampaignToUpdate';
export * from '../models/TheCreativeToCreate';
export * from '../models/TheCreativeToUpdate';
export * from '../models/TheDeploymentToCreate';
export * from '../models/TheNoteToUpdate';
export * from '../models/TheOrderLineToUpdate';
export * from '../models/TheOrganizationToUpdate';
export * from '../models/TheSegmentToCreate';
export * from '../models/TheSubscriptionToUpdate';
export * from '../models/TheTargetToUpdate';
export * from '../models/TypeDate';
export * from '../models/UnlockOrderLineRequest';
export * from '../models/UpdateCPMsRequest';
export * from '../models/UpdateDeploymentRequest';
export * from '../models/V1AccountingJob';
export * from '../models/V1AccountingJobKind';
export * from '../models/V1AdminListNotificationsResponse';
export * from '../models/V1ArchiveCampaignRequest';
export * from '../models/V1ArchiveCreativeRequest';
export * from '../models/V1ArchiveOrderLineRequest';
export * from '../models/V1Assignment';
export * from '../models/V1Attachment';
export * from '../models/V1AudienceAdded';
export * from '../models/V1AudienceFileType';
export * from '../models/V1AudienceRemoved';
export * from '../models/V1AudienceStatus';
export * from '../models/V1AudienceSubType';
export * from '../models/V1AudienceType';
export * from '../models/V1AuditConditions';
export * from '../models/V1Authorization';
export * from '../models/V1Balance';
export * from '../models/V1BatchAddAudiencesRequestAudience';
export * from '../models/V1BatchUpdateOrderLinesRequest';
export * from '../models/V1BatchUpdateOrderLinesResponse';
export * from '../models/V1BundleRequest';
export * from '../models/V1CampaignArchived';
export * from '../models/V1CampaignCreated';
export * from '../models/V1CampaignDeleted';
export * from '../models/V1CampaignEvent';
export * from '../models/V1CampaignFlightDatesSet';
export * from '../models/V1CampaignJobIdSet';
export * from '../models/V1CampaignNameSet';
export * from '../models/V1CampaignOrderLine';
export * from '../models/V1CampaignOrderLineUpdated';
export * from '../models/V1CampaignPoIdSet';
export * from '../models/V1CampaignPoliticalTransparencySet';
export * from '../models/V1CampaignRefIDSet';
export * from '../models/V1CampaignSearch';
export * from '../models/V1CampaignUnarchived';
export * from '../models/V1CancelAudienceGenerationRequest';
export * from '../models/V1CancelSubscriptionRequest';
export * from '../models/V1ClientRoles';
export * from '../models/V1CloneAudienceRequest';
export * from '../models/V1CommissionType';
export * from '../models/V1ComponentResult';
export * from '../models/V1ComponentResults';
export * from '../models/V1CostRange';
export * from '../models/V1CreativeAdded';
export * from '../models/V1CreativeFile';
export * from '../models/V1CreativeFileMetadata';
export * from '../models/V1CreativeRemoved';
export * from '../models/V1CreativeSearch';
export * from '../models/V1CredentialRepresentation';
export * from '../models/V1CreditCard';
export * from '../models/V1DataSource';
export * from '../models/V1DeploymentCreated';
export * from '../models/V1DeprecatedTargetJob';
export * from '../models/V1DeregisterOrgFromSyncResponse';
export * from '../models/V1DownloadAudienceFileResponse';
export * from '../models/V1DownloadBundleResponse';
export * from '../models/V1DownloadCreativeFileResponse';
export * from '../models/V1DownloadTOSAgreementResponse';
export * from '../models/V1FTP';
export * from '../models/V1FileSubType';
export * from '../models/V1GetMigrationEstimateResponse';
export * from '../models/V1GetMigrationResponse';
export * from '../models/V1GetNewMoverMatchCountResponse';
export * from '../models/V1GetNotificationsResponse';
export * from '../models/V1GetOrderLineCPMsResponse';
export * from '../models/V1GetOrgsRegisteredForSyncResponse';
export * from '../models/V1GetTemplateResponse';
export * from '../models/V1GetUserIPResponse';
export * from '../models/V1GlobalSearchResponse';
export * from '../models/V1Invoice';
export * from '../models/V1InvoiceStatus';
export * from '../models/V1JWT';
export * from '../models/V1Job';
export * from '../models/V1JobStatus';
export * from '../models/V1JobSubType';
export * from '../models/V1JobType';
export * from '../models/V1ListAccountingJobsResponse';
export * from '../models/V1ListAudienceFoldersResponse';
export * from '../models/V1ListAudiencesResponse';
export * from '../models/V1ListCampaignEventsResponse';
export * from '../models/V1ListCommissionGroupsResponse';
export * from '../models/V1ListCreativeFoldersResponse';
export * from '../models/V1ListCreditCardsResponse';
export * from '../models/V1ListInvoicesResponse';
export * from '../models/V1ListOfString';
export * from '../models/V1ListOrderLineEventsResponse';
export * from '../models/V1ListOrderLinesResponse';
export * from '../models/V1ListOrgSubscriptionsResponse';
export * from '../models/V1ListOrgUsersResponse';
export * from '../models/V1ListOrgsResponse';
export * from '../models/V1ListPixelsResponse';
export * from '../models/V1ListPublishersResponse';
export * from '../models/V1ListRulesResponse';
export * from '../models/V1ListStatsResponse';
export * from '../models/V1ListTOSAgreementsResponse';
export * from '../models/V1ListUsersResponse';
export * from '../models/V1MainDetails';
export * from '../models/V1Medium';
export * from '../models/V1Message';
export * from '../models/V1MessageType';
export * from '../models/V1Metrics';
export * from '../models/V1MigrateRequest';
export * from '../models/V1MigrateResponse';
export * from '../models/V1MigrationEstimate';
export * from '../models/V1MigrationSummary';
export * from '../models/V1MoverMatchCount';
export * from '../models/V1Notification';
export * from '../models/V1OAuthTokenRequest';
export * from '../models/V1OrderLineAdTypeSet';
export * from '../models/V1OrderLineAdded';
export * from '../models/V1OrderLineApproved';
export * from '../models/V1OrderLineArchived';
export * from '../models/V1OrderLineAttachedToCampaign';
export * from '../models/V1OrderLineAudienceSet';
export * from '../models/V1OrderLineAuditConditionsSet';
export * from '../models/V1OrderLineCPMSet';
export * from '../models/V1OrderLineCampaign';
export * from '../models/V1OrderLineCampaignAttached';
export * from '../models/V1OrderLineCampaignDetached';
export * from '../models/V1OrderLineCampaignSet';
export * from '../models/V1OrderLineClickThroughURLSet';
export * from '../models/V1OrderLineCloned';
export * from '../models/V1OrderLineCompleted';
export * from '../models/V1OrderLineConfigured';
export * from '../models/V1OrderLineConsoleCampaignSet';
export * from '../models/V1OrderLineConsoleClickThroughURLSet';
export * from '../models/V1OrderLineConsoleCreativesSet';
export * from '../models/V1OrderLineConsoleSegmentsSet';
export * from '../models/V1OrderLineCostSet';
export * from '../models/V1OrderLineCreated';
export * from '../models/V1OrderLineCreating';
export * from '../models/V1OrderLineCreativeAttached';
export * from '../models/V1OrderLineCreativeDetached';
export * from '../models/V1OrderLineCreativeSet';
export * from '../models/V1OrderLineDeleted';
export * from '../models/V1OrderLineDeployRetried';
export * from '../models/V1OrderLineDeployed';
export * from '../models/V1OrderLineDeployedToDestinationXandrCurate';
export * from '../models/V1OrderLineDeployedToDestinationXandrInvest';
export * from '../models/V1OrderLineDeployedWithConfig';
export * from '../models/V1OrderLineDeploymentCancelled';
export * from '../models/V1OrderLineDeploymentCancelling';
export * from '../models/V1OrderLineDeploymentDestinationConfigurationSet';
export * from '../models/V1OrderLineDeploymentDestinationSet';
export * from '../models/V1OrderLineDestinationXandrConsoleCreated';
export * from '../models/V1OrderLineDestinationXandrCurateCreated';
export * from '../models/V1OrderLineDestinationXandrCurateSet';
export * from '../models/V1OrderLineDestinationXandrInvestCreated';
export * from '../models/V1OrderLineDestinationXandrInvestSet';
export * from '../models/V1OrderLineDetachedFromCampaign';
export * from '../models/V1OrderLineEndTimeSet';
export * from '../models/V1OrderLineErroredDeployingToDestinationXandrCurate';
export * from '../models/V1OrderLineErroredDeployingToDestinationXandrInvest';
export * from '../models/V1OrderLineEvent';
export * from '../models/V1OrderLineFlightDatesSet';
export * from '../models/V1OrderLineFreeImpressionsSet';
export * from '../models/V1OrderLineImpressionsPerDaySet';
export * from '../models/V1OrderLineImpressionsSet';
export * from '../models/V1OrderLineInvestCampaignSet';
export * from '../models/V1OrderLineInvestClickThroughURLSet';
export * from '../models/V1OrderLineInvestCreativesSet';
export * from '../models/V1OrderLineInvestSegmentsSet';
export * from '../models/V1OrderLineJobIdSet';
export * from '../models/V1OrderLineLocked';
export * from '../models/V1OrderLineMinimumImpressionsSet';
export * from '../models/V1OrderLineNameSet';
export * from '../models/V1OrderLineNoteAppended';
export * from '../models/V1OrderLineNoteDeactivated';
export * from '../models/V1OrderLineNoteUpdated';
export * from '../models/V1OrderLineOrgIdSet';
export * from '../models/V1OrderLinePaidSet';
export * from '../models/V1OrderLinePaused';
export * from '../models/V1OrderLinePlayed';
export * from '../models/V1OrderLinePoIdSet';
export * from '../models/V1OrderLinePoliticalFieldsSet';
export * from '../models/V1OrderLinePoliticalSet';
export * from '../models/V1OrderLinePrepaySet';
export * from '../models/V1OrderLineRefIdSet';
export * from '../models/V1OrderLineRejected';
export * from '../models/V1OrderLineRemoved';
export * from '../models/V1OrderLineReviewStarted';
export * from '../models/V1OrderLineSearch';
export * from '../models/V1OrderLineSegmentConfigSet';
export * from '../models/V1OrderLineStartTimeSet';
export * from '../models/V1OrderLineStateSetToActive';
export * from '../models/V1OrderLineStateSetToPaused';
export * from '../models/V1OrderLineStatus';
export * from '../models/V1OrderLineStatusSetToDeploying';
export * from '../models/V1OrderLineStatusSetToDraft';
export * from '../models/V1OrderLineStatusSetToPrevious';
export * from '../models/V1OrderLineStepFunctionSet';
export * from '../models/V1OrderLineSubmittedForReview';
export * from '../models/V1OrderLineTargetJobAttached';
export * from '../models/V1OrderLineTargetJobDetached';
export * from '../models/V1OrderLineTemplateTypeSet';
export * from '../models/V1OrderLineUnarchived';
export * from '../models/V1OrderLineUnlocked';
export * from '../models/V1OrderLineViantAdelphicConfigurationSet';
export * from '../models/V1OrderLineViantAdelphicIDsSet';
export * from '../models/V1OrderLineViantAdelphicIDsSetCreativeID';
export * from '../models/V1OrgData';
export * from '../models/V1OrgLogo';
export * from '../models/V1PayOrderLinesRequest';
export * from '../models/V1Payment';
export * from '../models/V1Pixel';
export * from '../models/V1PoliticalFields';
export * from '../models/V1PoliticalTransparency';
export * from '../models/V1ProductType';
export * from '../models/V1Publisher';
export * from '../models/V1QueueDetails';
export * from '../models/V1QueueHealth';
export * from '../models/V1RebuildGlobalSearchRequest';
export * from '../models/V1RegisterOrgForSyncRequest';
export * from '../models/V1RegisterOrgForSyncResponse';
export * from '../models/V1RequestSupportRequest';
export * from '../models/V1Result';
export * from '../models/V1ResultIDs';
export * from '../models/V1ResultMetrics';
export * from '../models/V1Results';
export * from '../models/V1Review';
export * from '../models/V1ReviewRequested';
export * from '../models/V1ReviewStatus';
export * from '../models/V1Rule';
export * from '../models/V1RuleSet';
export * from '../models/V1RunCommissionsRequest';
export * from '../models/V1RunCommissionsResponse';
export * from '../models/V1RunInvoicingRequest';
export * from '../models/V1RunInvoicingResponse';
export * from '../models/V1SendMessageRequest';
export * from '../models/V1StartSyncResponse';
export * from '../models/V1State';
export * from '../models/V1Stats';
export * from '../models/V1StatsResponse';
export * from '../models/V1SubDetails';
export * from '../models/V1SubscriptionFreeTrialMetadata';
export * from '../models/V1SubscriptionInterval';
export * from '../models/V1SubscriptionMetadata';
export * from '../models/V1SubscriptionPeriodMetadata';
export * from '../models/V1SubscriptionService';
export * from '../models/V1SubscriptionStatus';
export * from '../models/V1SyncOrg';
export * from '../models/V1TOSAgreement';
export * from '../models/V1TargetJobSearch';
export * from '../models/V1TemplateType';
export * from '../models/V1UUID';
export * from '../models/V1UnarchiveCampaignRequest';
export * from '../models/V1UnarchiveCreativeRequest';
export * from '../models/V1UnarchiveOrderLineRequest';
export * from '../models/V1UpdateOrderLineRequest';
export * from '../models/V1UploadCreativeFileRequest';
export * from '../models/V1UploadOrgLogoMetadata';
export * from '../models/V1UploadTOSAgreementRequest';
export * from '../models/V2ArchiveTargetRequest';
export * from '../models/V2CreateJsonTargetRequest';
export * from '../models/V2DownloadTargetFileResponse';
export * from '../models/V2ListTargetFoldersResponse';
export * from '../models/V2ListTargetsResponse';
export * from '../models/V2ReplaceFileMetaData';
export * from '../models/V2ReplaceTargetFileRequest';
export * from '../models/V2Target';
export * from '../models/V2TargetJob';
export * from '../models/V2TargetStatus';
export * from '../models/V2UnarchiveTargetRequest';
export * from '../models/V2UploadTargetFileRequest';
export * from '../models/V3Advertiser';
export * from '../models/V3AugmentedLineItem';
export * from '../models/V3BrowserTarget';
export * from '../models/V3BudgetInterval';
export * from '../models/V3BuyerSeat';
export * from '../models/V3CampaignCreative';
export * from '../models/V3Condition';
export * from '../models/V3ConsoleCampaign';
export * from '../models/V3CountryTarget';
export * from '../models/V3CreativeType';
export * from '../models/V3CurateCampaign';
export * from '../models/V3CustomDateRange';
export * from '../models/V3DaypartTarget';
export * from '../models/V3Deal';
export * from '../models/V3DealList';
export * from '../models/V3DealType';
export * from '../models/V3DeploymentDestinationConfigurations';
export * from '../models/V3DirectMailCampaign';
export * from '../models/V3DirectMailConfiguration';
export * from '../models/V3DomainListTarget';
export * from '../models/V3EngagementRateTarget';
export * from '../models/V3GenericDeployRequest';
export * from '../models/V3GovernmentLevel';
export * from '../models/V3InventoryAttributeTarget';
export * from '../models/V3InventoryListTarget';
export * from '../models/V3InvestCampaign';
export * from '../models/V3Language';
export * from '../models/V3LanguageTarget';
export * from '../models/V3LegacyCampaign';
export * from '../models/V3LineItem';
export * from '../models/V3LineItemDeal';
export * from '../models/V3LineItemPixel';
export * from '../models/V3ListAdvertisersResponse';
export * from '../models/V3ListDeploymentsResponse';
export * from '../models/V3ListSegmentsResponse';
export * from '../models/V3ListXandrDealListsResponse';
export * from '../models/V3ListXandrDealsResponse';
export * from '../models/V3ListXandrLanguagesResponse';
export * from '../models/V3ListXandrRegionsResponse';
export * from '../models/V3MemberDataSharing';
export * from '../models/V3MemberDataSharingSegment';
export * from '../models/V3NonSeamlessInsertionOrder';
export * from '../models/V3PoliticalContent';
export * from '../models/V3Position';
export * from '../models/V3PositionTargets';
export * from '../models/V3Profile';
export * from '../models/V3QueueRequest';
export * from '../models/V3Region';
export * from '../models/V3RegionTarget';
export * from '../models/V3RegistrationForm';
export * from '../models/V3SGSegment';
export * from '../models/V3SeamlessInsertionOrder';
export * from '../models/V3Segment';
export * from '../models/V3SegmentConfig';
export * from '../models/V3SegmentGroupTarget';
export * from '../models/V3SegmentType';
export * from '../models/V3Split';
export * from '../models/V3SupplyStrategies';
export * from '../models/V3UserInfo';
export * from '../models/V3Valuation';
export * from '../models/V3Values';
export * from '../models/V3ViantAdelphicConfiguration';
export * from '../models/V3ViantCreativeConfig';
export * from '../models/V3ViantSegmentConfig';
export * from '../models/V3XandrConsoleConfiguration';
export * from '../models/V3XandrCurateConfiguration';
export * from '../models/V3XandrInvestConfiguration';

import { AddAudienceRequest } from '../models/AddAudienceRequest';
import { AddCreativeRequest } from '../models/AddCreativeRequest';
import { AddOrderLineRequest } from '../models/AddOrderLineRequest';
import { AdvertisingPlatformServiceAddAccountRepRequest } from '../models/AdvertisingPlatformServiceAddAccountRepRequest';
import { AdvertisingPlatformServiceAddCommissionGroupRequest } from '../models/AdvertisingPlatformServiceAddCommissionGroupRequest';
import { AdvertisingPlatformServiceAddSalesRepRequest } from '../models/AdvertisingPlatformServiceAddSalesRepRequest';
import { AdvertisingPlatformServiceAddUserRequest } from '../models/AdvertisingPlatformServiceAddUserRequest';
import { AdvertisingPlatformServiceAppendOrderLineNoteRequest } from '../models/AdvertisingPlatformServiceAppendOrderLineNoteRequest';
import { AdvertisingPlatformServiceBatchAddAudiencesRequest } from '../models/AdvertisingPlatformServiceBatchAddAudiencesRequest';
import { AdvertisingPlatformServiceBatchAddCreativesRequest } from '../models/AdvertisingPlatformServiceBatchAddCreativesRequest';
import { AdvertisingPlatformServiceBatchRemoveAudiencesRequest } from '../models/AdvertisingPlatformServiceBatchRemoveAudiencesRequest';
import { AdvertisingPlatformServiceBatchRemoveCreativesRequest } from '../models/AdvertisingPlatformServiceBatchRemoveCreativesRequest';
import { AdvertisingPlatformServiceCloneOrderLineRequest } from '../models/AdvertisingPlatformServiceCloneOrderLineRequest';
import { AdvertisingPlatformServiceCreateOrgNoteRequest } from '../models/AdvertisingPlatformServiceCreateOrgNoteRequest';
import { AdvertisingPlatformServiceDeactivateOrderLineNoteRequest } from '../models/AdvertisingPlatformServiceDeactivateOrderLineNoteRequest';
import { AdvertisingPlatformServiceRemoveUserRequest } from '../models/AdvertisingPlatformServiceRemoveUserRequest';
import { AdvertisingPlatformServiceRunOneSubscriptionRequest } from '../models/AdvertisingPlatformServiceRunOneSubscriptionRequest';
import { AdvertisingPlatformServiceSetCampaignPoliticalFormRequest } from '../models/AdvertisingPlatformServiceSetCampaignPoliticalFormRequest';
import { AdvertisingPlatformServiceUpdateFTPRequest } from '../models/AdvertisingPlatformServiceUpdateFTPRequest';
import { AdvertisingPlatformServiceUpdateOrderLineNoteRequest } from '../models/AdvertisingPlatformServiceUpdateOrderLineNoteRequest';
import { AdvertisingPlatformServiceUpdatePixelRequest } from '../models/AdvertisingPlatformServiceUpdatePixelRequest';
import { AdvertisingPlatformServiceUpdatePublisherRequest } from '../models/AdvertisingPlatformServiceUpdatePublisherRequest';
import { AdvertisingPlatformServiceUpdateRuleRequest } from '../models/AdvertisingPlatformServiceUpdateRuleRequest';
import { AdvertisingPlatformServiceUpdateRuleSetRequest } from '../models/AdvertisingPlatformServiceUpdateRuleSetRequest';
import { AdvertisingPlatformServiceUpdateSubscriptionRequest } from '../models/AdvertisingPlatformServiceUpdateSubscriptionRequest';
import { ApproveOrderLineRequest } from '../models/ApproveOrderLineRequest';
import { Apqsv1Checks } from '../models/Apqsv1Checks';
import { Apqsv1Dependency } from '../models/Apqsv1Dependency';
import { Apqsv1HealthCheck } from '../models/Apqsv1HealthCheck';
import { Apqsv1HealthCondition } from '../models/Apqsv1HealthCondition';
import { Apqsv1Miscellanea } from '../models/Apqsv1Miscellanea';
import { Apqsv1SystemStat } from '../models/Apqsv1SystemStat';
import { Billingservicev1Checks } from '../models/Billingservicev1Checks';
import { Billingservicev1CommissionGroup } from '../models/Billingservicev1CommissionGroup';
import { Billingservicev1Dependency } from '../models/Billingservicev1Dependency';
import { Billingservicev1HealthCheck } from '../models/Billingservicev1HealthCheck';
import { Billingservicev1HealthCondition } from '../models/Billingservicev1HealthCondition';
import { Billingservicev1ListSubscriptionsResponse } from '../models/Billingservicev1ListSubscriptionsResponse';
import { Billingservicev1Miscellanea } from '../models/Billingservicev1Miscellanea';
import { Billingservicev1Org } from '../models/Billingservicev1Org';
import { Billingservicev1Subscription } from '../models/Billingservicev1Subscription';
import { Billingservicev1SystemStat } from '../models/Billingservicev1SystemStat';
import { CPMContainsInformationRelatingToAClientCpmanization } from '../models/CPMContainsInformationRelatingToAClientCpmanization';
import { Campaignservicev1AdType } from '../models/Campaignservicev1AdType';
import { Campaignservicev1Audience } from '../models/Campaignservicev1Audience';
import { Campaignservicev1CPM } from '../models/Campaignservicev1CPM';
import { Campaignservicev1Campaign } from '../models/Campaignservicev1Campaign';
import { Campaignservicev1Checks } from '../models/Campaignservicev1Checks';
import { Campaignservicev1Creative } from '../models/Campaignservicev1Creative';
import { Campaignservicev1Dependency } from '../models/Campaignservicev1Dependency';
import { Campaignservicev1HealthCheck } from '../models/Campaignservicev1HealthCheck';
import { Campaignservicev1HealthCondition } from '../models/Campaignservicev1HealthCondition';
import { Campaignservicev1ListCampaignsResponse } from '../models/Campaignservicev1ListCampaignsResponse';
import { Campaignservicev1Miscellanea } from '../models/Campaignservicev1Miscellanea';
import { Campaignservicev1Note } from '../models/Campaignservicev1Note';
import { Campaignservicev1NoteType } from '../models/Campaignservicev1NoteType';
import { Campaignservicev1OrderLine } from '../models/Campaignservicev1OrderLine';
import { Campaignservicev1Status } from '../models/Campaignservicev1Status';
import { Campaignservicev1SystemStat } from '../models/Campaignservicev1SystemStat';
import { CancelOrderLineDeploymentRequest } from '../models/CancelOrderLineDeploymentRequest';
import { Contact } from '../models/Contact';
import { CreateCreditCardRequest } from '../models/CreateCreditCardRequest';
import { CreateDeploymentRequest } from '../models/CreateDeploymentRequest';
import { Creativeservicev1AdType } from '../models/Creativeservicev1AdType';
import { Creativeservicev1Audit } from '../models/Creativeservicev1Audit';
import { Creativeservicev1AuditResult } from '../models/Creativeservicev1AuditResult';
import { Creativeservicev1AuditStatus } from '../models/Creativeservicev1AuditStatus';
import { Creativeservicev1Checks } from '../models/Creativeservicev1Checks';
import { Creativeservicev1Creative } from '../models/Creativeservicev1Creative';
import { Creativeservicev1Dependency } from '../models/Creativeservicev1Dependency';
import { Creativeservicev1DeploymentType } from '../models/Creativeservicev1DeploymentType';
import { Creativeservicev1Destination } from '../models/Creativeservicev1Destination';
import { Creativeservicev1FileType } from '../models/Creativeservicev1FileType';
import { Creativeservicev1FolderStats } from '../models/Creativeservicev1FolderStats';
import { Creativeservicev1HealthCheck } from '../models/Creativeservicev1HealthCheck';
import { Creativeservicev1HealthCondition } from '../models/Creativeservicev1HealthCondition';
import { Creativeservicev1ListCreativesResponse } from '../models/Creativeservicev1ListCreativesResponse';
import { Creativeservicev1Miscellanea } from '../models/Creativeservicev1Miscellanea';
import { Creativeservicev1NativeMetadata } from '../models/Creativeservicev1NativeMetadata';
import { Creativeservicev1OrderLine } from '../models/Creativeservicev1OrderLine';
import { Creativeservicev1Status } from '../models/Creativeservicev1Status';
import { Creativeservicev1SystemStat } from '../models/Creativeservicev1SystemStat';
import { Creativeservicev1Type } from '../models/Creativeservicev1Type';
import { CreditCard } from '../models/CreditCard';
import { CreditCard1 } from '../models/CreditCard1';
import { DeployOrderLineRequest } from '../models/DeployOrderLineRequest';
import { Deployservicev3Audit } from '../models/Deployservicev3Audit';
import { Deployservicev3AuditResult } from '../models/Deployservicev3AuditResult';
import { Deployservicev3AuditStatus } from '../models/Deployservicev3AuditStatus';
import { Deployservicev3Campaign } from '../models/Deployservicev3Campaign';
import { Deployservicev3Checks } from '../models/Deployservicev3Checks';
import { Deployservicev3Creative } from '../models/Deployservicev3Creative';
import { Deployservicev3CreativeID } from '../models/Deployservicev3CreativeID';
import { Deployservicev3Dependency } from '../models/Deployservicev3Dependency';
import { Deployservicev3Deployment } from '../models/Deployservicev3Deployment';
import { Deployservicev3DeploymentType } from '../models/Deployservicev3DeploymentType';
import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { Deployservicev3HealthCheck } from '../models/Deployservicev3HealthCheck';
import { Deployservicev3HealthCondition } from '../models/Deployservicev3HealthCondition';
import { Deployservicev3ListCampaignsResponse } from '../models/Deployservicev3ListCampaignsResponse';
import { Deployservicev3ListCreativesResponse } from '../models/Deployservicev3ListCreativesResponse';
import { Deployservicev3Miscellanea } from '../models/Deployservicev3Miscellanea';
import { Deployservicev3NativeMetadata } from '../models/Deployservicev3NativeMetadata';
import { Deployservicev3Status } from '../models/Deployservicev3Status';
import { Deployservicev3SystemStat } from '../models/Deployservicev3SystemStat';
import { Deployservicev3Value } from '../models/Deployservicev3Value';
import { ExistingUserWithNewFieldValues } from '../models/ExistingUserWithNewFieldValues';
import { GetDefaultTemplateRequest } from '../models/GetDefaultTemplateRequest';
import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { Hagridv1Checks } from '../models/Hagridv1Checks';
import { Hagridv1Dependency } from '../models/Hagridv1Dependency';
import { Hagridv1FileMetaData } from '../models/Hagridv1FileMetaData';
import { Hagridv1HealthCheck } from '../models/Hagridv1HealthCheck';
import { Hagridv1HealthCondition } from '../models/Hagridv1HealthCondition';
import { Hagridv1ListSubscriptionsResponse } from '../models/Hagridv1ListSubscriptionsResponse';
import { Hagridv1Miscellanea } from '../models/Hagridv1Miscellanea';
import { Hagridv1Subscription } from '../models/Hagridv1Subscription';
import { Hagridv1SystemStat } from '../models/Hagridv1SystemStat';
import { Hagridv1User } from '../models/Hagridv1User';
import { Job } from '../models/Job';
import { LockOrderLineRequest } from '../models/LockOrderLineRequest';
import { Notification } from '../models/Notification';
import { Notificationservicev1Checks } from '../models/Notificationservicev1Checks';
import { Notificationservicev1Dependency } from '../models/Notificationservicev1Dependency';
import { Notificationservicev1HealthCheck } from '../models/Notificationservicev1HealthCheck';
import { Notificationservicev1HealthCondition } from '../models/Notificationservicev1HealthCondition';
import { Notificationservicev1Miscellanea } from '../models/Notificationservicev1Miscellanea';
import { Notificationservicev1SystemStat } from '../models/Notificationservicev1SystemStat';
import { Notificationservicev1Type } from '../models/Notificationservicev1Type';
import { OrderLineViantAdelphicIDsSetAudienceID } from '../models/OrderLineViantAdelphicIDsSetAudienceID';
import { Orgmanagerv1CPM } from '../models/Orgmanagerv1CPM';
import { Orgmanagerv1Checks } from '../models/Orgmanagerv1Checks';
import { Orgmanagerv1CommissionGroup } from '../models/Orgmanagerv1CommissionGroup';
import { Orgmanagerv1Contact } from '../models/Orgmanagerv1Contact';
import { Orgmanagerv1Dependency } from '../models/Orgmanagerv1Dependency';
import { Orgmanagerv1HealthCheck } from '../models/Orgmanagerv1HealthCheck';
import { Orgmanagerv1HealthCondition } from '../models/Orgmanagerv1HealthCondition';
import { Orgmanagerv1Miscellanea } from '../models/Orgmanagerv1Miscellanea';
import { Orgmanagerv1Note } from '../models/Orgmanagerv1Note';
import { Orgmanagerv1NoteType } from '../models/Orgmanagerv1NoteType';
import { Orgmanagerv1Org } from '../models/Orgmanagerv1Org';
import { Orgmanagerv1Status } from '../models/Orgmanagerv1Status';
import { Orgmanagerv1SystemStat } from '../models/Orgmanagerv1SystemStat';
import { Orgmanagerv1User } from '../models/Orgmanagerv1User';
import { PauseOrderLineRequest } from '../models/PauseOrderLineRequest';
import { PayInvoiceRequest } from '../models/PayInvoiceRequest';
import { PaySetUpFeeRequest } from '../models/PaySetUpFeeRequest';
import { PlayOrderLineRequest } from '../models/PlayOrderLineRequest';
import { PrepayRequest } from '../models/PrepayRequest';
import { ProtobufAny } from '../models/ProtobufAny';
import { RejectOrderLineRequest } from '../models/RejectOrderLineRequest';
import { RemoveAudienceRequest } from '../models/RemoveAudienceRequest';
import { RemoveCreativeRequest } from '../models/RemoveCreativeRequest';
import { RemoveOrderLineRequest } from '../models/RemoveOrderLineRequest';
import { RequestReviewRequest } from '../models/RequestReviewRequest';
import { StartReviewRequest } from '../models/StartReviewRequest';
import { StatsRequestWillHoldThePOSTRequestVars } from '../models/StatsRequestWillHoldThePOSTRequestVars';
import { StreamResultOfV1DownloadAudienceFileResponse } from '../models/StreamResultOfV1DownloadAudienceFileResponse';
import { StreamResultOfV1DownloadBundleResponse } from '../models/StreamResultOfV1DownloadBundleResponse';
import { StreamResultOfV1DownloadCreativeFileResponse } from '../models/StreamResultOfV1DownloadCreativeFileResponse';
import { StreamResultOfV1DownloadTOSAgreementResponse } from '../models/StreamResultOfV1DownloadTOSAgreementResponse';
import { StreamResultOfV2DownloadTargetFileResponse } from '../models/StreamResultOfV2DownloadTargetFileResponse';
import { Targetjobservicev1Audience } from '../models/Targetjobservicev1Audience';
import { Targetjobservicev1Checks } from '../models/Targetjobservicev1Checks';
import { Targetjobservicev1Column } from '../models/Targetjobservicev1Column';
import { Targetjobservicev1Dependency } from '../models/Targetjobservicev1Dependency';
import { Targetjobservicev1Deployment } from '../models/Targetjobservicev1Deployment';
import { Targetjobservicev1FolderStats } from '../models/Targetjobservicev1FolderStats';
import { Targetjobservicev1HealthCheck } from '../models/Targetjobservicev1HealthCheck';
import { Targetjobservicev1HealthCondition } from '../models/Targetjobservicev1HealthCondition';
import { Targetjobservicev1Miscellanea } from '../models/Targetjobservicev1Miscellanea';
import { Targetjobservicev1Note } from '../models/Targetjobservicev1Note';
import { Targetjobservicev1NoteType } from '../models/Targetjobservicev1NoteType';
import { Targetjobservicev1SystemStat } from '../models/Targetjobservicev1SystemStat';
import { Targetjobservicev1Timeframe } from '../models/Targetjobservicev1Timeframe';
import { Targetorchestratorv2Checks } from '../models/Targetorchestratorv2Checks';
import { Targetorchestratorv2Column } from '../models/Targetorchestratorv2Column';
import { Targetorchestratorv2Dependency } from '../models/Targetorchestratorv2Dependency';
import { Targetorchestratorv2FileMetaData } from '../models/Targetorchestratorv2FileMetaData';
import { Targetorchestratorv2FileType } from '../models/Targetorchestratorv2FileType';
import { Targetorchestratorv2FolderStats } from '../models/Targetorchestratorv2FolderStats';
import { Targetorchestratorv2HealthCheck } from '../models/Targetorchestratorv2HealthCheck';
import { Targetorchestratorv2HealthCondition } from '../models/Targetorchestratorv2HealthCondition';
import { Targetorchestratorv2Location } from '../models/Targetorchestratorv2Location';
import { Targetorchestratorv2Miscellanea } from '../models/Targetorchestratorv2Miscellanea';
import { Targetorchestratorv2SystemStat } from '../models/Targetorchestratorv2SystemStat';
import { TheAdvertiserToCreate } from '../models/TheAdvertiserToCreate';
import { TheAudienceToUpdate } from '../models/TheAudienceToUpdate';
import { TheCampaignToCreate } from '../models/TheCampaignToCreate';
import { TheCampaignToUpdate } from '../models/TheCampaignToUpdate';
import { TheCreativeToCreate } from '../models/TheCreativeToCreate';
import { TheCreativeToUpdate } from '../models/TheCreativeToUpdate';
import { TheDeploymentToCreate } from '../models/TheDeploymentToCreate';
import { TheNoteToUpdate } from '../models/TheNoteToUpdate';
import { TheOrderLineToUpdate } from '../models/TheOrderLineToUpdate';
import { TheOrganizationToUpdate } from '../models/TheOrganizationToUpdate';
import { TheSegmentToCreate } from '../models/TheSegmentToCreate';
import { TheSubscriptionToUpdate } from '../models/TheSubscriptionToUpdate';
import { TheTargetToUpdate } from '../models/TheTargetToUpdate';
import { TypeDate } from '../models/TypeDate';
import { UnlockOrderLineRequest } from '../models/UnlockOrderLineRequest';
import { UpdateCPMsRequest } from '../models/UpdateCPMsRequest';
import { UpdateDeploymentRequest } from '../models/UpdateDeploymentRequest';
import { V1AccountingJob } from '../models/V1AccountingJob';
import { V1AccountingJobKind } from '../models/V1AccountingJobKind';
import { V1AdminListNotificationsResponse } from '../models/V1AdminListNotificationsResponse';
import { V1ArchiveCampaignRequest } from '../models/V1ArchiveCampaignRequest';
import { V1ArchiveCreativeRequest } from '../models/V1ArchiveCreativeRequest';
import { V1ArchiveOrderLineRequest } from '../models/V1ArchiveOrderLineRequest';
import { V1Assignment } from '../models/V1Assignment';
import { V1Attachment } from '../models/V1Attachment';
import { V1AudienceAdded } from '../models/V1AudienceAdded';
import { V1AudienceFileType } from '../models/V1AudienceFileType';
import { V1AudienceRemoved } from '../models/V1AudienceRemoved';
import { V1AudienceStatus } from '../models/V1AudienceStatus';
import { V1AudienceSubType } from '../models/V1AudienceSubType';
import { V1AudienceType } from '../models/V1AudienceType';
import { V1AuditConditions } from '../models/V1AuditConditions';
import { V1Authorization } from '../models/V1Authorization';
import { V1Balance } from '../models/V1Balance';
import { V1BatchAddAudiencesRequestAudience } from '../models/V1BatchAddAudiencesRequestAudience';
import { V1BatchUpdateOrderLinesRequest } from '../models/V1BatchUpdateOrderLinesRequest';
import { V1BatchUpdateOrderLinesResponse } from '../models/V1BatchUpdateOrderLinesResponse';
import { V1BundleRequest } from '../models/V1BundleRequest';
import { V1CampaignArchived } from '../models/V1CampaignArchived';
import { V1CampaignCreated } from '../models/V1CampaignCreated';
import { V1CampaignDeleted } from '../models/V1CampaignDeleted';
import { V1CampaignEvent } from '../models/V1CampaignEvent';
import { V1CampaignFlightDatesSet } from '../models/V1CampaignFlightDatesSet';
import { V1CampaignJobIdSet } from '../models/V1CampaignJobIdSet';
import { V1CampaignNameSet } from '../models/V1CampaignNameSet';
import { V1CampaignOrderLine } from '../models/V1CampaignOrderLine';
import { V1CampaignOrderLineUpdated } from '../models/V1CampaignOrderLineUpdated';
import { V1CampaignPoIdSet } from '../models/V1CampaignPoIdSet';
import { V1CampaignPoliticalTransparencySet } from '../models/V1CampaignPoliticalTransparencySet';
import { V1CampaignRefIDSet } from '../models/V1CampaignRefIDSet';
import { V1CampaignSearch } from '../models/V1CampaignSearch';
import { V1CampaignUnarchived } from '../models/V1CampaignUnarchived';
import { V1CancelAudienceGenerationRequest } from '../models/V1CancelAudienceGenerationRequest';
import { V1CancelSubscriptionRequest } from '../models/V1CancelSubscriptionRequest';
import { V1ClientRoles } from '../models/V1ClientRoles';
import { V1CloneAudienceRequest } from '../models/V1CloneAudienceRequest';
import { V1CommissionType } from '../models/V1CommissionType';
import { V1ComponentResult } from '../models/V1ComponentResult';
import { V1ComponentResults } from '../models/V1ComponentResults';
import { V1CostRange } from '../models/V1CostRange';
import { V1CreativeAdded } from '../models/V1CreativeAdded';
import { V1CreativeFile } from '../models/V1CreativeFile';
import { V1CreativeFileMetadata } from '../models/V1CreativeFileMetadata';
import { V1CreativeRemoved } from '../models/V1CreativeRemoved';
import { V1CreativeSearch } from '../models/V1CreativeSearch';
import { V1CredentialRepresentation } from '../models/V1CredentialRepresentation';
import { V1CreditCard } from '../models/V1CreditCard';
import { V1DataSource } from '../models/V1DataSource';
import { V1DeploymentCreated } from '../models/V1DeploymentCreated';
import { V1DeprecatedTargetJob } from '../models/V1DeprecatedTargetJob';
import { V1DeregisterOrgFromSyncResponse } from '../models/V1DeregisterOrgFromSyncResponse';
import { V1DownloadAudienceFileResponse } from '../models/V1DownloadAudienceFileResponse';
import { V1DownloadBundleResponse } from '../models/V1DownloadBundleResponse';
import { V1DownloadCreativeFileResponse } from '../models/V1DownloadCreativeFileResponse';
import { V1DownloadTOSAgreementResponse } from '../models/V1DownloadTOSAgreementResponse';
import { V1FTP } from '../models/V1FTP';
import { V1FileSubType } from '../models/V1FileSubType';
import { V1GetMigrationEstimateResponse } from '../models/V1GetMigrationEstimateResponse';
import { V1GetMigrationResponse } from '../models/V1GetMigrationResponse';
import { V1GetNewMoverMatchCountResponse } from '../models/V1GetNewMoverMatchCountResponse';
import { V1GetNotificationsResponse } from '../models/V1GetNotificationsResponse';
import { V1GetOrderLineCPMsResponse } from '../models/V1GetOrderLineCPMsResponse';
import { V1GetOrgsRegisteredForSyncResponse } from '../models/V1GetOrgsRegisteredForSyncResponse';
import { V1GetTemplateResponse } from '../models/V1GetTemplateResponse';
import { V1GetUserIPResponse } from '../models/V1GetUserIPResponse';
import { V1GlobalSearchResponse } from '../models/V1GlobalSearchResponse';
import { V1Invoice } from '../models/V1Invoice';
import { V1InvoiceStatus } from '../models/V1InvoiceStatus';
import { V1JWT } from '../models/V1JWT';
import { V1Job } from '../models/V1Job';
import { V1JobStatus } from '../models/V1JobStatus';
import { V1JobSubType } from '../models/V1JobSubType';
import { V1JobType } from '../models/V1JobType';
import { V1ListAccountingJobsResponse } from '../models/V1ListAccountingJobsResponse';
import { V1ListAudienceFoldersResponse } from '../models/V1ListAudienceFoldersResponse';
import { V1ListAudiencesResponse } from '../models/V1ListAudiencesResponse';
import { V1ListCampaignEventsResponse } from '../models/V1ListCampaignEventsResponse';
import { V1ListCommissionGroupsResponse } from '../models/V1ListCommissionGroupsResponse';
import { V1ListCreativeFoldersResponse } from '../models/V1ListCreativeFoldersResponse';
import { V1ListCreditCardsResponse } from '../models/V1ListCreditCardsResponse';
import { V1ListInvoicesResponse } from '../models/V1ListInvoicesResponse';
import { V1ListOfString } from '../models/V1ListOfString';
import { V1ListOrderLineEventsResponse } from '../models/V1ListOrderLineEventsResponse';
import { V1ListOrderLinesResponse } from '../models/V1ListOrderLinesResponse';
import { V1ListOrgSubscriptionsResponse } from '../models/V1ListOrgSubscriptionsResponse';
import { V1ListOrgUsersResponse } from '../models/V1ListOrgUsersResponse';
import { V1ListOrgsResponse } from '../models/V1ListOrgsResponse';
import { V1ListPixelsResponse } from '../models/V1ListPixelsResponse';
import { V1ListPublishersResponse } from '../models/V1ListPublishersResponse';
import { V1ListRulesResponse } from '../models/V1ListRulesResponse';
import { V1ListStatsResponse } from '../models/V1ListStatsResponse';
import { V1ListTOSAgreementsResponse } from '../models/V1ListTOSAgreementsResponse';
import { V1ListUsersResponse } from '../models/V1ListUsersResponse';
import { V1MainDetails } from '../models/V1MainDetails';
import { V1Medium } from '../models/V1Medium';
import { V1Message } from '../models/V1Message';
import { V1MessageType } from '../models/V1MessageType';
import { V1Metrics } from '../models/V1Metrics';
import { V1MigrateRequest } from '../models/V1MigrateRequest';
import { V1MigrateResponse } from '../models/V1MigrateResponse';
import { V1MigrationEstimate } from '../models/V1MigrationEstimate';
import { V1MigrationSummary } from '../models/V1MigrationSummary';
import { V1MoverMatchCount } from '../models/V1MoverMatchCount';
import { V1Notification } from '../models/V1Notification';
import { V1OAuthTokenRequest } from '../models/V1OAuthTokenRequest';
import { V1OrderLineAdTypeSet } from '../models/V1OrderLineAdTypeSet';
import { V1OrderLineAdded } from '../models/V1OrderLineAdded';
import { V1OrderLineApproved } from '../models/V1OrderLineApproved';
import { V1OrderLineArchived } from '../models/V1OrderLineArchived';
import { V1OrderLineAttachedToCampaign } from '../models/V1OrderLineAttachedToCampaign';
import { V1OrderLineAudienceSet } from '../models/V1OrderLineAudienceSet';
import { V1OrderLineAuditConditionsSet } from '../models/V1OrderLineAuditConditionsSet';
import { V1OrderLineCPMSet } from '../models/V1OrderLineCPMSet';
import { V1OrderLineCampaign } from '../models/V1OrderLineCampaign';
import { V1OrderLineCampaignAttached } from '../models/V1OrderLineCampaignAttached';
import { V1OrderLineCampaignDetached } from '../models/V1OrderLineCampaignDetached';
import { V1OrderLineCampaignSet } from '../models/V1OrderLineCampaignSet';
import { V1OrderLineClickThroughURLSet } from '../models/V1OrderLineClickThroughURLSet';
import { V1OrderLineCloned } from '../models/V1OrderLineCloned';
import { V1OrderLineCompleted } from '../models/V1OrderLineCompleted';
import { V1OrderLineConfigured } from '../models/V1OrderLineConfigured';
import { V1OrderLineConsoleCampaignSet } from '../models/V1OrderLineConsoleCampaignSet';
import { V1OrderLineConsoleClickThroughURLSet } from '../models/V1OrderLineConsoleClickThroughURLSet';
import { V1OrderLineConsoleCreativesSet } from '../models/V1OrderLineConsoleCreativesSet';
import { V1OrderLineConsoleSegmentsSet } from '../models/V1OrderLineConsoleSegmentsSet';
import { V1OrderLineCostSet } from '../models/V1OrderLineCostSet';
import { V1OrderLineCreated } from '../models/V1OrderLineCreated';
import { V1OrderLineCreating } from '../models/V1OrderLineCreating';
import { V1OrderLineCreativeAttached } from '../models/V1OrderLineCreativeAttached';
import { V1OrderLineCreativeDetached } from '../models/V1OrderLineCreativeDetached';
import { V1OrderLineCreativeSet } from '../models/V1OrderLineCreativeSet';
import { V1OrderLineDeleted } from '../models/V1OrderLineDeleted';
import { V1OrderLineDeployRetried } from '../models/V1OrderLineDeployRetried';
import { V1OrderLineDeployed } from '../models/V1OrderLineDeployed';
import { V1OrderLineDeployedToDestinationXandrCurate } from '../models/V1OrderLineDeployedToDestinationXandrCurate';
import { V1OrderLineDeployedToDestinationXandrInvest } from '../models/V1OrderLineDeployedToDestinationXandrInvest';
import { V1OrderLineDeployedWithConfig } from '../models/V1OrderLineDeployedWithConfig';
import { V1OrderLineDeploymentCancelled } from '../models/V1OrderLineDeploymentCancelled';
import { V1OrderLineDeploymentCancelling } from '../models/V1OrderLineDeploymentCancelling';
import { V1OrderLineDeploymentDestinationConfigurationSet } from '../models/V1OrderLineDeploymentDestinationConfigurationSet';
import { V1OrderLineDeploymentDestinationSet } from '../models/V1OrderLineDeploymentDestinationSet';
import { V1OrderLineDestinationXandrConsoleCreated } from '../models/V1OrderLineDestinationXandrConsoleCreated';
import { V1OrderLineDestinationXandrCurateCreated } from '../models/V1OrderLineDestinationXandrCurateCreated';
import { V1OrderLineDestinationXandrCurateSet } from '../models/V1OrderLineDestinationXandrCurateSet';
import { V1OrderLineDestinationXandrInvestCreated } from '../models/V1OrderLineDestinationXandrInvestCreated';
import { V1OrderLineDestinationXandrInvestSet } from '../models/V1OrderLineDestinationXandrInvestSet';
import { V1OrderLineDetachedFromCampaign } from '../models/V1OrderLineDetachedFromCampaign';
import { V1OrderLineEndTimeSet } from '../models/V1OrderLineEndTimeSet';
import { V1OrderLineErroredDeployingToDestinationXandrCurate } from '../models/V1OrderLineErroredDeployingToDestinationXandrCurate';
import { V1OrderLineErroredDeployingToDestinationXandrInvest } from '../models/V1OrderLineErroredDeployingToDestinationXandrInvest';
import { V1OrderLineEvent } from '../models/V1OrderLineEvent';
import { V1OrderLineFlightDatesSet } from '../models/V1OrderLineFlightDatesSet';
import { V1OrderLineFreeImpressionsSet } from '../models/V1OrderLineFreeImpressionsSet';
import { V1OrderLineImpressionsPerDaySet } from '../models/V1OrderLineImpressionsPerDaySet';
import { V1OrderLineImpressionsSet } from '../models/V1OrderLineImpressionsSet';
import { V1OrderLineInvestCampaignSet } from '../models/V1OrderLineInvestCampaignSet';
import { V1OrderLineInvestClickThroughURLSet } from '../models/V1OrderLineInvestClickThroughURLSet';
import { V1OrderLineInvestCreativesSet } from '../models/V1OrderLineInvestCreativesSet';
import { V1OrderLineInvestSegmentsSet } from '../models/V1OrderLineInvestSegmentsSet';
import { V1OrderLineJobIdSet } from '../models/V1OrderLineJobIdSet';
import { V1OrderLineLocked } from '../models/V1OrderLineLocked';
import { V1OrderLineMinimumImpressionsSet } from '../models/V1OrderLineMinimumImpressionsSet';
import { V1OrderLineNameSet } from '../models/V1OrderLineNameSet';
import { V1OrderLineNoteAppended } from '../models/V1OrderLineNoteAppended';
import { V1OrderLineNoteDeactivated } from '../models/V1OrderLineNoteDeactivated';
import { V1OrderLineNoteUpdated } from '../models/V1OrderLineNoteUpdated';
import { V1OrderLineOrgIdSet } from '../models/V1OrderLineOrgIdSet';
import { V1OrderLinePaidSet } from '../models/V1OrderLinePaidSet';
import { V1OrderLinePaused } from '../models/V1OrderLinePaused';
import { V1OrderLinePlayed } from '../models/V1OrderLinePlayed';
import { V1OrderLinePoIdSet } from '../models/V1OrderLinePoIdSet';
import { V1OrderLinePoliticalFieldsSet } from '../models/V1OrderLinePoliticalFieldsSet';
import { V1OrderLinePoliticalSet } from '../models/V1OrderLinePoliticalSet';
import { V1OrderLinePrepaySet } from '../models/V1OrderLinePrepaySet';
import { V1OrderLineRefIdSet } from '../models/V1OrderLineRefIdSet';
import { V1OrderLineRejected } from '../models/V1OrderLineRejected';
import { V1OrderLineRemoved } from '../models/V1OrderLineRemoved';
import { V1OrderLineReviewStarted } from '../models/V1OrderLineReviewStarted';
import { V1OrderLineSearch } from '../models/V1OrderLineSearch';
import { V1OrderLineSegmentConfigSet } from '../models/V1OrderLineSegmentConfigSet';
import { V1OrderLineStartTimeSet } from '../models/V1OrderLineStartTimeSet';
import { V1OrderLineStateSetToActive } from '../models/V1OrderLineStateSetToActive';
import { V1OrderLineStateSetToPaused } from '../models/V1OrderLineStateSetToPaused';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V1OrderLineStatusSetToDeploying } from '../models/V1OrderLineStatusSetToDeploying';
import { V1OrderLineStatusSetToDraft } from '../models/V1OrderLineStatusSetToDraft';
import { V1OrderLineStatusSetToPrevious } from '../models/V1OrderLineStatusSetToPrevious';
import { V1OrderLineStepFunctionSet } from '../models/V1OrderLineStepFunctionSet';
import { V1OrderLineSubmittedForReview } from '../models/V1OrderLineSubmittedForReview';
import { V1OrderLineTargetJobAttached } from '../models/V1OrderLineTargetJobAttached';
import { V1OrderLineTargetJobDetached } from '../models/V1OrderLineTargetJobDetached';
import { V1OrderLineTemplateTypeSet } from '../models/V1OrderLineTemplateTypeSet';
import { V1OrderLineUnarchived } from '../models/V1OrderLineUnarchived';
import { V1OrderLineUnlocked } from '../models/V1OrderLineUnlocked';
import { V1OrderLineViantAdelphicConfigurationSet } from '../models/V1OrderLineViantAdelphicConfigurationSet';
import { V1OrderLineViantAdelphicIDsSet } from '../models/V1OrderLineViantAdelphicIDsSet';
import { V1OrderLineViantAdelphicIDsSetCreativeID } from '../models/V1OrderLineViantAdelphicIDsSetCreativeID';
import { V1OrgData } from '../models/V1OrgData';
import { V1OrgLogo } from '../models/V1OrgLogo';
import { V1PayOrderLinesRequest } from '../models/V1PayOrderLinesRequest';
import { V1Payment } from '../models/V1Payment';
import { V1Pixel } from '../models/V1Pixel';
import { V1PoliticalFields } from '../models/V1PoliticalFields';
import { V1PoliticalTransparency } from '../models/V1PoliticalTransparency';
import { V1ProductType } from '../models/V1ProductType';
import { V1Publisher } from '../models/V1Publisher';
import { V1QueueDetails } from '../models/V1QueueDetails';
import { V1QueueHealth } from '../models/V1QueueHealth';
import { V1RebuildGlobalSearchRequest } from '../models/V1RebuildGlobalSearchRequest';
import { V1RegisterOrgForSyncRequest } from '../models/V1RegisterOrgForSyncRequest';
import { V1RegisterOrgForSyncResponse } from '../models/V1RegisterOrgForSyncResponse';
import { V1RequestSupportRequest } from '../models/V1RequestSupportRequest';
import { V1Result } from '../models/V1Result';
import { V1ResultIDs } from '../models/V1ResultIDs';
import { V1ResultMetrics } from '../models/V1ResultMetrics';
import { V1Results } from '../models/V1Results';
import { V1Review } from '../models/V1Review';
import { V1ReviewRequested } from '../models/V1ReviewRequested';
import { V1ReviewStatus } from '../models/V1ReviewStatus';
import { V1Rule } from '../models/V1Rule';
import { V1RuleSet } from '../models/V1RuleSet';
import { V1RunCommissionsRequest } from '../models/V1RunCommissionsRequest';
import { V1RunCommissionsResponse } from '../models/V1RunCommissionsResponse';
import { V1RunInvoicingRequest } from '../models/V1RunInvoicingRequest';
import { V1RunInvoicingResponse } from '../models/V1RunInvoicingResponse';
import { V1SendMessageRequest } from '../models/V1SendMessageRequest';
import { V1StartSyncResponse } from '../models/V1StartSyncResponse';
import { V1State } from '../models/V1State';
import { V1Stats } from '../models/V1Stats';
import { V1StatsResponse } from '../models/V1StatsResponse';
import { V1SubDetails } from '../models/V1SubDetails';
import { V1SubscriptionFreeTrialMetadata } from '../models/V1SubscriptionFreeTrialMetadata';
import { V1SubscriptionInterval } from '../models/V1SubscriptionInterval';
import { V1SubscriptionMetadata } from '../models/V1SubscriptionMetadata';
import { V1SubscriptionPeriodMetadata } from '../models/V1SubscriptionPeriodMetadata';
import { V1SubscriptionService } from '../models/V1SubscriptionService';
import { V1SubscriptionStatus } from '../models/V1SubscriptionStatus';
import { V1SyncOrg } from '../models/V1SyncOrg';
import { V1TOSAgreement } from '../models/V1TOSAgreement';
import { V1TargetJobSearch } from '../models/V1TargetJobSearch';
import { V1TemplateType } from '../models/V1TemplateType';
import { V1UUID } from '../models/V1UUID';
import { V1UnarchiveCampaignRequest } from '../models/V1UnarchiveCampaignRequest';
import { V1UnarchiveCreativeRequest } from '../models/V1UnarchiveCreativeRequest';
import { V1UnarchiveOrderLineRequest } from '../models/V1UnarchiveOrderLineRequest';
import { V1UpdateOrderLineRequest } from '../models/V1UpdateOrderLineRequest';
import { V1UploadCreativeFileRequest } from '../models/V1UploadCreativeFileRequest';
import { V1UploadOrgLogoMetadata } from '../models/V1UploadOrgLogoMetadata';
import { V1UploadTOSAgreementRequest } from '../models/V1UploadTOSAgreementRequest';
import { V2ArchiveTargetRequest } from '../models/V2ArchiveTargetRequest';
import { V2CreateJsonTargetRequest } from '../models/V2CreateJsonTargetRequest';
import { V2DownloadTargetFileResponse } from '../models/V2DownloadTargetFileResponse';
import { V2ListTargetFoldersResponse } from '../models/V2ListTargetFoldersResponse';
import { V2ListTargetsResponse } from '../models/V2ListTargetsResponse';
import { V2ReplaceFileMetaData } from '../models/V2ReplaceFileMetaData';
import { V2ReplaceTargetFileRequest } from '../models/V2ReplaceTargetFileRequest';
import { V2Target } from '../models/V2Target';
import { V2TargetJob } from '../models/V2TargetJob';
import { V2TargetStatus } from '../models/V2TargetStatus';
import { V2UnarchiveTargetRequest } from '../models/V2UnarchiveTargetRequest';
import { V2UploadTargetFileRequest } from '../models/V2UploadTargetFileRequest';
import { V3Advertiser } from '../models/V3Advertiser';
import { V3AugmentedLineItem } from '../models/V3AugmentedLineItem';
import { V3BrowserTarget } from '../models/V3BrowserTarget';
import { V3BudgetInterval } from '../models/V3BudgetInterval';
import { V3BuyerSeat } from '../models/V3BuyerSeat';
import { V3CampaignCreative } from '../models/V3CampaignCreative';
import { V3Condition } from '../models/V3Condition';
import { V3ConsoleCampaign } from '../models/V3ConsoleCampaign';
import { V3CountryTarget } from '../models/V3CountryTarget';
import { V3CreativeType } from '../models/V3CreativeType';
import { V3CurateCampaign } from '../models/V3CurateCampaign';
import { V3CustomDateRange } from '../models/V3CustomDateRange';
import { V3DaypartTarget } from '../models/V3DaypartTarget';
import { V3Deal } from '../models/V3Deal';
import { V3DealList } from '../models/V3DealList';
import { V3DealType } from '../models/V3DealType';
import { V3DeploymentDestinationConfigurations } from '../models/V3DeploymentDestinationConfigurations';
import { V3DirectMailCampaign } from '../models/V3DirectMailCampaign';
import { V3DirectMailConfiguration } from '../models/V3DirectMailConfiguration';
import { V3DomainListTarget } from '../models/V3DomainListTarget';
import { V3EngagementRateTarget } from '../models/V3EngagementRateTarget';
import { V3GenericDeployRequest } from '../models/V3GenericDeployRequest';
import { V3GovernmentLevel } from '../models/V3GovernmentLevel';
import { V3InventoryAttributeTarget } from '../models/V3InventoryAttributeTarget';
import { V3InventoryListTarget } from '../models/V3InventoryListTarget';
import { V3InvestCampaign } from '../models/V3InvestCampaign';
import { V3Language } from '../models/V3Language';
import { V3LanguageTarget } from '../models/V3LanguageTarget';
import { V3LegacyCampaign } from '../models/V3LegacyCampaign';
import { V3LineItem } from '../models/V3LineItem';
import { V3LineItemDeal } from '../models/V3LineItemDeal';
import { V3LineItemPixel } from '../models/V3LineItemPixel';
import { V3ListAdvertisersResponse } from '../models/V3ListAdvertisersResponse';
import { V3ListDeploymentsResponse } from '../models/V3ListDeploymentsResponse';
import { V3ListSegmentsResponse } from '../models/V3ListSegmentsResponse';
import { V3ListXandrDealListsResponse } from '../models/V3ListXandrDealListsResponse';
import { V3ListXandrDealsResponse } from '../models/V3ListXandrDealsResponse';
import { V3ListXandrLanguagesResponse } from '../models/V3ListXandrLanguagesResponse';
import { V3ListXandrRegionsResponse } from '../models/V3ListXandrRegionsResponse';
import { V3MemberDataSharing } from '../models/V3MemberDataSharing';
import { V3MemberDataSharingSegment } from '../models/V3MemberDataSharingSegment';
import { V3NonSeamlessInsertionOrder } from '../models/V3NonSeamlessInsertionOrder';
import { V3PoliticalContent } from '../models/V3PoliticalContent';
import { V3Position } from '../models/V3Position';
import { V3PositionTargets } from '../models/V3PositionTargets';
import { V3Profile } from '../models/V3Profile';
import { V3QueueRequest } from '../models/V3QueueRequest';
import { V3Region } from '../models/V3Region';
import { V3RegionTarget } from '../models/V3RegionTarget';
import { V3RegistrationForm } from '../models/V3RegistrationForm';
import { V3SGSegment } from '../models/V3SGSegment';
import { V3SeamlessInsertionOrder } from '../models/V3SeamlessInsertionOrder';
import { V3Segment } from '../models/V3Segment';
import { V3SegmentConfig } from '../models/V3SegmentConfig';
import { V3SegmentGroupTarget } from '../models/V3SegmentGroupTarget';
import { V3SegmentType } from '../models/V3SegmentType';
import { V3Split } from '../models/V3Split';
import { V3SupplyStrategies } from '../models/V3SupplyStrategies';
import { V3UserInfo } from '../models/V3UserInfo';
import { V3Valuation } from '../models/V3Valuation';
import { V3Values } from '../models/V3Values';
import { V3ViantAdelphicConfiguration } from '../models/V3ViantAdelphicConfiguration';
import { V3ViantCreativeConfig } from '../models/V3ViantCreativeConfig';
import { V3ViantSegmentConfig } from '../models/V3ViantSegmentConfig';
import { V3XandrConsoleConfiguration } from '../models/V3XandrConsoleConfiguration';
import { V3XandrCurateConfiguration } from '../models/V3XandrCurateConfiguration';
import { V3XandrInvestConfiguration } from '../models/V3XandrInvestConfiguration';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0,
  "application/x-www-form-urlencoded": 0
}


let enumsMap: Set<string> = new Set<string>([
    "Apqsv1HealthCondition",
    "Billingservicev1HealthCondition",
    "Campaignservicev1AdType",
    "Campaignservicev1HealthCondition",
    "Campaignservicev1NoteType",
    "Campaignservicev1Status",
    "Creativeservicev1AdType",
    "Creativeservicev1AuditStatus",
    "Creativeservicev1DeploymentType",
    "Creativeservicev1Destination",
    "Creativeservicev1FileType",
    "Creativeservicev1HealthCondition",
    "Creativeservicev1Status",
    "Creativeservicev1Type",
    "Deployservicev3AuditStatus",
    "Deployservicev3DeploymentType",
    "Deployservicev3Destination",
    "Deployservicev3HealthCondition",
    "Deployservicev3Status",
    "Hagridv1HealthCondition",
    "Notificationservicev1HealthCondition",
    "Notificationservicev1Type",
    "Orgmanagerv1HealthCondition",
    "Orgmanagerv1NoteType",
    "Orgmanagerv1Status",
    "Targetjobservicev1HealthCondition",
    "Targetjobservicev1NoteType",
    "Targetorchestratorv2FileType",
    "Targetorchestratorv2HealthCondition",
    "V1AccountingJobKind",
    "V1AudienceFileType",
    "V1AudienceStatus",
    "V1AudienceSubType",
    "V1AudienceType",
    "V1CommissionType",
    "V1DataSource",
    "V1FileSubType",
    "V1InvoiceStatus",
    "V1JobStatus",
    "V1JobSubType",
    "V1JobType",
    "V1Medium",
    "V1MessageType",
    "V1OrderLineStatus",
    "V1ProductType",
    "V1ReviewStatus",
    "V1State",
    "V1SubscriptionInterval",
    "V1SubscriptionService",
    "V1SubscriptionStatus",
    "V1TemplateType",
    "V2TargetStatus",
    "V3CreativeType",
    "V3GovernmentLevel",
    "V3SegmentType",
]);

let typeMap: {[index: string]: any} = {
    "AddAudienceRequest": AddAudienceRequest,
    "AddCreativeRequest": AddCreativeRequest,
    "AddOrderLineRequest": AddOrderLineRequest,
    "AdvertisingPlatformServiceAddAccountRepRequest": AdvertisingPlatformServiceAddAccountRepRequest,
    "AdvertisingPlatformServiceAddCommissionGroupRequest": AdvertisingPlatformServiceAddCommissionGroupRequest,
    "AdvertisingPlatformServiceAddSalesRepRequest": AdvertisingPlatformServiceAddSalesRepRequest,
    "AdvertisingPlatformServiceAddUserRequest": AdvertisingPlatformServiceAddUserRequest,
    "AdvertisingPlatformServiceAppendOrderLineNoteRequest": AdvertisingPlatformServiceAppendOrderLineNoteRequest,
    "AdvertisingPlatformServiceBatchAddAudiencesRequest": AdvertisingPlatformServiceBatchAddAudiencesRequest,
    "AdvertisingPlatformServiceBatchAddCreativesRequest": AdvertisingPlatformServiceBatchAddCreativesRequest,
    "AdvertisingPlatformServiceBatchRemoveAudiencesRequest": AdvertisingPlatformServiceBatchRemoveAudiencesRequest,
    "AdvertisingPlatformServiceBatchRemoveCreativesRequest": AdvertisingPlatformServiceBatchRemoveCreativesRequest,
    "AdvertisingPlatformServiceCloneOrderLineRequest": AdvertisingPlatformServiceCloneOrderLineRequest,
    "AdvertisingPlatformServiceCreateOrgNoteRequest": AdvertisingPlatformServiceCreateOrgNoteRequest,
    "AdvertisingPlatformServiceDeactivateOrderLineNoteRequest": AdvertisingPlatformServiceDeactivateOrderLineNoteRequest,
    "AdvertisingPlatformServiceRemoveUserRequest": AdvertisingPlatformServiceRemoveUserRequest,
    "AdvertisingPlatformServiceRunOneSubscriptionRequest": AdvertisingPlatformServiceRunOneSubscriptionRequest,
    "AdvertisingPlatformServiceSetCampaignPoliticalFormRequest": AdvertisingPlatformServiceSetCampaignPoliticalFormRequest,
    "AdvertisingPlatformServiceUpdateFTPRequest": AdvertisingPlatformServiceUpdateFTPRequest,
    "AdvertisingPlatformServiceUpdateOrderLineNoteRequest": AdvertisingPlatformServiceUpdateOrderLineNoteRequest,
    "AdvertisingPlatformServiceUpdatePixelRequest": AdvertisingPlatformServiceUpdatePixelRequest,
    "AdvertisingPlatformServiceUpdatePublisherRequest": AdvertisingPlatformServiceUpdatePublisherRequest,
    "AdvertisingPlatformServiceUpdateRuleRequest": AdvertisingPlatformServiceUpdateRuleRequest,
    "AdvertisingPlatformServiceUpdateRuleSetRequest": AdvertisingPlatformServiceUpdateRuleSetRequest,
    "AdvertisingPlatformServiceUpdateSubscriptionRequest": AdvertisingPlatformServiceUpdateSubscriptionRequest,
    "ApproveOrderLineRequest": ApproveOrderLineRequest,
    "Apqsv1Checks": Apqsv1Checks,
    "Apqsv1Dependency": Apqsv1Dependency,
    "Apqsv1HealthCheck": Apqsv1HealthCheck,
    "Apqsv1Miscellanea": Apqsv1Miscellanea,
    "Apqsv1SystemStat": Apqsv1SystemStat,
    "Billingservicev1Checks": Billingservicev1Checks,
    "Billingservicev1CommissionGroup": Billingservicev1CommissionGroup,
    "Billingservicev1Dependency": Billingservicev1Dependency,
    "Billingservicev1HealthCheck": Billingservicev1HealthCheck,
    "Billingservicev1ListSubscriptionsResponse": Billingservicev1ListSubscriptionsResponse,
    "Billingservicev1Miscellanea": Billingservicev1Miscellanea,
    "Billingservicev1Org": Billingservicev1Org,
    "Billingservicev1Subscription": Billingservicev1Subscription,
    "Billingservicev1SystemStat": Billingservicev1SystemStat,
    "CPMContainsInformationRelatingToAClientCpmanization": CPMContainsInformationRelatingToAClientCpmanization,
    "Campaignservicev1Audience": Campaignservicev1Audience,
    "Campaignservicev1CPM": Campaignservicev1CPM,
    "Campaignservicev1Campaign": Campaignservicev1Campaign,
    "Campaignservicev1Checks": Campaignservicev1Checks,
    "Campaignservicev1Creative": Campaignservicev1Creative,
    "Campaignservicev1Dependency": Campaignservicev1Dependency,
    "Campaignservicev1HealthCheck": Campaignservicev1HealthCheck,
    "Campaignservicev1ListCampaignsResponse": Campaignservicev1ListCampaignsResponse,
    "Campaignservicev1Miscellanea": Campaignservicev1Miscellanea,
    "Campaignservicev1Note": Campaignservicev1Note,
    "Campaignservicev1OrderLine": Campaignservicev1OrderLine,
    "Campaignservicev1SystemStat": Campaignservicev1SystemStat,
    "CancelOrderLineDeploymentRequest": CancelOrderLineDeploymentRequest,
    "Contact": Contact,
    "CreateCreditCardRequest": CreateCreditCardRequest,
    "CreateDeploymentRequest": CreateDeploymentRequest,
    "Creativeservicev1Audit": Creativeservicev1Audit,
    "Creativeservicev1AuditResult": Creativeservicev1AuditResult,
    "Creativeservicev1Checks": Creativeservicev1Checks,
    "Creativeservicev1Creative": Creativeservicev1Creative,
    "Creativeservicev1Dependency": Creativeservicev1Dependency,
    "Creativeservicev1FolderStats": Creativeservicev1FolderStats,
    "Creativeservicev1HealthCheck": Creativeservicev1HealthCheck,
    "Creativeservicev1ListCreativesResponse": Creativeservicev1ListCreativesResponse,
    "Creativeservicev1Miscellanea": Creativeservicev1Miscellanea,
    "Creativeservicev1NativeMetadata": Creativeservicev1NativeMetadata,
    "Creativeservicev1OrderLine": Creativeservicev1OrderLine,
    "Creativeservicev1SystemStat": Creativeservicev1SystemStat,
    "CreditCard": CreditCard,
    "CreditCard1": CreditCard1,
    "DeployOrderLineRequest": DeployOrderLineRequest,
    "Deployservicev3Audit": Deployservicev3Audit,
    "Deployservicev3AuditResult": Deployservicev3AuditResult,
    "Deployservicev3Campaign": Deployservicev3Campaign,
    "Deployservicev3Checks": Deployservicev3Checks,
    "Deployservicev3Creative": Deployservicev3Creative,
    "Deployservicev3CreativeID": Deployservicev3CreativeID,
    "Deployservicev3Dependency": Deployservicev3Dependency,
    "Deployservicev3Deployment": Deployservicev3Deployment,
    "Deployservicev3HealthCheck": Deployservicev3HealthCheck,
    "Deployservicev3ListCampaignsResponse": Deployservicev3ListCampaignsResponse,
    "Deployservicev3ListCreativesResponse": Deployservicev3ListCreativesResponse,
    "Deployservicev3Miscellanea": Deployservicev3Miscellanea,
    "Deployservicev3NativeMetadata": Deployservicev3NativeMetadata,
    "Deployservicev3SystemStat": Deployservicev3SystemStat,
    "Deployservicev3Value": Deployservicev3Value,
    "ExistingUserWithNewFieldValues": ExistingUserWithNewFieldValues,
    "GetDefaultTemplateRequest": GetDefaultTemplateRequest,
    "GooglerpcStatus": GooglerpcStatus,
    "GoogletypeMoney": GoogletypeMoney,
    "Hagridv1Checks": Hagridv1Checks,
    "Hagridv1Dependency": Hagridv1Dependency,
    "Hagridv1FileMetaData": Hagridv1FileMetaData,
    "Hagridv1HealthCheck": Hagridv1HealthCheck,
    "Hagridv1ListSubscriptionsResponse": Hagridv1ListSubscriptionsResponse,
    "Hagridv1Miscellanea": Hagridv1Miscellanea,
    "Hagridv1Subscription": Hagridv1Subscription,
    "Hagridv1SystemStat": Hagridv1SystemStat,
    "Hagridv1User": Hagridv1User,
    "Job": Job,
    "LockOrderLineRequest": LockOrderLineRequest,
    "Notification": Notification,
    "Notificationservicev1Checks": Notificationservicev1Checks,
    "Notificationservicev1Dependency": Notificationservicev1Dependency,
    "Notificationservicev1HealthCheck": Notificationservicev1HealthCheck,
    "Notificationservicev1Miscellanea": Notificationservicev1Miscellanea,
    "Notificationservicev1SystemStat": Notificationservicev1SystemStat,
    "OrderLineViantAdelphicIDsSetAudienceID": OrderLineViantAdelphicIDsSetAudienceID,
    "Orgmanagerv1CPM": Orgmanagerv1CPM,
    "Orgmanagerv1Checks": Orgmanagerv1Checks,
    "Orgmanagerv1CommissionGroup": Orgmanagerv1CommissionGroup,
    "Orgmanagerv1Contact": Orgmanagerv1Contact,
    "Orgmanagerv1Dependency": Orgmanagerv1Dependency,
    "Orgmanagerv1HealthCheck": Orgmanagerv1HealthCheck,
    "Orgmanagerv1Miscellanea": Orgmanagerv1Miscellanea,
    "Orgmanagerv1Note": Orgmanagerv1Note,
    "Orgmanagerv1Org": Orgmanagerv1Org,
    "Orgmanagerv1SystemStat": Orgmanagerv1SystemStat,
    "Orgmanagerv1User": Orgmanagerv1User,
    "PauseOrderLineRequest": PauseOrderLineRequest,
    "PayInvoiceRequest": PayInvoiceRequest,
    "PaySetUpFeeRequest": PaySetUpFeeRequest,
    "PlayOrderLineRequest": PlayOrderLineRequest,
    "PrepayRequest": PrepayRequest,
    "ProtobufAny": ProtobufAny,
    "RejectOrderLineRequest": RejectOrderLineRequest,
    "RemoveAudienceRequest": RemoveAudienceRequest,
    "RemoveCreativeRequest": RemoveCreativeRequest,
    "RemoveOrderLineRequest": RemoveOrderLineRequest,
    "RequestReviewRequest": RequestReviewRequest,
    "StartReviewRequest": StartReviewRequest,
    "StatsRequestWillHoldThePOSTRequestVars": StatsRequestWillHoldThePOSTRequestVars,
    "StreamResultOfV1DownloadAudienceFileResponse": StreamResultOfV1DownloadAudienceFileResponse,
    "StreamResultOfV1DownloadBundleResponse": StreamResultOfV1DownloadBundleResponse,
    "StreamResultOfV1DownloadCreativeFileResponse": StreamResultOfV1DownloadCreativeFileResponse,
    "StreamResultOfV1DownloadTOSAgreementResponse": StreamResultOfV1DownloadTOSAgreementResponse,
    "StreamResultOfV2DownloadTargetFileResponse": StreamResultOfV2DownloadTargetFileResponse,
    "Targetjobservicev1Audience": Targetjobservicev1Audience,
    "Targetjobservicev1Checks": Targetjobservicev1Checks,
    "Targetjobservicev1Column": Targetjobservicev1Column,
    "Targetjobservicev1Dependency": Targetjobservicev1Dependency,
    "Targetjobservicev1Deployment": Targetjobservicev1Deployment,
    "Targetjobservicev1FolderStats": Targetjobservicev1FolderStats,
    "Targetjobservicev1HealthCheck": Targetjobservicev1HealthCheck,
    "Targetjobservicev1Miscellanea": Targetjobservicev1Miscellanea,
    "Targetjobservicev1Note": Targetjobservicev1Note,
    "Targetjobservicev1SystemStat": Targetjobservicev1SystemStat,
    "Targetjobservicev1Timeframe": Targetjobservicev1Timeframe,
    "Targetorchestratorv2Checks": Targetorchestratorv2Checks,
    "Targetorchestratorv2Column": Targetorchestratorv2Column,
    "Targetorchestratorv2Dependency": Targetorchestratorv2Dependency,
    "Targetorchestratorv2FileMetaData": Targetorchestratorv2FileMetaData,
    "Targetorchestratorv2FolderStats": Targetorchestratorv2FolderStats,
    "Targetorchestratorv2HealthCheck": Targetorchestratorv2HealthCheck,
    "Targetorchestratorv2Location": Targetorchestratorv2Location,
    "Targetorchestratorv2Miscellanea": Targetorchestratorv2Miscellanea,
    "Targetorchestratorv2SystemStat": Targetorchestratorv2SystemStat,
    "TheAdvertiserToCreate": TheAdvertiserToCreate,
    "TheAudienceToUpdate": TheAudienceToUpdate,
    "TheCampaignToCreate": TheCampaignToCreate,
    "TheCampaignToUpdate": TheCampaignToUpdate,
    "TheCreativeToCreate": TheCreativeToCreate,
    "TheCreativeToUpdate": TheCreativeToUpdate,
    "TheDeploymentToCreate": TheDeploymentToCreate,
    "TheNoteToUpdate": TheNoteToUpdate,
    "TheOrderLineToUpdate": TheOrderLineToUpdate,
    "TheOrganizationToUpdate": TheOrganizationToUpdate,
    "TheSegmentToCreate": TheSegmentToCreate,
    "TheSubscriptionToUpdate": TheSubscriptionToUpdate,
    "TheTargetToUpdate": TheTargetToUpdate,
    "TypeDate": TypeDate,
    "UnlockOrderLineRequest": UnlockOrderLineRequest,
    "UpdateCPMsRequest": UpdateCPMsRequest,
    "UpdateDeploymentRequest": UpdateDeploymentRequest,
    "V1AccountingJob": V1AccountingJob,
    "V1AdminListNotificationsResponse": V1AdminListNotificationsResponse,
    "V1ArchiveCampaignRequest": V1ArchiveCampaignRequest,
    "V1ArchiveCreativeRequest": V1ArchiveCreativeRequest,
    "V1ArchiveOrderLineRequest": V1ArchiveOrderLineRequest,
    "V1Assignment": V1Assignment,
    "V1Attachment": V1Attachment,
    "V1AudienceAdded": V1AudienceAdded,
    "V1AudienceRemoved": V1AudienceRemoved,
    "V1AuditConditions": V1AuditConditions,
    "V1Authorization": V1Authorization,
    "V1Balance": V1Balance,
    "V1BatchAddAudiencesRequestAudience": V1BatchAddAudiencesRequestAudience,
    "V1BatchUpdateOrderLinesRequest": V1BatchUpdateOrderLinesRequest,
    "V1BatchUpdateOrderLinesResponse": V1BatchUpdateOrderLinesResponse,
    "V1BundleRequest": V1BundleRequest,
    "V1CampaignArchived": V1CampaignArchived,
    "V1CampaignCreated": V1CampaignCreated,
    "V1CampaignDeleted": V1CampaignDeleted,
    "V1CampaignEvent": V1CampaignEvent,
    "V1CampaignFlightDatesSet": V1CampaignFlightDatesSet,
    "V1CampaignJobIdSet": V1CampaignJobIdSet,
    "V1CampaignNameSet": V1CampaignNameSet,
    "V1CampaignOrderLine": V1CampaignOrderLine,
    "V1CampaignOrderLineUpdated": V1CampaignOrderLineUpdated,
    "V1CampaignPoIdSet": V1CampaignPoIdSet,
    "V1CampaignPoliticalTransparencySet": V1CampaignPoliticalTransparencySet,
    "V1CampaignRefIDSet": V1CampaignRefIDSet,
    "V1CampaignSearch": V1CampaignSearch,
    "V1CampaignUnarchived": V1CampaignUnarchived,
    "V1CancelAudienceGenerationRequest": V1CancelAudienceGenerationRequest,
    "V1CancelSubscriptionRequest": V1CancelSubscriptionRequest,
    "V1ClientRoles": V1ClientRoles,
    "V1CloneAudienceRequest": V1CloneAudienceRequest,
    "V1ComponentResult": V1ComponentResult,
    "V1ComponentResults": V1ComponentResults,
    "V1CostRange": V1CostRange,
    "V1CreativeAdded": V1CreativeAdded,
    "V1CreativeFile": V1CreativeFile,
    "V1CreativeFileMetadata": V1CreativeFileMetadata,
    "V1CreativeRemoved": V1CreativeRemoved,
    "V1CreativeSearch": V1CreativeSearch,
    "V1CredentialRepresentation": V1CredentialRepresentation,
    "V1CreditCard": V1CreditCard,
    "V1DeploymentCreated": V1DeploymentCreated,
    "V1DeprecatedTargetJob": V1DeprecatedTargetJob,
    "V1DeregisterOrgFromSyncResponse": V1DeregisterOrgFromSyncResponse,
    "V1DownloadAudienceFileResponse": V1DownloadAudienceFileResponse,
    "V1DownloadBundleResponse": V1DownloadBundleResponse,
    "V1DownloadCreativeFileResponse": V1DownloadCreativeFileResponse,
    "V1DownloadTOSAgreementResponse": V1DownloadTOSAgreementResponse,
    "V1FTP": V1FTP,
    "V1GetMigrationEstimateResponse": V1GetMigrationEstimateResponse,
    "V1GetMigrationResponse": V1GetMigrationResponse,
    "V1GetNewMoverMatchCountResponse": V1GetNewMoverMatchCountResponse,
    "V1GetNotificationsResponse": V1GetNotificationsResponse,
    "V1GetOrderLineCPMsResponse": V1GetOrderLineCPMsResponse,
    "V1GetOrgsRegisteredForSyncResponse": V1GetOrgsRegisteredForSyncResponse,
    "V1GetTemplateResponse": V1GetTemplateResponse,
    "V1GetUserIPResponse": V1GetUserIPResponse,
    "V1GlobalSearchResponse": V1GlobalSearchResponse,
    "V1Invoice": V1Invoice,
    "V1JWT": V1JWT,
    "V1Job": V1Job,
    "V1ListAccountingJobsResponse": V1ListAccountingJobsResponse,
    "V1ListAudienceFoldersResponse": V1ListAudienceFoldersResponse,
    "V1ListAudiencesResponse": V1ListAudiencesResponse,
    "V1ListCampaignEventsResponse": V1ListCampaignEventsResponse,
    "V1ListCommissionGroupsResponse": V1ListCommissionGroupsResponse,
    "V1ListCreativeFoldersResponse": V1ListCreativeFoldersResponse,
    "V1ListCreditCardsResponse": V1ListCreditCardsResponse,
    "V1ListInvoicesResponse": V1ListInvoicesResponse,
    "V1ListOfString": V1ListOfString,
    "V1ListOrderLineEventsResponse": V1ListOrderLineEventsResponse,
    "V1ListOrderLinesResponse": V1ListOrderLinesResponse,
    "V1ListOrgSubscriptionsResponse": V1ListOrgSubscriptionsResponse,
    "V1ListOrgUsersResponse": V1ListOrgUsersResponse,
    "V1ListOrgsResponse": V1ListOrgsResponse,
    "V1ListPixelsResponse": V1ListPixelsResponse,
    "V1ListPublishersResponse": V1ListPublishersResponse,
    "V1ListRulesResponse": V1ListRulesResponse,
    "V1ListStatsResponse": V1ListStatsResponse,
    "V1ListTOSAgreementsResponse": V1ListTOSAgreementsResponse,
    "V1ListUsersResponse": V1ListUsersResponse,
    "V1MainDetails": V1MainDetails,
    "V1Message": V1Message,
    "V1Metrics": V1Metrics,
    "V1MigrateRequest": V1MigrateRequest,
    "V1MigrateResponse": V1MigrateResponse,
    "V1MigrationEstimate": V1MigrationEstimate,
    "V1MigrationSummary": V1MigrationSummary,
    "V1MoverMatchCount": V1MoverMatchCount,
    "V1Notification": V1Notification,
    "V1OAuthTokenRequest": V1OAuthTokenRequest,
    "V1OrderLineAdTypeSet": V1OrderLineAdTypeSet,
    "V1OrderLineAdded": V1OrderLineAdded,
    "V1OrderLineApproved": V1OrderLineApproved,
    "V1OrderLineArchived": V1OrderLineArchived,
    "V1OrderLineAttachedToCampaign": V1OrderLineAttachedToCampaign,
    "V1OrderLineAudienceSet": V1OrderLineAudienceSet,
    "V1OrderLineAuditConditionsSet": V1OrderLineAuditConditionsSet,
    "V1OrderLineCPMSet": V1OrderLineCPMSet,
    "V1OrderLineCampaign": V1OrderLineCampaign,
    "V1OrderLineCampaignAttached": V1OrderLineCampaignAttached,
    "V1OrderLineCampaignDetached": V1OrderLineCampaignDetached,
    "V1OrderLineCampaignSet": V1OrderLineCampaignSet,
    "V1OrderLineClickThroughURLSet": V1OrderLineClickThroughURLSet,
    "V1OrderLineCloned": V1OrderLineCloned,
    "V1OrderLineCompleted": V1OrderLineCompleted,
    "V1OrderLineConfigured": V1OrderLineConfigured,
    "V1OrderLineConsoleCampaignSet": V1OrderLineConsoleCampaignSet,
    "V1OrderLineConsoleClickThroughURLSet": V1OrderLineConsoleClickThroughURLSet,
    "V1OrderLineConsoleCreativesSet": V1OrderLineConsoleCreativesSet,
    "V1OrderLineConsoleSegmentsSet": V1OrderLineConsoleSegmentsSet,
    "V1OrderLineCostSet": V1OrderLineCostSet,
    "V1OrderLineCreated": V1OrderLineCreated,
    "V1OrderLineCreating": V1OrderLineCreating,
    "V1OrderLineCreativeAttached": V1OrderLineCreativeAttached,
    "V1OrderLineCreativeDetached": V1OrderLineCreativeDetached,
    "V1OrderLineCreativeSet": V1OrderLineCreativeSet,
    "V1OrderLineDeleted": V1OrderLineDeleted,
    "V1OrderLineDeployRetried": V1OrderLineDeployRetried,
    "V1OrderLineDeployed": V1OrderLineDeployed,
    "V1OrderLineDeployedToDestinationXandrCurate": V1OrderLineDeployedToDestinationXandrCurate,
    "V1OrderLineDeployedToDestinationXandrInvest": V1OrderLineDeployedToDestinationXandrInvest,
    "V1OrderLineDeployedWithConfig": V1OrderLineDeployedWithConfig,
    "V1OrderLineDeploymentCancelled": V1OrderLineDeploymentCancelled,
    "V1OrderLineDeploymentCancelling": V1OrderLineDeploymentCancelling,
    "V1OrderLineDeploymentDestinationConfigurationSet": V1OrderLineDeploymentDestinationConfigurationSet,
    "V1OrderLineDeploymentDestinationSet": V1OrderLineDeploymentDestinationSet,
    "V1OrderLineDestinationXandrConsoleCreated": V1OrderLineDestinationXandrConsoleCreated,
    "V1OrderLineDestinationXandrCurateCreated": V1OrderLineDestinationXandrCurateCreated,
    "V1OrderLineDestinationXandrCurateSet": V1OrderLineDestinationXandrCurateSet,
    "V1OrderLineDestinationXandrInvestCreated": V1OrderLineDestinationXandrInvestCreated,
    "V1OrderLineDestinationXandrInvestSet": V1OrderLineDestinationXandrInvestSet,
    "V1OrderLineDetachedFromCampaign": V1OrderLineDetachedFromCampaign,
    "V1OrderLineEndTimeSet": V1OrderLineEndTimeSet,
    "V1OrderLineErroredDeployingToDestinationXandrCurate": V1OrderLineErroredDeployingToDestinationXandrCurate,
    "V1OrderLineErroredDeployingToDestinationXandrInvest": V1OrderLineErroredDeployingToDestinationXandrInvest,
    "V1OrderLineEvent": V1OrderLineEvent,
    "V1OrderLineFlightDatesSet": V1OrderLineFlightDatesSet,
    "V1OrderLineFreeImpressionsSet": V1OrderLineFreeImpressionsSet,
    "V1OrderLineImpressionsPerDaySet": V1OrderLineImpressionsPerDaySet,
    "V1OrderLineImpressionsSet": V1OrderLineImpressionsSet,
    "V1OrderLineInvestCampaignSet": V1OrderLineInvestCampaignSet,
    "V1OrderLineInvestClickThroughURLSet": V1OrderLineInvestClickThroughURLSet,
    "V1OrderLineInvestCreativesSet": V1OrderLineInvestCreativesSet,
    "V1OrderLineInvestSegmentsSet": V1OrderLineInvestSegmentsSet,
    "V1OrderLineJobIdSet": V1OrderLineJobIdSet,
    "V1OrderLineLocked": V1OrderLineLocked,
    "V1OrderLineMinimumImpressionsSet": V1OrderLineMinimumImpressionsSet,
    "V1OrderLineNameSet": V1OrderLineNameSet,
    "V1OrderLineNoteAppended": V1OrderLineNoteAppended,
    "V1OrderLineNoteDeactivated": V1OrderLineNoteDeactivated,
    "V1OrderLineNoteUpdated": V1OrderLineNoteUpdated,
    "V1OrderLineOrgIdSet": V1OrderLineOrgIdSet,
    "V1OrderLinePaidSet": V1OrderLinePaidSet,
    "V1OrderLinePaused": V1OrderLinePaused,
    "V1OrderLinePlayed": V1OrderLinePlayed,
    "V1OrderLinePoIdSet": V1OrderLinePoIdSet,
    "V1OrderLinePoliticalFieldsSet": V1OrderLinePoliticalFieldsSet,
    "V1OrderLinePoliticalSet": V1OrderLinePoliticalSet,
    "V1OrderLinePrepaySet": V1OrderLinePrepaySet,
    "V1OrderLineRefIdSet": V1OrderLineRefIdSet,
    "V1OrderLineRejected": V1OrderLineRejected,
    "V1OrderLineRemoved": V1OrderLineRemoved,
    "V1OrderLineReviewStarted": V1OrderLineReviewStarted,
    "V1OrderLineSearch": V1OrderLineSearch,
    "V1OrderLineSegmentConfigSet": V1OrderLineSegmentConfigSet,
    "V1OrderLineStartTimeSet": V1OrderLineStartTimeSet,
    "V1OrderLineStateSetToActive": V1OrderLineStateSetToActive,
    "V1OrderLineStateSetToPaused": V1OrderLineStateSetToPaused,
    "V1OrderLineStatusSetToDeploying": V1OrderLineStatusSetToDeploying,
    "V1OrderLineStatusSetToDraft": V1OrderLineStatusSetToDraft,
    "V1OrderLineStatusSetToPrevious": V1OrderLineStatusSetToPrevious,
    "V1OrderLineStepFunctionSet": V1OrderLineStepFunctionSet,
    "V1OrderLineSubmittedForReview": V1OrderLineSubmittedForReview,
    "V1OrderLineTargetJobAttached": V1OrderLineTargetJobAttached,
    "V1OrderLineTargetJobDetached": V1OrderLineTargetJobDetached,
    "V1OrderLineTemplateTypeSet": V1OrderLineTemplateTypeSet,
    "V1OrderLineUnarchived": V1OrderLineUnarchived,
    "V1OrderLineUnlocked": V1OrderLineUnlocked,
    "V1OrderLineViantAdelphicConfigurationSet": V1OrderLineViantAdelphicConfigurationSet,
    "V1OrderLineViantAdelphicIDsSet": V1OrderLineViantAdelphicIDsSet,
    "V1OrderLineViantAdelphicIDsSetCreativeID": V1OrderLineViantAdelphicIDsSetCreativeID,
    "V1OrgData": V1OrgData,
    "V1OrgLogo": V1OrgLogo,
    "V1PayOrderLinesRequest": V1PayOrderLinesRequest,
    "V1Payment": V1Payment,
    "V1Pixel": V1Pixel,
    "V1PoliticalFields": V1PoliticalFields,
    "V1PoliticalTransparency": V1PoliticalTransparency,
    "V1Publisher": V1Publisher,
    "V1QueueDetails": V1QueueDetails,
    "V1QueueHealth": V1QueueHealth,
    "V1RebuildGlobalSearchRequest": V1RebuildGlobalSearchRequest,
    "V1RegisterOrgForSyncRequest": V1RegisterOrgForSyncRequest,
    "V1RegisterOrgForSyncResponse": V1RegisterOrgForSyncResponse,
    "V1RequestSupportRequest": V1RequestSupportRequest,
    "V1Result": V1Result,
    "V1ResultIDs": V1ResultIDs,
    "V1ResultMetrics": V1ResultMetrics,
    "V1Results": V1Results,
    "V1Review": V1Review,
    "V1ReviewRequested": V1ReviewRequested,
    "V1Rule": V1Rule,
    "V1RuleSet": V1RuleSet,
    "V1RunCommissionsRequest": V1RunCommissionsRequest,
    "V1RunCommissionsResponse": V1RunCommissionsResponse,
    "V1RunInvoicingRequest": V1RunInvoicingRequest,
    "V1RunInvoicingResponse": V1RunInvoicingResponse,
    "V1SendMessageRequest": V1SendMessageRequest,
    "V1StartSyncResponse": V1StartSyncResponse,
    "V1Stats": V1Stats,
    "V1StatsResponse": V1StatsResponse,
    "V1SubDetails": V1SubDetails,
    "V1SubscriptionFreeTrialMetadata": V1SubscriptionFreeTrialMetadata,
    "V1SubscriptionMetadata": V1SubscriptionMetadata,
    "V1SubscriptionPeriodMetadata": V1SubscriptionPeriodMetadata,
    "V1SyncOrg": V1SyncOrg,
    "V1TOSAgreement": V1TOSAgreement,
    "V1TargetJobSearch": V1TargetJobSearch,
    "V1UUID": V1UUID,
    "V1UnarchiveCampaignRequest": V1UnarchiveCampaignRequest,
    "V1UnarchiveCreativeRequest": V1UnarchiveCreativeRequest,
    "V1UnarchiveOrderLineRequest": V1UnarchiveOrderLineRequest,
    "V1UpdateOrderLineRequest": V1UpdateOrderLineRequest,
    "V1UploadCreativeFileRequest": V1UploadCreativeFileRequest,
    "V1UploadOrgLogoMetadata": V1UploadOrgLogoMetadata,
    "V1UploadTOSAgreementRequest": V1UploadTOSAgreementRequest,
    "V2ArchiveTargetRequest": V2ArchiveTargetRequest,
    "V2CreateJsonTargetRequest": V2CreateJsonTargetRequest,
    "V2DownloadTargetFileResponse": V2DownloadTargetFileResponse,
    "V2ListTargetFoldersResponse": V2ListTargetFoldersResponse,
    "V2ListTargetsResponse": V2ListTargetsResponse,
    "V2ReplaceFileMetaData": V2ReplaceFileMetaData,
    "V2ReplaceTargetFileRequest": V2ReplaceTargetFileRequest,
    "V2Target": V2Target,
    "V2TargetJob": V2TargetJob,
    "V2UnarchiveTargetRequest": V2UnarchiveTargetRequest,
    "V2UploadTargetFileRequest": V2UploadTargetFileRequest,
    "V3Advertiser": V3Advertiser,
    "V3AugmentedLineItem": V3AugmentedLineItem,
    "V3BrowserTarget": V3BrowserTarget,
    "V3BudgetInterval": V3BudgetInterval,
    "V3BuyerSeat": V3BuyerSeat,
    "V3CampaignCreative": V3CampaignCreative,
    "V3Condition": V3Condition,
    "V3ConsoleCampaign": V3ConsoleCampaign,
    "V3CountryTarget": V3CountryTarget,
    "V3CurateCampaign": V3CurateCampaign,
    "V3CustomDateRange": V3CustomDateRange,
    "V3DaypartTarget": V3DaypartTarget,
    "V3Deal": V3Deal,
    "V3DealList": V3DealList,
    "V3DealType": V3DealType,
    "V3DeploymentDestinationConfigurations": V3DeploymentDestinationConfigurations,
    "V3DirectMailCampaign": V3DirectMailCampaign,
    "V3DirectMailConfiguration": V3DirectMailConfiguration,
    "V3DomainListTarget": V3DomainListTarget,
    "V3EngagementRateTarget": V3EngagementRateTarget,
    "V3GenericDeployRequest": V3GenericDeployRequest,
    "V3InventoryAttributeTarget": V3InventoryAttributeTarget,
    "V3InventoryListTarget": V3InventoryListTarget,
    "V3InvestCampaign": V3InvestCampaign,
    "V3Language": V3Language,
    "V3LanguageTarget": V3LanguageTarget,
    "V3LegacyCampaign": V3LegacyCampaign,
    "V3LineItem": V3LineItem,
    "V3LineItemDeal": V3LineItemDeal,
    "V3LineItemPixel": V3LineItemPixel,
    "V3ListAdvertisersResponse": V3ListAdvertisersResponse,
    "V3ListDeploymentsResponse": V3ListDeploymentsResponse,
    "V3ListSegmentsResponse": V3ListSegmentsResponse,
    "V3ListXandrDealListsResponse": V3ListXandrDealListsResponse,
    "V3ListXandrDealsResponse": V3ListXandrDealsResponse,
    "V3ListXandrLanguagesResponse": V3ListXandrLanguagesResponse,
    "V3ListXandrRegionsResponse": V3ListXandrRegionsResponse,
    "V3MemberDataSharing": V3MemberDataSharing,
    "V3MemberDataSharingSegment": V3MemberDataSharingSegment,
    "V3NonSeamlessInsertionOrder": V3NonSeamlessInsertionOrder,
    "V3PoliticalContent": V3PoliticalContent,
    "V3Position": V3Position,
    "V3PositionTargets": V3PositionTargets,
    "V3Profile": V3Profile,
    "V3QueueRequest": V3QueueRequest,
    "V3Region": V3Region,
    "V3RegionTarget": V3RegionTarget,
    "V3RegistrationForm": V3RegistrationForm,
    "V3SGSegment": V3SGSegment,
    "V3SeamlessInsertionOrder": V3SeamlessInsertionOrder,
    "V3Segment": V3Segment,
    "V3SegmentConfig": V3SegmentConfig,
    "V3SegmentGroupTarget": V3SegmentGroupTarget,
    "V3Split": V3Split,
    "V3SupplyStrategies": V3SupplyStrategies,
    "V3UserInfo": V3UserInfo,
    "V3Valuation": V3Valuation,
    "V3Values": V3Values,
    "V3ViantAdelphicConfiguration": V3ViantAdelphicConfiguration,
    "V3ViantCreativeConfig": V3ViantCreativeConfig,
    "V3ViantSegmentConfig": V3ViantSegmentConfig,
    "V3XandrConsoleConfiguration": V3XandrConsoleConfiguration,
    "V3XandrCurateConfiguration": V3XandrCurateConfiguration,
    "V3XandrInvestConfiguration": V3XandrInvestConfiguration,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
                if (value !== undefined) {
                    instance[attributeType.name] = value;
                }
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "text/plain") {
            return String(data);
        }

        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "text/plain") {
            return rawData;
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        if (mediaType === "text/html") {
            return rawData;
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
