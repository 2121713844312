import { useState } from 'react'
import { Modal, Text, TextBody, showErrorMessage } from '@eltoro-ui/components'
import { V1CreditCard } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { styleTailwind } from 'Helpers'
import {
  BillingInfoInput,
  PaymentInfoInput,
  PaymentAuthorization,
} from './Steps'
import { StepIcon } from './StepIcon'
import './CCModal.scss'
import 'react-credit-cards/lib/styles.scss'

export type PaymentInfoType = {
  cardNumber?: string
  cardExp?: string
  cardNickname?: string
  cardIsDefault?: boolean
}

export type BillingInfoType = {
  firstName: string
  lastName: string
  address: string
  address2?: string
  city: string
  state: string
  zipcode: string
  country: string
}

export type PaymentAuthType = {
  signature: string
  isAuthorized: boolean
}

const StepButton = styleTailwind(
  'button',
  'CCModal__stepper-button flex items-center bg-gray relative flex-col text-center justify-center mr-2 relative rounded-full focus:ring',
)

export const CCModal = ({
  handleClose,
  setBillingRefresh,
  otherOrgCreditCards,
  currentlyEditingCard,
}: {
  handleClose: () => void
  setBillingRefresh?: (x: boolean) => void
  otherOrgCreditCards?: V1CreditCard[]
  currentlyEditingCard?: V1CreditCard
}) => {
  const { currentOrg, billingServiceApi, refreshCurrentOrg } = useAppContext()
  const [currentStep, setCurrentStep] = useState(1)
  const [step1Complete, setStep1Complete] = useState(false)
  const [step2Complete, setStep2Complete] = useState(false)
  const [step3Complete, setStep3Complete] = useState(false)
  const [errorSubmitting, setErrorSubmitting] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoType>({
    cardNumber: currentlyEditingCard?.cardNumber,
    cardExp: currentlyEditingCard?.expirationDate,
    cardNickname: currentlyEditingCard?.nickname,
    cardIsDefault: currentlyEditingCard?.isDefault,
  })
  const [billingInfo, setBillingInfo] = useState<BillingInfoType>({
    firstName: currentlyEditingCard?.firstName || '',
    lastName: currentlyEditingCard?.lastName || '',
    address: currentlyEditingCard?.address1 || '',
    address2: currentlyEditingCard?.address2 || '',
    city: currentlyEditingCard?.city || '',
    state: currentlyEditingCard?.state || '',
    zipcode: currentlyEditingCard?.zip || '',
    country: currentlyEditingCard?.country || '',
  })
  const [paymentAuth, setPaymentAuth] = useState<PaymentAuthType>({
    signature: currentlyEditingCard?.authorization?.signature || '',
    isAuthorized: currentlyEditingCard?.authorization?.approved || false,
  })
  const shouldUpdateAuth =
    !currentlyEditingCard?.authorization?.approved || // not previously approved
    !currentlyEditingCard?.authorization?.signature || // not previously signed
    currentlyEditingCard?.authorization?.signature !== paymentAuth.signature // previous signature is not same as current signature

  const handleSubmit = async (ccId?: string) => {
    if (currentOrg?.id && billingServiceApi) {
      if (!setBillingRefresh) return
      setSubmitting(true)
      if (ccId && paymentInfo.cardNumber) {
        billingServiceApi
          .advertisingPlatformServiceUpdateCreditCard(currentOrg.id, ccId, {
            cardNumber: paymentInfo.cardNumber,
            expirationDate: paymentInfo.cardExp,
            firstName: billingInfo.firstName,
            lastName: billingInfo.lastName,
            address1: billingInfo.address,
            address2: billingInfo.address2,
            city: billingInfo.city,
            state: billingInfo.state,
            zip: billingInfo.zipcode,
            country: billingInfo.country,
            nickname: paymentInfo.cardNickname,
            isDefault: paymentInfo.cardIsDefault,
            ...(shouldUpdateAuth
              ? {
                  authorization: {
                    approved: paymentAuth.isAuthorized,
                    signature: paymentAuth.signature,
                  },
                }
              : {}),
          })
          .then(() => {
            handleClose()
            refreshCurrentOrg()
          })
          .catch(() => setErrorSubmitting(true))
          .finally(() => setSubmitting(false))
      } else {
        if (
          !paymentInfo.cardNumber ||
          !paymentInfo.cardExp ||
          !billingInfo.firstName ||
          !billingInfo.lastName ||
          !billingInfo.address ||
          !billingInfo.city ||
          !billingInfo.zipcode
        ) {
          showErrorMessage('Missing information for credit card', '')
          setErrorSubmitting(true)
          return
        }
        if (!paymentAuth.isAuthorized || !paymentAuth.signature) {
          showErrorMessage('Missing authorization for credit card', '')
          setErrorSubmitting(true)
          return
        }
        billingServiceApi
          .advertisingPlatformServiceCreateCreditCard(currentOrg.id, {
            creditCard: {
              cardNumber: paymentInfo.cardNumber,
              expirationDate: paymentInfo.cardExp,
              firstName: billingInfo.firstName,
              lastName: billingInfo.lastName,
              address1: billingInfo.address,
              address2: billingInfo.address2,
              city: billingInfo.city,
              state: billingInfo.state,
              zip: billingInfo.zipcode,
              nickname: paymentInfo.cardNickname,
              country: billingInfo.country,
              isDefault: paymentInfo.cardIsDefault,
              authorization: {
                approved: paymentAuth.isAuthorized,
                signature: paymentAuth.signature,
              },
            },
          })
          .then(() => {
            setBillingRefresh(true)
            handleClose()
          })
          .catch(() => setErrorSubmitting(true))
          .finally(() => setSubmitting(false))
      }
    }
  }

  const navToStep = (step: 1 | 2 | 3) => {
    setErrorSubmitting(false)
    setPaymentAuth({
      signature: currentlyEditingCard?.authorization?.signature || '',
      isAuthorized: currentlyEditingCard?.authorization?.approved || false,
    })
    // Payment Information
    if (step === 1) setCurrentStep(1)
    // Billing Information
    if (step === 2) setCurrentStep(2)
    // Signature
    if (step === 3) setCurrentStep(3)
  }

  return (
    <Modal
      offClick={handleClose}
      className="CCModal"
      header={
        currentlyEditingCard ? (
          <div className="CCModal__header--edit flex flex-col">
            <Text weight="bold" size="xl" className="flex">
              <span
                className={`text-3xl font-light uppercase ${
                  paymentInfo.cardNickname ? 'RightVerticalPipe' : ''
                }`}
              >
                Update card
              </span>
              {paymentInfo.cardNickname ? (
                <span className="text-2xl">{paymentInfo.cardNickname}</span>
              ) : null}
            </Text>
            <TextBody>Update your credit card information below.</TextBody>
          </div>
        ) : (
          <div className="CCModal__header--add flex flex-col">
            <Text weight="bold" size="xl" className="CCModal__header--add-text">
              <span
                className={`text-2xl font-light ${
                  currentOrg && currentOrg.name ? 'RightVerticalPipe' : ''
                }`}
              >
                Add a new credit card
              </span>
              {currentOrg && currentOrg.name ? (
                <span className="text-2xl">{currentOrg.name}</span>
              ) : null}
            </Text>
            <TextBody>
              Enter your credit card information below. An org may store up to
              ten (10) credit cards
            </TextBody>
          </div>
        )
      }
    >
      <div className="CCModal__content flex gap-4">
        <div className="CCModal__stepper relative flex flex-col items-center justify-between gap-7">
          {/* Stepper */}
          {/* Step 1 */}
          <StepButton
            role="button"
            className="step-1 pointer-events-none"
            onClick={() => navToStep(1)}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Enter') navToStep(1)
            }}
            tabIndex={0}
          >
            <StepIcon
              step={1}
              currentStep={currentStep}
              label="Card Info"
              icon="credit-card-front"
              success={step1Complete}
            />
          </StepButton>

          {/* Step 2 */}
          <StepButton
            role="button"
            className="CCmodal__stepper-button step2 pointer-events-none"
            onClick={() => navToStep(2)}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Enter') navToStep(2)
            }}
            tabIndex={0}
          >
            <StepIcon
              step={2}
              currentStep={currentStep}
              label="Billing Info"
              icon="address-book"
              success={step2Complete}
            />
          </StepButton>

          {/* Step 3 */}
          <StepButton
            role="button"
            className="step3 pointer-events-none"
            onClick={() => navToStep(3)}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              if (e.key === 'Enter') navToStep(3)
            }}
            tabIndex={0}
          >
            <StepIcon
              step={3}
              currentStep={currentStep}
              label="Authorize"
              icon="pen-field"
              success={step3Complete}
            />
          </StepButton>
        </div>
        {/* steps content */}
        <div className="CCModal__steps flex-grow flex-col py-2">
          {currentStep === 1 && (
            <PaymentInfoInput
              navToStep={navToStep}
              setComplete={() => setStep1Complete(true)}
              paymentInfo={paymentInfo}
              setPaymentInfo={setPaymentInfo}
              isEditing={!!currentlyEditingCard}
              otherOrgCreditCards={otherOrgCreditCards}
            />
          )}
          {currentStep === 2 && (
            <BillingInfoInput
              setComplete={() => setStep2Complete(true)}
              navToStep={navToStep}
              billingInfo={billingInfo}
              setBillingInfo={setBillingInfo}
            />
          )}
          {currentStep === 3 && (
            <PaymentAuthorization
              setComplete={() => setStep3Complete(true)}
              handleSubmit={handleSubmit}
              navToStep={navToStep}
              paymentAuth={paymentAuth}
              setPaymentAuth={setPaymentAuth}
              nickname={paymentInfo.cardNickname}
              currentlyEditingCard={currentlyEditingCard}
              errorSubmitting={errorSubmitting}
              submitting={submitting}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
