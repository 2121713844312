import React, { FC } from 'react'
import { Text, TextHeader } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import { Card } from '../Card'
import { AnimatedStatusIcon, Confetti } from '../../Assets/AnimationComponents'
import './Notification.scss'

interface NotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Notification's CSS classes so you can use Tailwind classes if needed" */
  className?: string
  /** The Header for the Notification default is "Your order is complete!" */
  header?: string
  /** Is it icon? defaults to no */
  icon?: boolean
  /** Type of ET icon, expects icon name, default to 'target' */
  iconType?: string
  /** The ETicon CSS class, used for Tailwind styles */
  iconClass?: string
  /** The image for the Notification */
  image?: string | undefined
  /** The alt text for the image */
  imageAlt?: string | undefined
  /** The image height */
  imageHeight?: number | undefined
  /** The image width */
  imageWidth?: number | undefined
  /** Is is a column or row, or any class name you desire, defaults to column */
  layOut?: string | undefined
  /** the children of the component */
  children?: React.ReactNode
  /** show the Notification when it's big deal */
  confetti?: boolean
  /** max-width of Notification */
  maxWidth?: string
  /** Add FC comp to account for MapPoly/VR */
  comp?: React.ReactNode
  /** Option to remove the checkmark */
  removeCheckmark?: boolean
}

export const Notification: FC<NotificationProps> = ({
  className = '',
  header = 'Your order is complete!',
  children,
  icon = false,
  iconType = 'target',
  iconClass = '',
  image,
  imageAlt,
  imageHeight,
  imageWidth,
  layOut = 'column',
  confetti = false,
  maxWidth = '100%',
  comp,
  removeCheckmark = false,
  ...other
}) => {
  return (
    <Card className={`${confetti ? 'Card--confetti' : 'Card--simple'}`}>
      {confetti && <Confetti number={100} />}
      <div
        className={`Notification my-auto Notification__${layOut} Notification__${className} ${className} ${
          icon ? 'Icon-' : ''
        }${iconType}`}
      >
        <figure className="Notification__image">
          {icon && (
            <ETIcon className={`ETIcon--full ${iconClass}`} icon={iconType} />
          )}
          {!icon && image && (
            <img
              src={image}
              alt={imageAlt}
              height={imageHeight}
              width={imageWidth}
            />
          )}
          {!icon && comp}
          {!removeCheckmark && (
            <AnimatedStatusIcon cssClass="Checkmark" kind="checkMark" />
          )}
        </figure>
        <div className="Notification__message flex flex-col gap-1">
          {confetti ? (
            <TextHeader className="Confetti__header" type={1}>
              {header}
            </TextHeader>
          ) : (
            <TextHeader className="MessageHeader text-success text-3xl uppercase">
              {header}
            </TextHeader>
          )}
          <Text className="MessageBody pt-2 text-xl" tag="div">
            {children}
          </Text>
        </div>
      </div>
    </Card>
  )
}
