import { useState, useEffect } from 'react'
import { Fa, Tabs, TextHeader } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { CreativeUploader, CreativeUploaderStatus } from 'Components'
import {
  CreativeUploaderPropsType,
  CreativeUploaderStatusPropsType,
} from 'types'
import { CreativesLibraryPage } from 'Pages'
import { AdSetDisplay } from '../components'
import './VideoAdForm.scss'

export const VideoAdForm = ({
  uploaderProps,
  uploaderStatusProps,
  uploadType,
  isPoliticalAndCampaignCreation,
}: {
  uploaderProps: CreativeUploaderPropsType
  uploaderStatusProps: CreativeUploaderStatusPropsType
  uploadType?: 'video' | 'OTT'
  isPoliticalAndCampaignCreation?: boolean
}) => {
  const [selected, setSelected] = useState('')
  const [selectedVideoCreatives, setSelectedVideoCreatives] = useState<
    Creativeservicev1Creative[]
  >([])

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => {
    setSelected(buttonId)
  }
  const handleSelect = (creative: Creativeservicev1Creative) => {
    setSelectedVideoCreatives((prev) => [...prev, creative])
    setSelected('GetFromLibrary')
  }

  const handleUploadSideEffect = () => {
    setSelected('Upload')
  }

  const handleRemove = (creativeId: string) => {
    setSelectedVideoCreatives((prev) => prev.filter((f) => f.id !== creativeId))
  }

  useEffect(() => {
    if (selected !== 'Upload') uploaderStatusProps.setFiles([])
  }, [])
  return (
    <div className="VideoForm">
      <div className="VideoForm__Buttons my-2 flex w-full">
        <Tabs
          classNamePanel="VideoForm__tabs flex-1 w-full"
          tabs={[
            {
              id: 'videoAd',
              label: (
                <div className="VideoAdForm__header-text-icon flex w-auto items-center justify-center gap-2">
                  <Fa
                    icon={uploadType === 'video' ? 'video' : 'tv'}
                    size={2}
                    type="duotone"
                  />
                  <TextHeader
                    className="VideoAdForm__header-text font-normal normal-case"
                    type={5}
                  >
                    Upload a new file
                  </TextHeader>
                </div>
              ),
              onClick: () => handleButtonClick,
              defaultTab: selected === 'videoAd',
              component: (
                <CreativeUploader
                  handleSelect={handleSelect}
                  onClickSideEffect={handleUploadSideEffect}
                  uploadType={
                    uploadType === 'video'
                      ? 'CREATIVE_TYPE_VIDEO'
                      : 'CREATIVE_TYPE_OTT'
                  }
                  {...uploaderProps}
                  isInPoliticalCampaignCreation={isPoliticalAndCampaignCreation}
                />
              ),
            },
            {
              id: 'GetFromLibrary',
              label: (
                <>
                  <span
                    aria-label="Select from Creative Library"
                    data-tooltip="left"
                  >
                    <Fa
                      className="text-primary"
                      icon="folders"
                      size={3}
                      type="duotone"
                    />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text">
                    Select from Creative Library
                  </span>
                </>
              ),
              onClick: () => handleButtonClick,
              defaultTab: selected === 'GetFromLibrary',
              component: (
                <CreativesLibraryPage
                  selectedCreatives={selectedVideoCreatives}
                  addToCreativeSet={handleSelect}
                  removeFromCreativeSet={handleRemove}
                  modalPreview
                  windowed
                  filterByType={
                    uploadType === 'video'
                      ? ['CREATIVE_TYPE_VIDEO']
                      : ['CREATIVE_TYPE_OTT']
                  }
                />
              ),
            },
          ]}
        />
      </div>
      {uploaderProps.uploadFiles && selected === 'Upload' && (
        <CreativeUploaderStatus {...uploaderStatusProps} />
      )}
      <AdSetDisplay
        shouldOpen={!!selectedVideoCreatives.length}
        selectedCreatives={selectedVideoCreatives}
        setSelectedCreatives={setSelectedVideoCreatives}
        showBannerChecklist={false}
        type={uploadType || 'video'}
      />
    </div>
  )
}
