import { useState } from 'react'
import { Button, Fa, Modal, TextHeader } from '@eltoro-ui/components'
import { useNavigate } from 'react-router-dom'
import { styleTailwind } from 'Helpers'
import { TextInputWithErrorText } from 'Components'
import { useAppContext } from 'Contexts'
import {
  globalSearchOrderLineOptions,
  globalSearchAudienceOptions,
  globalSearchCreativeOptions,
} from './searchOptionConfigs'

const AdvancedSearchLabel = styleTailwind('p', 'text-l font-light')

// Commented out stuff is for supporting multiple statuses

const AdvancedSearchRow: React.FC<{
  label: string
  options: Array<{ label: string; value: string | number }>
  // selectedOptions: Array<string>
  selectedOption?: string
  // onChange: (value: Array<string>) => void
  onChange: (value: string) => void
}> = ({ label, options, selectedOption, onChange }) => {
  return (
    <div className="AdvancedSearchRow flex flex-wrap items-center justify-between gap-10">
      <div className="flex flex-col">
        <AdvancedSearchLabel>{label}</AdvancedSearchLabel>
      </div>
      {/* <MultiSelectDropdown
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
      /> */}
      <div className="relative m-2 flex max-w-sm items-center">
        <select
          className="form-select bg-base border-b-grey-300 m-0 w-full min-w-[20ch] appearance-none border-b px-3 py-1.5 outline-none focus:ring disabled:opacity-40"
          onChange={(e) => {
            onChange(e.target.value)
          }}
          placeholder="Choose exclusion timeframe"
          defaultValue={selectedOption || 'All'}
        >
          {options.map((option) => (
            <option key={`${label}_${option.value}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Fa
          icon="chevron-down"
          size={1}
          className="text-grey-500 pointer-events-none absolute right-1"
        />
      </div>
    </div>
  )
}

export const AdvancedSearchModal: React.FC<{
  setShowAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setShowAdvancedSearch }) => {
  // const [audienceType, setAudienceType] = useState<Array<V1JobType | 'all'>>([])
  const [audienceType, setAudienceType] = useState<number>()
  // const [creativeType, setCreativeType] = useState<
  //   Array<Creativeservicev1Type | 'all'>
  // >([])
  const [creativeType, setCreativeType] = useState<number>()
  // prettier-ignore
  // const [
  //   orderLineStatus,
  //   setOrderLineStatus,
  // ] = useState<Array<V1OrderLineStatus | 'all'>>([])
  const [
    orderLineStatus,
    setOrderLineStatus,
  ] = useState<number>()
  // const [keywords, setKeywords] = useState<Array<string>>([])
  const [keyword, setKeyword] = useState<string>('')
  const [include, setInclude] = useState<Array<string>>([])
  const { isAdmin } = useAppContext()

  const history = useNavigate()

  const handleReset = () => {
    // setAudienceType([])
    // setCreativeType([])
    // setOrderLineStatus([])
    // setKeywords([])
    setAudienceType(undefined)
    setCreativeType(undefined)
    setOrderLineStatus(undefined)
    setKeyword('')
    setInclude([])
  }

  const hasNoSearchableSelections =
    // !audienceType.length &&
    // !creativeType.length &&
    // !orderLineStatus.length &&
    // !keywords.length &&
    !audienceType &&
    !creativeType &&
    !orderLineStatus &&
    !keyword &&
    !include.length

  const handleSubmit = () => {
    const query = new URLSearchParams()
    // if (audienceType.length)
    //   query.set(
    //     'audienceType',
    //     audienceType.filter((val) => val !== 'all').join(','),
    //   )
    if (audienceType) query.set('audienceType', audienceType.toString())

    // if (creativeType.length)
    //   query.set(
    //     'creativeType',
    //     creativeType.filter((val) => val !== 'all').join(','),
    //   )
    if (creativeType) query.set('creativeType', creativeType.toString())

    // if (orderLineStatus.length)
    //   query.set(
    //     'orderLineStatus',
    //     orderLineStatus.filter((val) => val !== 'all').join(','),
    //   )
    if (orderLineStatus)
      query.set('orderLineStatus', orderLineStatus.toString())

    // if (keywords.length) {
    //   keywords.map((key) => query.append('keyword', key))
    // }
    query.set('keyword', keyword)
    if (include.length) {
      include.forEach((inc) => query.append('include', inc))
    } else {
      const inclusions = ['audiences', 'creatives', 'orgs', 'order lines']
      inclusions.forEach((inc) => query.append('include', inc))
    }

    // Close modal
    setShowAdvancedSearch(false)
    // Nav to search page with query
    history(`/search?${query}`)
  }

  const handleInclude = (inclusion: string, value: Array<string>) => {
    if (value.length) {
      setInclude((prev) => {
        return [...new Set([...prev, inclusion])]
      })
    } else {
      setInclude((prev) => prev.filter((item) => item !== inclusion))
    }
  }

  return (
    <Modal
      offClick={() => setShowAdvancedSearch(false)}
      positionFromTop="4rem"
      className="AdvancedSearchModal py-2"
      header={<TextHeader type={4}>Advanced Search</TextHeader>}
    >
      <div className="px-4">
        <div className="flex flex-col gap-6">
          <AdvancedSearchRow
            label="Audience Types"
            options={globalSearchAudienceOptions}
            // selectedOptions={audienceType}
            selectedOption={audienceType?.toString()}
            onChange={(value) => {
              handleInclude('audiences', [value])
              // setAudienceType(value as Array<V1JobType | 'all'>)
              setAudienceType(+value)
            }}
          />
          <AdvancedSearchRow
            label="Creative Types"
            options={globalSearchCreativeOptions}
            // selectedOptions={creativeType}
            selectedOption={creativeType?.toString()}
            onChange={(value) => {
              handleInclude('creatives', [value])
              // setCreativeType(value as Array<Creativeservicev1Type | 'all'>)
              setCreativeType(+value)
            }}
          />
          <AdvancedSearchRow
            label="Order Line Status"
            options={globalSearchOrderLineOptions(isAdmin)}
            // selectedOptions={orderLineStatus}
            selectedOption={orderLineStatus?.toString()}
            onChange={(value) => {
              handleInclude('order lines', [value])
              // setOrderLineStatus(
              //   value as Array<V1OrderLineStatus | 'all'>,
              // )
              setOrderLineStatus(+value)
            }}
          />
          <div className="flex w-full items-center justify-between gap-6 pr-5">
            <div>
              {/* <AdvancedSearchLabel>Keywords</AdvancedSearchLabel>
              <p className="text-xs">
                Press enter to add each keyword, or seperate with commas.
              </p> */}
              <AdvancedSearchLabel>Keyword</AdvancedSearchLabel>
            </div>
            {/* TODO: Put TagInput back when global search supports multiple keywords */}
            {/* <TagInput
              onAddTag={(tags) => setKeywords(tags)}
              addTagsInBulk
              validationPattern="^[a-zA-Z0-9_-\s]*$"
              value={keywords}
            /> */}
            <TextInputWithErrorText
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              isValid={(() => {
                const regex = new RegExp(/^[a-zA-Z0-9._-\s]*$/, 'i')
                return regex.test(keyword)
              })()}
              popoverContent={
                <div>
                  Keywords must be alphanumeric (a-z, 0-9) and can include
                  dashes (-) and underscores (_)
                </div>
              }
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-4">
          <Button onClick={handleReset} disabled={hasNoSearchableSelections}>
            Reset
          </Button>
          <Button onClick={handleSubmit} disabled={hasNoSearchableSelections}>
            Search
          </Button>
        </div>
      </div>
    </Modal>
  )
}
