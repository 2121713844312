import React from 'react'
import { Fa } from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'

export type SuccessStepIllustrationConfig = {
  icon: string
  text: string
}[]

const DiagonalDashedLine = ({ reverse }: { reverse?: boolean }) => {
  const dimensions = reverse
    ? { x1: '0', y1: '100%', x2: '100%', y2: '0' }
    : { x1: '0', y1: '0', x2: '100%', y2: '100%' }

  return (
    <svg className="h-[2.5rem] w-[5rem]">
      <line
        strokeDasharray="15"
        {...dimensions}
        className="stroke-[color:var(--color-grey-900)]"
      />
    </svg>
  )
}

const Icon = styleTailwind(Fa, 'text-[color:var(--color-tertiary)]')
const LineContainer = styleTailwind('div', 'flex items-center gap-2')
const LineText = styleTailwind('p', 'text-primary-300 font-bold')

export const SuccessStepIllustration: React.FC<{
  config: SuccessStepIllustrationConfig
}> = ({ config }) => {
  return (
    <div className="SuccessStepIllustration flex flex-col items-center gap-4">
      {config.map((line, index) => {
        const lineItems = [
          <Icon icon={line.icon} size={5} />,
          <LineText>{line.text}</LineText>,
        ]
        const odd = index % 2 !== 1
        return (
          <>
            <LineContainer>
              {odd ? lineItems : lineItems.reverse()}
            </LineContainer>
            {index !== config.length - 1 && (
              <DiagonalDashedLine reverse={odd} />
            )}
          </>
        )
      })}
    </div>
  )
}
