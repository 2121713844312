/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1SyncOrg {
    'id'?: string;
    'childOrgs'?: boolean;
    'creatives'?: boolean;
    'targets'?: boolean;
    'orderLines'?: boolean;
    'users'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "childOrgs",
            "baseName": "child_orgs",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "targets",
            "baseName": "targets",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "users",
            "baseName": "users",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1SyncOrg.attributeTypeMap;
    }

    public constructor() {
    }
}

