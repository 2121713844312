import { useState, useRef } from 'react'
import { TextHeader, TextInput, Button, Modal } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'

export const OLPauseNoteModal = ({
  handlePause,
  cancelFunc,
}: {
  handlePause: (note: string) => void
  cancelFunc: (x: boolean) => void
}) => {
  const [note, setNote] = useState('')
  const pauseRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([pauseRef], () => cancelFunc(false))

  return (
    <Modal classNameContent="OLPauseNoteModal__content overflow-visible">
      <div
        className="OLPauseNoteModal__ref flex min-w-[40rem] flex-col justify-end gap-2"
        ref={pauseRef}
      >
        <TextHeader type={4} className="OLPauseNoteModal__header">
          Add Pause Note
        </TextHeader>
        <TextInput
          onChange={(e) => {
            return setNote(e.target.value)
          }}
          placeholder="Reason for pausing..."
          value={note}
        />
        <div className="OLPauseNoteModal__buttons ButtonGroup ml-auto flex gap-4 pt-2">
          <Button
            onClick={() => {
              cancelFunc(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handlePause(note)
            }}
            disabled={!note}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}
