import { useState } from 'react'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import {
  Text,
  showErrorMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'
import { useAppContext, useCampaignContext } from 'Contexts'
import { ImpressionsOLRow } from './ImpressionsOLRow'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
// import { IMPRESSION_MAX, IMPRESSION_MIN } from '../shared'

export const ImpressionsEditGridRow = styleTailwind(
  'div',
  'ImpressionsEdit__GridRow grid grid-cols-3 laptopsmall:flex laptopsmall:flex-wrap laptopsmall:justify-between',
)
const TableHeader = styleTailwind(
  'div',
  'EditOLTable__header-text flex flex-col',
)
const TableSubHeader = styleTailwind(
  Text,
  'text-s tint-gray-700 font-regular normal-case',
)
export type ImpressionsChangeType = { olId: string; impressions: number }

export const ImpressionsEdit = ({
  className = '',
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [orderLineChanges, setOrderLineChanges] = useState<
    ImpressionsChangeType[]
  >([])
  const { campaignServiceApi, currentOrg, roles } = useAppContext()
  const { setRefresh } = useCampaignContext()

  const handleUpdate = () => {
    Promise.all(
      orderLineChanges.map((change) => {
        const ol = selectedOls.find((c) => c.id === change.olId)
        if (!campaignServiceApi || !currentOrg?.id) return Promise.resolve({})
        if (
          ol?.minimumImpressions &&
          change.impressions < ol.minimumImpressions &&
          roles &&
          roles.includes('nextgen_admin')
        ) {
          return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
            change.olId,
            currentOrg?.id,
            {
              impressions: change.impressions,
              minimumImpressions: change.impressions,
            },
          )
        }
        return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          change.olId,
          currentOrg?.id,
          {
            impressions: change.impressions,
          },
          'impressions',
        )
      }),
    )
      .then(() => {
        showSuccessMessage('Budget was updated successfully', '')
      })
      .catch(() => {
        showErrorMessage('Budget update failed', '')
      })
      .then(() => {
        onCloseModal()
        if (setRefreshCampaign) {
          setRefreshCampaign(true)
        }
        setRefresh(true)
      })
  }

  return (
    <div className={`ImpressionsEdit ${className}`}>
      <ImpressionsEditGridRow className="ImpressionsEdit__grid-header EditOLTable__header StickyHeader laptopsmall:hidden">
        <TableHeader>Order Line Name</TableHeader>
        <TableHeader>
          Total Impressions
          <TableSubHeader></TableSubHeader>
        </TableHeader>
        <TableHeader>
          Frequency
          <TableSubHeader>Impressions per target per day</TableSubHeader>
        </TableHeader>
        {/* <TableHeader>
          Impressions
          <TableSubHeader>Impressions per target per flight</TableSubHeader>
        </TableHeader> */}
      </ImpressionsEditGridRow>
      <div className="EditOLTable__body">
        {selectedOls.map((ol, i) => (
          <ImpressionsOLRow
            orderLine={ol}
            key={`ImpressionsOLRow__${ol.id}_${i}`}
            onChange={(change) => {
              if (
                ol?.minimumImpressions &&
                change.impressions < ol?.minimumImpressions &&
                !roles?.includes('nextgen_admin')
              )
                return

              setOrderLineChanges((prev) => {
                const filtered = prev.filter((p) => p.olId !== change.olId)
                return [...filtered, change]
              })
            }}
          />
        ))}
      </div>
      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!orderLineChanges.length}
      />
    </div>
  )
}
