import { Text, TextHeader } from '@eltoro-ui/components'
import { PreviewFileWithId } from 'Pages'
import React from 'react'

export const NativePreview: React.FC<{
  title?: string
  description?: string
  icon?: PreviewFileWithId
  logo?: PreviewFileWithId
  sponsor?: string
  callToAction?: string
}> = ({ title, description, logo, icon, sponsor, callToAction }) => {
  return (
    <div className="NativeBannerForm__preview mt-20 flex h-auto flex-col items-center gap-2">
      <div className="NativeBannerForm___preview-inner flex w-full flex-col justify-center gap-2 shadow-xl">
        {!title && !icon && !logo && !sponsor && !description && (
          <div className="NativeBannerPreviewContainer__placeholder border-default border-tint-light bg-base flex h-full w-full flex-col items-center justify-center text-center">
            <Text
              tag="h2"
              className="NativeBannerPreviewContainer__placeholder-text flex w-full flex-col gap-2 px-8 py-16 text-center font-light"
            >
              <span className="block text-6xl uppercase leading-none">
                Preview
              </span>
              <span className="-mt-3 block text-4xl leading-none">
                of your Native Banner ad
              </span>

              <span className="text-l pt-6 italic leading-normal">
                One possible way your ad could be displayed
              </span>
            </Text>
          </div>
        )}
        {(title || icon || logo || sponsor || description) && (
          <>
            <div className="text-center text-3xl font-light uppercase leading-none">
              Native banner preview
            </div>
            <div className="NativeBannerPreview__ad border-grey-300 bg-tint-light border-thin flex w-full flex-col p-5 text-center">
              {logo && (
                <figure className="Preview_logo flex flex-col items-center justify-center">
                  <img
                    className="block object-cover"
                    src={logo && logo.fileData.preview}
                    alt="Preview Logo"
                    height={1200}
                    width={627}
                  />
                </figure>
              )}
              <div className="Preview_title flex flex-col gap-4 py-4 text-center">
                <TextHeader
                  className="Preview_title-h3 text-center !text-4xl leading-none"
                  type={3}
                >
                  {title || "'Title'"}
                </TextHeader>
                <Text tag="p" className="Preview_description min-w-full">
                  {description || "'Description'"}
                </Text>
              </div>

              {callToAction && (
                <Text className="Button bg-base-info border-base-info animate-slidedown mx-auto my-4 w-auto py-2 text-center text-base leading-none">
                  {callToAction}
                </Text>
              )}
              {icon && (
                <figure className="Preview_logo mx-auto my-4 flex flex-col items-center justify-center">
                  <img
                    src={icon && icon.fileData.preview}
                    alt="Preview icon"
                    height={80}
                    width={80}
                  />
                </figure>
              )}
              <div className="PreviewSponsor mx-auto my-1">
                {sponsor || "'Sponsor'"}
              </div>
            </div>
          </>
        )}
      </div>

      {(title || icon || logo || sponsor || description) && (
        <Text className="NativeBannerForm_disclaimer block w-full pt-2 text-center text-sm italic leading-normal">
          <strong>NOTE:</strong> This is just one possible way your ad could be
          displayed
        </Text>
      )}
    </div>
  )
}
