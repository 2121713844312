import React, { useState, useEffect, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { Creativeservicev1Creative, Creativeservicev1Type } from 'next-gen-sdk'
import { Uploader, PreviewType, Fa, TextHeader } from '@eltoro-ui/components'
import { readableCreativeType, styleTailwind } from 'Helpers'
import { ThemeContext, useAppContext } from 'Contexts'
import { ErrorMsg, WarningMsg } from 'Components'
import { PreviewFileWithId } from 'Pages'
import { BannerText, VideoText, OTTText, HTML5Text } from './UploadText'
import { creativeValidator } from './helpers'
import classNames from 'classnames'

const CenterFlex = styleTailwind('div', 'CenterFlex mt-10')

export const CreativeUploader: React.FC<{
  setFiles: React.Dispatch<React.SetStateAction<PreviewFileWithId[]>>
  handleUpload: (
    fileandId: PreviewFileWithId,
    token: string,
    onUpload?: (creative: Creativeservicev1Creative) => void,
  ) => Promise<void>
  handleSelect?: (creative: Creativeservicev1Creative) => void
  uploadType?: Creativeservicev1Type
  onClickSideEffect?: (buttonId: string) => void
  hideTitle?: boolean
  isInPoliticalCampaignCreation?: boolean
}> = ({
  uploadType,
  setFiles,
  handleUpload,
  handleSelect,
  onClickSideEffect,
  isInPoliticalCampaignCreation,
}) => {
  const [error, setError] = useState<string>('')
  const [warning, setWarning] = useState<string>('')
  const [rerender, setRerender] = useState<boolean>(false)
  const [reqType, setReqType] = useState<Creativeservicev1Type | undefined>(
    uploadType,
  )
  const { tok } = useAppContext()
  const { name: theme } = useContext(ThemeContext)
  useEffect(() => {
    setReqType(uploadType)
    setRerender(true)
    setWarning('')
    setError('')
    setFiles([])
  }, [uploadType])

  const getUrl = (uploadType: Creativeservicev1Type) => {
    switch (uploadType) {
      case 'CREATIVE_TYPE_BANNER':
        return 'https://support.eltoro.com/hc/en-us/articles/4443240942491-Banner-Ads'
      case 'CREATIVE_TYPE_VIDEO':
        return 'https://support.eltoro.com/hc/en-us/articles/5024289285403-Videos'
      case 'CREATIVE_TYPE_OTT':
        return 'https://support.eltoro.com/hc/en-us/articles/5024789555611-OTT-Over-the-Top-'
      case 'CREATIVE_TYPE_HTML5':
        return 'https://support.eltoro.com/hc/en-us/articles/4770007799195-HTML5s'
      case 'CREATIVE_TYPE_UNSPECIFIED':
      case undefined:
      default:
        return 'https://support.eltoro.com/hc/en-us/articles/6140809628827-Creative-Best-Practices'
    }
  }

  const getText = (uploadType: Creativeservicev1Type) => {
    switch (uploadType) {
      case 'CREATIVE_TYPE_BANNER':
        return ' Banner '
      case 'CREATIVE_TYPE_VIDEO':
        return ' Video '
      case 'CREATIVE_TYPE_OTT':
        return ' OTT '
      case 'CREATIVE_TYPE_HTML5':
        return ' HTML5 '
      case 'CREATIVE_TYPE_UNSPECIFIED':
      case undefined:
      default:
        return ''
    }
  }

  useEffect(() => {
    if (rerender) {
      setRerender(false)
    }
  }, [rerender])
  return (
    <div className="CreativeUploader my-2 flex flex-wrap">
      <CenterFlex
        type="submit"
        onKeyDown={onClickSideEffect}
        className="CreativeUploader__Wrapper-button laptopsmall:min-w-[50%] w-full flex-1 flex-col"
        onClick={onClickSideEffect}
      >
        {uploadType === 'CREATIVE_TYPE_HTML5' && (
          <div
            className={classNames(
              'bg-danger-600 text-l rounded-m mb-2 flex items-center justify-center gap-2 p-4 text-white',
              { 'bg-danger-300': theme === 'darkBlue' || theme === 'dark' },
            )}
          >
            We currently do not support this media type for political
            advertising.
          </div>
        )}
        {!rerender && (
          <div className="CreativeUploader__wrap relative flex w-full flex-col items-center gap-4">
            <Uploader
              isStyled
              onDrop={(files: PreviewType[], rejectedFiles) => {
                setError('')
                setWarning('')
                if (
                  uploadType === 'CREATIVE_TYPE_HTML5' &&
                  rejectedFiles &&
                  rejectedFiles.length > 0
                ) {
                  setError('too-many-files')
                } else {
                  files.forEach(async (currentFile: PreviewType) => {
                    const validatorMsg =
                      uploadType &&
                      reqType &&
                      (await creativeValidator(currentFile, reqType))
                    setTimeout(() => {
                      if (validatorMsg?.type === 'error') {
                        setError(validatorMsg.msg)
                      } else {
                        if (validatorMsg?.type === 'warning')
                          setWarning(validatorMsg.msg)
                        const fileWithId: PreviewFileWithId = {
                          id: uuid(),
                          fileData: currentFile,
                        }
                        setFiles((prev) => [...prev, fileWithId])
                        if (handleSelect) {
                          handleUpload(fileWithId, tok, handleSelect)
                        } else {
                          handleUpload(fileWithId, tok)
                        }
                      }
                    }, 1000)
                  })
                }
              }}
            >
              <div className="CreativeUploader__Requirements flex w-full flex-col items-center justify-center">
                <TextHeader
                  className="flex flex-1 flex-col pt-1 pb-2 text-center"
                  type={3}
                >
                  <span className="desktop:text-3xl flex justify-center gap-2">
                    <span>Upload</span>
                    <span>
                      {uploadType ? readableCreativeType(uploadType) : 'file'}
                    </span>
                  </span>
                  <span className="CreativeUploader__uploader-header animate-slidedown desktop:text-xl font-regular flex flex-col pt-1 pb-6 text-center">
                    Drag and drop files here &shy;or click to select a file
                  </span>
                </TextHeader>
                {uploadType === 'CREATIVE_TYPE_BANNER' && <BannerText />}
                {uploadType === 'CREATIVE_TYPE_VIDEO' && <VideoText />}
                {uploadType === 'CREATIVE_TYPE_OTT' && <OTTText />}
                {uploadType === 'CREATIVE_TYPE_HTML5' && <HTML5Text />}
                {!uploadType && (
                  <span className="flex flex-col gap-1">
                    <BannerText />
                    <VideoText />
                    <OTTText />
                    <HTML5Text />
                  </span>
                )}
              </div>
            </Uploader>
            {isInPoliticalCampaignCreation && (
              <span className=" text-warning-600 group relative z-10 flex w-full flex-1 items-center justify-center gap-1 font-bold">
                If a creative does not have a legible "Paid for By" statement,
                it will be removed from the order line.
              </span>
            )}
            <a
              className="text-base-info-600 group relative z-10 flex w-full w-full flex-1 items-center justify-center gap-1 font-bold"
              target="_blank"
              rel="noopener noreferrer"
              href={getUrl(uploadType || 'CREATIVE_TYPE_UNSPECIFIED')}
            >
              <Fa
                className="text-base-info pr-1"
                icon="question-circle"
                size={1}
              />
              <span className="more-info-link">
                For more information on
                {getText(uploadType || 'CREATIVE_TYPE_UNSPECIFIED')}
                ads, click here
              </span>
            </a>
          </div>
        )}

        {error && (
          <ErrorMsg className="flex w-full justify-center">
            Upload failed. {error}
          </ErrorMsg>
        )}
        {warning && (
          <WarningMsg textType={2} className="mt-2" popoverText="View Specs">
            {warning}
          </WarningMsg>
        )}
      </CenterFlex>
    </div>
  )
}
