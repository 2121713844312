import { useEffect, useState } from 'react'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Audience,
  Targetjobservicev1Note,
  Targetjobservicev1NoteType,
} from 'next-gen-sdk'
import { useSearchParams } from 'react-router-dom'
import { useAppContext, useLibraryContext } from 'Contexts'
import { checkIfCreative } from 'Helpers'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'

export const useAudienceNotes = ({
  previewItem,
  setEditingNotes,
}: {
  previewItem: Creativeservicev1Creative | Targetjobservicev1Audience
  setEditingNotes: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [notesList, setNotesList] = useState<
    Targetjobservicev1Note[] | undefined
  >(!checkIfCreative(previewItem) ? previewItem.notes : undefined)
  const [currentNote, setCurrentNote] = useState<Targetjobservicev1Note>()
  const {
    pagination: { updateRow, findCachedRow },
  } = useLibraryContext()
  const { audienceServiceApi } = useAppContext()
  const [search] = useSearchParams()

  const handleUpdateNote = async (noteId: string, content: string) => {
    if (!previewItem.id || !previewItem.orgId || !audienceServiceApi) return

    // update note endpoint
    const updatedNote = await audienceServiceApi.advertisingPlatformServiceUpdateAudienceNote(
      previewItem.id,
      noteId,
      previewItem.orgId,
      { content },
      'content',
    )
    // change in notes list
    if (updatedNote) {
      setNotesList((prev) => {
        const prevIndex = (prev || []).findIndex((n) => n.id === noteId)
        if (prevIndex >= 0 && prev) {
          const newList = [
            ...prev.slice(0, prevIndex),
            updatedNote,
            ...prev.slice(prevIndex + 1),
          ]

          const existingAudience = findCachedRow(
            (aud) => aud?.id === previewItem.id,
          )?.existingItem
          if (existingAudience && !checkIfCreative(previewItem)) {
            updateRow((aud) => aud?.id === previewItem.id, {
              notes: newList,
            })
          }
          return newList
        } else {
          return [...(prev || []), updatedNote]
        }
      })
    }
  }

  const handleDeleteNote = async (noteId: string) => {
    if (!audienceServiceApi || !previewItem.id || !previewItem.orgId) return
    await audienceServiceApi
      .advertisingPlatformServiceDeleteAudienceNote(
        previewItem.id,
        noteId,
        previewItem.orgId,
      )
      .catch(() =>
        showErrorMessage('There was an error deleting your note', ''),
      )
    const existingAudience = findCachedRow((aud) => aud?.id === previewItem.id)
      ?.existingItem
    if (existingAudience && !checkIfCreative(previewItem)) {
      updateRow((aud) => aud?.id === previewItem.id, {
        notes: notesList?.filter((note) => note.id !== noteId) || [],
      })
    }
    setNotesList((prev) => [
      ...(prev || []).filter((note) => note.id !== noteId),
    ])
  }

  const handleSaveNote = async (
    type: Targetjobservicev1NoteType,
    content: string,
  ) => {
    if (!previewItem.id || !previewItem.orgId || !audienceServiceApi) return
    const newNote = await audienceServiceApi
      ?.advertisingPlatformServiceCreateAudienceNote(
        previewItem.id,
        previewItem.orgId,
        {
          type,
          content,
        },
      )
      .catch(() => showErrorMessage('There was an error saving your note', ''))
    if (newNote) {
      showSuccessMessage('Your audience note was saved.', '')
      const existingAudience = findCachedRow(
        (aud) => aud?.id === previewItem.id,
      )?.existingItem
      if (existingAudience && !checkIfCreative(previewItem)) {
        updateRow((aud) => aud?.id === previewItem.id, {
          notes: [newNote, ...(notesList || [])],
        })
      }
      setNotesList((prev) => [...(prev || []), newNote])
    }
    setEditingNotes(false)
  }

  // reset notes when changing preview item
  useEffect(() => {
    setNotesList(!checkIfCreative(previewItem) ? previewItem.notes : undefined)
  }, [previewItem])

  // get currentNote if search includes note_id
  useEffect(() => {
    const noteId = search.get('note_id')
    if (noteId && !checkIfCreative(previewItem)) {
      const note = previewItem.notes?.find((n) => n.id === noteId)
      if (
        note?.id === currentNote?.id ||
        note?.content === currentNote?.content
      )
        return
      if (note) setCurrentNote(note)
    }
  }, [currentNote?.content, currentNote?.id, previewItem, search])

  return {
    notesList,
    currentNote,
    setCurrentNote,
    handleSaveNote,
    handleDeleteNote,
    handleUpdateNote,
  }
}
