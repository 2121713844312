import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'

export const PrePayMsg = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames(
        'PrePayMsg border-base-info text-base-info-400 bg-base-info-50 flex w-full items-center gap-2 rounded-lg border p-2 font-bold',
        className,
      )}
    >
      <Fa
        icon="fa-circle-info"
        size={1}
        className="text-base-info-500"
        type="solid"
      />
      Pre-payment is required for all political order lines.
    </div>
  )
}
