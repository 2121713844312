import React from 'react'
import { Fa } from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'
import { ButtonConfigType } from 'Components/Buttons'

const OptionalFilter = styleTailwind(
  'div',
  'OptionalFilter text-m flex hover:cursor-pointer items-center justify-start',
)
const OptionalFilterLabel = styleTailwind('p', 'OptionalFilterLabel')

export type OptionalFilterConfig = {
  filter: string
  icon: string
  count?: number
}

// Helper to count the filters
export const countFilters = (
  config: OptionalFilterConfig[],
  buttonConfig: ButtonConfigType[],
  isAdmin: boolean,
) => {
  const updatedConfig = config.map((conf) => {
    const count = buttonConfig.filter(({ adminOnly, tagLabel }) => {
      if (conf.filter === 'all') return true
      if (adminOnly && !isAdmin) {
        return false
      }
      return tagLabel && tagLabel === conf.filter
    }).length
    return { ...conf, count }
  })
  return updatedConfig
}

export const OptionalFilters = ({
  currentFilter,
  handleChangeFilter,
  filterConfig,
}: {
  currentFilter: string
  handleChangeFilter: (filter: string) => void
  filterConfig: OptionalFilterConfig[]
}) => {
  return (
    <div className="OptionalFilters flex flex-1 gap-4">
      {filterConfig.map(({ filter, icon, count }) => {
        return (
          <OptionalFilter
            key={filter}
            className={`${
              currentFilter === filter ? 'text-secondary-500 is-selected' : ''
            }`}
            onClick={() => handleChangeFilter(filter)}
            role="button"
            tabIndex={0}
          >
            <Fa
              className={` ${
                currentFilter === filter
                  ? 'text-secondary-500 is-selected'
                  : 'text-tint-gray-300'
              }`}
              icon={icon}
              size={1}
            />
            <OptionalFilterLabel
              className={`OptionalFilterLabel capitalize ${
                currentFilter === filter
                  ? 'text-secondary-500 is-selected font-bold'
                  : 'text-tint-gray-500'
              }`}
            >
              {`${filter.split('_').join(' ')}${count ? ` (${count})` : ''}`}
            </OptionalFilterLabel>
          </OptionalFilter>
        )
      })}
    </div>
  )
}
