/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1MessageType } from '../models/V1MessageType';
import { HttpFile } from '../http/http';

/**
* Contains the contents of a message that is sent directly to users
*/
export class V1Message {
    'message'?: string;
    'title'?: string;
    'type'?: V1MessageType;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1MessageType",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Message.attributeTypeMap;
    }

    public constructor() {
    }
}

