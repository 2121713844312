import {
  AutoScroller,
  showErrorAlert,
  showInfoAlert,
  showWarningAlert,
  Button,
  Fa,
  Text,
} from '@eltoro-ui/components'
import { styleTailwind } from 'Helpers'
import { useAppContext } from 'Contexts'
import { Hagridv1User, V1Notification } from 'next-gen-sdk'
import { useEffect, useState } from 'react'

type Props = {
  onClose: () => void
  data: V1Notification
}

const DetailItem = styleTailwind(
  'label',
  'DetailItem flex gap-4 items-center w-full border-t-thin border-tint-gray-300 py-2',
)
const DetailSlug = styleTailwind('strong', 'DetailSlug text-right')

export const AlertDetail = ({ onClose, data }: Props) => {
  const [user, setUser] = useState<Hagridv1User>()
  const { userServiceApi } = useAppContext()

  // Get info about the user that created the alert
  useEffect(() => {
    if (!data.userId) return
    userServiceApi?.advertisingPlatformServiceGetUser(data.userId).then(setUser)
  }, [data.userId, userServiceApi])

  return (
    <AutoScroller
      className="Alert__detail animate-slidedown shadow-4xl bg-light mb-4 w-full"
      shouldScroll={data.id !== ''}
      scrollToTop
    >
      <div className="Alert__detail-content w-full p-4">
        <header className="Alert__detail-content--header flex justify-between">
          <Text className="py-1" tag="h4">
            Detail of "{data.title}" alert banner
          </Text>
          <div
            className="ButtonWrapTooltip h-7"
            data-tooltip="left"
            aria-label="Close alert detail"
          >
            <Button
              type="button"
              className="Alert__close border-thin transition-all"
              iconOnly={<Fa icon="times" size={1} />}
              onClick={onClose}
            />
          </div>
        </header>
        <div className="Alert__detail-wrap flex justify-between gap-8">
          <div className="Alert__detail-list flex w-full flex-col pt-3">
            <DetailItem>
              <DetailSlug>Id:</DetailSlug>
              <span>{data.id}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>User:</DetailSlug>
              <span>
                {user?.firstName} {user?.lastName} ({data.userId})
              </span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Title:</DetailSlug>
              <span>{data.title}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Type:</DetailSlug>
              <span>{data.type}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Message:</DetailSlug>
              <span>{data.message}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Active:</DetailSlug>
              <span>{data.active ? 'Yes' : 'No'}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Global:</DetailSlug>
              <span>{data.global ? 'Yes' : 'No'}</span>
            </DetailItem>

            <DetailItem>
              <DetailSlug>Time created:</DetailSlug>
              <span>{`${data.createTime}`}</span>
            </DetailItem>

            {data.deleteTime !== undefined && data.deleteTime !== null && (
              <DetailItem>
                <DetailSlug>Time deleted:</DetailSlug>
                <span>{`${data.deleteTime}`}</span>
              </DetailItem>
            )}
            {data.updateTime !== undefined && data.updateTime !== null && (
              <DetailItem>
                <DetailSlug>Time updated:</DetailSlug>
                <span>{`${data.updateTime}`}</span>
              </DetailItem>
            )}
          </div>
          <div
            className="Alert__detail-button-wrap ButtonWrapTooltip h-14"
            data-tooltip="left"
            aria-label="See alert demo"
          >
            <Button
              type="button"
              className="Alert__show-alert hover:text-primary-700 mt-4 border-none"
              iconOnly={<Fa icon="message-dots" size={3} animate="beat" />}
              onClick={() => {
                if (data.type === 'NOTIFICATION_TYPE_INFO')
                  showInfoAlert(`${data.title}`, `${data.message}`)
                if (data.type === 'NOTIFICATION_TYPE_WARNING')
                  showWarningAlert(`${data.title}`, `${data.message}`)
                if (data.type === 'NOTIFICATION_TYPE_ERROR')
                  showErrorAlert(`${data.title}`, `${data.message}`)
                if (data.type === 'NOTIFICATION_TYPE_UNSPECIFIED')
                  showInfoAlert(`${data.title}`, `${data.message}`)
              }}
            />
          </div>
        </div>
      </div>
    </AutoScroller>
  )
}
