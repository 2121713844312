// From https://stackoverflow.com/a/14919494/13157973
export const formatByteString = (
  bytes: number,
  decimal: number = 1,
): string => {
  const thresh = 1000

  if (bytes < thresh) return `${bytes} bytes`
  const units = ['kB', 'MB', 'GB', 'TB', 'PD', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** decimal

  let currentBytes = bytes

  do {
    currentBytes /= thresh
    u += 1
  } while (
    Math.round(Math.abs(currentBytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${currentBytes.toFixed(decimal)} ${units[u]}`
}
