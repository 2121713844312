import {
  AlertsPage,
  DashboardPage,
  AudienceLibraryPage,
  AudienceUpload,
  CreateCampaignPage,
  ChildOrgsPage,
  CreativesLibraryPage,
  OrgSettingsPage,
  SuccessPage,
  ThemeSelectionPage,
  PlaceholderPage,
  BillingPage,
  TestPage,
  SearchPage,
  DocumentationPage,
  InternalAPIDocsPage,
} from './Pages'
import { RouteObject } from 'react-router-dom'

export const authorizedRoutes: RouteObject[] = [
  { path: '/', element: <DashboardPage /> },
  { path: '/theme-info', element: <ThemeSelectionPage /> },
  { path: '/test-page', element: <TestPage /> },
  {
    path: '/audiences',
    children: [
      { path: 'create', element: <AudienceUpload /> },
      {
        path: 'audience-library',
        element: <AudienceLibraryPage />,
        children: [
          {
            path: ':audienceId',
            element: <AudienceLibraryPage />,
            children: [
              { path: 'notes/:noteId', element: <AudienceLibraryPage /> },
            ],
          },
        ],
      },

      { path: 'marketplace', element: <PlaceholderPage /> },
    ],
  },
  {
    path: '/campaigns',
    children: [
      {
        path: ':campaignId',
        children: [
          {
            path: 'success',
            element: <SuccessPage />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
        children: [
          { path: ':campaignId', element: <DashboardPage /> },
          { path: 'order-lines/:orderLineId', element: <DashboardPage /> },
        ],
      },
      {
        path: 'creative-library',
        element: <CreativesLibraryPage />,
        children: [{ path: ':creativeId', element: <CreativesLibraryPage /> }],
      },
      {
        path: 'create',
        element: <CreateCampaignPage />,
        children: [
          {
            path: ':campaignId',
            element: <CreateCampaignPage />,
            children: [
              { path: 'audiences', element: <CreateCampaignPage /> },
              {
                path: 'ads',
                element: <CreateCampaignPage />,
              },
              {
                path: 'political-transparency',
                element: <CreateCampaignPage />,
              },
              {
                path: 'settings',
                element: <CreateCampaignPage />,
              },
            ],
          },
        ],
      },

      {
        path: 'edit',
        element: <CreateCampaignPage />,
        children: [
          {
            path: ':campaignId',
            element: <CreateCampaignPage />,
            children: [
              { path: 'audiences', element: <CreateCampaignPage /> },
              {
                path: 'ads',
                element: <CreateCampaignPage />,
              },
              {
                path: 'political-transparency',
                element: <CreateCampaignPage />,
              },
              {
                path: 'settings',
                element: <CreateCampaignPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/reporting',
    children: [
      { path: 'matchback', element: <PlaceholderPage /> },
      { path: 'campaigns', element: <PlaceholderPage /> },
      { path: 'ip-targeting', element: <PlaceholderPage /> },
    ],
  },
  {
    path: '/orgs',
    children: [
      {
        path: ':orgId',
        children: [
          { path: 'child-orgs', element: <ChildOrgsPage /> },
          { path: 'org-settings', element: <OrgSettingsPage /> },
          { path: 'billing', element: <BillingPage /> },
        ],
      },
    ],
  },
  { path: '/search', element: <SearchPage /> },
  { path: '/documentation', element: <DocumentationPage /> },
  { path: '/internal-api-docs', element: <InternalAPIDocsPage /> },
  {
    path: '/alerts',
    element: <AlertsPage />,
    children: [
      { path: 'edit', element: <AlertsPage /> },
      { path: 'new', element: <AlertsPage /> },
    ],
  },
]
