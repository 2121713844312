/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Country Service](https://docs.xandr.com/bundle/xandr-api/page/country-service.html) in Xandr's documentation.
*/
export class V3CountryTarget {
    /**
    * ID of the country.
    */
    'id'?: number;
    /**
    * Name of the country.
    */
    'name'?: string;
    /**
    * The code of the country.
    */
    'code'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3CountryTarget.attributeTypeMap;
    }

    public constructor() {
    }
}

