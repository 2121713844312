/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Notificationservicev1Checks } from '../models/Notificationservicev1Checks';
import { Notificationservicev1HealthCondition } from '../models/Notificationservicev1HealthCondition';
import { HttpFile } from '../http/http';

export class Notificationservicev1HealthCheck {
    'serviceName'?: string;
    'healthCondition'?: Notificationservicev1HealthCondition;
    'commitHash'?: string;
    'serviceVersion'?: string;
    'checks'?: Notificationservicev1Checks;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "serviceName",
            "baseName": "serviceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "healthCondition",
            "baseName": "healthCondition",
            "type": "Notificationservicev1HealthCondition",
            "format": ""
        },
        {
            "name": "commitHash",
            "baseName": "commitHash",
            "type": "string",
            "format": ""
        },
        {
            "name": "serviceVersion",
            "baseName": "serviceVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "checks",
            "baseName": "checks",
            "type": "Notificationservicev1Checks",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Notificationservicev1HealthCheck.attributeTypeMap;
    }

    public constructor() {
    }
}

