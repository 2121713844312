// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { V1GetNewMoverMatchCountResponse } from '../models/V1GetNewMoverMatchCountResponse';

/**
 * no description
 */
export class NewMoverApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get the number of matched new mover homes for specified mover type(s) for a specified state or zipcode(s)
     * Get new mover matches per new mover type
     * @param moverType Mover Type  The type of mover for which you want to get the count.
     * @param stateAbbreviation State Abbreviation  The abbreviation of the state from which you want to get the mover count. You can query by state abbreviation or zipcode(s) but not both.
     * @param zipcode Zipcodes  The zipcodes from which you want to get the mover count. You can query by state abbreviation or zipcode(s) but not both.
     * @param orgId Org id  The org_id associated with the user making the request
     * @param requestId Request_ID  Id of the request
     */
    public async advertisingPlatformServiceGetNewMoverMatchCount(moverType?: Array<string>, stateAbbreviation?: string, zipcode?: Array<string>, orgId?: string, requestId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;






        // Path Params
        const localVarPath = '/v1/new-movers:getNewMoverMatchCount';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (moverType !== undefined) {
            requestContext.setQueryParam("mover_type", ObjectSerializer.serialize(moverType, "Array<string>", ""));
        }

        // Query Params
        if (stateAbbreviation !== undefined) {
            requestContext.setQueryParam("state_abbreviation", ObjectSerializer.serialize(stateAbbreviation, "string", ""));
        }

        // Query Params
        if (zipcode !== undefined) {
            requestContext.setQueryParam("zipcode", ObjectSerializer.serialize(zipcode, "Array<string>", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }

        // Query Params
        if (requestId !== undefined) {
            requestContext.setQueryParam("request_id", ObjectSerializer.serialize(requestId, "string", "uuid"));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class NewMoverApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceGetNewMoverMatchCount
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceGetNewMoverMatchCount(response: ResponseContext): Promise<V1GetNewMoverMatchCountResponse > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1GetNewMoverMatchCountResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1GetNewMoverMatchCountResponse", ""
            ) as V1GetNewMoverMatchCountResponse;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1GetNewMoverMatchCountResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1GetNewMoverMatchCountResponse", ""
            ) as V1GetNewMoverMatchCountResponse;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
