import React, { useEffect, useRef, useState } from 'react'
import './CreativeGen.scss'
import './Assets/css/all.css'
import { Button, Modal, LoadingBar, Tabs, Fa } from '@eltoro-ui/components'
import Konva from 'konva'
import useResizeObserver from '@react-hook/resize-observer'
import { useUnsplash, useTimeTravel, useSaveState } from 'Hooks'
import { handleZoom, loadState } from 'Helpers'
// import { trackDownload } from 'Requests'
import { CreativeStage } from 'Components/Canvas'
import {
  Sidebar,
  FilterTab,
  BackgroundImageTab,
  BlockSelection,
  EditBlock,
  DirectMailSizeSelector,
  ZoomSettings,
  BlockOverlays,
} from 'Components/UI'
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import {
  backgroundPhotoAtom,
  creativeTypeAtom,
  directMailSizeAtom,
  loadingBackgroundPhotoAtom,
  selectedKindAtom,
} from 'State'
import { KonvaEventObject } from 'konva/types/Node'

// CreativeBody is wrapped below by CreativeGen which sets its height and width and supplies the Recoil context
export const CreativeBody: React.FC<{
  onComplete: (
    base64s: string[],
    type: string,
    order: string[],
    state: string,
  ) => void
  defaultCreativeType?: 'banner' | 'directMail'
  onClose: () => void
}> = ({ onComplete, defaultCreativeType, onClose }) => {
  const { undo, canUndo, redo, canRedo } = useTimeTravel()
  const [creativeType, setCreativeType] = useRecoilState(creativeTypeAtom)
  const directMailSize = useRecoilValue(directMailSizeAtom)
  const saveState = useSaveState()
  const [stageDims, setStageDims] = useState({ width: 0, height: 0 })
  const creativeStageRef = useRef<HTMLDivElement>(null)
  const stageRef = useRef<Konva.Stage>(null)
  const [mainGroupLocation, setMainGroupLocation] = useState({ x: 0, y: 0 })
  const [showEditPanel, setShowEditPanel] = useState(false)
  const [zoom, setZoom] = useState({ x: 1, y: 1 })
  const backgroundPhoto = useRecoilValue(backgroundPhotoAtom)
  const [loading, setLoading] = useRecoilState(loadingBackgroundPhotoAtom)
  const setSelectedKind = useSetRecoilState(selectedKindAtom)
  const [tab, setTab] = useState('')
  const handleSelectTab = (selection: string) => {
    setTab(selection === tab ? '' : selection)
    setShowEditPanel(false)
    setSelectedKind(undefined)
  }
  // gets dimensions of stage
  useResizeObserver(creativeStageRef, (entry) =>
    setStageDims(entry.contentRect),
  )

  const { search, handleSearch } = useUnsplash()

  const adRefs = useRef<Konva.Group[]>([])
  const addToRefs = (el: Konva.Group) => {
    if (el && !adRefs.current.includes(el)) {
      adRefs.current.push(el)
    }
  }
  useEffect(() => {
    // if user changes creativeType (tabs) or direct mail size...
    // ...reset adRef array. Without this the array will...
    // ...continue to grow everytime a new board is rendered.
    adRefs.current = []
  }, [creativeType, directMailSize])

  const handleDataURL = (
    currentRef: Konva.Group,
    width: number,
    height: number,
    x: number,
    y: number,
    pixelRatio: number,
  ) => {
    const dataURL: string = currentRef.toDataURL({
      mimeType: 'image/jpeg',
      width,
      height,
      x,
      y,
      quality: 1,
      // creates larger jpeg when printing...
      // ...exact ratio will need to be updated when more info comes from printhouse.
      pixelRatio,
    })
    return dataURL
  }

  const handleSave = async () => {
    setLoading(() => {
      setTimeout(async () => {
        const results: string[] = []
        const order: string[] = []
        adRefs.current.forEach((currentRef) => {
          const attrib = currentRef.getAttrs()
          const width = currentRef.width()
          const height = currentRef.height()
          const { x, y } = currentRef.absolutePosition()
          if (creativeType === 'directMail')
            order.push(attrib.id.substring(attrib.id.indexOf('-') + 1))

          const pixelRatio = creativeType === 'directMail' ? 8 : 1
          results.push(
            handleDataURL(currentRef, width, height, x, y, pixelRatio),
          )
        })
        // call unsplash download to satisfy API required download tracking
        if (backgroundPhoto) {
          // trackDownload(photo.links.download_location)
        }
        onComplete(results, creativeType, order, await saveState())
      })
      return true
    })
  }

  const handleCreateTemplate = async () => {
    const thumbRef =
      creativeType === 'banner' ? adRefs.current[4] : adRefs.current[0]
    const imageWidth = thumbRef.width()
    const imageHeight = thumbRef.height()
    const { x, y } = thumbRef.absolutePosition()
    const imageString = handleDataURL(
      thumbRef,
      imageWidth,
      imageHeight,
      x,
      y,
      1,
    )
    const textState = await saveState()
    navigator.clipboard
      .writeText(`${textState}___${imageString}`)
      .then(() => {
        console.log('successfully copied')
      })
      .catch((err) => {
        console.error('could not copy')
      })
  }

  const handleZoomSet = (e: KonvaEventObject<WheelEvent>) => {
    if (!stageRef.current) return
    setZoom(handleZoom(e, stageRef.current))
  }

  const resetMainGroupLocation = () => {
    setMainGroupLocation({
      x: -stageDims.width / 2,
      y: -stageDims.height / 2,
    })
  }

  // if componet size updates for any reason...
  // ...reset main group location to make sure it doesnt fall out of bounds
  useEffect(() => {
    resetMainGroupLocation()
  }, [stageDims])

  const handleTabChange = (type: 'banner' | 'directMail') => {
    setCreativeType(type)
    handleSelectTab('')
    resetMainGroupLocation()
    setSelectedKind(undefined)
    setShowEditPanel(false)
  }

  useEffect(() => {
    if (defaultCreativeType) handleTabChange(defaultCreativeType)
  }, [defaultCreativeType])

  return (
    <div className="CreativeBody">
      <div className="CreativeBody__header">
        <Tabs
          tabs={[
            {
              id: 'banner',
              label: (
                <span className="flex gap-1">
                  <Fa icon="image" size={1} />
                  <span>Banner</span>
                </span>
              ),
              onClick: () => handleTabChange('banner'),
              defaultTab: defaultCreativeType === 'banner',
              isDisabled: defaultCreativeType === 'directMail',
            },
            {
              id: 'directMail',
              label: (
                <span className="flex gap-1">
                  <Fa icon="envelope" size={1} />
                  <span>Direct mail</span>
                </span>
              ),
              onClick: () => handleTabChange('directMail'),
              defaultTab: defaultCreativeType === 'directMail',
              isDisabled: defaultCreativeType === 'banner',
            },
          ]}
          on="white"
        />
        {creativeType === 'directMail' && <DirectMailSizeSelector />}
      </div>
      <div className="CreativeBody__body">
        <Sidebar
          className={
            tab === ''
              ? 'hidden'
              : `CreativeGen-Sidebar__body Sidebar__${tab}-body`
          }
          elements={
            <span className="flex flex-col gap-2">
              <Button
                kind={tab === 'image' ? 'default' : 'text'}
                iconOnly={<Fa size={1} icon="images" />}
                onClick={() => handleSelectTab('image')}
              />
              <Button
                kind={tab === 'filter' ? 'default' : 'text'}
                iconOnly={<Fa size={1} icon="paint-roller" />}
                onClick={() => handleSelectTab('filter')}
              />
              <Button
                kind={tab === 'blocks' ? 'default' : undefined}
                iconOnly={<Fa size={1} icon="square" />}
                onClick={() => handleSelectTab('blocks')}
              />
            </span>
          }
          footer={
            <span className="flex flex-col gap-2">
              {stageRef.current && (
                <ZoomSettings
                  windowDims={stageDims}
                  stage={stageRef.current}
                  zoom={zoom}
                  onChange={setZoom}
                  onPositionReset={resetMainGroupLocation}
                />
              )}
              <Button
                disabled={!canUndo}
                kind="text"
                iconOnly={<Fa icon="undo" size={1} />}
                onClick={undo}
              />
              <Button
                disabled={!canRedo}
                kind="text"
                iconOnly={<Fa icon="redo" size={1} />}
                onClick={redo}
              />
              <Button
                kind="text"
                iconOnly={<Fa icon="clipboard" size={1} />}
                onClick={handleCreateTemplate}
              />

              <Button
                iconOnly={<Fa icon="times" size={1} />}
                onClick={onClose}
              />
              <Button
                kind="primary"
                iconOnly={<Fa icon="save" size={1} />}
                onClick={handleSave}
                disabled={loading}
              />
            </span>
          }
        >
          {tab === 'image' && (
            <BackgroundImageTab search={search} onChange={handleSearch} />
          )}
          {tab === 'filter' && <FilterTab />}
          {tab === 'blocks' && <BlockSelection />}
        </Sidebar>
        <div ref={creativeStageRef} className="CreativeBody__stage">
          <CreativeStage
            stageRef={stageRef}
            isEditing={showEditPanel}
            onSelectBlock={(showEdit) => {
              setShowEditPanel(showEdit)
              setTab('')
            }}
            addToRefs={addToRefs}
            stageDims={stageDims}
            onZoom={handleZoomSet}
            groupLocation={mainGroupLocation}
            onGroupDragEnd={setMainGroupLocation}
          />
          <BlockOverlays />
        </div>
        {showEditPanel && (
          <Sidebar
            elements={<EditBlock onClose={() => setShowEditPanel(false)} />}
            side="right"
          />
        )}
      </div>
      {loading && (
        <Modal>
          <div className="CreativeBody__loading">
            <LoadingBar colorKind="tertiary" />
          </div>
        </Modal>
      )}
    </div>
  )
}

export const CreativeGen: React.FC<{
  onComplete: (
    base64s: string[],
    type: string,
    order: string[],
    state: string,
  ) => void
  defaultCreativeType?: 'banner' | 'directMail'
  onClose: () => void
  width: string
  height: string
  state?: string
}> = ({ onComplete, defaultCreativeType, onClose, width, height, state }) => {
  return (
    <RecoilRoot initializeState={(snapshot) => loadState(snapshot, state)}>
      <div className="CreativeGen" style={{ height, width }}>
        <CreativeBody
          onComplete={onComplete}
          defaultCreativeType={defaultCreativeType}
          onClose={onClose}
        />
      </div>
    </RecoilRoot>
  )
}
