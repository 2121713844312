import { useState } from 'react'
import {
  Button,
  Fa,
  Form,
  FormItem,
  Modal,
  RadioButton,
  RadioButtonGroup,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  Tabs,
  Text,
  TextHeader,
  TextInput,
} from '@eltoro-ui/components'
import { useForm, useValidatedState } from '@eltoro-ui/hooks'
import { useAppContext } from 'Contexts'
import Collab from 'Assets/undraw_real_time_collab.svg'
import {
  ErrorMessage,
  isValidCharLength,
  poll,
  styleTailwind,
  validCheck,
} from 'Helpers'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { getApiConfig } from 'Requests'

const FormLabel = styleTailwind('span', 'w-20 text-right')
const AddButton = styleTailwind(Button, 'AddUserButton m-auto my-2 w-2/3 ')

export const AddUserModal = ({
  setOpenUserModal,
  refetch,
}: {
  setOpenUserModal: React.Dispatch<React.SetStateAction<boolean>>
  refetch: () => void
}) => {
  const [type, setType] = useState<'new' | 'existing'>('new')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail, isValidEmail] = useValidatedState('', 'email')
  const [role, setRole] = useState<string>('')
  const [emailError, setEmailError] = useState('')
  const [loading, setLoading] = useState(false)
  const {
    currentOrg,
    setCurrentOrg,
    orgServiceApi,
    userServiceApi,
  } = useAppContext()
  const { isValidForm, touched } = useForm({
    firstName,
    lastName,
    email,
    role,
  })
  const required = ['firstName', 'lastName', 'email', 'role']
  const { validForm, missingFields } = isValidForm([...required, isValidEmail])
  const clearAllFields = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setRole('')
    setEmailError('')
  }
  const handleAddUser = async () => {
    setLoading(true)
    setEmailError('')
    showInfoMessage('Creating user', 'The user is being created')
    userServiceApi
      ?.advertisingPlatformServiceCreateUser({
        username: email,
        firstName,
        lastName,
        email,
        realmRoles: [role, 'default-roles-eltoro'],
        groups: ['/Client'],
      })
      .then(async (res) => {
        /*
        TODO: this may be easier to deal with when the user/hagrid/org services are updated
          problem:
          - user is created in keycloak
          - org service still needs to react to that event and update the org user db
          - this calling add user too fast sometimes and is unstable
        */
        showInfoMessage('Adding user', 'The user is being added to the org')
        const updatedOrg = await poll(
          async (token) => {
            if (!currentOrg?.id || !res.id) return undefined
            const org: Orgmanagerv1Org | undefined = await orgServiceApi
              ?.advertisingPlatformServiceAddUser(
                currentOrg.id,
                {
                  userId: res.id,
                },
                token ? getApiConfig(token) : undefined,
              )
              .catch((err) => {
                if (err.body?.message === 'userAlreadyAssignedToOrg') {
                  return currentOrg
                }
                return undefined
              })
            return org
          },
          (org?: Orgmanagerv1Org) => org === undefined,
          1000,
          90000,
        )
        if (updatedOrg) setCurrentOrg(updatedOrg)
        else showErrorMessage('Error adding user to org', '')
        setOpenUserModal(false)
      })
      .then(() => {
        refetch()
        showSuccessMessage(
          'User added',
          'Your user was successfully created and added to the org',
        )
      })
      .catch((err) => {
        if (err?.body?.message === 'User with this email exists') {
          setEmailError(
            'User with this email exists. Please try adding this user via the Add existing tab.',
          )
        } else
          showErrorMessage(
            'User Creation Failed',
            'If you think this is a problem reach out to support',
          )
      })
      .finally(() => setLoading(false))
  }
  const handleAddExistingUser = async () => {
    setLoading(true)
    if (!userServiceApi || !orgServiceApi || !currentOrg?.id) return
    await orgServiceApi
      .advertisingPlatformServiceAddUser(currentOrg.id, {
        email,
      })
      .then(() => {
        refetch()
        setOpenUserModal(false)
        showSuccessMessage(
          'User added',
          'Your user was successfully added to the org',
        )
      })
      .catch((err) => {
        if (err?.body?.message === 'userNotFound') {
          setEmailError('User with this email does not exist.')
          return
        }
        if (err?.body?.message === 'userAlreadyAssignedToOrg') {
          setEmailError('User is already assigned to org.')
          return
        }
        if (err?.body?.message === 'userCannotBeAdded') {
          setEmailError('User cannot be added to org.')
          return
        }
        showErrorMessage('Error adding existing user to org', '')
      })
      .finally(() => setLoading(false))
  }

  const handleChangeTab = (tab: 'new' | 'existing') => {
    setType(tab)
    clearAllFields()
  }

  if (!currentOrg) return null
  return (
    <Modal
      offClick={() => {
        setOpenUserModal(false)
        clearAllFields()
      }}
      className="w-[80%]"
      header={
        <div className="AddUserModal__header flex flex-col gap-2">
          <TextHeader
            className="AddUser-header-text font-normal normal-case"
            type={3}
          >
            <span
              className={`${
                currentOrg.name && currentOrg.name.length > 0
                  ? 'RightVerticalPipe'
                  : ''
              } font-light uppercase`}
            >
              Add user
            </span>
            <span>{currentOrg.name}</span>
          </TextHeader>
          <Text className="!font-normal">
            Send an email invitation for a new user account or link to an
            existing one
          </Text>
        </div>
      }
    >
      <div className="AddUser-content flex justify-around gap-4 px-2 pt-4 pb-6">
        <div className="AddUser-content--image tablet:hidden w-5/12 items-center">
          <img src={Collab} alt="test" className="h-auto w-full object-cover" />
        </div>
        <Tabs
          classNamePanel="AddUser-content--tab tablet:w-auto w-1/2 min-h-[26rem]"
          tabs={[
            {
              id: 'new',
              label: (
                <span className="flex gap-1">
                  <Fa icon="user-plus" size={1} type="duotone" />
                  <span>Add new</span>
                </span>
              ),
              onClick: () => handleChangeTab('new'),
              defaultTab: type === 'new',
              component: (
                <div className="AddUser-add-new">
                  <Form
                    className="Form__addUser-add-new"
                    required={required}
                    missingFields={missingFields}
                    touched={touched}
                    valid={validForm}
                  >
                    <FormItem
                      className="pt-2"
                      htmlFor="firstName"
                      label={<FormLabel>First Name</FormLabel>}
                      required
                      errorMessage={ErrorMessage({
                        fieldName: firstName || '',
                        max: 100,
                        label: 'First name',
                      })}
                      valid={
                        validCheck(firstName) &&
                        isValidCharLength(firstName, 100)
                      }
                    >
                      <TextInput
                        hideValidIcon
                        valid={isValidCharLength(firstName, 100)}
                        value={firstName}
                        maxLength={101}
                        classNameWrap="text-tint-gray-800"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFirstName(e.target.value)
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="pt-2"
                      htmlFor="lastName"
                      label={<FormLabel>Last Name</FormLabel>}
                      required
                      errorMessage={ErrorMessage({
                        fieldName: lastName || '',
                        max: 100,
                        label: 'Last name',
                      })}
                      valid={
                        validCheck(lastName || '') &&
                        isValidCharLength(lastName || '', 100)
                      }
                    >
                      <TextInput
                        hideValidIcon
                        valid={isValidCharLength(lastName || '', 100)}
                        value={lastName}
                        maxLength={101}
                        classNameWrap="text-tint-gray-800"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setLastName(e.target.value)
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="pt-2"
                      htmlFor="email"
                      label={<FormLabel>Email</FormLabel>}
                      required
                      errorMessage={
                        emailError ||
                        (!isValidEmail
                          ? 'Please type a valid email address'
                          : undefined)
                      }
                      valid={isValidEmail && !emailError}
                    >
                      <TextInput
                        hideValidIcon
                        valid={isValidCharLength(email || '', 255)}
                        value={email}
                        maxLength={256}
                        classNameWrap="Email__text-input"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmailError('')
                          setEmail(e.target.value)
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="pt-2 pb-2"
                      htmlFor="roles"
                      label={<FormLabel>Roles</FormLabel>}
                      required
                      errorMessage="Please select a role."
                      valid={!!role}
                    >
                      <RadioButtonGroup
                        className="AddUser__button-group gap-4"
                        valueSelected={role}
                        name="Role"
                        onChange={(newRole) => setRole(newRole as string)}
                      >
                        <RadioButton
                          value="nextgen_user"
                          label="Org Admin"
                          labelSub="Organizational Administrator"
                        />
                        <RadioButton
                          value="nextgen_read_only"
                          label="Read Only"
                          labelSub="Can view campaigns, audiences, creatives, reports for org + child orgs"
                        />
                      </RadioButtonGroup>
                    </FormItem>
                    <AddButton
                      className={validForm ? 'Button--primary' : ''}
                      type="submit"
                      disabled={!validForm || loading}
                      onClick={() => handleAddUser()}
                      iconLeft={
                        loading ? (
                          <Fa icon="spinner" animate="spin" size={1} />
                        ) : undefined
                      }
                    >
                      {`Creat${loading ? 'ing' : 'e'} user`}
                    </AddButton>
                  </Form>
                </div>
              ),
            },
            {
              id: 'existing',
              label: (
                <span className="flex gap-1">
                  <Fa icon="magnifying-glass" size={1} type="duotone" />
                  <span>Add existing</span>
                </span>
              ),
              onClick: () => handleChangeTab('existing'),
              defaultTab: type === 'existing',
              component: (
                <div className="AddUser-add-existing flex flex-col">
                  <FormItem
                    htmlFor="email"
                    label={<FormLabel>Email</FormLabel>}
                    required
                    errorMessage={ErrorMessage({
                      fieldName: email || '',
                      max: 255,
                      label: 'Email address',
                      isValid: !emailError,
                    })}
                    valid={isValidEmail && !emailError}
                  >
                    <TextInput
                      maxLength={256}
                      value={email}
                      classNameWrap="text-tint-gray-800 my-2"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                    />
                  </FormItem>
                  <AddButton
                    className={
                      isValidEmail && !emailError ? 'Button--primary' : ''
                    }
                    type="submit"
                    disabled={!email || loading || !isValidEmail}
                    onClick={() => handleAddExistingUser()}
                    iconLeft={
                      loading ? (
                        <Fa icon="spinner" animate="spin" size={1} />
                      ) : undefined
                    }
                  >
                    Add user
                  </AddButton>
                </div>
              ),
            },
          ]}
          on="white"
        />
      </div>
    </Modal>
  )
}
