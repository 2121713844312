import {
  Campaignservicev1CPM,
  Campaignservicev1Audience,
  Campaignservicev1OrderLine,
  V1ProductType,
  V1StatsResponse,
} from 'next-gen-sdk'
import { capitalize } from './text-helpers'
import { getTypeMoneyCost } from './cpm-helpers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useAppContext } from 'Contexts'

// returns what audience type and creative types can be attached
// to the passed in orderline
export const olTypesFinder = (
  orderline: Campaignservicev1OrderLine,
): {
  hasAudience: boolean
  audienceType: string
  hasCreatives: boolean
  acceptedCreativeTypes: string[]
} => {
  const audType =
    orderline?.audiences?.[0]?.productType?.split('PRODUCT_TYPE_')[1] || ''

  // TODO: Should these be by creative ad type? (ad type puts OTT and native on their own)
  const similarCreativeTypes = [
    ['BANNER', 'NATIVE-BANNER', 'HTML5', 'ADTAG-BANNER'],
    ['ADTAG-VIDEO', 'NATIVE-VIDEO', 'VAST-VIDEO', 'OTT'],
    ['PRINT'],
    ['VAST-AUDIO'],
    ['UNSPECIFIED'],
  ]
  const olNameCreativeType = () => {
    const rawName =
      orderline?.creatives?.[0]?.type?.split('CREATIVE_TYPE_')[1] || ''
    switch (rawName) {
      case 'NATIVE_BANNER':
        return 'NATIVE-BANNER'
      case 'NATIVE_VIDEO':
        return 'NATIVE-VIDEO'
      case 'AD_TAG_VIDEO':
        return 'ADTAG-VIDEO'
      case 'AD_TAG_BANNER':
        return 'ADTAG-BANNER'
      case 'VAST_AUDIO':
        return 'VAST-AUDIO'
      case 'VAST_VIDEO':
        return 'VAST-VIDEO'
      default:
        return rawName
    }
  }
  return {
    hasAudience: !!orderline?.audiences?.length,
    audienceType: audType,
    hasCreatives: !!orderline?.creatives?.length,
    acceptedCreativeTypes: similarCreativeTypes.find((types) =>
      types?.find((typ) => typ === olNameCreativeType()),
    ) || [''],
  }
}
// deconstructs, updates and reconstructs the dynamic orderline name
// this will need some more tweaking to handle custom user names
export const olNameParser = (
  olName: string,
  partUpdate?: { [key: string]: string },
): {
  isDraft: boolean
  campaignName?: string
  audienceType?: string
  creativeType?: string
  olNumber?: string
  partUpdate?: { [key: string]: string }
  updatedName?: string
} => {
  const creativeTypesNameList = [
    'UNSPECIFIED',
    'BANNER',
    'NATIVE-BANNER',
    'NATIVE-VIDEO',
    'HTML5',
    'ADTAG-VIDEO',
    'ADTAG-BANNER',
    'OTT',
    'VAST-AUDIO',
    'VAST-VIDEO',
    'PRINT',
  ]
  const audienceTypesNameList = [
    'UNKNOWN',
    'B2C',
    'B2B',
    'ADDRESS',
    'PREVIOUS',
    'DC',
    'DIRECTMAIL',
    'ZIP',
    'IP',
    'REVERSE',
    'ONSPOTMAID',
    'ADDRESSVALIDATION',
    'PIPELINE',
    'IPSFORGEOJSON',
    'DEVICE',
    'VR',
    'IPSFORWKB',
    'MAID',
    'PREMOVER',
    'POSTMOVER',
    'ESCROWMOVER',
    'RETARGETING',
  ]
  const nameParts = olName.split('_')
  const isDraft = nameParts.slice(-2, -1)[0] === 'Draft'
  const [olNumber] = nameParts.slice(-1)
  const audienceType =
    nameParts.find((part: string) =>
      audienceTypesNameList.some((tp: string) => tp === part),
    ) || ''
  const creativeType =
    nameParts.find((part: string) =>
      creativeTypesNameList.some((tp: string) => tp === part),
    ) || ''
  const campaignName = nameParts.filter(
    (part: string) =>
      part !== 'Draft' &&
      part !== olNumber &&
      part !== audienceType &&
      part !== creativeType,
  )[0]
  const nameObj = {
    campaignName,
    audienceType,
    creativeType,
    olNumber,
    isDraft,
    ...partUpdate,
  }
  const notDraft = !!(
    nameObj.campaignName &&
    nameObj.audienceType &&
    nameObj.creativeType
  )
  const draftText = notDraft ? '' : 'Draft_'
  const updatedName = [
    nameObj.campaignName ? `${nameObj.campaignName}_` : '',
    nameObj.audienceType ? `${nameObj.audienceType}_` : '',
    nameObj.creativeType ? `${nameObj.creativeType}_` : '',
    draftText,
    nameObj.olNumber,
  ].join('')
  return {
    ...nameObj,
    updatedName,
  }
}

export const useOrderLineCost = (
  orderLine: Campaignservicev1OrderLine,
  minImpressions: number,
  maxImpressions: number,
) => {
  const [cpms, setCpms] = useState<Array<Campaignservicev1CPM>>()
  const { campaignServiceApi } = useAppContext()

  useEffect(() => {
    if (orderLine.id && orderLine.orgId)
      campaignServiceApi
        ?.advertisingPlatformServiceGetOrderLineCPMs(
          orderLine.id,
          orderLine.orgId,
        )
        .then((res) => setCpms(res.cpms))
  }, [campaignServiceApi, orderLine.id, orderLine.orgId])

  const olAdType = orderLine.adType
    ?.toLowerCase()
    ?.split('_')
    .slice(2)
    .join('_')
  const olProductType = orderLine.audiences?.[0]?.productType
    ?.toLowerCase()
    .split('_')
    .slice(2)
    .join('_')
  const maxBudgetCPM = cpms?.find(
    (cpm) =>
      cpm.productName ===
      (orderLine.political
        ? `political ${olProductType} ${olAdType}`
        : `${olProductType} ${olAdType}`),
  )
  const cpmCreativeGroup = orderLine?.adType
    ? capitalize(orderLine?.adType)
    : 'ORDERLINE_ADTYPE_UNSPECIFIED'

  // figure out maximum/minimum cost based on impressions
  const cpmCost = maxBudgetCPM ? getTypeMoneyCost(maxBudgetCPM) : 0
  const minCost = (minImpressions / 1000) * cpmCost
  const maxCost = (maxImpressions / 1000) * cpmCost

  return { minCost, maxCost, cpmCreativeGroup, maxBudgetCPM }
}

export const canIAttach = (
  ol: Campaignservicev1OrderLine,
  audienceType: V1ProductType,
) => {
  if (ol && ol.audiences && ol.audiences.length > 0) {
    const { audiences } = ol
    const types = audiences.map((c) => c.productType)
    if (types.includes(audienceType)) {
      return true
    }
    return false
  }
  return true
}

export const durationOrDaysLeft = (orderline: Campaignservicev1OrderLine) => {
  const startDayjs = dayjs(orderline.startTime).startOf('day')
  const endDayjs = dayjs(orderline.endTime).endOf('day')
  const today = dayjs()
  if (today.isAfter(startDayjs) && today.isBefore(endDayjs)) {
    return Math.ceil(endDayjs.diff(today, 'days', true))
  }
  if (today.isAfter(endDayjs)) return 0
  return Math.ceil(endDayjs.diff(startDayjs, 'days', true))
}

// See doc for limit info https://eltorocorp.atlassian.net/wiki/spaces/PNG/pages/1874460991/Portal+Business+Rules#Order-Lines
export const checkOLAudienceCount = (
  orderline: Campaignservicev1OrderLine,
): {
  audienceCount: number
  hasRequiredAudienceCount?: boolean
  hasAudienceWithNoMatches?: boolean
  hasNoAudiences: boolean
  matchWarning?: string[]
} => {
  const getCount = (audiences: Campaignservicev1Audience[] | undefined) => {
    return audiences?.reduce((a, b) => (b.matched || 0) + a, 0) || 0
  }
  if (!orderline.audiences || !orderline.audiences.length)
    return { audienceCount: 0, hasNoAudiences: true }
  const audienceCount = getCount(orderline.audiences)
  const productType = orderline.audiences?.[0]?.productType
  if (productType === 'PRODUCT_TYPE_RETARGETING')
    return {
      audienceCount,
      hasRequiredAudienceCount: true,
      hasNoAudiences: false,
      hasAudienceWithNoMatches: false,
    }

  const hasAudienceWithNoMatches = !!orderline.audiences?.find(
    (aud) => !aud.matched,
  )
  if (productType === 'PRODUCT_TYPE_MAP_POLYGON')
    return {
      hasNoAudiences: false,
      audienceCount,
      hasRequiredAudienceCount: audienceCount >= 1,
      hasAudienceWithNoMatches,
    }

  // check for b2b, b2c
  if (productType === 'PRODUCT_TYPE_IP_TARGETING') {
    const b2bAudiences = orderline.audiences?.filter(
      (aud) => aud.type === 'AUDIENCE_TYPE_B2B',
    )
    const b2cAudiences = orderline.audiences?.filter(
      (aud) => aud.type === 'AUDIENCE_TYPE_B2C',
    )
    // if both types, make sure b2b is at least one and b2c is at least 500
    const hasB2B = (b2bAudiences || [])?.length > 0
    const hasB2C = (b2cAudiences || []).length > 0
    const hasEnoughB2B = hasB2B ? getCount(b2bAudiences) >= 1 : true
    const hasEnoughB2C = hasB2C ? getCount(b2cAudiences) >= 500 : true

    if (hasB2C || hasB2B) {
      return {
        hasNoAudiences: false,
        audienceCount,
        hasRequiredAudienceCount: hasEnoughB2C && hasEnoughB2B,
        ...((!hasEnoughB2B || !hasEnoughB2C) && {
          matchWarning: [
            ...(!hasEnoughB2B
              ? ['It is recommended to have at least 1 B2B audience match.']
              : []),
            ...(!hasEnoughB2C
              ? ['It is recommended to have at least 500 B2C audience matches.']
              : []),
          ],
        }),
        hasAudienceWithNoMatches,
      }
    }
  }
  // if has venue replay, require 500 OR 30000 devices if matched homes unselected
  if (productType === 'PRODUCT_TYPE_VENUE_REPLAY') {
    const groupedByAudienceId = orderline.audiences?.reduce(
      (
        acc: { parentId: string; audiences: Campaignservicev1Audience[] }[],
        audience,
      ) => {
        if (!audience.audienceId) return acc
        const existingIndex = acc.findIndex(
          (e) => e.parentId === audience.audienceId,
        )
        if (existingIndex >= 0) {
          return [
            ...acc.slice(0, existingIndex),
            {
              parentId: audience.audienceId,
              audiences: [...(acc[existingIndex]?.audiences || []), audience],
            },
            ...acc.slice(existingIndex + 1),
          ]
        }
        return [
          ...acc,
          { parentId: audience.audienceId, audiences: [audience] },
        ]
      },
      [],
    )

    let vrMatchWarnings: string[] = []
    let hasBadVrSet: boolean = false
    groupedByAudienceId.forEach((vrGroup) => {
      if (
        !vrGroup.audiences.find((aud) => aud.type === 'AUDIENCE_TYPE_ADDRESS')
      ) {
        // does not have mapped home job, return min 30000
        if (getCount(vrGroup.audiences) < 30000) {
          hasBadVrSet = true
          vrMatchWarnings = [
            ...new Set([
              ...vrMatchWarnings,
              'VR audiences with mapped homes unselected require at least 30,000 device audience matches.',
            ]),
          ]
        }
      }
    })
    return {
      hasNoAudiences: false,
      audienceCount,
      hasRequiredAudienceCount: !hasBadVrSet && audienceCount >= 500,
      ...((vrMatchWarnings || audienceCount < 500) && {
        matchWarning: [
          ...vrMatchWarnings,
          ...(audienceCount < 500
            ? ['It is recommended to have at least 500 audience matches.']
            : []),
        ],
      }),
      hasAudienceWithNoMatches,
    }
  }
  // if new mover, device id, zip codes, ip address
  return {
    hasNoAudiences: false,
    audienceCount,
    hasRequiredAudienceCount: audienceCount >= 500,
    ...(audienceCount < 500 && {
      matchWarning: [
        'It is recommended to have at least 500 audience matches.',
      ],
    }),
    hasAudienceWithNoMatches,
  }
}

export const getOrderLineSpent = (
  ol: Campaignservicev1OrderLine,
  impsServed: number,
) => {
  const budget = getOrderLineBudget(ol)
  const spent = ((impsServed || 0) / (ol.impressions || 1)) * budget
  // if overserve, return budget
  if (spent > budget) return budget
  return spent
}

export const getOrderLineBudget = (ol: Campaignservicev1OrderLine) => {
  return ol.costRange?.estimate ? getTypeMoneyCost(ol.costRange.estimate) : 0
}
