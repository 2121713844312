/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1AuditResult } from '../models/Creativeservicev1AuditResult';
import { Creativeservicev1AuditStatus } from '../models/Creativeservicev1AuditStatus';
import { Creativeservicev1DeploymentType } from '../models/Creativeservicev1DeploymentType';
import { Creativeservicev1Destination } from '../models/Creativeservicev1Destination';
import { HttpFile } from '../http/http';

/**
* A deployment's audit information
*/
export class Creativeservicev1Audit {
    /**
    * The ID of the audit.
    */
    'id'?: string;
    /**
    * The time the object was created.
    */
    'createTime'?: Date;
    /**
    * The time the object was updated.
    */
    'updateTime'?: Date;
    /**
    * The time the object was deleted.
    */
    'deleteTime'?: Date;
    'result'?: Creativeservicev1AuditResult;
    'type'?: Creativeservicev1DeploymentType;
    'destination'?: Creativeservicev1Destination;
    /**
    * The deployment id of the audit.
    */
    'deploymentId'?: string;
    'auditStatus'?: Creativeservicev1AuditStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "result",
            "baseName": "result",
            "type": "Creativeservicev1AuditResult",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Creativeservicev1DeploymentType",
            "format": ""
        },
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Creativeservicev1Destination",
            "format": ""
        },
        {
            "name": "deploymentId",
            "baseName": "deployment_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "auditStatus",
            "baseName": "audit_status",
            "type": "Creativeservicev1AuditStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Creativeservicev1Audit.attributeTypeMap;
    }

    public constructor() {
    }
}

