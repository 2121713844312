import { ReactNode, useEffect, useState } from 'react'
import { FormItem, TextInput } from '@eltoro-ui/components'
import { Orgmanagerv1Contact } from 'next-gen-sdk'
import classNames from 'classnames'
import { ErrorMessage, isValidCharLength, validCheck } from 'Helpers'
import { SelectCountry, SelectState } from 'Components'

export const ContactForm: React.FC<{
  contact: Orgmanagerv1Contact
  onChange: (newContact: Orgmanagerv1Contact) => void
  isValidEmail: boolean
  isValidPhone: boolean
  isValidZip: boolean
  className?: string
  contactHeader?: ReactNode
  addressHeader?: ReactNode
}> = ({
  contact,
  className,
  onChange,
  isValidEmail,
  isValidPhone,
  isValidZip,
  contactHeader,
  addressHeader,
}) => {
  const [fieldsTouched, setFieldsTouched] = useState<string[]>([])

  const {
    firstName,
    lastName,
    phone,
    email,
    address,
    city,
    state,
    zip,
    country,
  } = contact

  const handleBlur = (field: string) => {
    setFieldsTouched((prev) => [...new Set([...prev, field])])
  }

  const isTouched = (field: string) =>
    fieldsTouched.some((existing) => existing === field)

  return (
    <div className={`ContactForm flex flex-col gap-4 pt-4 ${className}`}>
      {contactHeader}
      <FormItem
        htmlFor={`first-name-${contact.id}`}
        label="First Name:"
        required
        errorMessage={ErrorMessage({
          fieldName: firstName || '',
          max: 100,
          label: 'First name',
        })}
        valid={
          validCheck(firstName || '') && isValidCharLength(firstName || '', 100)
        }
      >
        <TextInput
          valid={isValidCharLength(firstName || '', 100)}
          hideValidIcon
          maxLength={101}
          value={firstName || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, firstName: e.target.value })
          }
          onBlur={() => handleBlur('firstName')}
          autoComplete="given-name"
        />
      </FormItem>
      <FormItem
        htmlFor={`last-name-${contact.id}`}
        label="Last name:"
        errorMessage={ErrorMessage({
          fieldName: lastName || '',
          max: 100,
          label: 'Last name',
        })}
        valid={
          validCheck(lastName || '') && isValidCharLength(lastName || '', 100)
        }
        required
      >
        <TextInput
          valid={isValidCharLength(lastName || '', 100)}
          hideValidIcon
          value={lastName || ''}
          maxLength={101}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, lastName: e.target.value })
          }
          onBlur={() => handleBlur('lastName')}
          autoComplete="family-name"
        />
      </FormItem>
      <FormItem
        htmlFor={`phone-${contact.id}`}
        label="Phone:"
        required
        valid={
          !isTouched('phone')
            ? isValidPhone
            : isValidPhone && (phone || '').length > 1
        }
        errorMessage={ErrorMessage({
          fieldName: phone || '',
          max: 10,
          label: 'Phone number',
          isValid: isValidPhone,
        })}
        counter={(phone || '').length > 0}
      >
        <TextInput
          valid={isValidPhone && (phone || '').length > 1}
          hideValidIcon
          value={phone || ''}
          maxLength={11}
          // safari handles number as alphanumerical field change to tel so the FormItem's handler will prevent alpha chars.
          type="tel"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, phone: e.target.value })
          }
          onBlur={() => handleBlur('phone')}
          autoComplete="tel-national"
        />
      </FormItem>
      <FormItem
        htmlFor={`email-${contact.id}`}
        label="Email:"
        required
        valid={
          !isTouched('email')
            ? isValidEmail
            : isValidEmail && (email || '').length > 1
        }
        errorMessage={ErrorMessage({
          fieldName: email || '',
          max: 255,
          label: 'Email Address',
          isValid: isValidEmail,
        })}
      >
        <TextInput
          valid={isValidEmail && (email || '').length > 1}
          hideValidIcon
          maxLength={256}
          value={email || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, email: e.target.value })
          }
          onBlur={() => handleBlur('email')}
          autoComplete="email"
        />
      </FormItem>
      {addressHeader}
      <FormItem
        htmlFor={`address-${contact.id}`}
        label="Address:"
        errorMessage={ErrorMessage({
          fieldName: address || '',
          max: 255,
          label: 'Address',
        })}
        valid={
          validCheck(address || '') && isValidCharLength(address || '', 255)
        }
        required
      >
        <TextInput
          valid={isValidCharLength(address || '', 255)}
          hideValidIcon
          maxLength={256}
          value={address || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, address: e.target.value })
          }
          onBlur={() => handleBlur('address')}
          autoComplete="street-address"
        />
      </FormItem>
      <FormItem
        htmlFor={`city-${contact.id}`}
        label="City:"
        errorMessage={ErrorMessage({
          fieldName: city || '',
          max: 255,
          label: 'City name',
        })}
        valid={validCheck(city || '') && isValidCharLength(city || '', 255)}
        required
      >
        <TextInput
          valid={isValidCharLength(city || '', 255)}
          hideValidIcon
          maxLength={256}
          value={city || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, city: e.target.value })
          }
          onBlur={() => handleBlur('city')}
          autoComplete="home city"
        />
      </FormItem>
      {country === 'United States of America' && (
        <FormItem htmlFor={`state-${contact.id}`} label="State:" required>
          <SelectState
            country={country}
            value={state}
            onChange={(state) => onChange({ ...contact, state })}
            className={classNames(
              'ContactForm__SelectState border-tint-gray-300 min-h-[2.5rem] pb-1',
              {
                '!border-warning animate-glowwarning':
                  isTouched('state') && (state || '').length < 2,
                'border-success animate-glowsuccess':
                  isTouched('state') && state && state.length > 1,
              },
            )}
            onBlur={() => handleBlur('state')}
          />
        </FormItem>
      )}
      {country === 'Canada' && (
        <FormItem htmlFor={`state-${contact.id}`} label="Province:" required>
          <SelectState
            country={country}
            value={state}
            onChange={(state) => onChange({ ...contact, state })}
            className={classNames(
              'ContactForm__SelectState border-tint-gray-300 min-h-[2.5rem] pb-1',
              {
                '!border-warning animate-glowwarning':
                  isTouched('state') && (state || '').length < 2,
                'border-success animate-glowsuccess':
                  isTouched('state') && state && state.length > 1,
              },
            )}
            onBlur={() => handleBlur('state')}
          />
        </FormItem>
      )}
      <FormItem htmlFor={`country-${contact.id}`} label="Country:" required>
        <SelectCountry
          value={country}
          onChange={(country) => onChange({ ...contact, country })}
          className={classNames(
            'ContactForm__SelectCountry border-tint-gray-300 min-h-[2.5rem] pb-1',
            {
              '!border-warning animate-glowwarning':
                isTouched('country') && (country || '').length < 2,
              'border-success animate-glowsuccess':
                isTouched('country') && country && country.length > 1,
            },
          )}
          onBlur={() => handleBlur('country')}
        />
      </FormItem>
      <FormItem
        htmlFor={`zip-${contact.id}`}
        label={country === 'Canada' ? 'Postal Code:' : 'ZIP Code:'}
        required
        valid={
          !isTouched('zip') ? isValidZip : isValidZip && (zip || '').length > 1
        }
        errorMessage={ErrorMessage({
          fieldName: zip || '',
          max: 6,
          label: 'ZIP code',
          isValid: isValidZip,
        })}
        counter={(zip || '').length > 0}
      >
        <TextInput
          valid={isValidZip && (zip || '').length > 1}
          hideValidIcon
          value={zip || ''}
          maxLength={7}
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ ...contact, zip: e.target.value })
          }
          onBlur={() => handleBlur('zip')}
          autoComplete="postal-code"
        />
      </FormItem>
    </div>
  )
}
