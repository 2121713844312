/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1ResultMetrics {
    /**
    * Beginning of a search time frame
    */
    'start'?: string;
    /**
    * End of a search time frame
    */
    'end'?: string;
    /**
    * Total number of clicks across all impressions
    */
    'clicks'?: number;
    'completions'?: number;
    /**
    * Total number of post view and post click conversions
    */
    'conversions'?: number;
    /**
    * Total number of impressions served
    */
    'imps'?: number;
    /**
    * The number of measured impressions that were viewable, per the IAB Viewability definition, which states that an impression is viewable if 50% of the pixels are in-view during 1 consecutive second
    */
    'impsViewed'?: number;
    'pcts25'?: number;
    'pcts50'?: number;
    'pcts75'?: number;
    /**
    * The total number of recorded post-click conversions.
    */
    'postClickConvs'?: number;
    /**
    * The total number of recorded post-view conversions.
    */
    'postViewConvs'?: number;
    'starts'?: number;
    /**
    * The total number of impressions that were measured for viewability.
    */
    'viewMeasuredImps'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "start",
            "baseName": "start",
            "type": "string",
            "format": ""
        },
        {
            "name": "end",
            "baseName": "end",
            "type": "string",
            "format": ""
        },
        {
            "name": "clicks",
            "baseName": "clicks",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "completions",
            "baseName": "completions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "conversions",
            "baseName": "conversions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "imps",
            "baseName": "imps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "impsViewed",
            "baseName": "imps_viewed",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pcts25",
            "baseName": "pcts_25",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pcts50",
            "baseName": "pcts_50",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "pcts75",
            "baseName": "pcts_75",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "postClickConvs",
            "baseName": "post_click_convs",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "postViewConvs",
            "baseName": "post_view_convs",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "starts",
            "baseName": "starts",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "viewMeasuredImps",
            "baseName": "view_measured_imps",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1ResultMetrics.attributeTypeMap;
    }

    public constructor() {
    }
}

