import { useState, useEffect } from 'react'
import { Fa, Text, Tabs } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { ETIcon } from 'Assets'
import { CreativesLibraryPage } from 'Pages'
import {
  CreativeUploaderPropsType,
  CreativeUploaderStatusPropsType,
} from 'types'
import { CreativeUploader, CreativeUploaderStatus } from 'Components'
import { AdSetDisplay } from '../components'

export const HTML5AdForm = ({
  uploaderProps,
  uploaderStatusProps,
  isPoliticalAndCampaignCreation,
}: {
  uploaderProps: CreativeUploaderPropsType
  uploaderStatusProps: CreativeUploaderStatusPropsType
  isPoliticalAndCampaignCreation?: boolean
}) => {
  const [selectedTab, setSelectedTab] = useState('')
  const [selectedHTML5Creatives, setSelectedHTML5Creatives] = useState<
    Creativeservicev1Creative[]
  >([])
  const handleSelect = (creative: Creativeservicev1Creative) => {
    setSelectedHTML5Creatives((prev) => [...prev, creative])
    setSelectedTab('GetFromLibrary')
  }

  const handleRemove = (creativeId: string) => {
    setSelectedHTML5Creatives((prev) => prev.filter((f) => f.id !== creativeId))
  }

  const handleUploadSideEffect = () => {
    setSelectedTab('UploadHTML5')
  }
  useEffect(() => {
    if (selectedTab !== 'UploadHTML5') uploaderStatusProps.setFiles([])
  }, [selectedTab])

  return (
    <div className="HTML5AdForm">
      <Tabs
        classNamePanel="HTM5AdForm__tabs flex-1 w-full"
        tabs={[
          {
            id: 'UploadHTML5',
            label: (
              <div className="HTML5AdForm__header-text-icon flex w-auto items-center justify-center gap-2">
                <ETIcon className="HTML5AdForm__header-icon" icon="html5" />
                <Text
                  className="HTML5AdForm__header-text font-normal normal-case"
                  tag="h5"
                >
                  Upload a new file
                </Text>
              </div>
            ),
            defaultTab: selectedTab === 'UploadHTML5',
            component: (
              <div className="HTML5AdForm__creativeUploader CreativeUpload__columns flex-1">
                <CreativeUploader
                  handleSelect={handleSelect}
                  uploadType="CREATIVE_TYPE_HTML5"
                  onClickSideEffect={handleUploadSideEffect}
                  hideTitle
                  {...uploaderProps}
                  isInPoliticalCampaignCreation={isPoliticalAndCampaignCreation}
                />
                <CreativeUploaderStatus {...uploaderStatusProps} />
              </div>
            ),
          },
          {
            id: 'GetFromLibrary',
            label: (
              <>
                <span
                  aria-label="Select from Creative Library"
                  data-tooltip="left"
                >
                  <Fa
                    className="text-primary"
                    icon="folders"
                    size={3}
                    type="duotone"
                  />
                </span>
                <span className="CreativeTypeSelector__tabs--text">
                  Select from Creative Library
                </span>
              </>
            ),
            defaultTab: selectedTab === 'GetFromLibrary',
            component: (
              <CreativesLibraryPage
                selectedCreatives={selectedHTML5Creatives}
                addToCreativeSet={handleSelect}
                removeFromCreativeSet={handleRemove}
                filterByType={['CREATIVE_TYPE_HTML5']}
                emptyState={
                  <div className="HTML5AdForm__library-empty-state text-grey-700 flex flex-col items-center">
                    Oops! There are no HTML5 creatives in your library.
                  </div>
                }
              />
            ),
          },
        ]}
      />
      <AdSetDisplay
        shouldOpen={!!selectedHTML5Creatives.length}
        selectedCreatives={selectedHTML5Creatives}
        setSelectedCreatives={setSelectedHTML5Creatives}
        showBannerChecklist={false}
        type="banner"
      />
    </div>
  )
}
