import ElToroLogo from 'Assets/logo_364.webp'
import ETLogo from 'Assets/etLogo.svg'
import ETLogoLight from 'Assets/logo-white.svg'
import { lightTheme } from './light-theme'
import { darkBlueTheme } from './darkBlue-theme'
import { redTheme } from './red-theme'
import { blueTheme } from './blue-theme'
import { darkTheme } from './dark-theme'

// Helpers to detect user's OS mode, using window func instead of useMediaQuery cannot be called at the top level
const mQ = window.matchMedia('(prefers-color-scheme: dark)')

const darkMode = () => {
  return mQ.matches
}

// Check for OS dark mode changes
const modeReload = () => {
  const reload = () => {
    window.location.reload()
  }
  const _handleModeChange = (e: MediaQueryListEvent) => {
    // If user's OS mode is dark, reloads to show darkTheme
    if (e.matches) reload()
    // else reloads to lightTheme
    reload()
  }

  mQ.addEventListener('change', _handleModeChange)
}

modeReload()

export const themes = {
  default: {
    logoThumb: darkMode() ? ETLogoLight : ETLogo,
    logoLarge: ElToroLogo,
    theme: darkMode() ? darkTheme : lightTheme,
  },
  light: {
    logoThumb: ETLogo,
    logoLarge: ElToroLogo,
    theme: lightTheme,
  },
  darkBlue: {
    logoThumb: ETLogoLight,
    logoLarge: ElToroLogo,
    theme: darkBlueTheme,
  },
  red: {
    logoThumb: ETLogo,
    logoLarge: ElToroLogo,
    theme: redTheme,
  },
  blue: {
    logoThumb: ETLogo,
    logoLarge: ElToroLogo,
    theme: blueTheme,
  },
  dark: {
    logoThumb: ETLogoLight,
    logoLarge: ElToroLogo,
    theme: darkTheme,
  },
} as const

export type themeNamesType = keyof typeof themes
