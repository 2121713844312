import { useState } from 'react'
import {
  Button,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
  TextHeader,
} from '@eltoro-ui/components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { PriceTextInput } from 'Components'
import { useAppContext } from 'Contexts'
import { styleTailwind, getTypeMoneyCost, useOrderLineCost } from 'Helpers'
import { IMPRESSION_MAX } from 'Components/OrderLineEditWrapper/components/shared'

const WarningText = styleTailwind(
  'p',
  'WarningText text-warning flex py-4 !text-xs font-bold',
)

export const OrderLineBudgetEdit: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onUpdateOrderLine: (updated: Campaignservicev1OrderLine) => void
  onCancel: () => void
}> = ({ orderLine, onUpdateOrderLine, onCancel }) => {
  const { currentOrg, campaignServiceApi, roles } = useAppContext()
  const [input, setInput] = useState<number>(
    getTypeMoneyCost(orderLine?.costRange?.estimate || {}),
  )
  const [showBelowLimitWarning, setLimitWarning] = useState<boolean>(false)

  // TODO: There will be restrictions based on if OL is prepay
  // also this should look at the order line's min impressions
  // OL min imps || org min imps || default
  const { minCost, maxCost, maxBudgetCPM } = useOrderLineCost(
    orderLine,
    currentOrg?.minimumImpressions || 50000,
    IMPRESSION_MAX,
  )

  const cpmCost =
    !orderLine?.costRange?.estimate || !maxBudgetCPM?.units
      ? 0
      : getTypeMoneyCost(maxBudgetCPM)

  const handleOnSave = () => {
    const newImpressions = ((input || 0) / cpmCost) * 1000

    if (!orderLine.id || !campaignServiceApi || !orderLine.orgId) return
    if (
      orderLine.minimumImpressions &&
      newImpressions < orderLine.minimumImpressions &&
      roles &&
      roles.includes('nextgen_admin')
    ) {
      return campaignServiceApi
        .advertisingPlatformServiceUpdateOrderLine(
          orderLine.id,
          orderLine.orgId,
          {
            impressions: Math.round(Number(newImpressions)),
            minimumImpressions: Math.round(Number(newImpressions)),
          },
        )
        .then((updated) => {
          showSuccessMessage(
            'The Budget has been successfully updated',
            `The budget is now $${input} `,
          )
          onUpdateOrderLine(updated)
        })
        .catch((err) => {
          if (err) {
            showErrorMessage(
              'The Budget update failed',
              'Please try again, or contact support',
            )
          }
        })
    }
    campaignServiceApi
      .advertisingPlatformServiceUpdateOrderLine(
        orderLine.id,
        orderLine.orgId,
        {
          impressions: Math.round(Number(newImpressions)),
        },
        'impressions',
      )
      .then((updated) => {
        showSuccessMessage(
          'The Budget has been successfully updated',
          `The budget is now $${input} `,
        )
        onUpdateOrderLine(updated)
      })
      .catch((err) => {
        if (err) {
          showErrorMessage(
            'The Budget update failed',
            'Please try again, or contact support',
          )
        }
      })
  }
  return (
    <div className="OrderLineBudgetEdit">
      <TextHeader className="border-b-thin border-primary mb-6 pb-2" type={4}>
        Edit budget
      </TextHeader>
      {cpmCost ? (
        <>
          <div className="OrderLineBudgetEdit__main flex items-center gap-2">
            <p>
              <strong>Adjust approximate budget:</strong> Budget at ~$
              {cpmCost.toFixed(2)} CPM
            </p>
            <PriceTextInput
              value={input}
              onChange={(val) => {
                if (val < minCost && roles?.includes('nextgen_admin')) {
                  if (!showBelowLimitWarning) {
                    showWarningMessage(
                      'Below Minimum Budget',
                      `You have set the budget to lower than the minimum of ${minCost.toFixed(
                        2,
                      )}`,
                    )
                  }
                  setLimitWarning(true)
                  setInput(val)
                }
                if (val < minCost && !roles?.includes('nextgen_admin')) {
                  showWarningMessage(
                    'Unable to update order line',
                    `You may not set the budget to lower than ${minCost.toFixed(
                      2,
                    )}`,
                  )
                  setInput(val)
                  setTimeout(() => {
                    setInput(Number(minCost.toFixed(2)))
                  }, 8000)
                }
                if (val > minCost) {
                  setLimitWarning(false)
                  setInput(val)
                }
              }}
              min={roles?.includes('nextgen_admin') ? 1 : minCost}
              max={process.env.NODE_ENV === 'development' ? maxCost : undefined}
            />
          </div>
          <WarningText className="OrderLineBudgetEdit__disclaimer">
            {showBelowLimitWarning || input < minCost
              ? `The budget for this order line is now below the minimum of ${minCost.toFixed(
                  2,
                )}`
              : ''}
          </WarningText>
        </>
      ) : (
        <WarningText>Missing CPM cost for this order line</WarningText>
      )}
      <div className="OrderLineBudgetEdit__footer-buttons my-2 flex items-center justify-end gap-2">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          kind="primary"
          onClick={handleOnSave}
          disabled={
            (roles?.includes('nextgen_admin') ? false : input < minCost) ||
            !cpmCost
          }
        >
          Save
        </Button>
      </div>
    </div>
  )
}
