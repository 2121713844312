import React from 'react'
import { Fa, Text } from '@eltoro-ui/components'
import './CollapseButton.scss'

export const CollapseButton: React.FC<{
  classNameButton?: string
  className?: string
  isCollapsed: boolean
  showChevron?: boolean
  iconOnly?: boolean
  handleClick: () => void
  iconName?: string
}> = ({
  classNameButton = '',
  className = '',
  handleClick,
  isCollapsed,
  showChevron = false,
  iconOnly = false,
  iconName,
}) => {
  return (
    <button
      type="button"
      className={`CollapseButton group relative flex items-center pl-2 pt-1 transition-all ${classNameButton} ${
        isCollapsed && !iconOnly
          ? 'CollapseButton__collapsed'
          : 'CollapseButton__expanded'
      } ${iconOnly ? '' : 'icon-only'}`}
      onClick={handleClick}
    >
      <span
        className={`CollapseButton__icon relative z-20 flex h-full items-center justify-end gap-1 ${className} ${
          showChevron ? 'visible' : ''
        } ${isCollapsed && !iconOnly ? 'ml-0' : ''} ${
          isCollapsed ? 'isCollapsed' : 'isExpanded'
        }`}
        aria-label={isCollapsed ? 'Expand' : 'Collapse/close'}
        data-tooltip="left"
      >
        {isCollapsed && !iconOnly ? (
          <Text
            tag="h5"
            className="CollapseButton__back-link group-hover:border-b-secondary-500 border-b-thin text-secondary-500 border-secondary-500 inline-block h-full w-auto uppercase"
          >
            Back
          </Text>
        ) : (
          <Fa
            className={`CollapseButton__icon-fa ${
              isCollapsed ? 'text-secondary-500' : 'text-primary-300'
            }`}
            icon={(() => {
              if (iconName) return iconName
              return !iconOnly && showChevron
                ? 'circle-chevron-up'
                : 'chevron-up'
            })()}
            size={1}
            animate={isCollapsed ? 'flip' : 'fade'}
          />
        )}
      </span>
    </button>
  )
}
