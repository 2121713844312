import { Button, Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import { useCopy } from '@eltoro-ui/hooks'

export const CopyButton = ({
  text,
  icon,
  iconCopied,
  type,
  className,
}: {
  text: string
  icon?: string
  iconCopied?: string
  type?: string
  className?: string
}) => {
  const [copyUrl, copied] = useCopy()

  return (
    <Button
      className={classNames(
        'CopyButton relative !mb-0',
        className,
        copied ? 'tooltip-success' : '',
      )}
      iconOnly={
        !copied ? (
          <Fa icon={icon || 'copy'} className="!text-s" size={1} />
        ) : (
          <Fa icon={iconCopied || 'copy'} className="CopiedIcon" size={1} />
        )
      }
      size="m"
      onClick={() => copyUrl(text, type)}
      ariaLabel={copied ? 'Copied' : `Copy ${type || ''}`}
      dataTooltip={copied ? 'right' : 'left'}
    />
  )
}
