/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Orgmanagerv1Org } from '../models/Orgmanagerv1Org';
import { HttpFile } from '../http/http';

export class V1ListOrgsResponse {
    'orgs'?: Array<Orgmanagerv1Org>;
    /**
    * A token, which can be sent as `page_token` to retrieve the next page. If this field is omitted, there are no subsequent pages.
    */
    'nextPageToken'?: string;
    /**
    * The total count of organizations irrespective of pagination. If filtering is used, the `total_size` field reflects the total count of organizations after the filter is applied.
    */
    'totalSize'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgs",
            "baseName": "orgs",
            "type": "Array<Orgmanagerv1Org>",
            "format": ""
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "totalSize",
            "baseName": "total_size",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1ListOrgsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

