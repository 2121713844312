const tiltKeycloakBaseUrl = 'http://sunflowers-keycloak-headless:8080/'
export const tiltConfig = {
  authority: tiltKeycloakBaseUrl,
  client_id: process.env.REACT_APP_AUTH_OIDC_CLIENT_ID,
  // VITE
  //   client_id: import.meta.env.VITE_APP_AUTH_OIDC_CLIENT_ID,
  loadUserInfo: true,
  redirect_uri: 'http://localhost:3001',
  client_secret: process.env.REACT_APP_AUTH_OIDC_CLIENT_SECRET,
  // VITE
  //   client_secret: import.meta.env.VITE_APP_AUTH_OIDC_CLIENT_SECRET
  metadata: {
    issuer: `${tiltKeycloakBaseUrl}realms/eltoro`,
    authorization_endpoint: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/auth`,
    token_endpoint: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/token`,
    introspection_endpoint: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/token/introspect`,
    userinfo_endpoint: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/userinfo`,
    end_session_endpoint: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/logout`,
    frontchannel_logout_session_supported: true,
    frontchannel_logout_supported: true,
    jwks_uri: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/certs`,
    check_session_iframe: `${tiltKeycloakBaseUrl}realms/eltoro/protocol/openid-connect/login-status-iframe.html`,
  },
}

const createConfig = (
  baseURL: string,
  redirectURL: string,
  clientId?: string,
) => ({
  authority: `${baseURL}/auth`,
  client_id: clientId || 'eltoro-ui',
  loadUserInfo: true,
  redirect_uri: redirectURL,
  metadata: {
    issuer: `${baseURL}/auth/realms/eltoro`,
    authorization_endpoint: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/auth`,
    token_endpoint: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/token`,
    introspection_endpoint: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/token/introspect`,
    userinfo_endpoint: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/userinfo`,
    end_session_endpoint: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/logout`,
    frontchannel_logout_session_supported: true,
    frontchannel_logout_supported: true,
    jwks_uri: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/certs`,
    check_session_iframe: `${baseURL}/auth/realms/eltoro/protocol/openid-connect/login-status-iframe.html`,
  },
})

export const localConfig = createConfig(
  'https://auth.api.dev.eltoro.com',
  'http://localhost:3000',
)

export const devConfig = createConfig(
  'https://auth.api.dev.eltoro.com',
  'https://eltoro-ui.api.dev.eltoro.com/',
)

export const prodConfig = createConfig(
  'https://auth.api.eltoro.com',
  'https://eltoro-ui.api.eltoro.com/',
)
export const prodNextGenConfig = createConfig(
  'https://auth.api.eltoro.com',
  'https://nextgen.eltoro.com/',
)
