import { useEffect, useState } from 'react'
import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import './PriceTextInput.scss'

export const PriceTextInput = ({
  value,
  onChange,
  min,
  max,
  className,
  inputClassName,
}: {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  className?: string
  inputClassName?: string
}) => {
  const [touched, setTouched] = useState(false)
  const [input, setInput] = useState(value.toString() || '')

  const resetToValidNumber = () => {
    let newValue = value

    if (!input) {
      onChange(0)
      setInput('0')
      return
    }

    if (min && Number(value) < min) {
      newValue = min
    }
    if (max && Number(value) > max) {
      newValue = max
    }

    if (newValue.toString().split('.')[1]) {
      onChange(+newValue.toFixed(2))
      setInput(newValue.toFixed(2))
    } else {
      onChange(newValue)
      setInput(newValue.toString())
    }
  }

  useEffect(() => {
    setInput(value.toString())
  }, [value])

  return (
    <div
      className={classNames('PriceTextInput w-full', className, {
        'animate-glowwarning w-full':
          touched && ((max && +value > max) || (min && +value < min)),
      })}
    >
      <Fa icon="dollar-sign" size={1} />
      <input
        className={classNames('PriceTextInput__input', inputClassName)}
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
          if (e.target.value) onChange(Number(e.target.value))
        }}
        type="number"
        min={min}
        max={max}
        onBlur={() => {
          setTouched(true)
          resetToValidNumber()
        }}
        onKeyDown={(e) => {
          setTouched(true)
          if (e.key === 'e' || e.key === 'E') e.preventDefault()
          if (e.key === 'Enter') {
            resetToValidNumber()
          }
        }}
      />
    </div>
  )
}
