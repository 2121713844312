import {
  Targetjobservicev1Audience,
  Campaignservicev1Audience,
} from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { getApiConfig } from 'Requests'
import { getJobCounts } from 'Helpers'

// Takes a list of targets and returns the job matches and wether they are all quoted or not
export const getJobQuotes = async (
  audiences: Campaignservicev1Audience[],
  org: string,
) => {
  if (!audiences) return undefined
  let quoteStatus: {
    job: Targetjobservicev1Audience
    quoted: boolean
    count?: number
  }[] = []
  const token = localStorage.getItem('eltoro_token')
  const { audienceServiceAPI } = await ApiFactory(token)
  if (!audienceServiceAPI) return {}

  const jobCounts = await Promise.all(
    audiences.map((currentJob) => {
      if (!currentJob.id) return 0
      return audienceServiceAPI
        .advertisingPlatformServiceGetAudience(
          currentJob.id,
          org,
          getApiConfig(localStorage.getItem('eltoro_token') || token || ''),
        )
        .then((fullJob) => {
          const jobResult = fullJob.result
          if (
            !jobResult ||
            (jobResult?.processCompleted !== undefined &&
              jobResult.processCompleted < 1)
          ) {
            quoteStatus = [...quoteStatus, { job: fullJob, quoted: false }]
          } else {
            quoteStatus = [
              ...quoteStatus,
              {
                job: fullJob,
                quoted: true,
                count: getJobCounts(fullJob),
              },
            ]
          }

          return getJobCounts(fullJob) || 0
        })
    }),
  )

  return {
    count:
      jobCounts.length > 0 ? jobCounts.reduce((prev, num) => prev + num) : 0,
    quoteStatus,
  }
}
