/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* A specific type of Insertion Order. See [Insertion Order Service](https://docs.xandr.com/bundle/xandr-api/page/insertion-order-service.html) in Xandr's documentation.
*/
export class V3NonSeamlessInsertionOrder {
    /**
    * The name of the insertion order. (Maximum of 255 characters.)
    */
    'name'?: string;
    /**
    * The custom code for the insertion order.
    */
    'code'?: string;
    /**
    * Start date of the insertion order.
    */
    'startDate'?: Date;
    /**
    * End date of the insertion order.
    */
    'endDate'?: Date;
    /**
    * The ID of the insertion order.
    */
    'id'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3NonSeamlessInsertionOrder.attributeTypeMap;
    }

    public constructor() {
    }
}

