import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Fa,
  Popover,
  TextHeader,
  TextInput,
} from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { LibraryBreadcrumbs, TablePagination, IconButton } from 'Components'
import { useAppContext, useLibraryContext } from 'Contexts'
import { styleTailwind } from 'Helpers'
import './LibraryTableWrapper.scss'

const MenuItem = styleTailwind(
  Button,
  'LibraryMenuItem text-primary hover:bg-grey-100 hover:text-primary-600 w-full py-1',
)

const TableButton = styleTailwind(Button, 'rounded-s')

type LibraryTableWrapperProps = {
  type: 'creatives' | 'audiences'
  children?: React.ReactNode
}

export const LibraryTableWrapper: React.FC<LibraryTableWrapperProps> = ({
  type,
  children,
}) => {
  const [showSortBy, setShowSortBy] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const { currentOrg } = useAppContext()

  const sortOptionsRef = useRef<HTMLDivElement>(null)
  const sortOptionsButtonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([sortOptionsRef, sortOptionsButtonRef], () =>
    setShowSortBy(false),
  )

  const {
    pagination,
    currentFolderPath,
    setCurrentFolderPath,
    folderPagination,
    folderSort,
    setFolderSort,
    childOrgsOnly,
    setChildOrgsOnly,
    showArchived,
    setShowArchived,
    tableFilter,
    handleGlobalFilter,
  } = useLibraryContext()

  const archiveDisabled = tableFilter?.status && tableFilter.status.length > 0

  return (
    <div className="LibraryTableWrapper">
      <div className="LibraryTableWrapper__header flex flex-col justify-between gap-x-4 pt-3">
        {folderPagination.currentPageData.length > 0 &&
          currentFolderPath.length === 1 && (
            <div
              className={classNames(
                'LibraryTableWrapper__folders border-b-thin border-tint-gray-200 pb-2',
                {
                  'LibraryTableWrapper__body--loading':
                    folderPagination.loading,
                },
              )}
            >
              <div
                id="LibraryTableWrapper__folder-header"
                className="LibraryTableWrapper__folder-header relative flex items-center justify-between gap-2"
              >
                <TextHeader
                  type={4}
                  className="LibraryTableWrapper__folder-count -mt-2 flex gap-2"
                >
                  <span className="font-light">Number of folders:</span>
                  <span>{folderPagination.totalItems}</span>
                </TextHeader>
                {folderPagination?.totalItems > 5 ? (
                  <Popover
                    className="LibraryTableWrapper__popover right-0 top-16"
                    position={['bottom']}
                    align="start"
                    attachId="LibraryTableWrapper__folder-header"
                    content={
                      <div
                        ref={sortOptionsRef}
                        className="folderSort bg-base border-thin border-tint-gray-200 -mt-2 flex flex-col gap-1 p-1"
                      >
                        {folderSort && (
                          <MenuItem
                            kind="text"
                            iconLeft={
                              <Fa icon="xmark" type="duotone" size={1} />
                            }
                            onClick={() => setFolderSort(undefined)}
                          >
                            Clear sort
                          </MenuItem>
                        )}
                        <MenuItem
                          kind="text"
                          iconLeft={
                            <Fa icon="sort-alpha-up" type="duotone" size={1} />
                          }
                          onClick={() =>
                            setFolderSort({ field: 'name', direction: 'asc' })
                          }
                        >
                          Name asc
                        </MenuItem>
                        <MenuItem
                          kind="text"
                          iconLeft={
                            <Fa
                              icon="sort-alpha-down"
                              type="duotone"
                              size={1}
                            />
                          }
                          onClick={() =>
                            setFolderSort({ field: 'name', direction: 'desc' })
                          }
                        >
                          Name desc
                        </MenuItem>
                        <MenuItem
                          kind="text"
                          iconLeft={
                            <Fa icon="arrow-up-9-1" type="duotone" size={1} />
                          }
                          onClick={() =>
                            setFolderSort({ field: 'count', direction: 'asc' })
                          }
                        >
                          Count asc
                        </MenuItem>
                        <MenuItem
                          kind="text"
                          iconLeft={
                            <Fa icon="arrow-down-1-9" type="duotone" size={1} />
                          }
                          onClick={() =>
                            setFolderSort({ field: 'count', direction: 'desc' })
                          }
                        >
                          Count desc
                        </MenuItem>
                      </div>
                    }
                    isOpen={showSortBy}
                  >
                    <Button
                      className="LibraryTableWrapper__folders-button mt-7"
                      kind="text"
                      iconLeft={
                        <Fa
                          icon={showSortBy ? 'caret-up' : 'caret-down'}
                          size={1}
                          className="pb-1"
                        />
                      }
                      onClick={() => setShowSortBy(!showSortBy)}
                      buttonRef={sortOptionsButtonRef}
                      size="s"
                    >
                      {(() => {
                        if (folderSort) {
                          return `Sort: ${folderSort.field} ${folderSort.direction}`
                        }
                        return 'Sort folders'
                      })()}
                    </Button>
                  </Popover>
                ) : null}
              </div>
              <div className="LibraryTableWrapper__folder-list IconButtons__list Folders__list">
                {folderPagination.currentPageData.map((folder, index) => {
                  return (
                    <span
                      className="IconButton__wrap--small"
                      aria-label={`${
                        folder.count !== undefined ? folder.count : '0'
                      } file${
                        folder.count !== undefined && folder.count > 1
                          ? 's '
                          : ''
                      }`}
                      data-tooltip="top"
                      key={folder.name}
                    >
                      <IconButton
                        className="IconButton__library-folders relative"
                        classNameButton="text-center small"
                        id={folder.name || `${index}`}
                        title={folder.name || ''}
                        icon={
                          <Fa
                            className="text-secondary-200"
                            icon={
                              type === 'creatives'
                                ? 'folder-image'
                                : 'folder-grid'
                            }
                            size={10}
                            type="duotone"
                          />
                        }
                        subTitle={`${folder.count}`}
                        isSelected={false}
                        handleClick={() =>
                          folder.name && setCurrentFolderPath(['', folder.name])
                        }
                        centered
                        contentVertical
                        capitalize={false}
                      />
                    </span>
                  )
                })}
              </div>
              {/* Only show folder pagination if there are more than five folders */}
              {folderPagination?.totalItems > 5 ? (
                <div className="LibraryTableWrapper__pagination-footer folder-pagination">
                  <TablePagination pagination={folderPagination} />
                </div>
              ) : null}
            </div>
          )}
        <div className="LibraryTableWrapper__header flex justify-between pt-4">
          <div className="LibraryTableWrapper__header">
            <LibraryBreadcrumbs
              type={type}
              onCurrentFolderChange={(newPath) => setCurrentFolderPath(newPath)}
            />
          </div>
        </div>
        <div className="LibaryTableHeader__search-bar tablet:flex-wrap flex items-center justify-between gap-4">
          <TextInput
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value)
              handleGlobalFilter(['id', 'name'], [e.target.value])
            }}
            placeholder={`Search ${type}`}
            iconRight={
              searchInput ? (
                <button
                  onClick={() => {
                    setSearchInput('')
                    handleGlobalFilter(['id', 'name'], [])
                  }}
                >
                  <Fa icon="xmark" size={1} className="text-secondary" />
                </button>
              ) : (
                <Fa icon="search" size={1} className="text-primary" />
              )
            }
          />
          <div className="tablet:flex-wrap flex items-center gap-2">
            <span
              aria-label={(() => {
                if (!currentOrg?.childOrgs?.length)
                  return 'This organization does not have child orgs.'
                if (childOrgsOnly)
                  return `Click to hide ${type} from child orgs`
                return `Click to include ${type} from child orgs`
              })()}
              data-tooltip="left" // tooltip appears under back button on some instance
            >
              <TableButton
                iconRight={
                  <Fa
                    icon={childOrgsOnly ? 'square-check' : 'square'}
                    size={1}
                    type={!childOrgsOnly ? 'light' : undefined}
                  />
                }
                className="rounded-s"
                onClick={() => setChildOrgsOnly(!childOrgsOnly)}
                disabled={!currentOrg?.childOrgs?.length}
                kind={childOrgsOnly ? 'primary' : undefined}
              >
                Show child orgs
              </TableButton>
            </span>
            <span
              aria-label={(() => {
                if (archiveDisabled)
                  return 'Please clear the status filter before using archive toggle.'
                if (showArchived) return `Click to hide archived ${type}`
                return `Click to include archived ${type}`
              })()}
              data-tooltip="left"
            >
              <TableButton
                iconRight={
                  <Fa
                    icon={showArchived ? 'square-check' : 'square'}
                    size={1}
                    type={!showArchived ? 'light' : undefined}
                  />
                }
                onClick={() => setShowArchived(!showArchived)}
                disabled={archiveDisabled}
                kind={archiveDisabled ? 'primary' : undefined}
              >
                Show archive
              </TableButton>
            </span>
            {/* TODO: in PLADV-220 (I've started digging around with this when I have time) */}
            {/* <span aria-label="Coming Soon" data-tooltip="top">
              <TableButton
                iconRight={<Fa icon="arrows-rotate" size={1} />}
                disabled
              >
                Reset Table
              </TableButton>
            </span> */}
          </div>
        </div>
      </div>
      <div
        className={classNames('LibraryTableWrapper__body', {
          'LibraryTableWrapper__body--loading': pagination.loading,
        })}
      >
        {children}
        {!pagination.loading && !pagination.totalItems ? (
          <div className="LibraryTableWrapper__empty-state flex flex-col items-center py-6">
            <Fa
              icon="folder"
              size={5}
              type="duotone"
              className="text-primary-200"
            />
            <p className="text-grey-600">
              Your {`${type[0].toUpperCase()}${type.substring(1)}`} Library is
              empty.
              {type === 'audiences' ? (
                <Link className="LibraryTableLink pl-1" to="/audiences/create">
                  Create some now!
                </Link>
              ) : (
                ' Create some now!'
              )}
            </p>
          </div>
        ) : null}
        {pagination.totalItems > 10 ? (
          <div className="LibraryTableWrapper__pagination-footer">
            <TablePagination pagination={pagination} />
          </div>
        ) : null}
      </div>
    </div>
  )
}
