import React from 'react'
import {
  Avatar,
  Fa,
  showErrorMessage,
  Table,
  TableColumnType,
  TextHeader,
} from '@eltoro-ui/components'
import { usePagination } from '@eltoro-ui/hooks'
import { V1OrgData } from 'next-gen-sdk'
import { Link } from 'react-router-dom'
import { useAppContext } from 'Contexts'
import { TablePagination } from 'Components'

const VisitOrgLink: React.FC<{ org: V1OrgData }> = ({ org }) => {
  const { orgServiceApi, setCurrentOrg } = useAppContext()

  const getOrg = async () => {
    if (!orgServiceApi || !org.orgId) return undefined
    const fullOrg = await orgServiceApi
      .advertisingPlatformServiceGetOrg(org.orgId)
      .catch((err) =>
        showErrorMessage('Error getting org', err?.body?.message || ''),
      )
    if (fullOrg) setCurrentOrg(fullOrg)
  }

  return (
    <div className="flex items-center justify-end p-2">
      <Link onClick={() => getOrg()} to={`/orgs/${org.orgId}/org-settings`}>
        Visit Org
      </Link>
      <Fa icon="chevron-right" size={1} />
    </div>
  )
}

const searchOrgTableColumns: TableColumnType<V1OrgData>[] = [
  {
    path: 'name',
    label: 'Name',
    filterOn: 'string',
    RowCell: (org) => (
      <div className="SearchOrgTable__name-row flex max-w-md items-center gap-2 p-1">
        {(() => {
          if (org.logo) {
            return <Avatar src={org.logo} rounded />
          }
          return <Avatar name={org.name || ''} rounded />
        })()}
        {org.name}
      </div>
    ),
  },
  {
    path: 'orgId',
    label: 'ID',
    removeFilter: true,
  },
  {
    path: '',
    label: '',
    removeFilter: true,
    removeSort: true,
    alignment: 'right',
    RowCell: (org) => <VisitOrgLink org={org} />,
  },
]

export const SearchOrgTable: React.FC<{
  results: V1OrgData[]
}> = ({ results }) => {
  const orgPagination = usePagination<V1OrgData>({
    pageSize: 5,
    sourceData: results,
  })

  if (!results.length) return null

  return (
    <div className="SearchCreativeTable">
      <TextHeader type={4} className="SearchCreativeTable__header mt-4">
        Orgs ({results.length})
      </TextHeader>
      <div className="p-4">
        {orgPagination.data && (
          <>
            <Table
              columns={searchOrgTableColumns}
              rows={orgPagination.data}
              // onSort={() => {}}
              // onFilter={() => {}}
            />
            <div className="flex justify-end pt-2">
              <TablePagination pagination={orgPagination} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
