import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const rate = (num: number, total: number, round = 10000000) => {
  const newRate = Math.round(round * (num / Math.max(1, total))) / round
  if (Number.isNaN(newRate)) return 0
  return newRate
}

export const rateCeil = (num: number, deno: number) =>
  Math.ceil(rate(num, deno))

export const getDurationHours = (start: Date, stop: Date) => {
  if (!start || !stop) return 0
  // we don't want extra days if we cross timezones nor DST hours
  //   so we count # hrs, subtract 1 for start/stop diff, subtract 1 for DST, and then round-up
  const d0 = dayjs.utc(start).startOf('hour')
  const d1 = dayjs.utc(stop).startOf('hour')
  return Math.abs(d1.diff(d0, 'hours')) + 1
}
