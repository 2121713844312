import React, { useRef, useState, ReactNode } from 'react'
import { Fa, Popover, TextBody } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { LinkButton } from 'Components'

export const WarningMsg: React.FC<{
  popoverContent?: ReactNode
  popoverText?: string
  className?: string
  icon?: string
  textType?: 1 | 2 | 3
  children?: React.ReactNode
}> = ({
  popoverContent,
  popoverText,
  className,
  icon = 'warning',
  textType = 3,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const infoContentRef = useRef<HTMLDivElement>(null)
  const infoButtonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([infoContentRef, infoButtonRef], () => setIsOpen(false))

  return (
    <div
      className={`WarningMsg text-warning bg-warning-50 rounded-s p-2 ${className}`}
    >
      <div className="flex items-start gap-1">
        <Fa icon={icon} size={1} className="text-warning mt-2" />
        <TextBody type={textType} lineHeight={1}>
          <strong>Warning:</strong> {children}
        </TextBody>
      </div>
      {popoverContent && (
        <Popover
          position={['bottom']}
          align="center"
          content={
            <div
              ref={infoContentRef}
              className="bg-base text-m text-grey-500 rounded p-2 shadow-md"
            >
              {popoverContent}
            </div>
          }
          isOpen={isOpen}
        >
          <div className="mt-2 w-auto max-w-max pl-7" ref={infoButtonRef}>
            <LinkButton
              text={popoverText || 'Learn More'}
              onClick={() => !isOpen && setIsOpen(true)}
            />
          </div>
        </Popover>
      )}
    </div>
  )
}
