import React from 'react'
import { Fa, TextHeader } from '@eltoro-ui/components'
import { useLibraryContext } from 'Contexts'
import { LinkButton } from 'Components'
import { truncateIt } from 'Helpers'

export const LibraryBreadcrumbs: React.FC<{
  type: 'creatives' | 'audiences'
  onCurrentFolderChange: (newFolderPath: string[]) => void
}> = ({ type, onCurrentFolderChange }) => {
  const {
    pagination: { totalItems, loading },
    currentFolderPath,
  } = useLibraryContext()

  const renderBreadcrumbItem = (folder: string, index: number) => {
    if (index === currentFolderPath.length - 1) {
      const truncatedTitle = truncateIt(folder, 28)
      const currentLocationName =
        folder === ''
          ? `Your ${type === 'creatives' ? 'Creative' : 'Audience'} Library`
          : truncatedTitle

      return (
        <TextHeader className="text-l font-medium" key={folder} type={4}>
          {loading ? (
            currentLocationName
          ) : (
            <div className="flex gap-x-2">
              {folder === '' ? (
                <span className="text-l font-medium">
                  {currentLocationName}:
                </span>
              ) : (
                <span
                  className="text-l font-medium"
                  aria-label={`${folder}`}
                  data-tooltip="bottom"
                >
                  {truncatedTitle}:
                </span>
              )}
              <span className="font-bold">
                {totalItems || 0} item{totalItems > 1 ? 's' : null}
              </span>
            </div>
          )}
        </TextHeader>
      )
    }

    return (
      <React.Fragment key={folder}>
        <LinkButton
          size="l"
          text={
            folder === ''
              ? `Your ${type === 'creatives' ? 'Creative' : 'Audience'} Library`
              : folder
          }
          onClick={() => {
            const folderIndex = currentFolderPath.indexOf(folder)
            onCurrentFolderChange(currentFolderPath.slice(0, folderIndex + 1))
          }}
          className="mx-0"
        />
        <Fa icon="chevron-right" size={1} className="scale-75" type="light" />
      </React.Fragment>
    )
  }

  return (
    <div className="LibraryBreadcrumbs flex items-center">
      {currentFolderPath.map(renderBreadcrumbItem)}
    </div>
  )
}
