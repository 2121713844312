/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetorchestratorv2FileType } from '../models/Targetorchestratorv2FileType';
import { V1DataSource } from '../models/V1DataSource';
import { HttpFile } from '../http/http';

/**
* Input to Create Target from JSON
*/
export class V2CreateJsonTargetRequest {
    'type'?: Targetorchestratorv2FileType;
    /**
    * The RefID of the Target.
    */
    'refId'?: string;
    /**
    * The User's ID that is associated with the Target.
    */
    'userId'?: string;
    /**
    * The ID of the Org to which the Target ID associated.
    */
    'orgId'?: string;
    'dataSource'?: V1DataSource;
    /**
    * The id of the target described by the file's content. This is an optional field to be used in migrations but nonetheless needds to be unique, if used.
    */
    'targetId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "Targetorchestratorv2FileType",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V2CreateJsonTargetRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

