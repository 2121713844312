/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V3GenericDeployRequest {
    /**
    * The advertiser ID that all objects to be deployed are related.
    */
    'advertiserId'?: string;
    /**
    * The Step Function the deploymeny will use.
    */
    'stepFunction'?: string;
    /**
    * The time at which the deployment is scheduled for.
    */
    'scheduledTime'?: Date;
    /**
    * The config related to the deployment.
    */
    'config'?: string;
    /**
    * The campaign ID that is to be deployed.
    */
    'campaignId'?: string;
    /**
    * The segment IDs that are to be deployed.
    */
    'segmentIds'?: Array<string>;
    /**
    * The creative IDs that are to be deployed.
    */
    'creativeIds'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "stepFunction",
            "baseName": "step_function",
            "type": "string",
            "format": ""
        },
        {
            "name": "scheduledTime",
            "baseName": "scheduled_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "config",
            "baseName": "config",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentIds",
            "baseName": "segment_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "creativeIds",
            "baseName": "creative_ids",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3GenericDeployRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

