import React, { useState } from 'react'
import { V1RuleSet } from 'next-gen-sdk'
import { RadioButton, RadioButtonGroup, Checkbox } from '@eltoro-ui/components'
import { SelectedUseCaseType, StepperSection } from '../../WebToHome'
import { RuleSetContainer } from '../RuleSetContainer'
import { suggUsesLabels } from './SuggestedUsesLabels'

export const Step2BusinessLogic: React.FC<{
  selectedUseCase?: SelectedUseCaseType
  setSelectedUseCase: React.Dispatch<
    React.SetStateAction<SelectedUseCaseType | undefined>
  >
  setRuleSets: React.Dispatch<React.SetStateAction<V1RuleSet[]>>
  navButtons: (
    nextStep: 'domain' | 'logic' | 'finalize' | 'success',
    backStep?: 'domain' | 'logic' | 'finalize' | undefined,
  ) => JSX.Element
}> = ({ selectedUseCase, setSelectedUseCase, setRuleSets, navButtons }) => {
  const [optionShow, setOptionShow] = useState(false)
  const [location, setLocation] = useState(false)
  const [duplicate, setDuplicate] = useState(false)
  return (
    <StepperSection className="WebToHome__Step2BusinessLogic">
      <p className="text-l font-light">Step 2</p>
      <p className="text-l font-bold">Set Business Logic</p>
      <p>
        Define the business logic to determine who will be included in your
        audience
      </p>

      <div className="WebToHome__Step2BusinessLogic__wrapper mt-4 flex flex-grow flex-wrap gap-8 lg:flex-nowrap">
        {/* Options side */}
        <div className="WebToHome__Step2BusinessLogic__suggested-use-case-choices min-w-[17rem]">
          <div className="SuggestedUseOption__container">
            <fieldset>
              <legend className="font-bold">Suggested Use Cases:</legend>
              <RadioButtonGroup
                name="Suggested Use Cases"
                valueSelected={selectedUseCase!}
                onChange={(event) => {
                  setSelectedUseCase(event as SelectedUseCaseType)
                  setOptionShow(true)
                }}
              >
                {suggUsesLabels.map(({ id, label, labelSub, value }) => (
                  <RadioButton
                    key={id}
                    id={id}
                    value={value!}
                    checked={value === selectedUseCase}
                    label={label}
                    labelSub={labelSub}
                  />
                ))}
              </RadioButtonGroup>
            </fieldset>
          </div>
          {/* replace this temporary fieldset */}
          {optionShow && (
            <fieldset className="OptionalCheckbox animate-slideup mt-4">
              <legend className="font-bold">Optional</legend>
              <Checkbox
                checked={location}
                onChange={() => {
                  setLocation(!location)
                }}
                label="Exclude Locations (Geo-location filters)"
                labelSub="Identify states and/or ZIP codes to be excluded from your audience"
              />
              <Checkbox
                checked={duplicate}
                onChange={() => {
                  setDuplicate(!duplicate)
                }}
                label="Exclude Duplicates"
                labelSub="Filter out repeat visitors during the selected timeframe"
              />
            </fieldset>
          )}
        </div>
        {/* RegEx container side */}
        <div className="WebToHome__Step2BusinessLogic__suggested-use-case-container mr-1 flex-grow">
          {selectedUseCase === 'visitors_who_left' && (
            <RuleSetContainer
              type="visitors_who_left"
              onChange={(sets) => setRuleSets(sets)}
            />
          )}
          {selectedUseCase === 'keyword_targeting' && (
            <RuleSetContainer
              type="keyword_targeting"
              onChange={(sets) => setRuleSets(sets)}
            />
          )}
          {selectedUseCase === 'visits_to_site' && (
            <RuleSetContainer
              type="visits_to_site"
              onChange={(sets) => setRuleSets(sets)}
            />
          )}
          {selectedUseCase === 'advanced_options' && (
            <RuleSetContainer
              type="advanced_options"
              onChange={(sets) => setRuleSets(sets)}
            />
          )}
        </div>
      </div>
      {navButtons('finalize', 'domain')}
    </StepperSection>
  )
}
