import { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  Loader,
  Select,
  SelectOptionType,
  Text,
} from '@eltoro-ui/components'
import { Billingservicev1Org, V1CreditCard } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { checkExp, isOrgBillable } from 'Helpers'
import { LinkButton, CCModal } from 'Components'

export const CreditCardSelect = ({
  setSelectedCard,
  selectedCard,
  disabled,
}: {
  setSelectedCard: (cardId?: string) => void
  selectedCard?: string
  disabled?: boolean
}) => {
  const [billingError, setBillingError] = useState(false)
  const [showCCModal, setShowCCModal] = useState(false)
  const [refreshCards, setRefreshCards] = useState(true)
  const [billingOrg, setBillingOrg] = useState<Billingservicev1Org>()
  const [loading, setLoading] = useState(true)
  const { currentOrg, billingServiceApi } = useAppContext()

  // Get/refresh org's credit cards
  useEffect(() => {
    if (!currentOrg?.id || !billingServiceApi || !refreshCards) return
    setLoading(true)
    billingServiceApi
      .advertisingPlatformServiceGetBillingOrg(
        currentOrg.id, // orgId
      )
      .then((res) => {
        setBillingOrg(res)
        if (res.isBillingReady) {
          const authorizedCards = res.creditCards?.filter(
            (cc) =>
              cc.authorization &&
              cc.authorization.approved &&
              cc.authorization.signature,
          )
          setSelectedCard(authorizedCards?.find((cc) => cc.isDefault)?.id)
        }
      })
      .catch(() => setBillingError(true))
      .finally(() => {
        setRefreshCards(false)
        setLoading(false)
      })
  }, [
    billingServiceApi,
    currentOrg?.id,
    currentOrg?.prepay,
    refreshCards,
    setSelectedCard,
  ])

  const cards = billingOrg?.creditCards?.filter(
    (cc) =>
      cc.authorization &&
      cc.authorization.approved &&
      cc.authorization.signature &&
      cc.expirationDate &&
      checkExp(cc.expirationDate),
  ) // authorized unexpired cards only

  const selectedCardDetail = cards?.find((card) => card.id === selectedCard)

  const icon = (cc: V1CreditCard) => {
    if (cc.type === 'American Express') return 'cc-amex'
    if (cc.type === 'Diners Club International') return 'cc-diners-club'
    return `cc-${(cc?.type || '').toLowerCase()}`
  }

  if (loading) return <Loader />

  return (
    <>
      <div className="CreditCardSelect flex flex-col gap-2">
        {(() => {
          if (currentOrg && isOrgBillable(currentOrg) && billingError)
            return (
              <div className="bg-danger-100 text-danger-500 border-danger-500 rounded border p-2">
                <p className="font-bold">Billing Error</p>
                <p className="text-sm">
                  There was an error getting your billing information. Please
                  reload the page and try again. If you continue to see this
                  error, please reach out to support.
                </p>
              </div>
            )
          if (currentOrg && !isOrgBillable(currentOrg)) return null
          if (cards && cards.length > 0)
            return (
              <>
                <Text>Choose a Credit Card on file:</Text>
                {/* 5/26/23 - per Frankie- this is from a previous idea that all orgs defaulted to pre-pay and could request to be invoiced on an as-needed basis. Commenting out because it is not v2 parity. */}
                {/* {currentOrg && isOrgBillable(currentOrg) && !isPrepay() && (
                <LinkButton
                  text="Request Invoice"
                  onClick={() => {}}
                  className="max-w-max py-1"
                />
              )} */}
                <div className="flex flex-col items-end gap-1">
                  <Select
                    disabled={disabled}
                    placeholder="Select credit card"
                    options={cards.map((cc) => {
                      return {
                        value: cc.id,
                        label: (
                          <div className="flex gap-1 text-sm">
                            <Fa
                              icon={icon(cc)}
                              type="branded"
                              size={1}
                              className="text-grey-400"
                            />
                            <div className="flex w-full flex-col">
                              <p>{cc.nickname}</p>
                              <p>
                                {cc.type?.replace('International', "Int'l")}{' '}
                                {cc.cardNumber}
                              </p>
                              <p className="text-grey-600 italic">
                                {cc.isDefault && '(Default)'}
                              </p>
                            </div>
                          </div>
                        ),
                      }
                    })}
                    selectedOption={
                      selectedCard && selectedCardDetail
                        ? ({
                            value: selectedCard,
                            label: (
                              <div className="flex items-center gap-1">
                                <Fa
                                  icon={icon(selectedCardDetail)}
                                  type="branded"
                                  size={2}
                                  className="text-grey-400"
                                />
                                {selectedCardDetail.nickname}
                              </div>
                            ),
                          } as SelectOptionType)
                        : undefined
                    }
                    onChange={(selected) => setSelectedCard(selected?.value)}
                    maxHeight={300}
                    className="w-full"
                    selectClassName="min-h-[2.5rem]"
                    clearButton={false}
                  />
                  {cards.length < 10 && (
                    <LinkButton
                      text="Add a card"
                      onClick={() => setShowCCModal(true)}
                      disabled={disabled}
                    />
                  )}
                </div>
              </>
            )

          return (
            <Button
              iconLeft={<Fa icon="add" size={1} />}
              onClick={() => setShowCCModal(true)}
              disabled={disabled}
            >
              Add card
            </Button>
          )
        })()}
      </div>
      {showCCModal && (
        <CCModal
          handleClose={() => setShowCCModal(false)}
          setBillingRefresh={setRefreshCards}
          otherOrgCreditCards={cards}
        />
      )}
    </>
  )
}
