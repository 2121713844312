import React, { useState } from 'react'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import {
  AutoScroller,
  CollapsibleContainer,
  Fa,
  Text,
} from '@eltoro-ui/components'
import { AdSetPreview, BannerChecklist } from 'Components'
import { AdSetFormInputs } from '../AdSetFormInputs'
import './AdSetDisplay.scss'

export const AdSetDisplay = ({
  shouldOpen,
  selectedCreatives,
  setSelectedCreatives,
  showBannerChecklist,
  type,
}: {
  shouldOpen: boolean
  selectedCreatives: Creativeservicev1Creative[]
  setSelectedCreatives: React.Dispatch<
    React.SetStateAction<Creativeservicev1Creative[]>
  >
  showBannerChecklist: boolean
  type: 'banner' | 'video' | 'OTT' | 'native'
}) => {
  const [showBrandPerCreactive, setShowBrandPerCreative] = useState(false)
  const handleRemove = (creativeId: string) => {
    setSelectedCreatives((prev) => prev.filter((f) => f.id !== creativeId))
  }
  const uploadedSizes = selectedCreatives.map((creative) => {
    return creative.files &&
      creative.files[0] &&
      creative.files[0].width &&
      creative.files[0].height
      ? `${creative.files[0].width}x${creative.files[0].height}`
      : ''
  })
  return (
    <CollapsibleContainer
      isOpen={shouldOpen}
      classNameWrap="AdSetDisplay__collapsible flex flex-col gap-4"
    >
      <div className="AdSetDisplay__preview-container laptopsmall:flex-col">
        <div className="AdSetDisplay__BannerPreview flex flex-1 flex-col overflow-hidden">
          <div className="AdSetDisplay__BannerPreview__Header">
            <Text className="text-l flex items-center" tag="h4">
              {type[0].toUpperCase()}
              {type.substring(1).toLowerCase()} set configuration
            </Text>
            <div className="AdSetDisplay__BannerPreview__Info text-s flex items-center gap-1 pr-4 leading-none ">
              <Fa
                className="flex items-center justify-center font-light"
                icon="info-circle"
                size="lg"
              />
              All political or advocacy creatives must have a “Paid for By”
              statement or they will not pass creative audit
            </div>
          </div>
          <div
            className={`AdSetDisplay__BannerPreview__${
              selectedCreatives.length ? 'Body' : 'Empty'
            } bg-grey-50 border-thin border-tint-gray-300 items-center justify-start gap-3 p-3`}
          >
            {selectedCreatives.length ? (
              selectedCreatives.map((creative, i) => (
                <AdSetPreview
                  key={`${creative.id}_${i}`}
                  creativeItem={creative}
                  onRemove={handleRemove}
                  showBrand={showBrandPerCreactive}
                />
              ))
            ) : (
              <span className="text-ml">
                All added creatives will appear here for preview
              </span>
            )}
          </div>
        </div>
        {showBannerChecklist && (
          <BannerChecklist uploadedSizes={uploadedSizes} />
        )}
      </div>
      <AutoScroller
        className="AdSetFormInputs__autoscroller"
        shouldScroll={selectedCreatives.length < 1}
        blockPosition="end"
        scrollToTop={selectedCreatives.length < 1}
      >
        <AdSetFormInputs
          selectedCreatives={selectedCreatives}
          resetSelectedCreatives={() => setSelectedCreatives([])}
          type={type}
          showBrandPerCreative={showBrandPerCreactive}
          setShowBrandPerCreative={setShowBrandPerCreative}
        />
      </AutoScroller>
    </CollapsibleContainer>
  )
}
