import React from 'react'
import { Button, Fa, TextHeader } from '@eltoro-ui/components'
import { PreviewFileWithId } from 'Pages'
import { UploadStatusBar } from 'Components'
import { CreativeUploaderStatusPropsType } from 'types'

export const CreativeUploaderStatus: React.FC<CreativeUploaderStatusPropsType> = ({
  uploadFiles,
  setFiles,
  completed,
  progress,
  errored,
}) => {
  const removeCompleted = (fileId: string) => {
    setFiles((prev) => prev.filter((f) => f.id !== fileId))
  }
  const clearUploadedFiles = () => {
    setFiles([])
  }
  return (
    <div
      className="CreativeUploaderStatus py-4"
      style={{ display: uploadFiles.length > 0 ? '' : 'none' }}
    >
      {uploadFiles.length > 0 && (
        <div className="CreativeUploaderStatus__Files flex flex-col gap-2">
          <div className="CreativeUploaderStatus__header flex items-center justify-between pb-1">
            <TextHeader
              type={6}
              className="CreativeUploaderStatus__Title text-grey-600"
            >
              Current Uploads
            </TextHeader>
            <div className="CreativeUploaderStatus__Clear-button">
              <Button
                iconRight={<Fa icon="times" size={1} />}
                onClick={() => clearUploadedFiles()}
              >
                Clear All
              </Button>
            </div>
          </div>

          {uploadFiles.map((file: PreviewFileWithId) => {
            const { id } = file
            return (
              <UploadStatusBar
                key={id}
                file={file.fileData}
                id={id}
                complete={!!completed.find((f) => f.id === id)}
                errored={!!errored.find((f) => f.file.id === id)}
                currentStep={
                  progress?.find((f) => f.id && f.id === id)?.currentStep
                }
                uploadPercentage={
                  progress?.find((f) => f.id && f.id === id)?.uploadProgress ||
                  0
                }
                onRemove={removeCompleted}
                reason={errored.find((f) => f.file.id === id)?.reason?.message}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
