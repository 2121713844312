import React from 'react'
import { Rect } from 'react-konva'
import { useRecoilValue } from 'recoil'
import { addressSizeSelector } from 'State'

export const DirectMailAddress = () => {
  const { width, height, x, y } = useRecoilValue(addressSizeSelector)
  return (
    <Rect
      width={width}
      height={height}
      x={x}
      y={y}
      fill="white"
      stroke="black"
    />
  )
}
