import { useEffect, useMemo, useState } from 'react'
import { Fa, TableHeader } from '@eltoro-ui/components'
import { TokenPaginationType } from '@eltoro-ui/hooks'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { TablePagination } from 'Components'
import { OrderLineTableRow } from 'types'
import { OrderlineTableColumns } from './OrderlineTableColumns'
import { OrderlineTableRow } from './OrderlineTableRow'
import classNames from 'classnames'
import './OrderlineTable.scss'

export type OLTableUpdateRow = (
  change: Partial<Campaignservicev1OrderLine>,
) => void

export const OrderlineTable = ({
  pagination,
  childOrgsOnly,
  onOpenOrderLine,
  closePreview,
  setSort,
  setFilter,
  setChildOrgsFilter,
}: {
  pagination: TokenPaginationType<OrderLineTableRow>
  childOrgsOnly?: boolean
  onOpenOrderLine?: (orderLine: Campaignservicev1OrderLine) => void
  closePreview?: boolean
  setSort: React.Dispatch<React.SetStateAction<string | undefined>>
  setFilter: React.Dispatch<
    React.SetStateAction<
      | {
          [field: string]: (string | number)[]
        }
      | undefined
    >
  >
  setChildOrgsFilter?: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  // const [selectingReports, setSelectingReports] = useState(false)
  // const [selectedOrderlines, setSelectedOrderlines] = useState<
  //   Campaignservicev1OrderLine[]
  // >([])
  const [opened, setOpened] = useState<{
    orderline: Campaignservicev1OrderLine
    type: 'campaign' | 'ol'
  }>()

  const { isAdmin, currentOrg } = useAppContext()

  // const handleSelectOrderline = (row: Campaignservicev1OrderLine, checked: boolean) => {
  //   if (!checked) {
  //     setSelectedOrderlines((previous) =>
  //       previous.filter((ol) => ol.id !== row.id),
  //     )
  //   } else setSelectedOrderlines((previous) => [...previous, row])
  // }
  const { currentPageData: orderlines, updateRow, findCachedRow } = pagination

  const hasOrderLines = orderlines.length > 0

  useEffect(() => {
    if (closePreview) setOpened(undefined)
  }, [closePreview])

  const tableColumns = useMemo(() => OrderlineTableColumns(isAdmin), [isAdmin])
  const childOrgsForFilter = currentOrg?.childOrgs
    ?.map((co) => {
      return { label: co.name || '', value: co.id || '' }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <div className="OrderlineTable">
      {/* bulk report dowloading would be too heavy of a performance load for client-side generation; reports would need to be generated sever-side for this to make sense 
            {hasOrderLines && (
            <LinkButton
            text="Download Performance Reports"
            size="m"
            onClick={() => setSelectingReports(true)}
            />
            )} */}
      {/* {selectingReports && (
        <div className="OrderlineTable__report-selection-menu">
          <span className="OrderlineTable__ol-selected-text">
            <span className="font-bold">{selectedOrderlines.length}</span> Order
            Line
            {`${selectedOrderlines.length === 1 ? '' : 's'}`}
            &nbsp; selected
            {selectedOrderlines.length === 0 && '. Select one'}
          </span>
          <OrderLineIconLabel>
            <Fa className="text-secondary-500" icon="file-pdf" size="xs" />
            <LinkButton
              text="Download PDF"
              size="m"
              onClick={() => console.log('download PDF')}
            />
          </OrderLineIconLabel>
          <OrderLineIconLabel>
            <Fa className="text-secondary-500" icon="file-csv" size="xs" />
            <Spacer width="0.25rem" />
            <LinkButton
              text="Download CSV"
              size="m"
              onClick={() => console.log('download CSV')}
            />
          </OrderLineIconLabel>
          <OrderLineIconLabel className="Button Button--primary pr-2 ">
            <Fa icon="times" size="xs" />
            <LinkButton
              className="text-tint-light"
              text="Cancel"
              size="s"
              onClick={() => {
                setSelectingReports(false)
                setSelectedOrderlines([])
              }}
            />
          </OrderLineIconLabel>
        </div>
      )} */}
      <div
        className={classNames('OrderlineTable__table-wrapper', {
          'pointer-events-none opacity-50': pagination.loading,
        })}
      >
        <table
          className={classNames('Table OrderLineTable', {
            'mb-0': !hasOrderLines,
          })}
        >
          <TableHeader
            columns={[
              ...tableColumns,
              {
                path: '',
                label: 'Campaign',
                width: '12rem',
                removeSort: true,
                removeFilter: true,
              },
              ...(childOrgsOnly
                ? [
                    {
                      path: 'child_orgs',
                      label: 'Child Org',
                      filterOn: childOrgsForFilter || [],
                      removeSort: true,
                    },
                  ]
                : []),
              {
                path: '',
                label: '',
                removeFilter: true,
                removeSort: true,
              }, // expand button column
            ]}
            onSort={(_, sort, path) => {
              if (!sort || !path) {
                setSort(undefined)
                return
              }
              setSort(`${path}${sort === 'desc' ? ' desc' : ''}`)
            }}
            onFilter={(_, path, filterOn) => {
              let additions: (string | number)[] = []
              if (path === 'child_orgs' && setChildOrgsFilter) {
                setChildOrgsFilter(filterOn as string[])
                return
              }
              if (!isAdmin && path === 'status') {
                additions = [
                  // non-admin user searches for review started, add deployment_cancelled and errored
                  ...((filterOn as number[]).includes(20) ? [55, 120] : []),
                  // non-admin user searches for serving, add errored_and_serving
                  ...((filterOn as number[]).includes(70) ? [110] : []),
                  // non-admin user searches for deployed, add errored_and_deployed
                  ...((filterOn as number[]).includes(60) ? [130] : []),
                ]
              }
              const key = typeof path === 'object' ? path.join('.') : path
              setFilter((prev) => {
                return { ...prev, [key]: [...filterOn, ...additions] }
              })
            }}
            // handleSelectAll={
            //   selectingReports
            //     ? () => {
            //         if (selectedOrderlines.length === orderlines.length) {
            //           setSelectedOrderlines([])
            //         } else {
            //           setSelectedOrderlines(orderlines)
            //         }
            //       }
            //     : undefined
            // }
            // selectedAll={orderlines.length === selectedOrderlines.length}
          />
          <tbody className="Table__body">
            {orderlines.map((row, index) => {
              return (
                <OrderlineTableRow
                  key={`${row.orderLine.id}_${index}`}
                  row={row}
                  // selectingReports={selectingReports}
                  // selectedOrderlines={selectedOrderlines}
                  // handleSelectOrderline={handleSelectOrderline}
                  onOpen={(openedItem) => {
                    setOpened(openedItem)
                    if (
                      openedItem.type === 'campaign' &&
                      openedItem.orderline.campaign?.id
                    )
                      window.history.replaceState(
                        '',
                        '',
                        `/campaigns/dashboard/${
                          openedItem.orderline.campaign.id
                        }${
                          openedItem.orderline.orgId
                            ? `?org_id=${openedItem.orderline.orgId}`
                            : ''
                        }`,
                      )
                    if (onOpenOrderLine && openedItem.type === 'ol')
                      onOpenOrderLine(openedItem.orderline)
                  }}
                  onClose={() => {
                    setOpened(undefined)
                    const query = new URLSearchParams(window.location.search)
                    window.history.replaceState(
                      '',
                      '',
                      `/campaigns/dashboard?${query}`,
                    )
                  }}
                  opened={opened}
                  childOrgsOnly={childOrgsOnly}
                  columns={tableColumns}
                  handleUpdateRow={(
                    change: Partial<Campaignservicev1OrderLine>,
                  ) => {
                    const existingOrderLine = findCachedRow(
                      (ol) => ol?.orderLine?.id === row.orderLine.id,
                    )
                    if (existingOrderLine) {
                      updateRow(
                        (ol) => ol?.orderLine?.id === row.orderLine.id,
                        { orderLine: { ...row.orderLine, ...change } },
                      )
                    }
                  }}
                />
              )
            })}
          </tbody>
        </table>
        {!hasOrderLines && (
          <div className="OrderlineTable__empty-state m-auto flex h-full flex-col items-center py-6">
            <Fa
              icon="magnifying-glass"
              size={5}
              type="duotone"
              className="text-primary-200"
            />
            <p className="text-grey-600">Found no results.</p>
          </div>
        )}
        {pagination.totalItems > 10 && (
          <div className="TablePagination__container mb-4 flex justify-center">
            <TablePagination pagination={pagination} />
          </div>
        )}
      </div>
    </div>
  )
}
