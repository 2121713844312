import { ReactNode } from 'react'

export const Header = ({
  children,
  className = '',
  id = '',
}: {
  className?: string
  children: ReactNode
  id?: string
}) => {
  return (
    <div
      id={id}
      className={`${className} Header flex bg-highlight relative text-primary z-10 
        `}
    >
      {children}
    </div>
  )
}
