import axios from 'axios'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { getApiUrl } from 'Helpers'
import { CPMObjectType } from 'types'

// This is used in lieu of the SDK because of issues with snake case vs camelCase
// properties in the CPM map. For more info, see Slack:
// https://eltoroteam.slack.com/archives/G01CSAF0RJQ/p1700169954119849
// https://eltoroteam.slack.com/archives/G01CSAF0RJQ/p1700177617009289
export const createCPM = async (newCpm: CPMObjectType, orgId: string) => {
  const url = `${getApiUrl()}/v1`
  const token = localStorage.getItem('eltoro_token')
  const config = { Authorization: `Bearer ${token}` }

  const createdCpm = await axios.post<Orgmanagerv1Org>(
    `${url}/orgs/${orgId}/cpms`,
    {
      product_name: newCpm.product_name,
      units: newCpm.units,
      nanos: newCpm.nanos,
      currency_code: newCpm.currency_code,
    },
    {
      headers: config,
    },
  )

  return createdCpm.data
}
