import { useRef, useEffect, ReactNode, RefObject, forwardRef } from 'react'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'

export type ExportRendererPropsType = {
  children: ReactNode
  fileName: string
  height: number
  width: number
  shouldDownload: boolean
  setShouldDownload: (arg0: boolean) => void
  setLoading: (arg0: boolean) => void
  backgroundColor?: string
  exportAsPDF?: boolean
  exportAsJPEG?: boolean
  exportAsPNG?: boolean
  fileType?: string
  headerContent?: ReactNode
  info?: { [key: string]: string }
  loading?: boolean
}
export type ExportToPrintPropsType = {
  headerContent: ReactNode
  children: ReactNode
  className?: string
  showAsFile: boolean
}
const fileTypes = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
}

const saveAs = (uri: string, filename: string) => {
  const link = document.createElement('a')
  if (typeof link.download === 'string') {
    link.href = uri
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    window.open(uri)
  }
}

const exportComponent = async (
  node: RefObject<HTMLDivElement>,
  fileName: string,
  bgColor: string,
  customHeight: number = 1320,
  customWidth: number = 1020,
  fileType: string = fileTypes.PDF,
) => {
  const element = node.current
  if (!element) return

  const canvasOptions = {
    allowTaint: true,
    backgroundColor: bgColor,
    height: customHeight,
    useCORS: true,
    imageTimeout: 0,
    // removeContainer: false,
    scale: 2,
    // scrollY: -window.scrollY,
    width: customWidth,
  }
  const canvas = await html2canvas(element, canvasOptions)

  const height = customHeight
  const width = customWidth

  if (fileType === fileTypes.PDF) {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({
      orientation: height > width ? 'p' : 'l',
      unit: 'pt',
      format: [612, 792],
      // hotfixes: ['px_scaling'],
    })

    // get canvas width/height to set pdf's width/height
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = pdf.internal.pageSize.getHeight()

    const cData = canvas.toDataURL(fileTypes.JPEG, 0.7)

    const imgHeight = (canvas.height * pdfWidth) / canvas.width
    let yPos = 0

    while (imgHeight >= yPos) {
      // the addImage plugin type provided by the jsPDF dependency doesn't match the implementation

      pdf.addImage(cData, 0, yPos, pdfWidth, pdfHeight, fileName, 'FAST')
      yPos += imgHeight
      if (imgHeight > pdfHeight) {
        pdf.addPage()
        pdf.addImage(cData, 0, yPos, pdfWidth, pdfHeight, fileName, 'FAST')
      }
    }
    pdf.save(fileName, { returnPromise: true })
  }
  if (fileType !== fileTypes.PDF) {
    saveAs(canvas.toDataURL(fileType, 1.0), fileName)
  }
}

const exportComponentAsPDF = async (
  node: RefObject<HTMLDivElement>,
  fileName: string,
  backgroundColor: string = '#ffffff',
  height: number,
  width: number,
  fileType: string = fileTypes.PDF,
  setLoading: (value: React.SetStateAction<boolean>) => void,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    exportComponent(node, fileName, backgroundColor, height, width, fileType)
      .then(() => {
        resolve() // Resolve the promise when exportComponent completed
      })
      .catch((error) => {
        reject(error) // Reject the promise if there's an error during exportComponent
      })
      .finally(() => {
        setLoading(false) // Ensure loading state is reset
      })
  })
}

const exportComponentAsPNG = async (
  node: RefObject<HTMLDivElement>,
  fileName = 'component.png',
  backgroundColor = 'none',
  height: number,
  width: number,
  fileType = fileTypes.PNG,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    exportComponent(node, fileName, backgroundColor, height, width, fileType)
      .then(() => {
        resolve() // Resolve the promise when exportComponent completes successfully
      })
      .catch((error) => {
        reject(error) // Reject the promise if there's an error during exportComponent
      })
  })
}
const exportComponentAsJPEG = async (
  node: RefObject<HTMLDivElement>,
  fileName = 'component.jpeg',
  backgroundColor = 'none',
  height: number,
  width: number,
  fileType = fileTypes.JPEG,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    exportComponent(node, fileName, backgroundColor, height, width, fileType)
      .then(() => {
        resolve() // Resolve the promise when exportComponent completes successfully
      })
      .catch((error) => {
        reject(error) // Reject the promise if there's an error during exportComponent
      })
  })
}

export { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG }

const ExportToPrint = forwardRef<HTMLDivElement, ExportToPrintPropsType>(
  ({ headerContent, showAsFile, children, className }, ref) => {
    // console.log('showAsFile', showAsFile)
    // this is container for pdf
    return (
      <div
        className={`ExportToPrint flex flex-col text-${className} fixed`}
        ref={ref}
      >
        {showAsFile && (
          <div
            className={`Export2Print ExportToPrint__header ${className}-header flex justify-between gap-2`}
          >
            <div className="ExportToPrint__header-logo">{headerContent}</div>
            <div className="ExportToPrint__header-date flex gap-1 text-right">
              <span className="ExportToPrint__header-label">
                Today&apos;s date:
              </span>
              <strong className="ExportToPrint__header-date--today">
                {dayjs().format('MM-DD-YYYY')}
              </strong>
            </div>
          </div>
        )}
        <div
          className={`Export2Print ExportToPrint__content ${className}-content relative flex justify-center items-center w-full h-full`}
        >
          {children}
        </div>
      </div>
    )
  },
)

export const ExportRenderer = ({
  shouldDownload,
  setShouldDownload,
  setLoading,
  exportAsJPEG,
  exportAsPDF,
  exportAsPNG,
  height,
  width,
  loading,
  backgroundColor,
  fileType,
  fileName = 'ExportedToPrint',
  headerContent,
  children,
}: ExportRendererPropsType) => {
  const cleanedFileName = fileName.replace(/[^a-zA-Z0-9]/g, '_')
  const componentRef = useRef<HTMLDivElement>(null)

  const exportPDF = () => {
    exportComponentAsPDF(
      componentRef,
      cleanedFileName,
      backgroundColor,
      height,
      width,
      fileType,
      (loading) => setLoading,
    ).then(() => {
      setLoading(false)
    })
    setShouldDownload(false)
  }

  useEffect(() => {
    if (shouldDownload) {
      if (exportAsPDF) {
        if (!loading) setLoading(true)
        setTimeout(() => {
          exportPDF()
        }, 3000)
      }

      if (exportAsJPEG) {
        exportComponentAsJPEG(
          componentRef,
          cleanedFileName,
          backgroundColor,
          height,
          width,
          fileType,
        ).then(() => {
          setLoading(false)
        })
        setShouldDownload(false)
      }

      if (exportAsPNG) {
        exportComponentAsPNG(
          componentRef,
          cleanedFileName,
          backgroundColor,
          height,
          width,
          fileType,
        ).then(() => {
          setLoading(false)
        })
        setShouldDownload(false)
      }
    }
  }, [shouldDownload, componentRef])
  return (
    <>
      <ExportToPrint
        className={
          // eslint-disable-next-line no-nested-ternary
          exportAsPDF ? 'pdf' : exportAsPNG ? 'png' : 'jpeg'
        }
        ref={componentRef}
        headerContent={headerContent}
        showAsFile={exportAsJPEG || exportAsPDF || exportAsPNG || false}
      >
        {children}
      </ExportToPrint>
    </>
  )
}
