/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Hagridv1User } from '../models/Hagridv1User';
import { HttpFile } from '../http/http';

/**
* Values needed for getting a list of Users
*/
export class V1ListUsersResponse {
    /**
    * Array of Users
    */
    'users'?: Array<Hagridv1User>;
    /**
    * A token, which can be sent as `page_token` to retrieve the next page. If this field is omitted, there are no subsequent pages.
    */
    'nextPageToken'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "users",
            "baseName": "users",
            "type": "Array<Hagridv1User>",
            "format": ""
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1ListUsersResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

