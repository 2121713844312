import { useState } from 'react'
import { Button, TextHeader } from '@eltoro-ui/components'
import { V1Notification } from 'next-gen-sdk'
import { PageContent } from 'Components'
import { useAppContext } from 'Contexts'
import { useNotifications } from 'Hooks'
import { AlertForm } from './AlertForm'
import { PageEnum } from 'Enums'
import { AlertsList } from './AlertsList'
import './AlertsPage.scss'

export const AlertsPage = () => {
  const [show, setShow] = useState(PageEnum.list)
  const [dataToEdit, setDataToEdit] = useState({} as V1Notification)
  const {
    alertsList,
    createNotification,
    deleteNotification,
    updateNotification,
    loading,
    handleInputChange,
  } = useNotifications()
  const { roles } = useAppContext()
  const isAdmin = (roles && roles.includes('nextgen_admin')) || false

  const onAddAlert = () => {
    setShow(PageEnum.add)
  }

  const showList = () => {
    setShow(PageEnum.list)
  }
  const editAlertData = (data: V1Notification) => {
    setShow(PageEnum.edit)
    setDataToEdit(data)
    if (data.message) handleInputChange(data.message)
  }

  if (!isAdmin) return null

  return (
    <PageContent className="AlertsPage pt-5">
      <TextHeader type={1}>
        {show === PageEnum.list
          ? 'Alert list'
          : show === PageEnum.add
          ? 'Add a new alert'
          : 'Edit alert'}
      </TextHeader>

      <section className="AlertsPage-content">
        {show === PageEnum.list && (
          <div className="flex flex-col gap-4">
            <Button
              type="button"
              kind="primary"
              onClick={onAddAlert}
              className="AlertsPage__add-alert-btn relative z-20 -mb-10 px-8"
              size="l"
            >
              Add new
            </Button>
            {!loading && alertsList && (
              <AlertsList
                list={alertsList}
                onDelete={deleteNotification}
                onEdit={editAlertData}
                isAdmin={isAdmin}
              />
            )}
          </div>
        )}

        {show === PageEnum.add && (
          <AlertForm onCancel={showList} onCreate={createNotification} />
        )}

        {show === PageEnum.edit && (
          <AlertForm
            data={dataToEdit}
            onCancel={showList}
            onUpdate={updateNotification}
          />
        )}
      </section>
    </PageContent>
  )
}
