import { FC } from 'react'
import { ColorTokensWithLighterAndDarkerType, Text } from '../Text'
import { getColors } from '../../helpers'

export const SideMenuHeader: FC<{
  children?: JSX.Element
  className?: string
  title: string
  on?: ColorTokensWithLighterAndDarkerType
  kind?: 'primary' | 'secondary' | 'tertiary'
}> = ({ className = '', children, title, on, kind }) => {
  const style = () => {
    if (!on) return {}
    const { colors } = getColors(on, kind)
    return colors
  }
  return (
    <div
      className={`SideMenuHeader flex items-center ${className}`}
      style={style()}
    >
      <Text className="SideMenuHeader__title" tag="h6">
        {title}
      </Text>
      {children ? (
        <span className="SideMenuHeader__icon IconOnCollapsed">{children}</span>
      ) : null}
    </div>
  )
}
