import Konva from 'konva'
import { Stage, Layer, Group, Rect } from 'react-konva'
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'
import { handleCoverDrag } from 'Helpers'
import { Artboard } from 'Components/Canvas'
import React, { RefObject, useRef } from 'react'
import {
  artboardGroupDimensionsSelector,
  isDraggingLayerAtom,
  selectedArtboardsSelector,
} from 'State'
import { DimensionsType, PositionType } from 'Typings'
import { KonvaEventObject } from 'konva/types/Node'

// contains all konva related components
export const CreativeStage: React.FC<{
  stageRef: RefObject<Konva.Stage>
  isEditing: boolean
  onSelectBlock: (show: boolean) => void
  addToRefs: (el: Konva.Group) => void
  stageDims: DimensionsType
  onZoom: (e: KonvaEventObject<WheelEvent>) => void
  groupLocation: PositionType
  onGroupDragEnd: (newLocation: PositionType) => void
}> = ({
  stageRef,
  isEditing,
  onSelectBlock,
  addToRefs,
  stageDims,
  onZoom,
  groupLocation,
  onGroupDragEnd,
}) => {
  // RecoilBridge is needed to reach into konva's context supplied by Stage
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const mainGroupRef = useRef<Konva.Group>(null)
  const artboards = useRecoilValue(selectedArtboardsSelector)
  const artboardGroupDimensions = useRecoilValue(
    artboardGroupDimensionsSelector,
  )
  const setIsDraggingLayer = useSetRecoilState(isDraggingLayerAtom)

  const artboardGroupLocation = {
    x: stageDims.width - artboardGroupDimensions.width / 2,
    y: stageDims.height - artboardGroupDimensions.height / 2,
    width: artboardGroupDimensions.width,
    height: artboardGroupDimensions.height,
  }

  return (
    <Stage
      ref={stageRef}
      width={stageDims.width}
      height={stageDims.height}
      onWheel={onZoom}
    >
      <RecoilBridge>
        <Layer>
          <Group
            name="artboardGroupContainer"
            ref={mainGroupRef}
            x={groupLocation.x}
            y={groupLocation.y}
            width={stageDims.width * 2}
            height={stageDims.height * 2}
            draggable
            onDragStart={() => setIsDraggingLayer(true)}
            onDragEnd={(e: any) => {
              setIsDraggingLayer(false)
              if (e.target.name() === 'artboardGroupContainer') {
                onGroupDragEnd(e.target.position())
              }
            }}
            dragBoundFunc={(pos: any) =>
              handleCoverDrag(pos, mainGroupRef.current)
            }
          >
            {/* this rect acts as a draggable handle for the group above */}
            <Rect width={stageDims.width * 2} height={stageDims.height * 2} />
            {/* group below surrounds artboards so they move as a group when user drags */}
            <Group x={artboardGroupLocation.x} y={artboardGroupLocation.y}>
              {artboards.map((artboard) => (
                <Artboard
                  key={artboard.id}
                  adRef={addToRefs}
                  artboard={artboard}
                  isEditing={isEditing}
                  onSelectBlock={onSelectBlock}
                />
              ))}
            </Group>
          </Group>
        </Layer>
      </RecoilBridge>
    </Stage>
  )
}
