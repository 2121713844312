import { styleTailwind } from 'Helpers'

const CodeBlock = styleTailwind(
  'code',
  'CodeBlock block my-2 Code text-m flex flex-col whitespace-pre',
)

const CodeColHeadRow = styleTailwind(
  'span',
  'CodeColHeadRow -my-1 bg-grey-50 group-hover:bg-success-50 flex',
)
const CodeColHeadCell = styleTailwind(
  'span',
  'CodeColHeadCell -my-1 pt-1 font-bold transition-all group-hover:bg-success-50 pb-3 px-1 border-grey-200 border-thin bg-grey-50 font-bold flex group-hover:border-tint-gray-600',
)
const CodeColHeadRow2 = styleTailwind(
  'span',
  'CodeColHeadRow2 -my-1 bg-grey-50 transition-all group-hover:bg-success-50 group-disabled:bg-grey-50 pt-1 font-bold pb-3 px-1 border-grey-200 border-thin bg-grey-50 font-bold flex',
)

const CodeCell = styleTailwind(
  'span',
  'CodeCell -mt-1 py-2 px-1 transition-all text-left bg-base group-hover:bg-primary-50 border-grey-200 group-hover:border-tint-gray-600 border-thin',
)

const CodeSpaceCol = styleTailwind('span', 'CodeSpaceCol flex')

export const AddressListExample = () => (
  <CodeBlock>
    <CodeColHeadRow>
      <CodeColHeadCell className="flex-1">Address 1</CodeColHeadCell>
      <CodeColHeadCell className="border-grey-200 border-l-thin w-14 pr-2">
        zip
      </CodeColHeadCell>
    </CodeColHeadRow>
    <CodeSpaceCol>
      <CodeCell className="flex-1">1299 Brookside Dr.</CodeCell>
      <CodeCell className="w-14">40202</CodeCell>
    </CodeSpaceCol>
    <CodeSpaceCol>
      <CodeCell className="flex-1">19 Jamestown Place</CodeCell>
      <CodeCell className="w-14">02621</CodeCell>
    </CodeSpaceCol>
    <span className="block flex-1 py-2">Leading '0' in ZIP code is needed</span>
  </CodeBlock>
)
export const IPListExample = () => (
  <CodeBlock>
    <CodeColHeadRow2>IP</CodeColHeadRow2>
    <CodeCell>161.0.100.133</CodeCell>
    <CodeCell>69.73.247.142</CodeCell>
    <CodeCell>181.41.5.26</CodeCell>
  </CodeBlock>
)

export const ZIPListExample = () => (
  <CodeBlock>
    <CodeColHeadRow2>ZIP</CodeColHeadRow2>
    <CodeCell>40210</CodeCell>
    <CodeCell>02869</CodeCell>
    <CodeCell>55125</CodeCell>
  </CodeBlock>
)

export const MaidExample = () => (
  <CodeBlock>
    <CodeColHeadRow2>MAID</CodeColHeadRow2>
    <CodeCell>918F1D4F-D195-4A8B-AF47-44683FE11DB9</CodeCell>
    <CodeCell>01a80a81-e35f-4e3a-8590-d39c6def9fa7</CodeCell>
    <CodeCell>036e2b3a-2b3a-dda4-0829-a18e605ae716</CodeCell>
  </CodeBlock>
)
