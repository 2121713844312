/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1MoverMatchCount {
    /**
    * The type of mover for which the count is related.
    */
    'moverType'?: string;
    /**
    * The number of matched households found in the requested state or zipcodes.
    */
    'count'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "moverType",
            "baseName": "mover_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "count",
            "baseName": "count",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1MoverMatchCount.attributeTypeMap;
    }

    public constructor() {
    }
}

