/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Payment made by a credit card
*/
export class V1Payment {
    /**
    * ID of the Payment
    */
    'id'?: string;
    /**
    * Time the Payment was created at
    */
    'createTime'?: Date;
    /**
    * Time the Payment was updated at
    */
    'updateTime'?: Date;
    /**
    * Time the Payment was deleted at
    */
    'deleteTime'?: Date;
    /**
    * The Org that made the payment
    */
    'orgId'?: string;
    /**
    * The amount that was payed
    */
    'amount'?: number;
    /**
    * The ID of the credit card that made the payment
    */
    'creditCardId'?: string;
    /**
    * The ID of the user that made the payment
    */
    'userId'?: string;
    /**
    * The description for what the payment was for
    */
    'description'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "amount",
            "baseName": "amount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "creditCardId",
            "baseName": "credit_card_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Payment.attributeTypeMap;
    }

    public constructor() {
    }
}

