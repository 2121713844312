import { FlexBox, Spacer, Text, Fa } from '@eltoro-ui/components'
import React from 'react'
import './HorizontalLabel.scss'

export const HorizontalLabel: React.FC<{
  label: string
  hasError?: boolean
  children?: React.ReactNode
}> = ({ label, hasError = false, children }) => {
  return (
    <div className="HorizontalLabel">
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox>
          <Text on="white" weight="bold" kind="subdued">
            {label}
          </Text>
          <Spacer width="0.5rem" />
          {hasError && (
            <Fa
              className="HorizontalLabel__icon"
              icon="exclamation-triangle"
              size={1}
            />
          )}
        </FlexBox>
        {children && <>{children}</>}
      </FlexBox>
    </div>
  )
}
