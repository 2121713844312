// startPosition: Where you want the status to start from (split on '_')
// enumText: Any status or type enum from sunflower
// capitalize: Will capitalize all final words
// Example 1: 'CREATIVE_FILE_TYPE_UNKNOWN' -> simplifyEnumText(3, status) -> 'Unknown'
// Example 2: 'CREATIVE_STATUS_UPLOADING' -> simplifyEnumText(2, status) -> 'Uploading'
// Example 3; 'CREATIVE_TYPE_NATIVE_BANNER -> simplifyEnumText(2, type, true) -> 'Native Banner'
export const simplifyEnum = (
  startPosition: number,
  enumText: string,
  capitalize?: boolean,
) => {
  const split = enumText.toString().split('_')
  const text = split.slice(startPosition).join(' ')
  if (
    [
      'HTML',
      'JS',
      'CSS',
      'PDF',
      'HTML5',
      'OTT',
      'IP',
      'B2B',
      'B2C',
      'DC',
      'ZIP',
      'WKB',
      'VR',
    ].includes(text)
  ) {
    return text
  }
  if (capitalize) {
    const capped = split
      .slice(startPosition)
      .map((word) => `${word[0]}${word.substring(1).toLowerCase()}`)
    return capped.join(' ')
  }
  return `${text[0]}${text.substring(1).toLowerCase()}`
}

// Takes a value of and gets the matching enum key
// Example: getEnumKey(audienceStatusEnum, 37) => AUDIENCE_TYPE_VR
export const getEnumKey = (
  enumObject: { [key: string]: number | string },
  value: string | number,
) =>
  Object.keys(enumObject).find(
    (key) => enumObject[key].toString() === value.toString(),
  )
