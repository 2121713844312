import axios from 'axios'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Audience,
  V2Target,
} from 'next-gen-sdk'
import { checkIfCreative, getApiUrl } from 'Helpers'

export type DownloadFileAudienceType = {
  target: V2Target
  audience: Targetjobservicev1Audience
}

const rootUrl = `${getApiUrl()}/v1`

export const downloadFile = async (
  item: Creativeservicev1Creative | DownloadFileAudienceType,
  isSource?: boolean,
  dataReturnOnly?: boolean, // to only return the response data, and skip triggering a file download
  isSelected?: boolean, // only adops leadership
) => {
  const triggerDownload = (fileName: string, fileUrl: string) => {
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(fileUrl)
  }
  if (checkIfCreative(item)) {
    const firstFile = item.files?.[0]
    if (firstFile) {
      const { key, bucket } = firstFile
      const url = `https://s3.amazonaws.com/${bucket}/${key}`
      triggerDownload(item?.name || '', url)
    }
  } else {
    const fileNameType = () => {
      if (isSource) return 'source'
      if (isSelected) return 'selected'
      return 'append'
    }
    // need to also put 'selected'
    const fileName = `${fileNameType()}_${(
      item.audience.name ||
      item.audience.id ||
      ''
    ).slice(0, 240)}${
      item.audience.name && /.+(\.csv)$/.test(item.audience.name) ? '' : '.csv'
    }` // shorten file name to avoid > 255 char file names
    const audienceId = () => {
      // if this audience is VR, we should download the annotated file for the mapped homes job
      if (item.audience.type === 'AUDIENCE_TYPE_VR') {
        return item.audience.audiences?.find(
          (j) => j.subType === 'AUDIENCE_SUB_TYPE_HOMES',
        )?.id
      }
      return item.audience.id
    }
    if (!audienceId()) return
    const downloadURL = () => {
      const audienceFileType = () => {
        if (isSelected) return 'AUDIENCE_FILE_TYPE_SELECTED'
        if (
          item.audience.type === 'AUDIENCE_TYPE_DC' ||
          item.audience.type === 'AUDIENCE_TYPE_ZIP'
        )
          return 'AUDIENCE_FILE_TYPE_RESULTS'
        return 'AUDIENCE_FILE_TYPE_ANNOTATED'
      }
      if (isSource)
        return `${rootUrl}/targets:download?target_id=${item.target.id}&target_file_type=FILE_TYPE_SOURCE&org_id=${item.target.orgId}`
      return `${rootUrl}/audiences:download?audience_id=${audienceId()}&audience_file_type=${audienceFileType()}&org_id=${
        item.audience.orgId
      }`
    }

    const res = await axios({
      method: 'get',
      url: downloadURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('eltoro_token')}`,
      },
    })
    if (!dataReturnOnly) {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'text/csv' }),
      )
      triggerDownload(fileName, url)
    }
    return res.data
  }
}
