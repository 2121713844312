import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext, useCampaignContext } from 'Contexts'

import successImage from 'Assets/logo_364.webp'
import { Text } from '@eltoro-ui/components'
import { LinkButton, Notification } from 'Components'
import './SuccessPage.scss'

export const SuccessPage = () => {
  const { currentOrg } = useAppContext()
  const { campaign_name } = useCampaignContext()
  const { campaignId } = useParams<{
    campaignId: string
  }>()
  const history = useNavigate()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const pushURL = () => {
    history(`/campaigns/edit/${campaignId}/settings${query}`)
  }
  return (
    <>
      <Notification
        header="Success! Your campaign is submitted"
        image={successImage}
        confetti
      >
        <div className="LinkBox flex w-full flex-col gap-4 text-center">
          <Text className="text-center font-light" tag="p">
            Your campaign has been submitted to our Ad Operations team for
            review. You&apos;ll receive an email notification when your campaign
            has been approved and deployed or if any changes are needed.
          </Text>

          <div className="SuccessPage__links flex flex-1 items-center justify-center gap-6">
            {campaign_name && (
              <LinkButton
                className="SuccessPage__link w-auto"
                onClick={() => {
                  history(`/campaigns/dashboard/${campaignId}${query}`)
                }}
                // todo: link to campaign expanded on dashboard
                text={campaign_name}
              />
            )}

            <LinkButton
              className="SuccessPage__link text-l w-auto whitespace-nowrap"
              onClick={pushURL}
              // should link to receipt when billing is wired and if order is prepaid
              text={`Order # ${campaignId}`}
            />
          </div>
        </div>
      </Notification>
    </>
  )
}
