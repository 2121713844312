import { FC } from 'react'
import './SelectCountry.scss'

export const countryOptions = [
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'United States',
    value: 'United States of America',
  },
]

type CountrySelectType = {
  value?: string
  disabled?: boolean
  onChange: (value: string) => void
  required?: boolean
  className?: string
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
}

export const SelectCountry: FC<CountrySelectType> = ({
  value,
  disabled,
  onChange,
  required,
  className,
  onBlur,
}) => {
  return (
    <select
      required={required}
      autoComplete="address-level1"
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      data-testid="country-select"
      className={`SelectCountry text-grey-500 border-tint-gray-200 w-full border p-2 pr-2 focus:!ring ${
        className || ''
      }`}
      disabled={disabled}
      onBlur={onBlur}
    >
      {!value && (
        <option value="" disabled>
          Select Country
        </option>
      )}
      {countryOptions.map(({ value, label }) => (
        <option key={value} value={value} data-testid={`option-${value}`}>
          {label}
        </option>
      ))}
    </select>
  )
}
