import { useState, useEffect, useContext } from 'react'
import { V1StatsResponse, V1ResultMetrics } from 'next-gen-sdk'
import dayjs from 'dayjs'
import { AppContext } from 'Contexts'
import { Button, Fa, Select } from '@eltoro-ui/components'
/* import { getStats } from 'requests' */
/* import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
 * import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' */
/* import { PortalContext } from 'PortalContext' */
import './DailyBreakdown.scss'
import { DailyBreakdownType } from 'Components/Reports/ReportModal'

const DayContainer = ({
  className = '',
  day,
  item,
  week,
}: {
  className: string
  day: V1ResultMetrics
  item: string
  week: number
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { clicks, imps, end } = day
  const ctr = (Number(clicks) / Number(imps)) * 100

  const CTRGroup = () => (
    <>
      <div className="value-grouping">
        {!Number.isNaN(ctr) ? ctr.toFixed(3) : 0}%
      </div>
      <div className="label-grouping">CTR</div>
    </>
  )
  const ImpressionsGroup = ({ abbr }: { abbr?: boolean }) => (
    <>
      <div className="value-grouping">
        {imps?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </div>
      <div className="label-grouping">{abbr ? 'imp.' : 'impressions'}</div>
    </>
  )
  const ClicksGroup = () => (
    <>
      <div className="value-grouping">{clicks}</div>
      <div className="label-grouping">clicks</div>
    </>
  )

  const dayOfWeek = [
    { full: 'Sunday', abr: 'Sun' },
    { full: 'Monday', abr: 'Mon' },
    { full: 'Tuesday', abr: 'Tues' },
    { full: 'Wednesday', abr: 'Wed' },
    { full: 'Thursday', abr: 'Thurs' },
    { full: 'Friday', abr: 'Fri' },
    { full: 'Saturday', abr: 'Sat' },
  ]

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const narrow = windowWidth < 1025
  /* const style = narrow ? { width: height } : { height: height } */

  return (
    <div className={`DayContainer gap-1 DayContainer__${item} ${className}`}>
      <div className="flexContainer-DayContainer">
        <div className="container-flexContainer flexContainer-DayContainer">
          <div className="upperStats-container">
            <div className="grouping-upperStats">
              {(item.toLowerCase() === 'clicks' ||
                item.toLowerCase() === 'impressions') && <CTRGroup />}
              {item.toLowerCase() === 'ctr' && <ClicksGroup />}
            </div>
            <div className="grouping-upperStats">
              {(item.toLowerCase() === 'clicks' ||
                item.toLowerCase() === 'ctr') && <ImpressionsGroup abbr />}
              {item.toLowerCase() === 'impressions' && <ClicksGroup />}
            </div>
          </div>
          <div className="lowerStat-container">
            {item.toLowerCase() === 'impressions' && <ImpressionsGroup />}
            {item.toLowerCase() === 'ctr' && <CTRGroup />}
            {item.toLowerCase() === 'clicks' && <ClicksGroup />}
          </div>
        </div>
      </div>
      <div className="date-DayContainer">
        <div className="day-date">
          {dayOfWeek[dayjs(end).day()][narrow ? 'abr' : 'full']}
        </div>
        <div className="date-date">
          {dayjs(end).format(narrow ? 'MM-DD' : 'MM-DD-YY')}
        </div>
      </div>
    </div>
  )
}

export const DailyBreakdown = ({
  isPDF,
  orderlineStats,
  downloadWeek,
  setDownloadWeek,
  downloadItem,
  setDownloadItem,
}: {
  orderlineStats: V1StatsResponse
  isPDF: boolean
  downloadWeek?: number
  setDownloadWeek?: (x: number) => void
  downloadItem?: DailyBreakdownType
  setDownloadItem?: (x: DailyBreakdownType) => void
}) => {
  // const [breakdownChoice, setBreakdownChoice] = useState<string>()
  const [orgStats, setOrgStats] = useState(undefined)
  const [dailyStats, setDailyStats] = useState<V1ResultMetrics[] | []>(
    orgStats || [],
  )
  const [item, setItem] = useState<string>(downloadItem || 'clicks')
  const [week, setWeek] = useState(downloadWeek || 0)
  const [ready, setReady] = useState(false)
  const [totalClicks, setTotalClicks] = useState(0)
  const [totalImps, setTotalImps] = useState(0)
  const { currentOrg } = useContext(AppContext)
  const today = dayjs()

  const { results, totals } = orderlineStats
  const resultsBreakdown = results ? results[0] : undefined
  /* const getDailyStats = async (camp: V1ApqsCampaign) => {
     *   const campaignEndDate = () => {
     *     return dayjs(camp.flight_dates.stop).isBefore(today)
     *       ? dayjs(camp.flight_dates.stop).format('YYYY-MM-DD')
     *       : today.format('YYYY-MM-DD')
     *   }
     *   const request = {
     *     org_id: camp?.orgId || currentOrg?.id,
     *     ...(!org && { campaign_id: camp._id }),
     *     time_frame: 'day',
     *     ...(org
     *       ? { start_time: today.subtract(99, 'day').format('YYYY-MM-DD') }
     *       : {
     *           start_time: dayjs(camp.flight_dates.start).format('YYYY-MM-DD'),
     *         }),
     *     ...(org
     *       ? { end_time: today.format('YYYY-MM-DD') }
     *       : { end_time: campaignEndDate() }),
     *   }
     *   const res = await getStats(request).then((res) => {
     *     return res
     *   })

     *   if (org) {
     *     const firstIndex = res?.data?.results?.findIndex(
     *       (stat) => stat.clicks > 0 || stat.imps > 0,
     *     )
     *     const lastIndex =
     *       99 -
     *       res?.data?.results
     *         ?.slice()
     *         .reverse()
     *         .findIndex((stat) => stat.clicks > 0 || stat.imps > 0)
     *     setData(res?.data?.results?.slice(firstIndex, lastIndex + 1))
     *   } else {
     *     setData(res?.data?.results)
     *   }

     *   setOrgStats(res.data)
     * } */
  useEffect(() => {
    if (resultsBreakdown?.results) {
      setDailyStats(resultsBreakdown?.results)
    }
    setReady(true)
  }, [])

  // const statChoice = breakdownChoice || 'clicks'

  /* if (
   *   orgStats === undefined ||
   *   DailyStats === undefined ||
   *   (totalClicks === 0 && totalImps === 0)
   * ) {
   *   return null
   * } */
  return (
    <div
      className={`DailyBreakdown ${
        currentOrg
          ? 'DashboardDailyBreakdown'
          : 'dailyBreakdown-container-Stats'
      } ${isPDF ? `isPDF` : 'isOnline'}`}
    >
      <div className="header-DailyBreakdown text-xl">
        <div
          className={`${
            currentOrg ? 'dashboard-title-header' : 'title-header'
          } header-CampaignCreatives gap-1`}
        >
          <span>Daily breakdown by</span>
          <span>
            {item.toUpperCase() === 'CTR' ? 'click-through rate' : item}
          </span>
        </div>
        {!isPDF && (
          <div className="DailyBreakdown__controls flex gap-6">
            <Select
              placeholder=""
              maxHeight={250}
              selectedOption={{ value: item, label: item.toUpperCase() }}
              options={[
                { label: 'Clicks', value: 'clicks' },
                { label: 'Impressions', value: 'impressions' },
                { label: 'CTR', value: 'ctr' },
              ]}
              onChange={(option) => {
                if (option && option.value) {
                  setItem(option?.value)
                  if (setDownloadItem)
                    setDownloadItem(option.value as DailyBreakdownType)
                }
              }}
            />

            <div className="DailyBreakdown__nav flex items-center justify-center">
              <Button
                onClick={() => {
                  setWeek(week - 1)
                  if (setDownloadWeek) setDownloadWeek(week - 1)
                }}
                disabled={week === 0}
                iconOnly={
                  <Fa
                    className="DailyBreakdown__nav-icon group-hover:bg-primary rounded-full transition-all"
                    icon="arrow-left"
                    size={1}
                  />
                }
              />
            </div>
            <div className="DailyBreakdown__nav flex items-center justify-center">
              <Button
                onClick={() => {
                  setWeek(week + 1)
                  if (setDownloadWeek) setDownloadWeek(week + 1)
                }}
                disabled={week + 7 + 1 > dailyStats.length}
                iconOnly={
                  <Fa
                    className="DailyBreakdown__nav-icon"
                    icon="arrow-right"
                    size={1}
                  />
                }
              />
            </div>
          </div>
        )}
      </div>

      <div className="content-DailyBreakdown">
        <div className="week-content relative">
          {ready &&
            dailyStats
              .slice(week, week + 7)
              .map((day: V1ResultMetrics, index: number) => (
                <DayContainer
                  className={`${
                    week === 0 && !isPDF
                      ? 'animate-slideright'
                      : !isPDF
                      ? 'animate-slideleft'
                      : ''
                  }`}
                  key={`${day.start} ${index.toString()}`}
                  day={day}
                  item={item}
                  week={week}
                />
              ))}
        </div>
      </div>
    </div>
  )
}
