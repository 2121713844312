import { useContext } from 'react'
import { Fa } from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import classNames from 'classnames'
import { ThemeContext } from 'Contexts'

export const V2SyncWarning = ({
  currentOrg,
}: {
  currentOrg: Orgmanagerv1Org
}) => {
  const { name: theme } = useContext(ThemeContext)

  return (
    <div
      className={classNames(
        'bg-danger-600 text-l flex items-center justify-center gap-2 rounded-sm p-4 text-white',
        { 'bg-danger-300': theme === 'darkBlue' || theme === 'dark' },
      )}
    >
      <div className="RightVerticalPipe flex gap-2">
        <p className="font-bold">Synced with V2</p>
        <a
          href={`https://portal.eltoro.com/org/${currentOrg.id}`}
          target="_blank"
          rel="noopener noreferrer"
          data-tooltip="top"
          aria-label="Open org in V2"
          className="self-end border-none"
        >
          <Fa
            icon="arrow-up-right-from-square"
            size={1}
            className="!text-white"
          />
        </a>
      </div>

      <p className="text-m font-bold">Last sync:</p>
      <p className="text-m">
        {currentOrg?.lastSyncTime
          ? new Date(currentOrg.lastSyncTime).toLocaleString()
          : 'Unknown'}
      </p>
    </div>
  )
}
