import React, { useEffect, useState } from 'react'
import { MoreInfoContainer } from 'Components/UI/MoreInfoContainer'
import {
  Button,
  FlexBox,
  PreviewType,
  Slider,
  Spacer,
  Text,
  Uploader,
  Fa,
} from '@eltoro-ui/components'
import {
  Group,
  Layer,
  Image as KonvaImage,
  Stage,
  Line,
  Rect,
} from 'react-konva'
import {
  useRecoilState,
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
} from 'recoil'
import { headShotImageScaleSelector, headShotImageSelector } from 'State'
import './PhotoEdit.scss'
import useImage from 'use-image'
import { getSafeCoord, placeImage } from 'Helpers'
import { useDebounce } from '@eltoro-ui/hooks'

const canvasDim = 150
const canvasThird = canvasDim / 3
const canvasLines = [
  [canvasThird, 0, canvasThird, canvasDim],
  [canvasThird * 2, 0, canvasThird * 2, canvasDim],
  [0, canvasThird, canvasDim, canvasThird],
  [0, canvasThird * 2, canvasDim, canvasThird * 2],
]

export const PhotoEdit: React.FC<{ ids: string[] }> = ({ ids }) => {
  const [image, setImage] = useRecoilState(headShotImageSelector(ids))
  const [imageDims, setImageDims] = useState({ width: 0, height: 0 })
  const [scale, setScale] = useRecoilState(headShotImageScaleSelector(ids))
  const [localScale, setLocalScale] = useState(scale)
  const debouncedScale = useDebounce(localScale, 300)
  const imgDetails = placeImage(
    { width: canvasDim, height: canvasDim },
    imageDims,
    localScale,
  )

  useEffect(() => {
    setScale(debouncedScale)
  }, [debouncedScale])

  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
  const [customImg] = useImage(image, 'anonymous')

  const handleDrop = (file: PreviewType[]) => {
    setImage(file[0].preview)
  }

  // set image dimensions on load and upload
  useEffect(() => {
    const newImg = new Image()
    newImg.onload = () => {
      setImageDims({
        height: newImg.height,
        width: newImg.width,
      })
    }
    newImg.src = image
  }, [image])

  const handleZoom = (newScale: number) => {
    setLocalScale({ x: newScale, y: newScale })
  }

  const defaultImage =
    'https://www.naminorthwoods.org/wp-content/uploads/sites/47/2020/07/blank-avatar.png'
  const isCustomImage = image !== defaultImage
  return (
    <div className="PhotoEdit">
      <MoreInfoContainer label="Photo Info">
        <FlexBox alignItems="center" justifyContent="space-between" gap="1rem">
          <Text on="grey-100" weight="bold" kind="subdued">
            Image Upload
          </Text>
          {isCustomImage && (
            <Button
              onClick={() => {
                setImage(defaultImage)
                setLocalScale({ x: 1, y: 1 })
                setScale({ x: 1, y: 1 })
              }}
              iconLeft={<Fa icon="trash" size={1} />}
            />
          )}
        </FlexBox>
        <Spacer height="0.5rem" />
        {isCustomImage ? (
          <FlexBox flexDirection="column" alignItems="stretch">
            <Stage width={canvasDim} height={canvasDim}>
              <RecoilBridge>
                <Layer>
                  <Group
                    width={canvasDim}
                    height={canvasDim}
                    clipFunc={(ctx: CanvasRenderingContext2D) => {
                      ctx.rect(0, 0, canvasDim, canvasDim)
                    }}
                  >
                    <KonvaImage
                      image={customImg}
                      x={imgDetails.x}
                      y={imgDetails.y}
                      width={imgDetails.width}
                      height={imgDetails.height}
                      draggable
                      dragBoundFunc={(pos) => {
                        const containerDims = {
                          width: canvasDim,
                          height: canvasDim,
                        }
                        const imgDims = {
                          width: imgDetails.width,
                          height: imgDetails.height,
                        }
                        // makes sure image cannot leave parent bounds
                        return {
                          x: getSafeCoord('x', containerDims, imgDims, pos),
                          y: getSafeCoord('y', containerDims, imgDims, pos),
                        }
                      }}
                    />
                  </Group>
                  <Rect
                    width={canvasDim}
                    height={canvasDim}
                    stroke="grey"
                    listening={false}
                  />
                  {canvasLines.map((line, i) => (
                    <Line
                      key={i}
                      points={line}
                      stroke="grey"
                      listening={false}
                    />
                  ))}
                </Layer>
              </RecoilBridge>
            </Stage>
            <Slider
              value={localScale.x}
              onChange={handleZoom}
              min={1}
              max={3}
              step={0.1}
              aria-label="image frame crop"
            />
          </FlexBox>
        ) : (
          <Uploader isStyled accepts="images" onDrop={handleDrop}>
            <Text on="grey-100">Click or drag images here</Text>
          </Uploader>
        )}
      </MoreInfoContainer>
    </div>
  )
}
