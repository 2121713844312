import './DateTargetToggle.scss'
import { Checkbox } from '../../../Checkbox'

export const DateTargetToggle = ({
  startDate,
  endDate,
  inputTarget,
  onInputTargetChange,
  indefinite,
  onIndefiniteSelect,
  altButtonStyle,
}: {
  startDate: Date
  endDate: Date
  inputTarget: 'start' | 'end'
  onInputTargetChange: (newInputTarget: 'start' | 'end') => void
  indefinite?: boolean
  onIndefiniteSelect?: (selection: boolean) => void
  altButtonStyle?: boolean
}) => {
  const buttonStartDefault = `DateTargetToggle__button${
    inputTarget === 'start' ? ' DateTargetToggle__button--active' : ''
  }`
  const buttonEndDefault = `DateTargetToggle__button${
    inputTarget === 'end' ? ' DateTargetToggle__button--active' : ''
  }`
  const buttonStartAlt = `DateTargetToggle__buttonAlt${
    inputTarget === 'start' ? ' DateTargetToggle__buttonAlt--active' : ''
  }`
  const buttonEndAlt = `DateTargetToggle__buttonAlt${
    inputTarget === 'end' ? ' DateTargetToggle__buttonAlt--active' : ''
  }`
  const formatDate = (date: Date) =>
    date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  return (
    <>
      <div
        className={`DateTargetToggle${
          indefinite === true ? ' DateTargetToggle--noEndDate' : ''
        }`}
      >
        <button
          type="button"
          className={altButtonStyle ? buttonStartAlt : buttonStartDefault}
          onClick={() => onInputTargetChange('start')}
        >
          {!altButtonStyle && (
            <span
              className={`DateTargetToggle__label${
                indefinite === true ? ' DateTargetToggle__label--centered' : ''
              } uppercase`}
            >
              Start date
            </span>
          )}
          <span
            className={`DateTargetToggle__date${
              indefinite === true ? ' DateTargetToggle__date--centered' : ''
            }`}
          >
            {formatDate(startDate)}
          </span>
        </button>
        {!indefinite && (
          <button
            type="button"
            className={altButtonStyle ? buttonEndAlt : buttonEndDefault}
            onClick={() => onInputTargetChange('end')}
          >
            {!altButtonStyle && (
              <div className="DateTargetToggle__label uppercase">End date</div>
            )}
            <div className="DateTargetToggle__date">{formatDate(endDate)}</div>
          </button>
        )}
      </div>
      {onIndefiniteSelect && (
        <div className="IndefiniteSelector">
          <Checkbox
            checked={indefinite || false}
            onChange={() => onIndefiniteSelect(!indefinite)}
            label={
              <div className="uppercase  IndefiniteSelector__label">
                Set to indefinite end date
              </div>
            }
            size="m"
          />
        </div>
      )}
    </>
  )
}
