/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Creative } from '../models/Deployservicev3Creative';
import { V3ConsoleCampaign } from '../models/V3ConsoleCampaign';
import { V3Segment } from '../models/V3Segment';
import { V3SegmentConfig } from '../models/V3SegmentConfig';
import { HttpFile } from '../http/http';

/**
* The configuration for Xandr Console deployment destinations
*/
export class V3XandrConsoleConfiguration {
    /**
    * ID of the Order Line.
    */
    'orderLineId'?: string;
    /**
    * ID of the Organization.
    */
    'advertiserRefId'?: string;
    /**
    * Name of the Organization.
    */
    'advertiserName'?: string;
    /**
    * The URL the user will be redirected to if the creative is clicked.
    */
    'clickThroughUrl'?: string;
    'consoleCampaign'?: V3ConsoleCampaign;
    /**
    * Creatives that will be deployed.
    */
    'creatives'?: Array<Deployservicev3Creative>;
    /**
    * Segments that will be deployed.
    */
    'segments'?: Array<V3Segment>;
    'segmentConfig'?: V3SegmentConfig;
    /**
    * ID of the campaign.
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserRefId",
            "baseName": "advertiser_ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "advertiserName",
            "baseName": "advertiser_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickThroughUrl",
            "baseName": "click_through_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "consoleCampaign",
            "baseName": "console_campaign",
            "type": "V3ConsoleCampaign",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Deployservicev3Creative>",
            "format": ""
        },
        {
            "name": "segments",
            "baseName": "segments",
            "type": "Array<V3Segment>",
            "format": ""
        },
        {
            "name": "segmentConfig",
            "baseName": "segment_config",
            "type": "V3SegmentConfig",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V3XandrConsoleConfiguration.attributeTypeMap;
    }

    public constructor() {
    }
}

