/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* LegacyCampaign (Legacy). See [Campaign Service](https://docs.xandr.com/bundle/xandr-api/page/campaign-service.html) in Xandr's documentation.
*/
export class V3LegacyCampaign {
    /**
    * The state of the campaign.
    */
    'state'?: string;
    /**
    * The name of the campaign.
    */
    'name'?: string;
    /**
    * The code of the campaign.
    */
    'code'?: string;
    /**
    * The start date of the campaign.
    */
    'startDate'?: Date;
    /**
    * The end date of the campaign.
    */
    'endDate'?: Date;
    /**
    * A CPM bid. May be modified by the cadence_modifer.
    */
    'baseBid'?: number;
    /**
    * Set a maximum bid that will apply to variable pricing models (See cpm_bid_type).
    */
    'maxBid'?: number;
    /**
    * The level at which the cadence modifier is applied. Possible values: 'advertiser' or 'creative'.
    */
    'cadenceType'?: string;
    /**
    * The (optional) landing page URL for non-3rd party image and flash creatives
    */
    'clickUrl'?: string;
    /**
    * Comments about the campaign.
    */
    'comments'?: string;
    /**
    * The daily budget in impressions. Null corresponds to 'unlimited'.
    */
    'dailyBudgetImps'?: number;
    /**
    * The lifetime budget in impressions. Null corresponds to 'unlimited'.
    */
    'lifetimeBudgetImps'?: number;
    /**
    * If true, this campaign will temporarily change the level at which it learns while maintaining a specified profit margin percentage. See 'Optimization Levers' in the UI documentation for more details (customer login required).
    */
    'deferToLiPrediction'?: boolean;
    /**
    * When multiple creatives of the same size are trafficked via a line item, this field's setting is used to determine the creative rotation strategy that will be used. Note that creatives must be managed on the campaign in order to use this field.
    */
    'creativeDistributionType'?: string;
    /**
    * If true, the campaign's daily budgeted spend is spread out evenly throughout each day.
    */
    'enablePacing'?: boolean;
    /**
    * Unable to find this field.
    */
    'requireCookiesForTracking'?: boolean;
    /**
    * The ID of the campaign.
    */
    'id'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "state",
            "baseName": "state",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "baseBid",
            "baseName": "base_bid",
            "type": "number",
            "format": "double"
        },
        {
            "name": "maxBid",
            "baseName": "max_bid",
            "type": "number",
            "format": "double"
        },
        {
            "name": "cadenceType",
            "baseName": "cadence_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickUrl",
            "baseName": "click_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "comments",
            "baseName": "comments",
            "type": "string",
            "format": ""
        },
        {
            "name": "dailyBudgetImps",
            "baseName": "daily_budget_imps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "lifetimeBudgetImps",
            "baseName": "lifetime_budget_imps",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "deferToLiPrediction",
            "baseName": "defer_to_li_prediction",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "creativeDistributionType",
            "baseName": "creative_distribution_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "enablePacing",
            "baseName": "enable_pacing",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "requireCookiesForTracking",
            "baseName": "require_cookies_for_tracking",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3LegacyCampaign.attributeTypeMap;
    }

    public constructor() {
    }
}

