/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Contains data for an Org Logo image file
*/
export class V1OrgLogo {
    /**
    * ID of the org logo file
    */
    'id'?: string;
    /**
    * Date and time an Org Logo File was created at
    */
    'createTime'?: Date;
    /**
    * ID of the org that the file connected to
    */
    'orgId'?: string;
    /**
    * File location Key
    */
    'key'?: string;
    /**
    * Bucket that the file belongs to
    */
    'bucket'?: string;
    /**
    * Name assigned to the creative file
    */
    'name'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string",
            "format": ""
        },
        {
            "name": "bucket",
            "baseName": "bucket",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrgLogo.attributeTypeMap;
    }

    public constructor() {
    }
}

