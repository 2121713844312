import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'

export const addressTargetFixture: V2Target = {
  id: 'abcd',
  refId: '1234',
  orgId: '1234',
  fileType: 'FILE_TYPE_ADDRESS',
  dataSource: 'DATA_SOURCE_CLIENT',
  createTime: new Date(),
  status: 'TARGET_STATUS_SUBMITTED',
  locations: [],
  hasHeader: false,
  audiences: [],
}

export const ipFixture: V2Target = {
  id: 'efgh',
  refId: '5678',
  orgId: '1234',
  fileType: 'FILE_TYPE_IP',
  dataSource: 'DATA_SOURCE_CLIENT',
  createTime: new Date(),
  status: 'TARGET_STATUS_UPLOADED',
  locations: [],
  hasHeader: false,
  audiences: [],
}

export const targetWithLocationFixture: V2Target = {
  id: 'c5fku1kml4phus9imr20',
  refId: '',
  orgId: '',
  fileType: 'FILE_TYPE_ADDRESS',
  dataSource: 'DATA_SOURCE_CLIENT',
  createTime: new Date('2021-10-07T19:48:22.611095Z'),
  status: 'TARGET_STATUS_TARGETABLE',
  locations: [
    {
      id: 'c5fku1kml4phus9imr2g',
      type: 'source',
      createTime: new Date('2021-10-07T19:48:22.621282Z'),
      columns: [
        {
          id: 'c5fku1sml4phus9imr30',
          index: 0,
          type: 'address',
          value: 'address1',
          locationId: 'c5fku1kml4phus9imr2g',
        },
        {
          id: 'c5fku1sml4phus9imr3g',
          index: 1,
          type: 'address_2',
          value: 'other_ignore',
          locationId: 'c5fku1kml4phus9imr2g',
        },
        {
          id: 'c5fku1sml4phus9imr40',
          index: 2,
          type: 'city',
          value: 'city',
          locationId: 'c5fku1kml4phus9imr2g',
        },
        {
          id: 'c5fku1sml4phus9imr4g',
          index: 3,
          type: 'state',
          value: 'state',
          locationId: 'c5fku1kml4phus9imr2g',
        },
        {
          id: 'c5fku1sml4phus9imr50',
          index: 4,
          type: 'zip4',
          value: 'other_ignore',
          locationId: 'c5fku1kml4phus9imr2g',
        },
        {
          id: 'c5fku1sml4phus9imr5g',
          index: 5,
          type: 'zipcode',
          value: 'zip',
          locationId: 'c5fku1kml4phus9imr2g',
        },
      ],
      bucket: 'targetuploads.eltoro.com',
      key: 'dev/c5fku1kml4phus9imr20/two_address_lines (3rd copy).csv',
      filename: 'two_address_lines (3rd copy).csv',
      targetId: 'c5fku1kml4phus9imr20',
      metadata: 'bnVsbA==',
    },
    {
      id: 'c5fku1sml4phus9imr60',
      type: 'cleaned',
      createTime: new Date('2021-10-07T19:48:23.346027Z'),
      columns: [
        {
          id: 'c5fku1sml4phus9imr6g',
          index: 0,
          type: 'address',
          value: 'address1',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr70',
          index: 1,
          type: 'address_2',
          value: 'other_ignore',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr7g',
          index: 2,
          type: 'city',
          value: 'city',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr80',
          index: 3,
          type: 'state',
          value: 'state',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr8g',
          index: 4,
          type: 'zip4',
          value: 'other_ignore',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr90',
          index: 5,
          type: 'zipcode',
          value: 'zip',
          locationId: 'c5fku1sml4phus9imr60',
        },
        {
          id: 'c5fku1sml4phus9imr9g',
          index: 6,
          type: 'address_1',
          value: 'qAddress',
          locationId: 'c5fku1sml4phus9imr60',
        },
      ],
      bucket: 'targetuploads.eltoro.com',
      key: 'dev/c5fku1kml4phus9imr20/two_address_lines (3rd copy)-clean.csv',
      filename: 'two_address_lines (3rd copy)-clean.csv',
      targetId: 'c5fku1kml4phus9imr20',
      metadata:
        'eyJidWNrZXRSZXZpZXciOnsidG90YWwtbGluZXMiOjEyLCJkdXBsaWNhdGVzIjoxMiwiYXBhcnRtZW50cyI6MCwic3BlY2lhbC1jaGFycyI6MTMsInBvLWJveGVzIjowLCJiYWQtemlwcyI6MTMsImJhZC1hZGRyZXNzZXMiOjEzLCJyZW1haW5pbmctbGluZXMiOjB9LCJxdW90ZUNvbHVtbnMiOlt7InNyY0tleSI6ImFkZHJlc3MiLCJzcmNJbmRleCI6MCwic3JjRmllbGQiOiJhZGRyZXNzMSIsInBheWxvYWRLZXkiOiIifSx7InNyY0tleSI6ImFkZHJlc3NfMiIsInNyY0luZGV4IjoxLCJzcmNGaWVsZCI6Im90aGVyX2lnbm9yZSIsInBheWxvYWRLZXkiOiIifSx7InNyY0tleSI6ImNpdHkiLCJzcmNJbmRleCI6Miwic3JjRmllbGQiOiJjaXR5IiwicGF5bG9hZEtleSI6ImMifSx7InNyY0tleSI6InN0YXRlIiwic3JjSW5kZXgiOjMsInNyY0ZpZWxkIjoic3RhdGUiLCJwYXlsb2FkS2V5IjoicyJ9LHsic3JjS2V5IjoiemlwNCIsInNyY0luZGV4Ijo0LCJzcmNGaWVsZCI6Im90aGVyX2lnbm9yZSIsInBheWxvYWRLZXkiOiJ6NCJ9LHsic3JjS2V5IjoiemlwY29kZSIsInNyY0luZGV4Ijo1LCJzcmNGaWVsZCI6InppcCIsInBheWxvYWRLZXkiOiIifSx7InNyY0tleSI6ImFkZHJlc3NfMSIsInNyY0luZGV4Ijo2LCJzcmNGaWVsZCI6InFBZGRyZXNzIiwicGF5bG9hZEtleSI6ImEifV0sImZpbGVzIjpbeyJidWNrZXQiOiJ0YXJnZXR1cGxvYWRzLmVsdG9yby5jb20iLCJrZXkiOiJkZXYvYzVma3Uxa21sNHBodXM5aW1yMjAvdHdvX2FkZHJlc3NfbGluZXMgKDNyZCBjb3B5KS1jbGVhbi5jc3YiLCJ0eXBlIjoiYWRkZXNzZXMtY2xlYW4iLCJ1cGRhdGVkIjoiMjAyMS0xMC0wN1QxOTo0ODoyMy4yNDQyNTg5MzJaIiwibWltZSI6InRleHQvY3N2In0seyJidWNrZXQiOiJ0YXJnZXR1cGxvYWRzLmVsdG9yby5jb20iLCJrZXkiOiJkZXYvYzVma3Uxa21sNHBodXM5aW1yMjAvdHdvX2FkZHJlc3NfbGluZXMgKDNyZCBjb3B5KS1lcnJvcnMuY3N2IiwidHlwZSI6ImFkZGVzc2VzLWVycm9yIiwidXBkYXRlZCI6IjIwMjEtMTAtMDdUMTk6NDg6MjMuMzQyNzk5MzM4WiIsIm1pbWUiOiJ0ZXh0L2NzdiJ9XX0=',
    },
  ],
  hasHeader: true,
  audiences: [
    {
      id: 'c5fku1pnt6g61lh77tsg',
    },
  ],
  folder: 'nintendo',
}

export const jobFixture: Targetjobservicev1Audience = {
  id: 'c5fku1pnt6g61lh77tsg',
  createTime: new Date('2021-10-07T19:48:23.697389Z'),
  type: 'AUDIENCE_TYPE_B2C',
  status: 'AUDIENCE_STATUS_RUNNING',
  instanceId: 'c5fkuo9nt6g61lh77tt0',
  result: undefined,
  targetId: 'c5fku1kml4phus9imr20',
  audienceId: '',
  audiences: [],
  matchCap: 0,
  orgId: '',
  code: 0,
  name: 'Big Job',
}

export const vrJobFixture: Targetjobservicev1Audience = {
  id: 'c8f2hh0cgt0t5japu580',
  createTime: new Date('2022-03-01T14:08:04.107048Z'),
  type: 'AUDIENCE_TYPE_VR',
  status: 'AUDIENCE_STATUS_COMPLETED',
  instanceId: '',
  result: undefined,
  targetId: 'c8f2hgqoj7ip8vhv0eq0',
  audienceId: '',
  name: 'VR Job',
  audiences: [
    {
      id: 'c8f2ho8cgt0t5japu58g',
      createTime: new Date('2022-03-01T14:08:33.444962Z'),
      type: 'AUDIENCE_TYPE_ADDRESS',
      status: 'AUDIENCE_STATUS_COMPLETED',
      instanceId: 'c8f2htocgt0t5japu590',
      result: undefined,
      targetId: 'c8f2hgqoj7ip8vhv0eq0',
      audienceId: 'c8f2hh0cgt0t5japu580',
      matchCap: 0,
      orgId: 'ce80c207-d01f-451b-91d7-563626b1babd',
      code: 108,
      orderLineIds: [],
      timeframes: [],
      name: '',
    },
    {
      id: 'c8f2hu0cgt0t5japu5bg',
      createTime: new Date('2022-03-01T14:08:56.767245Z'),
      type: 'AUDIENCE_TYPE_DEVICE',
      status: 'AUDIENCE_STATUS_COMPLETED',
      instanceId: 'c8f2hu0cgt0t5japu5c0',
      result: undefined,
      targetId: 'c8f2hgqoj7ip8vhv0eq0',
      audienceId: 'c8f2hh0cgt0t5japu580',
      matchCap: 0,
      orgId: 'ce80c207-d01f-451b-91d7-563626b1babd',
      code: 111,
      orderLineIds: [],
      timeframes: [],
      name: '',
    },
    {
      id: 'c8f2hu0cgt0t5japu5ag',
      createTime: new Date('2022-03-01T14:08:56.370430Z'),
      type: 'AUDIENCE_TYPE_DEVICE',
      status: 'AUDIENCE_STATUS_COMPLETED',
      instanceId: 'c8f2hu0cgt0t5japu5b0',
      result: undefined,
      targetId: 'c8f2hgqoj7ip8vhv0eq0',
      audienceId: 'c8f2hh0cgt0t5japu580',
      matchCap: 0,
      orgId: 'ce80c207-d01f-451b-91d7-563626b1babd',
      code: 110,
      orderLineIds: [],
      timeframes: [],
      name: '',
    },
    {
      id: 'c8f2htocgt0t5japu59g',
      createTime: new Date('2022-03-01T14:08:55.882421Z'),
      type: 'AUDIENCE_TYPE_DEVICE',
      status: 'AUDIENCE_STATUS_COMPLETED',
      instanceId: 'c8f2htocgt0t5japu5a0',
      result: undefined,
      targetId: 'c8f2hgqoj7ip8vhv0eq0',
      audienceId: 'c8f2hh0cgt0t5japu580',
      matchCap: 0,
      orgId: 'ce80c207-d01f-451b-91d7-563626b1babd',
      code: 109,
      orderLineIds: [],
      timeframes: [],
      name: '',
    },
  ],
  matchCap: 0,
  orgId: 'ce80c207-d01f-451b-91d7-563626b1babd',
  code: 0,
  orderLineIds: [
    '98ffbd95-fd49-4cca-868b-d8f00f7f690c',
    'ecf3df37-d6d2-479e-b400-b534dff4466e',
    '6b5a6d1d-5f7f-46f3-b69a-a908496ae347',
  ],
  timeframes: [
    {
      start: '2022-02-15T14:07:25.000Z',
      stop: '2022-02-16T19:07:23.000Z',
    },
  ],
}

export const targetFixtures: V2Target[] = [
  addressTargetFixture,
  ipFixture,
  targetWithLocationFixture,
]
