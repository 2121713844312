import { Orgmanagerv1Org } from 'next-gen-sdk'
import axios from 'axios'
import { convertObjectKeysToSnakeCase, getApiUrl } from 'Helpers'

// This is used in lieu of the SDK because of issues with snake case vs camelCase
// properties in the CPM map. For more info, see Slack:
// https://eltoroteam.slack.com/archives/G01CSAF0RJQ/p1700169954119849
// https://eltoroteam.slack.com/archives/G01CSAF0RJQ/p1700177617009289
export const createChildOrg = async (childOrg: Orgmanagerv1Org) => {
  const url = `${getApiUrl()}/v1`
  const token = localStorage.getItem('eltoro_token')
  const config = { Authorization: `Bearer ${token}` }

  const newChildOrg = await axios.post<Orgmanagerv1Org>(
    `${url}/orgs`,
    {
      parent_org_id: childOrg.parentOrgId,
      billable_org_id: childOrg.billableOrgId,
      name: childOrg.name,
      contacts: childOrg.contacts?.map((c) => convertObjectKeysToSnakeCase(c)),
      cpms: childOrg.cpms,
    },
    {
      headers: config,
    },
  )

  return newChildOrg.data
}
