import React from 'react'
import { LoadingBar, TextInput } from '@eltoro-ui/components'
import classNames from 'classnames'
import { StepperSection } from '../../WebToHome'

export const Step3Finalize: React.FC<{
  audienceName: string
  setAudienceName: React.Dispatch<React.SetStateAction<string>>
  loading: Boolean
  navButtons: (
    nextStep: 'domain' | 'logic' | 'finalize' | 'success',
    backStep?: 'domain' | 'logic' | 'finalize' | undefined,
  ) => JSX.Element
}> = ({ audienceName, setAudienceName, loading, navButtons }) => {
  return (
    <StepperSection
      className={classNames('WebToHome__Step3Finalize relative', {
        'pointer-events-none opacity-25': loading,
      })}
    >
      {loading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <div className="max-w-3xl">
            <LoadingBar kind="circle" colorKind="primary" />
          </div>
        </div>
      )}
      <p className="text-l font-light">Step 3</p>
      <p className="text-l mb-4 font-bold">Finalize and Save</p>
      <p className="font-bold">
        Sync audience data with print house&apos;s SFTP server
      </p>
      <p className="text-s mb-2 font-light">
        Enter the secure file pathway for the print house where we will send
        address data to
      </p>
      <div className="mb-6 flex max-w-3xl flex-col justify-end">
        <TextInput value="" onChange={(e) => {}} />
        <p className="text-right text-xs font-light">
          Leave blank to use default
        </p>
      </div>
      <p className="font-bold">Name this audience</p>
      <p className="text-s mb-2 font-light">
        This is how you will find your audience group later
      </p>
      <TextInput
        value={audienceName}
        onChange={(e) => setAudienceName(e.target.value)}
        className="max-w-3xl"
        placeholder="Example: Name of your segment"
      />
      {navButtons('success', 'logic')}
    </StepperSection>
  )
}
