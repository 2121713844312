/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Checks } from '../models/Deployservicev3Checks';
import { Deployservicev3HealthCondition } from '../models/Deployservicev3HealthCondition';
import { HttpFile } from '../http/http';

export class Deployservicev3HealthCheck {
    'serviceName'?: string;
    'healthCondition'?: Deployservicev3HealthCondition;
    'commitHash'?: string;
    'serviceVersion'?: string;
    'checks'?: Deployservicev3Checks;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "serviceName",
            "baseName": "serviceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "healthCondition",
            "baseName": "healthCondition",
            "type": "Deployservicev3HealthCondition",
            "format": ""
        },
        {
            "name": "commitHash",
            "baseName": "commitHash",
            "type": "string",
            "format": ""
        },
        {
            "name": "serviceVersion",
            "baseName": "serviceVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "checks",
            "baseName": "checks",
            "type": "Deployservicev3Checks",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Deployservicev3HealthCheck.attributeTypeMap;
    }

    public constructor() {
    }
}

