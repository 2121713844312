/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1CommissionType } from '../models/V1CommissionType';
import { HttpFile } from '../http/http';

export class V1RunCommissionsRequest {
    'dateEnd'?: string;
    'addCommissions'?: boolean;
    'commissionType'?: V1CommissionType;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "dateEnd",
            "baseName": "dateEnd",
            "type": "string",
            "format": ""
        },
        {
            "name": "addCommissions",
            "baseName": "addCommissions",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "commissionType",
            "baseName": "commissionType",
            "type": "V1CommissionType",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1RunCommissionsRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

