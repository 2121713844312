import React, { useEffect, useRef } from 'react'
import { Text as TextType } from 'konva/types/shapes/Text'
import { Text } from 'react-konva'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  blockFontFamilyAtom,
  blockFontSizeAtom,
  blockFontStyleAtom,
  blockTextAlignAtom,
  blockTextColorAtom,
  blockTextDecorationAtom,
  blockTextOverflowErrorAtom,
  blockTextValueAtom,
} from 'State'
import { BlockConfigType } from 'Typings'
import { mergeRefs } from '@eltoro-ui/components'

// wraps Konva's Text with recoil related values to user can supply only a block config if needed
export const RecoilText = React.forwardRef(
  (
    {
      config,
      width,
      height,
      x,
      y,
      name,
      isOnMobile = false,
      value,
      ellipsis = true,
      id,
    }: {
      config: BlockConfigType
      width?: number
      height?: number
      x?: number
      y?: number
      name?: string
      isOnMobile?: boolean
      value?: string
      ellipsis?: boolean
      id?: string
    },
    forwardedRef,
  ) => {
    const ref = useRef<TextType>(null)
    const setTextError = useSetRecoilState(blockTextOverflowErrorAtom(id))
    const color = useRecoilValue(blockTextColorAtom(config))
    const fontSize = useRecoilValue(blockFontSizeAtom(config))
    const align = useRecoilValue(blockTextAlignAtom(config))
    const fontStyle = useRecoilValue(blockFontStyleAtom(config))
    const textDecoration = useRecoilValue(blockTextDecorationAtom(config))
    const fontFamily = useRecoilValue(blockFontFamilyAtom(config))
    const textValue = useRecoilValue(blockTextValueAtom(config))

    useEffect(() => {
      if (!ref.current) return
      const text = value || textValue
      const { textArr } = ref.current
      // konva supplies text broken into array if line length > 1
      const joinedText = () => {
        if (textArr.length) {
          return textArr.map((t) => t.text).join(' ')
        }
        return text
      }
      if (ellipsis) setTextError(text !== joinedText())
    }, [ref.current, fontSize, value, textValue])

    return (
      <>
        <Text
          name={name}
          ref={mergeRefs([ref, forwardedRef])}
          fill={color}
          x={x}
          y={y}
          height={height}
          width={width}
          align={align}
          text={value || textValue}
          fontSize={isOnMobile ? fontSize * 0.75 : fontSize}
          fontStyle={fontStyle}
          textDecoration={textDecoration}
          fontFamily={fontFamily}
          wrap="word"
          ellipsis={ellipsis}
        />
      </>
    )
  },
)
