import { useState } from 'react'
import { Button, Checkbox, Fa, Tooltip } from '@eltoro-ui/components'
import classNames from 'classnames'
import { simplifyEnum, userFriendlyAudienceName } from 'Helpers'
import {
  AudienceRowType,
  checkIfMappedHomesIsDisabled,
} from '../AudienceSelector'

export const AudienceRow: React.FC<{
  row: AudienceRowType
  onChange: (jobToChange: AudienceRowType, attached: boolean) => void
  audienceRows: AudienceRowType[]
  isMappedHomesDisabled?: boolean
}> = ({ row, onChange, audienceRows, isMappedHomesDisabled }) => {
  const [isOpen, setIsOpen] = useState(false)

  const disableDetach =
    row.subType === 'AUDIENCE_SUB_TYPE_HOMES' && isMappedHomesDisabled

  return (
    <>
      <div
        className={classNames(
          'AudienceRow-name max-w-l flex min-h-[1.5rem] items-center gap-1 py-1',
          {
            'pl-8': row.audienceId && row.subType,
          },
        )}
      >
        {row.audienceId && row.subType && (
          <Fa
            icon="arrow-turn-down-right"
            size={1}
            color="grey"
            className="pb-1 pl-1"
          />
        )}
        {row.type === 'AUDIENCE_TYPE_VR' &&
          row.status === 'AUDIENCE_STATUS_COMPLETED' &&
          row.subJobs &&
          row.subJobs.length > 0 && (
            <Button
              iconOnly={
                <Fa icon={isOpen ? 'caret-down' : 'caret-right'} size={1} />
              }
              onClick={() => setIsOpen(!isOpen)}
              className="self-center"
            />
          )}
        <div
          className={classNames('flex items-center gap-1', {
            'font-bold': !row.audienceId,
          })}
        >
          {row.locked && !row.audienceId && (
            <Fa icon="lock" size={1} className="!text-primary" />
          )}
          {row.audienceId && row.subType
            ? userFriendlyAudienceName(row.subType)
            : row.name || 'No name'}
        </div>
      </div>
      <div className="AudienceRow-type">
        {row.type ? simplifyEnum(2, row.type) : 'n/a'}
      </div>
      <div className="AudienceRow-matched">
        {(() => {
          if (!row.quoted) return 'Quoting'
          return row.matched?.toLocaleString() || ''
        })()}
      </div>
      <div className="AudienceRow-attach justify-self-end">
        <Checkbox
          checked={row.attached}
          onChange={() => {
            if (row.id) onChange(row, true)
          }}
          size="m"
        />
      </div>
      <div className="AudienceRow-detach justify-self-end">
        {(() => {
          const check = (
            <Checkbox
              checked={!row.attached}
              onChange={() => {
                if (row.id) onChange(row, false)
              }}
              size="m"
              disabled={disableDetach}
              className={disableDetach ? 'opacity-20' : undefined}
            />
          )
          if (disableDetach)
            return (
              <Tooltip
                position="left"
                content="Must include Mapped Homes when Other Device IDs in Home count is less than 30,000."
                classNameTooltip="w-[15rem] top-[-10px]"
                className="focus:rounded-sm"
              >
                {check}
              </Tooltip>
            )
          return check
        })()}
      </div>
      {((!row.audienceId && row.type !== 'AUDIENCE_TYPE_VR') ||
        !isOpen ||
        (row.type === 'AUDIENCE_TYPE_VR' &&
          row.subJobs &&
          row.subJobs.length === 0)) && (
        <div className="box border-grey-200 col-start-1 col-end-6 border-b" />
      )}
      {row.type === 'AUDIENCE_TYPE_VR' &&
        isOpen &&
        row.status === 'AUDIENCE_STATUS_COMPLETED' && (
          <>
            {row?.subJobs?.map((subJob) => {
              return (
                <div
                  className="AudienceRow__sub-jobs bg-gray animate-slidedown col-start-1 col-end-6"
                  key={subJob.id}
                >
                  <div className="AudienceRow__sub-job-row grid grid-cols-[2fr_1fr_1fr_1fr_1fr] items-center gap-x-4">
                    <AudienceRow
                      row={subJob}
                      onChange={onChange}
                      audienceRows={audienceRows}
                      isMappedHomesDisabled={checkIfMappedHomesIsDisabled(row)}
                    />
                  </div>
                </div>
              )
            })}
          </>
        )}
    </>
  )
}
