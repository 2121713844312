import React, { useState, useEffect } from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import { useAuth } from 'react-oidc-context'
import { getAPIDocsPage } from 'Requests'
import { Loading } from 'Components/Loading'

export const DocumentationPage = () => {
  const [docsSpec, setDocsSpec] = useState()

  const auth = useAuth()
  useEffect(() => {
    if (auth.user?.access_token) {
      getAPIDocsPage(auth.user.access_token).then((res) => {
        console.log(res)
        setDocsSpec(res)
      })
    }
  }, [auth.user?.access_token])

  return (
    <div className="DocumentationPage">
      {docsSpec ? (
        // @ts-ignore
        <SwaggerUI spec={docsSpec} />
      ) : (
        <Loading className="h-[84vh]" />
      )}
    </div>
  )
}
