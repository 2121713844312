/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceUpdateFTPRequest {
    /**
    * RefId of the ftp server
    */
    'refId'?: string;
    /**
    * The cred id for the ftp server
    */
    'credId'?: string;
    /**
    * Username for the ftp server
    */
    'username'?: string;
    /**
    * Password for the ftp server
    */
    'password'?: string;
    /**
    * The ftp server's host
    */
    'host'?: string;
    /**
    * The ftp server's port
    */
    'port'?: number;
    /**
    * The Id of the Publisher
    */
    'publisherId'?: string;
    /**
    * Whether it goes over TLS or not
    */
    'tls'?: boolean;
    /**
    * The Pixels OrgID
    */
    'orgId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "credId",
            "baseName": "cred_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "username",
            "baseName": "username",
            "type": "string",
            "format": ""
        },
        {
            "name": "password",
            "baseName": "password",
            "type": "string",
            "format": ""
        },
        {
            "name": "host",
            "baseName": "host",
            "type": "string",
            "format": ""
        },
        {
            "name": "port",
            "baseName": "port",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "publisherId",
            "baseName": "publisher_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "tls",
            "baseName": "tls",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceUpdateFTPRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

