import React, { ReactNode } from 'react'
import { Text } from '@eltoro-ui/components'
import { truncateMiddle } from 'Helpers'
import './IconButton.scss'

export type ButtonConfigType = {
  id: string
  title: string
  subTitle?: string
  tagLabel?: string
  icon: ReactNode
  comingSoon?: boolean
  disabled?: boolean
  adminOnly?: boolean
  className?: string
}

export const IconButton: React.FC<{
  contentVertical?: boolean
  id: string
  title: string
  subTitle?: string
  tagLabel?: string
  isSelected: boolean
  disabled?: boolean
  comingSoon?: boolean
  icon: React.ReactNode
  handleClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => void
  centered?: boolean
  classNameButton?: string
  className?: string
  capitalize?: boolean
}> = (props) => {
  const {
    handleClick,
    id,
    isSelected,
    title,
    icon,
    subTitle,
    contentVertical,
    centered,
    disabled,
    comingSoon,
    className = 'IconButton__base',
    classNameButton = '',
    capitalize = true,
    tagLabel,
  } = props

  const truncatedTitle = truncateMiddle(title, 28)

  return (
    <button
      disabled={disabled}
      type="button"
      className={`IconButton group relative flex flex-1 gap-2 ${classNameButton} ${
        isSelected ? 'is-selected' : ''
      }`}
      onClick={(e) => handleClick(e, id)}
    >
      <span
        className={`IconButtonWrapHover mobile:w-full flex flex-1 items-center justify-center rounded-lg transition-all ${className} ${
          !disabled ? 'hover:bg-tint-gray-050 focus:bg-tint-gray-050' : ''
        }`}
      >
        {disabled && comingSoon && (
          <span className="IconButton__ComingSoon text-tint-gray-300 border-tint-gray-200 absolute cursor-default text-center leading-none shadow-none hover:bg-transparent hover:shadow-none">
            COMING SOON
          </span>
        )}
        <span
          className={`IconButton__itemContainer laptop:flex-wrap flex items-center justify-center ${
            contentVertical
              ? 'IconButton__itemContainer-vertical flex-col'
              : 'IconButton__itemContainer-horizontal'
          }`}
        >
          <span className="IconButton__icon-wrap flex flex-col items-center justify-center">
            {icon}
          </span>
          <span
            className={`IconButton__text-wrap align-center relative flex flex-col items-center gap-1 ${
              centered ? 'items-center justify-center text-center' : ''
            }`}
          >
            {tagLabel && (
              <Text tag="h6" className="IconButton__tag-label">
                {tagLabel}
              </Text>
            )}
            {title && (
              <h3
                className={`IconButton__title ${
                  capitalize ? 'capitalize' : ''
                }`}
              >
                <span
                  className="IconButton__title--default"
                  aria-label={title}
                  data-tooltip="top"
                >
                  {truncatedTitle}
                </span>
                <span className="IconButton__title--hover">{title}</span>
              </h3>
            )}
            {subTitle && (
              <span className="IconButton__subTitle">
                <span className="text">{subTitle}</span>
              </span>
            )}
          </span>
        </span>
      </span>
    </button>
  )
}
