import React from 'react'
import { Fa, Text } from '@eltoro-ui/components'
import './BannerChecklist.scss'
import { bannerChecklistConfig } from './BannerChecklistConfig'

type ChecklistConfigType = {
  name: string
  suggested: boolean
  dimensions: string
  width: string
  height: string
}

export const BannerChecklist = ({
  uploadedSizes,
}: {
  uploadedSizes: string[]
}) => {
  const isProvided = (conf: ChecklistConfigType) =>
    uploadedSizes.some((size: string) => size === conf.dimensions)
  const sortedChecklist = bannerChecklistConfig.reduce(
    (acc: ChecklistConfigType[], val: ChecklistConfigType) => {
      if (isProvided(val)) return [val, ...acc]
      return [...acc, val]
    },
    [],
  )
  return (
    <div className="BannerChecklist">
      <div className="BannerChecklist__Header flex flex-col gap-1">
        <Text className="BannerChecklist__Header" tag="h4">
          Banner checklist:
        </Text>
        <div className="BannerChecklist__Subheader flex items-center font-light">
          <Fa
            className="text-warning-200 -ml-2 scale-75"
            icon="star"
            size={1}
          />
          We recommend at least three creative sizes with stars, for optimal
          performance
        </div>
      </div>
      <div className="BannerChecklist__Body bg-tint-light">
        {sortedChecklist.map((config: ChecklistConfigType) => {
          return (
            <div
              className="BannerChecklist__Body--item flex items-center gap-1"
              key={config.dimensions}
            >
              <div className="BannerChecklist__ExampleShape">
                <div
                  className="BannerChecklist__ExampleShapeItem"
                  style={{ width: config.width, height: config.height }}
                />
              </div>
              {config.suggested && (
                <Fa className="text-warning-200" icon="star" size="lg" />
              )}
              {config.name}: {config.dimensions}
              {isProvided(config) && (
                <Fa
                  className="text-success-300 ml-auto"
                  icon="check-circle"
                  size="lg"
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
