import { useEffect, useState } from 'react'
import { Form, FormItem, TextInput, Button } from '@eltoro-ui/components'
import { useValidatedState } from '@eltoro-ui/hooks'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { TextInputWithErrorText } from 'Components'
import {
  ErrorMessage,
  isValidCharLength,
  styleTailwind,
  validCheck,
} from 'Helpers'

const ClickThruField = styleTailwind(
  FormItem,
  'items-start ClickThruField FormItem flex-col gap-1',
)

const PreviewSubHead = styleTailwind(
  'h6',
  'PreviewSubHead__text flex text-m font-bold justify-between leading-normal uppercase pt-2',
)

const SubHeadCount = styleTailwind('span', 'font-light')

export const EditNativeBanner: React.FC<{
  editing: boolean
  previewItem: Creativeservicev1Creative
  cancelEdit: (value: boolean) => void
}> = ({ editing, previewItem, cancelEdit }) => {
  // Native Editing State
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [clickThru, setClickThru, isValidClickThru] = useValidatedState(
    'https://',
    'url',
  )
  const [sponsor, setSponsor] = useState<string>('')
  const [callToAction, setCallToAction] = useState<string>('')
  useEffect(() => {
    if (previewItem && previewItem.nativeMetadata) {
      setCallToAction(previewItem.nativeMetadata.callToAction || '')
      setTitle(previewItem.nativeMetadata.title || '')
      setClickThru(
        previewItem.nativeMetadata.alternativeMobileLandingPageUrl || '',
      )
      setSponsor(previewItem.nativeMetadata.sponsoredBy || '')
      setDescription(previewItem.nativeMetadata.bodyText || '')
    }
  }, [])
  return (
    <div className="PreviewPanel__thumbnails flex w-fit max-w-full flex-col break-words break-all">
      {!editing && (
        <div className="PreviewPanel__thumbnails-content text-s flex flex-col gap-1 leading-tight">
          <span className="PreviewPanel">
            <PreviewSubHead>
              Title:&nbsp;
              <SubHeadCount>
                ({previewItem.nativeMetadata?.title?.length}/25)
              </SubHeadCount>
            </PreviewSubHead>
            {previewItem.nativeMetadata?.title}
          </span>
          <span className="PreviewPanel">
            <PreviewSubHead>
              Description:&nbsp;
              <SubHeadCount>
                ({previewItem.nativeMetadata?.bodyText?.length}/90)
              </SubHeadCount>
            </PreviewSubHead>
            {previewItem.nativeMetadata?.bodyText}
          </span>
          <span className="PreviewPanel">
            <PreviewSubHead>
              Sponsor:&nbsp;
              <SubHeadCount>
                ({previewItem.nativeMetadata?.sponsoredBy?.length}/25)
              </SubHeadCount>
            </PreviewSubHead>
            {previewItem.nativeMetadata?.sponsoredBy}
          </span>
          <span className="PreviewPanel">
            <PreviewSubHead>
              Click-through URL:&nbsp;
              <SubHeadCount>
                (
                {
                  previewItem.nativeMetadata?.alternativeMobileLandingPageUrl
                    ?.length
                }
                /1000)
              </SubHeadCount>
            </PreviewSubHead>
            {previewItem.nativeMetadata?.alternativeMobileLandingPageUrl}
          </span>
          <span className="PreviewPanel">
            <PreviewSubHead>
              Call To Action:&nbsp;
              <SubHeadCount>
                ({previewItem.nativeMetadata?.callToAction?.length}/50)
              </SubHeadCount>
            </PreviewSubHead>
            {previewItem.nativeMetadata?.callToAction}
          </span>
        </div>
      )}
      {editing && (
        <Form className="font-bold">
          <FormItem
            wrapperClassname="flex flex-col pt-2"
            htmlFor="title"
            label="Title"
            errorMessage={ErrorMessage({
              fieldName: title,
              max: 25,
              label: 'Title',
              isValid: title.length > 1 && title.length < 25,
            })}
            valid={validCheck(title) && isValidCharLength(title, 25)}
            counter={title.length > 0}
            required
          >
            <TextInput
              value={title}
              maxLength={26}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.split('').length <= 25) {
                  setTitle(e.target.value)
                }
              }}
              size="s"
            />
          </FormItem>
          <FormItem
            htmlFor="description"
            label="Description"
            wrapperClassname="flex flex-col pt-2"
            errorMessage={ErrorMessage({
              fieldName: description,
              max: 90,
              label: 'Description',
              isValid: description.length > 1 && description.length < 90,
            })}
            valid={
              validCheck(description) && isValidCharLength(description, 90)
            }
            counter={description.length > 0}
            required
          >
            <textarea
              className="Native-Description border-thin border-grey-300 focus:ring-secondary-500 h-20 bg-transparent p-1 focus:ring"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength={91}
            />
          </FormItem>
          <FormItem
            htmlFor="sponsorName"
            label="Sponsor Name"
            wrapperClassname="flex flex-col pt-2"
            errorMessage={ErrorMessage({
              fieldName: sponsor,
              max: 90,
              label: 'Sponsor',
              isValid: sponsor.length > 1 && sponsor.length < 90,
            })}
            valid={validCheck(sponsor) && isValidCharLength(sponsor, 90)}
            counter={sponsor.length > 0}
            required
          >
            <TextInput
              value={sponsor}
              onChange={(e) => {
                if (e.target.value.split('').length <= 25) {
                  setSponsor(e.target.value)
                }
              }}
              size="s"
            />
          </FormItem>
          <ClickThruField
            htmlFor="clickthru"
            label={<span className="FormItem__label">Click-through URL</span>}
            errorMessage={ErrorMessage({
              fieldName: clickThru,
              max: 1000,
              label: 'Click-through URL',
              isValid:
                validCheck(clickThru) && isValidCharLength(clickThru, 1000),
            })}
            valid={validCheck(clickThru) && isValidCharLength(clickThru, 1000)}
            counter={clickThru.length > 8}
          >
            <TextInputWithErrorText
              id="clickthru"
              value={clickThru}
              onChange={(e) => setClickThru(e.target.value)}
              isValid={isValidClickThru}
              baseValues={['http://', 'https://']}
              maxLength={1001}
              popoverContent={(() => {
                if (!clickThru.includes('http'))
                  return (
                    <div className="text-warning-600">
                      URL requires &quot;http(s)://&quot;
                    </div>
                  )
                return (
                  <div className="text-warning-600">
                    Please enter a valid url in the format:
                    &quot;http://www.websitename.com&quot;
                  </div>
                )
              })()}
            />
          </ClickThruField>
          <FormItem
            htmlFor="callToAction"
            label="Call to action"
            required
            wrapperClassname="flex flex-col pt-2"
            errorMessage={ErrorMessage({
              fieldName: callToAction,
              max: 255,
              label: 'Call to action',
              isValid:
                validCheck(callToAction) &&
                isValidCharLength(callToAction, 255),
            })}
            valid={
              validCheck(callToAction) && isValidCharLength(callToAction, 255)
            }
            counter={callToAction.length > 0}
          >
            <TextInput
              value={callToAction}
              onChange={(e) => setCallToAction(e.target.value)}
              size="s"
            />
          </FormItem>
          <div className="flex justify-between pt-4">
            <Button
              size="l"
              rounded
              kind="default-grey"
              onClick={() => cancelEdit(false)}
            >
              Cancel
            </Button>
            <Button
              size="l"
              rounded
              kind="primary"
              onClick={() => {
                console.log('save changes')
              }}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}
