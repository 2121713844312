import { ReactNode } from 'react'
import './HeaderItem.scss'
import * as RRD from 'react-router-dom'

interface HeaderItemCommonPropsType
  extends React.HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  icon?: JSX.Element
}

interface HeaderItemWithLinkPropsType {
  to: string
  onClick?: undefined
}

interface HeaderItemWithOnClickPropsType {
  to?: undefined
  onClick: () => void
}

type HeaderItemPropsType = HeaderItemCommonPropsType &
  (HeaderItemWithLinkPropsType | HeaderItemWithOnClickPropsType)

export const HeaderItem = ({
  to,
  onClick,
  children,
  className = '',
  icon,
  ...other
}: HeaderItemPropsType) => {
  const { Link } = RRD

  if (to) {
    return (
      <Link className="HeaderItem" to={to}>
        {!!icon && (
          <span className={`${children ? 'HeaderItem__icon' : ''}`}>
            {icon}
          </span>
        )}
        {children}
      </Link>
    )
  }
  return (
    <button
      className={`HeaderItem ${className}`}
      type="button"
      onClick={
        onClick
          ? () => {
              onClick()
            }
          : undefined
      }
      {...other}
    >
      {!!icon && (
        <span className={`${children ? 'HeaderItem__icon' : ''}`}>{icon}</span>
      )}
      {children}
    </button>
  )
}
