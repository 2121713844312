import React, { FC } from 'react'
import './PageContent.scss'

interface PageContentProps extends React.HTMLProps<HTMLDivElement> {
  /** the children of the component */
  children?: React.ReactNode
  /** does card has rounded, shadow, default to yes */
  className?: string | undefined
}
// used as a section of a page, hence the HTML5 "section" tag
export const PageContent: FC<PageContentProps> = ({
  children,
  // use a meaningful page name for className, default is BasicPageContent
  className = 'BasicPageContent',
  ...other
}: PageContentProps) => {
  return (
    <>
      <section className={`PageContent ${className}`} {...other}>
        {children}
      </section>
    </>
  )
}
