/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1Column } from '../models/Targetjobservicev1Column';
import { V1ComponentResults } from '../models/V1ComponentResults';
import { V1JobType } from '../models/V1JobType';
import { HttpFile } from '../http/http';

/**
* Result is the result of a job when it finishes.
*/
export class V1Result {
    'jobType'?: V1JobType;
    /**
    * The number of rows in the initial uploaded target.
    */
    'submitted'?: number;
    /**
    * The number of rows that got results.
    */
    'matched'?: number;
    /**
    * The percentage of matches found in in initial uploaded file.
    */
    'matchRate'?: number;
    /**
    * The location of the selected file in s3.
    */
    'selectedFileLocation'?: string;
    /**
    * The location of the annotated file in s3.
    */
    'annotatedFileLocation'?: string;
    /**
    * How much of the process is completed.
    */
    'processCompleted'?: number;
    /**
    * The columns in the selected file.
    */
    'selectedColumns'?: Array<Targetjobservicev1Column>;
    /**
    * The columns in the annotated file.
    */
    'annotatedColumns'?: Array<Targetjobservicev1Column>;
    /**
    * Whether the file has a header or not.
    */
    'hasHeader'?: boolean;
    /**
    * The location of the validated file in s3.
    */
    'validatedFileLocation'?: string;
    'neighbors'?: number;
    'neighborsMatched'?: number;
    'neighborsToServe'?: number;
    'resultsFileLocation'?: string;
    /**
    * The columns in the results file.
    */
    'resultsColumns'?: Array<Targetjobservicev1Column>;
    'directMailFileLocation'?: string;
    'ips'?: number;
    'ipsMatched'?: number;
    'ipsToServe'?: number;
    /**
    * The location of the homes file in s3.
    */
    'homesFileLocation'?: string;
    'returnedIpCount'?: number;
    'devicesInHomesNotSeenFileLocation'?: string;
    'devicesWithoutHomesFileLocation'?: string;
    'devicesWithHomesFileLocation'?: string;
    'total'?: number;
    'devicesWithHomes'?: number;
    'totalHomes'?: number;
    'totalDeviceCount'?: number;
    'homeCount'?: number;
    'devicesInHomesNotSeen'?: number;
    'devicesWithoutHomes'?: number;
    'maidsFileLocation'?: string;
    'scriptPixel'?: string;
    'imagePixel'?: string;
    'matchBackFileLocation'?: string;
    /**
    * The location of the enriched file in s3.
    */
    'enrichedFileLocation'?: string;
    /**
    * The columns in the enriched file.
    */
    'enrichedColumns'?: Array<Targetjobservicev1Column>;
    /**
    * How many addressed were found in a zip job.
    */
    'addressesFound'?: number;
    /**
    * How many of the addresses that were found in the zipcodes resulted in matches.
    */
    'addressesMatched'?: number;
    /**
    * The number of rows in the initial uploaded target.
    */
    'recordsSubmitted'?: number;
    /**
    * The number of rows that got results.
    */
    'recordsMatched'?: number;
    /**
    * The number of rows generated off initial input.
    */
    'recordsGenerated'?: number;
    /**
    * The number of records generated from the initial records submitted from that got results.
    */
    'generatedRecordsMatched'?: number;
    'componentResults'?: V1ComponentResults;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "jobType",
            "baseName": "job_type",
            "type": "V1JobType",
            "format": ""
        },
        {
            "name": "submitted",
            "baseName": "submitted",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matched",
            "baseName": "matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "matchRate",
            "baseName": "match_rate",
            "type": "number",
            "format": "double"
        },
        {
            "name": "selectedFileLocation",
            "baseName": "selected_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "annotatedFileLocation",
            "baseName": "annotated_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "processCompleted",
            "baseName": "process_completed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "selectedColumns",
            "baseName": "selected_columns",
            "type": "Array<Targetjobservicev1Column>",
            "format": ""
        },
        {
            "name": "annotatedColumns",
            "baseName": "annotated_columns",
            "type": "Array<Targetjobservicev1Column>",
            "format": ""
        },
        {
            "name": "hasHeader",
            "baseName": "has_header",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "validatedFileLocation",
            "baseName": "validated_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "neighbors",
            "baseName": "neighbors",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "neighborsMatched",
            "baseName": "neighbors_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "neighborsToServe",
            "baseName": "neighbors_to_serve",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "resultsFileLocation",
            "baseName": "results_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "resultsColumns",
            "baseName": "results_columns",
            "type": "Array<Targetjobservicev1Column>",
            "format": ""
        },
        {
            "name": "directMailFileLocation",
            "baseName": "direct_mail_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "ips",
            "baseName": "ips",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "ipsMatched",
            "baseName": "ips_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "ipsToServe",
            "baseName": "ips_to_serve",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "homesFileLocation",
            "baseName": "homes_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "returnedIpCount",
            "baseName": "returned_ip_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "devicesInHomesNotSeenFileLocation",
            "baseName": "devices_in_homes_not_seen_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "devicesWithoutHomesFileLocation",
            "baseName": "devices_without_homes_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "devicesWithHomesFileLocation",
            "baseName": "devices_with_homes_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "total",
            "baseName": "total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "devicesWithHomes",
            "baseName": "devices_with_homes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalHomes",
            "baseName": "total_homes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "totalDeviceCount",
            "baseName": "total_device_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "homeCount",
            "baseName": "home_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "devicesInHomesNotSeen",
            "baseName": "devices_in_homes_not_seen",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "devicesWithoutHomes",
            "baseName": "devices_without_homes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "maidsFileLocation",
            "baseName": "maids_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "scriptPixel",
            "baseName": "script_pixel",
            "type": "string",
            "format": ""
        },
        {
            "name": "imagePixel",
            "baseName": "image_pixel",
            "type": "string",
            "format": ""
        },
        {
            "name": "matchBackFileLocation",
            "baseName": "match_back_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "enrichedFileLocation",
            "baseName": "enriched_file_location",
            "type": "string",
            "format": ""
        },
        {
            "name": "enrichedColumns",
            "baseName": "enriched_columns",
            "type": "Array<Targetjobservicev1Column>",
            "format": ""
        },
        {
            "name": "addressesFound",
            "baseName": "addresses_found",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "addressesMatched",
            "baseName": "addresses_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsSubmitted",
            "baseName": "records_submitted",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsMatched",
            "baseName": "records_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "recordsGenerated",
            "baseName": "records_generated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "generatedRecordsMatched",
            "baseName": "generated_records_matched",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "componentResults",
            "baseName": "component_results",
            "type": "V1ComponentResults",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Result.attributeTypeMap;
    }

    public constructor() {
    }
}

