export const creativeTypesEnum: { [key: string]: number } = {
  // Unspecified creative type
  CREATIVE_TYPE_UNSPECIFIED: 0,
  // Banner creative type
  CREATIVE_TYPE_BANNER: 3,
  // Native Banner creative type
  CREATIVE_TYPE_NATIVE_BANNER: 7,
  // Native Video creative type
  CREATIVE_TYPE_NATIVE_VIDEO: 9,
  // HTML creative type
  CREATIVE_TYPE_HTML5: 11,
  // Ad Tag Video creative type
  CREATIVE_TYPE_VAST_TAG: 13,
  // Ad Tag Banner creative type
  CREATIVE_TYPE_AD_TAG: 15,
  // OTT Video creative type
  CREATIVE_TYPE_OTT: 17,
  // Vast Audio creative type
  CREATIVE_TYPE_AUDIO: 20,
  // Vast Video creative type
  CREATIVE_TYPE_VIDEO: 22,
  // Direct Mail creative type
  CREATIVE_TYPE_MAIL_PIECE: 24,
}
export const creativeFileTypesEnum: { [key: string]: number } = {
  CREATIVE_FILE_TYPE_UNKNOWN: 0,
  CREATIVE_FILE_TYPE_MEDIA: 3,
  CREATIVE_FILE_TYPE_ALT_MEDIA: 5,
  CREATIVE_FILE_TYPE_THUMB: 7,
  CREATIVE_FILE_TYPE_HTML: 9,
  CREATIVE_FILE_TYPE_VAST: 11,
  CREATIVE_FILE_TYPE_JS: 13,
  CREATIVE_FILE_TYPE_CSS: 15,
  CREATIVE_FILE_TYPE_PDF: 17,
  CREATIVE_FILE_TYPE_ZIP: 19,
}
export const creativeStatusesEnum: { [key: string]: number } = {
  // UNSPECIFIED
  CREATIVE_STATUS_UNSPECIFIED: 0,
  // (-- COMMON RESTING STATUSS https://google.aip.dev/216#resting-statuss --)
  // ACCEPTED
  CREATIVE_STATUS_ACCEPTED: 1,
  // Creative is available for use
  CREATIVE_STATUS_ACTIVE: 2,
  // CANCELLED
  CREATIVE_STATUS_CANCELLED: 3,
  // DELETED
  CREATIVE_STATUS_DELETED: 4,
  // FAILED
  CREATIVE_STATUS_FAILED: 5,
  // SUCCEEDED
  CREATIVE_STATUS_SUCCEEDED: 6,
  // SUSPENDED
  CREATIVE_STATUS_SUSPENDED: 7,
  // VERIFIED
  CREATIVE_STATUS_VERIFIED: 8,
  // (-- COMMON ACTIVE STATUSS https://google.aip.dev/216#active-statuss --)
  // CREATING
  CREATIVE_STATUS_CREATING: 9,
  // DELETING
  CREATIVE_STATUS_DELETING: 10,
  // PENDING
  CREATIVE_STATUS_PENDING: 11,
  // REPAIRING
  CREATIVE_STATUS_REPAIRING: 12,
  // RUNNING
  CREATIVE_STATUS_RUNNING: 13,
  // SUSPENDING
  CREATIVE_STATUS_SUSPENDING: 14,
  // (-- CUSTOM RESTING STATUSS --)
  // Active & Approved
  CREATIVE_STATUS_APPROVED: 100,
  // Deleted from UI, but not Purging, still needed by OrderLine
  CREATIVE_STATUS_DISABLED: 101,
  // Stored to CDN, Prepare for Processing
  CREATIVE_STATUS_STORED: 102,
  // (-- CUSTOM ACTIVE STATUSS --)
  // Setup
  CREATIVE_STATUS_INITIALIZING: 200,
  // Validating and Post Processing File
  CREATIVE_STATUS_PROCESSING: 201,
  // Storing in Progress, Prepare for Processing
  CREATIVE_STATUS_STORING: 202,
  // Converting Video into standard formats/sizes
  CREATIVE_STATUS_TRANSCODING: 203,
  // Uploading in Progress
  CREATIVE_STATUS_UPLOADING: 204,
  // Building Video VAST.xml file
  CREATIVE_STATUS_VASTING: 205,
  // (-- CUSTOM STATUSS --)
  // Reviewing the Creative (manual)
  CREATIVE_STATUS_APPROVE: 301,
  // Invalid or bad Upload
  CREATIVE_STATUS_ERROR: 302,
  // Preparing for Processing
  CREATIVE_STATUS_PROCESS: 303,
  // Reviewing the Creative (automated)
  CREATIVE_STATUS_REVIEW: 304,
  // (-- ARCHIVE STATUSS --)
  // Creative is Archived
  CREATIVE_STATUS_ARCHIVED: 401,
  // Creative files are moved to Glacier and are not accessable in UI
  CREATIVE_STATUS_EXPIRED: 402,
  // Creative files have been deleted
  CREATIVE_STATUS_PURGED: 403,
  // Creative is Unarchiving
  CREATIVE_STATUS_UNARCHIVING: 404,
}
export const creativeAdTypesEnum: { [key: string]: number } = {
  // Unspecified creative ad type
  CREATIVE_ADTYPE_UNSPECIFIED: 0,
  // Banner creative ad type
  // Creative types included: CREATIVE_TYPE_BANNER, CREATIVE_TYPE_AD_TAG, AND CREATIVE_TYPE_HTML5
  CREATIVE_ADTYPE_BANNER: 3,
  // Video creative ad type
  // Creative types included: CREATIVE_TYPE_VIDEO, CREATIVE_TYPE_VAST_TAG
  CREATIVE_ADTYPE_VIDEO: 5,
  // Native creative ad type
  // Creative types included: CREATIVE_TYPE_NATIVE_BANNER, CREATIVE_TYPE_NATIVE_VIDEO
  CREATIVE_ADTYPE_NATIVE: 7,
  // OTT creative ad type
  // Creative types included: CREATIVE_TYPE_OTT
  CREATIVE_ADTYPE_OTT: 9,
}
export const imageMimes = [
  'bmp',
  'gif',
  'ico',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'tif',
  'tiff',
  'webp',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/zip',
]

export const videoMimes = [
  'avi',
  'mp4',
  'mpeg',
  'ogv',
  'ts',
  'webm',
  '3gp',
  '3g2',
  'video/avi',
  'video/mp4',
  'video/mpeg',
  'video/ogv',
  'video/ts',
  'video/webm',
  'video/3gp',
  'video/3g2',
  'video/quicktime',
]
export const audioMimes = [
  'aac',
  'mid',
  'midi',
  'mp3',
  'oga',
  'opus',
  'wav',
  'weba',
]
