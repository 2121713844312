/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Fields required on Political Orderlines
*/
export class V1PoliticalFields {
    'orgZip'?: string;
    'submitterName'?: string;
    'submitterAddress'?: string;
    'submitterCity'?: string;
    'submitterState'?: string;
    'submitterZip'?: string;
    'submitterCountry'?: string;
    'audienceIdsTargeted'?: string;
    'audienceDescription'?: string;
    'paidForBy'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orgZip",
            "baseName": "org_zip",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterName",
            "baseName": "submitter_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterAddress",
            "baseName": "submitter_address",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterCity",
            "baseName": "submitter_city",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterState",
            "baseName": "submitter_state",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterZip",
            "baseName": "submitter_zip",
            "type": "string",
            "format": ""
        },
        {
            "name": "submitterCountry",
            "baseName": "submitter_country",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceIdsTargeted",
            "baseName": "audience_ids_targeted",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceDescription",
            "baseName": "audience_description",
            "type": "string",
            "format": ""
        },
        {
            "name": "paidForBy",
            "baseName": "paid_for_by",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1PoliticalFields.attributeTypeMap;
    }

    public constructor() {
    }
}

