import classNames from 'classnames'
import { CopyButton } from 'Components/CopyButton'

export const PermalinkButton = ({
  url,
  className,
}: {
  url: string
  className?: string
}) => {
  return (
    <CopyButton
      text={`${window.location.origin}${url}`}
      icon="link"
      iconCopied="copy"
      type="permalink"
      className={classNames('PermalinkButton', className)}
    />
  )
}
