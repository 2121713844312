import { Button, FlexBox, Tabs, Spacer, Text, Fa } from '@eltoro-ui/components'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  allBlocksOfKindSelector,
  selectedBlockIdsAtom,
  selectedKindAtom,
  removeBlocksOfKindSelector,
  blockTextOverflowErrorSelector,
} from 'State'
import { blocksBase } from 'Data'
import {
  EditMainFeatures,
  EditShapeContents,
  EditTextContents,
  EditTextValues,
  SelectionTable,
  BlockQuickSelect,
  BlockSelectPopover,
  PhotoEdit,
  EditScale,
} from './components'
import './EditBlock.scss'

export const EditBlock: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const setSelectedBlockIds = useSetRecoilState(selectedBlockIdsAtom)
  const hasOverflowError = useRecoilValue(blockTextOverflowErrorSelector)
  const [selectedKind, setSelectedKind] = useRecoilState(selectedKindAtom)
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector)
  const handleRemoveBlocks = useSetRecoilState(removeBlocksOfKindSelector)

  const ids = allBlocksOfKind?.map((b) => b.id)

  // by default all of kind are selected
  useEffect(() => {
    if (ids) setSelectedBlockIds(ids)
  }, [selectedKind])

  const splitByCapital = (value: string) => {
    const split = value.split(/(?=[A-Z])/).join(' ')
    return split.charAt(0).toUpperCase() + split.slice(1)
  }

  const handleClose = () => {
    setSelectedKind(undefined)
    onClose()
  }

  const handleRemoveBlock = () => {
    handleRemoveBlocks([])
    handleClose()
  }

  if (!selectedKind) return null
  const { textContent, shapeContent, label } = blocksBase[selectedKind]
  const showPhotoEdit = selectedKind === 'imageFrame'
  return (
    <div className="EditBlock">
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center">
          <Text on="white" size="l" weight="bold">
            {label}
          </Text>
          <Spacer width="0.25rem" />
          <BlockQuickSelect label={`${label} Options`} />
        </FlexBox>
        <Button
          onClick={handleClose}
          kind="text"
          iconLeft={<Fa icon="times" size={1} />}
        />
      </FlexBox>
      <hr />
      <Spacer height="0.5rem" />
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexBox alignItems="center">
          <Text on="white" size="l" weight="bold">
            Settings
          </Text>
          <Spacer width="0.25rem" />
          <BlockSelectPopover />
        </FlexBox>
      </FlexBox>
      <Spacer />
      <Tabs
        on="white"
        tabs={[
          {
            id: 'content',
            label: (
              <FlexBox>
                <Fa size={1} icon="pencil-alt" />
                <Spacer width="0.5rem" />
                Content
              </FlexBox>
            ),
            component: (
              <>
                <Spacer />
                {Object.keys(textContent).map((textKey) => (
                  <EditTextValues
                    key={textKey}
                    label={splitByCapital(textKey)}
                    textKey={textKey}
                  />
                ))}
                <hr />
                {showPhotoEdit && ids && (
                  <>
                    <Spacer />
                    <PhotoEdit ids={ids} />
                  </>
                )}
                <Spacer />
                <EditMainFeatures />
                <Spacer />
                <EditScale />
              </>
            ),
          },
          {
            id: 'style',
            label: (
              <div
                className={`EditBlock__tab ${
                  hasOverflowError ? 'EditBlock__tab--has-error' : ''
                }`}
              >
                <FlexBox>
                  <Fa
                    size={1}
                    icon={
                      hasOverflowError
                        ? 'exclamation-triangle'
                        : 'star-half-alt'
                    }
                  />
                  <Spacer width="0.5rem" />
                  Style
                </FlexBox>
              </div>
            ),
            component: (
              <>
                <Spacer />
                {Object.keys(textContent).map((textKey) => (
                  <EditTextContents
                    key={textKey}
                    label={splitByCapital(textKey)}
                    textKey={textKey}
                  />
                ))}
                <Spacer />
                {shapeContent &&
                  Object.keys(shapeContent).map((shapeKey) => (
                    <EditShapeContents
                      key={shapeKey}
                      label={splitByCapital(shapeKey)}
                      shapeKey={shapeKey}
                    />
                  ))}
                <Spacer />
              </>
            ),
          },
          {
            id: 'advanced',
            label: (
              <FlexBox>
                <Fa size={1} icon="cog" />
                <Spacer width="0.5rem" />
                Advanced
              </FlexBox>
            ),
            component: (
              <>
                <Spacer />
                <SelectionTable />
                <Spacer />
                <Button onClick={handleRemoveBlock} kind="danger" width="100%">
                  {`Remove ${label}`}
                </Button>
              </>
            ),
          },
        ]}
      />
    </div>
  )
}
