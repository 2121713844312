import { poll } from 'Helpers'
import { ApiFactory } from 'apiFactory'
import {
  Targetjobservicev1Audience,
  Targetjobservicev1Timeframe,
  V1AudienceType,
  V1DataSource,
} from 'next-gen-sdk'
import { getApiConfig } from './getApiConfig'
import { showErrorMessage } from '@eltoro-ui/components'

export const createAndGenerateAudience = async (
  orgId: string,
  type: V1AudienceType,
  targetId: string,
  name: string,
  hidden?: boolean,
  dataSource?: V1DataSource,
  timeframes?: Targetjobservicev1Timeframe[],
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { audienceServiceAPI } = apis
  if (!audienceServiceAPI) return
  const job = await audienceServiceAPI?.advertisingPlatformServiceCreateAudience(
    orgId,
    {
      name,
      type,
      targetId,
      dataSource,
      hidden,
      // Devices for households are always locked on creation
      // https://eltorocorp.atlassian.net/browse/NGBR-229?focusedCommentId=121377
      locked: type === 'AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS' ? true : false,
      timeframes,
    },
  )
  const validate = (aud?: Targetjobservicev1Audience) =>
    aud?.status !== 'AUDIENCE_STATUS_CREATED'

  const createdAudience = await poll(
    (token) =>
      audienceServiceAPI?.advertisingPlatformServiceGetAudience(
        job.id || '',
        orgId,
        token ? getApiConfig(token) : undefined,
      ),
    validate,
    1000,
    900000,
  )
  if (
    !createdAudience ||
    createdAudience.status !== 'AUDIENCE_STATUS_CREATED'
  ) {
    showErrorMessage('Error creating audience', '')
    return undefined
  }

  return createdAudience
}
