/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* For a line item with the goal_type \"cpc\" or \"cpa\", the performance goal threshold, which determines the bid/no bid cutoff on optimized line items, and the performance goal target, which represents the desired clicks.
*/
export class V3Valuation {
    /**
    * Only set this field if you have set prefer_delivery_over_performance to false and revenue_type has not been set to cost_plus_margin. Set this to your desired minimum margin (e.g., 10 for 10%). This may cause your delivery and performance to be deprioritized.
    */
    'minMarginPct'?: number;
    /**
    * Unable to find in their documentation. Must be implicitly set.
    */
    'bidPricePacingEnabled'?: boolean;
    /**
    * Unable to find in their documentation. Must be implicitly set.
    */
    'bidPricePacingLever'?: number;
    /**
    * For legacy line items, and ALIs have adaptive pacing so they're typically defaulted to false, 0, and false.
    */
    'noRevenueLog'?: boolean;
    /**
    * The performance goal target is a required value when you are optimizing to a CTR, CPC goal, CPA goal, or Viewable CPM goal
    */
    'goalTarget'?: number;
    /**
    * The performance goal threshold determines bidding, inventory discovery, and the bid/no bid check on optimized line items
    */
    'goalThreshold'?: number;
    /**
    * The value below which the average CPM may not fall
    */
    'minAvgCpm'?: number;
    /**
    * The value above which the average CPM may not rise
    */
    'maxAvgCpm'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "minMarginPct",
            "baseName": "min_margin_pct",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "bidPricePacingEnabled",
            "baseName": "bid_price_pacing_enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "bidPricePacingLever",
            "baseName": "bid_price_pacing_lever",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "noRevenueLog",
            "baseName": "no_revenue_log",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "goalTarget",
            "baseName": "goal_target",
            "type": "number",
            "format": "double"
        },
        {
            "name": "goalThreshold",
            "baseName": "goal_threshold",
            "type": "number",
            "format": "double"
        },
        {
            "name": "minAvgCpm",
            "baseName": "min_avg_cpm",
            "type": "number",
            "format": "double"
        },
        {
            "name": "maxAvgCpm",
            "baseName": "max_avg_cpm",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return V3Valuation.attributeTypeMap;
    }

    public constructor() {
    }
}

