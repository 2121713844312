import { Toggle } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'

export const PoliticalToggle = ({
  isChecked,
  onChange,
}: {
  isChecked: boolean
  onChange: (value: boolean) => void
}) => {
  return (
    <div className="PoliticalToggle flex items-center gap-4">
      <ETIcon icon="advocacy" />
      <Toggle
        size="m"
        isChecked={isChecked}
        onChange={onChange}
        label={`${
          isChecked ? 'Hide ' : 'Show '
        }Limited Inventory(Political) CPM Pricing`}
      />
    </div>
  )
}
