import React, { useEffect, useState } from 'react'
import { V1Rule, V1RuleSet } from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { TextInput } from '@eltoro-ui/components'
import classNames from 'classnames'
import './RuleSetContainer.scss'

const Container = styleTailwind('div', 'RuleSetContainer bg-grey-50 p-4')
const FormLabel = styleTailwind('label', 'flex flex-col items-start max-w-2xl')
const NumberInput = styleTailwind(
  'input',
  'TextInput border-thin border-grey-400 mb-4 focus:outline-none',
)
// Break up comma-seperated terms
const commaRegEx = /\s*(?:,|$)\s*/

type RuleSetTemplateType = {
  setRuleSets: React.Dispatch<React.SetStateAction<(V1Rule | V1RuleSet)[]>>
}

const VisitorsWhoLeft: React.FC<RuleSetTemplateType> = ({ setRuleSets }) => {
  // end result
  const [matches, setMatches] = useState<string>('')
  const [exclusions, setExclusions] = useState<string>('')

  useEffect(() => {
    let newMatchRuleSet: V1Rule[] = []
    let newExcludeRuleSet: V1Rule[] = []

    if (matches) {
      const matchArray = matches.split(commaRegEx).filter((n) => n)
      newMatchRuleSet = matchArray.map((matchTerm) => {
        return { term: `.*${matchTerm}.*`, type: 'regex' }
      })
    }
    if (exclusions) {
      const excludeArray = exclusions.split(commaRegEx).filter((n) => n)
      newExcludeRuleSet = excludeArray.map((excludeTerm) => {
        return { term: `.*${excludeTerm}.*`, type: 'regex' }
      })
    }
    // Create rule set information
    const skeletonRuleSet: V1RuleSet[] = [
      ...(newMatchRuleSet.length
        ? [
            {
              operator: 'or',
              rules: newMatchRuleSet,
            },
          ]
        : []),
      ...(newExcludeRuleSet.length
        ? [
            {
              operator: 'not',
              rules: newExcludeRuleSet,
            },
          ]
        : []),
    ]
    setRuleSets(skeletonRuleSet)
  }, [matches, exclusions])

  return (
    <Container>
      <p className="mb-2">
        Use the &apos;<strong>Match All of the Following</strong>&apos; to
        specify the pages a user may land on while navigating to your
        website&apos;s shopping cart. Use the &apos;
        <strong>Exclusion Set</strong>&apos; function to specify the page(s) a
        user lands on who are successful in their purchases.
      </p>
      <p className="mb-4">
        Instructions: If the website page is www.yourwebsite.com/cart, enter{' '}
        <span className="font-mono">cart</span>
      </p>
      <FormLabel htmlFor="match">
        <p>
          <strong>Match</strong> any one of the following, seperate with comma
        </p>
      </FormLabel>
      <TextInput
        id="match"
        placeholder="Example: cart, check-out"
        value={matches}
        onChange={(e) => setMatches(e.target.value)}
        className="mb-4 max-w-xl"
      />
      <FormLabel htmlFor="exclude">
        <p>
          <strong>Exclude</strong> each of the following, seperate with comma
        </p>
      </FormLabel>
      <TextInput
        id="exclude"
        placeholder="Example: coupon, anniversary-sale"
        value={exclusions}
        onChange={(e) => setExclusions(e.target.value)}
        className="mb-4 max-w-xl"
      />
      <p>
        Tip: Target anyone that lands on your cart page, checkout page, delivery
        info page, or the payment page and exclude if they landed on the order
        confirmation page. Need more help?{' '}
        <a
          href="https://eltoro.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >
          Review this article
        </a>
        .
      </p>
    </Container>
  )
}

const KeywordTargeting: React.FC<RuleSetTemplateType> = ({ setRuleSets }) => {
  const [keywords, setKeywords] = useState<string>('')

  useEffect(() => {
    let newKeywordRuleSet: V1Rule[] = []
    if (keywords) {
      const keywordArray = keywords.split(commaRegEx).filter((n) => n)
      newKeywordRuleSet = keywordArray.map((keyword) => {
        return { term: `.*${keyword}.*`, type: 'regex' }
      })
    }
    // Create rule set information
    const skeletonRuleSet: V1RuleSet[] = [
      ...(newKeywordRuleSet.length
        ? [
            {
              operator: 'and',
              rules: newKeywordRuleSet,
            },
          ]
        : []),
    ]
    setRuleSets(skeletonRuleSet)
  }, [keywords])

  return (
    <Container>
      <p className="mb-4 font-bold">
        Capture all users who land on a page containing a specific keyword
      </p>
      <FormLabel htmlFor="keywords">
        <p>
          Enter your <strong>keywords</strong> below, seperate with a comma
        </p>
      </FormLabel>
      <TextInput
        id="keywords"
        placeholder="Example: cart, yourwebsite.com/bicycle"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        className="mb-4 max-w-xl"
      />
      <p>
        Tip: Capture visitors to any webpage that has &quot;bicycle&quot; in the
        URL by entering <span className="font-mono">bicycle</span>
        <br />
        To capture visitors that went to a certain path that has variances in
        the string or unique identifiers associated with it, enter{' '}
        <span className="font-mono">yourwebsite.com/bicycle</span> Need more
        help?{' '}
        <a
          href="https://eltoro.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >
          Review this article
        </a>
        .
      </p>
    </Container>
  )
}

const VisitsToSite: React.FC<RuleSetTemplateType> = ({ setRuleSets }) => {
  const [minimum, setMinimum] = useState<number>()
  const [maximum, setMaximum] = useState<number>()
  const [maxError, setMaxError] = useState(false)

  useEffect(() => {
    if (maximum && minimum && maximum < minimum) {
      setMaxError(true)
    }
    // Create rule set information
    const skeletonRuleSet: V1RuleSet[] = [
      {
        operator: 'and',
        rules: [
          {
            type: 'regex',
            term: '.*',
            minimum,
            maximum,
          },
        ],
      },
    ]
    if (minimum || maximum) setRuleSets(skeletonRuleSet)
  }, [minimum, maximum])

  return (
    <Container>
      <p className="font-bold">
        Capture all users that visit the pixeled website a specified number of
        times
      </p>
      <p className="mb-4 font-light">
        Need more help?{' '}
        <a
          href="https://eltoro.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >
          Review this article
        </a>
        .
      </p>
      <FormLabel htmlFor="max">
        <p>
          <strong>Maximum</strong> number of visits{' '}
          {maxError && (
            <>
              {' '}
              -{' '}
              <span className="text-danger text-xs">
                Minimum cannot be greater than the maximum
              </span>
            </>
          )}
        </p>
      </FormLabel>
      <NumberInput
        id="max"
        type="number"
        placeholder="Example: 10"
        value={maximum?.toString()}
        min={0}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setMaxError(false)
          setMaximum(Number(e.target.value))
        }}
        className={classNames({
          'VisitsToSite__NumberInput--error border-danger': maxError,
        })}
      />
      <FormLabel htmlFor="min">
        <p>
          <strong>Minimum</strong> number of visits
        </p>
      </FormLabel>
      <NumberInput
        id="min"
        type="number"
        placeholder="Example: 2"
        value={minimum?.toString()}
        min={0}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setMaxError(false)
          setMinimum(Number(e.target.value))
        }}
      />
    </Container>
  )
}

const AdvancedOptions: React.FC<RuleSetTemplateType> = ({ setRuleSets }) => {
  return (
    <Container>
      <p>
        Full editor. Need more help?{' '}
        <a
          href="https://eltoro.zendesk.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold"
        >
          Review this article
        </a>
        .
      </p>
      (coming soon)
    </Container>
  )
}

export const RuleSetContainer: React.FC<{
  type:
    | 'visitors_who_left'
    | 'keyword_targeting'
    | 'visits_to_site'
    | 'advanced_options'
  onChange: (ruleSets: Array<V1Rule | V1RuleSet>) => void
}> = ({ type, onChange }) => {
  const [ruleSets, setRuleSets] = useState<Array<V1RuleSet>>([])

  useEffect(() => {
    onChange(ruleSets)
  }, [ruleSets])

  if (type === 'visitors_who_left') {
    return <VisitorsWhoLeft setRuleSets={setRuleSets} />
  }
  if (type === 'keyword_targeting') {
    return <KeywordTargeting setRuleSets={setRuleSets} />
  }
  if (type === 'visits_to_site') {
    return <VisitsToSite setRuleSets={setRuleSets} />
  }
  if (type === 'advanced_options') {
    // TODO: Implement advanced regex rule set component
    return <AdvancedOptions setRuleSets={setRuleSets} />
  }
  return null
}
