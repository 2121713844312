import { logHelper } from '../logger'

const logger = logHelper(process.env.NODE_ENV, 'browser', '')
// From https://rgxdb.com/
const ISO8601Regex = /^(?:[\\+-]?\d{4}(?!\d{2}\b))(?:(-?)(?:(?:0[1-9]|1[0-2])(?:\1(?:[12]\d|0[1-9]|3[01]))?|W(?:[0-4]\d|5[0-2])(?:-?[1-7])?|(?:00[1-9]|0[1-9]\d|[12]\d{2}|3(?:[0-5]\d|6[1-6])))(?:[T\s](?:(?:(?:[01]\d|2[0-3])(?:(:?)[0-5]\d)?|24\\:?00)(?:[\\.,]\d+(?!:))?)?(?:\2[0-5]\d(?:[\\.,]\d+)?)?(?:[zZ]|(?:[\\+-])(?:[01]\d|2[0-3]):?(?:[0-5]\d)?)?)?)?$/

const isValidDateInput = (dateInput: string | Date, functionName: string) => {
  if (
    typeof dateInput === 'string' &&
    (!Date.parse(dateInput) || !ISO8601Regex.test(dateInput))
  ) {
    logger.error(
      `${functionName}: Date string '${dateInput}' cannot be parsed, or is not in a valid ISO 8601 format.`,
    )
    return false
  }
  if (dateInput instanceof Date && Number.isNaN(dateInput.getTime())) {
    logger.error(`${functionName}: Date is not valid.`)
    return false
  }
  return true
}

export const isToday = (comparedDate: Date) => {
  const today = new Date()
  return (
    comparedDate.getDate() === today.getDate() &&
    comparedDate.getMonth() === today.getMonth() &&
    comparedDate.getFullYear() === today.getFullYear()
  )
}

export const getDateBreakdown = (dateInput: string | Date) => {
  if (isValidDateInput(dateInput, 'getLocalDateTime')) {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput

    const hour = date.getHours()
    const hourStandard = (date.getHours() + 24) % 12 || 12
    const minute = +(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    const second = +(date.getSeconds() < 10 ? '0' : '') + date.getSeconds()

    const month = date.getMonth() + 1
    const monthLong = date.toLocaleString('default', { month: 'long' })
    const monthShort = date.toLocaleString('default', { month: 'short' })
    const year = date.getFullYear()
    const day = date.getDate()

    return {
      isToday: isToday(date),
      month,
      monthLong,
      monthShort,
      date: day,
      year,
      yearShort: +year.toString().substr(-2),
      hour,
      hourStandard,
      minute,
      second,
    }
  }
  return null
}

type DateInputType = string | Date

export const compareDates = (
  dateInput: DateInputType,
  secondDate?: DateInputType,
) => {
  let timeObject = {
    years: 0,
    months: 0,
    days: 0,
    weeks: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  const checkSecondDate = secondDate
    ? isValidDateInput(secondDate, 'compareDates')
    : true

  if (isValidDateInput(dateInput, 'compareDates') && checkSecondDate) {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
    const today = new Date()

    const hasOneDate = !secondDate

    let diff: number
    let originalDiff: number

    if (hasOneDate) {
      originalDiff = +today - +date
      diff = Math.abs((+today - +date) / 1000)
    } else {
      const date2 =
        typeof secondDate === 'string' ? new Date(secondDate) : dateInput
      originalDiff = +date - +date2
      diff = Math.abs((+date - +date2) / 1000)
    }

    if (diff === 0)
      return { ...timeObject, toString: () => (hasOneDate ? 'Just now' : '') }

    const timeUnitMap = {
      years: 31536000,
      months: 2592000,
      weeks: 604800,
      days: 86400,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    }

    // Iterate over all units, get value and add to timeObject, subtract from diff
    Object.entries(timeUnitMap).forEach((unit) => {
      const result = Math.floor(diff / unit[1])
      timeObject = { ...timeObject, [unit[0]]: result }
      diff -= result * unit[1]
    })

    const toString = (type?: 'long' | 'short') => {
      const timeUnits = Object.keys(timeUnitMap) as Array<
        keyof typeof timeUnitMap
      >
      // find the first key in timeUnitMap that has a value in timeObject (preserving order)
      const keyWithHighestPriority = timeUnits.find(
        (key) => timeObject[key] !== 0,
      )

      if (!keyWithHighestPriority) return 'Just now'

      if (keyWithHighestPriority) {
        if (type === 'short') {
          const abbr =
            keyWithHighestPriority === 'months'
              ? 'mo'
              : keyWithHighestPriority.slice(0, 1)
          return originalDiff < 0 && !secondDate
            ? ''
            : `${timeObject[keyWithHighestPriority]}${abbr}`
        }
        // if long/default
        return `${originalDiff < 0 && hasOneDate ? 'In ' : ''}${
          timeObject[keyWithHighestPriority]
        } ${
          timeObject[keyWithHighestPriority] === 1
            ? keyWithHighestPriority.slice(0, -1)
            : keyWithHighestPriority
        }${originalDiff > 0 && hasOneDate ? ' ago' : ''}`
      }
      return ''
    }
    return {
      ...timeObject,
      toString,
    }
  }
  return null
}

// Expiry date in terms of credit card- expires after month has passed
export const isValidExpiryDate = (expiry: string) => {
  const regex = /^(0[1-9]|1[0-2])\/?[0-9]{2}$/ // String needs to be MM/YY
  if (!regex.test(expiry)) {
    logger.error(
      `isValidExpiryDate: ${expiry} is an invalid date. Please use MM/YY format.`,
    )
    return false
  }
  const maxYear = new Date().getFullYear() + 5
  const maxYearValue = Number(maxYear.toString().slice(-2))
  const currentMonth = new Date().getMonth() + 1
  const currentYear = Number(new Date().getFullYear().toString().substr(-2))
  const expirySplit = expiry.split('/')
  const month = Number(expirySplit[0])
  const year = Number(expirySplit[1])
  const result =
    month > 0 &&
    month < 13 &&
    year < maxYearValue &&
    !(year < currentYear) &&
    (year > currentYear || (year === currentYear && month >= currentMonth))

  return result
}
