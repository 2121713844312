import React, { useRef } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { Button, Fa, Popover } from '@eltoro-ui/components'
import { useNavigate, useLocation } from 'react-router-dom'
import { styleTailwind } from 'Helpers'
import { useGlobalSearchQuery } from 'Hooks'

const MainHeaderMenuItem = styleTailwind(
  Button,
  'bg-gray text-primary py-2 hover:bg-grey-100 hover:text-primary-600',
)

export type SearchType =
  | 'all'
  | 'creatives'
  | 'audiences'
  | 'order lines'
  | 'orgs'

export const MainHeaderSearchMenu: React.FC<{
  localMenu: boolean
  setLocalMenu: React.Dispatch<React.SetStateAction<boolean>>
  localSearchType: SearchType
  setLocalSearchType: React.Dispatch<React.SetStateAction<SearchType>>
  setShowAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}> = ({
  localMenu,
  setLocalMenu,
  localSearchType,
  setLocalSearchType,
  setShowAdvancedSearch,
  disabled,
}) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([menuRef, menuButtonRef], () => {
    setLocalMenu(false)
  })
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useGlobalSearchQuery()

  const handleSearchTypeChange = (type: SearchType) => {
    setLocalMenu(false)
    setLocalSearchType(type)

    // if already on search page, reload
    if (pathname.includes('/search')) {
      const newQuery = new URLSearchParams()
      const currentKeyword = query.get('keyword')
      if (currentKeyword) newQuery.set('keyword', currentKeyword)

      if (type === 'all') {
        const allInclusions = ['audiences', 'orgs', 'creatives', 'order lines']
        allInclusions.forEach((inc) => newQuery.append('include', inc))
      }
      if (type === 'audiences') newQuery.append('include', 'audiences')
      if (type === 'creatives') newQuery.append('include', 'creatives')
      if (type === 'order lines') newQuery.append('include', 'order lines')
      if (type === 'orgs') newQuery.append('include', 'orgs')

      navigate(`/search?${newQuery}`)
    }
  }
  return (
    <Popover
      isOpen={localMenu}
      content={
        <div ref={menuRef} className="bg-base grid gap-2 p-2">
          <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="check-double" size={1} type="duotone" />}
            buttonRef={menuButtonRef}
            onClick={() => handleSearchTypeChange('all')}
          >
            All
          </MainHeaderMenuItem>
          <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="user-group" size={1} type="duotone" />}
            buttonRef={menuButtonRef}
            onClick={() => handleSearchTypeChange('audiences')}
          >
            Audiences
          </MainHeaderMenuItem>
          <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="images" size={1} type="duotone" />}
            buttonRef={menuButtonRef}
            onClick={() => handleSearchTypeChange('creatives')}
          >
            Creatives
          </MainHeaderMenuItem>
          <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="file-invoice" size={1} type="duotone" />}
            buttonRef={menuButtonRef}
            onClick={() => handleSearchTypeChange('order lines')}
          >
            Order Lines
          </MainHeaderMenuItem>
          <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="briefcase" size={1} type="duotone" />}
            buttonRef={menuButtonRef}
            onClick={() => handleSearchTypeChange('orgs')}
          >
            Orgs
          </MainHeaderMenuItem>
          {/* 3/13/23 Hiding for now- we do not have the functionality to search outside of keyword currently */}
          {/* https://eltoroteam.slack.com/archives/C029B5WPMU3/p1678724168348629?thread_ts=1678724066.944199&cid=C029B5WPMU3 */}
          {/* <MainHeaderMenuItem
            kind="text"
            size="s"
            iconLeft={<Fa icon="sliders" size={1} type="duotone" />}
            iconRight={<Fa icon="caret-right" size={1} />}
            buttonRef={menuButtonRef}
            onClick={() => {
              setLocalMenu(false)
              setShowAdvancedSearch(true)
            }}
          >
            Advanced Search
          </MainHeaderMenuItem> */}
        </div>
      }
      align="start"
      position={['bottom']}
    >
      <div className="flex h-full items-center">
        <button
          type="button"
          className="flex h-full items-center justify-center gap-0 whitespace-nowrap pl-2"
          onClick={() => setLocalMenu(true)}
          disabled={disabled}
        >
          <span className="text-s">
            {localSearchType.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase(),
            )}
          </span>
          <Fa icon="caret-down" size={1} type="duotone" />
        </button>
        <div className="border-r-1 border-grey-100 h-5 border-r" />
      </div>
    </Popover>
  )
}
