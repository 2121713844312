import React, { useState } from 'react'
import { Button, Fa, Text } from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import { Creativeservicev1Creative, V1CreativeFile } from 'next-gen-sdk'
import { readableCreativeType, DurationOrDimensionDetail } from 'Helpers'
import { ETIcon } from 'Assets'
import {
  CreativeThumbnail,
  CreativePreview,
  InfoPopover,
  BrandURLPop,
} from 'Components'
import './AdSetPreview.scss'
import { useValidatedState } from '@eltoro-ui/hooks'
import { useAppContext } from 'Contexts'
import { ClickThroughWithUTM } from 'Components/AdSetForms/components/ClickThroughWithUTM'

export const AdSetPreview: React.FC<{
  creativeItem: Creativeservicev1Creative
  onRemove: (creativeID: string) => void
  showBrand?: boolean
}> = ({ creativeItem, onRemove, showBrand }) => {
  const { creativeServiceApi, currentOrg } = useAppContext()
  const [brandUrl, setBrandUrl, isValidBrandURL] = useValidatedState(
    'https://',
    'url',
  )
  const [savedBrandUrl, setSavedBrandUrl] = useState(false)
  // prettier-ignore
  const [
    previewedFile,
    setPreviewedFile,
  ] = useState<V1CreativeFile>()

  const getThumbnail = (file: V1CreativeFile) => {
    return (
      <CreativeThumbnail
        key={file.id}
        file={file}
        onClick={() => setPreviewedFile(file)}
      />
    )
  }
  return (
    <>
      <div className="AdSetPreview">
        <div className="AdSetPreview__Button-wrap relative flex flex-row-reverse">
          <Button
            className="AdSetPreview__Button"
            kind="text"
            onClick={() => onRemove(creativeItem.id as string)}
            iconOnly={<Fa icon="times" size={1} />}
          />
        </div>
        <div className="AdSetPreview__thumbnail flex h-full items-center justify-center">
          {creativeItem.type !== 'CREATIVE_TYPE_HTML5' &&
          creativeItem.files &&
          creativeItem.files?.length > 0 ? (
            creativeItem.files?.map((file) => getThumbnail(file))
          ) : (
            <>
              {(creativeItem.type === 'CREATIVE_TYPE_AD_TAG' && (
                <ETIcon icon="ad-tag" />
              )) ||
                (creativeItem.type === 'CREATIVE_TYPE_VAST_TAG' && (
                  <ETIcon icon="vast-tag" />
                )) ||
                (creativeItem.type === 'CREATIVE_TYPE_HTML5' && (
                  <ETIcon icon="html5" />
                ))}
            </>
          )}
        </div>
        <div className="flex items-end justify-between">
          <div className="AdSetPreview__info">
            <span className="AdSetPreview__detail flex gap-1">
              <span className="font-bold">Name:</span>
              <span className="break-words pb-1 leading-none">
                {creativeItem.name || 'No name'}
              </span>
            </span>
            {creativeItem.type && (
              <span className="AdSetPreview__detail">
                <strong>Type:</strong>
                <span className="break-words leading-none">
                  {readableCreativeType(creativeItem.type)}
                </span>
              </span>
            )}
            {creativeItem.files?.[0]?.size && (
              <span className="AdSetPreview__detail flex gap-1">
                <strong>File Size:</strong>
                <span className="flex gap-2">
                  {formatByteString(Number(creativeItem.files[0].size))}
                  {creativeItem.type === 'CREATIVE_TYPE_BANNER' &&
                    creativeItem.files?.[0].size &&
                    +creativeItem.files[0].size > 100000 && (
                      <InfoPopover
                        icon="warning"
                        content={
                          <div className="AdSetPreview__file-size-popover max-w-sm">
                            <Text>
                              Your creative file size is above our recommended
                              specifications. This <strong>may</strong> limit
                              our serving capacity.
                            </Text>
                            <br />
                            <Text>
                              <strong>Recommended banner file size: </strong>{' '}
                              less than 100kb
                            </Text>
                          </div>
                        }
                      />
                    )}
                </span>
              </span>
            )}
            <span className="AdSetPreview__detail flex">
              {DurationOrDimensionDetail(creativeItem, true)}
            </span>
            {showBrand && (
              <span className="AdSetPreview__detail brandUrl flex pt-2">
                <BrandURLPop />
                {savedBrandUrl ? (
                  <span>{brandUrl}</span>
                ) : (
                  <ClickThroughWithUTM
                    OLName={''}
                    clickThru={brandUrl}
                    setClickThru={setBrandUrl}
                    isValidClickThru={isValidBrandURL}
                    brand
                    preview
                  />
                )}
                {isValidBrandURL && (
                  <span className="m-auto pl-1">
                    <Button
                      className="Button"
                      rounded
                      iconOnly={
                        <span
                          className="Tooltip-css-wrap"
                          data-tooltip="left"
                          aria-label={'Enter Brand Url'}
                        >
                          {isValidBrandURL && (
                            <Fa
                              icon={!savedBrandUrl ? 'save' : 'pencil'}
                              size={1}
                            />
                          )}
                        </span>
                      }
                      onClick={() => {
                        if (
                          isValidBrandURL &&
                          currentOrg?.id &&
                          creativeItem.id &&
                          !savedBrandUrl
                        ) {
                          creativeServiceApi
                            ?.advertisingPlatformServiceUpdateCreative(
                              creativeItem.id,
                              currentOrg.id,
                              {
                                nativeMetadata: {
                                  alternativeMobileLandingPageUrl: brandUrl,
                                },
                              },
                              '',
                            )
                            .then((res) => setSavedBrandUrl(true))
                        }
                        if (savedBrandUrl) {
                          setSavedBrandUrl(false)
                        }
                      }}
                    />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      </div>
      {previewedFile && (
        <CreativePreview
          file={previewedFile}
          offClick={() => setPreviewedFile(undefined)}
        />
      )}
    </>
  )
}
