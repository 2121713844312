import { useContext, useEffect, useRef } from 'react'
import SignaturePad from 'react-signature-canvas'
import { Button, TextHeader, Checkbox } from '@eltoro-ui/components'
import { V1CreditCard } from 'next-gen-sdk'
import { PaymentAuthType } from '../CCModal'
import classNames from 'classnames'
import { ThemeContext } from 'Contexts'

export const PaymentAuthorization = ({
  paymentAuth,
  setPaymentAuth,
  navToStep,
  setComplete,
  handleSubmit,
  errorSubmitting,
  nickname,
  currentlyEditingCard,
  submitting,
}: {
  setComplete: () => void
  navToStep: (step: 1 | 2 | 3) => void
  paymentAuth: PaymentAuthType
  setPaymentAuth: React.Dispatch<React.SetStateAction<PaymentAuthType>>
  handleSubmit: (ccId?: string) => void
  errorSubmitting: boolean
  nickname?: string
  currentlyEditingCard?: V1CreditCard
  submitting: boolean
}) => {
  // I can't seem to import the required type for react-signature-pad as the required type can only be   //  imported defaultly
  const sigCanvas = useRef<any>()
  const { name: theme } = useContext(ThemeContext)

  useEffect(() => {
    if (paymentAuth.signature && paymentAuth.isAuthorized) {
      setComplete()
    }
  }, [paymentAuth.signature, paymentAuth.isAuthorized, setComplete])

  // If there is already a signature, load it
  useEffect(() => {
    if (currentlyEditingCard?.authorization?.signature) {
      sigCanvas.current.fromDataURL(
        currentlyEditingCard?.authorization?.signature,
      )
    }
  }, [currentlyEditingCard?.authorization?.signature])

  return (
    <div className="PaymentAuthorization flex flex-col gap-4">
      <TextHeader className="PaymentAuthorization__step-header flex" type={4}>
        <span className="PaymentAuthorization__step RightVerticalPipe font-light uppercase">
          Step 3
        </span>
        <span
          className={`PaymentAuthorization__subTitle text-l ${
            nickname ? 'RightVerticalPipe' : ''
          }`}
        >
          Authorization for credit card
        </span>
        {nickname ? (
          <span className="PaymentAuthorization__nicknm text-l">
            {nickname}
          </span>
        ) : null}
      </TextHeader>

      <p className="text-ml my-2 w-0 min-w-fit leading-tight">
        By placing<strong> "{nickname}" </strong>on file with El Toro, you
        explicitly authorize El Toro to automatically charge
        <strong> {nickname} </strong>for any past balances and/or current and
        future orders placed by you or your company
      </p>

      <div className="PaymentAuthorization__sig-pad flex flex-col items-center justify-center gap-1">
        <i className="text-m font-thin">Draw your signature with your mouse</i>
        <div
          className={classNames(
            'SignaturePadContainer border-thin border-grey-400 max-w-min',
            {
              invert: theme === 'darkBlue' || theme === 'dark',
            },
          )}
        >
          {/* @ts-ignore */}
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              width: 500,
              height: 100,
              className: 'sigCanvas',
            }}
            onEnd={() => {
              setPaymentAuth((prev) => ({
                ...prev,
                signature: sigCanvas.current
                  .getTrimmedCanvas()
                  .toDataURL('image/png'),
              }))
            }}
          />
        </div>
        <div className="flex items-center justify-between gap-8 py-4">
          <Checkbox
            checked={paymentAuth.isAuthorized}
            onChange={() => {
              setPaymentAuth((prev) => ({
                ...prev,
                isAuthorized: !prev.isAuthorized,
              }))
            }}
            label="I agree to authorize this credit card"
          />

          <Button
            onClick={() => {
              sigCanvas.current.clear()
              setPaymentAuth((prev) => ({
                ...prev,
                signature: '',
              }))
            }}
          >
            Clear signature
          </Button>
        </div>
      </div>

      {errorSubmitting && (
        <div className="PaymentAuthorization__error-msg bg-danger-50 text-danger animate-slidedown rounded p-2 text-center font-bold">
          There was an error submitting your
          {currentlyEditingCard ? ' updates' : ' new credit card'}. Please try
          again. If the issue persists, please submit a support request.
        </div>
      )}
      <div className="mt-2 flex justify-between">
        <Button onClick={() => navToStep(2)}>Back</Button>
        <Button
          className={`Button ${
            paymentAuth.signature && paymentAuth.isAuthorized
              ? 'Button--primary ripples'
              : ''
          }`}
          disabled={
            submitting || !paymentAuth.signature || !paymentAuth.isAuthorized
          }
          onClick={() => handleSubmit(currentlyEditingCard?.id)}
          type="submit"
        >
          Agree and save card
        </Button>
      </div>
    </div>
  )
}
