import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'

export const saveCampaignName = async (
  newName: string,
  campaignId: string,
  orgId: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { campaignServiceAPI } = apis
  if (!campaignServiceAPI) return
  const campaign = await campaignServiceAPI.advertisingPlatformServiceUpdateCampaign(
    campaignId,
    orgId,
    {
      name: newName,
    },
    'name',
  )
  return campaign
}
