import React from 'react'
import { styleTailwind } from 'Helpers'
import { FormItem, Select, TextInput } from '@eltoro-ui/components'
import { InfoPopover } from 'Components'
import { ErrorMessage, isValidCharLength, validCheck } from 'Helpers'
import { bannerChecklistConfig } from 'Components/AdSetForms/BannerAdForm/Components/BannerChecklistConfig'

const AdFormLabel = styleTailwind(
  'label',
  'AdFormLabel FormItem__label font-semibold pb-1 flex gap-1 items-center',
)

export const AdTagFormInputs = ({
  type,
  loading,
  adTagText,
  setAdTagText,
  name,
  setName,
  adDimensions,
  setAdDimensions,
}: {
  type: 'video' | 'banner'
  loading: boolean
  adTagText?: string
  name: string
  setAdTagText: React.Dispatch<React.SetStateAction<string | undefined>>
  setName: React.Dispatch<React.SetStateAction<string>>
  adDimensions?: string
  setAdDimensions: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const adDimensionOptions = bannerChecklistConfig.map((sizeConfig) => {
    return {
      value: sizeConfig.dimensions,
      label: (
        <div className="AdDimensionOptions flex justify-between">
          <div className="flex items-center gap-1 text-xs">
            <div className="BannerChecklist__ExampleShape">
              <div
                className="BannerChecklist__ExampleShapeItem"
                style={{ width: sizeConfig.width, height: sizeConfig.height }}
              />
            </div>
            <span>
              {sizeConfig.name}: {sizeConfig.dimensions}
            </span>
          </div>
        </div>
      ),
    }
  })

  return (
    <div className="AdTagFormInputs flex min-w-[18rem] flex-col gap-6">
      {type === 'banner' && (
        <div className="FormItem flex-col gap-1 pb-1">
          <AdFormLabel>
            <span>Select creative dimensions</span>
            <span
              aria-label="Required field"
              data-tooltip="top"
              className="text-danger"
            >
              *
            </span>
          </AdFormLabel>
          <Select
            placeholder="Click here to select"
            options={adDimensionOptions}
            selectedOption={{
              value: adDimensions,
              label: (() => {
                const matchingConfig = bannerChecklistConfig.find(
                  (config) => config.name === adDimensions,
                )
                if (!matchingConfig)
                  return adDimensions || 'Click here to select'
                return (
                  <div className="AdTagFormInputs__select flex justify-between">
                    <div className="text-s flex">
                      <div className="BannerChecklist__ExampleShape">
                        <div
                          className="BannerChecklist__ExampleShapeItem"
                          style={{
                            width: matchingConfig.width,
                            height: matchingConfig.height,
                          }}
                        />
                      </div>
                      {matchingConfig.name}: {matchingConfig.dimensions}
                    </div>
                  </div>
                )
              })(),
            }}
            onChange={(opt) => setAdDimensions(opt?.value)}
            maxHeight={200}
            clearButton={false}
          />
        </div>
      )}
      <FormItem
        label={
          <AdFormLabel htmlFor="ad-tag-input">
            <span className="capitalize">{`${
              type === 'video' ? 'VAST' : 'ad'
            } tag`}</span>
            <InfoPopover
              content={
                <div className="text-s">
                  {type === 'video' ? (
                    <>
                      <strong>VAST Versions Supported</strong>
                      <ul className="list-inside list-disc">
                        <li>2.0</li>
                        <li>3.0</li>
                        <li>4.0</li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <strong>Supported Ad Types</strong>
                      <ul className="list-inside list-disc">
                        <li>iFrame HTML</li>
                        <li>Raw HTML</li>
                        <li>Raw Javascript</li>
                      </ul>
                      <strong>Supported Media Types</strong>
                      <ul className="list-inside list-disc">
                        <li>Interstitial</li>
                        <li>Expandable</li>
                        <li>Rollover</li>
                      </ul>
                    </>
                  )}
                </div>
              }
              icon="circle-question"
            />
          </AdFormLabel>
        }
        wrapperClassname="adTagText__input-field flex-col !gap-0"
        className="FormItem__ad-tag-input focus:ring-primary flex-col gap-1 "
        htmlFor="adTagText"
        required
        valid={(adTagText || '').length > 10}
        errorMessage={ErrorMessage({
          fieldName: adTagText || '',
          max: 520000,
          label: type === 'video' ? 'VAST tag' : 'Ad tag',
        })}
        counter={(adTagText || '').length > 0}
      >
        <textarea
          id="ad-tag-input"
          className="AdTag__input p-2 font-mono text-xs"
          cols={60}
          maxLength={520001} // default is 524288
          rows={type === 'video' ? 10 : 20}
          placeholder={
            type === 'video'
              ? 'Paste your VAST tag here...'
              : 'Enter your ad tag here...'
          }
          onChange={(e) => setAdTagText(e.target.value)}
          value={adTagText}
          disabled={loading}
        />
      </FormItem>
      <FormItem
        wrapperClassname="flex-col !gap-0"
        label={<AdFormLabel>Creative name</AdFormLabel>}
        htmlFor="name"
        required
        valid={validCheck(name) && isValidCharLength(name, 255)}
        errorMessage={ErrorMessage({
          fieldName: name,
          max: 255,
          label: 'Creative name',
          isValid: name.length > 0 && name.length < 255,
        })}
        counter={name.length > 0}
      >
        <TextInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name will be displayed in the Creative Library's name column"
          disabled={loading}
          maxLength={256}
        />
      </FormItem>
    </div>
  )
}
