/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1Dependency } from '../models/Creativeservicev1Dependency';
import { Creativeservicev1Miscellanea } from '../models/Creativeservicev1Miscellanea';
import { Creativeservicev1SystemStat } from '../models/Creativeservicev1SystemStat';
import { HttpFile } from '../http/http';

export class Creativeservicev1Checks {
    'systemStat'?: Creativeservicev1SystemStat;
    'dependencies'?: Array<Creativeservicev1Dependency>;
    'miscellaneous'?: Array<Creativeservicev1Miscellanea>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "systemStat",
            "baseName": "systemStat",
            "type": "Creativeservicev1SystemStat",
            "format": ""
        },
        {
            "name": "dependencies",
            "baseName": "dependencies",
            "type": "Array<Creativeservicev1Dependency>",
            "format": ""
        },
        {
            "name": "miscellaneous",
            "baseName": "miscellaneous",
            "type": "Array<Creativeservicev1Miscellanea>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Creativeservicev1Checks.attributeTypeMap;
    }

    public constructor() {
    }
}

