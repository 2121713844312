/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* If a credit card is not provided, then the default credit card on the organization will be used.
*/
export class PaySetUpFeeRequest {
    /**
    * ID of the Credit Card to pay the set-up fee with
    */
    'creditCardId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "creditCardId",
            "baseName": "credit_card_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PaySetUpFeeRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

