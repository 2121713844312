import { useRef, useState } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import {
  Campaignservicev1AdType,
  Creativeservicev1Creative,
  V1CreativeFile,
} from 'next-gen-sdk'
import { Fa, Popover, TextHeader } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import {
  isBannerAndMissingATop5Dimension,
  isVideo,
  simplifyEnum,
  styleTailwind,
} from 'Helpers'
import { CreativePreview, WarningMsg, CreativeThumbnail } from 'Components'

const IconThumbnailWrapper = styleTailwind(
  'div',
  'IconThumbnailWrapper h-full w-full flex items-center justify-center bg-primary-100 rounded',
)

export const CreativesCardItem = ({
  olCreatives,
  adType,
  readyToDeploy,
  loading,
}: {
  olCreatives?: Creativeservicev1Creative[]
  adType?: Campaignservicev1AdType
  readyToDeploy?: boolean
  loading?: boolean
}) => {
  const [showAllCreatives, setShowAllCreatives] = useState(false)
  // prettier-ignore
  const [
      previewedFile,
      setPreviewedFile,
    ] = useState<V1CreativeFile>()
  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([contentRef, buttonRef], () => setShowAllCreatives(false))

  if (loading)
    return (
      <div className="CreativesCardItem__loading-creatives flex h-full w-full animate-pulse flex-col items-center justify-start">
        <Fa
          className="CreativesCardItem__loading-icon !text-primary mr-6"
          icon="image"
          size={6}
        />
      </div>
    )

  if (!olCreatives || olCreatives.length === 0) {
    return (
      <div className="CreativesCardItem__missing-creative flex">
        <div
          className="CreativesCardItem__missing-creative-placeholder bg-warning-100 mx-2 flex flex-1 flex-col items-center py-2"
          data-tooltip="bottom"
          aria-label="You're missing creatives. Go back to add to all order lines or select 'Edit' checkbox."
        >
          <Fa className="text-warning" icon="exclamation" size={4} />
          <span className="text-xs">Missing creative</span>
        </div>
      </div>
    )
  }

  const [firstCreative] = olCreatives

  const file = firstCreative?.files?.[0]

  const getFileThumbnail = (
    file: V1CreativeFile,
    cover: boolean,
    onClick?: () => void,
  ) => (
    <CreativeThumbnail file={file} onClick={onClick} size="m" cover={cover} />
  )

  const hasCreativeMissingTop5Dimension = () =>
    olCreatives &&
    firstCreative?.type &&
    (firstCreative.type === 'CREATIVE_TYPE_BANNER' ||
      firstCreative.type === 'CREATIVE_TYPE_HTML5' ||
      firstCreative.type === 'CREATIVE_TYPE_AD_TAG') &&
    !!olCreatives.find((creative) => isBannerAndMissingATop5Dimension(creative))

  const hasCreativeAboveRecommendedSize = () =>
    olCreatives &&
    !!olCreatives.find(
      (creative) =>
        creative.type === 'CREATIVE_TYPE_BANNER' &&
        creative.files?.[0]?.size &&
        +creative.files?.[0]?.size > 100000,
    )

  const hasOTTWarning = () =>
    olCreatives &&
    !!olCreatives.find((creative) => creative.type === 'CREATIVE_TYPE_OTT')

  const popoverText = 'View Specs'

  return (
    <div
      className="CreativesCardItem flex h-full flex-col items-center gap-1"
      ref={buttonRef}
      data-tooltip="right"
      aria-label="Click to view thumbnail, creative type"
    >
      <Popover
        className="CreativeCardItem__thumbs"
        position={['right', 'right']}
        align="center"
        attachId="MainAppContainer"
        content={
          <div
            className="CreativesCardItem__popover-content border-tint-gray-300 border-thin bg-base flex max-w-xl flex-col gap-3 rounded p-0 shadow-lg"
            ref={contentRef}
          >
            <TextHeader
              type={6}
              className="CreativesCardItem__popover-content--title"
            >
              Creative Type: {adType ? simplifyEnum(2, adType) : 'Unknown'}
            </TextHeader>

            <div className="CreativesCardItem__thumb-container flex flex-1 flex-wrap justify-start gap-2">
              {olCreatives.map((creative) => {
                const file = creative.files?.[0]
                return (
                  <div
                    key={creative.id}
                    className="CreativesCardItem__thumb-item bg-base mx-auto flex flex-1 flex-col items-center gap-2"
                  >
                    {file && creative.type !== 'CREATIVE_TYPE_HTML5' ? (
                      getFileThumbnail(file, false, () => {
                        setPreviewedFile(file)
                        setShowAllCreatives(false)
                      })
                    ) : (
                      <IconThumbnailWrapper>
                        {(() => {
                          if (creative.type === 'CREATIVE_TYPE_HTML5')
                            return <ETIcon className="HTML5Icon" icon="html5" />
                          if (
                            creative.type === 'CREATIVE_TYPE_AD_TAG' ||
                            creative.type === 'CREATIVE_TYPE_VAST_TAG'
                          )
                            return <ETIcon icon="ad-tag" />
                        })()}
                      </IconThumbnailWrapper>
                    )}
                    <p className="text-xs">
                      Size:&nbsp;
                      {(() => {
                        if (creative.type === 'CREATIVE_TYPE_AD_TAG')
                          return `${creative.width}x${creative.height}`
                        if (file?.mime && isVideo(file.mime))
                          return `${file?.duration} seconds`
                        if (
                          file?.width !== undefined &&
                          file?.height !== undefined
                        )
                          return `${file.width}x${file.height}`
                      })()}
                    </p>
                  </div>
                )
              })}
            </div>
            <>
              {hasCreativeMissingTop5Dimension() && (
                <WarningMsg popoverText={popoverText}>
                  One or more of your creative(s) is not in the top 5
                  recommended sizes. This <strong>may</strong> limit our serving
                  capacity.
                </WarningMsg>
              )}
              {hasCreativeAboveRecommendedSize() && (
                <WarningMsg popoverText={popoverText}>
                  One or more of your creative(s) have a file size above our
                  recommended specifications. This <strong>may</strong> limit
                  our serving capacity. Recommended file size is less than
                  100kb.
                </WarningMsg>
              )}
              {hasOTTWarning() && (
                <WarningMsg popoverText={popoverText}>
                  One or more of your creative(s) do not meet our recommended
                  specifications for optimal delivery. This <strong>may</strong>{' '}
                  limit our serving capacity.
                </WarningMsg>
              )}
            </>
          </div>
        }
        isOpen={showAllCreatives}
      >
        <button
          className="CreativesCardItem__thumbnail-wrapper relative max-w-max"
          onClick={() => setShowAllCreatives((prev) => !prev)}
        >
          {file && firstCreative.type !== 'CREATIVE_TYPE_HTML5'
            ? getFileThumbnail(file, true)
            : (() => {
                if (firstCreative.type === 'CREATIVE_TYPE_HTML5')
                  return (
                    <IconThumbnailWrapper>
                      <ETIcon className="HTML5Icon" icon="html5" />
                    </IconThumbnailWrapper>
                  )

                if (
                  firstCreative?.type &&
                  (firstCreative.type === 'CREATIVE_TYPE_AD_TAG' ||
                    firstCreative.type === 'CREATIVE_TYPE_VAST_TAG')
                )
                  return (
                    <IconThumbnailWrapper>
                      <ETIcon icon="ad-tag" />
                    </IconThumbnailWrapper>
                  )
                return null
              })()}
          <div className="CreativesCardItem__count-badge text-l bg-gray absolute right-[-0.5rem] bottom-[-0.5rem] flex h-6 w-6 items-center justify-center rounded-full p-1 text-center font-bold shadow-lg">
            {olCreatives.length}
          </div>
        </button>
      </Popover>

      {readyToDeploy && (
        <div className="CreativesCardItem__deploy-badge bottom-ribbon">
          <div className="gradient flex items-center justify-center">
            <Fa icon="check" size={1} className="-ml-2 scale-75" />
            <p className="!text-m whitespace-nowrap font-normal">
              Ready to deploy
            </p>
          </div>
        </div>
      )}
      {previewedFile && (
        <CreativePreview
          file={previewedFile}
          offClick={() => setPreviewedFile(undefined)}
        />
      )}
    </div>
  )
}
