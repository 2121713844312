import { useState, useEffect } from 'react'
import { Button, FormItem, TextInput } from '@eltoro-ui/components'
import { validationRegEx } from '@eltoro-ui/hooks'
import { ErrorMessage, isValidCharLength, validCheck } from 'Helpers'
import './UTMFormFields.scss'

export const UTMFormFields = ({
  clickThru,
  OLName,
  onClose,
  setClickThru,
}: {
  clickThru: string
  OLName: string
  onClose: () => void
  setClickThru: (x: string) => void
}) => {
  // On initial mount, get existing info
  const url = validationRegEx['url'].test(clickThru) ? new URL(clickThru) : null
  const source = url?.searchParams?.get('utm_source')
  const medium = url?.searchParams?.get('utm_medium')
  const campaign = url?.searchParams?.get('utm_campaign')
  const content = url?.searchParams?.get('utm_content')
  const term = url?.searchParams?.get('utm_term')
  const [utmSource, setUTMSource] = useState(source || '')
  const [utmTerm, setUTMTerm] = useState(term || '')
  const [utmCampaign, setUTMCampaign] = useState(campaign || OLName || '')
  const [utmContent, setUTMContent] = useState(content || '')
  const [utmMedium, setUTMMedium] = useState(medium || '')
  const buildUrL = (
    source: string,
    medium: string,
    campaign: string,
    content?: string,
    term?: string,
  ) => {
    if (!source || !medium || !campaign) {
      return clickThru.split('?')[0]
    }
    const parsed = new URL(clickThru)
    parsed.searchParams.set('utm_source', source)
    parsed.searchParams.set('utm_campaign', campaign)
    parsed.searchParams.set('utm_medium', medium)
    if (content) {
      parsed.searchParams.set('utm_content', content)
    }
    if (term) {
      parsed.searchParams.set('utm_term', term)
    }

    return parsed.toString()
  }

  const clearUTM = () => {
    const parsed = new URL(clickThru)
    setUTMCampaign('')
    parsed.searchParams.delete('utm_campaign')
    setUTMContent('')
    parsed.searchParams.delete('utm_content')
    setUTMMedium('')
    parsed.searchParams.delete('utm_medium')
    setUTMTerm('')
    parsed.searchParams.delete('utm_term')
    setUTMSource('')
    parsed.searchParams.delete('utm_source')
    if (setClickThru) {
      setClickThru(parsed.toString())
    }
  }
  useEffect(() => {
    setClickThru(
      buildUrL(utmSource, utmMedium, utmCampaign, utmContent, utmTerm),
    )
  }, [utmCampaign, utmContent, utmSource, utmTerm, utmMedium])

  const utmTextInputStyles = (utmKey: string) =>
    utmKey && utmKey.length > 2 && utmKey.length <= 255
      ? 'border-success animate-glowsuccess'
      : ''

  return (
    <div className="UTMFormFields">
      <FormItem
        htmlFor="utm-medium"
        label="Campaign medium"
        required
        errorMessage={ErrorMessage({
          fieldName: utmMedium,
          max: 255,
          label: 'UTM',
          isValid: validCheck(utmMedium) && isValidCharLength(utmMedium, 255),
        })}
        valid={validCheck(utmMedium) && isValidCharLength(utmMedium, 255)}
        counter={utmMedium.length > 0}
      >
        <TextInput
          classNameWrap={`UTMFormFields ${utmTextInputStyles(utmMedium)}`}
          value={utmMedium}
          onChange={(e) => setUTMMedium((e.target as HTMLInputElement).value)}
          placeholder="e.g. Social, Email, Banner"
          maxLength={256}
        />
      </FormItem>
      <FormItem
        htmlFor="utm-campaign"
        label="Campaign name"
        required
        errorMessage={ErrorMessage({
          fieldName: utmCampaign,
          max: 255,
          label: 'Campaign name',
          isValid:
            validCheck(utmCampaign) && isValidCharLength(utmCampaign, 255),
        })}
        valid={validCheck(utmCampaign) && isValidCharLength(utmCampaign, 255)}
        counter={utmCampaign.length > 0}
      >
        <TextInput
          classNameWrap={`UTMFormFields__TextInput ${utmTextInputStyles(
            utmCampaign,
          )}`}
          value={utmCampaign}
          maxLength={256}
          onChange={(e) => setUTMCampaign((e.target as HTMLInputElement).value)}
          placeholder="e.g. Name of your campaign"
        />
      </FormItem>
      <FormItem
        htmlFor="utm-source"
        label="Campaign source"
        required
        errorMessage={ErrorMessage({
          fieldName: utmSource,
          max: 255,
          label: 'Campaign name',
          isValid: validCheck(utmSource) && isValidCharLength(utmSource, 255),
        })}
        valid={validCheck(utmSource) && isValidCharLength(utmSource, 255)}
        counter={utmSource.length > 0}
      >
        <TextInput
          classNameWrap={`UTMFormFields__TextInput ${utmTextInputStyles(
            utmSource,
          )}`}
          value={utmSource}
          onChange={(e) => setUTMSource((e.target as HTMLInputElement).value)}
          placeholder="e.g. Facebook, Google"
          maxLength={256}
        />
      </FormItem>
      <FormItem
        htmlFor="utm-content"
        label="Campaign content"
        errorMessage={ErrorMessage({
          fieldName: utmContent,
          max: 255,
          label: 'Campaign content',
          isValid: validCheck(utmContent) && isValidCharLength(utmContent, 255),
        })}
        valid={validCheck(utmContent) && isValidCharLength(utmContent, 255)}
        counter={utmContent.length > 0}
      >
        <TextInput
          classNameWrap={`UTMFormFields__TextInput ${utmTextInputStyles(
            utmContent,
          )}`}
          value={utmContent}
          onChange={(e) => setUTMContent((e.target as HTMLInputElement).value)}
          placeholder="e.g. Links to your campaign"
          maxLength={256}
        />
      </FormItem>
      <FormItem
        htmlFor="utm-term"
        label="Campaign term"
        errorMessage={ErrorMessage({
          fieldName: utmTerm,
          max: 255,
          label: 'Term',
          isValid: validCheck(utmTerm) && isValidCharLength(utmTerm, 255),
        })}
        valid={validCheck(utmTerm) && isValidCharLength(utmTerm, 255)}
        counter={utmTerm.length > 0}
      >
        <TextInput
          classNameWrap={`UTMFormFields__TextInput ${utmTextInputStyles(
            utmTerm,
          )}`}
          value={utmTerm}
          onChange={(e) => setUTMTerm((e.target as HTMLInputElement).value)}
          placeholder="e.g. Keywords you want to track"
          maxLength={256}
        />
      </FormItem>

      <Button
        className="UTMFormFields__button flex pt-1 !pr-0"
        kind="text"
        onClick={() => clearUTM()}
      >
        Clear all fields
      </Button>
    </div>
  )
}
