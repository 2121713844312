import { useEffect, useState } from 'react'
import { useBrowser } from '@eltoro-ui/hooks'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'
import { V1Notification } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { getSanitizedHTML } from 'Helpers'

export const useNotifications = () => {
  const Browser = useBrowser()
  const [alertsList, setAlertsList] = useState<V1Notification[]>([])
  const [message, setMessage] = useState<
    React.ChangeEvent<HTMLTextAreaElement> | string
  >('')
  const [loading, setLoading] = useState<boolean>(true)
  const [newNotifications, setNewNotifications] = useState<V1Notification[]>([])
  const { notificationsApi } = useAppContext()

  useEffect(() => {
    const filteredNewAlerts = (
      newAlerts: V1Notification[],
    ): V1Notification[] => {
      const currentTime = new Date()
      const days = 6
      const daysTime = new Date(
        currentTime.getTime() - days * 24 * 60 * 60 * 1000,
      )

      return newAlerts.filter((alert) => {
        if (alert.updateTime) {
          const alertTime = new Date(alert.updateTime)
          return alertTime > daysTime
        }
        return null
      })
    }
    const fetchData = async () => {
      notificationsApi
        ?.advertisingPlatformServiceGetNotifications()
        .then(({ notifications }) => {
          if (!notifications) return
          const sortedNotifications = notifications.sort(
            (a, b) => b.updateTime!.getTime() - a.updateTime!.getTime(),
          )
          const alertsOnly = sortedNotifications.filter(
            (notification) => notification.global && notification.active,
          )
          setAlertsList(alertsOnly)
          setNewNotifications(filteredNewAlerts(sortedNotifications))
        })
        .catch((err) => {
          showErrorMessage(
            'Error Getting Notifications...',
            `Please try again later!`,
          )
        })
        .finally(() => setLoading(false))
    }

    fetchData()
  }, [notificationsApi])

  const createNotification = async (data: V1Notification) => {
    if (!notificationsApi) return
    const sanitizedMessage = getSanitizedHTML(data.message as string, Browser)

    try {
      const createMessage = await notificationsApi?.advertisingPlatformServiceCreateNotification(
        {
          ...data,
          message: sanitizedMessage, // Sanitized message, allows tags
        },
      )
      if (createMessage) {
        setAlertsList([createMessage, ...alertsList])
        showSuccessMessage(
          `Added "${createMessage.title}"`,
          'The new alert should be on the list.',
        )
      } else {
        console.error('Getting an error from the API') // Handle no response
      }
    } catch (error) {
      showErrorMessage(
        'Error saving alert',
        `Error, ${error}, saving alert. Please try again later`,
      )
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement> | string,
  ) => {
    if (typeof e === 'string') {
      setMessage(e)
    } else {
      setMessage(e.target.value)
    }
  }

  const deleteNotification = async (data: V1Notification) => {
    if (!notificationsApi || !data.id) return
    notificationsApi
      ?.advertisingPlatformServiceDeleteNotification(data.id!)
      .then(() => {
        setAlertsList(
          alertsList.filter((deleteItem) => deleteItem.id !== data.id),
        )
        showSuccessMessage(
          `"${data.title}" is deleted`,
          "Refresh, if the alert isn't deleted",
        )
      })
      .catch(() =>
        showErrorMessage(
          `Error deleting ${data.title}`,
          'Please try again later',
        ),
      )
  }

  const updateNotification = (data: V1Notification) => {
    if (!notificationsApi || !data.id) return
    const sanitizedMessage = getSanitizedHTML(data.message as string, Browser)
    notificationsApi
      ?.advertisingPlatformServiceUpdateNotification(data.id, {
        title: data.title,
        type: data.type,
        createTime: data.createTime,
        updateTime: data.updateTime,
        message: sanitizedMessage,
        userId: data.userId,
      })
      .then(() => {
        setAlertsList(alertsList.map((i) => (i.id === data.id ? data : i)))
        showSuccessMessage(
          `"${data.title}" is updated`,
          "Refresh page, if the alert isn't updated",
        )
      })
      .catch(() =>
        showErrorMessage('Error updating alert', 'Please try again later'),
      )
  }

  return {
    alertsList,
    createNotification,
    deleteNotification,
    updateNotification,
    loading,
    message,
    newNotifications,
    handleInputChange,
  }
}
