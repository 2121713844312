/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [MemberDataSharingSegment](https://docs.xandr.com/bundle/data-providers/page/member-data-sharing-service.html) in Xandr's documentation.
*/
export class V3MemberDataSharingSegment {
    /**
    * The ID of the sharing record.
    */
    'id'?: number;
    /**
    * Read-only. The name of the type of deal. Possible values: 'Open Auction', 'Private Auction', 'Curated', or 'Programmatic Guaranteed'.
    */
    'name'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3MemberDataSharingSegment.attributeTypeMap;
    }

    public constructor() {
    }
}

