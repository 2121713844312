import {
  isValidElement,
  cloneElement,
  ReactElement,
  Children,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import { CharacterCounter } from '../CharacterCounter'
import './FormItem.scss'

type FormItemType = {
  // htmlFor must match a key in the form data for required, valid, etc to work as intended
  htmlFor: string
  children?: ReactNode
  className?: string
  counter?: boolean
  errorMessage?: string
  label?: string | ReactNode
  required?: boolean
  UNSAFE_style?: { [key: string]: any }
  valid?: boolean
  wrapperClassname?: string
}

export const FormItem = ({
  htmlFor,
  children,
  className,
  counter = false,
  errorMessage = '',
  label,
  required = false,
  valid = true,
  UNSAFE_style,
  wrapperClassname,
}: FormItemType) => {
  const [hasError, setHasError] = useState(false) // Track error state

  let maxLength: number = 256
  let minLength: number = 0

  const updateProps = (child: ReactElement) => {
    let newProps = { ...child.props }
    // set id of child to htmlFor for better UX
    newProps = { ...newProps, id: htmlFor }
    return newProps
  }

  const checkValid = (val: string | boolean) => {
    if (typeof val === 'string') return val === 'true'
    return val
  }

  const _valid = checkValid(valid)

  const inputValue =
    isValidElement(children) && typeof children.props.value === 'string'
      ? children.props.value
      : ''

  const inputLength: number = inputValue.length

  const hasInputError = errorMessage.length > 0 && !_valid
  //  target only input fields that need the special input handler so radios n checkboxes wouldn't be affected

  const isSupportedInput = (type: string) => {
    if (typeof type === 'string') {
      const supportedTypes = ['text', 'number', 'tel']
      return supportedTypes.includes(type.toLowerCase())
    }
    return false
  }
  // Extract maxLength from child input field if available
  Children.forEach(children, (child) => {
    if (isValidElement(child) && typeof child.props.maxLength === 'number') {
      maxLength = child.props.maxLength
    }
    if (isValidElement(child)) {
      const props = child.props as { minLength?: number }
      if (typeof props.minLength === 'number') {
        minLength = props.minLength
      }
    }
  })

  useEffect(() => {
    const remain = maxLength - inputLength
    const hasMinError = inputLength > 0 && inputLength < minLength
    if (hasInputError || remain < 0 || hasMinError) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  }, [hasInputError, inputLength, maxLength, minLength])

  return (
    <div
      className={`FormItem FormItem__${htmlFor} ${className || ''} ${
        required && inputLength < 1 ? 'FormItem__required' : ''
      } ${
        // eslint-disable-next-line no-nested-ternary
        hasError
          ? 'FormItem__hasError'
          : inputLength > 2 &&
            inputLength > minLength &&
            inputLength <= maxLength
          ? 'FormItem__isValid'
          : ''
      }`}
      style={UNSAFE_style || {}}
    >
      <div
        className={`FormItem__wrap flex laptop:flex-col gap-1 ${
          wrapperClassname || ''
        }`}
      >
        {label && (
          <label
            className="FormItem__label flex items-center"
            htmlFor={htmlFor}
          >
            {label}
            {required && (
              <span className="Required-asterisk text-danger inline-block h-auto">
                *
              </span>
            )}
          </label>
        )}
        <div
          className={`FormItem__textinput-message flex-1 flex flex-col ${
            // eslint-disable-next-line no-nested-ternary
            inputLength > maxLength
              ? 'border-warning'
              : inputLength > 2 && inputLength > minLength
              ? 'border-success'
              : ''
          }`}
        >
          {Children.map(children, (child) => {
            if (!isValidElement(child)) return false
            if (
              isValidElement(child) &&
              isSupportedInput(child.props.type) &&
              typeof child.props.value === 'string' &&
              typeof child.props.onChange === 'function'
            ) {
              return cloneElement(child as ReactElement, {
                ...child.props,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  child.props.onChange(e)
                },
              })
            }
            return cloneElement(child, updateProps(child))
          })}
          {counter && (
            <CharacterCounter
              fieldName={htmlFor}
              max={maxLength - 1}
              text={inputValue}
            />
          )}
          {hasError && (
            <div className="FormItem__msg-wrapper hasError animate-slidedown">
              <p className="FormItem__error-message">{errorMessage}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
