import { useState } from 'react'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { EditingCancelUpdateButtons } from '../EditingCancelUpdateButtons'
import { styleTailwind } from 'Helpers'
import { OptionalIdEditRow } from './OptionalIdEditRow'
import { useAppContext, useCampaignContext } from 'Contexts'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'
import { useLocation } from 'react-router-dom'

export const OptionalIdEditGridRow = styleTailwind(
  'div',
  'OptionalIdEditGridRow__GridRow grid grid-cols-3 laptopsmall:flex laptopsmall:flex-wrap laptopsmall:justify-between',
)
const TableHeader = styleTailwind(
  'div',
  'EditOLTable__header-text flex flex-col',
)

export type IDChangeType = {
  olId: string
  jobId?: string
  poId?: string
}

export const OptionalIdEdit = ({
  className,
  selectedOls,
  onCloseModal,
  setRefreshCampaign,
}: {
  className?: string
  selectedOls: Campaignservicev1OrderLine[]
  onCloseModal: () => void
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [orderLineChanges, setOrderLineChanges] = useState<IDChangeType[]>([])
  const { campaignServiceApi, currentOrg } = useAppContext()
  const { setRefresh } = useCampaignContext()
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')
  const handleUpdate = () => {
    Promise.all(
      orderLineChanges.map(({ olId, jobId, poId }) => {
        if (!campaignServiceApi || !currentOrg?.id) return Promise.resolve({})
        return campaignServiceApi.advertisingPlatformServiceUpdateOrderLine(
          olId,
          currentOrg.id,
          {
            jobId,
            poId,
          },
        )
      }),
    )
      .then(() => {
        showSuccessMessage('Optional ID was updated successfully', '')
      })
      .catch(() => {
        showErrorMessage('Optional ID update failed', '')
      })
      .then(() => {
        onCloseModal()
        if (setRefreshCampaign) {
          setRefreshCampaign(true)
        }
        if (isInCampaignCreation) setRefresh(true)
      })
  }

  return (
    <div className={`OptionalIdEdit m-2 ${className}`}>
      <OptionalIdEditGridRow className="EditOLTable__header StickyHeader laptopsmall:hidden">
        <TableHeader>Order Line Name</TableHeader>
        <TableHeader>Job ID</TableHeader>
        <TableHeader>P.O. #</TableHeader>
      </OptionalIdEditGridRow>
      <div className="EditOLTable__body">
        {selectedOls.map((ol) => (
          <OptionalIdEditRow
            orderLine={ol}
            onChange={(change) => {
              setOrderLineChanges((prev) => {
                const filtered = prev.filter((p) => p.olId !== change.olId)
                const prevChange = prev.find((p) => p.olId === change.olId)
                return [...filtered, { ...prevChange, ...change }]
              })
            }}
          />
        ))}
      </div>
      <EditingCancelUpdateButtons
        onCloseModal={onCloseModal}
        handleUpdate={handleUpdate}
        updateDisabled={!orderLineChanges.length}
      />
    </div>
  )
}
