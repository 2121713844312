/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V3Region {
    'id'?: number;
    'name'?: string;
    'iso31662'?: string;
    'countryId'?: number;
    'countryName'?: string;
    'countryCode'?: string;
    'active'?: boolean;
    'salesTaxRatePct'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "iso31662",
            "baseName": "iso_3166_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "countryId",
            "baseName": "country_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "countryName",
            "baseName": "country_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "countryCode",
            "baseName": "country_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "salesTaxRatePct",
            "baseName": "sales_tax_rate_pct",
            "type": "number",
            "format": "float"
        }    ];

    static getAttributeTypeMap() {
        return V3Region.attributeTypeMap;
    }

    public constructor() {
    }
}

