import { useEffect, useState } from 'react'
import {
  TextInput,
  Fa,
  Button,
  showErrorMessage,
  Pagination,
} from '@eltoro-ui/components'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { readableCreativeType } from 'Helpers'
import { creativeAdTypesEnum } from 'Enums'
import { ModifiedOrderLineType } from '../types'
import { getThumbnail } from './getThumbnail'

export const ChangeCreativeMenu = ({
  onCreativeSelect,
  orderLine,
}: {
  onCreativeSelect: (newCreative: Creativeservicev1Creative) => void
  orderLine: ModifiedOrderLineType
}) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 200)

  const { currentOrg, creativeServiceApi } = useAppContext()

  const handleFetchCreatives = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!creativeServiceApi) return empty

    // TODO: swap with order line's ad type
    // Filters by creatives that are compatible with this order line
    const orderLineTypeFilter =
      orderLine.creatives?.[0]?.adType &&
      orderLine.creatives?.[0]?.adType !== 'CREATIVE_ADTYPE_UNSPECIFIED'
        ? ` AND ad_type = ${creativeAdTypesEnum[orderLine.creatives[0].adType]}`
        : ''

    // Filters out creatives that are already on the order line
    const existingCreatives = orderLine.creatives?.reduce(
      (acc: string[], currentCreative) => {
        if (!currentCreative.id) return acc
        return [...acc, `"${currentCreative.id}"`]
      },
      [],
    )
    const existingCreativesFilter = existingCreatives
      ? ` AND id != ${existingCreatives.join(' AND id != ')}`
      : ''

    const res = await creativeServiceApi
      ?.advertisingPlatformServiceListCreatives(
        pageSize, // pageSize
        nextPageToken, // pageToken
        'create_time desc', // orderBy
        `org_id = "${
          currentOrg?.id || '*'
        }" AND name = "*${debouncedSearch}*"${orderLineTypeFilter}${existingCreativesFilter}`, // filter
      )
      .catch(() => showErrorMessage('Error fetching creatives', ''))

    if (res) {
      return {
        data: res.creatives || [],
        totalItems: res.totalSize || 0,
        nextPageToken: res.nextPageToken,
      }
    }
    return empty
  }

  const pagination = useTokenPagination<Creativeservicev1Creative>(
    {
      pageSize: 10,
      fetchData: handleFetchCreatives,
    },
    [debouncedSearch, creativeServiceApi],
  )

  const { currentPageData: creatives, loading, setPage } = pagination

  return (
    <div className="ChangeCreativeMenu flex max-h-72 flex-col">
      <div className="ChangeCreativeMenu__search flex items-center p-1 pt-0">
        <TextInput
          iconLeft={<Fa icon="search" size={1} />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchText && (
          <Button
            iconOnly={<Fa icon="times" size={1} />}
            onClick={() => setSearchText('')}
            kind="text"
            size="s"
          />
        )}
      </div>
      <div
        className={classNames(
          'ChangeCreativeMenu__creative-list relative flex min-h-[5rem] flex-col overflow-y-auto overflow-x-hidden pt-2',
          { 'pointer-events-none': loading },
        )}
      >
        {(loading || !creatives.length) && (
          <div className="ChangeCreativeMenu__loading absolute top-0 bottom-0 right-0 left-0 z-40 flex items-center justify-center overflow-clip p-2">
            {(() => {
              if (loading)
                return (
                  <Fa
                    icon="spinner"
                    size={2}
                    animate="spin"
                    color="var(--tint-khaki)"
                  />
                )
              if (!creatives.length)
                return <span className="text-ml">No creatives found.</span>
            })()}
          </div>
        )}
        {creatives.map((creative, index) => (
          <button
            type="button"
            onClick={() => onCreativeSelect(creative)}
            className={classNames(
              'ChangeCreativeMenu__creative-button hover:bg-gray mx-1 flex gap-2 p-1 focus:ring-2',
              { 'opacity-20': loading },
            )}
            key={`${creative.id}_${index}`}
          >
            {getThumbnail(creative, 'xs')}
            <div className="ChangeCreativeMenu__creative-button-name-type text-s flex max-w-[18rem] flex-col  text-left">
              <p className="truncate">{creative.name}</p>
              {creative.type && (
                <p className="text-tint-gray-700 italic">
                  {readableCreativeType(creative.type)}
                </p>
              )}
            </div>
          </button>
        ))}
      </div>
      <Pagination
        {...pagination}
        onChange={(pageInfo) => {
          setPage(pageInfo.page)
        }}
      />
    </div>
  )
}
