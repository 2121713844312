/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1Campaign } from '../models/Campaignservicev1Campaign';
import { HttpFile } from '../http/http';

/**
* The response from a ListCampaigns call
*/
export class Campaignservicev1ListCampaignsResponse {
    'campaigns'?: Array<Campaignservicev1Campaign>;
    /**
    * A token, which can be sent as `page_token` to retrieve the next page. If this field is omitted, there are no subsequent pages.
    */
    'nextPageToken'?: string;
    /**
    * The total count of campaigns irrespective of pagination. If filtering is used, the `total_size` field reflects the total count of campaigns after the filter is applied.
    */
    'totalSize'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "campaigns",
            "baseName": "campaigns",
            "type": "Array<Campaignservicev1Campaign>",
            "format": ""
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "totalSize",
            "baseName": "total_size",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return Campaignservicev1ListCampaignsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

