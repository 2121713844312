/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* The configuration around segment grouping.
*/
export class V3SegmentConfig {
    /**
    * Puts all segments in one group.
    */
    'groupSegments'?: boolean;
    /**
    * Appends Safe Segment 1 to the segments.
    */
    'includeSafeSegment1'?: boolean;
    /**
    * Appends Safe Segment 2 to the segments.
    */
    'includeSafeSegment2'?: boolean;
    /**
    * Appends Safe Segment 3 to the segments.
    */
    'includeSafeSegment3'?: boolean;
    /**
    * If using segment_targets, this defines the Boolean logic between the segments specified. If using segment_group_targets, this defines the Boolean logic between the segment groups (the Boolean logic between segments in a group is defined directly in the segment_group_targets array). Possible values: 'and' or 'or'.
    */
    'segmentBooleanOperator'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "groupSegments",
            "baseName": "group_segments",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "includeSafeSegment1",
            "baseName": "include_safe_segment_1",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "includeSafeSegment2",
            "baseName": "include_safe_segment_2",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "includeSafeSegment3",
            "baseName": "include_safe_segment_3",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "segmentBooleanOperator",
            "baseName": "segment_boolean_operator",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3SegmentConfig.attributeTypeMap;
    }

    public constructor() {
    }
}

