import { useState, useRef, useEffect } from 'react'
import {
  Button,
  TextHeader,
  Text,
  Fa,
  showSuccessMessage,
  showErrorMessage,
} from '@eltoro-ui/components'
import { getDaysBetween } from 'Helpers'
import { useAppContext } from 'Contexts'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import './OrderLineImpressionsEdit.scss'
import { IMPRESSION_MIN } from 'Components/OrderLineEditWrapper/components/shared'

export const addCommas = (e: string) => {
  const parts = e.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const OrderLineImpressionsEdit: React.FC<{
  orderLine: Campaignservicev1OrderLine
  orderLineCreativeType: string | undefined
  matches: number
  onUpdateOrderLine: (updated: Campaignservicev1OrderLine) => void
  drawerClose: () => void
  editDeployedOL?: boolean
}> = ({
  orderLine,
  orderLineCreativeType,
  matches,
  editDeployedOL,
  onUpdateOrderLine,
  drawerClose,
}) => {
  const [editTotal, setEditTotal] = useState<boolean>(false)
  const [editDaily, setEditDaily] = useState<boolean>(false)
  const [editImpMatch, setEditImpMatch] = useState<boolean>(false)
  const [total, setTotal] = useState<string>('')
  const [daily, setDaily] = useState<string>('')
  const [impMatch, setImpMatch] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const textRef = useRef<HTMLInputElement>(null)
  const { currentOrg, campaignServiceApi, roles } = useAppContext()

  const dayCount =
    orderLine?.startTime &&
    getDaysBetween(
      editDeployedOL ? new Date() : orderLine?.startTime,
      orderLine?.endTime || new Date(),
    )

  const handleOffClick = () => {
    if (orderLine && orderLine.impressions) {
      setTotal(String(orderLine.impressions))
    }
    setEditDaily(false)
    setEditImpMatch(false)
    setEditTotal(false)
  }

  const handleSaveTotal = (imps: string) => {
    if (!orderLine.id || !campaignServiceApi || !orderLine.orgId) return
    if (
      orderLine.minimumImpressions &&
      Number(imps) < orderLine.minimumImpressions &&
      roles &&
      roles.includes('nextgen_admin')
    ) {
      return campaignServiceApi
        .advertisingPlatformServiceUpdateOrderLine(
          orderLine.id,
          orderLine.orgId,
          {
            impressions: Math.round(Number(imps)),
            minimumImpressions: Math.round(Number(imps)),
          },
        )
        .then((updated) => {
          showSuccessMessage(
            'Impressions updated successfully',
            `The impressions are now ${updated.impressions}`,
          )
          setEditTotal(false)
          setEditImpMatch(false)
          setEditDaily(false)
          onUpdateOrderLine(updated)
        })
        .catch((err) => {
          if (err) {
            showErrorMessage('Impression update failed', 'Please try again.')
          }
        })
    }
    return campaignServiceApi
      .advertisingPlatformServiceUpdateOrderLine(
        orderLine.id,
        orderLine.orgId,
        {
          impressions: Math.round(Number(imps)),
        },
        'impressions',
      )
      .then((updated) => {
        showSuccessMessage(
          'Impressions updated successfully',
          `The impressions are now ${updated.impressions}`,
        )
        setEditTotal(false)
        setEditImpMatch(false)
        setEditDaily(false)
        onUpdateOrderLine(updated)
      })
      .catch((err) => {
        if (err) {
          showErrorMessage('Impression update failed', 'Please try again.')
        }
      })
  }
  useEffect(() => {
    if (orderLine && orderLine.impressions && dayCount) {
      const impsPerMatch = orderLine.impressions / matches
      const dailyImps = orderLine.impressions / dayCount
      setDaily(String(Math.round(dailyImps)))
      setImpMatch(String(Math.round(impsPerMatch)))
    }
  }, [matches])
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      if (editImpMatch) handleEditImpMatch(impMatch)
      if (editDaily) handleEditDaily(daily)
      if (editTotal) handleEditTotal(total)
      setLoading(false)
    }, 2000)
  }, [impMatch, daily, total])
  const handleEditTotal = (val: string) => {
    if (!dayCount) return
    const perDay = Number(val) / dayCount
    const perImp = Number(val) / matches
    setImpMatch(String(Math.round(perImp)))
    setDaily(String(Math.round(perDay)))
  }

  const handleEditImpMatch = (val: string) => {
    if (!dayCount) return
    const imps = Number(val) * matches
    const perDay = imps / dayCount
    setDaily(String(Math.round(perDay)))
    setTotal(String(imps))
  }
  const handleEditDaily = (val: string) => {
    if (!dayCount) return
    const imps = Number(val) * dayCount
    const perImp = imps / matches
    setTotal(String(imps))
    setImpMatch(String(Math.round(perImp)))
  }

  // useOnClickOutside(textRef, handleOffClick)
  if (editDeployedOL && drawerClose) {
    return (
      <div className="OrderLineImpressionsEdit">
        <TextHeader type={4}>Edit impressions</TextHeader>
        <div className="OrderLineImpressionsEdit__body border-t-thin border-primary mt-2 pt-2">
          {/* Total impressions widget */}
          <div className="OrderLineImpressionsEdit OrderLineImpressionsEdit__row mt-2">
            {editTotal ? (
              <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__deployed is-editable">
                <Button
                  kind="text"
                  onClick={() => {
                    if (editTotal) {
                      handleOffClick()
                    } else {
                      setEditTotal(false)
                    }
                  }}
                  disabled={loading}
                >
                  Edit Total Impressions:
                </Button>
              </div>
            ) : (
              <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__deployed">
                <Button
                  kind="text"
                  onClick={() => {
                    handleOffClick()
                    setEditTotal(true)
                  }}
                  disabled={loading}
                >
                  Edit Total Impressions:
                </Button>
              </div>
            )}
            {editTotal ? (
              <div className="OrderLineImpressionsEdit__amount">
                <input
                  ref={textRef}
                  placeholder={String(orderLine.impressions)}
                  className="OrderLineImpressionsEdit__input is-editable"
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                  type="number"
                  min={
                    roles?.includes('nextgen_admin')
                      ? 0 // no limit if admin
                      : currentOrg?.minimumImpressions || IMPRESSION_MIN
                  }
                  // onBlur={() => {
                  //   console.log('total')
                  // }}
                  // onKeyDown={(e) => {
                  //   if (e.key === '.' || e.key === 'e' || e.key === 'E')
                  //     e.preventDefault()
                  //   if (e.key === 'Enter') {
                  //     console.log('enter key')
                  //   }
                  // }}
                />

                <Button
                  kind="text"
                  iconRight={<Fa icon="times" size={1} />}
                  onClick={() => setEditTotal(false)}
                  disabled={loading}
                />
              </div>
            ) : (
              <div className="OrderLineImpressionsEdit__amount">
                <Button
                  kind="text"
                  iconRight={
                    loading ? (
                      <Fa icon="spinner" animate="spin" size={1} />
                    ) : (
                      <Fa icon="pencil" size={1} />
                    )
                  }
                  onClick={() => {
                    handleOffClick()
                    setEditTotal(true)
                  }}
                  disabled={loading}
                >
                  {addCommas(total) || addCommas(String(orderLine.impressions))}
                </Button>
              </div>
            )}
          </div>

          {/* Daily impression */}

          <div className="OrderLineImpressionsEdit OrderLineImpressionsEdit__row">
            {editDaily ? (
              <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__deployed is-editable">
                <Button
                  kind="text"
                  onClick={() => {
                    if (editDaily) {
                      handleOffClick()
                    } else {
                      setEditDaily(false)
                    }
                  }}
                  disabled={loading}
                >
                  {`Daily Impressions for ${dayCount} days:`}
                </Button>
              </div>
            ) : (
              <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__deployed">
                <Button
                  kind="text"
                  onClick={() => {
                    handleOffClick()
                    setEditDaily(true)
                  }}
                  disabled={loading}
                >
                  {`Daily Impressions for ${dayCount} days:`}
                </Button>
              </div>
            )}
            {editDaily ? (
              <div className="OrderLineImpressionsEdit__amount">
                <input
                  ref={textRef}
                  className="OrderLineImpressionsEdit__input is-editable"
                  value={daily}
                  onChange={(e) => setDaily(e.target.value)}
                  type="number"
                  min={2000}
                />
                <Button
                  kind="text"
                  iconRight={<Fa icon="times" size={1} />}
                  onClick={() => setEditDaily(false)}
                  disabled={loading}
                />
              </div>
            ) : (
              <div className="OrderLineImpressionsEdit__amount">
                <Button
                  kind="text"
                  iconRight={
                    loading ? (
                      <Fa icon="spinner" animate="spin" size={1} />
                    ) : (
                      <Fa icon="pencil" size={1} />
                    )
                  }
                  onClick={() => {
                    handleOffClick()
                    setEditDaily(true)
                  }}
                  disabled={loading}
                >
                  {addCommas(daily)}
                </Button>
              </div>
            )}
          </div>
          <div className="flex flex-row-reverse pt-4">
            {currentOrg?.minimumImpressions &&
              Number(total) < currentOrg?.minimumImpressions &&
              !roles?.includes('nextgen_admin') && (
                <Text className="text-danger-400 font-bold">
                  Impressions must be greater than{' '}
                  {currentOrg.minimumImpressions}. Please update your
                  daily/total impressions to meet the required minimum.
                </Text>
              )}
            {currentOrg?.minimumImpressions &&
              Number(total) < currentOrg?.minimumImpressions &&
              roles?.includes('nextgen_admin') && (
                <Text className="text-warning-400 font-bold">
                  You are now going lower than the current org minimum of{' '}
                  {currentOrg.minimumImpressions}.
                </Text>
              )}
          </div>
        </div>
        <div className="OrderLineImpressionsEdit__buttons ButtonGroup flex w-full justify-end gap-4 pt-4">
          <Button kind="default" onClick={() => drawerClose()}>
            Cancel
          </Button>
          <Button
            type="button"
            kind="primary"
            disabled={
              loading ||
              (!roles?.includes('nextgen_admin') &&
                currentOrg &&
                !!currentOrg.minimumImpressions &&
                Number(total) < currentOrg?.minimumImpressions)
            }
            onClick={() => handleSaveTotal(total)}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="OrderLineImpressionsEdit">
      <div className="OrderLineImpressionsEdit__header">
        <TextHeader
          className="OrderLineImpressionsEdit__header-h3 flex gap-2 pt-3"
          type={3}
        >
          <div className="OrderLineImpressionsEdit__name">
            {orderLine.name}:
          </div>
          <Text size="2xl" weight="normal">
            Edit Impressions
          </Text>
        </TextHeader>

        <Text size="m">
          {orderLine.creatives?.length} {orderLineCreativeType}
        </Text>
      </div>
      <div className="OrderLineImpressionsEdit__body w-[80%]">
        {/* Total impressions widget */}
        <div className="OrderLineImpressionsEdit OrderLineImpressionsEdit__row">
          {editTotal ? (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review is-editable">
              <Button
                kind="text"
                onClick={() => {
                  if (editTotal) {
                    handleOffClick()
                    handleSaveTotal(total)
                  } else {
                    setEditTotal(false)
                  }
                }}
                disabled={loading}
              >
                Total Impressions:
              </Button>
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review">
              <Button
                kind="text"
                onClick={() => {
                  handleOffClick()
                  setEditTotal(true)
                }}
                disabled={loading}
              >
                Total Impressions:
              </Button>
            </div>
          )}
          {editTotal ? (
            <div className="OrderLineImpressionsEdit__amount">
              <input
                ref={textRef}
                placeholder={String(orderLine.impressions)}
                className="OrderLineImpressionsEdit__input is-editable"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
                type="number"
                min={
                  roles?.includes('nextgen_admin')
                    ? 0 // no limit if admin
                    : currentOrg?.minimumImpressions || IMPRESSION_MIN
                }
                // onBlur={() => {
                //   console.log('total')
                // }}
                // onKeyDown={(e) => {
                //   if (e.key === '.' || e.key === 'e' || e.key === 'E')
                //     e.preventDefault()
                //   if (e.key === 'Enter') {
                //     console.log('enter key')
                //   }
                // }}
              />
              <Button
                kind="text"
                iconRight={<Fa icon="save" size={1} />}
                onClick={() => handleSaveTotal(total)}
                disabled={loading}
              />
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__amount">
              <Button
                kind="text"
                iconOnly={<Fa icon="pencil" size={1} />}
                onClick={() => {
                  handleOffClick()
                  setEditTotal(true)
                }}
                disabled={loading}
              >
                {addCommas(total) || addCommas(String(orderLine.impressions))}
              </Button>
            </div>
          )}
        </div>

        {/* Daily impression */}

        <div className="OrderLineImpressionsEdit OrderLineImpressionsEdit__row">
          {editDaily ? (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review is-editable">
              <Button
                kind="text"
                onClick={() => {
                  if (editDaily) {
                    handleSaveTotal(total)
                  } else {
                    setEditDaily(false)
                  }
                }}
                disabled={loading}
              >
                {`Daily Impressions for ${dayCount} days:`}
              </Button>
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review">
              <Button
                kind="text"
                onClick={() => {
                  handleOffClick()
                  setEditDaily(true)
                }}
                disabled={loading}
              >
                {`Daily Impressions for ${dayCount} days:`}
              </Button>
            </div>
          )}
          {editDaily ? (
            <div className="OrderLineImpressionsEdit__amount">
              <input
                ref={textRef}
                className="OrderLineImpressionsEdit__input is-editable"
                value={daily}
                onChange={(e) => setDaily(e.target.value)}
                type="number"
                min={2000}
              />
              <Button
                kind="text"
                iconRight={<Fa icon="save" size={1} />}
                onClick={() => handleSaveTotal(total)}
                disabled={loading}
              />
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__amount">
              <Button
                kind="text"
                iconOnly={<Fa icon="pencil" size={1} />}
                onClick={() => {
                  handleOffClick()
                  setEditDaily(true)
                }}
                disabled={loading}
              >
                {addCommas(daily)}
              </Button>
            </div>
          )}
        </div>

        {/* Impression match */}
        <div className="OrderLineImpressionsEdit OrderLineImpressionsEdit__row">
          {editImpMatch ? (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review is-editable">
              <Button
                kind="text"
                onClick={() => {
                  if (editImpMatch) {
                    handleSaveTotal(total)
                  } else {
                    setEditImpMatch(false)
                  }
                }}
                disabled={loading}
              >
                {`Impressions/match at ${matches} matches:`}
              </Button>
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__label OrderLineImpressionsEdit__label__review">
              <Button
                kind="text"
                onClick={() => {
                  handleOffClick()
                  setEditImpMatch(true)
                }}
                disabled={loading}
              >
                {`Impressions/match at ${matches} matches:`}
              </Button>
            </div>
          )}
          {editImpMatch ? (
            <div className="OrderLineImpressionsEdit__amount">
              <input
                ref={textRef}
                className="OrderLineImpressionsEdit__input is-editable"
                value={impMatch}
                placeholder={impMatch}
                onChange={(e) => setImpMatch(e.target.value)}
                type="number"
                min={5000}
              />
              <Button
                kind="text"
                iconRight={<Fa icon="save" size={1} />}
                onClick={() => handleSaveTotal(total)}
                disabled={loading}
              />
            </div>
          ) : (
            <div className="OrderLineImpressionsEdit__amount">
              <Button
                kind="text"
                iconOnly={<Fa icon="pencil" size={1} />}
                onClick={() => {
                  handleOffClick()
                  setEditImpMatch(true)
                }}
                disabled={loading}
              >
                {addCommas(impMatch)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
