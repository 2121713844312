import React, { useEffect, useState } from 'react'
import {
  Uploader,
  PreviewType,
  TextBody,
  Button,
  Fa,
  showErrorMessage,
} from '@eltoro-ui/components'
import { Error500 } from 'Components'
import { uploadOrgLogo } from 'Requests'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { FileRejection } from 'react-dropzone'
import { v2SyncPrompt } from 'Helpers'

export const LogoBlock: React.FC<{ currentOrg: Orgmanagerv1Org }> = ({
  currentOrg,
}) => {
  const [showUploader, setShowUploader] = useState(false)
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const [loadingLogo, setLoadingLogo] = useState(false)
  const [error, setError] = useState<string>()
  const {
    tok,
    refreshCurrentOrg,
    roles,
    orgServiceApi,
    isAdmin,
  } = useAppContext()

  // We may want to request to recieve the url like we see on the creative files in the future
  const logoSrc =
    currentOrg?.logo?.key &&
    `https://s3.amazonaws.com/c.eltoro.com/${currentOrg.logo.key}`

  const removeLogoFromOrg = () => {
    orgServiceApi
      ?.advertisingPlatformServiceDeleteOrgLogo(currentOrg?.id || '')
      .then(() => {
        setShowUploader(false)
        refreshCurrentOrg()
      })
      .catch(() => {
        showErrorMessage(
          'Error removing Logo ',
          "There was an error when attempting to remove the org's logo",
        )
      })
  }
  const updateOrgLogo = (
    files: PreviewType[],
    rejected: FileRejection[] | undefined,
  ) => {
    setError(undefined)
    if (!currentOrg.id || !tok) return

    // Only upload one file
    const [file] = files
    setLoadingLogo(true)
    if (!file || rejected?.length) {
      if (rejected?.length) {
        const [rejectedFile] = rejected
        setError(rejectedFile.errors[0].message)
      }
      setLoadingLogo(false)
      return
    }
    uploadOrgLogo(file.name, file, tok, currentOrg.id)
      .then(() => {
        setShowUploader(false)
        refreshCurrentOrg()
      })
      .catch(() => {
        showErrorMessage(
          'Error',
          'There was an error uploading your org logo. Please try again. If the issue persists, please submit a support request.',
        )
      })
  }
  useEffect(() => {
    // reset when org logo changes
    setShowPlaceholder(false)
    setLoadingLogo(false)
  }, [currentOrg.logo])

  if (!currentOrg) return <Error500 />
  return (
    <div
      className={`LogoBlock relative flex flex-col ${
        showUploader
          ? 'LogoBlock__Uploader mb-2 mt-2 gap-2'
          : 'LogoBlock__wLogo -mb-16'
      }`}
    >
      {showUploader ? (
        <div className="LogoBlock__uploader flex flex-col items-center transition-all">
          <Uploader isStyled maxFiles={1} accepts="logo" onDrop={updateOrgLogo}>
            <div className="flex items-center justify-center">
              <TextBody type={1}>
                Click to upload or drag image file here from your computer
              </TextBody>
            </div>
          </Uploader>
          {error && (
            <div className="LogoBlock__error bg-danger-50 text-danger animate-slidedown my-1 flex items-center gap-4 rounded-md p-2">
              {error}
            </div>
          )}
        </div>
      ) : (
        <div className="LogoImageWrap flex flex-col">
          {!currentOrg.logo ? (
            <div className="LogoImage NoLogoFound bg-primary !bg-primary-600 relative z-10 m-auto flex h-32 w-32 flex-col rounded-full">
              <Fa
                className="text-white"
                icon={isAdmin ? 'user-bounty-hunter' : '‘question-mark'}
                size={8}
              />
            </div>
          ) : (
            <div className="LogoImage bg-primary mx-auto flex h-32 w-32 flex-col items-center justify-center overflow-hidden rounded-full text-white">
              {!loadingLogo ? (
                <img
                  src={logoSrc}
                  alt={currentOrg.logo.name}
                  onError={(e) => {
                    if (e) {
                      setShowPlaceholder(true)
                    }
                  }}
                  className={`LogoBlock__logo-img h-full w-full object-cover ${
                    showPlaceholder && 'hidden'
                  }`}
                />
              ) : (
                <Fa
                  className="text-white"
                  animate="spin"
                  icon="spinner"
                  size={5}
                />
              )}
              {!loadingLogo && showPlaceholder && (
                <Fa className="text-white" icon="image" size={5} />
              )}
            </div>
          )}
        </div>
      )}
      {roles && !roles.includes('nextgen_read_only') && (
        <div className="LogoEditButton flex w-full flex-col items-center justify-center bg-transparent">
          {showUploader ? (
            <div className="flex gap-2">
              <Button
                className="CancelButton"
                kind="default"
                iconRight={<Fa icon="times" size={1} />}
                onClick={() => {
                  setShowUploader(false)
                  setError(undefined)
                }}
              >
                Cancel
              </Button>
              {currentOrg?.logo?.key && (
                <Button
                  className="CancelButton"
                  kind="danger"
                  iconRight={<Fa icon="times" size={1} />}
                  onClick={() => v2SyncPrompt(removeLogoFromOrg, currentOrg)}
                >
                  Remove Logo
                </Button>
              )}
            </div>
          ) : (
            <div className="LogoBlock__upload-button relative z-10">
              <Button
                className="EditButton bg-base hover:bg-primary-500 hover:border-base"
                iconOnly={<Fa icon="pencil" size={1} />}
                onClick={() =>
                  v2SyncPrompt(() => {
                    setShowUploader(true)
                    setError(undefined)
                  }, currentOrg)
                }
                title="Edit Logo"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
