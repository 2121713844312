import { useState } from 'react'
import { Button, Fa, Text, TextInput } from '@eltoro-ui/components'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { IDChangeType, OptionalIdEditGridRow } from './OptionalIdEdit'
import { TabletLabel } from '../shared'

const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')

const EditInput = ({
  placeholder,
  onChange,
  changeKey,
  type,
  orderLine,
  initialValue,
}: {
  placeholder: string
  onChange: (change: IDChangeType) => void
  changeKey: string
  type: string
  orderLine: Campaignservicev1OrderLine
  initialValue?: string
}) => {
  const [input, setInput] = useState(initialValue || '')
  const [editing, setEditing] = useState(false)

  return (
    <div className="flex min-h-[3rem] w-full items-center justify-between gap-4">
      {editing ? (
        <TextInput
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          value={input}
          className="animate-fadein"
        />
      ) : (
        <Text className="animate-fadein">{input || '(None)'}</Text>
      )}
      <div className="flex gap-2">
        {editing && (
          <span aria-label={`Save ${type}`} data-tooltip="bottom">
            <Button
              onClick={() => {
                if (!orderLine.id) return
                setEditing(!editing)
                onChange({ olId: orderLine.id, [changeKey]: input })
              }}
              iconOnly={<Fa icon="save" size={1} />}
              disabled={input === initialValue}
            />
          </span>
        )}
        <span
          aria-label={editing ? `Cancel ${type}` : `Edit ${type}`}
          data-tooltip="bottom"
        >
          <Button
            onClick={() => {
              setEditing(!editing)
              setInput(initialValue || '')
            }}
            iconOnly={<Fa icon={editing ? 'xmark' : 'pencil'} size={1} />}
          />
        </span>
        {!editing && (
          <span aria-label={`Delete ${type}`} data-tooltip="bottom">
            <Button
              onClick={() => {
                if (!orderLine.id) return
                onChange({ olId: orderLine.id, [changeKey]: '' })
                setInput('')
              }}
              iconOnly={<Fa icon="trash" size={1} />}
            />
          </span>
        )}
      </div>
    </div>
  )
}

export const OptionalIdEditRow = ({
  orderLine,
  onChange,
}: {
  orderLine: Campaignservicev1OrderLine
  onChange: (change: IDChangeType) => void
}) => {
  const sharedProps = {
    orderLine,
    onChange,
  }
  return (
    <OptionalIdEditGridRow className="EditOLRowItem">
      <EditOLRowItem>
        <TabletLabel>Order Line Name</TabletLabel>
        <Text>{orderLine.name}</Text>
      </EditOLRowItem>
      <EditOLRowItem>
        <TabletLabel>Job ID</TabletLabel>
        <EditInput
          type="Job ID"
          changeKey="jobId"
          initialValue={orderLine.jobId}
          placeholder="Enter job ID here"
          {...sharedProps}
        />
      </EditOLRowItem>
      <EditOLRowItem>
        <TabletLabel>P.O. #</TabletLabel>
        <EditInput
          type="P.O. ID"
          changeKey="poId"
          initialValue={orderLine.poId}
          placeholder="Enter P.O. ID # here"
          {...sharedProps}
        />
      </EditOLRowItem>
    </OptionalIdEditGridRow>
  )
}
