import { useState, useEffect } from 'react'
import { Button, Fa, Text } from '@eltoro-ui/components'
import classNames from 'classnames'
import { useMediaQuery, useValidatedState } from '@eltoro-ui/hooks'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { styleTailwind, simplifyEnum, urlHasUTM } from 'Helpers'
import { TextInputWithErrorText } from 'Components/TextInputWithErrorText'
import { CreativeRowCarousel } from './CreativeRowCarousel'
import { ModifiedOrderLineType } from '../types'
import { ClickThroughWithUTM } from 'Components/AdSetForms/components/ClickThroughWithUTM'

const HeaderText = styleTailwind(Text, 'EditOLTable__header-text')
const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')
const TableGrid = styleTailwind(
  'div',
  'EditOLTable__TableGrid laptopsmall:flex laptopsmall:flex-wrap laptopsmall:justify-between laptopsmall:gap-y-4',
)
const TabletHeader = styleTailwind(
  HeaderText,
  'EditOLTable__TabletHeader block',
)
const UTMEditorButton = styleTailwind(Button, 'UTMEditorButton text-primary')
const UTMEditorTooltip = styleTailwind('span', 'UTMEditorTooltip w-auto')

export type CreativeEditOLTableProps = {
  modifiedOrderLines: ModifiedOrderLineType[]
  handleUpdateClickThru: (
    clickThroughUrl: string,
    modifiedOL: ModifiedOrderLineType,
  ) => void
  handleAddCreative: (
    newCreative: Creativeservicev1Creative,
    orderLine?: ModifiedOrderLineType,
  ) => void
  handleRemoveCreative: (
    orderLine: ModifiedOrderLineType,
    creative?: Creativeservicev1Creative,
  ) => void
  handleSwapCreative: (
    newCreative: Creativeservicev1Creative,
    oldCreative: Creativeservicev1Creative,
    orderLine: ModifiedOrderLineType,
  ) => void
  loadingBrandUrls: boolean
  handleUpdateBrandURL?: (
    brandUrls: string[],
    modifiedOL: ModifiedOrderLineType,
  ) => void
  handleRemoveBrandURL?: (orderLine: ModifiedOrderLineType) => void
  modalClose?: () => void
}

const CreativeEditOLTableOrderLineRow = ({
  orderLine,
  modifiedOrderLines,
  handleUpdateClickThru,
  handleAddCreative,
  handleRemoveCreative,
  handleSwapCreative,
  loadingBrandUrls,
  handleUpdateBrandURL,
  handleRemoveBrandURL,
  modalClose,
}: {
  orderLine: ModifiedOrderLineType
} & CreativeEditOLTableProps) => {
  const [editingClickThru, setEditingClickThru] = useState(false)
  const [editingBrandUrl, setEditingBrandUrl] = useState(false)
  const [clickThru, setClickThru, isValidClickThru] = useValidatedState(
    orderLine.clickThroughUrl || 'https://',
    'url',
  )
  const [brandUrl, setBrandUrl, isValidBrandURL] = useValidatedState(
    'https://',
    'url',
  )

  const orderLineType = simplifyEnum(2, orderLine.adType || '', true)
  const creativeCount = (orderLine.creatives && orderLine.creatives.length) || 0

  useEffect(() => {
    // reset the text input value if parent changes
    setClickThru(orderLine.clickThroughUrl || '')
    if (orderLine.brandUrls?.[0]) setBrandUrl(orderLine.brandUrls?.[0])
  }, [
    orderLine.brandUrls,
    orderLine.clickThroughUrl,
    setBrandUrl,
    setClickThru,
  ])

  const [isTablet] = useMediaQuery('(max-width: 860px)')

  return (
    <TableGrid className="EditOLRowItem CreativeEditOLTableOrderLineRow">
      <EditOLRowItem className="EditOLRowItem__name break-words">
        {isTablet && <TabletHeader>Order Line Name</TabletHeader>}
        <Text className="OrderLineName break-all">
          {orderLine.name || 'No name'}
        </Text>
      </EditOLRowItem>
      <EditOLRowItem className="EditOLRowItem__ol-creative-info">
        {isTablet && <TabletHeader>Type & Count</TabletHeader>}

        <Text>
          {orderLineType}; {creativeCount}
        </Text>
      </EditOLRowItem>
      <EditOLRowItem className="EditOLRowItem__ol-creatives">
        {isTablet && <TabletHeader>Creatives</TabletHeader>}
        <CreativeRowCarousel
          orderLine={orderLine}
          modifiedOrderLines={modifiedOrderLines}
          handleAddCreative={handleAddCreative}
          handleRemoveCreative={handleRemoveCreative}
          handleUpdateClickThru={handleUpdateClickThru}
          handleSwapCreative={handleSwapCreative}
          modalClose={modalClose}
        />
      </EditOLRowItem>
      <div className="EditOLRowItem__ol-urls EditOLRowItem__item flex flex-col gap-2 p-4">
        {isTablet && <TabletHeader>Click-through URL & Tracking</TabletHeader>}
        <div className="ClickThruWrap border-tint-light-100 border-b-thin mb-2 flex w-full items-center justify-between gap-2 pb-4">
          {editingClickThru ? (
            <>
              <ClickThroughWithUTM
                OLName={''}
                clickThru={clickThru}
                setClickThru={setClickThru}
                isValidClickThru={isValidClickThru}
                preview
              />
              <UTMEditorTooltip
                className="mt-2"
                data-tooltip="top"
                aria-label="Save Click-through"
              >
                <Fa
                  icon="save"
                  size={1}
                  className={classNames('Button', {
                    'border-grey-200 text-grey-200 pointer-events-none': !isValidClickThru,
                  })}
                  onClick={() => {
                    handleUpdateClickThru(clickThru, orderLine)
                    setEditingClickThru(!editingClickThru)
                  }}
                />
              </UTMEditorTooltip>
            </>
          ) : (
            <div className="flex-1">
              <span className="font-bold">Click-Through URL:</span>
              <span
                className={classNames(
                  'flex max-w-[80ch] flex-1 gap-2 break-all py-1 text-xs',
                  {
                    'text-warning font-bold': !orderLine.clickThroughUrl,
                  },
                )}
              >
                {orderLine?.clickThroughUrl && (
                  <a
                    href={orderLine.clickThroughUrl.split('?')[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="OrderLine__clickThroughUrl flex items-center gap-2 py-2"
                  >
                    {orderLine.clickThroughUrl?.split('?')[0] ||
                      'No click-through for creative'}
                    <Fa
                      icon="arrow-up-right-from-square"
                      size={1}
                      className="text-primary inline-block pl-2 !text-xs"
                    />
                  </a>
                )}
                {urlHasUTM(clickThru) && (
                  <UTMEditorTooltip
                    className="text-primary ml-4 flex items-center justify-center font-semibold hover:cursor-pointer"
                    data-tooltip="top"
                    aria-label={`${clickThru.split('?')[1]}`}
                  >
                    <UTMEditorButton
                      className="!gap-0 !pl-1 !pr-3"
                      kind="text"
                      iconLeft={<Fa icon="check" size={1} />}
                      onClick={() => setEditingClickThru(!editingClickThru)}
                    >
                      UTM
                    </UTMEditorButton>
                  </UTMEditorTooltip>
                )}
              </span>
            </div>
          )}
          <UTMEditorTooltip
            className="mt-2"
            data-tooltip="top"
            aria-label={
              editingClickThru ? 'Cancel Edit' : 'Edit Click-through URL'
            }
          >
            <UTMEditorButton
              iconOnly={
                <Fa icon={editingClickThru ? 'xmark' : 'pencil'} size={1} />
              }
              onClick={() => setEditingClickThru(!editingClickThru)}
            />
          </UTMEditorTooltip>
        </div>

        <div className="BrandURLWrap flex w-full items-center justify-between gap-2">
          {(() => {
            if (loadingBrandUrls)
              return (
                <span className="BrandURLLoader animate-pulse">
                  Loading Brand URLs
                </span>
              )
            if (editingBrandUrl)
              return (
                <>
                  <TextInputWithErrorText
                    value={brandUrl}
                    onChange={(e) => setBrandUrl(e.target.value)}
                    isValid={isValidBrandURL && brandUrl.length <= 1000}
                    baseValues={['http://', 'https://']}
                    maxLength={1000}
                    popoverContent={(() => {
                      if (!brandUrl.includes('http'))
                        return <div>URL requires &quot;http(s)://&quot;</div>
                      return (
                        <div>
                          Please enter a valid url in the format:
                          &quot;https://www.websitename.com&quot;
                        </div>
                      )
                    })()}
                  />
                  <UTMEditorTooltip
                    className="self-center"
                    data-tooltip="top"
                    aria-label="Save Brand URL"
                  >
                    <UTMEditorButton
                      className={classNames({
                        'border-grey-200 text-grey-200 pointer-events-none': !isValidBrandURL,
                      })}
                      iconOnly={<Fa icon="save" size={1} />}
                      onClick={() => {
                        if (handleUpdateBrandURL)
                          handleUpdateBrandURL([brandUrl], orderLine)
                        setEditingBrandUrl(!editingBrandUrl)
                      }}
                    />
                  </UTMEditorTooltip>
                  <UTMEditorTooltip
                    className="self-center"
                    data-tooltip="top"
                    aria-label="Remove Brand URL"
                  >
                    <UTMEditorButton
                      iconOnly={<Fa icon="trash-xmark" size={1} />}
                      onClick={() => {
                        if (handleRemoveBrandURL)
                          handleRemoveBrandURL(orderLine)
                        setEditingBrandUrl(!editingBrandUrl)
                      }}
                    />
                  </UTMEditorTooltip>
                </>
              )

            return (
              <div>
                <p className="font-bold">{`(Optional) Brand URL${
                  (orderLine.brandUrls || []).length > 1 ? 's' : ''
                }:`}</p>
                {(orderLine.brandUrls || []).length === 0 && (
                  <Button
                    className="hover:!text-base-info !bg-transparent px-0"
                    kind="text"
                    onClick={() => setEditingBrandUrl(!editingBrandUrl)}
                  >
                    <span className="text-m normal-case">
                      No brand url is set
                    </span>
                  </Button>
                )}
                {(orderLine.brandUrls || []).length > 0 && (
                  <div className="OrderLine__brandUrls flex items-center break-all text-left leading-none">
                    {(orderLine.brandUrls || []).map((currentBrand, i) => {
                      return (
                        <div key={`${currentBrand}_${i}`}>
                          {currentBrand.length > 8 ? (
                            <a
                              className="OrderLine__brandUrls--link text-m flex items-center gap-2 py-2"
                              href={currentBrand}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>{currentBrand}</span>
                              <Fa
                                icon="arrow-up-right-from-square"
                                size={1}
                                className="text-primary !text-xs"
                              />
                            </a>
                          ) : (
                            <Button
                              className="hover:!text-base-info !bg-transparent px-0"
                              kind="text"
                              onClick={() =>
                                setEditingBrandUrl(!editingBrandUrl)
                              }
                            >
                              <span className="text-m px-0 normal-case">
                                No brand url
                              </span>
                            </Button>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })()}
          <UTMEditorTooltip
            className="self-center"
            data-tooltip="top"
            aria-label={editingBrandUrl ? 'Cancel Edit' : 'Edit Brand URL'}
          >
            <UTMEditorButton
              className={classNames({
                'border-grey-200 text-grey-200 pointer-events-none': loadingBrandUrls,
              })}
              iconOnly={
                <Fa icon={editingBrandUrl ? 'xmark' : 'pencil'} size={1} />
              }
              onClick={() => setEditingBrandUrl(!editingBrandUrl)}
            />
          </UTMEditorTooltip>
        </div>
      </div>
    </TableGrid>
  )
}

export const CreativeEditOLTable = ({
  modifiedOrderLines,
  handleAddCreative,
  handleRemoveCreative,
  handleUpdateClickThru,
  loadingBrandUrls,
  handleUpdateBrandURL,
  handleRemoveBrandURL,
  handleSwapCreative,
  modalClose,
}: CreativeEditOLTableProps) => {
  const [isDesktop] = useMediaQuery('(min-width: 861px)')
  return (
    <div className="EditOLTable w-full">
      {isDesktop && (
        <TableGrid className="EditOLTable__header StickyHeader">
          <HeaderText>Order Line Name</HeaderText>
          <HeaderText>Type & Count</HeaderText>
          <HeaderText>Creative</HeaderText>
          <HeaderText>Click-through URL & Tracking</HeaderText>
        </TableGrid>
      )}
      <div className="EditOLTable__body">
        {modifiedOrderLines.map((orderLine, index) => (
          <CreativeEditOLTableOrderLineRow
            key={`CreativeEditOLTableOrderLineRow_${
              orderLine.id || orderLine.tempId
            }_${index}`}
            orderLine={orderLine}
            modifiedOrderLines={modifiedOrderLines}
            handleAddCreative={handleAddCreative}
            handleSwapCreative={handleSwapCreative}
            handleRemoveCreative={handleRemoveCreative}
            handleUpdateClickThru={handleUpdateClickThru}
            loadingBrandUrls={loadingBrandUrls}
            handleUpdateBrandURL={handleUpdateBrandURL}
            handleRemoveBrandURL={handleRemoveBrandURL}
            modalClose={modalClose}
          />
        ))}
      </div>
    </div>
  )
}
