import {
  CollapsibleContainer,
  FlexBox,
  Spacer,
  Fa,
} from '@eltoro-ui/components'
import React, { useState } from 'react'
import './CollapseSection.scss'

export const CollapseSection: React.FC<{ label: string }> = ({
  label,
  children,
}) => {
  const [open, setOpen] = useState(true)
  return (
    <div className="CollapseSection">
      <button
        onClick={() => setOpen(!open)}
        className="CollapseSection__trigger"
        type="button"
      >
        <FlexBox justifyContent="space-between">
          {label}
          <Fa size={1} icon={open ? 'chevron-up' : 'chevron-down'} />
        </FlexBox>
      </button>
      <CollapsibleContainer isOpen={open}>
        <div className="CollapseSection__body">
          <Spacer height=".5rem" />
          {children}
        </div>
      </CollapsibleContainer>
    </div>
  )
}
