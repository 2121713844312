import { PreviewType } from '@eltoro-ui/components'
import { getApiUrl } from 'Helpers'
import axios from 'axios'
import { V1OrgLogo } from 'next-gen-sdk'

export const uploadOrgLogo = async (
  name: string,
  file: PreviewType,
  token: string,
  orgId: string,
  onProgress?: (percentage: number) => void,
) => {
  const url = `${getApiUrl()}/v1`
  const config = { Authorization: `Bearer ${token}` }
  const data = new FormData()
  data.append(
    'metadata',
    JSON.stringify({
      filename: name,
      id: orgId,
    }),
  )
  data.append('chunk', file)

  const res = await axios.post(`${url}/orgs/${orgId}:uploadLogo`, data, {
    headers: config,
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      if (onProgress) onProgress(percentage)
    },
  })

  if (res.status !== 200) {
    throw new Error(
      JSON.stringify({ status: res.status, message: res.statusText }),
    )
  }
  if (!res.data.mime) {
    res.data.mime = file?.type
  }
  return res.data as V1OrgLogo
}
