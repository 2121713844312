import { V1StatsResponse } from 'next-gen-sdk'

export const fixtureStatsResponse: V1StatsResponse = {
  results: [
    {
      results: [
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-25T00:00:00.000Z',
          end: '2018-03-25T00:00:00.000Z',
        },
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-26T00:00:00.000Z',
          end: '2018-03-26T00:00:00.000Z',
        },
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-27T00:00:00.000Z',
          end: '2018-03-27T00:00:00.000Z',
        },
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-28T00:00:00.000Z',
          end: '2018-03-28T00:00:00.000Z',
        },
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-29T00:00:00.000Z',
          end: '2018-03-29T00:00:00.000Z',
        },
        {
          imps: 100,
          clicks: 5,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-30T00:00:00.000Z',
          end: '2018-03-30T00:00:00.000Z',
        },
        // yesterday
        {
          imps: 95,
          clicks: 4,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-03-31T00:00:00.000Z',
          end: '2018-03-31T00:00:00.000Z',
        },
        // today
        {
          imps: 45,
          clicks: 1,
          conversions: 5,
          impsViewed: 3,
          postClickConvs: 1,
          viewMeasuredImps: 4,
          start: '2018-04-01T00:00:00.000Z',
          end: '2018-04-01T00:00:00.000Z',
        },
      ],
      totals: {
        imps: 740,
        clicks: 41,
        conversions: 5,
        impsViewed: 3,
        postClickConvs: 1,
        viewMeasuredImps: 4,
      },
      details: {
        resultsCount: 8,
        ids: {
          orgId: 'djakflsdjklfdsjsldkf',
          campaignId: 'kadlsfjksda',
          orderLineId: 'kfjad-dafs-adsfadsf-sdafsd',
        },
      },
    },
  ],
  totals: {
    imps: 1000,
    clicks: 100,
    conversions: 5,
    impsViewed: 3,
    postClickConvs: 1,
    viewMeasuredImps: 4,
  },
  details: {
    timezone: 'UTC',
    hourCount: 4,
    resultsCount: 10,
    levelOfDetail: [],
    start: '',
    end: '',
    ids: [],
  },
}

export const fixture2: V1StatsResponse = {
  results: [
    {
      results: [
        {
          start: '2022-04-28T00:00:00.000Z',
          end: '2022-04-28T23:59:59.000Z',
        },
        {
          start: '2022-04-29T00:00:00.000Z',
          end: '2022-04-29T23:59:59.000Z',
        },
        {
          start: '2022-04-30T00:00:00.000Z',
          end: '2022-04-30T23:59:59.000Z',
        },
        {
          start: '2022-05-01T00:00:00.000Z',
          end: '2022-05-01T23:59:59.000Z',
        },
        {
          start: '2022-05-02T00:00:00.000Z',
          end: '2022-05-02T23:59:59.000Z',
        },
        {
          start: '2022-05-03T00:00:00.000Z',
          end: '2022-05-03T23:59:59.000Z',
        },
        {
          start: '2022-05-04T00:00:00.000Z',
          end: '2022-05-04T23:59:59.000Z',
          clicks: 7,
          imps: 20567,
          impsViewed: 15396,
          viewMeasuredImps: 19790,
        },
        {
          start: '2022-05-05T00:00:00.000Z',
          end: '2022-05-05T23:59:59.000Z',
          clicks: 14,
          imps: 27278,
          impsViewed: 20664,
          viewMeasuredImps: 26133,
        },
      ],
      totals: {
        clicks: 21,
        imps: 47845,
        impsViewed: 36060,
        viewMeasuredImps: 45923,
      },
      details: {
        resultsCount: 8,
        ids: {
          orderLineId: 'tY6QXLFx5qE4tzXHd',
        },
      },
    },
  ],
  totals: {
    clicks: 21,
    imps: 47845,
    impsViewed: 36060,
    viewMeasuredImps: 45923,
  },
  details: {
    timezone: 'UTC',
    hourCount: 192,
    resultsCount: 1,
    levelOfDetail: ['order_line_id'],
    ids: [
      {
        orderLineId: 'tY6QXLFx5qE4tzXHd',
      },
    ],
    start: '2022-04-28T00:00:00.000Z',
    end: '2022-05-05T23:59:59.000Z',
  },
}

export const creativeStatsFixture: V1StatsResponse = {
  results: [
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 435,
          imps: 28690,
          impsViewed: 18634,
          viewMeasuredImps: 21468,
        },
      ],
      totals: {
        clicks: 435,
        imps: 28690,
        impsViewed: 18634,
        viewMeasuredImps: 21468,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '2CGhfhZs9T6LyRdwm',
        },
      },
    },
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 132,
          imps: 14721,
          impsViewed: 10203,
          viewMeasuredImps: 13968,
        },
      ],
      totals: {
        clicks: 132,
        imps: 14721,
        impsViewed: 10203,
        viewMeasuredImps: 13968,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '4J5mgWs4iKmhCtRde',
        },
      },
    },
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 18,
          imps: 1514,
          impsViewed: 1104,
          viewMeasuredImps: 1471,
        },
      ],
      totals: {
        clicks: 18,
        imps: 1514,
        impsViewed: 1104,
        viewMeasuredImps: 1471,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '4wPptPZfXD7dwHRYM',
        },
      },
    },
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 427,
          imps: 28632,
          impsViewed: 18617,
          viewMeasuredImps: 21404,
        },
      ],
      totals: {
        clicks: 427,
        imps: 28632,
        impsViewed: 18617,
        viewMeasuredImps: 21404,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '4yhmvrSQhzftDdgpa',
        },
      },
    },
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 21,
          imps: 1443,
          impsViewed: 1052,
          viewMeasuredImps: 1399,
        },
      ],
      totals: {
        clicks: 21,
        imps: 1443,
        impsViewed: 1052,
        viewMeasuredImps: 1399,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '76Cb2oatXdjyK2Xn6',
        },
      },
    },
    {
      results: [
        {
          start: '2022-03-07T00:00:00.000Z',
          end: '2022-05-11T23:59:59.000Z',
          clicks: 31,
          imps: 3617,
          impsViewed: 2559,
          viewMeasuredImps: 3470,
        },
      ],
      totals: {
        clicks: 31,
        imps: 3617,
        impsViewed: 2559,
        viewMeasuredImps: 3470,
      },
      details: {
        resultsCount: 1,
        ids: {
          orderLineId: '49oRzQgbbmkhgHbFC1',
          creativeId: '9c8MrFqiSxKfehJjx',
        },
      },
    },
  ],
  totals: {
    clicks: 7474,
    imps: 6549508,
    impsViewed: 4149075,
    viewMeasuredImps: 4950687,
  },
  details: {
    timezone: 'UTC',
    hourCount: 1584,
    resultsCount: 30,
    levelOfDetail: ['creativeId'],
    ids: [
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '2CGhfhZs9T6LyRdwm',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '4J5mgWs4iKmhCtRde',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '4wPptPZfXD7dwHRYM',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '4yhmvrSQhzftDdgpa',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '76Cb2oatXdjyK2Xn6',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: '9c8MrFqiSxKfehJjx',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'AxonjCkXNqyXsSKiP',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'DnjtjSNNGy6bpnPha',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'F38aGub6DydmkjojN',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'JMSHvEt3N5c2Jj7h8',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'JSf63SpJuBnt4DyqP',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'Jzr3QXyKHaFMWGp9z',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'Km3KDLtsxytvvDxe2',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'NGLgKkM5iXx4f5ZsJ',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'P4ySRawFDkCkRCmCY',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'PcYufzaAiLa4orXTJ',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'QRsD68NZMAJRJrZk7',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'R4WccpfqNhdW7snkg',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'TG6Fq569yYZXeqYDj',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'W6eN2DpJiBPj3sv7z',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'eBgT7u8244Gk6DZbb',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'eiF6PBygLZpqmpFip',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'fTfdWeJDD7ZrxPKfa',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'hmBX8ZqoG2pDuuKA3',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'jRWTQCZyPxndqcMCk',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'nRHragD9DyFPnLqJM',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'qnEgqfbJFNgWpmijq',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 's2B7TAsFrfc5s6Bf6',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'svW4ch9StuJSA42ub',
      },
      {
        orderLineId: '49oRzQgbbmkhgHbFC1',
        creativeId: 'xMWNnMZ3iCGgNWpN9',
      },
    ],
    start: '2022-03-07T00:00:00.000Z',
    end: '2022-05-11T23:59:59.000Z',
  },
}
