/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Information around an org logo file
*/
export class V1UploadOrgLogoMetadata {
    /**
    * The ID of the Org uploading the file
    */
    'id'?: string;
    /**
    * The name of the file for the Org Logo
    */
    'filename'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "filename",
            "baseName": "filename",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1UploadOrgLogoMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

