import { styleTailwind } from 'Helpers'

export const IMPRESSION_MAX = 180000 // From v2
export const IMPRESSION_MIN = 50000 // TODO: This should come from the org's minimum
// Should also bear in mind that an order line's minimum impressions can be overridden
// https://eltoroteam.slack.com/archives/C029B5WPMU3/p1669994655319479

export const NumberInput = styleTailwind(
  'input',
  'OLEdit__NumberInput border-tint-gray-300 border p-2 rounded min-w-[5rem] max-w-[7rem] tablet:w-full',
)

export const NA = <div className="font-light EditOLRowItem__item">N/A</div>

// These are used in place of the table headers when the screen size is small
export const TabletLabel = styleTailwind(
  'p',
  'OrderLineEditWrapper__TabletLabel EditOLTable__TabletHeader EditOLTable__header-text laptopsmall:block hidden',
)
export const LabelInputPair = styleTailwind(
  'div',
  'OrderLineEditWrapper__LabelInputPair laptopsmall:justify-between laptopsmall:flex-wrap flex items-center gap-2',
)
