import { useState } from 'react'
import { AutoScroller } from '@eltoro-ui/components'
import { BrandURLPop } from 'Components'
import { styleTailwind } from 'Helpers'
import { TextInputWithErrorText, UTMFormFields, UTMButton } from 'Components'

const UTMFormLabel = styleTailwind(
  'span',
  'UTMFormLabel items-center text-grey-600 flex w-auto desktop:pr-3 laptop:py-1 laptop:w-full desktop:whitespace-nowrap leading-none h-9 absolute z-20 -top-8',
)

export const ClickThroughWithUTM = ({
  className = '',
  clickThru,
  OLName,
  setClickThru,
  isValidClickThru,
  brand,
  preview,
}: {
  className?: string
  clickThru: string
  OLName: string
  setClickThru: (x: string) => void
  isValidClickThru: boolean
  brand?: boolean
  preview?: boolean
}) => {
  const [utmOpen, setUTMOpen] = useState<boolean>(false)
  return (
    <div
      className={`ClickThroughWithUTM__wrap ${
        brand ? 'BrandURL' : 'ClickThroughWithUTM'
      } flex flex-auto flex-col  ${className}`}
    >
      <div
        className={`${
          brand ? 'BrandURL' : 'ClickThroughWithUTM'
        }__input-button flex flex-auto items-start`}
      >
        {/* (It does have the associated control inside TextInputWithErrorText) */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor="BannerURL"
          className="relative flex flex-auto flex-wrap items-center"
        >
          {!preview && (
            <UTMFormLabel className="font-semibold">
              {brand ? <BrandURLPop /> : 'Click-through URL'}
            </UTMFormLabel>
          )}
          <TextInputWithErrorText
            id="BannerURL"
            className={`${brand ? '' : 'desktop:min-w-[36ch]'} flex-1`}
            value={clickThru}
            placeholder={clickThru}
            onChange={(e) => setClickThru(e.target.value)}
            isValid={isValidClickThru && clickThru.length <= 1000}
            baseValues={['http://', 'https://']}
            maxLength={1000}
            popoverContent={(() => {
              if (!clickThru.includes('http'))
                return (
                  <div className="text-warning">
                    URL requires &quot;http(s)://&quot;
                  </div>
                )
              return (
                <div className="text-warning">
                  Please enter a valid url under 1,000 characters:
                  &quot;https://www.websitename.com&quot;
                </div>
              )
            })()}
          />
        </label>
        {!brand && (
          <UTMButton
            className="UTMFormButton"
            text={utmOpen ? 'Close UTM' : 'Edit UTM'}
            onClick={() => {
              setUTMOpen((open) => !open)
            }}
          />
        )}
      </div>
      {utmOpen ? (
        <AutoScroller shouldScroll={utmOpen} scrollToTop className="UTM-form">
          <UTMFormFields
            clickThru={clickThru}
            OLName={OLName}
            setClickThru={setClickThru}
            onClose={() => setUTMOpen(false)}
          />
        </AutoScroller>
      ) : null}
    </div>
  )
}
