import { useState } from 'react'
import {
  Button,
  Checkbox,
  Fa,
  FormItem,
  Modal,
  TextInput,
  showErrorMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'
import {
  ErrorMessage,
  getEmailTemplate,
  isValidCharLength,
  validCheck,
} from 'Helpers'
import { supportRequest } from 'Requests'
import { useAppContext } from 'Contexts'
import { useAuth } from 'react-oidc-context'

export const UpdateNameModal = ({
  org,
  closeModal,
}: {
  org: Orgmanagerv1Org
  closeModal: () => void
}) => {
  const [name, setName] = useState(org.name || '')
  const [confirm, setConfirm] = useState(false)
  const [sendingRequest, setSendingRequest] = useState(false)
  const { isAdmin } = useAppContext()
  const auth = useAuth()

  const handleSubmit = async () => {
    setSendingRequest(true)

    const emailBody = `
  <p style="font-weight: bold; text-align: center; font-size: 20px">
    Your organization name is being changed!
  </p>
  <p>Greetings, ${org.name || ''} </p>
  <p>
    ${(() => {
      if (isAdmin) return 'An El Toro operator'
      if (auth?.user?.profile?.name) return auth?.user?.profile?.name
      return 'An organization admin'
    })()} has requested that the organization's name be changed in the
    El Toro Portal.
  </p>
  <table
    style="
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #bbbbbb;
    "
  >
    <tr style="border: 1px solid #bbbbbb">
      <td style="padding: 8px; border: 1px solid #bbbbbb; text-align: center">
        <strong>Current Organization Name</strong>
      </td>
      <td style="padding: 8px; border: 1px solid #bbbbbb; text-align: center">
        <strong>Requested Organization Name</strong>
      </td>
    </tr>
    <tr style="border: 1px solid #bbbbbb">
      <td style="padding: 8px; border: 1px solid #bbbbbb; text-align: center">
        ${org.name || ''}
      </td>
      <td style="padding: 8px; border: 1px solid #bbbbbb; text-align: center">
        ${name}
      </td>
    </tr>
  </table>
  <p>
    If you have any questions, please contact your Sales Executive or
    <a
      href="mailto:support@eltoro.com"
      style="color: #124e8a; text-decoration: underline"
      >support@eltoro.com</a
    >.
  </p>
  <p>Thank you for choosing El Toro!</p>
  <p style="font-size: 12px">
    <em
      >You are receiving this message as a notification. Please do not reply to
      this message.</em
    >
  </p>
  `

    if (!org.id) return
    await supportRequest(
      getEmailTemplate('Org Name Change Request', emailBody),
      'Your El Toro Organization name change request was received',
      org.id,
      'billing',
    )
      .then(() => {
        showSuccessMessage(
          'Your organization name change request has been sent.',
          'Responses to your request will be sent via email.',
        )
        closeModal()
      })
      .catch(() =>
        showErrorMessage(
          'There was an error sending your organization name change request.',
          'Please try again or contact support@eltoro.com.',
        ),
      )
      .finally(() => setSendingRequest(false))
  }

  return (
    <Modal
      className="Modal__change-org-name w-[50%]"
      offClick={closeModal}
      header="Change org name"
    >
      {sendingRequest && (
        <div className="UpdateNameModal__sending bg-base absolute top-0 bottom-0 right-0 left-0 z-40 overflow-clip p-2 opacity-60">
          <Fa
            className="text-tint-khaki"
            icon="spinner"
            size={6}
            animate="spin"
          />
        </div>
      )}
      <div className="Modal__change-org-name flex flex-col gap-2 p-1">
        <p>
          Please enter the updated name for <strong>{org.name}</strong>:
        </p>
        <FormItem
          htmlFor="new-org-name"
          label="Change name:"
          required
          errorMessage={ErrorMessage({
            fieldName: name,
            max: 255,
            label: 'Org name',
          })}
          valid={validCheck(name) && isValidCharLength(name, 255)}
        >
          <TextInput
            valid={isValidCharLength(name, 255)}
            hideValidIcon
            maxLength={256}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormItem>
        {name !== org.name && (
          <div className="Modal__change-org-name--confirm-note animate-slidedown flex flex-col gap-1 pt-4">
            {!confirm ? (
              <p className="bg-warning animate-slidedown rounded px-2 py-1 text-base font-bold">
                Confirm that you want to change your org's name
              </p>
            ) : (
              <p className="text-tint-gray-800 animate-slideleft ml-auto py-2 font-bold">
                It would take at least a day, to process your request
              </p>
            )}
            <Checkbox
              label={
                !confirm ? (
                  <span>
                    Change org's name to<strong> {name}</strong>
                  </span>
                ) : (
                  <span className="animate-slideleft font-bold">
                    Confirmed. Please change org's name to
                    <strong> {name}</strong>
                  </span>
                )
              }
              checked={confirm}
              onChange={setConfirm}
              classNameWrapperLabel="max-w-[unset]"
              disabled={name.length > 255 || name === org.name}
            />
          </div>
        )}

        <div className="UpdateNameModal__buttons mt-2 ml-auto flex gap-4">
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button
            kind="primary"
            disabled={name.length > 255 || name === org.name || !confirm}
            onClick={handleSubmit}
          >
            Send request
          </Button>
        </div>
      </div>
    </Modal>
  )
}
