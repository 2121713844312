import { useEffect, useState } from 'react'
import Cards, { Focused } from 'react-credit-cards'
import {
  useForm,
  useMask,
  useValidatedState,
  useValidation,
} from '@eltoro-ui/hooks'
import {
  Form,
  TextHeader,
  FormItem,
  Button,
  Fa,
  Checkbox,
  TextInput,
} from '@eltoro-ui/components'
import { PaymentInfoType } from '../CCModal'
import { inputClassNames } from './shared'
import 'react-credit-cards/lib/styles.scss'
import { V1CreditCard } from 'next-gen-sdk'
import { ErrorMessage, checkExp, isValidCharLength, validCheck } from 'Helpers'

export const PaymentInfoInput = ({
  setComplete,
  paymentInfo,
  setPaymentInfo,
  navToStep,
  otherOrgCreditCards,
  isEditing,
}: {
  setComplete: () => void
  navToStep: (step: 1 | 2 | 3) => void
  paymentInfo: PaymentInfoType
  setPaymentInfo: (info: PaymentInfoType) => void
  otherOrgCreditCards?: V1CreditCard[]
  isEditing?: boolean
}) => {
  const orgHasExistingCards = (otherOrgCreditCards || [])?.length > 0
  const [cardNumber, setCardNumber, isValidCardNumber] = useValidatedState(
    paymentInfo?.cardNumber || '',
    'credit-card',
  )
  const [cardExp, setCardExp] = useMask(
    paymentInfo?.cardExp || '',
    'expiration-date',
  )
  const isValidCardExp = useValidation(
    /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
  )
  /* const [cardCVC, setCardCVC, isValidCardCVC] = useValidatedState(
   *   '',
   *   /^[0-9]{3,4}$/,
   * ) */
  const [cardNickname, setCardNickname] = useState(
    paymentInfo?.cardNickname || '',
  )
  const [cardIsDefault, setCardIsDefault] = useState(
    paymentInfo?.cardIsDefault || !orgHasExistingCards,
  )
  const [focus, setFocus] = useState('')
  const [showSimilarCardWarning, setShowSimilarCardWarning] = useState(false)
  const [errors, setErrors] = useState<string[]>()

  const { formData, isValidForm, touched } = useForm({
    cardNumber,
    cardExp,
    /* cardCVC, */
    cardNickname,
    cardIsDefault,
  })

  const required = [
    ...(isEditing ? [] : ['cardNumber']), // remove this if we are editing existing
    'cardExp',
    'cardNickname',
  ]
  const { validForm, missingFields } = isValidForm(required)

  useEffect(() => {
    const checkForProbableExistingCard = () => {
      const possibleDuplicateCards = (otherOrgCreditCards || []).reduce(
        (acc: V1CreditCard[], card) => {
          if (
            card.cardNumber === cardNumber.slice(-4) &&
            card.expirationDate === cardExp
          ) {
            return [...acc, card]
          }
          return acc
        },
        [],
      )
      if (possibleDuplicateCards && possibleDuplicateCards.length > 0) {
        setShowSimilarCardWarning(true)
      } else {
        setShowSimilarCardWarning(false)
      }
    }
    if (validForm && cardNickname && cardNumber && cardExp)
      checkForProbableExistingCard()
  }, [cardNumber, cardExp, cardNickname, validForm, otherOrgCreditCards])
  return (
    <div className="PaymentInfoInput flex flex-col gap-4">
      <TextHeader className="PaymentInfoInput__step-header" type={4}>
        <span className="PaymentInfoInput__step RightVerticalPipe font-light uppercase">
          Step 1
        </span>
        <span className="PaymentInfoInput__subTitle text-l mb-8">
          Payment information
        </span>
      </TextHeader>
      <Form
        required={required}
        valid={validForm}
        touched={touched}
        missingFields={missingFields}
      >
        <div className="PaymentInfoInput__form-wrapper laptopsmall:flex-wrap flex gap-8">
          <div className="PaymentInfoInputContainer flex w-full flex-col gap-4">
            <div className="PaymentInfoInput__card-details flex w-full flex-wrap justify-between gap-6">
              {isEditing ? (
                <div className="flex flex-grow flex-wrap items-center gap-1">
                  <label className="FormItem__label" htmlFor="cardNumber">
                    Card number
                  </label>
                  <div className="PaymentInfoInput__editing-existing-cc-input-wrap flex flex-grow items-center">
                    <input
                      disabled
                      className={`${inputClassNames} w-full`}
                      value={`**** **** **** ${cardNumber}`}
                      onChange={(e) => setCardNumber(e.target.value)}
                      onFocus={(e) => setFocus(e.target.name)}
                    />
                    <Fa
                      icon="lock"
                      size={1}
                      className="text-grey-400  ml-[-1.75rem]"
                    />
                  </div>
                </div>
              ) : (
                <FormItem
                  className="cardNumber flex-grow"
                  htmlFor="cardNumber"
                  label="Card Number"
                  errorMessage={ErrorMessage({
                    fieldName: cardNumber || '',
                    max: 16,
                    label: 'Credit card number',
                    isValid:
                      cardNumber.length === 16 || cardNumber.length === 15,
                  })}
                  valid={
                    isValidCardNumber &&
                    !errors?.includes('missing-card-number') &&
                    validCheck(cardNumber || '') &&
                    isValidCharLength(cardNumber || '', 16)
                  }
                  counter={cardNumber.length > 0}
                >
                  <TextInput
                    className={inputClassNames}
                    // safari handles number as alphanumerical field, type = tel allows TextInput's handler prevent alpha chars.
                    type="tel"
                    maxLength={17}
                    value={cardNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setErrors((prev) =>
                        prev?.filter((val) => val !== 'missing-card-number'),
                      )
                      setCardNumber(e.target.value)
                    }}
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFocus(e.target.name)
                    }
                    autoComplete="cc-number"
                  />
                </FormItem>
              )}
              <FormItem
                required
                htmlFor="cardExp"
                label="Exp. date"
                errorMessage={ErrorMessage({
                  fieldName: cardExp || '',
                  max: 5,
                  label: 'Valid card expiration date',
                  isValid:
                    isValidCardExp(cardExp) &&
                    !errors?.includes('missing-card-exp') &&
                    !errors?.includes('expired-cc'),
                })}
                valid={
                  isValidCardExp(cardExp) &&
                  !errors?.includes('missing-card-exp') &&
                  !errors?.includes('expired-cc')
                }
              >
                <input
                  className={`${inputClassNames} w-min`}
                  value={cardExp}
                  onChange={(e) => {
                    setErrors((prev) =>
                      prev?.filter(
                        (val) =>
                          val !== 'missing-card-exp' && val !== 'expired-cc',
                      ),
                    )
                    setCardExp(e.target.value)
                  }}
                  onFocus={(e) => setFocus(e.target.name)}
                  autoComplete="cc-exp"
                />
              </FormItem>
            </div>
            <FormItem
              required
              htmlFor="cardNickname"
              label="Nickname "
              errorMessage={ErrorMessage({
                fieldName: cardExp || '',
                max: 5,
                label: 'Card nickname',
                isValid:
                  isValidCardExp(cardExp) &&
                  !errors?.includes('missing-card-nickname'),
              })}
              valid={
                isValidCardExp(cardExp) &&
                !errors?.includes('missing-card-exp') &&
                !errors?.includes('expired-cc')
              }
            >
              <input
                className={inputClassNames}
                value={cardNickname}
                onChange={(e) => {
                  setErrors((prev) =>
                    prev?.filter((val) => val !== 'missing-card-nickname'),
                  )
                  setCardNickname(e.target.value)
                }}
                onFocus={(e) => setFocus(e.target.name)}
              />
            </FormItem>
            {!(paymentInfo.cardIsDefault || !orgHasExistingCards) && (
              <FormItem htmlFor="cardDefault">
                <Checkbox
                  checked={cardIsDefault}
                  onChange={(checked) => setCardIsDefault(checked)}
                  label="Set as default"
                />
              </FormItem>
            )}
            {showSimilarCardWarning && (
              <p className="PaymentInfoInput-warning-msg bg-warning-50 text-warning animate-slidedown my-2 w-0 min-w-fit rounded py-2 pl-2 font-bold leading-tight">
                You have another card on file with similar details- if it is the
                same one, please delete that card first or update its details
                before adding it again.
              </p>
            )}
          </div>
          {/* @ts-ignore */}
          <Cards
            cvc="000"
            expiry={cardExp}
            focused={focus as Focused}
            name="Card Holder"
            number={cardNumber}
          />
        </div>
      </Form>
      <div className="my-4 flex w-full justify-end pb-8 pr-8">
        <Button
          className={
            validForm || checkExp(cardExp) ? 'Button--primary ripples' : ''
          }
          type="submit"
          onClick={() => {
            if (!validForm || !checkExp(cardExp)) {
              const errs = [
                ...((!checkExp(cardExp) && ['expired-cc']) || []),
                ...((missingFields.includes('cardNumber') && [
                  'missing-card-number',
                ]) ||
                  []),
                ...((missingFields.includes('cardExp') && [
                  'missing-card-exp',
                ]) ||
                  []),
                ...((missingFields.includes('cardNickname') && [
                  'missing-card-nickname',
                ]) ||
                  []),
              ]
              setErrors(errs)
            } else {
              setPaymentInfo(formData as PaymentInfoType)
              setComplete()
              navToStep(2)
            }
          }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
