import { useEffect, useRef } from 'react'
import { useScreenSize, ScreenSize } from '@eltoro-ui/hooks'
import { FCc } from '../types'
import { TabType } from './Tabs'
import './Tab.scss'

export const Tab: FCc<{
  className?: string
  tab: TabType
  onClick: () => void
  isActive: boolean
  onIsActiveChange: (placement: { left: number; width: number }) => void
}> = ({ className = '', tab, onClick, isActive, onIsActiveChange }) => {
  const tabRef = useRef<HTMLLIElement>(null)
  const { isDisabled, label } = tab
  const screenSize: ScreenSize = useScreenSize()

  useEffect(() => {
    // update placement as active tab changes
    if (!(tabRef.current && isActive)) return
    if (screenSize.width > 200 || screenSize.height > 200) {
      onIsActiveChange({
        width: tabRef.current.offsetWidth,
        left: tabRef.current.offsetLeft,
      })
    }
  }, [isActive, onIsActiveChange, screenSize.width, tabRef.current])
  // change from handleKeyPress as onKeyPress will be deprecated:
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/keypress_event
  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Tab' || e.key === 'Enter' || e.key === ' ') {
      onClick()
    }
  }

  return (
    <li
      className={`Tab ${isActive ? 'Tab--is-active' : ''} ${
        isDisabled ? 'Tab--is-disabled' : ''
      } ${className}`}
      ref={tabRef}
      role="tab"
      aria-selected={isActive}
      aria-disabled={isDisabled}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      tabIndex={0}
    >
      <span className="Tab__label">{label}</span>
    </li>
  )
}
