import { useState } from 'react'
import { cleanedMime, Text, Fa, Button, Tooltip } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { isAudio, simplifyEnum, styleTailwind } from 'Helpers'
import { ETIcon } from 'Assets'
import { WarningModal, CreativePreview, CreativeThumbnail } from 'Components'
import './CreativeCard.scss'

export const CreativeCard = ({
  creative,
  onRemove,
}: {
  creative: Creativeservicev1Creative
  onRemove: (id: string) => void
}) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)

  const handleOnPreviewClick = () => {
    setOpenPreview(true)
  }
  const CreativeIcon = styleTailwind(Fa, 'text-primary')

  const getCreativeThumb = () => {
    if (creative) {
      if (creative.type === 'CREATIVE_TYPE_HTML5')
        return <ETIcon className="HTML5Icon" icon="html5" />
      if (
        creative.type === 'CREATIVE_TYPE_AD_TAG' ||
        creative.type === 'CREATIVE_TYPE_VAST_TAG'
      )
        return <ETIcon icon="ad-tag" />
      const file = creative.files && creative.files[0]
      if (file) {
        const cleanMime = file.mime ? cleanedMime(file.mime) : null
        if (cleanMime && isAudio(cleanMime))
          return <CreativeIcon size={5} icon="volume-up" />

        return (
          <CreativeThumbnail
            file={file}
            onClick={handleOnPreviewClick}
            cover
            size="s"
          />
        )
      }
    }
    return <Fa size={3} icon="file-times" />
  }
  const creativeType = () => {
    return creative.type || ''
  }
  if (!creative) return null

  return (
    <>
      <div className="CreativeCard odd:bg-gray group flex gap-2 p-4">
        <div className="min-w-[60px]">{getCreativeThumb()}</div>
        <div className="max-w-full flex-grow flex-col pl-2">
          <div className="CreativeCard__name-wrapper">
            <p className="text-l text-primary !max-w-[35ch] truncate font-bold">
              {creative.name}
            </p>
          </div>
          <span className="text-grey-500 text-s">
            Type: {simplifyEnum(2, creativeType(), true)}
          </span>
        </div>
        <Tooltip
          className="CreativeCard__remove-button--wrap -mr-1"
          position="bottomLeft"
          content="Click to delete creative"
        >
          <Button
            className="CreativeCard__remove-button text-primary group-hover:!text-primer group-hover:!bg-danger-600"
            iconOnly={<Fa icon="times" size={1} />}
            kind="text"
            size="s"
            rounded
            onClick={() => {
              setWarningModalOpen(true)
            }}
          />
        </Tooltip>
      </div>
      {openPreview && (
        <CreativePreview
          creatives={[creative]}
          offClick={() => setOpenPreview(false)}
        />
      )}
      {warningModalOpen && (
        <WarningModal
          onConfirm={() => {
            if (onRemove) onRemove(creative.id || '')
            setWarningModalOpen(false)
          }}
          onCancel={() => setWarningModalOpen(false)}
        >
          <Text on="white" kind="subdued" size="l">
            Are you sure you want to remove
            <span className="break-all">
              <strong>{` ${creative.name || 'this creative'} `}</strong>
            </span>
            from the campaign?
          </Text>
        </WarningModal>
      )}
    </>
  )
}
