import { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import {
  Button,
  ButtonGroup,
  Form,
  FormItem,
  RadioButton,
  RadioButtonGroup,
  TextInput,
} from '@eltoro-ui/components'
import { Notificationservicev1Type, V1Notification } from 'next-gen-sdk'
import { useBrowser, useForm } from '@eltoro-ui/hooks'
import {
  ErrorMessage,
  getSanitizedHTML,
  isValidCharLength,
  validCheck,
  simpleNotifyLabel,
  styleTailwind,
} from 'Helpers'

type Props = {
  data?: V1Notification
  onCancel: () => void
  onCreate?: (data: V1Notification) => void
  onUpdate?: (data: V1Notification) => void
}

const AlertFormItem = styleTailwind(FormItem, 'Alerts__form-item flex mt-3')

const AlertTypes: Notificationservicev1Type[] = [
  'NOTIFICATION_TYPE_INFO',
  'NOTIFICATION_TYPE_WARNING',
  'NOTIFICATION_TYPE_ERROR',
  'NOTIFICATION_TYPE_UNSPECIFIED',
]

export const AlertForm = ({ data, onCancel, onCreate, onUpdate }: Props) => {
  const auth = useAuth()
  const [title, setTitle] = useState(data ? data.title : '')
  const [message, setMessage] = useState(data ? data.message : '')
  const [type, setType] = useState<Notificationservicev1Type | undefined>(
    data ? data.type : undefined,
  )
  const userId = auth?.user?.profile.sub || 'NextGen-Admin'

  const { isValidForm, touched } = useForm({
    title,
    type,
    message,
  })

  const Browser = useBrowser()

  const titleLengthValid = (title || '').length <= 255
  const messageLengthValid = (message || '').length <= 500

  const sanitizedMessage = getSanitizedHTML(message as string, Browser)

  const { validForm, missingFields } = isValidForm([
    'title',
    'type',
    'message',
    titleLengthValid,
    messageLengthValid,
  ])

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const handleCreate = () => {
    if (!validForm) {
      return false
    }
    const newData: V1Notification = {
      active: true,
      global: true,
      title: title,
      message: sanitizedMessage,
      type: type as Notificationservicev1Type,
      createTime: new Date(),
      deleteTime: undefined,
      updateTime: undefined,
      userId: userId,
    }
    if (!onCreate) return
    onCreate(newData)
    onCancel()
  }

  const handleUpdate = () => {
    if (!data) return
    const updatedData: V1Notification = {
      id: data.id,
      title: title,
      message: sanitizedMessage,
      type: type as Notificationservicev1Type,
      updateTime: new Date(),
    }

    if (!onUpdate) return
    onUpdate(updatedData)
    onCancel()
  }

  return (
    <div
      className={`AlertForm ${
        onCreate
          ? 'AlertForm__container--add-alert animate-slidedown'
          : 'AlertForm__container--edit-alert animate-slideup'
      }`}
    >
      <Form
        className="AlertPageForm__add-alert"
        missingFields={missingFields}
        touched={touched}
        valid={validForm}
        onSubmit={onCreate ? handleCreate : handleUpdate}
      >
        <header className="AlertPageForm__header-buttons space-between -mt-5 flex items-center">
          <ButtonGroup className="ml-auto">
            <Button
              type="button"
              className="AlertForm__cancel-button"
              onClick={onCancel}
              size="l"
            >
              Cancel
            </Button>
            <Button
              className="AlertForm__submit-button"
              kind="primary"
              type="submit"
              disabled={!validForm || !missingFields.values}
              size="l"
            >
              {onCreate ? 'Publish' : 'Update'}
            </Button>
          </ButtonGroup>
        </header>
        <AlertFormItem
          htmlFor="title"
          wrapperClassname="flex-col"
          label={onCreate ? 'New title' : 'Edit title'}
          className="AlertTitle__form-item flex-col"
          valid={touched && titleLengthValid ? true : !title}
          required
          errorMessage={ErrorMessage({
            fieldName: title || '',
            max: 256,
            label: 'Title',
            isValid: validCheck(title!) && isValidCharLength(title!, 256),
          })}
          counter={(title || '').length > 2}
        >
          <TextInput
            maxLength={256}
            value={title!}
            onChange={onTitleChange}
            className="AlertTextInputField"
          />
        </AlertFormItem>

        {/* alert type */}
        <AlertFormItem
          className="AlertType__form-item items-center"
          htmlFor="type"
          label="Notification Type"
          errorMessage="Please select a type"
          valid={touched}
          required
        >
          <RadioButtonGroup
            className="AlertType__radio-group"
            name="Notification type"
            valueSelected={type!}
            layout="horizontal"
            onChange={(type) => setType(type as Notificationservicev1Type)}
          >
            {AlertTypes.map((AlertType) => (
              <RadioButton
                name="type"
                key={AlertType}
                value={AlertType}
                label={simpleNotifyLabel(
                  AlertType as Notificationservicev1Type,
                )}
              />
            ))}
          </RadioButtonGroup>
        </AlertFormItem>

        <AlertFormItem
          className="AlertMessage__form-item flex flex-col"
          htmlFor="message"
          label={onCreate ? 'New message' : 'Edit message'}
          valid={touched && messageLengthValid ? true : !message}
          required
          wrapperClassname="AlertMessage__wrap"
          errorMessage={ErrorMessage({
            fieldName: message || '',
            max: 501,
            label: 'Message',
            isValid: validCheck(message!) && isValidCharLength(message!, 501),
          })}
        >
          <textarea
            name="message"
            className="AlertTextInputField"
            rows={5}
            value={message}
            onChange={onMessageChange}
            maxLength={501}
          />
          {(message || '').length > 10 ? (
            <div className="CharacterCounter CharacterCounter__message flex justify-between gap-2 text-sm">
              <p>
                Count: <strong>{(message || '').length}</strong>
              </p>
              <p>
                Remain: <strong>{500 - (message || '').length}</strong>
              </p>
            </div>
          ) : null}

          <p className="AttrTags-allowed py-2">
            <span className="uppercase">Tags allowed:</span> <i>a,</i>
            <i>b,</i>
            <i>i,</i>
            <i>em,</i>
            <i>strong,</i>
            <i>span,</i>
            <i>p</i> and <i>br</i>
            <span className="pl-8 uppercase">Attributes allowed:</span>
            <i>href,</i>
            <i>class,</i>
            <i>target</i> and <i>rel</i>
            <code className="py-2">
              <b className="pr-2">Example:</b>Your organization still needs to
              pay the initial set up fee of &#x3C;b
              class=&#x22;text-danger-600&#x22;&#x3E;$5,000&#x3C;/b&#x3E;.
              Please go to the &#x3C;a href=&#x22;billing/&#x22;
              target=&#x22;_blank&#x22; rel=&#x22;noopener
              noreferrer&#x22;&#x3E;billings page&#x3C;/a&#x3E; to ensure
              &#x3C;br&#x3E;that the set up fee is paid and your details are
              updated. &#x3C;p&#x3E;Reach out to your sales executive or email
              &#x3C;a class=&#x22;text-base-info-600&#x22;
              href=&#x22;mailto:billing@eltoro.com&#x22;&#x3E;billing@eltoro.com&#x3C;/a&#x3E;
              for assistance.&#x3C;/p&#x3E;
            </code>
          </p>
        </AlertFormItem>
      </Form>
    </div>
  )
}
