import React from 'react'
import { Fa, Pagination } from '@eltoro-ui/components'

export const TablePagination = ({
  pagination,
  pageSizeOptions,
}: {
  pagination: {
    page: number
    setPage: (value: number) => void
    totalPages: number
    pageSize: number
    setPageSize: (value: number) => void
    loading: boolean
    totalItems: number
  }
  // Override perPageOptions
  pageSizeOptions?: Array<{ value: string; label: string }>
}) => {
  const perPageOptions = pageSizeOptions || [
    { value: '5', label: '5 per page' },
    { value: '10', label: '10 per page' },
    { value: '20', label: '20 per page' },
    { value: '25', label: '25 per page' },
    { value: '50', label: '50 per page' },
    { value: '100', label: '100 per page' },
  ]

  const { setPage, pageSize, setPageSize } = pagination

  return (
    <div className="TablePagination">
      <Pagination
        {...pagination}
        onChange={(pageInfo) => {
          setPage(pageInfo.page)
        }}
      >
        <div className="flex gap-2">
          <div className="relative flex">
            <select
              value={pageSize}
              className="form-select bg-base border-grey-400 m-0 mr-2 w-full appearance-none rounded-lg border py-1.5 pl-3 pr-6 outline-none focus:ring disabled:opacity-40"
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
              placeholder="Page number"
            >
              {perPageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Fa
              icon="caret-down"
              size={1}
              className="text-primary pointer-events-none !absolute right-3"
            />
          </div>
        </div>
      </Pagination>
    </div>
  )
}
