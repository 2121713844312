import { useEffect, useRef, useState } from 'react'
import { AutoScroller, Button, TextHeader, Tabs } from '@eltoro-ui/components'
import { Targetjobservicev1Audience } from 'next-gen-sdk'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  AudienceUploadContainer,
  CollapseButton,
  countFilters,
  IconButton,
  IconButtons,
  OptionalFilters,
  PageContent,
  WebToHome,
} from 'Components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { useMediaQuery } from '@eltoro-ui/hooks'
import { ETIcon } from 'Assets'
import { styleTailwind } from 'Helpers'
import { audienceUploadConfigs } from './AudienceUploadConfigs'
import { audienceFilterConfig } from './audienceFilterConfig'
import './AudienceUpload.scss'

const AudienceCat = styleTailwind(
  'span',
  'AudienceHeader__category whitespace-nowrap',
)

const AudienceUploadHeader = () => {
  const [tablet] = useMediaQuery('(min-width: 280px) and (max-width: 1024px)')
  return (
    <div className="AudienceUpload__header">
      <TextHeader type={1}>Create Audiences</TextHeader>
      <TextHeader
        className="AudienceUpload__header__subTitle pb-3 pt-1 !font-light"
        type={4}
      >
        {tablet
          ? 'Upload audiences for ads'
          : 'Upload your audiences to serve targeted ads'}
      </TextHeader>
    </div>
  )
}
export const AudienceUploadNavigation = ({
  audienceTitle,
}: {
  audienceTitle?: string
}) => {
  const { roles } = useAppContext()
  const [selected, setSelected] = useState('')
  const [selectedAudiences, setSelectedAudiences] = useState<
    Targetjobservicev1Audience[]
  >([])
  const [filter, setFilter] = useState<string>('all')
  const productDivRef = useRef<null | HTMLDivElement>(null)
  const [isClosed, setIsClosed] = useState(false)
  const {
    campaign,
    NewhandleAttachAudiences,
    isPolitical,
  } = useCampaignContext()
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')
  const handleAddAudienceToAttach = (audience: Targetjobservicev1Audience) => {
    // prevent adding audiences when not in campaign creation (triggered by the back button)
    if (!isInCampaignCreation) return
    setSelectedAudiences((prev) => [...prev, audience])
  }
  const handleRemoveAudienceFromAttach = (
    audience: Targetjobservicev1Audience,
  ) => {
    if (!isInCampaignCreation) return
    setSelectedAudiences((prev) => prev.filter((aud) => aud.id !== audience.id))
  }
  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => {
    setSelected(buttonId)
    setIsClosed(true)
  }
  const sharedButtonProps = {
    centered: false,
    contentVertical: false,
  }
  const handleTabChange = (buttonId: string) => {
    setSelected(buttonId)
  }
  // this scrolled component out of viewport when it's viewed at /audiences/create
  useEffect(() => {
    if (productDivRef && productDivRef.current && selected) {
      productDivRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  const jumpToButton = selectedAudiences && selectedAudiences.length < 1
  const [tablet] = useMediaQuery('(min-width: 280px) and (max-width: 1024px)')

  audienceTitle = tablet
    ? 'Select audience type'
    : "Select the audiences you'd like to target"

  const headerText = () => {
    if (!selected) return audienceTitle
    return (
      <div className="AudienceHeaderText flex items-center gap-2">
        <span className="AudienceTitle__txt text-lg">{audienceTitle}</span>
        <span className="text-tint-gray-500 scale-x-50">&#62;</span>
        <span className="text-tint-gray-900 text-lg">
          {audienceUploadConfigs.find((conf) => conf.id === selected)?.title}
        </span>
      </div>
    )
  }

  const uploadContainerProps = {
    selectedAudiences: selectedAudiences,
    audienceType: selected,
    onCancel: () => {
      setSelected('')
      setIsClosed(!isClosed)
    },
    onAddAudienceToAttach: handleAddAudienceToAttach,
    onRemoveAudienceFromAttach: handleRemoveAudienceFromAttach,
    showLibrary: isInCampaignCreation,
  }

  return (
    <div className="AudienceUploadNavigation">
      <div className="AudienceUpload__typeButtonsContainer relative z-0">
        <header className="AudienceUpload__headerSub headerSub flex flex-1 gap-2">
          <TextHeader
            className="AudienceUpload__createText !font-regular"
            type={4}
          >
            {headerText()}
          </TextHeader>
          <CollapseButton
            className="AudienceUpload__collapse-button"
            isCollapsed={isClosed}
            handleClick={() => {
              if (
                selectedAudiences &&
                selectedAudiences.length > 0 &&
                isInCampaignCreation
              ) {
                NewhandleAttachAudiences(selectedAudiences).then(() => {
                  if (process.env.NODE_ENV !== 'production') {
                    console.log('BACK BUTTON:', { selectedAudiences })
                  }
                  setSelectedAudiences([])
                })
              }
              setSelected('')
              setIsClosed(!isClosed)
            }}
          />
        </header>
        <IconButtons
          className="AudienceUpload__IconButtons"
          classNameHeader="AudienceUpload__header-text absolute flex h-full items-center justify-start"
          isCollapsed={isClosed}
          filterChildren={
            <OptionalFilters
              currentFilter={filter}
              handleChangeFilter={(filter) => setFilter(filter)}
              filterConfig={countFilters(
                audienceFilterConfig,
                audienceUploadConfigs,
                roles?.includes('nextgen_admin') || false,
              )}
            />
          }
          iconChildren={audienceUploadConfigs.map((button) => {
            if (
              isInCampaignCreation &&
              isPolitical &&
              button.id === 'retargeting'
            ) {
              return undefined
            }
            if (button.adminOnly && !roles?.includes('nextgen_admin')) {
              return undefined
            }
            if (filter !== 'all' && filter !== button.tagLabel) {
              return undefined
            }
            return (
              <IconButton
                key={button.id}
                {...button}
                isSelected={selected === button.id}
                handleClick={handleButtonClick}
                {...sharedButtonProps}
              />
            )
          })}
        />
      </div>
      {selected && (
        <div className="AudienceUpload__selection" ref={productDivRef}>
          {(selected === 'address' ||
            selected === 'ip' ||
            selected === 'device' ||
            selected === 'reverseIp' ||
            selected === 'ipForMailing') && (
            <Tabs
              classNamePanel={`Address__tabs flex-1 w-full TabSelected-${selected}`}
              tabs={[
                {
                  id: 'address',
                  label: (
                    <>
                      {tablet ? (
                        <span aria-label="IP Targeting" data-tooltip="top">
                          <ETIcon icon="ip-targeting" />
                        </span>
                      ) : (
                        <ETIcon icon="ip-targeting" />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Address
                        </span>
                        <AudienceCat>IP Targeting</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('address'),
                  defaultTab: selected === 'address',
                  component: (
                    <AutoScroller
                      shouldScroll={jumpToButton}
                      scrollToTop={!selected}
                      blockPosition="end"
                    >
                      <AudienceUploadContainer {...uploadContainerProps} />
                    </AutoScroller>
                  ),
                },
                {
                  id: 'ip',
                  label: (
                    <>
                      {tablet ? (
                        <span aria-label="IP Address List" data-tooltip="top">
                          <ETIcon
                            className="AudienceTypeSelector__tabs--icon"
                            icon="sale-doc"
                          />
                        </span>
                      ) : (
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="sale-doc"
                        />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Address
                        </span>
                        <AudienceCat>IP Address List</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('ip'),
                  defaultTab: selected === 'ip',
                  component: (
                    <AudienceUploadContainer {...uploadContainerProps} />
                  ),
                },
              ].concat(
                !roles?.includes('nextgen_admin')
                  ? []
                  : [
                      {
                        id: 'device',
                        label: (
                          <>
                            {tablet ? (
                              <span aria-label="Device IDs" data-tooltip="top">
                                <ETIcon
                                  className="AudienceTypeSelector__tabs--icon"
                                  icon="maid"
                                />
                              </span>
                            ) : (
                              <ETIcon
                                className="AudienceTypeSelector__tabs--icon"
                                icon="maid"
                              />
                            )}
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Address
                              </span>
                              <AudienceCat>Device</AudienceCat>
                            </span>
                          </>
                        ),
                        onClick: () => handleTabChange('device'),
                        defaultTab: selected === 'device',
                        component: (
                          <AudienceUploadContainer {...uploadContainerProps} />
                        ),
                      },
                      {
                        id: 'reverseIp',
                        label: (
                          <>
                            {tablet ? (
                              <span aria-label="Reverse IP" data-tooltip="top">
                                <ETIcon icon="locator" />
                              </span>
                            ) : (
                              <ETIcon icon="locator" />
                            )}
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Address
                              </span>
                              <AudienceCat>Reverse IP</AudienceCat>
                            </span>
                          </>
                        ),
                        onClick: () => handleTabChange('reverseIp'),
                        defaultTab: selected === 'reverseIp',
                        component: (
                          <AudienceUploadContainer {...uploadContainerProps} />
                        ),
                      },
                      {
                        id: 'ipForMailing',
                        label: (
                          <>
                            {tablet ? (
                              <span
                                aria-label="IP List for mailing"
                                data-tooltip="top"
                              >
                                <ETIcon icon="map-data" />
                              </span>
                            ) : (
                              <ETIcon icon="map-data" />
                            )}
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Address
                              </span>
                              <AudienceCat>IP List for Mailing</AudienceCat>
                            </span>
                          </>
                        ),
                        onClick: () => handleTabChange('ipForMailing'),
                        defaultTab: selected === 'ipForMailing',
                        component: (
                          <AudienceUploadContainer {...uploadContainerProps} />
                        ),
                      },
                    ],
              )}
            />
          )}
          {(selected === 'venue-replay' ||
            selected === 'new-mover' ||
            selected === 'retargeting') && (
            <Tabs
              classNamePanel={`Address__tabs flex-1 w-full TabSelected-${selected}`}
              tabs={[
                {
                  id: 'venue-replay',
                  label: (
                    <>
                      {tablet ? (
                        <span aria-label="Venue Replay" data-tooltip="top">
                          <ETIcon icon="venue-replay" />
                        </span>
                      ) : (
                        <ETIcon icon="venue-replay" />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Behavioral
                        </span>
                        <AudienceCat>Venue Replay</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('venue-replay'),
                  defaultTab: selected === 'venue-replay',
                  component: (
                    <AudienceUploadContainer {...uploadContainerProps} />
                  ),
                },
                {
                  id: 'new-mover',
                  label: (
                    <>
                      {tablet ? (
                        <span aria-label="Digital New Mover" data-tooltip="top">
                          <ETIcon
                            className="AudienceTypeSelector__tabs--icon"
                            icon="movers"
                          />
                        </span>
                      ) : (
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="movers"
                        />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Behavioral
                        </span>
                        <AudienceCat>Digital New Mover</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('new-mover'),
                  defaultTab: selected === 'new-mover',
                  component: (
                    <AudienceUploadContainer {...uploadContainerProps} />
                  ),
                },
              ].concat(
                isInCampaignCreation && isPolitical
                  ? []
                  : [
                      {
                        id: 'retargeting',
                        label: (
                          <>
                            {tablet ? (
                              <span aria-label="Retargeting" data-tooltip="top">
                                <ETIcon
                                  className="AudienceTypeSelector__tabs--icon"
                                  icon="retargeting"
                                />
                              </span>
                            ) : (
                              <ETIcon
                                className="AudienceTypeSelector__tabs--icon"
                                icon="retargeting"
                              />
                            )}
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Behavioral
                              </span>
                              <AudienceCat>Retargeting</AudienceCat>
                            </span>
                          </>
                        ),
                        onClick: () => handleTabChange('retargeting'),
                        defaultTab: selected === 'retargeting',
                        component: (
                          <AudienceUploadContainer {...uploadContainerProps} />
                        ),
                      },
                    ],
              )}
            />
          )}

          {(selected === 'dc' || selected === 'zip') && (
            <Tabs
              classNamePanel={`Address__tabs flex-1 w-full TabSelected-${selected}`}
              tabs={[
                {
                  id: 'dc',
                  label: (
                    <>
                      {tablet ? (
                        <span
                          aria-label="Digital Canvassing"
                          data-tooltip="top"
                        >
                          <ETIcon icon="digital-canvassing" />
                        </span>
                      ) : (
                        <ETIcon icon="digital-canvassing" />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Location
                        </span>
                        <AudienceCat>Digital Canvassing</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('dc'),
                  defaultTab: selected === 'dc',
                  component: (
                    <AutoScroller
                      shouldScroll={jumpToButton}
                      scrollToTop={!selected}
                      blockPosition="end"
                    >
                      <AudienceUploadContainer {...uploadContainerProps} />
                    </AutoScroller>
                  ),
                },
                {
                  id: 'zip',
                  label: (
                    <>
                      {tablet ? (
                        <span aria-label="ZIP Codes List" data-tooltip="top">
                          <ETIcon
                            className="AudienceTypeSelector__tabs--icon"
                            icon="locator"
                          />
                        </span>
                      ) : (
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="locator"
                        />
                      )}
                      <span className="AudienceTypeSelector__tabs--text flex flex-col">
                        <span className="AudienceTypeSelector__tabs-category Tabs-category">
                          Location
                        </span>
                        <AudienceCat>ZIP Codes List</AudienceCat>
                      </span>
                    </>
                  ),
                  onClick: () => handleTabChange('zip'),
                  defaultTab: selected === 'zip',
                  component: (
                    <AudienceUploadContainer {...uploadContainerProps} />
                  ),
                },
              ]}
            />
          )}
          {selected === 'polygon' && (
            <AutoScroller
              shouldScroll={jumpToButton}
              scrollToTop={!selected}
              blockPosition="end"
            >
              <div className={isInCampaignCreation ? '' : 'Tabs__content px-4'}>
                <AudienceUploadContainer {...uploadContainerProps} />
              </div>
            </AutoScroller>
          )}
          {selected === 'web-to-home' && <WebToHome />}
          {campaign?.id && (
            <AutoScroller
              className="AddSelectedAudiences_autoscroller"
              shouldScroll={!!selected && jumpToButton}
              scrollToTop
            >
              <Button
                className={`AudienceUpload_add-button mt-4 mb-6 ml-auto w-56 px-4 ${
                  selectedAudiences.length > 0 ? 'Button--primary ripples' : ''
                }`}
                size="l"
                type="button"
                onClick={() => {
                  NewhandleAttachAudiences(selectedAudiences).then(() => {
                    if (process.env.NODE_ENV !== 'production') {
                      console.log('Add Selected:', { selectedAudiences })
                    }
                    setSelectedAudiences([])
                  })
                }}
                disabled={selectedAudiences.length === 0}
              >
                <div className="flex items-center gap-1">
                  Add selected Audiences
                  {selectedAudiences.length ? (
                    <div className="bg-base text-primary flex h-4 min-w-[1.25rem] items-center justify-center rounded-full border">
                      {selectedAudiences.length}
                    </div>
                  ) : null}
                </div>
              </Button>
            </AutoScroller>
          )}
        </div>
      )}
    </div>
  )
}

export const AudienceUpload = () => {
  const { isOnHold, currentOrg } = useAppContext()
  const history = useNavigate()

  useEffect(() => {
    if (isOnHold) {
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      history(`/campaigns/dashboard${query}`)
      return
    }
  }, [isOnHold, history, currentOrg?.id])
  return (
    <PageContent className="AudienceUpload">
      <AudienceUploadHeader />
      <AudienceUploadNavigation />
    </PageContent>
  )
}
