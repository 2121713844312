/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Segment Group Targets](https://docs.xandr.com/bundle/xandr-api/page/profile-service.html#ProfileService-SegmentGroupTargets) in the Profile Service in Xandr's documentation.
*/
export class V3SGSegment {
    /**
    * The ID of the segment.
    */
    'id'?: number;
    /**
    * The custom code for the segment.
    */
    'code'?: string;
    /**
    * Possible values: include or exclude.
    */
    'action'?: string;
    /**
    * The lower bound for the amount of time since a user was added to the segment.
    */
    'startMinutes'?: number;
    /**
    * The upper bound for the amount of time since a user was added to the segment.
    */
    'expireMinutes'?: number;
    /**
    * The non-inclusive upper bound for segment value targeting.
    */
    'otherLess'?: number;
    /**
    * The non-inclusive lower bound for segment value targeting
    */
    'otherGreater'?: number;
    /**
    * The exact segment value to target. Note: If you use other_in_list, you cannot use this field.
    */
    'otherEquals'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "action",
            "baseName": "action",
            "type": "string",
            "format": ""
        },
        {
            "name": "startMinutes",
            "baseName": "start_minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "expireMinutes",
            "baseName": "expire_minutes",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "otherLess",
            "baseName": "other_less",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "otherGreater",
            "baseName": "other_greater",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "otherEquals",
            "baseName": "other_equals",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3SGSegment.attributeTypeMap;
    }

    public constructor() {
    }
}

