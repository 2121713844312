/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { HttpFile } from '../http/http';

/**
* The request to deploy objects to deployment destinations
*/
export class V3QueueRequest {
    /**
    * The advertiser ID that all objects to be deployed are related.
    */
    'advertiserId'?: string;
    /**
    * The deployment destinations the objects to be deployed.
    */
    'destinations'?: Array<Deployservicev3Destination>;
    /**
    * The campaign ID that is to be deployed.
    */
    'campaignId'?: string;
    /**
    * The segment IDs that are to be deployed.
    */
    'segmentIds'?: Array<string>;
    /**
    * The creative IDs that are to be deployed.
    */
    'creativeIds'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "advertiserId",
            "baseName": "advertiser_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "destinations",
            "baseName": "destinations",
            "type": "Array<Deployservicev3Destination>",
            "format": ""
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentIds",
            "baseName": "segment_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "creativeIds",
            "baseName": "creative_ids",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3QueueRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

