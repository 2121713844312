import './CalendarInputMonth.scss'
import '../../../../Assets/css/all.css'
import { Fa } from '../../../FontAwesome'

export const CalendarInputMonth = ({
  month,
  onMonthChange,
}: {
  month: Date
  onMonthChange: (date: Date) => void
}) => {
  const copiedDate = new Date(month.valueOf())
  const incrementMonth = (increment: number) =>
    new Date(copiedDate.setMonth(copiedDate.getMonth() + increment).valueOf())

  return (
    <div className="CalendarInputMonth mb-4 mt-2">
      <button
        className="CalendarInputMonth__button group"
        type="button"
        onClick={() => onMonthChange(incrementMonth(-1))}
      >
        <Fa
          className="CalendarInputMonth__icon text-primary transition-all"
          icon="circle-chevron-left"
          size={3}
        />
      </button>
      <div className="CalendarInput__month-title text-l uppercase">
        {`${month.toLocaleString('default', {
          month: 'long',
        })} ${month.toLocaleString('default', { year: 'numeric' })}`}
      </div>
      <button
        className="CalendarInputMonth__button group"
        type="button"
        onClick={() => onMonthChange(incrementMonth(1))}
      >
        <Fa
          className="CalendarInputMonth__icon text-primary transition-all"
          icon="circle-chevron-right"
          size={3}
        />
      </button>
    </div>
  )
}
