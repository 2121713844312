import { useState } from 'react'
import {
  Button,
  Fa,
  LoadingBar,
  Modal,
  Popover,
  Text,
  TextHeader,
} from '@eltoro-ui/components'
import { getTypeMoneyCost, styleTailwind } from 'Helpers'
import { useAppContext } from 'Contexts'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'
import classNames from 'classnames'
import { useAuth } from 'react-oidc-context'
import { CreditCardSelect } from 'Components/CreditCardSelect'
import { SubmitOrderForReview } from 'Components/SubmitOrderForReview'
const SectionHeader = styleTailwind(TextHeader, 'text-primary my-2')
const FlexJustifyBetween = styleTailwind('div', 'flex justify-between')

// 1/10/24 it is not parity or supported in the back end to pay for changes to a prepay order line,
// so this component is currently not in use
export const ConfirmOLChangeModal = ({
  setOpen,
  orderLine,
  additionalCost,
  onSubmit,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  orderLine: Campaignservicev1OrderLine
  additionalCost: number
  onSubmit: () => void
}) => {
  const [showAddCostInfo, setShowAddCostInfo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCard, setSelectedCard] = useState<string>()
  const { currentOrg, campaignServiceApi, billingServiceApi } = useAppContext()
  const auth = useAuth()

  const handleSubmit = async () => {
    if (
      !orderLine.id ||
      !campaignServiceApi ||
      !currentOrg?.id ||
      !billingServiceApi ||
      !auth.user?.profile.sub
    )
      return

    setLoading(true)
    // if (orderLine.prepay && selectedCard) {
    // TODO: pay for prepay order line changes when supported
    // }
  }

  const { name, costRange } = orderLine
  const cost = costRange?.estimate
  const originalOrderLineCost = getTypeMoneyCost(cost || {})

  return (
    <Modal
      className="ConfirmOLChangeModal min-w-[50%] p-0"
      classNameContent={classNames(
        'flex flex-col border md:flex-row md:border-0',
        {
          'opacity-50 pointer-events-none relative': loading,
        },
      )}
      offClick={() => setOpen(false)}
    >
      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center border">
          <LoadingBar kind="circle" className="max-h-28" />
        </div>
      )}
      <div className="bg-tint-khaki-50 p-4 md:w-3/5">
        <TextHeader type={2} className="mb-6">
          Confirm Order Line Change
        </TextHeader>
        <Text tag="p" size="xl">
          Adjust budget from ${originalOrderLineCost.toFixed(2)} to $
          {(originalOrderLineCost + additionalCost).toFixed(2).toLocaleString()}
        </Text>
        <Text tag="p" size="xl">
          Order Line: {name}
        </Text>
      </div>
      <div className="bg-tint-gray-100 flex flex-col gap-3 p-4 md:w-2/5 md:max-w-5xl">
        <div>
          <SectionHeader type={3}>Cost</SectionHeader>
          <FlexJustifyBetween>
            <Text size="m">Adjust budget</Text>
            <Text size="m">${additionalCost.toFixed(2).toLocaleString()}</Text>
          </FlexJustifyBetween>
          <Text size="m">Additional fees</Text>
          <Popover
            isOpen={showAddCostInfo}
            position={['left']}
            content={
              <div className="ConfirmOLChangeModal__popover bg-base shadow-tint-gray-300 shadow-l animate-fadein rounded p-3">
                Some info about the additional fee
              </div>
            }
          >
            <div
              onFocus={() => setShowAddCostInfo(true)}
              onBlur={() => setShowAddCostInfo(false)}
              onMouseEnter={() => setShowAddCostInfo(true)}
              onMouseOut={() => setShowAddCostInfo(false)}
              onClick={() => setShowAddCostInfo(!showAddCostInfo)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space')
                  setShowAddCostInfo(!showAddCostInfo)
                if (e.key === 'Escape') {
                  setShowAddCostInfo(false)
                }
              }}
              role="button"
              tabIndex={0}
              className="ReviewShoppingCart__info-icon inline-block"
            >
              <Fa className="text-tint-gray-500" icon="info-circle" size="xs" />
            </div>
          </Popover>
          <FlexJustifyBetween className="text-primary font-semibold">
            <Text size="xl">Total</Text>
            <Text size="xl">${additionalCost.toFixed(2).toLocaleString()}</Text>
          </FlexJustifyBetween>
        </div>
        <TextHeader className="text-primary" type={4}>
          Payment Method
        </TextHeader>
        <CreditCardSelect
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
        />
        <SubmitOrderForReview
          loading={false} // come back to this some day
          isReady
          onSubmit={handleSubmit}
          buttonText="Pay & Submit"
        />
        <Button
          kind="text"
          className="mx-auto"
          size="l"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
