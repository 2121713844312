import * as React from 'react'
import './AnimatedStatusIcon.scss'

export function AnimatedStatusIcon({
  cssClass,
  width,
  height,
  kind,
}: {
  cssClass?: string
  width?: number
  height?: number
  kind?: string
}) {
  return (
    <svg
      className={cssClass ?? 'svg-icon'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width ?? 55} ${height ?? 55}`}
    >
      <circle
        className={`${cssClass}__circle`}
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className={`${cssClass}__check`}
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  )
}
