import React, { useEffect, useState } from 'react'
import { Button, Fa } from '@eltoro-ui/components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Notification, SuccessStepIllustration } from 'Components'
import { browserLog } from 'Tools'

const successIllustrationConfig = [
  {
    icon: 'laptop-code',
    text: 'Pixel Website',
  },
  {
    icon: 'wifi',
    text: 'Collect IP Addresses',
  },
  {
    icon: 'house',
    text: 'Match Physical Address',
  },
  {
    icon: 'envelopes-bulk',
    text: 'Send Direct Mail',
  },
]

export const WebToHomeSuccess: React.FC<{
  pixelCode: string
  reset: () => void
}> = ({ pixelCode, reset }) => {
  const [copied, setCopied] = useState(false)

  const copyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        browserLog.log('successfully copied')
        setCopied(true)
      })
      .catch((err) => browserLog.error('could not copy', err))
  }

  useEffect(() => {
    const resetCopied = (): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          setCopied(false)
          resolve()
        }, 5000)
      })

    resetCopied()
  }, [copied])

  const history = useNavigate()
  const location = useLocation()

  const isInCampaignCreation = location.pathname.includes('campaigns')

  return (
    <div className="WebToHome__WebToHomeSuccess mx-4 flex">
      <Notification
        header="Success!"
        layOut="row"
        removeCheckmark
        comp={<SuccessStepIllustration config={successIllustrationConfig} />}
      >
        <div className="text-grey-700">
          <p className="text-xl font-bold">
            You&apos;ve set up a new &apos;Web-To-Home&apos; audience
          </p>
          <p>
            Below is the unique pixel code. to place the pixel with Google Tag
            Manager, follow the instructions{' '}
            {/* TODO: Get the appropriate link here */}
            <a
              href="https://eltoro.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold"
            >
              here
            </a>
            . If placing the pixel directly onto the source code, insert the
            snippet into the <span className="font-mono">&lt;head&gt;</span> of
            the page. Once the pixel has been placed, please{' '}
            {/* TODO: Update library to be able to launch this particular audience */}
            <Link className="font-bold" to="/audiences/audience-library">
              enable daily processing
            </Link>{' '}
            to activate the audience.
          </p>
          <div className="bg-grey-50 my-8 p-4 font-mono text-xs">
            {pixelCode}
          </div>
          <div className="mt-[-2.75rem] flex flex-row-reverse">
            <Button
              size="s"
              iconLeft={<Fa icon={copied ? 'check' : 'copy'} size={1} />}
              onClick={() => copyText(pixelCode)}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button onClick={() => reset()} className="my-4 p-4 text-right">
              Set Up Another
            </Button>
            {!isInCampaignCreation && (
              <Button
                to="/audiences/audience-library"
                className="my-4 p-4 text-right"
              >
                View
              </Button>
            )}
          </div>
        </div>
      </Notification>
    </div>
  )
}
