import { useEffect, useState } from 'react'

/**
// * custom useScrollClass hook
// * //* * Custom hook listen to a scroll event listener and updates the addClass state 
//* based on the scrolling condition. It returns the CSSClass if addClass is true, or '' if false.
// expects three args: containerRef, cssClass and scrollTop at 0 default
// 
// Add the useScrollClass hook to get the headerClass value, which will contain the CSS class based on the scrolling condition.
 * @returns {boolean} cssClass - Returns a boolean indicating whether the scroll occurs and add the cssClass.
 *
 * example usage
 * *  const stickyClass = useScrollClass(
        containerRef,
        'isSticky',
        (scrollTop) => scrollTop > 0
 * *   )
 * * 
 */

export const useScrollClass = (
  containerRef: React.RefObject<HTMLDivElement | null>,
  cssClass: string,
  condition: (scrollTop: number) => boolean,
) => {
  const [addClass, setAddClass] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = containerRef.current || {}
      const shouldApply = condition(scrollTop || 0)
      setAddClass(shouldApply)
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef, condition])

  return addClass ? cssClass : ''
}
