/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class StatsRequestWillHoldThePOSTRequestVars {
    /**
    * Beginning of a search time frame
    */
    'startTime'?: string;
    /**
    * End of a search time frame
    */
    'endTime'?: string;
    /**
    * Granularity of the request
    */
    'timeFrame'?: string;
    /**
    * Timezone the report is in
    */
    'timezone'?: string;
    /**
    * ID of the Campaign(s)
    */
    'campaignId'?: Array<string>;
    /**
    * ID of the Creative(s)
    */
    'creativeId'?: Array<string>;
    /**
    * ID of the OrderLine(s)
    */
    'orderLineId'?: Array<string>;
    /**
    * Deaggregates the data based on provided fields
    */
    'levelOfDetail'?: Array<string>;
    'metrics'?: Array<string>;
    /**
    * ID of the request
    */
    'requestId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "string",
            "format": ""
        },
        {
            "name": "endTime",
            "baseName": "end_time",
            "type": "string",
            "format": ""
        },
        {
            "name": "timeFrame",
            "baseName": "time_frame",
            "type": "string",
            "format": ""
        },
        {
            "name": "timezone",
            "baseName": "timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "creativeId",
            "baseName": "creative_id",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "levelOfDetail",
            "baseName": "level_of_detail",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "metrics",
            "baseName": "metrics",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StatsRequestWillHoldThePOSTRequestVars.attributeTypeMap;
    }

    public constructor() {
    }
}

