import { useEffect, useRef, FC, ReactNode } from 'react'

export const AutoScroller: FC<{
  className?: string
  shouldScroll?: boolean
  scrollToTop?: boolean
  blockPosition?: ScrollLogicalPosition
  inlinePosition?: ScrollLogicalPosition
  children?: ReactNode
}> = ({
  className = '',
  shouldScroll = true,
  scrollToTop = true,
  blockPosition = 'start',
  inlinePosition = 'nearest',
  children,
}) => {
  const autoScrollRef = useRef<HTMLDivElement>(null)

  const scrollToElement = () => {
    if (shouldScroll && autoScrollRef.current) {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: scrollToTop ? blockPosition : 'end',
        inline: inlinePosition,
      }
      autoScrollRef.current.scrollIntoView(scrollOptions)
    }
  }

  useEffect(() => {
    // add top offset to prevent scroll from going over the top, 63px is header height :)
    const timeout = setTimeout(() => {
      window.scrollBy(0, -63)
    }, 800)

    return () => {
      scrollToElement()
      clearTimeout(timeout)
    }
  }, [scrollToElement])

  return (
    <div className={`AutoScroller ${className}`}>
      {scrollToTop ? (
        <>
          <div className="AutoScroller__ref isTop" ref={autoScrollRef} />
          {children}
        </>
      ) : (
        <>
          {children}
          <div className="AutoScroller__ref isBottom" ref={autoScrollRef} />
        </>
      )}
    </div>
  )
}
