import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { Loading, MainWithSide, PageContent, PreviewPanel } from 'Components'
import { showErrorMessage, Text } from '@eltoro-ui/components'
import {
  Creativeservicev1Creative,
  Creativeservicev1Type,
  V1OrderLineStatus,
  Targetjobservicev1Audience,
  V1GlobalSearchResponse,
  V1JobType,
} from 'next-gen-sdk'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { getEnumKey, simplifyEnum } from 'Helpers'
import { getApiConfig } from 'Requests'
import { useGlobalSearchQuery } from 'Hooks'
import { audienceTypesEnum, creativeTypesEnum } from 'Enums'
import { NoResultsSVG } from './NoResultsSVG'
import {
  SearchAudienceTable,
  SearchCreativeTable,
  SearchOrderLineTable,
  SearchOrgTable,
} from './Tables'
import './SearchPage.scss'
import { orderLineStatusOptions } from 'searchOptions'

const defaultSelectedItems = ['audiences', 'creatives', 'order lines', 'orgs']

export const SearchPage = () => {
  // Preview target or creative in side panel
  const [previewItem, setPreviewItem] = useState<
    Targetjobservicev1Audience | Creativeservicev1Creative
  >()
  // For filter checkboxes (Refine By)
  const [selectedItems, setSelectedItems] = useState<Array<string>>(
    defaultSelectedItems,
  )
  // Results from global search
  const [results, setResults] = useState<V1GlobalSearchResponse>()
  // Keywords saved from url
  const [searchKeywords, setSearchKeywords] = useState<Array<string>>()
  // Global search loading
  const [loading, setLoading] = useState(true)

  const {
    currentOrg,
    searchServiceApi,
    setSearchType,
    setSearchValue,
    isAdmin,
  } = useAppContext()
  const auth = useAuth()
  const query = useGlobalSearchQuery()
  const history = useNavigate()

  useEffect(() => {
    setPreviewItem(undefined)
    const audienceType = query.get('audienceType') || undefined
    const creativeType = query.get('creativeType') || undefined
    const orderLineStatus = query.get('orderLineStatus') || undefined
    const keywords = query.getAll('keyword')
    const include = query.getAll('include')
    // If URL does not have 'include', set all included
    if (include.length === 0) {
      defaultSelectedItems.forEach((selected) => {
        query.append('include', selected)
      })

      history(`../search?${query}`)
      return
    }

    // if query has exclusions, remove from the selectedItems, which is all by default
    const newSelectedItems = defaultSelectedItems.reduce(
      (acc: string[], currentSelectedItem) => {
        if (
          // include.length === 0 ||
          include.includes(currentSelectedItem)
        ) {
          return [...acc, currentSelectedItem]
        }
        return acc
      },
      [],
    )
    setSelectedItems(newSelectedItems)
    const allSearchTerms = [
      ...(keywords.filter((kw) => kw) || []),
      ...(creativeType
        ? [
            `creative:${simplifyEnum(
              2,
              getEnumKey(creativeTypesEnum, creativeType) ||
                'CREATIVE_TYPE_UNSPECIFIED',
            )}`,
          ]
        : []),
      ...(audienceType
        ? [
            `audience:${simplifyEnum(
              2,
              getEnumKey(audienceTypesEnum, audienceType) ||
                'AUDIENCE_TYPE_UNKNOWN',
            )}`,
          ]
        : []),
      ...(orderLineStatus
        ? [
            `OL:${
              orderLineStatusOptions(isAdmin).find(({ value }) => {
                return value.toString() === orderLineStatus
              })?.label
            }`,
          ]
        : []),
    ]
    setSearchKeywords(allSearchTerms)

    if (currentOrg?.id && auth.user) {
      const [firstKeyword] = keywords // 7/5/22 - only one keyword is currently supported

      const { access_token } = auth.user

      if (access_token && searchServiceApi) {
        setLoading(true)
        searchServiceApi
          .advertisingPlatformServiceGlobalSearch(
            undefined, // orgIds,
            [firstKeyword], // keywords
            creativeType as Creativeservicev1Type, // creativeType
            undefined, // creativeStatus,
            orderLineStatus as V1OrderLineStatus,
            audienceType as V1JobType,
            undefined, // jobStatus,
            // TODO: when this is updated, check into it:
            undefined, // limit
            undefined, // offset
            getApiConfig(access_token),
          )
          .then(async (res: V1GlobalSearchResponse) => {
            setResults(res)
          })
          .catch((err) => {
            // if the search is 'no resources found', just return empty
            if (err.body?.message === 'no resources found') {
              setResults({})
            } else {
              showErrorMessage(
                'Error fetching results',
                `There was an error fetching results for '${firstKeyword}'. Please try again.`,
              )
            }
          })
          .finally(() => setLoading(false))
      }
    }
  }, [query, currentOrg?.id, auth.user?.access_token])

  useEffect(() => {
    return () => {
      setSearchType('all')
      setSearchValue('')
    }
  }, [])

  const handleRowClick = (
    clicked: Targetjobservicev1Audience | Creativeservicev1Creative,
  ) => {
    if (clicked.id === previewItem?.id) {
      setPreviewItem(undefined)
    } else {
      setPreviewItem(clicked)
    }
  }

  const filteredOrderLines = results?.orderLines?.filter((ol) => ol.name || '')

  const totalResults =
    ((selectedItems.includes('audiences') && results?.audiences?.length) || 0) +
    ((selectedItems.includes('creatives') && results?.creatives?.length) || 0) +
    ((selectedItems.includes('order lines') && filteredOrderLines?.length) ||
      0) +
    ((selectedItems.includes('orgs') && results?.orgs?.length) || 0)

  if (loading) return <Loading />

  return (
    <PageContent className="SearchPage">
      <MainWithSide
        side={previewItem && <PreviewPanel previewItem={previewItem} />}
        onSideClose={() => setPreviewItem(undefined)}
      >
        <header className="SearchPage__header mt-4 mb-4 flex flex-wrap items-center">
          <p className="text-grey-400 mr-6 text-sm">
            {totalResults} result{totalResults === 1 ? '' : 's'} found for
            {searchKeywords &&
              ` '${searchKeywords.slice(0, 3).join(', ')}'${
                searchKeywords.length > 3
                  ? `... + ${searchKeywords.length - 3}`
                  : ''
              }`}
          </p>
          <div className="SearchPage__filter-menu flex flex-wrap items-center">
            <p className="mr-4 font-bold">Refine by</p>
            {['audiences', 'creatives', 'order lines', 'orgs'].map((value) => {
              const isChecked = !!selectedItems.find((item) => item === value)
              const isDisabled = selectedItems.length <= 1 && isChecked
              return (
                <div
                  className={classNames('Checkbox mr-2', {
                    'Checkbox--checked': isChecked,
                    'opacity-75': isDisabled,
                  })}
                  key={value}
                >
                  <label
                    htmlFor={`${value}-do-not-include`}
                    className={classNames(
                      'Checkbox__wrapper-label flex items-center gap-1 px-2 hover:cursor-pointer',
                      { 'hover:cursor-default': isDisabled },
                    )}
                  >
                    <div className="Checkbox__input-wrapper h-4 w-4">
                      <input
                        className="Checkbox__input"
                        type="checkbox"
                        id={`${value}-do-not-include`}
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={() => {
                          if (!isChecked) {
                            // add to url
                            query.append('include', value)
                            history(`../search?${query}`)
                          } else {
                            // remove from url
                            const newSelections = selectedItems.filter(
                              (item) => item !== value,
                            )
                            query.delete('include')
                            newSelections.forEach((selected) => {
                              query.append('include', selected)
                            })
                            history(`../search?${query}`)
                          }
                        }}
                      />
                      <div className="Checkbox__checkmark" />
                    </div>
                    <span
                      className={classNames('Checkbox__label text-sm', {
                        'text-grey': isDisabled,
                      })}
                    >
                      <span>
                        {value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                          letter.toUpperCase(),
                        )}
                      </span>
                    </span>
                  </label>
                </div>
              )
            })}
          </div>
        </header>
        {!loading && totalResults === 0 ? (
          <div className="SearchPage__empty-state mt-8 flex h-full w-full flex-col items-center justify-center gap-4">
            <div className="SeachPage__svg-wrapper flex h-[35vh] w-auto items-center justify-center">
              <NoResultsSVG />
            </div>
            <Text size="xl" tag="p" className="text-secondary-700 font-bold">
              Hmmm, we didn&apos;t find anything
              {searchKeywords?.length
                ? ` for "${searchKeywords.join(', ')}"`
                : null}
            </Text>
            <Text size="l" className="font-light">
              Please check your spelling or try other search terms, including
              ID.
            </Text>
            <Text size="m" className="font-light">
              <strong>TIP:</strong> This search is not case sensitive and does
              not return partial results. For example, if you&apos;re looking
              for an asset called &apos;blueberry&apos;, then a search term of
              &apos;blue&apos; will not return a match. However,
              &apos;blueberry&apos; or &apos;blueberries&apos; will match.
            </Text>
            <Text size="m" className="font-light">
              Still can&apos;t find what you&apos;re looking for?{' '}
              <a
                href="https://eltoro.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold"
              >
                Contact support
              </a>
            </Text>
          </div>
        ) : (
          <>
            {results?.audiences &&
              selectedItems.includes('audiences') &&
              currentOrg?.id && (
                <SearchAudienceTable
                  handleRowClick={handleRowClick}
                  jobs={results.audiences}
                />
              )}
            {results?.creatives && selectedItems.includes('creatives') && (
              <SearchCreativeTable
                results={results.creatives}
                handleRowClick={handleRowClick}
              />
            )}
            {filteredOrderLines && selectedItems.includes('order lines') && (
              <SearchOrderLineTable results={filteredOrderLines} />
            )}
            {results?.orgs && selectedItems.includes('orgs') && (
              <SearchOrgTable results={results.orgs} />
            )}
          </>
        )}
      </MainWithSide>
    </PageContent>
  )
}
