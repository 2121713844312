import React, { useEffect, useState } from 'react'
import { AutoScroller, Fa, Tabs, TextHeader } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import {
  CollapseButton,
  NativeBannerForm,
  IconButton,
  IconButtons,
  CurrentStepType,
  AdTagForm,
  BannerAdForm,
  HTML5AdForm,
  VideoAdForm,
  OptionalFilters,
  ButtonConfigType,
  countFilters,
  ErroredWithReason,
} from 'Components'
import { ETIcon } from 'Assets'
import { useAppContext, useCampaignContext } from 'Contexts'
import { isVideo, simplifyEnum } from 'Helpers'
import { PreviewFileWithId } from 'Pages'
import { uploadCreative } from 'Requests'
import { creativeFilterConfig } from './creativeFilterConfig'
import { useLocation } from 'react-router-dom'

export const iconButtonList: ButtonConfigType[] = [
  {
    id: 'banner',
    title: 'Banner',
    subTitle: 'Appears on websites and apps',
    className: 'Icon_button',
    icon: <Fa className="text-primary" icon="image" size={3} type="duotone" />,
    tagLabel: 'banner',
  },
  {
    id: 'video',
    title: 'Video',
    subTitle: 'Appears on websites and apps',
    className: 'Icon_button',
    icon: <Fa className="text-primary" icon="video" size={3} type="duotone" />,
    tagLabel: 'video',
  },
  {
    id: 'ott',
    title: 'OTT',
    subTitle: 'Appears on video streaming services',
    className: 'Icon_button',
    icon: <Fa className="text-primary" icon="tv" size={3} type="duotone" />,
    tagLabel: 'video',
  },
  {
    id: 'native',
    title: 'Native Ad',
    subTitle: 'Appears as article on websites',
    className: 'Icon_button',
    disabled: false,
    comingSoon: false,
    icon: (
      <Fa className="text-primary" icon="browser" size={3} type="duotone" />
    ),
    tagLabel: 'banner',
  },
  {
    id: 'html5',
    title: 'HTML 5',
    subTitle: 'Appears on websites and apps',
    className: 'Icon_button',
    disabled: false,
    comingSoon: true,
    icon: <ETIcon className="HTML5Icon" icon="html5" />,
    tagLabel: 'banner',
  },
  {
    id: 'vast',
    title: 'VAST Tag',
    subTitle: 'Appears on websites, apps, and video streaming services',
    className: 'Icon_button',
    icon: <ETIcon icon="vast-tag" />,
    tagLabel: 'video',
  },
  {
    id: 'adTag',
    title: 'Ad Tag',
    subTitle: 'Appears on websites and apps',
    className: 'Icon_button',
    icon: <ETIcon icon="ad-tag" />,
    tagLabel: 'banner',
  },
  {
    id: 'audio',
    title: 'Audio',
    subTitle: 'Appears on audio streaming services',
    className: 'Icon_button',
    disabled: true,
    comingSoon: true,
    icon: <Fa className="text-primary" icon="volume" size={3} type="duotone" />,
    tagLabel: 'audio',
  },
  {
    id: 'directMail',
    title: 'Direct Mail',
    subTitle: 'Send direct mail to your audiences',
    className: 'Icon_button',
    disabled: true,
    comingSoon: true,
    icon: <ETIcon className="text-primary" icon="direct-mail" />,
    tagLabel: 'print',
  },
]

export const CreateTypeSelector = ({
  creativeTitle = `Select the creatives you’d like to show your audience`,
}: {
  creativeTitle?: string
}) => {
  const [filter, setFilter] = useState<string>('all')
  const [selected, setSelected] = useState('')
  const [collapsedButtons, setCollapsedButtons] = useState<boolean>(false)
  const [uploadFiles, setFiles] = useState<PreviewFileWithId[]>([])
  const [completed, setCompleted] = useState<PreviewFileWithId[]>([])
  const [errored, setErrored] = useState<ErroredWithReason[]>([])
  const [progress, setProgress] = useState<
    { id: string; uploadProgress: number; currentStep?: CurrentStepType }[]
  >([])

  const { currentOrg, creativeServiceApi, rebuild, roles } = useAppContext()
  const { isPolitical } = useCampaignContext()
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  const updateProgress = (id: string, prog: number, step?: CurrentStepType) => {
    const updatedProgress = {
      id,
      uploadProgress: prog,
      currentStep: step || ('upload' as CurrentStepType),
    }
    setProgress((prev) => {
      const filtered = prev.filter((p) => p.id !== id)
      return [...filtered, updatedProgress]
    })
  }
  const handleUpload = async (
    fileandId: PreviewFileWithId,
    token: string,
    onUpload?: (creative: Creativeservicev1Creative) => void,
  ) => {
    if (!token) return
    if (rebuild) return
    if (!currentOrg || !currentOrg.id) return

    updateProgress(fileandId.id, 0) // Initiate progress
    const creative = await uploadCreative(
      fileandId.fileData.name,
      fileandId.fileData,
      token,
      currentOrg?.id,
      (prog) => {
        updateProgress(fileandId.id, prog)
      },
      selected === 'html5' ? 'CREATIVE_FILE_TYPE_ZIP' : undefined,
    ).catch((err) => {
      setErrored((prev) => [...prev, { file: fileandId, reason: err }])
    })
    if (!creative) return
    updateProgress(fileandId.id, 100, 'uploaded')
    if (!creative.id || !creative.mime || !currentOrg || !currentOrg.id) return
    const creativeType = () => {
      if (!creative.mime) return undefined
      if (selected === 'html5') return 'CREATIVE_TYPE_HTML5'
      if (selected === 'ott') return 'CREATIVE_TYPE_OTT'
      return isVideo(creative.mime)
        ? 'CREATIVE_TYPE_VIDEO'
        : 'CREATIVE_TYPE_BANNER'
    }
    creativeServiceApi
      ?.advertisingPlatformServiceCreateCreative(currentOrg.id, {
        files: [creative],
        name: creative.name,
        type: creativeType(),
      })
      .then((res) => {
        updateProgress(fileandId.id, 100, 'created')
        setCompleted((prev) => [...prev, fileandId])
        if (onUpload) onUpload(res)
      })
      .catch(() => setErrored((prev) => [...prev, { file: fileandId }]))
  }
  const uploaderProps = { setFiles, uploadFiles, handleUpload }
  const uploaderStatusProps = {
    uploadFiles,
    setFiles,
    completed,
    progress,
    errored,
  }

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => {
    setSelected(buttonId)
    setCollapsedButtons(true)
  }

  const handleTabChange = (buttonId: string) => {
    setSelected(buttonId)
  }

  const headerText = () => {
    if (!selected) return creativeTitle
    return (
      <div className="AudienceHeaderText flex items-center gap-2">
        <span className="CreativeTitle__txt">{creativeTitle}</span>
        <span className="text-tint-gray-500 scale-x-50">&#62;</span>
        <span className="text-tint-gray-900">
          {iconButtonList.find((conf) => conf.id === selected)?.title}
        </span>
      </div>
    )
  }

  return (
    <div className="CreativeTypeSelector h-full">
      <div className="CreativeTypeSelector_creative-types flex h-auto flex-1 flex-col">
        <header className="CreativeTypeSelector__headerSub headerSub flex flex-1 gap-2">
          <TextHeader
            className="CreativeTypeSelector__createText !font-regular"
            type={4}
          >
            {headerText()}
          </TextHeader>
          <CollapseButton
            className="CreativeTypeSelector__collapse-button w-20"
            isCollapsed={collapsedButtons}
            handleClick={() => {
              setSelected('')
              setCollapsedButtons(!collapsedButtons)
            }}
          />
        </header>

        <IconButtons
          className="CreativeTypeSelector__IconButtons"
          classNameHeader="CreativeTypeSelector absolute pl-1 flex h-full items-center justify-start"
          isCollapsed={collapsedButtons}
          filterChildren={
            !collapsedButtons && (
              <OptionalFilters
                currentFilter={filter}
                handleChangeFilter={(filter) => setFilter(filter)}
                filterConfig={countFilters(
                  creativeFilterConfig,
                  iconButtonList,
                  roles?.includes('nextgen_admin') || false,
                )}
              />
            )
          }
          iconChildren={iconButtonList.map((button) => {
            if (filter !== 'all' && filter !== button.tagLabel) {
              return undefined
            }
            if (
              isPolitical &&
              isInCampaignCreation &&
              (button.id === 'native' || button.id === 'html5')
            ) {
              return undefined
            }
            return (
              <IconButton
                key={button.id}
                {...button}
                isSelected={selected === button.id}
                handleClick={handleButtonClick}
              />
            )
          })}
        />
      </div>
      {(selected === 'banner' ||
        selected === 'html5' ||
        selected === 'adTag' ||
        selected === 'native') && (
        <Tabs
          classNamePanel={`Banners__tabs flex-1 w-full TabSelected-${selected}`}
          tabs={[
            {
              id: 'banner',
              label: (
                <>
                  <span aria-label="Banner" data-tooltip="top">
                    <Fa icon="image" size={2} type="duotone" />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text flex flex-col">
                    <span className="CreativeTypeSelector__tabs-category Tabs-category">
                      Banner
                    </span>
                    <span>Banner</span>
                  </span>
                </>
              ),
              onClick: () => handleTabChange('banner'),
              defaultTab: selected === 'banner',
              component: (
                <BannerAdForm
                  uploaderProps={uploaderProps}
                  uploaderStatusProps={uploaderStatusProps}
                  isPoliticalAndCampaignCreation={
                    isInCampaignCreation && isPolitical
                  }
                />
              ),
            },
            {
              id: 'adtag',
              label: (
                <>
                  <span aria-label="Ad Tag Banner" data-tooltip="top">
                    <ETIcon
                      className="CreativeTypeSelector__tabs--icon"
                      icon="ad-tag"
                    />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text flex flex-col">
                    <span className="CreativeTypeSelector__tabs-category Tabs-category">
                      Banner
                    </span>
                    <span>Ad Tag</span>
                  </span>
                </>
              ),
              onClick: () => handleTabChange('adTag'),
              defaultTab: selected === 'adTag',
              component: (
                <AdTagForm
                  type="banner"
                  isPoliticalAndCampaignCreation={
                    isInCampaignCreation && isPolitical
                  }
                />
              ),
            },
          ].concat(
            isInCampaignCreation && isPolitical
              ? []
              : [
                  {
                    id: 'html5',
                    label: (
                      <>
                        <span aria-label="HTML5 Banner" data-tooltip="top">
                          <ETIcon
                            className="CreativeTypeSelector__tabs--icon"
                            icon="html5"
                          />
                        </span>
                        <span className="CreativeTypeSelector__tabs--text flex flex-col">
                          <span className="CreativeTypeSelector__tabs-category Tabs-category">
                            Banner
                          </span>
                          <span>HTML5 Ad</span>
                        </span>
                      </>
                    ),
                    onClick: () => handleTabChange('html5'),
                    defaultTab: selected === 'html5',
                    component: (
                      <HTML5AdForm
                        uploaderProps={uploaderProps}
                        uploaderStatusProps={uploaderStatusProps}
                        isPoliticalAndCampaignCreation={
                          isPolitical && isInCampaignCreation
                        }
                      />
                    ),
                  },
                  {
                    id: 'native',
                    label: (
                      <>
                        <span aria-label="Native Ad Banner" data-tooltip="top">
                          <Fa icon="browser" size={2} type="duotone" />
                        </span>
                        <span className="CreativeTypeSelector__tabs--text flex flex-col">
                          <span className="CreativeTypeSelector__tabs-category Tabs-category">
                            Banner
                          </span>
                          <span>Native Ad</span>
                        </span>
                      </>
                    ),
                    onClick: () => handleTabChange('native'),
                    defaultTab: selected === 'native',
                    component: <NativeBannerForm />,
                  },
                ],
          )}
        />
      )}

      {(selected === 'video' || selected === 'ott' || selected === 'vast') && (
        <Tabs
          classNamePanel={`Video__tabs flex-1 w-full ${selected}`}
          tabs={[
            {
              id: 'video',
              label: (
                <>
                  <span aria-label="Video ad" data-tooltip="top">
                    <Fa icon="video" size={2} type="duotone" />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text flex flex-col">
                    <span className="CreativeTypeSelector__tabs-category Tabs-category">
                      Video
                    </span>
                    <span>Video</span>
                  </span>
                </>
              ),
              onClick: () => handleTabChange('video'),
              defaultTab: selected === 'video',
              component: (
                <VideoAdForm
                  uploaderProps={uploaderProps}
                  uploaderStatusProps={uploaderStatusProps}
                  uploadType="video"
                  isPoliticalAndCampaignCreation={
                    isInCampaignCreation && isPolitical
                  }
                />
              ),
            },
            {
              id: 'ott',
              label: (
                <>
                  <span aria-label="OTT Video Ad" data-tooltip="top">
                    <Fa
                      className="text-primary"
                      icon="tv"
                      size={3}
                      type="duotone"
                    />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text flex flex-col">
                    <span className="CreativeTypeSelector__tabs-category Tabs-category">
                      Video
                    </span>
                    <span>OTT</span>
                  </span>
                </>
              ),
              onClick: () => handleTabChange('ott'),
              defaultTab: selected === 'ott',
              component: (
                <VideoAdForm
                  uploaderProps={uploaderProps}
                  uploaderStatusProps={uploaderStatusProps}
                  uploadType="OTT"
                  isPoliticalAndCampaignCreation={
                    isInCampaignCreation && isPolitical
                  }
                />
              ),
            },
            {
              id: 'vast',
              label: (
                <>
                  <span aria-label="VAST TAG Video" data-tooltip="top">
                    <ETIcon icon="vast-tag" />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text flex flex-col">
                    <span className="CreativeTypeSelector__tabs-category Tabs-category">
                      Video
                    </span>
                    <span>VAST Tag</span>
                  </span>
                </>
              ),
              onClick: () => handleTabChange('vast'),
              defaultTab: selected === 'vast',
              component: (
                <AdTagForm
                  type="video"
                  isPoliticalAndCampaignCreation={
                    isInCampaignCreation && isPolitical
                  }
                />
              ),
            },
          ]}
        />
      )}
    </div>
  )
}
