import { useState } from 'react'
import { Button, Fa, Modal } from '@eltoro-ui/components'
import { PreviewWithId } from './SupportRequestModal'

const SupportRequestMediaThumbnail = ({
  currentFile,
  onRemove,
}: {
  currentFile: PreviewWithId
  onRemove: (fileId: string) => void
}) => {
  const [openPreview, setOpenPreview] = useState(false)
  const { file, id } = currentFile
  return (
    <div className="SupportRequestUploadList__file bg-gray flex items-center justify-between rounded p-2 shadow-sm">
      <div className="flex items-center gap-1">
        {(() => {
          if (file.type === 'text/csv')
            return <Fa icon="file-csv" size={2} type="duotone" />
          if (
            [
              'application/zip',
              'application/x-gzip',
              'application/x-zip-compressed',
              'application/x-compressed',
              'zip',
            ].includes(file.type)
          )
            return <Fa icon="file-zipper" size={2} type="duotone" />
          if (['image/jpeg', 'image/gif', 'image/png'].includes(file.type))
            return (
              <>
                <button onClick={() => setOpenPreview(true)}>
                  <img
                    src={currentFile.file.preview}
                    alt="thumbnail"
                    className="CreativeThumbnail--size-xs"
                  />
                </button>
                {openPreview && (
                  <Modal offClick={() => setOpenPreview(false)}>
                    <div className="SupportRequestUploadList__preview-close-btn mb-2 flex items-center justify-between gap-2">
                      <p className="font-bold">{currentFile.file.name}</p>
                      <Button
                        iconOnly={<Fa icon="times" size={1} />}
                        rounded
                        onClick={() => setOpenPreview(false)}
                      />
                    </div>
                    <img
                      src={currentFile.file.preview}
                      alt={currentFile.file.name}
                    />
                  </Modal>
                )}
              </>
            )
          if (
            [
              'video/avi',
              'video/mp4',
              'video/mpeg',
              'video/ogv',
              'video/ts',
              'video/webm',
              'video/3gp',
              'video/3g2',
              'video/mov',
            ].includes(file.type)
          )
            return (
              <>
                <button onClick={() => setOpenPreview(true)}>
                  <video className="CreativeThumbnail--size-xs">
                    <source
                      src={currentFile.file.preview}
                      type={currentFile.file.type}
                    />
                    <track default kind="captions" />
                    <Fa icon="file-video" size={2} type="duotone" />
                  </video>
                </button>
                {openPreview && (
                  <Modal offClick={() => setOpenPreview(false)}>
                    <div className="SupportRequestUploadList__preview-close-btn mb-2 flex items-center justify-between gap-2">
                      <p className="font-bold">{currentFile.file.name}</p>
                      <Button
                        iconOnly={<Fa icon="times" size={1} />}
                        rounded
                        onClick={() => setOpenPreview(false)}
                      />
                    </div>
                    <video>
                      <source
                        src={currentFile.file.preview}
                        type={currentFile.file.type}
                      />
                      <track default kind="captions" />
                      Your browser does not support the video element.
                    </video>
                  </Modal>
                )}
              </>
            )
          return <Fa icon="file" size={2} type="duotone" />
        })()}
        <p>{file.name}</p>
      </div>

      <div className="flex items-center gap-2">
        <Fa
          icon="xmark"
          size={1}
          onClick={() => onRemove(id)}
          className="ml-2"
        />
      </div>
    </div>
  )
}

export const SupportRequestUploadList = ({
  files,
  onRemove,
}: {
  files: PreviewWithId[]
  onRemove: (fileId: string) => void
}) => {
  return (
    <div className="SupportRequestUploadList animate-slidedown my-2 flex flex-col gap-2 overflow-y-auto px-1 max-h-48">
      {files.map((currentFile) => (
        <SupportRequestMediaThumbnail
          currentFile={currentFile}
          onRemove={onRemove}
          key={currentFile.id}
        />
      ))}
    </div>
  )
}
