import { ReactNode, useState } from 'react'
import { Button, Fa } from '@eltoro-ui/components'
import { styleTailwind, v2SyncPrompt } from 'Helpers'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'

const EditableButtonWrap = styleTailwind(
  'div',
  'EditableSectionButton--wrap Button--wrap mt-3 flex justify-end gap-2',
)

export const EditableSection = ({
  label,
  writableBody,
  readableBody,
  canSubmit = true,
  canDelete = false,
  onSave,
  onDelete,
  onCancel,
  isLoading = false,
}: {
  label: string
  canSubmit?: boolean
  canDelete?: boolean
  onSave: () => void
  onDelete?: () => void
  onCancel?: () => void
  writableBody: ReactNode
  readableBody: ReactNode
  isLoading?: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const { roles, isOnHold, currentOrg } = useAppContext()
  const PrimaryHeader = styleTailwind('h2', 'capitalize text-primary')

  const handleSave = () => {
    setIsEditing(false)
    onSave()
  }

  const handleCancel = () => {
    setIsEditing(false)
    if (onCancel) onCancel()
  }

  const headerButtons = () => {
    if (
      canDelete &&
      !isEditing &&
      roles &&
      !roles.includes('nextgen_read_only') &&
      !isOnHold
    ) {
      return (
        <EditableButtonWrap>
          <div
            className="EditableSectionButton RemoveButton"
            aria-label="Click to delete"
            data-tooltip="left"
          >
            <Button
              iconOnly={<Fa icon="times" size={1} />}
              onClick={onDelete}
              title="Delete item"
            />
          </div>
          <div
            className="EditableSectionButton Button--wrap EditButton"
            aria-label="Click to edit"
            data-tooltip="left"
          >
            <Button
              iconOnly={<Fa icon="pencil" size={1} />}
              onClick={() => v2SyncPrompt(() => setIsEditing(true), currentOrg)}
              title="Edit item"
            />
          </div>
        </EditableButtonWrap>
      )
    }
    return (
      !isEditing &&
      roles &&
      !roles.includes('nextgen_read_only') &&
      !isOnHold && (
        <EditableButtonWrap>
          <div className="EditableSectionButton EditButton">
            <Button
              iconOnly={<Fa icon="pencil" size={1} />}
              onClick={() => setIsEditing(true)}
              title="Add item"
            />
          </div>
        </EditableButtonWrap>
      )
    )
  }

  return (
    <div className="EditableSection">
      {!(label === '') && (
        <div className="EditableSection__label">
          <PrimaryHeader>{label}</PrimaryHeader>
        </div>
      )}
      {!isEditing && (
        <div
          className={classNames('relative', {
            'opacity-20': isLoading,
          })}
        >
          <div
            role="button"
            onClick={() => {
              if (roles && !roles.includes('nextgen_read_only') && !isOnHold)
                setIsEditing(true)
            }}
            onKeyDown={() => {
              if (roles && !roles.includes('nextgen_read_only') && !isOnHold)
                setIsEditing(true)
            }}
            className="EditableSection__body"
            tabIndex={0}
          >
            {readableBody}
            {isLoading && (
              <Fa
                icon="spinner"
                size={2}
                className="EditableSection__loading absolute top-[5%] right-[50%] animate-spin"
              />
            )}
          </div>
        </div>
      )}
      {isEditing && (
        <div className="EditableSection__edit">
          {writableBody}
          <div className="EditableSectionButton Button--wrap mt-3 flex justify-end gap-2">
            <Button onClick={handleCancel} title="Cancel">
              Cancel
            </Button>
            <Button
              disabled={!canSubmit}
              onClick={handleSave}
              kind="primary"
              title={!canSubmit ? 'Button disabled' : 'Edit Item'}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {headerButtons()}
    </div>
  )
}
