import React, { useRef, useState } from 'react'
import { Fa, Popover } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import './InfoPopover.scss'

export const InfoPopover = ({
  content,
  className = 'info-circle',
  classNameWrap = '',
  icon = 'info-circle',
}: {
  content: React.ReactNode
  className?: string
  classNameWrap?: string
  icon?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const infoContentRef = useRef<HTMLDivElement>(null)
  const infoButtonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([infoContentRef, infoButtonRef], () => setIsOpen(false))

  return (
    <Popover
      position={['bottom']}
      align="center"
      content={
        <div
          ref={infoContentRef}
          className={`InfoPopover__popover-container ${classNameWrap}`}
        >
          {content}
        </div>
      }
      isOpen={isOpen}
    >
      <div
        className="InfoPopover h-full w-min"
        ref={infoButtonRef}
        aria-label="Click for info"
        data-tooltip="right"
      >
        <Fa
          icon={icon}
          onClick={() => !isOpen && setIsOpen(true)}
          className={`InfoPopover__popover-icon ${className} ${
            isOpen ? 'active' : ''
          } ${
            className === 'warning' ? 'text-warning-500' : 'text-base-info-500'
          }`}
          size={'sm'}
        />
      </div>
    </Popover>
  )
}
