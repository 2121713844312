/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Pixel } from '../models/V1Pixel';
import { V1Publisher } from '../models/V1Publisher';
import { V1RuleSet } from '../models/V1RuleSet';
import { V1Stats } from '../models/V1Stats';
import { V1UUID } from '../models/V1UUID';
import { HttpFile } from '../http/http';

export class Hagridv1Subscription {
    /**
    * Id of the subscription
    */
    'id'?: string;
    /**
    * RefId of the subscription
    */
    'refId'?: string;
    /**
    * OrgId of the subscription
    */
    'orgId'?: string;
    /**
    * Name of the subscription
    */
    'name'?: string;
    /**
    * whether subscription is enabled or not
    */
    'enabled'?: boolean;
    /**
    * Time the subscription was created
    */
    'created'?: Date;
    /**
    * Time the subscription was updated
    */
    'updated'?: Date;
    /**
    * Time Period of the subscription
    */
    'timePeriod'?: number;
    /**
    * Time the subscription started
    */
    'startTime'?: number;
    /**
    * How long to exclude ips found from a pixel
    */
    'excludedTime'?: number;
    /**
    * Whether to send results to the server at the end of a run or not
    */
    'sendToFileServer'?: boolean;
    /**
    * PublisherID of the subscription's publisher
    */
    'publisherId'?: string;
    /**
    * Id of the subscription's Pixel
    */
    'pixelId'?: string;
    /**
    * ID of the UUID associated with the subscription
    */
    'uuidId'?: string;
    /**
    * file path to put files on ftp server
    */
    'ftpPath'?: string;
    /**
    * Whether to include the ET hash in the files
    */
    'includeEthash'?: boolean;
    /**
    * Max results to target after all exclusions are made
    */
    'matchCap'?: number;
    /**
    * States filtered out
    */
    'statesFiltered'?: Array<string>;
    /**
    * Zips Filtered out
    */
    'zipsFiltered'?: Array<string>;
    'publisher'?: V1Publisher;
    'uuid'?: V1UUID;
    'pixel'?: V1Pixel;
    'ruleSet'?: V1RuleSet;
    'latestStats'?: V1Stats;
    /**
    * All the stats for the subscriptions runs
    */
    'stats'?: Array<V1Stats>;
    /**
    * The Id of the latest failed run for this subscription
    */
    'failId'?: number;
    /**
    * The Id of the latest failed run for this subscription
    */
    'succeeded'?: boolean;
    /**
    * The first day the subscription should start running
    */
    'startDate'?: Date;
    /**
    * The last day the subscription should run
    */
    'endDate'?: Date;
    /**
    * whether to run subscription during flight dates or to run indefinitely
    */
    'flightDatesEnabled'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "timePeriod",
            "baseName": "time_period",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "excludedTime",
            "baseName": "excluded_time",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "sendToFileServer",
            "baseName": "send_to_file_server",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "publisherId",
            "baseName": "publisher_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "pixelId",
            "baseName": "pixel_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "uuidId",
            "baseName": "uuid_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "ftpPath",
            "baseName": "ftp_path",
            "type": "string",
            "format": ""
        },
        {
            "name": "includeEthash",
            "baseName": "include_ethash",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "matchCap",
            "baseName": "match_cap",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "statesFiltered",
            "baseName": "states_filtered",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "zipsFiltered",
            "baseName": "zips_filtered",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "publisher",
            "baseName": "publisher",
            "type": "V1Publisher",
            "format": ""
        },
        {
            "name": "uuid",
            "baseName": "uuid",
            "type": "V1UUID",
            "format": ""
        },
        {
            "name": "pixel",
            "baseName": "pixel",
            "type": "V1Pixel",
            "format": ""
        },
        {
            "name": "ruleSet",
            "baseName": "rule_set",
            "type": "V1RuleSet",
            "format": ""
        },
        {
            "name": "latestStats",
            "baseName": "latestStats",
            "type": "V1Stats",
            "format": ""
        },
        {
            "name": "stats",
            "baseName": "stats",
            "type": "Array<V1Stats>",
            "format": ""
        },
        {
            "name": "failId",
            "baseName": "fail_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "succeeded",
            "baseName": "succeeded",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "flightDatesEnabled",
            "baseName": "flight_dates_enabled",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Hagridv1Subscription.attributeTypeMap;
    }

    public constructor() {
    }
}

