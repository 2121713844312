// This file collects all options that might appear in various search scenarios (global search, table filters)

import {
  audienceStatusEnum,
  audienceTypesEnum,
  creativeStatusesEnum,
  creativeTypesEnum,
} from 'Enums'
import { simplifyEnum } from 'Helpers'
import { Creativeservicev1Status, V1AudienceStatus } from 'next-gen-sdk'

/** CREATIVES */

export const creativeStatusOptions = ([
  // 'CREATIVE_STATUS_UNSPECIFIED',
  'CREATIVE_STATUS_ACTIVE',
  'CREATIVE_STATUS_CREATING',
  'CREATIVE_STATUS_APPROVED',
  'CREATIVE_STATUS_ERROR',
  'CREATIVE_STATUS_EXPIRED',
  'CREATIVE_STATUS_PURGED',
  'CREATIVE_STATUS_UNARCHIVING',
  'CREATIVE_STATUS_ARCHIVED',
] as Creativeservicev1Status[]).map((value) => {
  return { label: simplifyEnum(2, value), value: creativeStatusesEnum[value] }
})

export const creativeTypeOptions = [
  {
    label: 'Banner',
    value: creativeTypesEnum['CREATIVE_TYPE_BANNER'],
  },
  {
    label: 'Video',
    value: creativeTypesEnum['CREATIVE_TYPE_VIDEO'],
  },
  {
    label: 'Native Banner',
    value: creativeTypesEnum['CREATIVE_TYPE_NATIVE_BANNER'],
  },
  {
    label: 'Native Video',
    value: creativeTypesEnum['CREATIVE_TYPE_NATIVE_VIDEO'],
  },
  {
    label: 'HTML5',
    value: creativeTypesEnum['CREATIVE_TYPE_HTML5'],
  },
  {
    label: 'VAST Tag',
    value: creativeTypesEnum['CREATIVE_TYPE_VAST_TAG'],
  },
  {
    label: 'Ad Tag',
    value: creativeTypesEnum['CREATIVE_TYPE_AD_TAG'],
  },
  {
    label: 'OTT',
    value: creativeTypesEnum['CREATIVE_TYPE_OTT'],
  },
  {
    label: 'Audio',
    value: creativeTypesEnum['CREATIVE_TYPE_AUDIO'],
  },
  {
    label: 'Direct Mail',
    value: creativeTypesEnum['CREATIVE_TYPE_MAIL_PIECE'],
  },
]

export const creativeFileTypeOptions = [
  {
    label: 'AVI',
    value: 'avi',
  },
  {
    label: 'MP4',
    value: 'mp4',
  },
  {
    label: 'MPEG',
    value: 'mpeg',
  },
  {
    label: 'OGV',
    value: 'ogv',
  },
  {
    label: 'TS',
    value: 'ts',
  },
  {
    label: 'WEBM',
    value: 'webm',
  },
  {
    label: '3GP',
    value: '3gp',
  },
  {
    label: '3G2',
    value: '3g2',
  },
  {
    label: 'BMP',
    value: 'bmp',
  },
  {
    label: 'GIF',
    value: 'gif',
  },
  {
    label: 'ICO',
    value: 'ico',
  },
  {
    label: 'JPEG',
    value: 'jpeg',
  },
  {
    label: 'JPG',
    value: 'jpg',
  },
  {
    label: 'PNG',
    value: 'png',
  },
  {
    label: 'SVG',
    value: 'svg',
  },
  {
    label: 'TIF',
    value: 'tif',
  },
  {
    label: 'TIFF',
    value: 'tiff',
  },
  {
    label: 'WEBP',
    value: 'webp',
  },
  {
    label: 'ZIP',
    value: 'zip',
  },
]

/** ORDER LINES */

export const orderLineStatusOptions = (isAdmin?: boolean) =>
  [
    ['ORDERLINE_STATE_UNSPECIFIED', 0],
    ['ORDERLINE_STATE_DRAFT', 1],
    ['ORDERLINE_STATE_IN_REVIEW', 2],
    ['ORDERLINE_STATE_APPROVED', 3],
    ['ORDERLINE_STATE_REJECTED', 4],
    ['ORDERLINE_STATE_ACTIVE', 5],
    ['ORDERLINE_STATE_PAUSED', 6],
    ['ORDERLINE_STATE_COMPLETED', 7],
    ['ORDERLINE_STATE_ARCHIVED', 8],
  ].map(([label, value]) => {
    return { label: simplifyEnum(2, label.toString(), true), value }
  })

export const orderLinePaceOptions = [
  {
    label: 'Under Pace',
    value: 'underPace',
  },
  {
    label: 'On Pace',
    value: 'onPace',
  },
  {
    label: 'Over Pace',
    value: 'overPace',
  },
  {
    label: 'Not Started',
    value: 'notStarted',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
]

/** AUDIENCES */

export const audienceStatusOptions = ([
  'AUDIENCE_STATUS_UNKNOWN',
  'AUDIENCE_STATUS_CREATED',
  'AUDIENCE_STATUS_QUEUED',
  'AUDIENCE_STATUS_RUNNING',
  'AUDIENCE_STATUS_COMPLETED',
  'AUDIENCE_STATUS_READY',
  'AUDIENCE_STATUS_CANCELLED',
  'AUDIENCE_STATUS_ERRORED',
  'AUDIENCE_STATUS_WAITING',
  'AUDIENCE_STATUS_EXPIRED',
  'AUDIENCE_STATUS_PURGED',
  'AUDIENCE_STATUS_UNARCHIVING',
  'AUDIENCE_STATUS_ARCHIVED',
] as V1AudienceStatus[]).map((value) => {
  if (value === 'AUDIENCE_STATUS_RUNNING')
    return { label: 'Processing', value: audienceStatusEnum[value] }
  return { label: simplifyEnum(2, value), value: audienceStatusEnum[value] }
})

export const audienceTypeOptions = [
  {
    label: 'Device ID',
    value: audienceTypesEnum['AUDIENCE_TYPE_DEVICE'],
  },
  {
    label: 'Digital Canvassing',
    value: audienceTypesEnum['AUDIENCE_TYPE_DC'],
  },
  {
    label: 'Escrow Mover',
    value: audienceTypesEnum['AUDIENCE_TYPE_ESCROWMOVER'],
  },
  {
    label: 'Devices For Households',
    value: audienceTypesEnum['AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS'],
  },
  {
    label: 'IP Address List',
    value: audienceTypesEnum['AUDIENCE_TYPE_IP'],
  },
  {
    label: 'IP List for Mailing',
    value: audienceTypesEnum['AUDIENCE_TYPE_ADDRESSES_FOR_IP'],
  },
  {
    label: 'IP Targeting: B2B',
    value: audienceTypesEnum['AUDIENCE_TYPE_B2B'],
  },
  {
    label: 'IP Targeting: B2C',
    value: audienceTypesEnum['AUDIENCE_TYPE_B2C'],
  },
  {
    label: 'Map Polygon',
    value: audienceTypesEnum['AUDIENCE_TYPE_IPSFORGEOJSON'],
  },
  {
    label: 'New Mover',
    value: audienceTypesEnum['AUDIENCE_TYPE_POSTMOVER'],
  },
  {
    label: 'Pre mover',
    value: audienceTypesEnum['AUDIENCE_TYPE_PREMOVER'],
  },
  {
    label: 'Retargeting',
    value: audienceTypesEnum['AUDIENCE_TYPE_RETARGETING'],
  },
  {
    label: 'Reverse IP',
    value: audienceTypesEnum['AUDIENCE_TYPE_REVERSE'],
  },
  {
    label: 'Venue Replay',
    value: audienceTypesEnum['AUDIENCE_TYPE_VR'],
  },
  {
    label: 'Zip Code List',
    value: audienceTypesEnum['AUDIENCE_TYPE_ZIP'],
  },
]

/** BILLING */

export const billingInvoiceStatusOptions = [
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Processing',
    value: 'processing',
  },
  {
    label: 'Declined',
    value: 'declined',
  },
  {
    label: 'Payment Due',
    value: 'paymentDue',
  },
  {
    label: 'Partially Paid',
    value: 'partiallyPaid',
  },
  {
    label: 'Past Due',
    value: 'pastDues',
  },
]

/** ORGS */

export const orgRolesOptions = [
  {
    label: 'Org Admin',
    value: 'admin',
  },
  {
    label: 'Org User',
    value: 'user',
  },
]
