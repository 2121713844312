import { TemplateType } from 'index'
import React from 'react'
import { Text, Spacer, Fa } from '@eltoro-ui/components'
import './Template.scss'

export const Template: React.FC<{
  template: TemplateType
  onSelect: (newTemplate: TemplateType) => void
}> = ({ template, onSelect }) => {
  return (
    <div className="Template">
      <button
        onClick={() => onSelect(template)}
        className={`Template__select-button ${
          template.id === 'create_new' ? 'Template__select-button--blank' : ''
        }`}
        type="button"
      >
        {template.id === 'create_new' ? (
          <Fa icon="plus" size={1} />
        ) : (
          <img
            src={template.preview}
            alt="template thumbnail"
            className="Template__img"
          />
        )}
      </button>
      <Spacer height="0.5rem" />
      <Text on="white" weight="bold">
        {template.label}
      </Text>
    </div>
  )
}
