import React, { useState } from 'react'
import {
  showErrorMessage,
  Table,
  TableColumnType,
  TextHeader,
} from '@eltoro-ui/components'
import { usePagination } from '@eltoro-ui/hooks'
import {
  V1JobType,
  V1TargetJobSearch,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import { simplifyEnum, userFriendlyAudienceName } from 'Helpers'
import { ETIcon } from 'Assets'
import { EmptyField, TablePagination } from 'Components'
import { audienceTypeOptions } from 'searchOptions'
import { OrgNameCell } from 'Pages/SearchPage/OrgNameCell'
import { useAppContext } from 'Contexts'

const getTargetIconName = (type: V1JobType) => {
  if (type === 'JOB_TYPE_MAID' || type === 'JOB_TYPE_DEVICE') return 'maid'
  if (type === 'JOB_TYPE_ESCROWMOVER' || type === 'JOB_TYPE_PREMOVER')
    return 'digital-new-movers'
  if (type === 'JOB_TYPE_POSTMOVER') return 'movers'
  if (type === 'JOB_TYPE_VR') return 'venue-replay'
  if (type === 'JOB_TYPE_B2B') return 'b2b'
  if (type === 'JOB_TYPE_B2C') return 'b2c'
  if (type === 'JOB_TYPE_DC') return 'digital-canvassing'
  if (type === 'JOB_TYPE_RETARGETING') return 'retargeting'
  if (type === 'JOB_TYPE_IP') return 'ip-targeting'
  if (type === 'JOB_TYPE_IPSFORGEOJSON' || type === 'JOB_TYPE_IPSFORWKB')
    return 'map-polygon'
  if (type === 'JOB_TYPE_DIRECTMAIL') return 'direct-mail'
  if (type === 'JOB_TYPE_ZIP') return 'locator'
  return 'target'
}

const searchJobTableColumns: TableColumnType<V1TargetJobSearch>[] = [
  {
    path: 'name',
    label: 'Name',
    filterOn: 'string',
    RowCell: ({ name, type }) => {
      return (
        <>
          {type && (
            <ETIcon
              className="AudienceLibrary__icon-custom h-9 w-11"
              icon={getTargetIconName(type)}
            />
          )}
          {name ? (
            <div className="AudienceLibrary__icon-filename ml-2 flex max-w-full flex-1 items-center gap-1">
              <span className="TruncateFileName flex-1 truncate">{name}</span>
            </div>
          ) : (
            <div className="AudienceLibrary__icon-filename flex max-w-full flex-1 items-center gap-1">
              No name
            </div>
          )}
        </>
      )
    },
  },
  {
    path: 'type',
    label: 'Type',
    filterOn: audienceTypeOptions,
    RowCell: ({ type }) =>
      type ? userFriendlyAudienceName(type) : <EmptyField />,
  },
  {
    path: 'status',
    label: 'Status',
    removeFilter: true,
    RowCell: ({ status }) =>
      status ? simplifyEnum(2, status) : <EmptyField />,
  },
  {
    path: 'orgId',
    label: 'Source Org',
    removeFilter: true,
    RowCell: ({ orgLogo, orgName }) =>
      orgName ? <OrgNameCell name={orgName} logo={orgLogo} /> : <EmptyField />,
  },
  {
    path: 'targetJobId',
    label: 'ID',
    removeFilter: true,
  },
]

export const SearchAudienceTable: React.FC<{
  handleRowClick: (clicked: Targetjobservicev1Audience) => void
  jobs?: V1TargetJobSearch[]
}> = ({ jobs, handleRowClick }) => {
  const [clicked, setClicked] = useState<V1TargetJobSearch>()
  const { audienceServiceApi } = useAppContext()

  const getAudience = async (audience: V1TargetJobSearch) => {
    if (!audienceServiceApi || !audience.targetJobId || !audience.orgId)
      return undefined
    return audienceServiceApi.advertisingPlatformServiceGetAudience(
      audience.targetJobId,
      audience.orgId,
    )
  }

  const targetPagination = usePagination<V1TargetJobSearch>({
    pageSize: 5,
    sourceData: jobs || [],
  })

  if (!jobs?.length) return null

  return (
    <div className="SearchAudienceTable">
      <TextHeader type={4} className="SearchAudienceTable__header mt-4">
        Audiences ({jobs?.length || 0})
      </TextHeader>
      <div className="p-4">
        <Table
          columns={searchJobTableColumns}
          rows={targetPagination.data}
          onClick={(audience) => {
            getAudience(audience)
              .then((fullAudience) => {
                if (fullAudience) {
                  handleRowClick(fullAudience)
                  if (clicked && audience.targetJobId === clicked.targetJobId) {
                    setClicked(undefined)
                  } else setClicked(audience)
                }
              })
              .catch((err) => {
                if (err?.body?.message === 'audienceNotFound') {
                  showErrorMessage(
                    'Audience Not Found',
                    `The audience was not found. Audience ID: ${audience.targetJobId}`,
                  )
                }
              })
          }}
          clicked={clicked}
          // onSort={() => {}}
          // onFilter={() => {}}
        />
        <div className="flex justify-end pt-2">
          <TablePagination pagination={targetPagination} />
        </div>
      </div>
    </div>
  )
}
