import { Notificationservicev1Type } from 'next-gen-sdk'
export const simpleNotifyLabel = (type: Notificationservicev1Type | string) => {
  switch (type) {
    case 'NOTIFICATION_TYPE_ERROR':
      return 'Error'
    case 'NOTIFICATION_TYPE_INFO':
      return 'Info'
    case 'NOTIFICATION_TYPE_WARNING':
      return 'Warning'
    case 'NOTIFICATION_TYPE_UNSPECIFIED':
      return 'Other'
    default:
      return undefined
  }
}
