import React from 'react'

export const NoResultsSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="NoResultsSVG block h-[inherit] w-[inherit]"
      viewBox="0 0 649.378 727.778"
    >
      <path
        d="M362.89 172.947h-4V63.402A63.402 63.402 0 0 0 295.49 0H63.402A63.402 63.402 0 0 0 0 63.402v600.974a63.402 63.402 0 0 0 63.402 63.402h232.086a63.402 63.402 0 0 0 63.402-63.402V250.923h4Z"
        fill="#e6e6e6"
      />
      <path
        d="M298.047 16.495h-30.295a22.495 22.495 0 0 1-20.827 30.99h-132.96a22.495 22.495 0 0 1-20.827-30.99H64.842a47.348 47.348 0 0 0-47.347 47.348v600.092a47.348 47.348 0 0 0 47.347 47.348h233.205a47.348 47.348 0 0 0 47.348-47.348V63.843a47.348 47.348 0 0 0-47.348-47.348Z"
        fill="#fff"
      />
      <path
        d="M326.151 487.819H46.532a5.184 5.184 0 0 1-5.178-5.178V413.28a5.184 5.184 0 0 1 5.178-5.178h279.62a5.184 5.184 0 0 1 5.177 5.178v69.362a5.184 5.184 0 0 1-5.178 5.177ZM46.532 410.173a3.11 3.11 0 0 0-3.107 3.107v69.362a3.11 3.11 0 0 0 3.107 3.107h279.62a3.11 3.11 0 0 0 3.106-3.107V413.28a3.11 3.11 0 0 0-3.107-3.107Z"
        fill="#e6e6e6"
      />
      <circle cx="82.778" cy="447.96" r="21.748" fill="#e6e6e6" />
      <path
        d="M133.006 433.462a3.625 3.625 0 0 0 0 7.249h170.878a3.625 3.625 0 0 0 .14-7.249H303.884Z"
        fill="#e6e6e6"
      />
      <path
        d="M133.006 455.21a3.625 3.625 0 0 0 0 7.249h170.878a3.625 3.625 0 0 0 .12-7.25h-.12Z"
        fill="#e6e6e6"
      />
      <path
        d="M326.151 603.809H46.532a5.184 5.184 0 0 1-5.178-5.178V529.27a5.184 5.184 0 0 1 5.178-5.178h279.62a5.184 5.184 0 0 1 5.177 5.178v69.362a5.184 5.184 0 0 1-5.178 5.177ZM46.532 526.163a3.11 3.11 0 0 0-3.107 3.107v69.362a3.11 3.11 0 0 0 3.107 3.107h279.62a3.11 3.11 0 0 0 3.106-3.107V529.27a3.11 3.11 0 0 0-3.107-3.107Z"
        fill="#e6e6e6"
      />
      <circle cx="82.778" cy="563.95" r="21.748" fill="#e6e6e6" />
      <path
        d="M133.006 549.452a3.625 3.625 0 0 0 0 7.249h170.878a3.625 3.625 0 0 0 .12-7.25h-.12Z"
        fill="#e6e6e6"
      />
      <path
        d="M133.006 571.2a3.625 3.625 0 0 0 0 7.249h170.878a3.625 3.625 0 0 0 .12-7.25h-.12Z"
        fill="#e6e6e6"
      />
      <path
        d="M180.457 343.884a98.343 98.343 0 0 1-98.384-98.302v-.082c0-.206 0-.423.012-.63.3-53.878 44.432-97.755 98.372-97.755a98.384 98.384 0 0 1 .023 196.768h-.023Zm0-194.7a96.519 96.519 0 0 0-96.3 95.749c-.01.22-.01.4-.01.564a96.325 96.325 0 1 0 96.336-96.313h-.026Z"
        fill="#e6e6e6"
      />
      <circle cx="315.114" cy="422.842" r="40" fill="#fff" />
      <path
        d="M301.222 440.838c-.069 0-.138 0-.206-.002h-21.5a10.398 10.398 0 0 1-.009-20.793h21.518c.106-.002.22-.002.334 0a10.398 10.398 0 0 1-.137 20.795Z"
        fill="#c1ba9d"
      />
      <path fill="#3f3d56" d="M325.345 725.344h324.033v2.241H325.345z" />
      <path
        d="M345.31 535.731a6.561 6.561 0 0 1-.63-1.259l-5.163-13.827a6.508 6.508 0 0 1 3.815-8.363l115.242-43.032a6.506 6.506 0 0 1 8.363 3.815l5.163 13.828a6.5 6.5 0 0 1-3.816 8.363l-115.24 43.031a6.507 6.507 0 0 1-7.734-2.556Z"
        fill="#c1ba9d"
      />
      <path
        d="m458.207 465.677-46.863 18.687a6 6 0 0 0-4.093 7.2l5.439 21.775a6 6 0 0 0 8.376 3.975l46.992-22.119a6.01 6.01 0 0 0 3.525-7.717l-5.649-18.275a6.01 6.01 0 0 0-7.727-3.526Z"
        fill="#2f2e41"
      />
      <path
        d="M543.97 601.339a6.507 6.507 0 0 1-6.893-4.34l-40.114-116.29a6.5 6.5 0 0 1 4.025-8.264l13.953-4.813a6.504 6.504 0 0 1 8.264 4.025l40.114 116.29a6.508 6.508 0 0 1-4.025 8.264l-13.953 4.813a6.561 6.561 0 0 1-1.372.315Z"
        fill="#c1ba9d"
      />
      <path
        d="m526.195 469.667 15.396 48.045a6 6 0 0 1-2.951 7.739l-20.26 9.657a6 6 0 0 1-8.396-3.934l-12.83-50.328a6.01 6.01 0 0 1 3.711-7.63l17.694-7.268a6.01 6.01 0 0 1 7.636 3.72ZM479.58 726.003h-14.76a6.508 6.508 0 0 1-6.5-6.5V596.488a6.508 6.508 0 0 1 6.5-6.5h14.76a6.508 6.508 0 0 1 6.5 6.5v123.015a6.508 6.508 0 0 1-6.5 6.5ZM508.772 726.003h-14.76a6.508 6.508 0 0 1-6.5-6.5V596.488a6.508 6.508 0 0 1 6.5-6.5h14.76a6.508 6.508 0 0 1 6.5 6.5v123.015a6.508 6.508 0 0 1-6.5 6.5Z"
        fill="#2f2e41"
      />
      <path
        d="M520.936 610.95h-67.063a7.008 7.008 0 0 1-7-7V495.007a40.531 40.531 0 0 1 81.063 0v108.945a7.008 7.008 0 0 1-7 7Z"
        fill="#ccc"
      />
      <path
        d="M531.03 637.6h-34V445.445l.632.173a45.382 45.382 0 0 1 33.368 43.687ZM475.015 637.6h-34V489.305a45.382 45.382 0 0 1 33.369-43.687l.631-.173Z"
        fill="#2f2e41"
      />
      <circle cx="486.897" cy="378.421" r="53.519" fill="#c1ba9d" />
      <path
        d="M547.404 366.099s3-83-36-56c0 0-22.5-75.5-77.5 37.5l-16 16s72-10 139 21Z"
        fill="#2f2e41"
      />
      <path
        d="M487.713 404.925c-3.306-.091-7.42-.206-10.59-2.522a8.133 8.133 0 0 1-3.2-6.072 5.47 5.47 0 0 1 1.86-4.494c1.656-1.398 4.074-1.727 6.679-.961l-2.7-19.726 1.982-.271 3.173 23.19-1.654-.76c-1.919-.88-4.552-1.327-6.188.056a3.515 3.515 0 0 0-1.153 2.895 6.147 6.147 0 0 0 2.381 4.528c2.467 1.802 5.746 2.034 9.466 2.138ZM459.192 372.852h10.772v2h-10.772zM493.192 372.852h10.772v2h-10.772z"
        fill="#2f2e41"
      />
      <path
        d="M367.571 540.833a6 6 0 0 1-7.968-2.918l-34.51-74.384a6 6 0 0 1 10.885-5.05l34.51 74.384a6 6 0 0 1-2.917 7.968Z"
        fill="#3f3d56"
      />
      <path
        d="M332.114 460.794a4 4 0 0 1-4-4v-50.952h-50a4 4 0 0 1 0-8h54a4 4 0 0 1 4 4v54.952a4 4 0 0 1-4 4Z"
        fill="#c1ba9d"
      />
      <path
        d="M333.06 466.449a48 48 0 1 1 23.34-63.744 48.054 48.054 0 0 1-23.34 63.744Zm-35.353-76.198a36 36 0 1 0 47.808 17.505 36.04 36.04 0 0 0-47.808-17.505Z"
        fill="#3f3d56"
      />
    </svg>
  )
}
