import React from 'react'
import { TextHeader } from '@eltoro-ui/components'
import { OrderlinePaceIndicator, PaceStatus } from '../OrderlinePaceIndicator'
import './OrderlinePacePopover.scss'

const paceTexts: { pace: PaceStatus; text: string }[] = [
  {
    pace: 'under',
    text: 'Pacing is behind schedule',
  },
  {
    pace: 'on',
    text: 'Pacing on schedule',
  },
  {
    pace: 'over',
    text: 'Pacing ahead of schedule',
  },
  {
    pace: 'not started',
    text: 'The order line has not started serving impressions',
  },
  {
    pace: 'paused',
    text: 'The order line is paused and not serving impressions',
  },
]

export const OrderlinePacePopover = () => {
  return (
    <div className="OrderlinePacePopover">
      <TextHeader type={4} className="mb-2">
        Order Line Pace
      </TextHeader>
      {paceTexts.map(({ pace, text }) => (
        <div key={pace} className="OrderlinePacePopover__row">
          <div className="OrderlinePacePopover__status-container">
            <OrderlinePaceIndicator pace={pace} />
          </div>
          <p>{text}</p>
        </div>
      ))}
    </div>
  )
}
