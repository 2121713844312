import { Targetjobservicev1Audience } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { OrderLinesWithError } from 'types'

export const getJobOrderLines = async (
  job: Targetjobservicev1Audience,
  org: string,
) => {
  if (!job.id) return []
  const token = localStorage.getItem('eltoro_token')
  const apis = await ApiFactory(token)
  const { campaignServiceAPI } = apis
  if (!campaignServiceAPI) return []
  let orderLineIds: string[] = job.orderLineIds || []
  // If job is VR, count the unique sub job order lines
  if (job.type === 'AUDIENCE_TYPE_VR' && job.audiences) {
    orderLineIds = job.audiences?.reduce(
      (acc: string[], currentJob: Targetjobservicev1Audience) => {
        if (!currentJob.orderLineIds) return acc
        return [...new Set([...acc, ...currentJob.orderLineIds])]
      },
      [],
    )
  }
  // get orderLines, filter out the placeholder OLs
  const orderLines = await Promise.all(
    orderLineIds.map((id) => {
      return campaignServiceAPI
        .advertisingPlatformServiceGetOrderLine(id, org)
        .then((ol) => ({
          id: ol.id,
          ol,
          err: !ol.id ? 'Missing order line id' : undefined,
        }))
        .catch(() => ({ id, err: 'Order line not found' }))
    }),
  )
  return orderLines as OrderLinesWithError[]
}
