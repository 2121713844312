/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1OrderLineEvent } from '../models/V1OrderLineEvent';
import { HttpFile } from '../http/http';

/**
* Contains a list of events from an orderline
*/
export class V1ListOrderLineEventsResponse {
    /**
    * List of order line events
    */
    'events'?: Array<V1OrderLineEvent>;
    'nextPageToken'?: string;
    'totalSize'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "events",
            "baseName": "events",
            "type": "Array<V1OrderLineEvent>",
            "format": ""
        },
        {
            "name": "nextPageToken",
            "baseName": "next_page_token",
            "type": "string",
            "format": ""
        },
        {
            "name": "totalSize",
            "baseName": "total_size",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1ListOrderLineEventsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

