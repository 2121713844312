import { Fa, TextHeader } from '@eltoro-ui/components'
import { userFriendlyJobStatus } from 'Helpers'
import { V1AudienceStatus } from 'next-gen-sdk'

const statusTexts: {
  status: V1AudienceStatus
  text: string
  icon: string
  color: string
}[] = [
  {
    status: 'AUDIENCE_STATUS_CREATED',
    text: 'The audience has been created',
    icon: 'cloud-check',
    color: 'text-warning-400',
  },
  {
    status: 'AUDIENCE_STATUS_QUEUED',
    text: 'The audience has been added to the quote queue',
    icon: 'hourglass-clock',
    color: '!text-warning-400',
  },
  {
    status: 'AUDIENCE_STATUS_RUNNING',
    text: 'The audience is currently quoting',
    icon: 'hourglass-half',
    color: '!text-warning-400',
  },
  {
    status: 'AUDIENCE_STATUS_COMPLETED',
    text: 'The audience has finished quoting',
    icon: 'hourglass-end',
    color: '!text-secondary-500',
  },
  {
    status: 'AUDIENCE_STATUS_READY',
    text: 'The audience has finished quoting and is ready to be used',
    icon: 'circle-check',
    color: '!text-success-400',
  },
  {
    status: 'AUDIENCE_STATUS_CANCELLED',
    text: 'The audience quote process has been cancelled',
    icon: 'ban',
    color: '!text-danger-500',
  },
  {
    status: 'AUDIENCE_STATUS_ERRORED',
    text: 'The audience has errored',
    icon: 'triangle-exclamation',
    color: '!text-danger-500',
  },
  {
    status: 'AUDIENCE_STATUS_EXPIRED',
    text: 'The audience is expired',
    icon: 'cloud-xmark',
    color: '!text-grey-500',
  },
  {
    status: 'AUDIENCE_STATUS_PURGED',
    text: 'The audience has been deleted and cannot be recovered',
    icon: 'trash-can-xmark',
    color: '!text-danger-500',
  },
  {
    status: 'AUDIENCE_STATUS_ARCHIVED',
    text: 'The audience has been archived',
    icon: 'box-archive',
    color: '!text-warning-400',
  },
  {
    status: 'AUDIENCE_STATUS_UNARCHIVING',
    text: 'The audience is in the process of unarchiving',
    icon: 'box-circle-check',
    color: '!text-warning-400',
  },
]

export const AudienceStatusIndicator = ({
  status,
  bold,
}: {
  status: V1AudienceStatus
  bold?: boolean
}) => {
  const match = statusTexts.find((s) => s.status === status)
  if (!match)
    return (
      <p className={bold ? 'font-semibold' : ''}>
        {userFriendlyJobStatus(status)}
      </p>
    )
  const { icon, color } = match
  return (
    <div className="flex items-center gap-1">
      <Fa icon={icon} size={1} className={color} />
      <p className={bold ? 'font-semibold' : ''}>
        {userFriendlyJobStatus(status)}
      </p>
    </div>
  )
}

export const AudienceStatusPopover = () => {
  return (
    <div
      className="AudienceStatusPopover"
      onClick={(e) => e.stopPropagation()} // prevent the click on popover from opening/closing the table column's dropdown menu
    >
      <TextHeader type={4} className="mb-2">
        Audience Statuses
      </TextHeader>
      {statusTexts.map(({ status, text }) => (
        <div
          className="AudienceStatusPopover__row child-stripe text-basetext grid grid-cols-[1.25fr_3fr] items-center py-2 px-1"
          key={status}
        >
          <AudienceStatusIndicator status={status} bold />
          <p className="text-xs">{text}</p>
        </div>
      ))}
    </div>
  )
}
