/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3DirectMailConfiguration } from '../models/V3DirectMailConfiguration';
import { V3ViantAdelphicConfiguration } from '../models/V3ViantAdelphicConfiguration';
import { V3XandrConsoleConfiguration } from '../models/V3XandrConsoleConfiguration';
import { V3XandrCurateConfiguration } from '../models/V3XandrCurateConfiguration';
import { V3XandrInvestConfiguration } from '../models/V3XandrInvestConfiguration';
import { HttpFile } from '../http/http';

/**
* The Order Line deployment configurations for all supported destinations
*/
export class V3DeploymentDestinationConfigurations {
    'xandrInvest'?: V3XandrInvestConfiguration;
    'xandrConsole'?: V3XandrConsoleConfiguration;
    'directMail'?: V3DirectMailConfiguration;
    'xandrCurate'?: V3XandrCurateConfiguration;
    'viantAdelphic'?: V3ViantAdelphicConfiguration;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "xandrInvest",
            "baseName": "xandr_invest",
            "type": "V3XandrInvestConfiguration",
            "format": ""
        },
        {
            "name": "xandrConsole",
            "baseName": "xandr_console",
            "type": "V3XandrConsoleConfiguration",
            "format": ""
        },
        {
            "name": "directMail",
            "baseName": "direct_mail",
            "type": "V3DirectMailConfiguration",
            "format": ""
        },
        {
            "name": "xandrCurate",
            "baseName": "xandr_curate",
            "type": "V3XandrCurateConfiguration",
            "format": ""
        },
        {
            "name": "viantAdelphic",
            "baseName": "viant_adelphic",
            "type": "V3ViantAdelphicConfiguration",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3DeploymentDestinationConfigurations.attributeTypeMap;
    }

    public constructor() {
    }
}

