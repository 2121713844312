/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* A specific type of Line Item. See [Line Item Service](https://docs.xandr.com/bundle/xandr-api/page/line-item-service---ali.html) in Xandr's documentation.
*/
export class V3LineItem {
    /**
    * The name of the line item.
    */
    'name'?: string;
    /**
    * The reference code of the line item.
    */
    'code'?: string;
    /**
    * The start date of the line item.
    */
    'startDate'?: Date;
    /**
    * The end date of the line item.
    */
    'endDate'?: Date;
    /**
    * The URL the user will be redirected to if the creative is clicked.
    */
    'clickUrl'?: string;
    /**
    * The ID of the line item.
    */
    'id'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "clickUrl",
            "baseName": "click_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3LineItem.attributeTypeMap;
    }

    public constructor() {
    }
}

