import React from 'react'
import { TextHeader } from '@eltoro-ui/components'
import { V1State } from 'next-gen-sdk'
import { OrderlineStateIndicator } from '../OrderlineStateIndicator'
import './OrderlineStatePopover.scss'

const statusTexts: {
  state: V1State
  text: string
}[] = [
  {
    state: 'ORDERLINE_STATE_DRAFT',
    text: 'The order line is in the process of being set up',
  },
  {
    state: 'ORDERLINE_STATE_APPROVED',
    text: 'Ad Operations has approved order line ',
  },
  {
    state: 'ORDERLINE_STATE_REJECTED',
    text: 'Ad Operations has rejected the order line. Changes are needed',
  },
  {
    state: 'ORDERLINE_STATE_ACTIVE',
    text: 'The order line is actively serving impressions',
  },
  {
    state: 'ORDERLINE_STATE_COMPLETED',
    text: 'The order line has ended and finished serving impressions',
  },
  {
    state: 'ORDERLINE_STATE_ARCHIVED',
    text: 'The order line is archived',
  },
  {
    state: 'ORDERLINE_STATE_PAUSED',
    text: 'The order line is paused and not serving impressions',
  },
]

export const OrderlineStatePopover = () => {
  return (
    <div
      className="OrderlineStatePopover"
      onClick={(e) => e.stopPropagation()} // prevent the click on popover from opening/closing the table column's dropdown menu
    >
      <TextHeader type={4} className="mb-2">
        Order Line Statuses
      </TextHeader>
      {statusTexts.map(({ state, text }) => (
        <div className="OrderlineStatePopover__row" key={state}>
          <div className="OrderlineStatePopover__status-container">
            <OrderlineStateIndicator state={state} />
          </div>
          <p>{text}</p>
        </div>
      ))}
    </div>
  )
}
