import { Link } from 'react-router-dom'
export const MainFooter = () => {
  return (
    <footer className="MainFooter border-t-thin border-t-tint-gray-100 pointer-events-none pointer-events-auto relative z-10 flex grid h-full w-full grid-cols-6 items-center bg-transparent">
      <div className="MainFooter__disclosures bg-base flex w-24 flex-col items-center py-2 text-xs font-light gap-1">
        <span className="Copyright__year">
          &copy; {new Date().getFullYear()} El Toro
        </span>
        <span className="FooterLinks">
          <Link
            to="https://eltoro.com/privacy-policy-2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </span>
      </div>
    </footer>
  )
}
