import { MutableSnapshot } from 'recoil'
import {
  backgroundPhotoAtom,
  blockBorderRadiusAtom,
  blockFontFamilyAtom,
  blockFontSizeAtom,
  blockFontStyleAtom,
  blocksAtom,
  blockShadowBlurAtom,
  blockShadowColorAtom,
  blockShadowOffsetXAtom,
  blockShadowOffsetYAtom,
  blockShadowOpacityAtom,
  blockShadowVisibleAtom,
  blockShapeColorAtom,
  blockShapeTypeAtom,
  blockStrokeColorAtom,
  blockStrokeEnabledAtom,
  blockStrokeWidthAtom,
  blockTextAlignAtom,
  blockTextColorAtom,
  blockTextDecorationAtom,
  blockTextValueAtom,
  creativeTypeAtom,
  directMailSizeAtom,
  filterAngleAtom,
  filterOpacityAtom,
  filterStartColorAtom,
  filterStartSpaceAtom,
  filterStopColorAtom,
  filterStopSpaceAtom,
  filterVisibleAtom,
  headShotImageAtom,
  positionAndDimensionsBlockAtom,
  scaleBlockAtom,
  visibleBlockAtom,
} from 'State'
import { SaveStateType } from 'Typings'

export const loadState = (snapshot: MutableSnapshot, state?: string) => {
  if (!state) return
  const parsedState: SaveStateType = JSON.parse(state)
  const {
    blockDetails,
    creativeType,
    backgroundPhoto,
    directMailSize,
    blocks,
    filterAngle,
    filterOpacity,
    filterStartColor,
    filterStartSpace,
    filterStopColor,
    filterStopSpace,
    filterVisible,
  } = parsedState
  snapshot.set(creativeTypeAtom, creativeType)
  snapshot.set(backgroundPhotoAtom, backgroundPhoto)
  snapshot.set(directMailSizeAtom, directMailSize)
  snapshot.set(blocksAtom, blocks)
  snapshot.set(filterVisibleAtom, filterVisible)
  snapshot.set(filterAngleAtom, filterAngle)
  snapshot.set(filterOpacityAtom, filterOpacity)
  snapshot.set(filterStartColorAtom, filterStartColor)
  snapshot.set(filterStopColorAtom, filterStopColor)
  snapshot.set(filterStartSpaceAtom, filterStartSpace)
  snapshot.set(filterStopSpaceAtom, filterStopSpace)
  Object.keys(blockDetails).forEach((blockId) => {
    const {
      visible,
      scale,
      positionDimension,
      textState,
      shapeState,
      headShotImage,
    } = blockDetails[blockId]
    const [currentBlock] = blocks.filter((block) => block.id === blockId)
    const { kind, artboardName } = currentBlock
    snapshot.set(visibleBlockAtom(blockId), visible)
    snapshot.set(scaleBlockAtom(blockId), scale)
    snapshot.set(
      positionAndDimensionsBlockAtom({ kind, artboardName }),
      positionDimension,
    )
    snapshot.set(headShotImageAtom(blockId), headShotImage)
    Object.keys(textState).forEach((textKey) => {
      const config = { id: blockId, kind, key: textKey }
      const {
        value,
        color,
        fontSize,
        fontFamily,
        fontStyle,
        textAlign,
        textDecoration,
      } = textState[textKey]
      snapshot.set(blockTextValueAtom(config), value)
      snapshot.set(blockTextColorAtom(config), color)
      snapshot.set(blockFontSizeAtom(config), fontSize)
      snapshot.set(blockFontFamilyAtom(config), fontFamily)
      snapshot.set(blockFontStyleAtom(config), fontStyle)
      snapshot.set(blockTextAlignAtom(config), textAlign)
      snapshot.set(blockTextDecorationAtom(config), textDecoration)
    })
    if (shapeState) {
      Object.keys(shapeState).forEach((shapeKey) => {
        const config = { id: blockId, kind, key: shapeKey }
        const { color, borderRadius, shape, shadow, stroke } = shapeState[
          shapeKey
        ]
        if (color) snapshot.set(blockShapeColorAtom(config), color)
        if (borderRadius)
          snapshot.set(blockBorderRadiusAtom(config), borderRadius)
        if (shape) snapshot.set(blockShapeTypeAtom(config), shape)
        if (shadow) {
          snapshot.set(blockShadowVisibleAtom(config), shadow.enabled)
          snapshot.set(blockShadowColorAtom(config), shadow.color)
          snapshot.set(blockShadowBlurAtom(config), shadow.blur)
          snapshot.set(blockShadowOpacityAtom(config), shadow.opacity)
          snapshot.set(blockShadowOffsetXAtom(config), shadow.offsetX)
          snapshot.set(blockShadowOffsetYAtom(config), shadow.offsetY)
        }
        if (stroke) {
          snapshot.set(blockStrokeEnabledAtom(config), stroke.enabled)
          snapshot.set(blockStrokeColorAtom(config), stroke.color)
          snapshot.set(blockStrokeWidthAtom(config), stroke.width)
        }
      })
    }
  })
}
