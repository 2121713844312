import { ApiFactory } from 'apiFactory'

export const saveOptionalCampaignIds = async (
  campaignId: string,
  orgId: string,
  jobId?: string,
  poId?: string,
) => {
  if (jobId === undefined && poId === undefined) return
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { campaignServiceAPI } = apis
  if (!campaignServiceAPI) return
  const campaign = await campaignServiceAPI.advertisingPlatformServiceUpdateCampaign(
    campaignId,
    orgId,
    {
      jobId,
      poId,
    },
  )
  return campaign
}
