/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Timeframe holds the start time and end time for a vr job
*/
export class Targetjobservicev1Timeframe {
    /**
    * The start time of the vr job
    */
    'start'?: string;
    /**
    * The stop time of the vr job
    */
    'stop'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "start",
            "baseName": "start",
            "type": "string",
            "format": "2018-05-29T12:45:00.332Z"
        },
        {
            "name": "stop",
            "baseName": "stop",
            "type": "string",
            "format": "2018-05-29T12:45:00.332Z"
        }    ];

    static getAttributeTypeMap() {
        return Targetjobservicev1Timeframe.attributeTypeMap;
    }

    public constructor() {
    }
}

