/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1MainDetails } from '../models/V1MainDetails';
import { V1Metrics } from '../models/V1Metrics';
import { V1Results } from '../models/V1Results';
import { HttpFile } from '../http/http';

export class V1StatsResponse {
    'results'?: Array<V1Results>;
    'totals'?: V1Metrics;
    'details'?: V1MainDetails;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "results",
            "baseName": "results",
            "type": "Array<V1Results>",
            "format": ""
        },
        {
            "name": "totals",
            "baseName": "totals",
            "type": "V1Metrics",
            "format": ""
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "V1MainDetails",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1StatsResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

