import { useState, useEffect } from 'react'
import { AutoScroller, Fa, Tabs, Text } from '@eltoro-ui/components'
import { CreativesLibraryPage } from 'Pages'
import { Creativeservicev1Creative, Creativeservicev1Type } from 'next-gen-sdk'
import {
  CreativeUploaderPropsType,
  CreativeUploaderStatusPropsType,
} from 'types'
import {
  IconButton,
  CreativeUploader,
  CreativeUploaderStatus,
  CreativeGenModal,
} from 'Components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { AdSetDisplay } from '../components'
import './BannerAdForm.scss'
import { useLocation } from 'react-router-dom'

export const BannerAdForm = ({
  uploaderProps,
  uploaderStatusProps,
  isPoliticalAndCampaignCreation,
}: {
  uploaderProps: CreativeUploaderPropsType
  uploaderStatusProps: CreativeUploaderStatusPropsType
  isPoliticalAndCampaignCreation?: boolean
}) => {
  const [selected, setSelected] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedBannerCreatives, setSelectedBannerCreatives] = useState<
    Creativeservicev1Creative[]
  >([])
  const { currentOrg } = useAppContext()
  const { isPolitical } = useCampaignContext()
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  const handleSelect = (creative: Creativeservicev1Creative) => {
    setSelectedBannerCreatives((prev) => [...prev, creative])
    setSelected('GetFromLibrary')
  }

  const handleRemove = (creativeId: string) => {
    setSelectedBannerCreatives((prev) =>
      prev.filter((f) => f.id !== creativeId),
    )
  }

  const handleUploadSideEffect = (buttonId: string) => {
    setSelected(buttonId)
  }

  useEffect(() => {
    if (selected !== 'Upload') uploaderStatusProps.setFiles([])
    if (selected === 'UseBannerGenerator') setShowModal(true)
  }, [])

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonId: string,
  ) => {
    setSelected(buttonId)
    if (buttonId === 'UseBannerGenerator') setShowModal(true)
  }

  return (
    <AutoScroller
      className="BannerAdForm__autoscroller"
      shouldScroll={selectedBannerCreatives.length < 1}
      scrollToTop={selectedBannerCreatives.length === 0}
    >
      <div className="BannerAdForm relative flex-1 gap-4">
        {currentOrg && currentOrg.id && (
          <>
            <Tabs
              classNamePanel="BannerAdForm__tabs flex-1 w-full"
              tabs={[
                {
                  id: 'uploader',
                  label: (
                    <div className="BannerAdForm__header-text-icon flex w-auto items-center justify-center gap-2">
                      <Fa icon="image" size={2} type="duotone" />
                      <Text
                        className="BannerAdForm__header-text font-normal normal-case"
                        tag="h5"
                      >
                        Upload a new file
                      </Text>
                    </div>
                  ),
                  onClick: () => handleButtonClick,
                  defaultTab: selected === 'uploader',
                  component: (
                    <CreativeUploader
                      handleSelect={handleSelect}
                      onClickSideEffect={handleUploadSideEffect}
                      uploadType="CREATIVE_TYPE_BANNER"
                      {...uploaderProps}
                      isInPoliticalCampaignCreation={
                        isPoliticalAndCampaignCreation
                      }
                    />
                  ),
                },
                {
                  id: 'GetFromLibrary',
                  label: (
                    <>
                      <span
                        aria-label="Select from Creative Library"
                        data-tooltip="left"
                      >
                        <Fa
                          className="text-primary"
                          icon="folders"
                          size={3}
                          type="duotone"
                        />
                      </span>
                      <span className="CreativeTypeSelector__tabs--text">
                        Select from Creative Library
                      </span>
                    </>
                  ),
                  onClick: () => handleButtonClick,
                  defaultTab: selected === 'GetFromLibrary',
                  component: (
                    <CreativesLibraryPage
                      selectedCreatives={selectedBannerCreatives}
                      addToCreativeSet={handleSelect}
                      removeFromCreativeSet={handleRemove}
                      modalPreview
                      windowed
                      filterByType={[
                        'CREATIVE_TYPE_BANNER',
                        ...(isPolitical && isInCampaignCreation
                          ? []
                          : ([
                              'CREATIVE_TYPE_HTML5',
                            ] as Creativeservicev1Type[])),
                      ]}
                    />
                  ),
                },
              ]}
            />
          </>
        )}
        <IconButton
          classNameButton="BannerGenerator__button"
          id="UseBannerGenerator"
          title="Launch Banner Generator"
          subTitle="Design & create all 5 recommended sizes"
          icon={
            <Fa
              className="text-primary"
              icon="image-polaroid"
              size={3}
              type="duotone"
            />
          }
          isSelected={selected === 'UseBannerGenerator'}
          handleClick={handleButtonClick}
        />
      </div>

      {uploaderProps.uploadFiles && !selected && (
        <div className="BannerForm__Selection">
          <CreativeUploaderStatus {...uploaderStatusProps} />
        </div>
      )}
      {showModal && currentOrg && currentOrg.id && (
        <CreativeGenModal
          defaultType="banner"
          currentOrg={currentOrg.id}
          onCloseOrOffClick={(b) => {
            setShowModal(b)
          }}
          onCreate={handleSelect}
        />
      )}
      <AdSetDisplay
        shouldOpen={selectedBannerCreatives.length > 0}
        selectedCreatives={selectedBannerCreatives}
        setSelectedCreatives={setSelectedBannerCreatives}
        showBannerChecklist
        type="banner"
      />
    </AutoScroller>
  )
}
