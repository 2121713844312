import { useEffect, useState, useRef } from 'react'
import {
  Creativeservicev1Creative,
  Campaignservicev1Creative,
} from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'

export type FullCreativesType = {
  creative: Creativeservicev1Creative
  err?: string
}

export const useGetFullOLCreatives = (
  creatives?: Campaignservicev1Creative[],
  orgId?: string,
  token?: string,
): [creatives: FullCreativesType[], loading: boolean] => {
  const [fullCreatives, setFullCreatives] = useState<FullCreativesType[]>([])
  const [loading, setLoading] = useState(true)
  const cache = useRef<FullCreativesType[]>([])

  useEffect(() => {
    if (creatives && token) {
      const { creativeServiceAPI } = ApiFactory(token)
      Promise.all(
        creatives.reduce(
          (acc: Promise<FullCreativesType>[], creative, index) => {
            if (creative.id && creativeServiceAPI && orgId) {
              // if this is in the array and in the same place as cache, just return cache creative
              if (creative.id === cache.current[index]?.creative?.id) {
                return [...acc, Promise.resolve(cache.current[index])]
              }

              return [
                ...acc,
                creativeServiceAPI
                  ?.advertisingPlatformServiceGetCreative(creative.id, orgId)
                  .then((res) => {
                    return {
                      creative: res,
                    }
                  })
                  .catch((err) =>
                    Promise.resolve({
                      err:
                        err?.body?.message === 'creativeNotFound'
                          ? `Creative not found: ${creative.id}`
                          : `Error getting creative: ${creative.id}. ${
                              err?.body?.message || ''
                            }`,
                      creative,
                    }),
                  ),
              ]
            }
            if (!creative.id) {
              return [
                ...acc,
                Promise.resolve({
                  err: 'This creative does not have an id.',
                  creative,
                }),
              ]
            }
            return acc
          },
          [],
        ),
      )
        .then((results) => {
          cache.current = results
          setFullCreatives(results)
        })
        .finally(() => setLoading(false))
    }
  }, [creatives, orgId, token])

  return [fullCreatives, loading]
}
