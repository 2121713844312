/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1NoteType } from '../models/Targetjobservicev1NoteType';
import { HttpFile } from '../http/http';

export class TheNoteToUpdate {
    /**
    * The time the Note was created.
    */
    'createTime'?: Date;
    /**
    * The last time the Note was updated.
    */
    'updateTime'?: Date;
    /**
    * The Audience's ID that this note is associated with.
    */
    'audienceId'?: string;
    'content'?: string;
    'userId'?: string;
    'userName'?: string;
    'type'?: Targetjobservicev1NoteType;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userName",
            "baseName": "user_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Targetjobservicev1NoteType",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheNoteToUpdate.attributeTypeMap;
    }

    public constructor() {
    }
}

