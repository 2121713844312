import { useState } from 'react'
import classNames from 'classnames'
import { Checkbox } from '../Checkbox'
import { Fa } from '../FontAwesome'
import { TableColumnType, TableHeaderType } from '../Table'
import { TableFilterDropdown } from '../TableFilterDropdown'
import './TableHeader.scss'
import '../../Assets/css/all.css'

export const TableHeader = <T extends { [key: string]: any }>({
  columns,
  stickyHeader,
  ExpandableRow,
  handleSelectAll,
  selectedAll,
  onSort,
  defaultSorted,
  onFilter,
}: TableHeaderType<T>) => {
  const [sorted, setSorted] = useState<[number, 'asc' | 'desc'] | undefined>(
    defaultSorted,
  )
  const [filtered, setFiltered] = useState<number[]>([])

  const handleSort = (columnIndex: number, value?: 'desc' | 'asc') => {
    const key = columns[columnIndex].filterPath || columns[columnIndex].path
    if (value) {
      setSorted([columnIndex, value])
      if (onSort) onSort(columnIndex, value, key)
      return
    }
    const newSort = () => {
      if (!sorted) return 'asc'
      return sorted[1] === 'asc' ? 'desc' : 'asc'
    }
    if (sorted && sorted[0] === columnIndex) {
      setSorted([columnIndex, newSort()])
    } else setSorted([columnIndex, 'asc'])
    if (onSort) onSort(columnIndex, newSort(), key)
  }

  const clearSort = (columnIndex: number) => {
    setSorted(undefined)
    if (onSort) onSort(columnIndex)
  }

  const getHeaderText = (col: TableColumnType<T>) => {
    if (col.label) return col.label
    if (typeof col.path === 'string') return col.path
    if (Array.isArray(col.path)) return col.path[col.path.length - 1]
    return ''
  }

  const getSortIcon = (index: number) => {
    const sharedProps = {
      icon: 'sort',
      size: 2,
    }

    if (sorted && sorted[0] === index && sorted[1] === 'asc') {
      return <Fa {...sharedProps} type="duotone" />
    }
    if (sorted && sorted[0] === index && sorted[1] === 'desc') {
      return <Fa {...sharedProps} type="duotone" className="rotate-180" />
    }
    return <Fa {...sharedProps} className="text-tint-gray-300" />
  }
  const getSortText = (index: number) => {
    if (sorted && sorted[0] === index && sorted[1] === 'asc') {
      return 'Sort items in ascending order'
    }
    if (sorted && sorted[0] === index && sorted[1] === 'desc') {
      return 'Sort items in descending order'
    }
    return 'Sort list of items'
  }

  return (
    <thead className="TableHeader">
      <tr className="TableHeader__row">
        {handleSelectAll && (
          <th
            className={classNames(
              'TableHeader__cell TableHeader__cell--checkbox',
              {
                'TableHeader__cell--sticky': stickyHeader,
              },
            )}
          >
            <Checkbox
              checked={selectedAll || false}
              onChange={() => handleSelectAll()}
              label=""
            />
          </th>
        )}
        {ExpandableRow && <td className="TableHeader__expand" />}
        {columns.map((col, columnIndex) => {
          const { removeSort, removeFilter } = col

          const headerWrapperClassNames = classNames(
            'TableHeader__label-container leading-none flex items-center relative',
            {
              [`TableHeader__label-container--${col.alignment}`]:
                col.alignment && !onSort,
            },
            {
              'text-base-info-600 font-bold active':
                (sorted && sorted[0] === columnIndex) ||
                filtered.includes(columnIndex),
            },
          )

          const showSort = onSort && !removeSort
          const showFilter = onFilter && !removeFilter

          return (
            <th
              key={columnIndex}
              className={classNames('TableHeader__cell', {
                'TableHeader__cell--sticky': stickyHeader,
                'TableHeader__cell--sort': onSort,
              })}
              style={col.width ? { width: col.width } : {}}
            >
              {showSort || showFilter ? (
                <TableFilterDropdown
                  column={col}
                  columnIndex={columnIndex}
                  onFilter={onFilter}
                  handleSort={handleSort}
                  sorted={sorted}
                  setFiltered={setFiltered}
                  clearSort={clearSort}
                >
                  <div className={headerWrapperClassNames}>
                    <span className="TableHeader__text flex-1">
                      {getHeaderText(col)}
                    </span>
                    {/* Sort icon */}
                    {showSort && (
                      <div className="TableSort__wrap flex items-center">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSort(columnIndex)
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation()
                            if (e.key === 'Space') {
                              handleSort(columnIndex)
                            }
                          }}
                          className="ButtonSort focus:ring-2"
                          aria-label={getSortText(columnIndex)}
                          data-tooltip="right"
                        >
                          {getSortIcon(columnIndex)}
                        </button>
                        {/* Remove sort button */}
                        {sorted && sorted[0] === columnIndex && (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation()
                              clearSort(columnIndex)
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation()
                              if (e.key === 'Space') {
                                clearSort(columnIndex)
                              }
                            }}
                            className="ButtonSort ButtonSort__remove focus:ring-2 -ml-1 animate-slideleft"
                            aria-label="Remove sort"
                            data-tooltip="right"
                          >
                            <Fa
                              className="text-base-info"
                              size={2}
                              icon="xmark"
                            />
                          </button>
                        )}
                      </div>
                    )}
                    {/* Filter icon */}
                    {showFilter && (
                      <button
                        className="ButtonFilter focus:ring-2"
                        type="button"
                        aria-label="Filter items"
                        data-tooltip="right"
                      >
                        <Fa
                          icon="filter"
                          size={1}
                          className={classNames({
                            'before:!text-base-info-600': filtered.includes(
                              columnIndex,
                            ),
                          })}
                        />
                      </button>
                    )}
                  </div>
                </TableFilterDropdown>
              ) : (
                <div className={headerWrapperClassNames}>
                  <span className="TableHeader__text flex-1">
                    {getHeaderText(col)}
                  </span>
                </div>
              )}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
