/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1CreativeFileMetadata } from '../models/V1CreativeFileMetadata';
import { HttpFile } from '../http/http';

/**
* Contains data for uploading a creative
*/
export class V1UploadCreativeFileRequest {
    'metadata'?: V1CreativeFileMetadata;
    'chunk'?: string;
    /**
    * Id of the org to which this creative belongs
    */
    'orgId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "V1CreativeFileMetadata",
            "format": ""
        },
        {
            "name": "chunk",
            "baseName": "chunk",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": "uuid"
        }    ];

    static getAttributeTypeMap() {
        return V1UploadCreativeFileRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

