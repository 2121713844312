import { blocksBase } from 'Data'
import { Snapshot, useRecoilCallback } from 'recoil'
import {
  backgroundPhotoAtom,
  blockBorderRadiusAtom,
  blockFontFamilyAtom,
  blockFontSizeAtom,
  blockFontStyleAtom,
  blocksAtom,
  blockShadowBlurAtom,
  blockShadowColorAtom,
  blockShadowOffsetXAtom,
  blockShadowOffsetYAtom,
  blockShadowOpacityAtom,
  blockShadowVisibleAtom,
  blockShapeColorAtom,
  blockShapeTypeAtom,
  blockStrokeColorAtom,
  blockStrokeEnabledAtom,
  blockStrokeWidthAtom,
  blockTextAlignAtom,
  blockTextColorAtom,
  blockTextDecorationAtom,
  blockTextValueAtom,
  creativeTypeAtom,
  directMailSizeAtom,
  filterAngleAtom,
  filterOpacityAtom,
  filterStartColorAtom,
  filterStartSpaceAtom,
  filterStopColorAtom,
  filterStopSpaceAtom,
  filterVisibleAtom,
  headShotImageAtom,
  positionAndDimensionsBlockAtom,
  scaleBlockAtom,
  visibleBlockAtom,
} from 'State'
import {
  AvailableBlocksType,
  BlockConfigType,
  BlockShapeType,
  BlockTextType,
  SaveStateType,
} from 'Typings'

const shadowState = async (config: BlockConfigType, snapshot: Snapshot) => {
  return {
    enabled: await snapshot.getPromise(blockShadowVisibleAtom(config)),
    color: await snapshot.getPromise(blockShadowColorAtom(config)),
    blur: await snapshot.getPromise(blockShadowBlurAtom(config)),
    opacity: await snapshot.getPromise(blockShadowOpacityAtom(config)),
    offsetX: await snapshot.getPromise(blockShadowOffsetXAtom(config)),
    offsetY: await snapshot.getPromise(blockShadowOffsetYAtom(config)),
  }
}

const strokeState = async (config: BlockConfigType, snapshot: Snapshot) => {
  return {
    enabled: await snapshot.getPromise(blockStrokeEnabledAtom(config)),
    color: await snapshot.getPromise(blockStrokeColorAtom(config)),
    width: await snapshot.getPromise(blockStrokeWidthAtom(config)),
  }
}

const textStateReducer = async (
  textContent: { [key: string]: BlockTextType },
  id: string,
  kind: AvailableBlocksType,
  snapshot: Snapshot,
) => {
  return Object.keys(textContent).reduce(async (textAcc, textKey) => {
    const awaitedTextAcc = await textAcc
    const config = { id, kind, key: textKey }
    return {
      ...awaitedTextAcc,
      [textKey]: {
        value: await snapshot.getPromise(blockTextValueAtom(config)),
        color: await snapshot.getPromise(blockTextColorAtom(config)),
        fontSize: await snapshot.getPromise(blockFontSizeAtom(config)),
        textAlign: await snapshot.getPromise(blockTextAlignAtom(config)),
        fontStyle: await snapshot.getPromise(blockFontStyleAtom(config)),
        fontFamily: await snapshot.getPromise(blockFontFamilyAtom(config)),
        textDecoration: await snapshot.getPromise(
          blockTextDecorationAtom(config),
        ),
      },
    }
  }, Promise.resolve({}))
}
const shapeStateReducer = async (
  shapeContent: { [key: string]: BlockShapeType },
  id: string,
  kind: AvailableBlocksType,
  snapshot: Snapshot,
) => {
  return Object.keys(shapeContent).reduce(async (acc, shapeKey) => {
    const awaitedAcc = await acc
    const config = { id, kind, key: shapeKey }
    return {
      ...awaitedAcc,
      [shapeKey]: {
        color: await snapshot.getPromise(blockShapeColorAtom(config)),
        borderRadius: await snapshot.getPromise(blockBorderRadiusAtom(config)),
        shape: await snapshot.getPromise(blockShapeTypeAtom(config)),
        shadow: await shadowState(config, snapshot),
        stroke: await strokeState(config, snapshot),
      },
    }
  }, Promise.resolve({}))
}

export const useSaveState = () => {
  const saveState = useRecoilCallback(({ snapshot }) => async () => {
    const creativeType = await snapshot.getPromise(creativeTypeAtom)
    const directMailSize = await snapshot.getPromise(directMailSizeAtom)
    const blocks = await snapshot.getPromise(blocksAtom)
    const backgroundPhoto = await snapshot.getPromise(backgroundPhotoAtom)
    const filterAngle = await snapshot.getPromise(filterAngleAtom)
    const filterStartColor = await snapshot.getPromise(filterStartColorAtom)
    const filterStopColor = await snapshot.getPromise(filterStopColorAtom)
    const filterVisible = await snapshot.getPromise(filterVisibleAtom)
    const filterStartSpace = await snapshot.getPromise(filterStartSpaceAtom)
    const filterStopSpace = await snapshot.getPromise(filterStopSpaceAtom)
    const filterOpacity = await snapshot.getPromise(filterOpacityAtom)
    const blockDetails = async () => {
      const allBlocks = await blocks.reduce(async (acc, block) => {
        const awaitedAcc = await acc
        const { id, kind, artboardName } = block
        const { textContent, shapeContent } = blocksBase[kind]
        return {
          ...awaitedAcc,
          [id]: {
            visible: await snapshot.getPromise(visibleBlockAtom(id)),
            positionDimension: await snapshot.getPromise(
              positionAndDimensionsBlockAtom({ kind, artboardName }),
            ),
            scale: await snapshot.getPromise(scaleBlockAtom(id)),
            headShotImage: await snapshot.getPromise(headShotImageAtom(id)),
            textState: await textStateReducer(textContent, id, kind, snapshot),
            shapeState:
              shapeContent &&
              (await shapeStateReducer(shapeContent, id, kind, snapshot)),
          },
        }
      }, Promise.resolve({}))
      return allBlocks
    }
    const stateBlock: SaveStateType = {
      creativeType,
      directMailSize,
      blocks,
      backgroundPhoto,
      filterAngle,
      filterStartColor,
      filterStopColor,
      filterVisible,
      filterStartSpace,
      filterStopSpace,
      filterOpacity,
      blockDetails: await blockDetails(),
    }
    return JSON.stringify(stateBlock)
  })

  return saveState
}
