/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Deployservicev3Destination } from '../models/Deployservicev3Destination';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { HttpFile } from '../http/http';

/**
* The event emitted when an Order Line is deployed.
*/
export class V1OrderLineDeployed {
    'id'?: string;
    /**
    * The org ID of the Order Line that is being deployed.
    */
    'orgId'?: string;
    /**
    * The IDs of the creatives associated to the Order Line that are to be deployed.
    */
    'creativeIds'?: Array<string>;
    'targetJobIds'?: Array<string>;
    'destination'?: Deployservicev3Destination;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;
    /**
    * The destinations where the Order Line is being deployed.
    */
    'destinations'?: Array<Deployservicev3Destination>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeIds",
            "baseName": "creative_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "targetJobIds",
            "baseName": "target_job_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "destination",
            "baseName": "destination",
            "type": "Deployservicev3Destination",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "destinations",
            "baseName": "destinations",
            "type": "Array<Deployservicev3Destination>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineDeployed.attributeTypeMap;
    }

    public constructor() {
    }
}

