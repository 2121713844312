// TODO: Use wire-up web-to-home's cases/options when api is ready
export type suggUsesType = {
  id?: string
  label?: string
  labelSub?: string
  value?: string | number
}

export const suggUsesLabels: suggUsesType[] = [
  {
    id: '1',
    label: 'Pixel Entire Site',
    labelSub: 'This will capture all visitors to all pages of the website',
    value: 'pixel_entire_site',
  },
  {
    id: '2',
    label: 'Visitors Who Left',
    labelSub: 'High interest visitors who failed to convert',
    value: 'visitors_who_left',
  },
  {
    id: '3',
    label: 'Keyword Targeting',
    labelSub: 'Specific keywords or product lines',
    value: 'keyword_targeting',
  },
  {
    id: '4',
    label: 'Visits to Site',
    labelSub: 'Capture unique visitors based on frequency',
    value: 'visits_to_site',
  },
  {
    id: '5',
    label: 'Advanced Options',
    labelSub: 'Create your own rule',
    value: 'advanced_options',
  },
]
