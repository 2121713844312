import React from 'react'
import { Button, Modal } from '@eltoro-ui/components'
import './WarningModal.scss'

export const WarningModal: React.FC<{
  onCancel?: () => void
  onConfirm: () => void
  children?: React.ReactNode
}> = ({ onCancel, onConfirm, children }) => {
  return (
    <Modal offClick={onCancel} className="WarningModal">
      {children}
      <div className="mt-4 flex justify-end gap-2 pb-1">
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        <Button
          className={`text-primer !border-transparent bg-primary ${
            onCancel ? 'bg-danger-600 hover:!bg-danger' : ''
          }`}
          onClick={onConfirm}
        >
          {onCancel ? 'Confirm' : 'OK'}
        </Button>
      </div>
    </Modal>
  )
}
