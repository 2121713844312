/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3ConsoleCampaign } from '../models/V3ConsoleCampaign';
import { V3CurateCampaign } from '../models/V3CurateCampaign';
import { V3InvestCampaign } from '../models/V3InvestCampaign';
import { HttpFile } from '../http/http';

/**
* Response to get an OrderLine's Destination Configuration Default Template.
*/
export class V1GetTemplateResponse {
    /**
    * ID of the Order Line.
    */
    'orderLineId'?: string;
    'investCampaign'?: V3InvestCampaign;
    'consoleCampaign'?: V3ConsoleCampaign;
    'curateCampaign'?: V3CurateCampaign;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "investCampaign",
            "baseName": "invest_campaign",
            "type": "V3InvestCampaign",
            "format": ""
        },
        {
            "name": "consoleCampaign",
            "baseName": "console_campaign",
            "type": "V3ConsoleCampaign",
            "format": ""
        },
        {
            "name": "curateCampaign",
            "baseName": "curate_campaign",
            "type": "V3CurateCampaign",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1GetTemplateResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

