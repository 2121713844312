import { useState } from 'react'
import { AutoScroller, Button, Fa, Tabs, Text } from '@eltoro-ui/components'
import { Creativeservicev1Creative } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { Notification } from 'Components'
import { CreativesLibraryPage } from 'Pages'
import { bannerFixture } from 'Fixtures'
import { ETIcon } from 'Assets'
import { AdTagFormInputs, AdTagFormPreview } from './components'
import { AdSetDisplay } from '../components'

type AdTagFormProps = {
  type: 'video' | 'banner'
  isInLibrary?: boolean
  refreshLibrary?: () => void
  onUseInNewCampaign?: (creative: Creativeservicev1Creative) => void
}

// The 'guts' of the AdTagForm. For use in places where the tab structure is not needed.
export const AdTagCreate = ({
  type,
  isInLibrary,
  refreshLibrary,
  setSelectedTab,
  handleSelect,
  onUseInNewCampaign,
  isPoliticalInCampaign,
}: {
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>
  handleSelect?: (creative: Creativeservicev1Creative) => void
  isPoliticalInCampaign?: boolean
} & AdTagFormProps) => {
  const [adDimensions, setAdDimensions] = useState<string>()
  const [adTagText, setAdTagText] = useState<string>()
  const [name, setName] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadedCreative, setUploadedCreative] = useState<
    Creativeservicev1Creative | undefined
  >(bannerFixture)

  const { currentOrg, creativeServiceApi, campaignServiceApi } = useAppContext()

  const handleCreateAdTagCreative = async (reset?: boolean) => {
    if (
      !currentOrg?.id ||
      !name ||
      !adTagText ||
      !creativeServiceApi ||
      !campaignServiceApi
    )
      return
    setError(false)
    setLoading(true)
    const width = adDimensions?.split('x')[0]
      ? Number(adDimensions?.split('x')[0])
      : undefined
    const height = adDimensions?.split('x')[1]
      ? Number(adDimensions?.split('x')[1])
      : undefined

    creativeServiceApi
      .advertisingPlatformServiceCreateCreative(currentOrg.id, {
        name,
        type:
          type === 'video' ? 'CREATIVE_TYPE_VAST_TAG' : 'CREATIVE_TYPE_AD_TAG',
        adTag: adTagText,
        width,
        height,
      })
      .then(async (newCreative) => {
        setUploadedCreative(newCreative)
        if (!isInLibrary) {
          if (handleSelect) handleSelect(newCreative)
          setLoading(false)
          if (reset) resetForm()
          return
        }
        if (refreshLibrary) refreshLibrary()
        setLoading(false)
        if (reset) {
          resetForm()
          setUploadedCreative(undefined)
          return
        }
        setUploadSuccess(true)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }

  const disableSaveButtonsBanner =
    !name || !adTagText || loading || !adDimensions
  const disableSaveButtonsVideo = !name || !adTagText || loading

  const resetForm = () => {
    setName('')
    setAdTagText('')
    setAdDimensions(undefined)
    setError(false)
  }
  if (uploadSuccess)
    return (
      <Notification header="Success!" layOut="row" icon iconType="ad-tag">
        <div className="tint-gray-800">
          <p>
            Your {type === 'video' ? 'VAST Tag' : 'Ad Tag'} creative
            {uploadedCreative?.name && (
              <>
                &nbsp;&quot;<strong>{uploadedCreative?.name}</strong>&quot;
              </>
            )}
            {`${
              isInLibrary
                ? ' has been added to your library'
                : ' has been added to this campaign and to your library'
            }`}
            .
          </p>
          <div className="flex justify-end gap-2 p-4">
            {onUseInNewCampaign && (
              <Button
                size="l"
                rounded={false}
                kind="default-grey"
                onClick={() => {
                  if (onUseInNewCampaign && uploadedCreative)
                    onUseInNewCampaign(uploadedCreative)
                }}
              >
                Use in New Campaign
              </Button>
            )}
            <Button
              onClick={() => {
                setUploadSuccess(false)
                setUploadedCreative(undefined)
                setError(false)
                if (setSelectedTab) setSelectedTab('adTagTab')
              }}
            >
              Upload another
            </Button>
          </div>
        </div>
      </Notification>
    )

  return (
    <>
      <div className="AdTagCreate mobile:flex-wrap flex justify-between gap-8 py-4">
        <div className="AdTagCreate__input-wrap flex flex-col gap-4">
          <div className="AdTagCreate__info pb-4">
            {type === 'banner' ? (
              <span className="AdTagCreateForm__header-text AdTag py-2 font-light leading-normal">
                Incorporating Ad Tags into your digital marketing mix will give
                you better creative control and data insights. Ownership,
                freshness, and privacy are other beneficial factors of utilizing
                Ad Tags.
                <a
                  href="https://eltoro.zendesk.com/hc/en-us/articles/4769878730011-Ad-Tags"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="AdTagCreate__info-link text-base-info flex items-center font-semibold"
                >
                  <Fa icon="question-circle" size={1} />
                  <span>Read more about Ad Tags</span>
                </a>
              </span>
            ) : (
              <span className="AdTagCreateForm__header-text VastTag py-2 font-light leading-normal">
                VAST tags offer more transparency and reporting at the creative
                level through third-party providers. VAST versions supported:
                2.0, 3.0, and 4.0. &nbsp;
                <a
                  href="https://eltoro.zendesk.com/hc/en-us/articles/5024504163739-VAST-Tags"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="AdTagCreate__info-link text-base-info flex items-center font-semibold"
                >
                  <Fa icon="question-circle" size={1} />
                  <span>Read more about VAST tags</span>
                </a>
              </span>
            )}
          </div>
          {/* Form column */}
          <AdTagFormInputs
            type={type}
            loading={loading}
            adTagText={adTagText}
            setAdTagText={setAdTagText}
            name={name}
            setName={setName}
            adDimensions={adDimensions}
            setAdDimensions={setAdDimensions}
          />
          {isPoliticalInCampaign && (
            <span className=" text-warning-600 group relative z-10 flex w-full flex-1 items-center justify-center gap-1 font-bold">
              If a creative does not have a legible "Paid for By" statement, it
              will be removed from the order line.
            </span>
          )}
          {/* Save button */}
          <div className="AdTag__save-button--wrap flex flex-row-reverse gap-4 py-4">
            <Button
              className={`AdTag__save-button ${
                !disableSaveButtonsBanner ? 'Button--primary ripples' : ''
              } w-32`}
              disabled={
                type === 'video'
                  ? disableSaveButtonsVideo
                  : disableSaveButtonsBanner
              }
              onClick={() => {
                handleCreateAdTagCreative().then(() => {
                  resetForm()
                  setUploadSuccess(true)
                })
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {/* Preview column */}
        <AdTagFormPreview type={type} loading={loading} error={error} />
      </div>
    </>
  )
}

export const AdTagForm = ({
  type,
  isInLibrary = false,
  refreshLibrary,
  isPoliticalAndCampaignCreation,
}: { isPoliticalAndCampaignCreation?: boolean } & AdTagFormProps) => {
  const [selectedTab, setSelectedTab] = useState('')
  const [selectedAdTagCreatives, setSelectedAdTagCreatives] = useState<
    Creativeservicev1Creative[]
  >([])

  const handleSelect = (creative: Creativeservicev1Creative) => {
    setSelectedAdTagCreatives((prev) => [...prev, creative])
  }
  const handleRemove = (creativeId: string) => {
    setSelectedAdTagCreatives((prev) => prev.filter((f) => f.id !== creativeId))
  }

  return (
    <AutoScroller
      className="AdTagForm__autoscroller w-full flex-1"
      shouldScroll={selectedAdTagCreatives.length < 1}
      scrollToTop={selectedAdTagCreatives.length === 0}
    >
      <div className="AdTagForm">
        <Tabs
          classNamePanel="AdTagForm__tabs flex-1 w-full"
          tabs={[
            {
              id: 'adTagTab',
              label: (
                <div className="AdTagForm__header-text-icon flex w-auto items-center justify-center gap-2">
                  <ETIcon
                    className="w-18"
                    icon={type === 'video' ? 'vast-tag' : 'ad-tag'}
                  />
                  <Text
                    className="AdTagForm__header-text font-normal normal-case"
                    tag="h5"
                  >
                    Upload a new file
                  </Text>
                </div>
              ),
              defaultTab: selectedTab === 'adTagTab',
              component: (
                <AdTagCreate
                  type={type}
                  isInLibrary={isInLibrary}
                  refreshLibrary={refreshLibrary}
                  setSelectedTab={setSelectedTab}
                  handleSelect={handleSelect}
                  isPoliticalInCampaign={isPoliticalAndCampaignCreation}
                />
              ),
            },
            {
              id: 'GetFromLibrary',
              label: (
                <>
                  <span
                    aria-label="“Select from Creative Library"
                    data-tooltip="left"
                  >
                    <Fa
                      className="text-primary"
                      icon="folders"
                      size={3}
                      type="duotone"
                    />
                  </span>
                  <span className="CreativeTypeSelector__tabs--text">
                    Select from Creative Library
                  </span>
                </>
              ),
              defaultTab: selectedTab === 'GetFromLibrary',
              component: (
                <CreativesLibraryPage
                  selectedCreatives={selectedAdTagCreatives}
                  addToCreativeSet={(creative) => {
                    handleSelect(creative)
                    setSelectedTab('GetFromLibrary')
                  }}
                  modalPreview={isInLibrary}
                  windowed={isInLibrary}
                  removeFromCreativeSet={handleRemove}
                  filterByType={
                    type === 'video'
                      ? ['CREATIVE_TYPE_VAST_TAG']
                      : ['CREATIVE_TYPE_AD_TAG']
                  }
                  emptyState={
                    <div className="AdTagForm__library-empty-state text-grey-700 flex flex-col items-center">
                      {`Oops! There are no ${
                        type === 'video' ? 'VAST ' : ''
                      }Ad Tag creatives in your Creative Library.`}
                    </div>
                  }
                />
              ),
            },
          ]}
        />
      </div>
      <AdSetDisplay
        shouldOpen={!!selectedAdTagCreatives.length}
        selectedCreatives={selectedAdTagCreatives}
        setSelectedCreatives={setSelectedAdTagCreatives}
        showBannerChecklist={false}
        type={type}
      />
    </AutoScroller>
  )
}
