import { useContext, useState } from 'react'
import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import { EditingType } from './CreateCampaignHeader'
import { ThemeContext } from 'Contexts'

export const CampaignHeaderDetail: React.FC<{
  type: EditingType
  onClick: () => void
  currentlyEditing?: EditingType
  disabled?: boolean
  children?: React.ReactNode
  notNeeded?: boolean
}> = ({ children, onClick, currentlyEditing, type, disabled, notNeeded }) => {
  const [hovered, setHovered] = useState(false)
  const { name: theme } = useContext(ThemeContext)
  const isDarkTheme = theme === 'dark' || theme === 'darkBlue'

  const isActive = currentlyEditing && currentlyEditing === type

  return (
    <button
      type="button"
      className={classNames(
        'CampaignHeaderDetail flex flex-1 items-center gap-2 rounded p-3 text-left focus:ring-2',
        {
          'bg-primary-50': !isDarkTheme && (hovered || isActive),
          'bg-primary-800': isDarkTheme && (hovered || isActive),
          'rounded-b-none': isActive,
          'opacity-60': currentlyEditing && !hovered && !isActive,
        },
      )}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={
        !disabled
          ? onClick
          : (e) => {
              e.stopPropagation()
            }
      }
    >
      {children}
      {notNeeded ? (
        <></>
      ) : !disabled ? (
        <Fa
          icon="pen"
          size={1}
          className={classNames('FontAwesome_pen-icon text-primary ml-auto', {
            'opacity-100': hovered,
            'opacity-0': !hovered || (currentlyEditing && isActive),
          })}
        />
      ) : (
        <span
          data-tooltip="right"
          aria-label="This campaign contains live order lines. Please edit eligible order lines using the edit menu."
        >
          <Fa
            icon="lock"
            size={1}
            className={classNames(
              'FontAwesome_info-icon text-primary ml-auto',
              {
                'opacity-100': hovered,
                'opacity-0': !hovered || (currentlyEditing && isActive),
              },
            )}
          />
        </span>
      )}
    </button>
  )
}
