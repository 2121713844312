import { useState } from 'react'
import { Button, TextHeader } from '@eltoro-ui/components'
import { CreditCardSelect } from 'Components'
import { useAppContext } from 'Contexts'

export const SetUpFee = () => {
  const [selectedCard, setSelectedCard] = useState<string>()
  const [status, setStatus] = useState<
    'submitting' | 'in-process' | 'overview' | 'error-submitting'
  >('overview')
  const {
    currentOrg,
    billingServiceApi,
    orgServiceApi,
    setCurrentOrg,
  } = useAppContext()

  const handlePaySetUpFee = () => {
    if (!billingServiceApi || !currentOrg?.id || !selectedCard) return
    setStatus('submitting')
    billingServiceApi
      .advertisingPlatformServicePaySetupFee(currentOrg.id, {
        creditCardId: selectedCard,
      })
      .then(() => {
        if (!orgServiceApi || !currentOrg?.id) return
        orgServiceApi
          .advertisingPlatformServiceGetOrg(currentOrg.id)
          .then(setCurrentOrg) // this sets the org so this component will not be shown
      })
      .catch(() => setStatus('error-submitting'))
  }

  return (
    <div className="SetUpFee BlockWithBorder">
      <div className="flex items-center justify-between">
        <TextHeader type={3}>Pre-payment required</TextHeader>
        {status === 'overview' && (
          <Button className="self-end" onClick={() => setStatus('in-process')}>
            Pay now
          </Button>
        )}
      </div>
      <div className="StepUpFee_main-container flex flex-col gap-2 py-2">
        <p className="text-danger font-bold">
          New organizations must pay a set up fee to submit campaigns.
        </p>
        <div className="SetUpFee__amount-to-pay flex gap-2">
          <strong>Amount to pay:</strong>$
          {currentOrg?.initialSetupFee?.toLocaleString()}
        </div>
        {status !== 'overview' && (
          <>
            <CreditCardSelect
              setSelectedCard={setSelectedCard}
              selectedCard={selectedCard}
              disabled={status === 'submitting'}
            />
            {status === 'error-submitting' && (
              <div className="SetUpFee__error-msg bg-danger-50 text-danger animate-slidedown rounded p-2 text-center font-bold">
                There was an error submitting your set up fee payment. Please
                try again. If the issue persists, please submit a support
                request.
              </div>
            )}
            {selectedCard && (
              <Button
                className="w-min self-center"
                disabled={!selectedCard || status === 'submitting'}
                onClick={handlePaySetUpFee}
              >
                Pay now
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
