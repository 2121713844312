import { getApiUrl } from 'Helpers'
import axios from 'axios'

export const downloadTos = async (
  filename: string,
  org_id: string,
  user_id: string,
) => {
  const rootUrl = `${getApiUrl()}/v1`

  const triggerDownload = (fileName: string, fileUrl: string) => {
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(fileUrl)
  }
  const res = await axios({
    method: 'get',
    url: `${rootUrl}/tos:download?filename=${filename}&org_id=${org_id}&user_id=${user_id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('eltoro_token')}`,
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
    },
    responseType: 'arraybuffer',
  })
  const url = window.URL.createObjectURL(
    new Blob([res.data], { type: 'application/pdf' }),
  )
  triggerDownload(filename, url)
}
