import React from 'react'
import {
  Spacer,
  Slider,
  Text,
  FlexBox,
  Button,
  GridBox,
  Fa,
} from '@eltoro-ui/components'
import './EditScale.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scaleBlockSelector, selectedBlockIdsAtom } from 'State'

export const EditScale = () => {
  const ids = useRecoilValue(selectedBlockIdsAtom)
  const [scale, setScale] = useRecoilState(scaleBlockSelector(ids))
  const handleReturnToDefault = () => {
    setScale({ x: 1, y: 1 })
  }
  return (
    <div className="EditScale">
      <FlexBox alignItems="center" justifyContent="space-between">
        <Text on="white" weight="bold" kind="subdued">
          Size
        </Text>
        <div
          className={`EditScale__undo-button ${
            scale.x !== 1 ? 'EditScale__undo-button--can-undo' : ''
          }`}
        >
          <Button
            onClick={handleReturnToDefault}
            kind="text"
            iconLeft={<Fa size={1} icon="undo" />}
          />
        </div>
      </FlexBox>
      <Spacer height="0.5rem" />
      <GridBox
        alignItems="center"
        gridTemplateColumns="min-content 1fr min-content"
        gap="0.5rem"
      >
        <Fa size={1} icon="compress" />
        <Slider
          value={scale.x}
          onChange={(newScale) => {
            setScale({
              x: newScale,
              y: newScale,
            })
          }}
          aria-label="scale slider"
          min={0.5}
          max={1.5}
          step={0.01}
        />
        <Fa size={1} icon="expand" />
      </GridBox>
      <Spacer />
    </div>
  )
}
