import React from 'react'
import FlipNumbers from 'react-flip-numbers'
import { StandardLonghandProperties } from 'csstype'
import './CounterButton.scss'
import { Fa } from '@eltoro-ui/components'

export const CounterButton: React.FC<{
  handleClick: (
    buttonId: string,
    e?: React.MouseEvent<HTMLButtonElement>,
  ) => void
  id: string
  isSelected: boolean
  title?: string
  subTitle?: string
  count?: string
  footerText?: string
  disabled?: boolean
  width?: StandardLonghandProperties['width']
  height?: StandardLonghandProperties['height']
  centered?: boolean
  customStyle?: { [key: string]: string }
}> = (props) => {
  const {
    handleClick,
    id,
    isSelected,
    title,
    subTitle,
    customStyle,
    width,
    height,
    centered,
    disabled,
    count = '0',
    footerText,
  } = props
  // reduces the number height if there the count is more than 6 digits long
  const dimensionsCalculate = () =>
    Number(
      count
        .toString()
        .split('')
        .reduce(
          (acc, val, index) => {
            if (val && index > 4) {
              return [acc[0] - acc[1], acc[1] + 10]
            }
            return acc
          },
          [60, 0],
        )[0],
    )
  return (
    <button
      disabled={disabled}
      type="button"
      className={`CounterButton relative${
        isSelected ? ' CounterButton__selected' : ''
      }`}
      onClick={(e) => handleClick(id, e)}
      style={{ width, height, ...customStyle }}
    >
      <div
        className={`CounterButton__text-container${
          centered ? ' CounterButton__text-container--centered' : ''
        }`}
      >
        <div className="CounterButton__title">{title}</div>
        <div className="CounterButton__subTitle leading-none">{subTitle}</div>
        {count && (
          <>
            <div className="CounterButton__counter">
              <FlipNumbers
                numbers={`${count?.toLocaleString() || 0}`}
                play
                height={dimensionsCalculate()}
                width={dimensionsCalculate() / 2}
                perspective={5000}
                duration={0.7}
              />
            </div>
            <div className="CounterButton__footerText">{footerText}</div>
          </>
        )}
      </div>
      <Fa
        icon={isSelected ? 'circle-check' : 'circle'}
        size={2}
        className="absolute right-2 bottom-2 max-h-6"
        type={!isSelected ? 'light' : undefined}
      />
    </button>
  )
}
