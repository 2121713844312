import React, { useEffect, useRef } from 'react'
import { KonvaEventObject } from 'konva/types/Node'
import { Group, Rect } from 'react-konva'
import { AvailableBlocksType } from 'Typings'
import { handleBlockDragMove } from 'Helpers'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  positionAndDimensionsBlockAtom,
  visibleBlockAtom,
  lockedBlockAtom,
  selectedKindAtom,
  selectedBlockIdsAtom,
  mainDimensionsBlockAtom,
  allBlocksOfKindSelector,
  scaleBlockAtom,
  absoluteBlockPositionAtom,
  isDraggingLayerAtom,
  showBlockOverlayAtom,
  blockOverlayDimsAtom,
} from 'State'
import Konva from 'konva'
import { ActionButton, BasicParagraph, SquareLogo, PoliticalHeader } from '..'
import { ImageFrame } from '../ImageFrame'

// acts as a wrapper for a specific block type
export const Block: React.FC<{
  blockConfig: {
    id: string
    kind: AvailableBlocksType
    artboardName: string
  }
  isEditing: boolean
  onSelect: (select: boolean) => void
  isOnMobile: boolean
}> = ({ blockConfig, isEditing, onSelect, isOnMobile }) => {
  const { kind, artboardName } = blockConfig
  const groupRef = useRef<Konva.Group>(null)
  const [{ x, y, width, height }, setBlockPosAndDims] = useRecoilState(
    positionAndDimensionsBlockAtom({ kind, artboardName }),
  )
  const mainDimensions = useRecoilValue(mainDimensionsBlockAtom(blockConfig.id))
  const visible = useRecoilValue(visibleBlockAtom(blockConfig.id))
  const scale = useRecoilValue(scaleBlockAtom(blockConfig.id))
  const locked = useRecoilValue(lockedBlockAtom(blockConfig.id))
  const [selectedKind, setSelectedKind] = useRecoilState(selectedKindAtom)
  const allBlocksOfKind = useRecoilValue(allBlocksOfKindSelector)
  const [selectedIds, setSelectedIds] = useRecoilState(selectedBlockIdsAtom)
  const setAbsPos = useSetRecoilState(absoluteBlockPositionAtom(blockConfig.id))
  const setShowBlockOverlay = useSetRecoilState(
    showBlockOverlayAtom(blockConfig.id),
  )
  const setBlockOverlayDims = useSetRecoilState(
    blockOverlayDimsAtom(blockConfig.id),
  )
  const isDraggingLayer = useRecoilValue(isDraggingLayerAtom)

  const isSelected =
    isEditing &&
    blockConfig.kind === selectedKind &&
    selectedIds.some((id) => id === blockConfig.id)

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    setAbsPos(e.target.absolutePosition())
    if (isSelected) setShowBlockOverlay(true)

    setBlockPosAndDims({
      width,
      height,
      x: e.target.x(),
      y: e.target.y(),
    })
    const layer = e.target.getLayer()
    if (!layer) return
    // removes any guides
    layer.find('.guide-line').destroy()
  }

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true
    // if there is no Block selected
    if (!selectedKind) {
      onSelect(true)
      setSelectedKind(blockConfig.kind)
      return
    }
    // if there is a block selected and user clicks a block of the same type
    if (blockConfig.kind === selectedKind) {
      const matchingBlocks = allBlocksOfKind
        ?.map((blockOfKind) => blockOfKind.id)
        .reduce((acc: boolean[], blockId) => {
          const match = selectedIds.some((selectedId) => selectedId === blockId)
          return [...acc, match]
        }, [])
      // if all blocks of a kind are selected, only select the individual block that is clicked
      if (matchingBlocks?.every((matchingBlock) => matchingBlock)) {
        setSelectedIds([blockConfig.id])
        return
      }
      // if next click will result in 0 selectedIds, close edit menu
      if (isSelected) {
        setSelectedIds(selectedIds.filter((id) => id !== blockConfig.id))
        if (selectedIds.length === 1) {
          setSelectedKind(undefined)
          onSelect(false)
        }
      } else {
        // otherwise, add block to selected array
        setSelectedIds([blockConfig.id, ...selectedIds])
      }
    } else {
      // select new kind
      setSelectedKind(blockConfig.kind)
    }
  }

  const renderPiece = () => {
    switch (blockConfig.kind) {
      case 'actionButton':
        return (
          <ActionButton
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        )
      case 'basicParagraph':
        return (
          <BasicParagraph
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        )
      case 'squareLogo':
        return (
          <SquareLogo
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        )
      case 'politicalHeader':
        return (
          <PoliticalHeader
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        )
      case 'imageFrame':
        return (
          <ImageFrame
            isOnMobile={isOnMobile}
            id={blockConfig.id}
            width={width}
            height={height}
          />
        )
      default:
        return <Rect />
    }
  }

  // a clumsy way to get the width and height of a block if it differs from the original recoil state
  const actualWidth = () => {
    if (isOnMobile && mainDimensions.width >= width) {
      return width
    }
    return mainDimensions.width > 0 ? mainDimensions.width : width
  }

  const actualHeight =
    mainDimensions.height > 0 ? mainDimensions.height : height

  // get position and dimensions of block when selected
  useEffect(() => {
    if (!groupRef.current) return
    setAbsPos(groupRef.current.getAbsolutePosition())
    setBlockOverlayDims({ width, height })
  }, [isSelected])

  // get position and dimensions of block when layer drag finishes
  useEffect(() => {
    if (!groupRef.current) return
    setAbsPos(groupRef.current.getAbsolutePosition())
  }, [isDraggingLayer])

  return (
    <Group
      ref={groupRef}
      id={blockConfig.id}
      x={x}
      y={y}
      scale={scale}
      draggable={!locked}
      onDragStart={(e: KonvaEventObject<DragEvent>) => {
        e.cancelBubble = true
        if (isSelected) {
          setShowBlockOverlay(false)
        }
      }}
      onDragMove={(e: KonvaEventObject<DragEvent>) =>
        handleBlockDragMove(e, actualWidth() * scale.x, actualHeight * scale.y)
      }
      onDragEnd={handleDragEnd}
      onClick={handleClick}
      onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
        if (locked) return
        const stage = e.currentTarget.getStage()
        if (!stage) return
        stage.container().style.cursor = 'pointer'
      }}
      onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
        const stage = e.currentTarget.getStage()
        if (!stage) return
        stage.container().style.cursor = 'default'
      }}
      opacity={visible ? 1 : 0}
      listening={visible}
      height={actualHeight}
    >
      {renderPiece()}
    </Group>
  )
}
