import { triggerPrompt } from '@eltoro-ui/components'
import { Orgmanagerv1Org } from 'next-gen-sdk'

export const v2SyncPrompt = (fn: Function, currentOrg?: Orgmanagerv1Org) => {
  const needsV2Confirmation =
    currentOrg?.lastSyncTime &&
    new Date(currentOrg.lastSyncTime).getFullYear() > 2023

  if (!needsV2Confirmation) {
    fn()
    return
  }
  triggerPrompt(
    'V2 Synced Org',
    <p>
      This organization syncs with V2. Ensure changes in NextGen also happen in
      V2 to avoid overwriting.
    </p>,
    fn,
  )
}
