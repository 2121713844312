import { useEffect, useRef, useState, memo } from 'react'
import {
  Creativeservicev1Creative,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import {
  Button,
  Fa,
  Popover,
  Table,
  TableColumnType,
} from '@eltoro-ui/components'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { checkIfCreative } from 'Helpers'
import { getCreativeOrderLines, getJobOrderLines } from 'Requests'
import { useAppContext } from 'Contexts'
import { OrderLinesWithError } from 'types'

type OrderLineCountProps = {
  item: Creativeservicev1Creative | Targetjobservicev1Audience
  isInPreviewPanel?: boolean // in PreviewPanel, is a button. Otherwise, link.
}

const IDRow = ({ row }: { row: OrderLinesWithError }) => {
  const [copyId, setCopyId] = useState(false)

  const copyIdToClipboard = (id: string) => {
    navigator.clipboard.writeText(id).then(() => setCopyId(true))
  }

  useEffect(() => {
    const resetCopied = (): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          setCopyId(false)
          resolve()
        }, 4000)
      })

    if (copyId) resetCopied()
  }, [copyId])
  return (
    <div className="flex items-center justify-between gap-4">
      {row.id}
      <Button
        className={classNames(
          'OrderLineCount__copy-id-btn min-w-[unset]',
          copyId ? 'tooltip-success' : '',
        )}
        iconOnly={
          !copyId ? (
            <Fa icon="link" size={1} className="!text-xs" />
          ) : (
            <Fa icon="copy" size={1} className="!text-xs" />
          )
        }
        size="s"
        onClick={() => copyIdToClipboard(row.id)}
        ariaLabel={copyId ? 'Copied ID' : 'Copy ID'}
        dataTooltip="left"
      />
    </div>
  )
}

export const OrderLineCount: React.FC<OrderLineCountProps> = memo(
  ({ item, isInPreviewPanel }) => {
    const [loading, setLoading] = useState(false)
    const [orderLines, setOrderLines] = useState<OrderLinesWithError[]>()
    const [orderLineIds, setOrderLineIds] = useState<string[]>([])
    const [isOpen, setIsOpen] = useState(false)

    const infoContentRef = useRef<HTMLDivElement>(null)
    const infoButtonRef = useRef<HTMLDivElement>(null)
    useOnClickOutside([infoContentRef, infoButtonRef], () => setIsOpen(false))
    const { currentOrg, tok } = useAppContext()

    // Loads the order lines when the user opens the dropdown
    useEffect(() => {
      const loadOrderLines = async () => {
        if (!currentOrg?.id || !isOpen || orderLines?.length) return
        setLoading(true)
        let ols: OrderLinesWithError[] = []
        if (checkIfCreative(item)) {
          ols = await getCreativeOrderLines(item, currentOrg.id)
        } else {
          ols = await getJobOrderLines(item, currentOrg.id)
        }
        setOrderLines(ols)
        setLoading(false)
      }
      if (isOpen) loadOrderLines()
    }, [currentOrg?.id, isOpen, item, orderLines?.length])

    // Gets the order line id count
    useEffect(() => {
      const getOlIds = async () => {
        if (checkIfCreative(item)) {
          setOrderLineIds(
            (item.orderLines || []).reduce((acc: string[], ol) => {
              if (ol.id) return [...acc, ol.id]
              return acc
            }, []),
          )
          return
        }
        if (item.type === 'AUDIENCE_TYPE_VR') {
          // get order line ids from sub jobs
          const idList = (item.audiences || [])?.reduce(
            (acc: string[], aud) => {
              return [...new Set([...acc, ...(aud.orderLineIds || [])])]
            },
            [],
          )
          setOrderLineIds(idList)
          return
        }
        setOrderLineIds(item.orderLineIds || [])
      }
      getOlIds()
    }, [currentOrg?.id, item, tok])

    const OLColumns: TableColumnType<OrderLinesWithError>[] = [
      {
        path: 'name',
        label: 'Order Line Name',
        RowCell: (row) => (
          <span className="flex gap-2 font-bold">
            {row.ol?.id && row.ol?.orgId && (
              <Link
                to={`/campaigns/dashboard/order-lines/${row.ol?.id}?org_id=${row.ol?.orgId}`}
              >
                <Fa
                  icon="arrow-up-right-from-square"
                  size={2}
                  className="!text-primary-500 inline-block px-1 !text-xs"
                />
              </Link>
            )}
            {row.err && (
              <Fa
                icon="triangle-exclamation"
                size={1}
                className="text-danger-500 inline-block px-1 !text-xs"
              />
            )}
            <span className="!max-w-[30ch] truncate">
              {row.err ? row.err : row.ol?.name || 'No name'}
            </span>
          </span>
        ),
      },
      {
        path: 'id',
        label: 'Order Line ID',
        RowCell: (row) => <IDRow row={row} />,
      },
    ]
    if (!orderLineIds && !isInPreviewPanel) return null
    return (
      <Popover
        position={isInPreviewPanel ? ['left'] : ['bottom']}
        align="start"
        content={
          // Preventing: click pop over, then clicks behind it on the table row.
          // There is no other need to 'click' this or add a keydown event, so disabling eslint
          // eslint-disable-next-line
          <div
            ref={infoContentRef}
            className="bg-base max-h-[15rem] overflow-y-auto rounded p-4 shadow"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              {loading ? (
                <>
                  <Fa
                    icon="spinner"
                    size={2}
                    className="text-grey-200 animate-spin"
                  />
                  <p>Loading</p>
                </>
              ) : (
                <Table columns={OLColumns} rows={orderLines || []} />
              )}
            </div>
          </div>
        }
        isOpen={isOpen}
      >
        <div className="OrderLine__count flex !justify-end">
          <Button
            onClick={(e) => {
              e?.stopPropagation()
              if (orderLineIds?.length) setIsOpen(true)
            }}
            className={classNames('Button--circle', {
              'text-l text-secondary-500 group hover:!text-primary-500 mx-auto border-none font-bold hover:!bg-transparent focus:bg-transparent': !isInPreviewPanel,
            })}
            rounded
          >
            {orderLineIds?.length > 0 ? (
              <span
                aria-label={`See ${orderLineIds?.length} Order Line${
                  orderLineIds?.length > 1 ? 's' : ''
                } attached`}
                data-tooltip="left"
              >
                {(orderLineIds?.length || 0).toString()}
              </span>
            ) : (
              <span
                aria-label="Not attached to any order line"
                data-tooltip="left"
                className="!text-grey-500 group-hover:!text-grey-500 !cursor-default"
              >
                {(orderLineIds?.length || 0).toString()}
              </span>
            )}
          </Button>
        </div>
      </Popover>
    )
  },
)
