import { useEffect, useState } from 'react'
import { Targetjobservicev1Audience } from 'next-gen-sdk'
import {
  Button,
  Fa,
  Text,
  Toggle,
  showErrorMessage,
  triggerPrompt,
} from '@eltoro-ui/components'
import classNames from 'classnames'
import { isInt } from 'Helpers'
import { useAppContext } from 'Contexts'

export const MatchCap = ({
  audience,
  hasDeployments,
  requoteDisabled,
  className,
}: {
  audience: Targetjobservicev1Audience
  hasDeployments?: boolean
  requoteDisabled?: boolean
  className?: string
}) => {
  const isLocked = audience.locked
  const matchCapped = !!audience.matchCap
  const matchCapInit =
    audience.matchCap && audience.matchCap > 0 ? `${audience.matchCap}` : ''
  const [toggleMatchCap, setToggleMatchCap] = useState(matchCapped)
  const [matchCapValue, setMatchCapValue] = useState<string>(matchCapInit)
  const { audienceServiceApi } = useAppContext()

  useEffect(() => {
    setToggleMatchCap(matchCapped)
    setMatchCapValue(matchCapInit)
  }, [matchCapped, matchCapInit])

  const handleMatchCap = async (value: number) => {
    if (audience.id && audience.orgId && audienceServiceApi) {
      await audienceServiceApi
        .advertisingPlatformServiceUpdateAudience(
          audience.id,
          audience.orgId,
          {
            matchCap: Number(value),
          },
          'match_cap',
        )
        .then(
          async () =>
            await audienceServiceApi.advertisingPlatformServiceGenerateAudience(
              audience.id,
              audience.orgId,
            ),
        )
        .catch(() => showErrorMessage('Error updating audience match cap', ''))
    }
  }
  // this seems redundant, but it is for handling the isLocked check for enter key on input or the save button
  const onMatchCap = () => {
    if (isLocked) {
      triggerPrompt(
        'Change Audience Match Cap',
        <p>
          This audience is currently locked. Are you sure you want to change the
          match cap of <strong>{audience.name}</strong> to{' '}
          <strong>{matchCapValue}</strong>?
        </p>,
        async () => handleMatchCap(Number(matchCapValue)),
      )
    } else handleMatchCap(Number(matchCapValue))
  }

  const matchCapValueIsOverSubmitted = !!(
    audience.initialMatches &&
    matchCapValue !== '' &&
    +matchCapValue > audience.initialMatches
  )
  const isInvalidInput = !!(
    matchCapValue &&
    (isNaN(Number(matchCapValue)) ||
      (matchCapValue && !isInt(matchCapValue)) ||
      Number(matchCapValue) < 0)
  )
  const errorMsg = () => {
    if (matchCapValueIsOverSubmitted)
      return `Value is over the possible matches for this audience: ${audience.initialMatches}`
    if (isInvalidInput) return 'Value is not a valid positive whole number'
  }

  return (
    <div
      className={classNames(
        'MatchCap grid grid-cols-2 items-center gap-2',
        className,
      )}
      data-tooltip="top"
      aria-label={
        hasDeployments
          ? 'This audience is deployed and cannot be match-capped'
          : undefined
      }
    >
      <Text className="text-xs">Enable Match Cap</Text>
      <Toggle
        className="ml-auto"
        size="xs"
        isChecked={toggleMatchCap}
        isDisabled={requoteDisabled || hasDeployments}
        onChange={(change) => {
          if (change) {
            setToggleMatchCap(change)
          } else if (
            audience.matchCap !== 0 &&
            audience.matchCap !== undefined // only set to 0 if it was not already zero
          ) {
            if (isLocked) {
              triggerPrompt(
                'Remove Audience Match Cap',
                <p>
                  This audience is currently locked. Are you sure you want to
                  remove the match cap from <strong>{audience.name}</strong>?
                </p>,
                async () => {
                  await handleMatchCap(0)
                  setMatchCapValue('')
                  setToggleMatchCap(false)
                },
              )
            } else {
              handleMatchCap(0).then(() => setToggleMatchCap(false))
              setMatchCapValue('')
            }
          } else setToggleMatchCap(false)
        }}
      />

      <div className="col-span-2 flex justify-between gap-2">
        <input
          type="number"
          value={matchCapValue}
          className={classNames(
            'border-thin border-tint-gray-300 w-full px-1 text-sm',
            {
              'border-warning animate-glowwarning':
                matchCapValueIsOverSubmitted || isInvalidInput,
            },
          )}
          disabled={!toggleMatchCap || requoteDisabled || hasDeployments}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E' || e.key === '-')
              e.preventDefault()
            if (
              e.key === 'Enter' &&
              matchCapValue &&
              !isNaN(Number(matchCapValue)) &&
              matchCapValue !== '0'
            ) {
              e.preventDefault()
              onMatchCap()
            }
          }}
          onChange={(e) => {
            if (/^\d+$/.test(e.target.value) || ' ') {
              setMatchCapValue(e.target.value)
            }
          }}
        />
        <div
          data-tooltip="top"
          aria-label={
            matchCapValueIsOverSubmitted || isInvalidInput
              ? errorMsg()
              : undefined
          }
        >
          <Button
            iconOnly={<Fa icon="save" size={1} />}
            disabled={
              !toggleMatchCap ||
              requoteDisabled ||
              matchCapValueIsOverSubmitted ||
              isInvalidInput
            }
            onClick={() => {
              if (
                matchCapValue &&
                !isNaN(Number(matchCapValue)) &&
                matchCapValue !== '0'
              )
                onMatchCap()
            }}
          />
        </div>
      </div>
      {toggleMatchCap && (
        <div
          className={classNames(
            'animate-slidedown col-span-2 text-xs font-semibold',
            {
              'text-warning-500':
                matchCapValueIsOverSubmitted || isInvalidInput,
            },
          )}
        >
          {matchCapValueIsOverSubmitted || isInvalidInput
            ? errorMsg()
            : `Max: ${audience.initialMatches}`}
        </div>
      )}
    </div>
  )
}
