import React from 'react'
import './Error500.scss'

export const Error500 = () => {
  return (
    <div className="Error500">
      <p className="Error500__main">
        Sorry, we seem to have run into a problem on our side.
      </p>
      <p>
        If this issue persists, please contact us at{' '}
        <a href="mailto:support@eltoro.com">support@eltoro.com</a> or reach out
        at 502-353-0390
      </p>
    </div>
  )
}
