import { V2Target } from 'next-gen-sdk'
import columnTypes from './columnTypes'

export type UploaderColumnsType = {
  src_key: string
  src_index: number
  src_field: string
  payload_key: string
}

/*
  Checks whether the column is an address column. Matches against the following patterns:
  1.) address1 AND not ip/email/id
  2.) address or addr AND not email/id
  3.) street
*/
const columnIsAddress = (col: string): boolean => {
  return (
    (/.*address1.*|.*address.*1.*/gi.test(col) &&
      !/.*ip.*|.*email.*|.*id.*/gi.test(col)) ||
    (/.*address.*|.*addr.*/gi.test(col) && !/.*email.*|.*id.*/gi.test(col)) ||
    /.*street.*/gi.test(col)
  )
}

const generateUniqueColumns = (
  cols: (
    | {
        value: string
        label: string
        fieldName: string
      }
    | undefined
  )[],
): {
  value: string
  label: string
  fieldName: string
}[] => {
  const columnValues = cols.map((col) => col && col.value)
  return cols.map(
    (
      col:
        | {
            value: string
            label: string
            fieldName: string
          }
        | undefined,
      i: number,
    ) => {
      const isDevice = col?.value === 'did' || col?.value === 'maid'
      if (col && (col.value === 'ph' || col.value === 'id')) return col
      if (
        col &&
        columnValues.findIndex((value) => value === (col && col.value)) === i &&
        !isDevice
      )
        return col
      if (
        isDevice &&
        col &&
        columnValues.findIndex(
          (value) => value === 'maid' || value === 'did',
        ) === i
      ) {
        return col
      }
      return { value: 'ig', label: 'Ignore', fieldName: 'other_ignore' }
    },
  )
}

export const guessHeader = (
  row: string[],
  existingTarget?: V2Target,
): UploaderColumnsType[] => {
  const existingColumns = existingTarget?.locations
    ?.filter((l) => l.type === 'source')
    .sort((a, b) => {
      if (!a.createTime || !b.createTime) return 0
      return (
        Date.parse(b.createTime.toISOString()) -
        Date.parse(a.createTime.toISOString())
      )
    })[0]?.columns
  const cols = existingColumns
    ? row.map((col: string) => {
        const existing = existingColumns.find(
          (existing) => existing.value === col,
        )
        if (existing) {
          const value = columnTypes.find(
            ({ fieldName }: { fieldName: string }) =>
              fieldName === existing.type,
          )?.value
          const label = existing.value
          const fieldName = existing.type
          if (!value || !label || !fieldName)
            return { value: 'ig', label: 'Ignore', fieldName: col }
          return { value, label, fieldName }
        }
        return { value: 'ig', label: 'Ignore', fieldName: col }
      })
    : row.map((col: string) => {
        // IP column
        if (/^[i|I][p|P]$/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'i',
          )
        }
        // TODO: IP address range?
        if (columnIsAddress(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'a',
          )
        }
        // City column
        if (/.*city.*|.*town.*/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'c',
          )
        }
        // State column
        if (/.*state.*|.*province.*/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 's',
          )
        }
        // Zip 4
        if (/.*zip.*4.*|.*zip.*four.*/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'ig',
          )
        }
        // Zip Code column
        if (/.*zip.*|.*zipcode.*|.*postal.*/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'z',
          )
        }
        // maids
        if (/.*maid*/gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'maid',
          )
        }
        // device ids
        if (/.*device*|.*did*./gi.test(col)) {
          return columnTypes.find(
            ({ value }: { value: string }) => value === 'did',
          )
        }
        return { value: 'ig', label: 'Ignore', fieldName: col }
      })
  const uniqueColumns = generateUniqueColumns(cols)
  return uniqueColumns.map(
    (
      column: {
        value: string
        fieldName: string
      },
      i: number,
    ) => ({
      src_key: column.fieldName,
      src_index: i,
      src_field: row[i],
      payload_key: column.value,
    }),
  )
}

export const hasRequiredColumns = (
  columns: UploaderColumnsType[],
  audienceType: string,
) => {
  const payload_keys = columns.map((column) => column.payload_key)
  if (audienceType === 'zip') {
    return payload_keys.includes('z')
  }
  if (audienceType === 'device') {
    return payload_keys.includes('maid') || payload_keys.includes('did')
  }
  if (
    audienceType === 'reverseIp' ||
    audienceType === 'ip' ||
    audienceType === 'ipForMailing'
  ) {
    return payload_keys.includes('i')
  }
  if (audienceType === 'address' || audienceType === 'dc') {
    return payload_keys.includes('a') && payload_keys.includes('z')
  }
  return false
}

// Address and ip list; will add other cases in the future
export const hasOnlyRequiredColumns = (
  columns: UploaderColumnsType[],
  audienceType: string,
) => {
  const payload_keys = columns.map((column) => column.payload_key)
  if (payload_keys.includes('i'))
    return columns.length === 1 && hasRequiredColumns(columns, audienceType)
  return columns.length === 2 && hasRequiredColumns(columns, audienceType)
}

// Checks if current columns are compatible with chosen audience type
export const matchesChosenType = (
  audienceType: string, // 'ip' | 'address' | 'dc'
  columns: UploaderColumnsType[],
) => {
  const payload_keys = columns.map((column) => column.payload_key)

  if (
    audienceType === 'ip' ||
    audienceType === 'reverseIp' ||
    audienceType === 'ipForMailing'
  )
    return payload_keys.includes('i')
  if (audienceType === 'address' || audienceType === 'dc')
    return payload_keys.includes('a') && payload_keys.includes('z')
  if (audienceType === 'zip') return payload_keys.includes('z')
  if (audienceType === 'device') {
    return payload_keys.includes('maid') || payload_keys.includes('did')
  }

  return false
}
