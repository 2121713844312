import { useRef, useState } from 'react'
import { Avatar, Popover } from '@eltoro-ui/components'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { Hagridv1User } from 'next-gen-sdk'
import { styleTailwind } from 'Helpers'
import { CopyButton } from 'Components'

const LinkedHeaderItem = styleTailwind(
  'p',
  'text-base-info group-hover:text-text font-bold transition-all group-hover:cursor-pointer group-hover:underline',
)

const DetailRow = styleTailwind(
  'div',
  'grid grid-cols-[0.5fr_3fr_1fr] gap-2 py-1 items-center',
)

export const SalesExecPopover = ({ user }: { user: Hagridv1User }) => {
  const [isOpen, setIsOpen] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  useOnClickOutside([contentRef, buttonRef], () => setIsOpen(false))

  return (
    <>
      <Popover
        position={['bottom']}
        align="center"
        content={
          <div
            ref={contentRef}
            className="SalesExecPopover__content bg-base text-m text-grey-500 rounded p-2 shadow-md"
          >
            <DetailRow>
              <strong>Email</strong>
              <a href={`mailto:${user.email}`}>{user.email}</a>
              <CopyButton
                text={user.email || ''}
                icon="copy"
                className="ml-auto"
              />
            </DetailRow>
            <DetailRow>
              <strong>ID</strong>
              {user.id}
              <CopyButton
                text={user.id || ''}
                icon="copy"
                className="ml-auto"
              />
            </DetailRow>
          </div>
        }
        isOpen={isOpen}
      >
        <button
          type="button"
          className="DetailSubHeader SalesExecPopover__button group flex max-w-max flex-wrap items-center justify-center gap-2"
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') setIsOpen(false)
          }}
          ref={buttonRef}
        >
          <Avatar
            size="s"
            rounded
            name={`${user.firstName} ${user.lastName}`}
          />
          <LinkedHeaderItem className="DetailSubheader">
            {user.firstName} {user.lastName}
          </LinkedHeaderItem>
        </button>
      </Popover>
      <div className="DetailInfo text-basetext">El Toro Sales Executive</div>
    </>
  )
}
