import { ReactNode, useRef, useState, RefObject } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { ColorTokensWithLighterAndDarkerType } from '../Text'
import '../../Assets/css/all.css'
import './HeaderDropdown.scss'
import { Fa } from '../FontAwesome'

export const HeaderDropdown = ({
  ariaLabel,
  children,
  className = '',
  dataTooltip,
  name,
  icon,
  position = 'right',
  on,
  kind,
  hasDropdownIcon = true,
  offClickExclusionRef = [],
}: {
  ariaLabel?: string
  children: ReactNode
  className?: string
  dataTooltip?: string
  position?: 'left' | 'right'
  on?: ColorTokensWithLighterAndDarkerType
  kind?: 'primary' | 'secondary' | 'tertiary'
  hasDropdownIcon?: boolean
  offClickExclusionRef?: RefObject<HTMLElement>[]
} & (
  | { name?: undefined; icon: JSX.Element }
  | { name: string; icon?: JSX.Element }
)) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useOnClickOutside([ref, ...offClickExclusionRef], () => setOpen(false))

  return (
    <div
      className={
        /* eslint-disable-next-line */
        'HeaderDropdown' +
        ` ${className}` +
        ` HeaderDropdown--position-${position}` +
        (open ? ' HeaderDropdown--is-open' : '')
      }
      ref={ref}
    >
      <button
        className="HeaderDropdown__button"
        type="button"
        onClick={() => {
          setOpen(!open)
        }}
        data-tooltip={dataTooltip || 'left'}
        aria-label={ariaLabel || 'Click for more'}
      >
        {!!icon && (
          <span
            className={`${children && name ? 'HeaderDropdown__iconLeft' : ''}`}
          >
            {icon}
          </span>
        )}
        {!!name && name}
        {hasDropdownIcon && (
          <Fa
            className={`HeaderDropdown__icon ${
              open ? 'HeaderDropdown__icon--is-open' : ''
            }`}
            icon="chevron-down"
            size={1}
          />
        )}
      </button>
      {open && (
        <div
          className="HeaderDropdown__options"
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {children}
        </div>
      )}
    </div>
  )
}
