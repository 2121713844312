/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1ResultIDs } from '../models/V1ResultIDs';
import { HttpFile } from '../http/http';

export class V1MainDetails {
    /**
    * Timezone the report is in
    */
    'timezone'?: string;
    /**
    * How many totals hours the request spans
    */
    'hourCount'?: number;
    /**
    * Total number of result objects
    */
    'resultsCount'?: number;
    /**
    * Deaggregates the data based on provided fields
    */
    'levelOfDetail'?: Array<string>;
    /**
    * List of IDs for those results
    */
    'ids'?: Array<V1ResultIDs>;
    /**
    * Beginning of a search time frame
    */
    'start'?: string;
    /**
    * End of a search time frame
    */
    'end'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "timezone",
            "baseName": "timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "hourCount",
            "baseName": "hour_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "resultsCount",
            "baseName": "results_count",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "levelOfDetail",
            "baseName": "level_of_detail",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "ids",
            "baseName": "ids",
            "type": "Array<V1ResultIDs>",
            "format": ""
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "string",
            "format": ""
        },
        {
            "name": "end",
            "baseName": "end",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1MainDetails.attributeTypeMap;
    }

    public constructor() {
    }
}

