/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Billingservicev1Subscription } from '../models/Billingservicev1Subscription';
import { V1Authorization } from '../models/V1Authorization';
import { V1Payment } from '../models/V1Payment';
import { HttpFile } from '../http/http';

/**
* Information needed to process a credit card
*/
export class V1CreditCard {
    /**
    * ID of the credit card
    */
    'id'?: string;
    /**
    * ID of the Customer the credit card belongs to
    */
    'customerId'?: string;
    /**
    * ID of the Org the credit card belongs to
    */
    'orgId'?: string;
    /**
    * List of payments the credit card has made
    */
    'payments'?: Array<V1Payment>;
    /**
    * Last four digits of the credit card
    */
    'cardNumber'?: string;
    /**
    * Expiration date of the credit card
    */
    'expirationDate'?: string;
    /**
    * Credit Card's Nickname
    */
    'nickname'?: string;
    /**
    * First name of the Credit Card holder
    */
    'firstName'?: string;
    /**
    * Last name of the Credit Card holder
    */
    'lastName'?: string;
    /**
    * Address Line 1 for the credit card
    */
    'address1'?: string;
    /**
    * Address Line 2 for the credit card
    */
    'address2'?: string;
    /**
    * City of Credit Card's Address
    */
    'city'?: string;
    /**
    * State of the Credit Card's Address
    */
    'state'?: string;
    /**
    * Zip Code of the Credit Card's Address
    */
    'zip'?: string;
    /**
    * Country of the Credit Card's Address
    */
    'country'?: string;
    'authorization'?: V1Authorization;
    /**
    * Type of the Credit Card
    */
    'type'?: string;
    /**
    * Is this credit card the default?
    */
    'isDefault'?: boolean;
    /**
    * Subscriptions associated with credit card
    */
    'subscriptions'?: Array<Billingservicev1Subscription>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "customerId",
            "baseName": "customer_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "payments",
            "baseName": "payments",
            "type": "Array<V1Payment>",
            "format": ""
        },
        {
            "name": "cardNumber",
            "baseName": "card_number",
            "type": "string",
            "format": ""
        },
        {
            "name": "expirationDate",
            "baseName": "expiration_date",
            "type": "string",
            "format": ""
        },
        {
            "name": "nickname",
            "baseName": "nickname",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "first_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "last_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "address1",
            "baseName": "address1",
            "type": "string",
            "format": ""
        },
        {
            "name": "address2",
            "baseName": "address2",
            "type": "string",
            "format": ""
        },
        {
            "name": "city",
            "baseName": "city",
            "type": "string",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "string",
            "format": ""
        },
        {
            "name": "zip",
            "baseName": "zip",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "authorization",
            "baseName": "authorization",
            "type": "V1Authorization",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "isDefault",
            "baseName": "is_default",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "subscriptions",
            "baseName": "subscriptions",
            "type": "Array<Billingservicev1Subscription>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1CreditCard.attributeTypeMap;
    }

    public constructor() {
    }
}

