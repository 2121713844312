import { OptionalFilterConfig } from 'Components/OptionalFilters'

export const audienceFilterConfig: OptionalFilterConfig[] = [
  {
    filter: 'all',
    icon: 'user',
  },
  {
    filter: 'behavioral',
    icon: 'brain',
  },
  {
    filter: 'location',
    icon: 'location-dot',
  },
  {
    filter: 'address',
    icon: 'building',
  },
  {
    filter: 'geographic',
    icon: 'map',
  },
]
