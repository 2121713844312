/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Creativeservicev1FileType } from '../models/Creativeservicev1FileType';
import { V1FileSubType } from '../models/V1FileSubType';
import { HttpFile } from '../http/http';

export class V1CreativeFile {
    /**
    * ID of the Creative file
    */
    'id'?: string;
    /**
    * The official name of the Creative File.
    */
    'name'?: string;
    /**
    * ID of the creative object the file connected to
    */
    'creativeId'?: string;
    /**
    * Date and time a Creative File was created at
    */
    'createTime'?: Date;
    /**
    * Date and time a Creative File was last updated
    */
    'updateTime'?: Date;
    /**
    * Date and time a Creative File was deleted
    */
    'deleteTime'?: Date;
    'type'?: Creativeservicev1FileType;
    'subType'?: V1FileSubType;
    /**
    * S3 Bucket where the Creative File is stored in
    */
    'bucket'?: string;
    /**
    * S3 Key of the Creative File. Format is \"<environment>/<creative_file_id>/<creative_file_name>\"
    */
    'key'?: string;
    /**
    * An IANA published MIME type (also referred to as media type).
    */
    'mime'?: string;
    /**
    * The byte size of the creative file
    */
    'size'?: number;
    /**
    * Height of the creative file in pixels
    */
    'height'?: number;
    /**
    * Width of the creative file in pixels
    */
    'width'?: number;
    /**
    * eTag of the creative file
    */
    'etag'?: string;
    /**
    *  URI that the creative is connected to when serving
    */
    'uri'?: string;
    /**
    * The length of videos creatives
    */
    'duration'?: number;
    /**
    * The file extension
    */
    'extension'?: string;
    /**
    * The bitrate
    */
    'bitrate'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeId",
            "baseName": "creative_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "Creativeservicev1FileType",
            "format": ""
        },
        {
            "name": "subType",
            "baseName": "sub_type",
            "type": "V1FileSubType",
            "format": ""
        },
        {
            "name": "bucket",
            "baseName": "bucket",
            "type": "string",
            "format": ""
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string",
            "format": ""
        },
        {
            "name": "mime",
            "baseName": "mime",
            "type": "string",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "etag",
            "baseName": "etag",
            "type": "string",
            "format": ""
        },
        {
            "name": "uri",
            "baseName": "uri",
            "type": "string",
            "format": ""
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "extension",
            "baseName": "extension",
            "type": "string",
            "format": ""
        },
        {
            "name": "bitrate",
            "baseName": "bitrate",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1CreativeFile.attributeTypeMap;
    }

    public constructor() {
    }
}

