import React, { useEffect, useState } from 'react'
import { Button, Modal, Fa, TextHeader, Text } from '@eltoro-ui/components'
import './AdTagViewer.scss'
import { useAppContext, useLibraryContext } from 'Contexts'
import { WarningModal } from 'Components'

interface AdTagViewerType {
  offClick: () => void
  adTag?: string
  id?: string
}

export const AdTagViewer = ({ adTag, id, offClick }: AdTagViewerType) => {
  const [editedAdTag, setEditedAdTag] = useState('')
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const { creativeServiceApi, currentOrg } = useAppContext()
  useEffect(() => {
    if (adTag) {
      setEditedAdTag(adTag)
    }
  }, [adTag])
  const handleAdTagUpdate = () => {
    if (!id || !currentOrg?.id) return
    creativeServiceApi
      ?.advertisingPlatformServiceUpdateCreative(
        id,
        currentOrg?.id,
        {
          adTag: editedAdTag,
        },
        'adTag',
      )
      .then(() => offClick())
  }
  return (
    <Modal
      className="AdTagViewer__modal"
      classNameContent="overflow-visible"
      offClick={offClick}
    >
      <div className="flex flex-row justify-between pb-2">
        <TextHeader>View/Edit your tag</TextHeader>
        <div className="AdTagViewer__close-button">
          <Button
            iconOnly={<Fa icon="times" size={1} />}
            rounded
            onClick={
              adTag === editedAdTag ? offClick : () => setWarningModalOpen(true)
            }
          />
        </div>
      </div>
      <textarea
        className="border-grey-400 w-4/5 resize rounded border p-2"
        value={editedAdTag}
        onChange={(e) => setEditedAdTag(e.target.value)}
      />
      <div className="AdTagViewer_buttons ButtonGroup ml-auto flex justify-end pt-6">
        <Button
          type="submit"
          iconLeft={<Fa className="text-primary" icon="fa-times" size={1} />}
          onClick={
            adTag === editedAdTag ? offClick : () => setWarningModalOpen(true)
          }
        >
          Cancel
        </Button>
        <Button
          type="submit"
          iconLeft={<Fa icon="fa-save" size={1} />}
          disabled={adTag === editedAdTag}
          className="ml-2"
          onClick={() => {
            handleAdTagUpdate()
          }}
        >
          Save
        </Button>
      </div>
      {warningModalOpen && (
        <WarningModal
          onConfirm={() => {
            offClick()
            setWarningModalOpen(false)
          }}
          onCancel={() => setWarningModalOpen(false)}
        >
          <Text size="l">
            You have modified your tag, are you sure you want to cancel?
          </Text>
        </WarningModal>
      )}
    </Modal>
  )
}
