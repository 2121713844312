/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Attachment } from '../models/V1Attachment';
import { V1Message } from '../models/V1Message';
import { HttpFile } from '../http/http';

/**
* The info about the source file being attached as well as the data in the email
*/
export class V1RequestSupportRequest {
    'attachments'?: Array<V1Attachment>;
    'message'?: V1Message;
    'orgId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "attachments",
            "baseName": "attachments",
            "type": "Array<V1Attachment>",
            "format": ""
        },
        {
            "name": "message",
            "baseName": "message",
            "type": "V1Message",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1RequestSupportRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

