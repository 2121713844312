import { useCallback, useState, useRef } from 'react'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import {
  Orgmanagerv1Org,
  V1ListOrderLinesResponse,
  V1OrderLineStatus,
  V1StatsResponse,
} from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { getOrgFilter } from 'Helpers'
import { showErrorMessage } from '@eltoro-ui/components'
import { OrderLineTableRow } from 'types'
import { statMakerForEnvironment } from 'Components/Reports/Stats/statMockData'
import { getOrderLineStats } from 'Helpers/getOrderLineStats'

export const useDashboardTableData = () => {
  const [childOrgsOnly, setChildOrgsOnly] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [sort, setSort] = useState<string>()
  const [childOrgFilter, setChildOrgsFilter] = useState<string[]>([])
  const [filter, setFilter] = useState<{
    [field: string]: Array<string | number>
  }>()
  const debouncedFilter = useDebounce(filter, 200)
  const isMounted = useRef(false)

  const {
    currentOrg,
    campaignServiceApi,
    setRebuild,
    errorRebuildCount,
  } = useAppContext()

  const handleFetchOrderLines = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }
      if (currentOrg?.id && campaignServiceApi && isMounted.current) {
        const filterString =
          debouncedFilter &&
          Object.entries(debouncedFilter)
            .reduce((acc: string[], [key, value]) => {
              if (!value.length) return acc
              const string = value
                .map((val) => {
                  // old OLs did not get migrated, use status to filter archived
                  if (key === 'state' && val === 8) {
                    return 'status = 90'
                  }
                  return `${key} = ${
                    typeof val === 'number' ||
                    key === 'status' ||
                    key === 'state'
                      ? val
                      : `"*${val}*"`
                  }`
                })
                .join(' OR ')

              return [...acc, string]
            }, [])
            .join(' AND ')
        const archiveFilter = () => {
          // if filterString INCLUDES status OR if we need to show the archived, then ignore this filter
          if (
            (filterString?.includes('status') &&
              !filterString?.includes('!= 90')) ||
            (filterString?.includes('state') &&
              !filterString?.includes('= 8')) ||
            showArchived
          ) {
            return ''
          }
          return 'AND status != 90 AND state !=8'
        }
        const res = await campaignServiceApi
          .advertisingPlatformServiceListOrderLines(
            pageSize, // pageSize
            nextPageToken, // pageToken
            sort || 'create_time desc', // orderBy
            `${
              childOrgFilter && childOrgFilter.length > 0
                ? getOrgFilter(false, currentOrg, childOrgFilter)
                : getOrgFilter(childOrgsOnly, currentOrg)
            }${filterString ? ` AND ${filterString}` : ''} ${archiveFilter()}`, // filter
          )
          .then((res) => res)
          .catch((err) => {
            const {
              body: { code, message },
            } = err
            if (code === 401 && errorRebuildCount.current < 3) {
              const newCount = errorRebuildCount.current + 1
              errorRebuildCount.current = newCount
              setRebuild(true)
            }
            if (code !== 401 || errorRebuildCount.current === 3)
              showErrorMessage('Error getting order lines', `${message || ''}`)
            return {
              orderLines: [],
              totalSize: 0,
            } as V1ListOrderLinesResponse
          })

        if (res?.orderLines) {
          const tableRows = await Promise.all(
            res.orderLines.map(async (ol) => {
              let childOrg: Orgmanagerv1Org | undefined
              let stats: V1StatsResponse | undefined
              // if childOrgsOnly and not on the currentOrg (top level), getChildOrg
              if (childOrgsOnly && ol.orgId !== currentOrg.id) {
                childOrg = currentOrg?.childOrgs?.find(
                  (org) => org.id === ol.orgId,
                )
              }
              if (
                ol.status &&
                ([
                  'ORDERLINE_STATUS_DEPLOYED',
                  'ORDERLINE_STATUS_ERRORED_AND_DEPLOYED',
                  'ORDERLINE_STATUS_ERRORED_AND_SERVING',
                  'ORDERLINE_STATUS_SERVING',
                  'ORDERLINE_STATUS_COMPLETED',
                  'ORDERLINE_STATUS_ARCHIVED',
                  'ORDERLINE_STATUS_PAUSED',
                  'ORDERLINE_STATUS_DEPLOYING',
                ] as V1OrderLineStatus[]).includes(ol.status)
              ) {
                stats =
                  process.env.REACT_APP_ENV === 'production' &&
                  !/dev/.test(window.location.href)
                    ? await getOrderLineStats(ol, currentOrg.id || '')
                    : statMakerForEnvironment(ol)
              }

              return {
                orderLine: ol,
                stats,
                childOrg,
              } as OrderLineTableRow
            }),
          ).then((res) => res)
          return {
            data: tableRows,
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } else {
        isMounted.current = true
      }

      return empty
    },
    [
      currentOrg,
      campaignServiceApi,
      debouncedFilter,
      sort,
      childOrgsOnly,
      showArchived,
      errorRebuildCount,
      setRebuild,
      childOrgFilter,
    ],
  )

  const paginationProps = useTokenPagination<OrderLineTableRow>(
    {
      pageSize: 10,
      fetchData: handleFetchOrderLines,
    },
    [
      childOrgsOnly,
      showArchived,
      currentOrg,
      sort,
      debouncedFilter,
      childOrgFilter,
    ],
  )

  return {
    paginationProps,
    childOrgsOnly,
    setChildOrgsOnly,
    showArchived,
    setShowArchived,
    sort,
    setSort,
    filter,
    setFilter,
    setChildOrgsFilter,
  }
}
