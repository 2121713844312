/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { V1DownloadBundleResponse } from '../models/V1DownloadBundleResponse';
import { HttpFile } from '../http/http';

export class StreamResultOfV1DownloadBundleResponse {
    'result'?: V1DownloadBundleResponse;
    'error'?: GooglerpcStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "result",
            "baseName": "result",
            "type": "V1DownloadBundleResponse",
            "format": ""
        },
        {
            "name": "error",
            "baseName": "error",
            "type": "GooglerpcStatus",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StreamResultOfV1DownloadBundleResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

