import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import {
  Campaignservicev1OrderLine,
  V1StatsResponse,
  V1ResultMetrics,
} from 'next-gen-sdk'
import { getDaysBetween, styleTailwind } from 'Helpers'
import './ServeStatsTable.scss'
import dayjs from 'dayjs'

const YAxisLabel = styleTailwind('th', 'font-bold')
const FB = styleTailwind('div', 'Flexbox flex flex-col gap-3')

const StatMovementIndicator = ({
  num1,
  num2,
  percent,
}: {
  num1: number
  num2: number
  percent?: boolean
}) => {
  const baseClassName = 'ServeStatsTable__movement-indicator'
  const difference = num1 - num2

  if (!difference) return null

  return (
    <div
      className={classNames(baseClassName, {
        [`${baseClassName}--orange`]: difference < 0,
        [`${baseClassName}--blue`]: difference > 0,
      })}
    >
      {difference > 0 ? (
        <>
          <Fa icon="arrow-up" size="sm" />
          {`+${parseFloat(difference.toFixed(3))}${percent ? '%' : ''}`}
        </>
      ) : (
        <>
          <Fa icon="arrow-down" size="sm" />
          {`${parseFloat(difference.toFixed(3))}${percent ? '%' : ''}`}
        </>
      )}
    </div>
  )
}

export const ServeStatsTable = ({
  orderline,
  stats,
}: {
  orderline: Campaignservicev1OrderLine
  stats?: V1StatsResponse
}) => {
  const getPercentageText = (percent: number) => {
    if (Number.isNaN(percent)) return 'no results'
    if (Math.abs(percent) > 1) return `${parseFloat(percent.toFixed(1))}%`
    return `${parseFloat(percent.toFixed(3))}%`
  }

  const getDayTotals = (day: 'yesterday' | 'today' | 'week') => {
    if (stats?.results) {
      const byDayResults = stats?.results[0]?.results
      const yesterday = byDayResults
        ?.map((c) => c)
        .filter((cur) => {
          const yDay = dayjs()
            .startOf('day')
            .subtract(1, 'day')
            .utc(true)
            .format('YYYY-MM-DD')
          if (!cur.end) return {}
          return (
            dayjs(cur.end).startOf('day').utc(true).format('YYYY-MM-DD') ===
            yDay
          )
        })
      const today = byDayResults
        ?.map((c) => c)
        .filter((cur) => {
          const day = dayjs().startOf('day').utc(true).format('YYYY-MM-DD')
          if (!cur.start) return {}
          return (
            dayjs(cur.end).startOf('day').utc(true).format('YYYY-MM-DD') === day
          )
        })
      const sevenDayIndex = byDayResults?.findIndex((cur) => {
        const day = dayjs().startOf('day').utc(true).format('YYYY-MM-DD')
        if (!cur.start) return {}
        return (
          dayjs(cur.end).startOf('day').utc(true).format('YYYY-MM-DD') === day
        )
      })
      const sevenDayStats =
        sevenDayIndex && sevenDayIndex > 7
          ? byDayResults?.slice(sevenDayIndex - 7, sevenDayIndex)
          : byDayResults?.slice(0, sevenDayIndex)
      if (byDayResults) {
        if (day === 'yesterday' && yesterday) return yesterday[0]
        if (day === 'week') return sevenDayStats
        if (day === 'today' && today) return today[0]
      }
    }
    return null
  }

  const yesterdayStats = getDayTotals('yesterday') as V1ResultMetrics
  const todayStats = getDayTotals('today') as V1ResultMetrics
  const weeklyStats = getDayTotals('week') as V1ResultMetrics[]
  const weekStats = weeklyStats?.reduce(
    (acc: V1ResultMetrics, currentResult: V1ResultMetrics) => {
      return {
        imps: Number(currentResult.imps || 0) + Number(acc.imps || 0),
        clicks: Number(currentResult.clicks || 0) + Number(acc.clicks || 0),
      } as V1ResultMetrics
    },
    {},
  )
  const lifetimeStats = stats?.totals

  const orderLineCouldBeRunning =
    orderline.endTime &&
    orderline.endTime.getTime() > new Date().getTime() &&
    ![
      'ORDERLINE_STATUS_REJECTED',
      'ORDERLINE_STATUS_COMPLETED',
      'ORDERLINE_STATUS_ARCHIVED',
    ].includes(orderline.status || '') // rejected, completed, archived

  // TODO!!! Fix this math
  // Lifetime: (imps served / imps budgeted * 1.01)/(days served / days budgeted)
  const lifetimeImpsServedPercent =
    Number(lifetimeStats?.imps || 0) / (Number(orderline.impressions) * 1.01) ||
    0

  const daysServedPercent =
    (orderline.startTime &&
      orderline.endTime &&
      (getDaysBetween(orderline.startTime, new Date()) === 0
        ? 1
        : getDaysBetween(orderline.startTime, new Date())) /
        getDaysBetween(orderline.startTime, orderline.endTime)) ||
    0

  // Today: (serve today)/(imps remaining now + serve today)/(days remaining)
  const todayPace =
    orderline.endTime &&
    orderline.impressions &&
    (Number(todayStats?.imps) || 0) /
      ((orderline?.impressions - Number(lifetimeStats?.imps || 0)) /
        (getDaysBetween(new Date(), orderline.endTime) === 0 &&
        orderline.endTime
          ? 1
          : getDaysBetween(new Date(), orderline.endTime)))

  // BEWARE: need to see if stats endpoint is including today? Assuming lifetime already includes today:
  // 7 day: (last 7 days serve)/(7*(imps remaining + serve today))/(days remaining)
  const weekPace =
    weekStats?.imps &&
    orderline.impressions &&
    orderline.endTime &&
    Number(weekStats.imps) /
      ((7 * (orderline?.impressions - Number(lifetimeStats?.imps || 0))) /
        (getDaysBetween(new Date(), orderline.endTime) === 0 &&
        orderline.endTime
          ? 1
          : getDaysBetween(new Date(), orderline.endTime)))

  // Yesterday: (imps served yesterday) / ((imps remaining + imps served yesterday)/days left from now + 1)
  const yesterdayPace =
    yesterdayStats?.imps &&
    todayStats?.imps &&
    lifetimeStats?.imps &&
    orderline.impressions &&
    orderline.endTime &&
    Number(yesterdayStats.imps) /
      ((orderline?.impressions -
        Number(lifetimeStats?.imps) +
        Number(yesterdayStats?.imps)) /
        (getDaysBetween(new Date(), orderline.endTime) === 0 &&
        orderline.endTime
          ? 1
          : getDaysBetween(new Date(), orderline.endTime) + 1))

  return (
    <div className="ServeStatsTable w-full">
      <table>
        <thead>
          <tr>
            <th />
            <th scope="col">Impressions</th>
            <th scope="col">Pace %</th>
            <th scope="col">Clicks</th>
            <th scope="col">CTR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <YAxisLabel>Lifetime</YAxisLabel>
            <td>
              {/* Impressions */}
              <FB>
                {lifetimeStats?.imps}
                {orderLineCouldBeRunning && (
                  <StatMovementIndicator
                    num1={Number(lifetimeStats?.imps || 0)}
                    num2={
                      Number(lifetimeStats?.imps || 0) -
                      Number(todayStats?.imps || 0)
                    }
                  />
                )}
              </FB>
            </td>
            <td>
              {/* Pace */}
              {!!orderline.impressions &&
              lifetimeStats?.imps !== undefined &&
              !!daysServedPercent ? (
                <FB>
                  {getPercentageText(
                    (lifetimeImpsServedPercent / daysServedPercent) * 100,
                  )}

                  {orderLineCouldBeRunning && (
                    <StatMovementIndicator
                      num1={
                        (lifetimeImpsServedPercent / daysServedPercent) * 100
                      }
                      num2={
                        (lifetimeImpsServedPercent / daysServedPercent) * 100 -
                        (todayPace || 0) * 100
                      }
                      percent
                    />
                  )}
                </FB>
              ) : (
                '0%'
              )}
            </td>
            <td>
              {/* Clicks */}
              <FB>
                {lifetimeStats?.clicks}
                {orderLineCouldBeRunning && (
                  <StatMovementIndicator
                    num1={Number(lifetimeStats?.clicks || 0)}
                    num2={
                      Number(lifetimeStats?.clicks || 0) -
                      Number(todayStats?.clicks || 0)
                    }
                  />
                )}
              </FB>
            </td>
            <td>
              {/* CTR */}
              <FB>
                {lifetimeStats?.clicks !== undefined &&
                  lifetimeStats?.imps !== undefined &&
                  getPercentageText(
                    (Number(lifetimeStats.clicks) /
                      Number(lifetimeStats.imps)) *
                      100,
                  )}

                {orderLineCouldBeRunning && (
                  <StatMovementIndicator
                    num1={
                      (Number(todayStats?.clicks || 0) /
                        Number(todayStats?.imps || 0)) *
                      100
                    }
                    num2={
                      (Number(lifetimeStats?.clicks || 0) /
                        Number(lifetimeStats?.imps || 0)) *
                      100
                    }
                    percent
                  />
                )}
              </FB>
            </td>
          </tr>
          {orderLineCouldBeRunning &&
            [todayStats, yesterdayStats, weekStats].map((statsObj, index) => {
              return (
                <tr
                  key={(() => {
                    if (index === 0) return 'today-stats'
                    if (index === 1) return 'yesterday-stats'
                    if (index === 2) return 'week-stats'
                    return ''
                  })()}
                >
                  {index === 0 && <YAxisLabel>Today</YAxisLabel>}
                  {index === 1 && <YAxisLabel>Yesterday</YAxisLabel>}
                  {index === 2 && <YAxisLabel>7 Day</YAxisLabel>}
                  <td>{statsObj?.imps || 0}</td>
                  {index === 0 && (
                    <td>{getPercentageText((todayPace || 0) * 100)}</td>
                  )}
                  {index === 1 && (
                    <td>{getPercentageText((yesterdayPace || 0) * 100)}</td>
                  )}
                  {index === 2 && (
                    <td>{getPercentageText((weekPace || 0) * 100)}</td>
                  )}
                  <td>{statsObj?.clicks || 0}</td>
                  <td>
                    {statsObj?.clicks !== undefined &&
                      statsObj?.imps !== undefined &&
                      getPercentageText(
                        (Number(statsObj.clicks) / Number(statsObj.imps)) * 100,
                      )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}
