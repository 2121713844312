import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import { ThemeContext } from 'Contexts'

import { Button, Fa } from '@eltoro-ui/components'

export const LoginPage = () => {
  const location = useLocation()
  const auth = useAuth()
  const isLoggedIn = auth && auth.isAuthenticated

  const loginKC = useCallback(() => {
    auth?.signinRedirect()
  }, [auth])
  useEffect(() => {
    if (!isLoggedIn && !auth.isLoading && location.pathname !== '/logout') {
      loginKC()
    }
  }, [auth.isLoading])

  const { logoLarge } = useContext(ThemeContext)

  return (
    <div className="LoginPage bg-primer flex h-full w-full">
      <div className="m-auto flex max-w-sm flex-col items-center justify-center gap-5">
        <div className="LoginPage__logo">
          <img className="object-fit" alt="logo" src={logoLarge} />
        </div>
        {location.pathname === '/logout' && (
          <div className="LoginPage__logout-content">
            <b>You’ve Successfully Logged Out</b>
          </div>
        )}
        {location.pathname !== '/logout' ? (
          <>
            <Fa
              icon="spinner"
              size={2}
              animate="spin"
              color="var(--tint-khaki)"
            />{' '}
            Loading Login Provider
          </>
        ) : (
          <Button kind="primary" size="l" type="submit" onClick={loginKC}>
            LOGIN
          </Button>
        )}
      </div>
    </div>
  )
}
