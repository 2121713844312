import { addressSizePixels, stampSizePixels } from 'Helpers'
import { selector } from 'recoil'
import { directMailSizeAtom } from './state'

export const stampSizeSelector = selector({
  key: 'stampSize',
  get: ({ get }) => {
    const directMailSize = get(directMailSizeAtom)
    if (!directMailSize)
      return {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      }
    const { width, height, x, y } = stampSizePixels(directMailSize)
    return { width, height, x, y }
  },
})

export const addressSizeSelector = selector({
  key: 'addressSizeSelector',
  get: ({ get }) => {
    const directMailSize = get(directMailSizeAtom)
    if (!directMailSize)
      return {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
      }
    const { width, height, x, y } = addressSizePixels(directMailSize)
    return { width, height, x, y }
  },
})
