import { getApiUrl } from 'Helpers'
import * as SDK from 'next-gen-sdk'

export const ApiFactory = (token: string | undefined | null) => {
  if (token === null) {
    setTimeout(() => {
      ApiFactory(localStorage.getItem('eltoro_token'))
    }, 5000)
  }
  const url = getApiUrl()
  if (token) {
    const apiConfiguration = SDK.createConfiguration({
      baseServer: new SDK.ServerConfiguration(url, {}),
      authMethods: { OAuth2: { accessToken: `${token}` } },
    })
    const orgServiceAPI = new SDK.OrganizationsApi(apiConfiguration)
    const audienceServiceAPI = new SDK.AudiencesApi(apiConfiguration)
    const statsServiceAPI = new SDK.StatsApi(apiConfiguration)
    const targetServiceAPI = new SDK.TargetsApi(apiConfiguration)
    const creativeServiceAPI = new SDK.CreativesApi(apiConfiguration)
    const notificationsAPI = new SDK.NotificationsApi(apiConfiguration)
    const campaignServiceAPI = new SDK.CampaignsApi(apiConfiguration)
    const billingServiceAPI = new SDK.BillingApi(apiConfiguration)
    const webToHomeAPI = new SDK.WebToHomeApi(apiConfiguration)
    const newMoverAPI = new SDK.NewMoverApi(apiConfiguration)
    const searchServiceAPI = new SDK.GlobalSearchApi(apiConfiguration)
    const tosApi = new SDK.TermsOfServiceApi(apiConfiguration)
    const userServiceAPI = new SDK.UsersApi(apiConfiguration)
    return {
      orgServiceAPI,
      statsServiceAPI,
      targetServiceAPI,
      creativeServiceAPI,
      notificationsAPI,
      campaignServiceAPI,
      billingServiceAPI,
      webToHomeAPI,
      newMoverAPI,
      searchServiceAPI,
      audienceServiceAPI,
      tosApi,
      userServiceAPI,
    }
  }
  return {}
}
